import  {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {fetchTickets} from '../../actions/TicketAction';
import classes from './TicketsBuilder.module.css';

const Header = (props) => {
    return (
        <div className={classes.list_header}>
            <div className={classes.msg_header}>
                {props.acceptingChat &&
                <div className={classes.header_text}>{props.header}</div>
                }
                {props.acceptingChat === false &&
                <div className={classes.header_text_red}>You are currently not accepting chats</div>
                }
            </div>
        </div>
    )
}

const TicketList = (props) => {
    return (
        <tr onClick={() => props.onTicketClick(props.uid, props.subject, props.yourTicket)}>
            <td className={classes.name_content}>
                <div className={classes.name_content_list}>
                    <div className={classes.image_content}>
                        <div className={classes.image_view}>
                            <span>{(props.name).charAt(0).toUpperCase()}</span>
                        </div>
                    </div>
                    <div className={classes.name_data_list}>
                        <p>{props.name}</p>
                        <p style={{color: 'rgba(66, 77, 87, 0.6)'}}>{props.email}</p>
                    </div>
                </div>
            </td>
            <td className={classes.subject_content}>{props.subject}</td>
            <td className={classes.assigned_content}>{props.name}</td>
            <td className={classes.status_content}>
                {props.status &&
                <div className={classes.status_highlight_open}>
                    <div className={classes.status_style_open}>Open</div>
                </div>
                }
                {!props.status &&
                <div className={classes.status_highlight_close}>
                    <div className={classes.status_style_close}>Solved</div>
                </div>
                }
            </td>
        </tr>
    )
}

class TicketsBody extends Component {

    componentDidMount() {
        this.props.fetchTickets();
    }

    onTicketClick = (uid, subject, owner) => {
        const path = {
            pathname: '/tickets/update',
            search: '',
            hash: '',
            state: {ticketID: uid, subject: subject, owner: owner}
        }
        this.props.history.push(path);
    }

    render() {
        const TicketLists = this.props.tickets.map(ticket => {
            return <TicketList
                key={ticket.uid}
                uid={ticket.uid}
                subject={ticket.subject}
                email={ticket.requester_email}
                name={ticket.requester_name}
                status={ticket.status}
                yourTicket={ticket.your_ticket}
                onTicketClick={(uid, subject) => this.onTicketClick(uid, subject)}
            />
        });
        return (
            <div className={classes.main_body}>
                <div className={classes.main_block}>
                    <div className={classes.msg_body}>
                        <Header header={this.props.header} acceptingChat={this.props.acceptingChat}/>
                    </div>
                    <div className={classes.list}>
                        <div className={classes.detail_content}>
                            <div className={classes.detail_view}>
                                <div className={classes.main_detail_view}>
                                    <div className={classes.detail_count}>{this.props.tickets.length} tickets</div>
                                </div>
                                <div className={classes.detail_content_body}>
                                    <div className={classes.detail_content_view}>
                                        <div className={classes.detail_header}>
                                            <table>
                                                <thead>
                                                <tr>
                                                    <td>Requester</td>
                                                    <td>Subject</td>
                                                    <td>Assigned</td>
                                                    <td>Status</td>
                                                </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div className={classes.detail_body}>
                                            <table>
                                                <tbody>
                                                {TicketLists}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ticketsReducer, conversationReducer, acceptingReducer}) => {
    const {tickets} = ticketsReducer;
    const {acceptingChat} = acceptingReducer
    return {tickets, acceptingChat};
}

export default compose(withRouter, connect(mapStateToProps, {fetchTickets}))(TicketsBody);
