import {BILLING_CODE_CHANGE, BILLING_CODE_DESC_CHANGE, BILLING_CODE_SAVE, FETCH_BILLING_CODES} from '../constants';

const INITIAL_STATE = {billing_codes: [], billing_code_value: '', billing_code_desc_value: ''};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BILLING_CODE_CHANGE:
            return {...state, billing_code_value: action.payload};
        case BILLING_CODE_DESC_CHANGE:
            return {...state, billing_code_desc_value: action.payload};
        case BILLING_CODE_SAVE:
            return {...state, billing_code_value: '', billing_code_desc_value: ''};
        case FETCH_BILLING_CODES:
            return {...state, billing_codes: action.payload};
        default:
            return state;
    }
}
