import {FETCH_PROFILE} from '../constants';

const INITIAL_STATE = {profile_data: [], uid: null};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_PROFILE:
            return {...state, profile_data: action.payload.doctor, uid: action.payload.uid};
        default:
            return state;
    }
}
