import  {Fragment, useState} from 'react';
import ChatList from './list';
import SendBox from './box';
import classes from '../encounters.module.css';

import _classes from '../_encounters.module.css';
import _style from '../../../../assets/_classes.module.css'
import isMobileView from '../../../../utils/isMobileView';
import _Header from '../../../../components/UI/Header/Header'
import {FaBars, FaPhone, FaPhoneSlash} from "react-icons/fa";
import moment from 'moment';
import CallWithTwilio from '../../../../components/twilioCallComponent/CallWithTwilio'

const duration = 1000
const sidebarStyle = {
    transition: `width ${duration}ms`
}
const sidebarTransitionStyles = {
    entering: {width: 0},
    entered: {width: '250px'},
    exiting: {width: '250px'},
    exited: {width: 0}
}
const linkStyle = {
    transition: `opacity ${duration}ms`
}
const linkTransitionStyles = {
    entering: {opacity: 0},
    entered: {opacity: 1},
    exiting: {opacity: 1},
    exited: {opacity: 0}
}
const AgentList = (props) => {
    return (
        <li>
            <div className={classes.main_assign_view}>
                <div className={classes.main_assign_inner_view}>
                    <img src={props.profileImageURL}
                         alt="//cdn.livechatinc.com/cloud/?uri=https://livechat.s3.amazonaws.com/default/avatars/female_44.jpg"/>
                    <span className={classes.online_style}></span>
                    <div className={classes.main_assign_content}>
                        <div className={classes.name_text}>{props.name}</div>
                        <div className={classes.email_text}>{props.email}</div>
                    </div>
                    <div className={classes.main_transfer_button}>
                        <button onClick={() => props.onAssignClick(props.doctor)}>Transfer Chat</button>
                    </div>
                </div>
            </div>
        </li>
    )
}

const Header = (props) => {
    const agentslist = props.transfer_doctors && props.transfer_doctors.map(agent => {
        if (agent !== undefined && agent.transferChat) {
            return <AgentList
                key={agent.uid}
                doctor={agent}
                profileImageURL={agent.profileImageURL}
                name={agent.fullName}
                email={agent.staffEmail}
                onAssignClick={(agent) => props.onAssignClick(agent)}
            />
        } else {

        }
    });


    var DOB = props.DOB;
    if (DOB !== "NA") {
         // let timeNow = new Date().getFullYear();
        // let ageInYears = timeNow - new Date(dob).getFullYear();
        let ageInYears = moment().diff(moment(DOB, "MMMM,DD,YYYY"), "years");
        console.log("xxyx",DOB)
        DOB = DOB + " (" + ageInYears + ")";
    }
    // eslint-disable-next-line array-callback-return
    var rate_tag = props.tags?.find(tag => {
        if (tag !== undefined) {
            if (tag.tag === 'rating_screen') {
                return tag;
            }
        }
    });
    var ban_tag = props.tags?.find(tag => {
        if (tag !== undefined) {
            if (tag.tag === 'ban') {
                return tag;
            }
        }
    });
    if (!rate_tag) {
        rate_tag = {tag: null, uid: null, id: null}
    }
    if (!ban_tag) {
        ban_tag = {tag: null, uid: null, id: null}
    }
    return (

        <Fragment>
            <div className={classes.list_header}>
                {props.showChat &&
                <div className={classes.msg_header}>
                    <div className={classes.scrollable}>
                        <div className={classes.info_row}>
                            <div className={classes.col_4}><span className={classes.header_attr}>Patient:</span> <span
                                className={classes.header_attr_value}> {props.name}</span></div>
                            <div className={classes.col_4}><span className={classes.header_attr}>D.O.B:</span> <span
                                className={classes.header_attr_value}> {DOB}</span></div>
                            <div className={classes.col_4}><span className={classes.header_attr}>Gender:</span> <span
                                className={classes.header_attr_value}> {props.gender}</span></div>
                        </div>
                        <div className={classes.info_row}>
                            <div className={classes.col_6}><span className={classes.header_attr}>Issue:</span> <span
                                className={classes.header_attr_value}>{props.symptoms}</span></div>
                        </div>
                    </div>
                </div>
                }
                {!props.showChat &&
                <Fragment>
                    <div className={classes.msg_header_default}>
                        <span className={classes.header_text}>Select a chat from left pane</span>
                    </div>
                </Fragment>
                }
                <div className={classes.info_section_col}>
                    {(props.doctorIsValid || props.adminIsValid) &&
                    <Fragment>
                        {props.showChat &&
                        // <div className={classes.header_banUser} style={{right:'140px'}} onClick={() => props.videoChat()}>
                        //     <span>Start Conference Call</span>
                        // </div>
                        <div className={classes.header_banUser} style={{right: '140px'}}>
                            {/* {this is twilio call button for desktop version} */}
                            <CallWithTwilio
                                patientId={props.patientId}
                                doctorID={props.staffData.uid}
                                patientNumber={props.patient_phone_number}
                                defaultComponent={
                                    <button className={classes.btn2}
                                            style={{
                                                'marginTop': '8px'
                                            }}
                                            type="button">
                                        Call Patient
                                    </button>
                                }
                                onCallingComponent={
                                    <button className={classes.btn2}
                                            style={{
                                                'marginTop': '8px'
                                            }}
                                            type="button">
                                        Calling...
                                    </button>
                                }
                                onConnectedComponent={
                                    <button
                                        className={classes.btn2}
                                        style={{
                                            "backgroundColor": "Red",
                                            "fontColor": "white",
                                            'marginTop': '8px'
                                        }}
                                        type="button"
                                    >
                                        Hang Up
                                    </button>
                                }
                                onRingingComponent={
                                    <button
                                        className={classes.btn2}
                                        style={{
                                            "backgroundColor": "Green",
                                            "fontColor": "white",
                                            'marginTop': '8px'
                                        }}
                                        type="button"
                                    >
                                        Ringing...
                                    </button>
                                }
                            ></CallWithTwilio>
                        </div>
                        }
                        {/*props.staffData && props.staffData.transferChat &&
                                <div className={classes.header_transfer} onClick={() => props.transferChat()}>
                                    <span>Transfer Chat</span>
                                </div>
                            */}

                        {/* props.showChat &&
                                <div className={classes.header_rate} onClick={() => props.onTagClick(rate_tag.tag, rate_tag.id, rate_tag.uid)}>
                                    <span>Get Patient Review</span>
                                </div> */
                        }

                        {/* props.showChat &&
                                <div className={classes.header_banUser} onClick={() => props.onTagClick(ban_tag.tag, ban_tag.id, ban_tag.uid)}>
                                    <span>Ban User For Spam</span>
                                </div> */
                        }
                    </Fragment>
                    }
                </div>
            </div>
            {props.showOnlineDoctors && agentslist.length !== 0 &&
            <div className={classes.one}>
                <div className={classes.assign_list_view}>
                    <div className={classes.main_assign_list}>
                        <ul>
                            {agentslist}
                        </ul>
                    </div>
                </div>
            </div>
            }
            {props.showOnlineDoctors && agentslist.length === 0 &&
            <div className={classes.one}>
                <div className={classes.assign_list_view}>
                    <div className={classes.main_assign_list}>
                        <p>No other doctor is online</p>
                    </div>
                </div>
            </div>
            }
        </Fragment>
    )
}

const ChatScreen = (props) => {

    const [showSidebarModal, setSidebarVisibility] = useState(false)
    const [selectedSidebarItem, setSidebarItem] = useState('')
    let date = new Date();
    let gmtOffset = (props.gmtOffset) ? props.gmtOffset : 0;
    let utc = date.getTime() + (date.getTimezoneOffset() * 60000);
    let current_time = (new Date(utc + (3600000 * gmtOffset))).toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    });
    let showChat = props.gender || props.city || props.country || props.platform || props.email || props.convoCount || props.buildNumber || props.medication;

    return (isMobileView()
            ? <>
                {/* <hocAux style={{ heigth: '100%', 'background-color': '#F1F2F6', }}> */}
                <div className={_style.main}
                     style={{
                         heigth: '100%', marginTop: '0px'
                         , position: 'relative'
                         // ,bottom:0,position:'absolute',top:0,left:0,right:0,
                     }}>
                    <_Header title="Chat"
                             back={{title: ' ', onClick: () => props.chatSelected(false)}}
                             style={{position: 'absolute', top: 60}}
                             customView={<div style={{
                                 flex: 1,
                                 flexDirection: 'row',
                                 width: '100%',
                                 display: 'flex',
                                 justifyContent: 'space-between',
                                 marginRight: '10px'
                             }}>
                                 <div style={{
                                     flexDirection: 'column',
                                     display: 'flex',
                                     fontSize: '14px',
                                     fontFamily: 'HarmoniaSansStd-Bold',
                                     color: "#884a9d"
                                 }}>
                                     <div style={{
                                         fontFamily: 'HarmoniaSansStd-Bold',
                                         color: "#884a9d"
                                     }}> {props.patientName}</div>
                                     <div style={{
                                         fontFamily: 'HarmoniaSansStd-Regular',
                                         color: "#000"
                                     }}>{moment().diff((props.DOB), 'years', false) + ' years'}, {props.city}, {props.country}</div>
                                 </div>
                                 <div style={{
                                     flexDirection: 'row',
                                     display: 'flex',
                                     fontSize: '14px',
                                     verticalAlign: 'center',
                                     alignItems: 'center'
                                 }}>
                                     {/* <FaVideo color="#884a9d" size={22} style={{ marginRight: '10px' }} onClick={() => {

                                props.videoChat()
                            }}></FaVideo> */}

                                     {/* this is twilio call button for responsive version */}
                                     <CallWithTwilio
                                         doctorID={props.staffData.uid}
                                         patientNumber={props.patient_phone_number}
                                         defaultComponent={
                                             <FaPhone
                                                 color="#884a9d"
                                                 size={22}
                                                 style={{marginRight: "10px"}}
                                             ></FaPhone>
                                         }
                                         onCallingComponent={<div style={{paddingRight: 10}}>Calling...</div>}
                                         onConnectedComponent={
                                             <FaPhoneSlash
                                                 color="Red"
                                                 size={24}
                                                 style={{marginRight: "10px"}}
                                             ></FaPhoneSlash>
                                         }
                                         onRingingComponent={<div style={{paddingRight: 10}}>Ringing...</div>}
                                     ></CallWithTwilio>

                                     <FaBars color="#884a9d" size={22} style={{marginRight: '10px',}}
                                             onClick={() => {
                                                 props.toggleSidebar(true)
                                                 // setSidebarVisibility(!showSidebarModal);
                                                 // alert('he')
                                             }}></FaBars>
                                 </div>
                             </div>}
                    />
                    {
                        // <div className={_classes.videoContainer} onClick={() => props.maximizeVideo()}>
                        //     <div className={_classes.videoText}>Click to return to call</div>
                        // </div>
                    }
                    <div className={_classes.msg_body} style={{height: 'calc(100%-180px)', marginTop: '60px'}}>

                        <div className={_classes.list} style={{height: 'calc(100%-180px)',}}>
                            <div className={_classes.chatlist}>
                                <div className={_classes.chatview}>
                                    <div className={_classes.chatwindow}>
                                        {(props.videoState === true || props.audioState) && props.minimizeVideo === true &&
                                        <div className={_classes.videoContainer} onClick={() => props.maximizeVideo()}>
                                            <div className={_classes.videoText}>Click to return to call</div>
                                        </div>}
                                        <ChatList
                                            loading_messages={props.loading_messages}
                                            messages={props.messages}
                                            doctorName={props.doctorName}
                                            doctorImage={props.doctorImage}
                                            patientName={props.patientName}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <SendBox
                            doctorIsValid={props.doctorIsValid}
                            adminIsValid={props.adminIsValid}
                            inputBarText={props.inputBarText}
                            onChangeText={(event) => props.onChangeText(event)}
                            onsendmessage={(textareaRef) => props.onsendmessage(textareaRef)}
                            inputElement={props.inputElement}
                            textareaElement={props.textareaElement}
                            show={props.open}
                            tags={props.tags}
                            showCannedResponseList={props.showCannedResponseList}
                            selectedCannedResponseText={props.selectedCannedResponseText}
                            selectedCannedResponseShortCut={props.selectedCannedResponseShortCut}
                            canned_responses={props.canned_responses}
                            onCannedResponseClick={(uid, short_cut, text) => props.onCannedResponseClick(uid, short_cut, text)}
                            selectedCannedResponse={(textareaRef, text, short_cut) => props.selectedCannedResponse(textareaRef, text, short_cut)}
                            onCannedResponseShow={() => props.onCannedResponseShow()}
                            selectedTags={props.selectedTags}
                            onRemoveTagClick={(name, id, uid) => props.onRemoveTagClick(name, id, uid)}
                            onTagClick={(name, id, uid) => props.onTagClick(name, id, uid)}
                            openTagListModal={() => props.openTagListModal()}
                            onAttachmentClick={(file_input) => props.onAttachmentClick(file_input)}
                            _handleImageChange={(e) => props._handleImageChange(e)}
                        />
                    </div>
                </div>

                {/* </hocAux> */}
                {/* <_Sidebar></_Sidebar> */}
            </> :
            <div className={classes.chats}>
                <div className={classes.block}>
                    <Header
                        patientId={props.patientId}
                        staffData={props.staffData}
                        transfer_doctors={props.transfer_doctors}
                        showOnlineDoctors={props.showOnlineDoctors}
                        doctorIsValid={props.doctorIsValid}
                        adminIsValid={props.adminIsValid}
                        videoChat={props.videoChat()}
                        audioChat={props.audioChat()}
                        videoState={props.videoState}
                        audioState={props.audioState}
                        transferChat={() => props.transferChat()}
                        maximizeVideo={props.maximizeVideo()}
                        closeChat={() => props.closeChat()}
                        tags={props.tags}
                        onTagClick={(name, id, uid) => props.onTagClick(name, id, uid)}
                        city={props.city ? props.city : "NA"}
                        country={props.country ? props.country : "NA"}
                        complete_address={props.complete_address ? props.complete_address : "NA"}
                        platform={props.platform ? props.platform.toUpperCase() : "NA"}
                        email={props.email ? props.email : "NA"}
                        current_time={current_time ? current_time : "NA"}
                        convoCount={props.convoCount ? props.convoCount : "0"}
                        buildNumber={props.buildNumber ? props.buildNumber : "NA"}
                        medication={props.medication ? props.medication : "None"}
                        symptoms={props.symptoms ? props.symptoms : "NA"}
                        gender={props.gender ? props.gender : "NA"}
                        name={props.patientName}
                        showChat={showChat}
                        acceptingChat={props.acceptingChat}
                        DOB={props.DOB ? props.DOB.replace(' ', ',').replace(' ', ',') : "NA"}
                        patient_phone_number={props.patient_phone_number}
                    />
                    <div className={classes.msg_body}>
                        <div className={classes.list}>
                            <div className={classes.chatlist}>
                                <div className={classes.chatview}>
                                    <div className={classes.chatwindow}>
                                        <ChatList
                                            loading_messages={props.loading_messages}
                                            messages={props.messages}
                                            doctorName={props.doctorName}
                                            doctorImage={props.doctorImage}
                                            patientName={props.patientName}
                                        />
                                        <SendBox
                                            doctorIsValid={props.doctorIsValid}
                                            adminIsValid={props.adminIsValid}
                                            inputBarText={props.inputBarText}
                                            onChangeText={(event) => props.onChangeText(event)}
                                            onsendmessage={(textareaRef) => props.onsendmessage(textareaRef)}
                                            inputElement={props.inputElement}
                                            textareaElement={props.textareaElement}
                                            show={props.open}
                                            tags={props.tags}
                                            showCannedResponseList={props.showCannedResponseList}
                                            selectedCannedResponseText={props.selectedCannedResponseText}
                                            selectedCannedResponseShortCut={props.selectedCannedResponseShortCut}
                                            canned_responses={props.canned_responses}
                                            onCannedResponseClick={(uid, short_cut, text) => props.onCannedResponseClick(uid, short_cut, text)}
                                            selectedCannedResponse={(textareaRef, text, short_cut) => props.selectedCannedResponse(textareaRef, text, short_cut)}
                                            onCannedResponseShow={() => props.onCannedResponseShow()}
                                            selectedTags={props.selectedTags}
                                            onRemoveTagClick={(name, id, uid) => props.onRemoveTagClick(name, id, uid)}
                                            onTagClick={(name, id, uid) => props.onTagClick(name, id, uid)}
                                            openTagListModal={() => props.openTagListModal()}
                                            onAttachmentClick={(file_input) => props.onAttachmentClick(file_input)}
                                            _handleImageChange={(e) => props._handleImageChange(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default ChatScreen;
