import {BILLING_CODE_CHANGE, BILLING_CODE_DESC_CHANGE, BILLING_CODE_SAVE, FETCH_BILLING_CODES} from '../constants';

import _ from 'lodash';
import {auth, database} from "../firebase";

export const billing_code_changed = (text) => {
    return {
        type: BILLING_CODE_CHANGE,
        payload: text
    }
}

export const billing_code_desc_changed = (text) => {
    return {
        type: BILLING_CODE_DESC_CHANGE,
        payload: text
    }
}

export const save_billing_code = (code, desc) => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        getDoctorRef(currentUserID).once('value', doctorSnap => {
            if (doctorSnap.val()) {
                var doctorClinicName = doctorSnap.val().clinicName.toLowerCase();

                let billingCodeValue = {
                    billing_code: code,
                    billing_code_desc: desc,
                }
                let billingCodekey = getBillingCodesRef(doctorClinicName).push().key;
                var updates = {};
                updates[`/billing_codes/${doctorClinicName}/${billingCodekey}`] = billingCodeValue;
                database.ref().update(updates)
                    .then(() => {
                        dispatch({
                            type: BILLING_CODE_SAVE
                        })
                    })
                    .catch((error) => {
                        //console.log("error: ", error.message);
                    })
            }
        });
    }
}

export const fetchBillingCodes = () => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        getDoctorRef(currentUserID).once('value', doctorSnap => {
            if (doctorSnap.val()) {
                var doctorClinicName = doctorSnap.val().clinicName.toLowerCase();
                getBillingCodesRef(doctorClinicName).on('value', codesSnap => {
                    if (codesSnap.val()) {
                        const codes = _.map(codesSnap.val(), (val, uid) => {
                            return {...val, uid};
                        });

                        const filteredCodes = codes.filter(function (el) {
                            return el != null && el !== undefined;
                        });

                        dispatch({
                            type: FETCH_BILLING_CODES,
                            payload: filteredCodes
                        })
                    } else {
                        dispatch({
                            type: FETCH_BILLING_CODES,
                            payload: []
                        })
                    }
                })
            }
        });
    }
}

export const updateBillingCode = (uid, desc, code, state, callback) => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        getDoctorRef(currentUserID).once('value', doctorSnap => {
            if (doctorSnap.val()) {
                var doctorClinicName = doctorSnap.val().clinicName.toLowerCase();

                let billingCodeValue = {
                    billing_code: code,
                    billing_code_desc: desc,
                }

                var updates = {};
                updates[`/billing_codes/${doctorClinicName}/${uid}`] = billingCodeValue;
                database.ref().update(updates)
                    .then(() => {
                        callback(true);
                    })
                    .catch((error) => {
                        callback(false);
                        console.log("error: ", error.message);
                    })
            }
        });
    }
}

export const deleteBillingCode = (uid, state) => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        getDoctorRef(currentUserID).once('value', doctorSnap => {
            if (doctorSnap.val()) {
                var doctorClinicName = doctorSnap.val().clinicName.toLowerCase();

                let billingCodeValue = {
                    billing_code: null,
                    billing_code_desc: null,
                }

                var updates = {};

                updates[`/billing_codes/${doctorClinicName}/${uid}`] = billingCodeValue;
                database.ref().update(updates)
                    .then(() => {
                        dispatch({
                            type: BILLING_CODE_SAVE
                        })
                    })
                    .catch((error) => {
                        console.log('error: ', error);
                    })
            }
        });
    }
}

const getBillingCodesRef = (doctorClinicName) => {
    return database.ref(`/billing_codes/${doctorClinicName}`);
}

const getDoctorRef = (uid) => {
    return database.ref(`/doctors/${uid}`);
}
