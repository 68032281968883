import {combineReducers} from 'redux';
import loginAuth from './LoginAuth';
import forgotPasswordReducer from './ForgotPasswordReducer';


import homeReducer from './HomeReducer';
import agentsReducer from './AgentsReducer';
import conversationReducer from './ConversationReducer';
import chatReducer from './ChatReducer';
import customersReducer from './CustomersReducer';
import tagsReducer from './TagsReducer';
import cannedResponseReducer from './CannedResponseReducer';
import ticketsReducer from './TicketsReducer';
import doctorReducer from './DoctorReducer';
import profileReducer from './ProfileReducer';
import archivesReducer from './ArchivesReducer';
import acceptingReducer from './AcceptingReducer';
import clinicalPatientsReducer from './ClinicalPatientsReducer';
import outPatientFormReducer from './OutPatientFormReducer';
import reportReducer from './ReportsReducer';
import activitiesReducer from './ActivitiesReducer';
import clinicalChatsReducer from './ClinicalChatsReducer';
import clinicalcannedResponseReducer from './clinicSetting/ClinicCannedResponseReducer';
import clinicCannedResponseReducer from './clinicSetting/ClinicCannedResponseReducer';
import billingcodesReducer from './BillingCodeReducer';


import createClinicReducer from './CreateClinicReducer';
import clinicProfileSettingReducer from './ClinicalProfileSettingReducer';
import staffClinicReducer from './ClinicalStaffReducer';

import clinicBillingCodesReducer from './clinicSetting/ClinicBillingCodeReducer';
import clinicTagsReducer from './clinicSetting/ClinicTagsReducer';

import clinicAcceptingChatReducer from './clinicSetting/ClinicAcceptChatReducer';

export default combineReducers({
    loginAuth, homeReducer, agentsReducer, conversationReducer,
    chatReducer, customersReducer, tagsReducer, cannedResponseReducer,
    ticketsReducer, doctorReducer, profileReducer, archivesReducer, acceptingReducer,
    outPatientFormReducer, reportReducer, activitiesReducer, clinicalChatsReducer,
    clinicalcannedResponseReducer, billingcodesReducer, forgotPasswordReducer, clinicalPatientsReducer,
    createClinicReducer, clinicProfileSettingReducer, staffClinicReducer,
    clinicBillingCodesReducer, clinicTagsReducer, clinicCannedResponseReducer,
    clinicAcceptingChatReducer
});
