import {
    CLEAR_USERS_LIST,
    FETCH_BANNED_USERS,
    FETCH_CUSTOMERS,
    FETCH_CUSTOMERS_UPDATE,
    FETCH_NEXT_USER_ID,
    FETCH_USER_DETAILS,
    REFRESH_CUSTOMERS
} from '../constants';

const INITIAL_STATE = {customersData: [], usersData: [], bannedUsersData: [], nextUserID: '', showLoadMore: false};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REFRESH_CUSTOMERS:
            return INITIAL_STATE;
        case FETCH_CUSTOMERS:
            return {...state, customersData: action.payload};
        case FETCH_USER_DETAILS:
            return {...state, usersData: [...state.usersData, action.payload]};
        case CLEAR_USERS_LIST:
            return {...state, usersData: [], showLoadMore: false};
        case FETCH_BANNED_USERS:
            return {...state, bannedUsersData: action.payload.bannedUsersData};
        case FETCH_NEXT_USER_ID:
            return {...state, nextUserID: action.payload.nextUserID, showLoadMore: action.payload.showLoadMore};
        case FETCH_CUSTOMERS_UPDATE:
            for (var i = state.customersData.length - 1; i >= 0; i--) {
                if (state.customersData[i].patientID === action.payload.patientID &&
                    state.customersData[i].timestamp < action.payload.timestamp) {
                    state.customersData[i] = action.payload;
                    return {...state, customersData: [...state.customersData]};
                }
            }
            return {...state};
        default:
            return state;
    }
}
