import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/performance'
import 'firebase/compat/analytics'
import 'firebase/compat/database'
import 'firebase/compat/messaging'
import 'firebase/compat/functions'
import 'firebase/compat/remote-config'
import 'firebase/compat/app-check'

import config from './firebaseconfig'
import {ENVIRONMENT} from "./environment";
import {createLogger} from "redux-logger";
if (process.env.NODE_ENV === 'development') {
    window.FIREBASE_APPCHECK_DEBUG_TOKEN=true;

}
firebase.initializeApp(config.firebase)
firebase.analytics()
const appCheck = firebase.appCheck();

appCheck.activate(
    new firebase.appCheck.ReCaptchaEnterpriseProvider(ENVIRONMENT === 'development'?"6Le-I5chAAAAAD3pXxCWFvsBwWannq0LETdXEgbw":"6LeWcf4hAAAAAFMHlo9hCDu5hUhMGB4D0taH3Wig"),
    true // Set to true to allow auto-refresh.
);

export const auth = firebase.auth()
export const authRef = firebase.auth

export const database = firebase.database()
export const messaging = firebase.messaging()
export const messagingRef = firebase.messaging
export const functions = firebase.functions()

export const storage = firebase.storage()
export const storageRef = firebase.storage
export const performance = firebase.performance()
export const firestore = firebase.firestore()
export const remoteConfig = firebase.remoteConfig()
remoteConfig.settings.fetchTimeMillis = 60000
remoteConfig.settings.minimumFetchIntervalMillis = 300000
remoteConfig.defaultConfig = ({
    'all_issues': "COVID-19,Sexual Health,Pregnancy,Cold or Flu,Gastrointestinal,Skin,Children's Health,Other\n"
});
remoteConfig.ensureInitialized().then(data=>remoteConfig.fetchAndActivate().then(data=>{})).catch(error=>remoteConfig.activate())
let publicKey = ENVIRONMENT === 'development'? 'BP5b5B58pPNO25TGfpN-F-AtU31bJQ_oOUxim3wgBzVQD2A4Nu9dmKPv83Fr8j2aQbeol0t3iwt0OnUyb0u-wj8' : 'BH5IT6hzylPPZUmMnPUv4Aacy-uVeJ2twCM6ebKYRsq4BzRjpeM53tVgsCwT_STISE8FkWA707076FkDW5QNvUs';
var token = "";
if (messagingRef.isSupported()) {
    //messaging.usePublicVapidKey(publicKey);
    messaging.getToken({vapidKey: publicKey})
        .then(newToken => {
            token = newToken;
            console.log("token ", token)
        })
        .catch((error) => {
            token = null;
        })
}
export const getToken = () =>{
    console.log("getToken token", token)
    return token;
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        messaging.onMessage( (payload) => {
            console.log("onMessage payload ", payload)
            resolve(payload);
        });
    });
export default firebase
