import  {Component} from 'react';
import Input from '../../components/UI/Input/Input';
import Spinner from '../../components/UI/Spinner/Spinner';
import Button from '../../components/UI/Button/Button';
import classes from './AddAgents.module.css';

import {connect} from 'react-redux';
import {addAgent} from '../../actions/AgentsAction';

const Header = (props) => {
    return (
        <div className={classes.cross} onClick={props.onHideAddAgentModal}>
            <div className={classes.add}>Add Agents
                <div className={classes.close}>
                    <svg fill="#000000" width="14px" height="14px" viewBox="0 0 14 14">
                        <g fill="inherit" fillRule="evenodd">
                            <path
                                d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7z"></path>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    )
}

class AddAgents extends Component {

    state = {
        controls: {
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'User email address'
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            },
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Password'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false
            },
            name: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Name'
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                touched: false
            },
            role: {
                elementType: 'select',
                elementConfig: {
                    options: [
                        {value: '', displayValue: 'Select'},
                        {value: 'Admin', displayValue: 'Admin'},
                        {value: 'Doctor', displayValue: 'Doctor'}
                    ]
                },
                value: '',
                validation: {},
                valid: true
            }
        },
        clinicalDoctor: false,
        clinicName: '',
        locationName: '',
        address: '',
        phoneNumber: ''
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    inputChangedHandler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: this.checkValidity(event.target.value, this.state.controls[controlName].validation),
                touched: true
            }
        };
        this.setState({controls: updatedControls});
    }

    submitHandler = (event) => {
        event.preventDefault();
        var self = this;
        // alert(this.state.controls.role.value)
        // return;
        if (this.state.controls.email.value && this.state.controls.password.value &&
            this.state.controls.name.value && this.state.controls.role.value) {
            if (this.state.clinicalDoctor) {
                if (this.state.clinicName !== '' || this.state.locationName !== '' ||
                    this.state.address !== '' || this.state.phoneNumber !== '') {
                    this.props.addAgent(
                        this.state.controls.email.value,
                        this.state.controls.password.value,
                        this.state.controls.name.value,
                        this.state.controls.role.value,
                        this.state.clinicalDoctor,
                        this.state.clinicName,
                        this.state.locationName,
                        this.state.address,
                        this.state.phoneNumber,
                        function (status) {
                            if (status) {
                                self.state.controls.email.value = '';
                                self.state.controls.password.value = '';
                                self.state.controls.name.value = '';
                                self.state.controls.role.value = 'Admin';
                                self.setState({
                                    clinicalDoctor: false,
                                    clinicName: '',
                                    locationName: '',
                                    address: '',
                                    phoneNumber: '',
                                });
                            }
                        }
                    );
                } else {
                    alert("Please fill out the complete form");
                }
            } else {
                this.props.addAgent(
                    this.state.controls.email.value,
                    this.state.controls.password.value,
                    this.state.controls.name.value,
                    this.state.controls.role.value,
                    this.state.clinicalDoctor,
                    this.state.clinicName,
                    this.state.locationName,
                    this.state.address,
                    this.state.phoneNumber,
                    function (status) {
                        if (status) {
                            self.state.controls.email.value = '';
                            self.state.controls.password.value = '';
                            self.state.controls.name.value = '';
                            self.state.controls.role.value = 'Admin';
                            self.setState({
                                clinicalDoctor: false,
                                clinicName: '',
                                locationName: '',
                                address: '',
                                phoneNumber: '',
                            });
                        }
                    }
                );
            }
        } else {
            alert("Please fill out the complete form");
        }

    }

    handleChange = () => {
        this.setState((state) => {
            return {clinicalDoctor: !state.clinicalDoctor}
        });
    }

    clinicNameChange = (event) => {
        this.setState({clinicName: event.target.value});
    }

    clinicLocationChange = (event) => {
        this.setState({locationName: event.target.value});
    }

    clinicAddressChange = (event) => {
        this.setState({address: event.target.value});
    }

    clinicPhoneChange = (event) => {
        this.setState({phoneNumber: event.target.value});
    }

    render() {
        const formElementsArray = [];
        for (let key in this.state.controls) {
            formElementsArray.push({
                id: key,
                config: this.state.controls[key]
            });
        }

        let errorMessage = null;
        let successMessage = null;

        if (this.props.error) {
            if (this.props.success === 'false') {
                errorMessage = (
                    <p className={classes.error}>{this.props.error}</p>
                );
            } else {
                successMessage = (
                    <p className={classes.success_message}>{this.props.error}</p>
                );
            }
        }

        let form = (
            <div>
                <div className={this.props.success === 'true' ? classes.hidden : ""}>
                    <form onSubmit={this.submitHandler}>
                        {formElementsArray.map(formElement => (
                            <Input
                                key={formElement.id}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                invalid={!formElement.config.valid}
                                shouldValidate={formElement.config.validation}
                                touched={formElement.config.touched}
                                changed={(event) => this.inputChangedHandler(event, formElement.id)}/>
                        ))}
                        {this.state.controls.role.value === 'Doctor' &&
                        <div>
                            {/*<label className={classes.label_style}>
                                <span className={classes.span_style}>Clinical Doctor: </span>
                                <span>
                                    <Switch
                                        onChange={() => this.handleChange()}
                                        checked={this.state.clinicalDoctor}
                                        onColor="#85479f"
                                        onHandleColor="#d36797"
                                        handleDiameter={30}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                    />
                                </span>
                            </label> */}
                            {this.state.clinicalDoctor &&
                            <div>
                                <Input
                                    elementConfig={{placeholder: "Clinic Name"}}
                                    value={this.state.clinicName}
                                    changed={(event) => this.clinicNameChange(event)}
                                    required={true}
                                />
                                <Input
                                    elementConfig={{placeholder: "Clinic Location Name"}}
                                    value={this.state.locationName}
                                    changed={(event) => this.clinicLocationChange(event)}
                                    required={true}
                                />
                                <Input
                                    elementConfig={{placeholder: "Clinic Address"}}
                                    value={this.state.address}
                                    changed={(event) => this.clinicAddressChange(event)}
                                    required={true}
                                />
                                <Input
                                    elementConfig={{placeholder: "Clinic Phone Number"}}
                                    value={this.state.phoneNumber}
                                    changed={(event) => this.clinicPhoneChange(event)}
                                    required={true}
                                />
                            </div>
                            }
                        </div>
                        }
                        <Button btnType="Success">Add Agent</Button>
                        {errorMessage}
                    </form>
                </div>
                <div className={this.props.success === 'true' ? classes.success_message_div : classes.hidden}>
                    {successMessage}
                </div>
            </div>
        );

        if (this.props.loading) {
            form = <Spinner/>;
        }

        return (
            <div className={classes.Auth}>
                <Header onHideAddAgentModal={this.props.modalClosed}/>
                {form}
            </div>
        );
    }
}

const mapStateToProps = ({agentsReducer}) => {
    const {loading, error, success} = agentsReducer;
    return {loading, error, success};
};

export default connect(mapStateToProps, {addAgent})(AddAgents);
