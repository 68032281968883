import React, {useEffect, useState, Fragment, useRef} from 'react'
import classes from './messagebubble.module.css';
import {connect} from "react-redux";

const RightMessage = (props) => {
    return (
        <div className={classes.msg_body}>
            <div className={classes.msg_right_name}>
                <div className={classes.msg_name_text}>{props.doctorName}</div>
            </div>
            <div className={classes.msg_right_body}>
                {props.doctorImg !== undefined &&
                <img className={classes.msg_right_img} alt="" src={props.doctorImg}/>
                }
                {props.doctorImg === undefined &&
                <div className={classes.msg_right_img}>{(props.doctorName).charAt(0)}</div>
                }
                <div className={classes.msg_right_container}>
                    <div className={classes.msg_right_content}>
                        <div className={classes.msg_right_content1}>
                            <div className={classes.msg_right_content2}>
                                <div>
                                    <div className={classes.msg_right_bubble_view}>
                                        <div className={classes.msg_right_bubble_container}>
                                            <div className={classes.msg_right_bubble_container_content}>
                                                <div className={classes.msg_bubble_content_row}>
                                                    <div className={classes.msg_bubble_right}>
                                                        <div
                                                            className={classes.msg_bubble_container_content}>{props.content}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )


}
const mapStateToProps = ({ conversationReducer }) => {
    const { patientId, patients } = conversationReducer;
    return { patientId, patients };
};

export default connect(mapStateToProps, {})(RightMessage);
