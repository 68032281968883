import  {Component} from 'react';
import Modal from '../components/UI/Modal/Modal';
import classes from './hoc.module.css';

export default function (ComposedComponent) {
    class NetworkDetector extends Component {
        state = {
            isDisconnected: false
        }

        componentDidMount() {
            this.handleConnectionChange();
            window.addEventListener('online', this.handleConnectionChange);
            window.addEventListener('offline', this.handleConnectionChange);
        }

        componentWillUnmount() {
            window.removeEventListener('online', this.handleConnectionChange);
            window.removeEventListener('offline', this.handleConnectionChange);
        }


        handleConnectionChange = () => {
            const condition = navigator.onLine ? 'online' : 'offline';
            //console.log("condition: ", condition);
            if (condition === 'online') {
                const webPing = setInterval(() => {
                    fetch('//google.com', {
                        mode: 'no-cors',
                    })
                        .then(() => {
                            this.setState({isDisconnected: false}, () => {
                                return clearInterval(webPing)
                            });
                        }).catch(() => this.setState({isDisconnected: true}))
                }, 2000);
                return;
            }

            return this.setState({isDisconnected: true});
        }

        render() {
            const {isDisconnected} = this.state;
            return (
                <div>
                    {isDisconnected && (
                        <Modal show={isDisconnected}>
                            <div className={classes.container}>
                                <h2 className={classes.heading}>Internet connection lost</h2>
                                <p className={classes.sub_heading}>Please refresh the page</p>
                            </div>
                        </Modal>
                    )
                    }
                    <ComposedComponent {...this.props} />
                </div>
            );
        }
    }

    return NetworkDetector;
}
