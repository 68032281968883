import {Component, createRef, Fragment, useEffect, useState} from 'react';
import Aux from '../../../hoc/hocAux';
import {connect} from 'react-redux';
import classes from './encounters.module.css';
import _classes from './_encounters.module.css';

import _style from '../../../assets/_classes.module.css';
import Calendar from '../../../components/customCalendar/calendar';
import _Header from '../../../components/UI/Header/Header'
import AcceptChatFooter from '../../../components/AcceptChatFooter/AcceptChat'
// import useCamera from '../../../utils/hooks/useCamera'
import _Sidebar from './patientDetail/_Sidebar'
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import {logoutClinicMember} from '../../../actions/ClinicStaffAction';

import {
    changeQueueStatusForClinicalChats,
    closeClinicalChatByAdmin,
    fetchAllMessagesForClinicChats,
    fetchChatPrescriptions,
    fetchClinicalChats,
    fetchClinicalConversationCount,
    fetchClinicalUnReadCount,
    fetchStatus,
    refreshClinicalConversations,
    sendDoctorFirstMessage,
    sendMessage,
    sendNewMessageNotification,
    setAlgolia,
    setMediaHeader,
    setVideo,
    transferChatToAnotherClinicDoctor,
    transferClinicalChatToYDOByAdmin,
    turnSelectChat
} from '../../../actions/ClinicalChatsAction';
import {
    changeAcceptStatus,
    checkStatusAccept,
    childChanged,
    endVideoCall,
    fetchChatTags,
    findPatientDetail,
    get_followUp,
    getAcceptStatus,
    refreshDeclineStatus,
    refreshRemoteStream,
    removeTagInChat,
    resetSelectedConversationId,
    save_followUp,
    saveRemoteStream,
    saveTagInChat,
    set_notification,
    setFlagFalse,
    setFlagTrue,
    setFollowUpStatus,
    setSelectedItem,
    setSkipStatus,
    setVideoId,
    setVideoState,
    setVideoStatusFalse,
    setVideoStatusTrue
} from '../../../actions/ConversationAction';
import {
    fetchUserChatHistory,
    imageData,
    makeUnreadAllMessages,
    onChangeText,
    refreshMessages
} from '../../../actions/ChatAction';

import {fetchClinicalDoctors} from '../../../actions/ReportsAction';


import {
    deletePatients,
    fetchRegisteredPatients,
    fetchTimeSlots,
    removeClinicalPatientsListener,
    sendInvites,
    sendUnFilledInvites,
    storeSlot,
    updatePatient,
    updateRegistrationStatus
} from '../../../actions/clinicalPatientsActions';

import Spinner from '../../../components/UI/Spinner/VideoSpinner';

import {fetchDoctorDetails} from '../../../actions/DoctorAction';
import {
    clinic_fetchCannedResponsesForAssessment,
    clinic_fetchCannedResponsesForChats,
    clinic_fetchCannedResponsesForObjectiveFindings,
    clinic_fetchCannedResponsesForPlan,
    clinic_setAssessment,
    clinic_setFindings,
    clinic_setPlan,
    clinic_setSubjectiveSymptoms
} from '../../../actions/clinic/setting/ClinicCannedResponseAction';

import {fetchTags} from '../../../actions/TagsAction';

import {childChangedDoctor} from '../../../actions/AgentsAction';

import ClinicalConversationList from './conversations/list';
import _ClinicalConversationList from './conversations/_list';
import ChatScreen from './screens/chat-screen';
import ClinicalPatientDetail from './patientDetail/ClinicalPatientDetail';
import OutPatientForm from './patientDetail/outPatientForm/outPatientForm';

import _, {debounce} from 'lodash';

import Modal from '../../../components/UI/Modal/Modal';
import ProgressBar from '../../../components/UI/Progressbar/Progressbar';

import {FaAngleLeft, FaBars, FaMicrophone, FaMicrophoneSlash, FaPhoneSlash, FaVideo} from "react-icons/fa";
import {MdSwitchCamera} from "react-icons/md";

//import AgoraRTC from "agora-rtc-sdk";

import Dialog from '@material-ui/core/Dialog';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import Switch from "react-switch";
import isMobileView from '../../../utils/isMobileView';
import {database} from "../../../firebase";
// Javascript
// Enable log upload
//AgoraRTC.Logger.enableLogUpload();
// Set the log output level as INFO
//AgoraRTC.Logger.setLogLevel(AgoraRTC.Logger.INFO);

//let client = AgoraRTC.createClient({mode: "live", codec: "vp8"});

// enableWebSdkInteroperability(true)
const USER_ID = Math.floor(Math.random() * 1000000001);
var moment = require("moment");
let disableBackButton = false
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
// if (isMobileView() && !disableBackButton) {
//     window.history.pushState(null, null, window.location.href);
//   disableBackButton=true

// }else if (isMobileView() && disableBackButton){
//     window.onpopstate = function (event) {
//         window.history.go(1);
//     }
// }

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

function MyVerticallyCenteredModal(props) {
    return (
        <Dialog
            open={props.show}
            onClose={() => props.onHide()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="customized-dialog-title"
                         onClose={() => props.onHide()}> {props.modalHeaderText} </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description"> {props.modalBodyText} </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}

const DoctorsList = (props) => {
    return (
        <li>
            <div className={classes.icon_image}>
                <div className={classes.main_assign_inner_view}>
                    <img
                        src={props.profileImageURL ? props.profileImageURL : "https://livechat.s3.amazonaws.com/default/avatars/female_44.jpg"}/>
                    {props.doctor.online && <span className={classes.online_style}></span>}
                    {!props.doctor.online && <span className={classes.offline_style}></span>}
                    <div className={classes.main_assign_content}>
                        <div className={classes.name_text}>{props.name}</div>
                        <div className={classes.email_text}>{props.email}</div>
                    </div>
                    <div className={classes.main_transfer_button}>
                        <button onClick={() => props.onAssignClick(props.transfer, props.doctor)}>Transfer Patient
                        </button>
                    </div>
                </div>
            </div>
        </li>
    )
}

function TransferChatModal(props) {
    const doctorsList = props.onlineDoctors && props.onlineDoctors.map(doctor => {
        if (doctor !== undefined && doctor.transferChat && doctor.online) {
            return <DoctorsList
                key={doctor.uid}
                doctor={doctor}
                transfer={false}
                profileImageURL={doctor.profileImageURL}
                name={doctor.fullName}
                email={doctor.staffEmail ? doctor.staffEmail : doctor.email}
                onAssignClick={(status, doctor) => props.onAssignClick(status, doctor)}
            />
        } else {

        }
    });

    const allDoctors = props.onlineDoctors && props.onlineDoctors.map(doctor => {
        if (doctor !== undefined && doctor.ansPatients) {
            return <DoctorsList
                key={doctor.uid}
                doctor={doctor}
                transfer={true}
                profileImageURL={doctor.profileImageURL}
                name={doctor.fullName}
                email={doctor.staffEmail ? doctor.staffEmail : doctor.email}
                onAssignClick={(status, doctor) => props.onAssignClick(status, doctor)}
            />
        } else {

        }
    });

    return (
        <Dialog
            open={props.show}
            onClose={() => props.onHide()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="customized-dialog-title"
                         onClose={() => props.onHide()}> {props.modalHeaderText} </DialogTitle>
            <DialogContent>
                <ul>
                    {doctorsList}
                </ul>
                <div className={classes.diglogText}> Transfer to Queue</div>
                <ul>
                    {allDoctors}
                </ul>
            </DialogContent>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    icon: {
        borderRadius: '50%',
        width: 20,
        height: 20,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        }
    },
    checkedIcon: {
        backgroundColor: '#894A9E',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        }
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 100,
    }
}));

function WorkingHours(props) {
    const styles = useStyles();

    const [selectedValue, setSelectedValue] = useState();
    const [resetDate, setresetDate] = useState(false);
    const [resetDateValue, setresetDateValue] = useState(moment());
    const [startDate, setStartDate] = useState(moment());
    const [selectedTime, setselectedTime] = useState('');
    const [timeSlots, setTimeSlots] = useState([]);
    const [showCalender, setShowCalender] = useState(false);

    const handleChange = (event) => {
        var hours = event.target.value;
        if (hours == 1 || hours == 3 || hours == 12) {
            setSelectedValue(event.target.value);
            setShowCalender(false);
            setStartDate(moment(new Date().getTime() + (hours * 60 * 60 * 1000)));
            setselectedTime(new Date().getTime() + (hours * 60 * 60 * 1000));
        }
    };

    useEffect(() => {
        var startDateTime = new Date(startDate);
        startDateTime.setHours(0, 0, 0, 0);
        startDateTime = startDateTime.getTime();

        var endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 1);
        endDate.setHours(0, 0, 0, 0);
        endDate = endDate.getTime();

        var slots1 = [];
        var endDuration = startDateTime + 10 * 60000;
        while (endDate >= endDuration) {
            let slot = {
                startTime: startDateTime,
            }
            startDateTime = endDuration;
            endDuration = startDateTime + 10 * 60000;
            slots1.push(slot);
        }

        setTimeSlots(slots1);

    }, [startDate]);

    const slots = timeSlots.length > 0 ? timeSlots.map(slot => {
        return (
            <div
                key={'slot_' + slot.startTime}
                className={selectedTime === slot.startTime ? classes.selectedTimeSlotsSectionButton : classes.timeSlotsSectionButton}
                onClick={() => {
                    setselectedTime(slot.startTime);
                    setStartDate(moment(slot.startTime));
                }}
            >{moment(slot.startTime).format("h:mm a")}</div>
        )
    }) : null;

    return (
        <Dialog
            open={props.show}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            // maxWidth='xs'
        >
            <DialogTitle className={classes.modal_header_text}> Accepting Patients </DialogTitle>
            <DialogContent className={classes.modal_content}>
                <div className={classes.middle_container}>
                    <span className={classes.modal_header_heading}>Accepting Patients for</span>
                    <div>
                        <Radio
                            checked={selectedValue === '1'}
                            onChange={handleChange}
                            value="1"
                            checkedIcon={<span className={clsx(styles.icon, styles.checkedIcon)}/>}
                            icon={<span className={styles.icon}/>}
                            {...props}
                        />
                        <span className={classes.span_text}>1 hour</span>
                        <Radio
                            checked={selectedValue === '3'}
                            onChange={handleChange}
                            value="3"
                            checkedIcon={<span className={clsx(styles.icon, styles.checkedIcon)}/>}
                            icon={<span className={styles.icon}/>}
                            {...props}

                        />
                        <span className={classes.span_text}>3 hour</span>
                        <Radio
                            checked={selectedValue === '12'}
                            onChange={handleChange}
                            value="12"
                            checkedIcon={<span className={clsx(styles.icon, styles.checkedIcon)}/>}
                            icon={<span className={styles.icon}/>}
                            {...props}
                        />
                        <span className={classes.span_text}>12 hour</span>
                    </div>
                    <div className={classes.time_container}>
                        <span className={classes.span_heading_text}>or until</span>
                        <span
                            className={classes.time_field}
                            onClick={() => {
                                setShowCalender(true)
                                setSelectedValue();
                                setStartDate(moment(new Date().getTime()));
                            }}
                        >{startDate.format('MMMM DD YYYY, h:mm a')}</span>
                    </div>
                    {showCalender &&
                    <div className={classes.time_container}>
                        <div className={classes.calenderSection}>
                            <Calendar
                                resetDate={resetDate}
                                resetDateValue={resetDateValue}
                                resetUpdate={() => {
                                    setresetDate(true)
                                }}
                                shouldHighlightWeekends
                                dateChanged={(date) => {
                                    setStartDate(date)
                                }}
                            />
                        </div>
                        {true &&
                        <div className={classes.timeSlotsSection}>
                            <span className={classes.timeSlotDate}>{startDate.format('dddd, MMMM DD')}</span>
                            <div className={classes.timeslotsScroll}>
                                {slots}
                            </div>
                        </div>
                        }
                    </div>
                    }
                </div>
                <div className={classes.bottom_view}>
                    <button className={classes.button} onClick={() => props.onApply(selectedTime)}>Apply</button>
                    <button className={classes.white_button} onClick={() => props.onHide()}>Cancel</button>
                </div>
            </DialogContent>
        </Dialog>
    );
}

const Header = (props) => {
    return (
        <div className={classes.cross}>
            <div className={classes.add}>Upload Attachment</div>
        </div>
    )
}

const MediaHeader = (props) => {
    return (
        <div className={classes.cross}>
            <div className={classes.add}>Audio/Video</div>
        </div>
    )
}

class ClinicalDoctors extends Component {

    /*localStream = AgoraRTC.createStream({
        streamID: USER_ID,
        audio: true,
        video: true,
        screen: false,
    });*/
    /*localAudioStream = AgoraRTC.createStream({
        streamID: USER_ID,
        audio: true,
        video: false,
        screen: false,
    });*/

    constructor(props) {
        super(props);
        this.acceptingChatInput = createRef();
        this.state = {
            fromID: '',
            conversationId: '',
            file: '',
            filetype: '',
            showUploadingModal: false,
            showCloseModal: false,
            showTagsListModal: false,
            selectedTags: [],
            showCannedResponseList: false,
            selectedCannedResponseShortCut: '',
            selectedCannedResponseText: '',
            showSelectedMessages: null,
            showOnlineDoctors: false,
            canned_responses: [],
            chats_canned_responses: [],
            playSound: false,
            messageLength: 0,
            showOutPatientForm: false,
            chatHistoryItemClick: false,
            chatHistoryUid: '',
            chatHistoryCategory: '',
            remoteStreams: {},
            minimizeVideo: true,
            audMute: false,
            vidMute: false,
            declineShown: false,
            videoState: false,
            videoStatus: this.props.videoStatus,
            audioState: false,
            type: '',
            patientInitial: '',
            showMediaModal: false,
            showPulse: false,
            open: false,
            clinicalChatData: null,
            actionPressed: false,
            showUpView: false,
            followClassStatus: false,
            skipClassStatus: false,
            dateTime: '',
            endTime: null,
            showSOAPNotes: 0,
            followUpDays: 0,
            allFollowUp: this.props.all_followUp.length == 0 ? [] : this.props.all_followUp,
            selectedFollowUpPatient: null,
            followUpDoctorName: '',
            showScheduleFollow: false,
            selectedDoctor: this.props.staffData?.ansPatients ? this.props.staffData : {},
            selectedDoctorFinal: this.props.staffData?.ansPatients ? this.props.staffData : {},
            scheduleFollowupState: 0,
            scheuledDate: (this.props.followupDate) ? this.props.followupDate : '',
            resetDate: true,
            resetDateValue: moment(),
            radioValue: '',
            selectedDay: moment(),
            selectedPatient: {
                dob: this.props.DOB,
                email: this.props.email,
                firstName: this.props.patientName,
                gender: this.props.gender,
                gmtOffset: this.props.gmtOffset,
                insuranceCardNo: this.props.healthInsuranceCardNumber,
                lastName: '',
                userId: this.props.patientId
            },
            bookingInProgress: false,
            selectedDuration: 0,
            followUpID: null,
            showModal: false,
            modalHeaderText: '',
            modalBodyText: '',
            showWorkingHoursModal: false,

        }

        if (props.history.location.state !== undefined && props.history.location.state !== null) {
            let {queued, conversationId, patientID, doctorID, issue, category} = props.history.location.state;
            if (queued) {
                this.removedFromQueueAndshowMessages(true, conversationId, patientID, doctorID, issue, category);
            } else {
                this.showMessages(true, conversationId, patientID, doctorID, issue, category);
            }
        }
    }

    onHideModal = (type) => {
        this.setState({showModal: type})
    }

    initLocalStream = () => {
        /*let me = this;
        me.localStream.setVideoProfile('720p_1')
        me.localStream.init(
            function () {
                me.localStream.play("agora_local");
            },
            function (err) {
                //console.log("AGORA getUserMedia failed", err);
            }
        );
        // me.localStream.addTrack(AgoraRTC.createStream({
        //     streamID: USER_ID+1,
        //     audio: true,
        //     video: true,
        //     screen: false,
        // }).getVideoTrack());
        this.switchCamera()*/
    };

    initLocalAudioStream = () => {
        /*let me = this;
        me.localAudioStream.setAudioProfile('high_quality')
        me.localAudioStream.init(
            function () {
                me.localAudioStream.play("agora_local");
            },
            function (err) {
                //console.log("getUserMedia failed", err);
            }
        );*/
    };

    UNSAFE_componentWillMount() {
        this.switchCamera()
        this.props.fetchClinicalDoctors(this.props.clinicId, () => {
        });
        this.props.setAlgolia(this.props.clinicId);
        this.props.fetchTags();
        this.props.childChanged(this.props.clinicId);//
        this.props.childChangedDoctor(this.props.clinicId);//
        this.props.clinic_fetchCannedResponsesForChats(this.props.clinicId, 'chats');
        this.props.clinic_fetchCannedResponsesForObjectiveFindings(this.props.clinicId);
        this.props.clinic_fetchCannedResponsesForAssessment(this.props.clinicId);
        this.props.clinic_fetchCannedResponsesForPlan(this.props.clinicId);
        this.props.getAcceptStatus(this.props.clinicId);
        this.props.refreshMessages();//
        this.props.refreshClinicalConversations();

        this.props.fetchClinicalChats(this.props.clinicId, false);
        this.props.fetchClinicalConversationCount(this.props.clinicId);
        this.props.fetchClinicalUnReadCount(this.props.clinicId);
        this.props.get_followUp("CLINICAL", this.props.full_name, this.props.clinicId);

    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.acceptingChatTime && !this.props.acceptingChatTime) {
            let diff = newProps.acceptingChatTime - (new Date().getTime());
            if (diff > 0) {
                setTimeout(() => {
                    if (this.props.acceptingChat) {
                        let self = this
                        this.props.changeAcceptStatus(this.props.clinicId, false, null, () => {
                            this.setState({showWorkingHoursModal: false});
                        });
                    }

                }, diff);
            }

        }
        if ((newProps.followupDate !== this.props.followupDate) && Boolean(newProps.followupDate)) {
            const follow_time = new Date(newProps.followupDate);
            let gmtOffset = (this.props.gmtOffset) ? this.props.gmtOffset : 0;
            let utc = follow_time.getTime() + (follow_time.getTimezoneOffset() * 60000);
            let followUpDate = (new Date(utc + (3600000 * gmtOffset)));
            const current_time = new Date();
            let current_utc = current_time.getTime() + (current_time.getTimezoneOffset() * 60000);
            let finalDate = (new Date(current_utc + (3600000 * 0)));
            const diffTime = Math.abs(followUpDate - finalDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;
            //this.props.save_followUp(this.state.selectedFollowUpPatient.userId, "Dashboard Follow Up",this.props.doctorDetail.doctorName, this.state.selectedFollowUpPatient.firstName + " " + this.state.selectedFollowUpPatient.lastName, this.state.scheuledDate, this.state.selectedFollowUpPatient.email, (this.state.selectedFollowUpPatient.gmtOffset)?this.state.selectedFollowUpPatient.gmtOffset:0)
            this.setState({followClassStatus: true, followUpDays: diffDays + 1});
        }

        if (this.props.isLoading === true && newProps.isLoading === false) {
            this.setState({showUpView: false})
        }
        if (this.props.all_followUp != newProps.all_followUp) {
            this.setState({allFollowUp: newProps.all_followUp})
        }

        if (newProps.changedDoctorKey === newProps.staffData.uid) {
            this.props.checkStatusAccept();
        }
        if (this.props.messages.length > 0 && this.state.messageLength !== this.props.messages.length) {
            this.setState({messageLength: this.props.messages.length});
        }
        if (newProps.clinic_canned_responses !== this.props.clinic_canned_responses) {
            this.setState({canned_responses: newProps.clinic_canned_responses});
        }
        if (newProps.clinic_chats_canned_responses !== this.props.clinic_chats_canned_responses) {
            this.setState({chats_canned_responses: newProps.clinic_chats_canned_responses});
        }
        if (newProps.videoChatDeclineStatus === true /* && !Boolean(this.props.videoChatDeclineStatus)  */ && this.state.declineShown === false && this.props.declinedDoctorId === this.props.staffData.uid) {
            this.setState({declineShown: true});
            window.alert(`${this.state.type} chat request was declined by the patient`);
            if (this.state.type === 'Video') {
                this.stopDeclinedVideoCall();


            } else {
                this.stopDeclinedAudioCall()
            }
            this.props.refreshDeclineStatus();
        }
        if (newProps.patientId !== this.props.patientId) {
            this.setState({
                selectedPatient: {
                    dob: newProps.DOB,
                    email: newProps.email,
                    firstName: newProps.patientName,
                    gender: newProps.gender,
                    gmtOffset: newProps.gmtOffset,
                    insuranceCardNo: newProps.healthInsuranceCardNumber,
                    lastName: '',
                    userId: newProps.patientId
                }
            })
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.deletedConversationKey === this.props.conversationId || this.props.changedConversation === this.props.conversationId) {
            this.props.refreshMessages();
        }
    }

    skipStatus = () => {
        this.props.setSkipStatus(this.props.conversationId, this.props.clinicId);
        this.setState({skipClassStatus: true, showUpView: false, followUpDays: 0});
    }

    componentDidMount() {

        const {acceptingChatTime} = this.props;
        var self = this;
        window.onbeforeunload = function () {
            if (this.state.type === 'Video') {
                this.stopVideoCall();
            } else {
                this.stopAudioCall()
            }
            return true
        }
        this.onAcceptingChatTimeEnding(acceptingChatTime);
        /*let cameras = client
            .getCameras((devices) => {
                //console.log('devices', devices[0])
            })*/
        //     .then((cameras) => {

        // })
        //         const cameraList = useCamera(client);
        this.setState({
            selectedPatient: {
                dob: this.props.DOB,
                email: this.props.email,
                firstName: this.props.patientName,
                gender: this.props.gender,
                gmtOffset: this.props.gmtOffset,
                insuranceCardNo: this.props.healthInsuranceCardNumber,
                lastName: '',
                userId: this.props.patientId
            }
        })
    }

    showMediaModalPress = () => {
        this.setState({showMediaModal: true})
        this.props.setMediaHeader(this.props.patientName, this.props.DOB, this.props.gender, this.props.symptoms)
    }

    onVideoPress = () => {
        this.startVideoChat()
        this.setState({showMediaModal: false})
        this.props.setVideo()
        var message_video = `Starting video call`
        this.props.sendMessage(this.props.clinicId, this.props.conversationId, {
            type: "text",
            content: message_video
        }, this.props.patientId, this.props.doctorName, this.props.doctorImage);
    }

    onAudioPress = () => {
        /*this.startAudioChat()
        this.setState({showMediaModal: false})
        var message_video = `Starting audio call`
        this.props.sendMessage(this.props.clinicId, this.props.conversationId, {
            type: "text",
            content: message_video
        }, this.props.patientId, this.props.doctorName, this.props.doctorImage);*/

    }

    startVideoChat = () => {
        /*setTimeout(() => {
            this.props.setVideoState(this.props.location, "Video", this.props.clinicId);
            this.props.setVideoStatusTrue();
        }, 5000);
        this.props.setVideoId(this.props.conversationId)
        this.setState({minimizeVideo: false, videoState: true, type: 'Video', showSOAPNotes: 2});
        this.initLocalStream();
        this.initClient();
        this.joinChannel();*/
    }

    startAudioChat = () => {
        /*setTimeout(() => {
            this.props.setVideoState(this.props.location, "Audio", this.props.clinicId);
            this.props.setVideoStatusTrue();
        }, 5000);
        this.props.setVideoId(this.props.conversationId)
        this.setState({
            minimizeVideo: false,
            audioState: true,
            type: 'Audio',
            patientInitial: this.props.patientName,
            showSOAPNotes: 1
        });
        this.initLocalAudioStream();
        this.initClient();
        this.joinAudioChannel();*/
    }

    showMessages = async (chatData) => {
        const {paidChat, conversationId, patientID, doctorID, issue, category} = chatData;
        const {clinicId} = this.props;
        if (this.userDetail !== null && this.userDetail !== undefined) {
            this.userDetail.refreshHistory();
        }

        this.props.setFlagTrue();
        this.setState({
            chatHistoryItemClick: false,
            showUpView: false,
            skipClassStatus: false,
            followClassStatus: false
        });

        this.props.setSelectedItem(paidChat, patientID, doctorID, conversationId, issue, category);
        if (this.props.staffData.uid === doctorID)
            this.props.makeUnreadAllMessages(conversationId, patientID);

        await this.props.findPatientDetail(patientID);
        await this.props.refreshMessages();
        await this.props.turnSelectChat(clinicId, conversationId);
        await this.props.fetchAllMessagesForClinicChats(clinicId, conversationId);
        await this.props.fetchChatTags(conversationId);
        await this.props.fetchUserChatHistory(patientID, clinicId);
        await this.props.fetchStatus(clinicId, conversationId);
        await this.props.fetchChatPrescriptions(null, clinicId, conversationId);
        this.props.clinic_setSubjectiveSymptoms(conversationId)
        this.props.clinic_setFindings(conversationId)
        this.props.clinic_setAssessment(conversationId)
        this.props.clinic_setPlan(conversationId)
        this.setState({minimizeVideo: true});
    }

    removedFromQueueAndshowMessages = debounce((chatData) => {
        const self = this;
        const {paidChat, conversationId, patientID, issue, category, transferredFromTier2} = chatData;
        const {clinicId, staffData, profile_image, full_name} = this.props;
        database.ref(`/users/${patientID}`)
            .once("value")
            .then(res => {
                if (res.val()) {
                    self.props.sendNewMessageNotification(
                        res.val().notificationToken,
                        null,
                        full_name
                    );
                } else {
                    console.error("failed to send queue removal notification to patient from non ydo doctor");
                }
            })
            .catch(err => {
                //console.log("firebase user query error:", err)
            });

        if (staffData && profile_image && full_name) {
            if (staffData && staffData.ansPatients) {
                if (this.props.acceptingChat) {
                    this.props.changeQueueStatusForClinicalChats(clinicId, paidChat, conversationId, patientID, staffData, this.props.full_name, patientID, issue, category);
                    this.props.setSelectedItem(paidChat, patientID, staffData.uid, conversationId, issue, category);
                    this.props.findPatientDetail(patientID);
                    this.props.sendDoctorFirstMessage(clinicId, conversationId, Boolean(transferredFromTier2));
                    this.props.refreshMessages();
                    this.props.turnSelectChat(clinicId, conversationId);
                    this.props.fetchAllMessagesForClinicChats(clinicId, conversationId);
                    this.props.fetchChatTags(conversationId);
                    this.props.fetchUserChatHistory(patientID, clinicId);
                    this.props.fetchStatus(clinicId, conversationId);
                    this.props.fetchChatPrescriptions(null, clinicId, conversationId);

                    this.setState({showUpView: false, skipClassStatus: false, followClassStatus: false});
                } else {
                    alert("Please turn on the Accept Patient.");
                }
            } else {
                this.setState({
                    showModal: true,
                    modalHeaderText: 'Permission Denied',
                    modalBodyText: 'You don\'t have permission to answer patients'
                });
            }
        } else {
            this.setState({
                showModal: true,
                modalHeaderText: 'Update Profile',
                modalBodyText: 'Please update your profile. Name and profile image are complusory'
            });
        }
    }, 1000);

    followUpSaveSuccess = () => {
        this.props.get_followUp("Clinical", this.props.full_name, this.props.clinicId);
    }

    onChangeText = (event) => {

        if ((event.target.value).match('#')) {
            this.setState({showCannedResponseList: true});
            this.filterUsers((event.target.value.slice((event.target.value).search('#'), event.target.value.length)));
        }
        this.props.onChangeText(event.target.value);

    }

    onsendmessage = (textareaRef, patientId, doctorName) => {
        const {clinicId, conversationId, full_name, profile_image} = this.props;
        if (this.props.inputBarText.length > 0) {
            textareaRef.current.value = "";
            this.props.sendMessage(clinicId, conversationId, {
                type: "text",
                content: this.props.inputBarText
            }, this.props.patientId, full_name, profile_image);
        }
    }
    searchFirebaseId = (id, followUp_id, index, patientId) => {
        this.props.set_notification(id, followUp_id, index, this.props.clinicId, patientId);
        setTimeout(() => {
            this.setState({allFollowUp: this.props.all_followUp})
        }, 2000)
    }

    followStatus = (dateTime, diffDays, timeString, dateString) => {
        const {clinicId, conversationId, full_name, profile_image} = this.props;
        this.props.save_followUp(this.props.patientId, this.props.symptoms, this.props.doctorName, this.props.patientName, dateTime, this.props.email, this.props.gmtOffset, 'Clinical')
        this.props.setFollowUpStatus(this.props.conversationId, this.props.clinicId, this.state.scheuledDate)
        this.setState({followClassStatus: true, dateTime: dateTime, followUpDays: diffDays + 1})
        let message = `Your follow up has been set to ${dateString} at ${timeString} with ${this.props.doctorName}`
        this.props.sendMessage(clinicId, conversationId, {
            type: "text",
            content: message
        }, this.props.patientId, full_name, profile_image);
    }

    filterUsers = (event) => {
        var keyword = event.toLowerCase();
        var filtered_cannedResponses = this.state.chats_canned_responses.filter((user) => {
            user = user.shortcut.toLowerCase();
            return user.indexOf(keyword) > -1;
        });
        if (filtered_cannedResponses.length !== 0) {
            this.setState({chats_canned_responses: filtered_cannedResponses});
        } else {
            this.setState({chats_canned_responses: this.props.clinic_chats_canned_responses});
        }
    }

    uploadImage = () => {
        const {clinicId, conversationId, full_name, profile_image} = this.props;
        var self = this;
        if (this.state.file !== '') {
            this.props.imageData(this.state.file, function (image_url) {
                if (image_url !== null) {
                    self.props.sendMessage(clinicId, conversationId, {
                        type: self.state.filetype,
                        content: image_url
                    }, self.props.patientId, full_name, profile_image);
                    self.setState({showUploadingModal: false});
                }
                self.setState({showUploadingModal: false});
            });
        }
    }

    getFileExtension(filename) {
        return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
    }

    onAttachmentClick = (e) => {
        e.current.click();
    }

    _handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            var type = file.type.split('/');
            this.setState({
                file: file,
                filetype: type[0],
                showUploadingModal: true
            });
        }
        reader.readAsDataURL(file)
    }
    send3dayFollowUp = async () => {
        if (isMobileView()) {
            await this.onRadioButtonChange('3 days')
            await this.setState({selectedDoctorFinal: this.state.selectedDoctor, scheduleFollowupState: 1});
            await this.setState({selectedDuration: 15,});
            await this.props.fetchTimeSlots(this.state.selectedDoctorFinal, this.state.selectedRow, this.state.selectedPatient, this.state.selectedDay.format('YYYY MM DD'), this.state.selectedDuration)
            await this.setState({scheduledDate: this.props.scheduleSlots[0]?.startDate})

            setTimeout(() => {
                this.setState({bookingInProgress: true})
                let scheduledTime = this.state.scheuledDate - new Date().getTime()
                this.props.storeSlot(this.props.clinicId, this.state.selectedDoctorFinal, this.state.selectedRow, this.state.selectedPatient, Object.values(this.props.scheduleSlots[0])[0], this.state.selectedDuration, (this.state.followUpID) ? this.state.followUpID : this.props.followUpID, this.props.conversationId, (status, msg) => {
                    if (status) {
                        // Schedule followups
                        let scheduledDate = Object.values(this.props.scheduleSlots[0])[0]

                        // fetch(doctorServicesUrl+`/schedule_followup/${this.props.clinicId}/${this.props.doctorID}/${this.props.conversationId}/${scheduledTime}`, {
                        //     method: 'GET',
                        //     headers: {
                        //         Accept: 'application/json',
                        //         'Content-Type': 'application/json',
                        //     },
                        //     // body: JSON.stringify({
                        //     //     amount: 4900,
                        //     //     id: convosnap.val().stripeToken,
                        //     // }),
                        // },
                        // )
                        // .then(response => response.json())
                        // .then(success => {
                        //     console.log('success: ', success);
                        // })

                        //--------------
                        //this.props.fetchTimeSlots(this.state.selectedDoctorFinal, this.state.selectedRow, this.state.selectedPatient, this.state.selectedDay.format('YYYY MM DD'), this.state.selectedDuration);
                        //this.setState({ showFollowUpErrorText: '', showFollowUpError: false, showFollowUpSuccess:true, showFollowUpSuccessText: "Follow up saved successfully!", scheuledDate:'', bookingInProgress: false});

                        const follow_time = new Date(scheduledDate);
                        let gmtOffset = (this.props.gmtOffset) ? this.props.gmtOffset : 0;
                        let utc = follow_time.getTime() + (follow_time.getTimezoneOffset() * 60000);
                        let followUpDate = (new Date(utc + (3600000 * gmtOffset)));

                        const current_time = new Date();

                        let current_utc = current_time.getTime() + (current_time.getTimezoneOffset() * 60000);
                        let finalDate = (new Date(current_utc + (3600000 * 0)));

                        const diffTime = Math.abs(followUpDate - finalDate);
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;
                        var date = new Date(scheduledDate);
                        var options = {
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true
                        };
                        var timeString = followUpDate.toLocaleString('en-US', options);
                        var dateString = new Date(scheduledDate).toDateString();
                        dateString = dateString.split(' ').slice(1).join(' ');
                        var month_names = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
                        var month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                        dateString = dateString.split(' ');
                        var month = month_names[month_names_short.indexOf(dateString[0])];
                        date = parseInt(dateString[1]);
                        var phenom;
                        if (date === 1) {
                            phenom = 'st';
                        } else if (date === 2) {
                            phenom = 'nd';
                        } else if (date === 3) {
                            phenom = 'rd';
                        } else if (date === 21) {
                            phenom = 'st';
                        } else if (date === 22) {
                            phenom = 'nd'
                        } else if (date === 23) {
                            phenom = 'rd';
                        } else {
                            phenom = 'th';
                        }
                        dateString = month + ' ' + String(date) + phenom;
                        //this.props.save_followUp(this.state.selectedFollowUpPatient.userId, "Dashboard Follow Up",this.props.doctorDetail.doctorName, this.state.selectedFollowUpPatient.firstName + " " + this.state.selectedFollowUpPatient.lastName, this.state.scheuledDate, this.state.selectedFollowUpPatient.email, (this.state.selectedFollowUpPatient.gmtOffset)?this.state.selectedFollowUpPatient.gmtOffset:0)

                        this.props.setFollowUpStatus(this.props.conversationId, this.props.clinicId, this.state.scheuledDate, (msg === 'success') ? null : msg);
                        this.setState({followClassStatus: true, followUpDays: diffDays + 1})
                        let message = `Your follow up has been set to ${dateString} at ${timeString} with ${this.props.full_name}`
                        this.props.sendMessage(this.props.clinicId, this.props.conversationId, {
                            type: "text",
                            content: message
                        }, this.props.patientId, this.props.full_name, this.props.profile_image);
                        this.setState({
                            selectedFollowUpPatient: null,
                            followUpDoctorName: '',
                            showScheduleFollow: false,
                            selectedDoctor: this.props.staffData.ansPatients ? this.props.staffData : {},
                            selectedDoctorFinal: this.props.staffData.ansPatients ? this.props.staffData : {},
                            scheduleFollowupState: 0,
                            scheuledDate: '',
                            resetDate: true,
                            selectedDay: moment(),
                            selectedDuration: 0,
                            bookingInProgress: false,
                            resetDateValue: moment()
                        });

                    } else {
                        this.setState({
                            showFollowUpError: true,
                            showFollowUpErrorText: `Scheduling Follow up due to error: ${msg}`,
                            scheuledDate: '',
                            bookingInProgress: false
                        });
                    }
                }, this.props.symptoms, this.props.selectedCategory); //doctor,patientid, date, duration

            }, 3000)

        }

    }
    closeChat = async () => {
        let medicationObject = this.props.user_medications && this.props.user_medications[this.props.selectedConversation || this.props.conversationId]
        let medications = medicationObject?.data || []
        let check = medicationObject?.isChanged || false
        if(check === true){
        alert("Please either save your changes in prescription or discard them.");
        return;
        }
        if(medications?.length> 0 && !this.props.pharmacy?.fax_number){
            alert("Please enter fax number for sending out the prescription.");
            return
        }
        if (isMobileView()) {

            if (this.props.doctorIsValid || this.props.admin) {
                if (this.props.videoChatId === this.props.conversationId && (this.state.videoState === true || this.state.audioState === true)) {
                    if (this.state.type === 'Video') {
                        this.stopVideoCall();
                    } else {
                        this.stopAudioCall();
                    }
                }

                this.setState((state) => ({endTime: new Date().getTime(), showOutPatientForm: true}));
                await this.send3dayFollowUp()
            }
            return
        }

        if (!this.props.admin) {
            if (this.props.followUpStatus === true || this.props.skipStatus === true || this.state.skipClassStatus === true || this.state.followClassStatus === true) {
                if (window.confirm("Are you sure you want to close this chat?")) {
                    if (this.props.doctorIsValid || this.props.admin) {
                        if (this.props.videoChatId === this.props.conversationId && (this.state.videoState === true || this.state.audioState === true)) {
                            if (this.state.type === 'Video') {
                                this.stopVideoCall();
                            } else {
                                this.stopAudioCall();
                            }

                        }
                        if (!isMobileView()) this.userDetail.refreshCloseHistory();
                        this.setState((state) => ({
                            endTime: new Date().getTime(),
                            showOutPatientForm: true,
                            showUpView: false
                        }));
                    }
                }
            } else {
                if (window.confirm(`We dont see any follow up set for ${this.props.patientName}. Would you like to set a follow up?`)) {
                    this.setState({showUpView: true});
                } else {
                    this.setState({showUpView: true});
                }
            }
        } else {
            if (window.confirm("Are you sure you want to close this chat?")) {
                if (this.props.doctorIsValid || this.props.admin) {
                    if (this.props.videoChatId === this.props.conversationId && (this.state.videoState === true || this.state.audioState === true)) {
                        if (this.state.type === 'Video') {
                            this.stopVideoCall();
                        } else {
                            this.stopAudioCall();
                        }
                    }
                    if (!isMobileView()) this.userDetail.refreshCloseHistory();

                    this.setState((state) => ({endTime: new Date().getTime(), showOutPatientForm: true}));
                }
            }
        }
    }

    closeChatModal = () => {
        this.setState((state) => ({showOutPatientForm: false}));
    }

    openTagListModal = () => {
        this.setState({showTagsListModal: !this.state.showTagsListModal});
    }

    onChatHistoryItemClick = (historyIndex, category, issue) => {
        this.setState({chatHistoryItemClick: true, chatHistoryUid: historyIndex, chatHistoryCategory: issue})
    }

    loadCurrentChat = () => {
        const {clinicId, conversationId} = this.props;
        this.props.fetchAllMessagesForClinicChats(clinicId, conversationId);
        this.setState({chatHistoryItemClick: false})
    }

    onTagClick = (name, id, uid) => {
        if (this.props.tagChatList.length !== 0) {
            const found = this.props.tagChatList.some(item => item.id === uid);
            if (!found) {
                this.props.saveTagInChat(this.props.conversationId, {id: uid, name: name});
                this.setState({showTagsListModal: false});
            }
        } else {
            this.props.saveTagInChat(this.props.conversationId, {id: uid, name: name});
            this.setState({showTagsListModal: false});
        }
    }

    onRemoveTagClick = (name, id, uid) => {
        this.props.removeTagInChat(this.props.conversationId, {uid: uid});
    }

    onCannedResponseClick = (uid, short_cut, text) => {
        this.setState({
            showCannedResponseList: false,
            selectedCannedResponseText: text,
            selectedCannedResponseShortCut: short_cut
        });
    }

    onCannedResponseShow = () => {
        this.setState({
            showCannedResponseList: !this.state.showCannedResponseList,
            selectedCannedResponseText: '',
            selectedCannedResponseShortCut: ''
        });
    }

    selectedCannedResponse = (textareaRef, text, short_cut) => {
        var updatedString;
        this.setState({
            selectedCannedResponseText: '',
            selectedCannedResponseShortCut: '',
            chats_canned_responses: this.props.clinic_chats_canned_responses
        });
        if (this.props.inputBarText.search('#') !== -1) {
            var replaceText = (this.props.inputBarText.slice((this.props.inputBarText).search('#'), this.props.inputBarText.length));
            updatedString = (this.props.inputBarText).replace(replaceText, text);
        } else {
            updatedString = (this.props.inputBarText).concat(' ', text);
        }
        textareaRef.current.value = updatedString;
        this.props.onChangeText(updatedString);
    }

    initClient = () => {
        /*client.init("e816acbc1d8e4e4db0e2b99df7694527", function () {
        }, function (err) {
            //console.log("AgoraRTC client init failed", err);
        });

        this.subscribeToClient();
        client.enableAudioVolumeIndicator();*/
    };

    subscribeToClient = () => {
        /*let me = this;
        client.on("stream-added", me.onStreamAdded);
        client.on("stream-subscribed", me.onRemoteClientAdded);
        client.on("peer-leave", me.onPeerLeave);
        client.on("peer-join", me.onPeerJoin);
        client.on("volume-indicator", function (evt) {
            //console.log("Event", evt, evt.attr.length)
            if (evt.attr.length > 0) {
                me.setState({showPulse: true})
            } else {
                me.setState({showPulse: false})

            }
        });*/
    };

    onPeerJoin = evt => {
        let me = this;
        let remoteStream = evt?.stream;

    }

    onPeerLeave = evt => {
        if (this.state.type === 'Video') {
            this.stopVideoCall();
        } else {
            this.stopAudioCall()
        }
    }

    onStreamAdded = evt => {
        /*let me = this;
        let stream = evt.stream;
        me.setState({remoteStreams: {...me.state.remoteStream, [stream.getId()]: stream}}, () => {
            this.props.saveRemoteStream(this.state.remoteStreams);
            client.subscribe(stream, function (err) {
                //console.log("Subscribe stream failed", err);
            });
        });*/
    };

    stopAudioCall = () => {
        /*this.localAudioStream.stop();
        client.leave(function () {
            //……
        }, function (err) {
            //console.log("client leave failed ", err);
            //error handling
        });


        this.localAudioStream.close();
        this.props.endVideoCall(this.props.location, this.props.clinicId);
        this.props.setVideo()
        this.props.setVideoStatusFalse();
        this.props.refreshRemoteStream();

        this.setState({
            minimizeVideo: true,
            audMute: false,
            vidMute: false,
            remoteStreams: {},
            declineShown: false,
            audioState: false
        })*/
    }

    stopDeclinedAudioCall = () => {
        /*this.localAudioStream.stop();
        client.leave(function () {
            //……
        }, function (err) {
            //console.log("client leave failed ", err);
            //error handling
        });


        this.localAudioStream.close();
        // this.props.endVideoCall(this.props.location, this.props.clinicId);
        this.props.setVideo()
        this.props.setVideoStatusFalse();
        this.props.refreshRemoteStream();

        this.setState({
            minimizeVideo: true,
            audMute: false,
            vidMute: false,
            remoteStreams: {},
            declineShown: false,
            audioState: false
        })*/
    }

    cancelMediaModal = () => {
        this.setState({showMediaModal: false})
    }

    stopVideoCall = () => {
        /*this.localStream.stop();
        client.leave(function () {
            //……
        }, function (err) {
            //console.log("client leave failed ", err);
            //error handling
        });
        this.localStream.close();
        this.props.endVideoCall(this.props.location, this.props.clinicId);
        this.props.setVideo()

        this.props.setVideoStatusFalse();
        this.props.refreshRemoteStream();

        this.setState({
            minimizeVideo: true,
            audMute: false,
            vidMute: false,
            remoteStreams: {},
            declineShown: false,
            videoState: false
        })*/

    }
    stopDeclinedVideoCall = () => {
        /*this.localStream.stop();
        client.leave(function () {
            //……
        }, function (err) {
            //console.log("client leave failed ", err);
            //error handling
        });


        this.localStream.close();
        // this.props.endVideoCall(this.props.location, this.props.clinicId);
        this.props.setVideo()

        this.props.setVideoStatusFalse();
        this.props.refreshRemoteStream();

        this.setState({
            minimizeVideo: true,
            audMute: false,
            vidMute: false,
            remoteStreams: {},
            declineShown: false,
            videoState: false
        })*/
    }

    joinChannel = () => {
        /*let me = this;
        client.join(null, String(this.props.patientId), USER_ID, function (uid) {
                client.publish(me.localStream, function (err) {
                    //console.log("Publish local stream error: " + err);
                });

                client.on("stream-published", function (evt) {
                });
            },
            function (err) {
                //console.log("Join channel failed", err);
            });*/
    };

    joinAudioChannel = () => {
        /*let me = this;
        client.join(null, String(this.props.patientId), USER_ID, function (uid) {
                client.publish(me.localAudioStream, function (err) {
                    //console.log("Publish local stream error: " + err);
                });

                client.on("stream-published", function (evt) {
                });
            },
            function (err) {
                //console.log("Join channel failed", err);
            });*/
    };

    onSendFollowUpRequest = (diffDays, timeString, dateString) => {
        const {clinicId, conversationId, full_name, profile_image} = this.props;
        let message = `I would like to follow up with you in ${diffDays} ${diffDays > 1 ? 'days' : 'day'}. Is ${dateString} at ${timeString} good for you?`
        this.props.sendMessage(clinicId, conversationId, {
            type: "text",
            content: message
        }, this.props.patientId, full_name, profile_image);
    }

    onRemoteClientAdded = evt => {
        let me = this;
        let remoteStream = evt.stream;
        //console.log("onRemoteStreamAdded")
        me.state.remoteStreams[remoteStream.getId()].play("agora_remote " + remoteStream.getId(), function (errState) {
            if (errState != null) {
                if (errState.video != null) {
                    if (errState.video.status != null) {
                        if (errState.video.status === 'paused') {
                            me.state.remoteStreams[remoteStream.getId()].play("agora_remote " + remoteStream.getId(), function (errState) {
                                //console.log("Error reviving stream", errState)
                            })
                        }
                    }
                }
            }
        });
    };

    maximizeVideo = () => {
        this.setState({minimizeVideo: false});
    }

    toggleAudio = () => {
        /*if (this.state.audMute === false) {
            this.localStream.muteAudio()
            this.setState({audMute: true});
        } else {
            this.localStream.unmuteAudio()
            this.setState({audMute: false});
        }*/
    }

    toggleVideo = () => {
        /*if (this.state.vidMute === false) {
            this.localStream.muteVideo();
            this.setState({vidMute: true});
        } else {
            this.localStream.unmuteVideo();
            this.setState({vidMute: false});
        }*/
    }
    switchCamera = async () => {
        /*await client.getCameras(async (devices) => {
            await this.setState({camerasList: devices})
            //console.log('camerasList', devices)
            if (Boolean(this.state.cameraDeviceIndex)) {
                let index = (this.state.cameraDeviceIndex + 1) % devices.length
                this.setState({cameraDeviceIndex: index})
                this.localStream.setDevice(devices[index].deviceId)
            }
            // alert(JSON.stringify(devices))
        })*/
        //console.log('this.state.camerasList', this.state.camerasList)
        // this.localStream.setDevice(this.state.camerasList[0].deviceId)
    }
    toggleAudioChat = () => {
        /*if (this.state.audMute === false) {
            this.localAudioStream.muteAudio()
            this.setState({audMute: true});
        } else {
            this.localAudioStream.unmuteAudio()
            this.setState({audMute: false});
        }*/
    }

    TransferClinicalChatToYDO = () => {
        this.setState({actionPressed: true});
        var self = this;
        this.props.transferClinicalChatToYDOByAdmin(this.state.clinicalChatData, function (status) {
            self.setState({open: false, actionPressed: false});
        });
    }

    CloseChatByAdmin = () => {
        this.setState({actionPressed: true});
        var self = this;
        this.props.closeClinicalChatByAdmin(this.state.clinicalChatData, function (status) {
            //console.log('status: ', status);
            self.setState({open: false, actionPressed: false});
        });
    }

    onRadioButtonChange = (value) => {
        let todayDate = new Date();
        this.setState({radioValue: value});
        if (value === '3 days') {
            todayDate.setDate(todayDate.getDate() + 3);
            this.setState({selectedDay: moment(todayDate), resetDateValue: moment(todayDate), resetDate: true});

        } else if (value === '1 week') {
            todayDate.setDate(todayDate.getDate() + 7)
            this.setState({selectedDay: moment(todayDate), resetDateValue: moment(todayDate), resetDate: true});
        } else {
            todayDate.setDate(todayDate.getDate() + 21)
            this.setState({selectedDay: moment(todayDate), resetDateValue: moment(todayDate), resetDate: true});
        }
    }
    componentWillUnmount = () => {
        this.props.resetSelectedConversationId();
    }

    clearFollowUpForm = () => {
        this.setState({
            selectedFollowUpPatient: null,
            followUpDoctorName: '',
            showScheduleFollow: false,
            selectedDoctor: this.props.staffData.ansPatients ? this.props.staffData : {},
            selectedDoctorFinal: this.props.staffData.ansPatients ? this.props.staffData : {},
            scheduleFollowupState: 0,
            scheuledDate: '',
            resetDate: true,
            selectedDay: moment(),
            selectedDuration: 0,
            bookingInProgress: false,
            resetDateValue: moment()
        });
        this.onRadioButtonChange(this.state.radioValue);
    }

    sendFollowUp = async () => {

        if (this.state.scheduleFollowupState === 0) {
            this.setState({selectedDoctorFinal: this.state.selectedDoctor, scheduleFollowupState: 1});
        } else {
            if (!Boolean(this.state.scheuledDate) || !Boolean(this.state.selectedDuration)) {
                this.setState({
                    showFollowUpErrorText: "Please fill all fields to send follow up",
                    showFollowUpError: true
                });
            } else {
                this.setState({bookingInProgress: true})
                let scheduledTime = this.state.scheuledDate - new Date().getTime()
                let fid = (this.props.followUpID) ? this.props.followUpID : (this.props.followupId ? this.props.followupId : null) //follow up id, if it already exists
                console.log('Followups Set Notify this.props.conversationId, fid', this.props.conversationId, fid)
                if (Boolean(this.props.followupId)) {
                    await database.ref(`users/${this.props.patientId}/followupId`).once('value', snap => {
                        if (snap.exists()) {
                            snap.ref.set(null)
                        }
                    })
                }
                this.props.storeSlot(this.props.clinicId, this.state.selectedDoctorFinal, this.state.selectedRow, this.state.selectedPatient, this.state.scheuledDate, this.state.selectedDuration, fid, this.props.conversationId, (status, msg) => {
                    if (status) {
                        // Schedule followups

                        const follow_time = new Date(this.state.scheuledDate);
                        let gmtOffset = (this.props.gmtOffset) ? this.props.gmtOffset : 0;
                        let utc = follow_time.getTime() + (follow_time.getTimezoneOffset() * 60000);
                        let followUpDate = (new Date(utc + (3600000 * gmtOffset)));

                        const current_time = new Date();

                        let current_utc = current_time.getTime() + (current_time.getTimezoneOffset() * 60000);
                        let finalDate = (new Date(current_utc + (3600000 * 0)));

                        const diffTime = Math.abs(followUpDate - finalDate);
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;
                        var date = new Date(this.state.scheuledDate);
                        var options = {
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true
                        };
                        var timeString = followUpDate.toLocaleString('en-US', options);
                        var dateString = new Date(this.state.scheuledDate).toDateString();
                        dateString = dateString.split(' ').slice(1).join(' ');
                        var month_names = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
                        var month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                        dateString = dateString.split(' ');
                        var month = month_names[month_names_short.indexOf(dateString[0])];
                        date = parseInt(dateString[1]);
                        var phenom;
                        if (date === 1) {
                            phenom = 'st';
                        } else if (date === 2) {
                            phenom = 'nd';
                        } else if (date === 3) {
                            phenom = 'rd';
                        } else if (date === 21) {
                            phenom = 'st';
                        } else if (date === 22) {
                            phenom = 'nd'
                        } else if (date === 23) {
                            phenom = 'rd';
                        } else {
                            phenom = 'th';
                        }
                        dateString = month + ' ' + String(date) + phenom;
                        //this.props.save_followUp(this.state.selectedFollowUpPatient.userId, "Dashboard Follow Up",this.props.doctorDetail.doctorName, this.state.selectedFollowUpPatient.firstName + " " + this.state.selectedFollowUpPatient.lastName, this.state.scheuledDate, this.state.selectedFollowUpPatient.email, (this.state.selectedFollowUpPatient.gmtOffset)?this.state.selectedFollowUpPatient.gmtOffset:0)

                        this.props.setFollowUpStatus(this.props.conversationId, this.props.clinicId, this.state.scheuledDate, (msg === 'success') ? null : msg);
                        this.setState({followClassStatus: true, followUpDays: diffDays + 1})
                        let message = `Your follow up has been set to ${dateString} at ${timeString} with ${this.props.full_name}`
                        this.props.sendMessage(this.props.clinicId, this.props.conversationId, {
                            type: "text",
                            content: message
                        }, this.props.patientId, this.props.full_name, this.props.profile_image);
                        this.setState({
                            selectedFollowUpPatient: null,
                            followUpDoctorName: '',
                            showScheduleFollow: false,
                            selectedDoctor: this.props.staffData.ansPatients ? this.props.staffData : {},
                            selectedDoctorFinal: this.props.staffData.ansPatients ? this.props.staffData : {},
                            scheduleFollowupState: 0,
                            scheuledDate: '',
                            resetDate: true,
                            selectedDay: moment(),
                            selectedDuration: 0,
                            bookingInProgress: false,
                            resetDateValue: moment()
                        });

                    } else {
                        this.setState({
                            showFollowUpError: true,
                            showFollowUpErrorText: `Scheduling Follow up due to error: ${msg}`,
                            scheuledDate: '',
                            bookingInProgress: false
                        });
                    }
                }, this.props.symptoms, this.props.selectedCategory); //doctor,patientid, date, duration
            }
        }
    }

    transferChat = () => {
        this.setState({showOnlineDoctors: true});
    }

    onHideTransferChatModal = (type) => {
        this.setState({showOnlineDoctors: type});
    }

    onAssignClick = (status, doctor) => {
        this.setState({showOnlineDoctors: !this.state.showOnlineDoctors});
        this.props.transferChatToAnotherClinicDoctor(status, this.props.clinicId, this.props.conversationId, doctor, true, this.props.admin, this.props.patientName);
    }

    handleSwitch = () => {
        const {acceptingChat, clinicId} = this.props;
        var self = this;
        console.log('acceptingChat: ', acceptingChat);
        if (acceptingChat === false) {
            this.setState({showWorkingHoursModal: true});
        } else {
            this.props.changeAcceptStatus(clinicId, false, null, () => {
                self.setState({showWorkingHoursModal: false});
            });
        }
    }
    _handleSwitch = (val) => {

        this.setState({_showWorkingHoursModal: !val});

    }

    onAcceptingChatTimeEnding = (datetime) => {
        const {acceptingChat, clinicId} = this.props;
        var self = this;
        if (acceptingChat === true) {
            let d = new Date();
            let currTime = d.getTime();
            let testingTime = datetime - (currTime + 3570000); // to check functionality hardcoded value of 10 or 20 seconds
            let closeChatTime = datetime - (currTime - 20000);
            if (closeChatTime > 0) {
                setTimeout(() => {
                    //this code runs in case of toggling off the 'Accept Chats' Switch || else part of funciton 'handleSwitch'
                    this.props.changeAcceptStatus(clinicId, false, null, () => {
                        self.setState({showWorkingHoursModal: false});
                        // setTimeout(() => {
                        //     // logout function
                        //     this.onLogoutClick();
                        // }, 3000); //gets logout after 3 sec
                    });
                }, closeChatTime);
            } else {
                //this code runs in case of toggling off the 'Accept Chats' Switch || else part of funciton 'handleSwitch'
                this.props.changeAcceptStatus(clinicId, false, null, () => {
                    self.setState({showWorkingHoursModal: false});
                    // setTimeout(() => {
                    //     // logout function
                    //     this.onLogoutClick();
                    // }, 3000); //gets logout after 3 sec
                });
            }
        }
    }

    onApplyWorkingHours = (datetime) => {
        const {acceptingChat, clinicId} = this.props;
        var self = this;
        if (acceptingChat === false) {
            this.props.changeAcceptStatus(clinicId, true, datetime, () => {
                self.setState({showWorkingHoursModal: false});
            });
            setTimeout(() => {
                this.onAcceptingChatTimeEnding(datetime);
            }, 500);
        } else {
            this.props.changeAcceptStatus(clinicId, false, null, () => {
                self.setState({showWorkingHoursModal: false});
            });
        }
    }
    _onApplyWorkingHours = (datetime) => {
        console.log('datetime: ', datetime);
        const {acceptingChat, clinicId} = this.props;
        var self = this;

        this.props.changeAcceptStatus(clinicId, true, datetime, () => {
            self.setState({_showWorkingHoursModal: false});
        });

    }

    onHideWorkingHoursModal = (type) => {
        this.setState({showWorkingHoursModal: type});
    }

    onLogoutClick = () => {
        window.localStorage.setItem('logged_in', false);

        const {staffData, clinicId} = this.props;
        console.log("staffData: ", staffData);

        this.props.logoutClinicMember(clinicId, staffData.uid);
    }


    onDateChange = (date) => {

        this.setState({selectedDay: date});
        this.props.fetchTimeSlots(this.state.selectedDoctorFinal, this.state.selectedRow, this.state.selectedPatient, date.format('YYYY MM DD'), this.state.selectedDuration);
    }

    render() {

        var selectedconvoCount = _.find(this.props.unreadClinicalConvoCounts, ["conversationId", this.props.conversationId]);
        var selectedconvo = _.find(this.props.clinical_conversations, ["conversationId", this.props.conversationId]);

        if (this.props.buildNumber !== undefined && this.props.buildNumber != null) {
            var splitBuild = this.props.buildNumber.split('(');
            if (splitBuild.length > 1) {
                splitBuild = splitBuild[1].substring(0, splitBuild[1].length - 1);
                splitBuild = parseInt(splitBuild);
            }
        }

        let showChat = this.props.gender || this.props.city || this.props.country || this.props.platform || this.props.email || this.props.convoCount || this.props.buildNumber || this.props.medication;
        const {
            open, actionPressed, showModal, modalHeaderText, modalBodyText,
            showOnlineDoctors, showWorkingHoursModal
        } = this.state;

        const {
            clinic_canned_responses, clinic_chats_canned_responses, clinic_canned_responses_assessment,
            clinic_canned_responses_plan, subjective_symptoms, findings, assessment, plan,
            staffData, acceptingChat, acceptingChatTime
        } = this.props;

        const slots = (this.props.scheduleSlots) ? this.props.scheduleSlots.length > 0 ? this.props.scheduleSlots.map(slot => {
            return (
                <div key={'slot_' + slot.startTime}
                     className={this.state.scheuledDate === slot.startTime ? classes.selectedTimeSlotsSectionButton : classes.timeSlotsSectionButton}
                     onClick={() => {
                         this.setState({scheuledDate: slot.startTime})
                     }}>{moment(slot.startTime).format("h:mm a")}</div>
            )
        }) : null : null;

//        const doctors = (this.props.clinical_doctors) ? this.props.clinical_doctors.length > 0 ? this.props.clinical_doctors.map(doctor => {
        const doctors = (this.props.clinical_doctors) ? this.props.clinical_doctors.length > 0 ? this.props.clinical_doctors.map(doctor => {
            //MA-455 (if condition was added to remove doctors with no name)
            if (doctor.fullName !== "") {
                return (
                    <div key={doctor.uid} className={classes.dateFilterRows}><input type='radio'
                                                                                    checked={this.state.selectedDoctor.uid === doctor.uid}
                                                                                    onChange={() => {
                                                                                        this.setState({selectedDoctor: doctor})
                                                                                    }}
                                                                                    className={classes.dateFiltersRadio}/>{doctor.fullName}
                    </div>
                )
            }
        }) : null : null;

        console.log('acceptingChatTime: ', acceptingChatTime, 'props: ', this.props);
        return (
            isMobileView() ? <Aux style={{
                    width: window.innerWidth,
                    height: window.innerHeight,
                    'background-color': '#F1F2F6',
                    'overflow': 'hidden'
                }}>
                    {<div className={_style.main}
                          style={Boolean(this.state.chatSelected) ? {height: '0px',} : {height: window.innerHeight - 60}}>
                        {!Boolean(this.state.chatSelected) && <>
                            <_Header title="Patient Consultations" back={{onClick: () => this.props.history.goBack(null)}}/>
                            <_ClinicalConversationList
                                {...this.props}
                                chatSelected={(val, dontConfirm = false, queued = false) => {
                                    console.log("chatSelected val ", val)
                                    if (queued === true) {
                                        this.setState({
                                            chatSelectedConfirmed: false,
                                            showChatConfirmationModal: true,
                                            chatSelected: false

                                        })
                                        return;
                                    }
                                    if (dontConfirm) {
                                        this.setState({
                                            chatSelectedConfirmed: true,
                                            showChatConfirmationModal: false,
                                            chatSelected: val
                                        })
                                        return;
                                    }
                                    this.setState({chatSelected: val})
                                    this.setState({showChatConfirmationModal: false})
                                }}
                                removedFromQueueAndshowMessagesConfirmedStatus={Boolean(this.state.chatSelectedConfirmed) && !Boolean(this.state.showChatConfirmationModal)}
                                admin={this.props.admin}
                                conversations={this.props.clinical_conversations}
                                searchFirebaseId={(id, followUp_id, index, patientFirebaseId) => {
                                    this.searchFirebaseId(id, followUp_id, index, patientFirebaseId)
                                }}
                                allFollowUp={this.state.allFollowUp}
                                allTime={this.props.all_time}
                                chatCounts={this.props.clinicalChatCounts}
                                unreadConvoCounts={this.props.unreadClinicalConvoCounts}
                                selectedConversation={this.props.conversationId}
                                chatHistoryItemClick={this.state.chatHistoryItemClick}
                                showMessages={(chatData) => this.showMessages(chatData)}
                                removedFromQueueAndshowMessages={(chatData) => this.removedFromQueueAndshowMessages(chatData)}
                            />
                            <AcceptChatFooter acceptingChat></AcceptChatFooter>
                        </>}


                    </div>}
                    {Boolean(this.state.showChatConfirmationModal) && <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: window.innerWidth,
                        height: window.innerHeight,
                        alignContent: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(52, 52, 52, 0.8)',
                        position: 'fixed',
                        top: 0,
                        zIndex: '10'
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: window.innerWidth * 0.7,
                            height: '200px',
                            alignContent: 'center',
                            justifyContent: 'space-evenly',
                            top: '100px',
                            backgroundColor: '#fff',
                            borderRadius: '5px',
                            alignSelf: 'center',
                            justifySelf: 'center',
                            padding: '10px',
                        }}>
                            <div style={{textAlign: 'center', color: "#884a9d"}}>Are you sure you want to pick up this
                                consult?
                            </div>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                justifySelf: 'end',
                                width: '100%'
                            }}>
                                <button style={{
                                    width: '100px',
                                    height: '40px',
                                    border: '1px solid #884a9d',
                                    borderRadius: '5px',
                                    backgroundColor: '#fff',
                                    color: "#884a9d",
                                    fontSize: '14px'
                                }} color="#884a9d" title="Cancel" onClick={() => {
                                    this.setState({showChatConfirmationModal: false, chatSelected: false})
                                }}>Cancel
                                </button>
                                <button style={{
                                    width: '100px',
                                    height: '40px',
                                    backgroundColor: '#fff',
                                    borderRadius: '5px',
                                    backgroundColor: "#884a9d",
                                    border: '1px',
                                    color: '#fff',
                                    fontSize: '14px'
                                }} title="Confirm" onClick={() => {
                                    this.setState({
                                        chatSelectedConfirmed: true,
                                        showChatConfirmationModal: false
                                    })
                                }}>Confirm
                                </button>
                            </div>

                        </div>
                    </div>
                    }
                    <div className={this.state.minimizeVideo ? _classes.mainDivVideoFalse : _classes.mainDivVideo}>
                        {!this.state.minimizeVideo && <_Header title="Chat" back={{
                            title: ' ', onClick: () => {
                                this.setState({minimizeVideo: true})
                            }
                        }} customView={<div style={{
                            flex: 1,
                            flexDirection: 'row',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginRight: '10px'
                        }}>
                            <div style={{
                                flexDirection: 'column',
                                display: 'flex',
                                fontSize: '14px',
                                fontFamily: 'HarmoniaSansStd-Bold',
                                color: "#884a9d"
                            }}>
                                <div style={{
                                    fontFamily: 'HarmoniaSansStd-Bold',
                                    color: "#884a9d"
                                }}> {this.props.patientName}</div>
                                <div style={{
                                    fontFamily: 'HarmoniaSansStd-Regular',
                                    color: "#000"
                                }}>{moment().diff((this.props.DOB), 'years', false) + ' years'}, {this.props.city}, {this.props.country}</div>
                            </div>
                            <div style={{flexDirection: 'row', display: 'flex', fontSize: '14px', verticalAlign: 'center'}}>
                                {/* <FaVideo color="#884a9d" size={22} style={{ marginRight: '10px' }} onClick={()=>{
                                alert('J')
                                console.log('Responsive VideoChat click',props)
                                props.videoChat()
                                }}></FaVideo> */}
                                <FaBars color="#884a9d" size={22} style={{marginRight: '10px',}}
                                        onClick={() => {
                                            this.setState({showSidebar: true})
                                            // setSidebarVisibility(!showSidebarModal);
                                            // alert('he')
                                        }}></FaBars>
                            </div>
                        </div>}
                        />}
                        {/* <div  style={{
                        height: window.innerHeight, backgroundColor: 'rgba(185, 185, 185, 0.8)',width:'100%'
                        //  ...linkStyle,
                        //  ...linkTransitionStyles[tstate]
                    }
                    }
                    ></div> */}
                        {/* < */}
                        {/* <div style={{ backgroundColor: 'white', height: 10 }}></div> */}
                        <div style={{backgroundColor: 'black',}}>
                            {Object.keys(this.state.remoteStreams).length === 0 && this.state.minimizeVideo === false &&
                            <Fragment>

                                <div className={this.state.type === 'Audio' ? _classes.spinner1 : _classes.spinner}>
                                    <Spinner/>
                                </div>
                                <div className={_classes.requestDiv}>Requesting patient to connect</div>
                                <Fragment style={{backgroundColor: "#884a9d"}}>
                                    {/* Bottom Bar options */}
                                    <div
                                        className={this.state.type === 'Video' ? _classes.videoHeader22 : _classes.videoHeader2}>
                                        <div onClick={() => this.setState({minimizeVideo: true})}>
                                            <FaAngleLeft color={'#fff'}></FaAngleLeft>
                                        </div>
                                        <div
                                            onClick={() => this.state.videoState === true ? this.toggleAudio() : this.toggleAudioChat()}>
                                            {this.state.audMute === false &&
                                            <FaMicrophone className={classes.microphone}/>
                                            }
                                            {this.state.audMute === true &&
                                            <FaMicrophoneSlash className={classes.microphone}/>
                                            }
                                        </div>
                                        <div
                                            onClick={() => this.state.videoState === true ? this.stopVideoCall() : this.stopAudioCall()}>
                                            <div onClick={() => this.setState({minimizeVideo: true})}>
                                                <FaPhoneSlash className={classes.microphone}/>
                                            </div>
                                        </div>
                                        <div onClick={() => this.toggleVideo()}>
                                            <FaVideo color={'#fff'}/>
                                        </div>
                                        <div onClick={() => this.switchCamera()}>
                                            <MdSwitchCamera color={'#fff'}/>
                                        </div>
                                    </div>
                                </Fragment>
                            </Fragment>
                            }

                            <Fragment>
                                <div
                                    className={this.state.minimizeVideo ? _classes.minimizeVideo : Object.keys(this.state.remoteStreams).length == 0 ? _classes.minimizeVideo : _classes.mainDiv}>
                                    {Object.keys(this.state.remoteStreams).map(key => {
                                        let stream = this.state.remoteStreams[key];
                                        let streamId = stream.getId();
                                        return (
                                            <div key={streamId}
                                                 id={`agora_remote ${streamId}`}
                                                 className={this.state.minimizeVideo ? _classes.minimizeVideo : _classes.div1}>
                                                {this.state.type === 'Audio' &&
                                                <div style={{
                                                    height: 0,
                                                    width: 0,
                                                    borderRadius: 100,
                                                    backgroundColor: this.state.showPulse === true ? 'black' : 'black',
                                                    marginLeft: 200,
                                                    paddingTop: 1,
                                                    marginTop: 100
                                                }}>
                                                    <div style={{
                                                        height: 150,
                                                        width: 150,
                                                        borderRadius: 80,
                                                        backgroundColor: 'green',
                                                        alignSelf: 'center',
                                                        top: 60,
                                                        marginTop: 130,
                                                        marginLeft: 16,
                                                        marginTop: 14
                                                    }}>
                                                        <span style={{
                                                            color: 'white',
                                                            display: 'flex',
                                                            paddingTop: 30,
                                                            paddingLeft: 50,
                                                            fontSize: 70
                                                        }}>{this.state.patientInitial.charAt(0)}</span>
                                                    </div>
                                                    {this.state.showPulse === true &&
                                                    <div style={{
                                                        marginTop: 100,
                                                        marginLeft: 5,
                                                        color: 'white',
                                                        width: 200
                                                    }}>{this.state.patientInitial} is talking</div>
                                                    }
                                                </div>
                                                }
                                            </div>
                                        )
                                    })}

                                    <div id="agora_local"
                                         className={this.state.minimizeVideo ? _classes.minimizeVideo : Object.keys(this.state.remoteStreams).length == 0 ? _classes.minimizeVideo : _classes.div2}></div>
                                    {Object.keys(this.state.remoteStreams).length !== 0 && this.state.minimizeVideo === false &&

                                    <Fragment>
                                        <div
                                            className={this.state.minimizeVideo ? _classes.minimizeVideo : Object.keys(this.state.remoteStreams).length == 0 ? _classes.minimizeVideo : this.state.type === 'Video' ? _classes.videoHeader11 : _classes.videoHeader23}>
                                            <div onClick={() => this.setState({minimizeVideo: true})}>
                                                <FaAngleLeft color={'#fff'}></FaAngleLeft>
                                            </div>
                                            <div
                                                onClick={() => this.state.videoState === true ? this.toggleAudio() : this.toggleAudioChat()}>
                                                {this.state.audMute === false &&
                                                <FaMicrophone className={classes.microphone}/>
                                                }
                                                {this.state.audMute === true &&
                                                <FaMicrophoneSlash className={classes.microphone}/>
                                                }
                                            </div>
                                            <div
                                                onClick={() => this.state.videoState === true ? this.stopVideoCall() : this.stopAudioCall()}>
                                                <div onClick={() => this.setState({minimizeVideo: true})}>
                                                    <FaPhoneSlash className={classes.microphone}/>
                                                </div>
                                            </div>
                                            < div onClick={() => this.toggleVideo()}>
                                                <FaVideo color={'#fff'}/>
                                            </div>
                                            <div onClick={() => this.switchCamera()}>
                                                <MdSwitchCamera color={'#fff'}/>
                                            </div>
                                        </div>
                                    </Fragment>

                                    }
                                </div>
                            </Fragment>
                        </div>
                        {/* <div style={this.state.type === 'Video' ? { height: '8%', backgroundColor: 'black' } : { height: '8%', backgroundColor: 'black' }}></div> */}
                    </div>
                    {this.state.minimizeVideo === true && Boolean(this.state.chatSelected) &&
                    <Fragment>
                        <ChatScreen
                            patientId={this.props.patientId}
                            chatSelected={(val, dontConfirm = false) => {
                                if (dontConfirm) {
                                    this.setState({
                                        chatSelectedConfirmed: true,
                                        showChatConfirmationModal: false,
                                        chatSelected: val
                                    })
                                    return;
                                }
                                this.setState({chatSelected: val})
                                this.setState({chatSelectedConfirmed: false})
                            }}
                            toggleSidebar={(val) => this.setState({showSidebar: val})}
                            staffData={staffData}
                            loading_messages={this.props.loading_messages}
                            doctorIsValid={this.props.doctorIsValid}
                            adminIsValid={this.props.admin}
                            closeChat={() => this.closeChat()}
                            transferChat={() => this.transferChat()}
                            videoState={this.state.videoState}
                            videoChat={() => this.showMediaModalPress()}
                            switchCamera={() => this.switchCamera()}
                            audioChat={() => this.showMediaModalPress()}
                            audioState={this.state.audioState}
                            minimizeVideo={this.state.minimizeVideo}
                            maximizeVideo={() => this.maximizeVideo()}
                            open={this.state.showTagsListModal}
                            tags={this.props.tags}
                            showCannedResponseList={this.state.showCannedResponseList}
                            canned_responses={this.state.chats_canned_responses}
                            selectedCannedResponseText={this.state.selectedCannedResponseText}
                            selectedCannedResponseShortCut={this.state.selectedCannedResponseShortCut}
                            onCannedResponseClick={(uid, short_cut, text) => this.onCannedResponseClick(uid, short_cut, text)}
                            onCannedResponseShow={() => this.onCannedResponseShow()}
                            selectedCannedResponse={(textareaRef, text, short_cut) => this.selectedCannedResponse(textareaRef, text, short_cut)}
                            onTagClick={(name, id, uid) => this.onTagClick(name, id, uid)}
                            openTagListModal={() => this.openTagListModal()}
                            selectedTags={this.props.tagChatList}
                            onRemoveTagClick={(name, id, uid) => this.onRemoveTagClick(name, id, uid)}
                            messages={this.state.chatHistoryItemClick === false ? this.props.messages : this.props.chatHistory[this.state.chatHistoryUid].messages}
                            patientName={this.props.patientName}
                            doctorName={this.props.doctorName}
                            doctorImage={this.props.doctorImage}
                            inputBarText={this.props.inputBarText}
                            onChangeText={(event) => this.onChangeText(event)}
                            onsendmessage={(textareaRef) => this.onsendmessage(textareaRef, this.props.patientId, this.props.doctorName)}
                            inputElement={createRef()}
                            textareaElement={createRef()}
                            onAttachmentClick={(file_input) => this.onAttachmentClick(file_input)}
                            _handleImageChange={(e) => this._handleImageChange(e)}
                            convoCount={this.props.convoCount}
                            city={this.props.city}
                            country={this.props.country}
                            complete_address={this.props.complete_address}
                            user_id={this.props.user_id}
                            platform={this.props.platform}
                            email={this.props.email}
                            gmtOffset={this.props.gmtOffset}
                            medication={this.props.medication}
                            symptoms={this.state.chatHistoryItemClick === false ? this.props.symptoms : this.state.chatHistoryCategory}
                            gender={this.props.gender}
                            DOB={this.props.DOB}
                            buildNumber={this.props.buildNumber}
                            acceptingChat={this.props.acceptingChat}
                            heartRate={this.props.heartRate}
                            weightKg={this.props.weightKg}
                            weightLbs={this.props.weightLbs}
                            stepCount={this.props.stepCount}
                            runningWalking={this.props.runningWalking}
                            heightCm={this.props.heightCm}
                            currentSleep={this.props.currentSleep}
                            averageSleep={this.props.averageSleep}
                            heightFeet={this.props.heightFeet}
                            periodStartDate={this.props.periodStartDate}
                            flowType={this.props.flowType}
                            restingHeartRate={this.props.restingHeartRate}
                            patient_phone_number={this.props.patient_phone_number}
                        />

                    </Fragment>
                    }
                    <OutPatientForm
                        show={this.state.showOutPatientForm}
                        currentDoctorProfile={this.props.currentDoctorProfile}
                        hideModal={() => {
                            this.closeChatModal()
                            this.setState({chatSelected: '', chatSelectedConfirmed: false, showSidebar: false})
                            this.forceUpdate()
                        }}
                        followClassStatus={this.state.followClassStatus}
                        followUpStatus={this.props.followUpStatus}
                        followUpID={(this.state.followUpID) ? this.state.followUpID : this.props.followUpID}
                        followUpDate={Boolean(this.state.scheuledDate) ? this.state.scheuledDate : this.props.followupDate}
                        patientName={this.props.patientName}
                        patientDOB={this.props.DOB}
                        doctorName={this.props.doctorName}
                        doctorImage={this.props.doctorImage}
                        doctorBillingNumber={this.props.staffData?.billingNumber}
                        signatureImageURL={this.props.signatureImageURL}
                        doctorlicense={this.props.doctorlicense}
                        clinicPhoneNumber={this.props.clinicPhoneNumber}
                        clinicPostalCode={this.props.postal_code}
                        clinicAddress={this.props.clinicAddress}
                        start_timestamp={Boolean(selectedconvo) ? selectedconvo.start_timestamp : null}
                        endTime={this.state.endTime}
                        isVideoCall={this.state.showSOAPNotes}
                        followUpDays={this.state.followUpDays}
                        closeChatData={{
                            "paidChat": this.props.paidChat,
                            "conversationId": this.props.conversationId,
                            "doctorID": this.props.doctorID,
                            "admin": this.props.admin,
                            "doctorName": this.props.doctorName,
                            "patientName": this.props.patientName,
                            "patientId": this.props.patientId,
                            "selectedIssue": this.props.selectedIssue,
                            "selectedCategory": this.props.selectedCategory,
                            "tagChatList": this.props.tagChatList,
                            "heartRate": this.props.heartRate,
                            "weightKg": this.props.weightKg,
                            "weightLbs": this.props.weightLbs,
                            "stepCount": this.props.stepCount,
                            "runningWalking": this.props.runningWalking,
                            "heightCm": this.props.heightCm,
                            "currentSleep": this.props.currentSleep,
                            "averageSleep": this.props.averageSleep,
                            "heightFeet": this.props.heightFeet,
                            "periodStartDate": this.props.periodStartDate,
                            "flowType": this.props.flowType,
                            "restingHeartRate": this.props.restingHeartRate,
                            "complete_address": this.props.complete_address,
                        }}
                        healthInsuranceCardFront={this.props.healthInsuranceCardFront}
                        healthInsuranceCardBack={this.props.healthInsuranceCardBack}
                        healthInsuranceCardNumber={this.props.healthInsuranceCardNumber}
                        city={this.props.city}
                        province={this.props.province}
                        gender={this.props.gender}
                        postalcode={this.props.postalcode}
                        plan={this.props.plan}
                        patient_phone_number={this.props.patient_phone_number}
                        subjective_symptoms={this.props.subjective_symptoms}
                        findings={this.props.findings}
                        assessment={this.props.assessment}
                        patientAddress={this.props.complete_address + ", " + this.props.city + ", " + this.props.province + ", " + this.props.postalcode + ", " + this.props.country}
                        setSubjectiveSymptoms={this.props.clinic_setSubjectiveSymptoms}
                        setFindings={this.props.clinic_setFindings}
                        setAssessment={this.props.clinic_setAssessment}
                        setPlan={this.props.clinic_setPlan}
                        patient_profile={this.props.patientProfile}
                        user_medications={this.props.user_medications}

                    />
                    {Boolean(this.state.showSidebar) && <_Sidebar
                        {...this.props}
                        toggleSidebar={(val) => this.setState({showSidebar: val})}
                        showSidebar={Boolean(this.state.showSidebar)}
                        prev_chats={this.props.chatHistory}
                        selectedConversation={this.props.conversationId}
                        onSendFollowUpRequest={(diffDays, timeString, dateString) => this.onSendFollowUpRequest(diffDays, timeString, dateString)}
                        skipStatus={() => this.skipStatus()}
                        followStatus={(dateTime, diffDays, timeString, dateString) => this.followStatus(dateTime, diffDays, timeString, dateString)}
                        showUpView={this.state.showUpView}
                        doctorIsValid={this.props.doctorIsValid}
                        isLoading={this.props.isLoading}
                        videoChat={() => this.showMediaModalPress()}
                        maximizeVideo={() => this.maximizeVideo()}
                        videoState={this.state.videoState}
                        audioState={this.state.audioState}
                        minimizeVideo={this.state.minimizeVideo}
                        email={this.props.email}
                        DOB={this.props.DOB}
                        followUpSaveSuccess={() => this.followUpSaveSuccess()}
                        radioButtonChange={(value) => this.onRadioButtonChange(value)}
                        patient_profile={this.props.patientProfile}
                        closeChat={() => this.closeChat()}
                        showHistoryMessages={(uid) => this.showHistoryMessages(uid)}
                        heartRate={this.props.heartRate}
                        weightKg={this.props.weightKg}
                        weightLbs={this.props.weightLbs}
                        stepCount={this.props.stepCount}
                        runningWalking={this.props.runningWalking}
                        heightCm={this.props.heightCm}
                        currentSleep={this.props.currentSleep}
                        averageSleep={this.props.averageSleep}
                        patientName={this.props.patientName}
                        heightFeet={this.props.heightFeet}
                        periodStartDate={this.props.periodStartDate}
                        flowType={this.props.flowType}
                        restingHeartRate={this.props.restingHeartRate}
                        historyMessage={(historyIndex, category, issue) => this.onChatHistoryItemClick(historyIndex, category, issue)}
                        loadCurrentChat={this.loadCurrentChat}
                        setFlagTrue={this.props.setFlagTrue}
                        setFlagFalse={this.props.setFlagFalse}
                        unReadCountObject={selectedconvoCount}
                        user_id={this.props.user_id}
                        patientId={this.props.patientId}
                        patientFirebaseID={this.props.patientFirebaseID}
                        healthInsuranceCardFront={this.props.healthInsuranceCardFront}
                        healthInsuranceCardBack={this.props.healthInsuranceCardBack}
                        healthInsuranceCardNumber={this.props.healthInsuranceCardNumber}
                        complete_address={this.props.complete_address}
                        convoCount={this.props.convoCount}
                        gmtOffset={this.props.gmtOffset}
                        platform={this.props.platform}
                        buildNumber={this.props.buildNumber}
                        plan={this.props.plan}
                        subjective_symptoms={this.props.subjective_symptoms}
                        findings={this.props.findings}
                        assessment={this.props.assessment}
                        canned_responses={this.state.canned_responses}
                        canned_responses_assessment={this.props.clinic_canned_responses_assessment}
                        canned_responses_plan={this.props.clinic_canned_responses_plan}
                        setSubjectiveSymptoms={this.props.clinic_setSubjectiveSymptoms}
                        setFindings={this.props.clinic_setFindings}
                        setAssessment={this.props.clinic_setAssessment}
                        setPlan={this.props.clinic_setPlan}
                        showSOAPNotes={this.state.showSOAPNotes}
                        setShowScheduleFollow={(status) => this.setState({showScheduleFollow: status})}
                        followUpStatus={this.props.followUpStatus}
                        followUpDate={Boolean(this.state.scheuledDate) ? this.state.scheuledDate : this.props.followupDate}
                        followUpID={(this.state.followUpID) ? this.state.followUpID : this.props.followUpID}
                        clinical_doctors={this.state.clinical_doctors}
                        scheduleSlots={this.state.scheduleSlots}
                        slots_loading={this.state.slots_loading}
                        user_medications={this.props.user_medications}

                        update_med_time={this.props.update_med_time}
                        fetchTimeSlots={(selectedDoctorFinal, selectedRow, selectedPatient, date, selectedDuration) => this.props.fetchTimeSlots(selectedDoctorFinal, selectedRow, selectedPatient, date, selectedDuration)}
                    ></_Sidebar>}
                    <Modal show={this.state.showMediaModal}>
                        <div className={classes.modal}>
                            <MediaHeader/>
                            <div className={classes.modalHeader}>Please select a media chat type</div>
                            <div style={isMobileView() ? {
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            } : {display: 'flex', flexDirection: 'row'}}>

                                {/* <button
                                className={(this.props.platform === 'android' && splitBuild > 259) || (this.props.platform === 'ios' && splitBuild > 384) ? classes.btn1 : classes.btn1a}
                                type="button"
                                onClick={() => this.onVideoPress()}
                            >Video Conference</button> */}

                                {((this.props.platform === 'android' && splitBuild > 259) || (this.props.platform === 'ios' && splitBuild > 384)) &&
                                <button
                                    className={classes.btn2}
                                    type="button"
                                    onClick={() => this.onAudioPress()}
                                >Audio Only</button>
                                }

                                <button
                                    className={(this.props.platform === 'android' && splitBuild > 259) || (this.props.platform === 'ios' && splitBuild > 384) ? classes.btn3 : classes.btn3a}
                                    type="button"
                                    onClick={() => this.cancelMediaModal()}
                                >Cancel
                                </button>

                            </div>
                            {((this.props.platform === 'android' && splitBuild <= 259) || (this.props.platform === 'ios' && splitBuild <= 384)) &&
                            <div className={classes.error}>Audio Chat is not available for this patient as the patient is on
                                an older version of the app.</div>
                            }
                        </div>
                    </Modal>
                    <Modal show={this.state.showUploadingModal} modalClosed={this.onHideAddAgentModal}>
                        <div className={classes.modal} style={{}}>
                            {!isMobileView() && <Header/>}
                            {this.props.uploadingImage &&
                            <ProgressBar/>
                            }
                            {!this.props.uploadingImage &&
                            <div className={classes.modalcontainer} style={isMobileView() ? {flexDirection: 'row'} : null}>
                                <span className={classes.spandiv}>{this.state.file.name}</span>
                                <button
                                    className={classes.btn}
                                    type="button"
                                    onClick={() => this.uploadImage()}
                                >Upload
                                </button>
                            </div>
                            }
                        </div>
                    </Modal>

                </Aux> :
                <Aux>
                    <div className={classes.top_bar}>
                        <div className={classes.header_doctor_name}>
                            <span>{this.props.full_name}</span>
                        </div>
                        <div className={classes.header_status}>
                            Status:
                            {acceptingChat && <span> Accepting Patients</span>}
                            {!acceptingChat &&
                            <span className={classes.notAcceptingChat}> Not Accepting Patients</span>}
                            {(this.props.doctorIsValid || this.props.admin) && showChat &&
                            <div className={classes.header_close} onClick={() => this.closeChat()}>
                                <span>End Encounter</span>
                            </div>
                            }
                        </div>
                        <div className={classes.header_accept_chat_status}>
                            <span>Accept Patients:</span>
                            <Switch
                                onChange={() => this.handleSwitch()}
                                checked={acceptingChat}
                                onColor="#884a9d"
                                onHandleColor="#884a9d"
                                handleDiameter={20}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                            />
                            {acceptingChatTime !== null &&
                            <span
                                className={classes.date_span}>{moment(acceptingChatTime).format('MMMM DD YYYY, h:mm a')}</span>
                            }
                        </div>
                    </div>
                    <div className={this.state.showScheduleFollow ? classes.overlay : classes.hide_overlay}
                         onClick={this.clearFollowUpForm}></div>
                    <div className={classes.scheduleModal} style={{
                        //MA-455
                        //transform: this.state.showScheduleFollow ? 'translateY(0),' : 'translateY(-100vh)',
                        transform: this.state.showScheduleFollow ? 'translateY(0) translate(-50%, -50%)' : 'translateY(-100vh) translate(-50%, -50%)',
                        opacity: this.state.showScheduleFollow ? '1' : '0',
                        //MA-455
                        position: 'absolute', left: '50%', top: '50%',
                    }}>
                        <div className={classes.schedule_header}>Schedule Appointment</div>
                        {this.state.scheduleFollowupState === 1 &&
                        <div className={classes.scheduleFollowCalender}>
                            {<div className={classes.slotSection}>
                                <span className={classes.slotSectionHeaderText}>Select a time slot</span>
                                <div
                                    className={this.state.selectedDuration !== 15 ? classes.slotSectionButton : classes.selectedSlotSectionButton}
                                    onClick={() => {
                                        this.setState({selectedDuration: 15, scheuledDate: ''});
                                        this.props.fetchTimeSlots(this.state.selectedDoctorFinal, this.state.selectedRow, this.state.selectedPatient, this.state.selectedDay.format('YYYY MM DD'), 15);
                                    }}>15 minutes
                                </div>
                                <div
                                    className={this.state.selectedDuration !== 30 ? classes.slotSectionButton : classes.selectedSlotSectionButton}
                                    onClick={() => {
                                        this.setState({selectedDuration: 30, scheuledDate: ''});
                                        this.props.fetchTimeSlots(this.state.selectedDoctorFinal, this.state.selectedRow, this.state.selectedPatient, this.state.selectedDay.format('YYYY MM DD'), 30);
                                    }}>30 minutes
                                </div>
                                <div
                                    className={this.state.selectedDuration !== 45 ? classes.slotSectionButton : classes.selectedSlotSectionButton}
                                    onClick={() => {
                                        this.setState({selectedDuration: 45, scheuledDate: ''});
                                        this.props.fetchTimeSlots(this.state.selectedDoctorFinal, this.state.selectedRow, this.state.selectedPatient, this.state.selectedDay.format('YYYY MM DD'), 45);
                                    }}>45 minutes
                                </div>
                                <div
                                    className={this.state.selectedDuration !== 60 ? classes.slotSectionButton : classes.selectedSlotSectionButton}
                                    onClick={() => {
                                        this.setState({selectedDuration: 60, scheuledDate: ''});
                                        this.props.fetchTimeSlots(this.state.selectedDoctorFinal, this.state.selectedRow, this.state.selectedPatient, this.state.selectedDay.format('YYYY MM DD'), 60);
                                    }}>60 minutes
                                </div>
                            </div>
                            }
                            <div className={classes.calenderSection}>
                                {this.state.selectedDuration !== 0 &&
                                <Calendar
                                    resetDate={this.state.resetDate}
                                    resetDateValue={this.state.resetDateValue}
                                    resetUpdate={() => {
                                        this.setState({resetDate: false})
                                    }}
                                    shouldHighlightWeekends
                                    dateChanged={(date) => this.onDateChange(date)}
                                />
                                }
                            </div>
                            {this.state.selectedDuration !== 0 && <div className={classes.timeSlotsSection}>
                                <span
                                    className={classes.timeSlotDate}>{this.state.selectedDay.format('dddd, MMMM DD')}</span>

                                <div className={classes.timeslotsScroll}>
                                    {console.log(!this.props.slots_loading, slots, this.state.selectedPatient, 'slots check')}
                                    {Boolean(slots) && !this.props.slots_loading && slots}
                                    {this.props.slots_loading && <div className={classes.loading_loader_sm}/>}
                                </div>
                            </div>}
                        </div>}
                        {this.state.scheduleFollowupState === 0 &&
                        <div className={classes.scheduleDoctorSection}>
                            <span
                                className={classes.scheduleDoctorText}>Select a doctor to schedule an appointment</span>
                            <div className={classes.doctorRadioButtonsRow}>
                                {console.log("this.props.clinical_doctors --->", this.props.clinical_doctors)}
                                {console.log("doctors --->", doctors)}


                                {doctors}

                            </div>

                        </div>}
                        {this.state.showFollowUpError &&
                        <div className={classes.scheduleErrorText}>{this.state.showFollowUpErrorText}</div>}

                        <div className={classes.scheduleButtonView}>
                            {!this.state.bookingInProgress && <div className={classes.saveButton}
                                                                   onClick={this.sendFollowUp}>{this.state.scheduleFollowupState === 1 ? 'Schedule Appointment' : 'Continue'}</div>}
                            {this.state.bookingInProgress && <div className={classes.saveButton}>
                                <div className={classes.loading_loader_sm}/>
                            </div>}
                            <div className={classes.cancelButton} onClick={this.clearFollowUpForm}>Cancel</div>
                        </div>
                    </div>

                    <div className={classes.main}>
                        <ClinicalConversationList
                            {...this.props}
                            admin={this.props.staffData.accountType === 'admin'}
                            conversations={this.props.clinical_conversations}
                            searchFirebaseId={(id, followUp_id, index, patientFirebaseId) => {
                                this.searchFirebaseId(id, followUp_id, index, patientFirebaseId)
                            }}
                            allFollowUp={this.state.allFollowUp}
                            allTime={this.props.all_time}
                            chatCounts={this.props.clinicalChatCounts}
                            unreadConvoCounts={this.props.unreadClinicalConvoCounts}
                            selectedConversation={this.props.conversationId}
                            chatHistoryItemClick={this.state.chatHistoryItemClick}
                            showMessages={(chatData) => this.showMessages(chatData)}
                            removedFromQueueAndshowMessages={(chatData) => this.removedFromQueueAndshowMessages(chatData)}
                        />
                        {this.state.minimizeVideo === true &&
                        <Fragment>
                            <ChatScreen
                                patientId={this.props.patientId}
                                staffData={staffData}
                                loading_messages={this.props.loading_messages}
                                doctorIsValid={this.props.doctorIsValid}
                                adminIsValid={this.props.admin}
                                closeChat={() => this.closeChat()}
                                transferChat={() => this.transferChat()}
                                videoState={this.state.videoState}
                                videoChat={() => this.showMediaModalPress}
                                audioChat={() => this.onAudioPress}
                                audioState={this.state.audioState}
                                minimizeVideo={this.state.minimizeVideo}
                                maximizeVideo={() => this.maximizeVideo}
                                open={this.state.showTagsListModal}
                                tags={this.props.tags}
                                showCannedResponseList={this.state.showCannedResponseList}
                                canned_responses={this.state.chats_canned_responses}
                                selectedCannedResponseText={this.state.selectedCannedResponseText}
                                selectedCannedResponseShortCut={this.state.selectedCannedResponseShortCut}
                                onCannedResponseClick={(uid, short_cut, text) => this.onCannedResponseClick(uid, short_cut, text)}
                                onCannedResponseShow={() => this.onCannedResponseShow()}
                                selectedCannedResponse={(textareaRef, text, short_cut) => this.selectedCannedResponse(textareaRef, text, short_cut)}
                                onTagClick={(name, id, uid) => this.onTagClick(name, id, uid)}
                                openTagListModal={() => this.openTagListModal()}
                                selectedTags={this.props.tagChatList}
                                onRemoveTagClick={(name, id, uid) => this.onRemoveTagClick(name, id, uid)}
                                messages={this.state.chatHistoryItemClick === false ? this.props.messages : this.props.chatHistory[this.state.chatHistoryUid].messages}
                                patientName={this.props.patientName}
                                doctorName={this.props.doctorName}
                                doctorImage={this.props.doctorImage}
                                inputBarText={this.props.inputBarText}
                                onChangeText={(event) => this.onChangeText(event)}
                                onsendmessage={(textareaRef) => this.onsendmessage(textareaRef, this.props.patientId, this.props.doctorName)}
                                inputElement={createRef()}
                                textareaElement={createRef()}
                                onAttachmentClick={(file_input) => this.onAttachmentClick(file_input)}
                                _handleImageChange={(e) => this._handleImageChange(e)}
                                convoCount={this.props.convoCount}
                                city={this.props.city}
                                country={this.props.country}
                                complete_address={this.props.complete_address}
                                user_id={this.props.user_id}
                                platform={this.props.platform}
                                email={this.props.email}
                                gmtOffset={this.props.gmtOffset}
                                medication={this.props.medication}
                                symptoms={this.state.chatHistoryItemClick === false ? this.props.symptoms : this.state.chatHistoryCategory}
                                gender={this.props.gender}
                                DOB={this.props.DOB}
                                buildNumber={this.props.buildNumber}
                                acceptingChat={this.props.acceptingChat}
                                heartRate={this.props.heartRate}
                                weightKg={this.props.weightKg}
                                weightLbs={this.props.weightLbs}
                                stepCount={this.props.stepCount}
                                runningWalking={this.props.runningWalking}
                                heightCm={this.props.heightCm}
                                currentSleep={this.props.currentSleep}
                                averageSleep={this.props.averageSleep}
                                heightFeet={this.props.heightFeet}
                                periodStartDate={this.props.periodStartDate}
                                flowType={this.props.flowType}
                                restingHeartRate={this.props.restingHeartRate}
                                patient_phone_number={this.props.patient_phone_number}
                            />
                        </Fragment>
                        }
                        <div className={this.state.minimizeVideo ? classes.mainDivVideoFalse : classes.mainDivVideo}>
                            <div className={classes.list_header1}>
                                <div className={this.state.minimizeVideo ? classes.msg_header : classes.msg_header1}>
                                    <div className={classes.scrollable}>
                                        <div className={classes.info_row}>
                                            <div className={classes.col_4}><span
                                                className={classes.header_attr}>Patient:</span> <span
                                                className={classes.header_attr_value}> {this.props.mediaName}</span>
                                            </div>
                                            <div className={classes.col_4}><span
                                                className={classes.header_attr}>D.O.B:</span> <span
                                                className={classes.header_attr_value}> {this.props.mediaDob}</span>
                                            </div>
                                            <div className={classes.col_4}><span
                                                className={classes.header_attr}>Gender:</span> <span
                                                className={classes.header_attr_value}> {this.props.mediaGender}</span>
                                            </div>
                                        </div>
                                        <div className={classes.info_row}>
                                            <div className={classes.col_6}><span
                                                className={classes.header_attr}>Issue:</span> <span
                                                className={classes.header_attr_value}>{this.props.mediaIssue}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{backgroundColor: 'white', height: 10}}></div>
                            <div style={{backgroundColor: 'black', marginTop: 10}}>
                                {Object.keys(this.state.remoteStreams).length === 0 && this.state.minimizeVideo === false &&
                                <Fragment>

                                    <div className={this.state.type === 'Audio' ? classes.spinner1 : classes.spinner}>
                                        <Spinner/>
                                    </div>
                                    <div className={classes.requestDiv}>Requesting patient to connect</div>
                                    <Fragment>
                                        <div
                                            className={this.state.type === 'Video' ? classes.videoHeader22 : classes.videoHeader2}>
                                            <div onClick={() => this.setState({minimizeVideo: true})}>
                                                <svg fill="white" width="15px" height="11px" viewBox="0 0 12 8">
                                                    <path fill="inherit" fillRule="evenodd"
                                                          d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                                </svg>
                                            </div>
                                            <div
                                                onClick={() => this.state.videoState === true ? this.toggleAudio() : this.toggleAudioChat()}>
                                                {this.state.audMute === false &&
                                                <FaMicrophone className={classes.microphone}/>
                                                }
                                                {this.state.audMute === true &&
                                                <FaMicrophoneSlash className={classes.microphone}/>
                                                }
                                            </div>
                                            <div
                                                onClick={() => this.state.videoState === true ? this.stopVideoCall() : this.stopAudioCall()}>
                                                <div onClick={() => this.setState({minimizeVideo: true})}>
                                                    <FaPhoneSlash className={classes.microphone}/>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                </Fragment>
                                }

                                <Fragment>
                                    <div
                                        className={this.state.minimizeVideo ? classes.minimizeVideo : Object.keys(this.state.remoteStreams).length == 0 ? classes.minimizeVideo : classes.mainDiv}>
                                        {Object.keys(this.state.remoteStreams).map((key, index) => {
                                            let stream = this.state.remoteStreams[key];
                                            let streamId = stream.getId();
                                            console.log('multi-streams', (this.state.remoteStreams), Object.keys(this.state.remoteStreams).length, index)
                                            return Object.keys(this.state.remoteStreams).length - 1 === index ? (
                                                <div key={streamId}
                                                     id={`agora_remote ${streamId}`}
                                                     className={this.state.minimizeVideo ? classes.minimizeVideo : classes.div1}>
                                                    {this.state.type === 'Audio' &&
                                                    <div style={{
                                                        height: 0,
                                                        width: 0,
                                                        borderRadius: 100,
                                                        backgroundColor: this.state.showPulse === true ? 'black' : 'black',
                                                        marginLeft: 200,
                                                        paddingTop: 1,
                                                        marginTop: 100
                                                    }}>
                                                        <div style={{
                                                            height: 150,
                                                            width: 150,
                                                            borderRadius: 80,
                                                            backgroundColor: 'green',
                                                            alignSelf: 'center',
                                                            top: 60,
                                                            marginTop: 130,
                                                            marginLeft: 16,
                                                            marginTop: 14
                                                        }}>
                                                            <span style={{
                                                                color: 'white',
                                                                display: 'flex',
                                                                paddingTop: 30,
                                                                paddingLeft: 50,
                                                                fontSize: 70
                                                            }}>{this.state.patientInitial.charAt(0)}</span>
                                                        </div>
                                                        {this.state.showPulse === true &&
                                                        <div style={{
                                                            marginTop: 10,
                                                            marginLeft: 5,
                                                            color: 'white',
                                                            width: 200
                                                        }}>{this.state.patientInitial} is talking</div>
                                                        }
                                                    </div>
                                                    }
                                                </div>
                                            ) : null
                                        })}

                                        <div id="agora_local"
                                             className={this.state.minimizeVideo ? classes.minimizeVideo : Object.keys(this.state.remoteStreams).length == 0 ? classes.minimizeVideo : classes.div2}></div>
                                        {Object.keys(this.state.remoteStreams).length !== 0 && this.state.minimizeVideo === false &&

                                        <Fragment>
                                            <div
                                                className={this.state.minimizeVideo ? classes.minimizeVideo : Object.keys(this.state.remoteStreams).length == 0 ? classes.minimizeVideo : this.state.type === 'Video' ? classes.videoHeader11 : classes.videoHeader23}>
                                                <div onClick={() => this.setState({minimizeVideo: true})}>
                                                    <svg fill="white" width="15px" height="11px" viewBox="0 0 12 8">
                                                        <path fill="inherit" fillRule="evenodd"
                                                              d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                                    </svg>
                                                </div>
                                                <div
                                                    onClick={() => this.state.videoState === true ? this.toggleAudio() : this.toggleAudioChat()}>
                                                    {this.state.audMute === false &&
                                                    <FaMicrophone className={classes.microphone}/>
                                                    }
                                                    {this.state.audMute === true &&
                                                    <FaMicrophoneSlash className={classes.microphone}/>
                                                    }
                                                </div>
                                                <div
                                                    onClick={() => this.state.videoState === true ? this.stopVideoCall() : this.stopAudioCall()}>
                                                    <div onClick={() => this.setState({minimizeVideo: true})}>
                                                        <FaPhoneSlash className={classes.microphone}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>

                                        }
                                    </div>
                                </Fragment>
                            </div>
                            <div style={this.state.type === 'Video' ? {
                                height: '8%',
                                backgroundColor: 'black'
                            } : {height: '8%', backgroundColor: 'black'}}></div>
                        </div>

                        <ClinicalPatientDetail
                            {...this.props}
                            childRef={ref => (this.userDetail = ref)}
                            prev_chats={this.props.chatHistory}
                            selectedConversation={this.props.conversationId}
                            onSendFollowUpRequest={(diffDays, timeString, dateString) => this.onSendFollowUpRequest(diffDays, timeString, dateString)}
                            skipStatus={() => this.skipStatus()}
                            followStatus={(dateTime, diffDays, timeString, dateString) => this.followStatus(dateTime, diffDays, timeString, dateString)}
                            showUpView={this.state.showUpView}
                            doctorIsValid={this.props.doctorIsValid}
                            isLoading={this.props.isLoading}
                            videoChat={() => this.showMediaModalPress()}
                            maximizeVideo={() => this.maximizeVideo()}
                            videoState={this.state.videoState}
                            audioState={this.state.audioState}
                            minimizeVideo={this.state.minimizeVideo}
                            email={this.props.email}
                            DOB={this.props.DOB}
                            followUpSaveSuccess={() => this.followUpSaveSuccess()}
                            radioButtonChange={(value) => this.onRadioButtonChange(value)}
                            patient_profile={this.props.patientProfile}
                            closeChat={() => this.closeChat()}
                            showHistoryMessages={(uid) => this.showHistoryMessages(uid)}
                            heartRate={this.props.heartRate}
                            weightKg={this.props.weightKg}
                            weightLbs={this.props.weightLbs}
                            stepCount={this.props.stepCount}
                            runningWalking={this.props.runningWalking}
                            heightCm={this.props.heightCm}
                            currentSleep={this.props.currentSleep}
                            averageSleep={this.props.averageSleep}
                            patientName={this.props.patientName}
                            heightFeet={this.props.heightFeet}
                            periodStartDate={this.props.periodStartDate}
                            flowType={this.props.flowType}
                            restingHeartRate={this.props.restingHeartRate}
                            historyMessage={(historyIndex, category, issue) => this.onChatHistoryItemClick(historyIndex, category, issue)}
                            loadCurrentChat={this.loadCurrentChat}
                            setFlagTrue={this.props.setFlagTrue}
                            setFlagFalse={this.props.setFlagFalse}
                            unReadCountObject={selectedconvoCount}
                            user_id={this.props.user_id}
                            patientId={this.props.patientId}
                            patientFirebaseID={this.props.patientFirebaseID}
                            healthInsuranceCardFront={this.props.healthInsuranceCardFront}
                            healthInsuranceCardBack={this.props.healthInsuranceCardBack}
                            healthInsuranceCardNumber={this.props.healthInsuranceCardNumber}
                            complete_address={this.props.complete_address}
                            convoCount={this.props.convoCount}
                            gmtOffset={this.props.gmtOffset}
                            platform={this.props.platform}
                            buildNumber={this.props.buildNumber}
                            plan={this.props.plan}
                            subjective_symptoms={this.props.subjective_symptoms}
                            findings={this.props.findings}
                            assessment={this.props.assessment}
                            canned_responses={this.state.canned_responses}
                            canned_responses_assessment={this.props.clinic_canned_responses_assessment}
                            canned_responses_plan={this.props.clinic_canned_responses_plan}
                            setSubjectiveSymptoms={this.props.clinic_setSubjectiveSymptoms}
                            setFindings={this.props.clinic_setFindings}
                            setAssessment={this.props.clinic_setAssessment}
                            setPlan={this.props.clinic_setPlan}
                            showSOAPNotes={this.state.showSOAPNotes}
                            setShowScheduleFollow={(status) => this.setState({showScheduleFollow: status})}
                            followUpStatus={this.props.followUpStatus}
                            followUpDate={Boolean(this.state.scheuledDate) ? this.state.scheuledDate : this.props.followupDate}
                            followUpID={(this.state.followUpID) ? this.state.followUpID : this.props.followUpID}
                            clinical_doctors={this.state.clinical_doctors}
                            scheduleSlots={this.state.scheduleSlots}
                            slots_loading={this.state.slots_loading}
                            clinicId={this.props.clinicId}
                            user_medications={this.props.user_medications}
                            update_med_time={this.props.update_med_time}
                            fetchTimeSlots={(selectedDoctorFinal, selectedRow, selectedPatient, date, selectedDuration) => this.props.fetchTimeSlots(selectedDoctorFinal, selectedRow, selectedPatient, date, selectedDuration)}
                        />

                        <OutPatientForm
                            {...this.props}
                            show={this.state.showOutPatientForm}
                            currentDoctorProfile={this.props.currentDoctorProfile}
                            hideModal={() => this.closeChatModal()}
                            followClassStatus={this.state.followClassStatus}
                            followUpStatus={this.props.followUpStatus}
                            followUpID={(this.state.followUpID) ? this.state.followUpID : this.props.followUpID}
                            followUpDate={Boolean(this.state.scheuledDate) ? this.state.scheuledDate : this.props.followupDate}
                            patientName={this.props.patientName}
                            patientDOB={this.props.DOB}
                            patient_phone_number={this.props.patient_phone_number}
                            doctorName={this.props.doctorName}
                            doctorImage={this.props.doctorImage}
                            signatureImageURL={this.props.signatureImageURL}
                            doctorlicense={this.props.doctorlicense}
                            clinicPhoneNumber={this.props.clinicPhoneNumber}
                            clinicPostalCode={this.props.postal_code}
                            clinicAddress={this.props.clinicAddress}
                            start_timestamp={Boolean(selectedconvo) ? selectedconvo.start_timestamp : null}
                            endTime={this.state.endTime}
                            isVideoCall={this.state.showSOAPNotes}
                            followUpDays={this.state.followUpDays}
                            closeChatData={{
                                "paidChat": this.props.paidChat,
                                "conversationId": this.props.conversationId,
                                "doctorID": this.props.doctorID,
                                "admin": this.props.admin,
                                "doctorName": this.props.doctorName,
                                "patientName": this.props.patientName,
                                "patientId": this.props.patientId,
                                "selectedIssue": this.props.selectedIssue,
                                "selectedCategory": this.props.selectedCategory,
                                "tagChatList": this.props.tagChatList,
                                "heartRate": this.props.heartRate,
                                "weightKg": this.props.weightKg,
                                "weightLbs": this.props.weightLbs,
                                "stepCount": this.props.stepCount,
                                "runningWalking": this.props.runningWalking,
                                "heightCm": this.props.heightCm,
                                "currentSleep": this.props.currentSleep,
                                "averageSleep": this.props.averageSleep,
                                "heightFeet": this.props.heightFeet,
                                "periodStartDate": this.props.periodStartDate,
                                "flowType": this.props.flowType,
                                "restingHeartRate": this.props.restingHeartRate,
                                "complete_address": this.props.complete_address,
                            }}
                            healthInsuranceCardFront={this.props.healthInsuranceCardFront}
                            healthInsuranceCardBack={this.props.healthInsuranceCardBack}
                            healthInsuranceCardNumber={this.props.healthInsuranceCardNumber}
                            city={this.props.city}
                            province={this.props.province}
                            gender={this.props.gender}
                            postalcode={this.props.postalcode}
                            patientAddress={this.props.complete_address + ", " + this.props.city + ", " + this.props.province + ", " + this.props.postalcode + ", " + this.props.country}
                            plan={this.props.plan}
                            subjective_symptoms={this.props.subjective_symptoms}
                            findings={this.props.findings}
                            assessment={this.props.assessment}
                            setSubjectiveSymptoms={this.props.clinic_setSubjectiveSymptoms}
                            setFindings={this.props.clinic_setFindings}
                            setAssessment={this.props.clinic_setAssessment}
                            setPlan={this.props.clinic_setPlan}
                            patient_profile={this.props.patientProfile}
                            user_medications={this.props.user_medications}
                        />

                        <Modal show={this.state.showUploadingModal} modalClosed={this.onHideAddAgentModal}>
                            <div className={classes.modal}>
                                <Header/>
                                {this.props.uploadingImage &&
                                <ProgressBar/>
                                }
                                {!this.props.uploadingImage &&
                                <div className={classes.modalcontainer}>
                                    <span className={classes.spandiv}>{this.state.file.name}</span>
                                    <button
                                        className={classes.btn}
                                        type="button"
                                        onClick={() => this.uploadImage()}
                                    >Upload
                                    </button>
                                </div>
                                }
                            </div>
                        </Modal>
                        <Modal show={this.state.showMediaModal}>
                            <div className={classes.modal}>
                                <MediaHeader/>
                                <div className={classes.modalHeader}>Please select a media chat type</div>
                                <div style={isMobileView() ? {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                } : {display: 'flex', flexDirection: 'row'}}>

                                    {/* <button
                                        className={(this.props.platform === 'android' && splitBuild > 259) || (this.props.platform === 'ios' && splitBuild > 384) ? classes.btn1 : classes.btn1a}
                                        type="button"
                                        onClick={() => this.onVideoPress()}
                                    >Video Conference</button> */}

                                    {((this.props.platform === 'android' && splitBuild > 259) || (this.props.platform === 'ios' && splitBuild > 384)) &&
                                    <button
                                        className={classes.btn2}
                                        type="button"
                                        onClick={() => this.onAudioPress()}
                                    >Audio Only</button>
                                    }

                                    <button
                                        className={(this.props.platform === 'android' && splitBuild > 259) || (this.props.platform === 'ios' && splitBuild > 384) ? classes.btn3 : classes.btn3a}
                                        type="button"
                                        onClick={() => this.cancelMediaModal()}
                                    >Cancel
                                    </button>

                                </div>
                                {((this.props.platform === 'android' && splitBuild <= 259) || (this.props.platform === 'ios' && splitBuild <= 384)) &&
                                <div className={classes.error}>Audio Chat is not available for this patient as the
                                    patient is on an older version of the app.</div>
                                }
                            </div>
                        </Modal>
                    </div>
                    <MyVerticallyCenteredModal
                        show={showModal}
                        modalHeaderText={modalHeaderText}
                        modalBodyText={modalBodyText}
                        onHide={() => this.onHideModal(!showModal)}
                    />
                    <TransferChatModal
                        show={showOnlineDoctors}
                        onlineDoctors={this.props.clinical_doctors}
                        onHide={() => this.onHideTransferChatModal(!showOnlineDoctors)}
                        onAssignClick={(status, doctor) => this.onAssignClick(status, doctor)}
                    />
                    <WorkingHours
                        show={showWorkingHoursModal}
                        onHide={() => this.onHideWorkingHoursModal(!showWorkingHoursModal)}
                        onApply={(datetime) => this.onApplyWorkingHours(datetime)}
                    />
                </Aux>
        )
    }
}

const mapStateToProps = ({
                             clinicProfileSettingReducer,
                             homeReducer,
                             clinicalChatsReducer,
                             conversationReducer,
                             acceptingReducer,
                             chatReducer,
                             doctorReducer,
                             tagsReducer,
                             clinicalcannedResponseReducer,
                             reportReducer,
                             clinicalPatientsReducer
                         }) => {

    const {
        clinicId, staffData, clinic_name, full_name,
        userType, userProfile, profile_image, signature, postal_code, country: clinicCountry
    } = clinicProfileSettingReducer;
    const {clinical_doctors} = reportReducer;
    const {scheduleSlots, slots_loading} = clinicalPatientsReducer;

    const {admin} = homeReducer;
    const {
        loading,
        clinical_conversations,
        clinicalChatCounts,
        unreadClinicalConvoCounts,
        videoChatFlag
    } = clinicalChatsReducer;
    const {
        newMessage,
        deletedConversationKey,
        patientId,
        doctorIsValid,
        conversationId,
        paidChat,
        tagChatList,
        transfer_doctors,
        changedConversation,
        selectedIssue,
        selectedCategory,
        videoChatDeclineStatus,
        declinedDoctorId,
        videoStatus,
        remoteStreams,
        videoChatId,
        all_followUp,
        all_time,
        updateFollowUp,
        mediaName,
        mediaDob,
        mediaGender,
        mediaIssue,
        user_medications,
    } = conversationReducer;

    const {acceptingChat, acceptingChatTime, changedDoctorKey} = acceptingReducer;
    const {
        loading_messages,
        messages,
        inputBarText,
        location,
        patientName,
        convoCount,
        buildNumber,
        city,
        province,
        postalcode,
        averageSleep,
        heartRate,
        heightFeet,
        currentSleep,
        weightKg,
        weightLbs,
        stepCount,
        runningWalking,
        heightCm,
        country,
        gmtOffset,
        DOB,
        email,
        medication,
        symptoms,
        gender,
        platform,
        doctorID,
        doctorName,
        doctorImage,
        currentUserID,
        uploadingImage,
        chatHistory,
        restingHeartRate,
        periodStartDate,
        flowType,
        complete_address,
        user_id,
        healthInsuranceCardFront,
        healthInsuranceCardBack,
        healthInsuranceCardNumber,
        patientFirebaseID,
        clinicPhoneNumber,
        clinicFaxNumber,
        clinicProvince,
        signatureImageURL,
        doctorlicense,
        clinicAddress,
        patientProfile,
        update_med_time,
        clinicPostalCode,
        currentDoctorProfile,
        patient_phone_number,
        followupId,
        pharmacy
    } = chatReducer;
    const {doctorDetail} = doctorReducer;
    const {tags, tag_value, followUpStatus, skipStatus, followupDate, followUpID, isLoading} = tagsReducer;
    const {
        clinic_canned_responses, clinic_chats_canned_responses, clinic_canned_responses_assessment,
        clinic_canned_responses_plan, subjective_symptoms, findings, assessment, plan
    } = clinicalcannedResponseReducer;

    return {
        clinicId,
        staffData,
        clinic_name,
        full_name,
        userType,
        userProfile,
        profile_image,
        signature,
        videoChatFlag,
        loading,
        clinical_conversations,
        clinicalChatCounts,
        newMessage,
        deletedConversationKey,
        patientId,
        updateFollowUp,
        doctorIsValid,
        conversationId,
        videoChatId,
        paidChat,
        tagChatList,
        transfer_doctors,
        admin,
        all_followUp,
        all_time,
        changedConversation,
        selectedIssue,
        selectedCategory,
        acceptingChat,
        acceptingChatTime,
        isLoading,
        changedDoctorKey,
        mediaName,
        mediaDob,
        mediaGender,
        mediaIssue,
        loading_messages,
        messages,
        inputBarText,
        location,
        patientName,
        convoCount,
        buildNumber,
        city,
        averageSleep,
        heartRate,
        heightFeet,
        currentSleep,
        weightKg,
        weightLbs,
        stepCount,
        runningWalking,
        heightCm,
        country,
        province,
        postalcode,
        gmtOffset,
        DOB,
        email,
        medication,
        symptoms,
        gender,
        platform,
        doctorID,
        doctorName,
        doctorImage,
        patientProfile,
        currentUserID,
        uploadingImage,
        chatHistory,
        restingHeartRate,
        periodStartDate,
        flowType,
        doctorDetail,
        tags,
        tag_value,
        clinic_chats_canned_responses,
        clinic_canned_responses,
        clinic_canned_responses_assessment,
        clinic_canned_responses_plan,
        videoChatDeclineStatus,
        declinedDoctorId,
        videoStatus,
        remoteStreams,
        complete_address,
        user_id,
        healthInsuranceCardFront,
        healthInsuranceCardBack,
        healthInsuranceCardNumber,
        patientFirebaseID,
        unreadClinicalConvoCounts,
        followUpStatus,
        skipStatus,
        followupDate,
        followUpID,
        clinicPhoneNumber,
        clinicFaxNumber,
        clinicProvince,
        clinicPostalCode,
        signatureImageURL,
        doctorlicense,
        clinicAddress,
        subjective_symptoms,
        findings,
        assessment,
        plan,
        clinical_doctors,
        scheduleSlots,
        slots_loading,
        user_medications,
        update_med_time,
        currentDoctorProfile,
        patient_phone_number,
        postal_code,
        clinicCountry,
        followupId,
        pharmacy
    };
};

export default connect(mapStateToProps, {
    fetchDoctorDetails,
    fetchTags,
    childChanged,
    childChangedDoctor,
    setVideoId,
    get_followUp,
    clinic_fetchCannedResponsesForChats,
    clinic_fetchCannedResponsesForObjectiveFindings,
    clinic_fetchCannedResponsesForAssessment,
    clinic_fetchCannedResponsesForPlan,
    fetchAllMessagesForClinicChats,
    getAcceptStatus,
    checkStatusAccept,
    refreshMessages,
    fetchClinicalUnReadCount,
    setMediaHeader,
    refreshClinicalConversations,
    fetchClinicalChats,
    fetchClinicalConversationCount,
    setSelectedItem,
    findPatientDetail,
    turnSelectChat,
    changeQueueStatusForClinicalChats,
    setFlagTrue,
    setFlagFalse,
    sendDoctorFirstMessage,
    onChangeText,
    sendMessage,
    makeUnreadAllMessages,
    imageData,
    fetchUserChatHistory,
    fetchChatTags,
    saveTagInChat,
    removeTagInChat,
    setFollowUpStatus,
    setSkipStatus,
    save_followUp,
    fetchStatus,
    closeClinicalChatByAdmin,
    transferClinicalChatToYDOByAdmin,
    setVideoState,
    endVideoCall,
    setVideoStatusFalse,
    setVideoStatusTrue,
    saveRemoteStream,
    refreshRemoteStream,
    refreshDeclineStatus,
    setVideo,
    clinic_setSubjectiveSymptoms,
    clinic_setFindings,
    clinic_setAssessment,
    clinic_setPlan,
    set_notification,
    setAlgolia,
    sendInvites,
    sendUnFilledInvites,
    fetchRegisteredPatients,
    updatePatient,
    deletePatients,
    updateRegistrationStatus,
    fetchTimeSlots,
    storeSlot,
    removeClinicalPatientsListener,
    fetchClinicalDoctors,
    resetSelectedConversationId,
    transferChatToAnotherClinicDoctor,
    changeAcceptStatus,
    fetchChatPrescriptions,
    sendNewMessageNotification,
    logoutClinicMember
})(ClinicalDoctors);
