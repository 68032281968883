import {
    ATTACHMENT_UPLOAD_FAIL,
    ATTACHMENT_UPLOAD_PROGRESS,
    ATTACHMENT_UPLOAD_SUCCESS,
    CHANGE_TEXT,
    CLINICAL_HEALTH_INSURANCE_CARD_UPDATED,
    FETCH_CLINIC_MESSAGES,
    FETCH_USER_CHAT_HISTORY,
    FETCHING_CLINIC_MESSAGES,
    FETCHING_MESSAGES,
    FLAG_FALSE,
    FLAG_TRUE,
    MESSAGE_FETCH_FAILS,
    MESSAGES_FETCH_SUCCESS,
    REFRESH_MESSAGES,
    SELECTED_CHAT_PATIENT_DETAIL,
    SENDING_CLINIC_MESSAGE,
    SENDING_MESSAGE
} from '../constants';

const INITIAL_STATE = {
    location: '',
    messages: [],
    loading_messages: false,
    inputBarText: '',
    country: "",
    city: "",
    province: "",
    postalcode: "",
    platform: "",
    email: "",
    gmtOffset: "",
    convoCount: "",
    buildNumber: "",
    medication: "",
    symptoms: "",
    gender: "",
    DOB: "",
    name: "",
    onlineAgent: null,
    patientName: '',
    doctorID: null,
    doctorName: '',
    doctorImage: null,
    currentUserID: null,
    uploadingImage: false,
    chatHistory: [],
    showNotification: false,
    heartRate: '',
    weightKg: '',
    weightLbs: '',
    stepCount: '',
    runningWalking: '',
    heightCm: '',
    currentSleep: '',
    heightFeet: '',
    periodStartDate: '',
    flowType: '',
    restingHeartRate: '',
    flag: false,
    complete_address: '',
    user_id: '',
    patientFirebaseID: null,
    healthInsuranceCardFront: null,
    healthInsuranceCardBack: null,
    healthInsuranceCardNumber: null,
    doctorlicense: '',
    currentDoctorProfile: {},
    clinicAddress: '',
    clinicPhoneNumber: '',
    clinicFaxNumber:"",
    clinicPostalCode: '',
    signatureImageURL: '',
    patientProfile: {},
    patient_phone_number: '',
    pharmacy: '',
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REFRESH_MESSAGES:
            return INITIAL_STATE;
        case FETCHING_MESSAGES:
            return {...state, loading_messages: true, messages: []};
        case MESSAGES_FETCH_SUCCESS:
            console.log("MESSAGES_FETCH_SUCCESS triggereds")
            return {
                ...state,
                //messages: [...state.messages, action.payload.messages],
                messages: action.payload.messages,
                location: action.payload.location,
                doctorID: action.payload.doctorId,
                doctorName: action.payload.doctorName,
                doctorlicense: action.payload.doctorlicense,
                clinicAddress: action.payload.clinicAddress,
                clinicPhoneNumber: action.payload.clinicPhoneNumber,
                clinicFaxNumber: action.payload.clinicFaxNumber,
                clinicProvince: action.payload.clinicProvince,
                clinicPostalCode: action.payload.clinicPostalCode,
                signatureImageURL: action.payload.signatureImageURL,
                doctorImage: action.payload.doctorImage !== undefined ? action.payload.doctorImage : 'https://firebasestorage.googleapis.com/v0/b/yourdoctorsonline-staging.appspot.com/o/DoctorsProfileImage%2Fdownload.png?alt=media&token=cf3b29d0-a67e-430b-b317-c0b7002ce098',
                currentUserID: action.payload.currentUserId,
                loading_messages: false,
                showNotification: action.payload.showNotification
            };
        case MESSAGE_FETCH_FAILS:
            return {...state, messages: [], loading_messages: false};
        case SELECTED_CHAT_PATIENT_DETAIL:
            console.log("findPatientDetail SELECTED_CHAT_PATIENT_DETAIL action.payload ", action.payload)
            return {
                ...state,
                patientFirebaseID: action.payload.patientFirebaseID,
                patient_phone_number: action.payload.patient_phone_number,
                patientName: action.payload.patientName,
                convoCount: action.payload.convoCount,
                city: action.payload.city,
                country: action.payload.country,
                postalcode: action.payload.postalcode,
                province: action.payload.province,
                complete_address: action.payload.complete_address,
                user_id: action.payload.user_id,
                platform: action.payload.platform,
                email: action.payload.email,
                gmtOffset: action.payload.gmtOffset,
                medication: action.payload.medication,
                symptoms: action.payload.symptoms,
                gender: action.payload.gender,
                DOB: action.payload.DOB,
                buildNumber: action.payload.buildNumber,
                heartRate: action.payload.heartRate,
                weightKg: action.payload.weightKg === 'NA' ? 'NA' : action.payload.weightKg === '' ? 'NA' : parseInt(action.payload.weightKg),
                weightLbs: action.payload.weightLbs,
                stepCount: action.payload.stepCount,
                runningWalking: action.payload.runningWalking === 'NA' ? 'NA' : action.payload.runningWalking === '' ? 'NA' : parseFloat(action.payload.runningWalking),
                heightCm: action.payload.heightCm === 'NA' ? 'NA' : action.payload.heightCm === '' ? 'NA' : parseInt(action.payload.heightCm),
                currentSleep: action.payload.currentSleep,
                averageSleep: action.payload.averageSleep,
                heightFeet: action.payload.heightFeet,
                periodStartDate: action.payload.periodStartDate,
                flowType: action.payload.flowType,
                restingHeartRate: action.payload.restingHeartRate,
                healthInsuranceCardFront: action.payload.healthInsuranceCardFront,
                healthInsuranceCardBack: action.payload.healthInsuranceCardBack,
                healthInsuranceCardNumber: action.payload.healthInsuranceCardNumber,
                patientProfile: action.payload.patientProfile,
                followupId: action.payload.followupId,
                pharmacy: action.payload.pharmacy

            }
        case CLINICAL_HEALTH_INSURANCE_CARD_UPDATED:
            return {
                ...state,
                healthInsuranceCardFront: action.payload.healthInsuranceCardFront,
                healthInsuranceCardBack: action.payload.healthInsuranceCardBack,
                healthInsuranceCardNumber: action.payload.healthInsuranceCardNumber,
            };
        case CHANGE_TEXT:
            return {...state, inputBarText: action.payload};
        case SENDING_MESSAGE:
            return {...state, inputBarText: ''};
        case SENDING_CLINIC_MESSAGE:
            return {...state, inputBarText: ''};
        case ATTACHMENT_UPLOAD_PROGRESS:
            return {...state, uploadingImage: true};
        case ATTACHMENT_UPLOAD_SUCCESS:
            return {...state, uploadingImage: false};
        case ATTACHMENT_UPLOAD_FAIL:
            return {...state, uploadingImage: false};
        case FETCH_USER_CHAT_HISTORY:
            return {...state, chatHistory: action.payload};
        case FLAG_TRUE:
            return {...state, flag: true};
        case FLAG_FALSE:
            return {...state, flag: false};
        case FETCHING_CLINIC_MESSAGES:
            return {...state, loading_messages: true, messages: []};
        case FETCH_CLINIC_MESSAGES:
            console.log("FETCH_CLINIC_MESSAGES triggereds")

            return {
                ...state,
                //messages: [...state.messages, action.payload.messages],
                messages: action.payload.messages,
                location: action.payload.location,
                doctorID: action.payload.doctorId,
                doctorName: action.payload.doctorName,
                doctorlicense: action.payload.doctorlicense,
                clinicAddress: action.payload.clinicAddress,
                clinicPhoneNumber: action.payload.clinicPhoneNumber,
                clinicFaxNumber: action.payload.clinicFaxNumber,
                clinicProvince: action.payload.clinicProvince,
                clinicPostalCode: action.payload.clinicPostalCode,
                signatureImageURL: action.payload.signatureImageURL,
                doctorImage: action.payload.doctorImage !== undefined ? action.payload.doctorImage : 'https://firebasestorage.googleapis.com/v0/b/yourdoctorsonline-staging.appspot.com/o/DoctorsProfileImage%2Fdownload.png?alt=media&token=cf3b29d0-a67e-430b-b317-c0b7002ce098',
                currentUserID: action.payload.currentUserId,
                loading_messages: false,
                showNotification: action.payload.showNotification,
                currentDoctorProfile: action.payload.currentDoctorProfile
            };


        default:
            return state;
    }
}
