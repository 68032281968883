import {FETCHING_ACTIVITIES_FAILS, FETCHING_ACTIVITIES_IN_PROCESS, FETCHING_ACTIVITIES_SUCCESS} from '../constants';
import _ from 'lodash';
import {database} from "../firebase";

export const fetchAllActivites = () => {
    return (dispatch) => {
        dispatch({type: FETCHING_ACTIVITIES_IN_PROCESS});
        getActivitiesRef().on('value', activitySnapShot => {
            if (activitySnapShot.val()) {
                var count = activitySnapShot.numChildren();
                var i = 0;
                var activities = [];
                activitySnapShot.forEach(function (childActivitySnapShot) {
                    if (childActivitySnapShot.val()) {
                        var childCount = childActivitySnapShot.numChildren();
                        var j = 0;
                        var childActivities = [];
                        childActivitySnapShot.forEach(function (subSnapShot) {
                            if (subSnapShot.val()) {
                                getDoctorRef(subSnapShot.key).once('value', doctorSnapShot => {
                                    if (doctorSnapShot.exists() && doctorSnapShot.val().doctorName) {
                                        var activityData = subSnapShot.val();
                                        const activity = _.map(activityData, (val, uid) => {
                                            return {...val, uid};
                                        });
                                        var doctorName = doctorSnapShot.val().doctorName;
                                        childActivities.push({childData: activity, doctorName: doctorName});
                                        j++;

                                        if (childCount === j) {
                                            activities.push({key: childActivitySnapShot.key, data: childActivities});
                                        }
                                    } else {
                                        j++;
                                    }
                                })
                            } else {
                                j++;
                            }
                        })

                        i++;
                        if (count === i) {

                            const sortedActivities = activities.sort((a, b) => new Date(b.key) - new Date(a.key))
                            dispatch({
                                type: FETCHING_ACTIVITIES_SUCCESS,
                                payload: sortedActivities
                            });
                        }
                    } else {
                        i++;
                        if (count === i) {
                            const sortedActivities = activities.sort((a, b) => new Date(b.key) - new Date(a.key))
                            dispatch({
                                type: FETCHING_ACTIVITIES_SUCCESS,
                                payload: sortedActivities
                            });
                        }
                    }
                })
            } else {
                dispatch({
                    type: FETCHING_ACTIVITIES_FAILS,
                    payload: []
                })
            }
        })
    }
}

const getActivitiesRef = () => {
    return database.ref(`/activities`);
}

const getDoctorRef = (uid) => {
    return database.ref(`/doctors/${uid}`);
}

