import Axios from "axios";
// import _classes from './_styles.css';
import React, {Component} from "react";
import styles from './callwithtwilio.module.css'

const {Device} = require("twilio-client");

class CallWithTwilio extends Component {
    constructor(props) {
        super(props);

        this.state = {
            status: "",
            ready: false,
            phoneNumber: "",
            callRinging: false,
            callConnected: false,
            calling: false,
            callPermission: false,
        };
        // this.setup = this.setup.bind(this);
    }



    // setup(event) {
    //   // prevents form submission and page refresh
    //   event.preventDefault();

    //   //     this.state.device.audio.incoming(false);
    //   //   this.state.device.audio.outgoing(false);
    //   //   this.state.device.audio.disconnect(false);
    // }

    disconnect = () => {
        this.state.device.disconnectAll();
        this.setState({
            ready: false,
            callRinging: false,
            callConnected: false,
            calling: false,
        });
        this.state.device.destroy();
    };

    deviceInitiation = async () => {
        console.log("deviceInitiation initialed")
        let device;
        this.setState({calling: true});
        await Axios.get(
            `https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/tokenGenerator?identity=${this.props.identity}`,
            {
                headers: {
                    "Access-Control-Allow-Origin": true,
                },
            }
        )
            // .then(response => response.json())
            .then((data) => {
                console.log("deviceInitiation data", data)
                device = new Device(data.data.data.token, {
                    codecPreferences: ["opus", "pcmu"],
                    fakeLocalDTMF: true,
                    enableRingingState: true,
                });

                this.setState({
                    device,
                    callPermission: true,
                });

                this.state.device.on("incoming", (connection) => {
                    // immediately accepts incoming connection
                    // connection.accept();
                    this.setState({
                        status: connection.status(),
                    });
                });

                this.state.device.on("ready", (device) => {
                    this.setState({
                        status: "device ready",
                        ready: true,
                    });
                    this.initiateCall();
                });

                this.state.device.on("error", (error) => {
                    console.log("Twilio.Device Error: " , error);
                    this.setState({
                        ready: false,
                        callRinging: false,
                        callConnected: false,
                        calling: false,
                        callPermission: false,
                    });
                });

                this.state.device.on("connect", (connection) => {
                    this.setState({
                        status: connection.status(),
                        callConnected: true,
                        callRinging: false,
                        calling: false,
                    });
                });

                this.state.device.on("disconnect", (connection) => {
                    this.setState({
                        status: connection.status(),
                        callRinging: false,
                        callConnected: false,
                        callling: false,
                        callPermission: false,
                    });
                });
            })
            .catch((err) => console.log(err));
    }

    formatPhoneNumber = (phoneNumberString) => {
        let phone = phoneNumberString.replace(/[^\d]/g, "");
        let intlCode = `+${  this.props.countryCode}`;
        if (phone.length >= 10) {
            let num = phone.substr(phone.length-10, phone.length)
            if(!this.props.countryCode){
                intlCode = '+' + phone.substr(0,phone.length-10)
            }
            console.log("formatPhoneNumber intlCode", intlCode)
            console.log("formatPhoneNumber num", num)
            return intlCode + " " + num.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
            //return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
    }

    initiateCall = () => {
        let toNumber = this.formatPhoneNumber(this.props.phoneNumber.replace(this.props.countryCode, ''));
        console.log("initiateCall toNumber ", toNumber)
        if(toNumber){
            const params = {
                To: toNumber,
            };
            try {
                if (this.state.device && this.state.callPermission) {
                    const outgoingConnection = this.state.device.connect(params);
                    outgoingConnection.on("ringing", () => {
                    });
                    this.setState({callRinging: true});
                }
            } catch (error) {
                console.log("Twilio call catch error", error);
                if(error?.message) alert(error?.message)
            }
        }else{
            this.disconnect()
            alert('incorrect phone number')
        }
        //toNumber = toNumber.replace(/[^0-9+]/g, "");
        //const numbers = toNumber.split("");

        /*if (toNumber.length === 10) {
            toNumber = `+1${toNumber}`;
        } else if (toNumber.length === 11) {
            toNumber = numbers[0] === "1" ? `+${toNumber}` : toNumber;
        }*/


    };

    render() {
        return (this.state.ready && this.state.callRinging
                ?
                (<button
                    className={styles.twilioButton}
                    style={{
                        "backgroundColor": "green",
                        "color": "white",
                    }}
                >
                    Ringing...
                </button>)
                : this.state.ready && this.state.callConnected
                    ?
                    (<button onClick={this.disconnect}
                             className={styles.twilioButton}
                             style={{
                                 "backgroundColor": "red",
                                 "color": "white",
                             }}
                    >
                        Hang Up
                    </button>)
                    :
                    this.state.calling
                        ?
                        (<button className={styles.twilioButton}>
                            Calling...
                        </button>)
                        :
                        (<button onClick={this.deviceInitiation} className={styles.twilioButton} >
                            Call Patient
                        </button>)
        );
    }
}

export default CallWithTwilio;
