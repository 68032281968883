import {
  CHAT_TRANSFERED_DONE,
  CLOSE_CHAT,
  CLOSE_CHAT_OTHER,
  CONVERSATIONS_FETCH_SUCCESS,
  ALL_CONVERSATIONS_FETCH_SUCCESS,
  CONVERSATIONS_REMOVED,
  CONVERSATIONS_FETCH_UPDATE,
  CONVERSATIONS_FETCHING,
  FETCH_CHAT_TAGS,
  FETCH_CHAT_TAGS_FAILED,
  FETCH_CHAT_TAGS_SUCCESS,
  FETCH_CONVERSATIONS_COUNT_FOR_EACH_DOCTOR,
  FETCH_DOCTORS_NAME_AND_IMAGES,
  FETCH_ONLINE_DOCTORS,
  FETCH_PRESCRIPTIONS,
  FETCH_PRESCRIPTIONS_DATA,
  FETCH_PRESCRIPTIONS_DATA_CHATCHANGED,
  FOLLOW_UP,
  FOLLOW_UP_CHANGE,
  FOLLOW_UP_REMOVAL,
  MEDIA_HEADER_DATA,
  PICKED_FROM_QUEUE,
  REFRESH_CONVERSATION,
  REFRESH_REMOTE_STREAMS,
  REFRESH_VIDEO_CHAT_DECLINED,
  RESET_CONVERSATIONID,
  SAVE_REMOTE_STREAMS,
  SELECTED_CHAT,
  TAB_CHANGE_SELECTED_CHAT,
  SET_PAYPERMINUTE,
  SET_VIDEOCHAT_ID,
  VIDEO_CHAT_DECLINED,
  VIDEO_STATE_FALSE,
  VIDEO_STATE_TRUE,
  PRESCRIPTION_ISCHANGED,
  UPDATE_CONVERSATION_MEDICATION,
  PRESCRIPTION_ISSAVED,
  DISCARD_CHANGES, UPDATE_PATIENT, UPDATE_DOCTORS, UPDATE_PATIENTS, REMOVE_PATIENT,
  SET_CHAT_HISTORY_ITEM_CLICKED, UPDATE_SELECTED_PATIENT,
  SET_CALL_STATUS, CONVERSATIONS_FETCH_SUCCESS_ON_ADDED
} from "../constants";
import _ from "lodash";

const INITIAL_STATE = {
  conversations: [],
  loading: false,
  newMessage: {},
  deletedConversationKey: null,
  patientId: null,
  doctorIsValid: null,
  conversationId: null,
  paidChat: null,
  tagChatList: [],
  transfer_doctors: [],
  admin: null,
  changedConversation: null,
  selectedIssue: null,
  selectedCategory: null,
  videoChatDeclineStatus: "",
  declinedDoctorId: "",
  videoStatus: false,
  remoteStreams: {},
  videoChatId: "",
  chatCounts: [],
  chatStartTime: null,
  all_followUp: [],
  all_time: [],
  updateFollowUp: "",
  mediaName: "",
  mediaDob: "",
  mediaGender: "",
  mediaIssue: "",
  payPerMinuteChat: false,
  prescription_data: [],
  update_med_time: new Date().getTime(),
  user_medications: [],
  doctors:{},
  patients: {},
  selectedPatient: null,
  chatHistoryItemClick: null,
  selectedConversationObj: null,
  callData: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CALL_STATUS:
      return {
        ...state,
        callData:action.payload
      };
    case UPDATE_DOCTORS:
      return {
        ...state,
        doctors:action.payload
      };
    case UPDATE_SELECTED_PATIENT:
      return {
        ...state,
        selectedPatient:action.payload
      };
    case SET_CHAT_HISTORY_ITEM_CLICKED:
      return {
        ...state,
        chatHistoryItemClick :action.payload
      };
    case UPDATE_PATIENTS:
        let patients = {...state.patients};
      patients[action.payload.patientID] = action.payload.patientObj
      return {
        ...state,
        patients
      };
    case REMOVE_PATIENT:
       let patientsRem = state.patients? {...state.patients} : {};
      let patientKeys = Object.keys(patientsRem || {});
      let patientKeyIndex = patientKeys.findIndex(key => key === action.payload)
      if(patientKeyIndex >= 0) {
        delete patientsRem[patientKeys[patientKeyIndex]];
      }
      //patients[action.payload.patientID] = action.payload.patientObj
      return {
        ...state,
        patients:patientsRem
      };
    case SELECTED_CHAT:
      return {
        ...state,
        patientId: action.payload.patientId,
        doctorIsValid: action.payload.doctorIsValid,
        conversationId: action.payload.conversationId,
        paidChat: action.payload.paidChat,
        admin: action.payload.admin,
        selectedIssue: action.payload.issue,
        selectedCategory: action.payload.category,
        chatStartTime: action.payload.chatStartTime,
        selectedConversationObj: action.payload.selectedChat
      };
    case TAB_CHANGE_SELECTED_CHAT:
      return {
        ...state,
        patientId: action.payload.patientId,
        doctorIsValid: action.payload.doctorIsValid,
        conversationId: action.payload.conversationId,
        paidChat: action.payload.paidChat,
        admin: action.payload.admin,
        selectedIssue: action.payload.issue,
        selectedCategory: action.payload.category,
        chatStartTime: action.payload.chatStartTime,
        selectedConversationObj: action.payload.selectedChat

      };
    case SET_PAYPERMINUTE:
      return {
        ...state,
        payPerMinuteChat: action.payload,
      };
    case CHAT_TRANSFERED_DONE:
      return { ...state, doctorIsValid: false };
    case MEDIA_HEADER_DATA:
      return {
        ...state,
        mediaName: action.payload.mediaName,
        mediaDob: action.payload.mediaDob,
        mediaGender: action.payload.mediaGender,
        mediaIssue: action.payload.mediaIssue,
      };
    case FETCH_CHAT_TAGS:
      return { ...state, tagChatList: [] };
    case FOLLOW_UP:
      return {
        ...state,
        all_followUp: action.payload.follow_up,
        all_time: action.payload.time,
      };
    case FOLLOW_UP_CHANGE:
      return { ...state, all_followUp: action.payload };
    case FOLLOW_UP_REMOVAL:
      return {
        ...state,
        all_followUp: action.payload.follow_up,
        all_time: action.payload.time,
        updateFollowUp: Math.random(),
      };
    case FETCH_CHAT_TAGS_SUCCESS:
      return { ...state, tagChatList: action.payload };
    case FETCH_CHAT_TAGS_FAILED:
      return { ...state, tagChatList: action.payload };
    case CONVERSATIONS_FETCHING:
      return { ...state, loading: true, conversations: [] };
    case ALL_CONVERSATIONS_FETCH_SUCCESS:
      return {
        ...state,
        conversations: action.payload,
        loading: false,
      };

    case  CONVERSATIONS_FETCH_SUCCESS_ON_ADDED:
      let conversationsADD = [...state.conversations]
      let conversationObjADD = action.payload
      let indexADD = _.findIndex(conversationsADD, {conversationId: conversationObjADD.conversationId});
      if(indexADD === -1){
        conversationsADD.push(conversationObjADD);
        let newStateADD =   { ...state,
          conversations: conversationsADD,
          loading: false }
        const selectedConversationObjADD = state.selectedConversationObj;
        if(!!selectedConversationObjADD && selectedConversationObjADD?.conversationId === conversationObjADD?.conversationId){
          newStateADD.selectedConversationObj = conversationObjADD;
        }
        return newStateADD;
      } else{
        return state;
      }

      /*const bubbles = _.map(conversations, (val, uid) => {
        return {...val, uid};
      });*/

    case CONVERSATIONS_FETCH_SUCCESS:
      let conversations = [...state.conversations]
      let conversationObj = action.payload
      let index = _.findIndex(conversations, {conversationId: conversationObj.conversationId});
      index !== -1 ? conversations.splice(index, 1, conversationObj) : conversations.push(conversationObj);
      /*const bubbles = _.map(conversations, (val, uid) => {
        return {...val, uid};
      });*/
      let newState =   { ...state,
        conversations: conversations,
        loading: false }
      const selectedConversationObj = state.selectedConversationObj;
      if(!!selectedConversationObj && selectedConversationObj?.conversationId === conversationObj?.conversationId){
        newState.selectedConversationObj = conversationObj;
      }
      return newState;
    case CONVERSATIONS_REMOVED:
      let conversationsRem = [...state.conversations]
      let indexRem = _.findIndex(conversationsRem, {conversationId: action.payload});
      if(indexRem !== -1) conversationsRem.splice(indexRem, 1)
      /*const bubbles = _.map(conversationsRem, (val, uid) => {
        return {...val, uid};
      });*/
      return { ...state,
        conversations: conversationsRem,
        loading: false };
    case CONVERSATIONS_FETCH_UPDATE:
      for (var l = state.conversations.length - 1; l >= 0; l--) {
        if (state.conversations[l].doctorID === action.payload.doctorID) {
          state.conversations[l].onlinePaidChats =
            action.payload.onlinePaidChats;
          state.conversations[l].onlineFreeChats =
            action.payload.onlineFreeChats;
          state.conversations[l].totalChatLimit = action.payload.totalChatLimit;
        }
      }
      return { ...state, conversations: [...state.conversations] };
    case CLOSE_CHAT:
      return {
        ...state,
        deletedConversationKey: action.payload.conversationId,
        admin: false,
      };
    case CLOSE_CHAT_OTHER:
      return { ...state, changedConversation: action.payload, admin: false };
    case PICKED_FROM_QUEUE:
      for (var j = state.conversations.length - 1; j >= 0; j--) {
        if (state.conversations[j].conversationId === action.payload) {
          state.conversations[j].chatInQueue = false;
          return { ...state, conversations: [...state.conversations] };
        }
      }
      return { ...state };
    case REFRESH_CONVERSATION:
      return INITIAL_STATE;
    case FETCH_ONLINE_DOCTORS:
      return { ...state, transfer_doctors: action.payload };
    case VIDEO_CHAT_DECLINED:
      return {
        ...state,
        videoChatDeclineStatus: true,
        declinedDoctorId: action.payload,
      };
    case REFRESH_VIDEO_CHAT_DECLINED:
      return { ...state, videoChatDeclineStatus: "" };
    case VIDEO_STATE_TRUE:
      return { ...state, videoStatus: true };
    case VIDEO_STATE_FALSE:
      return { ...state, videoStatus: false };
    case REFRESH_REMOTE_STREAMS:
      return { ...state, remoteStreams: {} };
    case SET_VIDEOCHAT_ID:
      return { ...state, videoChatId: action.payload };
    case SAVE_REMOTE_STREAMS:
      return { ...state, remoteStreams: action.payload };
    case FETCH_CONVERSATIONS_COUNT_FOR_EACH_DOCTOR:
      return { ...state, chatCounts: action.payload };
    case FETCH_DOCTORS_NAME_AND_IMAGES:
      return { ...state, doctorsNameAndImages: action.payload };
    case RESET_CONVERSATIONID:
      return { ...state, conversationId: null, selectedConversationObj: null };
    case FETCH_PRESCRIPTIONS:
      if (!state.user_medications[action.payload.conversationID]?.isChanged) {
        let user_medications = { ...state.user_medications };
        let conversation_data = user_medications[action.payload.conversationID] || {}
        conversation_data.data = action.payload.data
        conversation_data.isChanged = false
        user_medications[action.payload.conversationID] = conversation_data
        return {
          ...state,
          user_medications: user_medications,
          update_med_time: new Date().getTime(),
        };
      } else {
        return state;
      }
    case DISCARD_CHANGES:
      if (!!state.user_medications[action.payload.conversationID]?.isChanged) {
        let user_medications = { ...state.user_medications };
        let conversation_data = user_medications[action.payload.conversationID] || {}
        conversation_data.data = action.payload.data
        conversation_data.isChanged = false
        user_medications[action.payload.conversationID] = conversation_data
        return {
          ...state,
          user_medications: user_medications,
          update_med_time: new Date().getTime(),
        };
      } else {
        return state;
      }
    case PRESCRIPTION_ISCHANGED:
      if (!state.user_medications[action.payload.conversationID]?.isChanged) {
        let user_medications = { ...state.user_medications };
        let conversation_data = user_medications[action.payload.conversationID] || {}
        conversation_data.isChanged = true
        user_medications[action.payload.conversationID] = conversation_data
        return {
          ...state,
          user_medications: user_medications
        };
      } else {
        return state;
      }
    case PRESCRIPTION_ISSAVED:
    if (state.user_medications[action.payload.conversationID]?.isChanged) {
      let user_medications = { ...state.user_medications };
      let conversation_data = user_medications[action.payload.conversationID] || {}
      conversation_data.isChanged = false
      user_medications[action.payload.conversationID] = conversation_data
      return {
        ...state,
        user_medications: user_medications
      };
    } else {
      return state;
    }
    case FETCH_PRESCRIPTIONS_DATA:
      if (action.payload.data?.DOB && action.payload.data?.current_medications && action.payload.data?.gender && action.payload.data?.medical_conditions && action.payload.data?.name && action.payload.data?.summary) {
        let prescription_data = { ...state.prescription_data };
        let patient_data = prescription_data[action.payload.conversationID] || {}
        patient_data = action.payload.data
        prescription_data[action.payload.conversationID] = patient_data
        return {
          ...state,
          prescription_data: prescription_data,
          update_med_time: new Date().getTime(),
        };
      } else {
      return state;
    }
    case FETCH_PRESCRIPTIONS_DATA_CHATCHANGED:
      let prescription_data = { ...state.prescription_data };
      let patient_data = prescription_data[action.payload.conversationID] || {}
      patient_data = action.payload.data
      prescription_data[action.payload.conversationID] = patient_data
      return {
        ...state,
        prescription_data: prescription_data,
        update_med_time: new Date().getTime(),
      };
    case UPDATE_CONVERSATION_MEDICATION:
      // if (!!state.user_medications[action.payload.conversationID]?.isChanged) {
      let user_medications = { ...state.user_medications };
      let conversation_data = user_medications[action.payload.conversationID] || {}
      conversation_data.data = action.payload.data
      conversation_data.isChanged = true
      user_medications[action.payload.conversationID] = conversation_data
      return {
        ...state,
        user_medications: user_medications,
      };
      // } else {
      //   return state;
      // }
    default:
      return state;
  }
};
