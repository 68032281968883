import React, {useEffect, useState, Fragment, useRef} from 'react'
import classes from "../../../components/pharmacyComponent/pharmacycomponent.module.css";
import TextareaAutosize from "react-autosize-textarea";
import Button from "react-bootstrap/Button";
import Image from "../../../components/Image";
import {connect} from "react-redux";
import Spinner from "../../../components/Spinner";
import {database} from "../../../firebase";
import {updatePatient} from "../../../actions/ConversationAction";
import moment from "moment";
import _ from "lodash";
import axios from "axios";
import {firebaseRoot} from "../../../config";

const PatientInfoSection = (props) => {
    const [errorMessage, setErrorMessage] = useState('')
    const [DOB, setDOB] = useState('')
    const [name, setName] = useState('')
    const [phone_number, setPhoneNumber] = useState('')
    const [city, setCity] = useState('')
    const [country, setCountry] = useState('')
    const [state, setState] = useState('')
    const [postalcode, setPostalCode] = useState('')
    const [complete_address, setAddress] = useState('')
    const [showBox, setShowBox] = useState(false)
    const [loading, setLoading] = useState(false)
    const [currentTime, setCurrentTime] = useState('')
    const [patientId, setPatientId] = useState(null)
    const [unReadCountObject, setUnReadCountObject] = useState(null)
    const [ownerID, setOwnerID] = useState(null)

    useEffect(()=>{
        console.log("PatientInfoSection props.selectedConversationObj ", props.selectedConversationObj)
        setOwnerID(props.selectedConversationObj?.owner)
    },[props.selectedConversationObj])

    useEffect(()=>{
        setUnReadCountObject(_.find(props.conversations, [
            "conversationId",
            props.conversationId,
        ]))
        // console.log("unReadCountObject ", unReadCountObject)
    },[props.conversationId, props.conversations])

    useEffect(()=>{
        let date = new Date();
        let gmtOffset = (props?.selectedPatient?.gmtOffset) ? props?.selectedPatient?.gmtOffset : 0;
        let utc = date.getTime() + (date.getTimezoneOffset() * 60000);
        setCurrentTime((new Date(utc + (3600000 * gmtOffset))).toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        }))
    },[props?.selectedPatient?.gmtOffset])

    useEffect(()=>{
        if(patientId !== props.patientId){
            setDOB('')
            setName('')
            setPhoneNumber( '')
            setCity( '')
            setCountry('')
            setState( '')
            setPostalCode('')
            setAddress('')
            setPatientId(props.patientId)
        }
        if(props.selectedPatient && props.patientId){
            setPhoneNumber(props.selectedPatient?.phone_number || '')
            setCity(props.selectedPatient?.city || '')
            setCountry(props.selectedPatient?.country || '')
            setState(props.selectedPatient?.state || '')
            setPostalCode(props.selectedPatient?.postalcode || '')
            setAddress(props.selectedPatient?.complete_address || '')
            console.log("PatientInfoSection ownerID ", ownerID)
            console.log("PatientInfoSection props?.patientId ", props?.patientId)

            if(ownerID && ownerID !== props?.patientId && props.selectedPatient?.profiles){
                console.log("PatientInfoSection props.selectedPatient?.profiles[ownerID]?.DOB ", props.selectedPatient?.profiles[ownerID]?.DOB)
                setDOB(moment(props.selectedPatient?.profiles[ownerID]?.DOB || null).format('YYYY-MM-DD') )
                    setName(props.selectedPatient?.profiles[ownerID]?.name || props.selectedPatient?.profiles[ownerID]?.userName || '')
            }else{
                    setDOB(moment(props.selectedPatient?.DOB || null).format('YYYY-MM-DD') )
                    setName(props.selectedPatient?.name || props.selectedPatient?.userName || '')
            }
        }


    },[props.patientId, props.selectedPatient, ownerID])

    const setShowBoxFunction = () => {
        setShowBox(!showBox)
    }

    const saveChanges = async () => {
        if(props.patientId){
            let updates = {};
            if(DOB) updates[ownerID && ownerID !== props.patientId?`users/${props.patientId}/profiles/${ownerID}/DOB`:`users/${props.patientId}/DOB`] = moment(DOB).format('MMMM DD YYYY')
            if(name) updates[ownerID && ownerID !== props.patientId?`users/${props.patientId}/profiles/${ownerID}/name`:`users/${props.patientId}/name`] = name
            if(name) updates[ownerID && ownerID !== props.patientId?`users/${props.patientId}/profiles/${ownerID}/userName`:`users/${props.patientId}/userName`] = name
            if(phone_number) updates[`users/${props.patientId}/phone_number`] = phone_number
            if(city) updates[`users/${props.patientId}/city`] = city
            if(country) updates[`users/${props.patientId}/country`] = country
            if(state) updates[`users/${props.patientId}/state`] = state
            if(postalcode) updates[`users/${props.patientId}/postalcode`] = postalcode
            if(complete_address) updates[`users/${props.patientId}/complete_address`] = complete_address
            try{
                await database.ref().update(updates);
                props.updatePatient(props.patientId)
                setTimeout(setLoading(false), 100)
            }catch (e) {
                props.updatePatient(props.patientId)
                setTimeout(setLoading(false), 100)
            }
        }
    }

    const discardChanges = () =>{
        if(props.selectedPatient && props.patientId){
            if(ownerID && ownerID !== props.patientId){
                setDOB(moment(props.selectedPatient?.profiles[ownerID]?.DOB || null).format('YYYY-MM-DD') )
                setName(props.selectedPatient?.profiles[ownerID]?.name || props.selectedPatient?.profiles[ownerID]?.userName || '')
            }else{
                setDOB(moment(props.selectedPatient?.DOB || null).format('YYYY-MM-DD') )
                setName(props.selectedPatient?.name || props.selectedPatient?.userName || '')
            }
            setPhoneNumber(props.selectedPatient?.phone_number || '')
            setCity(props.selectedPatient?.city || '')
            setCountry(props.selectedPatient?.country || '')
            setState(props.selectedPatient?.state || '')
            setPostalCode(props.selectedPatient?.postalcode || '')
            setAddress(props.selectedPatient?.complete_address || '')
        }
    }

    return (
        <div className={classes.generalInformation}>
            <Fragment>
                <div className={classes.openchatlist}
                     onClick={setShowBoxFunction}>
                    <div className={classes.openchatlistSection}>
                        <div
                            className={showBox === true ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                            <svg fill="#884a9d" width="12px" height="8px"
                                 viewBox="0 0 12 8">
                                <path fill="inherit" fillRule="evenodd"
                                      d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                            </svg>
                        </div>
                        <div className={classes.subListTitle}>Patient Info</div>
                    </div>
                </div>
                {showBox &&
                <div className={classes.expandable_section}>
                    {
                        <div className={classes.general_info_section}>
                            <div>
                                <span className={classes.header_attr}>Local Time:</span>
                                <span className={classes.header_attr_value}>{currentTime || 'N/A'}</span>
                            </div>
                            <div>
                                <span className={classes.header_attr}># of Prior Encounters:</span>
                                <span className={classes.header_attr_value}>{props?.selectedPatient?.convoCount || 'N/A'}</span>
                            </div>
                            <div>
                                <span className={classes.header_attr}>Device:</span>
                                <span className={classes.header_attr_value}>{(props?.selectedPatient?.platform || 'N/A').toUpperCase()}</span>
                            </div>
                            <div>
                                <span className={classes.header_attr}>Email:</span>
                                <span className={classes.header_attr_value}>{props.selectedPatient?.email || 'N/A' }</span>
                            </div>
                            <div>
                                <span className={classes.header_attr}>Chat Session:</span>
                                <span className={classes.header_attr_value}>
                                    <span>{props.conversationId || 'N/A'}</span>
                                    <span onClick={()=>navigator.clipboard.writeText(props.conversationId || 'N/A')}
                                        className={classes.copyConversationID}>
                                        Copy
                                    </span>
                                </span>
                            </div>
                            {props.selectedPatient?.notes && <div>
                                <span className={classes.header_attr}>Orchyd Health Report:</span>
                                <span className={classes.header_attr_value}>
                                    <a target="_blank" href={props.selectedPatient?.notes}
                                          className={classes.copyConversationID}>
                                        Open Notes
                                    </a>
                                </span>
                            </div>}
                            {(unReadCountObject?.partner_id === 7 || unReadCountObject?.doNotCharge) && <div><span
                                className={classes.header_attr}> Prescriptions:</span> <span
                                className={classes.header_attr_value}>Do not charge this patient.</span>
                            </div>}
                            <div className={classes.SOAP_div}>
                                <span className={classes.SOAP_heading}>Name:</span>
                                <input
                                    className={classes.SOAP_textarea}
                                    style={{minHeight:45}}
                                    placeholder="Patient Name"
                                    value={name}
                                    onChange={(e)=>setName(e.target.value)}

                                />
                            </div>
                            <div className={classes.SOAP_div}>
                                <span className={classes.SOAP_heading}>Date of Birth:</span>
                                <input
                                    type='date'
                                    className={classes.SOAP_textarea}
                                    style={{minHeight:45}}
                                    placeholder="Date of Birth"
                                    value={DOB}
                                    onChange={(e)=>setDOB(e.target.value)}
                                />
                            </div>
                            <div className={classes.SOAP_div}>
                                <span className={classes.SOAP_heading}>Phone Number:</span>
                                <input
                                    className={classes.SOAP_textarea}
                                    style={{minHeight:45}}
                                    placeholder="Enter phone number"
                                    value={phone_number}
                                    onChange={(e)=>setPhoneNumber(e.target.value)}
                                />
                            </div>

                            <div className={classes.SOAP_div}>
                                <span className={classes.SOAP_heading}>City:</span>
                                <input
                                    className={classes.SOAP_textarea}
                                    style={{minHeight:45}}
                                    placeholder="Enter city"
                                    value={city}
                                    onChange={(e)=>setCity(e.target.value)}
                                />
                            </div>

                            <div className={classes.SOAP_div}>
                                <span className={classes.SOAP_heading}>State:</span>
                                <input
                                    className={classes.SOAP_textarea}
                                    style={{minHeight:45}}
                                    placeholder="Enter state"
                                    value={state}
                                    onChange={(e)=>setState(e.target.value)}
                                />
                            </div>

                            <div className={classes.SOAP_div}>
                                <span className={classes.SOAP_heading}>Country:</span>
                                <input
                                    className={classes.SOAP_textarea}
                                    style={{minHeight:45}}
                                    placeholder="Enter country"
                                    value={country}
                                    onChange={(e)=>setCountry(e.target.value)}
                                />
                            </div>

                            <div className={classes.SOAP_div}>
                                <span className={classes.SOAP_heading}>Address:</span>
                                <input
                                    className={classes.SOAP_textarea}
                                    style={{minHeight:45}}
                                    placeholder="Enter address"
                                    value={complete_address}
                                    onChange={setName}
                                />
                            </div>

                            <div className={classes.SOAP_div}>
                                <span className={classes.SOAP_heading}>Postal Code:</span>
                                <input
                                    className={classes.SOAP_textarea}
                                    style={{minHeight:45}}
                                    placeholder="Enter postal code"
                                    value={postalcode}
                                    onChange={(e)=>setPostalCode(e.target.value)}
                                />
                            </div>

                            {errorMessage && <span style={{
                                fontSize: 14,
                                color: "#a30707"
                            }}>{errorMessage}</span>}
                            {loading? <div className={classes.SOAP_buttons} style={{justifyContent: "center", marginTop:10}}><Spinner color={"black"}  loading/></div> : <div className={classes.SOAP_buttons} style={{justifyContent: "right"}}>
                                {<Button size="sm" className={classes.button_invert}
                                         onClick={discardChanges}>Discard</Button>}
                                <Button size="sm" className={classes.button}
                                        onClick={saveChanges}>Save</Button>
                            </div>

                            }

                        </div>
                    }
                </div>
                }
            </Fragment>
        </div>
    )
}
const mapStateToProps = ({ conversationReducer }) => {
    const { patientId, conversationId, conversations, selectedPatient, selectedConversationObj } = conversationReducer;
    return { patientId, conversationId, conversations, selectedPatient, selectedConversationObj};
};

export default connect(mapStateToProps, {updatePatient})(PatientInfoSection);
