import classes from "./cannedresponselist.module.css";

const CannedResponsesList = (props) => {
    return (
        <div className={classes.canned_response_list}>
            <div className={classes.canned_response_view}>
                {props.canned_responses_data.map((data, index) => {
                    return (
                        <div key={index} className={classes.canned_response_item}
                             onClick={() => props.onCannedResponseClick(data.uid, data.shortcut, data.text)}>
                            <div className={classes.canned_response_text}>{data.shortcut}</div>
                            <div className={classes.canned_response_arrow}
                                 onClick={() => props.onCannedResponseClick(data.uid, data.shortcut, data.text)}>
                                <svg data-test="shortcut-select-button" fill="#000000" width="4px" height="12px"
                                     viewBox="0 0 8 13">
                                    <g fill="inherit" fillRule="evenodd">
                                        <path fillRule="nonzero"
                                              d="M.59 11.34l4.58-4.59L.59 2.16 2 .75l6 6-6 6z"></path>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
};

export default CannedResponsesList
