import  {Component, Fragment, createRef} from 'react';
import classes from './clinicpatientdetail.module.css';
import TimeAgo from 'timeago-react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import TextareaAutosize from 'react-autosize-textarea';
import {updateInsuranceCard} from '../../../../actions/ClinicalChatsAction';
import {connect} from 'react-redux';
import ReactDOM from "react-dom";
//import 'jspdf-autotable'
//import sqlite from 'sqlite3'
//import { open } from 'sqlite'
import PatientProfile from '../../../clinicalScreens/profileBuilder'
//import * as jsPDF from 'jspdf'
import html2canvas from 'html2canvas';
import {database} from "../../../../firebase";

var healthProblems = []
var familyProblems = []
var healthOtherProblems = []
var familyyOtherProblem = []
var _ = require('lodash');
var moment = require("moment");

const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
}

const LeftMessage = (props) => {
    var name = props.patientName !== undefined ? capitalize(props.patientName) : 'Patient';
    return (
        <div className={classes.msg_body}>
            <div className={classes.msg_name}>
                <div className={classes.msg_name_text}>{name}</div>
            </div>
            <div className={classes.msg_left_body}>
                <div className={classes.msg_left_img}>{name.charAt(0)}</div>
                <div className={classes.msg_text_body}>
                    <div className={classes.msg_text_container}>
                        <div className={classes.msg_bubble_view}>
                            <div className={classes.msg_bubble_container}>
                                <div>
                                    <div className={classes.msg_bubble_content}>
                                        <div className={classes.msg_bubble_content1}>
                                            <div className={classes.msg_bubble_content2}>
                                                <div className={classes.msg_bubble_content_row}>
                                                    <div className={classes.msg_bubble_content_view}>
                                                        <div className={classes.msg_bubble_container_content}>
                                                            {props.content}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const RightMessage = (props) => {
    return (
        <div className={classes.msg_body}>
            <div className={classes.msg_right_name}>
                <div className={classes.msg_name_text}>{props.doctorName}</div>
            </div>
            <div className={classes.msg_right_body}>
                {props.doctorImg !== undefined &&
                <img className={classes.msg_right_img} alt="" src={props.doctorImg}/>
                }
                {props.doctorImg === undefined &&
                <div className={classes.msg_right_img}>{(props.doctorName).charAt(0)}</div>
                }
                <div className={classes.msg_right_container}>
                    <div className={classes.msg_right_content}>
                        <div className={classes.msg_right_content1}>
                            <div className={classes.msg_right_content2}>
                                <div>
                                    <div className={classes.msg_right_bubble_view}>
                                        <div className={classes.msg_right_bubble_container}>
                                            <div className={classes.msg_right_bubble_container_content}>
                                                <div className={classes.msg_bubble_content_row}>
                                                    <div className={classes.msg_bubble_right}>
                                                        <div
                                                            className={classes.msg_bubble_container_content}>{props.content}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const CannedResponsesList = (props) => {
    return (
        props.canned_responses_data.map(function (data, index) {
            return (
                <div key={index} className={classes.canned_response_item}
                     onClick={() => props.onCannedResponseClick(data.uid, data.shortcut, data.text)}>
                    <div className={classes.canned_response_text}>{data.shortcut}</div>
                    <div className={classes.canned_response_arrow}
                         onClick={() => props.onCannedResponseClick(data.uid, data.shortcut, data.text)}>
                        <svg data-test="shortcut-select-button" fill="#000000" width="4px" height="12px"
                             viewBox="0 0 8 13">
                            <g fill="inherit" fillRule="evenodd">
                                <path fillRule="nonzero" d="M.59 11.34l4.58-4.59L.59 2.16 2 .75l6 6-6 6z"></path>
                            </g>
                        </svg>
                    </div>
                </div>
            )
        })
    )
};

class UserDetailedScreen extends Component {
    constructor(props) {
        super(props);
        this.buttonRef1 = createRef()
        this.buttonRef2 = createRef()
        this.buttonRef3 = createRef()

        this.state = {
            show_history_list: true,
            showSelectedMessages: null,
            show_health_info: true,
            show_general_info: true,
            showEditBlock: false,
            showSOAPNotes: true,
            selectedChat: '',
            limit: 2,
            currentChatSelected: true,
            showInsurance: true,
            dateTime: `${new Date(moment().utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getFullYear()}-${`${new Date(moment().utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getMonth() + 1}`.padStart(2, 0)}-${`${new Date(moment().utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getDate()}`.padStart(2, 0)}T${`${new Date(moment().utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getHours()}`.padStart(2, 0)}:${`${new Date(moment().utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getMinutes()}`.padStart(2, 0)}`,
            showInsuranceEditModal: false,
            insuranceNumber: '',
            insuranceFront: '',
            insuranceBack: '',
            symptoms: '',
            findings: '',
            assessment: '',
            plan: '',
            patient_profile: true,
            showFollowUp: true,
            openWin: false,
            check: '',
            modalWindow: null,
            showfollowUpView: false,
            showCannedResponseForObjectiveFindingsList: false,
            showCannedResponseForAssessmentList: false,
            showCannedResponseForPlanList: false,
            canned_responses: [],
            canned_responses_assessment: [],
            canned_responses_plan: [],
        }
        this.soapnotes = createRef();
    }

    componentDidMount() {
        const {childRef} = this.props;
        childRef(this);
        this.setState({
            symptoms: this.props.subjective_symptoms,
            findings: this.props.findings,
            assessment: this.props.assessment,
            plan: this.props.plan,
            canned_responses: this.props.canned_responses,
            canned_responses_assessment: this.props.canned_responses_assessment,
            canned_responses_plan: this.props.canned_responses_plan,
        })
        var todayDate = new Date(moment().add(parseInt(this.props.gmtOffset) * -1, 'hours').format("YYYY-MM-DD HH:mm:ss"));

        /* open({
        filename: 'test.db',
        driver: sqlite3.Database
        }).then((db) => {
            console.log("connection established");
        // do your thing
        }) */
    }

    componentWillUnmount() {
        const {childRef} = this.props;
        childRef(undefined);
    }

    showPatientFollowUp = () => {
        this.setState((state) => ({showFollowUp: !state.showFollowUp}));
    }

    showSOAPInfo = () => {
        console.log("clinicalpatientdetail in clinical screens soap notes")
        this.setState((state) => ({showSOAPNotes: !state.showSOAPNotes}));
        //  if (this.soapnotes) this.soapnotes.current.scrollIntoView({ block: "start" });
    }

    editSOAPNotes = () => {
        console.log("clinicalpatientdetail in clinical screens soap notes edit soap notes")
        this.setState((state) => ({showEditBlock: true}));
    }

    showInsuranceInformation = () => {
        this.setState((state) => ({showInsurance: !state.showInsurance}));
    }

    onSaveSOAP = () => {
        this.setState({showEditBlock: false, showfollowUpView: true});
        // this.props.setSubjectiveSymptoms(this.state.symptoms);
        // this.props.setFindings(this.state.findings);
        // this.props.setAssessment(this.state.assessment);
        // this.props.setPlan(this.state.plan);
        let update={}
        let conversationID = this.props.conversationId || this.props.selectedConversation
        update[`/openClinicalConversations/${this.props.clinicId}/${conversationID}/symptoms`]=this.state.symptoms
        update[`/openClinicalConversations/${this.props.clinicId}/${conversationID}/findings`]=this.state.findings
        update[`/openClinicalConversations/${this.props.clinicId}/${conversationID}/assessment`]=this.state.assessment
        update[`/openClinicalConversations/${this.props.clinicId}/${conversationID}/plan`]=this.state.plan
        database.ref().update(update)
    }

    onClearSOAP = () => {
        this.setState({showEditBlock: false, symptoms: '', findings: '', assessment: '', plan: ''});
        // this.props.setSubjectiveSymptoms('');
        // this.props.setFindings('');
        // this.props.setAssessment('');
        // this.props.setPlan('');
        let update={}
        let conversationID = this.props.conversationId || this.props.selectedConversation
        update[`/openClinicalConversations/${this.props.clinicId}/${conversationID}/symptoms`]= ""
        update[`/openClinicalConversations/${this.props.clinicId}/${conversationID}/findings`]= ""
        update[`/openClinicalConversations/${this.props.clinicId}/${conversationID}/assessment`]= ""
        update[`/openClinicalConversations/${this.props.clinicId}/${conversationID}/plan`]= ""
        database.ref().update(update)
    }

    handleConfirmRequest = () => {
        const followUpDate = new Date(this.state.dateTime);
        const finalDate = new Date();
        const diffTime = Math.abs(followUpDate - finalDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;

        var date = new Date(this.state.dateTime);
        var options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };

        var timeString = date.toLocaleString('en-US', options);
        var dateString = new Date(this.state.dateTime).toDateString();
        dateString = dateString.split(' ').slice(1).join(' ');
        var month_names = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        var month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        dateString = dateString.split(' ');
        var month = month_names[month_names_short.indexOf(dateString[0])];
        date = parseInt(dateString[1]);

        var phenom;
        if (date === 1) {
            phenom = 'st';
        } else if (date === 2) {
            phenom = 'nd';
        } else if (date === 3) {
            phenom = 'rd';
        } else if (date === 21) {
            phenom = 'st';
        } else if (date === 22) {
            phenom = 'nd'
        } else if (date === 23) {
            phenom = 'rd';
        } else {
            phenom = 'th';
        }
        dateString = month + ' ' + String(date) + phenom;
        this.props.followStatus(this.state.dateTime, diffDays, timeString, dateString);
    }

    onChangeValue = (event) => {
        var self = this
        var todayDate = new Date(moment().add(parseInt(this.props.gmtOffset) * -1, 'hours').format("YYYY-MM-DD HH:mm:ss"));

        if (event.target.value === '3 days') {
            todayDate.setDate(todayDate.getDate() + 3)
            self.setState({
                dateTime: `${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getFullYear()}-${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getMonth() + 1}`.padStart(2, 0)}-${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getDate()}`.padStart(2, 0)}T${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getHours()}`.padStart(2, 0)}:${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getMinutes()}`.padStart(2, 0)}`
            });
        } else if (event.target.value === '1 week') {
            todayDate.setDate(todayDate.getDate() + 7)
            self.setState({
                dateTime: `${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getFullYear()}-${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getMonth() + 1}`.padStart(2, 0)}-${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getDate()}`.padStart(2, 0)}T${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getHours()}`.padStart(2, 0)}:${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getMinutes()}`.padStart(2, 0)}`
            });
        } else {
            todayDate.setDate(todayDate.getDate() + 30)
            self.setState({
                dateTime: `${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getFullYear()}-${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getMonth() + 1}`.padStart(2, 0)}-${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getDate()}`.padStart(2, 0)}T${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getHours()}`.padStart(2, 0)}:${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getMinutes()}`.padStart(2, 0)}`
            });
        }
    }

    handleSendRequest = () => {
        const followUpDate = new Date(this.state.dateTime);
        const finalDate = new Date();
        const diffTime = Math.abs(followUpDate - finalDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;
        var date = new Date(this.state.dateTime);
        var options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };
        var timeString = date.toLocaleString('en-US', options);
        var dateString = new Date(this.state.dateTime).toDateString()
        dateString = dateString.split(' ').slice(1).join(' ')
        var month_names = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        var month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        dateString = dateString.split(' ');
        var month = month_names[month_names_short.indexOf(dateString[0])];
        date = parseInt(dateString[1]);
        var phenom;
        if (date === 1) {
            phenom = 'st';
        } else if (date === 2) {
            phenom = 'nd';
        } else if (date === 3) {
            phenom = 'rd';
        } else if (date === 21) {
            phenom = 'st';
        } else if (date === 22) {
            phenom = 'nd';
        } else if (date === 23) {
            phenom = 'rd';
        } else {
            phenom = 'th';
        }

        dateString = month + ' ' + String(date) + phenom;
        this.props.onSendFollowUpRequest(diffDays, timeString, dateString);
    }

    handleSkipRequest = () => {
        this.props.skipStatus();
        this.setState({showFollowUp: false})

    }

    handleChangeFollowUpDateTime = (event) => {
        this.setState({dateTime: event.target.value});
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (this.props.isLoading === true && nextProps.isLoading === false) {
            this.setState({showFollowUp: false});
            this.props.followUpSaveSuccess()
        }
        if (this.props.gmtOffset != nextProps.gmtOffset) {
            if (this.buttonRef1.current != null
            ) {
                this.buttonRef1.current.checked = false

            }
            if (this.buttonRef2.current != null
            ) {
                this.buttonRef2.current.checked = false

            }
            if (this.buttonRef3.current != null
            ) {
                this.buttonRef3.current.checked = false

            }
            this.setState({
                dateTime: `${new Date(moment().utcOffset(nextProps.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getFullYear()}-${`${new Date(moment().utcOffset(nextProps.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getMonth() + 1}`.padStart(2, 0)}-${`${new Date(moment().utcOffset(nextProps.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getDate()}`.padStart(2, 0)}T${`${new Date(moment().utcOffset(nextProps.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getHours()}`.padStart(2, 0)}:${`${new Date(moment().utcOffset(nextProps.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getMinutes()}`.padStart(2, 0)}`

            })
        }

        if (nextProps.canned_responses !== this.props.canned_responses) {
            this.setState({canned_responses: nextProps.canned_responses});
        }

        if (nextProps.canned_responses_assessment !== this.props.canned_responses_assessment) {
            this.setState({canned_responses_assessment: nextProps.canned_responses_assessment});
        }

        if (nextProps.canned_responses_plan !== this.props.canned_responses_plan) {
            this.setState({canned_responses_plan: nextProps.canned_responses_plan});
        }

        if (nextProps.subjective_symptoms !== this.props.subjective_symptoms) {
            this.setState({symptoms: nextProps.subjective_symptoms});
        }

        if (nextProps.findings !== this.props.findings) {
            this.setState({findings: nextProps.findings});
        }

        if (nextProps.assessment !== this.props.assessment) {
            this.setState({assessment: nextProps.assessment});
        }

        if (nextProps.plan !== this.props.plan) {
            this.setState({plan: nextProps.plan});
        }
        if (!(_.isEqual(this.props.patient_profile, nextProps.patient_profile))) {
            familyProblems = []
            healthProblems = []
            this.generatePatientProfile(nextProps.patient_profile)
        }
        if (!Boolean(this.props.showSOAPNotes) && Boolean(nextProps.showSOAPNotes)) {
            this.setState({showEditBlock: true});
        }
    }

    renderPopOut() {
        var self = this
        if (this.modalWindow) {

            let root = this.modalWindow.document.body;
            ReactDOM.render(<PatientProfile
                patientName={self.props.patientName}
                dob={self.props.DOB}
                email={self.props.email}
                patient_profile={self.props.patient_profile}
            />, root);
        }
    }


    refreshCloseHistory = () => {
        this.props.setFlagFalse();
        this.setState({
            showlist: false,
            showSelectedMessages: null,
            selectedChat: '',
            currentChatSelected: true,
            limit: 2
        })
    }

    refreshHistory = () => {
        this.props.setFlagFalse();
        this.setState({
            showlist: false,
            showSelectedMessages: null,
            selectedChat: '',
            currentChatSelected: true
        })
    }

    showHistoryMessages = (uid, category, issue) => {
        this.props.setFlagTrue()
        this.setState({showSelectedMessages: null});
        this.setState({selectedChat: uid});
        this.setState({currentChatSelected: false});
        this.props.historyMessage(uid, category, issue);
    }

    onLoadMore = () => {
        this.setState({limit: this.state.limit + 2});
    }

    showHistoryList = () => {
        this.setState((state) => ({show_history_list: !state.show_history_list}));
    }

    showGeneralInfo = () => {
        this.setState((state) => ({show_general_info: !state.show_general_info}));
    }

    showHealthInfo = () => {
        this.setState((state) => ({show_health_info: !state.show_health_info}));
    }

    loadCurrent = () => {
        this.props.unReadCountObject.unreadCount = 0;
        this.setState({selectedChat: null, currentChatSelected: true});
        this.props.setFlagFalse();
        this.props.loadCurrentChat();
    }

    showInsuranceModal = () => {
        let {healthInsuranceCardNumber, healthInsuranceCardFront, healthInsuranceCardBack} = this.props;
        this.setState({
            showInsuranceEditModal: true,
            insuranceNumber: healthInsuranceCardNumber,
            insuranceFront: healthInsuranceCardFront,
            insuranceBack: healthInsuranceCardBack,
        });
    }

    insuranceNumberChange = (event) => {
        this.setState({insuranceNumber: event.target.value});
    }

    insuranceFrontUrl = (event) => {
        this.setState({insuranceFront: event.target.value});
    }

    insuranceBackUrl = (event) => {
        this.setState({insuranceBack: event.target.value});
    }

    saveInsuranceCard = () => {
        let {insuranceNumber, insuranceFront, insuranceBack} = this.state;
        var self = this;
        this.props.updateInsuranceCard(this.props.patientFirebaseID, insuranceNumber, insuranceFront, insuranceBack, function (status) {
            if (status) {
                self.setState({showInsuranceEditModal: false});
            }
        });
    }

    filterUsers = (event) => {
        var keyword = event.toLowerCase();
        var filtered_cannedResponses = this.state.canned_responses.filter((user) => {
            user = user.shortcut.toLowerCase();
            return user.indexOf(keyword) > -1;
        });
        if (filtered_cannedResponses.length !== 0) {
            this.setState({canned_responses: filtered_cannedResponses});
        } else {
            this.setState({canned_responses: this.props.canned_responses});
        }
    }

    filterAssessment = (event) => {
        var keyword = event.toLowerCase();
        var filtered_cannedResponses = this.state.canned_responses_assessment.filter((user) => {
            user = user.shortcut.toLowerCase();
            return user.indexOf(keyword) > -1;
        });
        if (filtered_cannedResponses.length !== 0) {
            this.setState({canned_responses_assessment: filtered_cannedResponses});
        } else {
            this.setState({canned_responses_assessment: this.props.canned_responses_assessment});
        }
    }

    filterPlan = (event) => {
        var keyword = event.toLowerCase();
        var filtered_cannedResponses = this.state.canned_responses_plan.filter((user) => {
            user = user.shortcut.toLowerCase();
            return user.indexOf(keyword) > -1;
        });
        if (filtered_cannedResponses.length !== 0) {
            this.setState({canned_responses_plan: filtered_cannedResponses});
        } else {
            this.setState({canned_responses_plan: this.props.canned_responses_plan});
        }
    }

    onSubjectSymptomsChange = (event) => {
        this.setState({symptoms: event.target.value});
    }

    onObjectiveFindingsChange = (event) => {
        if ((event.target.value).match('#')) {
            this.setState({showCannedResponseForObjectiveFindingsList: true});
            this.filterUsers((event.target.value.slice((event.target.value).search('#'), event.target.value.length)));
        }
        this.setState({findings: event.target.value});
    }
    onAssessmentGoalsChange = (event) => {

        if ((event.target.value).match('#')) {
            this.setState({showCannedResponseForAssessmentList: true});
            this.filterAssessment((event.target.value.slice((event.target.value).search('#'), event.target.value.length)));
        }
        this.setState({assessment: event.target.value});
    }

    onPlanChange = (event) => {

        if ((event.target.value).match('#')) {
            this.setState({showCannedResponseForPlanList: true});
            this.filterPlan((event.target.value.slice((event.target.value).search('#'), event.target.value.length)));
        }
        this.setState({plan: event.target.value});
    }

    onObjectiveCannedResponseClick = (uid, short_cut, text) => {
        this.setState({showCannedResponseForObjectiveFindingsList: false});
        var updatedString;

        if (this.state.findings.search('#') !== -1) {
            var replaceText = (this.state.findings.slice((this.state.findings).search('#'), this.state.findings.length));
            updatedString = (this.state.findings).replace(replaceText, text);
        } else {
            updatedString = (this.state.findings).concat(' ', text);
        }
        this.setState({findings: updatedString});
    }

    onAssessmentCannedResponseClick = (uid, short_cut, text) => {
        this.setState({showCannedResponseForAssessmentList: false});
        var updatedString;

        if (this.state.assessment.search('#') !== -1) {
            var replaceText = (this.state.assessment.slice((this.state.assessment).search('#'), this.state.assessment.length));
            updatedString = (this.state.assessment).replace(replaceText, text);
        } else {
            updatedString = (this.state.assessment).concat(' ', text);
        }
        this.setState({assessment: updatedString});
    }

    onPlanCannedResponseClick = (uid, short_cut, text) => {
        this.setState({showCannedResponseForPlanList: false});
        var updatedString;

        if (this.state.plan.search('#') !== -1) {
            var replaceText = (this.state.plan.slice((this.state.plan).search('#'), this.state.plan.length));
            updatedString = (this.state.plan).replace(replaceText, text);
        } else {
            updatedString = (this.state.plan).concat(' ', text);
        }
        this.setState({plan: updatedString});
    }
    showPatientFollowUp = () => {
        this.setState((state) => ({showFollowUp: !state.showFollowUp}));
    }
    openWindow = () => {
        if (this.props.patient_profile.selected != null && this.props.patient_profile.selected != undefined && this.props.patient_profile.selected != '') {
            if (this.props.patient_profile.selected.length == 0) {
                var medicationTable = "<i>No medication reported</i>"
            } else {
                medicationTable = `<table style="width:100%;border: 1px solid #D8D8D8;border-collapse: collapse"><tr><th style="border: 1px solid #D8D8D8;border-collapse: collapse;width:25%;background-color:#894A9E;color:white;">Medication</th>
        <th style="border: 1px solid #D8D8D8;border-collapse: collapse; width:25%;background-color:#894A9E;color:white;">Dosage</th><th style="border: 1px solid #D8D8D8;border-collapse: collapse; width:25%;background-color:#894A9E;color:white;">Direction</th><th style="border: 1px solid #D8D8D8;border-collapse: collapse; width:25%;background-color:#894A9E;color:white;">Date Modified</th>`;
                for (var j = 0; j < this.props.patient_profile.selected.length; j++) {
                    let direction = this.props.patient_profile.selected[j].split(" ")
                    direction = direction[direction.length - 1]
                    let medName = this.props.patient_profile.selected[j]
                    medName = medName.substring(0, medName.lastIndexOf(" "))
                    medicationTable += "</tr><tr>";
                    medicationTable += `<td style="width:25%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + medName + "</td>";
                    medicationTable += `<td style="width:25%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.dosage[j] + "</td>";
                    medicationTable += `<td style="width:25%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + direction + "</td>";
                    medicationTable += `<td style="width:25%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.medDates[j] + "</td>";
                }
                medicationTable += "</tr></table>"

            }
        } else {
            var medicationTable = "<i>No medication reported</i>"
        }
        if (this.props.patient_profile.allergyData != null && this.props.patient_profile.allergyData != undefined && this.props.patient_profile.allergyData != '') {

            if (this.props.patient_profile.allergyData.length == 0) {
                var allergyTable = "<i>No allergies reported</i>"
            } else {
                allergyTable = `<table style="width:100%;border: 1px solid #D8D8D8;border-collapse: collapse"><tr><th style="border: 1px solid #D8D8D8;border-collapse: collapse;width:50%;background-color:#894A9E;color:white;">Allergy Type</th>
            <th style="border: 1px solid #D8D8D8;border-collapse: collapse; width:50%;background-color:#894A9E;color:white;">Date Modified</th>`;

                for (var i = 0; i < this.props.patient_profile.allergyData.length; i++) {
                    allergyTable += "</tr><tr>";

                    allergyTable += `<td style="width:50%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.allergyData[i] + "</td>";
                    allergyTable += `<td style="width:50%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.allergyDates[i] + "</td>";

                }

                allergyTable += "</tr></table>";
            }
        } else {
            var allergyTable = "<i>No allergies reported</i>"
        }
        if (this.props.patient_profile.asthmaFlag == false && this.props.patient_profile.kidneyFlag == false && this.props.patient_profile.bpFlag == false && this.props.patient_profile.diabetesFlag == false && this.props.patient_profile.heartFlag == false && this.props.patient_profile.headFlag == false && this.props.patient_profile.utiFlag == false && this.props.patient_profile.depressionsFlag == false && this.props.patient_profile.seizureFlag == false && this.props.patient_profile.strokeFlag == false && this.props.patient_profile.thyroidFlag == false && this.props.patient_profile.arrhythmiasFlag == false && this.props.patient_profile.anxietyFlag == false && this.props.patient_profile.panicFlag == false && this.props.patient_profile.copdFlag == false && this.props.patient_profile.eczemaFlag == false && this.props.patient_profile.psoriasisFlag == false && this.props.patient_profile.cancerFlag == false && this.props.patient_profile.otherFlag == false) {
            var healthProblemTable = "<i>No health problems reported</i>"

        } else {
            healthProblemTable = `<table style="width:100%;border: 1px solid #D8D8D8;border-collapse: collapse"><tr><th style="border: 1px solid #D8D8D8;border-collapse: collapse;width:33%;background-color:#894A9E;color:white;">Condition</th>
            <th style="border: 1px solid #D8D8D8;border-collapse: collapse; width:33%;background-color:#894A9E;color:white;">Answer</th><th style="border: 1px solid #D8D8D8;border-collapse: collapse; width:33%;background-color:#894A9E;color:white;">Date Modified</th>`;
            healthProblemTable += "</tr><tr>";
            if (this.props.patient_profile.asthmaFlag == true) {
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Asthama" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.asthamaDate + "</td>";


            }
            if (this.props.patient_profile.kidneyFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Kidney Problems" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.kidneyDate + "</td>";


            }
            if (this.props.patient_profile.bpFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "High Blood Pressure" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.bpDate + "</td>";


            }
            if (this.props.patient_profile.diabetesFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Diabetes" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.diabetesDate + "</td>";


            }
            if (this.props.patient_profile.heartFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Heart Problem" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.heartDate + "</td>";


            }
            if (this.props.patient_profile.headFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Headaches/Migraines" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.headDate + "</td>";


            }
            if (this.props.patient_profile.utiFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Urinary Tract Infection" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.utiDate + "</td>";


            }
            if (this.props.patient_profile.depressionsFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Depression" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.depressionsDate + "</td>";


            }
            if (this.props.patient_profile.seizureFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Seizures" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.seizureDate + "</td>";


            }
            if (this.props.patient_profile.strokeFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Stroke" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.strokeDate + "</td>";


            }
            if (this.props.patient_profile.thyroidFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Thyroid Disease" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.thyroidDate + "</td>";


            }
            if (this.props.patient_profile.arrhythmiasFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Arrhythmias" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.arrhythmiasDate + "</td>";


            }
            if (this.props.patient_profile.anxietyFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Anxiety" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.anxietyDate + "</td>";


            }
            if (this.props.patient_profile.panicFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Panic Attacks" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.panicDate + "</td>";


            }
            if (this.props.patient_profile.copdFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "COPD" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.copdDate + "</td>";


            }
            if (this.props.patient_profile.eczemaFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Eczema" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.eczemaDate + "</td>";


            }
            if (this.props.patient_profile.psoriasisFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Psoriasis" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.psoriasisDate + "</td>";


            }
            if (this.props.patient_profile.cancerFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Cancer" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.cancerData.toString() + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.cancerDate + "</td>";


            }
            if (this.props.patient_profile.otherFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Other" + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.otherData.toString() + "</td>";
                healthProblemTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.otherDate + "</td>";


            }
            healthProblemTable += "</tr></table>";


        }
        if (this.props.patient_profile.asthmaFlagFamily == false && this.props.patient_profile.kidneyFlagFamily == false && this.props.patient_profile.bpFlagFamily == false && this.props.patient_profile.diabetesFlagFamily == false && this.props.patient_profile.heartFlagFamily == false && this.props.patient_profile.headFlagFamily == false && this.props.patient_profile.utiFlagFamily == false && this.props.patient_profile.depressionsFlagFamily == false && this.props.patient_profile.seizureFlagFamily == false && this.props.patient_profile.strokeFlagFamily == false && this.props.patient_profile.thyroidFlagFamily == false && this.props.patient_profile.arrhythmiasFlagFamily == false && this.props.patient_profile.anxietyFlagFamily == false && this.props.patient_profile.panicFlagFamily == false && this.props.patient_profile.copdFlagFamily == false && this.props.patient_profile.eczemaFlagFamily == false && this.props.patient_profile.psoriasisFlagFamily == false && this.props.patient_profile.cancerFlagFamily == false && this.props.patient_profile.otherFlagFamily == false) {
            var familyHistoryTable = "<i>No family history reported</i>"

        } else {
            familyHistoryTable = `<table width="100%" style=border: 1px solid #D8D8D8;border-collapse: collapse><tr><th style="border: 1px solid #D8D8D8;border-collapse: collapse;width:33%;background-color:#894A9E;color:white;">Condition</th>
            <th style="border: 1px solid #D8D8D8;border-collapse: collapse; width:33%;background-color:#894A9E;color:white;">Relationship</th><th style="border: 1px solid #D8D8D8;border-collapse: collapse; width:33%;background-color:#894A9E;color:white;">Date Modified</th>`;
            familyHistoryTable += "</tr><tr>";
            if (this.props.patient_profile.asthmaFlagFamily == true) {
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Asthama" + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.asthamaAnswer + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.asthamaFamilyDate + "</td>";


            }
            if (this.props.patient_profile.kidneyFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Kidney Problems" + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.kidneyAnswer + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.kidneyFamilyDate + "</td>";


            }
            if (this.props.patient_profile.bpFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "High Blood Pressure" + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.bpAnswer + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.bpFamilyDate + "</td>";


            }
            if (this.props.patient_profile.diabetesFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Diabetes" + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.diabetesAnswer + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.diabetesFamilyDate + "</td>";


            }
            if (this.props.patient_profile.heartFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Heart Problem" + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.heartAnswer + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.heartFamilyDate + "</td>";


            }
            if (this.props.patient_profile.headFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Headaches/Migraines" + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.headAnswer + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.headFamilyDate + "</td>";


            }
            if (this.props.patient_profile.utiFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Urinary Tract Infection" + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.utiAnswer + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.utiFamilyDate + "</td>";


            }
            if (this.props.patient_profile.depressionsFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Depression" + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.depressionsAnswer + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.depressionsFamilyDate + "</td>";


            }
            if (this.props.patient_profile.seizureFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Seizures" + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.seizureAnswer + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.seizureFamilyDate + "</td>";


            }
            if (this.props.patient_profile.strokeFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Stroke" + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.strokeAnswer + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.strokeFamilyDate + "</td>";


            }
            if (this.props.patient_profile.thyroidFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Thyroid Disease" + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.thyroidAnswer + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.thyroidFamilyDate + "</td>";


            }
            if (this.props.patient_profile.arrhythmiasFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Arrhythmias" + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.arrhythmiasAnswer + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.arrhythmiasFamilyDate + "</td>";


            }
            if (this.props.patient_profile.anxietyFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Anxiety" + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.anxietyAnswer + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.anxietyFamilyDate + "</td>";


            }
            if (this.props.patient_profile.panicFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Panic Attacks" + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.panicAnswer + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.panicFamilyDate + "</td>";


            }
            if (this.props.patient_profile.copdFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "COPD" + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.copdAnswer + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.copdFamilyDate + "</td>";


            }
            if (this.props.patient_profile.eczemaFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Eczema" + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.eczemaAnswer + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.eczemaFamilyDate + "</td>";


            }
            if (this.props.patient_profile.psoriasisFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Psoriasis" + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.psoriasisAnswer + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.psoriasisFamilyDate + "</td>";


            }
            if (this.props.patient_profile.cancerFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Cancer" + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.cancerAnswer + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.cancerFamilyDate + "</td>";


            }
            if (this.props.patient_profile.otherFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + "Other" + "(" + this.props.patient_profile.otherFamilyData.toString() + ")" + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.otherFamilyAnswer.toString() + "</td>";
                familyHistoryTable += `<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10">` + this.props.patient_profile.otherFamilyDate + "</td>";


            }
            familyHistoryTable += "</tr></table></div>";


        }


        var source = `
            
            
        <div "><h2 style="text-align: center;font-size:15;margin: 0
        ">EPatient Medical History</h2><h2 style="text-align: center;font-size:15;margin: 0
        ">${this.props.patientName}</h2>
        <h4 style="text-align: center;font-size:15;  margin: 0
        ">${this.props.DOB}</h4>
        <h3 style="text-align: center;font-size:15;  margin: 0
        ">Your Doctors Online</h3>

        <hr></div>
        
<h6 style="font-size:15; margin:0;padding-top:10">Social History</h6>
<table style="width:100%;border: 1px solid #D8D8D8;border-collapse: collapse">
<tr>
<th style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;background-color:#894A9E;color:white;">Condition</th>
<th style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;background-color:#894A9E;color:white;">Answer</th>
<th style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;background-color:#894A9E;color:white;">Date Modified</th>

</tr>
<tr>
<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10px"> Smoke/Use Tobacco</td>
<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10px"> ${this.props.patient_profile.smokeFlag == true ? "Yes" : "No"}</td>
<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10px"> ${this.props.patient_profile.smokeDate}</td>

</tr>
<tr>
<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10px"> How frequently do you use tobacco? </td>
<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10px"> ${this.props.patient_profile.smokeAnswer == '' ? '-' : this.props.patient_profile.smokeAnswer}</td>
<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10px"> ${this.props.patient_profile.smokeQueDate == null || this.props.patient_profile.smokeQueDate == undefined || this.props.patient_profile.smokeQueDate == '' ? '-' : this.props.patient_profile.smokeQueDate}</td>

</tr>
<tr>
<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10px"> Drink Alcohol </td>
<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10px"> ${this.props.patient_profile.alcoholFlag == true ? 'Yes' : 'No'}</td>
<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10px"> ${this.props.patient_profile.alcoholDate}</td>

</tr>
<tr>
<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10px"> How frequently do you use alcohol? </td>
<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10px"> ${this.props.patient_profile.alocoholAnswer == '' ? '-' : this.props.patient_profile.alocoholAnswer}</td>
<td style="width:33%;border: 1px solid #D8D8D8;border-collapse: collapse;padding-left:10px"> ${this.props.patient_profile.alcoholQueDate == null || this.props.patient_profile.alcoholQueDate == undefined || this.props.patient_profile.alcoholQueDate == '' ? '-' : this.props.patient_profile.alcoholQueDate}</td>

</tr>
</table>

<h6 style="font-size:15; margin:0;padding-top:10">Health Problems</h6>
${healthProblemTable}
<div style="page-break-inside: avoid">

<h6 style="font-size:15; margin:0;padding-top:10">Allergies</h6>
${allergyTable}
</div>
<div style="page-break-inside: avoid">
<h6 style="font-size:15; margin:0;padding-top:10">Medications</h6>
${medicationTable}
</div>
<div style="page-break-inside: avoid">
<h6 style="font-size:15; margin:0;padding-top:10">Family History</h6>
${familyHistoryTable}



`

    }
    /*generatePdf = (patientProfile) => {

        if (this.props.patient_profile.selected != null && this.props.patient_profile.selected != undefined && this.props.patient_profile.selected != '') {
            if (this.props.patient_profile.selected.length == 0) {
                var medicationTable = "<i>No medication reported</i>"
            } else {
                medicationTable = `<table style="    display:inline-block;
                position: relative;page-break-inside: avoid;margin-left:1.5pt;margin-top:2pt;width:220pt;border: 1px solid #D8D8D8;border-collapse: collapse"><tr>
                <th style="width:55pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;background-color:#894A9E;color:white;font-size:6pt;text-align: center">Medication</th>
        <th style="width:55pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;background-color:#894A9E;color:white;font-size:6pt;text-align: center">Dosage</th>
        <th style="width:55pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;background-color:#894A9E;color:white;font-size:6pt;text-align: center">Direction</th>
        <th style="width:55pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;background-color:#894A9E;color:white;font-size:6pt;text-align: center">Date Modified</th>`;
                for (var j = 0; j < this.props.patient_profile.selected.length; j++) {
                    let direction = this.props.patient_profile.selected[j].split(" ")
                    direction = direction[direction.length - 1]
                    let medName = this.props.patient_profile.selected[j]
                    medName = medName.substring(0, medName.lastIndexOf(" "))
                    medicationTable += "</tr><tr>";

                    medicationTable += `<td style="width:55pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + medName + "</td>";
                    medicationTable += `<td style="width:55pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.dosage[j] + "</td>";
                    medicationTable += `<td style="width:55pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + direction + "</td>";
                    medicationTable += `<td style="width:55pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.medDates[j] + "</td>";


                }
                medicationTable += "</tr></table>"

            }
        } else {
            var medicationTable = "<i>No medication reported</i>"
        }
        if (this.props.patient_profile.allergyData != null && this.props.patient_profile.allergyData != undefined && this.props.patient_profile.allergyData != '') {

            if (this.props.patient_profile.allergyData.length == 0) {
                var allergyTable = "<i>No allergies reported</i>"
            } else {
                allergyTable = `<table style="margin-left:1.5pt;margin-top:2pt;width:220pt;border: 1px solid #D8D8D8;border-collapse: collapse"><tr>
                <th style="width:110pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;background-color:#894A9E;color:white;font-size:6pt;text-align: center">Allergy Type</th>
            <th style="width:110pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;background-color:#894A9E;color:white;font-size:6pt;text-align: center">Date Modified</th>`;

                for (var i = 0; i < this.props.patient_profile.allergyData.length; i++) {
                    allergyTable += "</tr><tr>";

                    allergyTable += `<td style="width:110pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.allergyData[i] + "</td>";
                    allergyTable += `<td style="width:110pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.allergyDates[i] + "</td>";

                }

                allergyTable += "</tr></table>";
            }
        } else {
            var allergyTable = "<i>No allergies reported</i>"
        }
        if (this.props.patient_profile.asthmaFlag == false && this.props.patient_profile.kidneyFlag == false && this.props.patient_profile.bpFlag == false && this.props.patient_profile.diabetesFlag == false && this.props.patient_profile.heartFlag == false && this.props.patient_profile.headFlag == false && this.props.patient_profile.utiFlag == false && this.props.patient_profile.depressionsFlag == false && this.props.patient_profile.seizureFlag == false && this.props.patient_profile.strokeFlag == false && this.props.patient_profile.thyroidFlag == false && this.props.patient_profile.arrhythmiasFlag == false && this.props.patient_profile.anxietyFlag == false && this.props.patient_profile.panicFlag == false && this.props.patient_profile.copdFlag == false && this.props.patient_profile.eczemaFlag == false && this.props.patient_profile.psoriasisFlag == false && this.props.patient_profile.cancerFlag == false && this.props.patient_profile.otherFlag == false) {
            var healthProblemTable = "<i>No health problems reported</i>"

        } else {
            healthProblemTable = `<table style="margin-left:1.5pt;margin-top:2pt;width:220pt;border: 1px solid #D8D8D8;border-collapse: collapse"><tr>
            <th style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;background-color:#894A9E;color:white;font-size:6pt;text-align: center">Condition</th>

            <th style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;background-color:#894A9E;color:white;font-size:6pt;text-align: center">Answer</th>
            <th style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;background-color:#894A9E;color:white;font-size:6pt;text-align: center">Date Modified</th>`;
            healthProblemTable += "</tr><tr>";
            if (this.props.patient_profile.asthmaFlag == true) {
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Asthama" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.asthamaDate + "</td>";


            }
            if (this.props.patient_profile.kidneyFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Kidney Problems" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.kidneyDate + "</td>";


            }
            if (this.props.patient_profile.bpFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "High Blood Pressure" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.bpDate + "</td>";


            }
            if (this.props.patient_profile.diabetesFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Diabetes" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.diabetesDate + "</td>";


            }
            if (this.props.patient_profile.heartFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Heart Problem" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.heartDate + "</td>";


            }
            if (this.props.patient_profile.headFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Headaches/Migraines" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.headDate + "</td>";


            }
            if (this.props.patient_profile.utiFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Urinary Tract Infection" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.utiDate + "</td>";


            }
            if (this.props.patient_profile.depressionsFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Depression" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.depressionsDate + "</td>";


            }
            if (this.props.patient_profile.seizureFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Seizures" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.seizureDate + "</td>";


            }
            if (this.props.patient_profile.strokeFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Stroke" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.strokeDate + "</td>";


            }
            if (this.props.patient_profile.thyroidFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Thyroid Disease" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.thyroidDate + "</td>";


            }
            if (this.props.patient_profile.arrhythmiasFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Arrhythmias" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.arrhythmiasDate + "</td>";


            }
            if (this.props.patient_profile.anxietyFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Anxiety" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.anxietyDate + "</td>";


            }
            if (this.props.patient_profile.panicFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Panic Attacks" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.panicDate + "</td>";


            }
            if (this.props.patient_profile.copdFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "COPD" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.copdDate + "</td>";


            }
            if (this.props.patient_profile.eczemaFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Eczema" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.eczemaDate + "</td>";


            }
            if (this.props.patient_profile.psoriasisFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Psoriasis" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Yes" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.psoriasisDate + "</td>";


            }
            if (this.props.patient_profile.cancerFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Cancer" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.cancerData.toString() + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.cancerDate + "</td>";


            }
            if (this.props.patient_profile.otherFlag == true) {
                healthProblemTable += "</tr><tr>";

                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Other" + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.other + "</td>";
                healthProblemTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.otherDate + "</td>";


            }
            healthProblemTable += "</tr></table>";


        }
        if (this.props.patient_profile.asthmaFlagFamily == false && this.props.patient_profile.kidneyFlagFamily == false && this.props.patient_profile.bpFlagFamily == false && this.props.patient_profile.diabetesFlagFamily == false && this.props.patient_profile.heartFlagFamily == false && this.props.patient_profile.headFlagFamily == false && this.props.patient_profile.utiFlagFamily == false && this.props.patient_profile.depressionsFlagFamily == false && this.props.patient_profile.seizureFlagFamily == false && this.props.patient_profile.strokeFlagFamily == false && this.props.patient_profile.thyroidFlagFamily == false && this.props.patient_profile.arrhythmiasFlagFamily == false && this.props.patient_profile.anxietyFlagFamily == false && this.props.patient_profile.panicFlagFamily == false && this.props.patient_profile.copdFlagFamily == false && this.props.patient_profile.eczemaFlagFamily == false && this.props.patient_profile.psoriasisFlagFamily == false && this.props.patient_profile.cancerFlagFamily == false && this.props.patient_profile.otherFlagFamily == false) {
            var familyHistoryTable = "<i>No family history reported</i>"

        } else {
            familyHistoryTable = `<table style="margin-left:1.5pt;margin-top:2pt;width:220pt;border: 1px solid #D8D8D8;border-collapse: collapse"><tr>
            <th style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;background-color:#894A9E;color:white;font-size:6pt;text-align: center">Condition</th>
            <th style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;background-color:#894A9E;color:white;font-size:6pt;text-align: center">Relationship</th>
            <th style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;background-color:#894A9E;color:white;font-size:6pt;text-align: center">Date Modified</th>`;
            familyHistoryTable += "</tr><tr>";
            if (this.props.patient_profile.asthmaFlagFamily == true) {
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Asthama" + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.asthamaAnswer + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.asthamaFamilyDate + "</td>";


            }
            if (this.props.patient_profile.kidneyFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Kidney Problems" + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.kidneyAnswer + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.kidneyFamilyDate + "</td>";


            }
            if (this.props.patient_profile.bpFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "High Blood Pressure" + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.bpAnswer + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.bpFamilyDate + "</td>";


            }
            if (this.props.patient_profile.diabetesFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Diabetes" + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.diabetesAnswer + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.diabetesFamilyDate + "</td>";


            }
            if (this.props.patient_profile.heartFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Heart Problem" + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.heartAnswer + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.heartFamilyDate + "</td>";


            }
            if (this.props.patient_profile.headFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Headaches/Migraines" + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.headAnswer + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.headFamilyDate + "</td>";


            }
            if (this.props.patient_profile.utiFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Urinary Tract Infection" + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.utiAnswer + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.utiFamilyDate + "</td>";


            }
            if (this.props.patient_profile.depressionsFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Depression" + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.depressionsAnswer + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.depressionsFamilyDate + "</td>";


            }
            if (this.props.patient_profile.seizureFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Seizures" + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.seizureAnswer + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.seizureFamilyDate + "</td>";


            }
            if (this.props.patient_profile.strokeFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Stroke" + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.strokeAnswer + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.strokeFamilyDate + "</td>";


            }
            if (this.props.patient_profile.thyroidFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Thyroid Disease" + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.thyroidAnswer + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.thyroidFamilyDate + "</td>";


            }
            if (this.props.patient_profile.arrhythmiasFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Arrhythmias" + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.arrhythmiasAnswer + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.arrhythmiasFamilyDate + "</td>";


            }
            if (this.props.patient_profile.anxietyFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Anxiety" + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.anxietyAnswer + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.anxietyFamilyDate + "</td>";


            }
            if (this.props.patient_profile.panicFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Panic Attacks" + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.panicAnswer + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.panicFamilyDate + "</td>";


            }
            if (this.props.patient_profile.copdFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "COPD" + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.copdAnswer + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.copdFamilyDate + "</td>";


            }
            if (this.props.patient_profile.eczemaFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Eczema" + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.eczemaAnswer + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.eczemaFamilyDate + "</td>";


            }
            if (this.props.patient_profile.psoriasisFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Psoriasis" + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.psoriasisAnswer + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.psoriasisFamilyDate + "</td>";


            }
            if (this.props.patient_profile.cancerFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Cancer" + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.cancerAnswer + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.cancerFamilyDate + "</td>";


            }
            if (this.props.patient_profile.otherFlagFamily == true) {
                familyHistoryTable += "</tr><tr>";

                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + "Other" + "(" + this.props.patient_profile.otherFamily + ")" + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.otherAnswerFamily + "</td>";
                familyHistoryTable += `<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt">` + this.props.patient_profile.otherFamilyDate + "</td>";


            }
            familyHistoryTable += "</tr></table></div>";


        }


        var source = `


        <div style=" display:block;width:230pt" ><h2 style="text-align: center;font-size:6pt;margin: 0
        ">Patient Medical History</h2><h2 style="text-align: center;font-size:6pt;margin: 0
        ">${this.props.patientName}</h2>
        <h4 style="text-align: center;font-size:6pt;  margin: 0
        ">${this.props.DOB}</h4>
        <h3 style="text-align: center;font-size:6pt;  margin: 0
        ">YourDoctorsOnline</h3>

        <hr></div>

<h6 style="font-size:6pt;width:230pt ; margin: 0;white-space: pre-wrap">Social History</h6>
<table style="margin-left:1.5pt;margin-top:2pt;width:220pt;border: 1px solid #D8D8D8;border-collapse: collapse">
<tr>
<th style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;background-color:#894A9E;color:white;font-size:6pt;text-align: center">Condition</th>
<th style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;background-color:#894A9E;color:white;font-size:6pt;text-align: center">Answer</th>
<th style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;background-color:#894A9E;color:white;font-size:6pt;text-align: center">Date Modified</th>

</tr>
<tr>
<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt"> Smoke/Use Tobacco</td>
<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt"> ${this.props.patient_profile.smokeFlag == true ? "Yes" : "No"}</td>
<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt"> ${this.props.patient_profile.smokeDate}</td>

</tr>
<tr>
<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt"> How frequently do you use tobacco? </td>
<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt"> ${this.props.patient_profile.smokeAnswer == '' ? '-' : this.props.patient_profile.smokeAnswer}</td>
<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt"> ${this.props.patient_profile.smokeQueDate == null || this.props.patient_profile.smokeQueDate == undefined || this.props.patient_profile.smokeQueDate == '' ? '-' : this.props.patient_profile.smokeQueDate}</td>

</tr>
<tr>
<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt"> Drink Alcohol </td>
<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt"> ${this.props.patient_profile.alcoholFlag == true ? 'Yes' : 'No'}</td>
<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt"> ${this.props.patient_profile.alcoholDate}</td>

</tr>
<tr>
<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt"> How frequently do you use alcohol? </td>
<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt"> ${this.props.patient_profile.alocoholAnswer == '' ? '-' : this.props.patient_profile.alocoholAnswer}</td>
<td style="width:76pt;border: 0.2pt solid #D8D8D8;border-collapse: collapse;padding-left:4pt;font-size:6pt"> ${this.props.patient_profile.alcoholQueDate == null || this.props.patient_profile.alcoholQueDate == undefined || this.props.patient_profile.alcoholQueDate == '' ? '-' : this.props.patient_profile.alcoholQueDate}</td>

</tr>
</table>

<h6 style="font-size:6pt;width:230pt ; margin: 0;">Health Problems</h6>
${healthProblemTable}
<div style="display: inline-block;page-break-inside: avoid">

<h6 style="font-size:6pt;width:230pt ; margin: 0;">Allergies</h6>
${allergyTable}
</div>
<div style="display: inline-table; position: relative;page-break-inside: avoid">
<h6 style="font-size:6pt;width:230pt ; margin: 0;">Medications</h6>
${medicationTable}
</div>
<div style="display: inline-block;page-break-inside: avoid">
<h6 style="font-size:6pt;width:230pt ; margin: 0;">Family History</h6>
${familyHistoryTable}



`
        var doc = new jsPDF('p', 'pt', 'a4');
        doc.setFontSize(14);

        window.html2canvas = html2canvas
        doc.html(
            source, {

                callback: function (doc) {
                    doc.output("dataurlnewwindow");

                }
            }
        )


    }*/
    showPatientProfile = () => {
        this.setState((state) => ({patient_profile: !state.patient_profile}));

    }
    generatePatientProfile = (patient_profile) => {
        if (patient_profile != null && patient_profile != undefined && patient_profile != {}) {
            var all_keys = Object.keys(patient_profile)
            if (all_keys.length > 0) {
                var pos1 = all_keys[all_keys.length - 1]
                var pos2 = all_keys[all_keys.length - 2]
                all_keys[all_keys.length - 1] = all_keys[83]
                all_keys[83] = pos1
                all_keys[all_keys.length - 2] = all_keys[84]
                all_keys[84] = pos2
                var heightInCm = parseInt(patient_profile.patient_height)
                var totalInches = heightInCm / 2.54
                var heightInFeet = Math.floor(totalInches / 12)
                var remainderInches = totalInches % 12
                remainderInches = Math.ceil(remainderInches)
                var heightInch = heightInFeet + ',' + remainderInches
                console.log("height", heightInch)
            }
            for (var key of all_keys) {
                if(key){
                    if (key.indexOf("Flag") != -1 && key.indexOf("Family") == -1 && key.indexOf("alcoholFlag") == -1 && key.indexOf("smokeFlag") == -1 && key.indexOf("allergyFlag") == -1) {
                        if (patient_profile[key] == true) {
                            if (key == 'anxietyFlag') {
                                healthProblems.push("Anxiety")
                            }
                            if (key == 'asthmaFlag') {
                                healthProblems.push("Asthama\n")
                            }
                            if (key == 'arrhythmiasFlag') {
                                healthProblems.push("Arrhythmias")
                            }
                            if (key == 'bpFlag') {
                                healthProblems.push("High Blood Pressure")
                            }
                            if (key == 'cancerFlag') {
                                if (patient_profile.cancerData != null && patient_profile.cancerData != undefined && patient_profile.cancerData != '' && patient_profile.cancerData != []) {
                                    healthProblems.push("Cancer" + "(" + patient_profile.cancerData.toString() + ")")
                                } else {
                                    healthProblems.push("Cancer")
                                }
                            }
                            if (key == 'copdFlag') {
                                healthProblems.push("COPD")
                            }
                            if (key == 'depressionsFlag') {
                                healthProblems.push("Depressions")
                            }
                            if (key == 'diabetesFlag') {
                                healthProblems.push("Diabetes")
                            }
                            if (key == 'eczemaFlag') {
                                healthProblems.push("\n Eczema")
                            }
                            if (key == 'headFlag') {
                                healthProblems.push("Headaches/Migraines")
                            }
                            if (key == 'heartFlag') {
                                healthProblems.push("Heart Problems")
                            }
                            if (key == 'kidneyFlag') {
                                healthProblems.push(" Kidney Problems")
                            }
                            if (key == 'otherFlag') {
                                if (patient_profile.otherData != null && patient_profile.otherData != undefined && patient_profile.otherData != '' && patient_profile.otherData != []) {
                                    var otherProbs = ''
                                    patient_profile.otherData.map((item, index) => {
                                        if (index == 0) {
                                            otherProbs = "Other Problems:"
                                            healthProblems.push(otherProbs)
                                            otherProbs = `${index + 1}.` + item + ""
                                            healthProblems.push(otherProbs)


                                        } else {
                                            otherProbs = `${index + 1}.` + item + ""
                                            healthProblems.push(otherProbs)

                                        }
                                    })


                                }

                            }
                            if (key == 'panicFlag') {
                                healthProblems.push("Panic Attacks")
                            }
                            if (key == 'psoriasisFlag') {
                                healthProblems.push("Psoriasis")
                            }
                            if (key == 'seizureFlag') {
                                healthProblems.push("Seizures")
                            }
                            if (key == 'strokeFlag') {
                                healthProblems.push("Stroke")
                            }
                            if (key == 'thyroidFlag') {
                                healthProblems.push("Thyroid")
                            }
                            if (key == 'utiFlag') {
                                healthProblems.push("Urinary Tract Infection")
                            }
                        }

                    }
                    if (key.indexOf("Family") != -1 && key.indexOf("Flag") != -1) {
                        if (patient_profile[key] == true) {
                            if (key == ("anxietyFlagFamily")) {
                                if (patient_profile.anxietyAnswer != null && patient_profile.anxietyAnswer != undefined && patient_profile.anxietyAnswer != '') {
                                    familyProblems.push("Anxiety(" + patient_profile.anxietyAnswer + ")")
                                }
                            }
                            if (key == ("arrhythmiasFlagFamily")) {
                                if (patient_profile.arrhythmiasAnswer != null && patient_profile.arrhythmiasAnswer != undefined && patient_profile.arrhythmiasAnswer != '') {
                                    familyProblems.push("Arrhythmias(" + patient_profile.arrhythmiasAnswer + ")")
                                }
                            }
                            if (key == ("asthmaFlagFamily")) {
                                if (patient_profile.asthamaAnswer != null && patient_profile.asthamaAnswer != undefined && patient_profile.asthamaAnswer != '') {
                                    familyProblems.push("Asthma(" + patient_profile.asthamaAnswer + ")")
                                }
                            }
                            if (key == ("bpFlagFamily")) {
                                if (patient_profile.bpAnswer != null && patient_profile.bpAnswer != undefined && patient_profile.bpAnswer != '') {
                                    familyProblems.push("High Blood Pressure(" + patient_profile.bpAnswer + ")")
                                }
                            }


                            if (key == ("cancerFlagFamily")) {
                                if (patient_profile.cancerFamilyData != null && patient_profile.cancerFamilyData != undefined && patient_profile.cancerFamilyData != '' && patient_profile.cancerFamilyData != []) {
                                    var cancerProb = ''
                                    patient_profile.cancerFamilyData.map((item, index) => {
                                        if (index == 0) {
                                            cancerProb = "Cancer:"
                                            familyProblems.push(cancerProb)
                                            cancerProb = `${index + 1}.` + item + ":" + patient_profile.cancerFamilyAnswer[index] + ""
                                            familyProblems.push(cancerProb)


                                        } else {
                                            cancerProb = `${index + 1}.` + item + ":" + patient_profile.cancerFamilyAnswer[index] + ""
                                            familyProblems.push(cancerProb)

                                        }
                                    })
                                }
                            }


                            if (key == ("copdFlagFamily")) {
                                if (patient_profile.copdAnswer != null && patient_profile.copdAnswer != undefined && patient_profile.copdAnswer != '') {
                                    familyProblems.push("COPD(" + patient_profile.copdAnswer + ")")
                                }
                            }
                            if (key == ("depressionsFlagFamily")) {
                                if (patient_profile.adepressionsAnswer != null && patient_profile.depressionsAnswer != undefined && patient_profile.depressionsAnswer != '') {
                                    familyProblems.push("Depression(" + patient_profile.depressionsAnswer + ")")
                                }
                            }
                            if (key == ("diabetesFlagFamily")) {
                                if (patient_profile.diabetesAnswer != null && patient_profile.diabetesAnswer != undefined && patient_profile.diabetesAnswer != '') {
                                    familyProblems.push("Diabetes(" + patient_profile.diabetesAnswer + ")")
                                }
                            }
                            if (key == ("eczemaFlagFamily")) {
                                if (patient_profile.eczemaAnswer != null && patient_profile.eczemaAnswer != undefined && patient_profile.eczemaAnswer != '') {
                                    familyProblems.push("Eczema(" + patient_profile.eczemaAnswer + ")")
                                }
                            }
                            if (key == ("headFlagFamily")) {
                                if (patient_profile.headAnswer != null && patient_profile.headAnswer != undefined && patient_profile.headAnswer != '') {
                                    familyProblems.push("Headaches/Migraines(" + patient_profile.headAnswer + ")")
                                }
                            }
                            if (key == ("heartFlagFamily")) {
                                if (patient_profile.heartAnswer != null && patient_profile.heartAnswer != undefined && patient_profile.heartAnswer != '') {
                                    familyProblems.push("Heart Problems(" + patient_profile.heartAnswer + ")")
                                }
                            }
                            if (key == ("kidneyFlagFamily")) {
                                if (patient_profile.kidneyAnswer != null && patient_profile.kidneyAnswer != undefined && patient_profile.kidneyAnswer != '') {
                                    familyProblems.push("Kidney Problems(" + patient_profile.kidneyAnswer + ")")
                                }
                            }

                            if (key == ("panicFlagFamily")) {
                                if (patient_profile.panicAnswer != null && patient_profile.panicAnswer != undefined && patient_profile.panicAnswer != '') {
                                    familyProblems.push("Panic Attacks(" + patient_profile.panicAnswer + ")")
                                }
                            }
                            if (key == ("psoriasisFlagFamily")) {
                                if (patient_profile.psoriasisAnswer != null && patient_profile.psoriasisAnswer != undefined && patient_profile.psoriasisAnswer != '') {
                                    familyProblems.push("Psoriasis(" + patient_profile.psoriasisAnswer + ")")
                                }
                            }
                            if (key == ("seizureFlagFamily")) {
                                if (patient_profile.seizureAnswer != null && patient_profile.seizureAnswer != undefined && patient_profile.seizureAnswer != '') {
                                    familyProblems.push("Seizures(" + patient_profile.seizureAnswer + ")")
                                }
                            }
                            if (key == ("strokeFlagFamily")) {
                                if (patient_profile.strokeAnswer != null && patient_profile.strokeAnswer != undefined && patient_profile.strokeAnswer != '') {
                                    familyProblems.push("Stroke(" + patient_profile.strokeAnswer + ")")
                                }
                            }
                            if (key == ("thyroidFlagFamily")) {
                                if (patient_profile.thyroidAnswer != null && patient_profile.thyroidAnswer != undefined && patient_profile.thyroidAnswer != '') {
                                    familyProblems.push("Thyroid Problems(" + patient_profile.thyroidAnswer + ")")
                                }
                            }
                            if (key == ("utiFlagFamily")) {
                                if (patient_profile.utiAnswer != null && patient_profile.utiAnswer != undefined && patient_profile.utiAnswer != '') {
                                    familyProblems.push("Urinary Tract Infection(" + patient_profile.utiAnswer + ")")
                                }
                            }
                            if (key == ("otherFlagFamily")) {
                                if (patient_profile.otherFamilyData != null && patient_profile.otherFamilyData != undefined && patient_profile.otherFamilyData != '' && patient_profile.otherFamilyData != []) {
                                    var otherProbs = ''
                                    patient_profile.otherFamilyData.map((item, index) => {
                                        if (index == 0) {
                                            otherProbs = "Other Problems:"
                                            familyProblems.push(otherProbs)
                                            otherProbs = `${index + 1}.` + item + ":" + patient_profile.otherFamilyAnswer[index] + ""
                                            familyProblems.push(otherProbs)


                                        } else {
                                            otherProbs = `${index + 1}.` + item + ":" + patient_profile.otherFamilyAnswer[index] + ""
                                            familyProblems.push(otherProbs)

                                        }
                                    })
                                }
                            }

                        }

                    }
                }
            }

        }
        this.setState({check: Math.random()})
    }
    onCancel = () => {
        this.setState({showInsuranceEditModal: false});
    }

    render() {
        console.log("gmt ", this.props.gmtOffset)
        let {insuranceNumber, insuranceFront, insuranceBack} = this.state;
        const chatlist = this.props.prev_chats.slice(0, this.state.limit).map(chat => {
            const messagesList = chat.messages.map(message => {
                if (message.receiveMessage.direction === 'left') {
                    return <LeftMessage
                        key={message.uid}
                        content={message.receiveMessage.content}
                        patientName={'Patient'}
                    />
                } else {
                    return <RightMessage
                        key={message.uid}
                        content={message.receiveMessage.content}
                        doctorName={chat.name}
                        doctorImg={chat.image}
                    />
                }
            });
            return (
                <Fragment key={chat.uid}>
                    <div className={classes.list_container}
                         onClick={() => this.showHistoryMessages(chat.uid, chat.category, chat.issue)}>
                        <div
                            className={chat.uid === this.state.selectedChat ? classes.selected_item_container : classes.item_container}>
                            <div className={classes.item_content_view}>
                                <div
                                    className={chat.uid === this.state.selectedChat ? classes.first_selected_item_name : classes.first_item_name}>
                                    <TimeAgo datetime={chat.timestamp}/></div>
                                <div className={classes.categoryTab}>
                                    {!Boolean(chat.findings) && <div
                                        className={chat.uid === this.state.selectedChat ? classes.selected_item_name : classes.item_name}>
                                        <span className={classes.header_attr}>Symptom:</span> {chat.category}</div>}
                                    {!Boolean(chat.findings) && <div
                                        className={chat.uid === this.state.selectedChat ? classes.selected_item_name : classes.item_name}>
                                        <span className={classes.header_attr}>Issue:</span> {chat.issue}</div>}
                                    {Boolean(chat.findings) && <div
                                        className={chat.uid === this.state.selectedChat ? classes.selected_item_name : classes.item_name}>
                                        <span className={classes.header_attr}>Objective Findings:</span> {chat.findings}
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={this.state.showSelectedMessages === chat.uid ? classes.response_item_selected : classes.response_item}>
                        {messagesList}
                    </div>
                </Fragment>
            )
        });
        let date = new Date();
        let gmtOffset = (this.props.gmtOffset) ? this.props.gmtOffset : 0;
        let utc = date.getTime() + (date.getTimezoneOffset() * 60000);
        let current_time = (new Date(utc + (3600000 * gmtOffset))).toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
        let healthInfoFound = Boolean(
            (this.props.currentSleep !== "NA" && Boolean(this.props.currentSleep)) ||
            (this.props.averageSleep !== "NA" && Boolean(this.props.averageSleep)) ||
            (this.props.heartRate !== "NA" && Boolean(this.props.heartRate)) ||
            (this.props.restingHeartRate !== "NA" && Boolean(this.props.restingHeartRate)) ||
            ((this.props.heightFeet !== "NA" && Boolean(this.props.heightFeet)) || (this.props.heightCm !== "NA" && Boolean(this.props.heightCm))) ||
            ((this.props.weightLbs !== "NA" && Boolean(this.props.weightLbs)) || (this.props.weightKg !== "NA" && Boolean(this.props.weightKg))) ||
            (this.props.periodStartDate !== "NA" && Boolean(this.props.periodStartDate)) ||
            (this.props.flowType !== "NA" && Boolean(this.props.flowType)) ||
            (this.props.stepCount !== "NA" && Boolean(this.props.stepCount)) ||
            (this.props.runningWalking !== "NA" && Boolean(this.props.runningWalking))
        );

        return (
            <div className={classes.main}>
                {this.renderPopOut()}
                {this.openWindow()}
                <div className={classes.container}>
                    <div className={classes.content_container}>
                        <div className={classes.main_content_container}>
                            <div className={classes.detailed_view}>
                                <div className={classes.detailed_view_container}>
                                    {(this.props.videoState === true || this.props.audioState) && this.props.minimizeVideo === true &&
                                    <div className={classes.videoContainer} onClick={() => this.props.maximizeVideo()}>
                                        <div className={classes.videoText}>Click to return to call</div>
                                    </div>
                                    }
                                    {this.props.selectedConversation !== null &&
                                    <Fragment>
                                        {this.state.showEditBlock && this.props.doctorIsValid &&
                                        <div className={classes.generalInformation}>
                                            <Fragment>
                                                <div className={classes.openchatlist}
                                                     onClick={() => this.showSOAPInfo()}>
                                                    <div className={classes.openchatlistSection}>
                                                        <div
                                                            className={this.state.showSOAPNotes === true ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                                                            <svg fill="#4384F5" width="12px" height="8px"
                                                                 viewBox="0 0 12 8">
                                                                <path fill="inherit" fillRule="evenodd"
                                                                      d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                                            </svg>
                                                        </div>
                                                        <div className={classes.subListTitle}>SOAP Notes</div>
                                                    </div>
                                                </div>
                                                {this.state.showSOAPNotes &&
                                                <div className={classes.expandable_section} ref={this.soapnotes}>
                                                    {this.props.isLoading === false &&
                                                    <div className={classes.general_info_section}>
                                                        <div className={classes.SOAP_div}>
                                                            <span
                                                                className={classes.SOAP_heading}>Subjective Symptoms:</span>
                                                            <TextareaAutosize
                                                                className={classes.SOAP_textarea}
                                                                //placeholder="Pain in the leg"
                                                                placeholder="Start typing here..."
                                                                onChange={(text) => this.onSubjectSymptomsChange(text)}
                                                                value={this.state.symptoms}
                                                            />
                                                        </div>
                                                        <div className={classes.SOAP_div}>
                                                            <span
                                                                className={classes.SOAP_heading}>Objective Findings:</span>
                                                            <TextareaAutosize
                                                                className={classes.SOAP_textarea}
                                                                //placeholder="Burn in the knee"
                                                                placeholder="Start typing here..."
                                                                onChange={(text) => this.onObjectiveFindingsChange(text)}
                                                                value={this.state.findings}
                                                            />
                                                            {this.state.showCannedResponseForObjectiveFindingsList &&
                                                            <div className={classes.canned_response_list}>
                                                                <div className={classes.canned_response_view}>
                                                                    <CannedResponsesList
                                                                        canned_responses_data={this.state.canned_responses}
                                                                        onCannedResponseClick={(uid, short_cut, text) => this.onObjectiveCannedResponseClick(uid, short_cut, text)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            }
                                                        </div>
                                                        <div className={classes.SOAP_div}>
                                                            <span className={classes.SOAP_heading}>Assessment:</span>
                                                            <TextareaAutosize
                                                                className={classes.SOAP_textarea}
                                                                //placeholder="Long term / Short term"
                                                                placeholder="Start typing here..."
                                                                onChange={(text) => this.onAssessmentGoalsChange(text)}
                                                                value={this.state.assessment}
                                                            />
                                                            {this.state.showCannedResponseForAssessmentList &&
                                                            <div className={classes.canned_response_list}>
                                                                <div className={classes.canned_response_view}>
                                                                    <CannedResponsesList
                                                                        canned_responses_data={this.state.canned_responses_assessment}
                                                                        onCannedResponseClick={(uid, short_cut, text) => this.onAssessmentCannedResponseClick(uid, short_cut, text)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            }
                                                        </div>
                                                        <div className={classes.SOAP_div}>
                                                            <span className={classes.SOAP_heading}>Plan:</span>
                                                            <TextareaAutosize
                                                                className={classes.SOAP_textarea}
                                                                //placeholder="Future treament / Frequency / Self-care"
                                                                placeholder="Start typing here..."
                                                                onChange={(text) => this.onPlanChange(text)}
                                                                value={this.state.plan}
                                                            />
                                                            {this.state.showCannedResponseForPlanList &&
                                                            <div className={classes.canned_response_list}>
                                                                <div className={classes.canned_response_view}>
                                                                    <CannedResponsesList
                                                                        canned_responses_data={this.state.canned_responses_plan}
                                                                        onCannedResponseClick={(uid, short_cut, text) => this.onPlanCannedResponseClick(uid, short_cut, text)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            }
                                                        </div>
                                                        <div className={classes.SOAP_buttons}>
                                                            <span className={classes.SOAP_list_text}
                                                                  onClick={() => this.onSaveSOAP()}>Save</span>
                                                            <span className={classes.SOAP_clear_button} onClick={() => {
                                                                this.onSaveSOAP();
                                                                this.props.closeChat();
                                                            }}>End Encounter</span>
                                                            <span className={classes.SOAP_clear_button}
                                                                  onClick={() => this.onClearSOAP()}>Clear</span>
                                                        </div>
                                                    </div>
                                                    }
                                                    {this.props.isLoading &&
                                                    <div className={classes.spinner_view}>
                                                        <Spinner animation="border" size="sm" variant="light"/>
                                                    </div>
                                                    }
                                                </div>
                                                }
                                            </Fragment>
                                        </div>
                                        }
                                        {this.props.healthInsuranceCardFront && this.props.healthInsuranceCardBack && this.props.healthInsuranceCardNumber &&
                                        <div className={classes.openchatlist}
                                             onClick={() => this.showInsuranceInformation()}>
                                            <div className={classes.openchatlistSection}>
                                                <div
                                                    className={this.state.showInsurance === true ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                                                    <svg fill="#4384F5" width="12px" height="8px" viewBox="0 0 12 8">
                                                        <path fill="inherit" fillRule="evenodd"
                                                              d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                                    </svg>
                                                </div>
                                                <div className={classes.subListTitle}>Insurance Information</div>
                                            </div>
                                        </div>
                                        }
                                        {this.props.healthInsuranceCardFront && this.props.healthInsuranceCardBack && this.props.healthInsuranceCardNumber && this.state.showInsurance &&
                                        <div className={classes.insuranceDetailedView}>
                                            <div className={classes.insuranceRow}>
                                                <span className={classes.headingText}>Insurance #: </span>
                                                <div className={classes.row}>
                                                    <span
                                                        className={classes.subHeadingText}>{this.props.healthInsuranceCardNumber}</span>
                                                    <button className={classes.editButton}
                                                            onClick={() => this.showInsuranceModal()}>Edit
                                                    </button>
                                                </div>
                                            </div>
                                            <div className={classes.insuranceRow}>
                                                <span className={classes.headingText}>Front View: </span>
                                                <span className={classes.subHeadingText}>
                                                            <a href={this.props.healthInsuranceCardFront}
                                                               target="_blank" rel="noopener noreferrer" alt="">
                                                                <span>View Image</span>
                                                            </a>
                                                        </span>
                                            </div>
                                            <div className={classes.insuranceRow}>
                                                <span className={classes.headingText}>Back View: </span>
                                                <span className={classes.subHeadingText}>
                                                            <a href={this.props.healthInsuranceCardBack} target="_blank"
                                                               rel="noopener noreferrer" alt="">
                                                                <span>View Image</span>
                                                            </a>
                                                        </span>
                                            </div>
                                        </div>
                                        }
                                        {this.props.selectedConversation !== null && (this.props.showUpView === true || this.state.showfollowUpView === true) && this.props.doctorIsValid &&
                                        <div className={classes.openchatlist}
                                             onClick={() => this.showPatientFollowUp()}>
                                            <div className={classes.openchatlistSection}>
                                                <div
                                                    className={this.state.showFollowUp === true ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                                                    <svg fill="#4384F5" width="12px" height="8px" viewBox="0 0 12 8">
                                                        <path fill="inherit" fillRule="evenodd"
                                                              d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                                    </svg>
                                                </div>
                                                <div className={classes.subListTitle}>Patient Follow Up</div>
                                            </div>
                                        </div>
                                        }
                                        {this.state.showFollowUp && (this.props.showUpView === true || this.state.showfollowUpView === true) && this.props.doctorIsValid &&
                                        <div className={classes.loadMoreFollowUp1}>
                                            {this.props.isLoading === false &&
                                            <div>
                                                <span>Follow Up in:</span>
                                                <div onChange={this.onChangeValue} className={classes.radioButton}>
                                                    <input type="radio" value="3 days" name="gender"
                                                           ref={this.buttonRef1}/> 3 days
                                                    <input className={classes.radioButton1} type="radio" value="1 week"
                                                           name="gender" ref={this.buttonRef2}/> 1 week
                                                    <input className={classes.radioButton1} type="radio" value="3 weeks"
                                                           name="gender" ref={this.buttonRef3}/> 3 weeks
                                                </div>
                                                <div className={classes.radioButton}>
                                                    <span className={classes.followUpText}>at</span>
                                                    <input
                                                        id="date-local"
                                                        type="datetime-local"
                                                        value={this.state.dateTime}
                                                        onChange={this.handleChangeFollowUpDateTime}
                                                        className={classes.followUpInput}
                                                    />
                                                </div>
                                                <Button variant="primary" size="sm" className={classes.simpleButton}
                                                        onClick={() => this.handleSendRequest()}>Send
                                                    Request</Button>{' '}
                                                <Button variant="secondary " size="sm" className={classes.simpleButton1}
                                                        onClick={() => this.handleConfirmRequest()}>Confirm</Button>{' '}
                                                <Button variant="secondary " size="sm" className={classes.simpleButton2}
                                                        onClick={() => this.handleSkipRequest()}>As Needed</Button>{' '}
                                            </div>
                                            }
                                            {this.props.isLoading &&
                                            <div className={classes.spinner_view}>
                                                <Spinner animation="border" size="sm" variant="light"/>
                                            </div>
                                            }
                                        </div>
                                        }
                                        <div className={classes.generalInformation}>
                                            <Fragment>
                                                <div className={classes.openchatlist}
                                                     onClick={() => this.showGeneralInfo()}>
                                                    <div className={classes.openchatlistSection}>
                                                        <div
                                                            className={this.state.show_general_info === true ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                                                            <svg fill="#4384F5" width="12px" height="8px"
                                                                 viewBox="0 0 12 8">
                                                                <path fill="inherit" fillRule="evenodd"
                                                                      d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                                            </svg>
                                                        </div>
                                                        <div className={classes.subListTitle}>General Information</div>
                                                    </div>
                                                </div>
                                                {this.state.show_general_info &&
                                                <div className={classes.expandable_section}>
                                                    {
                                                        <div className={classes.general_info_section}>
                                                            <div><span className={classes.header_attr}>Phone #:</span>
                                                                <span
                                                                    className={classes.header_attr_value}>{this.props.patient_phone_number || 'N/A'}</span>
                                                            </div>
                                                            <div className={classes.addressSegment}><span
                                                                className={classes.header_attr}>Address:</span> <span
                                                                className={classes.header_attr_value}>{this.props.complete_address}</span>
                                                            </div>
                                                            <div><span
                                                                className={classes.header_attr}>Local Time:</span> <span
                                                                className={classes.header_attr_value}>{current_time}</span>
                                                            </div>
                                                            <div><span className={classes.header_attr}># of Prior Encounters:</span>
                                                                <span
                                                                    className={classes.header_attr_value}>{this.props.convoCount}</span>
                                                            </div>
                                                            <div><span className={classes.header_attr}>Device:</span>
                                                                <span
                                                                    className={classes.header_attr_value}>{this.props.platform.toUpperCase()}</span>
                                                            </div>
                                                            <div><span className={classes.header_attr}>Chat Session ID:</span>
                                                                <span
                                                                    className={classes.header_attr_value}>{this.props.selectedConversation}</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                }
                                            </Fragment>

                                        </div>
                                        <div className={classes.generalInformation}>
                                            <Fragment>
                                                <div className={classes.openchatlist}
                                                     onClick={() => this.showPatientProfile()}>
                                                    <div className={classes.openchatlistSection}>
                                                        <div
                                                            className={this.state.patient_profile === true ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                                                            <svg fill="#4384F5" width="12px" height="8px"
                                                                 viewBox="0 0 12 8">
                                                                <path fill="inherit" fillRule="evenodd"
                                                                      d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                                            </svg>
                                                        </div>
                                                        <div className={classes.subListTitle}>Cummulative Patient
                                                            Profile
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.patient_profile && Object.keys(this.props.patient_profile).length !== 0 && this.props.patient_profile != null && this.props.patient_profile != undefined &&
                                                <div className={classes.loadMoreFollowUp}>

                                                    <div className={classes.general_info_section}>
                                                        {this.props.patient_profile != null && this.props.patient_profile != undefined &&
                                                        <Fragment>
                                                            <div onClick={() => {
                                                                const modalTitle = "Patient Profile";
                                                                if (!Boolean(this.modalWindow)) {
                                                                    this.modalWindow = window.open("/view_pdf", modalTitle);
                                                                    this.modalWindow.addEventListener('load', () => {
                                                                            this.modalWindow.document.title = modalTitle;
                                                                            this.renderPopOut();
                                                                        }, false
                                                                    );
                                                                } else {
                                                                    this.modalWindow.close();
                                                                    this.modalWindow = window.open("/view_pdf", modalTitle);
                                                                    this.modalWindow.addEventListener('load', () => {
                                                                            this.modalWindow.document.title = modalTitle;
                                                                            this.renderPopOut();
                                                                        }, false
                                                                    );
                                                                }

                                                            }}><span className={classes.header_attr_link}>View patients medical history</span>
                                                                <span className={classes.header_attr_value}></span>
                                                            </div>
                                                            <div style={{height: '28px'}}><span
                                                                className={classes.header_attr}>Height:</span></div>
                                                            <div style={{height: '28px'}}><span
                                                                className={classes.header_attr_value}>{this.props.patient_profile.patient_height !== "NA" && Boolean(this.props.patient_profile.patient_height) ? this.props.patient_profile.patient_height + ' cms' : '-'}</span>
                                                            </div>
                                                            <div style={{height: '28px'}}><span
                                                                className={classes.header_attr}>Weight:</span></div>
                                                            <div style={{height: '28px'}}><span
                                                                className={classes.header_attr_value}>{this.props.patient_profile.patient_weight !== "NA" && Boolean(this.props.patient_profile.patient_weight) ? this.props.patient_profile.patient_weight + ' lbs' : '-'}</span>
                                                            </div>
                                                            <div style={{height: '28px'}}><span
                                                                className={classes.header_attr}>Blood Group:</span>
                                                            </div>
                                                            <div style={{height: '28px'}}><span
                                                                className={classes.header_attr_value}>{this.props.patient_profile.bloodType != null && this.props.patient_profile.bloodType != undefined && this.props.patient_profile.bloodType != '' ? this.props.patient_profile.bloodType : '-'}</span>
                                                            </div>

                                                            <div style={{height: '28px'}}><span
                                                                className={classes.header_attr}>Smoke / Use Tobacco?</span>
                                                            </div>
                                                            <div style={{height: '28px'}}><span
                                                                className={classes.header_attr_value}>{this.props.patient_profile.smokeFlag == true ? this.props.patient_profile.smokeAnswer != null && this.props.patient_profile.smokeAnswer != undefined && this.props.patient_profile.smokeAnswer != '' ? this.props.patient_profile.smokeAnswer : this.props.patient_profile.smokeAnswer != null && this.props.patient_profile.smokeAnswer != undefined ? 'No' : '-' : this.props.patient_profile.smokeFlag != null && this.props.patient_profile.smokeFlag != undefined ? 'No' : '-'}</span>
                                                            </div>

                                                            <div style={{height: '28px'}}><span
                                                                className={classes.header_attr}>Drink Alcohol?</span>
                                                            </div>
                                                            <div style={{height: '28px'}}><span
                                                                className={classes.header_attr_value}>{this.props.patient_profile.alcoholFlag == true ? this.props.patient_profile.alocoholAnswer != null && this.props.patient_profile.alocoholAnswer != undefined && this.props.patient_profile.alocoholAnswer != '' ? this.props.patient_profile.alocoholAnswer : this.props.patient_profile.alocoholAnswer != null && this.props.patient_profile.alocoholAnswer != undefined ? 'No' : '-' : this.props.patient_profile.alcoholFlag != null && this.props.patient_profile.alcoholFlag != undefined ? 'No' : '-'}</span>
                                                            </div>
                                                            <div><span className={classes.header_attr}>Allergies:</span>
                                                                <span
                                                                    className={classes.header_attr_value}>{this.props.patient_profile.allergyData == null || this.props.patient_profile.allergyData == undefined || this.props.patient_profile.allergyData == [] ?
                                                                    <div>None</div> : this.props.patient_profile.allergyData.toString().split(',').map((it, i) =>
                                                                        <div style={{marginTop: -10}}
                                                                             key={'x' + i}>{it}</div>)}</span></div>
                                                            <div><span
                                                                className={classes.header_attr}>Medications:</span>
                                                                <span
                                                                    className={classes.header_attr_value}>{this.props.patient_profile.selected == null || this.props.patient_profile.selected == undefined || this.props.patient_profile.selected == [] ?
                                                                    <div>None</div> : this.props.patient_profile.selected.toString().split(',').map((it, i) =>
                                                                        <div style={{marginTop: -10}}
                                                                             key={'x' + i}>{it}</div>)}</span></div>
                                                            <div><span className={classes.header_attr}>Current Medical Issues:</span>
                                                                <span
                                                                    className={classes.header_attr_value}>{healthProblems.length == 0 ? "-" : healthProblems.toString().split(',').map((it, i) =>
                                                                    <div style={{marginTop: -10}}
                                                                         key={'x' + i}>{it}</div>)}</span></div>
                                                            <div><span className={classes.header_attr}>Family Medical History:</span>
                                                                <span
                                                                    className={classes.header_attr_value}>{familyProblems.length == 0 ? "-" : familyProblems.toString().split(',').map((it, i) =>
                                                                    <div style={{marginTop: -10}}
                                                                         key={'x' + i}>{it}</div>)}</span></div>


                                                        </Fragment>
                                                        }
                                                    </div>


                                                </div>
                                                }
                                                {this.state.patient_profile && (Object.keys(this.props.patient_profile).length === 0 || this.props.patient_profile == null || this.props.patient_profile == undefined) &&
                                                <div className={classes.list}>
                                                    <div className={classes.list_text}>Patient Profile Not Available
                                                    </div>
                                                </div>
                                                }
                                            </Fragment>
                                        </div>
                                        <div className={classes.generalInformation}>
                                            <Fragment>
                                                <div className={classes.openchatlist}
                                                     onClick={() => this.showHealthInfo()}>
                                                    <div className={classes.openchatlistSection}>
                                                        <div
                                                            className={this.state.show_health_info === true ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                                                            <svg fill="#4384F5" width="12px" height="8px"
                                                                 viewBox="0 0 12 8">
                                                                <path fill="inherit" fillRule="evenodd"
                                                                      d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                                            </svg>
                                                        </div>
                                                        <div className={classes.subListTitle}>Digital Vitals</div>
                                                    </div>
                                                </div>
                                                {this.state.show_health_info && healthInfoFound &&
                                                <div className={classes.expandable_section}>

                                                    <div className={classes.general_info_section}>
                                                        {this.props.currentSleep !== "NA" && Boolean(this.props.currentSleep) &&
                                                        <div>
                                                                        <span className={classes.header_attr}>
                                                                            Current Sleep:
                                                                    </span>
                                                            <span
                                                                className={classes.header_attr_value}> {String(this.props.currentSleep).replace(/,/g, ' hrs ') === '' ? '-' : String(this.props.currentSleep).replace(/,/g, ' hrs ') + ' mins'}
                                                                        </span>
                                                        </div>
                                                        }
                                                        {this.props.averageSleep !== "NA" && Boolean(this.props.averageSleep) &&
                                                        <div>
                                                                        <span className={classes.header_attr}>
                                                                            Average Sleep:
                                                                    </span>
                                                            <span
                                                                className={classes.header_attr_value}> {String(this.props.averageSleep).replace(/,/g, ' hrs ') === 'undefined' ? '-' : String(this.props.averageSleep).replace(/,/g, ' hrs ') + ' mins'}
                                                                        </span>
                                                        </div>
                                                        }
                                                        {this.props.heartRate !== "NA" && Boolean(this.props.heartRate) &&
                                                        <div>
                                                                        <span className={classes.header_attr}>
                                                                            Current BPM:
                                                                    </span>
                                                            <span
                                                                className={classes.header_attr_value}> {this.props.heartRate === '' ? '-' : this.props.heartRate}
                                                                        </span>
                                                        </div>
                                                        }
                                                        {this.props.restingHeartRate !== "NA" && Boolean(this.props.restingHeartRate) &&
                                                        <div>
                                                                        <span className={classes.header_attr}>
                                                                            Resting BPM:
                                                                    </span>
                                                            <span
                                                                className={classes.header_attr_value}> {this.props.restingHeartRate === '' ? '-' : this.props.restingHeartRate}
                                                                        </span>
                                                        </div>
                                                        }
                                                        {((this.props.heightFeet !== "NA" && Boolean(this.props.heightFeet)) || (this.props.heightCm !== "NA" && Boolean(this.props.heightCm))) &&
                                                        <div>
                                                                        <span className={classes.header_attr}>
                                                                            Height:
                                                                    </span>
                                                            <span
                                                                className={classes.header_attr_value}> {String(this.props.heightFeet) === '' ? '-' : String(this.props.heightFeet).replace(/,/g, `'`)}{`'`} / {this.props.heightCm === '' ? '-' : this.props.heightCm + ' cms'}
                                                                        </span>
                                                        </div>
                                                        }
                                                        {((this.props.weightLbs !== "NA" && Boolean(this.props.weightLbs)) || (this.props.weightKg !== "NA" && Boolean(this.props.weightKg))) &&
                                                        <div>
                                                                        <span className={classes.header_attr}>
                                                                            Weight:
                                                                    </span>
                                                            <span
                                                                className={classes.header_attr_value}> {this.props.weightLbs === 'NA' ? 'NA' : this.props.weightLbs === '' ? '-' : parseInt(this.props.weightLbs) + ' lbs'} / {this.props.weightKg === '' ? '-' : this.props.weightKg + ' kgs'}
                                                                        </span>
                                                        </div>
                                                        }
                                                        {this.props.periodStartDate !== "NA" && Boolean(this.props.periodStartDate) &&
                                                        <div>
                                                                        <span className={classes.header_attr}>
                                                                            Last Period:
                                                                    </span>
                                                            <span
                                                                className={classes.header_attr_value}> {this.props.periodStartDate === '' ? '-' : this.props.periodStartDate}
                                                                        </span>
                                                        </div>
                                                        }
                                                        {this.props.flowType !== "NA" && Boolean(this.props.flowType) &&
                                                        <div>
                                                                        <span className={classes.header_attr}>
                                                                            Flow Type:
                                                                    </span>
                                                            <span
                                                                className={classes.header_attr_value}> {this.props.flowType === '' ? '-' : this.props.flowType}
                                                                        </span>
                                                        </div>
                                                        }
                                                        {this.props.stepCount !== "NA" && Boolean(this.props.stepCount) &&
                                                        <div>
                                                                        <span className={classes.header_attr}>
                                                                            Avg Movement:
                                                                    </span>
                                                            <span
                                                                className={classes.header_attr_value}> {this.props.stepCount === '' ? '-' : this.props.stepCount + ' steps'}
                                                                        </span>
                                                        </div>
                                                        }
                                                        {this.props.runningWalking !== "NA" && Boolean(this.props.runningWalking) &&
                                                        <div>
                                                                        <span className={classes.header_attr}>
                                                                            Avg Walking Distance:
                                                                    </span>
                                                            <span
                                                                className={classes.header_attr_value}> {this.props.runningWalking === 'NA' ? 'NA' : this.props.runningWalking === '' ? '-' : parseFloat(this.props.runningWalking).toFixed(2) + ' kms'}
                                                                        </span>
                                                        </div>
                                                        }
                                                    </div>

                                                </div>
                                                }
                                            </Fragment>
                                            {this.state.show_health_info && !healthInfoFound &&
                                            <div className={classes.list}>
                                                <div className={classes.list_text}>No information available</div>
                                            </div>
                                            }
                                        </div>

                                        <div className={classes.openchatlist} onClick={() => this.showHistoryList()}>
                                            <div className={classes.openchatlistSection}>
                                                <div
                                                    className={this.state.show_history_list === true ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                                                    <svg fill="#4384F5" width="12px" height="8px" viewBox="0 0 12 8">
                                                        <path fill="inherit" fillRule="evenodd"
                                                              d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                                    </svg>
                                                </div>
                                                <div className={classes.subListTitle}>Past Encounters</div>
                                            </div>
                                        </div>
                                    </Fragment>
                                    }
                                    {this.state.show_history_list && this.props.prev_chats.length !== 0 &&
                                    <Fragment>
                                        {this.props.unReadCountObject !== undefined &&
                                        <div
                                            className={this.state.currentChatSelected ? classes.selected_currentChat : classes.currentChat}
                                            onClick={() => {
                                                this.loadCurrent()
                                            }}>
                                            Current Chat
                                            {this.props.unReadCountObject.unreadCount !== 0 &&
                                            <div className={classes.list_unread_style}>
                                                <span
                                                    className={classes.unread_msg_style}>{this.props.unReadCountObject.unreadCount}</span>
                                            </div>
                                            }
                                        </div>
                                        }
                                        <div className={classes.list}>
                                            {chatlist}
                                        </div>
                                        <div className={classes.spacer}></div>
                                        {this.state.limit < this.props.prev_chats.length &&
                                        <div className={classes.loadMore} onClick={() => this.onLoadMore()}> Load More
                                            Chat </div>
                                        }
                                    </Fragment>
                                    }
                                    {this.props.selectedConversation !== null && this.state.show_history_list && this.props.prev_chats.length === 0 &&
                                    <div className={classes.list}>
                                        <div className={classes.list_text}>No Chat History</div>
                                    </div>
                                    }
                                    {this.props.selectedConversation != null && !this.state.showEditBlock && this.props.doctorIsValid &&
                                    <div className={classes.generalInformation}>
                                        <Fragment>
                                            <div className={classes.openchatlist} onClick={() => this.showSOAPInfo()}>
                                                <div className={classes.openchatlistSection}>
                                                    <div
                                                        className={this.state.showSOAPNotes === true ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                                                        <svg fill="#4384F5" width="12px" height="8px"
                                                             viewBox="0 0 12 8">
                                                            <path fill="inherit" fillRule="evenodd"
                                                                  d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                                        </svg>
                                                    </div>
                                                    <div className={classes.subListTitle}>SOAP Notes</div>
                                                </div>
                                            </div>
                                        </Fragment>
                                        {this.state.showSOAPNotes &&
                                        <div className={classes.SOAP_list}>
                                            <div className={classes.SOAP_list_text}
                                                 onClick={() => this.editSOAPNotes()}>Edit Notes
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    }
                                    {this.props.selectedConversation != null && this.props.showUpView === false && this.props.doctorIsValid && !this.state.showfollowUpView &&
                                    <div className={classes.openchatlist} onClick={() => this.showPatientFollowUp()}>
                                        <div className={classes.openchatlistSection}>
                                            <div
                                                className={this.state.showFollowUp === true ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                                                <svg fill="#4384F5" width="12px" height="8px" viewBox="0 0 12 8">
                                                    <path fill="inherit" fillRule="evenodd"
                                                          d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                                </svg>
                                            </div>
                                            <div className={classes.subListTitle}>Patient Follow Up</div>
                                        </div>
                                    </div>
                                    }
                                    {this.props.selectedConversation !== null && this.state.showFollowUp && this.props.showUpView === false && this.props.doctorIsValid && !this.state.showfollowUpView &&
                                    <div className={classes.loadMoreFollowUp}>
                                        {this.props.isLoading === false &&
                                        <div>
                                            <span>Follow Up in:</span>
                                            <div onChange={this.onChangeValue} className={classes.radioButton}>
                                                <input type="radio" value="3 days" name="gender"
                                                       ref={this.buttonRef1}/> 3 days
                                                <input className={classes.radioButton1} type="radio" value="1 week"
                                                       name="gender" ref={this.buttonRef2}/> 1 week
                                                <input className={classes.radioButton1} type="radio" value="3 weeks"
                                                       name="gender" ref={this.buttonRef3}/> 3 weeks
                                            </div>
                                            <div className={classes.radioButton}>
                                                <span className={classes.followUpText}>at</span>
                                                <input
                                                    id="date-local"
                                                    type="datetime-local"
                                                    value={this.state.dateTime}
                                                    onChange={this.handleChangeFollowUpDateTime}
                                                    className={classes.followUpInput}
                                                />
                                            </div>
                                            <Button variant="primary" size="sm" className={classes.simpleButton}
                                                    onClick={() => this.handleSendRequest()}>Send Request</Button>{' '}
                                            <Button variant="secondary " size="sm" className={classes.simpleButton1}
                                                    onClick={() => this.handleConfirmRequest()}>Confirm</Button>{' '}
                                            <Button variant="secondary " size="sm" className={classes.simpleButton2}
                                                    onClick={() => this.handleSkipRequest()}>As Needed</Button>{' '}
                                        </div>
                                        }
                                        {this.props.isLoading &&
                                        <div className={classes.spinner_view}>
                                            <Spinner animation="border" size="sm" variant="light"/>
                                        </div>
                                        }
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={classes.modal} style={{
                            transform: this.state.showInsuranceEditModal ? 'translateY(0)' : 'translateY(-100vh)',
                            opacity: this.state.showInsuranceEditModal ? '1' : '0'
                        }}>
                            <div className={classes.modalcontainer}>
                                <div className={classes.spandiv}>Insurance Information</div>
                                <div className={classes.inputContainer}>
                                    <div className={classes.subHeading}>Insurance #:</div>
                                    <input
                                        className={classes.input}
                                        onChange={(text) => this.insuranceNumberChange(text)}
                                        value={insuranceNumber}
                                    />
                                </div>
                                <div className={classes.inputContainer}>
                                    <div className={classes.subHeading}>Front View:</div>
                                    <input
                                        className={classes.input}
                                        onChange={(text) => this.insuranceFrontUrl(text)}
                                        value={insuranceFront}
                                    />
                                </div>
                                <div className={classes.inputContainer}>
                                    <div className={classes.subHeading}>Back View:</div>
                                    <input
                                        className={classes.input}
                                        onChange={(text) => this.insuranceBackUrl(text)}
                                        value={insuranceBack}
                                    />
                                </div>
                                <div className={classes.buttonView}>
                                    <button className={classes.saveButton}
                                            onClick={() => this.saveInsuranceCard()}>Save
                                    </button>
                                    <button className={classes.cancelButton} onClick={() => this.onCancel()}>Cancel
                                    </button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, {updateInsuranceCard})(UserDetailedScreen);
