import Axios from "axios";
// import _classes from './_archivestyles.module.css';
import  {Component} from "react";
import {database} from "../../firebase";
import axios from "axios";
import {firebaseRoot} from "../../config";


const {Device} = require("twilio-client");

class CallWithTwilio extends Component {
    constructor(props) {
        super(props);

        this.state = {
            identity: "default",
            status: "",
            ready: false,
            patientNumber: "",
            callRinging: false,
            callConnected: false,
            calling: false,
            callPermission: false,
        };
        // this.setup = this.setup.bind(this);
    }

    async componentDidMount() {
        await this.setState({
            identity: this.props.doctorID ? this.props.doctorID : "default_user",
        });
    }

    // setup(event) {
    //   // prevents form submission and page refresh
    //   event.preventDefault();

    //   //     this.state.device.audio.incoming(false);
    //   //   this.state.device.audio.outgoing(false);
    //   //   this.state.device.audio.disconnect(false);
    // }

    disconnect = () => {
        this.state.device.disconnectAll();
        this.setState({
            ready: false,
            callRinging: false,
            callConnected: false,
            calling: false,
        });
        this.state.device.destroy();
    };

    deviceInitiation = async () => {
        let device;
        this.setState({calling: true});
        await Axios.get(
            `https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/tokenGenerator?identity=${this.state.identity}`,
            {
                headers: {
                    "Access-Control-Allow-Origin": true,
                },
            }
        )
            // .then(response => response.json())
            .then((data) => {
                device = new Device(data.data.data.token, {
                    codecPreferences: ["opus", "pcmu"],
                    fakeLocalDTMF: true,
                    enableRingingState: true,
                });

                this.setState({
                    device: device,
                    callPermission: true,
                });

                this.state.device.on("incoming", (connection) => {
                    // immediately accepts incoming connection
                    //connection.accept();
                    this.setState({
                        status: connection.status(),
                    });
                });

                this.state.device.on("ready", (device) => {
                    this.setState({
                        status: "device ready",
                        ready: true,
                    });
                    this.initiateCall();
                });

                this.state.device.on("error", (error) => {
                    console.log("Twilio.Device Error: " + error.message);
                    this.setState({
                        ready: false,
                        callRinging: false,
                        callConnected: false,
                        calling: false,
                        callPermission: false,
                    });
                });

                this.state.device.on("connect", (connection) => {
                    this.setState({
                        status: connection.status(),
                        callConnected: true,
                        callRinging: false,
                        calling: false,
                    });
                });

                this.state.device.on("disconnect", (connection) => {
                    this.setState({
                        status: connection.status(),
                        callRinging: false,
                        callConnected: false,
                        callling: false,
                        callPermission: false,
                    });
                });
            })
            .catch((err) => console.log(err));
    }

    initiateCall = () => {
        let toNumber = this.props.patientNumber || "";
        const patientId = this.props.patientId;

        toNumber = toNumber.replace(/[^0-9+]/g, "");
        const numbers = toNumber.split("");

        if (toNumber.length === 10) {
            toNumber = `+1${toNumber}`;
        } else if (toNumber.length === 11) {
            toNumber = numbers[0] === "1" ? `+${toNumber}` : toNumber;
        }

        if (patientId) {
            const updates = {[`/users/${patientId}/phone_number`]: toNumber};
            database.ref().update(updates).then(res => console.log(res))
                .catch(error => console.error(error));

        }
        let params = {
            To: toNumber,
            // To: “923336931992”
        };
        try {
            if (this.state.device && this.state.callPermission) {
                let outgoingConnection = this.state.device.connect(params);
                outgoingConnection.on("ringing", function () {
                });
                this.setState({callRinging: true});
            }
        } catch (error) {
            console.log("Twilio call catch error", error);
        }
    };

    render() {
        return (
            <div>
                {this.state.ready && this.state.callRinging
                    ?
                    (<div>{this.props.onRingingComponent}</div>)
                    : this.state.ready && this.state.callConnected
                        ?
                        (<div onClick={this.disconnect}>{this.props.onConnectedComponent}</div>)
                        :
                        this.state.calling
                            ?
                            (<div>{this.props.onCallingComponent}</div>)
                            :
                            (<div onClick={this.deviceInitiation}>{this.props.defaultComponent}</div>)
                }
                {/* <div>
              <p>Getting the device Ready.</p>
              <form>
                <input type="submit" value="Refresh"></input>
              </form>
            </div> */}
                {/* <p>{ this.state.status }</p> */}
            </div>
        );
    }
}

export default CallWithTwilio;
