
import {NavLink} from 'react-router-dom';
import classes from './staff.module.css';

const Header = (props) => {
    return (
        <div className={classes.main_body}>
            <div className={classes.heading}>
                <NavLink to="/agents"
                         className={props.activeLink === 'Agents' ? classes.active : null}
                         onClick={() => props.HeaderItem('Agents')}
                >Staff Members</NavLink>
            </div>
        </div>
    )
}

export default Header;
