import { ENVIRONMENT } from './environment';

let rootURL;
if (ENVIRONMENT === "development") {
    rootURL = 'https://admin.yourdoctors.online/api/0.1/';
}else{
    rootURL = 'https://admin.yourdoctors.online/api/0.1/';
}
export const firebaseRoot = ENVIRONMENT === "development"?"https://us-central1-yourdoctorsonline-staging.cloudfunctions.net": "https://us-central1-yourdoctorsonline-prod.cloudfunctions.net";
export const firebaseNorthEastRoot = ENVIRONMENT === "development"?"https://northamerica-northeast1-yourdoctorsonline-staging.cloudfunctions.net": "https://northamerica-northeast1-yourdoctorsonline-prod.cloudfunctions.net";

export default rootURL;
