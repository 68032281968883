import { ENVIRONMENT } from './environment';

let options;
if (ENVIRONMENT === "development") {
    options = {
        dirName: "attachments",
        bucketName: "yourdoctors-production",
        region: "us-west-2",
        accessKeyId: "AKIAJ5JOP7LKHAMM4GQA",
        secretAccessKey: "6Wg0ENuNyWvHNiITXL9lfoH+5H1ybw0LAm7QbJTb",
    }
} else {
    options = {
        dirName: "attachments",
        bucketName: "yourdoctors-production",
        region: "us-west-2",
        accessKeyId: "AKIAJ5JOP7LKHAMM4GQA",
        secretAccessKey: "6Wg0ENuNyWvHNiITXL9lfoH+5H1ybw0LAm7QbJTb",
    }
}

export default options;