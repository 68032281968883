import {
    ADD_CLINIC_DATA_FAILED,
    ADD_CLINIC_DATA_PROCESS,
    ADD_CLINIC_DATA_SUCCESS,
    CLINICAL_ADMIN_CREATED_FAILED,
    CLINICAL_ADMIN_CREATED_SUCCESS,
    CLINICAL_ADMIN_ROLE_ASSIGNED_SUCCESS,
    CLINICAL_DATA_STORED_SUCCESS,
    CREATE_CLINIC_PROCESS,
    GET_CLINIC_DATA
} from '../constants';

const INITIAL_STATE = {loading: false, step: 0, error: '', clinicId: null, full_name: '', clinic_name: ''};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_CLINIC_PROCESS:
            return {...state, loading: true, step: 1, error: ''};
        case CLINICAL_ADMIN_CREATED_SUCCESS:
            return {...state, loading: true, step: action.payload.id, error: '', full_name: action.payload.name};
        case CLINICAL_ADMIN_CREATED_FAILED:
            return {...state, loading: false, error: action.payload, step: 0};
        case CLINICAL_ADMIN_ROLE_ASSIGNED_SUCCESS:
            return {...state, loading: false, step: action.payload.id};
        case CLINICAL_DATA_STORED_SUCCESS:
            return {
                ...state,
                loading: false,
                step: action.payload.id,
                clinicId: action.payload.uid,
                full_name: action.payload.fullName,
                clinic_name: action.payload.clinic_name,
            };
        case ADD_CLINIC_DATA_PROCESS:
            return {...state, loading: true};
        case ADD_CLINIC_DATA_SUCCESS:
            return {...state, loading: false};
        case ADD_CLINIC_DATA_FAILED:
            return {...state, loading: false};
        case GET_CLINIC_DATA:
            return {
                ...state,
                clinicId: action.payload.data.clinicID,
                full_name: action.payload.data.doctorName,
                clinic_name: action.payload.name,
            }
        default:
            return state;
    }
}
