import {
    FETCH_STATUS,
    FETCH_TAGS,
    FOLLOW_SAVE_DONE,
    FOLLOW_SAVE_PROGRESS,
    REFRESH_TAGS_STATUS,
    TAG_CHANGE,
    TAG_SAVE
} from '../constants';

const INITIAL_STATE = {
    tags: [],
    tag_value: '',
    error: '',
    user: {},
    followUpStatus: undefined,
    skipStatus: undefined,
    isLoading: false,
    followupDate: '',
    followUpID: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REFRESH_TAGS_STATUS:
            return INITIAL_STATE;
        case TAG_CHANGE:
            return {...state, tag_value: action.payload};
        case TAG_SAVE:
            return {...state, tag_value: ''};
        case FETCH_TAGS:
            return {...state, tags: action.payload};
        case FETCH_STATUS:

            let obj = {...state, followUpStatus: action.payload.followUpStatus};
            if (action.payload.skipStatus !== 'nosave') {
                obj.skipStatus = action.payload.skipStatus;
            } else {
                obj.skipStatus = undefined;
            }
            if (action.payload.followupDate) {
                obj.followupDate = action.payload.followupDate;
            } else {
                obj.followupDate = '';
            }
            if (action.payload.followUpID) {
                obj.followUpID = action.payload.followUpID;
            } else {
                obj.followUpID = null;
            }
            return obj;
        case FOLLOW_SAVE_PROGRESS:
            return {...state, isLoading: true};
        case FOLLOW_SAVE_DONE:
            return {...state, isLoading: false};
        default:
            return state;
    }
}
