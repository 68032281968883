
import classes from './TicketsBuilder.module.css';

const TicketList = (props) => {
    return (
        <div className={classes.chatsList}>
            <div className={classes.block}>
                <div className={classes.list_body}>
                    <div className={classes.list_header}>
                        <div className={classes.header_body}>
                            <div className={classes.absolute_header_text}>
                                <div className={classes.header_text}>Tickets</div>
                            </div>

                            <div className={classes.absolute_header_button}>
                                <div className={classes.absolute_button}>
                                    <button type="button" onClick={() => props.onAddTicket()}>+ New ticket</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.list}>
                        <div className={classes.list_container}>
                            <div className={classes.list_item}>
                                <div className={classes.list_main}>
                                    <div className={classes.list_item_view} width="12px" height="8px" fill="#a0a6ab">
                                        <svg fill="#a0a6ab" width="12px" height="8px" viewBox="0 0 12 8">
                                            <path fill="inherit" fillRule="evenodd"
                                                  d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <a href="true" onClick={() => props.ticketsItemsClick('All tickets')}>
                                    <span> All tickets</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TicketList;
