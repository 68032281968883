import  {Component} from 'react';
import {connect} from 'react-redux';
import csc from 'country-state-city';

import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import classes from '../../../assets/classes.module.css';

import Input from '../../../components/UI/Input/ClinicInput';
import ClipLoader from 'react-spinners/ClipLoader';

import logo from '../../../assets/logo.png';

import {add_clinic, getClinicAdminAndClinicData} from '../../../actions/clinic/create/CreateClinicAction';

class AddClinic extends Component {

    constructor(props) {
        super(props);
        this.state = {
            controls: {
                address: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'Clinic Address',
                        tooltip: 'This is the physical location of your clinic',
                    },
                    value: '',
                    validation: {
                        required: true,
                        minLength: 6
                    },
                    valid: false,
                    touched: false
                },
                clinic_email: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'email',
                        placeholder: 'Clinic Email Address',
                        tooltip: 'A general inbox where patient inquiries can be directed to',
                    },
                    value: '',
                    validation: {
                        required: true,
                        isEmail: true
                    },
                    valid: false,
                    touched: false
                },
            },
            country: 'Canada',
            province: '',
            city: '',
            postal_code: '',
            phone_number: '',
            fax_number: '',
            countries: {'options': csc.getAllCountries()},
            provinces: {'options': csc.getStatesOfCountry("38")},
        }
    }

    componentDidMount() {
        this.props.getClinicAdminAndClinicData();
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    inputChangedHandler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: this.checkValidity(event.target.value, this.state.controls[controlName].validation),
                touched: true
            }
        };
        this.setState({controls: updatedControls});
    }

    subInputChangedHandler = (event, controlName) => {
        var target = event.target || event.srcElement;
        var index = target.selectedIndex + 1;
        var provincesData = csc.getStatesOfCountry(index.toString());
        if (controlName === 'country') {
            this.setState({provinces: {'options': provincesData}, [controlName]: event.target.value});
        } else {
            this.setState({[controlName]: event.target.value});
        }
    }

    submitHandler = (event) => {
        event.preventDefault();
    }

    addClinicData = () => {
        var self = this;
        this.props.add_clinic(
            this.props.clinicId,
            this.props.clinic_name,
            this.props.full_name,
            this.state.controls.address.value,
            this.state.controls.clinic_email.value,
            this.state.city,
            this.state.province,
            this.state.country,
            this.state.phone_number,
            this.state.fax_number,
            this.state.postal_code,
            this.state.email,
            this.state.password,
            function (status) {
                if (status) {
                    const path = {
                        pathname: '/clinic_admin_home',
                        search: '',
                        hash: '',
                    }
                    self.props.history.push(path);
                }
            }
        );
    }


    render() {

        const formElementsArray = [];
        for (let key in this.state.controls) {
            formElementsArray.push({
                id: key,
                config: this.state.controls[key]
            });
        }

        let errorMessage = null;

        if (this.props.error) {
            errorMessage = (
                <p className={classes.error}>{this.props.error}</p>
            );
        }

        let form = (
            <form onSubmit={this.submitHandler}>
                {formElementsArray.map(formElement => (
                    <Input
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)}/>
                ))}
                {errorMessage}
            </form>
        );

        const {loading, full_name, clinic_name} = this.props;
        let {countries, provinces} = this.state;

        return (
            <div className={classes.container}>
                <div className={classes.content}>
                    <div className={classes.main_view}>
                        <div className={classes.logo}>
                            <img src={logo} alt="logo" className={classes.logo_container}/>
                        </div>
                        <span className={classes.heading}>Welcome to your virtual clinic. We need some more info to complete the administration for {clinic_name} </span>
                        <div className={classes.form_view}>
                            {form}
                            <div className={classes.sub_form_view}>
                                <OverlayTrigger
                                    key={'left'}
                                    placement={'left'}
                                    overlay={
                                        <Tooltip id={`tooltip-left`}>Country</Tooltip>
                                    }
                                >
                                    <select
                                        className={classes.ClinicSmallInputElement}
                                        placeholder="Country"
                                        defaultValue="Canada"
                                        onChange={(event) => this.subInputChangedHandler(event, "country")}>
                                        {countries.options.map(option => (
                                            <option key={option.id} id={option.id} value={option.name}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    key={'right'}
                                    placement={'right'}
                                    overlay={
                                        <Tooltip id={`tooltip-right`}>Province</Tooltip>
                                    }
                                >
                                    <select
                                        className={classes.ClinicSmallInputRightElement}
                                        placeholder="Province"
                                        onChange={(event) => this.subInputChangedHandler(event, "province")}>
                                        {provinces.options.map(option => (
                                            <option key={option.id} id={option.id} value={option.name}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </OverlayTrigger>
                            </div>
                            <div className={classes.sub_form_view}>
                                <OverlayTrigger
                                    key={'left'}
                                    placement={'left'}
                                    overlay={
                                        <Tooltip id={`tooltip-left`}>City</Tooltip>
                                    }
                                >
                                    <input
                                        className={classes.ClinicSmallInputElement}
                                        placeholder="City"
                                        onChange={(event) => this.subInputChangedHandler(event, "city")}
                                    />
                                </OverlayTrigger>
                                <OverlayTrigger
                                    key={'right'}
                                    placement={'right'}
                                    overlay={
                                        <Tooltip id={`tooltip-right`}>Postal or Zip code</Tooltip>
                                    }
                                >
                                    <input
                                        className={classes.ClinicSmallInputRightElement}
                                        placeholder="Postal Code"
                                        onChange={(event) => this.subInputChangedHandler(event, "postal_code")}
                                    />
                                </OverlayTrigger>
                            </div>
                            <div className={classes.sub_form_view}>
                                <OverlayTrigger
                                    key={'left'}
                                    placement={'left'}
                                    overlay={
                                        <Tooltip id={`tooltip-left`}>This is the phone number that will be used if
                                            patients have any inquiries. Please start with a + and a country code
                                            followed by your phone number. Ex +18774534777</Tooltip>
                                    }
                                >
                                    <input
                                        className={classes.ClinicSmallInputElement}
                                        placeholder="Clinic Phone Number"
                                        onChange={(event) => this.subInputChangedHandler(event, "phone_number")}
                                    />
                                </OverlayTrigger>
                                <OverlayTrigger
                                    key={'right'}
                                    placement={'right'}
                                    overlay={
                                        <Tooltip id={`tooltip-right`}>Please start with a + and a country code followed
                                            by your phone number. Ex +18774534777. If you don’t have one, please leave
                                            it blank</Tooltip>
                                    }
                                >
                                    <input
                                        className={classes.ClinicSmallInputRightElement}
                                        placeholder="Clinic Fax Number"
                                        onChange={(event) => this.subInputChangedHandler(event, "fax_number")}
                                    />
                                </OverlayTrigger>
                            </div>
                            {loading &&
                            <ClipLoader
                                size={150}
                                color={"#884a9d"}
                            />
                            }
                            {!loading &&
                            <div className={classes.button_view} onClick={() => this.addClinicData()}>
                                <button className={classes.button}>Enter my clinic</button>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({createClinicReducer}) => {
    const {loading, clinicId, full_name, clinic_name, error, user} = createClinicReducer;
    return {loading, clinicId, full_name, clinic_name, error, user};
};

export default connect(mapStateToProps, {getClinicAdminAndClinicData, add_clinic})(AddClinic);
