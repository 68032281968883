import  {Component} from 'react';
import classes from './styles.css';
import {connect} from 'react-redux';
import ReactDOM from "react-dom";
import {closePatientChat, refreshState} from '../../../../../actions/ConversationAction';
import {
    saveAndCloseClinicalPatientForm,
    saveClinicalPatientFormInprogress
} from '../../../../../actions/ClinicalChatsAction';
import {
    fetchCannedResponsesForAssessment,
    fetchCannedResponsesForObjectiveFindings,
    fetchCannedResponsesForPlan
} from '../../../../../actions/ClinicalCannedResponseAction';
import {fetchBillingCodes} from '../../../../../actions/BillingCodesAction';
import MyDocument from '../../../../PdfBuilder';
import {pdf} from '@react-pdf/renderer';

import MyPdf from '../../../../clinicalScreens/CreatePdf';

import _ from 'lodash';

import TextareaAutosize from 'react-autosize-textarea';
import {functions} from "../../../../../firebase";
import axios from "axios";

//var request = require('request');

const ListItem = (props) => {
    return (
        props.data.map(function (data, index) {
            return data.title.indexOf('</') !== -1 ? (
                    <li key={index}
                        onClick={() => props.onSelectDiagnosisIssue(data, (data.title).replace(/<\s*br[^>]?>/, '\n').replace(/(<([^>]+)>)/g, ""), data.id)}
                        dangerouslySetInnerHTML={{__html: data.title.replace(/( <? *script)/gi, 'illegalscript')}}/>) :
                (<li onClick={() => props.onSelectDiagnosisIssue(data, data.title, data.id)} key={index}
                     value={data.title}>
                    <div>{`${data.title}`}</div>
                    <div
                        className={classes.titleText}>{`${(data.matchingPVs[0].label).replace(/(<([^>]+)>)/g, "")}`}</div>
                </li>);
        })
    )
}

function formatDate() {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(d.setDate(d.getDate() + 1)),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const CannedResponsesList = (props) => {
    return (
        props.canned_responses_data.map(function (data, index) {
            return (
                <div key={index} className={classes.canned_response_item}
                     onClick={() => props.onCannedResponseClick(data.uid, data.shortcut, data.text)}>
                    <div className={classes.canned_response_text}>{data.shortcut}</div>
                    <div className={classes.canned_response_arrow}
                         onClick={() => props.onCannedResponseClick(data.uid, data.shortcut, data.text)}>
                        <svg data-test="shortcut-select-button" fill="#000000" width="4px" height="12px"
                             viewBox="0 0 8 13">
                            <g fill="inherit" fillRule="evenodd">
                                <path fillRule="nonzero" d="M.59 11.34l4.58-4.59L.59 2.16 2 .75l6 6-6 6z"></path>
                            </g>
                        </svg>
                    </div>
                </div>
            )
        })
    )
};

const BillingCodeOption = (props) => {
    return (
        props.codes.map(function (data, index) {
            return (
                <option key={index} value={data.billing_code}>{data.billing_code}</option>
            )
        })
    )
}
var healthProblems = [];
var familyProblems = [];

class outPatientForm extends Component {

    constructor(props) {

        super(props);
        this.state = {
            dateTime: formatDate(),
            access_token: '',
            events: [],
            diagnosisValue: '',
            followUpCheck: false,
            displayDiagnosisList: false,
            opinionDescriptionValue: '',
            opinionDescription: false,
            scheduled_followup: "",
            commentAdded: '',
            mediciensArr: [],
            typing: false,
            typingTimeout: 0,
            symptoms: '',
            findings: '',
            assessment: '',
            plan: '',
            icd: '',
            followuptext: 'as needed',
            billing_code: '',
            billing_unit: 1,
            showCannedResponseForObjectiveFindingsList: false,
            showCannedResponseForAssessmentList: false,
            showCannedResponseForPlanList: false,
            canned_responses: [],
            canned_responses_assessment: [],
            canned_responses_plan: [],
            billing_codes: [],
            showIndicator: false,
            modalWindow: null,
            patient_profile: false,
            video_consult: false,
            encounter_duration: false,
            special_referals: false,
            requisitions: false,
            prescriptions: false,
            followUpDays: 0,
        };

        this.baseState = this.state;
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (!(_.isEqual(this.props.patient_profile, nextProps.patient_profile))) {
            familyProblems = []
            healthProblems = []
            this.generatePatientProfile(nextProps.patient_profile);
        }

        if (!Boolean(this.props.subjective_symptoms) && nextProps.closeChatData.selectedIssue !== '' && nextProps.closeChatData.selectedIssue !== null) {
            this.setState({symptoms: nextProps.closeChatData.selectedIssue});
        }
        if (nextProps.followClassStatus === true || nextProps.followUpStatus === true) {
            this.setState({followUpCheck: true})
        } else {
            this.setState({followUpCheck: false})
        }

        if (nextProps.canned_responses !== this.props.canned_responses) {
            this.setState({canned_responses: nextProps.canned_responses});
        }

        if (nextProps.canned_responses_assessment !== this.props.canned_responses_assessment) {
            this.setState({canned_responses_assessment: nextProps.canned_responses_assessment});
        }

        if (nextProps.canned_responses_plan !== this.props.canned_responses_plan) {
            this.setState({canned_responses_plan: nextProps.canned_responses_plan});
        }

        if (nextProps.billing_codes !== this.props.billing_codes) {
            this.setState({billing_codes: nextProps.billing_codes});
        }

        if (nextProps.subjective_symptoms !== this.props.subjective_symptoms) {
            this.setState({symptoms: nextProps.subjective_symptoms});
        }

        if (nextProps.findings !== this.props.findings) {
            this.setState({findings: nextProps.findings});
        }

        if (nextProps.assessment !== this.props.assessment) {
            this.setState({assessment: nextProps.assessment});
        }

        if (nextProps.plan !== this.props.plan) {
            this.setState({plan: nextProps.plan});
        }

        if (this.props.isVideoCall === 2 || nextProps.isVideoCall === 2) {
            this.setState({video_consult: true});
        }

        if (nextProps.followUpDays !== this.props.followUpDays) {
            this.setState({followUpDays: nextProps.followUpDays});
        }
    }

    UNSAFE_componentWillMount() {
        const getToken = functions.httpsCallable('getToken');
        getToken().then(result => {
            this.setState({access_token: JSON.parse(result.data).access_token});
        });
        this.setState({
            followUpDays: this.props.followUpDays,
            symptoms: this.props.subjective_symptoms,
            findings: this.props.findings,
            assessment: this.props.assessment,
            plan: this.props.plan
        })
        this.props.fetchCannedResponsesForObjectiveFindings();
        this.props.fetchCannedResponsesForAssessment();
        this.props.fetchCannedResponsesForPlan();
        this.props.fetchBillingCodes();
    }

    generatePatientProfile = (patient_profile) => {
        if (patient_profile != null && patient_profile != undefined && patient_profile != {}) {

            for (var key of Object.keys(patient_profile)) {
                if(key){
                    if (key.indexOf("Flag") != -1 && key.indexOf("Family") == -1 && key.indexOf("alcoholFlag") == -1 && key.indexOf("smokeFlag") == -1 && key.indexOf("allergyFlag") == -1) {
                        if (patient_profile[key] == true) {
                            if (key == 'anxietyFlag') {
                                healthProblems.push("Anxiety")
                            }
                            if (key == 'asthmaFlag') {
                                healthProblems.push("Asthama\n")
                            }
                            if (key == 'arrhythmiasFlag') {
                                healthProblems.push("Arrhythmias")
                            }
                            if (key == 'bpFlag') {
                                healthProblems.push("High Blood Pressure")
                            }
                            if (key == 'cancerFlag') {
                                if (patient_profile.cancerData != null && patient_profile.cancerData != undefined && patient_profile.cancerData != '' && patient_profile.cancerData != []) {
                                    healthProblems.push("Cancer" + "(" + patient_profile.cancerData.toString() + ")")
                                } else {
                                    healthProblems.push("Cancer")
                                }
                            }
                            if (key == 'copdFlag') {
                                healthProblems.push("COPD")
                            }
                            if (key == 'depressionsFlag') {
                                healthProblems.push("Depressions")
                            }
                            if (key == 'diabetesFlag') {
                                healthProblems.push("Diabetes")
                            }
                            if (key == 'eczemaFlag') {
                                healthProblems.push("\n Eczema")
                            }
                            if (key == 'headFlag') {
                                healthProblems.push("Headaches/Migraines")
                            }
                            if (key == 'heartFlag') {
                                healthProblems.push("Heart Problems")
                            }
                            if (key == 'kidneyFlag') {
                                healthProblems.push(" Kidney Problems")
                            }
                            if (key == 'otherFlag') {
                                if (patient_profile.other != null && patient_profile.other != undefined && patient_profile.other != '' && patient_profile.other != []) {
                                    healthProblems.push("Other Problems" + "(" + patient_profile.other + ")");
                                }

                                healthProblems.push("Other Problems");
                            }
                            if (key == 'panicFlag') {
                                healthProblems.push("Panic Attacks")
                            }
                            if (key == 'psoriasisFlag') {
                                healthProblems.push("Psoriasis")
                            }
                            if (key == 'seizureFlag') {
                                healthProblems.push("Seizures")
                            }
                            if (key == 'strokeFlag') {
                                healthProblems.push("Stroke")
                            }
                            if (key == 'thyroidFlag') {
                                healthProblems.push("Thyroid")
                            }
                            if (key == 'utiFlag') {
                                healthProblems.push("Urinary Tract Infection")
                            }
                        }
                    }
                    if (key.indexOf("Family") != -1 && key.indexOf("Flag") != -1) {
                        if (patient_profile[key] == true) {
                            if (key == ("anxietyFlagFamily")) {
                                if (patient_profile.anxietyAnswer != null || patient_profile.anxietyAnswer != undefined || patient_profile.anxietyAnswer != '') {
                                    familyProblems.push("Anxiety(" + patient_profile.anxietyAnswer + ")")
                                }
                            }
                            if (key == ("arrhythmiasFlagFamily")) {
                                if (patient_profile.arrhythmiasAnswer != null || patient_profile.arrhythmiasAnswer != undefined || patient_profile.arrhythmiasAnswer != '') {
                                    familyProblems.push("Arrhythmias(" + patient_profile.arrhythmiasAnswer + ")")
                                }
                            }
                            if (key == ("asthmaFlagFamily")) {
                                if (patient_profile.asthamaAnswer != null || patient_profile.asthamaAnswer != undefined || patient_profile.asthamaAnswer != '') {
                                    familyProblems.push("Asthma(" + patient_profile.asthamaAnswer + ")")
                                }
                            }
                            if (key == ("bpFlagFamily")) {
                                if (patient_profile.bpAnswer != null || patient_profile.bpAnswer != undefined || patient_profile.bpAnswer != '') {
                                    familyProblems.push("High Blood Pressure(" + patient_profile.bpAnswer + ")")
                                }
                            }
                            if (key == ("cancerFlagFamily")) {
                                if (patient_profile.cancerAnswer != null || patient_profile.cancerAnswer != undefined || patient_profile.cancerAnswer != '') {
                                    familyProblems.push("Cancer(" + patient_profile.cancerAnswer + ")")
                                }
                            }
                            if (key == ("copdFlagFamily")) {
                                if (patient_profile.copdAnswer != null || patient_profile.copdAnswer != undefined || patient_profile.copdAnswer != '') {
                                    familyProblems.push("COPD(" + patient_profile.copdAnswer + ")")
                                }
                            }
                            if (key == ("depressionsFlagFamily")) {
                                if (patient_profile.adepressionsAnswer != null || patient_profile.depressionsAnswer != undefined || patient_profile.depressionsAnswer != '') {
                                    familyProblems.push("Depression(" + patient_profile.depressionsAnswer + ")")
                                }
                            }
                            if (key == ("diabetesFlagFamily")) {
                                if (patient_profile.diabetesAnswer != null || patient_profile.diabetesAnswer != undefined || patient_profile.diabetesAnswer != '') {
                                    familyProblems.push("Diabetes(" + patient_profile.diabetesAnswer + ")")
                                }
                            }
                            if (key == ("eczemaFlagFamily")) {
                                if (patient_profile.eczemaAnswer != null || patient_profile.eczemaAnswer != undefined || patient_profile.eczemaAnswer != '') {
                                    familyProblems.push("Eczema(" + patient_profile.eczemaAnswer + ")")
                                }
                            }
                            if (key == ("headFlagFamily")) {
                                if (patient_profile.headAnswer != null || patient_profile.headAnswer != undefined || patient_profile.headAnswer != '') {
                                    familyProblems.push("Headaches/Migraines(" + patient_profile.headAnswer + ")")
                                }
                            }
                            if (key == ("heartFlagFamily")) {
                                if (patient_profile.heartAnswer != null || patient_profile.heartAnswer != undefined || patient_profile.heartAnswer != '') {
                                    familyProblems.push("Heart Problems(" + patient_profile.heartAnswer + ")")
                                }
                            }
                            if (key == ("kidneyFlagFamily")) {
                                if (patient_profile.kidneyAnswer != null || patient_profile.kidneyAnswer != undefined || patient_profile.kidneyAnswer != '') {
                                    familyProblems.push("Kidney Problems(" + patient_profile.kidneyAnswer + ")")
                                }
                            }

                            if (key == ("panicFlagFamily")) {
                                if (patient_profile.panicAnswer != null || patient_profile.panicAnswer != undefined || patient_profile.panicAnswer != '') {
                                    familyProblems.push("Panic Attacks(" + patient_profile.panicAnswer + ")")
                                }
                            }
                            if (key == ("psoriasisFlagFamily")) {
                                if (patient_profile.psoriasisAnswer != null || patient_profile.psoriasisAnswer != undefined || patient_profile.psoriasisAnswer != '') {
                                    familyProblems.push("Psoriasis(" + patient_profile.psoriasisAnswer + ")")
                                }
                            }
                            if (key == ("seizureFlagFamily")) {
                                if (patient_profile.seizureAnswer != null || patient_profile.seizureAnswer != undefined || patient_profile.seizureAnswer != '') {
                                    familyProblems.push("Seizures(" + patient_profile.seizureAnswer + ")")
                                }
                            }
                            if (key == ("strokeFlagFamily")) {
                                if (patient_profile.strokeAnswer != null || patient_profile.strokeAnswer != undefined || patient_profile.strokeAnswer != '') {
                                    familyProblems.push("Stroke(" + patient_profile.strokeAnswer + ")")
                                }
                            }
                            if (key == ("thyroidFlagFamily")) {
                                if (patient_profile.thyroidAnswer != null || patient_profile.thyroidAnswer != undefined || patient_profile.thyroidAnswer != '') {
                                    familyProblems.push("Thyroid Problems(" + patient_profile.thyroidAnswer + ")")
                                }
                            }
                            if (key == ("utiFlagFamily")) {
                                if (patient_profile.utiAnswer != null || patient_profile.utiAnswer != undefined || patient_profile.utiAnswer != '') {
                                    familyProblems.push("Urinary Tract Infection(" + patient_profile.utiAnswer + ")")
                                }
                            }
                            if (key == ("otherFlagFamily")) {
                                if (patient_profile.otherAnswerFamily != null || patient_profile.otherAnswerFamily != undefined || patient_profile.otherAnswerFamily != '') {
                                    familyProblems.push("Other Problems(" + patient_profile.otherAnswerFamily + ":" + patient_profile.otherFamily + ")")
                                }
                            }

                        }

                    }
                }

            }
        }
        this.setState({check: Math.random()})
    }

    diagnosisInput = (event) => {
        var self = this;
        this.setState({showIndicator: true});
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            icd: event.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
                self.findDiagnosis(self.state.icd);
            }, 1000)
        });

    }

    findDiagnosis = text => {
        if (text.length > 2) {
            this.setState({displayDiagnosisList: true});
            /*var options = {
                url: `https://id.who.int/icd/entity/search?q=${text}&useFlexisearch=false&flatResults=true`,
                headers: {
                    'Authorization': 'Bearer ' + this.state.access_token,
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Accept-Language': 'en',
                    'API-Version': 'v2'
                }
            };*/
            let self = this;
            /*function callback(error, response, body) {
                if (!error && response.statusCode === 200) {
                    var info = JSON.parse(body);
                    self.setState({
                        showIndicator: false,
                        events: _.orderBy(info.destinationEntities, ['score'], ['desc'])
                    });
                }
            }*/
            axios({
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + this.state.access_token,
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Accept-Language': 'en',
                    'API-Version': 'v2'
                },
                url: `https://id.who.int/icd/entity/search?q=${text}&useFlexisearch=false&flatResults=true`,
                mode: 'cors',
            }).then(res=>{
                let info = res?.data?.destinationEntities? res?.data: JSON.parse(res.data);
                self.setState({
                    showIndicator: false,
                    events: _.orderBy(info.destinationEntities, ['score'], ['desc'])
                });
            }).catch(e=>{})
            //request(options, callback);
        } else {
            this.setState({showIndicator: false});
        }
    }

    handleChangeFollowUpDateTime = (event) => {
        this.setState({dateTime: event.target.value});
    };

    onSelectDiagnosisIssue = (data, title, id) => {
        this.setState({icd: title, displayDiagnosisList: false});
        var number = id.split('/entity/');

        /*var options = {
            url: `https://id.who.int/icd/entity/${number[1]}`,
            headers: {
                'Authorization': 'Bearer ' + this.state.access_token,
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Accept-Language': 'en',
                'API-Version': 'v2'
            }
        };*/
        var self = this;
        axios({
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + this.state.access_token,
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Accept-Language': 'en',
                'API-Version': 'v2'
            },
            url: `https://id.who.int/icd/entity/${number[1]}`,
            mode: 'cors',
        }).then(res=>{
            let info = res?.data?.definition? res?.data: JSON.parse(res.data);
            if (info.definition) {
                var descOption = Object.values(info.definition);
                self.setState({opinionDescription: true, opinionDescriptionValue: descOption[1]});
            } else {
                self.setState({opinionDescription: false, opinionDescriptionValue: ''});
            }
        }).catch(e=>{})
        /*function callback(error, response, body) {
            if (!error && response.statusCode === 200) {
                var info = JSON.parse(body);
                if (info.definition) {
                    var descOption = Object.values(info.definition);
                    self.setState({opinionDescription: true, opinionDescriptionValue: descOption[1]});
                } else {
                    self.setState({opinionDescription: false, opinionDescriptionValue: ''});
                }
            }
        }*/

        //request(options, callback);
    }

    handleChangeFollowUp = () => {
        this.setState({followUpCheck: !this.state.followUpCheck});
    }

    onCancelClick = () => {
        this.props.hideModal();
    }

    saveOutPatientForm = async () => {
        this.props.saveClinicalPatientFormInprogress();

        const {
            paidChat, conversationId, doctorID, admin, doctorName, patientName, patientId,
            selectedIssue, selectedCategory, tagChatList,
            heartRate, weightKg, weightLbs, stepCount, runningWalking, heightCm,
            currentSleep, averageSleep, heightFeet, periodStartDate, flowType, restingHeartRate,
            complete_address
        } = this.props.closeChatData;
        const {
            symptoms,
            findings,
            assessment,
            plan,
            icd,
            billing_code,
            billing_unit,
            followUpCheck,
            dateTime,
            prescriptions,
            requisitions,
            special_referals,
            encounter_duration,
            video_consult,
            followUpDays
        } = this.state;
        const {
            clinicName,
            patientDOB,
            city,
            province,
            postalcode,
            healthInsuranceCardNumber,
            healthInsuranceCardFront,
            healthInsuranceCardBack,
            gender
        } = this.props;
        const element = <MyPdf
            plan={this.state.plan}
            assessment={this.state.assessment}
            findings={this.state.findings}
            icd={this.state.icd}
            followuptext={this.state.followUpDays !== 0 ? this.state.followUpDays + ' days' : 'as needed'}
            video_consult={this.state.video_consult}
            encounter_duration={this.state.encounter_duration}
            special_referals={this.state.special_referals}
            requisitions={this.state.requisitions}
            prescriptions={this.state.prescriptions}
            patient_issue={this.state.symptoms}
            patientName={this.props.patientName}
            patientDOB={this.props.patientDOB}
            doctorName={this.props.doctorName}
            clinicName={this.props.clinicName}
            doctorImage={this.props.doctorImage}
            signatureImageURL={this.props.signatureImageURL}
            doctorlicense={this.props.doctorlicense}
            clinicPhoneNumber={this.props.clinicPhoneNumber}
            clinicAddress={this.props.clinicAddress}
            familyProblems={familyProblems}
            healthProblems={healthProblems}
            patient_profile={this.props.patient_profile}
            endTime={this.props.endTime}
            start_timestamp={this.props.start_timestamp}
            user_medications={this.props.user_medications}/>;

        const myPdf = pdf();
        myPdf.updateContainer(element);
        const blob = await myPdf.toBlob();
        var pname = this.props.patientName.toLowerCase();
        var dname = this.props.doctorName.toLowerCase();
        while (pname.indexOf(' ') > -1) {
            pname = pname.replace(' ', '-');
        }
        while (dname.indexOf(' ') > -1) {
            dname = dname.replace(' ', '-');
        }
        //patientname-doctorname-datetimestamp.pdf
        var file = new File([blob], pname + "-" + dname + "-" + this.props.start_timestamp + ".pdf", {lastModified: (new Date()).getTime()});

        const formData = new FormData();
        formData.append('file', file);
        fetch('https://reports.staging.yourdoctors.online/storage/uploadPDFFile', {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            body: formData// body data type must match "Content-Type" header
        }).then(response1 => response1.json())
            .then((data) => {
                this.props.saveAndCloseClinicalPatientForm(clinicName, patientDOB, city, province, postalcode, healthInsuranceCardNumber, healthInsuranceCardFront, healthInsuranceCardBack, gender, paidChat, conversationId, doctorID, admin, doctorName,
                    patientName, patientId, selectedIssue, selectedCategory, tagChatList,
                    symptoms, findings, assessment, plan, icd, billing_code, billing_unit,
                    followUpCheck, dateTime, heartRate, weightKg, weightLbs, stepCount, runningWalking, heightCm,
                    currentSleep, averageSleep, heightFeet, periodStartDate, flowType, restingHeartRate,
                    complete_address, prescriptions, requisitions, special_referals, encounter_duration, video_consult, this.props.start_timestamp, followUpDays, data.filename);


            }).catch((error) => {
            console.log("filesending failed error ", error);
        })

    }

    closeChat = () => {
        const {
            paidChat, conversationId, doctorID, admin, doctorName, patientName, patientId,
            selectedIssue, selectedCategory,
            heartRate, weightKg, weightLbs, stepCount, runningWalking, heightCm,
            currentSleep, averageSleep, heightFeet, periodStartDate, flowType, restingHeartRate,
            complete_address
        } = this.props.closeChatData;
        this.props.closePatientChat(paidChat, conversationId, doctorID, admin, doctorName,
            patientName, patientId, selectedIssue, selectedCategory, heartRate, weightKg, weightLbs,
            stepCount, runningWalking, heightCm, currentSleep, averageSleep, heightFeet, periodStartDate,
            flowType, restingHeartRate, complete_address);
    }

    closeOnSaveClick = () => {
        this.props.hideModal();
        this.setState(this.baseState);
        this.props.refreshState();
    }

    onSubjectSymptomsChange = (event) => {
        this.setState({symptoms: event.target.value});
    }

    filterUsers = (event) => {
        var keyword = event.toLowerCase();
        var filtered_cannedResponses = this.state.canned_responses.filter((user) => {
            user = user.shortcut.toLowerCase();
            return user.indexOf(keyword) > -1;
        });
        if (filtered_cannedResponses.length !== 0) {
            this.setState({canned_responses: filtered_cannedResponses});
        } else {
            this.setState({canned_responses: this.props.canned_responses});
        }
    }

    filterAssessment = (event) => {
        var keyword = event.toLowerCase();
        var filtered_cannedResponses = this.state.canned_responses_assessment.filter((user) => {
            user = user.shortcut.toLowerCase();
            return user.indexOf(keyword) > -1;
        });
        if (filtered_cannedResponses.length !== 0) {
            this.setState({canned_responses_assessment: filtered_cannedResponses});
        } else {
            this.setState({canned_responses_assessment: this.props.canned_responses_assessment});
        }
    }

    filterPlan = (event) => {
        var keyword = event.toLowerCase();
        var filtered_cannedResponses = this.state.canned_responses_plan.filter((user) => {
            user = user.shortcut.toLowerCase();
            return user.indexOf(keyword) > -1;
        });
        if (filtered_cannedResponses.length !== 0) {
            this.setState({canned_responses_plan: filtered_cannedResponses});
        } else {
            this.setState({canned_responses_plan: this.props.canned_responses_plan});
        }
    }

    onObjectiveFindingsChange = (event) => {
        if ((event.target.value).match('#')) {
            this.setState({showCannedResponseForObjectiveFindingsList: true});
            this.filterUsers((event.target.value.slice((event.target.value).search('#'), event.target.value.length)));
        }
        this.setState({findings: event.target.value});
    }

    onAssessmentGoalsChange = (event) => {

        if ((event.target.value).match('#')) {
            this.setState({showCannedResponseForAssessmentList: true});
            this.filterAssessment((event.target.value.slice((event.target.value).search('#'), event.target.value.length)));
        }
        this.setState({assessment: event.target.value});
    }

    onPlanChange = (event) => {

        if ((event.target.value).match('#')) {
            this.setState({showCannedResponseForPlanList: true});
            this.filterPlan((event.target.value.slice((event.target.value).search('#'), event.target.value.length)));
        }
        this.setState({plan: event.target.value});
    }

    renderPopOut() {
        if (this.modalWindow) {
            let root = this.modalWindow.document.body;
            ReactDOM.render(<MyDocument
                plan={this.state.plan}
                assessment={this.state.assessment}
                findings={this.state.findings}
                icd={this.state.icd}
                followuptext={this.state.followUpDays !== 0 ? this.state.followUpDays + ' days' : 'as needed'}
                video_consult={this.state.video_consult}
                encounter_duration={this.state.encounter_duration}
                special_referals={this.state.special_referals}
                requisitions={this.state.requisitions}
                prescriptions={this.state.prescriptions}
                patient_issue={this.state.symptoms}
                patientName={this.props.patientName}
                patientDOB={this.props.patientDOB}
                doctorName={this.props.doctorName}
                clinicName={this.props.clinicName}
                doctorImage={this.props.doctorImage}
                signatureImageURL={this.props.signatureImageURL}
                doctorlicense={this.props.doctorlicense}
                clinicPhoneNumber={this.props.clinicPhoneNumber}
                clinicAddress={this.props.clinicAddress}
                familyProblems={familyProblems}
                healthProblems={healthProblems}
                patient_profile={this.props.patient_profile}
                endTime={this.props.endTime}
                start_timestamp={this.props.start_timestamp}

            />, root);
        }
    }

    onObjectiveCannedResponseClick = (uid, short_cut, text) => {
        this.setState({showCannedResponseForObjectiveFindingsList: false});
        var updatedString;

        if (this.state.findings.search('#') !== -1) {
            var replaceText = (this.state.findings.slice((this.state.findings).search('#'), this.state.findings.length));
            updatedString = (this.state.findings).replace(replaceText, text);
        } else {
            updatedString = (this.state.findings).concat(' ', text);
        }
        this.setState({findings: updatedString});
    }

    onAssessmentCannedResponseClick = (uid, short_cut, text) => {
        this.setState({showCannedResponseForAssessmentList: false});
        var updatedString;

        if (this.state.assessment.search('#') !== -1) {
            var replaceText = (this.state.assessment.slice((this.state.assessment).search('#'), this.state.assessment.length));
            updatedString = (this.state.assessment).replace(replaceText, text);
        } else {
            updatedString = (this.state.assessment).concat(' ', text);
        }
        this.setState({assessment: updatedString});
    }

    onPlanCannedResponseClick = (uid, short_cut, text) => {
        this.setState({showCannedResponseForPlanList: false});
        var updatedString;

        if (this.state.plan.search('#') !== -1) {
            var replaceText = (this.state.plan.slice((this.state.plan).search('#'), this.state.plan.length));
            updatedString = (this.state.plan).replace(replaceText, text);
        } else {
            updatedString = (this.state.plan).concat(' ', text);
        }
        this.setState({plan: updatedString});
    }

    onCodeSelect = (event) => {
        this.setState({billing_code: event.target.value});
    }

    onBillingUnit = (event) => {
        this.setState({billing_unit: event.target.value});
    }

    render() {

        let {
            symptoms, findings, assessment, plan, icd, billing_unit, opinionDescription, opinionDescriptionValue,
            canned_responses, canned_responses_assessment, canned_responses_plan, billing_codes, showIndicator
        } = this.state;
        return (
            <div className={classes.modal} style={{
                transform: this.props.show ? 'translateY(0)' : 'translateY(-100vh)',
                opacity: this.props.show ? '1' : '0'
            }}>

                <div className={classes.header}>Encounter SOAP Note</div>
                {this.props.message === '' &&
                <div className={classes.container}>
                    <div className={classes.content}>
                        <div className={classes.main}>
                            <div className={classes.symptomsText}>Subjective Symptoms:</div>
                            <TextareaAutosize
                                className={classes.input}
                                onChange={(text) => this.onSubjectSymptomsChange(text)}
                                value={symptoms}
                            />
                        </div>
                        <div className={classes.main}>
                            <div className={classes.symptomsText}>Objective Findings:</div>
                            <TextareaAutosize
                                className={classes.input}
                                placeholder={'Burn in knee'}
                                onChange={(text) => this.onObjectiveFindingsChange(text)}
                                value={findings}
                            />
                            {this.state.showCannedResponseForObjectiveFindingsList &&
                            <div className={classes.canned_response_list}>
                                <div className={classes.canned_response_view}>
                                    <CannedResponsesList
                                        canned_responses_data={canned_responses}
                                        onCannedResponseClick={(uid, short_cut, text) => this.onObjectiveCannedResponseClick(uid, short_cut, text)}
                                    />
                                </div>
                            </div>
                            }
                        </div>
                        <div className={classes.diagnosticMain}>
                            <div className={classes.diagnosisText}>Assessment:</div>
                            <TextareaAutosize
                                placeholder={'Long term / Short term'}
                                className={classes.input}
                                onChange={(text) => this.onAssessmentGoalsChange(text)}
                                value={assessment}
                            />
                            {this.state.showCannedResponseForAssessmentList &&
                            <div className={classes.canned_response_list}>
                                <div className={classes.canned_response_view}>
                                    <CannedResponsesList
                                        canned_responses_data={canned_responses_assessment}
                                        onCannedResponseClick={(uid, short_cut, text) => this.onAssessmentCannedResponseClick(uid, short_cut, text)}
                                    />
                                </div>
                            </div>
                            }
                        </div>
                        <div className={classes.main}>
                            <div className={classes.symptomsText}>Plan:</div>
                            <TextareaAutosize
                                placeholder={'Start typing here...'}
                                className={classes.input}
                                onChange={(text) => this.onPlanChange(text)}
                                value={plan}
                            />
                            {this.state.showCannedResponseForPlanList &&
                            <div className={classes.canned_response_list}>
                                <div className={classes.canned_response_view}>
                                    <CannedResponsesList
                                        canned_responses_data={canned_responses_plan}
                                        onCannedResponseClick={(uid, short_cut, text) => this.onPlanCannedResponseClick(uid, short_cut, text)}
                                    />
                                </div>
                            </div>
                            }
                        </div>
                        <div className={classes.main}>
                            <div className={classes.symptomsText}>Diagnosis(ICD):</div>
                            <input
                                className={classes.diagnoticInput}
                                onChange={(text) => this.diagnosisInput(text)}
                                value={icd}
                                placeholder={'Start typing here...'}
                            />
                            {showIndicator &&
                            <div className={classes.iconContainer}>
                                <i className={classes.loader}></i>
                            </div>
                            }
                            <ul className={classes.listView}
                                style={{display: this.state.displayDiagnosisList ? 'block' : 'none'}}>
                                <ListItem
                                    data={this.state.events}
                                    onSelectDiagnosisIssue={(data, title, id) => this.onSelectDiagnosisIssue(data, title, id)}
                                />
                            </ul>
                        </div>

                        <div className={classes.main_with_border}>
                            <div className={classes.followuptext}>Follow up:</div>
                            <span>Patient has been advised to follow up {this.state.followUpDays !== 0 ? this.state.followUpDays + ' days' : this.state.followuptext}</span>
                        </div>

                        <div className={classes.header}>Encounter Features</div>
                        <div className={classes.main}>
                            <div className={classes.split_half}>
                                <div className={classes.radioText}>Prescription:</div>
                                <div className={classes.radioButtonStyle}>
                                    <input type="checkbox" name="prescriptions"
                                           checked={this.state.prescriptions}
                                           onClick={() => {
                                               this.setState({prescriptions: !this.state.prescriptions})
                                           }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes.main}>
                            <div className={classes.split_half}>
                                <div className={classes.radioText}>Requisitions:</div>
                                <div className={classes.radioButtonStyle}>
                                    <input type="checkbox" name="requisitions"
                                           checked={this.state.requisitions}
                                           onClick={() => {
                                               this.setState({requisitions: !this.state.requisitions})
                                           }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes.main}>
                            <div className={classes.split_half}>
                                <div className={classes.radioText}>Referral:</div>
                                <div className={classes.radioButtonStyle}>
                                    <input type="checkbox" name="special_referals"
                                           checked={this.state.special_referals}
                                           onClick={() => {
                                               this.setState({special_referals: !this.state.special_referals})
                                           }}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div className={classes.main}>
                                <div className={classes.split_half}>
                                    <div className={classes.radioText}>Video Conference: </div>
                                    <div className={classes.radioButtonStyle}>
                                        <input type="checkbox" name="video_consult"
                                            checked={this.state.video_consult}
                                            onClick={()=>{ this.setState({video_consult: !this.state.video_consult }) }}
                                        />
                                    </div>
                                </div>
                            </div>                                   */}
                        <div className={classes.main_with_border}>
                            <div className={classes.split_half}>
                                <div className={classes.radioText}>Encounter Duration:</div>
                                <div className={classes.radioButtonStyle}>
                                    <input type="checkbox" name="encounter_duration"
                                           checked={this.state.encounter_duration}
                                           onClick={() => {
                                               this.setState({encounter_duration: !this.state.encounter_duration})
                                           }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes.header}>Encounter Billing</div>
                        <div className={classes.billingMain}>
                            <div className={classes.billingText}>Billing Code:</div>
                            <select className={classes.smallInput} onChange={(code) => this.onCodeSelect(code)}>
                                <option key={-1}></option>
                                <BillingCodeOption
                                    codes={billing_codes}
                                />
                            </select>
                            <div className={classes.billingCodeText}>Billing Unit:</div>
                            <input
                                className={classes.smallInputBillingUnit}
                                onChange={(text) => this.onBillingUnit(text)}
                                value={billing_unit}
                                type="number"
                                placeholder={'Billing Unit'}
                            />
                        </div>
                    </div>
                    {!this.props.loading &&
                    <div className={classes.buttonView}>
                        <button className={classes.addMoreMediciensButton}
                                onClick={() => this.saveOutPatientForm()}>Save and Close
                        </button>
                        <button className={classes.cancelButton} onClick={() => {
                            const modalTitle = "Virtual Clinic Visit Report";
                            if (!Boolean(this.modalWindow)) {
                                this.modalWindow = window.open("/clinicalreport", modalTitle);
                                this.modalWindow.addEventListener('load', () => {
                                    this.modalWindow.document.title = modalTitle;
                                    this.renderPopOut();
                                }, false);
                            } else {
                                this.modalWindow.close();
                                this.modalWindow = window.open("/clinicalreport", modalTitle);
                                this.modalWindow.addEventListener('load', () => {
                                    this.modalWindow.document.title = modalTitle;
                                    this.renderPopOut();
                                }, false);
                            }
                        }}> View PDF
                        </button>
                        <button className={classes.cancelButton} onClick={() => this.onCancelClick()}>Cancel</button>
                    </div>
                    }

                    {this.props.error !== '' &&
                    <div className={classes.buttonView}>
                        <div className={classes.errorText}>{this.props.error}</div>
                        <button className={classes.addMoreMediciensButton} onClick={() => this.closeChat()}>Close Chat
                        </button>
                    </div>
                    }

                    {this.props.loading &&
                    <div className={classes.buttonView}>
                        <div className={classes.no_loading_view}>
                            <div className={classes.loading_loader}/>
                        </div>
                    </div>
                    }
                </div>
                }
                {this.props.message !== '' &&
                <div className={classes.saveView}>
                    <div className={classes.messageText}>{this.props.message}</div>
                    <button className={classes.okCancelButton} onClick={() => this.closeOnSaveClick()}>OK</button>
                </div>
                }
            </div>
        )
    }
}

const mapStateToProps = ({clinicalcannedResponseReducer, billingcodesReducer, outPatientFormReducer}) => {
    const {loading, message, error} = outPatientFormReducer;
    const {canned_responses, canned_responses_assessment, canned_responses_plan} = clinicalcannedResponseReducer;
    const {billing_codes} = billingcodesReducer;
    return {
        loading, message, error, canned_responses, canned_responses_assessment,
        canned_responses_plan, billing_codes
    };
}

export default connect(mapStateToProps, {
    saveAndCloseClinicalPatientForm, saveClinicalPatientFormInprogress, closePatientChat, refreshState,
    fetchCannedResponsesForObjectiveFindings, fetchCannedResponsesForAssessment, fetchCannedResponsesForPlan,
    fetchBillingCodes
})(outPatientForm);
