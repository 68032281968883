
import {NavLink} from 'react-router-dom';
import classes from './CustomersStyle.module.css';

const Header = (props) => {
    return (
        <div className={classes.main_body}>
            <div className={classes.heading}>
                <NavLink to="/customers"
                         className={props.activeLink === 'customers' ? classes.active : null}
                         onClick={() => props.HeaderItem('customers')}
                >All Customers</NavLink>
            </div>
            {/*<div className={classes.heading}>
                <NavLink to="/customers/chatting"
                    className={props.activeLink === 'chatting' ? classes.active: null}
                    onClick={() => props.HeaderItem('chatting')}
                >Chatting</NavLink>
            </div>*/}
        </div>
    )
}

export default Header;
