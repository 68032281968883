import  {Component} from 'react';
import classes from './outpatientform.module.css';
import _style from '../../../../../assets/_classes.module.css'
import {connect} from 'react-redux';
import ReactDOM from "react-dom";
import {closePatientChat, refreshState} from '../../../../../actions/ConversationAction';
import {
    saveAndCloseClinicalPatientForm,
    saveClinicalPatientFormInprogress,
    fetchChatPrescription
} from '../../../../../actions/ClinicalChatsAction';
import ngrokUrl from '../../../../../ngrok';

import {
    clinic_fetchCannedResponsesForObjectiveFindings,
    clinic_fetchCannedResponsesForAssessment, clinic_fetchCannedResponsesForPlan,
    clinic_canned_response_save,

} from '../../../../../actions/clinic/setting/ClinicCannedResponseAction';

import PrescriptionBuilder from '../../prescriptionBuilder';
import CreatePrescPdf from '../../createPresciptionPdf';


import {clinic_fetchBillingCodes} from '../../../../../actions/clinic/setting/ClinicBillingCodesAction';

import MyDocument from '../../../../PdfBuilder';
import {pdf} from '@react-pdf/renderer';

import MyPdf from '../../CreatePdf';

import _ from 'lodash';

import TextareaAutosize from 'react-autosize-textarea';
import hocAux from '../../../../../hoc/hocAux';
import _Header from '../../../../../components/UI/Header/Header'
import isMobileView from '../../../../../utils/isMobileView';
import {ENVIRONMENT} from "../../../../../environment";
import {functions, storage, storageRef} from "../../../../../firebase";
import axios from "axios";

//var request = require('request');

const ListItem = (props) => {
    return (
        props.data.map(function (data, index) {
            return data.title.indexOf('</') !== -1 ? (
                    <li key={index}
                        onClick={() => props.onSelectDiagnosisIssue(data, (data.title).replace(/<\s*br[^>]?>/, '\n').replace(/(<([^>]+)>)/g, ""), data.id)}
                        dangerouslySetInnerHTML={{__html: data.title.replace(/( <? *script)/gi, 'illegalscript')}}/>) :
                (<li onClick={() => props.onSelectDiagnosisIssue(data, data.title, data.id)} key={index}
                     value={data.title}>
                    <div>{`${data.title}`}</div>
                    <div
                        className={classes.titleText}>{`${(data.matchingPVs[0].label).replace(/(<([^>]+)>)/g, "")}`}</div>
                </li>);
        })
    )
}

function formatDate() {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(d.setDate(d.getDate() + 1)),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const CannedResponsesList = (props) => {
    return (
        props.canned_responses_data.map(function (data, index) {
            return (
                <div key={index} className={classes.canned_response_item}
                     onClick={() => props.onCannedResponseClick(data.uid, data.shortcut, data.text)}>
                    <div className={classes.canned_response_text}>{data.shortcut}</div>
                    <div className={classes.canned_response_arrow}
                         onClick={() => props.onCannedResponseClick(data.uid, data.shortcut, data.text)}>
                        <svg data-test="shortcut-select-button" fill="#000000" width="4px" height="12px"
                             viewBox="0 0 8 13">
                            <g fill="inherit" fillRule="evenodd">
                                <path fillRule="nonzero" d="M.59 11.34l4.58-4.59L.59 2.16 2 .75l6 6-6 6z"></path>
                            </g>
                        </svg>
                    </div>
                </div>
            )
        })
    )
};

const BillingCodeOption = (props) => {
    return (
        props.codes.map(function (data, index) {
            return (
                <option key={index} value={data.billing_code}>{data.billing_code}</option>
            )
        })
    )
}
var healthProblems = [];
var familyProblems = [];

class outPatientForm extends Component {

    constructor(props) {

        super(props);
        this.state = {
            dateTime: formatDate(),
            access_token: '',
            events: [],
            diagnosisValue: '',
            followUpCheck: false,
            displayDiagnosisList: false,
            opinionDescriptionValue: '',
            opinionDescription: false,
            scheduled_followup: "",
            commentAdded: '',
            mediciensArr: [],
            typing: false,
            typingTimeout: 0,
            symptoms: '',
            findings: '',
            assessment: '',
            plan: '',
            icd: '',
            followuptext: 'as needed',
            billing_code: '',
            billing_unit: 1,
            showCannedResponseForObjectiveFindingsList: false,
            showCannedResponseForAssessmentList: false,
            showCannedResponseForPlanList: false,
            canned_responses: [],
            canned_responses_assessment: [],
            canned_responses_plan: [],
            billing_codes: [],
            showIndicator: false,
            modalWindow: null,
            patient_profile: false,
            video_consult: false,
            encounter_duration: false,
            special_referals: false,
            requisitions: false,
            prescriptions: false,
            followUpDays: 0,
            findingsCannedShortcut: '',
            assessmentGoalsCannedShortcut: '',
            planCannedShortcut: '',
            symptomsCannedShortcut: ''

        };

        this.baseState = this.state;
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        // console.log("outpatientform nextProps", nextProps)
        // console.log("outpatientform this.state", this.state)
        if (!(_.isEqual(this.props.patient_profile, nextProps.patient_profile))) {
            familyProblems = []
            healthProblems = []
            this.generatePatientProfile(nextProps.patient_profile);
        }

        // if (!Boolean(this.props.subjective_symptoms) && nextProps.closeChatData.selectedIssue !== '' && nextProps.closeChatData.selectedIssue !== null) {
        //     this.setState({symptoms: nextProps.closeChatData.selectedIssue});
        // }
        if (nextProps.followClassStatus === true || nextProps.followUpStatus === true) {
            this.setState({followUpCheck: true})
        } else {
            this.setState({followUpCheck: false})
        }

        if (nextProps.clinic_canned_responses !== this.props.clinic_canned_responses) {
            this.setState({canned_responses: nextProps.clinic_canned_responses});
        }

        if (nextProps.clinic_canned_responses_assessment !== this.props.clinic_canned_responses_assessment) {
            this.setState({canned_responses_assessment: nextProps.clinic_canned_responses_assessment});
        }

        if (nextProps.clinic_canned_responses_plan !== this.props.clinic_canned_responses_plan) {
            this.setState({canned_responses_plan: nextProps.clinic_canned_responses_plan});
        }

        if (nextProps.clinic_billing_codes !== this.props.clinic_billing_codes) {
            this.setState({billing_codes: nextProps.clinic_billing_codes});
        }

        if (nextProps.subjective_symptoms !== this.props.subjective_symptoms) {
            this.setState({symptoms: nextProps.subjective_symptoms});
        }

        if (nextProps.findings !== this.props.findings) {
            this.setState({findings: nextProps.findings});
        }

        if (nextProps.assessment !== this.props.assessment) {
            this.setState({assessment: nextProps.assessment});
        }

        if (nextProps.plan !== this.props.plan) {
            this.setState({plan: nextProps.plan});
        }

        if (this.props.isVideoCall === 2 || nextProps.isVideoCall === 2) {
            this.setState({video_consult: true});
        }

        if (nextProps.followUpDays !== this.props.followUpDays) {
            this.setState({followUpDays: nextProps.followUpDays});
        }
    }

    componentDidMount() {
        // console.log("outpatientform nextProps", this.props)
        // console.log("outpatientform this.state", this.state)
        // const {childRef} = this.props;
        // childRef(this);
        this.setState({
            symptoms: this.props.subjective_symptoms,
            findings: this.props.findings,
            assessment: this.props.assessment,
            plan: this.props.plan,
            canned_responses: this.props.canned_responses,
            canned_responses_assessment: this.props.canned_responses_assessment,
            canned_responses_plan: this.props.canned_responses_plan,
        })
    }

    UNSAFE_componentWillMount() {

        const getToken = functions.httpsCallable('getToken');
        getToken().then(result => {
            this.setState({access_token: JSON.parse(result.data).access_token});
        });
        this.setState({
            followUpDays: this.props.followUpDays,
            symptoms: this.props.subjective_symptoms,
            findings: this.props.findings,
            assessment: this.props.assessment,
            plan: this.props.plan
        })

        this.props.clinic_fetchCannedResponsesForObjectiveFindings(this.props.clinicId);
        this.props.clinic_fetchCannedResponsesForAssessment(this.props.clinicId);
        this.props.clinic_fetchCannedResponsesForPlan(this.props.clinicId);
        this.props.clinic_fetchBillingCodes(this.props.clinicId);
    }

    generatePatientProfile = (patient_profile) => {
        if (patient_profile != null && patient_profile != undefined && patient_profile != {}) {

            for (var key of Object.keys(patient_profile)) {
                if(key){
                    if (key.indexOf("Flag") != -1 && key.indexOf("Family") == -1 && key.indexOf("alcoholFlag") == -1 && key.indexOf("smokeFlag") == -1 && key.indexOf("allergyFlag") == -1) {
                        if (patient_profile[key] == true) {
                            if (key == 'anxietyFlag') {
                                healthProblems.push("Anxiety")
                            }
                            if (key == 'asthmaFlag') {
                                healthProblems.push("Asthama\n")
                            }
                            if (key == 'arrhythmiasFlag') {
                                healthProblems.push("Arrhythmias")
                            }
                            if (key == 'bpFlag') {
                                healthProblems.push("High Blood Pressure")
                            }
                            if (key == 'cancerFlag') {
                                if (patient_profile.cancerData != null && patient_profile.cancerData != undefined && patient_profile.cancerData != '' && patient_profile.cancerData != []) {
                                    healthProblems.push("Cancer" + "(" + patient_profile.cancerData.toString() + ")")
                                } else {
                                    healthProblems.push("Cancer")
                                }
                            }
                            if (key == 'copdFlag') {
                                healthProblems.push("COPD")
                            }
                            if (key == 'depressionsFlag') {
                                healthProblems.push("Depressions")
                            }
                            if (key == 'diabetesFlag') {
                                healthProblems.push("Diabetes")
                            }
                            if (key == 'eczemaFlag') {
                                healthProblems.push("\n Eczema")
                            }
                            if (key == 'headFlag') {
                                healthProblems.push("Headaches/Migraines")
                            }
                            if (key == 'heartFlag') {
                                healthProblems.push("Heart Problems")
                            }
                            if (key == 'kidneyFlag') {
                                healthProblems.push(" Kidney Problems")
                            }
                            if (key == 'otherFlag') {
                                if (patient_profile.other != null && patient_profile.other != undefined && patient_profile.other != '' && patient_profile.other != []) {
                                    healthProblems.push("Other Problems" + "(" + patient_profile.other + ")");
                                }

                                healthProblems.push("Other Problems");
                            }
                            if (key == 'panicFlag') {
                                healthProblems.push("Panic Attacks")
                            }
                            if (key == 'psoriasisFlag') {
                                healthProblems.push("Psoriasis")
                            }
                            if (key == 'seizureFlag') {
                                healthProblems.push("Seizures")
                            }
                            if (key == 'strokeFlag') {
                                healthProblems.push("Stroke")
                            }
                            if (key == 'thyroidFlag') {
                                healthProblems.push("Thyroid")
                            }
                            if (key == 'utiFlag') {
                                healthProblems.push("Urinary Tract Infection")
                            }
                        }
                    }
                    if (key.indexOf("Family") != -1 && key.indexOf("Flag") != -1) {
                        if (patient_profile[key] == true) {
                            if (key == ("anxietyFlagFamily")) {
                                if (patient_profile.anxietyAnswer != null || patient_profile.anxietyAnswer != undefined || patient_profile.anxietyAnswer != '') {
                                    familyProblems.push("Anxiety(" + patient_profile.anxietyAnswer + ")")
                                }
                            }
                            if (key == ("arrhythmiasFlagFamily")) {
                                if (patient_profile.arrhythmiasAnswer != null || patient_profile.arrhythmiasAnswer != undefined || patient_profile.arrhythmiasAnswer != '') {
                                    familyProblems.push("Arrhythmias(" + patient_profile.arrhythmiasAnswer + ")")
                                }
                            }
                            if (key == ("asthmaFlagFamily")) {
                                if (patient_profile.asthamaAnswer != null || patient_profile.asthamaAnswer != undefined || patient_profile.asthamaAnswer != '') {
                                    familyProblems.push("Asthma(" + patient_profile.asthamaAnswer + ")")
                                }
                            }
                            if (key == ("bpFlagFamily")) {
                                if (patient_profile.bpAnswer != null || patient_profile.bpAnswer != undefined || patient_profile.bpAnswer != '') {
                                    familyProblems.push("High Blood Pressure(" + patient_profile.bpAnswer + ")")
                                }
                            }
                            if (key == ("cancerFlagFamily")) {
                                if (patient_profile.cancerAnswer != null || patient_profile.cancerAnswer != undefined || patient_profile.cancerAnswer != '') {
                                    familyProblems.push("Cancer(" + patient_profile.cancerAnswer + ")")
                                }
                            }
                            if (key == ("copdFlagFamily")) {
                                if (patient_profile.copdAnswer != null || patient_profile.copdAnswer != undefined || patient_profile.copdAnswer != '') {
                                    familyProblems.push("COPD(" + patient_profile.copdAnswer + ")")
                                }
                            }
                            if (key == ("depressionsFlagFamily")) {
                                if (patient_profile.adepressionsAnswer != null || patient_profile.depressionsAnswer != undefined || patient_profile.depressionsAnswer != '') {
                                    familyProblems.push("Depression(" + patient_profile.depressionsAnswer + ")")
                                }
                            }
                            if (key == ("diabetesFlagFamily")) {
                                if (patient_profile.diabetesAnswer != null || patient_profile.diabetesAnswer != undefined || patient_profile.diabetesAnswer != '') {
                                    familyProblems.push("Diabetes(" + patient_profile.diabetesAnswer + ")")
                                }
                            }
                            if (key == ("eczemaFlagFamily")) {
                                if (patient_profile.eczemaAnswer != null || patient_profile.eczemaAnswer != undefined || patient_profile.eczemaAnswer != '') {
                                    familyProblems.push("Eczema(" + patient_profile.eczemaAnswer + ")")
                                }
                            }
                            if (key == ("headFlagFamily")) {
                                if (patient_profile.headAnswer != null || patient_profile.headAnswer != undefined || patient_profile.headAnswer != '') {
                                    familyProblems.push("Headaches/Migraines(" + patient_profile.headAnswer + ")")
                                }
                            }
                            if (key == ("heartFlagFamily")) {
                                if (patient_profile.heartAnswer != null || patient_profile.heartAnswer != undefined || patient_profile.heartAnswer != '') {
                                    familyProblems.push("Heart Problems(" + patient_profile.heartAnswer + ")")
                                }
                            }
                            if (key == ("kidneyFlagFamily")) {
                                if (patient_profile.kidneyAnswer != null || patient_profile.kidneyAnswer != undefined || patient_profile.kidneyAnswer != '') {
                                    familyProblems.push("Kidney Problems(" + patient_profile.kidneyAnswer + ")")
                                }
                            }

                            if (key == ("panicFlagFamily")) {
                                if (patient_profile.panicAnswer != null || patient_profile.panicAnswer != undefined || patient_profile.panicAnswer != '') {
                                    familyProblems.push("Panic Attacks(" + patient_profile.panicAnswer + ")")
                                }
                            }
                            if (key == ("psoriasisFlagFamily")) {
                                if (patient_profile.psoriasisAnswer != null || patient_profile.psoriasisAnswer != undefined || patient_profile.psoriasisAnswer != '') {
                                    familyProblems.push("Psoriasis(" + patient_profile.psoriasisAnswer + ")")
                                }
                            }
                            if (key == ("seizureFlagFamily")) {
                                if (patient_profile.seizureAnswer != null || patient_profile.seizureAnswer != undefined || patient_profile.seizureAnswer != '') {
                                    familyProblems.push("Seizures(" + patient_profile.seizureAnswer + ")")
                                }
                            }
                            if (key == ("strokeFlagFamily")) {
                                if (patient_profile.strokeAnswer != null || patient_profile.strokeAnswer != undefined || patient_profile.strokeAnswer != '') {
                                    familyProblems.push("Stroke(" + patient_profile.strokeAnswer + ")")
                                }
                            }
                            if (key == ("thyroidFlagFamily")) {
                                if (patient_profile.thyroidAnswer != null || patient_profile.thyroidAnswer != undefined || patient_profile.thyroidAnswer != '') {
                                    familyProblems.push("Thyroid Problems(" + patient_profile.thyroidAnswer + ")")
                                }
                            }
                            if (key == ("utiFlagFamily")) {
                                if (patient_profile.utiAnswer != null || patient_profile.utiAnswer != undefined || patient_profile.utiAnswer != '') {
                                    familyProblems.push("Urinary Tract Infection(" + patient_profile.utiAnswer + ")")
                                }
                            }
                            if (key == ("otherFlagFamily")) {
                                if (patient_profile.otherAnswerFamily != null || patient_profile.otherAnswerFamily != undefined || patient_profile.otherAnswerFamily != '') {
                                    familyProblems.push("Other Problems(" + patient_profile.otherAnswerFamily + ":" + patient_profile.otherFamily + ")")
                                }
                            }

                        }

                    }
                }

            }
        }
        this.setState({check: Math.random()})
    }

    diagnosisInput = (event) => {
        var self = this;
        this.setState({showIndicator: true});
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            icd: event.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
                self.findDiagnosis(self.state.icd);
            }, 1000)
        });

    }

    findDiagnosis = text => {
        if (text.length > 2) {
            this.setState({displayDiagnosisList: true});
            /*var options = {
                url: `https://id.who.int/icd/entity/search?q=${text}&useFlexisearch=false&flatResults=true`,
                headers: {
                    'Authorization': 'Bearer ' + this.state.access_token,
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Accept-Language': 'en',
                    'API-Version': 'v2'
                }
            };*/
            let self = this;
            /*function callback(error, response, body) {
                if (!error && response.statusCode === 200) {
                    var info = JSON.parse(body);
                    self.setState({
                        showIndicator: false,
                        events: _.orderBy(info.destinationEntities, ['score'], ['desc'])
                    });
                }
            }*/
            axios({
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + this.state.access_token,
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Accept-Language': 'en',
                    'API-Version': 'v2'
                },
                url: `https://id.who.int/icd/entity/search?q=${text}&useFlexisearch=false&flatResults=true`,
                mode: 'cors',
            }).then(res=>{
                let info = res?.data?.destinationEntities? res?.data: JSON.parse(res.data);
                self.setState({
                    showIndicator: false,
                    events: _.orderBy(info.destinationEntities, ['score'], ['desc'])
                });
            }).catch(e=>{})
            //request(options, callback);
        } else {
            this.setState({showIndicator: false});
        }
    }

    handleChangeFollowUpDateTime = (event) => {
        this.setState({dateTime: event.target.value});
    };

    onSelectDiagnosisIssue = (data, title, id) => {
        this.setState({icd: title, displayDiagnosisList: false});
        var number = id.split('/entity/');

        /*var options = {
            url: `https://id.who.int/icd/entity/${number[1]}`,
            headers: {
                'Authorization': 'Bearer ' + this.state.access_token,
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Accept-Language': 'en',
                'API-Version': 'v2'
            }
        };*/
        var self = this;
        axios({
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + this.state.access_token,
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Accept-Language': 'en',
                'API-Version': 'v2'
            },
            url: `https://id.who.int/icd/entity/${number[1]}`,
            mode: 'cors',
        }).then(res=>{
            let info = res?.data?.definition? res?.data: JSON.parse(res.data);
            if (info.definition) {
                var descOption = Object.values(info.definition);
                self.setState({opinionDescription: true, opinionDescriptionValue: descOption[1]});
            } else {
                self.setState({opinionDescription: false, opinionDescriptionValue: ''});
            }
        }).catch(e=>{})
        /*function callback(error, response, body) {
            if (!error && response.statusCode === 200) {
                var info = JSON.parse(body);
                if (info.definition) {
                    var descOption = Object.values(info.definition);
                    self.setState({opinionDescription: true, opinionDescriptionValue: descOption[1]});
                } else {
                    self.setState({opinionDescription: false, opinionDescriptionValue: ''});
                }
            }
        }*/

        //request(options, callback);
    }

    handleChangeFollowUp = () => {
        this.setState({followUpCheck: !this.state.followUpCheck});
    }

    onCancelClick = () => {
        this.props.hideModal();
    }

    uploadPdf = (clinicId, patientId, conversationId, filename, file, metadata, fax_number, location, callback) => {
        var uploadTask = storage.ref(`/${location}/tier2/${clinicId}/${patientId}/${conversationId}/`).child(`${filename}`).put(file, metadata);
        uploadTask.on("state_changed" , (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                //console.log('firebasestorage Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case storageRef.TaskState.PAUSED: // or 'paused'
                        //console.log('firebasestorage Upload is paused');
                        break;
                    case storageRef.TaskState.RUNNING: // or 'running'
                        //console.log('firebasestorage Upload is running');
                        break;
                }
            },
            (error) => {
                //console.log("firebasestorage error ", error)
                callback(null)
                // Handle unsuccessful uploads
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    //console.log('firebasestorage File available at', downloadURL);
                    let cloudFunctionURL = ENVIRONMENT === "production"? "https://us-central1-yourdoctorsonline-prod.cloudfunctions.net" :"https://us-central1-yourdoctorsonline-staging.cloudfunctions.net"
                    if(fax_number){
                        fetch(cloudFunctionURL + '/sendPrescriptionFax',{
                            method: "POST", // *GET, POST, PUT, DELETE, etc.
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                perscription_url: downloadURL,
                                fax_number:fax_number
                            })// body data type must match "Content-Type" header
                        }).then(data => {

                        }).catch(error => {

                        })
                    }

                    callback(downloadURL)
                }).catch((error) =>{
                    callback(null)
                })
            });
    }

    saveOutPatientForm = async () => {
        await this.onSaveCannedResponse()
        // return
        this.props.saveClinicalPatientFormInprogress();

        const {
            clinicId, clinic_name, full_name, address, country,
            phone, fax, license_no, profile_image, signature, user_medications
        } = this.props;

        const {
            paidChat, conversationId, doctorID, admin, doctorName, patientName, patientId,
            selectedIssue, selectedCategory, tagChatList,
            heartRate, weightKg, weightLbs, stepCount, runningWalking, heightCm,
            currentSleep, averageSleep, heightFeet, periodStartDate, flowType, restingHeartRate,
            complete_address
        } = this.props.closeChatData;

        const {
            symptoms, findings, assessment, plan, icd, billing_code, billing_unit,
            followUpCheck, dateTime, prescriptions, requisitions, special_referals,
            encounter_duration, video_consult
        } = this.state;

        const {
            patientDOB, city, province, postalcode, postal_code, healthInsuranceCardNumber,
            healthInsuranceCardFront, healthInsuranceCardBack, gender, followUpID, followUpDays
        } = this.props;
        //console.log("postalcode  || postal_code",postalcode || postal_code)
        let current_user_medications = this.props.user_medications? this.props.user_medications[this.props.selectedConversation || this.props.conversationId]?.data || [] : []
        const element = <MyPdf
            {...this.props}
            plan={plan}
            assessment={assessment}
            findings={findings}
            icd={icd}
            followuptext={followUpDays !== 0 ? followUpDays + ' days' : 'as needed'}
            video_consult={video_consult}
            encounter_duration={encounter_duration}
            special_referals={special_referals}
            requisitions={requisitions}
            prescriptions={prescriptions}
            patient_issue={symptoms}
            patientName={this.props.patientName}
            patientDOB={this.props.patientDOB}
            doctorName={full_name}
            clinicName={clinic_name}
            doctorImage={profile_image}
            signatureImageURL={signature}
            doctorlicense={license_no}
            clinicPhoneNumber={phone}
            clinicFaxNumber={fax}
            clinicAddress={address + ' ' + city + ' ' + province + ' ' + country}
            familyProblems={familyProblems}
            healthProblems={healthProblems}
            patient_profile={this.props.patient_profile}
            endTime={this.props.endTime}
            start_timestamp={this.props.start_timestamp}
            user_medications={current_user_medications}
            currentDoctorProfile={this.currentDoctorProfile}
        />;

        const myPdf = pdf();
        myPdf.updateContainer(element);
        const blob = await myPdf.toBlob();
        var pname = this.props.patientName.toLowerCase();
        var dname = full_name.toLowerCase();
        while (pname.indexOf(' ') > -1) {
            pname = pname.replace(' ', '-');
        }
        while (dname.indexOf(' ') > -1) {
            dname = dname.replace(' ', '-');
        }
        let filename = pname + "-" + dname + "-" + this.props.start_timestamp + ".pdf";
        //patientname-doctorname-datetimestamp.pdf
        var file = new File([blob], filename, {lastModified: (new Date()).getTime()});
        var metadata = {
            contentType: 'application/pdf',
        };
        this.uploadPdf(clinicId, patientId, conversationId, filename, file, metadata, null, "soap_notes",async (soap_notes_url)=> {
            // console.log("uploadpdf this.props", this.props)
            if (current_user_medications.length > 0) {
                const pres = <CreatePrescPdf
                    prescriptionID={this.props.closeChatData?.conversationId || ""}
                    clinicName={this.props.prescriptionDoctor?.clinic_name || this.props.clinic_name}
                    clinicAddress={this.props.prescriptionDoctor?.address || this.props.clinicAddress}
                    clinicCity={this.props.prescriptionDoctor?.city || this.props.clinic_name}
                    clinicCountry={this.props.prescriptionDoctor?.country || this.props.clinicCountry}
                    clinicState={this.props.prescriptionDoctor?.state || this.props.clinicProvince}
                    clinicPostalCode={this.props.prescriptionDoctor?.postal_code || this.props.clinicPostalCode}
                    clinicPhoneNumber={this.props.prescriptionDoctor?.phone || this.props.clinicPhoneNumber}
                    clinicFaxNumber={this.props.prescriptionDoctor?.fax || this.props.clinicFaxNumber}
                    patientName={this.props.prescription_data[this.props.selectedConversation || this.props.conversationId]?.name || this.props.patientName}
                    patientDOB={this.props.prescription_data[this.props.selectedConversation || this.props.conversationId]?.DOB || this.props.DOB}
                    patientGender={this.props.prescription_data[this.props.selectedConversation || this.props.conversationId]?.gender || this.props.gender}
                    patient_phone_number={this.props.patient_phone_number}
                    patientAddress={this.props.complete_address}
                    healthInsuranceCardNumber={this.props.healthInsuranceCardNumber || ""}
                    start_timestamp={this.props.start_timestamp || (new Date()).getTime()}
                    user_medications={current_user_medications}
                    doctorName={this.props.doctorName}
                    signatureImageURL={this.props.prescriptionDoctor?.signature_url || this.props.currentDoctorProfile?.signatureImageURL}
                    doctorlicense={this.props.prescriptionDoctor?.license_no ||this.props.doctorlicense}
                    license_name={this.props.prescriptionDoctor?.license_name}
                    endTime={this.props.endTime || (new Date()).getTime()}
                />;
                const myPrescPdf = pdf();
                myPrescPdf.updateContainer(pres);
                const presBlob = await myPrescPdf.toBlob();
                pname = this.props.patientName.toLowerCase();
                dname = full_name.toLowerCase();
                while (pname.indexOf(' ') > -1) {
                    pname = pname.replace(' ', '-');
                }
                while (dname.indexOf(' ') > -1) {
                    dname = dname.replace(' ', '-');
                }
                let presFilename = pname + "-" + dname + "-prescription-" + this.props.start_timestamp + ".pdf";
                //patientname-doctorname-datetimestamp.pdf
                var presFile = new File([presBlob], presFilename, {lastModified: (new Date()).getTime()});



                this.uploadPdf(clinicId, patientId, conversationId, presFilename, presFile, metadata, this.props.pharmacy?.fax_number, "prescriptions",(prescription_url)=>{
                    let prescription_pdf_data = {
                        prescription_url: prescription_url || "",
                        pharmacy: this.props.pharmacy,
                        prescription_data: this.props.prescription_data[this.props.selectedConversation || this.props.conversationId],
                        doctorImage: this.props.doctorImage
                    }
                    this.props.saveAndCloseClinicalPatientForm(clinicId, clinic_name, patientDOB, country, city, province, postalcode || postal_code,
                        healthInsuranceCardNumber, healthInsuranceCardFront, healthInsuranceCardBack, gender, paidChat,
                        conversationId, doctorID, admin, full_name, patientName, patientId, selectedIssue, selectedCategory,
                        tagChatList, symptoms, findings, assessment, plan, icd, billing_code, billing_unit,
                        followUpCheck, dateTime, heartRate, weightKg, weightLbs, stepCount, runningWalking, heightCm,
                        currentSleep, averageSleep, heightFeet, periodStartDate, flowType, restingHeartRate,
                        complete_address, prescriptions, requisitions, special_referals, encounter_duration, video_consult,
                        this.props.start_timestamp, followUpDays, soap_notes_url || '', followUpID, prescription_pdf_data || {}, current_user_medications);
                })

                //this.closeOnSaveClick()


            } else {
                //this.closeOnSaveClick()
                this.props.saveAndCloseClinicalPatientForm(clinicId, clinic_name, patientDOB, country, city, province, postalcode || postal_code,
                    healthInsuranceCardNumber, healthInsuranceCardFront, healthInsuranceCardBack, gender, paidChat,
                    conversationId, doctorID, admin, full_name, patientName, patientId, selectedIssue, selectedCategory,
                    tagChatList, symptoms, findings, assessment, plan, icd, billing_code, billing_unit,
                    followUpCheck, dateTime, heartRate, weightKg, weightLbs, stepCount, runningWalking, heightCm,
                    currentSleep, averageSleep, heightFeet, periodStartDate, flowType, restingHeartRate,
                    complete_address, prescriptions, requisitions, special_referals, encounter_duration, video_consult,
                    this.props.start_timestamp, followUpDays, soap_notes_url || '', followUpID, '', current_user_medications);

            }
        })
    }

    closeChat = () => {
        const {clinicId, full_name} = this.props;
        const {
            paidChat, conversationId, doctorID, admin, doctorName, patientName, patientId,
            selectedIssue, selectedCategory,
            heartRate, weightKg, weightLbs, stepCount, runningWalking, heightCm,
            currentSleep, averageSleep, heightFeet, periodStartDate, flowType, restingHeartRate,
            complete_address
        } = this.props.closeChatData;
        this.props.closePatientChat(clinicId, paidChat, conversationId, doctorID, admin, full_name,
            patientName, patientId, selectedIssue, selectedCategory, heartRate, weightKg, weightLbs,
            stepCount, runningWalking, heightCm, currentSleep, averageSleep, heightFeet, periodStartDate,
            flowType, restingHeartRate, complete_address);
    }

    closeOnSaveClick = () => {
        this.props.hideModal();
        this.setState(this.baseState);
        this.props.refreshState();
    }

    onSubjectSymptomsChange = (event) => {
        this.setState({symptoms: event.target.value});
    }

    filterUsers = (event) => {
        var keyword = event.toLowerCase();
        var filtered_cannedResponses = this.state.canned_responses.filter((user) => {
            user = user.shortcut.toLowerCase();
            return user.indexOf(keyword) > -1;
        });
        if (filtered_cannedResponses.length !== 0) {
            this.setState({canned_responses: filtered_cannedResponses});
        } else {
            this.setState({canned_responses: this.props.clinic_canned_responses});
        }
    }

    filterAssessment = (event) => {
        var keyword = event.toLowerCase();
        var filtered_cannedResponses = this.state.canned_responses_assessment.filter((user) => {
            user = user.shortcut.toLowerCase();
            return user.indexOf(keyword) > -1;
        });
        if (filtered_cannedResponses.length !== 0) {
            this.setState({canned_responses_assessment: filtered_cannedResponses});
        } else {
            this.setState({canned_responses_assessment: this.props.clinic_canned_responses_assessment});
        }
    }

    filterPlan = (event) => {
        var keyword = event.toLowerCase();
        var filtered_cannedResponses = this.state.canned_responses_plan.filter((user) => {
            user = user.shortcut.toLowerCase();
            return user.indexOf(keyword) > -1;
        });
        if (filtered_cannedResponses.length !== 0) {
            this.setState({canned_responses_plan: filtered_cannedResponses});
        } else {
            this.setState({canned_responses_plan: this.props.clinic_canned_responses_plan});
        }
    }

    onObjectiveFindingsChange = (event) => {
        if ((event.target.value).match('#')) {
            this.setState({showCannedResponseForObjectiveFindingsList: true});
            this.filterUsers((event.target.value.slice((event.target.value).search('#'), event.target.value.length)));
        }
        this.setState({findings: event.target.value});
    }

    onAssessmentGoalsChange = (event) => {

        if ((event.target.value).match('#')) {
            this.setState({showCannedResponseForAssessmentList: true});
            this.filterAssessment((event.target.value.slice((event.target.value).search('#'), event.target.value.length)));
        }
        this.setState({assessment: event.target.value});
    }

    onPlanChange = (event) => {

        if ((event.target.value).match('#')) {
            this.setState({showCannedResponseForPlanList: true});
            this.filterPlan((event.target.value.slice((event.target.value).search('#'), event.target.value.length)));
        }
        this.setState({plan: event.target.value});
    }
    onSaveCannedResponse = () => {
        // event.preventDefault();
        if (Boolean(this.state.symptoms) && Boolean(this.state.symptomsCannedShortcut) && Boolean(this.state.symptomsCheckbox)) {
            this.props.clinic_canned_response_save(
                this.props.clinicId,
                this.state.symptoms,
                this.state.symptomsCannedShortcut,
                'chats'
            );
        }
        if (Boolean(this.state.plan) && Boolean(this.state.planCannedShortcut) && Boolean(this.state.planCheckbox)) {
            this.props.clinic_canned_response_save(
                this.props.clinicId,
                this.state.plan,
                this.state.planCannedShortcut,
                'plan'
            );
        }
        if (Boolean(this.state.assessment) && Boolean(this.state.assessmentCannedShortcut) && Boolean(this.state.assessmentCheckbox)) {
            this.props.clinic_canned_response_save(
                this.props.clinicId,
                this.state.assessment,
                this.state.assessmentCannedShortcut,
                'assessment'
            );
        }
        if (Boolean(this.state.findings) && Boolean(this.state.findingsCannedShortcut) && Boolean(this.state.findingsCheckbox)) {
            this.props.clinic_canned_response_save(
                this.props.clinicId,
                this.state.findings,
                this.state.findingsCannedShortcut,
                'findings'
            );
        }

    }

    renderPrescription() {
        const {
            clinic_name,
            full_name,
            address,
            city,
            province,
            country,
            phone,
            fax,
            license_no,
            profile_image,
            signature,
            healthInsuranceCardNumber
        } = this.props;
        if (this.modalWindow) {
            let root = this.modalWindow.document.body;
            ReactDOM.render(<PrescriptionBuilder
                prescriptionID={this.props.closeChatData?.conversationId || ""}
                clinicName={this.props.prescriptionDoctor?.clinic_name || this.props.clinic_name}
                clinicAddress={this.props.prescriptionDoctor?.address || this.props.clinicAddress}
                clinicCity={this.props.prescriptionDoctor?.city || this.props.clinic_name}
                clinicCountry={this.props.prescriptionDoctor?.country || this.props.clinicCountry}
                clinicState={this.props.prescriptionDoctor?.state || this.props.clinicProvince}
                clinicPostalCode={this.props.prescriptionDoctor?.postal_code || this.props.clinicPostalCode}
                clinicPhoneNumber={this.props.prescriptionDoctor?.phone || this.props.clinicPhoneNumber}
                clinicFaxNumber={this.props.prescriptionDoctor?.fax || this.props.clinicFaxNumber}
                patientName={this.props.prescription_data[this.props.selectedConversation || this.props.conversationId]?.name || this.props.patientName}
                patientDOB={this.props.prescription_data[this.props.selectedConversation || this.props.conversationId]?.DOB || this.props.DOB}
                patientGender={this.props.prescription_data[this.props.selectedConversation || this.props.conversationId]?.gender || this.props.gender}
                patient_phone_number={this.props.patient_phone_number}
                patientAddress={this.props.complete_address}
                healthInsuranceCardNumber={this.props.healthInsuranceCardNumber || ""}
                start_timestamp={this.props.start_timestamp || (new Date()).getTime()}
                user_medications={this.props.user_medications[this.props.selectedConversation || this.props.conversationId]?.data}
                doctorName={this.props.doctorName}
                signatureImageURL={this.props.prescriptionDoctor?.signature_url || this.props.currentDoctorProfile?.signatureImageURL}
                doctorlicense={this.props.prescriptionDoctor?.license_no ||this.props.doctorlicense}
                license_name={this.props.prescriptionDoctor?.license_name}
                endTime={this.props.endTime || (new Date()).getTime()}
            />, root);
        }
    }

    renderPopOut() {
        const {
            clinic_name,
            full_name,
            address,
            city,
            province,
            country,
            phone,
            fax,
            license_no,
            profile_image,
            signature,
            healthInsuranceCardNumber
        } = this.props;
        if (this.modalWindow) {
            let root = this.modalWindow.document.body;
            ReactDOM.render(<MyDocument
                {...this.props}
                symptoms={this.state.symptoms}
                plan={this.state.plan}
                assessment={this.state.assessment}
                findings={this.state.findings}
                icd={this.state.icd}
                followuptext={this.props.followUpDays !== 0 ? this.props.followUpDays + ' days' : 'as needed'}
                video_consult={this.state.video_consult}
                encounter_duration={this.state.encounter_duration}
                special_referals={this.state.special_referals}
                requisitions={this.state.requisitions}
                prescriptions={this.state.prescriptions}
                patient_issue={this.state.symptoms}
                patientName={this.props.patientName}
                patientDOB={this.props.patientDOB}
                doctorName={full_name}
                clinicName={clinic_name}
                doctorImage={profile_image}
                signatureImageURL={signature}
                currentDoctorProfile={{
                    ...this.props.currentDoctorProfile,
                    staffPhone: Boolean(this.props.currentDoctorProfile?.phoneNumber) ? this.props.currentDoctorProfile.phoneNumber : null,
                    healthInsuranceCardNumber
                }}
                doctorlicense={license_no}
                clinicPhoneNumber={phone}
                clinicPostalCode={this.props.clinicPostalCode}
                clinicFaxNumber={fax}
                clinicAddress={address + ' ' + city + ' ' + province + ' ' + country}
                familyProblems={familyProblems}
                healthProblems={healthProblems}
                patient_profile={this.props.patient_profile}
                endTime={this.props.endTime}
                start_timestamp={this.props.start_timestamp}
                user_medications={this.props.user_medications[this.props.selectedConversation || this.props.conversationId]?.data}
            />, root);
        }
    }

    onObjectiveCannedResponseClick = (uid, short_cut, text) => {
        this.setState({showCannedResponseForObjectiveFindingsList: false});
        var updatedString;

        if (this.state.findings.search('#') !== -1) {
            var replaceText = (this.state.findings.slice((this.state.findings).search('#'), this.state.findings.length));
            updatedString = (this.state.findings).replace(replaceText, text);
        } else {
            updatedString = (this.state.findings).concat(' ', text);
        }
        this.setState({findings: updatedString});
    }

    onAssessmentCannedResponseClick = (uid, short_cut, text) => {
        this.setState({showCannedResponseForAssessmentList: false});
        var updatedString;

        if (this.state.assessment.search('#') !== -1) {
            var replaceText = (this.state.assessment.slice((this.state.assessment).search('#'), this.state.assessment.length));
            updatedString = (this.state.assessment).replace(replaceText, text);
        } else {
            updatedString = (this.state.assessment).concat(' ', text);
        }
        this.setState({assessment: updatedString});
    }

    onPlanCannedResponseClick = (uid, short_cut, text) => {
        this.setState({showCannedResponseForPlanList: false});
        var updatedString;

        if (this.state.plan.search('#') !== -1) {
            var replaceText = (this.state.plan.slice((this.state.plan).search('#'), this.state.plan.length));
            updatedString = (this.state.plan).replace(replaceText, text);
        } else {
            updatedString = (this.state.plan).concat(' ', text);
        }
        this.setState({plan: updatedString});
    }

    onCodeSelect = (event) => {
        this.setState({billing_code: event.target.value});
    }

    onBillingUnit = (event) => {
        this.setState({billing_unit: event.target.value});
    }

    render() {
        let {
            symptoms,
            findings,
            assessment,
            plan,
            symptomsCheckbox,
            findingsCheckbox,
            assessmentCheckbox,
            planCheckbox,
            icd,
            billing_unit,
            opinionDescription,
            opinionDescriptionValue,
            canned_responses,
            canned_responses_assessment,
            canned_responses_plan,
            billing_codes,
            showIndicator,
            symptomsCannedShortcut,
            findingsCannedShortcut,
            assessmentGoalsCannedShortcut,
            planCannedShortcut
        } = this.state;
        return (
            <div className={classes.modal} style={{
                transform: this.props.show ? 'translateY(0)' : 'translateY(-100vh)',
                opacity: this.props.show ? '1' : '0'
            }}>

                {isMobileView() ?
                    <_Header title="End Patient Encounter" back={{onClick: () => this.onCancelClick()}}/> :
                    <div className={classes.header}>Encounter SOAP Note</div>}

                {this.props.message === '' &&
                <div className={classes.container}>
                    <div className={classes.content} style={isMobileView() ? {marginTop: '110px'} : null}>
                        {isMobileView() ?
                            <div className={classes.ongoingConsultation}><p>{this.props.patientName} • Ongoing
                                Consultation</p></div> : null}
                        {isMobileView() ? <div className={classes.main_with_border}>

                            <span>You can press # to load any saved templates </span>
                        </div> : null}
                        {[{
                            label: 'Subjective Symptoms:',
                            placeholder: 'Start typing here...',
                            value: symptoms,
                            onChange: (text) => this.onSubjectSymptomsChange(text),
                            onChangeCannedShortcut: (text) => this.setState({symptomsCannedShortcut: text}),
                            cannedShortcut: 'symptomsCannedShortcut',
                            checkbox: 'symptomsCheckbox',
                            checkboxLabel: 'Save as a Subjective Symptom template',
                        },
                            {
                                label: 'Objective Findings:',
                                placeholder: 'Start typing here...',
                                value: findings,
                                onChange: (text) => this.onObjectiveFindingsChange(text),
                                onChangeCannedShortcut: (text) => this.setState({findingsCannedShortcut: text}),
                                cannedShortcut: 'findingsCannedShortcut',
                                checkbox: 'findingsCheckbox',
                                checkboxLabel: 'Save as a Objective Finding template',
                                children: this.state.showCannedResponseForObjectiveFindingsList &&
                                    <div className={classes.canned_response_list}>
                                        <div className={classes.canned_response_view}>
                                            <CannedResponsesList
                                                canned_responses_data={canned_responses}
                                                onCannedResponseClick={(uid, short_cut, text) => this.onObjectiveCannedResponseClick(uid, short_cut, text)}
                                            />
                                        </div>
                                    </div>
                            },
                            {
                                label: 'Assessment:',
                                placeholder: 'Start typing here...',
                                value: assessment,
                                onChange: (text) => this.onAssessmentGoalsChange(text),
                                onChangeCannedShortcut: (text) => this.setState({assessmentGoalsCannedShortcut: text}),
                                cannedShortcut: 'assessmentGoalsCannedShortcut',
                                checkbox: 'assessmentCheckbox',
                                checkboxLabel: 'Save as a Assessment template',
                                children: this.state.showCannedResponseForAssessmentList &&
                                    <div className={classes.canned_response_list}>
                                        <div className={classes.canned_response_view}>
                                            <CannedResponsesList
                                                canned_responses_data={canned_responses_assessment}
                                                onCannedResponseClick={(uid, short_cut, text) => this.onAssessmentCannedResponseClick(uid, short_cut, text)}
                                            />
                                        </div>
                                    </div>
                            },
                            {
                                label: 'Plan:',
                                placeholder: 'Start typing here...',
                                value: plan,
                                onChange: (text) => this.onPlanChange(text),
                                onChangeCannedShortcut: (text) => this.setState({planCannedShortcut: text}),
                                cannedShortcut: 'planCannedShortcut',
                                checkbox: 'planCheckbox',
                                checkboxLabel: 'Save as a Plan template',
                                children: this.state.showCannedResponseForPlanList &&
                                    <div className={classes.canned_response_list}>
                                        <div className={classes.canned_response_view}>
                                            <CannedResponsesList
                                                canned_responses_data={canned_responses_plan}
                                                onCannedResponseClick={(uid, short_cut, text) => this.onPlanCannedResponseClick(uid, short_cut, text)}
                                            />
                                        </div>
                                    </div>
                                ,
                                sibling: null
                            }].map(item => <>
                            <div className={classes.main}>
                                <div className={classes.symptomsText}>{item.label} </div>
                                {isMobileView() ? <textarea
                                    className={classes.input}
                                    placeholder={item.placeholder}
                                    onChange={(text) => item.onChange(text)}
                                    value={item.value}
                                    style={{height: '80px'}}
                                /> : <TextareaAutosize
                                    className={classes.input}
                                    placeholder={item.placeholder}
                                    onChange={(text) => item.onChange(text)}
                                    value={item.value}
                                />}
                                {item.children ? item.children : null}
                            </div>
                            {isMobileView() ? <div className={classes.main} style={{flexDirection: 'row'}}>
                                <div className={classes.split_half}>

                                    <div className={classes.radioButtonStyle}>
                                        <input type="checkbox" name={item.checkbox}
                                               checked={this.state[item.checkbox]}
                                               onClick={() => {
                                                   this.setState({[item.checkbox]: !this.state[item.checkbox]})
                                               }}
                                        />
                                    </div>

                                </div>
                                <textarea
                                    className={classes.symptomCheckboxLabel}
                                    placeholder={item.checkboxLabel}
                                    onChange={(text) => item.onChangeCannedShortcut(text.target.value)}
                                    value={this.state[item.cannedShortcut]}
                                    style={{height: '15px'}}
                                />
                                {/* <div className={classes.symptomCheckboxLabel}>{item.checkboxLabel} */}
                                {/* </div> */}
                            </div> : null}
                        </>)}


                        <div className={classes.main_with_border}
                             style={isMobileView() ? {flexDirection: 'row'} : null}>
                            {!isMobileView() ? <div className={classes.followuptext}> Follow up: </div> : null}
                            <span>Patient has been advised to follow up in {this.props.followUpDays !== 0 ? this.props.followUpDays + ' days' : this.state.followuptext}  </span>{isMobileView() ?
                            <button className={_style.sidebar_btn} style={{
                                width: 'auto',
                                marginLeft: '20px',
                                paddingRight: '20px',
                                paddingLeft: '20px'
                            }} onClick={() => {
                            }}>Edit</button> : null}
                        </div>


                        {isMobileView() ? null : <div className={classes.header}>Encounter Features</div>}
                        {

                            [{label: 'Prescription', name: 'prescriptions',},
                                {label: 'Requisitions', name: 'requisitions',},
                                {label: 'Referral', name: 'special_referals',},
                                // { label: 'Video Conference', name: 'video_consult', },
                                {label: 'Encounter Duration', name: 'encounter_duration',}
                            ].map(item => isMobileView() ? <div className={classes.main} style={{flexDirection: 'row'}}>
                                <div className={classes.split_half}>

                                    <div className={classes.radioButtonStyle} style={{'margin-left': '-10px'}}>
                                        <input type="checkbox" name={item.name}
                                               checked={this.state[item.name]}
                                               onClick={() => {
                                                   this.setState({[item.name]: !this.state[item.name]})
                                               }}
                                        />
                                    </div>

                                </div>
                                <div className={classes.radioText}>{item.label} </div>
                            </div> : <div className={classes.main}>
                                <div className={classes.split_half}>
                                    <div className={classes.radioText}>{item.label} :</div>
                                    <div className={classes.radioButtonStyle}>
                                        <input type="checkbox" name={item.name}
                                               checked={this.state[item.name]}
                                               onClick={() => {
                                                   this.setState({[item.name]: !this.state[item.name]})
                                               }}
                                        />
                                    </div>
                                </div>
                            </div>)
                        }


                        {isMobileView() ? null : <div className={classes.header}>Encounter Billing</div>}
                        <div className={classes.billingMain}>
                            <div className={classes.main}>
                                <div className={classes.billingText}>Billing Code:</div>
                                <select className={classes.smallInput} onChange={(code) => this.onCodeSelect(code)}>
                                    <option key={-1}></option>
                                    <BillingCodeOption
                                        codes={billing_codes}
                                    />
                                </select></div>

                            <div className={classes.main}>
                                <div className={classes.billingCodeText}>Billing Unit:</div>
                                <input
                                    className={classes.input}
                                    className={classes.smallInputBillingUnit}
                                    onChange={(text) => this.onBillingUnit(text)}
                                    value={billing_unit}
                                    type="number"
                                    placeholder={'Billing Unit'}
                                />
                            </div>
                        </div>
                    </div>

                    {!this.props.loading &&
                    <div className={classes.buttonView}>
                        <button className={isMobileView() ? _style.sidebar_btn : classes.addMoreMediciensButton}
                                onClick={() => this.saveOutPatientForm()}>Save and Close
                        </button>
                        <button className={isMobileView() ? _style.sidebar_transparent_btn : classes.cancelButton}
                                onClick={() => {
                                    const modalTitle = "Virtual Clinic Visit Report";
                                    if (!Boolean(this.modalWindow)) {
                                        this.modalWindow = window.open("/clinicalreport", modalTitle);
                                        this.modalWindow.addEventListener('load', () => {
                                            this.modalWindow.document.title = modalTitle;
                                            this.renderPopOut();
                                            // this.renderPrescription()
                                        }, false);
                                    } else {
                                        this.modalWindow.close();
                                        this.modalWindow = window.open("/clinicalreport", modalTitle);
                                        this.modalWindow.addEventListener('load', () => {
                                            this.modalWindow.document.title = modalTitle;
                                            this.renderPopOut();
                                            // this.renderPrescription()
                                        }, false);
                                    }
                                }}> View PDF
                        </button>
                        <button className={isMobileView() ? _style.sidebar_transparent_btn : classes.cancelButton}
                                onClick={() => this.onCancelClick()}>Cancel
                        </button>
                    </div>
                    }

                    {this.props.error !== '' &&
                    <div className={classes.buttonView}>
                        <div className={classes.errorText}>{this.props.error}</div>
                        <button className={isMobileView() ? _style.sidebar_btn : classes.addMoreMediciensButton}
                                onClick={() => this.closeChat()}>Close Chat
                        </button>
                    </div>
                    }

                    {this.props.loading &&
                    <div className={classes.buttonView}>
                        <div className={classes.no_loading_view}>
                            <div className={classes.loading_loader}/>
                        </div>
                    </div>
                    }
                </div>
                }
                {this.props.message !== '' &&
                <div className={classes.saveView} style={isMobileView ? {marginTop: '70px'} : null}>
                    <div className={classes.messageText}>{this.props.message}</div>
                    <button className={isMobileView() ? _style.sidebar_transparent_btn : classes.okCancelButton}
                            onClick={() => this.closeOnSaveClick()}>OK
                    </button>
                </div>
                }
            </div>
        )
    }
}

const mapStateToProps = ({
                             clinicProfileSettingReducer, clinicalcannedResponseReducer,
                             clinicBillingCodesReducer, outPatientFormReducer, conversationReducer
                         }) => {

    const {user_medications, prescription_data} = conversationReducer;
    const {
        clinicId, clinic_name, full_name, address, city, province, country, phone, fax, license_no,
        profile_image, signature
    } = clinicProfileSettingReducer;

    const {loading, message, error} = outPatientFormReducer;
    const {
        clinic_canned_responses, clinic_canned_responses_assessment,
        clinic_canned_responses_plan
    } = clinicalcannedResponseReducer;
    const {clinic_billing_codes} = clinicBillingCodesReducer;

    return {
        clinicId, clinic_name, full_name, address, city, province, country, phone, fax, license_no,
        profile_image, signature, loading, message, error, clinic_canned_responses,
        clinic_canned_responses_assessment, clinic_canned_responses_plan, clinic_billing_codes,
        user_medications, prescription_data
    };
}

export default connect(mapStateToProps, {
    saveAndCloseClinicalPatientForm, closePatientChat, refreshState,
    clinic_fetchCannedResponsesForObjectiveFindings, clinic_fetchCannedResponsesForAssessment,
    clinic_fetchCannedResponsesForPlan, clinic_fetchBillingCodes, saveClinicalPatientFormInprogress,
    clinic_canned_response_save,
})(outPatientForm);
