
import classes from '../navigation.module.css';
import {NavLink} from 'react-router-dom';

const NavigationItem = (props) => (
    <li className={classes.NavigationItem}>
        <NavLink
            to={props.link}
            className={props.active}
            onClick={(e) => props.videoChatActive === false ? props.navClick(props.children) : (e.preventDefault(), window.alert(`A conference call is still active. Please close the Media chat to navigate`))}>
            <div className={classes.NavigationItemContainer}>
                {props.children !== 'Profile' &&
                <div className={classes.NavigationItemIcon} width="22px" height="22px" fill="#ffffff">{props.path}</div>
                }
                {props.children === 'Profile' &&
                <div className={classes.NavigationItemLogout}>
                    <img className={classes.NavigationItemLogout} src={props.profileImage} alt=""/>
                </div>
                }
            </div>
            <div className={classes.NavigationItemText}>{props.children}</div>
        </NavLink>
    </li>
)

export default NavigationItem;
