
import CustomerList from './CustomerList';
import classes from './CustomersStyle.module.css';

const Header = (props) => {
    return (
        <div className={classes.header_content}>
            <div className={classes.name_header}>
                <span className={classes.table_heading}>NAME</span>
            </div>
            <div className={classes.email_header}>
                <span className={classes.table_heading}>EMAIL</span>
            </div>
            <div className={classes.actions_header}>
                <span className={classes.table_heading}>ACTIONS</span>
            </div>
            <div className={classes.activity_header}>
                <span className={classes.table_heading}>ACTIVITY</span>
            </div>
            <div className={classes.chatting_header}>
                <span className={classes.table_heading}>CHATTING WITH</span>
            </div>
            <div className={classes.chats_count_header}>
                <span className={classes.table_heading}>NO. OF CHATS </span>
            </div>
        </div>
    )
}

const CustomersBody = (props) => {
    const customers = props.customersData.map(customer => {
        return <CustomerList
            key={customer.timestamp}
            name={customer.patientName}
            email={customer.patientEmail}
            doctorName={customer.doctorName}
            chatcount={customer.patientChatsCount}
            openChat={customer.openChat}
            queued={customer.chatInQueue}
            ownerChat={customer.ownerChat}
            issue={customer.issue}
            category={customer.category}
            chatOwner={customer.chatOwner}
            doctorID={customer.doctorID}
            patientID={customer.patientID}
            conversationId={customer.conversationId}
            onCustomerButtonClick={(queued, openChat, chatOwner, patientID, doctorID, conversationId,
                                    issue, category) => props.onChatButtonClick(queued, openChat, chatOwner, patientID, doctorID,
                conversationId, issue, category)}
        />
    })
    return (
        <div className={classes.table_content}>
            <Header/>
            <div classes={classes.list_view}>
                <div className={classes.table_view}>
                    {customers}
                </div>
            </div>
        </div>
    )
}

export default CustomersBody;
