import {
    CHANGE_CLINICAL_CHAT_STATUS_FAILED,
    CHANGE_CLINICAL_CHAT_STATUS_SUCCESS,
    FETCH_CLINICAL_CHATS_FAILED,
    FETCH_CLINICAL_CHATS_IN_PROCESS,
    FETCH_CLINICAL_CHATS_SUCCESS,
    FETCH_CLINICAL_CHATS_UPDATE,
    FETCH_CLINICAL_CONVERSATIONS_COUNT_FOR_EACH_DOCTOR,
    REFRESH_CLINICAL_CONVERSATION,
    SET_VIDEO_STATUS
} from '../constants.js';

const INITIAL_STATE = {clinical_conversations: [], loading: false, clinicalChatCounts: [], videoChatFlag: false};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REFRESH_CLINICAL_CONVERSATION:
            return INITIAL_STATE;
        case FETCH_CLINICAL_CONVERSATIONS_COUNT_FOR_EACH_DOCTOR:
            return {...state, clinicalChatCounts: action.payload};
        case SET_VIDEO_STATUS:
            return {...state, videoChatFlag: !state.videoChatFlag};
        case FETCH_CLINICAL_CHATS_IN_PROCESS:
            return {...state, loading: true};
        case FETCH_CLINICAL_CHATS_SUCCESS:
            return {...state, clinical_conversations: action.payload, loading: false};
        case FETCH_CLINICAL_CHATS_UPDATE:
            return {
                ...state, clinical_conversations:
                    state.clinical_conversations.map(conversation => conversation.conversationId === action.payload.conversationId ?
                        // transform the one with a matching conversationId
                        {
                            ...conversation,
                            patientID: action.payload.patientID,
                            doctorID: action.payload.doctorID,
                            doctorName: action.payload.doctorName,
                            activeOnlineChats: action.payload.activeOnlineChats,
                            onlineFreeChats: action.payload.onlineFreeChats,
                            onlinePaidChats: action.payload.onlinePaidChats,
                            totalChatLimit: action.payload.totalChatLimit,
                            chatInQueue: action.payload.queued,
                            paidChat: action.payload.paidChat,
                            ownerChat: conversation.doctorID === action.payload.doctorID ? true : false,
                            patientCountry: action.payload.country,
                            patientCity: action.payload.city,
                            lastMessageTimeStamp: action.payload.timestamp,
                            start_timestamp: action.payload.timestamp,
                            timestamp: action.payload.timestamp,
                            unreadCount: action.payload.unreadCount
                        } :
                        // otherwise return original conversation
                        conversation
                    ), loading: false
            };
        case FETCH_CLINICAL_CHATS_FAILED:
            return {...state, clinical_conversations: action.payload, loading: false};
        case CHANGE_CLINICAL_CHAT_STATUS_SUCCESS:
            for (var j = state.clinical_conversations.length - 1; j >= 0; j--) {
                if (state.clinical_conversations[j].conversationId === action.payload.conversationId) {
                    state.clinical_conversations[j].chatInQueue = false;
                    state.clinical_conversations[j].yourClinicalChat = action.payload.yourClinicalChat;
                    return {...state, clinical_conversations: [...state.clinical_conversations]};
                }
            }
            return {...state};
        case CHANGE_CLINICAL_CHAT_STATUS_FAILED:
            return {...state,};
        default:
            return state;
    }
};
