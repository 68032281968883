import {CLINIC_FETCH_TAGS, CLINIC_TAG_CHANGE, CLINIC_TAG_SAVE} from '../../../constants';

import _ from 'lodash';
import {database} from "../../../firebase";

export const clinic_tag_changed = (text) => {
    return {
        type: CLINIC_TAG_CHANGE,
        payload: text
    }
}

export const clinic_save_tags = (tag) => {
    return (dispatch) => {
        let tagsValue = {
            tag: tag,
            ticketCount: 0,
            ChatCount: 0
        }
        let tagkey = getTagsRef().push().key;
        var updates = {};
        updates[`/tags/${tagkey}`] = tagsValue;
        database.ref().update(updates)
            .then(() => {
                dispatch({
                    type: CLINIC_TAG_SAVE
                })
            })
            .catch((error) => {
                //console.log("error: ", error.message);
            })
    }
}

export const clinic_fetchTags = () => {
    return (dispatch) => {
        getTagsRef().on('value', tagSnap => {
            if (tagSnap.val()) {
                const tags = _.map(tagSnap.val(), (val, uid) => {
                    return {...val, uid};
                });

                const filteredTags = tags.filter(function (el) {
                    return el != null && el !== undefined;
                });

                dispatch({
                    type: CLINIC_FETCH_TAGS,
                    payload: filteredTags
                })
            } else {
                dispatch({
                    type: CLINIC_FETCH_TAGS,
                    payload: []
                })
            }
        })
    }
}

const getTagsRef = () => {
    return database.ref().child('tags');
}
