
import Navigation from '../navigationItems/Navigation';

const SideBar = (props) => (<Navigation
        profileImage={props.profileImage}
        adminStatus={props.admin}
        clinicalDoctor={props.clinicalStatus}
        navClick={(data) => props.navClick(data)}/>
)

export default SideBar;
