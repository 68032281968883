import {AgoraVideoPlayer, createClient, createMicrophoneAndCameraTracks} from "agora-rtc-react";
import AgoraRTC from 'agora-rtc-sdk-ng';
import VirtualBackgroundExtension from "agora-extension-virtual-background";
import {useEffect} from "react";
import Videos from "./Video";
import Controls from "./Controls";
import styles from "./AgoraComponent.module.css";
import Spinner from "../../../components/Spinner";
import classes from "./AgoraComponent.module.css";
import microphone from "../../../assets/microphone.png";
import muteMicrophone from "../../../assets/mute-microphone.png";
const muteCamera = require('../../../assets/mute-camera.png');

const {useState} = require("react");

const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();
/*const extension = new VirtualBackgroundExtension();
AgoraRTC.registerExtensions([extension]);
let processor = null;
var localTracks = {
    videoTrack: null,
    audioTrack: null
};*/
export const VideoCall = (props) => {
    const {setInCall, channelName, client, appId, token} = props;
    const [users, setUsers] = useState ([]);
    const [start, setStart] = useState(false);
    const {ready, tracks} = useMicrophoneAndCameraTracks();
    const [remoteConnectionState, setRemoteConnectionState] = useState({audio: true, video: true});

    const init = async (name) => {
        console.log("init", name);
        client.on("user-published", async (user, mediaType) => {
            console.log("agoraaa user-published");

            await client.subscribe(user, mediaType);
            setUsers((prevUsers) => {
                let oldUsers = prevUsers.filter((User) => User.uid !== user.uid);
                return [...oldUsers, user];
            });
            if (mediaType === "audio") {
                user.audioTrack?.play();
            }
            setRemoteConnectionState((prevState) => {
                let newState = {...prevState};
                newState[mediaType] = true;
                return newState;
            })
        });

        client.on("user-unpublished", (user, type) => {
            console.log("agoraaa user-unpublished", user, type);
            if (type === "audio") {
                user.audioTrack?.stop();
            }
            setRemoteConnectionState((prevState) => {
                let newState = {...prevState};
                newState[type] = false;
                return newState;
            })
            /*if (type === "video") {
                setUsers((prevUsers) => {
                    return prevUsers.filter((User) => User.uid !== user.uid);
                });
            }*/
        });

        client.on("user-left", (user) => {
            console.log("agoraaa  user-left", user);
            setUsers((prevUsers) => {
                return prevUsers.filter((User) => User.uid !== user.uid);
            });
            if (user.audioTrack) {
                user.audioTrack?.stop();
            }
            setRemoteConnectionState({audio: false, video: false})

        });

        await client.join(appId, channelName, token, props.account);
        console.log("tracks ", tracks)
        if (tracks) await client.publish([tracks[0], tracks[1]]);
        setStart(true);

    };

    useEffect(() => {
        // function to initialise the SDK
        try{

            if (ready && tracks && !start) {
                console.log("agoraaa init ready");
                init(channelName);
            }
        }catch (e) {
            console.log("agoraaa init e", e)
        }


    }, [channelName, client, ready, tracks, start]);
    useEffect(()=>{
        console.log("users ", users)
        console.log("tracks ", tracks)
    },[users, tracks])
    return (
        <>
            {ready && tracks && (
                <div>
                    <div>
                        {start && tracks && users.length > 0?
                            users.map((user) => {
                                if (user.videoTrack && remoteConnectionState.video) {
                                    return (
                                        <div>
                                            {!remoteConnectionState.audio && <div className={styles.muteAudioIconRemote}>
                                                <img
                                                    className={classes.icon_image_small}
                                                    src={muteMicrophone}
                                                    alt=""
                                                />
                                            </div> }
                                        <AgoraVideoPlayer className={`vid ${styles.video_frame_remote}`}
                                                          videoTrack={user.videoTrack} key={user.uid}/>
                                        </div>
                                    );
                                } else return <div className={`vid ${styles.video_frame_black_remote}`}>
                                    <img
                                    className={classes.icon_image}
                                    src={muteCamera}
                                    alt=""
                                /> <img
                                        className={classes.icon_image}
                                        src={remoteConnectionState.audio? microphone: muteMicrophone}
                                        alt=""
                                    />  </div>;
                            }):<div className={`vid ${styles.video_frame_black_remote}`}><Spinner  color={"white"}  isLoading={true}/></div>}
                        {start && tracks ?
                            <AgoraVideoPlayer className={`vid ${styles.video_frame}`} videoTrack={tracks[1]}
                                              key={users[0]?.uid}/> : <div className={`vid ${styles.video_frame_black}`}><Spinner  color={"white"}  isLoading={true}/></div>}
                    </div>
                    <div>
                        <Controls tracks={tracks} setStart={setStart} setInCall={setInCall} client={client} type={"videoCall"}/>
                    </div>
                </div>
            )}

            {/*{start && tracks && <Videos users={users} tracks={tracks} />}*/}
        </>
    );

};
