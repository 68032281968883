import {
    CANNED_RESPONSE_DESCRIPTION,
    CANNED_RESPONSE_SAVE,
    CANNED_RESPONSE_SHORT_CUT,
    CLINIC_CANNED_RESPONSE_DESCRIPTION,
    CLINIC_CANNED_RESPONSE_SAVE,
    CLINIC_CANNED_RESPONSE_SHORT_CUT,
    CLINIC_FETCH_CANNED_RESPONSE,
    FETCH_CANNED_RESPONSE,
    SET_SUBJECTIVE_SYMPTOMS,
    SET_ASSESSMENT,
    SET_FINDINGS,
    SET_PLAN, REFRESH_CANNED_STATUS,
} from '../constants';

const INITIAL_STATE = {
    description: '', short_cut: '', canned_responses: [],
    clinic_description: '', clinic_short_cut: '', clinic_canned_responses: [], subjective_symptoms: '', findings: '', assessment: '', plan: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REFRESH_CANNED_STATUS:
            return INITIAL_STATE;
        case CANNED_RESPONSE_DESCRIPTION:
            return {...state, description: action.payload};
        case CANNED_RESPONSE_SHORT_CUT:
            return {...state, short_cut: action.payload};
        case CANNED_RESPONSE_SAVE:
            return {...state, description: '', short_cut: ''};
        case FETCH_CANNED_RESPONSE:
            return {...state, canned_responses: action.payload};
        case CLINIC_CANNED_RESPONSE_DESCRIPTION:
            return {...state, clinic_description: action.payload};
        case CLINIC_CANNED_RESPONSE_SHORT_CUT:
            return {...state, clinic_short_cut: action.payload};
        case CLINIC_CANNED_RESPONSE_SAVE:
            return {...state, clinic_description: '', clinic_short_cut: ''};
        case CLINIC_FETCH_CANNED_RESPONSE:
            return {...state, clinic_canned_responses: action.payload};
        case SET_SUBJECTIVE_SYMPTOMS:
            return {...state, subjective_symptoms: action.payload};
        case SET_FINDINGS:
            return {...state, findings: action.payload};
        case SET_ASSESSMENT:
            return {...state, assessment: action.payload};
        case SET_PLAN:
            return {...state, plan: action.payload};
        default:
            return state;
    }
}
