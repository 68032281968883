import  {Component, Fragment, createRef} from 'react';
import Aux from '../../../hoc/hocAux';
import {connect} from 'react-redux';
import classes from './ClinicalPatientsBuilder.module.css';
import {CSVReader} from 'react-papaparse';
import {Link} from 'react-router-dom';

import _ from 'lodash';
import Calendar from '../../../components/customCalendar/calendar.js';
import {save_followUp} from '../../../actions/ConversationAction';
import {fetchClinicalDoctors} from '../../../actions/ReportsAction';
import {
    deletePatients,
    fetchRegisteredPatients,
    fetchTimeSlots,
    removeClinicalPatientsListener,
    sendInvites,
    sendUnFilledInvites,
    storeSlot,
    updatePatient,
    updateRegistrationStatus
} from '../../../actions/clinicalPatientsActions';
import TextareaAutosize from 'react-autosize-textarea';
import actionicon from '../../../assets/actionicon.png';
import {ENVIRONMENT} from '../../../environment';
import {sendNewMessageNotification} from '../../../actions/ClinicalChatsAction'
import {database} from "../../../firebase";

var moment = require("moment");


const buttonRef = createRef()


class ClinicalPatientsBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            DOB: '',
            healthInsuranceNo: '',
            patientEmail: '',
            gender: '',
            filterFirstName: '',
            filterLastName: '',
            filterGender: '',
            filterDOB: '',
            filterHealthInsuranceNo: '',
            filterRegistrationStatus: '',
            patientEmails: '',
            selectedFirstName: '',
            selectedLastName: '',
            selectedgender: '',
            selectedDOB: '',
            selectedhealthInsuranceNo: '',
            selectedregistrationStatus: '',
            selectedpatientEmail: '',
            selectedDoctor: this.props.staffData.ansPatients ? this.props.staffData : {},
            selectedDoctorFinal: this.props.staffData.ansPatients ? this.props.staffData : {},
            fromID: '',
            conversationId: '',
            file: '',
            filetype: '',
            showUploadingModal: false,
            showCloseModal: false,
            showTagsListModal: false,
            selectedTags: [],
            showCannedResponseList: false,
            selectedCannedResponseShortCut: '',
            selectedCannedResponseText: '',
            showSelectedMessages: null,
            showOnlineDoctors: false,
            canned_responses: [],
            playSound: false,
            messageLength: 0,
            showOutPatientForm: false,
            chatHistoryItemClick: false,
            chatHistoryUid: '',
            chatHistoryCategory: '',
            remoteStreams: {},
            minimizeVideo: true,
            audMute: false,
            vidMute: false,
            declineShown: false,
            videoState: false,
            videoStatus: this.props.videoStatus,
            showUpView: false,
            followClassStatus: false,
            skipClassStatus: false,
            dateTime: '',
            selectedPatients: [],
            showBatchUploadModal: false,
            showAddPatientModal: false,
            patientsInvites: [],
            showBatchUploadError: false,
            batchUploadError: '',
            showAddPatientError: false,
            addPatientError: '',
            selectedRow: null,
            editRow: false,
            showActions: false,
            hoverRow: null,
            actionState: 0,
            toDelete: {},
            isManualInfo: false,
            showScheduleFollow: false,
            selectedFollowUpPatient: null,
            scheuledDate: '',
            followUpDoctorName: '',
            showFollowUpErrorText: '',
            showFollowUpError: false,
            showFollowUpSuccessText: '',
            showFollowUpSuccess: false,
            showAddPatientSuccess: false,
            addPatientSuccess: '',
            selectedDay: moment(),
            selectedDuration: 0,
            scheduleFollowupState: 0,
            selectedTime: '',
            selectedPatient: null,
            resetDate: false,
            bookingInProgress: false,
            invitePatientInProgress: false

        }

        /* if (props.history.location.state !== undefined && props.history.location.state !== null) {
            let { queued, conversationId, patientID, doctorID, issue, category, start_time } = props.history.location.state;
            if (queued) {
                this.removedFromQueueAndshowMessages(true, conversationId, patientID, doctorID, issue, category, start_time);
            } else {
                this.showMessages(true, conversationId, patientID, doctorID, issue, category, start_time);
            }
        } */
    }

    UNSAFE_componentWillMount() {
        this.props.fetchRegisteredPatients(this.props.clinicId);
        this.props.fetchClinicalDoctors(this.props.clinicId, () => {
        });
        /*  this.props.fetchDoctorDetails();
         this.props.fetchOnlineDoctors();
         this.props.fetchTags();
         this.props.childChanged();
         this.props.childChangedDoctor();
         this.props.fetchCannedResponses();
         this.props.getAcceptStatus();
         this.props.refreshMessages();
         if (this.props.history.location.state === undefined) {
             this.props.refreshConversations();
             this.props.fetchConversations();
             this.props.fetchConversationCount();
             this.props.fetchDoctorsNameAndImages();
         } */
    }

    componentWillUnmount() {
        this.props.removeClinicalPatientsListener();
    }

    UNSAFE_componentWillReceiveProps(newProps) {

    }

    componentDidUpdate(prevProps) {

    }


    handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e)
        }
    }
    openBatchModal = (e) => {
        this.setState({
            showBatchUploadModal: true,
            batchUploadError: '',
            showBatchUploadError: false
        });

    }
    deletePatient = () => {
        if (window.confirm("Are you sure you want to delete patients?")) {
            this.props.deletePatients(Object.keys(this.state.toDelete), this.props.clinicId);
            this.setState({toDelete: {}});
        }
    }
    handleOnFileLoad = (data) => {
        this.setState({
            batchUploadError: '',
            showBatchUploadError: false
        })
        var patientsInvites = [];
        if (data.length > 0) {
            var header = data[0];
            if (header.data.length < 6) {
                this.setState({
                    batchUploadError: 'file is not in correct format.',
                    showBatchUploadError: true
                })
            } else {
                if (header.data[0] === 'Last Name' && header.data[1] === 'First Name' && header.data[2] === 'Gender' && header.data[3] === 'Date of Birth' && header.data[4] === 'Health Insurance #' && header.data[5] === 'Email Address') {
                    for (var i = 1; i < data.length; i++) {
                        var row = {};
                        row.lastName = data[i].data[0];
                        row.firstName = data[i].data[1];
                        row.gender = data[i].data[2];
                        row.dob = data[i].data[3];
                        row.insuranceCardNo = data[i].data[4];
                        row.email = data[i].data[5];
                        patientsInvites.push(row);
                    }
                    this.setState({patientsInvites: patientsInvites});
                } else {
                    this.setState({
                        batchUploadError: 'file is not in correct format.',
                        showBatchUploadError: true
                    })
                }
            }
        }
    }

    handleOnError = (err, file, inputElem, reason) => {
        this.setState({
            batchUploadError: err,
            showBatchUploadError: true
        })
        //console.log(err);
    }
    hideAddPatientModal = () => {
        this.setState({showAddPatientModal: false, showAddPatientSuccess: false});
    }

    showAddPatientModal = () => {
        this.setState({
            showAddPatientModal: true,
            showAddPatientError: false,
            addPatientError: ''
        });
    }
    handleOnRemoveFile = (data) => {
        this.setState({showBatchUploadModal: false, showBatchUploadError: false, batchUploadError: ''});
        this.setState({patientsInvites: []});
    }
    uploadFile = () => {
        if (!this.state.showBatchUploadError) {
            this.setState({invitePatientInProgress: true});
            if (this.state.patientsInvites.length > 0) {
                this.props.sendInvites(this.state.patientsInvites, this.props.clinicId, this.props.clinic_name, (status, msg) => {
                    this.setState({invitePatientInProgress: false});
                    if (status) {
                        this.setState({showBatchUploadModal: false});
                    } else {
                        this.setState({
                            batchUploadError: msg,
                            showBatchUploadError: true
                        });
                    }
                });
            } else {
                this.setState({
                    invitePatientInProgress: false, batchUploadError: "please upload a valid file to continue",
                    showBatchUploadError: true
                });
            }
        }
    }
    clearAddPatientForm = () => {
        this.setState({
            showAddPatientModal: false,
            firstName: '',
            lastName: '',
            gender: '',
            DOB: '',
            healthInsuranceNo: '',
            patientEmail: ''
        });
    }
    sendRegistrationEmail = () => {
        this.setState({invitePatientInProgress: true})
        if (this.state.isManualInfo) {
            var now = new Date(this.state.DOB)
            var DOBFormat = moment(now).format('YYYY-MM-DD');
            var data = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                dob: DOBFormat,
                insuranceCardNo: this.state.healthInsuranceNo,
                email: this.state.patientEmail,
                gender: this.state.gender
            }
            if (!Boolean(this.state.firstName) || !Boolean(this.state.lastName) || !Boolean(this.state.DOB) || !Boolean(this.state.healthInsuranceNo) || !Boolean(this.state.patientEmail) || !Boolean(this.state.gender)) {
                this.setState({
                    showAddPatientError: true,
                    addPatientError: "empty fields are not allowed",
                    showAddPatientSuccess: false,
                    invitePatientInProgress: false
                });
            } else {
                var re = /\S+@\S+\.\S+/;
                if (!re.test(this.state.patientEmail)) {
                    this.setState({
                        showAddPatientError: true,
                        showAddPatientSuccess: false,
                        addPatientError: `${this.state.patientEmail} is not a valid email`,
                        invitePatientInProgress: false
                    })
                } else {

                    this.props.sendInvites([data], this.props.clinicId, this.props.clinic_name, (status, msg) => {
                        if (status) {
                            this.setState({
                                showAddPatientModal: false,
                                firstName: '',
                                lastName: '',
                                gender: '',
                                DOB: '',
                                healthInsuranceNo: '',
                                patientEmail: '',
                                invitePatientInProgress: false
                            });
                        } else {
                            this.setState({
                                showAddPatientError: true,
                                addPatientError: msg,
                                firstName: '',
                                lastName: '',
                                gender: '',
                                DOB: '',
                                healthInsuranceNo: '',
                                patientEmail: '',
                                invitePatientInProgress: false
                            });
                        }

                    });
                }
            }
        } else {
            if (this.state.patientEmails) {
                var patientEmails = this.state.patientEmails.replace(/\s/g, '').split(',');
                var errorEmail = '';
                var re = /\S+@\S+\.\S+/;
                for (var i = 0; i < patientEmails.length; i++) {
                    if (!re.test(patientEmails[i])) {
                        errorEmail = patientEmails[i];
                        break;
                    }
                }
                if (errorEmail !== '') {
                    this.setState({
                        showAddPatientError: true,
                        addPatientError: `${errorEmail} is not a valid email`,
                        showAddPatientSuccess: false
                    });
                } else {
                    this.props.sendUnFilledInvites(patientEmails, this.props.clinicId, this.props.clinic_name, (status, msg) => {
                        if (status) {
                            this.setState({
                                showAddPatientError: false,
                                addPatientError: '',
                                showAddPatientSuccess: true,
                                addPatientSuccess: 'email sent successfully',
                                invitePatientInProgress: false
                            });

                        } else {
                            this.setState({
                                showAddPatientError: true,
                                addPatientError: msg,
                                showAddPatientSuccess: false,
                                addPatientSuccess: '',
                                invitePatientInProgress: false
                            });

                        }
                    });
                }
            } else {
                this.setState({
                    showAddPatientError: true,
                    addPatientError: "no email found",
                    invitePatientInProgress: false
                })
            }


        }


    }
    resendEmail = (patients) => {

        var params = 'email=' + encodeURIComponent(patients.email) + '&' +
            'clinic_patient_registry_key=' + encodeURIComponent(patients.uid) + '&' +
            'clinic_name=' + encodeURIComponent(this.props.clinic_name) + '&' +
            'patient_name=' + encodeURIComponent(patients.firstName + " " + patients.lastName) + '&isManual=true';
        let url = '';
        if (ENVIRONMENT === 'development') {
            url = "https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/sendClinicPatientEmail?";
        } else {
            url = "https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/sendClinicPatientEmail?";
        }
        fetch(url + params)
            .then(() => {
                var updates = {};
                updates[`/clinicalPatients/${this.props.clinicId}/${patients.uid}/registrationStatus`] = 'mailsent';
                updates[`/clinicalPatients/${this.props.clinicId}/${patients.uid}/timestamp`] = (new Date()).getTime();
                database.ref().update(updates);
                this.props.updateRegistrationStatus(patients.uid, 'mailsent');
                this.setState({actionState: 0, selectRow: null});
            })
            .catch((e) => {
                //console.log("mail failed ", e);
            })

    }
    selectRow = (patients) => {
        this.setState({
            editRow: true,
            selectedRow: patients.uid,
            selectedFirstName: patients.firstName,
            selectedLastName: patients.lastName,//.substring(patients.name.lastIndexOf(' '), patients.name.length),
            selectedgender: patients.gender,
            selectedDOB: patients.dob,
            selectedhealthInsuranceNo: patients.insuranceCardNo,
            selectedpatientEmail: patients.email,
            selectedPatient: patients,
            actionState: 3
        });
    }
    saveSelectedRow = () => {
        var data = {
            uid: this.state.selectedRow,
            firstName: this.state.selectedFirstName,
            lastName: this.state.selectedLastName,
            gender: this.state.selectedgender,
            dob: this.state.selectedDOB,
            insuranceCardNo: this.state.selectedhealthInsuranceNo,
            email: this.state.selectedpatientEmail,
        }
        if (!Boolean(this.state.selectedRow) || !Boolean(this.state.selectedFirstName) || !Boolean(this.state.selectedLastName) || !Boolean(this.state.selectedgender) || !Boolean(this.state.selectedDOB) || !Boolean(this.state.selectedhealthInsuranceNo) || !Boolean(this.state.selectedpatientEmail)) {
            alert("empty fields are not allowed");
        } else {
            this.props.updatePatient(data, this.props.clinicId);
            this.setState({
                selectedRow: null,
                selectedFirstName: '',
                selectedLastName: '',
                selectedgender: '',
                selectedDOB: '',
                selectedhealthInsuranceNo: '',
                selectedpatientEmail: '',
                editRow: false,
                selectedPatient: null
            });
        }

    }
    removeSelectedRow = () => {
        this.setState({
            selectedRow: null,
            selectedFirstName: '',
            selectedLastName: '',
            selectedgender: '',
            selectedDOB: '',
            selectedhealthInsuranceNo: '',
            selectedpatientEmail: '',
            editRow: false,
            selectedPatient: null
        });
    }
    actionClick = (patient) => {
        if (patient.registrationStatus === 'mailsent' || patient.registrationStatus === 'outstanding') {
            this.setState({
                selectedRow: patient.uid,
                selectedPatient: patient,
                actionState: 1,
            })
        } else if (patient.registrationStatus === 'inviteaccepted') {
            this.setState({
                selectedRow: patient.uid,
                selectedPatient: patient,
                actionState: 2,
            })
        } else {
            this.setState({
                actionState: 0,
            })
        }
    }

    sendFollowUp = () => {

        if (this.state.scheduleFollowupState === 0) {
            this.setState({selectedDoctorFinal: this.state.selectedDoctor, scheduleFollowupState: 1});
        } else {
            if (!Boolean(this.state.selectedTime) || !Boolean(this.state.selectedDuration)) {
                this.setState({
                    showFollowUpErrorText: "Please fill all fields to send follow up",
                    showFollowUpError: true
                });
            } else {
                this.setState({bookingInProgress: true})
                this.props.storeSlot(this.props.clinicId, this.state.selectedDoctorFinal, this.state.selectedRow, this.state.selectedPatient, this.state.selectedTime, this.state.selectedDuration, null, null, (status, error) => {
                    if (status) {
                        this.props.fetchTimeSlots(this.state.selectedDoctorFinal, this.state.selectedRow, this.state.selectedPatient, this.state.selectedDay.format('YYYY MM DD'), this.state.selectedDuration);
                        this.setState({
                            showFollowUpErrorText: '',
                            showFollowUpError: false,
                            showFollowUpSuccess: true,
                            showFollowUpSuccessText: "Follow up saved successfully!",
                            selectedTime: '',
                            bookingInProgress: false
                        });
                    } else {
                        this.setState({
                            showFollowUpError: true,
                            showFollowUpErrorText: `Scheduling Follow up due to error: ${error}`,
                            showFollowUpSuccess: false,
                            showFollowUpSuccessText: '',
                            selectedTime: '',
                            bookingInProgress: false
                        });
                    }
                }); //doctor,patientid, date, duration

                /* this.setState({showFollowUpErrorText:'', showFollowUpError: false});
                const followUpDate = new Date(this.state.scheuledDate);
                const finalDate = new Date();
                const diffTime = Math.abs(followUpDate - finalDate);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;

                var date = new Date(this.state.scheuledDate);
                var options = {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                };

                var timeString = date.toLocaleString('en-US', options);
                var dateString = new Date(this.state.scheuledDate).toDateString();
                dateString = dateString.split(' ').slice(1).join(' ');
                var month_names = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
                var month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                dateString = dateString.split(' ');
                var month = month_names[month_names_short.indexOf(dateString[0])];
                date = parseInt(dateString[1]);

                var phenom;
                if (date === 1) {
                    phenom = 'st';
                } else if (date === 2) {
                    phenom = 'nd';
                } else if (date === 3) {
                    phenom = 'rd';
                } else if (date === 21) {
                    phenom = 'st';
                } else if (date === 22) {
                    phenom = 'nd'
                } else if (date === 23) {
                    phenom = 'rd';
                } else {
                    phenom = 'th';
                }
                dateString = month + ' ' + String(date) + phenom;
                this.props.save_followUp(this.state.selectedFollowUpPatient.userId, "Dashboard Follow Up",this.props.doctorDetail.doctorName, this.state.selectedFollowUpPatient.firstName + " " + this.state.selectedFollowUpPatient.lastName, this.state.scheuledDate, this.state.selectedFollowUpPatient.email, (this.state.selectedFollowUpPatient.gmtOffset)?this.state.selectedFollowUpPatient.gmtOffset:0)
                this.setState({ followUpDoctorName:'', scheuledDate: '', showScheduleFollow:false}) */

            }
        }

    }
    scheduleFollowUp = (patient) => {
        if (!this.props.staffData.ansPatients) {
            window.confirm(`You don't have the authority to schedule a follow up. Please contact the administrator for further details.`);
        } else {
            this.setState({showScheduleFollow: true, selectedFollowUpPatient: patient});
        }

    }

    clearFollowUpForm = () => {
        this.setState({
            selectedFollowUpPatient: null,
            followUpDoctorName: '',
            scheuledDate: '',
            showScheduleFollow: false,
            selectedDoctor: this.props.staffData.ansPatients ? this.props.staffData : {},
            selectedDoctorFinal: this.props.staffData.ansPatients ? this.props.staffData : {},
            scheduleFollowupState: 0,
            selectedTime: '',
            resetDate: true,
            selectedDay: moment(),
            selectedDuration: 0,
            showFollowUpErrorText: '',
            showFollowUpError: false,
            showFollowUpSuccess: false,
            showFollowUpSuccessText: ''
        });
    }

    startPatientChat = (patient) => {
        if (!this.props.staffData.ansPatients) {
            window.confirm(`You don't have the authority to start a chat. Please contact the administrator for further details.`);
        } else {
            database.ref(`/users/${patient.userId}`).once('value', patientSnap => {
                if (patientSnap.val()) {
                    if (Boolean(patientSnap.val().conversations)) {
                        window.confirm('patient is already having a conversation with a doctor.');
                    } else if (!this.props.staffData.ansPatients) {
                        window.confirm("you don't have the permission to start a chat.");
                    } else {
                        let conversationID = database.ref(`/conversations`).push().key;
                        let messageID = database.ref(`/conversations/${conversationID}`).push().key;
                        var now = new Date();
                        var freeData = {
                            status: true,
                            queued: false,
                            patientID: patient.userId,
                            doctorID: this.props.staffData.uid,
                            timestamp: now.getTime(),
                            paidChat: true,
                            issue: 'clinicInvite',
                            category: "Others",
                            clinical_chat: true,
                            clinical_location: this.props.province,
                            clinical_to_skip: false,
                            clinicName: this.props.clinic_name,
                            appActivated: (patientSnap.val().platform === 'web') ? false : true
                        }
                        let data = {
                            location: conversationID,
                            doctorID: this.props.staffData.uid,
                            doctorName: this.props.staffData.fullName,
                            issue: 'clinicInvite',
                            category: "Others",
                            clinical_chat: true,
                            clinic_id: this.props.clinicId
                        };
                        var timeNow = new Date().getFullYear();
                        var ageInYears = timeNow - new Date(patientSnap.val().DOB).getFullYear();
                        var updates = {};
                        var patientName = Boolean(patientSnap.val().name) ? patientSnap.val().name : Boolean(patientSnap.val().userName) ? patientSnap.val().userName : "Patient";
                        let messageValue = {
                            type: "text",
                            timestamp: now.getTime(),
                            content: `Hi ${patientName}!\nI'm ${this.props.staffData.fullName}.\nHow may I help you?`,
                            fromID: this.props.staffData.uid,
                            toID: patient.userId,
                            isRead: false
                        };
                        updates[`/openClinicalConversations/${this.props.clinicId}/${conversationID}`] = freeData;
                        updates[`/conversations/${conversationID}/${messageID}`] = messageValue;
                        updates[`/users/${patient.userId}/conversations`] = data;
                        updates[`/users/${patient.userId}/symptoms`] = 'clinicInvite';
                        database.ref().update(updates)
                            .then(data => {
                                this.props.history.push('/clinic_encounter')
                                if (freeData.appActivated == true) {
                                    this.props.sendNewMessageNotification(patientSnap.val().notificationToken, null, this.props.staffData.fullName)

                                }
                            })
                    }
                }
            });
        }

    }

    saveDoctorFilterChange = () => {
        this.setState({selectedDoctorFinal: this.state.selectedDoctor, scheduleFollowupState: 1});

    }
    cancelDoctorFilterChange = () => {
        this.setState({selectedDoctor: this.state.selectedDoctorFinal, scheduleFollowupState: 0});
        this.hideFollowUpModal();
    }

    render() {
        const slots = this.props.scheduleSlots.length > 0 ? this.props.scheduleSlots.map(slot => {

            return (
                <div key={'slot_' + slot.startTime}
                     className={this.state.selectedTime === slot.startTime ? classes.selectedTimeSlotsSectionButton : classes.timeSlotsSectionButton}
                     onClick={() => {
                         this.setState({selectedTime: slot.startTime})
                     }}>{moment(slot.startTime).format("h:mm a")}</div>
            )
        }) : null;
        const doctors = this.props.clinical_doctors.length > 0 ? this.props.clinical_doctors.map(doctor => {
            return (
                <div key={doctor.uid} className={classes.dateFilterRows}><input type='radio'
                                                                                checked={this.state.selectedDoctor.uid === doctor.uid}
                                                                                onChange={() => {
                                                                                    this.setState({selectedDoctor: doctor})
                                                                                }}
                                                                                className={classes.dateFiltersRadio}/>{doctor.fullName}
                </div>
            )
        }) : null;
        const patients = this.props.clinical_patients.length > 0 ? this.props.clinical_patients.map(patients => {
            var isSelected = this.state.selectedRow === patients.uid;
            var isHovered = this.state.hoverRow === patients.uid;
            var first_name = patients.firstName;
            var last_name = patients.lastName;
            var toShow = true;
            var DOB = (patients.dob) ? (patients.dob).indexOf('-') === -1 ? [patients.dob.slice(0, 4) + '-' + patients.dob.slice(4, 6) + '-' + patients.dob.slice(6)].join('') : patients.dob : "";
            var dateOfBirth = moment(DOB).format('LL');
            var timeNow = new Date().getFullYear();
            var ageInYears = timeNow - new Date(DOB).getFullYear();
            dateOfBirth = dateOfBirth + " (" + ageInYears + ")";
            var registration = patients.registrationStatus === 'outstanding' ? 'outstanding' : patients.registrationStatus === 'mailsent' ? "registration sent" : patients.registrationStatus === 'inviteaccepted' ? "view archives" : "none";
            if (Boolean(this.state.filterFirstName) && (first_name.toLowerCase()).indexOf(this.state.filterFirstName.toLowerCase()) < 0) {
                toShow = false;
            } else if (Boolean(this.state.filterLastName) && (last_name.toLowerCase()).indexOf(this.state.filterLastName.toLowerCase()) < 0) {
                toShow = false;
            } else if (Boolean(this.state.filterGender) && (patients.gender.toLowerCase()).indexOf(this.state.filterGender.toLowerCase()) < 0) {
                toShow = false;
            } else if (Boolean(this.state.filterHealthInsuranceNo) && patients.insuranceCardNo.indexOf(this.state.filterHealthInsuranceNo) < 0) {
                toShow = false;
            } else if (Boolean(this.state.filterRegistrationStatus) && registration.indexOf(this.state.filterRegistrationStatus.toLowerCase()) < 0) {
                toShow = false;
            }
            /* else if(this.state.filterRegistrationStatus.toLowerCase() === "outstanding" || this.state.filterRegistrationStatus.toLowerCase() === "registration sent" || this.state.filterRegistrationStatus.toLowerCase() === "view archives"){
                if(this.state.filterRegistrationStatus.toLowerCase() === "outstanding" && patients.registrationStatus !== 'outstanding'){
                    toShow = false;
                }else if(this.state.filterRegistrationStatus.toLowerCase() === "registration sent" && patients.registrationStatus !== 'mailsent'){
                    toShow = false;
                }else if (this.state.filterRegistrationStatus.toLowerCase() === "view archives" && patients.registrationStatus !== 'inviteaccepted'){
                    toShow = false;
                }
            } */ else if (Boolean(this.state.filterDOB)) {

                if ((dateOfBirth.toLowerCase()).indexOf(this.state.filterDOB.toLowerCase()) < 0)
                    toShow = false;
            } //filterDOB remaining
            if (toShow) {
                return (
                    <div key={patients.uid} className={isHovered ? classes.selected_patient_row : classes.patient_row}
                          onMouseEnter={(e) => {
                        this.setState({hoverRow: patients.uid})
                    }}>
                        <div className={classes.checkboxes}>
                            <input type="checkbox" name={patients.uid}
                                   checked={Boolean(this.state.toDelete[patients.uid])}
                                   onClick={() => {
                                       var newToDelete = this.state.toDelete;
                                       if (Boolean(this.state.toDelete[patients.uid])) {
                                           delete newToDelete[patients.uid];
                                       } else {
                                           newToDelete[patients.uid] = true;
                                       }
                                       this.setState({toDelete: newToDelete});
                                   }}
                            />
                        </div>
                        <div className={isSelected ? classes.selected_table_row : classes.table_row}>
                            <div className={classes.table_column}>
                                {!(isSelected && this.state.editRow) && <span>{last_name}</span>}
                                {isSelected && this.state.editRow && <input
                                    type="text"
                                    value={this.state.selectedLastName}
                                    onChange={(e) => {
                                        this.setState({selectedLastName: e.target.value})
                                    }}
                                    className={classes.row_input}
                                />}
                            </div>
                            <div className={classes.table_column}>
                                {!(isSelected && this.state.editRow) && <span>{first_name}</span>}
                                {isSelected && this.state.editRow && <input
                                    type="text"
                                    value={this.state.selectedFirstName}
                                    onChange={(e) => {
                                        this.setState({selectedFirstName: e.target.value})
                                    }}
                                    className={classes.row_input}
                                />}
                            </div>
                            <div className={classes.table_column}>
                                {!(isSelected && this.state.editRow) && <span>{patients.gender}</span>}
                                {isSelected && this.state.editRow &&
                                <select className={classes.row_input} value={this.state.selectedgender}
                                        onChange={(text) => {
                                            this.setState({selectedgender: text})
                                        }}>
                                    <option key={0} value={'Male'}>Male</option>
                                    <option key={1} value={'Female'}>Female</option>
                                </select>}
                            </div>
                            <div className={classes.table_column}>
                                {!(isSelected && this.state.editRow) && <span>{dateOfBirth}</span>}
                                {isSelected && this.state.editRow && <input
                                    type="text"
                                    value={this.state.selectedDOB}
                                    onChange={(e) => {
                                        this.setState({selectedDOB: e.target.value})
                                    }}
                                    className={classes.row_input}
                                />}
                            </div>
                            <div className={classes.table_column_big}>
                                {!(isSelected && this.state.editRow) && <span>{patients.insuranceCardNo}</span>}
                                {isSelected && this.state.editRow && <input
                                    type="text"
                                    value={this.state.selectedhealthInsuranceNo}
                                    onChange={(e) => {
                                        this.setState({selectedhealthInsuranceNo: e.target.value})
                                    }}
                                    className={classes.insurance_row_input}
                                />}
                            </div>
                            <div className={classes.table_column_big}>
                                {!(isSelected && this.state.editRow) &&
                                patients.registrationStatus !== 'inviteaccepted' && <span
                                    className={patients.registrationStatus === 'mailsent' ? classes.reg_blue : classes.reg_red}>{patients.registrationStatus === 'mailsent' ? 'Registration Sent' : 'Outstanding'}</span>}
                                {isSelected && this.state.editRow && <input
                                    type="text"
                                    value={this.state.selectedpatientEmail}
                                    onChange={(e) => {
                                        this.setState({selectedpatientEmail: e.target.value})
                                    }}
                                    className={classes.email_row_input}
                                />}

                                {!(isSelected && this.state.editRow) &&
                                patients.registrationStatus === 'inviteaccepted' && <Link to={{
                                    pathname: "/clinic_archives",
                                    state: {action: 'isfromCliniclPaitientsRegistrations', userId: patients.userId}
                                }}><span className={classes.viewArchivediv}>View Archives</span></Link>}
                            </div>
                        </div>

                        <div className={classes.action_field}>

                            {((this.state.actionState === 0 && isSelected) || !isSelected) &&
                            <img src={actionicon} alt="actionicon" className={classes.actioniconImg}
                                 onClick={() => this.actionClick(patients)}/>}
                            {this.state.actionState === 1 && isSelected &&
                            <div className={classes.actionButtons}>
                                <span
                                    className={!isHovered ? classes.cancelButtonSmallWithBorder : classes.cancelButtonSmall}
                                    onClick={() => this.selectRow(patients)}>Edit</span>
                                <span
                                    className={!isHovered ? classes.cancelButtonSmallWithBorder : classes.cancelButtonSmall}
                                    onClick={() => this.resendEmail(patients)}>Resend Email</span>
                            </div>
                            }
                            {this.state.actionState === 2 && isSelected &&
                            <div className={classes.actionButtons}>
                                <span
                                    className={!isHovered ? classes.cancelButtonSmallWithBorder : classes.cancelButtonSmall}
                                    onClick={() => this.selectRow(patients)}>Edit</span>
                                <span
                                    className={!isHovered ? classes.cancelButtonSmallWithBorder : classes.cancelButtonSmall}
                                    onClick={() => this.startPatientChat(patients)}>Message</span>
                                <span
                                    className={!isHovered ? classes.cancelButtonSmallWithBorder : classes.cancelButtonSmall}
                                    onClick={() => this.scheduleFollowUp(patients)}>Schedule</span>
                            </div>
                            }
                            {this.state.actionState === 3 && isSelected &&
                            <div className={classes.actionButtons}>
                                <span className={classes.saveButtonSmall} onClick={this.saveSelectedRow}>Save</span>
                                <span
                                    className={!isHovered ? classes.cancelButtonSmallWithBorder : classes.cancelButtonSmall}
                                    onClick={this.removeSelectedRow}>Cancel</span>
                            </div>
                            }

                        </div>
                        {/* <div colSpan="3" className={classes.no_doctors}>There is currently no doctor online.</div> */}
                    </div>)


            } else {
                return null;
            }

        }) : null;
        const filtered_patients = _.compact(patients);
        //Intl.DateTimeFormat().resolvedOptions().timeZone
        //new Date().toLocaleDateString(undefined, { timeZoneName: 'long' })
        return (
            <Fragment>
                <Aux>
                    <div className={classes.top_bar}>
                        <div className={classes.header_doctor_name}>
                            <span>{this.props.full_name}</span>
                        </div>
                        <div className={classes.header_status}>
                            Status:
                            {this.props.acceptingChat && <span> Accepting Patients</span>}
                            {!this.props.acceptingChat &&
                            <span className={classes.notAcceptingChat}> Not Accepting Patients</span>}
                        </div>
                    </div>
                    <div className={this.state.showScheduleFollow ? classes.overlay : classes.hide_overlay}
                         onClick={this.clearFollowUpForm}></div>
                    <div className={classes.scheduleModal} style={{
                        transform: this.state.showScheduleFollow ? 'translateY(0)' : 'translateY(-100vh)',
                        opacity: this.state.showScheduleFollow ? '1' : '0',
                    }}>
                        <div className={classes.header}>Schedule Appointment</div>
                        {this.state.scheduleFollowupState === 1 &&
                        <div className={classes.scheduleFollowCalender}>
                            {<div className={classes.slotSection}>
                                <span className={classes.slotSectionHeaderText}>Select a time slot</span>
                                <div
                                    className={this.state.selectedDuration !== 15 ? classes.slotSectionButton : classes.selectedSlotSectionButton}
                                    onClick={() => {
                                        this.setState({selectedDuration: 15, selectedTime: ''});
                                        this.props.fetchTimeSlots(this.state.selectedDoctorFinal, this.state.selectedRow, this.state.selectedPatient, this.state.selectedDay.format('YYYY MM DD'), 15);
                                    }}>15 minutes
                                </div>
                                <div
                                    className={this.state.selectedDuration !== 30 ? classes.slotSectionButton : classes.selectedSlotSectionButton}
                                    onClick={() => {
                                        this.setState({selectedDuration: 30, selectedTime: ''});
                                        this.props.fetchTimeSlots(this.state.selectedDoctorFinal, this.state.selectedRow, this.state.selectedPatient, this.state.selectedDay.format('YYYY MM DD'), 30);
                                    }}>30 minutes
                                </div>
                                <div
                                    className={this.state.selectedDuration !== 45 ? classes.slotSectionButton : classes.selectedSlotSectionButton}
                                    onClick={() => {
                                        this.setState({selectedDuration: 45, selectedTime: ''});
                                        this.props.fetchTimeSlots(this.state.selectedDoctorFinal, this.state.selectedRow, this.state.selectedPatient, this.state.selectedDay.format('YYYY MM DD'), 45);
                                    }}>45 minutes
                                </div>
                                <div
                                    className={this.state.selectedDuration !== 60 ? classes.slotSectionButton : classes.selectedSlotSectionButton}
                                    onClick={() => {
                                        this.setState({selectedDuration: 60, selectedTime: ''});
                                        this.props.fetchTimeSlots(this.state.selectedDoctorFinal, this.state.selectedRow, this.state.selectedPatient, this.state.selectedDay.format('YYYY MM DD'), 60);
                                    }}>60 minutes
                                </div>
                            </div>
                            }
                            <div className={classes.calenderSection}>
                                {this.state.selectedDuration !== 0 && <Calendar
                                    //value={this.state.selectedDay}
                                    //onChange={(e)=>{this.setState({selectedDay:e.target.value})}}
                                    resetDate={this.state.resetDate}
                                    resetUpdate={() => {
                                        this.setState({resetDate: false})
                                    }}

                                    shouldHighlightWeekends
                                    dateChanged={(date) => {
                                        console.log("calendar.js date ", date);
                                        this.setState({selectedDay: date});
                                        this.props.fetchTimeSlots(this.state.selectedDoctorFinal, this.state.selectedRow, this.state.selectedPatient, date.format('YYYY MM DD'), this.state.selectedDuration);
                                    }}
                                /> //doctorName, patientid, date, duration
                                }
                            </div>
                            {this.state.selectedDuration !== 0 && <div className={classes.timeSlotsSection}>
                                <span
                                    className={classes.timeSlotDate}>{this.state.selectedDay.format('dddd, MMMM DD')}</span>
                                <div className={classes.timeslotsScroll}>
                                    {Boolean(slots) && !this.props.slots_loading && slots}
                                    {this.props.slots_loading && <div className={classes.loading_loader_sm}/>}
                                </div>
                            </div>}
                        </div>}
                        {this.state.scheduleFollowupState === 0 &&
                        <div className={classes.scheduleDoctorSection}>
                            <span
                                className={classes.scheduleDoctorText}>Select a doctor to schedule an appointment</span>
                            <div className={classes.doctorRadioButtonsRow}>
                                {doctors}
                            </div>

                        </div>}
                        {this.state.showFollowUpSuccess &&
                        <div className={classes.batchUploadSuccessText}>{this.state.showFollowUpSuccessText}</div>}
                        {this.state.showFollowUpError &&
                        <div className={classes.batchUploadErrorText}>{this.state.showFollowUpErrorText}</div>}
                        <div className={classes.addPatientButtonView}>
                            {!this.state.bookingInProgress && <div className={classes.saveButton}
                                                                   onClick={this.sendFollowUp}>{this.state.scheduleFollowupState === 1 ? 'Schedule Appointment' : 'Continue'}</div>}
                            {this.state.bookingInProgress && <div className={classes.saveButton}>
                                <div className={classes.loading_loader_sm}/>
                            </div>}
                            <div className={classes.cancelButton} onClick={this.clearFollowUpForm}>Cancel</div>
                        </div>
                    </div>

                    <div className={this.state.showBatchUploadModal ? classes.overlay : classes.hide_overlay}
                         onClick={this.handleOnRemoveFile}></div>
                    <div className={classes.batchUploadModal} style={{
                        transform: this.state.showBatchUploadModal ? 'translateY(0)' : 'translateY(-100vh)',
                        opacity: this.state.showBatchUploadModal ? '1' : '0'
                    }}>
                        <div className={classes.header}>Batch Upload</div>
                        <CSVReader
                            ref={buttonRef}
                            onFileLoad={this.handleOnFileLoad}
                            onError={this.handleOnError}
                            noClick

                            onRemoveFile={this.handleOnRemoveFile}
                        >
                            {({file}) => (

                                <div className={classes.popUpMain}>
                                    <div className={classes.popUpText}>File Location:</div>
                                    <TextareaAutosize
                                        placeholder={'Select File'}
                                        className={classes.input}
                                        onClick={this.handleOpenDialog}
                                        value={Boolean(file) ? file.name : ""}
                                    />
                                </div>
                            )}
                        </CSVReader>
                        <div className={classes.batchUploadText1}>Please upload a comma seperated value (.csv)
                            spreadsheet. The first row of the file should contain Last Name, First Name, Gender, Date of
                            Birth, Health Insurance #, and Email Address.
                        </div>
                        <div className={classes.batchUploadText2}>The Date of Birth should be in the format of
                            1986-08-12 for Aug 12th, 1986.
                        </div>
                        {this.state.showBatchUploadError &&
                        <div className={classes.batchUploadErrorText}>{this.state.batchUploadError}</div>}

                        <div className={classes.buttonView}>
                            {!this.state.invitePatientInProgress &&
                            <div className={classes.saveButton} onClick={this.uploadFile}>Upload</div>}
                            {this.state.invitePatientInProgress && <div className={classes.saveButton}>
                                <div className={classes.loading_loader_sm}/>
                            </div>}
                            <div className={classes.cancelButton} onClick={this.handleOnRemoveFile}>Cancel</div>
                        </div>
                    </div>

                    <div className={this.state.showAddPatientModal ? classes.overlay : classes.hide_overlay}
                         onClick={this.hideAddPatientModal}></div>
                    <div className={classes.addPatientModal} style={{
                        transform: this.state.showAddPatientModal ? 'translateY(0)' : 'translateY(-100vh)',
                        opacity: this.state.showAddPatientModal ? '1' : '0',
                    }}>
                        {!this.state.showAddPatientSuccess && <div>
                            <div className={classes.header}>Add Patient</div>
                            <div className={classes.addpatientRadioButton}><input type='radio' style={{marginRight: 5}}
                                                                                  checked={this.state.isManualInfo === false}
                                                                                  onChange={() => {
                                                                                      this.setState({isManualInfo: false})
                                                                                  }}/><span>Invite a patient to create their profile</span>
                            </div>

                            <div className={classes.addpatientRadioButton}><input type='radio' style={{marginRight: 5}}
                                                                                  checked={this.state.isManualInfo === true}
                                                                                  onChange={() => {
                                                                                      this.setState({isManualInfo: true})
                                                                                  }}/><span>Manually enter patient’s information</span>
                            </div>
                            {this.state.isManualInfo && <div>
                                <div className={classes.popUpMain}>
                                    <div className={classes.popUpText}>First Name:</div>
                                    <TextareaAutosize
                                        placeholder={'John'}
                                        className={classes.input}
                                        onChange={(e) => {
                                            this.setState({firstName: e.target.value})
                                        }}
                                        value={this.state.firstName}
                                    />
                                </div>
                                <div className={classes.popUpMain}>
                                    <div className={classes.popUpText}>Last Name:</div>
                                    <TextareaAutosize
                                        placeholder={'Doe'}
                                        className={classes.input}
                                        onChange={(e) => {
                                            this.setState({lastName: e.target.value})
                                        }}
                                        value={this.state.lastName}
                                    />
                                </div>
                                <div className={classes.popUpMain}>
                                    <div className={classes.popUpText}>Email Address:</div>
                                    <TextareaAutosize
                                        placeholder={'johndoe@gmail.com'}
                                        className={classes.input}
                                        onChange={(e) => {
                                            this.setState({patientEmail: e.target.value})
                                        }}
                                        value={this.state.patientEmail}
                                    />
                                </div>
                                <div className={classes.popUpMain}>
                                    <div className={classes.popUpText}>Health Insurance#:</div>
                                    <TextareaAutosize
                                        placeholder={'111-111-111'}
                                        className={classes.input}
                                        onChange={(e) => {
                                            this.setState({healthInsuranceNo: e.target.value})
                                        }}
                                        value={this.state.healthInsuranceNo}
                                    />
                                </div>
                                <div className={classes.popUpMain}>
                                    <div className={classes.popUpText}>D.O.B.:</div>
                                    <input
                                        id="date"
                                        type="date"
                                        placeholder=""
                                        value={this.state.DOB}
                                        onChange={(e) => {
                                            this.setState({DOB: e.target.value})
                                        }}
                                        className={classes.input}
                                    />
                                </div>
                                <div className={classes.popUpMain}>
                                    <div className={classes.popUpText}>Gender:</div>
                                    <select className={classes.genderInput} onChange={(e) => {
                                        this.setState({gender: e.target.value})
                                    }}>
                                        <option key={0} value={''}></option>
                                        <option key={1} value={'Male'}>Male</option>
                                        <option key={2} value={'Female'}>Female</option>
                                    </select>
                                </div>
                            </div>}
                            {!this.state.isManualInfo && <div>
                                <span className={classes.addpatientinviteText}>Enter your patient’s email address so that they can register to your clinic. You can enter multiple patient email addresses seperated by a comma.</span>
                                <div className={classes.popUpMain}>
                                    <div className={classes.popUpText}>Email Address:</div>
                                    <TextareaAutosize
                                        placeholder={'johndoe@test.com'}
                                        className={classes.input}
                                        onChange={(e) => {
                                            this.setState({patientEmails: e.target.value})
                                        }}
                                        value={this.state.patientEmails}
                                    />
                                </div>
                            </div>
                            }


                            {this.state.showAddPatientError &&
                            <div className={classes.batchUploadErrorText}>{this.state.addPatientError}</div>}
                            {this.state.showAddPatientSuccess &&
                            <div className={classes.batchUploadSuccessText}>{this.state.addPatientSuccess}</div>}


                            <div className={classes.addPatientButtonView}>
                                {!this.state.invitePatientInProgress &&
                                <div className={classes.saveButton} onClick={this.sendRegistrationEmail}>Add
                                    Patient</div>}
                                {this.state.invitePatientInProgress && <div className={classes.saveButton}>
                                    <div className={classes.loading_loader_sm}/>
                                </div>}
                                <div className={classes.cancelButton} onClick={this.clearAddPatientForm}>Cancel</div>
                            </div>
                        </div>}
                        {this.state.showAddPatientSuccess && <div>
                            <div className={classes.header} style={{color: "#894A9E"}}>Email Sent Successfully</div>
                        </div>}

                    </div>
                    <div className={classes.main}>
                        <div className={classes.head_buttons}>
                            {Object.keys(this.state.toDelete).length !== 0 &&
                            <span className={classes.delete_patient_button}
                                  onClick={this.deletePatient}>Delete Patients</span>}
                            <span className={classes.batch_upload_button}
                                  onClick={this.openBatchModal}>Batch Upload</span>
                            <span className={classes.add_patient_button} onClick={this.showAddPatientModal}>+ Add Patient</span>
                        </div>
                        <div className={classes.patients_table}>
                            <div className={classes.top_row}>
                                <span className={classes.top_row_attr_start}></span>
                                <span className={classes.top_row_attr}>Last Name</span>
                                <span className={classes.top_row_attr}>First Name</span>
                                <span className={classes.top_row_attr}>Gender</span>
                                <span className={classes.top_row_attr}>Date of Birth(D.O.B)</span>
                                <span className={classes.top_row_attr_big}>Health Insurance#</span>
                                <span className={classes.top_row_attr_big}>Registration Status</span>
                                <span className={classes.top_row_attr_end}></span>

                            </div>
                            <div className={classes.inputfield}>
                                <span className={classes.top_row_attr_start}></span>
                                <div className={classes.top_row_attr}>
                                    <input className={classes.viewArchivediv} onChange={(e) => {
                                        this.setState({filterLastName: e.target.value})
                                    }} value={this.state.filterLastName} placeholder="Search"/>
                                </div>
                                <div className={classes.top_row_attr}>
                                    <input className={classes.viewArchivediv} onChange={(e) => {
                                        this.setState({filterFirstName: e.target.value})
                                    }} value={this.state.filterFirstName} placeholder="Search"/>
                                </div>
                                <div className={classes.top_row_attr}>
                                    <input className={classes.viewArchivediv} onChange={(e) => {
                                        this.setState({filterGender: e.target.value})
                                    }} value={this.state.filterGender} placeholder="Search"/>
                                </div>
                                <div className={classes.top_row_attr}>
                                    <input className={classes.viewArchivediv} onChange={(e) => {
                                        this.setState({filterDOB: e.target.value})
                                    }} value={this.state.filterDOB} placeholder="Search"/>
                                </div>
                                <div className={classes.top_row_attr_big}>
                                    <input className={classes.viewArchivedivBig} onChange={(e) => {
                                        this.setState({filterHealthInsuranceNo: e.target.value})
                                    }} value={this.state.filterHealthInsuranceNo} placeholder="Search"/>
                                </div>
                                <div className={classes.top_row_attr_big}>
                                    <input className={classes.viewArchivedivBig} onChange={(e) => {
                                        this.setState({filterRegistrationStatus: e.target.value})
                                    }} value={this.state.filterRegistrationStatus} placeholder="Search"
                                    />
                                </div>
                                <span className={classes.top_row_attr_end}></span>
                            </div>

                            {filtered_patients}
                        </div>
                    </div>
                </Aux>
            </Fragment>
        );
    }
}

const mapStateToProps = ({
                             acceptingReducer,
                             doctorReducer,
                             clinicalPatientsReducer,
                             reportReducer,
                             clinicProfileSettingReducer
                         }) => {

    const {
        clinicData, staffData, clinicId, clinic_name, full_name, address, province, city, country, phone, postal_code,
        fax, email_address, license_no, profile_image, signature, clinic_email_address, physician_user,
        loading, message, userType, userProfile
    } = clinicProfileSettingReducer;
    const {clinical_patients, scheduleSlots, slots_loading} = clinicalPatientsReducer;
    const {acceptingChat} = acceptingReducer;
    const {doctorDetail} = doctorReducer;
    const {clinical_doctors} = reportReducer;
    return {
        acceptingChat, doctorDetail, clinical_patients, scheduleSlots, clinical_doctors, slots_loading,
        clinicData, staffData, clinicId, clinic_name, full_name, address, province, city, country, phone, postal_code,
        fax, email_address, license_no, profile_image, signature, clinic_email_address, physician_user,
        loading, message, userType, userProfile

    };
};

export default connect(mapStateToProps, {
    sendInvites,
    sendNewMessageNotification,
    sendUnFilledInvites,
    fetchRegisteredPatients,
    updatePatient,
    deletePatients,
    save_followUp,
    updateRegistrationStatus,
    fetchTimeSlots,
    fetchClinicalDoctors,
    storeSlot,
    removeClinicalPatientsListener
})(ClinicalPatientsBuilder);
