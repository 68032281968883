
import classes from '../settings.module.css';

const BillingCodesList = (props) => {
    if (props.selectedEditCode === props.uid) {
        return (
            <div className={classes.response_item}>
                <div className={classes.response_item_form}>
                    <div className={classes.response_item_field}>
                        <input
                            value={props.selectedCodeValue}
                            onChange={(event) => props.onChangeCode(event)}
                        />
                        <input
                            value={props.selectedCodeDesciption}
                            onChange={(event) => props.onChangeCodeDescription(event)}
                        />
                    </div>
                    <div className={classes.response_item_submit}>
                        <button
                            onClick={() => props.updateBillingCode(props.uid, props.selectedCodeDesciption, props.selectedCodeValue)}>
                            <span>Save changes</span>
                        </button>
                        <span>or
                            <div className={classes.cancel_button}
                                 onClick={() => props.updateCancelClick()}>cancel</div>
                        </span>
                    </div>
                </div>
            </div>
        )
    } else if (props.selectedDeleteCode === props.uid) {
        return (
            <div className={classes.response_item}>
                <div className={classes.delete_view}>
                    <div className={classes.confirm_remove}>
                        <div className={classes.wrapper}>
                            <div>
                                <button onClick={() => props.confirmDeleteClick(props.selectedDeleteCode)}><span>Delete this response</span>
                                </button>
                                or
                                <span className={classes.cancel_delete_button}
                                      onClick={() => props.confirmCancelClick()}>cancel</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className={classes.response_item}>
            <div className={classes.view}>
                <div className={classes.links}>
                    <span
                        onClick={() => props.onEditClick(props.uid, props.billingCodeDescription, props.billingCode)}>Edit</span>
                    <span onClick={() => props.onDeleteClick(props.uid)}>Delete</span>
                </div>
                <div className={classes.tags}>
                    <span>Billing Code</span>
                    <div className={classes.shortcut}>{props.billingCode}</div>
                    <span> --- </span>
                    <div className={classes.text}>{props.billingCodeDescription}</div>
                </div>
            </div>
        </div>
    )
}

const BillingCodes = (props) => {

    const taglist = props.billingCodesList.map(code => {
        return <BillingCodesList
            key={code.uid}
            uid={code.uid}
            billingCode={code.billing_code}
            billingCodeDescription={code.billing_code_desc}
            selectedCodeDesciption={props.selectedTagDesciption}
            selectedCodeValue={props.selectedTagShortCut}
            selectedEditCode={props.selectedEditTag}
            selectedDeleteCode={props.selectedDeleteTag}
            onChangeCode={(event) => props.onChangeCannedShortCut(event)}
            onChangeCodeDescription={(event) => props.onChangeCannedDescription(event)}
            onEditClick={(uid, desc, shortcut) => props.onEditClick(uid, desc, shortcut)}
            onDeleteClick={(uid) => props.onDeleteClick(uid)}
            updateBillingCode={(uid, desc, shortcut) => props.updateCannedResponse(uid, desc, shortcut)}
            updateCancelClick={() => props.updateCancelClick()}
            confirmDeleteClick={(uid) => props.confirmDeleteClick(uid)}
            confirmCancelClick={() => props.confirmCancelClick()}
        />
    });

    return (
        <div className={classes.detail_content}>
            <form className={classes.form}>
                <label>
                    <input
                        type="text"
                        id="add-code"
                        placeholder="Billing Code"
                        tabIndex="1"
                        maxLength="70"
                        required
                        value={props.billing_code}
                        onChange={(text) => props.onBillingCodeTextChange(text)}
                    />
                    <textarea
                        type="text"
                        id="add-tag"
                        placeholder="Canned responses text...."
                        tabIndex="1"
                        required
                        value={props.billing_code_desc}
                        onChange={(text) => props.onBillingCodeDescriptionChange(text)}
                    />
                </label>
                <button tabIndex="2" onClick={(event) => props.onSaveBillingCode(event)}>
                    <span>Add a billing code</span>
                </button>
            </form>
            <div className={classes.tag_list}>
                <div className={classes.tag_list_header}>
                    {taglist}
                </div>
            </div>
        </div>
    )
}

export default BillingCodes;
