import { Fragment, useEffect, useRef, useState} from 'react'

import {connect} from "react-redux";
import classes from "../../containers/chatBuilder/userDetail/UserDetailedScreen.module.css";
import TextareaAutosize from "react-autosize-textarea";
import Spinner from "react-bootstrap/Spinner";
import {database} from "../../firebase";
import CannedResponsesList from "../CannedResponseList/CannedResponseList";


const SOAPNotes = (props) => {
    const [showSOAPNotes, setShowSOAPNotes] = useState(false)
    const [showEditBlock, setShowEditBlock] = useState(false)
    //conversationId
    const [currentConversationId, setCurrentConversationId] = useState(null)
    const [symptoms, setSymptoms] = useState("")
    const [findings, setFindings] = useState("")
    const [assessment, setAssessment] = useState("")
    const [plan, setPlan] = useState("")
    const [showCannedResponseForObjectiveFindingsList, setShowCannedResponseForObjectiveFindingsList] = useState(false)
    const [showCannedResponseForAssessmentList, setShowCannedResponseForAssessmentList] = useState(false)
    const [showCannedResponseForPlanList, setShowCannedResponseForPlanList] = useState(false)
    const soapnotes = useRef(null)

    const showSOAPInfo = () => setShowSOAPNotes(!showSOAPNotes)

    const editSOAPNotes = () => {
        setShowEditBlock(true)
        if (soapnotes?.current)soapnotes.current.scrollIntoView({behavior: 'smooth'})
    }

    const onSaveSOAP = () => {
        if(props.conversationId) {
            setShowEditBlock(false)
            let update = {}
            update[`/openconversations/${props.conversationId}/symptoms`] = symptoms || null
            update[`/openconversations/${props.conversationId}/findings`] = findings || null
            update[`/openconversations/${props.conversationId}/assessment`] = assessment || null
            update[`/openconversations/${props.conversationId}/plan`] = plan || null
            database.ref().update(update).then(data => {
            }).catch(e => {
            })
        }
    }

    const onClearSOAP = () => {
        setShowEditBlock(false);
        setSymptoms('');
        setFindings('')
        setAssessment('')
        setPlan('')
        if(props.conversationId) {
            let update = {}
            update[`/openconversations/${props.conversationId}/symptoms`] = ""
            update[`/openconversations/${props.conversationId}/findings`] = ""
            update[`/openconversations/${props.conversationId}/assessment`] = ""
            update[`/openconversations/${props.conversationId}/plan`] = ""
            database.ref().update(update).then(data => {
            }).catch(e => {
            })
        }
    }

    const onObjectiveFindingsChange = (event) => {
        if ((event.target.value).match('#')) {
            setShowCannedResponseForObjectiveFindingsList(true)
        }
        setFindings(event.target.value)
    }

    const onAssessmentGoalsChange = (event) => {
        if ((event.target.value).match('#')) {
            setShowCannedResponseForAssessmentList(true)
        }
        setAssessment(event.target.value)
    }

    const onPlanChange = (event) => {
        if ((event.target.value).match('#')) {
            setShowCannedResponseForPlanList(true)
        }
        setPlan(event.target.value);
    }

    const onObjectiveCannedResponseClick = (uid, short_cut, text) => {
        setShowCannedResponseForObjectiveFindingsList(false)
        let updatedString;

        if (findings.search('#') !== -1) {
            let replaceText = (findings.slice((findings).search('#'), findings.length));
            updatedString = (findings).replace(replaceText, text);
        } else {
            updatedString = (findings).concat(' ', text);
        }
        setFindings(updatedString)
    }

    const onAssessmentCannedResponseClick = (uid, short_cut, text) => {
        setShowCannedResponseForAssessmentList(false)
        let updatedString;
        if (assessment.search('#') !== -1) {
            let replaceText = (assessment.slice((assessment).search('#'), assessment.length));
            updatedString = (assessment).replace(replaceText, text);
        } else {
            updatedString = (assessment).concat(' ', text);
        }
        setAssessment(updatedString)
    }

    const onPlanCannedResponseClick = (uid, short_cut, text) => {
        setShowCannedResponseForPlanList(false)
        let updatedString;
        if (plan.search('#') !== -1) {
            let replaceText = (plan.slice((plan).search('#'), plan.length));
            updatedString = (plan).replace(replaceText, text);
        } else {
            updatedString = (plan).concat(' ', text);
        }
        setPlan(updatedString)
    }

    useEffect(()=>{
        setCurrentConversationId(props.conversationId);
        setFindings(props.selectedConversationObj?.findings || "");
    },[props.conversationId, props.selectedConversationObj?.findings])

    useEffect(()=>{
        setCurrentConversationId(props.conversationId);
        setAssessment(props.selectedConversationObj?.assessment || ""); 
       },[props.conversationId, props.selectedConversationObj?.assessment])
    
    useEffect(()=>{
        setCurrentConversationId(props.conversationId);
        setPlan(props.selectedConversationObj?.plan || "");
    },[props.conversationId, props.selectedConversationObj?.plan])
    
    useEffect(()=>{
        setCurrentConversationId(props.conversationId);
        setSymptoms(props.selectedConversationObj?.symptoms || "");
    },[props.conversationId, props.selectedConversationObj?.symptoms])


    return (
        <div>
            {props.conversationId && props.doctorIsValid && <div className={classes.generalInformation}>
                <Fragment>
                    <div className={classes.openchatlist} onClick={() => showSOAPInfo()}>
                        <div className={classes.openchatlistSection}>
                            <div
                                className={showSOAPNotes === true ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                                <svg fill="#884a9d" width="12px" height="8px"
                                     viewBox="0 0 12 8">
                                    <path fill="inherit" fillRule="evenodd"
                                          d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                </svg>
                            </div>
                            <div className={classes.subListTitle}>SOAP Notes</div>
                        </div>
                    </div>
                </Fragment>
                {showSOAPNotes? showEditBlock?  <div className={classes.expandable_section} ref={soapnotes}>
                        {!props.isLoading &&
                        <div className={classes.general_info_section}>
                            <div className={classes.SOAP_div}>
                                <span className={classes.SOAP_heading}>Subjective Symptoms:</span>
                                <TextareaAutosize
                                    className={classes.SOAP_textarea}
                                    placeholder="Start typing here..."
                                    onChange={(text) => setSymptoms(text.target.value)}
                                    value={symptoms}
                                />
                            </div>
                            <div className={classes.SOAP_div}>
                                <span className={classes.SOAP_heading}>Objective Findings:</span>
                                <TextareaAutosize
                                    className={classes.SOAP_textarea}
                                    placeholder="Start typing here..."
                                    onChange={(text) => onObjectiveFindingsChange(text)}
                                    value={findings}
                                />
                                {showCannedResponseForObjectiveFindingsList &&
                                    <CannedResponsesList
                                        canned_responses_data={props.canned_responses}
                                        onCannedResponseClick={(uid, short_cut, text) => onObjectiveCannedResponseClick(uid, short_cut, text)}
                                    />
                                }
                            </div>
                            <div className={classes.SOAP_div}>
                                                                <span
                                                                    className={classes.SOAP_heading}>Assessment:</span>
                                <TextareaAutosize
                                    className={classes.SOAP_textarea}
                                    placeholder="Start typing here..."
                                    onChange={(text) => onAssessmentGoalsChange(text)}
                                    value={assessment}
                                />
                                {showCannedResponseForAssessmentList && <CannedResponsesList
                                            canned_responses_data={props.canned_responses}
                                            onCannedResponseClick={(uid, short_cut, text) => onAssessmentCannedResponseClick(uid, short_cut, text)}
                                        />}
                            </div>
                            <div className={classes.SOAP_div}>
                                <span className={classes.SOAP_heading}>Plan:</span>
                                <TextareaAutosize
                                    className={classes.SOAP_textarea}
                                    placeholder="Start typing here..."
                                    onChange={(text) => onPlanChange(text)}
                                    value={plan}
                                />
                                {showCannedResponseForPlanList && <CannedResponsesList
                                            canned_responses_data={props.canned_responses}
                                            onCannedResponseClick={(uid, short_cut, text) => onPlanCannedResponseClick(uid, short_cut, text)}
                                        />}
                            </div>
                            <div className={classes.SOAP_buttons}>
                                                                <span className={classes.SOAP_list_text}
                                                                      onClick={() => onSaveSOAP()}>Save</span>
                                {/*<span className={classes.SOAP_clear_button}
                                      onClick={() => {
                                          onSaveSOAP();
                                          props.closeChat();
                                      }}>End Encounter</span>*/}
                                <span className={classes.SOAP_clear_button}
                                      onClick={() => onClearSOAP()}>Clear</span>
                            </div>
                        </div>
                        }
                        {props.isLoading &&
                        <div className={classes.spinner_view}>
                            <Spinner animation="border" size="sm" variant="light"/>
                        </div>
                        }
                    </div>
                :<div className={classes.SOAP_list}>
                    <div className={classes.SOAP_list_text}
                         onClick={() => editSOAPNotes()}>Edit Notes
                    </div>
                </div>:<></>
                }
            </div>}

        </div>
    )

}

const mapStateToProps = ({conversationReducer, cannedResponseReducer}) => {
    const {conversationId, doctorIsValid, selectedConversationObj } = conversationReducer;
    const { canned_responses } = cannedResponseReducer;

    return { conversationId, doctorIsValid, canned_responses, selectedConversationObj };
};

export default connect(mapStateToProps, {

})(SOAPNotes);
