
import classes from './DoctorsStyle.module.css';
import DoctorsList from './DoctorsList';
import {MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';

import _ from 'lodash';

const Header = (props) => {
    return (
        <tr>
            <th>
                <span className={classes.table_heading}>Status</span>
            </th>
            <th>
                <span className={classes.table_heading}>Name</span>
            </th>
            <th className={classes.table_column}>
                <span className={classes.table_heading}>Availbility</span>
            </th>
            <th className={classes.table_column}>
                <span className={classes.table_heading}>Session Chats Count</span>
            </th>
            <th className={classes.table_column}>
                <span className={classes.table_heading}>Paid Chat</span>
            </th>
        </tr>
    )
}

const DoctorsBody = (props) => {
    if (props.activeLink === 'doctors') {
        // eslint-disable-next-line array-callback-return
        const agents = props.agentsData.length > 0 ? props.agentsData.map(agent => {
            if (agent.email && agent.online && !agent.clinicalDoctor) {
                var onlineChats = props.chatCounts.length !== 0 && props.chatCounts.find(chat => chat.doctorID === agent.uid);
                return <DoctorsList
                    key={agent.uid}
                    doctorID={agent.uid}
                    doctorName={agent.doctorName}
                    email={agent.email}
                    role={agent.role}
                    freeChatLimit={agent.freeChatLimit}
                    onlineFreeChats={agent.onlineFreeChats}
                    activeOnlineChats={agent.activeOnlineChats}
                    onlinePaidChats={onlineChats !== undefined && onlineChats !== false ? onlineChats.onlinePaidChats : 0}
                    paidChatLimit={agent.totalChatLimit}
                    accepting_chat={agent.accepting_chat}
                    agent={agent}
                    image={agent.profileImageURL}
                    onlineStatus={agent.online}
                    isNoDoctor={false}
                />
            }
        }) : null;
        const filtered_agents = _.compact(agents);
        return (

            <div className={classes.table_content}>
                <MDBTable>
                    <MDBTableHead>
                        <Header/>
                    </MDBTableHead>
                    <MDBTableBody>
                        {filtered_agents}
                        {(filtered_agents === null || filtered_agents.length < 1) &&
                        <DoctorsList isNoDoctor={true}/>
                        }
                    </MDBTableBody>
                </MDBTable>
            </div>
        )
    }
}

export default DoctorsBody;
