
import classes from '../../../../assets/classes.module.css';

const CannedResponsesList = (props) => {
    if (props.selectedEditTag === props.uid) {
        return (
            <div className={classes.response_item}>
                <div className={classes.response_item_form}>
                    <div className={classes.response_item_field}>
                        <textarea
                            value={props.selectedTagDesciption}
                            onChange={(event) => props.onChangeCannedDescription(event)}
                        />
                        <div className={classes.response_item_tag}>
                            <input
                                value={props.selectedTagShortCut}
                                onChange={(event) => props.onChangeCannedShortCut(event)}
                            />
                        </div>
                    </div>
                    <div className={classes.response_item_submit}>
                        <button
                            onClick={() => props.updateCannedResponse(props.uid, props.selectedTagDesciption, props.selectedTagShortCut)}>
                            <span>Save changes</span>
                        </button>
                        <span>or
                            <div className={classes.cancel_button}
                                 onClick={() => props.updateCancelClick()}>cancel</div>
                        </span>
                    </div>
                </div>
            </div>
        )
    } else if (props.selectedDeleteTag === props.uid) {
        return (
            <div className={classes.response_item}>
                <div className={classes.delete_view}>
                    <div className={classes.confirm_remove}>
                        <div className={classes.wrapper}>
                            <div>
                                <button onClick={() => props.confirmDeleteClick(props.selectedDeleteTag)}><span>Delete this response</span>
                                </button>
                                or
                                <span
                                    className={classes.cancel_delete_button}
                                    onClick={() => props.confirmCancelClick()}
                                >cancel</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className={classes.response_item}>
            <div className={classes.view}>
                <div className={classes.text}>{props.description}</div>
                <div className={classes.tags}>
                    <span>Shortcut: </span>
                    <div className={classes.shortcut}>{props.shortcut}</div>
                    <div className={classes.links}>
                        <span
                            onClick={() => props.onEditClick(props.uid, props.description, props.shortcut)}>Edit</span>
                        <span onClick={() => props.onDeleteClick(props.uid)}>Delete</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const CannedResponses = (props) => {
    const cannedResponsesList = props.canned_responsesList_chats.map(canned_response => {
        return <CannedResponsesList
            key={canned_response.uid}
            uid={canned_response.uid}
            description={canned_response.text}
            shortcut={canned_response.shortcut}
            selectedEditTag={props.selectedEditTag}
            selectedDeleteTag={props.selectedDeleteTag}
            selectedTagDesciption={props.selectedTagDesciption}
            selectedTagShortCut={props.selectedTagShortCut}
            onChangeCannedDescription={(event) => props.onChangeCannedDescription(event)}
            onChangeCannedShortCut={(event) => props.onChangeCannedShortCut(event)}
            onEditClick={(uid, desc, shortcut) => props.onEditClick(uid, desc, shortcut)}
            updateCannedResponse={(uid, desc, shortcut) => props.updateCannedResponse(uid, desc, shortcut)}
            updateCancelClick={() => props.updateCancelClick()}
            onDeleteClick={(uid) => props.onDeleteClick(uid)}
            confirmDeleteClick={(uid) => props.confirmDeleteClick(uid)}
            confirmCancelClick={() => props.confirmCancelClick()}
        />
    });

    return (
        <div className={classes.detail_content}>
            <form className={classes.form}>
                <textarea
                    type="text"
                    id="add-tag"
                    placeholder="Canned responses text...."
                    tabIndex="1"
                    required
                    value={props.description}
                    onChange={(text) => props.onCannedResponseTextChange(text)}
                />

                <input
                    type="text"
                    id="add-tag"
                    placeholder="Shortcut..."
                    tabIndex="1"
                    required
                    value={props.short_cut}
                    onChange={(text) => props.onCannedResponseShortcutChange(text)}
                />
                <span className={classes.form_span}>To use a canned response during an encounter, type in # and the shortcut name.</span>
            </form>

            <div className={classes.response}>
                <div className={classes.response_list}>
                    {cannedResponsesList}
                </div>
            </div>
            <div className={classes.bottom_bar}/>
            <div className={classes.button_row}>
                <button className={classes.higlighted_button}
                        onClick={(event) => props.onSaveCannedResponse(event)}>Save
                </button>
            </div>
        </div>
    )
}

export default CannedResponses;
