import  {Component} from 'react';
import {connect} from 'react-redux';
import Aux from '../../../hoc/hocAux';

import classes from './settings.module.css';
import List from './list';
import Detail from './detail';

import {clinic_fetchTags, clinic_save_tags, clinic_tag_changed} from '../../../actions/clinic/setting/ClinicTagsAction';
import {
    clinic_billing_code_changed,
    clinic_billing_code_desc_changed,
    clinic_deleteBillingCode,
    clinic_fetchBillingCodes,
    clinic_save_billing_code,
    clinic_updateBillingCode
} from '../../../actions/clinic/setting/ClinicBillingCodesAction';
import {
    clinic_canned_response_descriptopn_changed,
    clinic_canned_response_save,
    clinic_canned_response_shortcut_changed,
    clinic_deleteCannedResponse,
    clinic_fetchCannedResponsesForAssessment,
    clinic_fetchCannedResponsesForChats,
    clinic_fetchCannedResponsesForObjectiveFindings,
    clinic_fetchCannedResponsesForPlan,
    clinic_updateCannedResponse
} from '../../../actions/clinic/setting/ClinicCannedResponseAction';

class ClinicSetting extends Component {

    constructor(props) {
        super(props);
        this.state = {
            headerText: '',
            selectedEditTag: null,
            selectedTagDesciption: '',
            selectedTagShortCut: '',
            selectedDeleteTag: null,
            showCannedResponsesItems: false,
        }
    }

    componentDidMount() {
        this.props.clinic_fetchTags(this.props.clinicId);
        this.props.clinic_fetchCannedResponsesForChats(this.props.clinicId);
        this.props.clinic_fetchCannedResponsesForObjectiveFindings(this.props.clinicId);
        this.props.clinic_fetchCannedResponsesForAssessment(this.props.clinicId);
        this.props.clinic_fetchCannedResponsesForPlan(this.props.clinicId);
        this.props.clinic_fetchBillingCodes(this.props.clinicId);
    }

    settingItemsClick = (item) => {
        this.setState({headerText: item});
    }

    onTagTextChange = (event) => {
        this.props.clinic_tag_changed(event.target.value);
    }

    onSaveTag = (event) => {
        event.preventDefault();
        if (this.props.clinic_tag_value !== '') {
            this.props.clinic_save_tags(this.props.clinicId, this.props.clinic_tag_value);
        } else {
            alert("Please check tag input.");
        }
    }

    description = (event) => {
        this.props.clinic_canned_response_descriptopn_changed(event.target.value);
    }

    shortcut = (event) => {
        this.props.clinic_canned_response_shortcut_changed(event.target.value);
    }

    onSaveCannedResponse = (event) => {
        event.preventDefault();
        if (this.props.description !== '' && this.props.short_cut !== '') {
            this.props.clinic_canned_response_save(
                this.props.clinicId,
                this.props.clinic_description,
                this.props.clinic_short_cut,
                this.state.headerText
            );
        } else {
            alert("Please check your canned response text and shortcut. We need both");
        }
    }

    onEditClick = (uid, desc, shortcut) => {
        this.setState({selectedEditTag: uid, selectedTagDesciption: desc, selectedTagShortCut: shortcut});
    }

    onChangeCannedDescription = (event) => {
        this.setState({selectedTagDesciption: event.target.value});
    }

    onChangeCannedShortCut = (event) => {
        this.setState({selectedTagShortCut: event.target.value});
    }

    updateCannedResponse = (uid, desc, shortcut) => {
        var self = this;
        if (this.state.headerText === 'Billing Codes') {
            this.props.clinic_updateBillingCode(this.props.clinicId, uid, desc, shortcut, this.state.headerText, function (status) {
                self.setState({selectedEditTag: null, selectedTagDesciption: '', selectedTagShortCut: ''});
            });
        } else {
            this.props.clinic_updateCannedResponse(this.props.clinicId, uid, desc, shortcut, this.state.headerText, function (status) {
                self.setState({selectedEditTag: null, selectedTagDesciption: '', selectedTagShortCut: ''});
            });
        }
    }

    updateCancelClick = () => {
        this.setState({selectedEditTag: null, selectedTagDesciption: '', selectedTagShortCut: ''});
    }

    onDeleteClick = (uid) => {
        this.setState({selectedDeleteTag: uid});
    }

    confirmDeleteClick = (uid) => {
        var self = this;
        if (this.state.headerText === 'Billing Codes') {
            this.props.clinic_deleteBillingCode(this.props.clinicId, uid, this.state.headerText, function (status) {
                self.setState({selectedDeleteTag: uid});
            });
        } else {
            this.props.clinic_deleteCannedResponse(this.props.clinicId, uid, this.state.headerText, function (status) {
                self.setState({selectedDeleteTag: uid});
            });
        }
    }

    confirmCancelClick = () => {
        this.setState({selectedDeleteTag: null});
    }

    onBillingCodeTextChange = (event) => {
        this.props.clinic_billing_code_changed(event.target.value);
    }

    onBillingCodeDescriptionChange = (event) => {
        this.props.clinic_billing_code_desc_changed(event.target.value);
    }

    onSaveBillingCode = (event) => {
        event.preventDefault();
        if (this.props.clinic_billing_code_value !== '' && this.props.clinic_billing_code_desc_value !== '') {
            this.props.clinic_save_billing_code(this.props.clinicId, this.props.clinic_billing_code_value, this.props.clinic_billing_code_desc_value);
        } else {
            alert("Please check dilling codes input.");
        }
    }

    showCannedResponsesInfo = () => {
        this.setState((state) => ({showCannedResponsesItems: !state.showCannedResponsesItems}));
    }

    render() {
        return (
            <Aux>
                <div className={classes.main}>
                    <List
                        showCannedResponsesItems={this.state.showCannedResponsesItems}
                        showCannedResponsesInfo={() => this.showCannedResponsesInfo()}
                        settingItemsClick={(item) => this.settingItemsClick(item)}
                        acceptingChat={this.props.acceptingChat}
                        userType={this.props.userType}
                    />
                    <Detail
                        header={this.state.headerText}
                        tag={this.props.clinic_tag_value}
                        tagsList={this.props.clinic_tags}
                        onTagTextChange={(text) => this.onTagTextChange(text)}
                        onSaveTag={(event) => this.onSaveTag(event)}
                        description={this.props.clinic_description}
                        short_cut={this.props.clinic_short_cut}
                        selectedEditTag={this.state.selectedEditTag}
                        selectedDeleteTag={this.state.selectedDeleteTag}
                        selectedTagDesciption={this.state.selectedTagDesciption}
                        selectedTagShortCut={this.state.selectedTagShortCut}
                        canned_responsesList={this.props.clinic_canned_responses}
                        canned_responsesList_chats={this.props.clinic_chats_canned_responses}
                        canned_responsesList_assessment={this.props.clinic_canned_responses_assessment}
                        canned_responsesList_plan={this.props.clinic_canned_responses_plan}
                        onChangeCannedDescription={(event) => this.onChangeCannedDescription(event)}
                        onChangeCannedShortCut={(event) => this.onChangeCannedShortCut(event)}
                        onCannedResponseTextChange={(text) => this.description(text)}
                        onCannedResponseShortcutChange={(text) => this.shortcut(text)}
                        onSaveCannedResponse={(event) => this.onSaveCannedResponse(event)}
                        updateCannedResponse={(uid, desc, shortcut) => this.updateCannedResponse(uid, desc, shortcut)}
                        updateCancelClick={() => this.updateCancelClick()}
                        onEditClick={(uid, desc, shortcut) => this.onEditClick(uid, desc, shortcut)}
                        onDeleteClick={(uid) => this.onDeleteClick(uid)}
                        confirmDeleteClick={(uid) => this.confirmDeleteClick(uid)}
                        confirmCancelClick={() => this.confirmCancelClick()}
                        billingCodesList={this.props.clinic_billing_codes}
                        billing_code={this.props.clinic_billing_code_value}
                        billing_code_desc={this.props.clinic_billing_code_desc_value}
                        onBillingCodeTextChange={(text) => this.onBillingCodeTextChange(text)}
                        onBillingCodeDescriptionChange={(text) => this.onBillingCodeDescriptionChange(text)}
                        onSaveBillingCode={(event) => this.onSaveBillingCode(event)}
                    />
                </div>
            </Aux>
        )
    }
}

const mapStateToProps = ({
                             clinicProfileSettingReducer, clinicTagsReducer, clinicCannedResponseReducer,
                             clinicBillingCodesReducer
                         }) => {

    const {clinicId, userType} = clinicProfileSettingReducer;

    const {clinic_tags, clinic_tag_value} = clinicTagsReducer;
    const {clinic_billing_code_value, clinic_billing_code_desc_value, clinic_billing_codes} = clinicBillingCodesReducer;

    const {
        clinic_description, clinic_short_cut, clinic_canned_responses,
        clinic_chats_canned_responses, clinic_canned_responses_assessment,
        clinic_canned_responses_plan
    } = clinicCannedResponseReducer;

    return {
        clinicId, userType,
        clinic_tags, clinic_tag_value,
        clinic_billing_code_value, clinic_billing_code_desc_value, clinic_billing_codes,
        clinic_description, clinic_short_cut, clinic_canned_responses,
        clinic_chats_canned_responses, clinic_canned_responses_assessment,
        clinic_canned_responses_plan
    };
}

export default connect(mapStateToProps, {
    clinic_tag_changed,
    clinic_save_tags,
    clinic_fetchTags,
    clinic_billing_code_changed,
    clinic_billing_code_desc_changed,
    clinic_save_billing_code,
    clinic_fetchBillingCodes,
    clinic_updateBillingCode,
    clinic_deleteBillingCode,
    clinic_canned_response_descriptopn_changed,
    clinic_canned_response_shortcut_changed,
    clinic_canned_response_save,
    clinic_fetchCannedResponsesForChats,
    clinic_fetchCannedResponsesForObjectiveFindings,
    clinic_fetchCannedResponsesForAssessment,
    clinic_fetchCannedResponsesForPlan,
    clinic_updateCannedResponse,
    clinic_deleteCannedResponse
})(ClinicSetting);
