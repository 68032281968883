import {Component, createRef} from 'react';
import {connect} from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import csc from 'country-state-city';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import classes from '../../../assets/classes.module.css';
import _classes from '../../../assets/_classes.module.css';

import {
    ClinicDrBillingNo,
    ClinicLicense,
    FullName,
    getClinicDataByID,
    setUpStaffProfile,
    showClinicAdminProfileImage,
    showClinicAdminSignature,
    StaffPhoneNumber
} from '../../../actions/clinic/create/CreateClinicAction';

import {forgotPassword} from '../../../actions/LoginAction';
import isMobileView from '../../../utils/isMobileView';

function InputItem(props) {
    return (
        <div style={{display: 'flex', flexDirection: 'column', margin: '10px 0px'}}><label
            className={_classes.inputItemLabel}>{props.label}</label>
            {Boolean(props.options) ? <select
                    className={_classes.inputItem}
                    {...props}
                    placeholder={props.placeholder}
                    value={props.value || ''}
                    onChange={(e) => props.onChange(e)}
                >
                    {props.options.options.map(option => (
                        <option key={option.id} id={option.id} value={option.name}>
                            {option.name}
                        </option>
                    ))}
                </select> :
                props.type === 'file' ?
                    null :
                    <input className={props.disabled ? _classes.readOnlyInputItem : _classes.inputItem}  {...props}
                           placeholder={props.placeholder} value={props.value || ''}
                           onChange={(e) => props.onChange(e)}></input>}

        </div>
    );
}

class Admin extends Component {

    constructor(props) {
        super(props);
        this.textInput = createRef();
        this.signatureFile = createRef();
        this.state = {
            file: '',
            signature_file: '',
            showChangePasswordModal: false,
            selectedItem: 'profile',
            profile_value: 'Profile',
            countries: {'options': csc.getAllCountries()},
            provinces: {'options': csc.getStatesOfCountry("38")},
        }
    }

    changeFullName = (event) => {
        this.props.FullName(event.target.value);
    }

    changePhone = (event) => {
        this.props.StaffPhoneNumber(event.target.value);
    }

    changeLicenseNo = (event) => {
        this.props.ClinicLicense(event.target.value);
    }
    changeBillingNo = (event) => {
        this.props.ClinicDrBillingNo(event.target.value);
    }
    onAttachmentClick = (e) => {
        e.current.click();
    }

    _handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            var type = file.type.split('/');
            this.props.showClinicAdminProfileImage(reader.result);
            this.setState({
                file: file,
            });
        }
        reader.readAsDataURL(file);
    }

    _handleSignatureChnage = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            var type = file.type.split('/');
            if (!(file.type + '').includes('jpeg', 'jpg')) {
                alert('Signature should be in a .jpg or .jpeg format with a maximum file size of 5MB.')
                return
            }
            this.props.showClinicAdminSignature(reader.result);
            this.setState({
                signature_file: file,
            });
        }

        reader.readAsDataURL(file);
    }


    onSaveClick = (event) => {
        event.preventDefault();
        this.props.setUpStaffProfile(
            this.state.file,
            this.state.signature_file,
            this.props.clinicId,
            this.props,
        );
        alert('Saved')
    }

    render() {

        const {
            clinicData, staffData, userEmail, clinic_name, full_name, address, province, city, country,
            phone, phoneNumber, postal_code, fax, email_address, license_no, profile_image, signature,
            clinic_email_address, loading, message, physician_user, userType, userProfile, loader,
            msg, err, staffPhoneNumber, dr_billing_no
        } = this.props;

        let {selectedItem, countries, provinces, showChangePasswordModal} = this.state;

        return (
            !isMobileView() ? <div className={classes.main_container}>
                    <div className={classes.main_container_header}>Personalize your profile</div>
                    <div className={classes.form_container_view}>
                        <div className={classes.form_row}>
                            <span className={classes.label}>Clinic name: </span>
                            <OverlayTrigger
                                key={'right'}
                                placement={'right'}
                                overlay={
                                    <Tooltip id={`tooltip-left`}>Clinic name</Tooltip>
                                }
                            >
                                <input
                                    className={classes.readOnly_input}
                                    value={clinic_name}
                                    readOnly
                                />
                            </OverlayTrigger>
                        </div>
                        <div className={classes.form_row}>
                            <span className={classes.label}>Profile: </span>
                            <OverlayTrigger
                                key={'right'}
                                placement={'right'}
                                overlay={
                                    <Tooltip id={`tooltip-left`}>Your profile</Tooltip>
                                }
                            >
                                <input
                                    className={classes.readOnly_input}
                                    value={Boolean(staffData) ? staffData.staffProfile.charAt(0).toUpperCase() + staffData.staffProfile.slice(1) : userProfile}
                                    readOnly
                                />
                            </OverlayTrigger>
                        </div>
                        <div className={classes.form_row}>
                            <span className={classes.label}>Full name: </span>
                            <OverlayTrigger
                                key={'right'}
                                placement={'right'}
                                overlay={
                                    <Tooltip id={`tooltip-left`}>Your full name that patients will be seeing.</Tooltip>
                                }
                            >
                                <input
                                    className={classes.form_input}
                                    value={full_name}
                                    onChange={(event) => this.changeFullName(event)}
                                />
                            </OverlayTrigger>
                        </div>
                        <div className={classes.form_row}>
                            <span className={classes.label}>Phone: </span>
                            <OverlayTrigger
                                key={'right'}
                                placement={'right'}
                                overlay={
                                    <Tooltip id={`tooltip-left`}>This is the phone number that will be used if patients have
                                        any inquiries. Please start with a + and a country code followed by your phone
                                        number. Ex +18774534777</Tooltip>
                                }
                            >
                                <input
                                    className={classes.form_small_input}
                                    value={staffPhoneNumber}
                                    onChange={(event) => this.changePhone(event, staffPhoneNumber)}
                                />
                            </OverlayTrigger>
                        </div>
                        <div className={classes.form_row}>
                            <span className={classes.label}>Email Address: </span>
                            <OverlayTrigger
                                key={'right'}
                                placement={'right'}
                                overlay={
                                    <Tooltip id={`tooltip-left`}>Email Address</Tooltip>
                                }
                            >
                                <input
                                    className={classes.readOnly_input}
                                    value={userEmail}
                                    readOnly
                                />
                            </OverlayTrigger>
                        </div>
                        {staffData && (staffData.soapNotes && staffData.createPrescription) &&
                        <div className={classes.form_row}>
                            <span className={classes.label}>License no: </span>
                            <OverlayTrigger
                                key={'right'}
                                placement={'right'}
                                overlay={
                                    <Tooltip id={`tooltip-left`}>This is your medical identification number that will be on
                                        SOAP notes, prescriptions, etc</Tooltip>
                                }
                            >
                                <input
                                    className={classes.form_input}
                                    value={license_no}
                                    onChange={(event) => this.changeLicenseNo(event, license_no)}
                                />
                            </OverlayTrigger>
                        </div>
                        }
                        <div className={classes.form_row}>
                            <span className={classes.label}>Billing No: </span>
                            <OverlayTrigger
                                key={'right'}
                                placement={'right'}
                                overlay={
                                    <Tooltip id={`tooltip-left`}></Tooltip>
                                }
                            >
                                <input
                                    className={classes.form_input}
                                    value={dr_billing_no}
                                    onChange={(event) => this.changeBillingNo(event, dr_billing_no)}
                                />
                            </OverlayTrigger>
                        </div>
                        <div className={classes.form_row}>
                            <span className={classes.label}>Password: </span>
                            <div className={classes.column_view}>
                                <div className={classes.underline_label} onClick={() => {
                                    this.props.onChangePassword(true)
                                }}>Reset Password
                                </div>
                            </div>
                        </div>
                        <div className={classes.form_row}>
                            <span className={classes.label}>Profile Picture: </span>
                            <div>
                                {(profile_image !== null && profile_image !== undefined) &&
                                <img
                                    className={classes.image_container}
                                    src={profile_image}
                                    alt=""
                                />
                                }
                                <input
                                    className={classes.hidden_input}
                                    type="file"
                                    accept="image/jpeg"
                                    ref={this.textInput}
                                    onChange={(e) => this._handleImageChange(e)}
                                />
                            </div>
                            <div className={classes.column_view}>
                                <div className={classes.underline_label}
                                     onClick={() => this.onAttachmentClick(this.textInput)}>Upload Picture
                                </div>
                                <span>Profile picture should be in a .jpg or .jpeg format with a maximum file size of 5MB.</span>
                            </div>
                        </div>
                        {staffData && (staffData.soapNotes && staffData.createPrescription) &&
                        <div className={classes.form_row}>
                            <span className={classes.label}>Upload Signature: </span>
                            {/* {(signature === null || signature === undefined) &&  */}
                            <div>
                                <input
                                    type="file"
                                    accept="image/jpeg"
                                    ref={this.signatureFile}
                                    className={classes.hidden_input}
                                    onChange={(e) => this._handleSignatureChnage(e)}
                                />
                            </div>
                            {/* } */}
                            {(signature !== null && signature !== undefined) &&
                            <img
                                className={classes.signature_container}
                                src={signature}
                                alt=""
                            />
                            }
                            <div className={classes.column_view}>
                                <div className={classes.underline_label}
                                     onClick={() => this.onAttachmentClick(this.signatureFile)}>Upload Signature
                                </div>
                                <span>Signature should be in a .jpg or .jpeg format with a maximum file size of 5MB.</span>
                            </div>
                        </div>
                        }
                        {loading &&
                        <div className={classes.center_view}>
                            <ClipLoader
                                size={100}
                                color={"#884a9d"}
                            />
                        </div>
                        }
                        {!loading &&
                        <div className={classes.button_row}>
                            <button className={classes.higlighted_button}
                                    onClick={(event) => this.onSaveClick(event)}>Save
                            </button>
                        </div>
                        }
                        {message !== '' &&
                        <div className={classes.center_view}>
                            <span>{message}</span>
                        </div>
                        }
                    </div>
                </div>
                : <div className={_classes._form} style={{
                    'padding': '20px',
                    'padding-bottom': '200px',
                    'background-color': 'rgb(241,242,246)',
                    'overflow-y': 'scroll',
                    position: 'absolute',
                    left: 0,
                    top: 70,
                    right: 0,
                    'height': '-webkit-fill-available',
                    zIndex: 10
                }}>

                    <InputItem label='Clinic name:'
                               placeholder='YDO Clinic'
                               property='clinic'
                               value={clinic_name}
                               onChange={() => {
                               }}
                               disabled
                    ></InputItem>
                    <InputItem label='Profile:'
                               placeholder='Your Profile'
                               property='profile'
                               value={Boolean(staffData) ? staffData.staffProfile.charAt(0).toUpperCase() + staffData.staffProfile.slice(1) : userProfile}
                               onChange={() => {
                               }}
                               disabled
                    ></InputItem>
                    <InputItem label='Full Name:'
                               placeholder='Raihan Masroor'
                               property='fullName'

                               value={this.props.full_name}
                               onChange={(event) => this.changeFullName(event)}
                    ></InputItem>
                    <InputItem label='Email:'
                               placeholder='raihan@yourdoctors.online'
                               property='email'
                               value={userEmail}
                               onChange={() => {
                               }}
                               disabled
                    ></InputItem>
                    <InputItem
                        label='License No:'
                        placeholder='123123123'
                        property='licenseNo'
                        value={this.props.license_no}
                        onChange={(event) => this.changeLicenseNo(event, license_no)}></InputItem>
                    <InputItem
                        label='Billing No (Optional):'
                        placeholder='123123'
                        property='billingNo'
                        value={this.props.dr_billing_no}
                        onChange={(event) => this.changeBillingNo(event)}></InputItem>
                    {/* <InputItem
            label='Address:'
            placeholder='1055 Candian Place'
            property='address'
            value={this.props.address}
            onChange={(event) => this.changeAddress(event)}
        ></InputItem> */}
                    {/* <InputItem
            label='City:'
            placeholder='Toronto'
            property='city'
            value={this.props.city}
            onChange={(event) => this.changeCity(event)}></InputItem>
        <InputItem
            label='Provice:'
            placeholder='Ontario'
            property='province'
            value={this.props.province} onChange={(event) => this.changeProvince(event, province)}></InputItem> */}
                    {/* <InputItem
            label='Country:'
            placeholder='Canada'
            property='country'
            value={this.props.country}
            onChange={(event) => this.changeCountry(event, country)}></InputItem> */}
                    <InputItem
                        label='Phone:'
                        placeholder='123123123'
                        property='phoneNo'
                        value={this.props.staffPhoneNumber}
                        onChange={(event) => this.changePhone(event, staffPhoneNumber)}
                    ></InputItem>
                    {/* <InputItem label='Fax:'
            placeholder='Canada'
            property='fax'
            value={this.props.fax}
            onChange={(event) => this.changeFax(event, fax)}
        ></InputItem> */}
                    <InputItem
                        label='Profile Picture:'
                        type="file"
                        accept="image/jpeg"
                        ref={this.textInput}
                        // className={classes.hidden_input}
                        onChange={(e) => this._handleImageChange(e)}
                    ></InputItem>
                    {(Boolean(profile_image)) &&
                    <img
                        className={classes.image_container}
                        src={profile_image}
                        alt=""
                        style={{'margin-left': '20px'}}
                    />
                    }
                    <input
                        className={classes.hidden_input}
                        type="file"
                        accept="image/jpeg"
                        ref={this.textInput}
                        onChange={(e) => this._handleImageChange(e)}
                    />
                    <div style={{display: 'flex', flexDirection: 'column', margin: '10px 0px'}}>

                        {/* <label className={_classes.inputItemLabel} >Profile Picture:</label > */}
                        <label className={_classes.inputItemLabel} style={{
                            textDecorationLine: 'underline',
                            color: 'black',
                            'margin-top': '5px',
                            'margin-bottom': '5px'
                        }} onClick={() => this.onAttachmentClick(this.textInput)}>Upload Picture</label>
                    </div>
                    {staffData && (staffData.soapNotes && staffData.createPrescription) &&
                    <div style={{display: 'flex', flexDirection: 'column', margin: '10px 0px'}}>
                        <label className={_classes.inputItemLabel}>Signature:</label>
                        {/* {(signature === null || signature === undefined) && */}
                        <input
                            accept="image/jpeg"
                            ref={this.signatureFile}
                            className={classes.hidden_input}
                            onChange={(e) => this._handleSignatureChnage(e)}
                        />
                        {/* } */}
                        {(signature !== null && signature !== undefined) &&
                        <img
                            className={classes.signature_container}
                            src={signature}
                            alt=""
                            style={{'margin-left': '20px'}}
                        />
                        }
                        <label className={_classes.inputItemLabel} style={{
                            textDecorationLine: 'underline',
                            color: 'black',
                            'margin-top': '5px',
                            'margin-bottom': '5px'
                        }} onClick={() => this.onAttachmentClick(this.signatureFile)}>Upload Signature</label>
                    </div>}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        justifyItems: 'center',
                        alignItems: 'center',
                        margin: '20px 10px'
                    }}>
                        <button style={{
                            width: '50%',
                            height: '40px',
                            backgroundColor: '#fff',
                            borderRadius: '5px',
                            backgroundColor: "#884a9d",
                            border: '1px',
                            color: '#fff',
                            fontSize: '14px'
                        }}
                                title="Confirm"
                                onClick={(event) => this.onSaveClick(event)}>Save
                        </button>
                    </div>

                </div>
        )
    }
}

const mapStateToProps = ({clinicProfileSettingReducer, forgotPasswordReducer}) => {
    const {
        clinicData,
        staffData,
        userEmail,
        clinicId,
        clinic_name,
        full_name,
        address,
        province,
        city,
        country,
        phone,
        postal_code,
        fax,
        email_address,
        license_no,
        dr_billing_no,
        profile_image,
        signature,
        clinic_email_address,
        physician_user,
        loading,
        message,
        userType,
        userProfile,
        staffPhoneNumber
    } = clinicProfileSettingReducer;

    const {loader, msg, err} = forgotPasswordReducer;

    return {
        clinicData,
        staffData,
        userEmail,
        clinicId,
        clinicId,
        clinic_name,
        full_name,
        address,
        province,
        city,
        country,
        phone,
        postal_code,
        fax,
        email_address,
        license_no,
        dr_billing_no,
        profile_image,
        signature,
        staffPhoneNumber,
        clinic_email_address,
        physician_user,
        loading,
        message,
        userType,
        userProfile,
        loader,
        msg,
        err
    };
};

export default connect(mapStateToProps, {
    forgotPassword, getClinicDataByID,
    FullName, ClinicLicense, ClinicDrBillingNo, showClinicAdminProfileImage, showClinicAdminSignature,
    setUpStaffProfile, StaffPhoneNumber
})(Admin);
