import  {Component, createRef} from 'react';
import {connect} from 'react-redux';
import classes from './ClinicalPatientsBuilder.module.css';

import Logo from '../../../assets/reg_logo.png';
import EmailIcon from '../../../assets/email.png';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {createBrowserHistory} from 'history';
import {loginUser} from '../../../actions/LoginAction';
import _ from 'lodash';
import {auth, authRef, database} from "../../../firebase";
import axios from "axios";
import {firebaseRoot} from "../../../config";

const moment = require("moment");
const browserHistory = createBrowserHistory();
const queryString = require('query-string');

class ClinicalPatientRegistrationBuilder extends Component {
    constructor(props) {
        super(props);
        this.dob_input = createRef();
        this.first_name_input = createRef();
        this.last_name_input = createRef();
        this.gender_input = createRef();
        this.email_address_input = createRef();

        this.state = {
            gender: "",
            saveGender: "",
            first_name: "",
            last_name: "",
            email_address: "",
            create_password: "",
            dob: moment('01 Jan, 1970').format("YYYY-MM-DD"),
            health_insurance_no: "",
            terms_checks: false,
            loading: true,
            isAlreadyRegistered: false,
            disableFields: false,
            registered: false,
            loggedIn: false,
            signedIn: false,
            clinic_name: "",
            clinic_patient_registry_key: null,
            focusIndex: null,
            firstdigit: '',
            seconddigit: '',
            thirddigit: '',
            userId: '',
            showError: false,
            errorMessage: "",
            showLogin: false,
            showVerifyEmail: false,
            sent: false,
            notSent: false,
            social_profile: null,
            social_provider: "",
            isloginwait: false,
            isLoginWaitFix: false,
            socialUser: null,
            alreadyVerified: false,
            clinic: {},
            appURL: ""
        };
        this.ref0 = createRef()
        this.ref1 = createRef()
        this.ref2 = createRef()
    }

    focusDiv = () => {
        if (this.state.focusIndex) {
            switch (this.state.focusIndex) {
                case 1:
                    this.ref0.current.focus()
                    break;
                case 2:
                    this.ref1.current.focus()
                    break;
                case 3:
                    this.ref2.current.focus()
                    break;
                default:
                    break;
            }
        }
    }

    UNSAFE_componentWillMount = () => {
        const clinic_id = browserHistory.location.pathname.replace('/patient-registration_', '');
        const parsed = queryString.parse(browserHistory.location.search);
        var clinic_name = decodeURIComponent(parsed.clinic_name);
        if (clinic_id.indexOf('patient') < 0) {
            let clinic = {};
            clinic.uid = clinic_id;

            this.setState({clinic_name: clinic_name, clinic: clinic});
            database.ref(`clinic/${clinic_id}`).once('value', clinicSnap => {
                if (clinicSnap.val()) {
                    let clinic_name = clinicSnap.val().clinicName;
                    let clinic = clinicSnap.val();
                    clinic.uid = clinicSnap.key;
                    this.setState({clinic_name: clinic_name, clinic: clinic});
                }
            })
        }
        var clinic_patient_registry_key = (parsed.clinic_patient_registry_key) ? decodeURIComponent(parsed.clinic_patient_registry_key) : null;
        this.setState({clinic_patient_registry_key: clinic_patient_registry_key});
        if (Boolean(parsed.email)) {
            var email = parsed.email.replace(' ', '+');
            this.setState({email_address: email});
            var root = 'https://admin.yourdoctors.online/api/0.1/';
            fetch(root + `admin_users/getuser?email=${encodeURIComponent(email)}`, {
                method: "get",
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(response => response.json())
                .then(success => {
                    if (Boolean(success.user)) {
                        this.setState({isAlreadyRegistered: true});
                    }
                    if (parsed.clinic_patient_registry_key) {
                        database.ref(`clinicalPatients/${clinic_id}/${decodeURIComponent(parsed.clinic_patient_registry_key)}`).once('value', patientValue => {
                            if (patientValue.val()) {
                                if (clinic_patient_registry_key) this.setState({disableFields: true});

                                var DOB = [patientValue.val().dob.slice(0, 4) + '-' + patientValue.val().dob.slice(4, 6) + '-' + patientValue.val().dob.slice(6)].join('');
                                this.setState({
                                    loading: false,
                                    first_name: patientValue.val().firstName,
                                    last_name: patientValue.val().lastName,
                                    gender: patientValue.val().gender,
                                    saveGender: patientValue.val().gender,
                                    health_insurance_no: patientValue.val().insuranceCardNo,
                                    dob: patientValue.val().dob,
                                    email_address: patientValue.val().email
                                });
                                if (this.first_name_input.current) this.first_name_input.current.value = patientValue.val().firstName;
                                if (this.last_name_input.current) this.last_name_input.current.value = patientValue.val().lastName;
                                if (this.gender_input.current) this.gender_input.current.value = patientValue.val().gender;
                                if (this.email_address_input.current) this.email_address_input.current.value = patientValue.val().email;
                                if (this.dob_input.current) this.dob_input.current.value = DOB;

                                if (Boolean(success.user)) {
                                    this.setState({isAlreadyRegistered: true});
                                }
                            } else {
                                this.setState({
                                    loading: false
                                })
                            }
                        })
                            .catch(error => {
                                this.setState({
                                    loading: false
                                })
                                console.log("firebase error", error);
                            })
                    } else {
                        this.setState({
                            loading: false
                        })
                    }


                })
        } else {
            this.setState({
                loading: false,
                showLogin: true,
            })
        }
    }
    /* componentDidMount = () =>{
        this.focusDiv();
    }
    UNSAFE_componentWillUpdate = () =>{
        this.focusDiv();
    } */

    handleSocialRegister = (user) => {
        this.setState({isloginwait: true});
        this.setState({social_profile: user._profile, social_provider: user._provider});
        var access_token = user._token.accessToken;
        const credential = authRef.FacebookAuthProvider.credential(access_token);
        // login with credential
        const firebaseUserCredential = auth.signInWithCredential(credential);
        if (user._profile.email) {
            this.setState({email_address: user._profile.email})
        }
        if (user._profile.firstName) {
            this.setState({first_name: user._profile.firstName})
        }
        if (user._profile.lastName) {
            this.setState({last_name: user._profile.lastName})
        }
        this.registerWithSocial(firebaseUserCredential.user);
    }
    handleSocialRegisterFailure = (err) => {
        console.log("handleSocialRegisterFailure err: ", err);
        this.setState({
            showError: true,
            errorMessage: "Account creation failed, Please try again later."
        });
    }
    fetchIp = (callback) => {
        fetch('https://jsonip.com', {mode: 'cors'})
            .then((resp) => resp.json())
            .then((ip) => {
                callback(ip);
            })
            .catch(error => {
                console.log("fetch ip error", error);
                callback(null);
            })
    }
    confirmHealthVerification = () => {
        var healthno = this.state.health_insurance_no.substr(this.state.health_insurance_no.length - 3, this.state.health_insurance_no.length);
        var writeNo = this.state.firstdigit + this.state.seconddigit + this.state.thirddigit;
        if (healthno === writeNo) {
            var updates = {};
            this.setState({showError: false, errorText: '"verification failed"', signedIn: true});
            this.setURL();
            updates[`/users/${this.state.userId}/isUserVerified`] = true;
            database.ref().update(updates).then(()=>{}).catch(error=>{})
        } else {
            this.setState({showError: true, errorMessage: "verification failed"});
            console.log("confirmHealthVerification failure");
        }


    }
    setURL = () => {
        let self = this;
        var data = JSON.stringify({
            "dynamicLinkInfo": {
                "domainUriPrefix": "https://yourdoctor.page.link",
                "link": encodeURI(`https://mobile.yourdoctors.online/isfromDashboard?email_address=${this.state.email_address}`),
                "androidInfo": {
                    "androidPackageName": "com.ydo.smartapp"
                },
                "iosInfo": {
                    "iosBundleId": "com.ydo.smartapp",
                    "iosAppStoreId": "1439216318"
                }
            }
        });
        fetch("https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCdx3hnXpnL8TShLzDjDDBdOwDzlKEoGrg", {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: data
        })
            .then(function (response) {
                response.json().then(response => {
                    self.setState({appURL: response.shortLink});
                    //response.shortLink
                })
                    .catch((error) => {
                        console.log("error ", error);
                    })
            })
            .catch((error) => {
                console.log("error 1 ", error);
            })
    }
    loginAccount = () => {
        var filled = Boolean(this.state.email_address) && Boolean(this.state.create_password);
        if (filled) {
            this.setState({
                isloginwait: true, showError: false,
                errorMessage: ''
            })
            auth.signInWithEmailAndPassword(this.state.email_address, this.state.create_password)
                .then(user => {
                    let userData = user;
                    database.ref(`/users/${userData.user.uid}`).once('value', userSnap => {
                        if (userSnap.val() && userSnap.val().ban === "yes") {
                            window.confirm("Your account is banned, please contact the administration for further assistance");
                        } else {
                            var data = JSON.stringify({
                                "signIn": {
                                    "email": this.state.email_address,
                                    "password": this.state.create_password,
                                    "notification_token": userSnap.val().notificationToken
                                }
                            });
                            var root = 'https://admin.yourdoctors.online/api/0.1/';
                            fetch(root + 'sessions/sign_in_facebook', {
                                method: 'post',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: data
                            })
                                .then(response => response.json())
                                .then((success) => {
                                    if (success.status === true && success.status !== undefined) {
                                        var username = (success.signIn.firstname.indexOf("patient") !== -1 && success.signIn.firstname.indexOf("Patient")) ? success.signIn.firstname : this.state.first_name + " " + this.state.last_name;
                                        var firstname = (this.state.first_name) ? this.state.first_name : success.signIn.firstname.substr(0, success.signIn.firstname.indexOf(" ") > -1 ? success.signIn.firstname.indexOf(" ") : success.signIn.firstname.length);
                                        var last_name = (this.state.last_name) ? this.state.last_name : success.signIn.firstname.substr(success.signIn.firstname.indexOf(" ") > -1 ? success.signIn.firstname.indexOf(" ") + 1 : 0, success.signIn.firstname.length);
                                        let clinicFound = false;
                                        const rawClinics = (userSnap.val().clinics) ? _.map(userSnap.val().clinics, (val, uid) => {
                                            if (Boolean(val))
                                                return {...val, uid};
                                        }) : [];
                                        var clinics = (rawClinics) ? rawClinics.filter(val => {
                                            if (Boolean(val)) return val;
                                        }) : [];

                                        for (let i = 0; i < clinics.length; i++) {
                                            if (clinics[i].clinic_id === this.state.clinic.uid)
                                                clinicFound = true;
                                        }
                                        if (this.state.clinic_patient_registry_key) {
                                            let updates = {};

                                            var age = (userSnap.val().DOB) ? userSnap.val().DOB : moment(this.state.dob).format("MMMM DD YYYY");
                                            var gender = (userSnap.val().gender) ? userSnap.val().gender : this.state.saveGender;
                                            let date = new Date();
                                            let offset = date.getTimezoneOffset();
                                            offset = offset ? offset : 0;
                                            offset = (offset * -1) / 60;
                                            if (offset) updates[`/users/${userData.user.uid}/gmtOffset`] = offset;
                                            updates[`/clinicalPatients/${this.state.clinic.uid}/${this.state.clinic_patient_registry_key}/registrationStatus`] = "inviteaccepted";
                                            if (userData.user.uid) updates[`/clinicalPatients/${this.state.clinic.uid}/${this.state.clinic_patient_registry_key}/userId`] = userData.user.uid;

                                            if (username) updates[`/users/${userData.user.uid}/userName`] = username;
                                            if (success.signIn.current_sign_at_ip) updates[`/users/${userData.user.uid}/current_sign_at_ip`] = success.signIn.current_sign_at_ip;
                                            if (success.signIn.last_sign_at_ip) updates[`/users/${userData.user.uid}/last_sign_at_ip`] = success.signIn.last_sign_at_ip;
                                            if (age) updates[`/users/${userData.user.uid}/DOB`] = age;
                                            if (gender) updates[`/users/${userData.user.uid}/gender`] = gender;
                                            if (userSnap.val().healthInsuranceCardNumber || this.state.health_insurance_no) updates[`/users/${userData.user.uid}/healthInsuranceCardNumber`] = (userSnap.val().healthInsuranceCardNumber) ? userSnap.val().healthInsuranceCardNumber : this.state.health_insurance_no;
                                            if (!clinicFound) {
                                                let clinicID = database.ref(`/users/${userData.user.uid}/clinics/`).push().key;
                                                updates[`/users/${userData.user.uid}/clinics/${clinicID}`] = {clinic_id: this.state.clinic.uid};
                                                if (!userSnap.val().selectedClinic) {
                                                    updates[`/users/${userData.user.uid}/selectedClinic`] = clinicID;
                                                }
                                            }

                                            database.ref().update(updates).then(()=>{}).catch(error=>{})

                                        } else {
                                            let updates = {};
                                            if (!clinicFound) {
                                                let clinic_patient_registry_key = database.ref(`/users/${userData.user.uid}/clinics/`).push().key;
                                                updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/dob`] = moment(new Date(age)).format('YYYY-MM-DD');
                                                if (this.state.email_address) updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/email`] = this.state.email_address;
                                                if (firstname) updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/firstName`] = firstname;
                                                updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/gender`] = (userSnap.val().gender) ? userSnap.val().gender : this.state.saveGender;
                                                updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/insuranceCardNo`] = (userSnap.val().healthInsuranceCardNumber) ? userSnap.val().healthInsuranceCardNumber : this.state.health_insurance_no;
                                                updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/lastName`] = (last_name) ? last_name : '';
                                                updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/timestamp`] = (new Date()).getTime();
                                                updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/registrationStatus`] = "inviteaccepted";
                                                updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/userId`] = userData.user.uid;

                                                updates[`/users/${userData.user.uid}/current_sign_at_ip`] = success.signIn.current_sign_at_ip;
                                                updates[`/users/${userData.user.uid}/last_sign_at_ip`] = success.signIn.last_sign_at_ip;
                                                let clinicID = database.ref(`/users/${userData.user.uid}/clinics/`).push().key;
                                                updates[`/users/${userData.user.uid}/clinics/${clinicID}`] = {clinic_id: this.state.clinic.uid};
                                                if (!userSnap.val().selectedClinic) {
                                                    updates[`/users/${userData.user.uid}/selectedClinic`] = clinicID;
                                                }
                                                database.ref().update(updates).then(()=>{}).catch(error=>{})
                                            }


                                        }

                                        if (success.signIn.ban === "ban") {
                                            window.confirm("Your account is banned, please contact the administration for further assistance");
                                        } else {
                                            this.setState({loggedIn: true});
                                            this.setURL();

                                        }

                                    }
                                });
                        }
                    })
                })
        } else {
            this.setState({
                showError: true,
                errorMessage: "Please fill all the reqired fields", isloginwait: false
            })
        }


    }

    registerWithSocial = (user) => {
        this.setState({socialUser: user});
        var filled = Boolean(this.state.gender) && Boolean(this.state.first_name) && Boolean(this.state.last_name) && Boolean(this.state.email_address) && Boolean(this.state.create_password) && Boolean(this.state.dob) && this.state.dob !== "1900-01-01" && Boolean(this.state.health_insurance_no);
        if (filled) {
            this.setState({isloginwait: true});
            if (this.state.terms_checks) {
                this.setState({showError: false, errorMessage: ''})
                var root = 'https://admin.yourdoctors.online/api/0.1/';
                fetch(root + `admin_users/getuser?email=${encodeURIComponent(user._user.email)}`, {
                    method: "get",
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(success => {
                        if (Boolean(success.user)) {
                            this.fetchIp(ip => {
                                let ipaddr = '';
                                if (ip) {
                                    ipaddr = ip.ip;
                                }
                                var data = {
                                    "signUp": {
                                        "email": user._user.email,
                                        "password": user._user.uid,
                                        "age": moment(this.state.dob).format("MMMM DD YYYY"),
                                        "gender": this.state.saveGender,
                                        "notReferralCode": true,
                                        "opentok_session_id": null,
                                        "password_confirmation": "",
                                        "phone_number": "+1",
                                        "phone_number_country": "Canada",
                                        "referral_code": "",
                                        "role": "patient",
                                        "device_id": 123,
                                        "subscription_model_paid": false,
                                        "subscription_model_purchased": false,
                                        "token": null,
                                        "platform": "web",
                                        "ban": 'no',
                                        "ip": ipaddr,
                                        "locationEnabled": false,
                                        "country": null,
                                        "latitude": null,
                                        "longitude": null,
                                        "firstname": this.state.first_name + " " + this.state.last_name
                                    }
                                };
                                fetch(root + 'users/sign_up', {
                                    method: 'post',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify(data)
                                })
                                    .then(response => response.json())
                                    .then((success) => {
                                        if (success.status === true && success.status !== undefined) {
                                            var userData = user;
                                            let offset = (new Date()).getTimezoneOffset();
                                            offset = offset ? offset : 0;
                                            offset = (offset * -1) / 60;
                                            let firebaseData = {
                                                "email": user._user.email,
                                                "platform": "web",
                                                "ban": 'no',
                                                'gender': this.state.saveGender,
                                                'DOB': moment(this.state.dob).format("MMMM DD YYYY"),
                                                /* "device_id": uniqueId, */
                                                "user_id": success.signUp.user.id,
                                                "current_sign_at_ip": success.signUp.user.current_sign_at_ip,
                                                "last_sign_at_ip": success.signUp.user.last_sign_at_ip,
                                                "userName": this.state.first_name + " " + this.state.last_name,
                                                "healthInsuranceCardNumber": this.state.health_insurance_no,
                                                "isUserVerified": true,
                                                "gmtOffset": offset
                                            };
                                            this.setState({userId: userData.user.uid});
                                            database.ref().child('users').child(userData.user.uid).set(firebaseData)
                                                .then(() => {
                                                    if (this.state.clinic_patient_registry_key) {
                                                        var updates = {};
                                                        updates[`/clinicalPatients/${this.state.clinic.uid}/${this.state.clinic_patient_registry_key}/registrationStatus`] = "inviteaccepted";
                                                        updates[`/clinicalPatients/${this.state.clinic.uid}/${this.state.clinic_patient_registry_key}/userId`] = userData.user.uid;
                                                        if (userData._profile) {
                                                            if (userData._profile.email) updates[`/clinicalPatients/${this.state.clinic.uid}/${this.state.clinic_patient_registry_key}/email`] = userData._profile.email;
                                                            if (userData._profile.firstName) updates[`/clinicalPatients/${this.state.clinic.uid}/${this.state.clinic_patient_registry_key}/firstName`] = userData._profile.firstName;
                                                            if (userData._profile.lastName) updates[`/clinicalPatients/${this.state.clinic.uid}/${this.state.clinic_patient_registry_key}/lastName`] = userData._profile.lastName;
                                                        }
                                                        database.ref().update(updates).then(()=>{}).catch(error=>{})
                                                    } else {

                                                        let clinic_patient_registry_key = database.ref(`/users/${userData.user.uid}/clinics/`).push().key;
                                                        var updates = {};
                                                        updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/registrationStatus`] = "inviteaccepted";
                                                        updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/userId`] = userData.user.uid;
                                                        if (userData._profile) {
                                                            if (userData._profile.email) updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/email`] = userData._profile.email;
                                                            if (userData._profile.firstName) updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/firstName`] = userData._profile.firstName;
                                                            if (userData._profile.lastName) updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/lastName`] = userData._profile.lastName;
                                                        } else {
                                                            if (userData._profile.email) updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/email`] = user._user.email;
                                                            if (userData._profile.firstName) updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/firstName`] = this.state.first_name;
                                                            if (userData._profile.lastName) updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/lastName`] = this.state.last_name;

                                                        }
                                                        updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/dob`] = moment(this.state.dob).format('YYYY-MM-DD');
                                                        updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/gender`] = this.state.saveGender;
                                                        updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/insuranceCardNo`] = this.state.health_insurance_no;
                                                        updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/timestamp`] = (new Date()).getTime();
                                                        database.ref().update(updates).then(()=>{}).catch(error=>{})
                                                    }
                                                    let clinicID = database.ref(`/users/${userData.user.uid}/clinics/`).push().key;
                                                    var updates1 = {};
                                                    updates1[`/users/${userData.user.uid}/clinics/${clinicID}`] = {clinic_id: this.state.clinic.uid};
                                                    updates1[`/users/${userData.user.uid}/selectedClinic`] = clinicID;
                                                    database.ref().update(updates).then(()=>{}).catch(error=>{})

                                                    if (!this.state.clinic_patient_registry_key) {
                                                        var self = this;
                                                        this.setState({
                                                            signedIn: true,
                                                            isloginwait: false,
                                                            isLoginWaitFix: false,
                                                            registered: true,
                                                            alreadyVerified: true
                                                        });
                                                        this.setURL();
                                                        /* if (!(userData.user.emailVerified)) {
                                                            auth.currentUser.sendEmailVerification()
                                                                .then(() =>{
                                                                    self.setState({showVerifyEmail:true});
                                                                    self.checkEmailVerification();
                                                                })
                                                        }else{
                                                            this.setState({signedIn:true, isloginwait: false, isLoginWaitFix:false});
                                                        } */
                                                    } else {
                                                        this.setState({
                                                            registered: true,
                                                            isloginwait: false,
                                                            isLoginWaitFix: false
                                                        });
                                                    }
                                                })
                                        }
                                    })

                            })
                        } else {
                            var data = JSON.stringify({
                                "signIn": {
                                    "email": this.state.email_address,
                                    "password": this.state.create_password,
                                }
                            });
                            var root = 'https://admin.yourdoctors.online/api/0.1/';
                            fetch(root + 'sessions/sign_in_facebook', {
                                method: 'post',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: data
                            })
                                .then(response => response.json())
                                .then((success) => {
                                    var userData = user;

                                    if (success.status === true && success.status !== undefined) {
                                        database.ref(`/users/${userData.user.uid}`).once('value', userSnap => {
                                            if (userSnap.val() && userSnap.val().ban === "yes") {
                                                window.confirm("Your account is banned, please contact the administration for further assistance");
                                            } else {
                                                var gender = (success.signIn.gender) ? success.signIn.gender : this.state.saveGender;
                                                if (this.state.clinic_patient_registry_key) {
                                                    var updates = {};
                                                    var username = (success.signIn.firstname.indexOf("patient") !== -1 && success.signIn.firstname.indexOf("Patient")) ? success.signIn.firstname : this.state.first_name + " " + this.state.last_name;
                                                    var age = (success.signIn.age) ? success.signIn.age : moment(this.state.dob).format("MMMM DD YYYY");
                                                    let date = new Date();
                                                    let offset = date.getTimezoneOffset();
                                                    offset = offset ? offset : 0;
                                                    offset = (offset * -1) / 60;
                                                    updates[`/users/${userData.user.uid}/gmtOffset`] = offset;
                                                    updates[`/clinicalPatients/${this.state.clinic.uid}/${this.state.clinic_patient_registry_key}/registrationStatus`] = "inviteaccepted";
                                                    updates[`/clinicalPatients/${this.state.clinic.uid}/${this.state.clinic_patient_registry_key}/userId`] = userData.user.uid;
                                                    if (userData._profile) {
                                                        if (userData._profile.email) updates[`/clinicalPatients/${this.state.clinic.uid}/${this.state.clinic_patient_registry_key}/email`] = userData._profile.email;
                                                        if (userData._profile.firstName) updates[`/clinicalPatients/${this.state.clinic.uid}/${this.state.clinic_patient_registry_key}/firstName`] = userData._profile.firstName;
                                                        if (userData._profile.lastName) updates[`/clinicalPatients/${this.state.clinic.uid}/${this.state.clinic_patient_registry_key}/lastName`] = userData._profile.lastName;
                                                    }
                                                    updates[`/users/${userData.user.uid}/userName`] = username;
                                                    updates[`/users/${userData.user.uid}/current_sign_at_ip`] = success.signIn.current_sign_at_ip;
                                                    updates[`/users/${userData.user.uid}/last_sign_at_ip`] = success.signIn.last_sign_at_ip;
                                                    updates[`/users/${userData.user.uid}/DOB`] = age;
                                                    updates[`/users/${userData.user.uid}/gender`] = gender;
                                                    if (this.state.health_insurance_no) updates[`/users/${userData.user.uid}/healthInsuranceCardNumber`] = this.state.health_insurance_no;

                                                    let clinicFound = false;
                                                    const rawClinics = (userSnap.val().clinics) ? _.map(userSnap.val().clinics, (val, uid) => {
                                                        if (Boolean(val))
                                                            return {...val, uid};
                                                    }) : null;
                                                    var clinics = (rawClinics) ? rawClinics.filter(val => {
                                                        if (Boolean(val)) return val;
                                                    }) : [];

                                                    for (let i = 0; i < clinics.length; i++) {
                                                        if (clinics[i].clinic_id === this.state.clinic.uid)
                                                            clinicFound = true;
                                                    }

                                                    if (!clinicFound) {
                                                        let clinicID = database.ref(`/users/${userData.user.uid}/clinics/`).push().key;
                                                        updates[`/users/${userData.user.uid}/clinics/${clinicID}`] = {clinic_id: this.state.clinic.uid};
                                                        if (!userSnap.val().selectedClinic) {
                                                            updates[`/users/${userData.user.uid}/selectedClinic`] = clinicID;
                                                        }
                                                    }

                                                    database.ref().update(updates).then(()=>{}).catch(error=>{})


                                                } else {

                                                    let clinic_patient_registry_key = database.ref(`/users/${userData.user.uid}/clinics/`).push().key;
                                                    if (userData._profile) {
                                                        if (userData._profile.email) updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/email`] = userData._profile.email;
                                                        if (userData._profile.firstName) updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/firstName`] = userData._profile.firstName;
                                                        if (userData._profile.lastName) updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/lastName`] = userData._profile.lastName;
                                                    } else {
                                                        let firstname = (this.state.first_name) ? this.state.first_name : success.signIn.firstname.substr(0, success.signIn.firstname.indexOf(" ") > -1 ? success.signIn.firstname.indexOf(" ") : success.signIn.firstname.length);
                                                        let last_name = (this.state.last_name) ? this.state.last_name : success.signIn.firstname.substr(success.signIn.firstname.indexOf(" ") > -1 ? success.signIn.firstname.indexOf(" ") + 1 : 0, success.signIn.firstname.length);

                                                        updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/email`] = this.state.email_address;
                                                        updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/firstName`] = firstname;
                                                        updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/lastName`] = (last_name) ? last_name : '';
                                                    }

                                                    updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/dob`] = moment(new Date(age)).format('YYYY-MM-DD');
                                                    updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/gender`] = gender;
                                                    if (this.state.health_insurance_no) updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/insuranceCardNo`] = this.state.health_insurance_no;
                                                    updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/timestamp`] = (new Date()).getTime();
                                                    updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/registrationStatus`] = "inviteaccepted";
                                                    updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/userId`] = userData.user.uid;

                                                    let clinicID = database.ref(`/users/${userData.user.uid}/clinics/`).push().key;
                                                    updates[`/users/${userData.user.uid}/current_sign_at_ip`] = success.signIn.current_sign_at_ip;
                                                    updates[`/users/${userData.user.uid}/last_sign_at_ip`] = success.signIn.last_sign_at_ip;
                                                    updates[`/users/${userData.user.uid}/clinics/${clinicID}`] = {clinic_id: this.state.clinic.uid};
                                                    if (!userSnap.val().selectedClinic) {
                                                        updates[`/users/${userData.user.uid}/selectedClinic`] = clinicID;
                                                    }
                                                    database.ref().update(updates).then(()=>{}).catch(error=>{})
                                                }

                                                if (success.signIn.ban === "ban") {
                                                    window.confirm("Your account is banned, please contact the administration for further assistance");
                                                } else {
                                                    this.setState({
                                                        loggedIn: true,
                                                        isloginwait: false,
                                                        isLoginWaitFix: false
                                                    });
                                                    this.setURL();
                                                }
                                            }
                                        })

                                    }
                                });
                        }
                    })

            } else {
                this.setState({showError: true, errorMessage: "Please accept terms and conditions."})
            }
        } else {
            this.setState({
                showError: true,
                errorMessage: "Please fill all the reqired fields", isLoginWaitFix: true, isloginwait: false
            });
        }
    }
    registerUser = () => {
        var filled = Boolean(this.state.gender) && Boolean(this.state.first_name) && Boolean(this.state.last_name) && Boolean(this.state.email_address) && Boolean(this.state.create_password) && Boolean(this.state.dob) && this.state.dob !== "1900-01-01" && Boolean(this.state.health_insurance_no);
        if (filled) {
            if (this.state.terms_checks) {
                this.setState({showError: false, errorMessage: '', isloginwait: true})

                this.fetchIp(ip => {
                    let ipaddr = '';
                    if (ip) {
                        ipaddr = ip.ip;
                    }
                    var data = {
                        "signUp": {
                            "email": this.state.email_address,
                            "password": this.state.create_password,
                            "age": moment(this.state.dob).format("MMMM DD YYYY"),
                            "gender": this.state.saveGender,
                            "notReferralCode": true,
                            "opentok_session_id": null,
                            "password_confirmation": "",
                            "phone_number": "+1",
                            "phone_number_country": "Canada",
                            "referral_code": "",
                            "role": "patient",
                            "device_id": 123,
                            "subscription_model_paid": false,
                            "subscription_model_purchased": false,
                            "token": null,
                            "platform": "web",
                            "ban": 'no',
                            "ip": ipaddr,
                            "locationEnabled": false,
                            "country": null,
                            "latitude": null,
                            "longitude": null,
                            "firstname": this.state.first_name + " " + this.state.last_name
                        }
                    };
                    var root = 'https://admin.yourdoctors.online/api/0.1/';
                    fetch(root + 'users/sign_up', {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    })
                        .then(response => response.json())
                        .then((success) => {
                            if (success.status === true && success.status !== undefined) {
                                auth.createUserWithEmailAndPassword(this.state.email_address, this.state.create_password)
                                    .then(user => {
                                        var userData = user;
                                        let offset = (new Date()).getTimezoneOffset();
                                        offset = offset ? offset : 0;
                                        offset = (offset * -1) / 60;
                                        let firebaseData = {
                                            "email": this.state.email_address,
                                            "platform": "web",
                                            "ban": 'no',
                                            'gender': this.state.saveGender,
                                            'DOB': moment(this.state.dob).format("MMMM DD YYYY"),
                                            /* "device_id": uniqueId, */
                                            "user_id": success.signUp.user.id,
                                            "current_sign_at_ip": success.signUp.user.current_sign_at_ip,
                                            "last_sign_at_ip": success.signUp.user.last_sign_at_ip,
                                            "userName": this.state.first_name + " " + this.state.last_name,
                                            "healthInsuranceCardNumber": this.state.health_insurance_no,
                                            "isUserVerified": true,
                                            "gmtOffset": offset
                                        };
                                        this.setState({userId: userData.user.uid});
                                        database.ref().child('users').child(userData.user.uid).set(firebaseData)
                                            .then(() => {
                                                if (this.state.clinic_patient_registry_key) {
                                                    let updates = {};
                                                    updates[`/clinicalPatients/${this.state.clinic.uid}/${this.state.clinic_patient_registry_key}/registrationStatus`] = "inviteaccepted";
                                                    updates[`/clinicalPatients/${this.state.clinic.uid}/${this.state.clinic_patient_registry_key}/userId`] = userData.user.uid;
                                                    database.ref().update(updates);
                                                } else {
                                                    let updates = {};
                                                    let clinic_patient_registry_key = database.ref(`/users/${userData.user.uid}/clinics/`).push().key;
                                                    updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/dob`] = moment(this.state.dob).format('YYYY-MM-DD');
                                                    updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/email`] = this.state.email_address;
                                                    updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/firstName`] = this.state.first_name;
                                                    updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/gender`] = this.state.saveGender;
                                                    if (this.state.health_insurance_no) updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/insuranceCardNo`] = this.state.health_insurance_no;
                                                    updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/lastName`] = this.state.last_name;
                                                    updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/timestamp`] = (new Date()).getTime();
                                                    updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/registrationStatus`] = "inviteaccepted";
                                                    updates[`/clinicalPatients/${this.state.clinic.uid}/${clinic_patient_registry_key}/userId`] = userData.user.uid;
                                                    database.ref().update(updates).then(()=>{}).catch(error=>{})
                                                }
                                                let clinicID = database.ref(`/users/${userData.user.uid}/clinics/`).push().key;
                                                var updates1 = {};
                                                updates1[`/users/${userData.user.uid}/clinics/${clinicID}`] = {clinic_id: this.state.clinic.uid};
                                                updates1[`/users/${userData.user.uid}/selectedClinic`] = clinicID;
                                                database.ref().update(updates1).then(()=>{}).catch(error=>{})

                                                if (!this.state.clinic_patient_registry_key) {
                                                    var self = this;
                                                    this.setState({
                                                        signedIn: true,
                                                        isloginwait: false,
                                                        isLoginWaitFix: false,
                                                        registered: true,
                                                        alreadyVerified: true
                                                    });
                                                    this.setURL();
                                                    /* if (!(userData.user.emailVerified)) {
                                                        auth.currentUser.sendEmailVerification()
                                                            .then(() =>{
                                                                self.setState({showVerifyEmail:true});
                                                                self.checkEmailVerification();
                                                            })
                                                    }else{
                                                        this.setState({signedIn:true,isloginwait: false, isLoginWaitFix:false});
                                                    } */

                                                } else {
                                                    this.setState({
                                                        registered: true,
                                                        isloginwait: false,
                                                        isLoginWaitFix: false
                                                    });
                                                }
                                            })
                                    })
                                    .catch((error) => {
                                        console.log("signupUser fail called Inner: ", error.message);
                                        this.setState({
                                            showError: true,
                                            errorMessage: error.message,
                                            isloginwait: false
                                        })
                                    });
                            } else {
                                this.setState({
                                    showError: true,
                                    errorMessage: "email already exist",
                                    isloginwait: false
                                })
                            }
                        })
                        .catch(error => {
                            this.setState({showError: true, errorMessage: error.message, isloginwait: false})
                        })

                })

            } else {
                this.setState({showError: true, errorMessage: "Please accept terms and conditions."})
            }
        } else {
            this.setState({
                showError: true,
                errorMessage: "Please fill all the reqired fields"
            });
        }
    }
    genderChange = (text) => {
        this.setState({gender: (text) ? text.toLowerCase() : '', saveGender: text});
        /* var input = text.toLowerCase();
        if((this.state.gender === "male" || this.state.gender === "female") && (input === "mal" || input === "femal")){
            this.setState({gender:"",saveGender:""});
        }
        if(input === "m" || input === "ma" || input === "mal" || input === "male" || input.indexOf("male") !== -1){
            this.setState({gender:"male",saveGender:"Male"});
        }else if(input === "f" || input === "fe" || input === "fem" || input === "fema" || input === "femal" || input === "female" || input.indexOf("female") !== -1){
            this.setState({gender:"female",saveGender:"Female"});
        } */
    }
    checkEmailVerification = () => {
        var current = auth.currentUser
        current.reload()
        if (auth.currentUser.emailVerified) {
            clearInterval(this.state.timer)
            this.setState({showVerifyEmail: false, signedIn: true, registered: true, alreadyVerified: true});
            this.setURL();
        } else {
            clearInterval(this.state.timer)
            this.state.timer = setInterval(() => {
                this.checkEmailVerification();
            }, 2000)
        }
    }

    didntGetEmail = () => {
        var self = this
        auth.currentUser.sendEmailVerification()
            .then(function () {
                // Verification email sent.
                //  alert("Email has been sent")
                self.setState({sent: true, notSent: false})

            })
            .catch(function (error) {
                console.log("Error from resending email", error)
                self.setState({sent: false, notSent: true})

                // Error occurred. Inspect error.code.
            });

    }

    render() {
        return (
            <div className={classes.mainFont}>
                {this.state.loading && <div className={classes.spinner}>
                    <div className={classes.loading_loader}/>
                </div>}
                {!this.state.loading && !this.state.isAlreadyRegistered && !this.state.showVerifyEmail && <div>
                    <div className={classes.top_bar_reg}>
                        <img src={Logo} className={classes.logo} alt="logo"/>
                    </div>
                    <div className={classes.mainRegistration}>
                        <div className={classes.Auth}>
                            {!this.state.signedIn &&
                            <span className={classes.regHeaderText}>Register yourself with {this.state.clinic_name} to begin a virtual consult</span>}
                            {!this.state.registered && !this.state.signedIn &&
                            <div>
                                <div className={classes.regForm}>
                                    {!this.state.clinic_patient_registry_key &&
                                    <div className={classes.regFormRowTop}>
                                        <div className={classes.regFormInputFirst} style={{fontSize: 28}}>
                                            {/* <FloatingLabelInput
                                                    id="first_name"
                                                    label="First Name"
                                                    refs={this.first_name_input}

                                                    value={this.state.first_name}
                                                    onChange={(e)=>{
                                                        this.setState({first_name:e.target.value})
                                                    }}
                                                    disabled={this.state.disableFields}
                                                    className={classes.disableBorderBottom}
                                                    /> */}
                                            <OverlayTrigger
                                                key={'left'}
                                                placement={'left'}
                                                overlay={
                                                    <Tooltip id={`tooltip-left`}>First Name</Tooltip>
                                                }
                                            >
                                                <input
                                                    id="first_name"
                                                    label="First Name"
                                                    refs={this.first_name_input}
                                                    value={this.state.first_name}
                                                    disabled={this.state.disableFields}
                                                    className={classes.Input}
                                                    placeholder='First Name'
                                                    type='text'
                                                    onChange={(e) => {
                                                        this.setState({first_name: e.target.value})
                                                    }}
                                                />
                                            </OverlayTrigger>

                                            {/* <IoMdCloseCircle
                                                    size={15}
                                                    color={'gray'}
                                                    className={classes.crossicon}
                                                    onClick={() => {this.setState({first_name:''})}}
                                                /> */}
                                        </div>
                                        <div className={classes.regFormInput}>
                                            {/* <FloatingLabelInput
                                                    id="last_name"
                                                    label="Last Name"
                                                    refs={this.last_name_input}

                                                    value={this.state.last_name}
                                                    disabled={this.state.disableFields}
                                                    onChange={(e)=>{
                                                        this.setState({last_name:e.target.value})
                                                    }}
                                                    className={classes.disableBorderBottom}
                                                    />
                                                    <IoMdCloseCircle
                                                    size={15}
                                                    color={'gray'}
                                                    className={classes.crossicon}
                                                    onClick={() => {this.setState({last_name:''})}}
                                                    /> */}
                                            <OverlayTrigger
                                                key={'left'}
                                                placement={'left'}
                                                overlay={
                                                    <Tooltip id={`tooltip-left`}>Last Name</Tooltip>
                                                }
                                            >
                                                <input
                                                    id="last_name"
                                                    label="Last Name"
                                                    refs={this.last_name_input}
                                                    value={this.state.last_name}
                                                    disabled={this.state.disableFields}
                                                    className={classes.Input}
                                                    placeholder='Last Name'
                                                    type='text'
                                                    onChange={(e) => {
                                                        this.setState({last_name: e.target.value})
                                                    }}
                                                />
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    }
                                    <div className={classes.regFormRow} style={{fontSize: 28}}>
                                        {/* <FloatingLabelInput
                                            id="email_address"
                                            label="Email address"

                                            refs={this.email_address_input}
                                            value={this.state.email_address}
                                            disabled={this.state.disableFields}
                                            onChange={(e)=>{
                                                this.setState({email_address:e.target.value})
                                            }}
                                            className={classes.disableBorderBottom}
                                            />
                                            <IoMdCloseCircle
                                                size={15}
                                                color={'gray'}
                                                className={classes.crossicon}
                                                onClick={() => {this.setState({email_address:''})}}
                                                /> */}
                                        <OverlayTrigger
                                            key={'left'}
                                            placement={'left'}
                                            overlay={
                                                <Tooltip id={`tooltip-left`}>Email address</Tooltip>
                                            }
                                        >
                                            <input
                                                id="email_address"
                                                label="Email address"
                                                refs={this.email_address_input}
                                                value={this.state.email_address}
                                                disabled={this.state.disableFields}
                                                onChange={(e) => {
                                                    this.setState({email_address: e.target.value})
                                                }}
                                                className={classes.Input}
                                                placeholder='Email address'
                                                type='text'

                                            />
                                        </OverlayTrigger>
                                    </div>
                                    <div className={classes.regFormRow} style={{fontSize: 28}}>
                                        {/* <FloatingLabelInput
                                            id="create_password"
                                            label="Create password"

                                            type="password"
                                            value={this.state.create_password}
                                            onChange={(e)=>{
                                                this.setState({create_password:e.target.value})
                                            }}
                                            className={classes.disableBorderBottom}
                                            />
                                        <IoMdCloseCircle
                                            size={15}
                                            color={'gray'}
                                            className={classes.crossicon}
                                            onClick={() => {this.setState({email_address:''})}}
                                        /> */}
                                        <OverlayTrigger
                                            key={'left'}
                                            placement={'left'}
                                            overlay={
                                                <Tooltip id={`tooltip-left`}>Create password</Tooltip>
                                            }
                                        >
                                            <input
                                                id="create_password"
                                                label="Create password"
                                                type="password"
                                                value={this.state.create_password}
                                                onChange={(e) => {
                                                    this.setState({create_password: e.target.value})
                                                }}
                                                className={classes.Input}
                                                placeholder='Create password'


                                            />
                                        </OverlayTrigger>
                                    </div>
                                    {!this.state.clinic_patient_registry_key && <div className={classes.regFormRow}>
                                        {/* <div style={{ fontSize: 28, width:"100%"}}>
                                            <FloatingLabelInput
                                                id="dob"
                                                label="Date of Birth"

                                                refs={this.dob_input}
                                                type="date"
                                                value={this.state.dob}
                                                onChange={(e)=>{
                                                    this.setState({dob:e.target.value})
                                                }}
                                                className={classes.disableBorderBottom}
                                            />
                                        </div>
                                        <IoMdCloseCircle
                                            size={15}
                                            color={'gray'}
                                            className={classes.crossicon}
                                            onClick={() => {this.setState({dob:''})}}
                                        /> */}
                                        <OverlayTrigger
                                            key={'left'}
                                            placement={'left'}
                                            overlay={
                                                <Tooltip id={`tooltip-left`}>Date of Birth Format: YYYY-MM-DD</Tooltip>
                                            }
                                        >
                                            <input
                                                id="dob"
                                                label="Date of Birth"
                                                refs={this.dob_input}
                                                type="date"
                                                value={this.state.dob}
                                                onChange={(e) => {
                                                    this.setState({dob: e.target.value})
                                                }}
                                                className={classes.Input}
                                                placeholder='Date of Birth'

                                            />
                                        </OverlayTrigger>
                                    </div>}
                                    {!this.state.clinic_patient_registry_key &&
                                    <div className={classes.regFormRow}
                                         style={{fontSize: 28, textDecoration: 'none', width: "50%"}}>
                                        {/* <FloatingLabelInput
                                            id="gender"
                                            label="Gender"
                                            refs={this.gender_input}
                                            onChange={(e)=>this.genderChange(e.target.value)}
                                            value={this.state.gender}
                                            disabled={this.state.disableFields}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 8) {
                                                    this.setState({gender:"",saveGender:""})
                                                }
                                            }}
                                            className={classes.disableBorderBottom}
                                        />
                                        <IoMdCloseCircle
                                            size={15}
                                            color={'gray'}
                                            className={classes.crossicon}
                                            onClick={() => {this.setState({gender:'',saveGender:''})}}
                                        /> */}
                                        <OverlayTrigger
                                            key={'left'}
                                            placement={'left'}
                                            overlay={
                                                <Tooltip id={`tooltip-left`}>Gender</Tooltip>
                                            }
                                        >

                                            <select /* className={classes.form_small_input} */
                                                id="gender"
                                                label="Gender"
                                                refs={this.gender_input}
                                                className={classes.inputSelect}
                                                style={{border: "none"}}
                                                placeholder='Gender'
                                                onChange={(e) => {
                                                    this.genderChange(e.target.value)
                                                }}
                                                defaultValue={'DEFAULT'}
                                            >
                                                <option value="DEFAULT" disabled hidden>Gender</option>
                                                <option key={1} value={'Male'}>Male</option>
                                                <option key={2} value={'Female'}>Female</option>

                                            </select>
                                            {/* <input
                                                id="gender"
                                                label="Gender"
                                                refs={this.gender_input}
                                                type="text"
                                                value={this.state.gender}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 8) {
                                                        this.setState({gender:"",saveGender:""})
                                                    }
                                                }}
                                                className={classes.Input}
                                                placeholder='Gender'

                                            /> */}
                                        </OverlayTrigger>
                                    </div>}
                                    {!this.state.clinic_patient_registry_key &&
                                    <div className={classes.regFormRow} style={{fontSize: 28}}>
                                        {/* <FloatingLabelInput
                                            id="health_insurance_no"
                                            label="Health Insurance #"
                                            value={this.state.health_insurance_no}
                                            onChange={(e)=>{
                                                this.setState({health_insurance_no:e.target.value})
                                            }}
                                            className={classes.disableBorderBottom}
                                        />
                                        <IoMdCloseCircle
                                            size={15}
                                            color={'gray'}
                                            className={classes.crossicon}
                                            onClick={() => {this.setState({health_insurance_no:''})}}
                                        /> */}
                                        <OverlayTrigger
                                            key={'left'}
                                            placement={'left'}
                                            overlay={
                                                <Tooltip id={`tooltip-left`}>Health Insurance #</Tooltip>
                                            }
                                        >
                                            <input
                                                id="health_insurance_no"
                                                label="Health Insurance #"
                                                type="text"
                                                value={this.state.health_insurance_no}
                                                onChange={(e) => {
                                                    this.setState({health_insurance_no: e.target.value})
                                                }}
                                                className={classes.Input}
                                                placeholder='Health Insurance #'

                                            />
                                        </OverlayTrigger>
                                    </div>}
                                    <div className={classes.regFormRowTerms} style={{
                                        fontSize: 18,
                                        marginTop: 14,
                                        alignItems: "center",
                                        justifyContent: "left"
                                    }}>
                                        <input type="radio" className={classes.checkboxStyle}
                                               checked={this.state.terms_checks} onClick={() => {
                                            this.setState({terms_checks: !this.state.terms_checks})
                                        }}/>
                                        <span>By registering, you agree to our <a
                                            href="https://yourdoctors.online/wp-content/uploads/2020/10/YDO_Terms_of_Use.pdf/"
                                            className={classes.termsButton} target="_blank" rel="noopener noreferrer">Terms and Conditions</a></span>
                                    </div>
                                    {this.state.showError &&
                                    <div className={classes.errorText}>{this.state.errorMessage}</div>}
                                    {!this.state.isloginwait &&
                                    <div className={classes.confirmButton} onClick={this.registerUser}>
                                        <img src={EmailIcon} className={classes.emaillogo} alt="email"/>Register with
                                        Email
                                    </div>}
                                    {/* !this.state.isloginwait &&  !this.state.isLoginWaitFix && <div>
                                        <div className={classes.confirmButton}>
                                            {this.state.terms_checks &&
                                                <SocialButton
                                                    provider='facebook'
                                                    appId='2502599029824607'
                                                    onLoginSuccess={this.handleSocialRegister}
                                                    onLoginFailure={this.handleSocialRegisterFailure}
                                                    scope="user_birthday,user_gender"
                                                    style={{backgroundColor: "unset",
                                                        color: "#894A9E",
                                                        border: 'none',

                                                    }}
                                                >
                                                    <img src={FbIcon} className={classes.fblogo} alt="facebook" />Register with Facebook
                                                </SocialButton>
                                            }
                                            {!this.state.terms_checks &&
                                                <div style={{backgroundColor: "unset",
                                                    color: "#894A9E",
                                                    border: 'none',}} onClick={()=>{this.setState({showError:true, errorMessage:"Please accept terms and conditions."})}}>
                                                    <img src={FbIcon} className={classes.fblogo} alt="facebook" />Register with Facebook
                                                </div>
                                            }
                                        </div>
                                        <div className={classes.confirmButton} >
                                            {this.state.terms_checks &&
                                                <SocialButton
                                                    provider='google'
                                                    appId={ENVIRONMENT === 'production'?'177934657566-nm6fu31o9tvj9cppmlmi3uvgb6b7rkrg.apps.googleusercontent.com':'280300232842-c8q0uvek5kdqj89jdlrlrav8ml9iktju.apps.googleusercontent.com'}
                                                    onLoginSuccess={this.handleSocialRegister}
                                                    onLoginFailure={this.handleSocialRegisterFailure}
                                                    scope={['https://www.googleapis.com/auth/user.birthday.read','https://www.googleapis.com/auth/user.gender.read']}
                                                    style={{backgroundColor: "unset",
                                                            color: "#894A9E",
                                                            border: 'none',
                                                        }}
                                                    >
                                                    <img src={GoogleIcon} className={classes.googlelogo} alt="google_signin" />Register with Google
                                                </SocialButton>
                                            }
                                            {!this.state.terms_checks &&
                                                <div style={{backgroundColor: "unset",
                                                    color: "#894A9E",
                                                    border: 'none',}} onClick={()=>{this.setState({showError:true, errorMessage:"Please accept terms and conditions."})}}>
                                                    <img src={GoogleIcon} className={classes.googlelogo} alt="google_signin" />Register with Google
                                                </div>
                                            }
                                        </div>
                                    </div> */}
                                    {this.state.isloginwait && !this.state.isLoginWaitFix &&
                                    <div className={classes.confirmButton}>
                                        <div className={classes.loading_loader_sm}/>
                                    </div>}
                                    {this.state.isLoginWaitFix && !this.state.isloginwait &&
                                    <div className={classes.confirmButton}
                                         onClick={() => this.handleSocialRegister(this.state.socialUser)}>
                                        Continue
                                    </div>}
                                    {this.state.showLogin &&
                                    <div className={classes.loginDiv}>or already registered? <span
                                        className={classes.loginButton} onClick={() => {
                                        this.setState({isAlreadyRegistered: true})
                                    }}>Login</span></div>}
                                </div>
                            </div>
                            }
                            {this.state.registered && !this.state.signedIn && !this.state.alreadyVerified &&
                            <div>
                                <span className={classes.subHeaderText2}>For privacy concerns, can you confirm the last 3 digits of your health insurance card</span>
                                <div className={classes.regForm}>
                                    <div>
                                        <span style={{
                                            fontSize: '17',
                                            color: '#6D7278',
                                            display: 'flex',
                                            paddingBottom: 15
                                        }}> Health Insurance #</span>
                                        <div style={{marginTop: '20'}}>
                                            <input type="text" maxlength="1"
                                                   value={this.state.firstdigit}
                                                   className={classes.inputDigits}
                                                   ref={this.ref0}
                                                   onChange={(e) => {
                                                       var val = e.target.value.replace(/[^0-9]/g, '');
                                                       this.setState({firstdigit: val});
                                                       if (val) this.ref1.current.focus()
                                                   }}/>
                                            <input type="text" maxlength="1"
                                                   value={this.state.seconddigit}
                                                   className={classes.inputDigits}

                                                   ref={this.ref1}
                                                   onChange={(e) => {
                                                       var val = e.target.value.replace(/[^0-9]/g, '');
                                                       this.setState({seconddigit: val});
                                                       if (val)
                                                           this.ref2.current.focus()
                                                   }}/>
                                            <input type="text" maxlength="1"
                                                   value={this.state.thirddigit}
                                                   className={classes.inputDigits}

                                                   ref={this.ref2}
                                                   onChange={(e) => {
                                                       var val = e.target.value.replace(/[^0-9]/g, '');
                                                       this.setState({thirddigit: val});
                                                       if (val) this.ref0.current.focus();

                                                   }}/>


                                        </div>
                                    </div>
                                    {this.state.showError &&
                                    <div className={classes.errorText}>{this.state.errorMessage}</div>}
                                    <div className={classes.confirmButtonAlt} onClick={this.confirmHealthVerification}>
                                        Continue
                                    </div>
                                    <div className={classes.confirmButton} onClick={() => {
                                        this.setState({signedIn: true});
                                        this.setURL();
                                    }}>
                                        Skip
                                    </div>
                                </div>
                            </div>
                            }
                            {this.state.signedIn && this.state.registered && <div>
                                <span
                                    className={classes.subHeaderText2}>You have successfully registered to {this.state.clinic_name}. Please click the button below to use our app.</span>
                                <a href={this.state.appURL} className={classes.confirmButton}>
                                    Continue
                                </a>
                            </div>}
                        </div>


                    </div>

                </div>}
                {!this.state.loading && this.state.isAlreadyRegistered && !this.state.showVerifyEmail &&
                <div>
                    <div className={classes.top_bar_reg}>
                        <img src={Logo} className={classes.logo} alt="logo"/>
                    </div>
                    <div className={classes.mainRegistration}>
                        <div className={classes.Auth}>
                            {!this.state.loggedIn &&
                            <span className={classes.regHeaderText}>Log into your account to begin a virtual consult with {this.state.clinic_name}</span>}
                            {!this.state.loggedIn && <div>
                                <div className={classes.regForm}>
                                    {/* <div className={classes.regFormRowTop}>
                                    <div className={classes.regFormInputFirst} style={{ fontSize: 28 }}>
                                                <FloatingLabelInput
                                                    id="first_name"
                                                    label="First Name"
                                                    refs={this.first_name_input}

                                                    value={this.state.first_name}
                                                    onChange={(e)=>{
                                                        this.setState({first_name:e.target.value})
                                                    }}
                                                    disabled={this.state.disableFields}
                                                    className={classes.disableBorderBottom}
                                                    />
                                                <IoMdCloseCircle
                                                    size={15}
                                                    color={'gray'}
                                                    className={classes.crossicon}
                                                    onClick={() => {this.setState({first_name:''})}}
                                                />
                                            </div>
                                            <div className={classes.regFormInput}>
                                                <FloatingLabelInput
                                                    id="last_name"
                                                    label="Last Name"
                                                    refs={this.last_name_input}

                                                    value={this.state.last_name}
                                                    disabled={this.state.disableFields}
                                                    onChange={(e)=>{
                                                        this.setState({last_name:e.target.value})
                                                    }}
                                                    className={classes.disableBorderBottom}
                                                    />
                                                    <IoMdCloseCircle
                                                    size={15}
                                                    color={'gray'}
                                                    className={classes.crossicon}
                                                    onClick={() => {this.setState({last_name:''})}}
                                                    />
                                            </div>
                                        </div>  */}
                                    <div className={classes.regFormRow} style={{fontSize: 28}}>
                                        {/* <FloatingLabelInput
                                                id="email_address"
                                                label="Email address"

                                                refs={this.email_address_input}
                                                value={this.state.email_address}
                                                disabled={this.state.disableFields}
                                                onChange={(e)=>{
                                                    this.setState({email_address:e.target.value})
                                                }}
                                                className={classes.disableBorderBottom}
                                                />
                                                {!this.state.disableFields &&<IoMdCloseCircle
                                                    size={15}
                                                    color={'gray'}
                                                    className={classes.crossicon}
                                                    onClick={() => {this.setState({email_address:''})}}
                                                    />} */}
                                        <OverlayTrigger
                                            key={'left'}
                                            placement={'left'}
                                            overlay={
                                                <Tooltip id={`tooltip-left`}>Email address</Tooltip>
                                            }
                                        >
                                            <input
                                                id="email_address"
                                                label="Email address"
                                                type="text"
                                                refs={this.email_address_input}
                                                value={this.state.email_address}
                                                onChange={(e) => {
                                                    this.setState({email_address: e.target.value})
                                                }}
                                                className={classes.Input}
                                                placeholder='Email address'

                                            />
                                        </OverlayTrigger>
                                    </div>
                                    <div className={classes.regFormRow} style={{fontSize: 28}}>
                                        {/* <FloatingLabelInput
                                                id="create_password"
                                                label="Enter your password"

                                                type="password"
                                                value={this.state.create_password}
                                                onChange={(e)=>{
                                                    this.setState({create_password:e.target.value})
                                                }}
                                                className={classes.disableBorderBottom}
                                                />
                                            <IoMdCloseCircle
                                                size={15}
                                                color={'gray'}
                                                className={classes.crossicon}
                                                onClick={() => {this.setState({email_address:''})}}
                                            /> */}
                                        <OverlayTrigger
                                            key={'left'}
                                            placement={'left'}
                                            overlay={
                                                <Tooltip id={`tooltip-left`}>Enter your password</Tooltip>
                                            }
                                        >
                                            <input
                                                id="create_password"
                                                label="Enter your password"
                                                type="password"
                                                value={this.state.create_password}
                                                onChange={(e) => {
                                                    this.setState({create_password: e.target.value})
                                                }}
                                                className={classes.Input}
                                                placeholder='Enter your password'

                                            />
                                        </OverlayTrigger>
                                    </div>

                                    {this.state.showError &&
                                    <div className={classes.errorText}>{this.state.errorMessage}</div>}
                                    {!this.state.isloginwait &&
                                    <div className={classes.confirmButton} onClick={this.loginAccount}>
                                        <img src={EmailIcon} className={classes.emaillogo} alt="email"/>Login with Email
                                    </div>
                                    }

                                    {this.state.isloginwait && <div className={classes.confirmButton}>
                                        <div className={classes.loading_loader_sm}/>
                                    </div>}

                                </div>
                            </div>}
                            {this.state.loggedIn && <div>
                                <span
                                    className={classes.subHeaderText2}>You have successfully registered to {this.state.clinic_name}. Please click the button below to use our app.</span>
                                <a href={this.state.appURL} className={classes.confirmButton}>
                                    Continue
                                </a>
                            </div>}
                        </div>


                    </div>

                </div>
                }

                {!this.state.loading && this.state.showVerifyEmail && <div>
                    <div className={classes.top_bar_reg}>
                        <img src={Logo} className={classes.logo} alt="logo"/>
                    </div>
                    <div className={classes.mainRegistration}>
                        <div className={classes.Auth}>
                            <span className={classes.regHeaderText} style={{textAlign: "center"}}>Verifying Email</span>
                            <span className={classes.subHeaderText2}>A verifying email has been sent to you. Please open the link within the email to verify your account. Make sure to check your junk folder and set the email as "not spam".</span>
                            <span className={classes.confirmButton} onClick={() => this.didntGetEmail}>
                                    I DID NOT RECEIVE EMAIL
                                </span>
                            {this.state.sent &&
                            <span style={classes.sentEmailText}>Verification email sent again</span>
                            }
                            {this.state.notSent &&
                            <span style={classes.sentEmailText}>Can't send verification email. Please try again in a few minutes</span>
                            }
                        </div>
                    </div>
                </div>}
            </div>
        );
    }
}


const mapStateToProps = ({loginAuth}) => {
    const {loading, error} = loginAuth;
    return {loading, error};
};

export default connect(mapStateToProps, {loginUser})(ClinicalPatientRegistrationBuilder);
