import  {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import classes from './profileModal.module.css';
import Aux from '../../../hoc/hocAux';
import {logoutUser} from '../../../actions/LoginAction';
import {changeAcceptStatus} from '../../../actions/ConversationAction';
import Spinner from 'react-bootstrap/Spinner';
import Switch from "react-switch";

const image = require('../../../assets/download.png');
const qrCode = require('../../../assets/bright-hr-qr-code.png');

class ProfileModal extends Component {

    constructor(props) {
        super(props);
        this.state = {checked: false};
        this.handleChange = this.handleChange.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.show !== this.props.show ||
            nextProps.children !== this.props.children ||
            nextProps.acceptingChat !== this.props.acceptingChat ||
            nextProps.doctor_loading !== this.props.doctor_loading
    }

    onLogoutClick = () => {
        window.localStorage.setItem('logged_in', false)
        this.props.logoutUser();
    }

    handleChange() {
        if (this.props.acceptingChat === false) {
            this.props.changeAcceptStatus(null, true, null, () => {
            });
        } else {
            this.props.changeAcceptStatus(null, false, null, () => {
            });
        }
    }

    render() {
        return (
            <Aux>
                <div className={classes.ModalDoctor}
                     style={{
                         transform: this.props.show ? 'translateX(0)' : 'translateX(-50vh)',
                         opacity: this.props.show ? '1' : '0'
                     }}>
                    <div className={classes.main_view}>
                        <div className={classes.icon_image_view}>
                            <img
                                className={classes.icon_image}
                                src={this.props.profile_data.profileImageURL !== undefined ? this.props.profile_data.profileImageURL : image}
                                alt=""
                            />
                            <div className={classes.profile_detail_view}>
                                <h3>{this.props.profile_data.doctorName !== undefined && this.props.profile_data.doctorName !== null ? this.props.profile_data.doctorName : 'Admin'}</h3>
                                {this.props.profile_data.role === 'Admin' && <span onClick={this.props.onViewProfileClick}>View profile</span>}
                            </div>

                        </div>
                        <div style={{display: "flex", justifyContent:"center", width: "100%"}}>
                            <img
                                src={qrCode}
                                alt="qr-code"
                                style={{width:80}}
                            />
                        </div>
                        {this.props.profile_data.role !== 'Admin' &&
                        <label className={classes.label_style}>
                            <span className={classes.span_style}>Accept Patients</span>
                            <Switch
                                onChange={this.handleChange}
                                checked={this.props.acceptingChat}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                            />
                        </label>
                        }
                        <div className={classes.border_bottom}/>
                        <div className={classes.logout_view}>
                            {!this.props.doctor_loading &&
                            <button className={classes.logout_button} onClick={() => this.onLogoutClick()}>
                                <div className={classes.btn_text}>Logout</div>
                            </button>
                            }
                            {this.props.doctor_loading &&
                            <div className={classes.spinner_view}>
                                <Spinner animation="border" size="sm" variant="light"/>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </Aux>
        )
    }
}

const mapStateToProps = ({profileReducer, acceptingReducer, loginAuth}) => {
    const {profile_data, uid} = profileReducer;
    const {acceptingChat} = acceptingReducer;
    const {doctor_loading} = loginAuth;

    return {doctor_loading, profile_data, uid, acceptingChat};
}

export default compose(withRouter, connect(mapStateToProps, {changeAcceptStatus, logoutUser}))(ProfileModal);

