
import { BarLoader, BeatLoader, ClipLoader, ClockLoader, FadeLoader, HashLoader	} from 'react-spinners'
import { PropTypes } from 'prop-types'

/**
 * types of spinners
 * https://www.davidhu.io/react-spinners/
 *
 * github
 * https://github.com/davidhu2000/react-spinners
 */

const CustomBarLoader = ({ color, size, isLoading, ...others }) => (
    <BarLoader color={color}  loading={isLoading} {...others} />
)

CustomBarLoader.propTypes = {
    color: PropTypes.string,
    size: PropTypes.string,
    isLoading: PropTypes.bool,
}

CustomBarLoader.defaultProps = {
    color: 'black',
    size: '1.5rem',
    isLoading: false,
}

export default CustomBarLoader
