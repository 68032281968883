
import classes from './SettingBuilder.module.css';
import Tags from './tags/Tags';
import CannedResponses from './cannedResponses/CannedResponses';
import BillingCodes from './billingCodes/BillingCodes';

const Header = (props) => {
    return(
        <div className={classes.list_header}>
            <div className={classes.msg_header}>
                <div className={classes.header_text}>{props.header}</div>
            </div>
        </div>
    )
}

const SettingDetail = (props) => {
    return(
        <div className={classes.main_body}>
            <div className={classes.main_block}>
                <div className={classes.msg_body}>
                    <Header header={props.header}/>
                </div>
                <div className={classes.list}>
                    <div className={classes.content_body}>
                        <div className={classes.detail_view}>
                            <div className={classes.main_detail_view}>
                                {props.header === 'Tags' &&
                                    <Tags
                                        tagsList={props.tagsList}
                                        onTagTextChange={(text) => props.onTagTextChange(text)}
                                        tag={props.tag}
                                        onSaveTag={(event) => props.onSaveTag(event)}
                                    />
                                }
                                {props.header === 'Canned Responses for Chats' &&
                                    <CannedResponses
                                        canned_responsesList={props.canned_responsesList_chats}
                                        description={props.description}
                                        short_cut={props.short_cut}
                                        selectedEditTag={props.selectedEditTag}
                                        selectedDeleteTag={props.selectedDeleteTag}
                                        selectedTagDesciption={props.selectedTagDesciption}
                                        selectedTagShortCut={props.selectedTagShortCut}
                                        onChangeCannedDescription={(event) => props.onChangeCannedDescription(event)}
                                        onChangeCannedShortCut={(event) => props.onChangeCannedShortCut(event)}
                                        onEditClick={(uid, desc, shortcut) => props.onEditClick(uid, desc, shortcut)}
                                        updateCannedResponse={(uid, desc, shortcut) => props.updateCannedResponse(uid, desc, shortcut)}
                                        updateCancelClick={() => props.updateCancelClick()}
                                        onDeleteClick={(uid) => props.onDeleteClick(uid)}
                                        confirmDeleteClick={(uid) => props.confirmDeleteClick(uid)}
                                        confirmCancelClick={() => props.confirmCancelClick()}
                                        onCannedResponseTextChange={(text) => props.onCannedResponseTextChange(text)}
                                        onCannedResponseShortcutChange={(text) => props.onCannedResponseShortcutChange(text)}
                                        onSaveCannedResponse={(event) => props.onSaveCannedResponse(event)}
                                    />
                                }
                                {props.header === 'Canned Responses for Objective Finding' &&
                                    <CannedResponses
                                        canned_responsesList={props.canned_responsesList}
                                        description={props.description}
                                        short_cut={props.short_cut}
                                        selectedEditTag={props.selectedEditTag}
                                        selectedDeleteTag={props.selectedDeleteTag}
                                        selectedTagDesciption={props.selectedTagDesciption}
                                        selectedTagShortCut={props.selectedTagShortCut}
                                        onChangeCannedDescription={(event) => props.onChangeCannedDescription(event)}
                                        onChangeCannedShortCut={(event) => props.onChangeCannedShortCut(event)}
                                        onEditClick={(uid, desc, shortcut) => props.onEditClick(uid, desc, shortcut)}
                                        updateCannedResponse={(uid, desc, shortcut) => props.updateCannedResponse(uid, desc, shortcut)}
                                        updateCancelClick={() => props.updateCancelClick()}
                                        onDeleteClick={(uid) => props.onDeleteClick(uid)}
                                        confirmDeleteClick={(uid) => props.confirmDeleteClick(uid)}
                                        confirmCancelClick={() => props.confirmCancelClick()}
                                        onCannedResponseTextChange={(text) => props.onCannedResponseTextChange(text)}
                                        onCannedResponseShortcutChange={(text) => props.onCannedResponseShortcutChange(text)}
                                        onSaveCannedResponse={(event) => props.onSaveCannedResponse(event)}
                                    />
                                }
                                {props.header === 'Canned Responses for Assessment' &&
                                    <CannedResponses
                                        canned_responsesList={props.canned_responsesList_assessment}
                                        description={props.description}
                                        short_cut={props.short_cut}
                                        selectedEditTag={props.selectedEditTag}
                                        selectedDeleteTag={props.selectedDeleteTag}
                                        selectedTagDesciption={props.selectedTagDesciption}
                                        selectedTagShortCut={props.selectedTagShortCut}
                                        onChangeCannedDescription={(event) => props.onChangeCannedDescription(event)}
                                        onChangeCannedShortCut={(event) => props.onChangeCannedShortCut(event)}
                                        onEditClick={(uid, desc, shortcut) => props.onEditClick(uid, desc, shortcut)}
                                        updateCannedResponse={(uid, desc, shortcut) => props.updateCannedResponse(uid, desc, shortcut)}
                                        updateCancelClick={() => props.updateCancelClick()}
                                        onDeleteClick={(uid) => props.onDeleteClick(uid)}
                                        confirmDeleteClick={(uid) => props.confirmDeleteClick(uid)}
                                        confirmCancelClick={() => props.confirmCancelClick()}
                                        onCannedResponseTextChange={(text) => props.onCannedResponseTextChange(text)}
                                        onCannedResponseShortcutChange={(text) => props.onCannedResponseShortcutChange(text)}
                                        onSaveCannedResponse={(event) => props.onSaveCannedResponse(event)}
                                    />
                                }
                                {props.header === 'Canned Responses for Plan' &&
                                    <CannedResponses
                                        canned_responsesList={props.canned_responsesList_plan}
                                        description={props.description}
                                        short_cut={props.short_cut}
                                        selectedEditTag={props.selectedEditTag}
                                        selectedDeleteTag={props.selectedDeleteTag}
                                        selectedTagDesciption={props.selectedTagDesciption}
                                        selectedTagShortCut={props.selectedTagShortCut}
                                        onChangeCannedDescription={(event) => props.onChangeCannedDescription(event)}
                                        onChangeCannedShortCut={(event) => props.onChangeCannedShortCut(event)}
                                        onEditClick={(uid, desc, shortcut) => props.onEditClick(uid, desc, shortcut)}
                                        updateCannedResponse={(uid, desc, shortcut) => props.updateCannedResponse(uid, desc, shortcut)}
                                        updateCancelClick={() => props.updateCancelClick()}
                                        onDeleteClick={(uid) => props.onDeleteClick(uid)}
                                        confirmDeleteClick={(uid) => props.confirmDeleteClick(uid)}
                                        confirmCancelClick={() => props.confirmCancelClick()}
                                        onCannedResponseTextChange={(text) => props.onCannedResponseTextChange(text)}
                                        onCannedResponseShortcutChange={(text) => props.onCannedResponseShortcutChange(text)}
                                        onSaveCannedResponse={(event) => props.onSaveCannedResponse(event)}
                                    />
                                }
                                {props.header === 'Billing Codes' &&
                                    <BillingCodes
                                        billingCodesList={props.billingCodesList}
                                        billing_code={props.billing_code}
                                        billing_code_desc={props.billing_code_desc}
                                        selectedEditTag={props.selectedEditTag}
                                        selectedDeleteTag={props.selectedDeleteTag}
                                        selectedTagDesciption={props.selectedTagDesciption}
                                        selectedTagShortCut={props.selectedTagShortCut}
                                        onBillingCodeTextChange={(text) => props.onBillingCodeTextChange(text)}
                                        onBillingCodeDescriptionChange={(text) => props.onBillingCodeDescriptionChange(text)}
                                        onSaveBillingCode={(event) => props.onSaveBillingCode(event)}
                                        onEditClick={(uid, desc, shortcut) => props.onEditClick(uid, desc, shortcut)}
                                        onDeleteClick={(uid) => props.onDeleteClick(uid)}
                                        onChangeCannedShortCut={(event) => props.onChangeCannedShortCut(event)}
                                        onChangeCannedDescription={(event) => props.onChangeCannedDescription(event)}
                                        updateCannedResponse={(uid, desc, shortcut) => props.updateCannedResponse(uid, desc, shortcut)}
                                        updateCancelClick={() => props.updateCancelClick()}
                                        confirmDeleteClick={(uid) => props.confirmDeleteClick(uid)}
                                        confirmCancelClick={() => props.confirmCancelClick()}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingDetail;
