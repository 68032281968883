import {Component, createRef, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import csc from 'country-state-city';
import Switch from "react-switch";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Radio from '@material-ui/core/Radio';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Calendar from '../../../components/customCalendar/calendar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';

import Items from './Items';
import AcceptChatFooter from '../../../components/AcceptChatFooter/AcceptChat'

import Aux from '../../../hoc/hocAux';
import Header from '../../../components/UI/Header/Header'
import classes from '../../../assets/classes.module.css';
import styles from "../encounters/encounters.module.css"
import _classes from '../../../assets/_classes.module.css';

import {
    fetchClinicalChats,
    fetchClinicalConversationCount,
    refreshClinicalConversations
} from '../../../actions/ClinicalChatsAction';
import {
    ClinicAddress,
    ClinicalCity,
    ClinicalPostalCode,
    ClinicCountry,
    ClinicEmailAddress,
    ClinicFax,
    ClinicLicense,
    ClinicName,
    ClinicPhone,
    ClinicPhysicianUser,
    ClinicProvince,
    ClinicReportEmailAddress,
    FullName,
    getClinicDataByID,
    listenClinicData,
    setUpClinicProfile,
    showClinicAdminProfileImage,
    showClinicAdminSignature
} from '../../../actions/clinic/create/CreateClinicAction';
import {changeAcceptStatus, checkStatusAccept, getAcceptStatus} from '../../../actions/ConversationAction';

import {refreshMessages} from '../../../actions/ChatAction';
import {logoutClinicMember} from '../../../actions/ClinicStaffAction';
import {forgotPassword, resetForgotPasswordStates} from '../../../actions/LoginAction';


import Admin from './admin';
import Staff from './staff';
import InviteStaff from './invite-staff';
import CannedResponses from './cannedresponses';
import BillingCodes from './billingcodes';
import InvitePatient from './invitePatient';
import Reports from './reports';
import {FaAngleRight, FaFileMedical, FaPlus, FaUsersCog} from 'react-icons/fa'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import isMobileView from '../../../utils/isMobileView';

var moment = require("moment");


window.onbeforeunload = function () {
    window.history.forward()
    return true
}


function ListItem(props) {
    let iconName = ''
    switch (props.label) {
        case 'Patient Consultations':
            iconName = <FaPlus style={{justifySelf: 'flex-start'}} color="#884a9d" size={22}/>
            break;
        case 'Patient Archives':
            iconName = <FaFileMedical style={{justifySelf: 'flex-start'}} color="#884a9d" size={22}/>
            break;
        case 'Profile Settings':
            iconName = <FaUsersCog style={{justifySelf: 'flex-start'}} color="#884a9d" size={25}/>
            break;
        default:

            iconName = <FaUsersCog style={{justifySelf: 'flex-start'}} color="#884a9d" size={25}/>
            break;
    }
    return (
        <div className={_classes.list} onClick={() => {
            props.onClick()
        }}>
            {iconName}
            <p style={{
                fontSize: '12px',
                alignSelf: 'left',
                display: 'flex',
                flex: 1,
                textAlign: 'left',
                marginLeft: '20px'
            }}>{props.label}</p>
            <FaAngleRight style={{justifySelf: 'flex-start'}} color="#884a9d" size={22}/></div>
    );
}

function MyVerticallyCenteredModal(props) {

    return (
        <Modal
            {...props}
            size="lg"
            dialogClassName={classes.modal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header bsPrefix={classes.modalHeader} closeButton>
                <Modal.Title id="contained-modal-title-vcenter" bsPrefix={classes.modalHeader}>
                    Password Reset
                </Modal.Title>
            </Modal.Header>
            <Modal.Body bsPrefix={classes.modalBody}>
                <p>
                    A password reset email has been sent to you. Please open the link within
                    the email to reset the password of your account. Make sure to check your
                    junk folder and set the email as “not spam”.
                </p>
            </Modal.Body>
            {!props.loader &&
            <Modal.Footer bsPrefix={classes.modalFooter}>
                <Button bsPrefix={classes.button} onClick={props.onClose}>Continue</Button>
                <Button bsPrefix={classes.white_button} onClick={props.onContinue}>I did not receive email</Button>
            </Modal.Footer>
            }
            {props.loader &&
            <Modal.Footer bsPrefix={classes.modalFooter}>
                <Button bsPrefix={classes.button} onClick={props.onClose}>Continue</Button>
                <Button bsPrefix={classes.white_button} onClick={props.onContinue}>I did not receive email</Button>
            </Modal.Footer>
            }
            {props.msg !== '' &&
            <Modal.Footer bsPrefix={classes.modalFooter}>
                <p>{props.msg} “I did not receive email”.</p>
            </Modal.Footer>
            }
            {props.err !== '' &&
            <Modal.Footer bsPrefix={classes.modalFooter}>
                <p>{props.err}</p>
            </Modal.Footer>
            }
        </Modal>
    );
}

function InputItem(props) {
    return (
        <div style={{display: 'flex', flexDirection: 'column', margin: '10px 0px'}}><label
            className={_classes.inputItemLabel}>{props.label}</label><input className={_classes.inputItem}
                                                                            placeholder={props.placeholder}
                                                                            value={props.value || ''}
                                                                            onChange={(text) => props.onChange(text.target.value)}></input>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    icon: {
        borderRadius: '50%',
        width: 20,
        height: 20,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        }
    },
    checkedIcon: {
        backgroundColor: '#894A9E',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        }
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 100,
    }
}));

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

function WorkingHours(props) {
    const localStyles = useStyles();

    const [selectedValue, setSelectedValue] = useState();
    const [resetDate, setresetDate] = useState(false);
    const [resetDateValue, setresetDateValue] = useState(moment());
    const [startDate, setStartDate] = useState(moment());
    const [selectedTime, setselectedTime] = useState('');
    const [timeSlots, setTimeSlots] = useState([]);
    const [showCalender, setShowCalender] = useState(false);

    const handleChange = (event) => {
        var hours = event.target.value;
        if (hours == 1 || hours == 3 || hours == 12) {
            setSelectedValue(event.target.value);
            setShowCalender(false);
            setStartDate(moment(new Date().getTime() + (hours * 60 * 60 * 1000)));
            setselectedTime(new Date().getTime() + (hours * 60 * 60 * 1000));
        }
    };

    useEffect(() => {
        var startDateTime = new Date(startDate);
        startDateTime.setHours(0, 0, 0, 0);
        startDateTime = startDateTime.getTime();

        var endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 1);
        endDate.setHours(0, 0, 0, 0);
        endDate = endDate.getTime();

        var slots1 = [];
        var endDuration = startDateTime + 10 * 60000;
        while (endDate >= endDuration) {
            let slot = {
                startTime: startDateTime,
            }
            startDateTime = endDuration;
            endDuration = startDateTime + 10 * 60000;
            slots1.push(slot);
        }

        setTimeSlots(slots1);

    }, [startDate]);

    const slots = timeSlots.length > 0 ? timeSlots.map(slot => {
        return (
            <div
                key={'slot_' + slot.startTime}
                className={selectedTime === slot.startTime ? styles.selectedTimeSlotsSectionButton : styles.timeSlotsSectionButton}
                onClick={() => {
                    setselectedTime(slot.startTime);
                    setStartDate(moment(slot.startTime));
                }}
            >{moment(slot.startTime).format("h:mm a")}</div>
        )
    }) : null;

    return (
        <Dialog
            open={props.show}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            // maxWidth='xs'
        >
            <DialogTitle className={styles.modal_header_text}> Accepting Patients </DialogTitle>
            <DialogContent className={styles.modal_content}>
                <div className={styles.middle_container}>
                    <span className={styles.modal_header_heading}>Accepting Patients for</span>
                    <div>
                        <Radio
                            checked={selectedValue === '1'}
                            onChange={handleChange}
                            value="1"
                            checkedIcon={<span className={clsx(localStyles.icon, localStyles.checkedIcon)}/>}
                            icon={<span className={localStyles.icon}/>}
                            {...props}
                        />
                        <span className={styles.span_text}>1 hour</span>
                        <Radio
                            checked={selectedValue === '3'}
                            onChange={handleChange}
                            value="3"
                            checkedIcon={<span className={clsx(localStyles.icon, localStyles.checkedIcon)}/>}
                            icon={<span className={localStyles.icon}/>}
                            {...props}

                        />
                        <span className={styles.span_text}>3 hour</span>
                        <Radio
                            checked={selectedValue === '12'}
                            onChange={handleChange}
                            value="12"
                            checkedIcon={<span className={clsx(localStyles.icon, localStyles.checkedIcon)}/>}
                            icon={<span className={localStyles.icon}/>}
                            {...props}
                        />
                        <span className={styles.span_text}>12 hour</span>
                    </div>
                    <div className={styles.time_container}>
                        <span className={styles.span_heading_text}>or until</span>
                        <span
                            className={styles.time_field}
                            onClick={() => {
                                setShowCalender(true)
                                setSelectedValue();
                                setStartDate(moment(new Date().getTime()));
                            }}
                        >{startDate.format('MMMM DD YYYY, h:mm a')}</span>
                    </div>
                    {showCalender &&
                    <div className={styles.time_container}>
                        <div className={styles.calenderSection}>
                            <Calendar
                                resetDate={resetDate}
                                resetDateValue={resetDateValue}
                                resetUpdate={() => {
                                    setresetDate(true)
                                }}
                                shouldHighlightWeekends
                                dateChanged={(date) => {
                                    setStartDate(date)
                                }}
                            />
                        </div>
                        {true &&
                        <div className={styles.timeSlotsSection}>
                            <span className={styles.timeSlotDate}>{startDate.format('dddd, MMMM DD')}</span>
                            <div className={styles.timeslotsScroll}>
                                {slots}
                            </div>
                        </div>
                        }
                    </div>
                    }
                </div>
                <div className={styles.bottom_view}>
                    <button className={styles.button} onClick={() => props.onApply(selectedTime)}>Apply</button>
                    <button className={styles.white_button} onClick={() => props.onHide()}>Cancel</button>
                </div>
            </DialogContent>
        </Dialog>
    );
}

class ClinicalHomeAdmin extends Component {

    constructor(props) {
        super(props);
        this.textInput = createRef();
        this.signatureFile = createRef();
        this.state = {
            file: '',
            signature_file: '',
            showChangePasswordModal: false,
            selectedItem: isMobileView() ? "" : 'profile',
            profile_value: 'Profile',
            countries: {'options': csc.getAllCountries()},
            provinces: {'options': csc.getStatesOfCountry("38")},
            settingsVisible: false,
            fullName: props.full_name,
            licenseNo: props.license_no || '',
            address: props.address || '',
            city: props.city || '',
            country: props.country || '',
            province: props.province || '',
            phone: props.phone || '',
            billingNo: props.billingNo || '',
            profileImage: props.profile_image || '',
            signature: props.signature || '',
            showWorkingHoursModal: false,
            resetEmailFlag: true
        }
    }

    async componentWillMount() {
        await this.props.getClinicDataByID((clinicID) => {
            if (clinicID) {
                this.props.getAcceptStatus(clinicID);
                this.props.fetchClinicalChats(clinicID, false);
                this.props.refreshMessages();//
                this.props.refreshClinicalConversations();
                this.props.fetchClinicalChats(clinicID, false);
                this.props.fetchClinicalConversationCount(clinicID);
                this.props.listenClinicData(clinicID);
            }

        })


    }

    async componentDidMount() {
        setTimeout(() => {
            const {staffData} = this.props;
            if (this.props?.staffData?.accepting_chat_timestamp) {
                this.onAcceptingChatTimeEnding(staffData.accepting_chat_timestamp);
            }
        }, 2000);
        this.onAcceptingChatTimeEnding(this.props.acceptingChatTime);

        if (isMobileView()) {
            window.onbeforeunload = function () {
                // window.history.forward()
                return "Leaving this page will reset the wizard";
            };


        }
        const {props} = this
        this.acceptingChatInput = createRef()
        this.setState({
            fullName: props.full_name || '',
            licenseNo: props.license_no || '',
            address: props.address || '',
            city: props.city || '',
            country: props.country || '',
            province: props.province || '',
            phone: props.phone || '',
            billingNo: props.billingNo || '',
            profileImage: props.profile_image || '',
            signature: props.signature || ''
        })
    }

    handleResetEmailFlag = () => {
        if (this.state.showChangePasswordModal === true && this.state.resetEmailFlag === true) {
            this.sendResetEmail();
            this.setState({resetEmailFlag: false});
        } else if (this.state.showChangePasswordModal === false && this.state.resetEmailFlag === false) {
            this.setState({resetEmailFlag: true});
        }
    }

    selectSideBarItem = (item) => {
        this.setState({selectedItem: item});
    }

    onChangePassword = (type) => {
        this.setState({showChangePasswordModal: !type});
        this.props.resetForgotPasswordStates();
    }

    sendResetEmail = () => {
        const {staffData} = this.props;
        var email = staffData && staffData.staffEmail ? staffData.staffEmail : staffData.email;
        this.props.forgotPassword(email);
    }
    handleSwitch = () => {
        const {acceptingChat, clinicId} = this.props;
        var self = this;
        if (acceptingChat === false) {
            this.setState({showWorkingHoursModal: true});
        } else {
            this.props.changeAcceptStatus(clinicId, false, null, () => {
                self.setState({showWorkingHoursModal: false});
            });
        }
    }
    _handleSwitch = (val) => {
        const {acceptingChat, clinicId} = this.props;
        var self = this;
        if (acceptingChat === false) {
            this.setState({_showWorkingHoursModal: true});
        } else {
            this.props.changeAcceptStatus(clinicId, false, null, () => {
                self.setState({_showWorkingHoursModal: false});
            });
        }


    }

    onAcceptingChatTimeEnding = (datetime) => {
        const {acceptingChat, clinicId} = this.props;
        var self = this;
        if (acceptingChat === true) {
            let d = new Date();
            let currTime = d.getTime();
            let testingTime = datetime - (currTime + 3570000); // to check functionality hardcoded value of 10 or 20 seconds
            let closeChatTime = datetime - (currTime - 20000);
            if (closeChatTime > 0) {
                setTimeout(() => {
                    //this code runs in case of toggling off the 'Accept Chats' Switch || else part of funciton 'handleSwitch'
                    this.props.changeAcceptStatus(clinicId, false, null, () => {
                        self.setState({showWorkingHoursModal: false});
                        // setTimeout(() => {
                        //     // logout function
                        //     this.onLogoutClick();
                        // }, 3000); //gets logout after 3 sec
                    });
                }, closeChatTime);
            } else {
                //this code runs in case of toggling off the 'Accept Chats' Switch || else part of funciton 'handleSwitch'
                this.props.changeAcceptStatus(clinicId, false, null, () => {
                    self.setState({showWorkingHoursModal: false});
                    // setTimeout(() => {
                    //     // logout function
                    //     this.onLogoutClick();
                    // }, 3000); //gets logout after 3 sec
                });
            }
        }
    }

    onHideWorkingHoursModal = (type) => {
        this.setState({showWorkingHoursModal: type});
    }

    onApplyWorkingHours = (datetime) => {
        const {acceptingChat, clinicId} = this.props;
        var self = this;
        if (acceptingChat === false) {
            this.props.changeAcceptStatus(clinicId, true, datetime, () => {
                self.setState({showWorkingHoursModal: false});
            });
            setTimeout(() => {
                this.onAcceptingChatTimeEnding(datetime);
            }, 500);
        } else {
            this.props.changeAcceptStatus(clinicId, false, null, () => {
                self.setState({showWorkingHoursModal: false});
            });
        }
    }
    _onApplyWorkingHours = (datetime) => {
        const {acceptingChat, clinicId} = this.props;
        var self = this;

        this.props.changeAcceptStatus(clinicId, true, datetime, () => {
            self.setState({_showWorkingHoursModal: false});
        });

    }
    viewStaffMembers = () => {
        const path = {
            pathname: '/clinic_staff',
            search: '',
            hash: '',
        }
        this.props.history.push(path);
    }

    viewPatients = () => {
        const path = {
            pathname: '/clinic_patients',
            search: '',
            hash: '',
        }
        this.props.history.push(path);
    }
    setValue = (property, value) => {
        this.setState({[property]: value})
    }
    onLogoutClick = () => {
        window.localStorage.setItem('logged_in', false);
        const {staffData, clinicId} = this.props;
        this.props.logoutClinicMember(clinicId, staffData.uid);
    }

    renderInput(props, openCalendar, closeCalendar) {
        function clear() {
            props.onChange({target: {value: ''}});
        }

        return (
            <div>
                <input {...props} />
                <button onClick={openCalendar}>open calendar</button>
                <button onClick={closeCalendar}>close calendar</button>
                <button onClick={clear}>clear</button>
            </div>
        );
    }


    render() {

        const myconversation = this.props.clinical_conversations?.filter(mychat => {
            return (!mychat.chatInQueue && mychat.ownerChat && mychat.ownerClinics && mychat.appActivated)
        });
        const openconversation = this.props.clinical_conversations?.filter(openchat => {
            return (!openchat.chatInQueue && !openchat.ownerChat && openchat.ownerClinics && openchat.appActivated
            )
        });
        const queuedconversation = this.props.clinical_conversations?.filter(queuechat => {
            return (queuechat.chatInQueue && queuechat.appActivated)
        });
        const inwaitconversation = this.props.clinical_conversations?.filter(awaitingresponsechat => {
            return (awaitingresponsechat.ownerClinics && !awaitingresponsechat.appActivated)
        });

        const {
            staffData, clinic_name, full_name,
            loading, message, physician_user, userType, userProfile, loader, msg, err, acceptingChat, acceptingChatTime
        } = this.props;
        let {selectedItem, countries, provinces, showChangePasswordModal} = this.state;
        const showChat = true;
        this.handleResetEmailFlag();
        return isMobileView() ? (
            <Aux style={{heigth: "100%", "background-color": "#F1F2F6"}}>
                <Header
                    title={Boolean(this.state.selectedItem) ? "Profile Settings" : "Dashboard"}
                    back={
                        Boolean(this.state.selectedItem)
                            ? {onClick: () => this.selectSideBarItem("")}
                            : null
                    }
                    style={{}}
                ></Header>
                <div
                    className={_classes.main}
                    style={{
                        height: `${window.innerHeight}-95px`,
                        paddingBottom: "100px",
                        "padding-top": "65px",
                    }}
                >
                    {
                        <>
                            <div className={_classes.sub_header}>
                                <span className={_classes.subHeaderText}>Hi, {full_name}!</span>
                            </div>
                            <div className={_classes.listContainer} style={{overflow: "scroll"}}>
                                <ListItem
                                    label="Patient Consultations"
                                    onClick={() => this.props.history.push("clinic_encounter")}
                                ></ListItem>

                                <ListItem
                                    label="Patient Archives"
                                    onClick={() => this.props.history.push("clinic_archives")}
                                ></ListItem>
                                <ListItem
                                    label="Profile Settings"
                                    onClick={() => {
                                        this.selectSideBarItem("profile");
                                    }}
                                ></ListItem>

                                {/* {listItem({ label: 'Patient Consultations' })}
                            {listItem({ label: 'Patient Archives' })}
                            {listItem({ label: 'Profile Settings' })} */}
                            </div>
                            <div
                                style={{
                                    bottom: "100px",
                                    left: "0px",
                                    right: "0px",
                                    position: "absolute",
                                    "justify-content": "center",
                                    "align-items": "center",
                                    /* width: 100%, */
                                    "align-content": "center",
                                    "justify-items": "center",
                                    "flex-direction": "column",
                                    display: "flex",
                                    backgroundColor: "#f1f2f6",
                                }}
                            >
                                {/* <button className={_classes.button} style={{ width: '70%', 'align-self': 'center' }} onClick={() => this.refs.datetime.navigate()}>open calendar</button>
                         <Datetime ref="datetime"
                          open={false} /> */}
                                {/* <Datetime  inputProps={{ disabled: true,}}></Datetime> */}
                                {/* <input ref={ this.acceptingChatInput } type="datetime-local" style={{
                          background: 'transparent',
                          bottom: '0',
                          color: 'transparent',
                          cursor:' pointer',
                          height: 'auto',
                          left: '0',
                          position: 'absolute',
                          right: '0',
                          top: '0',
                          width: 'auto'}}  open={Boolean(this.state.openCal)} /> */}
                                {/* <div> You are currently seeing {'1'} patient</div> */}
                                {Boolean(myconversation.length) ? (
                                    <>
                                        {" "}
                                        <div style={{fontSize: "12px"}}>
                                            {" "}
                                            You are currently seeing {myconversation.length} patients.
                                        </div>
                                        <button
                                            className={_classes.button}
                                            style={{width: "70%", "align-self": "center"}}
                                            onClick={() => {
                                                this.props.history.push("clinic_encounter");
                                            }}
                                        >
                                            Back to Patient
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        {!acceptingChat ? (
                                            <>
                                                {" "}
                                                <div style={{fontSize: "12px"}}>
                                                    {" "}
                                                    You are currently not accepting any patients
                                                </div>
                                                <button
                                                    className={_classes.button}
                                                    style={{width: "70%", "align-self": "center"}}
                                                    onClick={this.submitHandler}
                                                >
                                                    Begin Seeing Patients
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                {!Boolean(queuedconversation.length) ? (
                                                    <div style={{fontSize: "12px"}}>
                                                        {" "}
                                                        There are currently no patients in your queue.
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div style={{fontSize: "12px"}}>
                                                            {" "}
                                                            You currently have {queuedconversation.length} patient
                                                            consult in
                                                            the queue.
                                                        </div>
                                                        <button
                                                            className={_classes.button}
                                                            style={{width: "70%", "align-self": "center"}}
                                                            onClick={() => {
                                                                this.acceptingChatInput.current.click(true);
                                                            }}
                                                        >
                                                            Begin Consultation
                                                        </button>
                                                        {" "}
                                                    </>
                                                )}

                                                {/* <input ref={this.acceptingChatInput} type="datetime-local" onChange={() => { }} open={true} /> */}
                                                {/* <button className={_classes.button} style={{ width: '70%', 'align-self': 'center' }} onClick={() => { this.acceptingChatInput.current.click(true) }}>Begin Consultation</button> */}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            {/* <div style={{position:'absolute',left:0,top:70,right:0,height:'-webkit-fill-available',zIndex:10}}> */}
                            {selectedItem === "profile" && userType === "admin" && (
                                <Admin
                                    style={{
                                        position: "absolute",
                                        left: 0,
                                        top: 70,
                                        right: 0,
                                        height: "-webkit-fill-available",
                                        zIndex: 10,
                                    }}
                                    onChangePassword={() => this.onChangePassword(showChangePasswordModal)}
                                />
                            )}
                            {selectedItem === "profile" && userType !== "admin" && (
                                <Staff
                                    style={{
                                        position: "absolute",
                                        left: 0,
                                        top: 70,
                                        right: 0,
                                        height: "-webkit-fill-available",
                                        zIndex: 10,
                                    }}
                                    onChangePassword={() => this.onChangePassword(showChangePasswordModal)}
                                />
                            )}
                            {/* </div> */}
                        </>
                    }
                    <AcceptChatFooter></AcceptChatFooter>

                    {/* {userType === 'admin' &&
                   <div className={classes.header}>
                       <span className={classes.headerText}>Your trial has started</span>
                       <button className={classes.button}>Pricing coming soon</button>
                   </div>
               }
                   <div className={classes.sub_header}>
                   <span className={classes.subHeaderText}>Welcome {full_name} to {clinic_name}</span>
               </div>
               <div className={classes.form_container}>
                   <div className={classes.form_header}>Get ready to start seeing patients. Try these tips to get started</div>
                   <div className={classes.form_content}>
                       <Items
                           active={selectedItem}
                           userType={userType}
                           userProfile={userProfile}
                           staffData={staffData}
                           ItemSelected={(item) => this.selectSideBarItem(item)}
                       />
                       {selectedItem === 'profile' && userType === 'admin' &&
                           <Admin onChangePassword={() => this.onChangePassword(showChangePasswordModal)} />
                       }
                       {selectedItem === 'profile' && userType !== 'admin' &&
                           <Staff onChangePassword={() => this.onChangePassword(showChangePasswordModal)} />
                       }

                       {selectedItem === "staff" &&
                           <InviteStaff onViewStaffMembers = {() => this.viewStaffMembers()} />
                       }
                       {selectedItem === "cannedresponses" &&
                           <CannedResponses />
                       }
                       {selectedItem === "billingcodes" &&
                           <BillingCodes />
                       }
                       {selectedItem === "invitepatients" &&
                           <InvitePatient viewPatients = {() => this.viewPatients()}/>
                       }
                       {selectedItem === "reports" &&
                           <Reports />
                       }
                   </div>
               </div>
            */}
                    {!Boolean(acceptingChat) && Boolean(this.state._showWorkingHoursModal) && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: window.innerWidth,
                                height: window.innerHeight,
                                alignContent: "center",
                                justifyContent: "center",
                                backgroundColor: "rgba(52, 52, 52, 0.8)",
                                position: "fixed",
                                top: 0,
                                zIndex: "10",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "300px",
                                    height: "200px",
                                    alignContent: "center",
                                    justifyContent: "space-evenly",
                                    top: "100px",
                                    backgroundColor: "#fff",
                                    borderRadius: "5px",
                                    alignSelf: "center",
                                    justifySelf: "center",
                                    padding: "10px",
                                }}
                            >
                                <div style={{textAlign: "center", color: "#884a9d"}}>
                                    Please select a date
                                </div>
                                <span
                                    className={_classes.subHeaderText}
                                    style={{fontSize: "12px", fontWeight: "normal", alignContent: "center"}}
                                >
                      You want to accept chats until
                                    {!Boolean(this.state.acceptingChatTime)
                                        ? "[select date]"
                                        : this.state.acceptingChatTime}
                                    <input
                                        ref={this.acceptingChatInput}
                                        type="datetime-local"
                                        onChange={e => {
                                            this.setState({acceptingChatTime: e.target.value});
                                        }}
                                        defaultValue={""}
                                        value={acceptingChatTime}
                                        style={{height: "10px"}}
                                    />
                    </span>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-around",
                                        justifySelf: "end",
                                        width: "100%",
                                    }}
                                >
                                    <button
                                        style={{
                                            width: "100px",
                                            height: "40px",
                                            border: "1px solid #884a9d",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff",
                                            color: "#884a9d",
                                            fontSize: "14px",
                                        }}
                                        color="#884a9d"
                                        title="Cancel"
                                        onClick={() => {
                                            this.setState({
                                                _showWorkingHoursModal: false,
                                            });
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        style={{
                                            width: "100px",
                                            height: "40px",
                                            backgroundColor: "#fff",
                                            borderRadius: "5px",
                                            backgroundColor: "#884a9d",
                                            border: "1px",
                                            color: "#fff",
                                            fontSize: "14px",
                                        }}
                                        title="Confirm"
                                        onClick={() => {
                                            this._onApplyWorkingHours(this.state.acceptingChatTime);
                                            this.setState({
                                                _showWorkingHoursModal: false,
                                            });
                                        }}
                                    >
                                        DONE
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {/* <MyVerticallyCenteredModal
                    loader={loader.toString()}
                    msg={msg}
                    err={err}
                    show={showChangePasswordModal}
                    onHide={() => this.onChangePassword(showChangePasswordModal)}
                    onContinue={() => this.sendResetEmail()}
                /> */}
            </Aux>
        ) : (
            <Aux>
                <div className={classes.main}>
                    {
                        userType === "admin" /*  &&
                        <div className={classes.header}>
                            <span className={classes.headerText}>Your trial has started</span>
                            <button className={classes.button}>Pricing coming soon</button>
                        </div> */
                    }
                    <div className={classes.headerWhite}>
                <span className={classes.subHeaderText}>
                  Welcome {full_name} to {clinic_name}
                </span>
                        {/* -------duplicated encounter banner--------- */}
                        <div className={styles.top_bar}>
                            <div className={styles.header_status}>
                                Status:
                                {acceptingChat && <span> Accepting Patients</span>}
                                {!acceptingChat && (
                                    <span className={styles.notAcceptingChat}> Not Accepting Patients</span>
                                )}
                                {(this.props.doctorIsValid || this.props.admin) && showChat && (
                                    <div className={styles.header_close} onClick={() => this.closeChat()}>
                                        <span>End Encounter</span>
                                    </div>
                                )}
                            </div>
                            <div className={styles.header_accept_chat_status}>
                                <span>Accept Patients:</span>
                                <Switch
                                    onChange={() => this.handleSwitch()}
                                    checked={acceptingChat}
                                    onColor="#884a9d"
                                    onHandleColor="#884a9d"
                                    handleDiameter={20}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="react-switch"
                                />
                                {acceptingChatTime !== null && (
                                    <span className={styles.date_span}>
                        {moment(acceptingChatTime).format("MMMM DD YYYY, h:mm a")}
                      </span>
                                )}
                            </div>
                        </div>
                        <WorkingHours
                            show={this.state.showWorkingHoursModal}
                            onHide={() => this.onHideWorkingHoursModal(!this.state.showWorkingHoursModal)}
                            onApply={(datetime) => this.onApplyWorkingHours(datetime)}
                        />
                        {/* ---------------- */}
                    </div>
                    <div className={classes.form_container}>
                        <div className={classes.form_header}>
                            Always keep your profile picture and signature updated. Click&#160;
                            <Link to="/clinic_encounter" style={{display: 'contents'}}>encouters </Link>&#160;to start
                            seeing the patients.
                        </div>
                        <div className={classes.form_content}>
                            <Items
                                active={selectedItem}
                                userType={userType}
                                userProfile={userProfile}
                                staffData={staffData}
                                ItemSelected={item => this.selectSideBarItem(item)}
                            />
                            {selectedItem === "profile" && userType === "admin" && (
                                <Admin
                                    onChangePassword={() => this.onChangePassword(showChangePasswordModal)}
                                />
                            )}
                            {selectedItem === "profile" && userType !== "admin" && (
                                <Staff
                                    onChangePassword={() => this.onChangePassword(showChangePasswordModal)}
                                />
                            )}
                            {selectedItem === "staff" && (
                                <InviteStaff onViewStaffMembers={() => this.viewStaffMembers()}/>
                            )}
                            {selectedItem === "cannedresponses" && <CannedResponses/>}
                            {selectedItem === "billingcodes" && <BillingCodes/>}
                            {selectedItem === "invitepatients" && (
                                <InvitePatient viewPatients={() => this.viewPatients()}/>
                            )}
                            {selectedItem === "reports" && <Reports/>}
                        </div>
                    </div>
                </div>

                <MyVerticallyCenteredModal
                    loader={loader.toString()}
                    msg={msg}
                    err={err}
                    show={showChangePasswordModal}
                    onHide={() => this.onChangePassword(showChangePasswordModal)}
                    onContinue={() => this.sendResetEmail()}
                    onClose={() => this.setState({showChangePasswordModal: !showChangePasswordModal})}
                />

            </Aux>
        );
    }
}

const mapStateToProps = ({
                             clinicProfileSettingReducer,
                             forgotPasswordReducer,
                             clinicalChatsReducer,
                             acceptingReducer
                         }) => {
    const {
        staffData, clinic_name, full_name,
        userType, userProfile, clinicId, address, city, province, country, phone, fax, license_no,
        profile_image, signature
    } = clinicProfileSettingReducer;
    const {clinical_conversations} = clinicalChatsReducer;
    const {acceptingChat, acceptingChatTime, changedDoctorKey} = acceptingReducer;
    const {loader, msg, err} = forgotPasswordReducer;
    return {
        staffData,
        clinic_name,
        full_name,
        clinicId,
        address,
        city,
        province,
        country,
        phone,
        fax,
        license_no,
        profile_image,
        signature,
        userType,
        userProfile,
        loader,
        msg,
        err,
        clinical_conversations,
        acceptingChat,
        acceptingChatTime,
        changedDoctorKey
    };
};

export default connect(mapStateToProps, {
    forgotPassword,
    resetForgotPasswordStates,
    getClinicDataByID,
    listenClinicData,
    ClinicName,
    FullName,
    ClinicAddress,
    ClinicProvince,
    ClinicalCity,
    ClinicalPostalCode,
    ClinicCountry,
    ClinicPhone,
    ClinicFax,
    ClinicEmailAddress,
    ClinicLicense,
    ClinicReportEmailAddress,
    showClinicAdminProfileImage,
    showClinicAdminSignature,
    setUpClinicProfile,
    ClinicPhysicianUser,
    logoutClinicMember,
    fetchClinicalChats,
    getAcceptStatus,
    checkStatusAccept,
    refreshMessages,
    refreshClinicalConversations,
    fetchClinicalChats,
    fetchClinicalConversationCount,
    changeAcceptStatus
})(ClinicalHomeAdmin);
