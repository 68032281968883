import { ENVIRONMENT } from './environment';

let doctorServicesUrl;
if (ENVIRONMENT === "development") {
    doctorServicesUrl = 'https://doctor-services.staging.yourdoctors.online/';
    // doctorServicesUrl = "http://localhost:8076/";
    // doctorServicesUrl = 'https://doctor-services.yourdoctors.online/';
}else{
    doctorServicesUrl = 'https://doctor-services.yourdoctors.online/';
    //doctorServicesUrl = "http://localhost:8077/";
}

export default doctorServicesUrl;