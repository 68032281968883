import  {Component} from 'react';
import {childChangedDoctor, fetchAgents} from '../../actions/AgentsAction';
import {fetchAllUsers, fetchBannedUsers, fetchSearchedUsers, userChangeState} from '../../actions/UsersAction';

import {checkStatusAccept} from '../../actions/ConversationAction';
import {connect} from 'react-redux';
import Aux from '../../hoc/hocAux';
import classes from './UserStyle.module.css';
import Header from './UserHeader';
import Body from './UserBody';

class UserBuilder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeLink: 'patients',
            search_query: ''
        }
    }

    onHeaderChange = (data) => {
        this.setState((state) => ({activeLink: data}))
        //console.logfetchBannedUsers onHeaderChange data", data)
        if (data === 'patients_banned') {
            this.props.fetchBannedUsers();
        } else {
            if (this.props.usersData.length === 0) {
                this.props.fetchAllUsers(null, false);
            }
        }
    }

    componentDidMount() {
        //this.props.fetchAllUsers(this.props.nextUserID,false);
    }

    LoadMore() {
        //this.props.fetchAllUsers(this.props.nextUserID,false);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.changedDoctorKey === newProps.doctorDetail.uid) {
            //this.props.checkStatusAccept();
        }
    }

    userState(isBan, userID) {
        this.props.userChangeState(isBan, userID);
        //console.logfetchBannedUsers userState isBan userID", isBan, userID)
        this.props.fetchBannedUsers();
        this.props.fetchSearchedUsers(this.state.search_query);
    }

    searchUsers(data) {
        this.setState((state) => ({search_query: data}))
        if (data === '') {
            //this.props.fetchAllUsers(null,true);
        } else {
            this.props.fetchSearchedUsers(data);
        }
    }

    render() {
        return (
            <Aux>
                <div className={classes.main}>
                    {this.props.acceptingChat &&
                    <div className={classes.header}>Patients</div>
                    }
                    {this.props.acceptingChat === false &&
                    <div className={classes.header_red}>You are currently not accepting chats</div>
                    }
                    <div className={classes.body}>
                        <Header
                            HeaderItem={(data) => this.onHeaderChange(data)}
                            activeLink={this.state.activeLink}
                            searchUsers={(data) => this.searchUsers(data)}
                            search_query={this.state.search_query}
                        />
                        <Body
                            usersData={this.props.usersData}
                            bannedUsersData={this.props.bannedUsersData}
                            activeLink={this.state.activeLink}
                            onAgentClick={this.onAgentClick}
                            showLoadMore={this.props.showLoadMore}
                            selectedAgent={this.state.selectedAgent}
                            currentUid={this.props.doctorDetail.uid}
                            LoadMore={() => this.LoadMore()}
                            userState={(isBan, userID) => this.userState(isBan, userID)}
                            searchUsers={(data) => this.searchUsers(data)}
                            search_query={this.state.search_query}
                        />
                    </div>
                </div>
            </Aux>
        )
    }
}

const mapStateToProps = ({customersReducer, acceptingReducer, agentsReducer, doctorReducer}) => {
    const {customersData, usersData, showLoadMore, nextUserID, bannedUsersData} = customersReducer;
    const {acceptingChat, changedDoctorKey} = acceptingReducer
    const {doctorDetail} = doctorReducer;
    const {agentsData, success} = agentsReducer;


    return {
        customersData,
        acceptingChat,
        doctorDetail,
        changedDoctorKey,
        agentsData,
        success,
        usersData,
        showLoadMore,
        nextUserID,
        bannedUsersData
    };
}

export default connect(mapStateToProps, {
    childChangedDoctor,
    checkStatusAccept,
    fetchAgents,
    fetchAllUsers,
    fetchBannedUsers,
    userChangeState,
    fetchSearchedUsers
})(UserBuilder);
