import {ENVIRONMENT} from "./environment";

let data = ENVIRONMENT === 'development'? {
    apiKey: "AIzaSyAJ2ffJun46HUinDNiNfMa5RxTJCwZHHiY",
    authDomain: "yourdoctorsonline-staging.firebaseapp.com",
    databaseURL: "https://yourdoctorsonline-staging.firebaseio.com",
    projectId: "yourdoctorsonline-staging",
    storageBucket: "yourdoctorsonline-staging.appspot.com",
    messagingSenderId: "280300232842",
    appId: "1:280300232842:web:dc79cc3bb70cd55f"
}: {
    apiKey: "AIzaSyCdx3hnXpnL8TShLzDjDDBdOwDzlKEoGrg",
    authDomain: "yourdoctorsonline-prod.firebaseapp.com",
    databaseURL: "https://yourdoctorsonline-prod.firebaseio.com",
    projectId: "yourdoctorsonline-prod",
    storageBucket: "yourdoctorsonline-prod",
    messagingSenderId: "177934657566",
    appId: "1:177934657566:web:1deafae05620b88a9ae60f"
};
export default {
    firebase: data
}
