import {
    AgoraVideoPlayer
} from "agora-rtc-react";

const Videos = (props) => {
    const { users, tracks } = props;
    {/* <div>

            {users.length===1 && users[0]?.videoTrack?<AgoraVideoPlayer className='vid' videoTrack={users[0]?.videoTrack} style={{height: '95%', width: '95%'}}  key={users[0].uid}/>:<div>empty frame</div>}
            {users.length > 0?<AgoraVideoPlayer className='vid' videoTrack={tracks[1]} style={{height: '95%', width: '95%'}} />:<div>empty frame</div>}

            {/*<div id="videos">
                {users.length > 0 &&
                users.map((user) => {
                    if (user.videoTrack) {
                        return (
                        );
                    } else return null;
                })}
            </div>
</div>*/}
    return (
        <div>
            <div id="videos">
                <AgoraVideoPlayer className='vid' videoTrack={tracks[1]} style={{height: '95%', width: '95%'}} />
                {users.length > 0 &&
                users.map((user) => {
                    if (user.videoTrack) {
                        return (
                            <AgoraVideoPlayer className='vid' videoTrack={user.videoTrack} style={{height: '95%', width: '95%'}} key={user.uid} />
                        );
                    } else return null;
                })}
            </div>
        </div>
    );
};
export default Videos
