import React, {useEffect, useState, Fragment, useRef} from 'react'
import {connect} from "react-redux";
import classes from './messagebubble.module.css';

const LeftMessage = (props) => {
    const [name, setName] = useState('');

    const capitalize = (s) => {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    useEffect(()=>{
        setName(props.patientName !== undefined ? capitalize(props.patientName) : 'Patient')
    },[props.patientName])
    return (
        <div className={classes.msg_body}>
            <div className={classes.msg_name}>
                <div className={classes.msg_name_text}>{name}</div>
            </div>
            <div className={classes.msg_left_body}>
                <div className={classes.msg_left_img}>{name.charAt(0)}</div>
                <div className={classes.msg_text_body}>
                    <div className={classes.msg_text_container}>
                        <div className={classes.msg_bubble_view}>
                            <div className={classes.msg_bubble_container}>
                                <div>
                                    <div className={classes.msg_bubble_content}>
                                        <div className={classes.msg_bubble_content1}>
                                            <div className={classes.msg_bubble_content2}>
                                                <div className={classes.msg_bubble_content_row}>
                                                    <div className={classes.msg_bubble_content_view}>
                                                        <div className={classes.msg_bubble_container_content}>
                                                            {props.content}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )


}
const mapStateToProps = ({ conversationReducer }) => {
    const { patientId, patients } = conversationReducer;
    return { patientId, patients };
};

export default connect(mapStateToProps, {})(LeftMessage);
