import {
    EMAIL_CHANGE,
    FETCH_TICKET_DATA,
    FETCH_TICKETS,
    FETCH_TICKETS_AGENTS,
    MESSAGE_CHANGE,
    NAME_CHANGE,
    NEW_MESSAGE,
    SUBJECT_CHANGE,
    TICKET_FAIL,
    TICKET_SAVE
} from '../constants';
import S3FileUpload from 'react-s3';
import config from '../aws3config';

import _ from 'lodash';
import {auth, database} from "../firebase";


export const subjectChange = (subject) => {
    return {
        type: SUBJECT_CHANGE,
        payload: subject
    }
}

export const nameChange = (name) => {
    return {
        type: NAME_CHANGE,
        payload: name
    }
}

export const emailChange = (email) => {
    return {
        type: EMAIL_CHANGE,
        payload: email
    }
}

export const messageChange = (msg) => {
    return {
        type: MESSAGE_CHANGE,
        payload: msg
    }
}

export const newMessage = (new_msg) => {
    return {
        type: NEW_MESSAGE,
        payload: new_msg
    }
}

export const saveNewMessage = (file, status, ticketID, {updateMessage, ticketData}) => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        var now = new Date();
        var updates = {};
        if (status) {
            if (file !== '') {
                S3FileUpload.uploadFile(file, config)
                    .then(response => {
                        //console.log("response: ", response);
                        if (response.result.status !== 204)
                            return false;

                        //File Uploaded to S3.
                        if (response.location) {
                            let messageChildKey = getTicketKeyMessageRef(ticketData.messagesID).push().key;

                            let messageValue = {
                                message: updateMessage,
                                fileUrl: response.location,
                                fromID: currentUserID,
                                toID: ticketData.assignee_ID,
                                timestamp: now.getTime()
                            }

                            updates[`/ticket-messages/${ticketData.messagesID}/${messageChildKey}`] = messageValue;
                            database.ref().update(updates)
                                .then(() => {
                                    dispatch({
                                        type: TICKET_SAVE,
                                    })
                                })
                                .catch((error) => {
                                    //console.log("error: ", error.message);
                                    dispatch({
                                        type: TICKET_FAIL,
                                        payload: error.message
                                    })
                                })
                        } else {
                            return false;
                        }
                    });
            } else if (updateMessage !== '') {
                let messageChildKey = getTicketKeyMessageRef(ticketData.messagesID).push().key;

                let messageValue = {
                    fileUrl: false,
                    message: updateMessage,
                    fromID: currentUserID,
                    toID: ticketData.assignee_ID,
                    timestamp: now.getTime(),
                };

                updates[`/ticket-messages/${ticketData.messagesID}/${messageChildKey}`] = messageValue;
                database.ref().update(updates)
                    .then(() => {
                        dispatch({
                            type: TICKET_SAVE,
                        })
                    })
                    .catch((error) => {
                        //console.log("error: ", error.message);
                        dispatch({
                            type: TICKET_FAIL,
                            payload: error.message
                        })
                    })
            }
        } else {
            if (file !== '') {
                S3FileUpload.uploadFile(file, config)
                    .then(response => {
                        //console.log("response: ", response);
                        if (response.result.status !== 204)
                            return false;

                        //File Uploaded to S3.
                        if (response.location) {
                            let messageChildKey = getTicketKeyMessageRef(ticketData.messagesID).push().key;

                            let messageValue = {
                                message: updateMessage,
                                fileUrl: response.location,
                                fromID: currentUserID,
                                toID: ticketData.assignee_ID,
                                timestamp: now.getTime()
                            }

                            let TicketsValue = {
                                subject: ticketData.subject,
                                requester_name: ticketData.requester_name,
                                requester_email: ticketData.requester_email,
                                requester_ID: ticketData.requester_ID,
                                assignee_ID: ticketData.assignee_ID,
                                assignee_Name: ticketData.assignee_Name,
                                assignee_Email: ticketData.assignee_Email,
                                messagesID: ticketData.messagesID,
                                status: status,
                                timestamp: now.getTime()
                            }

                            updates[`/tickets/${ticketID}`] = TicketsValue;
                            updates[`/ticket-messages/${ticketData.messagesID}/${messageChildKey}`] = messageValue;
                            database.ref().update(updates)
                                .then(() => {
                                    dispatch({
                                        type: TICKET_SAVE,
                                    })
                                })
                                .catch((error) => {
                                    //console.log("error: ", error.message);
                                    dispatch({
                                        type: TICKET_FAIL,
                                        payload: error.message
                                    })
                                })
                        } else {
                            return false;
                        }
                    });
            } else if (updateMessage !== '') {
                let messageChildKey = getTicketKeyMessageRef(ticketData.messagesID).push().key;

                let messageValue = {
                    fileUrl: false,
                    message: updateMessage,
                    fromID: currentUserID,
                    toID: ticketData.assignee_ID,
                    timestamp: now.getTime()
                };

                let TicketsValue = {
                    subject: ticketData.subject,
                    requester_name: ticketData.requester_name,
                    requester_email: ticketData.requester_email,
                    requester_ID: ticketData.requester_ID,
                    assignee_ID: ticketData.assignee_ID,
                    assignee_Name: ticketData.assignee_Name,
                    assignee_Email: ticketData.assignee_Email,
                    messagesID: ticketData.messagesID,
                    status: status,
                    timestamp: now.getTime()
                }

                updates[`/tickets/${ticketID}`] = TicketsValue;
                updates[`/ticket-messages/${ticketData.messagesID}/${messageChildKey}`] = messageValue;
                database.ref().update(updates)
                    .then(() => {
                        dispatch({
                            type: TICKET_SAVE,
                        })
                    })
                    .catch((error) => {
                        //console.log("error: ", error.message);
                        dispatch({
                            type: TICKET_FAIL,
                            payload: error.message
                        })
                    })
            } else {
                let TicketsValue = {
                    subject: ticketData.subject,
                    requester_name: ticketData.requester_name,
                    requester_email: ticketData.requester_email,
                    requester_ID: ticketData.requester_ID,
                    assignee_ID: ticketData.assignee_ID,
                    assignee_Name: ticketData.assignee_Name,
                    assignee_Email: ticketData.assignee_Email,
                    messagesID: ticketData.messagesID,
                    status: status,
                    timestamp: now.getTime()
                }


                updates[`/tickets/${ticketID}`] = TicketsValue;
                database.ref().update(updates)
                    .then(() => {
                        dispatch({
                            type: TICKET_SAVE,
                        })
                    })
                    .catch((error) => {
                        //console.log("error: ", error.message);
                        dispatch({
                            type: TICKET_FAIL,
                            payload: error.message
                        })
                    })
            }
        }
    }
}

export const saveTicket = (file, assign, {subject, name, email, message}) => {
    return async (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        if (assign.agentName !== undefined) {
            if (file !== '') {
                S3FileUpload.uploadFile(file, config)
                    .then(response => {
                        //console.log("response: ", response);
                        if (response.result.status !== 204)
                            return false;

                        //File Uploaded to S3.
                        if (response.location) {
                            var now = new Date();
                            let ticketkey = getTicketsRef().push().key;
                            let messageKey = getTicketMessageRef().push().key;
                            let messageChildKey = getTicketKeyMessageRef(messageKey).push().key;

                            let messageValue = {
                                message: message,
                                fileUrl: response.location,
                                timestamp: now.getTime(),
                                fromID: currentUserID,
                                toID: assign.agentID
                            }

                            let TicketsValue = {
                                subject: subject,
                                requester_name: name,
                                requester_email: email,
                                requester_ID: currentUserID,
                                assignee_ID: assign.agentID,
                                assignee_Name: assign.agentName,
                                assignee_Email: assign.agentEmail,
                                messagesID: messageKey,
                                status: true,
                                timestamp: now.getTime()
                            }

                            var updates = {};
                            updates[`/tickets/${ticketkey}`] = TicketsValue;
                            updates[`/ticket-messages/${messageKey}/${messageChildKey}`] = messageValue;
                            database.ref().update(updates)
                                .then(() => {
                                    dispatch({
                                        type: TICKET_SAVE,
                                    })
                                })
                                .catch((error) => {
                                    //console.log("error: ", error.message);
                                    dispatch({
                                        type: TICKET_FAIL,
                                        payload: error.message
                                    })
                                })
                        } else {
                            return false;
                        }
                    });
            } else {
                var now = new Date();
                let ticketkey = getTicketsRef().push().key;
                let messageKey = getTicketMessageRef().push().key;
                let messageChildKey = getTicketKeyMessageRef(messageKey).push().key;

                let messageValue = {
                    fileUrl: false,
                    message: message,
                    timestamp: now.getTime(),
                    fromID: currentUserID,
                    toID: assign.agentID
                };
                let TicketsValue = {
                    subject: subject,
                    requester_name: name,
                    requester_email: email,
                    requester_ID: currentUserID,
                    assignee_ID: assign.agentID,
                    assignee_Name: assign.agentName,
                    assignee_Email: assign.agentEmail,
                    messagesID: messageKey,
                    status: true,
                    timestamp: now.getTime()
                };

                var updates = {};
                updates[`/tickets/${ticketkey}`] = TicketsValue;
                updates[`/ticket-messages/${messageKey}/${messageChildKey}`] = messageValue;
                database.ref().update(updates)
                    .then(() => {
                        dispatch({
                            type: TICKET_SAVE,
                        })
                    })
                    .catch((error) => {
                        //console.log("error: ", error.message);
                        dispatch({
                            type: TICKET_FAIL,
                            payload: error.message
                        })
                    })
            }
        } else {
            dispatch({
                type: TICKET_FAIL,
                payload: 'Please select assignee'
            })
        }
    }
}

export const fetchTickets = () => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        getTicketsRef().on('value', ticketSnapshot => {
            if (ticketSnapshot.val()) {
                const tickets = _.map(ticketSnapshot.val(), (val, uid) => {
                    if (val.assignee_ID === currentUserID) {
                        val.your_ticket = true;
                    } else {
                        val.your_ticket = false;
                    }
                    return {...val, uid};
                });

                dispatch({
                    type: FETCH_TICKETS,
                    payload: tickets
                })
            } else {
                dispatch({
                    type: FETCH_TICKETS,
                    payload: []
                })
            }
        })
    }
}

export const fetchTicketData = (uid) => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        getTicketUidRef(uid).on('value', ticketSnapshot => {
            if (ticketSnapshot.val()) {
                var messageKey = ticketSnapshot.val().messagesID;
                getTicketKeyMessageRef(messageKey).on('value', messageSnapshot => {
                    const messages = _.map(messageSnapshot.val(), (val, uid) => {
                        if (val.fromID === currentUserID) {
                            val.direction = "right";
                        } else {
                            val.direction = "left";
                        }
                        return {...val, uid};
                    });
                    dispatch({
                        type: FETCH_TICKET_DATA,
                        payload: {
                            ticket: ticketSnapshot.val(), messages: messages,
                            status: ticketSnapshot.val().status
                        }
                    })
                });
            } else {
                dispatch({
                    type: FETCH_TICKET_DATA,
                    payload: []
                })
            }
        })
    }
}

export const fetchAgentsForTickets = () => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        getAgents().on('value', doctorsSnapshot => {
            if (doctorsSnapshot.val()) {
                const agents = _.map(doctorsSnapshot.val(), (val, uid) => {
                    if (uid !== currentUserID) {
                        return {...val, uid};
                    }
                });

                dispatch({
                    type: FETCH_TICKETS_AGENTS,
                    payload: agents
                })
            } else {
                dispatch({
                    type: FETCH_TICKETS_AGENTS,
                    payload: []
                })
            }
        })
    }
}

const getAgents = () => {
    return database.ref().child('doctors');
}

const getTicketsRef = () => {
    return database.ref().child('tickets');
}

const getTicketUidRef = (uid) => {
    return database.ref(`/tickets/${uid}`);
}

const getTicketMessageRef = () => {
    return database.ref(`/ticket-messages`);
}

const getTicketKeyMessageRef = (uid) => {
    return database.ref(`/ticket-messages/${uid}`);
}
