import {
    CLINICAL_PATIENTS_FETCH_CLEAR,
    CLINICAL_PATIENTS_FETCH_SUCCESS,
    SCHEDULE_SLOTS,
    SLOTS_LOADING,
    UPDATE_PATIENT
} from '../constants.js';

const INITIAL_STATE = {clinical_patients: [], loading: false, scheduleSlots: [], slots_loading: false};


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SLOTS_LOADING:
            return {...state, slots_loading: true};
        case CLINICAL_PATIENTS_FETCH_SUCCESS:
            return {...state, clinical_patients: [...state.clinical_patients, action.payload]};
        case CLINICAL_PATIENTS_FETCH_CLEAR:
            return {...state, clinical_patients: []};
        case UPDATE_PATIENT:
            for (var i = 0; i < state.clinical_patients.length; i++) {
                if (state.clinical_patients[i].uid === action.payload.patientID) {
                    state.clinical_patients[i].registrationStatus = action.payload.status;
                }
            }
            return {...state, clinical_patients: [...state.clinical_patients]};
        case SCHEDULE_SLOTS:
            return {...state, scheduleSlots: action.payload, slots_loading: false};
        default:
            return state;
    }
};
