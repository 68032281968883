
import classes from '../SettingBuilder.module.css';

const TagsList = (props) => {
    return(
        <div className={classes.tag}>
            <div className={classes.tag_view}>
                <div className={classes.text}>
                    <span>{props.tag}</span>
                </div>
            </div>
        </div>
    )
}

const Tags = (props) => {

    const taglist = props.tagsList.map(tag => {
        return <TagsList key={tag.uid} tag={tag.tag}/>
    });

    return(
        <div className={classes.detail_content}>
            <div className={classes.content}>
                <h2>What are Tags</h2>
                <p className={classes.intro}>Tags let you categorize your chats and tickets.</p>
            </div>
            <form className={classes.form}>
                <label>
                    <input
                        type="text"
                        id="add-tag"
                        placeholder="Tag"
                        tabIndex="1"
                        maxLength="70"
                        required
                        value={props.tag}
                        onChange={(text) => props.onTagTextChange(text)}
                    />
                </label>
                <button tabIndex="2" onClick={(event) => props.onSaveTag(event)}>
                    <span>Add a tag</span>
                </button>
            </form>
            <div className={classes.tag_list}>
                <div className={classes.tag_list_header}>
                    <div className={classes.view}>
                        <div className={classes.text}>Tag</div>
                    </div>
                    {taglist}
                </div>
            </div>
        </div>
    )
}

export default Tags;
