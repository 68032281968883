import {
    FETCH_CLINICAL_REPORTS_HISTORY,
    FETCH_CLINICAL_REPORTS_INFO,
    FETCH_CLINICALDOCTORS_SUCCESS,
    FETCH_DAILY_REPORTS_FAILS,
    FETCH_DAILY_REPORTS_PROCESS,
    FETCH_DAILY_REPORTS_SUCCESS,
    FETCH_DOCTORS_SUCCESS,
    FETCHING_DOCTORS
} from '../constants';

import _ from 'lodash';
import ngrokUrl from '../ngrok';
import moment from 'moment';
import {ENVIRONMENT} from '../environment';
import {database} from "../firebase";

export const dailyReport = () => {
    return (dispatch) => {
        dispatch({type: FETCH_DAILY_REPORTS_PROCESS});
        /*fetchData(`${ngrokUrl}/getChatSessionHoursByDate`, 'GET')
            .then((data) => {
                dispatch({
                    type: FETCH_DAILY_REPORTS_SUCCESS,
                    payload: data
                })
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_DAILY_REPORTS_FAILS,
                    payload: error.message
                })
            })*/
    }
}

export const fetchDoctors = () => {
    return (dispatch) => {
        dispatch({type: FETCHING_DOCTORS});
        getDoctorsRef().once('value', doctorSnapshot => {
            if (doctorSnapshot.val()) {
                const doctors = _.map(doctorSnapshot.val(), (val, uid) => {
                    return {...val, uid};
                });

                dispatch({
                    type: FETCH_DOCTORS_SUCCESS,
                    payload: doctors
                });
            } else {
                dispatch({
                    type: FETCH_DOCTORS_SUCCESS,
                    payload: []
                })
            }
        })
    }
}
export const fetchClinicalDoctors = (clinicid, callback) => {
    return (dispatch) => {
        dispatch({type: FETCHING_DOCTORS});
        var doctors = [];
        try {
            getStaffMembersRef(clinicid).once('value', doctorsSnapshot => {
                if (doctorsSnapshot.val()) {
                    var count = doctorsSnapshot.numChildren();

                    var index = 0;
                    doctorsSnapshot.forEach(doctor => {
                        if (doctor.val()) {
                            if (doctor.val().ansPatients) {
                                var doc = doctor.val();
                                doc.uid = doctor.key;
                                doctors.push(doc);
                            }
                        }

                        if (count === index + 1) {
                            dispatch({
                                type: FETCH_CLINICALDOCTORS_SUCCESS,
                                payload: doctors
                            });
                            callback(doctors);
                        }
                        index = index + 1;

                    })
                } else {
                    dispatch({
                        type: FETCH_CLINICALDOCTORS_SUCCESS,
                        payload: []
                    })
                    callback([]);
                }
            })

        } catch (error) {
            //console.log("fetchClinicalDoctors error ", error);
        }

    }
}

export const fetchEncountersInfo = (data) => {
    return (dispatch) => {
        var startDate = new Date();
        var endDate = new Date();
        endDate.setDate(endDate.getDate() + 1);

        if (data.dateRange === -1) {
            startDate.setDate(startDate.getDate() - 29);
        } else if (data.dateRange === 1) {
            startDate.setDate(startDate.getDate() - 6);
        } else if (data.dateRange === 2) {
            startDate.setDate(startDate.getDate() - 27);
        } else if (data.dateRange === 3) {
            startDate.setMonth(startDate.getMonth() - 3);
        } else if (data.dateRange === 4) {
            startDate.setMonth(startDate.getMonth() - 6);
        } else if (data.dateRange === 5) {
            startDate.setMonth(startDate.getMonth() - 12);
        } else if (data.dateRange === 6) {
            startDate.setMonth(startDate.getMonth() - 16);
        } else if (data.dateRange === 7) {
            startDate = new Date(data.startDate);
            endDate = new Date(data.endDate);
            endDate.setDate(endDate.getDate() + 1);
        }
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);

        var dateArray = [];
        var doctorArray = [];
        var doctors = data.doctors;
        for (var i = 0; i < doctors.length; i++) {
            doctorArray.push({doctorID: doctors[i].uid, name: doctors[i].fullName, encounter: 0});
        }
        var currentDate = moment(startDate);
        var stopDate = moment(endDate);
        while (currentDate <= stopDate) {
            dateArray.push({timestamp: moment(currentDate).valueOf(), name: currentDate.format('L'), Encounters: 0})
            currentDate = moment(currentDate).add(1, 'days');
        }
        getClinicalReportsRef(data.clinicId).orderByChild('timestamp').startAt(dateArray[0][0]).once('value', reportsSnap => {
            var totalconvocount = reportsSnap.numChildren();
            var convocount = totalconvocount;
            var maxoccurance = 0;
            var convoindex = 0;
            if (reportsSnap.val()) {
                reportsSnap.forEach(archiveSnap => {
                    var isRight = true;
                    if (archiveSnap.val()) {
                        if (Boolean(data.doctor.uid)) isRight = archiveSnap.val().doctorID === data.doctor.uid;
                        var date = new Date(archiveSnap.val().timestamp);
                        date.setHours(0, 0, 0, 0);
                        var dateMoment = moment(date);
                        if (moment(dateMoment).valueOf() <= stopDate.valueOf() && isRight) {
                            let dateindex = dateArray.findIndex(date => date.timestamp === moment(dateMoment).valueOf());
                            if (dateindex > -1) {
                                dateArray[dateindex].Encounters = dateArray[dateindex].Encounters + 1;
                                if (maxoccurance < dateArray[dateindex][2]) maxoccurance = dateArray[dateindex][2];
                                var doctorindex = doctorArray.findIndex(doctor => doctor.doctorID === archiveSnap.val().doctorID);
                                if (doctorindex < 0) {
                                    doctorArray.push({
                                        doctorID: archiveSnap.val().doctorID,
                                        name: archiveSnap.val().fullName,
                                        encounter: 1
                                    })
                                } else {
                                    doctorArray[doctorindex].encounter = doctorArray[doctorindex].encounter + 1;
                                }
                            } else {
                                convocount = convocount - 1;
                            }

                        } else {
                            convocount = convocount - 1;
                        }
                    } else {
                        convocount = convocount - 1;
                    }
                    if (convoindex === totalconvocount - 1) {
                        var payload = {
                            doctorArray: doctorArray,
                            dateArray: dateArray,
                            numberOfConversations: convocount,
                            maxConvoInterval: maxoccurance
                        }

                        dispatch({
                            type: FETCH_CLINICAL_REPORTS_INFO,
                            payload: payload
                        })
                    }
                    convoindex = convoindex + 1;

                })
            }
        })
    }
}
export const fetchReportsHistory = (clinicId) => {
    return (dispatch) => {
        getClinicalReportsHistoryRef(clinicId).orderByChild('timestamp').limitToLast(10).once('value', allHistorySnap => {
            var count = allHistorySnap.numChildren();

            var reports = [];
            if (allHistorySnap.val()) {
                var index = 0;
                allHistorySnap.forEach(historySnap => {
                    index = index + 1;
                    if (historySnap.val()) {
                        var report = historySnap.val();
                        report.uid = historySnap.key;
                        reports.push(report);
                    }

                    if (count === index) {
                        var sorted_reports = reports.length > 1 ? _.orderBy(reports, ['timestamp'], ['desc']) : reports;
                        dispatch({
                            type: FETCH_CLINICAL_REPORTS_HISTORY,
                            payload: sorted_reports
                        })
                    }

                })

            }
        })
    }
}
export const fetchReportsByDate = (start, end) => {
    return (dispatch) => {
        dispatch({type: FETCH_DAILY_REPORTS_PROCESS});
        /*fetchData(`${ngrokUrl}/getChatSessionBetweenDates?start=${start}&end=${end}`, 'GET')
            .then((data) => {
                dispatch({
                    type: FETCH_DAILY_REPORTS_SUCCESS,
                    payload: data
                })
            })
            .catch((error) => {
                //console.log("error: ", error);
                dispatch({
                    type: FETCH_DAILY_REPORTS_FAILS,
                    payload: error.message
                })
            })*/
    }
}
export const saveReportSetting = (data, callback) => {
    return (dispatch) => {
        let clinic_id = data.clinicId;
        if (clinic_id) {
            var updates = {};
            updates[`clinic/${clinic_id}/mailDuration`] = data.mailDuration;
            updates[`clinic/${clinic_id}/clinicReportEmailAddress`] = data.reportEmail;
            updates[`clinic/${clinic_id}/DOBFormat`] = data.DOBFormat;
            updates[`clinic/${clinic_id}/EncounterDateFormat`] = data.EncounterDateFormat;
            updates[`clinic/${clinic_id}/EHRClinicId`] = data.EHRClinicId;
            updates[`clinic/${clinic_id}/EHRScannerId`] = data.EHRScannerId;


            for (let i = 0; i < data.clinical_doctors.length; i++) {
                updates[`clinicStaffMember/${clinic_id}/${data.clinical_doctors[i].uid}/EHRDoctorID`] = data.clinical_doctors[i].EHRDoctorID;
            }
            database.ref().update(updates)
                .then(() => {
                    if (!Boolean(data.previousMailDuration)) {
                        let reportingMailData = {
                            init: true,
                            clinic_id: clinic_id,
                            mailDuration: data.mailDuration
                        }
                        let url = '';
                        if (ENVIRONMENT === 'development') {
                            url = "https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/createReportingEmailTask?";
                        } else {
                            url = "https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/createReportingEmailTask?";
                        }
                        fetch(url + `init=true&clinic_id=${clinic_id}&mailDuration=${data.mailDuration}`, {}).then(success => {
                            callback(true, "reports setting saved successfully");
                        })
                            .catch(error => {
                                callback(false, "reports sending failed due to error: ", error);
                                //console.log("reportingMailData error", error)
                            })
                    } else {
                        callback(true, "reports setting saved successfully");
                    }
                })
                .catch((error) => {
                    callback(false, "reports setting failed due to error: ", error);
                })

        } else {
            callback(false, "reports setting failed, please try again later");
        }

    }
}
export const fetchDoctorReports = (uid) => {
    return (dispatch) => {
        dispatch({type: FETCH_DAILY_REPORTS_PROCESS});
        /*fetchData(`${ngrokUrl}/getChatSessionByFirebaseIDAndGroupByDate?FirebaseID=${uid}`, 'GET')
            .then((data) => {
                dispatch({
                    type: FETCH_DAILY_REPORTS_SUCCESS,
                    payload: data
                })
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_DAILY_REPORTS_FAILS,
                    payload: error.message
                })
            })*/
    }
}

export const fetchReportsByFirebaseAndGroupByDate = (uid, start, end) => {
    return (dispatch) => {
        dispatch({type: FETCH_DAILY_REPORTS_PROCESS});
        /*fetchData(`${ngrokUrl}/getChatSessionByFirebaseIDAndGroupByDateRange?FirebaseID=${uid}&start=${start}&end=${end}`, 'GET')
            .then((data) => {
                dispatch({
                    type: FETCH_DAILY_REPORTS_SUCCESS,
                    payload: data
                })
            })
            .catch((error) => {
                //console.log("error: ", error);
                dispatch({
                    type: FETCH_DAILY_REPORTS_FAILS,
                    payload: error.message
                })
            })*/
    }
}

const getDoctorsRef = () => {
    return database.ref(`/doctors`);
}
const getUsersRef = (patientId) => {
    return database.ref(`/users/${patientId}`);
}

async function fetchData(url = '', method) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
    });
    return await response.json(); // parses JSON response into native JavaScript objects
}

const getClinicalReportsRef = (clinicName) => {
    return database.ref(`/clinicalReportsData/${clinicName}`);
}
const getClinicalReportsHistoryRef = (clinicName) => {
    return database.ref(`/clinicalReportsHistory/${clinicName}`);
}

const getdoctorByRef = (uid) => {
    return database.ref(`/doctors/${uid}`);
}
const getStaffMembersRef = (clinic) => {
    return database.ref(`/clinicStaffMember/${clinic}`);
}

const getClinicData = (uid) => {
    return database.ref(`/clinic/${uid}`);
}
