import  {useRef} from 'react';
import {NavLink} from 'react-router-dom';
import classes from './UserStyle.module.css';

const Header = (props) => {
    const inputRef = useRef(null);
    return (
        <div className={classes.main_body}>
            <div className={classes.heading}>
                <NavLink to="/patients"
                         className={props.activeLink === 'patients' ? classes.active : null}
                         onClick={() => props.HeaderItem('patients')}
                >Search Patient</NavLink>
            </div>
            <div className={classes.heading}>
                <NavLink to="/patients"
                         className={props.activeLink === 'patients_banned' ? classes.active : null}
                         onClick={() => props.HeaderItem('patients_banned')}
                >Banned</NavLink>
            </div>
            {props.search_query !== '' &&
            <div className={classes.search_bar}>
                <input type="text" ref={inputRef} placeholder={props.search_query}
                       className={classes.search_input}></input>

                <input
                    type="button"
                    value="Search"
                    className={classes.search_button}
                    onClick={() => props.searchUsers(inputRef.current.value)}
                />
            </div>}

        </div>
    )
}
export default Header;
