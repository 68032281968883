export const ADMIN_LOGIN_EMAIL = "Admin_login_email";
export const ADMIN_LOGIN_PASSWORD = "Admin_login_password";
export const ADMIN_LOGIN_PROCESS = "Admin_login_process";
export const ADMIN_LOGIN_SUCCESS = "Admin_login_success";
export const ADMIN_LOGIN_FAILS = "Admin_login_failed";
export const ADMIN_LOGOUT_PROCESS = "Admin_logout_process";
export const ADMIN_LOGOUT_SUCCESS = "Admin_logout_success";
export const ADMIN_LOGOUT_FAILS = "Admin_logout_failed";
export const ADD_AGENT_INPROGRESS = 'Agent added in-progress';
export const DELETE_DOCTOR_SUCCESS = 'delete user success';
export const ADD_AGENT_SUCCESS = 'Agent added successfully';
export const ADD_AGENT_FAILS = 'Agent added failed';
export const FETCH_AGENT = 'Agent data fetched';
export const RESET_SUCCESS = 'false';
export const SET_CALL_STATUS = 'SET_CALL_STATUS';


export const DOCTOR_LOGOUT_INPROGRESS = 'Doctor_logout_in_progress';
export const DOCTOR_LOGOUT_SUCCESS = 'Doctor_logout_in_success';
export const DOCTOR_LOGOUT_FAILS = 'Doctor_logout_in_failed';

export const UPDATE_DOCTOR_NAME = 'Update_doctor_name';
export const UPDATE_DOCTOR_TITLE = 'Update_doctor_title';
export const UPDATE_DOCTOR_LICENSE = 'Update_doctor_license';
export const DOCTOR_DESCRIPTION = 'DOCTOR_DESCRIPTION';
export const DOCTOR_EXPERIENCE = 'DOCTOR_EXPERIENCE';
export const DOCTOR_HOSPITAL_LOCATION = 'DOCTOR_HOSPITAL_LOCATION';
export const DOCTOR_CHAT_ASSIGN_CHECK = 'DOCTOR_CHAT_ASSIGN_CHECK';

export const CANNED_RESPONSE_UPDATE = 'Canned_response_update';
export const CANNED_RESPONSE_DELETE = 'Canned_response_delete';

export const SHOW_IMAGE_PREVIEW = 'Show_image_preview';
export const SHOW_SIGNATURE_PREVIEW = 'SHOW_SIGNATURE_PREVIEW';
export const ADD_TOTAL_CHAT_LIMIT = 'Add_in_total_chat_limit';
export const MINUS_TOTAL_CHAT_LIMIT = 'Minus_in_total_chat_limit';
export const ADD_FREE_CHAT_LIMIT = 'Add_in_free_chat_limit';
export const MINUS_FREE_CHAT_LIMIT = 'Minus_in_free_chat_limit';
export const ADD_PAID_CHAT_LIMIT = 'Add_in_paid_chat_limit';
export const MINUS_PAID_CHAT_LIMIT = 'Minus_in_paid_chat_limit';

export const UPDATE_DOCTOR_DETAIL_SUCCESS = 'update_doctor_detail_success';
export const UPDATE_DOCTOR_DETAIL_FAILED = 'update_doctor_detail_failed';

export const CHECK_ADMIN = 'Check_admin';

export const FETCH_AGENTS_INPROGRESS = 'Agents fetched request in-progress';
export const FETCH_AGENTS_SUCCESS = 'Agents fetched request successfully done';
export const FETCH_AGENTS_FAILED = 'Agents fetched request failed';
export const CURRENT_USER_ID = 'current user id';


export const CLINICAL_DOCTOR_STATUS = 'Clinical doctor status';
export const CLINIC_NAME = 'Clinic Name';
export const CLINIC_LOCATION = 'Clinic Location';
export const CLINIC_ADDRESS = 'Clinic  Address';
export const CLINIC_PHONE_NUMBER = 'Clinic Phone Number';

//////////////////////////
// Messaging Constants //
////////////////////////

export const CONVERSATIONS_FETCHING = 'conversations_fetching';
export const CONVERSATIONS_FETCH_SUCCESS = 'conversations_fetch_success';
export const CONVERSATIONS_FETCH_SUCCESS_ON_ADDED = 'CONVERSATIONS_FETCH_SUCCESS_ON_ADDED';


export const ALL_CONVERSATIONS_FETCH_SUCCESS = 'all_conversations_fetch_success';

export const CONVERSATIONS_REMOVED = 'CONVERSATIONS_REMOVED';
export const CONVERSATIONS_FETCH_FAILS = 'conversations_fetch_fails';
export const CONVERSATIONS_FETCH_UPDATE = 'conversations_fetch_update';
export const REFRESH_CONVERSATION = 'refresh_conversations';
export const FETCH_CONVERSATIONS_COUNT_FOR_EACH_DOCTOR = 'fetch_conversation_count';
export const FETCH_DOCTORS_NAME_AND_IMAGES = 'fetch_doctors_name_and_images';

export const FETCHING_MESSAGES = 'fetching_messages';
export const MESSAGES_FETCH_SUCCESS = 'messages_fetch_success';
export const MESSAGE_FETCH_FAILS = 'messages_fetch_fails';
export const CHANGE_TEXT = 'change_text';

export const SENDING_MESSAGE = 'sending_message';
export const MESSAGE_SENT_SUCCESS = 'messages_sent_success';
export const MESSAGE_SENT_FAILS = 'messages_sent_fails';

export const REFRESH_MESSAGES = 'refresh_messages';
export const FETCHING_PRODUCT_DETAIL = 'fetching_product_detail';
export const FETCHING_PRODUCT_DETAIL_SUCCESS = 'fetching_product_detail_success';


export const CLOSE_CHAT = 'close_chat';
export const CLOSE_CHAT_OTHER = 'close_chat_other';

export const UPDATE_PATIENTS = 'UPDATE_PATIENTS';
export const REMOVE_PATIENT = 'REMOVE_PATIENT';


export const UPDATE_DOCTORS = 'UPDATE_DOCTORS';
export const SELECTED_CHAT = 'selected_chat';
export const TAB_CHANGE_SELECTED_CHAT = 'tab_change_selected_chat';
export const SELECTED_CHAT_PATIENT_DETAIL = 'Selected_chat_patient_detail';
export const PICKED_FROM_QUEUE = 'picked_from_queue';
export const ACCEPTING_CHAT_TRUE = 'accepting_chat_true';
export const ACCEPTING_CHAT_FALSE = 'accepting_chat_false';
export const ACCEPTING_CHAT_TRUE_ADMIN = 'accepting_chat_true_admin';
export const ACCEPTING_CHAT_FALSE_ADMIN = 'accepting_chat_false_admin';

export const CHANGED_DOCTOR_KEY = 'changed_doctor_key'
export const FETCH_STATUS = 'fetch_status';
export const RESET_CONVERSATIONID = 'RESET_CONVERSATIONID';

///////////////////////////
// Attachment constants //
/////////////////////////

export const ATTACHMENT_UPLOAD_PROGRESS = 'attachment_uploading_progress';
export const ATTACHMENT_UPLOAD_SUCCESS = 'attachment_uploading_success';
export const ATTACHMENT_UPLOAD_FAIL = 'attachment_uploading_fails';


//////////////////////////
// Customers constants //
////////////////////////

export const REFRESH_CUSTOMERS = 'refresh_customers';
export const FETCH_CUSTOMERS = 'fetch_customers';
export const FETCH_CUSTOMERS_UPDATE = 'fetch_customers_update';
export const FETCH_NEXT_USER_ID = 'fetch_next_user_id';
export const FETCH_USER_DETAILS = 'fetch_user_details';
export const FETCH_BANNED_USERS = 'fetch_banned_users_details';
export const CLEAR_USERS_LIST = 'clear users list';

export const TAG_CHANGE = 'tag_changed';
export const TAG_SAVE = 'tag_saved';
export const FETCH_TAGS = 'fetch_tags';

export const CANNED_RESPONSE_DESCRIPTION = 'Canned_response_description';
export const CANNED_RESPONSE_SHORT_CUT = 'Canned_response_short_cut';
export const CANNED_RESPONSE_SAVE = 'Canned_response_save';
export const FETCH_CANNED_RESPONSE = 'Fetch_canned_response';
export const UPDATE_SELECTED_PATIENT = 'UPDATE_SELECTED_PATIENT';



////////////////////////
// Tickets constants //
//////////////////////

export const SUBJECT_CHANGE = 'Subject_change';
export const NAME_CHANGE = 'Subject_name';
export const EMAIL_CHANGE = 'Subject_email';
export const MESSAGE_CHANGE = 'Subject_message';
export const TICKET_SAVE = 'Ticket_save';
export const TICKET_FAIL = 'Ticket_failed';
export const FETCH_TICKETS = 'Fetch_tickets';

export const FETCH_TICKET_DATA = 'Fetch_ticket_data';
export const NEW_MESSAGE = 'New_message';
export const FETCH_TICKETS_AGENTS = 'Fetch_tickets_agent';


///////////////////////
// Doctor constants //
/////////////////////

export const FETCH_DOCTOR_DETAILS = 'Fetch_doctor_details';
export const FETCH_PROFILE = 'Fetch_profile_detail';


/////////////////////////
// Archives constants //
///////////////////////

export const FETCH_ARCHIVES_LOADING = 'Fetch_achives_loading';
export const FETCH_ARCHIVES_STATUS = 'Fetch_archives_status';
export const FETCH_ARCHIVES_STATUS_FAILED = 'Fetch_archives_status_failed';
export const FETCHING_ARCHIVE_MESSAGES = 'Fething_archive_messages';
export const FETCH_ARCHIVE_MESSAGES_SUCCESS = 'Fetch_archive_messages_success';
export const SEARCH_ARCHIVES = 'Search_Archives';
export const FETCH_ALL_ARCHIVES_STATUS = 'FETCH_ALL_ARCHIVES_STATUS';
export const FETCH_USER_CHAT_HISTORY = 'Fetch_user_chat_history';
export const RESET_ARCHIVE_CHAT = "reset_archive_chat";

//////////////////////////
// Chat Tags constants //
////////////////////////

export const FETCH_CHAT_TAGS = 'Fetch_chat_tags';
export const FETCH_CHAT_TAGS_SUCCESS = 'Fetch_chat_tags_success';
export const FETCH_CHAT_TAGS_FAILED = 'Fetch_chat_tags_failed';

//////////////////////////////
// Chat Transfar constants //
////////////////////////////

export const FETCH_ONLINE_DOCTORS = 'Fetch_online_doctors';
export const CHAT_TRANSFERED_DONE = 'Chat_transfered_done';


/////////////////////////////////
// Out Patient Form constants //
///////////////////////////////


export const REFRESH_STATE = 'Refresh_initial_state';
export const SAVE_OUT_PATIENT_FORM_IN_PROCESS = 'Save_out_patient_form_in_process';
export const SAVE_OUT_PATIENT_FORM_SUCCESS = 'Save_out_patient_form_success';
export const SAVE_OUT_PATIENT_FORM_FAILS = 'Save_out_patient_form_fails';
export const SAVE_OUT_PATIENT_FORM_UPDATED = 'Save_out_patient_form_updated';
export const SET_CHAT_HISTORY_ITEM_CLICKED = 'SET_CHAT_HISTORY_ITEM_CLICKED';


////////////////////////
// Reports constants //
//////////////////////

export const FETCH_DAILY_REPORTS_PROCESS = 'Fetch_daily_reports_process';
export const FETCH_DAILY_REPORTS_SUCCESS = 'Fetch_daily_reports_success';
export const FETCH_DAILY_REPORTS_FAILS = 'Fetch_daily_reports_fails';
export const FETCHING_DOCTORS = 'Fetching_doctors';
export const FETCH_DOCTORS_SUCCESS = 'Fetching_doctors_success';
export const FETCH_CLINICAL_REPORTS_INFO = 'FETCH_CLINICAL_REPORTS_INFO';



///////////////////////////
// Activities constants //
/////////////////////////

export const FETCHING_ACTIVITIES_IN_PROCESS = 'Fetching_activities_in_process';
export const FETCHING_ACTIVITIES_SUCCESS = 'Fetching_activities_success';
export const FETCHING_ACTIVITIES_FAILS = 'Fetching_activities_failed';
export const FLAG_TRUE = 'flag_true';
export const FLAG_FALSE = 'flag_false';
export const VIDEO_CHAT_DECLINED = 'video_chat_declined';
export const REFRESH_VIDEO_CHAT_DECLINED = 'refresh_video_chat_declined';
export const VIDEO_STATE_TRUE = 'video_state_true';
export const VIDEO_STATE_FALSE = 'video_state_false';
export const SAVE_REMOTE_STREAMS = 'save_remote_streams';
export const REFRESH_REMOTE_STREAMS = 'refresh_remote_streams';


///////////////////////////////
// Clinical Chats constants //
/////////////////////////////

export const REFRESH_CLINICAL_CONVERSATION = 'refresh_clinical_chats';
export const FETCH_CLINICAL_CHATS_IN_PROCESS = 'Fetching_clinical_chats_in_process';
export const FETCH_PRESCRIPTIONS = 'fetch_prescriptions';
export const DISCARD_CHANGES = 'discard_changes';
export const FETCH_PRESCRIPTIONS_DATA = 'fetch_clinical_prescriptions_data';
export const PRESCRIPTION_ISCHANGED = 'prescription_ischanged';
export const PRESCRIPTION_ISSAVED = 'prescription_issaved';
export const UPDATE_CONVERSATION_MEDICATION = 'update_conversation_medication';
export const FETCH_PRESCRIPTIONS_DATA_CHATCHANGED = "fetch_prescription_data_chatchanged"

export const FETCH_CLINICAL_CHATS_SUCCESS = 'Fetching_clinical_chats_success';
export const FETCH_CLINICAL_CHATS_FAILED = 'Fetching_clinical_chats_failed';
export const FETCH_CLINICAL_CHATS_UPDATE = 'Fetch_clinical_chats_update';
export const FETCH_CLINICAL_CONVERSATIONS_COUNT_FOR_EACH_DOCTOR = 'Fetch_clinical_conversations_count_for_each_doctor';
export const CHANGE_CLINICAL_CHAT_STATUS_SUCCESS = 'Change_clinical_chat_status';
export const CHANGE_CLINICAL_CHAT_STATUS_FAILED = 'Change_clinical_chat_status';
export const SAVE_CLINICAL_PATIENT_FORM_IN_PROCESS = 'Save_clinical_patient_form_in_progress';
export const SAVE_CLINICAL_PATIENT_FORM_SUCCESS = 'Save_clinical_patient_form_in_success';
export const SAVE_CLINICAL_PATIENT_FORM_FAILED = 'Save_clinical_patient_form_in_failed';
export const CLOSE_CLINICAL_CHAT = 'Close_clinical_chat';
export const SET_VIDEOCHAT_ID = 'set_videochat_id';
export const FETCH_UNREAD_MESSAGES_COUNT = 'fetch_unread_messages_count';
export const CLEAR_UNREAD_MESSAGES_COUNT = 'clear_unread_messages_count';
export const FETCH_CLINICAL_UNREAD_MESSAGES_COUNT = 'fetch_clinical_unread_messages_count';
export const CLEAR_CLINICAL_UNREAD_MESSAGES_COUNT = 'clear_clinical_unread_messages_count';
export const CLINICAL_HEALTH_INSURANCE_CARD_UPDATED = 'clinical_health_insurance_card_updated';

/////////////////////////////////
// Clinical doctors constants //
///////////////////////////////

export const FETCH_ONLINE_CLINICAL_DOCTORS_GROUP_IN_PROCESS = 'Fetch_online_clinical_doctors_group_in_process';
export const FETCH_ONLINE_CLINICAL_DOCTORS_GROUP = 'Fetch_online_clinical_doctors_group';
export const FOLLOW_SAVE_PROGRESS = 'follow_save_progress';
export const FOLLOW_SAVE_DONE = 'follow_save_done';

////////////////////////////////
// Clinical canned responses //
//////////////////////////////

export const CLINICAL_CANNED_RESPONSE_DESCRIPTION = 'CLINICAL_CANNED_RESPONSE_DESCRIPTION';
export const CLINICAL_CANNED_RESPONSE_SHORT_CUT = 'CLINICAL_CANNED_RESPONSE_SHORT_CUT';
export const CLINICAL_CANNED_RESPONSE_SAVE = 'CLINICAL_CANNED_RESPONSE_SAVE';

export const CLINICAL_FETCH_CANNED_RESPONSE = 'CLINICAL_FETCH_CANNED_RESPONSE';
export const CLINICAL_FETCH_CHAT_CANNED_RESPONSE = 'CLINICAL_FETCH_CHAT_CANNED_RESPONSE';
export const CLINICAL_FETCH_CANNED_RESPONSE_ASSESSMENT = 'CLINICAL_FETCH_CANNED_RESPONSE_ASSESSMENT';
export const CLINICAL_FETCH_CANNED_RESPONSE_PLAN = 'CLINICAL_FETCH_CANNED_RESPONSE_PLAN';
export const CLINICAL_CANNED_RESPONSE_UPDATE = 'CLINICAL_CANNED_RESPONSE_UPDATE';
export const CLINICAL_CANNED_RESPONSE_DELETE = 'CLINICAL_CANNED_RESPONSE_DELETE';

/////////////////////////////
// Clinical billing codes //
///////////////////////////

export const BILLING_CODE_CHANGE = 'BILLING_CODE_CHANGE';
export const BILLING_CODE_DESC_CHANGE = 'BILLING_CODE_DESC_CHANGE';
export const BILLING_CODE_SAVE = 'BILLING_CODE_SAVE';
export const FETCH_BILLING_CODES = 'FETCH_BILLING_CODES';


////////////////////////////////
// Clinical out patient form //
//////////////////////////////

export const SAVE_CLINICAL_OUT_PATIENT_FORM_IN_PROCESS = 'SAVE_CLINICAL_OUT_PATIENT_FORM_IN_PROCESS';
export const SAVE_CLINICAL_OUT_PATIENT_FORM_UPDATED = 'SAVE_CLINICAL_OUT_PATIENT_FORM_UPDATED';
export const SET_SUBJECTIVE_SYMPTOMS = 'SET_SUBJECTIVE_SYMPTOMS';
export const SET_FINDINGS = 'SET_FINDINGS';
export const SET_ASSESSMENT = 'SET_ASSESSMENT';
export const SET_PLAN = 'SET_PLAN';


export const FOLLOW_UP = 'follow_up';
export const FOLLOW_UP_CHANGE = 'follow_up_change';
export const FOLLOW_UP_REMOVAL = 'FOLLOW_UP_REMOVAL';

////////////////////////////////////
// Clinical Patients Registration //
////////////////////////////////////

export const CLINICAL_PATIENTS_FETCH_SUCCESS = 'CLINICAL_PATIENTS_FETCH_SUCCESS';
export const CLINICAL_PATIENTS_FETCH_CLEAR = 'CLINICAL_PATIENTS_FETCH_CLEAR';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const FETCH_CLINICALDOCTORS_SUCCESS = 'FETCH_CLINICALDOCTORS_SUCCESS';
export const FETCH_CLINICAL_REPORTS_HISTORY = 'FETCH_CLINICAL_REPORTS_HISTORY';
export const SCHEDULE_SLOTS = 'SCHEDULE_SLOTS';
export const SLOTS_LOADING = 'SLOTS_LOADING';




////////////////////
// Create Clinic //
//////////////////

export const CREATE_CLINIC_PROCESS = 'CREATE_CLINIC_PROCESS';
export const CLINICAL_ADMIN_CREATED_SUCCESS = 'CLINICAL_ADMIN_CREATED_SUCCESS';
export const CLINICAL_ADMIN_CREATED_FAILED = 'CLINICAL_ADMIN_CREATED_FAILED';
export const CLINICAL_ADMIN_ROLE_ASSIGNED_SUCCESS = 'CLINICAL_ADMIN_ROLE_ASSIGNED_SUCCESS';
export const CLINICAL_DATA_STORED_SUCCESS = 'CLINICAL_ADMIN_ROLE_ASSIGNED_SUCCESS';

export const ADD_CLINIC_DATA_PROCESS = 'ADD_CLINIC_DATA_PROCESS';
export const ADD_CLINIC_DATA_SUCCESS = 'ADD_CLINIC_DATA_SUCCESS';
export const ADD_CLINIC_DATA_FAILED = 'ADD_CLINIC_DATA_FAILED';

export const GET_CLINIC_DATA_PROCESS = 'GET_CLINIC_DATA_PROCESS';
export const GET_CLINIC_DATA_SUCCESS = 'GET_CLINIC_DATA_SUCCESS';
export const GET_CLINIC_DATA_FAILED = 'GET_CLINIC_DATA_FAILED';

export const CLINIC_ADMIN_NAME = 'CLINIC_ADMIN_NAME';
export const FULL_NAME = 'FULL_NAME';
export const CLINIC_ADMIN_ADDRESS = 'CLINIC_ADMIN_ADDRESS';
export const CLINIC_PROVINCE = 'CLINIC_PROVINCE';
export const CLINIC_CITY = 'CLINIC_CITY';
export const CLINIC_POSTAL_CODE = 'CLINIC_POSTAL_CODE';
export const CLINIC_COUNTRY = 'CLINIC_COUNTRY';
export const CLINIC_ADMIN_PHONE_NUMBER = 'CLINIC_ADMIN_PHONE_NUMBER';
export const CLINIC_FAX = 'CLINIC_FAX';
export const CLINIC_EMAIL_ADDRESS = 'CLINIC_EMAIL_ADDRESS';
export const CLINIC_PHYSICIAN_USER = 'CLINIC_PHYSICIAN_USER';
export const CLINIC_LICENSE_NO = 'CLINIC_LICENSE_NO';
export const CLINIC_DR_BILLING_NO = 'CLINIC_DR_BILLING_NO';

export const CLINIC_REPORT_EMAIL_ADDRESS = 'CLINIC_REPORT_EMAIL_ADDRESS';
export const CLINIC_ADMIN_PROFILE_IMAGE = 'CLINIC_ADMIN_PROFILE_IMAGE';
export const CLINIC_ADMIN_SIGNATURE = 'CLINIC_ADMIN_SIGNATURE';

export const ADMIN_PROFILE_SETTING_PROCESS = 'ADMIN_PROFILE_SETTING_PROCESS';
export const ADMIN_PROFILE_SETTING_SUCCESS = 'ADMIN_PROFILE_SETTING_SUCCESS';
export const ADMIN_PROFILE_SETTING_FAILED = 'ADMIN_PROFILE_SETTING_FAILED';
export const LISTEN_CLINIC_DATA = "LISTEN_CLINIC_DATA";


export const STAFF_EMAIL_ADDRESS = 'STAFF_EMAIL_ADDRESS';
export const OWNER_PROFILE = 'OWNER_PROFILE';
export const ADMIN_PROFILE = 'ADMIN_PROFILE';
export const PHYSICIAN_PROFILE = 'PHYSICIAN_PROFILE';
export const NURSE_PROFILE = 'NURSE_PROFILE';
export const RECEPTIONIST_PROFILE = 'RECEPTIONIST_PROFILE';
export const CUSTOM_PROFILE = 'CUSTOM_PROFILE';

export const ADD_MORE_USERS_INTO_STAFF = 'ADD_MORE_USERS_INTO_STAFF';
export const CREATE_PRESCRIPTIONS = 'CREATE_PRESCRIPTIONS';
export const INVITE_PATIENTS = 'INVITE_PATIENTS';
export const STAFF_SOAP_NOTES = 'STAFF_SOAP_NOTES';
export const VIEW_PATIENTS_IN_QUEUE = 'VIEW_PATIENTS_IN_QUEUE';
export const ANSWER_PATIENTS = 'ANSWER_PATIENTS';
export const TRANSFER_CHAT = 'TRANSFER_CHAT';
export const VIEW_ARCHIVES = 'VIEW_ARCHIVES';
export const VIEW_INSURANCE_INFO = 'VIEW_INSURANCE_INFO';
export const GET_REPORTS = 'GET_REPORTS';
export const NEED_STAFF_ADDRESS = 'NEED_STAFF_ADDRESS';
export const STAFF_ADDRESS = 'STAFF_ADDRESS';
export const STAFF_COUNTRY = 'STAFF_COUNTRY';
export const STAFF_PROVINCE = 'STAFF_PROVINCE';
export const STAFF_CITY = 'STAFF_CITY';
export const STAFF_MEMEBER_PHONE_NUMBER = 'STAFF_MEMEBER_PHONE_NUMBER';
export const STAFF_FAX_NUMBER = 'STAFF_FAX_NUMBER';

export const ACCOUNT_TYPE = 'ACCOUNT_TYPE';
export const STAFF_FULL_NAME = 'STAFF_FULL_NAME';
export const STAFF_PHONE = 'STAFF_PHONE';
export const STAFF_PHONE_NUMBER = 'STAFF_PHONE_NUMBER';
export const STAFF_LICENSE_NO = 'STAFF_LICENSE_NO';
export const STAFF_PROFILE_IMAGE = 'STAFF_PROFILE_IMAGE';
export const STAFF_SIGNATURE = 'STAFF_SIGNATURE';

export const INVITE_STAFF_PROCESS = 'INVITE_STAFF_PROCESS';
export const INVITE_STAFF_SUCCESS = 'INVITE_STAFF_SUCCESS';
export const INVITE_STAFF_FAILED = 'INVITE_STAFF_FAILED';

export const GET_CLINIC_DATA = 'GET_CLINIC_DATA';
export const FETCH_STAFF_MEMBERS = 'FETCH_STAFF_MEMBERS';

export const RESET_FORGORT_PASSWORD_STATES = 'RESET_FORGORT_PASSWORD_STATES';
export const FORGOT_PASSWORD_IN_PROGRESS = 'FORGOT_PASSWORD_IN_PROGRESS';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';

export const CLINIC_TAG_CHANGE = 'CLINIC_TAG_CHANGE';
export const CLINIC_TAG_SAVE = 'CLINIC_TAG_SAVE';
export const CLINIC_FETCH_TAGS = 'CLINIC_FETCH_TAGS';

export const CLINIC_BILLING_CODE_CHANGE = 'CLINIC_BILLING_CODE_CHANGE';
export const CLINIC_BILLING_CODE_DESC_CHANGE = 'CLINIC_BILLING_CODE_DESC_CHANGE';
export const CLINIC_BILLING_CODE_SAVE = 'CLINIC_BILLING_CODE_SAVE';
export const CLINIC_FETCH_BILLING_CODES = 'CLINIC_FETCH_BILLING_CODES';

export const CLINIC_CANNED_RESPONSE_DESCRIPTION = 'CLINIC_CANNED_RESPONSE_DESCRIPTION';
export const CLINIC_CANNED_RESPONSE_SHORT_CUT = 'CLINIC_CANNED_RESPONSE_SHORT_CUT';
export const CLINIC_CANNED_RESPONSE_SAVE = 'CLINIC_CANNED_RESPONSE_SAVE';
export const CLINIC_FETCH_CANNED_RESPONSE = 'CLINIC_FETCH_CANNED_RESPONSE';
export const CLINIC_CANNED_RESPONSE_UPDATE = 'CLINIC_CANNED_RESPONSE_UPDATE';
export const CLINIC_CANNED_RESPONSE_DELETE = 'CLINIC_CANNED_RESPONSE_DELETE';
export const CLINIC_FETCH_CHAT_CANNED_RESPONSE = 'CLINIC_FETCH_CHAT_CANNED_RESPONSE';
export const CLINIC_FETCH_CANNED_RESPONSE_ASSESSMENT = 'CLINIC_FETCH_CANNED_RESPONSE_ASSESSMENT';
export const CLINIC_FETCH_CANNED_RESPONSE_PLAN = 'CLINIC_FETCH_CANNED_RESPONSE_PLAN';

export const CLINIC_SUBJECTIVE_SYMPTOMS = 'CLINIC_SUBJECTIVE_SYMPTOMS';
export const CLINIC_FINDINGS = 'CLINIC_FINDINGS';
export const CLINIC_ASSESSMENT = 'CLINIC_ASSESSMENT';
export const CLINIC_PLAN = 'CLINIC_PLAN';

export const FETCHING_CLINIC_MESSAGES = 'FETCHING_CLINIC_MESSAGES';
export const FETCH_CLINIC_MESSAGES = 'FETCH_CLINIC_MESSAGES';
export const SENDING_CLINIC_MESSAGE = 'SENDING_CLINIC_MESSAGE';
export const FETCH_CLINIC_STATUS = 'FETCH_CLINIC_STATUS';

export const SET_VIDEO_STATUS = 'SET_VIDEO_STATUS';
export const MEDIA_HEADER_DATA = 'MEDIA_HEADER_DATA';
export const SET_PAYPERMINUTE = 'SET_PAYPERMINUTE'

///////////////////////////
// CLINICAL ACCEPT CHAT //
/////////////////////////

export const CLINIC_ACCEPTING_CHAT = 'CLINIC_ACCEPTING_CHAT';


export const REFRESH_DOCTOR_STATUS = 'REFRESH_DOCTOR_STATUS';
export const REFRESH_TAGS_STATUS = 'REFRESH_TAGS_STATUS';
export const REFRESH_CANNED_STATUS = 'REFRESH_CANNED_STATUS';
export const REFRESH_AGENT_STATUS = 'REFRESH_AGENT_STATUS';
