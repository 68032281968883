import  {Component} from 'react';
import {Document, Font, Page, StyleSheet, Text, View} from '@react-pdf/renderer';
import timesnewroman from './fonts/timesnewroman.ttf';
import timesnewromanbold from './fonts/timesnewromanbold.ttf';
import timesnewromanbolditalic from './fonts/timesnewromanbolditalic.ttf';
import timesnewromanitalic from './fonts/timesnewromanitalic.ttf';
import arial from './fonts/ARIAL.TTF';
import arialbold from './fonts/ARIALBD.TTF';
import arialbolditalic from './fonts/ARIALBI.TTF';
import arialitalic from './fonts/ARIALI.TTF';

var moment = require("moment");
Font.register({
    family: 'serif', fonts: [
        {src: timesnewroman}, // font-style: normal, font-weight: normal
        {src: timesnewromanitalic, fontStyle: 'italic'},
        {src: timesnewromanbold, fontWeight: 700},
        {src: timesnewromanbolditalic, fontStyle: 'italic', fontWeight: 700},
    ]
});

Font.register({
    family: 'arial', fonts: [
        {src: arial}, // font-style: normal, font-weight: normal
        {src: arialitalic, fontStyle: 'italic'},
        {src: arialbold, fontWeight: 500},
        {src: arialbolditalic, fontStyle: 'italic', fontWeight: 500},
    ]
});
const styles = StyleSheet.create({
    body: {
        padding: 10
    },
    page: {
        flexDirection: 'column',
        backgroundColor: 'white',
        paddingHorizontal: 30,
        fontFamily: "arial",
        fontSize: "12",
        paddingVertical: 5,
        display: 'flex'

    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        top: 16,
    },
    tableFamily: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        top: 83,
    },
    tableSocial: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        top: 30,
    },
    tableMeds: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        top: 71,
    },
    tableHealth: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        top: 45,
    },
    tableAllergies: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        top: 58,
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableColHeader: {
        width: "33.3%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: '#894A9E'
    },
    tableColHeaderAllergy: {
        width: "50%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: '#894A9E'
    },
    tableColHeaderMed: {
        width: "25%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: '#894A9E'
    },
    tableCol: {
        width: "33.3%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableColMed: {
        width: "25%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableColAllergy: {
        width: "50%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCellHeader: {
        margin: "auto",
        margin: 5,
        fontSize: 12,
        fontWeight: 500,
        color: 'white'
    },
    tableCell: {
        margin: "auto",
        margin: 5,
        fontSize: 10
    }
})

// Create Document Component
class PatientProfile extends Component {
    onDocumentLoadSuccess = (numPages) => {
    }
    render = () => {
        var self = this


        return (

            <Document
            >
                <Page size={'a4'} style={styles.page} debug="true">
                    <Text style={{alignSelf: 'center', fontWeight: 'bold', fontSize: 14}}>Patient Medical History</Text>
                    <Text style={{
                        alignSelf: 'center',
                        fontWeight: 'bold',
                        top: 2,
                        fontSize: 14
                    }}>{this.props.patientName}</Text>
                    <Text
                        style={{alignSelf: 'center', fontWeight: 'bold', top: 4, fontSize: 14}}>{this.props.dob}</Text>
                    <Text style={{alignSelf: 'center', fontWeight: 'bold', top: 6, fontSize: 14}}>Your Doctors
                        Online</Text>
                    <hr style={{
                        color: '#000000',
                        backgroundColor: '#000000',
                        height: .5,
                        borderColor: '#000000',
                        top: 10
                    }}/>


                    <Text style={{top: 30, fontSize: 14, fontWeight: 'bold'}}>Social History</Text>
                    <View style={styles.tableSocial}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>Condition</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>Answer</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>Date Modified</Text>
                            </View>

                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Smoke/Use Tobacco</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text
                                    style={styles.tableCell}>{this.props.patient_profile.smokeFlag == true ? "Yes" : "No"}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.props.patient_profile.smokeDate}</Text>
                            </View>

                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>How frequently do you use tobacco?</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text
                                    style={styles.tableCell}>{this.props.patient_profile.smokeAnswer == '' ? '-' : this.props.patient_profile.smokeAnswer}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text
                                    style={styles.tableCell}>{this.props.patient_profile.smokeQueDate == null || this.props.patient_profile.smokeQueDate == undefined || this.props.patient_profile.smokeQueDate == '' ? '-' : this.props.patient_profile.smokeQueDate}</Text>
                            </View>

                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Drink Alcohol</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text
                                    style={styles.tableCell}>{this.props.patient_profile.alcoholFlag == true ? 'Yes' : 'No'}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.props.patient_profile.alcoholDate}</Text>
                            </View>

                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>How frequently do you use alcohol?</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text
                                    style={styles.tableCell}>{this.props.patient_profile.alocoholAnswer == '' ? '-' : this.props.patient_profile.alocoholAnswer}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text
                                    style={styles.tableCell}>{this.props.patient_profile.alcoholQueDate == null || this.props.patient_profile.alcoholQueDate == undefined || this.props.patient_profile.alcoholQueDate == '' ? '-' : this.props.patient_profile.alcoholQueDate}</Text>
                            </View>

                        </View>
                    </View>

                    <Text style={{top: 42, fontSize: 14, fontWeight: 'bold'}}>Health Problems</Text>
                    <View style={styles.tableHealth}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>Condition</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>Answer</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>Date Modified</Text>
                            </View>

                        </View>
                        {this.props.patient_profile.asthmaFlag == true &&

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Asthama</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Yes</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.props.patient_profile.asthamaDate}</Text>
                            </View>

                        </View>
                        }
                        {this.props.patient_profile.kidneyFlag == true &&

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Kidney</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Yes</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.props.patient_profile.kidneyDate}</Text>
                            </View>

                        </View>
                        }
                        {this.props.patient_profile.bpFlag == true &&

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>High Blood Pressure</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Yes</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.props.patient_profile.bpDate}</Text>
                            </View>

                        </View>
                        }
                        {this.props.patient_profile.diabetesFlag == true &&

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Diabetes</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Yes</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.props.patient_profile.diabetesDate}</Text>
                            </View>

                        </View>
                        }
                        {this.props.patient_profile.heartFlag == true &&

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Heart Problems</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Yes</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.props.patient_profile.heartDate}</Text>
                            </View>

                        </View>
                        }
                        {this.props.patient_profile.headFlag == true &&

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Headaches/Migraines</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Yes</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.props.patient_profile.headDate}</Text>
                            </View>

                        </View>
                        }
                        {this.props.patient_profile.utiFlag == true &&

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Urinary Tract Infection</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Yes</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.props.patient_profile.utiDate}</Text>
                            </View>

                        </View>
                        }
                        {this.props.patient_profile.depressionsFlag == true &&

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Depression</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Yes</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.props.patient_profile.depressionsDate}</Text>
                            </View>

                        </View>
                        }
                        {this.props.patient_profile.seizureFlag == true &&

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Seizures</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Yes</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.props.patient_profile.seizureDate}</Text>
                            </View>

                        </View>
                        }
                        {this.props.patient_profile.strokeFlag == true &&

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Stroke</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Yes</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.props.patient_profile.strokeDate}</Text>
                            </View>

                        </View>
                        }
                        {this.props.patient_profile.thyroidFlag == true &&

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Thyroid Disease</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Yes</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.props.patient_profile.thyroidDate}</Text>
                            </View>

                        </View>
                        }
                        {this.props.patient_profile.arrhythmiasFlag == true &&

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Arrhythmias</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Yes</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.props.patient_profile.arrhythmiasDate}</Text>
                            </View>

                        </View>
                        }
                        {this.props.patient_profile.anxietyFlag == true &&

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Anxiety</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Yes</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.props.patient_profile.anxietyDate}</Text>
                            </View>

                        </View>
                        }
                        {this.props.patient_profile.panicFlag == true &&

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Panic Attacks</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Yes</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.props.patient_profile.panicDate}</Text>
                            </View>

                        </View>
                        }

                        {this.props.patient_profile.eczemaFlag == true &&

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Eczema</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Yes</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.props.patient_profile.eczemaDate}</Text>
                            </View>

                        </View>
                        }
                        {this.props.patient_profile.copdFlag == true &&

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>COPD</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Yes</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.props.patient_profile.copdDate}</Text>
                            </View>

                        </View>
                        }
                        {this.props.patient_profile.psoriasisFlag == true &&

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Psoriasis</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Yes</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.props.patient_profile.psoriasisDate}</Text>
                            </View>

                        </View>
                        }
                        {this.props.patient_profile.cancerFlag == true &&

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Cancer</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.props.patient_profile.cancerData.toString()}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.props.patient_profile.cancerDate}</Text>
                            </View>

                        </View>
                        }
                        {this.props.patient_profile.otherFlag == true &&

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Other</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.props.patient_profile.otherData.toString()}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{this.props.patient_profile.otherDate}</Text>
                            </View>

                        </View>
                        }
                    </View>
                    <View wrap={false}>
                        {this.props.patient_profile != null && this.props.patient_profile != undefined && this.props.patient_profile.allergyData != null && this.props.patient_profile.allergyData != undefined && this.props.patient_profile.allergyData.length > 0 &&
                        <Text wrap={false} style={{top: 55, fontSize: 14, fontWeight: 'bold'}}>Allergies</Text>
                        }
                        {this.props.patient_profile != null && this.props.patient_profile != undefined && this.props.patient_profile.allergyData != null && this.props.patient_profile.allergyData != undefined && this.props.patient_profile.allergyData.length > 0 &&
                        <View wrap={false} style={styles.tableAllergies}>
                            <View style={styles.tableRow}>
                                <View style={styles.tableColHeaderAllergy}>
                                    <Text style={styles.tableCellHeader}>AllergyType</Text>
                                </View>

                                <View style={styles.tableColHeaderAllergy}>
                                    <Text style={styles.tableCellHeader}>Date Modified</Text>
                                </View>

                            </View>
                            {this.props.patient_profile.allergyData.map(function (item, i) {
                                return (
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColAllergy}>
                                            <Text
                                                style={styles.tableCell}>{self.props.patient_profile.allergyData[i]}</Text>
                                        </View>
                                        <View style={styles.tableColAllergy}>
                                            <Text
                                                style={styles.tableCell}>{self.props.patient_profile.allergyDates[i]}</Text>
                                        </View>


                                    </View>
                                )
                            })}

                        </View>
                        }


                    </View>
                    <View wrap={false}>
                        {this.props.patient_profile != null && this.props.patient_profile != undefined && this.props.patient_profile.selected != null && this.props.patient_profile.selected != undefined &&
                        <Text style={{top: 68, fontSize: 14, fontWeight: 'bold'}}>Medications</Text>
                        }
                        {this.props.patient_profile != null && this.props.patient_profile != undefined && this.props.patient_profile.selected != null && this.props.patient_profile.selected != undefined && this.props.patient_profile.selected.length > 0 &&
                        <View style={styles.tableMeds}>
                            <View style={styles.tableRow}>
                                <View style={styles.tableColHeaderMed}>
                                    <Text style={styles.tableCellHeader}>Medication</Text>
                                </View>

                                <View style={styles.tableColHeaderMed}>
                                    <Text style={styles.tableCellHeader}>Dosage</Text>
                                </View>
                                <View style={styles.tableColHeaderMed}>
                                    <Text style={styles.tableCellHeader}>Direction</Text>
                                </View>
                                <View style={styles.tableColHeaderMed}>
                                    <Text style={styles.tableCellHeader}>Date Modified</Text>
                                </View>

                            </View>
                            {this.props.patient_profile.selected.map(function (item, i) {
                                let direction = self.props.patient_profile.selected[i].split(" ")
                                direction = direction[direction.length - 1]
                                let medName = self.props.patient_profile.selected[i]
                                medName = medName.substring(0, medName.lastIndexOf(" "))
                                return (
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColMed}>
                                            <Text style={styles.tableCell}>{medName}</Text>
                                        </View>
                                        <View style={styles.tableColMed}>
                                            <Text style={styles.tableCell}>{self.props.patient_profile.dosage[i]}</Text>
                                        </View>
                                        <View style={styles.tableColMed}>
                                            <Text style={styles.tableCell}>{direction}</Text>
                                        </View>
                                        <View style={styles.tableColMed}>
                                            <Text
                                                style={styles.tableCell}>{self.props.patient_profile.medDates[i]}</Text>
                                        </View>


                                    </View>
                                )
                            })}

                        </View>
                        }

                    </View>
                    <View wrap={false}>


                        <Text style={{top: 80, fontSize: 14, fontWeight: 'bold'}}>Family Problems</Text>
                        <View style={styles.tableFamily}>
                            <View style={styles.tableRow}>
                                <View style={styles.tableColHeader}>
                                    <Text style={styles.tableCellHeader}>Condition</Text>
                                </View>
                                <View style={styles.tableColHeader}>
                                    <Text style={styles.tableCellHeader}>Relationship</Text>
                                </View>
                                <View style={styles.tableColHeader}>
                                    <Text style={styles.tableCellHeader}>Date Modified</Text>
                                </View>

                            </View>
                            {this.props.patient_profile.asthmaFlagFamily == true &&

                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Asthama</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.asthamaAnswer}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.asthamaFamilyDate}</Text>
                                </View>

                            </View>
                            }
                            {this.props.patient_profile.kidneyFlagFamily == true &&

                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Kidney Problems</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.kidneyAnswer}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.kidneyFamilyDate}</Text>
                                </View>

                            </View>
                            }
                            {this.props.patient_profile.bpFlagFamily == true &&

                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>High Blood Pressure</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.bpAnswer}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.bpFamilyDate}</Text>
                                </View>

                            </View>
                            }
                            {this.props.patient_profile.diabetesFlagFamily == true &&

                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Diabetes</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.diabetesAnswer}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text
                                        style={styles.tableCell}>{this.props.patient_profile.diabetesFamilyDate}</Text>
                                </View>

                            </View>
                            }
                            {this.props.patient_profile.heartFlagFamily == true &&

                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Heart Problems</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.heartAnswer}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.heartFamilyDate}</Text>
                                </View>

                            </View>
                            }
                            {this.props.patient_profile.headFlagFamily == true &&

                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Headaches/Migraines</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.headAnswer}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.headFamilyDate}</Text>
                                </View>

                            </View>
                            }
                            {this.props.patient_profile.utiFlagFamily == true &&

                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Urinary Tract Infection</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.utiAnswer}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.utiFamilyDate}</Text>
                                </View>

                            </View>
                            }
                            {this.props.patient_profile.depressionsFlagFamily == true &&

                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Depression</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.depressionsAnswer}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text
                                        style={styles.tableCell}>{this.props.patient_profile.depressionsFamilyDate}</Text>
                                </View>

                            </View>
                            }
                            {this.props.patient_profile.seizureFlagFamily == true &&

                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Seizures</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.seizureAnswer}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.seizureFamilyDate}</Text>
                                </View>

                            </View>
                            }
                            {this.props.patient_profile.strokeFlagFamily == true &&

                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Stroke</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.strokeAnswer}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.strokeFamilyDate}</Text>
                                </View>

                            </View>
                            }
                            {this.props.patient_profile.thyroidFlagFamily == true &&

                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Thyroid Disease</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.thyroidAnswer}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.thyroidFamilyDate}</Text>
                                </View>

                            </View>
                            }
                            {this.props.patient_profile.arrhythmiasFlagFamily == true &&

                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Arrhythmias</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.arrhythmiasAnswer}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text
                                        style={styles.tableCell}>{this.props.patient_profile.arrhythmiasFamilyDate}</Text>
                                </View>

                            </View>
                            }
                            {this.props.patient_profile.anxietyFlagFamily == true &&

                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Anxiety</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.anxietyAnswer}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.anxietyFamilyDate}</Text>
                                </View>

                            </View>
                            }
                            {this.props.patient_profile.panicFlagFamily == true &&

                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Panic Attacks</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.panicAnswer}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.panicFamilyDate}</Text>
                                </View>

                            </View>
                            }

                            {this.props.patient_profile.eczemaFlagFamily == true &&

                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Eczema</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.eczemaAnswer}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.eczemaFamilyDate}</Text>
                                </View>

                            </View>
                            }
                            {this.props.patient_profile.copdFlagFamily == true &&

                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>COPD</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.copdAnswer}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.copdFamilyDate}</Text>
                                </View>

                            </View>
                            }
                            {this.props.patient_profile.psoriasisFlagFamily == true &&

                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Psoriasis</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.psoriasisAnswer}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text
                                        style={styles.tableCell}>{this.props.patient_profile.psoriasisFamilyDate}</Text>
                                </View>

                            </View>
                            }

                            {this.props.patient_profile.cancerFlagFamily == true &&
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text
                                        style={styles.tableCell}>Cancer{"(" + this.props.patient_profile.cancerFamilyData.toString() + ")"}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text
                                        style={styles.tableCell}>{this.props.patient_profile.cancerFamilyAnswer.toString()}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.cancerFamilyDate}</Text>
                                </View>

                            </View>
                            }
                            {this.props.patient_profile.otherFlagFamily == true &&
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text
                                        style={styles.tableCell}>Other{"(" + this.props.patient_profile.otherFamilyData.toString() + ")"}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text
                                        style={styles.tableCell}>{this.props.patient_profile.otherFamilyAnswer.toString()}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{this.props.patient_profile.otherFamilyDate}</Text>
                                </View>

                            </View>
                            }
                        </View>
                    </View>


                </Page>

            </Document>
        )
    };
}

export default PatientProfile;
