import {
    FETCH_ALL_ARCHIVES_STATUS,
    FETCH_ARCHIVE_MESSAGES_SUCCESS,
    FETCH_ARCHIVES_LOADING,
    FETCH_ARCHIVES_STATUS,
    FETCH_ARCHIVES_STATUS_FAILED,
    FETCHING_ARCHIVE_MESSAGES,
    RESET_ARCHIVE_CHAT,
    SEARCH_ARCHIVES,
} from "../constants";


import {ENVIRONMENT} from "../environment";
import _ from "lodash";
import algoliasearch from "algoliasearch";
import {auth, database} from "../firebase";

var client_store = algoliasearch("FL1OI9QKUW", "f0427fde3618b1db1bfb004c623215db");

var index_store = null;

export const setAlgolia = clinicid => {
    return dispatch => {
        // convertArchivesData();
        if (clinicid) {
            index_store = client_store.initIndex(clinicid + "_conversations");
        } else {
            if (ENVIRONMENT === "development") {
                index_store = client_store.initIndex("stg_conversations");
            } else {
                index_store = client_store.initIndex("conversations");
            }
        }
    };
};
export const fetchArchives = (archives, timestamp) => {
    return dispatch => {
        // convertArchivesData();
        fetchArchivesData(dispatch, archives, timestamp);
    };
};

const convertArchivesData = () => {
    getOpenArchivesRef().once("value", archiveSnap => {
        if (archiveSnap.val()) {
            archiveSnap.forEach(valSnap => {
                if (!valSnap.val().status) {
                    var updates = {};
                    updates[`/archiveConversations/${valSnap.key}`] = valSnap.val();
                    updates[`/openconversations/${valSnap.key}`] = null;
                    database
                        .ref()
                        .update(updates)
                        .then(data => {
                        })
                        .catch(error => {
                        });
                }
            });
        }
    });
};

const fetchArchivesData = (dispatch, oldArchives, timestamp) => {
    dispatch({type: FETCH_ARCHIVES_LOADING});
    var archives = [];

    let archive_list = getArchivesRef().orderByChild("timestamp").limitToLast(100);
    if (Boolean(timestamp)) archive_list = archive_list.endAt(timestamp);
    archive_list.once("value", archiveSnap => {
        if (archiveSnap.val()) {
            var count = archiveSnap.numChildren();
            var i = 0;
            var archive_index = 0;
            archiveSnap.forEach(valSnap => {
                archive_index = archive_index + 1;
                if (!valSnap.val().status) {
                    getdoctorByRef(valSnap.val().doctorID).once("value", doctorSnap => {
                        if (doctorSnap.val()) {
                            getUserRef(valSnap.val().patientID).once("value", patientSnap => {
                                if (patientSnap.val()) {

                                    let owner = valSnap.val()?.owner === valSnap.val().patientID? null:  valSnap.val()?.owner  || null;
                                    let ownerProfile = owner? patientSnap.val().profiles? patientSnap.val().profiles[owner] : null : null;
                                    if (ownerProfile){
                                        ownerProfile.uid = owner;
                                    }
                                    let userName =
                                        (patientSnap.val().userName === "No name" ||
                                            patientSnap.val().userName === "Patient") &&
                                        patientSnap.val().name
                                            ? patientSnap.val().name
                                            : patientSnap.val().userName;
                                    let archiveData = valSnap.val();
                                    let chatCount = patientSnap.val().history_conversations;

                                    archiveData.conversationID = valSnap.key;
                                    archiveData.patientEmail = patientSnap.val().email;
                                    archiveData.city = patientSnap.val().city;
                                    archiveData.country = patientSnap.val().country;
                                    archiveData.platform = patientSnap.val().platform;
                                    archiveData.DOB = patientSnap.val().DOB;
                                    archiveData.ownerProfile = ownerProfile;
                                    archiveData.gender = patientSnap.val().gender;
                                    archiveData.symptoms = archiveData?.symptoms || archiveData?.followUpData?.diagnosis
                                    archiveData.currentMedication = archiveData?.medications || patientSnap.val().medication;
                                    archiveData.gmtOffset = patientSnap.val().gmtOffset;
                                    archiveData.buildNumber = patientSnap.val().buildNumber;
                                    archiveData.patientName = userName;
                                    archiveData.convoCount =
                                        chatCount !== null && chatCount !== undefined
                                            ? Object.keys(chatCount).length
                                            : 0;
                                    archiveData.doctorName = doctorSnap.val().doctorName;
                                    archiveData.doctorID = valSnap.val().doctorID;
                                    archiveData.doctorProfileImg = doctorSnap.val().profileImageURL;
                                    archiveData.doctorEmail = doctorSnap.val().email;
                                    archiveData.healthInsuranceCardBack = patientSnap.val().healthInsuranceCardBack;
                                    archiveData.healthInsuranceCardFront = patientSnap.val().healthInsuranceCardFront;
                                    archiveData.healthInsuranceCardNumber = patientSnap.val().healthInsuranceCardNumber;
                                    archives.push(archiveData);

                                    if (count - 1 === i) {
                                        returnArchives(dispatch, oldArchives, archives, count, i);
                                    } else {
                                        i = i + 1;
                                    }
                                } else {
                                    i = i + 1;
                                    if (count === i && archives.length === 0) {
                                        dispatch({
                                            type: FETCH_ARCHIVES_STATUS,
                                            payload: [],
                                        });
                                    } else if (count === i && archives.length !== 0) {
                                        returnArchives(dispatch, oldArchives, archives, count, i);
                                    }
                                }
                            });
                        } else {
                            i = i + 1;
                            if (count === i && archives.length === 0) {
                                dispatch({
                                    type: FETCH_ARCHIVES_STATUS,
                                    payload: [],
                                });
                            } else if (count === i && archives.length !== 0) {
                                returnArchives(dispatch, oldArchives, archives, count, i);
                            }
                        }
                    });
                } else {
                    i = i + 1;
                    if (count === i && archives.length === 0) {
                        dispatch({
                            type: FETCH_ARCHIVES_STATUS,
                            payload: [],
                        });
                    } else if (count === i && archives.length !== 0) {
                        returnArchives(dispatch, oldArchives, archives, count, i);
                    }
                }
            });
        } else {
            dispatch({
                type: FETCH_ARCHIVES_STATUS,
                payload: [],
            });
        }
    });
};

const storeIndex = (archiveData, i) => {
    if (i === archiveData.length) {
        return;
    }

    return index_store
        .addObject(archiveData[i])
        .then(data => {
            storeIndex(archiveData, i + 1);
        })
        .catch(error => {
            storeIndex(archiveData, i + 1);
        });
};

export const fetchAllArchivesData = () => {
    return dispatch => {
        database
            .ref()
            .child("archiveConversations")
            .orderByChild("timestamp")
            .limitToLast(10)
            .once("value", archiveSnap => {
                if (archiveSnap.val()) {
                    var count = archiveSnap.numChildren();
                    var i = 0;
                    var archives = [];
                    archiveSnap.forEach(valSnap => {
                        if (!valSnap.val().status) {
                            var convos = "";

                            database
                                .ref(`/conversations/${valSnap.key}`)
                                .once("value", convoSnap => {
                                    convoSnap.forEach(convoInstance => {
                                        if (convoInstance.val().type === "text") {
                                            convos = convos + convoInstance.val().content;
                                        }
                                    });
                                })
                                .then(data => {
                                    database
                                        .ref(`/doctors/${valSnap.val().doctorID}`)
                                        .once("value", doctorSnap => {
                                            if (doctorSnap.val()) {
                                                database
                                                    .ref(`/users/${valSnap.val().patientID}`)
                                                    .once("value", patientSnap => {
                                                        if (patientSnap.val()) {
                                                            let userName =
                                                                (patientSnap.val().userName === "No name" ||
                                                                    patientSnap.val().userName === "Patient") &&
                                                                patientSnap.val().name
                                                                    ? patientSnap.val().name
                                                                    : patientSnap.val().userName;
                                                            let archiveData = valSnap.val();
                                                            let tags = "";
                                                            if (Boolean(archiveData.tagsList)) {
                                                                var tagslist = _.map(archiveData.tagsList, (val, uid) => {
                                                                    return {...val, uid};
                                                                });
                                                                tagslist.forEach(tag => {
                                                                    if (Boolean(tag.name)) {
                                                                        tags = tags + ", " + tag.name;
                                                                    }
                                                                });
                                                            }

                                                            archiveData.tags = tags;
                                                            archiveData.objectID = valSnap.key;
                                                            archiveData.conversations = convos;
                                                            archiveData.category = valSnap.val().category;
                                                            archiveData.doctorID = valSnap.val().doctorID;
                                                            archiveData.patientID = valSnap.val().patientID;
                                                            let chatCount = patientSnap.val().history_conversations;
                                                            archiveData.conversationID = valSnap.key;
                                                            archiveData.patientEmail = patientSnap.val().email;
                                                            archiveData.city = patientSnap.val().city;
                                                            archiveData.country = patientSnap.val().country;
                                                            archiveData.platform = patientSnap.val().platform;
                                                            archiveData.DOB = patientSnap.val().DOB;
                                                            archiveData.gender = patientSnap.val().gender;
                                                            archiveData.symptoms = patientSnap?.val()?.symptoms || archiveData?.followUpData?.diagnosis
                                                            archiveData.medication = patientSnap.val().medication;
                                                            archiveData.gmtOffset = patientSnap.val().gmtOffset;
                                                            archiveData.buildNumber = patientSnap.val().buildNumber;
                                                            archiveData.patientName = userName;
                                                            archiveData.convoCount =
                                                                chatCount !== null && chatCount !== undefined
                                                                    ? Object.keys(chatCount).length
                                                                    : 0;
                                                            archiveData.doctorName = doctorSnap.val().doctorName;
                                                            archiveData.doctorID = valSnap.val().doctorID;
                                                            archiveData.doctorProfileImg = doctorSnap.val().profileImageURL;
                                                            archiveData.doctorEmail = doctorSnap.val().email;
                                                            archiveData.user_id = patientSnap.val().user_id;
                                                            archives.push(archiveData);

                                                            if (i === count - 1) {
                                                                storeIndex(archives, 0);
                                                                dispatch({
                                                                    type: FETCH_ALL_ARCHIVES_STATUS,
                                                                    payload: [],
                                                                });
                                                            } else {
                                                                i = i + 1;
                                                            }
                                                        } else {
                                                            i = i + 1;
                                                        }
                                                    })
                                                    .then(data => {
                                                    })
                                                    .catch(error => {
                                                    });
                                            } else {
                                                i = i + 1;
                                            }
                                        })
                                        .then(data => {
                                        })
                                        .catch(error => {
                                        });
                                })
                                .catch(error => {
                                });
                        } else {
                            i = i + 1;
                        }
                    });
                } else {

                }
            })
            .then(data => {
                dispatch({
                    type: FETCH_ALL_ARCHIVES_STATUS,
                    payload: [],
                });
            })
            .catch(error => {
            });
    };
};

export const fetchArchiveMessages = (
    user_id,
    patientEmail,
    patientName,
    doctorName,
    doctorID,
    patientID,
    doctorImage,
    conversationID,
    tagsList,
    city,
    country,
    platform,
    DOB,
    gender,
    issue,
    currentMedication,
    diagnosisValue,
    opinionDescriptionValue,
    commentAdded,
    medications,
    followUpData,
    gmtOffset,
    buildNumber,
    convoCount,
    averageSleep,
    currentSleep,
    heartRate,
    heightCm,
    heightFeet,
    stepCount,
    runningWalking,
    weightKg,
    weightLbs,
    periodStartDate,
    flowType,
    restingHeartRate,
    complete_address,
    timestamp,
    issueResolved,
    symptoms,
    findings,
    assessment,
    plan,
    ownerProfile,
    owner,
    icd,
    billing_code,
    billing_unit,
    prescriptions,
    requisitions,
    special_referals,
    video_consult,
    followUpDays
) => {
    return dispatch => {
        dispatch({type: FETCHING_ARCHIVE_MESSAGES});

        getConversationRef(conversationID).once("value", snap => {
            if (snap.val()) {
                var count = snap.numChildren();
                var i = 0;
                var messages = [];
                snap.forEach(function (childSnapshot) {
                    let receiveMessage = childSnapshot.val();
                    receiveMessage.uid = childSnapshot.key;

                    if (receiveMessage.toID === patientID) {
                        receiveMessage.direction = "right";
                    } else {
                        receiveMessage.direction = "left";
                    }

                    let objMessages = {receiveMessage: receiveMessage};

                    messages.push(objMessages);
                    if (count - 1 === i) {
                        i = count - 1;
                        database.ref(`users/${patientID}`).once('value', s => {
                            if (s.exists()) {
                                //let owner = s.val()?.owner === patientID? null:  s.val()?.owner  || null;
                                //console.log("FETCH_ARCHIVE_MESSAGES_SUCCESS owner ", owner)

                                let ownerProfile = owner? s.val().profiles[owner] : null;
                                if (ownerProfile){
                                    ownerProfile.uid = owner;
                                }
                                console.log("FETCH_ARCHIVE_MESSAGES_SUCCESS ownerProfile ", ownerProfile)
                                dispatch({
                                    type: FETCH_ARCHIVE_MESSAGES_SUCCESS,
                                    payload: {
                                        patientID,
                                        user_id: user_id,
                                        issue:issue,
                                        messages: messages,
                                        location: conversationID,
                                        doctorId: doctorID,
                                        doctorName: doctorName,
                                        doctorImage: doctorImage,
                                        patientEmail: patientEmail,
                                        patientName: patientName,
                                        city: city,
                                        country: country,
                                        complete_address: complete_address,
                                        phone_number: s.val().phone_number,
                                        platform: platform,
                                        DOB: DOB,
                                        ownerProfile:ownerProfile,
                                        gender: gender,
                                        timestamp: timestamp,
                                        currentMedication: currentMedication,
                                        diagnosisValue: diagnosisValue,
                                        opinionDescriptionValue: opinionDescriptionValue,
                                        medicationData: medications,
                                        followUpData: followUpData,
                                        commentAdded: commentAdded,
                                        gmtOffset: gmtOffset,
                                        buildNumber: buildNumber,
                                        convoCount: convoCount,
                                        averageSleep: averageSleep,
                                        currentSleep: currentSleep,
                                        heartRate: heartRate,
                                        heightCm: heightCm,
                                        heightFeet: heightFeet,
                                        stepCount: stepCount,
                                        runningWalking: runningWalking,
                                        weightKg: weightKg,
                                        weightLbs: weightLbs,
                                        periodStartDate: periodStartDate,
                                        flowType: flowType,
                                        restingHeartRate: restingHeartRate,
                                        symptoms: symptoms,
                                        findings: findings,
                                        assessment: assessment,
                                        plan: plan,
                                        icd: icd,
                                        billing_code: billing_code,
                                        billing_unit: billing_unit,
                                        issueResolved: issueResolved,
                                        prescriptions: prescriptions,
                                        requisitions: requisitions,
                                        healthCardNumber: s.val().healthInsuranceCardNumber,
                                        healthInsuranceCardBack: s.val().healthInsuranceCardBack || null,
                                        healthInsuranceCardFront: s.val().healthInsuranceCardFront || null,
                                        healthInsuranceCardNumber: s.val().healthInsuranceCardNumber || null,
                                        special_referals: special_referals,
                                        video_consult: video_consult,
                                        followUpDays: followUpDays,
                                    },
                                });
                            }
                        })

                    } else {
                        i = i + 1;
                    }
                });
            } else {
                dispatch({
                    type: FETCH_ARCHIVE_MESSAGES_SUCCESS,
                    payload: [],
                });
            }
        });
    };
};

export const fetchClinicalArchiveMessages = (
    user_id,
    patientEmail,
    patientName,
    doctorName,
    doctorID,
    patientID,
    doctorImage,
    conversationID,
    tagsList,
    city,
    country,
    platform,
    DOB,
    gender,
    issue,
    currentMedication,
    diagnosisValue,
    opinionDescriptionValue,
    commentAdded,
    medications,
    followUpData,
    gmtOffset,
    buildNumber,
    convoCount,
    averageSleep,
    currentSleep,
    heartRate,
    heightCm,
    heightFeet,
    stepCount,
    runningWalking,
    weightKg,
    weightLbs,
    periodStartDate,
    flowType,
    restingHeartRate,
    complete_address,
    timestamp,
    issueResolved,
    symptoms,
    findings,
    assessment,
    plan,
    icd,
    billing_code,
    billing_unit,
    prescriptions,
    requisitions,
    special_referals,
    video_consult,
    followUpDays,
    archiveObj
) => {
    return dispatch => {
        dispatch({type: FETCHING_ARCHIVE_MESSAGES});

        getConversationRef(conversationID).once("value", snap => {
            if (snap.val()) {
                var count = snap.numChildren();
                var i = 0;
                var messages = [];
                snap.forEach(function (childSnapshot) {
                    let receiveMessage = childSnapshot.val();
                    receiveMessage.uid = childSnapshot.key;

                    if (receiveMessage.toID === patientID) {
                        receiveMessage.direction = "right";
                    } else {
                        receiveMessage.direction = "left";
                    }

                    let objMessages = {receiveMessage: receiveMessage};

                    messages.push(objMessages);
                    if (count - 1 === i) {
                        i = count - 1;
                        database.ref(`users/${patientID}`).once('value', s => {
                                if (s.exists()) {
                                    dispatch({
                                        type: FETCH_ARCHIVE_MESSAGES_SUCCESS,
                                        payload: {
                                            patientID,
                                            user_id: user_id,
                                            messages: messages,
                                            location: conversationID,
                                            doctorId: doctorID,
                                            doctorName: doctorName,
                                            doctorImage: doctorImage,
                                            patientEmail: patientEmail,
                                            patientName: patientName,
                                            city: city,
                                            country: country,
                                            complete_address: complete_address,
                                            phone_number: s.val().phone_number,
                                            platform: platform,
                                            DOB: DOB,
                                            gender: gender,
                                            timestamp: timestamp,
                                            currentMedication: currentMedication,
                                            diagnosisValue: diagnosisValue,
                                            opinionDescriptionValue: opinionDescriptionValue,
                                            medicationData: medications,
                                            followUpData: followUpData,
                                            commentAdded: commentAdded,
                                            gmtOffset: gmtOffset,
                                            buildNumber: buildNumber,
                                            convoCount: convoCount,
                                            averageSleep: averageSleep,
                                            currentSleep: currentSleep,
                                            heartRate: heartRate,
                                            heightCm: heightCm,
                                            heightFeet: heightFeet,
                                            stepCount: stepCount,
                                            runningWalking: runningWalking,
                                            weightKg: weightKg,
                                            weightLbs: weightLbs,
                                            periodStartDate: periodStartDate,
                                            flowType: flowType,
                                            restingHeartRate: restingHeartRate,
                                            symptoms: symptoms,
                                            findings: findings,
                                            assessment: assessment,
                                            plan: plan,
                                            icd: icd,
                                            billing_code: billing_code,
                                            billing_unit: billing_unit,
                                            issueResolved: issueResolved,
                                            prescriptions: prescriptions,
                                            requisitions: requisitions,
                                            special_referals: special_referals,
                                            healthCardNumber: s.val().healthInsuranceCardNumber,
                                            healthInsuranceCardBack: s.val().healthInsuranceCardBack,
                                            healthInsuranceCardFront: s.val().healthInsuranceCardFront,
                                            healthInsuranceCardNumber: s.val().healthInsuranceCardNumber,
                                            video_consult: video_consult,
                                            followUpDays: followUpDays,
                                            selectedArchiveObj: archiveObj,
                                        },
                                    });
                                }
                            }
                        )
                    } else {
                        i = i + 1;
                    }
                });
            } else {
                dispatch({
                    type: FETCH_ARCHIVE_MESSAGES_SUCCESS,
                    payload: [],
                });
            }
        });
    };
};

export const resetArchiveMessages = () => {
    return dispatch => {
        dispatch({
            type: RESET_ARCHIVE_CHAT
        });
    };
};

export const searchArchives = (input, archives, type) => {
    return dispatch => {
        dispatch({type: FETCH_ARCHIVES_LOADING});
        dispatch({
            type: RESET_ARCHIVE_CHAT
        });
        let newArchives = archives;
        if(type === "chat-id" && input.length>0){
            database.ref(`archiveConversations/${input}`).once('value', valSnap => {
                if (valSnap.val()) {
                    getdoctorByRef(valSnap.val().doctorID).once("value", doctorSnap => {
                        if (doctorSnap.val()) {
                            getUserRef(valSnap.val().patientID).once("value", patientSnap => {
                                let owner = valSnap.val()?.owner === valSnap.val().patientID? null:  valSnap.val()?.owner  || null;
                                let ownerProfile = owner? patientSnap.val().profiles? patientSnap.val().profiles[owner] : null : null;
                                if (ownerProfile){
                                    ownerProfile.uid = owner;
                                }
                                if (patientSnap.val()) {
                                    let userName =
                                        (patientSnap.val().userName === "No name" ||
                                            patientSnap.val().userName === "Patient") &&
                                        patientSnap.val().name
                                            ? patientSnap.val().name
                                            : patientSnap.val().userName;
                                    let archiveData = valSnap.val();
                                    let chatCount = patientSnap.val().history_conversations;

                                    archiveData.conversationID = valSnap.key;
                                    archiveData.patientEmail = patientSnap.val().email;
                                    archiveData.city = patientSnap.val().city;
                                    archiveData.country = patientSnap.val().country;
                                    archiveData.platform = patientSnap.val().platform;
                                    archiveData.DOB = patientSnap.val().DOB;
                                    archiveData.gender = patientSnap.val().gender;
                                    archiveData.symptoms = patientSnap?.val()?.symptoms || archiveData?.followUpData?.diagnosis
                                    archiveData.currentMedication = patientSnap.val().medication;
                                    archiveData.gmtOffset = patientSnap.val().gmtOffset;
                                    archiveData.buildNumber = patientSnap.val().buildNumber;
                                    archiveData.patientName = userName;
                                    archiveData.ownerProfile = ownerProfile;
                                    archiveData.convoCount =
                                        chatCount !== null && chatCount !== undefined
                                            ? Object.keys(chatCount).length
                                            : 0;
                                    archiveData.doctorName = doctorSnap.val().doctorName;
                                    archiveData.doctorID = valSnap.val().doctorID;
                                    archiveData.doctorProfileImg = doctorSnap.val().profileImageURL;
                                    archiveData.doctorEmail = doctorSnap.val().email;
                                    archives.push(archiveData);
                                    dispatch({
                                        type: SEARCH_ARCHIVES,
                                        payload: archives,
                                    });
                                } else {
                                    dispatch({
                                        type: SEARCH_ARCHIVES,
                                        payload: [],
                                    });
                                }
                            });
                        } else {
                            dispatch({
                                type: SEARCH_ARCHIVES,
                                payload: [],
                            });
                        }
                    });
                }else{
                    dispatch({
                        type: SEARCH_ARCHIVES,
                        payload: [],
                    });
                }
            })
        }
        else if (input.length !== 0 && input.length > 2) {
            index_store.browse({query: input}, function searchDone(err, content) {
                if (!Boolean(err) && Boolean(content)) {
                    newArchives = [...content.hits];
                    let count = 0;
                    let length = content.hits?.length || 0;
                    if(length>0){
                        content.hits.forEach((hit, index)=>{
                            console.log("hit ", hit)
                            let owner = hit?.owner === hit.patientID? null:  hit.owner  || null;
                            if(owner){
                                console.log("searchDone owner ", owner)
                                database.ref(`/users/${hit.patientID}/profiles/${owner}`).once("value", patientProfileSnap => {
                                    let ownerProfile = patientProfileSnap.exists()? patientProfileSnap.toJSON():null;
                                    console.log("searchDone ownerProfile ", ownerProfile)
                                    if (ownerProfile){
                                        ownerProfile.uid = owner;
                                    }
                                    newArchives[index].ownerProfile = ownerProfile;
                                    count++;
                                    if(count >= length){
                                        dispatch({
                                            type: SEARCH_ARCHIVES,
                                            payload: newArchives,
                                        });
                                    }

                                })
                            }else{
                                count++;
                                if(count >= length){
                                    dispatch({
                                        type: SEARCH_ARCHIVES,
                                        payload: newArchives,
                                    });
                                }
                            }
                        })
                    }else {
                        dispatch({
                            type: SEARCH_ARCHIVES,
                            payload: [],
                        });
                    }



                } else {
                    dispatch({
                        type: SEARCH_ARCHIVES,
                        payload: [],
                    });
                }
            });
        } else if (input.length === 0) {
            fetchArchivesData(dispatch, [], new Date().getTime());
        }
    };
};

export const fetchPatientsClinicalArchives = (patientid, clinicid) => {
    var timestamp = null;
    return dispatch => {
        dispatch({type: FETCH_ARCHIVES_LOADING});

        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        getClinicStaffMemberRef(clinicid, currentUserID).once("value", doctorSnapshot => {
            if (doctorSnapshot.val()) {
                getUserHistoryConversationRef(patientid).once("value", historySnap => {
                    historySnap.forEach(childHistorySnapshot => {
                        if (childHistorySnapshot.val()) {
                            var count = historySnap.numChildren();
                            var i = 0;
                            var archives = [];
                            historySnap.forEach(childHistorySnapshot => {
                                var convoId = childHistorySnapshot.val().location;
                                getClinicalArchiveRef(clinicid, convoId).once("value", valSnap => {
                                    if (valSnap.val()) {
                                        if (!valSnap.val().status) {
                                            getClinicStaffMemberRef(clinicid, valSnap.val().doctorID).once(
                                                "value",
                                                doctorSnap => {
                                                    if (doctorSnap.val()) {
                                                        getUserRef(valSnap.val().patientID).once("value", patientSnap => {
                                                            if (patientSnap.val()) {
                                                                let userName =
                                                                    (patientSnap.val().userName === "No name" ||
                                                                        patientSnap.val().userName === "Patient") &&
                                                                    patientSnap.val().name
                                                                        ? patientSnap.val().name
                                                                        : patientSnap.val().userName;
                                                                let archiveData = valSnap.val();
                                                                let chatCount = patientSnap.val().history_conversations;

                                                                archiveData.conversationID = valSnap.key;
                                                                archiveData.patientEmail = patientSnap.val().email;
                                                                archiveData.city = patientSnap.val().city;
                                                                archiveData.country = patientSnap.val().country;
                                                                archiveData.platform = patientSnap.val().platform;
                                                                archiveData.DOB = patientSnap.val().DOB;
                                                                archiveData.gender = patientSnap.val().gender;
                                                                archiveData.currentMedication = patientSnap.val().medication;
                                                                archiveData.gmtOffset = patientSnap.val().gmtOffset;
                                                                archiveData.buildNumber = patientSnap.val().buildNumber;
                                                                archiveData.patientName = userName;
                                                                archiveData.convoCount =
                                                                    chatCount !== null && chatCount !== undefined
                                                                        ? Object.keys(chatCount).length
                                                                        : 0;
                                                                archiveData.doctorName = doctorSnap.val().fullName;
                                                                archiveData.doctorID = valSnap.val().doctorID;
                                                                archiveData.doctorProfileImg = doctorSnap.val().profileImageURL;
                                                                archiveData.doctorEmail = doctorSnap.val().staffEmail;
                                                                archives.push(archiveData);

                                                                if (count - 1 === i) {
                                                                    returnArchives(dispatch, [], archives, count, i);
                                                                } else {
                                                                    i = i + 1;
                                                                }
                                                            } else {
                                                                i = i + 1;
                                                                if (count === i && archives.length === 0) {
                                                                    dispatch({type: FETCH_ARCHIVES_STATUS_FAILED});
                                                                } else if (count === i && archives.length !== 0) {
                                                                    returnArchives(dispatch, [], archives, count, i);
                                                                }
                                                            }
                                                        });
                                                    } else {
                                                        i = i + 1;
                                                        if (count === i && archives.length === 0) {
                                                            dispatch({type: FETCH_ARCHIVES_STATUS_FAILED});
                                                        } else if (count === i && archives.length !== 0) {
                                                            returnArchives(dispatch, [], archives, count, i);
                                                        }
                                                    }
                                                }
                                            );
                                        } else {
                                            i = i + 1;
                                            if (count === i && archives.length === 0) {
                                                dispatch({type: FETCH_ARCHIVES_STATUS_FAILED});
                                            } else if (count === i && archives.length !== 0) {
                                                returnArchives(dispatch, [], archives, count, i);
                                            }
                                        }
                                    } else {
                                        i = i + 1;
                                        if (count === i && archives.length === 0) {
                                            dispatch({type: FETCH_ARCHIVES_STATUS_FAILED});
                                        } else if (count === i && archives.length !== 0) {
                                            returnArchives(dispatch, [], archives, count, i);
                                        }
                                    }
                                });
                            });
                        } else {
                            dispatch({type: FETCH_ARCHIVES_STATUS_FAILED});
                        }
                    });
                });
            } else {
                dispatch({type: FETCH_ARCHIVES_STATUS_FAILED});
            }
        });
    };
};

export const fetchClinicalArchives = (oldArchives, timestamp, clinicid) => {
    return dispatch => {
        dispatch({type: FETCH_ARCHIVES_LOADING});
        var archives = [];
        let archive_list = getClinicalArchivesRef(clinicid).orderByChild("timestamp").limitToLast(100);
        if (Boolean(timestamp)) archive_list = archive_list.endAt(timestamp);
        archive_list.once("value", archiveSnap => {
            if (archiveSnap.val()) {
                var count = archiveSnap.numChildren();
                var i = 0;
                var archive_index = 0;
                archiveSnap.forEach(valSnap => {
                    archive_index = archive_index + 1;
                    if (!valSnap.val().status && valSnap.val().doctorID === auth.currentUser.uid) {
                        getClinicStaffMemberRef(clinicid, valSnap.val().doctorID).once("value", doctorSnap => {
                            if (doctorSnap.val()) {
                                getUserRef(valSnap.val().patientID).once("value", patientSnap => {
                                    if (patientSnap.val()) {
                                        let userName =
                                            (patientSnap.val().userName === "No name" ||
                                                patientSnap.val().userName === "Patient") &&
                                            patientSnap.val().name
                                                ? patientSnap.val().name
                                                : patientSnap.val().userName;
                                        let archiveData = valSnap.val();
                                        let chatCount = patientSnap.val().history_conversations;
                                        archiveData.conversationID = valSnap.key;
                                        archiveData.patientEmail = patientSnap.val().email;
                                        archiveData.city = patientSnap.val().city;
                                        archiveData.country = patientSnap.val().country;
                                        archiveData.platform = patientSnap.val().platform;
                                        archiveData.DOB = patientSnap.val().DOB;
                                        archiveData.gender = patientSnap.val().gender;
                                        archiveData.currentMedication = patientSnap.val().medication;
                                        archiveData.gmtOffset = patientSnap.val().gmtOffset;
                                        archiveData.buildNumber = patientSnap.val().buildNumber;
                                        archiveData.patientName = userName;
                                        archiveData.convoCount =
                                            chatCount !== null && chatCount !== undefined
                                                ? Object.keys(chatCount).length
                                                : 0;
                                        archiveData.doctorName = doctorSnap.val().fullName;
                                        archiveData.doctorID = valSnap.val().doctorID;
                                        archiveData.doctorProfileImg = doctorSnap.val().profileImageURL;
                                        archiveData.doctorEmail = doctorSnap.val().staffEmail;
                                        archiveData.signatureURL = doctorSnap.val().signatureImageURL;
                                        archiveData.licenseNo = doctorSnap.val().licenseNo;
                                        archiveData.patient_profile = patientSnap.val().PatientProfile;
                                        archives.push(archiveData);

                                        if (count - 1 === i) {
                                            returnArchives(dispatch, oldArchives, archives, count, i);
                                        } else {
                                            i = i + 1;
                                        }
                                    } else {
                                        i = i + 1;
                                        if (count === i && archives.length === 0) {
                                            dispatch({type: FETCH_ARCHIVES_STATUS_FAILED});
                                        } else if (count === i && archives.length !== 0) {
                                            returnArchives(dispatch, oldArchives, archives, count, i);
                                        }
                                    }
                                });
                            } else {
                                getdoctorByRef(valSnap.val().doctorID).once("value", doctorSnap => {
                                    if (doctorSnap.val()) {
                                        getUserRef(valSnap.val().patientID).once("value", patientSnap => {
                                            if (patientSnap.val()) {
                                                let userName =
                                                    (patientSnap.val().userName === "No name" ||
                                                        patientSnap.val().userName === "Patient") &&
                                                    patientSnap.val().name
                                                        ? patientSnap.val().name
                                                        : patientSnap.val().userName;
                                                let archiveData = valSnap.val();
                                                let chatCount = patientSnap.val().history_conversations;
                                                archiveData.conversationID = valSnap.key;
                                                archiveData.patientEmail = patientSnap.val().email;
                                                archiveData.city = patientSnap.val().city;
                                                archiveData.country = patientSnap.val().country;
                                                archiveData.platform = patientSnap.val().platform;
                                                archiveData.DOB = patientSnap.val().DOB;
                                                archiveData.gender = patientSnap.val().gender;
                                                archiveData.currentMedication = patientSnap.val().medication;
                                                archiveData.gmtOffset = patientSnap.val().gmtOffset;
                                                archiveData.buildNumber = patientSnap.val().buildNumber;
                                                archiveData.patientName = userName;
                                                archiveData.convoCount =
                                                    chatCount !== null && chatCount !== undefined
                                                        ? Object.keys(chatCount).length
                                                        : 0;
                                                archiveData.doctorName = doctorSnap.val().fullName;
                                                archiveData.doctorID = valSnap.val().doctorID;
                                                archiveData.doctorProfileImg = doctorSnap.val().profileImageURL;
                                                archiveData.doctorEmail = doctorSnap.val().email;
                                                archives.push(archiveData);

                                                if (count - 1 === i) {
                                                    returnArchives(dispatch, oldArchives, archives, count, i);
                                                } else {
                                                    i = i + 1;
                                                }
                                            } else {
                                                i = i + 1;
                                                if (count === i && archives.length === 0) {
                                                    dispatch({type: FETCH_ARCHIVES_STATUS_FAILED});
                                                } else if (count === i && archives.length !== 0) {
                                                    returnArchives(dispatch, oldArchives, archives, count, i);
                                                }
                                            }
                                        });
                                    } else {
                                        i = i + 1;
                                        if (count === i && archives.length === 0) {
                                            dispatch({type: FETCH_ARCHIVES_STATUS_FAILED});
                                        } else if (count === i && archives.length !== 0) {
                                            returnArchives(dispatch, oldArchives, archives, count, i);
                                        }
                                    }
                                });
                            }
                        });
                    } else {
                        i = i + 1;
                        if (count === i && archives.length === 0) {
                            dispatch({type: FETCH_ARCHIVES_STATUS_FAILED});
                        } else if (count === i && archives.length !== 0) {
                            returnArchives(dispatch, oldArchives, archives, count, i);
                        }
                    }
                });
            } else {
                dispatch({type: FETCH_ARCHIVES_STATUS_FAILED});
            }
        });
    };
};

const returnArchives = (dispatch, oldArchives, archives, count, i) => {
    var sorted_archives =
        archives.length > 1 ? _.orderBy(archives, ["timestamp"], ["desc"]) : archives;
    var time = sorted_archives[sorted_archives.length - 1].timestamp;
    var index = oldArchives.length;
    sorted_archives =
        sorted_archives.length === 100
            ? sorted_archives.slice(0, sorted_archives.length - 1)
            : sorted_archives;
    var new_Array = _.concat(oldArchives, sorted_archives);

    dispatch({
        type: FETCH_ARCHIVES_STATUS,
        payload: {
            sorted_archives: new_Array,
            time: time,
            previous_length: index,
            isLast: count !== 100,
        },
    });
};

const getClinicalArchivesRef = clinicName => {
    return database.ref(`/clinicArchiveConversations/${clinicName}`);
};
const getClinicalArchiveRef = (clinicName, convoId) => {
    return database.ref(`/clinicArchiveConversations/${clinicName}/${convoId}`);
};

const getArchivesRef = () => {
    return database.ref().child("archiveConversations");
};

const getdoctorByRef = uid => {
    return database.ref(`/doctors/${uid}`);
};

const getClinicStaffMemberRef = (ID, uid) => {
    return database.ref(`/clinicStaffMember/${ID}/${uid}`);
};

const getUserRef = uid => {
    return database.ref(`/users/${uid}`);
};

const getConversationRef = location => {
    return database.ref(`/conversations/${location}`);
};

const getOpenArchivesRef = () => {
    return database.ref(`/openconversations/`);
};

const getUserHistoryConversationRef = userId => {
    return database.ref(`/users/${userId}/history_conversations`);
};
