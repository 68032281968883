import {Component, createRef, useEffect, useState} from "react";
import classes from "./Archives.module.css";
import moment from "moment";

const capitalize = s => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const ArchiveItem = props => {
    const [patientType, setPatientType] = useState({});
    var selected = props.selectedArchive === props.conversationID ? true : false;
    var name = props.patientName !== undefined ? capitalize(props.patientName) : "Patient";
    var email = props.patientEmail !== undefined ? `(${props.patientEmail})` : "";
    const [chatType, setChatType] = useState("");
    useEffect(() => {
        if (props?.archiveData){
            if (props?.archiveData?.isEMRChat) {
                if (props?.archiveData?.referralFromApp) {
                    setChatType("Free Chat");
                }else {
                    setChatType("Health Card");
                }
            }else if(props?.archiveData?.subscriptionType === "quarterly"){
                setChatType("Quarterly Plan User");
            } else if(props?.archiveData?.subscriptionType === "yearly"){
                setChatType("Yearly Plan User");
            }else if(props?.archiveData?.subscriptionType === "monthly"){
                setChatType("Monthly Plan User");
            } else if(props?.archiveData?.partner_id ===7){
                setChatType("Orchyd Patient");
            } else if(props?.archiveData?.isPhysioPatient){
                setChatType("Physio Patient");
            }else if(props?.archiveData?.payAfterChat){
                setChatType(`Trial plan user${props?.archiveData?.paymentCompleted ? " - Paid" : props?.archiveData?.checkoutSessionID ? " - Unpaid" : ""}`);
            }else if(props?.archiveData?.doNotCharge){
                if(props.archiveData?.fromConsumable){
                    if (props.archiveData?.freePrescription && props.archiveData?.freeMedicalNote){
                        setChatType("Free prescriptions & notes");
                    } else if (props.archiveData?.freePrescription){
                        setChatType("Free Prescription");
                    } else if (props.archiveData?.freeMedicalNote){
                        setChatType("Free Medical Note");
                    }else{
                        setChatType("Free Prescription");
                    }
                }else{
                    setChatType("Free Prescription");
                }
            } else{
                setChatType("Private Pay");
            }
        }

    }, [props?.archiveData]);
    return (
        <div
            className={selected ? classes.selected_list_view : classes.arc_list_view}
            onClick={() => props.onArchiveItemClick(props.archiveData)}
        >
            <div className={classes.list_img_style}>
                <div className={classes.selected_list_img}>
                    <span className={classes.selected_user_img}>{name.charAt(0)}</span>
                </div>
            </div>
            <div className={classes.list_item}>
                <div className={selected ? classes.selected_item_name : classes.patient_name}>{name}</div>
                <div className={selected ? classes.selected_item_name : classes.item_name}>{email}</div>
                <div className={selected ? classes.selected_item_name : classes.item_name}>
                    {props.doctorName}
                </div>
                <div className={selected ? classes.item_payment_status : classes.item_payment_status}>{chatType}</div>

                {/*<div className={selected ? classes.selected_item_name : classes.item_name}>*/}
                {/*    <span> {patientType} </span>*/}
                {/*</div>*/}
                <div className={selected ? classes.selected_item_name : classes.item_name}>
                    <span>{name.charAt(0)}: </span>
                    Hello Doctor! My name is {name}.
                </div>
            </div>
            {!selected && (
                <div className={classes.list_item_second}>
                    <div>
                        <div className={classes.time_style}>
                            {moment(props.timestamp).format("YYYY-MM-DD hh:mm:ss")}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default class ArchiveList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedArchive: null,
            archiveSetState: 0,
        };
    }

    onArchiveItemClick = data => {
        const {
            user_id,
            patientEmail,
            patientName,
            doctorName,
            doctorID,
            patientID,
            doctorImage,
            conversationID,
            tagsList,
            city,
            country,
            platform,
            DOB,
            gender,
            issue,
            currentMedications,
            diagnosisValue,
            opinionDescriptionValue,
            commentAdded,
            medications,
            followUpData,
            gmtOffset,
            buildNumber,
            convoCount,
            averageSleep,
            currentSleep,
            heartRate,
            heightCm,
            heightFeet,
            stepCount,
            runningWalking,
            weightKg,
            weightLbs,
            periodStartDate,
            flowType,
            restingHeartRate,
            complete_address,
            timestamp,
            issueResolved,
            symptoms,
            findings,
            assessment,
            plan,
            ownerProfile,
            owner
        } = data;

        this.setState({selectedArchive: conversationID});
        this.props.archiveHeaderItem(
            user_id,
            patientEmail,
            patientName,
            doctorName,
            doctorID,
            patientID,
            doctorImage,
            conversationID,
            tagsList,
            city,
            country,
            platform,
            DOB,
            gender,
            issue,
            currentMedications,
            diagnosisValue,
            opinionDescriptionValue,
            commentAdded,
            medications,
            followUpData,
            gmtOffset,
            buildNumber,
            convoCount,
            averageSleep,
            currentSleep,
            heartRate,
            heightCm,
            heightFeet,
            stepCount,
            runningWalking,
            weightKg,
            weightLbs,
            periodStartDate,
            flowType,
            restingHeartRate,
            complete_address,
            timestamp,
            issueResolved,
            symptoms,
            findings,
            assessment,
            plan,
            ownerProfile,
            owner
        );
    };

    archiveDefault = () => {
        if (this.props.archives[0]) {
            const {
                user_id,
                patientEmail,
                patientName,
                doctorName,
                doctorID,
                patientID,
                doctorImage,
                conversationID,
                tagsList,
                city,
                country,
                platform,
                DOB,
                gender,
                issue,
                currentMedications,
                diagnosisValue,
                opinionDescriptionValue,
                commentAdded,
                medications,
                followUpData,
                gmtOffset,
                buildNumber,
                convoCount,
                averageSleep,
                currentSleep,
                heartRate,
                heightCm,
                heightFeet,
                stepCount,
                runningWalking,
                weightKg,
                weightLbs,
                periodStartDate,
                flowType,
                restingHeartRate,
                complete_address,
                timestamp,
                issueResolved,
                symptoms,
                findings,
                assessment,
                plan,
                ownerProfile,
                owner
            } = this.props.archives[0];

            this.setState({selectedArchive: conversationID});
            this.props.archiveHeaderItem(
                user_id,
                patientEmail,
                patientName,
                doctorName,
                doctorID,
                patientID,
                doctorImage,
                conversationID,
                tagsList,
                city,
                country,
                platform,
                DOB,
                gender,
                issue,
                currentMedications,
                diagnosisValue,
                opinionDescriptionValue,
                commentAdded,
                medications,
                followUpData,
                gmtOffset,
                buildNumber,
                convoCount,
                averageSleep,
                currentSleep,
                heartRate,
                heightCm,
                heightFeet,
                stepCount,
                runningWalking,
                weightKg,
                weightLbs,
                periodStartDate,
                flowType,
                restingHeartRate,
                complete_address,
                timestamp,
                issueResolved,
                symptoms,
                findings,
                assessment,
                plan,
                ownerProfile,
                owner
            );
        }
    };

    UNSAFE_componentWillReceiveProps(newProps) {
        if (
            Boolean(newProps.archives) &&
            Boolean(newProps.archives[0]) &&
            this.state.archiveSetState !== 1
        ) {
            const {
                user_id,
                patientEmail,
                patientName,
                doctorName,
                doctorID,
                patientID,
                doctorImage,
                conversationID,
                tagsList,
                city,
                country,
                platform,
                DOB,
                gender,
                issue,
                currentMedications,
                diagnosisValue,
                opinionDescriptionValue,
                commentAdded,
                medications,
                followUpData,
                gmtOffset,
                buildNumber,
                convoCount,
                averageSleep,
                currentSleep,
                heartRate,
                heightCm,
                heightFeet,
                stepCount,
                runningWalking,
                weightKg,
                weightLbs,
                periodStartDate,
                flowType,
                restingHeartRate,
                complete_address,
                timestamp,
                issueResolved,
                symptoms,
                findings,
                assessment,
                plan
            } = newProps.archives[0];

            this.setState({selectedArchive: newProps.archives[0].conversationID, archiveSetState: 1});

            this.props.archiveHeaderItem(
                user_id,
                patientEmail,
                patientName,
                doctorName,
                doctorID,
                patientID,
                doctorImage,
                conversationID,
                tagsList,
                city,
                country,
                platform,
                DOB,
                gender,
                issue,
                currentMedications,
                diagnosisValue,
                opinionDescriptionValue,
                commentAdded,
                medications,
                followUpData,
                gmtOffset,
                buildNumber,
                convoCount,
                averageSleep,
                currentSleep,
                heartRate,
                heightCm,
                heightFeet,
                stepCount,
                runningWalking,
                weightKg,
                weightLbs,
                periodStartDate,
                flowType,
                restingHeartRate,
                complete_address,
                timestamp,
                issueResolved,
                symptoms,
                findings,
                assessment,
                plan
            );
        }
    }

    componentDidUpdate() {
        if (Boolean(this.props.previous_length) && !this.props.isSearch) {
            if (
                this.props.previous_length !== this.props.archives.length - 1 &&
                Boolean(this[`myrefRow ${this.props.previous_length}`].current)
            )
                this[`myrefRow ${this.props.previous_length}`].current.scrollIntoView({block: "start"});
        }
    }

    archiveSearchValue = event => {
        this.props.archiveSearchValue(event.target.value);
    };
    archiveSearchText = event => {
        this.props.archiveSearchText();
    };

    render() {
        var archivesList = [];
        if (Boolean(this.props.archives)) {
            archivesList = this.props.archives.map((archive, index) => {
                let ref = `myrefRow ${index}`;
                this[ref] = createRef();
                return (
                    <ArchiveItem
                        key={archive.conversationID}
                        patientName={archive.ownerProfile?.name || archive.patientName}
                        doctorName={archive.doctorName}
                        conversationID={archive.conversationID}
                        doctorImage={archive.doctorProfileImg}
                        doctorID={archive.doctorID}
                        timestamp={archive.timestamp}
                        archiveData={archive}
                        patientEmail={archive.patientEmail}
                        tagsList={archive.tagsList !== undefined ? archive.tagsList : []}
                        onArchiveItemClick={data => this.onArchiveItemClick(data)}
                        selectedArchive={this.state.selectedArchive}
                        nextref={this[ref]}
                    />
                );
            });
        }

        return (
            <div className={classes.list_view}>
                <div className={classes.block}>
                    <div className={classes.block}>
                        <div className={classes.main_block}>
                            <div className={classes.list_body_view}>
                                <div className={classes.list_body_main}>
                                    <div className={classes.search_box}>
                                        <div className={classes.search_box_section}>
                                            <div className={classes.input_view}>
                                                <input
                                                    placeholder="Press enter to search in archives"
                                                    className={classes.input_style}
                                                    onChange={event => this.archiveSearchValue(event)}
                                                    onKeyPress={event => {
                                                        if (event.key === "Enter") {
                                                            this.props.archiveSearchText(event);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className={classes.search_sub_section}>
                                                <div className={classes.list_count}>
                                                    {Boolean(this.props.archives) && (
                                                        <div>
                                                            <span>{this.props.archives.length} </span>chats
                                                        </div>
                                                    )}
                                                </div>
                                                <div className={classes.search_button}>
                                                    <div onClick={(event)=>this.props.archiveSearchText(event)}>
                                                        Search
                                                    </div>
                                                    <div onClick={(event)=>this.props.archiveSearchText(event, "chat-id")}>
                                                        Search Chat ID
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {!this.props.loading &&
                                    Boolean(this.props.archives) &&
                                    this.props.archives.length !== 0 && (
                                        <div className={classes.ul_list}>
                                            {archivesList}
                                            {!this.props.isSearch && !this.props.isLast && (
                                                <div
                                                    className={classes.load_more}
                                                    onClick={() => this.props.nextArchives()}
                                                >
                                                    Load More
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {!this.props.loading && this.props.archives.length === 0 && (
                                        <div className={classes.no_text}>No data is found</div>
                                    )}

                                    {this.props.loading && (
                                        <div className={classes.no_loading}>
                                            <div className={classes.no_loading_view}>
                                                <div className={classes.loading_loader}/>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
