import  {Component} from 'react';
import {connect} from 'react-redux';

import Aux from '../../hoc/hocAux';
import classes from './ActivitiesStyle.module.css';
import {fetchAllActivites} from '../../actions/ActivitiesAction';

class Activities extends Component {

    UNSAFE_componentWillMount() {
        this.props.fetchAllActivites();
    }

    checkString = (match, value) => {
        switch (match) {
            case 'loginTime':
                return `Logged in at: ${new Date(value)}\n`;
            case 'logoutTime':
                return `Logged out at: ${new Date(value)}\n`;
            case 'acceptingChatOnTime':
                return `Turned on accepting chat at: ${new Date(value)}\n`;
            case 'acceptingChatOffTime':
                return `Turned off accepting chat at: ${new Date(value)}\n`;
            case 'browserInfo':
                return `Browser name: ${value.name} and OS: ${value.os}\n`;
            default:
                return null;
        }
    }

    render() {
        return (
            <Aux>
                <div className={classes.main}>
                    <div className={classes.header}>Activity</div>
                    {this.props.actvities.map(activity => {
                        return (
                            <div key={activity.key}>
                                <div className={classes.main_body}>Date: {activity.key}</div>
                                {activity.data.map((home, index) =>
                                    <div key={index}>
                                        <div className={classes.heading}>Doctor Name: {home.doctorName}</div>
                                        {home.childData.map((log, index) =>
                                            <div key={index}>
                                                {Object.entries(log).map(([key, val], index) =>
                                                    <div key={index}
                                                         className={classes.sub_content}> {this.checkString(key, val)} </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
            </Aux>
        )
    }
}

const mapStateToProps = ({activitiesReducer}) => {
    const {actvities, loading, error} = activitiesReducer;
    return {actvities, loading, error};
}

export default connect(mapStateToProps, {fetchAllActivites})(Activities);
