import React, {useEffect, useState, Fragment} from 'react'
import classes from "../../../components/pharmacyComponent/pharmacycomponent.module.css";
import Button from "react-bootstrap/Button";
import {connect} from "react-redux";
import Spinner from "../../../components/Spinner";
import {updatePatient} from "../../../actions/ConversationAction";
import _ from "lodash";
import axios from "axios";
import {firebaseRoot} from "../../../config"
import {database} from "../../../firebase";
import removeIcon from "../../../assets/remove.svg";
import styles from "../../../components/Image/image.module.css";

const UserWarning = (props) => {
    const {from}=props
    const [errorMessage, setErrorMessage] = useState('')
    const [warningMessage, setWarningMessage] = useState('')
    const [warnings, setWarnings] = useState(null)

    const [showBox, setShowBox] = useState(true)
    const [loading, setLoading] = useState(false)
    const [currentTime, setCurrentTime] = useState('')
    const [patientId, setPatientId] = useState(null)
    const [unReadCountObject, setUnReadCountObject] = useState(null)
    const [ownerID, setOwnerID] = useState(null)
    let warningListener=null

    /*useEffect(()=>{
        console.log("UserWarning props.selectedConversationObj ", props.selectedConversationObj)
        setOwnerID(props.selectedConversationObj?.owner)
    },[props.selectedConversationObj])*/

    useEffect(()=>{
        if (from === "archives"){
            setOwnerID(props.archiveOwnerProfile?.uid)
        }else{
            setOwnerID(props.selectedConversationObj?.owner)
        }
    }, [from, props.archiveOwnerProfile, props.selectedConversationObj])

    useEffect(async () => {
        if (warningListener) {
            warningListener.off()
            warningListener = null
        }
        let patientId = props.selectedConversationObj?.patientID
        let owner = from === "archives"?ownerID: props.selectedConversationObj?.owner || ownerID
        console.log("ownerID, from, props.archive_userID, patientId ", {owner, from, archive_userID: props.archive_userID, patientId})
        if (from==='archives'){
            warningListener = props.archive_userID? owner? database.ref(`/users/${props.archive_userID}/profiles/${owner}/warnings`): database.ref(`/users/${props.archive_userID}/warnings`): null;
        }else {
            warningListener = patientId? owner? database.ref(`/users/${patientId}/profiles/${owner}/warnings`): database.ref(`/users/${patientId}/warnings`): null;
        }

        if (warningListener){
            await  warningListener
                // database.ref(`/users/${props.archive_userID}`)
                .on('value', async (patientSnap) => {
                    const patientData = patientSnap.toJSON() || {}
                    console.log("patientData patientData ", patientData)

                    setWarnings(Object.entries(patientData || {}))
                })
        }
    }, [ownerID, from, props.archive_userID, props.selectedConversationObj]);

    /*useEffect(() => {
        console.log("unReadCountObject ", unReadCountObject)
    }, [unReadCountObject]);*/

    useEffect(()=>{
        setOwnerID(null);
        return()=>{
            if (warningListener) {
                warningListener.off()
                warningListener = null
            }
        }
    },[])

/*
    useEffect(async () => {

        if (warningListener) {
            warningListener.off()
            warningListener = null
        }
   if(props.archive_userID && from==='archives'){

       warningListener = database.ref(`/users/${props.archive_userID}`)
       await  warningListener
           // database.ref(`/users/${props.archive_userID}`)
           .on('value', async (patientSnap) => {
               const patientData = patientSnap.toJSON() || {}

               setWarnings(Object.entries(patientData?.warnings || {}))
           })
   }

    },[props.archive_userID])
*/

    /*useEffect(()=>{
        setUnReadCountObject(_.find(props.conversations, [
            "conversationId",
            props.conversationId,
        ]))
    },[props.conversationId, props.conversations])*/

    /*useEffect(()=>{
        if(patientId !== props.patientId){
            setWarningMessage('')
            setWarnings(null)
            setPatientId(props.patientId)
        }
        if(props.selectedPatient && props.patientId){
            setWarningMessage('')
            console.log("props.selectedPatient?.warnings ", props.selectedPatient?.warnings)
            const currentWarnings = ownerID? props.selectedPatient?.profiles?.[ownerID]?.warnings || {} : props.selectedPatient?.warnings || {};
            console.log("Object.entries(props.selectedPatient?.warnings || {}) ", Object.entries(currentWarnings));

            (Object.entries(currentWarnings)).forEach(([key, value]) => {
                console.log("warnings key", key)
                console.log("warnings value", value)
            })
            setWarnings(Object.entries(currentWarnings))
        }

    },[props.patientId, props.selectedPatient, ownerID])*/

    const setShowBoxFunction = () => {
        setShowBox(!showBox)
    }

    const saveChanges = async () => {
        if (warningMessage){
            let patientId = props.selectedConversationObj?.patientID
            let updates = {};
            let path = from==='archives'? ownerID? `${props.archive_userID}/profiles/${ownerID}`: props.archive_userID :  ownerID? `${patientId}/profiles/${ownerID}`: patientId;
            updates[`users/${path}/warnings/${Date.now()}`] = warningMessage;


            try{
                await database.ref().update(updates);
                if(from !== 'archives')  props.updatePatient(patientId)
                setTimeout(()=>setLoading(false), 100)
            }catch (e) {
                if(from !== 'archives')  props.updatePatient(patientId)
                setTimeout(()=>setLoading(false), 100)
            }

            setWarningMessage('')
        }
    }

    useEffect(()=>{
        if(warnings)warnings.forEach(([key, value]) => {
            console.log("warningswarningskey ", key)
            console.log("warningswarningsvalue ", value)
        })
    },[warnings])

    const discardChanges = () =>{
        setWarningMessage('')
        let patientId = props.selectedConversationObj?.patientID
        let path = from==='archives'? ownerID? `${props.archive_userID}/profiles/${ownerID}`: props.archive_userID :  ownerID? `${patientId}/profiles/${ownerID}`: patientId;
        database.ref(`/users/${path}/warnings`).once('value',  (patientWarningSnap) => {
            const patientWarningData = patientWarningSnap.val() || {}
            setWarnings(Object.entries(patientWarningData || {}))
        })
        /*if(from === "archives"){
            if(props.archive_userID ){
                database.ref(`/users/${props.archive_userID}/warnings`).once('value',  (patientWarningSnap) => {
                        const patientWarningData = patientWarningSnap.val() || {}
                        setWarnings(Object.entries(patientWarningData || {}))
                    })
            }
        }else if(props.selectedPatient && props.patientId){
            setWarnings(Object.entries(props.selectedPatient?.warnings || {}))
        }*/
    }


    return (
        <div className={classes.generalInformation}>
            <Fragment>
                <div className={classes.openchatlist}
                     onClick={setShowBoxFunction}>
                    <div className={classes.openchatlistSection}>
                        <div
                            className={showBox === true ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                            <svg fill="#884a9d" width="12px" height="8px"
                                 viewBox="0 0 12 8">
                                <path fill="inherit" fillRule="evenodd"
                                      d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                            </svg>
                        </div>
                        <div className={classes.subListTitle}>Warning Section</div>
                    </div>
                </div>
                {showBox &&
                <div className={classes.expandable_section}>

                        <div className={classes.general_info_section}>

                            {warnings?.length>0?<div>
                                <span className={classes.SOAP_heading}>Warnings:</span>
                                    {warnings.map(([key, value]) => {
                                        return <div className={classes.warningTextColumn} key={key}>
                                            <span className={classes.sickNoteFileName}>{value}</span>
                                            <img
                                                src={removeIcon}
                                                className={styles.warningRemoveIcon}
                                                alt={'x'} onClick={() => {
                                                let updates = {};
                                                let patientId = props.selectedConversationObj?.patientID
                                                let path = from==='archives'? ownerID? `${props.archive_userID}/profiles/${ownerID}`: props.archive_userID :  ownerID? `${patientId}/profiles/${ownerID}`: patientId;
                                                updates[`/users/${path}/warnings/${key}`] = null;
                                                //if(props.archive_userID) updates[`/users/${props.archive_userID}/warnings/${key}`] = null;
                                                //if (props.patientId) updates[`/users/${props.patientId}/warnings/${key}`] = null;
                                                database.ref().update(updates).then(() => {
                                                    setWarningMessage('')
                                                }).catch(error => {
                                                })
                                            }
                                            }/>
                                        </div>
                                    })}
                            </div>
                                :""}
                            <div className={classes.SOAP_div}>
                                <span className={classes.SOAP_heading}>Add Warning:</span>
                                <input
                                    className={classes.SOAP_textarea}
                                    style={{minHeight:45}}
                                    placeholder="Warning Message"
                                    value={warningMessage}
                                    onChange={(e)=>setWarningMessage(e.target.value)}

                                />
                            </div>

                            {errorMessage && <span style={{
                                fontSize: 14,
                                color: "#a30707"
                            }}>{errorMessage}</span>}
                            {loading? <div className={classes.SOAP_buttons} style={{justifyContent: "center", marginTop:10}}><Spinner color={"black"}  loading/></div> : <div className={classes.SOAP_buttons} style={{justifyContent: "right"}}>
                                {<Button size="sm" className={classes.button_invert}
                                         onClick={discardChanges}>Discard</Button>}
                                <Button size="sm" className={classes.button}
                                        onClick={saveChanges}>Save</Button>
                            </div>

                            }

                        </div>

                </div>
                }
            </Fragment>
        </div>
    )
}
const mapStateToProps = ({ conversationReducer,archivesReducer }) => {
    const { patientId, conversationId, conversations, selectedPatient, selectedConversationObj } = conversationReducer;
    const {archive_userID, archiveOwnerProfile, archive_conversationID} = archivesReducer;
    return { patientId, conversationId, conversations, selectedPatient, selectedConversationObj, archive_userID, archive_conversationID, archiveOwnerProfile};
};

export default connect(mapStateToProps, {updatePatient})(UserWarning);
