import {
    FETCH_DOCTOR_DETAILS,
    FETCH_ONLINE_CLINICAL_DOCTORS_GROUP,
    FETCH_ONLINE_CLINICAL_DOCTORS_GROUP_IN_PROCESS, REFRESH_DOCTOR_STATUS
} from '../constants';

const INITIAL_STATE = {doctorDetail: [], loading: false, onlineDoctors: []};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REFRESH_DOCTOR_STATUS:
            return INITIAL_STATE;
        case FETCH_DOCTOR_DETAILS:
            return {...state, doctorDetail: action.payload};
        case FETCH_ONLINE_CLINICAL_DOCTORS_GROUP_IN_PROCESS:
            return {...state, loading: true};
        case FETCH_ONLINE_CLINICAL_DOCTORS_GROUP:
            return {...state, loading: false, onlineDoctors: action.payload};
        default:
            return state;
    }
}
