import {
    ADMIN_LOGIN_FAILS,
    ADMIN_LOGIN_PROCESS,
    ADMIN_LOGIN_SUCCESS,
    DOCTOR_LOGOUT_FAILS,
    DOCTOR_LOGOUT_INPROGRESS,
    DOCTOR_LOGOUT_SUCCESS
} from '../constants';

const INITIAL_STATE = {loading: false, error: '', user: {}, doctor_loading: false};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADMIN_LOGIN_PROCESS:
            return {...state, loading: true, error: '', doctor_loading: false};
        case ADMIN_LOGIN_SUCCESS:
            return {...state, loading: false, error: '', user: action.payload};
        case ADMIN_LOGIN_FAILS:
            return {...state, loading: false, error: action.payload};
        case DOCTOR_LOGOUT_INPROGRESS:
            return {...state, doctor_loading: true};
        case DOCTOR_LOGOUT_SUCCESS:
            return {...state, doctor_loading: false, loading: false};
        case DOCTOR_LOGOUT_FAILS:
            return {...state, doctor_loading: false, loading: false};
        default:
            return state;
    }
}
