import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import styles from './image.module.css'
import Spinner from '../Spinner'
import {storage, storageRef} from "../../firebase";
import removeIcon from '../../assets/remove.svg';
import healthCardFront from '../../assets/member_card_front.svg';
import healthCardBack from '../../assets/member_card_back.svg';
import Modal from "react-bootstrap/Modal";

const Image = forwardRef((props, ref) => {
    const inputRef = useRef(null)
    const [imageLocalURL, setImageLocalURL] = useState('')
    const [loading, setLoading] = useState(false)
    const [imageURL, setImageURL] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [selectedImage, setSelectedImage ] = useState(null)
    const {disabled} = props;
    useEffect(() => {
        console.log('props.value', props.value)
        setImageURL(props.value || null)
        //if (props.value?.data) setImageLocalURL(URL.createObjectURL(props.value.data))
    }, [props.value])

    const onRemoveImage = () => {
        setImageURL(null);
        setSelectedImage(null);
        setImageLocalURL(null);
        //console.log('onRemoveImage ', props.slug)
        //props.formikProps.setFieldValue(props.slug, null )
    }
    const saveImage = ( callback) => {
        let image = selectedImage;
        console.log('saveImage image', image)
        if (image == null) {
            setSelectedImage(null);
            setImageLocalURL(null);
            callback(null)
            return
        }
        setLoading(true)
        let uploadTask = storage.ref(props.path).put(image)
        uploadTask.on('state_changed', (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                console.log(`firebasestorage Upload is ${progress}% done`)
                switch (snapshot.state) {
                    case storageRef.TaskState.PAUSED: // or 'paused'
                        console.log('firebasestorage Upload is paused')
                        break
                    case storageRef.TaskState.RUNNING: // or 'running'
                        console.log('firebasestorage Upload is running')
                        break
                    default:
                        break
                }
            },
            (error) => {
                console.log('firebasestorage error ', error)
                setLoading(false)
                setSelectedImage(null);
                setImageLocalURL(null);
                callback(null)
                // Handle unsuccessful uploads
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    console.log('firebasestorage File available at', downloadURL)
                    setLoading(false)
                    setSelectedImage(null);
                    setImageLocalURL(null);
                    callback(downloadURL)
                }).catch((error) => {
                    console.log('firebasestorage getDownloadURL error ', error)
                    setLoading(false)
                    setSelectedImage(null);
                    setImageLocalURL(null);
                    callback(null)
                })
            })
    }
    const resetState = () => {
        setImageURL(props.value || null)
        setSelectedImage(null);
        setImageLocalURL(null);
        if (props.value?.data) setImageLocalURL(URL.createObjectURL(props.value.data))

    }

    useImperativeHandle(ref, () => ({
        saveImage,
        resetState
    }));

    const handleFileChange = (e) => {

        const image = e?.target.files[0]
        //console.log('handleFileChange props.formikProps ', props.formikProps)
        if(props.path){
            setSelectedImage(image)
            setImageLocalURL(URL.createObjectURL(image))

            /*props.saveImage(image, (url)=>{
                setImageURL(url)
                //props.formikProps.setFieldValue(props.slug, { url: url })
            })*/
        }else{
            //props.formikProps.setFieldValue(props.slug, { data: image })
        }
        //setImageLocalURL(localImage)


    }

    const onHideModal = () => {
        console.log("onHideModal clicked")
        setShowModal(false)
    }

    const renderModal = () => <Modal
        show={showModal} centered
        onHide={()=>{setShowModal(false)}}
        toggle={onHideModal}> {props.type === 'video' ?
        <video width='320' height='240' controls
               className='align-self-center  h-100'>
            <source src={imageURL || imageLocalURL} type='video/mp4' />
            Your browser does not support the video tag.
        </video> : <img src={imageURL || imageLocalURL} alt='full image' />
    }</Modal>;

    return (
        <div
            style={props.styles || {}}
        >
            <span>{props.label}</span>
            {loading? (
                <div className={styles.placeHolderCard}>
                    <Spinner color={"black"}  loading/>
                </div>
            ): imageURL || imageLocalURL ? (
                <div>
                    <div className={styles.placeHolderCard}>
                        <img
                            src={imageURL || imageLocalURL}
                            className={styles.filledImage}
                            alt={props?.slug === 'healthCardFront' ? 'healthCardFront' : 'healthCardBack'}
                            onClick={() => setShowModal(true)}
                        />

                        {!disabled && <img
                            src={removeIcon}
                            className={styles.removeIcon}
                            alt={'x'} onClick={onRemoveImage}/>}
                    </div>
                    <div className={styles.copyLink} onClick={()=>navigator.clipboard.writeText(imageURL || '')}>Copy Link</div>
                </div>
            ) : (
                <>
                    <input
                        ref={inputRef}
                        id='car'
                        type='file'
                        accept='image/*'
                        capture='camera'
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    <div className={styles.placeHolderCard}
                         onClick={() => {
                             if (!disabled) inputRef.current.click()
                         }}>
                        <img
                            src={props?.slug === 'healthCardFront' ? healthCardFront : healthCardBack}
                            className={styles.placeHolderImage}
                            alt={props?.slug === 'healthCardFront' ? 'healthCardFront' : 'healthCardBack'} />
                    </div>
                </>
            )}
            {showModal && renderModal()}
        </div>
    )
});
/* Image.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.object),
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
}

Image.defaultProps = {
  label: '',
  className: '',

  style: {},
  onClick: () => {},
  children: null,
  disabled: false,
  isLoading: false,
} */

export default Image
