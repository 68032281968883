import {
    CLINIC_BILLING_CODE_CHANGE,
    CLINIC_BILLING_CODE_DESC_CHANGE,
    CLINIC_BILLING_CODE_SAVE,
    CLINIC_FETCH_BILLING_CODES
} from '../../../constants';

import _ from 'lodash';
import {auth, database} from "../../../firebase";

export const clinic_billing_code_changed = (text) => {
    return {
        type: CLINIC_BILLING_CODE_CHANGE,
        payload: text
    }
}

export const clinic_billing_code_desc_changed = (text) => {
    return {
        type: CLINIC_BILLING_CODE_DESC_CHANGE,
        payload: text
    }
}

export const clinic_save_billing_code = (ID, code, desc) => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        let billingCodeValue = {
            billing_code: code,
            billing_code_desc: desc,
        }
        let billingCodekey = getBillingCodesRef(ID).push().key;
        var updates = {};
        updates[`clinicSetting/${ID}/billing_codes/${billingCodekey}`] = billingCodeValue;
        database.ref().update(updates)
            .then(() => {
                dispatch({
                    type: CLINIC_BILLING_CODE_SAVE
                })
            })
            .catch((error) => {
                //console.log("error: ", error.message);
            })
    }
}

export const clinic_fetchBillingCodes = (ID) => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        getBillingCodesRef(ID).on('value', codesSnap => {
            if (codesSnap.val()) {
                const codes = _.map(codesSnap.val(), (val, uid) => {
                    return {...val, uid};
                });

                const filteredCodes = codes.filter(function (el) {
                    return el != null && el !== undefined;
                });

                dispatch({
                    type: CLINIC_FETCH_BILLING_CODES,
                    payload: filteredCodes
                })
            } else {
                dispatch({
                    type: CLINIC_FETCH_BILLING_CODES,
                    payload: []
                })
            }
        })
    }
}

export const clinic_updateBillingCode = (ID, uid, desc, code, callback) => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        let billingCodeValue = {
            billing_code: code,
            billing_code_desc: desc,
        }

        var updates = {};
        updates[`/clinicSetting/${ID}/billing_codes/${uid}`] = billingCodeValue;
        database.ref().update(updates)
            .then(() => {
                callback(true);
            })
            .catch((error) => {
                callback(false);
                //console.log("error: ", error?.message);
            })
    }
}

export const clinic_deleteBillingCode = (ID, uid) => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        let billingCodeValue = {
            billing_code: null,
            billing_code_desc: null,
        }

        var updates = {};

        updates[`/clinicSetting/${ID}/billing_codes/${uid}`] = billingCodeValue;
        database.ref().update(updates)
            .then(() => {
                dispatch({
                    type: CLINIC_BILLING_CODE_SAVE
                })
            })
            .catch((error) => {
                //console.log('error: ', error);
            })
    }
}

const getBillingCodesRef = (ID) => {
    return database.ref(`clinicSetting/${ID}/billing_codes`);
}
