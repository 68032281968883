
import classes from './staff.module.css';
import AgentsList from './items';

const Addagent = (props) => {
    return (
        <tr className={classes.tr_view} onClick={() => props.onAddAgentClick()}>
            <td>
                <div className={classes.content}>
                    <div className={classes.plusIcon}>
                        <svg fill="#4384F5" width="14px" height="14px" viewBox="0 0 14 14">
                            <path fill="inherit" fillRule="nonzero" d="M14 8H8v6H6V8H0V6h6V0h2v6h6z"></path>
                        </svg>
                    </div>
                </div>
            </td>
            <td className={classes.td_view} colSpan="4">
                <div className={classes.invite}>Add Agent</div>
            </td>
        </tr>
    )
}

const Header = (props) => {
    return (
        <table classes={classes.table_view}>
            <thead>
            <tr>
                <th>
                    <span className={classes.table_heading}>Status</span>
                </th>
                <th>
                    <span className={classes.table_heading}>Name</span>
                </th>
                <th>
                    <span className={classes.table_heading}>Role</span>
                </th>
            </tr>
            </thead>
        </table>
    )
}

const AgentBody = (props) => {
    if (props.activeLink === 'Agents') {
        const agents = props.agentsData.length > 0 ? props.agentsData.map(agent => {
            return <AgentsList
                key={agent.uid}
                doctorID={agent.uid}
                doctorName={agent.doctorName}
                email={agent.email}
                role={agent.role}
                agent={agent}
                image={agent.profileImageURL}
                onlineStatus={agent.online}
                onAgentClick={props.onAgentClick}
                selectedAgent={props.selectedAgent}
                clinicalDoctor={agent.clinicalDoctor}
                clinicName={agent.clinicName}
                clinicLocationName={agent.clinicLocationName}
            />
        }) : null;
        return (
            <div className={classes.table_content}>
                <Header/>
                <div classes={classes.list_view}>
                    <table className={classes.table_view}>
                        <tbody>
                        {agents}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    } else {
        const agents = props.groupData.length > 0 ? props.groupData.map(agent => {
            return <AgentsList
                key={agent.uid}
                doctorName={agent.doctorName}
                email={agent.email}
                role={agent.role}
            />
        }) : null;
        return (
            <div className={classes.table_content}>
                <Header/>
            </div>
        )
    }
}

export default AgentBody;
