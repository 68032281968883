import {useEffect, useState} from "react";
import classes from "./AgoraComponent.module.css";
import {connect} from "react-redux";
import {endAgoraCall} from "../../../actions/ConversationAction";
import {database} from "../../../firebase";
import axios from "axios";
import {firebaseNorthEastRoot, firebaseRoot} from "../../../config";
const attendCall = require('../../../assets/attend_call.png');
const audioCallDisabled = require('../../../assets/audio_call_disabled.png');
const audioCallEnabled = require('../../../assets/audio_call_enabled.png');
const camera = require('../../../assets/camera-icon.png');
const declineCall = require('../../../assets/decline_call.png');
const defaultUser = require('../../../assets/defalt_user.png');
const microphone = require('../../../assets/microphone.png');
const muteCamera = require('../../../assets/mute-camera.png');
const muteMicrophone = require('../../../assets/mute-microphone.png');
const muteSpeaker = require('../../../assets/mute-speaker.png');
const videoCallDisabled = require('../../../assets/video_call_disabled.png');
const videoCallEnabled = require('../../../assets/video_call_enabled.png');
let callLogsRef = null;
const Controls = (props) => {
    const { track, tracks, setStart, setInCall, client, type } = props;
    const [trackState, setTrackState] = useState({ video: true, audio: true });

    const mute = async (type) => {
        if (type === "audio") {
            await tracks[0].setEnabled(!trackState.audio);
            setTrackState((ps) => {
                return { ...ps, audio: !ps.audio };
            });
        } else if (type === "video") {
            await tracks[1].setEnabled(!trackState.video);
            setTrackState((ps) => {
                return { ...ps, video: !ps.video };
            });
        }
    };
    const muteAudio = async () => {
        await track.setEnabled(!trackState.audio);
        setTrackState((ps) => {
            return { ...ps, audio: !ps.audio };
        });
    };

    const leaveChannel = async (status) => {
        try{

            await client.leave();
            client.removeAllListeners();
            if(type === "audioCall"){
                track.close();
            }else{
                tracks[1].close();
                tracks[0].close();
            }

            let updates = {};
            props.endAgoraCall();
            // await client.destroy();
            setStart(false);
            setInCall(false);
            try{
                let notificationPayload = {
                    "notificationToken":props?.selectedPatient?.notificationToken,
                    "data":{
                        "ReminderType": props?.callData?.callType === "audio"? "audioCallEnd":"videoCallEnd"
                    }
                }
                let voipNotificationPayload = {
                    "production": process.env.NODE_ENV !== 'development',
                    "token": props?.selectedPatient?.voipToken,
                    "payload": {
                        "reminderType": props?.callData?.callType === "audio"? "audioCallEnd":"videoCallEnd"
                    }
                }
                if(props?.selectedPatient?.notificationToken && (props?.selectedPatient?.platform).toLowerCase() === "android" && props?.callData?.callType){
                    //console.log("customCloudMessage notificationPayload", JSON.stringify(notificationPayload))

                    await database.ref(`callLogs/${props?.callData?.conversationId}/status`).once('value', async callLogsSnap =>{
                        //console.log("customCloudMessage callLogsSnap.val()", callLogsSnap.val())

                        if(callLogsSnap.val() === "initiated" || callLogsSnap.val() === "call_ringing"){
                            await axios({
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                url: `${firebaseRoot}/customCloudMessage`,
                                mode: 'cors',
                                data: JSON.stringify(notificationPayload),
                            })
                        }
                    })
                }
                if(props?.selectedPatient?.voipToken && (props?.selectedPatient?.platform).toLowerCase() === "ios" && props?.callData?.callType){
                    //console.log("customCloudMessage notificationPayload", JSON.stringify(notificationPayload))

                    await database.ref(`callLogs/${props?.callData?.conversationId}/status`).once('value', async callLogsSnap =>{
                        //console.log("customCloudMessage callLogsSnap.val()", callLogsSnap.val())

                        if(callLogsSnap.val() === "initiated" || callLogsSnap.val() === "call_ringing"){
                            await axios({
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                url: `${firebaseNorthEastRoot}/callNotificationVOIP`,
                                mode: 'cors',
                                data: JSON.stringify(voipNotificationPayload),
                            })
                        }
                    })
                }

            }
            catch (e) {
                console.log("error ", e)
            }
            if(props?.callData?.conversationId){
                updates[`/callLogs/${props?.callData?.conversationId}/hostStatus`] = "unavailable";
                if(!status) updates[`/callLogs/${props?.callData?.conversationId}/status`] = "doctor_ended";
                if(!status) updates[`/callLogs/${props?.callData?.conversationId}/events/${Date.now()}`] = "doctor_ended";
                await database
                    .ref()
                    .update(updates)
            }




        }catch (e) {
            console.log("error ", e)
        }


    };
    useEffect(()=>{
        if(callLogsRef){
            callLogsRef = null;
        }
        if(props?.callData?.conversationId){
            callLogsRef = database.ref(`callLogs/${props?.callData?.conversationId}/status`)
                callLogsRef.on('value', callLogsStatus => {

                const status = callLogsStatus.val()
                if(status === "patient_ended" || status === "patient_declined"){
                    leaveChannel("status");
                }
            })
        }

    },[props?.callData?.conversationId])
    if(type=== "audioCall"){
        return (
            <div className={`controls ${classes.controlSection}`}>
                <img
                    className={classes.icon_image}
                    src={trackState.audio ? microphone : muteMicrophone}
                    alt=""
                    onClick={() => muteAudio("audio")}
                />
                <img
                    className={classes.icon_image}
                    src={declineCall}
                    alt=""
                    onClick={() => leaveChannel()}
                />
            </div>
        );
    }
    return (
        <div className={`controls ${classes.controlSection}`}>
            <img
                className={classes.icon_image}
                src={trackState.audio ? microphone : muteMicrophone}
                alt=""
                onClick={() => mute("audio")}
            />
            <img
                className={classes.icon_image}
                src={trackState.video ? camera : muteCamera}
                alt=""
                onClick={() => mute("video")}
            />
            <img
                className={classes.icon_image}
                src={declineCall}
                alt=""
                onClick={() => leaveChannel()}
            />
        </div>
    );
};
const mapStateToProps = ({ conversationReducer }) => {
    const { callData, selectedPatient } = conversationReducer;

    return { callData, selectedPatient };
};
export default connect(mapStateToProps, { endAgoraCall})(Controls);
