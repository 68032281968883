import {Component, createRef, Fragment} from "react";
import classes from "./clinicpatientdetail.module.css";
import _style from "../../../../assets/_classes.module.css";
import TextareaAutosize from "react-autosize-textarea";
import {
    discardChanges,
    dispatchPrescriptionIschanged,
    dispatchPrescriptionIssaved,
    fetchChatPrescriptions,
    sendMessage,
    updateConversationMedication,
    updateConversationPrescriptionData,
    updateInsuranceCard,
} from "../../../../actions/ClinicalChatsAction";
import ReactDOM from "react-dom";
import PrescriptionBuilder from "../prescriptionBuilder";
import Select from "react-select";
import update from "react-addons-update";


import isMobileView from "../../../../utils/isMobileView";
import {connect} from "react-redux";
import {database} from "../../../../firebase";


var _ = require("lodash");
var moment = require("moment");

const optionsFreq = [
  { value: "Once a day", label: "Once a day" },
  { value: "Twice a day", label: "Twice a day" },
  { value: "Thrice a day", label: "Thrice a day" },
  { value: "Others", label: "Others", isCustom: true },
];

class PrescriptionForm extends Component {
  constructor(props) {
    super(props);
    this.state = { gender: "" };
    this.buttonRef1 = createRef();
    this.buttonRef2 = createRef();
    this.buttonRef3 = createRef();
    this.soapnotes = createRef();
    this.state = {
      gender: "",
      patient_name: "",
      patient_gender: "",
      patient_DOB: "",
      patient_summary: "",
      patient_current_medications: "",
      patient_medical_conditions: "",
      show_history_list: true,
      showSelectedMessages: null,
      show_health_info: true,
      show_general_info: true,
      show_prescriptions: true,
      showEditBlock: false,
      showSOAPNotes: true,
      selectedChat: "",
      limit: 2,
      currentChatSelected: true,
      showInsurance: true,
      showInsuranceEditModal: false,
      insuranceNumber: "",
      insuranceFront: "",
      insuranceBack: "",
      symptoms: "",
      findings: "",
      assessment: "",
      plan: "",
      patient_profile: true,
      showFollowUp: true,
      openWin: false,
      check: "",
      modalWindow: null,
      showfollowUpView: false,
      showCannedResponseForObjectiveFindingsList: false,
      showCannedResponseForAssessmentList: false,
      showCannedResponseForPlanList: false,
      canned_responses: [],
      canned_responses_assessment: [],
      canned_responses_plan: [],
      medications: [],
      routeOptions: [],
      medicationOptions: [],
      doseOptions: [],
      drugApiToken: "",
      options: [],
      allowedMedicationsOptions: [],
    };
    this.soapnotes = createRef();
  }

  componentDidMount() {
    // console.log("componentDidMount this.props", this.props)
    // console.log("componentDidMount this.state", this.state)
    if (this.props.allowedMedications?.length>0){
      let allowedMedications = this.props.allowedMedications||[]
      // console.log("this.props.allowedmedications", allowedMedications);
      this.setState({
        allowedMedicationsOptions: allowedMedications.map(medication =>{
          return {
            value: medication,
            label: medication,
            isCustom: true,
            pcid: -1,
          }
        })
      })
    }
    // const { childRef } = this.props;
    // childRef(this);
    if (this.props.selectedConversation || this.props.conversationId)
      this.props.fetchChatPrescriptions(
        this.props.isFrom,
        this.props.clinicId,
        this.props.selectedConversation || this.props.conversationId
      );
      // console.log("chat change")
      // console.log("didmount props", this.props);
      // console.log("didmount state", this.state);
    fetch(
      "https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/drugAPIToken"
    )
    .then((data) => data.json())
    .then((response) => {
      this.setState({ drugApiToken: response.token });
      if(this.state.drugApiToken){
        let medicationObject = this.props.user_medications && this.props.user_medications[this.props.selectedConversation || this.props.conversationId]
        let medications = medicationObject?.data || []
        if(medications.length>0) {
          medications.map((medication, index) => {
            if ((medication?.pcid)
            ){
              let med = {}
              med = {
                value: medication.medication || "",
                label: medication.medication || "",
                pcid: medication.pcid || "",
                check: true
              }
              // console.log("check this.state.medications", this.state.medications)
              this.onMedicationChange(med, index)
              // console.log("check medications", medications)
            }
          })
        }
      }
    });
        // const { childRef } = this.props;
        /* var meds = [{'medication':'test','dose':'','quantity':'','repeats':'', 'lu_codes':'', 'instructions':''}, {'medication':'','dose':'','quantity':'','repeats':'', 'lu_codes':'', 'instructions':''}];
        this.setState({medications: meds}) */
    if (this.props.user_medications[this.props.selectedConversation || this.props.conversationId]?.data) {
      let medicationObject = this.props.user_medications && this.props.user_medications[this.props.selectedConversation || this.props.conversationId]
      let medications = medicationObject?.data || []
      // console.log("check user_medication update_med_time", medications)
      if (medications?.length > 0) {
        let meds = [];
        for (let i = 0; i < medications.length; i++) {
          let med = {};
          med.medication = ""
          if (medications[i]?.medication){
            med.medication = {
              label: medications[i].medication,
              value: medications[i].medication,
              pcid: medications[i].pcid,
            };
          }
          med.route = ""
          if (medications[i]?.route){
            med.route = {
              label: medications[i].route,
              value: medications[i].route,
            };
          }
          med.dose = ""
          if (medications[i]?.dose){
            med.dose = {
              label: medications[i].dose,
              value: medications[i].dose,
            };
          }
          med.quantity = medications[i]?.quantity || "";
          med.instructions = medications[i]?.instructions || "";
          med.lu_codes = medications[i]?.lu_codes || "";
          med.repeats = medications[i]?.repeats || "";
          med.duration = medications[i]?.duration || ""
          med.frequency = ""
          if (medications[i]?.frequency){
            med.frequency = {
              label: medications[i].frequency,
              value: medications[i].frequency,
            };
          }
          med.composition = medications[i]?.composition || "";
          med.refills = medications[i]?.refills || "";
          meds.push(med);
        }
        this.setState({ medications: meds },
          () => {
            // console.log("check user_medication update_med_time", this.state.medications)
          });
        // console.log("check user_medication update_med_time", meds)
        // console.log("check user_medication update_med_time", this.state.medications)
      } else {
        this.setState({ medications: [] });
      }
    }

    this.setState({
      symptoms: this.props.subjective_symptoms,
      findings: this.props.findings,
      assessment: this.props.assessment,
      plan: this.props.plan,
      canned_responses: this.props.canned_responses,
      canned_responses_assessment: this.props.canned_responses_assessment,
      canned_responses_plan: this.props.canned_responses_plan,
    });
    var todayDate = new Date(
      moment()
        .add(parseInt(this.props.gmtOffset) * -1, "hours")
        .format("YYYY-MM-DD HH:mm:ss")
    );
    if (this.props.prescription_data[this.props.selectedConversation || this.props.conversationId]) {
      this.setState({
        patient_current_medications:
          this.props.prescription_data[this.props.selectedConversation || this.props.conversationId].current_medications || "",
        patient_medical_conditions:
          this.props.prescription_data[this.props.selectedConversation || this.props.conversationId].medical_conditions || "",
        patient_summary: this.props.prescription_data[this.props.selectedConversation || this.props.conversationId].summary || "",
      });
    }
    if (this.props.patientName || this.props.prescription_data[this.props.selectedConversation || this.props.conversationId]?.name) {

      this.setState({
        patient_name:
          this.props.prescription_data[this.props.selectedConversation || this.props.conversationId]?.name || "",
      });
    }
    if (this.props.gender || this.props.prescription_data[this.props.selectedConversation || this.props.conversationId]?.gender) {
      this.setState({
        patient_gender:
          this.props.prescription_data[this.props.selectedConversation || this.props.conversationId]?.gender || "",
      });
    }
    if (this.props.DOB || this.props.prescription_data[this.props.selectedConversation || this.props.conversationId]?.DOB) {
      this.setState({
        patient_DOB: this.props.prescription_data[this.props.selectedConversation || this.props.conversationId]?.DOB || "",
      });
    }

  }

  componentWillUnmount() {
    // const { childRef } = this.props;
    // childRef(undefined);
  }

  onChangeValue = (event) => {
    this.props.radioButtonChange(event.target.value);

    /* var todayDate = new Date(moment().add(parseInt(this.props.gmtOffset) * -1, 'hours').format("YYYY-MM-DD HH:mm:ss"));

        if (event.target.value === '3 days') {
            todayDate.setDate(todayDate.getDate() + 3)
            self.setState({
                dateTime: `${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getFullYear()}-${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getMonth() + 1}`.padStart(2, 0)}-${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getDate()}`.padStart(2, 0)}T${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getHours()}`.padStart(2, 0)}:${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getMinutes()}`.padStart(2, 0)}`
            });
        } else if (event.target.value === '1 week') {
            todayDate.setDate(todayDate.getDate() + 7)
            self.setState({
                dateTime: `${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getFullYear()}-${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getMonth() + 1}`.padStart(2, 0)}-${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getDate()}`.padStart(2, 0)}T${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getHours()}`.padStart(2, 0)}:${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getMinutes()}`.padStart(2, 0)}`
            });
        } else {
            todayDate.setDate(todayDate.getDate() + 30)
            self.setState({
                dateTime: `${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getFullYear()}-${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getMonth() + 1}`.padStart(2, 0)}-${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getDate()}`.padStart(2, 0)}T${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getHours()}`.padStart(2, 0)}:${`${new Date(moment(todayDate).utcOffset(this.props.gmtOffset).format("YYYY-MM-DD HH:mm:ss")).getMinutes()}`.padStart(2, 0)}`
            });
        } */
  };

  incrementRefillValue = (index) => {
    let medications = { ...this.state.medications[index] };
    let refills = medications.refills || 0;
    medications.refills = parseInt(refills) + 1;
    this.setState(
      update(this.state, {
        medications: {
          [index]: {
            $set: medications,
          },
        },
      })
    );
  };

  decrementRefillValue = (index) => {
    let medications = { ...this.state.medications[index] };
    let refills = medications.refills || 0;
    if (refills <= 0) {
    } else {
      medications.refills = parseInt(refills) - 1;
      this.setState(
        update(this.state, {
          medications: {
            [index]: {
              $set: medications,
            },
          },
        })
      );
    }
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    // console.log("unsafe_componentwillreceiveprops this.props", this.props)
    // console.log("unsafe_componentwillreceiveprops nextProps", nextProps)
    if (this.props.allowedMedications !== nextProps.allowedMedications){
      let allowedMedications = nextProps.allowedMedications||[]
      this.setState({
          allowedMedicationsOptions: allowedMedications.map(medication => {
              return {
                  value: medication,
                  label: medication,
                  isCustom: true,
                  pcid: -1,
              }
          })
      })
    }

    if (this.props.prescription_data[this.props.selectedConversation || this.props.conversationId] !== nextProps.prescription_data[nextProps.selectedConversation || nextProps.conversationId] && nextProps.prescription_data[nextProps.selectedConversation || nextProps.conversationId]) {
      this.setState({
        patient_current_medications:
          nextProps.prescription_data[nextProps.selectedConversation || nextProps.conversationId].current_medications || "",
        patient_medical_conditions:
          nextProps.prescription_data[nextProps.selectedConversation || nextProps.conversationId].medical_conditions || "",
        patient_summary: nextProps.prescription_data[nextProps.selectedConversation || nextProps.conversationId].summary || "",
      });
    }
    if (
      // this.props.patientName !== nextProps.patientName ||
      this.props.prescription_data[this.props.selectedConversation || this.props.conversationId]?.name !== nextProps.prescription_data[nextProps.selectedConversation || nextProps.conversationId]?.name
    ) {
      this.setState({
        patient_name:
          nextProps.prescription_data[nextProps.selectedConversation || nextProps.conversationId]?.name || ""
          // nextProps.patientName,
      });
    }
    if (
      // this.props.gender !== nextProps.gender ||
      this.props.prescription_data[this.props.selectedConversation || this.props.conversationId]?.gender !==
        nextProps.prescription_data[nextProps.selectedConversation || nextProps.conversationId]?.gender
    ) {
      this.setState({
        patient_gender: nextProps.prescription_data[nextProps.selectedConversation || nextProps.conversationId]?.gender || ""
        // nextProps.gender,
      });
    }
    if (
      // this.props.DOB !== nextProps.DOB ||
      this.props.prescription_data[this.props.selectedConversation || this.props.conversationId]?.DOB !== nextProps.prescription_data[nextProps.selectedConversation || nextProps.conversationId]?.DOB
    ) {
      this.setState({
        patient_DOB: nextProps.prescription_data[nextProps.selectedConversation || nextProps.conversationId]?.DOB || ""
        // nextProps.DOB,
      });
    }

    if (
      this.props.selectedConversation !== nextProps.selectedConversation ||
      this.props.conversationId !== nextProps.conversationId
    ) {
      if (nextProps.selectedConversation || nextProps.conversationId) {
        // console.log("chat created")
        this.props.fetchChatPrescriptions(
          this.props.isFrom,
          this.props.clinicId,
          nextProps.selectedConversation || nextProps.conversationId
        );
      }
    }

    if(this.props.selectedConversation !== nextProps.selectedConversation && this.props.selectedConversation){
      let meds_data = {};
      meds_data.DOB = this.state.patient_DOB || "";
      meds_data.current_medications = this.state.patient_current_medications || "";
      meds_data.gender = this.state.patient_gender || "";
      meds_data.medical_conditions = this.state.patient_medical_conditions || "";
      meds_data.name = this.state.patient_name || "";
      meds_data.summary = this.state.patient_summary || "";
      // console.log("Prescription Data chat change", meds_data)
      this.props.updateConversationPrescriptionData(this.props.selectedConversation, meds_data)
    }

    if(this.props.conversationId !== nextProps.conversationId && this.props.conversationId){
      let meds_data = {};
      meds_data.DOB = this.state.patient_DOB || "";
      meds_data.current_medications = this.state.patient_current_medications || "";
      meds_data.gender = this.state.patient_gender || "";
      meds_data.medical_conditions = this.state.patient_medical_conditions || "";
      meds_data.name = this.state.patient_name || "";
      meds_data.summary = this.state.patient_summary || "";
      // console.log("Prescription Data chat change", meds_data)
      this.props.updateConversationPrescriptionData(this.props.selectedConversation, meds_data)
    }

    if(this.props.selectedConversation !== nextProps.selectedConversation && this.props.selectedConversation){
      let medication = this.state.medications
      if (medication?.length > 0) {
        let meds = [];
        for (let i = 0; i < medication.length; i++) {
          let med = {};
          med.medication = medication[i].medication?.value || medication[i].medication || "";
          med.route = medication[i].route?.value || medication[i].route || "";
          med.dose = medication[i].dose?.value || medication[i].dose || "";
          med.quantity = medication[i].quantity || "";
          med.instructions = medication[i].instructions || "";
          med.lu_codes = medication[i].lu_codes || "";
          med.repeats = medication[i].repeats || "";
          med.duration = medication[i].duration?.value || medication[i].duration || "";
          med.frequency = medication[i].frequency?.value || medication[i].frequency || "";
          med.pcid = medication[i].medication?.pcid || medication[i].pcid || "";
          med.composition = medication[i].composition || "";
          med.refills = medication[i].refills || "";
          meds.push(med);
        }
        this.props.updateConversationMedication(this.props.selectedConversation, meds)
      }
    }

    if(this.props.conversationId !== nextProps.conversationId && this.props.conversationId){
      let medication = this.state.medications
      if (medication?.length > 0) {
        let meds = [];
        for (let i = 0; i < medication.length; i++) {
          let med = {};
          med.medication = medication[i].medication?.value || medication[i].medication || "";
          med.route = medication[i].route?.value || medication[i].route || "";
          med.dose = medication[i].dose?.value || medication[i].dose || "";
          med.quantity = medication[i].quantity || "";
          med.instructions = medication[i].instructions || "";
          med.lu_codes = medication[i].lu_codes || "";
          med.repeats = medication[i].repeats || "";
          med.duration = medication[i].duration?.value || medication[i].duration || "";
          med.frequency = medication[i].frequency?.value || medication[i].frequency || "";
          med.pcid = medication[i].medication?.pcid || medication[i].pcid || "";
          med.composition = medication[i].composition || "";
          med.refills = medication[i].refills || "";
          meds.push(med);
        }
        this.props.updateConversationMedication(this.props.conversationId, meds)
      }
    }

    if (this.props.update_med_time !== nextProps.update_med_time) {
      let medicationObject = nextProps.user_medications && nextProps.user_medications[nextProps.selectedConversation || nextProps.conversationId]
      let medications = medicationObject?.data || []
      if (medications?.length > 0) {
        let meds = [];
        for (let i = 0; i < medications.length; i++) {
          let med = {};
          med.medication = ""
          if (medications[i]?.medication){
            med.medication = {
              label: medications[i].medication,
              value: medications[i].medication,
              pcid: medications[i].pcid,
            };
          }
          med.route = ""
          if (medications[i]?.route){
            med.route = {
              label: medications[i].route,
              value: medications[i].route,
            };
          }
          med.dose = ""
          if (medications[i]?.dose){
            med.dose = {
              label: medications[i].dose,
              value: medications[i].dose,
            };
          }
          med.quantity = medications[i]?.quantity || "";
          med.instructions = medications[i]?.instructions || "";
          med.lu_codes = medications[i]?.lu_codes || "";
          med.repeats = medications[i]?.repeats || "";
          med.duration = medications[i]?.duration || ""
          med.frequency = ""
          if (medications[i]?.frequency){
            med.frequency = {
              label: medications[i].frequency,
              value: medications[i].frequency,
            };
          }
          med.composition = medications[i]?.composition || "";
          med.refills = medications[i]?.refills || "";
          meds.push(med);
        }
        this.setState({ medications: meds },
          () => {
          });
      } else {
        this.setState({ medications: [] });
      }
    }

    if (this.props.isLoading === true && nextProps.isLoading === false) {
      this.setState({ showFollowUp: false });
      this.props.followUpSaveSuccess();
    }
    if (this.props.gmtOffset != nextProps.gmtOffset) {
      if (this.buttonRef1.current != null) {
        this.buttonRef1.current.checked = false;
      }
      if (this.buttonRef2.current != null) {
        this.buttonRef2.current.checked = false;
      }
      if (this.buttonRef3.current != null) {
        this.buttonRef3.current.checked = false;
      }
      this.setState({
        dateTime: `${new Date(
          moment().utcOffset(nextProps.gmtOffset).format("YYYY-MM-DD HH:mm:ss")
        ).getFullYear()}-${`${
          new Date(
            moment()
              .utcOffset(nextProps.gmtOffset)
              .format("YYYY-MM-DD HH:mm:ss")
          ).getMonth() + 1
        }`.padStart(2, 0)}-${`${new Date(
          moment().utcOffset(nextProps.gmtOffset).format("YYYY-MM-DD HH:mm:ss")
        ).getDate()}`.padStart(2, 0)}T${`${new Date(
          moment().utcOffset(nextProps.gmtOffset).format("YYYY-MM-DD HH:mm:ss")
        ).getHours()}`.padStart(2, 0)}:${`${new Date(
          moment().utcOffset(nextProps.gmtOffset).format("YYYY-MM-DD HH:mm:ss")
        ).getMinutes()}`.padStart(2, 0)}`,
      });
    }

    if (nextProps.canned_responses !== this.props.canned_responses) {
      this.setState({ canned_responses: nextProps.canned_responses });
    }

    if (
      nextProps.canned_responses_assessment !==
      this.props.canned_responses_assessment
    ) {
      this.setState({
        canned_responses_assessment: nextProps.canned_responses_assessment,
      });
    }

    if (nextProps.canned_responses_plan !== this.props.canned_responses_plan) {
      this.setState({ canned_responses_plan: nextProps.canned_responses_plan });
    }

    if (nextProps.subjective_symptoms !== this.props.subjective_symptoms) {
      this.setState({ symptoms: nextProps.subjective_symptoms });
    }

    if (nextProps.findings !== this.props.findings) {
      this.setState({ findings: nextProps.findings });
    }

    if (nextProps.assessment !== this.props.assessment) {
      this.setState({ assessment: nextProps.assessment });
    }

    if (nextProps.plan !== this.props.plan) {
      this.setState({ plan: nextProps.plan });
    }

    if (
      !Boolean(this.props.showSOAPNotes) &&
      Boolean(nextProps.showSOAPNotes)
    ) {
      this.setState({ showEditBlock: true });
    }
    // console.log("unsafe_componentwillreceiveprops this.state", this.state)
  };

  renderPrescription() {
    // console.log("renderPrescription this.props", this.props)
    // console.log("renderPrescription this.state", this.state)
    let medicationObject = this.props.user_medications && this.props.user_medications[this.props.selectedConversation || this.props.conversationId]
    let medications = medicationObject?.data || []
    if (this.modalWindow) {
      let root = this.modalWindow?.document?.body;
      ReactDOM.render(
        <PrescriptionBuilder
          prescriptionID={
            this.props.selectedConversation || this.props.conversationId
          }
          clinicName={
            this.props.prescriptionDoctor?.clinic_name || this.props.clinic_name
          }
          clinicAddress={
            this.props.prescriptionDoctor?.address || this.props.clinicAddress
          }
          clinicCity={
            this.props.prescriptionDoctor?.city || this.props.clinic_name
          }
          clinicCountry={
            this.props.prescriptionDoctor?.country || this.props.clinicCountry
          }
          clinicState={
            this.props.prescriptionDoctor?.state || this.props.clinicProvince
          }
          clinicPostalCode={
            this.props.prescriptionDoctor?.postal_code ||
            this.props.clinicPostalCode
          }
          clinicPhoneNumber={
            this.props.prescriptionDoctor?.phone || this.props.clinicPhoneNumber
          }
          clinicFaxNumber={
            this.props.prescriptionDoctor?.fax || this.props.clinicFaxNumber
          }
          patientName={
            this.props.prescription_data[this.props.selectedConversation || this.props.conversationId]?.name || this.props.patientName
          }
          patientDOB={this.props.prescription_data[this.props.selectedConversation || this.props.conversationId]?.DOB || this.props.DOB}
          patientGender={
            this.props.prescription_data[this.props.selectedConversation || this.props.conversationId]?.gender || this.props.gender
          }
          patient_phone_number={this.props.patient_phone_number}
          patientAddress={this.props.complete_address}
          healthInsuranceCardNumber={this.props.healthInsuranceCardNumber || ""}
          start_timestamp={this.props.start_timestamp || new Date().getTime()}
          user_medications={medications}
          doctorName={this.props.doctorName}
          signatureImageURL={
            this.props.prescriptionDoctor?.signature_url ||
            this.props.currentDoctorProfile?.signatureImageURL
          }
          doctorlicense={
            this.props.prescriptionDoctor?.license_no ||
            this.props.doctorlicense
          }
          license_name={this.props.prescriptionDoctor?.license_name}
          endTime={this.props.endTime || new Date().getTime()}
        />,
        root
      );
    }
  }

  showPrescriptions = () => {
    this.setState((state) => ({
      show_prescriptions: !state.show_prescriptions,
    }));
  };

  getStaffMemberByRef = (clinicid, uid) => {
    return database
      .ref(`/clinicStaffMember/${clinicid}/${uid}`)
      .once("value");
  };
  getDoctorByRef = (uid) => {
    return database.ref(`/doctors/${uid}`).once("value");
  };

  sendPrescriptionMessage = (
    clinicID,
    conversationID,
    medicineRemoved,
    prescribedMeds
  ) => {
    const prescribedMedsToStringFmt =
      prescribedMeds.length > 0
        ? prescribedMeds
            ?.map((med) => `${med.medication} (${med.dose})`)
            .join(", \n")
        : "";

    database
      .ref(
        this.props.isFrom === "tier1"
          ? `/openconversations/${conversationID}/`
          : `/openClinicalConversations/${clinicID}/${conversationID}/`
      )
      .once("value")
      .then(async (snap) => {
        const val = snap.val();
        if (val.doctorID) {
          try {
            if (this.props.isFrom === "tier1") {
              const staffMembers = await this.getDoctorByRef(val.doctorID);
              const clinicDoctorSnapVal = staffMembers.val();
              if (clinicDoctorSnapVal.doctorName) {
                const content =
                  medicineRemoved !== false && prescribedMeds.length > 0
                    ? `Following medicines have been issued to you:\n${prescribedMedsToStringFmt}\nDr. Asim Cheema, the prescribing physician will follow up with you within 48 hours for an update on your medical condition.\nIf you have any questions or concerns in the meantime please reach out to support@yourdoctors.online.`
                    : "Your prescription has been removed";
                this.props.sendYDOMessage(
                  conversationID,
                  { type: "text", content }, //isprescription flag to send and medications as object (comma separated string)
                  this.props.patientId,
                  this.props.doctorName,
                  this.props.doctorImage
                );
                /*this.props.sendMessage(
                    clinicID,
                    conversationID,
                    { type: "text", content },
                    this.props.patientId,
                    clinicDoctorSnapVal.doctorName,
                    ""
                );*/
              } else {
                console.log("doctor's full name doesn't exist. ");
              }
            } else {
              const staffMembers = await this.getStaffMemberByRef(
                clinicID,
                val.doctorID
              );
              const clinicDoctorSnapVal = staffMembers.val();

              if (clinicDoctorSnapVal.fullName) {
                const content =
                  medicineRemoved !== false && prescribedMeds.length > 0
                    ? `Following medicines have been issued to you:\n${prescribedMedsToStringFmt}.`
                    : "Your prescription has been removed";

                this.props.sendMessage(
                  clinicID,
                  conversationID,
                  { type: "text", content },
                  this.props.patientId,
                  clinicDoctorSnapVal.fullName,
                  ""
                );
              } else {
                console.log("doctor's full name doesn't exist. ");
              }
            }
          } catch (error) {
            console.log("doctor name in clinicStaffMember not found", error);
          }
        } else {
          console.error(
            "doctor ID not found. unable to send prescription message"
          );
        }
      })
      .catch((err) => {
        console.error("error in fetching doctor's conversation", err);
      });
  };

  deleteMedication = () =>{
    let medication = this.state.medications
    // console.log("deletemedication function this.state.medication", this.state.medications)
    // if (medication?.length > 0) {
    let meds = [];
    for (let i = 0; i < medication.length; i++) {
      let med = {};
      med.medication = medication[i].medication?.value || medication[i].medication || "";
      med.route = medication[i].route?.value || medication[i].route || "";
      med.dose = medication[i].dose?.value || medication[i].dose || "";
      med.quantity = medication[i].quantity || "";
      med.instructions = medication[i].instructions || "";
      med.lu_codes = medication[i].lu_codes || "";
      med.repeats = medication[i].repeats || "";
      med.duration = medication[i].duration?.value || medication[i].duration || "";
      med.frequency = medication[i].frequency?.value || medication[i].frequency || "";
      med.pcid = medication[i].medication?.pcid || medication[i].pcid || "";
      med.composition = medication[i].composition || "";
      med.refills = medication[i].refills || "";
      meds.push(med);
    }
    // console.log("deletemedication function meds", meds)
    this.props.updateConversationMedication(this.props.selectedConversation, meds)
    // }
  }
  discardChanges = () => {
    this.props.discardChanges(
      this.props.isFrom,
      this.props.clinicId,
      this.props.selectedConversation || this.props.conversationId
    );
  }
  onCancel = () => {
    this.setState({ showInsuranceEditModal: false });
  };
  saveMedication = (isDelete) => {
    this.setState({ error: "" });
    let isnotFilled = false;
    let isUserInfoFilled = !(
      !!this.state.patient_name &&
      !!this.state.patient_gender &&
      !!this.state.patient_DOB &&
      !!this.state.patient_summary &&
      !!this.state.patient_current_medications &&
      !!this.state.patient_medical_conditions
    );
    let medications = [...this.state.medications];
    let conversationID =
    this.props.selectedConversation || this.props.conversationId;
    let clinicID = this.props.clinicId;
    if (medications.length > 0) {
      for (let i = 0; i < medications.length; i++) {
        let data = { ...medications[i] };
        if (!(!!(data.medication) && !(data.medication === "Others" || data.medication?.value === "Others") && !!(data.route) && !!(data.dose) && !!(data.quantity) && !!(data.frequency) && !!(data.duration) && !!(data.composition) && Boolean(data.instructions))) {
          // && Boolean(data.repeats) && Boolean(data.lu_codes)
          isnotFilled = true;
        }
      }
      if (isnotFilled || isUserInfoFilled) {
        this.setState({ error: "Please fill all the fields" });
      } else {
        alert(
          "Your prescription has been saved and will be sent to the patient after the encounter"
        );
        let updates = {};
        let updates1 = {};
        let prescribedMeds = [];
        this.props.dispatchPrescriptionIssaved(this.props.selectedConversation || this.props.conversationId)
        updates[
          this.props.isFrom === "tier1"
            ? `/openconversations/${conversationID}/prescriptions`
            : `/openClinicalConversations/${clinicID}/${conversationID}/prescriptions`
        ] = null;
        for (let i = 0; i < medications.length; i++) {
          let data = { ...medications[i] };
          // console.log("pcidData", data.medication.pcid);
          // console.log("presctiprion update1 be data", data);
          let presciptiondata = data;
          presciptiondata.pcid = data.medication.pcid;
          // console.log("pcidData", data.medication.pcid);
          presciptiondata.medication = data.medication.value;
          presciptiondata.route = data.route.value;
          presciptiondata.dose = data.dose.value;
          presciptiondata.frequency = data.frequency.value;
          presciptiondata.duration = data.duration;
          presciptiondata.quantity = data.quantity;
          presciptiondata.refills = data.refills;
          presciptiondata.instructions = data.instructions;
          presciptiondata.composition = data.composition;
          prescribedMeds.push(presciptiondata);
          // console.log("presctiprion update1 be data", presciptiondata);
          if (this.props.isFrom === "tier1") {
            let prescriptionkey = database
              .ref(`/openconversations/${conversationID}/prescriptions`)
              .push().key;
            updates1[
              `/openconversations/${conversationID}/prescriptions/${prescriptionkey}`
            ] = presciptiondata;
            if (this.state.patient_name)
              updates1[
                `/openconversations/${conversationID}/prescriptions_data/name`
              ] = this.state.patient_name;
            if (this.state.patient_gender)
              updates1[
                `/openconversations/${conversationID}/prescriptions_data/gender`
              ] = this.state.patient_gender;
            if (this.state.patient_DOB)
              updates1[
                `/openconversations/${conversationID}/prescriptions_data/DOB`
              ] = this.state.patient_DOB;
            if (this.state.patient_summary)
              updates1[
                `/openconversations/${conversationID}/prescriptions_data/summary`
              ] = this.state.patient_summary;
            if (this.state.patient_current_medications)
              updates1[
                `/openconversations/${conversationID}/prescriptions_data/current_medications`
              ] = this.state.patient_current_medications;
            if (this.state.patient_medical_conditions)
              updates1[
                `/openconversations/${conversationID}/prescriptions_data/medical_conditions`
              ] = this.state.patient_medical_conditions;
          } else {
            let prescriptionkey = database
              .ref(
                `/openClinicalConversations/${clinicID}/${conversationID}/prescriptions`
              )
              .push().key;
            updates1[
              `/openClinicalConversations/${clinicID}/${conversationID}/prescriptions/${prescriptionkey}`
            ] = presciptiondata;
            if (this.state.patient_name)
              updates1[
                `/openClinicalConversations/${clinicID}/${conversationID}/prescriptions_data/name`
              ] = this.state.patient_name;
            if (this.state.patient_gender)
              updates1[
                `/openClinicalConversations/${clinicID}/${conversationID}/prescriptions_data/gender`
              ] = this.state.patient_gender;
            if (this.state.patient_DOB)
              updates1[
                `/openClinicalConversations/${clinicID}/${conversationID}/prescriptions_data/DOB`
              ] = this.state.patient_DOB;
            if (this.state.patient_summary)
              updates1[
                `/openClinicalConversations/${clinicID}/${conversationID}/prescriptions_data/summary`
              ] = this.state.patient_summary;
            if (this.state.patient_current_medications)
              updates1[
                `/openClinicalConversations/${clinicID}/${conversationID}/prescriptions_data/current_medications`
              ] = this.state.patient_current_medications;
            if (this.state.patient_medical_conditions)
              updates1[
                `/openClinicalConversations/${clinicID}/${conversationID}/prescriptions_data/medical_conditions`
              ] = this.state.patient_medical_conditions;
          }
        }
        database.ref()
          .update(updates)
          .then((data) => {
            database
              .ref()
              .update(updates1)
              .then((data) => {
                if (!Boolean(isDelete)) {
                  this.setState({ show_prescriptions: false });

                  if (isMobileView()) this.props.goBack();

                  this.sendPrescriptionMessage(
                    clinicID,
                    conversationID,
                    true,
                    prescribedMeds
                  );
                }
              })
              .catch((error) => {
                console.log("presctiprion update1 error", error);
              });
          })
          .catch((error) => {
            console.log("presctiprion update error", error);
          });
      }
    } else {
      alert(
        "Your prescription has been removed"
      );
      this.props.dispatchPrescriptionIssaved(this.props.selectedConversation || this.props.conversationId)
      database
      .ref(
        this.props.isFrom === "tier1"
          ? `/openconversations/${conversationID}/`
          : `/openClinicalConversations/${clinicID}/${conversationID}/`
      )
      .once("value")
      .then(async (snap) => {
        const val = snap.val();
        if(val.prescriptions){
          database.ref(
              this.props.isFrom === "tier1"
                ? `/openconversations/${conversationID}/prescriptions`
                : `/openClinicalConversations/${clinicID}/${conversationID}/prescriptions`
            )
            .set([])
            .then(() => this.forceUpdate());
          this.sendPrescriptionMessage(clinicID, conversationID, false, []);
          console.log("All deleted");
          // this.forceUpdate()
        }
      })
    }
        /* const modalTitle = "Patient Profile";
        if (!Boolean(this.modalWindow)) {
            this.modalWindow = window.open("/view_pdf", modalTitle);
            this.modalWindow.addEventListener('load', () => {
                this.modalWindow.document.title = modalTitle;
                this.renderPrescription();
            }, false
            );
        } else {
            this.modalWindow.close();
            this.modalWindow = window.open("/view_pdf", modalTitle);
            this.modalWindow.addEventListener('load', () => {
                this.modalWindow.document.title = modalTitle;
                this.renderPrescription();
            }, false
            );
        } */
    // this.props.fetchChatPrescriptions(this.props.isFrom,clinicID, conversationID)
  };

  changeMedication = (text, index) => {
    const regex = /(<([^>]+)>)/gi;
    if (text) {
      fetch(`https://api-js.drugbank.com/v1/product_concepts?q=${text}`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.state.drugApiToken}`,
        },
      })
        //.then(response => response.json())
        .then((response) => {
          let self = this;
          response.json().then((responseData) => {
            let medicationsData = [];
            // console.log("Drugbank api response MD", responseData)
            for (let i = 0; i < responseData.length; i++) {
              /*brand: "DM"
              display_name: "DM"
              drugbank_pcid: "DBPC0369087"
              form: null
              hits: (4) [{…}, {…}, {…}, {…}]
              ingredients: Array(1)
              0:
              drug: {name: 'Dextromethorphan', drugbank_id: 'DB00514'}
              name: "Dextromethorphan"
                  [[Prototype]*/
              let isAllowed = (this.props.isFrom !== "tier1")
              if(this.props.allowedMedications && !isAllowed ){
                for(let j = 0; j < (responseData[i].ingredients?.length || 0 ); j++ ){
                  if(this.props.allowedMedications.findIndex(data => {
                    return String(data).toLowerCase().replace(/\s/g, '') == String(responseData[i]?.name).toLowerCase().replace(/\s/g, '')
                  }) >=0)
                    isAllowed = true;
                }
              }
              if (isAllowed) {
                for (let j = 0; j < responseData[i].hits.length; j++) {
                  medicationsData.push({
                    value: responseData[i].hits[j].value.replace(regex, ""),
                    pcid: responseData[i].drugbank_pcid,
                    label: responseData[i].hits[j].value.replace(regex, ""),
                  });
                }
              }
            }
            // console.log("Allowed medications", medicationsData)
            self.setState(
              update(this.state, {
                medicationOptions: {
                  [index]: {
                    $set: medicationsData,
                  },
                },
              })
            );
          });
        })
        .catch((error) => {
          console.log("drugbankplus error ", error);
        });
    }
  };
  onMedicationChange = (selectedOption, index) => {
    if(!selectedOption?.check){
      this.props.dispatchPrescriptionIschanged(this.props.selectedConversation || this.props.conversationId)
    }
    let self = this;
    let medication = {...self.state.medications[index]};
    medication.medication = {...selectedOption}
    if (medication.medication.value== "Others"){
      medication.medication.value=""
      medication.medication.label=""
    }
    self.setState(
      update(this.state, {
        medications: {
          [index]: {
            $set: medication,
          },
        },
      })
    );
    if (selectedOption.pcid === -1) {
      let routeOptions = [];
      let medicationObject = this.props.user_medications && this.props.user_medications[this.props.selectedConversation || this.props.conversationId]
      let medications = medicationObject?.data || []
      if(medications[index]?.route){
        routeOptions.push({
          value: medications[index]?.route,
          label: medications[index]?.route,
          isCustom: true,
        });
      }
      else{
        routeOptions.push({
          value: "Others",
          label: "Others",
          isCustom: true,
        });
      }
      self.setState(
        update(self.state, {
          routeOptions: {
            [index]: {
              $set: routeOptions,
            },
          },
        })
      );
      let doseData = [];
      if(medications[index]?.dose){
        doseData.push({
          value: medications[index]?.dose,
          label: medications[index]?.dose,
          isCustom: true,
        });
      }
      else{
        doseData.push({
          value: "Others",
          label: "Others",
          isCustom: true,
        });
      }
      self.setState(
        update(self.state, {
          doseOptions: {
            [index]: {
              $set: doseData,
            },
          },
        })
      );
      medication.medication = selectedOption;
      medication.route = routeOptions[0];
      medication.dose = doseData[0];
      self.setState(
        update(this.state, {
          medications: {
            [index]: {
              $set: medication,
            },
          },
        })

      );
    } else {
      fetch(
        `https://api-js.drugbank.com/v1/ca/product_concepts/${selectedOption.pcid}/routes`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.state.drugApiToken}`,
          },
        }
      ).then((response) => {
        let routeOptions = [];
        response.json().then((responseData) => {
          for (let i = 0; i < responseData.length; i++) {
            routeOptions.push({
              value: responseData[i].route,
              label: responseData[i].route,
            });
          }
          routeOptions.push({
            value: "Others",
            label: "Others",
            isCustom: true,
          });
          self.setState(
            update(self.state, {
              routeOptions: {
                [index]: {
                  $set: routeOptions,
                },
              },
            })
          );
        });
      });

      fetch(
        `https://api-js.drugbank.com/v1/ca/product_concepts/${selectedOption.pcid}/strengths`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.state.drugApiToken}`,
          },
        }
      ).then((response) => {
        let doseData = [];
        response.json().then((responseData) => {
          for (let i = 0; i < responseData.length; i++) {
            var str = responseData[i].strengths.split(",");
            for (let j = 0; j < str.length; j++) {
              if (!doseData.includes(str[j])) {
                doseData.push({
                  value: str[j],
                  label: str[j],
                });
              }
            }
          }
          doseData.push({
            value: "Others",
            label: "Others",
            isCustom: true,
          });
          self.setState(
            update(self.state, {
              doseOptions: {
                [index]: {
                  $set: doseData,
                },
              },
            })
          );
        });
      });
    }
  };


  renderMedications(showMobileView) {
    let self = this;
    // console.log("render props", this.props);
    // console.log("render state", this.state);


    return this.state.medications.map((data, index) => {
      // console.log("render data", data)
      return (
        <div key={`medication_${index}`}>
          <div className={classes.prescriptionEntries}>
            <span
              className={
                showMobileView ? _style.inputItemLabel : classes.header_attr
              }
              style={showMobileView ? { margin: "0 0 -5px 0" } : null}
            >
              Medication
            </span>
            <span
              className={
                showMobileView ? _style.inputItemLabel : classes.header_attr
              }
              style={showMobileView ? { margin: "0 0 -5px 0" } : null}
            >
              Name
            </span>
            {self?.state?.medications[index]?.medication?.isCustom !== true &&
              <Select
                placeholder={"Medication"}
                value={data.medication}
                defaultValue={data.medication}
                options={self.state.medicationOptions[index]?.length>0?self.state.medicationOptions[index]:[{value: "Others", label: "Others", isCustom: true, pcid: -1}]}
                onInputChange={(text) => self.changeMedication(text, index)}
                onChange={(selectedOption) =>
                  this.onMedicationChange(selectedOption, index)
                }
              />}
            {this.props.isFrom !== "tier1" && self?.state?.medications[index]?.medication?.isCustom === true &&
            <input
                className={classes.prescriptionItem}
                placeholder={"Medication"}
                value={
                  data.medication?.value === "Others"
                    ? ""
                    : data.medication?.value
                }
                onChange={(e) => {
                  let medications = self.state.medications[index];
                  medications.medication = {
                    value: e.target.value,
                    label: e.target.value,
                    pcid: -1,
                    isCustom: true,
                  };

                  self.setState(
                    update(this.state, {
                      medications: {
                        [index]: {
                          $set: medications,
                        },
                      },
                    })
                  );
                }}
              />}
              {this.props.isFrom === "tier1" && self?.state?.medications[index]?.medication?.isCustom === true &&
              <Select
                placeholder={"Medication"}
                value={data.medication}
                defaultValue={data.medication}
                options={self.state.allowedMedicationsOptions}
                onChange={(selectedOption) =>
                  {let medications = self.state.medications[index];
                  medications.medication = selectedOption;

                  self.setState(
                    update(this.state, {
                      medications: {
                        [index]: {
                          $set: medications,
                        },
                      },
                    })
                  );}
                }
              />}
            <span
              className={
                showMobileView ? _style.inputItemLabel : classes.header_attr
              }
              style={showMobileView ? { margin: "0 0 -5px 0" } : null}
            >
              Composition
            </span>
            <input
              className={classes.prescriptionItem}
              placeholder={"Tablets or Syrups"}
              value={data.composition}
              onChange={(e) => {
                let medications = self.state.medications[index];
                medications.composition = e.target.value;
                self.setState(
                  update(this.state, {
                    medications: {
                      [index]: {
                        $set: medications,
                      },
                    },
                  })
                );
              }}
            />
            <span
              className={
                showMobileView ? _style.inputItemLabel : classes.header_attr
              }
              style={showMobileView ? { margin: "0 0 -5px 0" } : null}
            >
              Route
            </span>
            {self?.state?.medications[index]?.route?.isCustom ? (
              <input
                className={classes.prescriptionItem}
                placeholder={"Route"}
                value={data.route?.value === "Others" ? "" : data.route?.value}
                onChange={(e) => {
                  let medications = self.state.medications[index];
                  medications.route = {
                    value: e.target.value,
                    label: e.target.value,
                    isCustom: true,
                  };

                  self.setState(
                    update(this.state, {
                      medications: {
                        [index]: {
                          $set: medications,
                        },
                      },
                    })
                  );
                }}
              />
            ) : (
              <Select
                placeholder={"Route"}
                value={data.route}
                defaultValue={data.route}
                options={self.state.routeOptions[index]}
                disabled={true}
                onChange={(selectedOption) => {
                  let medications = self.state.medications[index];
                  // console.log("render route")
                  medications.route = selectedOption;
                  self.setState(
                    update(this.state, {
                      medications: {
                        [index]: {
                          $set: medications,
                        },
                      },
                    })
                  );
                }}
              />
            )}

            <span
              className={
                showMobileView ? _style.inputItemLabel : classes.header_attr
              }
              style={showMobileView ? { margin: "0 0 -5px 0" } : null}
            >
              Strength
            </span>
            {self?.state?.medications[index]?.dose?.isCustom ? (
              <input
                className={classes.prescriptionItem}
                placeholder={"Strength"}
                value={data.dose?.value === "Others" ? "" : data.dose?.value}
                onChange={(e) => {
                  let medications = self.state.medications[index];
                  medications.dose = {
                    value: e.target.value,
                    label: e.target.value,
                    isCustom: true,
                  };

                  self.setState(
                    update(this.state, {
                      medications: {
                        [index]: {
                          $set: medications,
                        },
                      },
                    })
                  );
                }}
              />
            ) : (
              <Select
                placeholder={"Strength"}
                value={data.dose}
                defaultValue={data.dose}
                options={self.state.doseOptions[index]}
                disabled={true}
                onChange={(selectedOption) => {
                  let medications = self.state.medications[index];
                  // console.log("render strength")
                  medications.dose = selectedOption;
                  self.setState(
                    update(this.state, {
                      medications: {
                        [index]: {
                          $set: medications,
                        },
                      },
                    })
                  );
                }}
              />
            )}
            <span
              className={
                showMobileView ? _style.inputItemLabel : classes.header_attr
              }
              style={showMobileView ? { margin: "0 0 -5px 0" } : null}
            >
              Frequency
            </span>
            {self?.state?.medications[index]?.frequency?.isCustom ? (
              <input
                className={classes.prescriptionItem}
                placeholder={"Frequency"}
                value={
                  data.frequency?.value === "Others"
                    ? ""
                    : data.frequency?.value
                }
                onChange={(e) => {
                  let medications = self.state.medications[index];
                  medications.frequency = {
                    value: e.target.value,
                    label: e.target.value,
                    isCustom: true,
                  };

                  self.setState(
                    update(this.state, {
                      medications: {
                        [index]: {
                          $set: medications,
                        },
                      },
                    })
                  );
                }}
              />
            ) : (
              <Select
                placeholder={"Frequency"}
                value={data.frequency}
                options={optionsFreq}
                onChange={(selectedOption) => {
                  let medications = self.state.medications[index];
                  medications.frequency = selectedOption;

                  self.setState(
                    update(this.state, {
                      medications: {
                        [index]: {
                          $set: medications,
                        },
                      },
                    })
                  );
                }}
              />
            )}
            <span
              className={
                showMobileView ? _style.inputItemLabel : classes.header_attr
              }
              style={showMobileView ? { margin: "0 0 -5px 0" } : null}
            >
              Duration
            </span>
              <input
                  className={classes.prescriptionItem}
                  placeholder={"Duration"}
                  value={data.duration}
                  onChange={(e) => {
                    let medications = self.state.medications[index];
                    medications.duration = e.target.value;
                    self.setState(
                        update(this.state, {
                          medications: {
                            [index]: {
                              $set: medications,
                            },
                          },
                        })
                    );
                  }}
              />

              <span
                  className={
                    showMobileView ? _style.inputItemLabel : classes.header_attr
                  }
                  style={showMobileView ? { margin: "0 0 -5px 0" } : null}
              >

              Total Quantity
            </span>
            <input
              className={classes.prescriptionItem}
              placeholder={"Total Quantity"}
              value={data.quantity}
              onChange={(e) => {
                let medications = self.state.medications[index];

                medications.quantity = e.target.value;
                self.setState(
                  update(this.state, {
                    medications: {
                      [index]: {
                        $set: medications,
                      },
                    },
                  })
                );
              }}
            />
            <span
              className={
                showMobileView ? _style.inputItemLabel : classes.header_attr
              }
              style={showMobileView ? { margin: "0 0 -5px 0" } : null}
            >
              Refills
            </span>
            <div className={classes.refillcounter}>
              <button
                className={classes.refillButton}
                onClick={() => this.decrementRefillValue(index)}
              >
                -
              </button>
              <span className={classes.refillItemText}>{data.refills = data.refills||0}</span>
              <button
                className={classes.refillButton}
                onClick={() => this.incrementRefillValue(index)}
              >
                +
              </button>
            </div>

            <span
              className={
                showMobileView ? _style.inputItemLabel : classes.header_attr
              }
              style={showMobileView ? { margin: "0 0 -5px 0" } : null}
            >
              Instructions
            </span>
            <TextareaAutosize
              className={classes.SOAP_textarea}
              placeholder="Take with food. If you experience bleeding, stop immediately and visit your nearest ER"
              onChange={(e) => {
                let medications = self.state.medications[index];
                medications.instructions = e.target.value;
                self.setState(
                  update(this.state, {
                    medications: {
                      [index]: {
                        $set: medications,
                      },
                    },
                  })
                );
              }}
              value={data.instructions}
            />

            <div
              className={classes.delete_btn}
              onClick={() => {
                let conversationID =
                this.props.selectedConversation || this.props.conversationId;
                let clinicID = this.props.clinicId;
                if (this.state.medications.length > 0) {
                  let medications = this.state.medications;
                  // console.log("deletemedication button this.state.medication before pressing", this.state.medications)
                  medications.splice(index, 1);
                  this.setState({ medications: medications });
                  // console.log("deletemedication button this.state.medication after pressing", this.state.medications)
                  this.deleteMedication();
                  // database.ref(`/openClinicalConversations/${clinicID}/${conversationID}/prescriptions/${this.state.medications[index].key}`).set(null)
                } else {
                  let medications = [
                    {
                      medication: "",
                      route: "",
                      dose: "",
                      quantity: "",
                      repeats: "",
                      lu_codes: "",
                      instructions: "",
                      duration: "",
                      frequency: "",
                    },
                  ];
                  this.setState({ medications: medications });
                  // console.log("deletemedication button this.state.medication before pressing no medication", this.state.medications)
                }
              }}
            >
              Delete Medication
            </div>
          </div>
        </div>
      );
    });
  }

  render() {
    // console.log("render this.props", this.props)
    // console.log("render this.state", this.state)
    let showPrescription =
    Boolean(this.state.show_prescriptions) || isMobileView();
    let showMobileView = isMobileView();
    let self = this;
    let med = this.props.user_medications[this.props.selectedConversation || this.props.conversationId]?.data;
    // console.log("render show prescription med", med)
    let medicationObject = this.props.user_medications && this.props.user_medications[this.props.selectedConversation || this.props.conversationId]
    let check = medicationObject?.isChanged;
    return (
      <div>
        {
          <div className={classes.generalInformation}>
            <Fragment>
              {!showMobileView &&
                (this.props.selectedConversation != null ||
                  this.props.conversationId !== null) && (
                  <div
                    className={classes.openchatlist}
                    onClick={() => this.showPrescriptions()}
                  >
                    <div className={classes.openchatlistSection}>
                      <div
                        className={
                          this.state.show_prescriptions === true
                            ? classes.subListTitleIcon
                            : classes.subListTitleIconRotate
                        }
                      >
                        <svg
                          fill="#884a9d"
                          width="12px"
                          height="8px"
                          viewBox="0 0 12 8"
                        >
                          <path
                            fill="inherit"
                            fillRule="evenodd"
                            d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"
                          ></path>
                        </svg>
                      </div>
                      <div className={classes.subListTitle}>
                        Write Prescription
                      </div>
                    </div>
                  </div>
                )}
              {showPrescription && (
                <div className={classes.expandable_section}>
                  {
                    <div className={classes.general_info_section}>
                      <span
                        className={
                          showMobileView
                            ? _style.inputItemLabel
                            : classes.header_attr
                        }
                        style={showMobileView ? { margin: "0 0 -5px 0" } : null}
                      >
                        Patient Name
                      </span>
                      <input
                        className={classes.prescriptionItem}
                        value={this.state.patient_name}
                        placeholder="Name"
                        onChange={(e) => {
                          this.setState({
                            patient_name: e.target.value,
                          });
                        }}
                      />
                      <span
                        className={
                          showMobileView
                            ? _style.inputItemLabel
                            : classes.header_attr
                        }
                        style={showMobileView ? { margin: "0 0 -5px 0" } : null}
                      >
                        Age
                      </span>
                      <input
                        className={classes.prescriptionItem}
                        value={this.state.patient_DOB}
                        placeholder="Age"
                        onChange={(e) => {
                          self.setState({ patient_DOB: e.target.value, });
                        }}
                      />
                      <span
                        className={
                          showMobileView
                            ? _style.inputItemLabel
                            : classes.header_attr
                        }
                        style={showMobileView ? { margin: "0 0 -5px 0" } : null}
                      >
                        Gender
                      </span>
                      <input
                        className={classes.prescriptionItem}
                        value={this.state.patient_gender}
                        placeholder="Male/Female"
                        onChange={(e) => {
                          self.setState({ patient_gender: e.target.value, });
                        }}
                      />
                      <span
                        className={
                          showMobileView
                            ? _style.inputItemLabel
                            : classes.header_attr
                        }
                        style={showMobileView ? { margin: "0 0 -5px 0" } : null}
                      >
                        Patient Summary
                      </span>
                      <TextareaAutosize
                        className={classes.SOAP_textarea}
                        placeholder="Write a brief summary about the patient and the medical issue for the prescription approval doctor."
                        onChange={(e) => {
                          self.setState({ patient_summary: e.target.value, });
                        }}
                        value={this.state.patient_summary}
                      />
                      <span
                        className={
                          showMobileView
                            ? _style.inputItemLabel
                            : classes.header_attr
                        }
                        style={showMobileView ? { margin: "0 0 -5px 0" } : null}
                      >
                        Current Medication
                      </span>
                      <TextareaAutosize
                        className={classes.SOAP_textarea}
                        placeholder="Is the patient taking any medications currently? Please write the name and dosage..."
                        onChange={(e) => {
                          self.setState({
                            patient_current_medications: e.target.value,
                          });
                        }}
                        value={this.state.patient_current_medications}
                      />
                      <span
                        className={
                          showMobileView
                            ? _style.inputItemLabel
                            : classes.header_attr
                        }
                        style={showMobileView ? { margin: "0 0 -5px 0" } : null}
                      >
                        Known Medical Conditions and Allergies
                      </span>
                      <TextareaAutosize
                        className={classes.SOAP_textarea}
                        placeholder="High Blood Pressure, Diabetes"
                        onChange={(e) => {
                          self.setState({
                            patient_medical_conditions: e.target.value,
                          });
                        }}
                        value={this.state.patient_medical_conditions}
                      />
                      {this.renderMedications(showMobileView)}
                      {Boolean(this.state.error) && (
                        <div
                          style={{
                            width: "200px",
                            color: "red",
                            textAlign: "center",
                            alignSelf: "center",
                          }}
                        >
                          {this.state.error}
                        </div>
                      )}
                      <div
                        className={classes.transparent_btn}
                        onClick={() => {
                          let medications = [...this.state.medications];
                          medications.push({
                            medication: "",
                            route: "",
                            dose: "",
                            quantity: "",
                            repeats: "",
                            lu_codes: "",
                            instructions: "",
                            duration: "",
                            frequency: "",
                            composition: "",
                          });
                          this.setState({ medications: medications });
                        }}
                      >
                        {this.state.medications.length > 0
                          ? "Add Another Medication"
                          : "Add Medication"}
                      </div>
                      {med?.length > 0 && check === false && this.state.medications.length > 0 &&
                      (
                        <div
                          className={classes.transparent_btn}
                          onClick={() => {
                            const modalTitle = "Prescription";
                            console.log("this.modalWindow ", this.modalWindow);
                            if (!!this.modalWindow) {
                              this.modalWindow.close();
                            }
                            this.modalWindow = window.open(
                              "/clinicalreport",
                              modalTitle
                            );
                            this.modalWindow.addEventListener(
                              "load",
                              () => {
                                this.modalWindow.document.title = modalTitle;
                                this.renderPrescription();
                              },
                              false
                            );
                          }}
                        >
                          {"View Prescription"}
                        </div>
                      )}

                      {check === true && (
                        <div
                          className={classes.add_btn}
                          onClick={() => {
                            this.saveMedication();
                          }}
                        >
                          {"Save Changes"}
                        </div>
                      )}
                      {check === true && (
                        <div
                          className={classes.discard_btn}
                          onClick={() => {
                            this.discardChanges();
                          }}
                        >
                          {"Discard Changes"}
                        </div>
                      )}
                      {showMobileView && (
                        <div
                          className={classes.transparent_btn}
                          style={
                            showMobileView ? { marginBottom: "100px" } : null
                          }
                          onClick={() => this.props.goBack()}
                        >
                          Cancel
                        </div>
                      )}
                    </div>
                  }
                </div>
              )}
            </Fragment>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = ({ conversationReducer }) => {
  const { user_medications, prescription_data, update_med_time } =
    conversationReducer;

  return {
    user_medications,
    prescription_data,
    update_med_time,
  };
};

export default connect(mapStateToProps, {
  updateInsuranceCard,
  fetchChatPrescriptions,
  discardChanges,
  updateConversationMedication,
  dispatchPrescriptionIschanged,
  dispatchPrescriptionIssaved,
  sendMessage,
  updateConversationPrescriptionData,
})(PrescriptionForm);
