
import classes from './clinicdoctor.module.css';
import DoctorsList from './List';
import {MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';

import _ from 'lodash';

const Header = (props) => {
    return (
        <tr>
            <th>
                <span className={classes.table_heading}>Status</span>
            </th>
            <th>
                <span className={classes.table_heading}>Name</span>
            </th>
            <th className={classes.table_column}>
                <span className={classes.table_heading}>Availbility</span>
            </th>
            <th className={classes.table_column}>
                <span className={classes.table_heading}>Free Chat</span>
            </th>
            <th className={classes.table_column}>
                <span className={classes.table_heading}>Paid Chat</span>
            </th>
        </tr>
    )
}

const Body = (props) => {
    // eslint-disable-next-line array-callback-return
    const agents = props.agentsData.length > 0 ? props.agentsData.map(agent => {
        if (agent.email && agent.online) {
            return <DoctorsList
                key={agent.uid}
                doctorID={agent.uid}
                doctorName={agent.doctorName}
                email={agent.email}
                role={agent.role}
                freeChatLimit={agent.freeChatLimit}
                onlineFreeChats={agent.onlineFreeChats}
                onlinePaidChats={agent.onlinePaidChats}
                paidChatLimit={agent.paidChatLimit}
                accepting_chat={agent.accepting_chat}
                agent={agent}
                image={agent.profileImageURL}
                onlineStatus={agent.online}
                isNoDoctor={false}
            />
        }
    }) : null;
    const filtered_agents = _.compact(agents);
    return (

        <div className={classes.table_content}>
            <MDBTable>
                <MDBTableHead>
                    <Header/>
                </MDBTableHead>
                <MDBTableBody>
                    {filtered_agents}
                    {(filtered_agents === null || filtered_agents.length < 1) &&
                    <DoctorsList isNoDoctor={true}/>
                    }
                </MDBTableBody>
            </MDBTable>
        </div>
    )
}

export default Body;
