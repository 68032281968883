import  {Component, Fragment} from "react";
import classes from "./Archives.module.css";
import moment from "moment";

const capitalize = s => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const LeftMessage = props => {
    var name = props.patientName !== undefined ? capitalize(props.patientName) : "Patient";
    return (
        <div className={classes.msg_body}>
            <div className={classes.msg_name}>
                <div className={classes.msg_name_text}>{name}</div>
            </div>
            <div className={classes.msg_left_body}>
                <div className={classes.msg_left_img}>{name.charAt(0)}</div>
                <div className={classes.msg_text_body}>
                    <div className={classes.msg_text_container}>
                        <div className={classes.msg_bubble_view}>
                            <div className={classes.msg_bubble_container}>
                                <div>
                                    <div className={classes.msg_bubble_content}>
                                        <div className={classes.msg_bubble_content1}>
                                            <div className={classes.msg_bubble_content2}>
                                                <div className={classes.msg_bubble_content_row}>
                                                    <div className={classes.msg_bubble_content_view}>
                                                        <div className={classes.msg_bubble_container_content}>
                                                            <div>{props.content}</div>
                                                            <div className={classes.message_time_left}>
                                <span className={classes.timestamp_span_left}>
                                  {moment(props.timestamp).format("YYYY-MM-DDThh:mm:ssZ")}
                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const RightMessage = props => {
    return (
        <div className={classes.msg_body}>
            <div className={classes.msg_right_name}>
                <div className={classes.msg_name_text}>{props.doctorName}</div>
            </div>
            <div className={classes.msg_right_body}>
                {props.doctorImg !== undefined && (
                    <img className={classes.msg_right_img} alt="" src={props.doctorImg}/>
                )}
                {props.doctorImg === undefined && (
                    <div className={classes.msg_right_img}>{props.doctorName.charAt(0)}</div>
                )}
                <div className={classes.msg_right_container}>
                    <div className={classes.msg_right_content}>
                        <div className={classes.msg_right_content1}>
                            <div className={classes.msg_right_content2}>
                                <div>
                                    <div className={classes.msg_right_bubble_view}>
                                        <div className={classes.msg_right_bubble_container}>
                                            <div className={classes.msg_right_bubble_container_content}>
                                                <div className={classes.msg_bubble_content_row}>
                                                    <div className={classes.msg_bubble_right}>
                                                        <div className={classes.msg_bubble_container_content}>
                                                            <div>{props.content}</div>
                                                            <div className={classes.message_time_right}>
                                <span className={classes.timestamp_span_right}>
                                  {moment(props.timestamp).format("YYYY-MM-DDThh:mm:ssZ")}
                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const TagList = props => {
    return (
        <span>
      <span className={classes.tag_name}>{props.name}</span>
    </span>
    );
};

export default class ArchiveBody extends Component {
    render() {
        const messageList = this.props.archives_messages.map(message => {
            if (message.receiveMessage.direction === "left") {
                return (
                    <LeftMessage
                        key={message.receiveMessage.uid}
                        timestamp={message.receiveMessage.timestamp}
                        content={message.receiveMessage.content}
                        patientName={this.props.patientName}
                    />
                );
            } else {
                return (
                    <RightMessage
                        key={message.receiveMessage.uid}
                        timestamp={message.receiveMessage.timestamp}
                        content={message.receiveMessage.content}
                        doctorName={this.props.doctorName}
                        doctorImg={this.props.doctorImg}
                    />
                );
            }
        });

        const taglist = this.props.tags.map(tag => {
            return <TagList key={tag.id} name={tag.name}/>;
        });
        var inputDate = new Date(
            Boolean(this.props.archive_timestamp) ? this.props.archive_timestamp : null
        );

        var todaysDate = new Date();
        var date = "";
        if (inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
            date = "Today";
        } else {
            date = inputDate.toDateString();
        }
        var doctor_name =
            this.props.headerText.doctor !== undefined ? ` and ${this.props.headerText.doctor}` : "";
        return (
            <div className={classes.main_body}>
                <div className={classes.block}>
                    <div className={classes.main_block}>
                        <div className={classes.header}>
                            <div className={classes.body_header}>
                                <Fragment>
                                    <div className={classes.msg_header_default}>
                    <span className={classes.header_text_top}>
                      <strong>{this.props.headerText.name}</strong>
                        {doctor_name}
                    </span>
                                    </div>
                                </Fragment>
                            </div>
                        </div>
                        <div className={classes.main_body_view}>
                            <div className={classes.main_body_container}>
                                {!this.props.load_messages && (
                                    <Fragment>
                                        <div className={classes.main_body_content}>
                                            {Boolean(this.props.archive_timestamp) && (
                                                <div className={classes.main_body_content_header}>
                                                    <div className={classes.header_line}></div>
                                                    <div className={classes.header_text}>{date}</div>
                                                    <div className={classes.header_line}></div>
                                                </div>
                                            )}
                                            {messageList}
                                        </div>
                                        <div className={classes.tag_list_view}>
                                            <div className={classes.tag_view}>{taglist}</div>
                                        </div>
                                    </Fragment>
                                )}
                                {this.props.load_messages && (
                                    <div className={classes.no_loading}>
                                        <div className={classes.no_loading_view}>
                                            <div className={classes.loading_loader}/>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
