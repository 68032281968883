import { ENVIRONMENT } from './environment';

let ngrokUrl;
if (ENVIRONMENT === 'development') {
    // export const ngrokUrl = 'https://a9565b14.ngrok.io';
    ngrokUrl = 'https://reports.staging.yourdoctors.online';
}else{
    ngrokUrl = 'https://reports.yourdoctors.online';
}
export default ngrokUrl;
