import {
    FETCH_ALL_ARCHIVES_STATUS,
    FETCH_ARCHIVE_MESSAGES_SUCCESS,
    FETCH_ARCHIVES_LOADING,
    FETCH_ARCHIVES_STATUS,
    FETCH_ARCHIVES_STATUS_FAILED,
    FETCHING_ARCHIVE_MESSAGES,
    RESET_ARCHIVE_CHAT,
    SAVE_CLINICAL_OUT_PATIENT_FORM_UPDATED,
    SAVE_OUT_PATIENT_FORM_UPDATED,
    SEARCH_ARCHIVES,
} from "../constants";

const INITIAL_STATE = {
    loading: false,
    archives: [],
    load_messages: false,
    archives_messages: [],
    archive_doctorName: "",
    archive_doctorImg: null,
    archive_patientEmail: "",
    archive_userID: null,
    archive_patientName: "Patient",
    archive_patientCity: "",
    archive_patientCountry: "",
    archive_platform: "",
    archive_complete_address: "",
    archive_patientDOB: "",
    archive_patientGender: "",
    archive_patientSymptoms: "",
    archive_patientMedications: "",
    archive_commentAdded: "",
    archive_opinionDescriptionValue: "",
    archive_diagnosisValue: "",
    archive_findings: "",
    archive_assessment: "",
    archive_plan: "",
    archive_icd: "",
    archive_billingCode: "",
    archive_billingUnit: 0,
    archive_patientChatCount: "",
    archive_patientBuildNumber: "",
    archive_conversationID: null,
    allArchives: [],
    archive_timestamp: null,
    lastTimeStamp: new Date().getTime(),
    time: new Date().getTime(),
    previous_length: 0,
    isSearch: false,
    isLast: false,
    archive_patientFollowUpData: [],
    archive_patientMedicationData: [],
    averageSleep: "",
    currentSleep: "",
    heartRate: "",
    heightCm: "",
    heightFeet: "",
    stepCount: "",
    runningWalking: "",
    weightKg: "",
    weightLbs: "",
    periodStartDate: "",
    flowType: "",
    restingHeartRate: "",
    archive_issueResolved: "",
    archive_prescriptions: "",
    archive_requisitions: "",
    archive_special_referals: "",
    archive_video_consult: "",
    archive_followUpDays: "",
    selectedArchiveObj: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_ARCHIVES_LOADING:
            return {...state, loading: true, archives: []};
        case FETCH_ARCHIVES_STATUS:
            return {
                ...state,
                loading: false,
                archives: action.payload.sorted_archives,
                lastTimeStamp: action.payload.time,
                previous_length: action.payload.previous_length,
                isSearch: false,
                isLast: action.payload.isLast,
            };
        case FETCH_ARCHIVES_STATUS_FAILED:
            return {
                ...state,
                loading: false,
                archives: [],
                lastTimeStamp: new Date().getTime(),
                previous_length: 0,
                isSearch: false,
                isLast: false,
            };
        case FETCH_ALL_ARCHIVES_STATUS:
            return {...state, loading: false};
        case FETCHING_ARCHIVE_MESSAGES:
            return {...state, load_messages: true, archives_messages: []};
        case RESET_ARCHIVE_CHAT:
            return {
                ...state,
                load_messages: false,
                archive_userID: "",
                archives_messages: [],
                archive_doctorName: "",
                archive_doctorImg: "",
                archive_patientEmail: "",
                archive_patientName: "",
                archive_patientCity: "",
                archive_patientCountry: "",
                archive_complete_address: "",
                archive_phone_number: "",
                archive_platform: "",
                archive_timestamp: "",
                archive_patientDOB: "",
                archive_patientGender: "",
                archive_patientMedications: "",
                archive_commentAdded: "",
                archive_patientMedicationData: "",
                archive_patientFollowUpData: [],
                archive_patientgmtOffset: "",
                archive_patientBuildNumber: "",
                archive_patientChatCount: "",
                archive_conversationID: "",
                archive_healthInsuranceCardBack: "",
                archive_healthInsuranceCardFront: "",
                archive_healthInsuranceCardNumber: "",
                averageSleep: "",
                currentSleep: "",
                heartRate: "",
                heightCm: "",
                heightFeet: "",
                stepCount: "",
                runningWalking: "",
                weightKg: "",
                weightLbs: "",
                periodStartDate: "",
                flowType: "",
                restingHeartRate: "",
                archive_patientSymptoms: "",
                archive_diagnosisValue: "",
                archive_opinionDescriptionValue: "",
                archive_findings: "",
                archive_assessment: "",
                archive_plan: "",
                archive_icd: "",
                archive_billingCode: "",
                archive_billingUnit: "",
                archive_issueResolved: "",
                archive_prescriptions: "",
                archive_requisitions: "",
                archive_special_referals: "",
                archive_video_consult: "",
                archive_followUpDays: "",
                selectedArchiveObj: "",
            };
        case SEARCH_ARCHIVES:
            return {...state, loading: false, archives: action.payload, isSearch: true};
        case SAVE_OUT_PATIENT_FORM_UPDATED:
            let archives = state.archives
            let index = archives.findIndex(archive => archive.conversationID ===  action.payload.conversationID)
            if(index !== -1){
                archives[index].symptoms = action.payload.symptoms;
                archives[index].findings = action.payload.findings;
                archives[index].assessment = action.payload.assessment;
                archives[index].plan = action.payload.plan;
                archives[index].issueResolved = action.payload.issueResolved;
            }
            return {
                ...state,
                archive_patientSymptoms:
                action.payload.symptoms || null,
                archive_issueResolved: action.payload.issueResolved || null,
                archive_findings: action.payload.findings || null,
                archive_assessment: action.payload.assessment || null,
                archive_plan: action.payload.plan || null,
            };
        case SAVE_CLINICAL_OUT_PATIENT_FORM_UPDATED:
            return {
                ...state,
                archive_patientSymptoms:
                action.payload.symptoms || null,
                archive_findings: action.payload.findings !== undefined ? action.payload.findings : null,
                archive_assessment:
                    action.payload.assessment !== undefined ? action.payload.assessment : null,
                archive_plan: action.payload.plan !== undefined ? action.payload.plan : null,
                archive_icd: action.payload.icd !== undefined ? action.payload.icd : null,
                archive_billingCode:
                    action.payload.billing_code !== undefined ? action.payload.billing_code : null,
                archive_billingUnit:
                    action.payload.billing_unit !== undefined ? action.payload.billing_unit : null,
                archive_prescriptions:
                    action.payload.prescriptions !== undefined ? action.payload.prescriptions : null,
                archive_requisitions:
                    action.payload.requisitions !== undefined ? action.payload.requisitions : null,
                archive_special_referals:
                    action.payload.special_referals !== undefined ? action.payload.special_referals : null,
                archive_video_consult:
                    action.payload.video_consult !== undefined ? action.payload.video_consult : null,
                archive_followUpDays:
                    action.payload.followUpDays !== undefined ? action.payload.followUpDays : null,
            };
        case FETCH_ARCHIVE_MESSAGES_SUCCESS:
            return {
                ...state,
                load_messages: false,
                archive_userID: action.payload.patientID,
                archives_messages: action.payload.messages,
                archive_doctorName: action.payload.doctorName,
                archive_doctorImg: action.payload.doctorImage,
                archive_patientEmail: action.payload.patientEmail,
                archive_patientName: action.payload.patientName,
                archive_patientCity: action.payload.city,
                archive_patientCountry: action.payload.country,
                archive_complete_address: action.payload.complete_address,
                archive_phone_number: action.payload.phone_number,
                archive_platform: action.payload.platform,
                archive_timestamp: action.payload.timestamp,
                archive_patientDOB: action.payload.DOB,
                archive_patientGender: action.payload.gender,
                archive_patientMedications: action.payload.currentMedication,
                archive_commentAdded: action.payload.commentAdded || null,
                archive_patientMedicationData: action.payload.medicationData || [],
                archive_patientFollowUpData: action.payload.followUpData  || [],
                archive_patientgmtOffset: action.payload.gmtOffset,
                archive_patientBuildNumber: action.payload.buildNumber,
                archive_patientChatCount: action.payload.convoCount,
                archive_conversationID: action.payload.location,
                archive_healthInsuranceCardBack: action.payload.healthInsuranceCardBack,
                archive_healthInsuranceCardFront: action.payload.healthInsuranceCardFront,
                archive_healthInsuranceCardNumber: action.payload.healthInsuranceCardNumber,
                archive_healthCardNumber: action.payload.healthCardNumber,
                archiveOwnerProfile: action.payload.ownerProfile,
                averageSleep: action.payload.averageSleep,
                currentSleep: action.payload.currentSleep,
                heartRate: action.payload.heartRate,
                heightCm: action.payload.heightCm,
                heightFeet: action.payload.heightFeet,
                stepCount: action.payload.stepCount,
                runningWalking: action.payload.runningWalking,
                weightKg: action.payload.weightKg,
                weightLbs: action.payload.weightLbs,
                periodStartDate: action.payload.periodStartDate,
                flowType: action.payload.flowType,
                restingHeartRate: action.payload.restingHeartRate,

                archive_patientSymptoms:  action.payload.symptoms || null,
                archive_diagnosisValue: action.payload.diagnosisValue || null,
                archive_opinionDescriptionValue: action.payload.opinionDescriptionValue || null,
                archive_findings: action.payload.findings || null,
                archive_assessment:  action.payload.assessment || null,
                archive_plan: action.payload.plan || null,
                archive_icd: action.payload.icd || null,
                archive_billingCode: action.payload.billing_code || null,
                archive_billingUnit: action.payload.billing_unit || null,
                archive_issueResolved: action.payload.issueResolved || null,
                archive_prescriptions: action.payload.prescriptions || null,
                archive_requisitions: action.payload.requisitions || null,
                archive_special_referals: action.payload.special_referals || null,
                archive_video_consult: action.payload.video_consult || null,
                archive_followUpDays: action.payload.followUpDays || null,
                selectedArchiveObj: action.payload.selectedArchiveObj,
            };
        default:
            return state;
    }
};
