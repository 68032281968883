import  {Component, createRef} from 'react';
import {connect} from 'react-redux';
import {fetchTicketData, newMessage, saveNewMessage} from '../../../actions/TicketAction';

import classes from '../TicketsBuilder.module.css';

const Header = (props) => {
    return (
        <div className={classes.add_content_header}>
            <div className={classes.absolute_back_button} onClick={() => props.backClick()}>
                <a href>
                    <div className={classes.back_button}>
                        <svg width="16" height="16" viewBox="0 0 16 16">
                            <g fill="none" fillRule="evenodd">
                                <path d="M-4-4h24v24H-4z"></path>
                                <path fill="#4384F5" fillRule="nonzero"
                                      d="M16 7H3.83l5.59-5.59L8 0 0 8l8 8 1.41-1.41L3.83 9H16z"></path>
                            </g>
                        </svg>
                    </div>
                    <span>Back</span>
                </a>
            </div>
            <div className={classes.absolute_header}>{props.subject}</div>
        </div>
    )
}

const MessageList = (props) => {
    if (props.direction === 'left') {
        return (
            <div className={classes.update_message_body_left}>
                <span>{props.msg}</span>
            </div>
        )
    }
    return (
        <div className={classes.update_message_body_right}>
            <span>{props.msg}</span>
        </div>
    )
}

class TicketDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newfileName: '',
            newfile: '',
            show: false,
            status: ''
        }
        this.fileUpload = createRef();
    }

    componentDidMount() {
        this.props.fetchTicketData(this.props.history.location.state.ticketID);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.state.status === '') {
            this.setState({status: newProps.updateTicketStatus});
        }
    }

    backClick = () => {
        this.props.history.goBack();
    }

    onNewMessage = (event) => {
        this.props.newMessage(event.target.value);
    }

    removeNewFile = () => {
        this.setState({newfileName: '', newfile: ''});
    }

    onAttachmentClick = (e) => {
        //console.log("e: ", e.current.click());
    }

    _handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            // var type = file.type.split('/');
            this.setState({newfileName: file.name, newfile: file});

        }
        reader.readAsDataURL(file);
    }

    onSendPress = (event) => {
        event.preventDefault();
        this.props.saveNewMessage(this.state.newfile, this.state.status, this.props.history.location.state.ticketID, this.props,);
    }

    showStatusList = () => {
        this.setState({show: true});
    }

    onItemSelect = (status) => {
        this.setState({show: false, status: status});
    }

    render() {
        const messages = this.props.updateTicketMessage.map(message => {
            return <MessageList key={message.uid} file={message.fileUrl} msg={message.message}
                                timestamp={message.timestamp} direction={message.direction}/>
        });
        return (
            <div className={classes.main}>
                <div className={classes.add_content}>
                    <div className={classes.content_block}>
                        <div className={classes.update_content_body}>
                            <Header subject={this.props.history.location.state.subject}
                                    backClick={() => this.backClick()}/>
                            <div className={classes.add_body}>
                                <div className={classes.main_update_body}>
                                    <div className={classes.update_body}>
                                        <div className={classes.update_inner_body}>
                                            <div className={classes.update_absolute_body}>
                                                <div className={classes.update_body_header}>
                                                    <div
                                                        className={classes.update_name_header}>{this.props.ticketData.requester_name}</div>
                                                    <div
                                                        className={classes.update_date_header}>{this.props.ticketData.timestamp}</div>
                                                </div>
                                                {messages}
                                            </div>
                                            <div className={classes.update_bottom_body}>
                                                <div className={classes.update_bottom_line}>
                                                    <div className={classes.bottom_assigned}>
                                                        {this.props.ticketData.requester_name} assigned ticket
                                                        to <strong> {this.props.ticketData.assignee_Name}</strong>
                                                    </div>
                                                    <div className={classes.bottom_status}>Sat, 25 May 2019, 10:53 pm
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.props.updateTicketStatus &&
                                <div className={classes.main_sendbox_body}>
                                    <div className={classes.box_main_view}>
                                        <div className={classes.lc_msg_row_field}>
                                            {this.state.newfile !== '' &&
                                            <div className={classes.file_text}>
                                                <span className={classes.file_text_span}>{this.state.newfileName}</span>
                                                <span className={classes.remove_span}
                                                      onClick={() => this.removeNewFile()}>remove</span>
                                            </div>
                                            }
                                            <div className={classes.lc_msg_field_input}>
                                                    <textarea
                                                        placeholder="Type a message"
                                                        required
                                                        value={this.props.updateMessage}
                                                        onChange={(text) => this.onNewMessage(text)}
                                                    >
                                                    </textarea>
                                            </div>
                                            <div className={classes.lc_msg_field_tag}>
                                                <div className={classes.lc_msg_field_tag_view}>
                                                    <div className={classes.canned_responses}>
                                                        <div className={classes.canned_responses_icon}>
                                                            <svg fill="#424d57" width="16px" height="16px"
                                                                 viewBox="0 0 13 14">
                                                                <g fill="inherit">
                                                                    <path
                                                                        d="M11.4800171,9.8 L1.68001709,9.8 C1.26001709,9.8 0.98001709,9.52 0.98001709,9.1 C0.98001709,8.68 1.26001709,8.4 1.68001709,8.4 L11.4800171,8.4 C11.9000171,8.4 12.1800171,8.68 12.1800171,9.1 C12.1800171,9.52 11.9000171,9.8 11.4800171,9.8 Z"></path>
                                                                    <path
                                                                        d="M12.4599915,5.88001709 L2.65999146,5.88001709 C2.23999146,5.88001709 1.95999146,5.60001709 1.95999146,5.18001709 C1.95999146,4.76001709 2.23999146,4.48001709 2.65999146,4.48001709 L12.4599915,4.48001709 C12.8799915,4.48001709 13.1599915,4.76001709 13.1599915,5.18001709 C13.1599915,5.60001709 12.8799915,5.88001709 12.4599915,5.88001709 Z"></path>
                                                                    <path
                                                                        d="M14.7,7.7 L3.5,7.7 C3.08,7.7 2.8,7.42 2.8,7 C2.8,6.58 3.08,6.3 3.5,6.3 L14.7,6.3 C15.12,6.3 15.4,6.58 15.4,7 C15.4,7.42 15.12,7.7 14.7,7.7 Z"
                                                                        transform="translate(9.100000, 7.000000) rotate(99.000000) translate(-9.100000, -7.000000)"></path>
                                                                    <path
                                                                        d="M10.5,7.7 L-0.7,7.7 C-1.12,7.7 -1.4,7.42 -1.4,7 C-1.4,6.58 -1.12,6.3 -0.7,6.3 L10.5,6.3 C10.92,6.3 11.2,6.58 11.2,7 C11.2,7.42 10.92,7.7 10.5,7.7 Z"
                                                                        transform="translate(4.900000, 7.000000) rotate(99.000000) translate(-4.900000, -7.000000)"></path>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className={classes.canned_responses}
                                                         onClick={() => this.onAttachmentClick(this.fileUpload)}>
                                                        <input
                                                            type="file"
                                                            ref={this.fileUpload}
                                                            onChange={(e) => this._handleImageChange(e)}
                                                        />
                                                        <div className={classes.canned_responses_icon}>
                                                            <svg fill="#424d57" width="18px" height="18px"
                                                                 viewBox="0 0 8 16">
                                                                <g>
                                                                    <path fill="inherit"
                                                                          d="M7 4v7.667a2.666 2.666 0 1 1-5.333 0V3.333a1.667 1.667 0 0 1 3.333 0v7c0 .367-.3.667-.667.667a.669.669 0 0 1-.666-.667V4h-1v6.333a1.667 1.667 0 0 0 3.333 0v-7a2.666 2.666 0 1 0-5.333 0v8.334a3.665 3.665 0 0 0 3.666 3.666A3.665 3.665 0 0 0 8 11.667V4H7z"></path>
                                                                    <path fill="#ffffff" fillOpacity="0"
                                                                          d="M-4 0h16v16H-4z"></path>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={classes.lc_msg_field_button_view}>
                                                    <div className={classes.update_status_view}>
                                                        <span>Ticket status</span>
                                                        <button type="button"
                                                                disable={(this.props.updateTicketStatus).toString()}
                                                                onClick={() => this.showStatusList()}>{this.state.status ? 'Open' : 'Solved'}
                                                            <div className={classes.list_view}>
                                                                <svg width="12px" height="12px" viewBox="0 0 8 13">
                                                                    <g fill="inherit" fillRule="evenodd">
                                                                        <path fillRule="nonzero"
                                                                              d="M.59 11.34l4.58-4.59L.59 2.16 2 .75l6 6-6 6z"></path>
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </button>
                                                        <div tabIndex="0" className={classes.status_list}
                                                             data-placement="top-start"
                                                             style={{opacity: this.state.show ? '1' : '0'}}>
                                                            <ul className={classes.status_list_ul} tabIndex="0">
                                                                <li onClick={() => this.onItemSelect(true)}>
                                                                    <div className={classes.status_list_li}>
                                                                        <div
                                                                            className={classes.status_list_li_content}>Open
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li onClick={() => this.onItemSelect(false)}>
                                                                    <div className={classes.status_list_li}>
                                                                        <div
                                                                            className={classes.status_list_li_content}>Solved
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <button type="button"
                                                            disable={this.props.history.location.state.owner}
                                                            onClick={(event) => this.onSendPress(event)}>Send
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ticketsReducer}) => {
    const {ticketData, updateTicketMessage, updateTicketStatus, updateMessage} = ticketsReducer;
    return {ticketData, updateTicketMessage, updateTicketStatus, updateMessage};
}

export default connect(mapStateToProps, {fetchTicketData, newMessage, saveNewMessage})(TicketDetail);
