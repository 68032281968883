import  {Component} from 'react';
import {Document, Font, Image, Page, StyleSheet, Text, View} from '@react-pdf/renderer';
import timesnewroman from '../../../assets/fonts/timesnewroman.ttf';
import timesnewromanbold from '../../../assets/fonts/timesnewromanbold.ttf';
import timesnewromanbolditalic from '../../../assets/fonts/timesnewromanbolditalic.ttf';
import timesnewromanitalic from '../../../assets/fonts/timesnewromanitalic.ttf';
import arial from '../../../assets/fonts/ARIAL.TTF';
import arialbold from '../../../assets/fonts/ARIALBD.TTF';
import arialbolditalic from '../../../assets/fonts/ARIALBI.TTF';
import arialitalic from '../../../assets/fonts/ARIALI.TTF';

var moment = require("moment");
Font.register({
    family: 'serif', fonts: [
        {src: timesnewroman}, // font-style: normal, font-weight: normal
        {src: timesnewromanitalic, fontStyle: 'italic'},
        {src: timesnewromanbold, fontWeight: 700},
        {src: timesnewromanbolditalic, fontStyle: 'italic', fontWeight: 700},
    ]
});

Font.register({
    family: 'arial', fonts: [
        {src: arial}, // font-style: normal, font-weight: normal
        {src: arialitalic, fontStyle: 'italic'},
        {src: arialbold, fontWeight: 700},
        {src: arialbolditalic, fontStyle: 'italic', fontWeight: 700},
    ]
});

class MyDocument extends Component {

    render = () => {
        var patientDOB = this.props.patientDOB?.replace(" ", ",")?.replace(" ", ", ")?.replace(",", " ");
        var current_date = moment(this.props.start_timestamp)?.format('LL');
       // let medicationObject = this.props.user_medications && this.props.user_medications[this.props.selectedConversation || this.props.conversationId]
        let medications = this.props.user_medications
        // console.log("props medications pdf", medications)
        return (
            <Document title="Prescription">
                <Page size={["8.5in", "11in"]} style={styles.page} wrap>
                    <Text style={styles.prescription_header}>YDO Prescription ID: {this.props.prescriptionID}</Text>
                    <View style={styles.pdf_header}>
                        <Text>{this.props.clinicName}</Text>
                        <Text>{this.props.clinicAddress},</Text>
                        <Text>{this.props.clinicCity}, {this.props.clinicCountry}, {this.props.clinicPostalCode}</Text>
                        <Text>{"Ph: " + this.props.clinicPhoneNumber}</Text>
                        <Text>{"Fax: " + this.props.clinicFaxNumber}</Text>
                    </View>

                    <View style={styles.patient_info_section}>

                        <View style={styles.patient_info_row}>
                            <Text style={styles.labelStyle}>Patient's Name: </Text>
                            <Text>{this.props.patientName}</Text>
                        </View>
                        <View style={styles.patient_info_row}>
                            <Text style={styles.labelStyle}>Date of Birth: </Text>
                            <Text>{patientDOB}</Text>
                        </View>
                        <View style={styles.patient_info_row}>
                            <Text style={styles.labelStyle}>Gender: </Text>
                            <Text>{this.props.patientGender}</Text>
                        </View>
                        {!!this.props.healthInsuranceCardNumber && <View style={styles.patient_info_row}>
                            <Text style={styles.labelStyle}>Health Card #: </Text>
                            <Text>{this.props.healthInsuranceCardNumber}</Text>
                        </View>}
                        {!!this.props.patient_phone_number && <View style={styles.patient_info_row}>
                            <Text style={styles.labelStyle}>Phone number: </Text>
                            <Text>{this.props.patient_phone_number}</Text>
                        </View>}

                        <View style={styles.patient_info_row}>
                            <Text style={styles.labelStyle}>Date: </Text>
                            <Text>{current_date}</Text>
                        </View>

                        <View style={{paddingVertical:15, fontSize:24, fontWeight:"900"}}>
                            <Text>Rx</Text>
                        </View>

                        {!!medications && medications?.map((val, key) => {
                            return (
                                <View>
                                    <View style={styles.patient_info_row}>
                                        <Text style={styles.addressText}>Medication {key+1}:- </Text>
                                    </View>
                                    <View style={styles.patient_info_row}>
                                        <Text style={styles.labelStyle}>Composition: </Text>
                                        <Text>{val.composition}</Text>
                                    </View>
                                    <View style={styles.patient_info_row}>
                                        <Text style={styles.labelStyle}>Name: </Text>
                                        <Text>{val.medication}</Text>
                                    </View>
                                    <View style={styles.patient_info_row}>
                                        <Text style={styles.labelStyle}>Route: </Text>
                                        <Text>{val.route}</Text>
                                    </View>
                                    <View style={styles.patient_info_row}>
                                        <Text style={styles.labelStyle}>Dosage: </Text>
                                        <Text >{val.dose}</Text>
                                    </View>
                                    <View style={styles.patient_info_row}>
                                        <Text style={styles.labelStyle}>Frequency: </Text>
                                        <Text>{val.frequency}</Text>
                                    </View>
                                    <View style={styles.patient_info_row}>
                                        <Text style={styles.labelStyle}>Duration: </Text>
                                        <Text>{val.duration}</Text>
                                    </View>
                                    <View style={styles.patient_info_row}>
                                        <Text style={styles.labelStyle}>Total Quantity: </Text>
                                        <Text>{val.quantity}</Text>
                                    </View>
                                    <View style={styles.patient_info_row}>
                                        <Text style={styles.labelStyle}>Refills: </Text>
                                        <Text>{val.refills}</Text>
                                    </View>
                                    <View style={styles.patient_info_row}>
                                        <Text style={styles.labelStyle}>Instructions: </Text>
                                        <Text>{val.instructions}</Text>
                                    </View>
                                </View>
                            )

                        })}

                    </View>
                    <View style={[styles.patient_info_section_without_border,{paddingTop:20}]}>

                        <View style={styles.patient_info_row}>
                            <Text style={styles.labelStyle}>Signature: </Text>
                            {this.props.signatureImageURL &&<Image /* src={this.props.signatureImageURL} */
                                source={{
                                    uri: this.props.signatureImageURL,
                                    headers: {Pragma: 'no-cache', 'Cache-Control': 'no-cache'}
                                }}
                                style={{width: 80, height: 50}}
                            />}
                        </View>
                        <View style={styles.patient_info_row}>
                            <Text style={styles.labelStyle}>Doctor Name: </Text>
                            <Text>{this.props.doctorName}</Text>
                        </View>

                        <View style={styles.patient_info_row}>
                            <Text style={styles.labelStyle}>{this.props.license_name? this.props.license_name :"License No"}: </Text>
                            <Text>{this.props.doctorlicense}</Text>
                        </View>
                        {/*<View style={styles.patient_info_detail_doctor_header}>
                            <Text>License #: {this.props.doctorlicense}</Text>
                            <Text>{staffAddress ? staffAddress : this.props.address}</Text>
                            <Text>{staffCity ? staffCity : this.props.city}</Text>
                            <Text>{staffProvince ? staffProvince : this.props.province}</Text>
                            <Text>{staffCountry ? staffCountry : this.props.country}</Text>
                            <Text>Ph: {phoneNumber ? phoneNumber : this.props.clinicPhoneNumber}</Text>
                            <Text>Fax: {staffFaxNo ? staffFaxNo : this.props.clinicFaxNumber}</Text>
                            <Text>Postal code: {this.props.clinicPostalCode}</Text>
                        </View>*/}
                    </View>
                </Page>
            </Document>
        )
    };
}
// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: 'white',
        padding: 20,
        fontFamily: "arial",
        fontSize: "11",
        height: "100%"
    },
    labelStyle:{
        width:"25%",
        paddingBottom:5
    },
    prescription_header: {
        fontSize: "12",
        fontFamily:"arial",
        paddingVertical: 20,
    },
    pageNumber: {
        position: 'absolute',
        bottom: 15,
        left: 0,
        right: 0,
        textAlign: "right",
        marginRight: "20",
        fontSize: "10",
    },

    title_section: {
        margin: 10,
        paddingVertical: 30,
        fontWeight: "ultrabold",
        fontSize: "11",
    },
    tableHeadText: {
        fontWeight: "ultrabold",
        fontSize: "8",
    },
    pdf_header: {
        width: "100%",
        fontSize: "10",
        paddingBottom: 10,
        marginBottom: "20",
        borderBottom: "1px #d9d9d9 solid"
    },

    report_title: {
        textAlign: "center",
        fontSize: "11",
        fontWeight: 900
    },

    patient_info_row_sub_section: {
        flexDirection: 'row',
        paddingRight: "20",
        width: "50%",
        textAlign: "left",
        fontSize: "11",
    },

    patient_info_detail_sub_section: {
        flexDirection: 'row',
        fontSize: "11",
    },

    patient_info_detail_header: {
        flexDirection: 'row',
        paddingBottom: 5,
        fontWeight: 900,
        fontSize: "11",
    },

    patient_info_detail_value: {
        paddingBottom: 10,
        fontSize: "11",
    },
    patient_info_detail_value_last: {
        fontSize: "11"
    },
    patient_info_row: {
        paddingBottom: "5",
        width: "100%",
        flexDirection: 'row',
        fontSize: "11",
    },

    patient_info_section: {
        flexDirection: 'column',
        paddingBottom: "15",
        fontSize: "11",
        borderBottom: "1px #d9d9d9 solid"
    },

    patient_info_section_without_border_final: {
        paddingBottom: "40",
        marginBottom: "20",
        fontSize: "11",
        position: "absolute",
        bottom: 15,
        left: 0,
        right: 0,
        textAlign: "left",
        marginRight: "20",
        paddingHorizontal: 40,
    },

    patient_info_detail_doctor_header: {
        flexDirection: 'column',
        paddingBottom: 5,
        fontSize: "11",
    },

    history_section_row: {
        flexDirection: 'row',
        fontSize: "11",
    },

    history_section_detail_header: {
        flexDirection: 'row',
        fontWeight: 900,
        fontSize: "11",
        width: "140"
    },

    history_section_detail_value: {
        paddingBottom: 5,
        fontSize: "11",
    },

    history_section_header: {
        paddingBottom: 15,
        fontSize: "12",
        fontWeight: 900
    },
    prescriptionTable: {
        display: 'flex',
        flexDirection: 'column',


    },
    table: {
        width: '100%',

        display: 'flex',
        flexDirection: 'column',
        marginVertical: 12
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    addressText: {
        fontWeight: 700,
        paddingVertical: 15
    },
    cell: {

        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'left',
        flexWrap: 'wrap'
    }
});

export default MyDocument;
