
import classes from './staff.module.css';
import Switch from 'react-switch';

const defaultImage = 'https://firebasestorage.googleapis.com/v0/b/yourdoctorsonline-staging.appspot.com/o/DoctorsProfileImage%2Fdownload.png?alt=media&token=cf3b29d0-a67e-430b-b317-c0b7002ce098';

const AgentsDetails = (props) => {
    var chatcount = props.doctorData.conversations !== null && props.doctorData.conversations !== undefined ? Object.keys(props.doctorData.conversations).length : 0;
    if (props.selectedAgent !== null) {
        return (
            <div className={classes.detail}>
                <div className={classes.header}>Details</div>
                <div className={classes.detail_info}>
                    {/*<div className={classes.profile}>
                        <div className={classes.profile_info}>
                            <div className={classes.avatar_container}>
                                <div className={classes.profile_avatar}>
                                    <img
                                        className={classes.profile_avatar_view}
                                        src={
                                            props.doctorData.profileImageURL !== undefined &&
                                            props.doctorData.profileImageURL !== null ?
                                            props.doctorData.profileImageURL : defaultImage
                                        }
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className={classes.profile_schema}>
                                <div className={classes.profile_name}>{
                                        props.doctorData.fullName !== undefined &&
                                        props.doctorData.fullName !== '' ? props.doctorData.fullName : props.doctorData.staffEmail
                                    }
                                </div>
                                <div className={classes.doctor_email}>{props.doctorData.email}
                                    <div className={ props.doctorData.staffProfile === 'admin' ? classes.admin_role_dt : classes.doctor_role_dt }>{props.doctorData.staffProfile}</div>
                                </div>
                                <div className={classes.doctor_category}>{props.doctorData.accountType !== undefined && props.doctorData.accountType !== null ? props.doctorData.accountType : ''}</div>
                            </div>
                        </div>
                    </div>*/}

                    <div className={classes.additional} onClick={() => props.hideInfo()}>
                        <div className={props.showingInfo === true ? classes.chevron : classes.chevron_rotated}>
                            <svg fill="#884a9d" width="12px" height="8px" viewBox="0 0 12 8">
                                <path fill="inherit" fillRule="evenodd"
                                      d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                            </svg>
                        </div>
                        <div style={{width: 20}}></div>
                        Additional Info
                    </div>

                    {props.showingInfo &&
                    <div className={classes.groups_info}>
                        <div className={classes.groups_category}>
                            <div className={classes.groups_member}>
                                <div className={classes.form_item_left}>
                                    <input
                                        type="checkbox"
                                        checked={props.doctorData.addMoreUsers}
                                        onChange={() => {
                                            return false;
                                        }}
                                    />
                                    <span className={classes.additional_1}> Add more users to your staff </span>
                                </div>
                                <div className={classes.form_item_right}>
                                    <input
                                        type="checkbox"
                                        checked={props.doctorData.createPrescription}
                                        onChange={() => {
                                            return false;
                                        }}
                                    />
                                    <span className={classes.additional_1}> Create prescriptions </span>
                                </div>
                                <div className={classes.form_item_left}>
                                    <input
                                        type="checkbox"
                                        checked={props.doctorData.invitePatients}
                                        onChange={() => {
                                            return false;
                                        }}
                                    />
                                    <span className={classes.additional_1}> Invite patients </span>
                                </div>
                                <div className={classes.form_item_right}>
                                    <input
                                        type="checkbox"
                                        checked={props.doctorData.soapNotes}
                                        onChange={() => {
                                            return false;
                                        }}
                                    />
                                    <span className={classes.additional_1}> Create SOAP notes </span>
                                </div>
                                <div className={classes.form_item_left}>
                                    <input
                                        type="checkbox"
                                        checked={props.doctorData.ansPatients}
                                        onChange={() => {
                                            return false;
                                        }}
                                    />
                                    <span className={classes.additional_1}> Answer patients </span>
                                </div>
                                <div className={classes.form_item_right}>
                                    <input
                                        type="checkbox"
                                        checked={props.doctorData.transferChat}
                                        onChange={() => {
                                            return false;
                                        }}
                                    />
                                    <span className={classes.additional_1}> Transfer chat </span>
                                </div>
                                <div className={classes.form_item_left}>
                                    <input
                                        type="checkbox"
                                        checked={props.doctorData.viewPatientsInQueue}
                                        onChange={() => {
                                            return false;
                                        }}
                                    />
                                    <span className={classes.additional_1}> View patients in queue </span>
                                </div>
                                <div className={classes.form_item_right}>
                                    <input
                                        type="checkbox"
                                        checked={props.doctorData.viewArchives}
                                        onChange={() => {
                                            return false;
                                        }}
                                    />
                                    <span className={classes.additional_1}> View archives </span>
                                </div>
                                <div className={classes.form_item_left}>
                                    <input
                                        type="checkbox"
                                        checked={props.doctorData.viewInsuranceInfo}
                                        onChange={() => {
                                            return false;
                                        }}
                                    />
                                    <span className={classes.additional_1}> View patient insurance info </span>
                                </div>
                                <div className={classes.form_item_right}>
                                    <input
                                        type="checkbox"
                                        checked={props.doctorData.getReports}
                                        onChange={() => {
                                            return false;
                                        }}
                                    />
                                    <span className={classes.additional_1}> Get reports </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    {props.doctorData.role === "Doctor" && props.doctorData.profileStatus !== 'deleted' && props.doctorData.online &&
                    <div className={classes.detail_bottom_div}>
                        <label className={classes.label_style}>
                            <span className={classes.span_style}>Accept Patients </span>
                            <span>
                                <Switch
                                    checked={props.acceptStatus}
                                    onChange={() => props.acceptStatusChanged(props.acceptStatus)}
                                    onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={30}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="react-switch"
                                />
                            </span>
                        </label>
                    </div>}
                    {props.doctorData.role === "Doctor" && props.doctorData.profileStatus !== 'deleted' &&
                    <div className={classes.detail_bottom_div}>
                        <div className={classes.del_doctor} onClick={() => props.deleteDoctor(props.doctorData)}>
                            Deactivate Doctor
                        </div>
                    </div>}
                    {props.doctorData.role === "Doctor" && props.doctorData.profileStatus === 'deleted' &&
                    <div className={classes.detail_bottom_div}>
                        <div className={classes.activate_doctor} onClick={() => props.activateDoctor(props.doctorData)}>
                            Activate Doctor
                        </div>
                    </div>}
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default AgentsDetails;
