import  { Component } from 'react';
import { connect } from 'react-redux';
import Aux from '../../../hoc/hocAux';
import classes from './SettingBuilder.module.css';
import SettingList from './SettingList';
import SettingDetail from './SettingDetail';
import { tag_changed, save_tags, fetchTags } from '../../../actions/TagsAction';
import { billing_code_changed, billing_code_desc_changed,
    save_billing_code, fetchBillingCodes, updateBillingCode, deleteBillingCode } from '../../../actions/BillingCodesAction';
import {
    canned_response_description_changed, canned_response_shortcut_changed, canned_response_save,
    fetchCannedResponsesForChats, fetchCannedResponsesForObjectiveFindings,
    fetchCannedResponsesForAssessment, fetchCannedResponsesForPlan,
    updateCannedResponse, deleteCannedResponse
} from '../../../actions/ClinicalCannedResponseAction';
import { childChangedDoctor } from '../../../actions/AgentsAction';
import { checkStatusAccept } from '../../../actions/ConversationAction';

class SettingBuilder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            headerText: '',
            selectedEditTag: null,
            selectedTagDesciption: '',
            selectedTagShortCut: '',
            selectedDeleteTag: null,
            showCannedResponsesItems: false,
        }
    }

    componentDidMount() {
        this.props.fetchTags();
        this.props.fetchCannedResponsesForChats();
        this.props.fetchCannedResponsesForObjectiveFindings();
        this.props.fetchCannedResponsesForAssessment();
        this.props.fetchCannedResponsesForPlan();
        this.props.childChangedDoctor();
        this.props.fetchBillingCodes();
    }

    settingItemsClick = (item) => {
        this.setState({ headerText: item });
    }

    onTagTextChange = (event) => {
        this.props.tag_changed(event.target.value);
    }

    onSaveTag = (event) => {
        event.preventDefault();
        if (this.props.tag_value !== '') {
            this.props.save_tags(this.props.tag_value);
        } else {
            alert("Please check tag input.");
        }
    }

    description = (event) => {
        this.props.canned_response_description_changed(event.target.value);
    }

    shortcut = (event) => {
        this.props.canned_response_shortcut_changed(event.target.value);
    }

    onSaveCannedResponse = (event) => {
        event.preventDefault();
        if (this.props.description !== '' && this.props.short_cut !== '') {
            this.props.canned_response_save(this.props.description, this.props.short_cut, this.state.headerText);
        } else {
            alert("Please check your canned response text and shortcut. We need both");
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.changedDoctorKey === newProps.doctorDetail.uid) {
            this.props.checkStatusAccept();
        }
    }
    onEditClick = (uid, desc, shortcut) => {
        this.setState({ selectedEditTag: uid, selectedTagDesciption: desc, selectedTagShortCut: shortcut });
    }

    onChangeCannedDescription = (event) => {
        this.setState({ selectedTagDesciption: event.target.value });
    }

    onChangeCannedShortCut = (event) => {
        this.setState({ selectedTagShortCut: event.target.value });
    }

    updateCannedResponse = (uid, desc, shortcut) => {
        var self = this;
        if(this.state.headerText === 'Billing Codes'){
            this.props.updateBillingCode(uid, desc, shortcut, this.state.headerText, function (status) {
                self.setState({ selectedEditTag: null, selectedTagDesciption: '', selectedTagShortCut: '' });
            });
        }else{
            this.props.updateCannedResponse(uid, desc, shortcut, this.state.headerText, function (status) {
                self.setState({ selectedEditTag: null, selectedTagDesciption: '', selectedTagShortCut: '' });
            });
        }
    }

    updateCancelClick = () => {
        this.setState({ selectedEditTag: null, selectedTagDesciption: '', selectedTagShortCut: '' });
    }

    onDeleteClick = (uid) => {
        this.setState({ selectedDeleteTag: uid });
    }

    confirmDeleteClick = (uid) => {
        var self = this;
        if(this.state.headerText === 'Billing Codes'){
            this.props.deleteBillingCode(uid, this.state.headerText, function (status) {
                self.setState({ selectedDeleteTag: uid });
            });
        } else {
            this.props.deleteCannedResponse(uid, this.state.headerText, function (status) {
                self.setState({ selectedDeleteTag: uid });
            });
        }
    }

    confirmCancelClick = () => {
        this.setState({ selectedDeleteTag: null });
    }

    onBillingCodeTextChange = (event) => {
        this.props.billing_code_changed(event.target.value);
    }

    onBillingCodeDescriptionChange = (event) => {
        this.props.billing_code_desc_changed(event.target.value);
    }

    onSaveBillingCode = (event) => {
        event.preventDefault();
        if (this.props.billing_code_value !== '' && this.props.billing_code_desc_value !== '') {
            this.props.save_billing_code(this.props.billing_code_value, this.props.billing_code_desc_value);
        } else {
            alert("Please check tag input.");
        }
    }

    showCannedResponsesInfo = () => {
        this.setState((state) => ({ showCannedResponsesItems: !state.showCannedResponsesItems }));
    }

    render() {
        return (
            <Aux>
                <div className={classes.main}>
                    <SettingList
                        showCannedResponsesItems={this.state.showCannedResponsesItems}
                        showCannedResponsesInfo={() => this.showCannedResponsesInfo()}
                        settingItemsClick={(item) => this.settingItemsClick(item)}
                        acceptingChat={this.props.acceptingChat}
                    />
                    <SettingDetail
                        header={this.state.headerText}
                        tag={this.props.tag_value}
                        tagsList={this.props.tags}
                        onTagTextChange={(text) => this.onTagTextChange(text)}
                        onSaveTag={(event) => this.onSaveTag(event)}
                        description={this.props.description}
                        short_cut={this.props.short_cut}
                        selectedEditTag={this.state.selectedEditTag}
                        selectedDeleteTag={this.state.selectedDeleteTag}
                        selectedTagDesciption={this.state.selectedTagDesciption}
                        selectedTagShortCut={this.state.selectedTagShortCut}
                        canned_responsesList={this.props.canned_responses}
                        canned_responsesList_chats={this.props.chats_canned_responses}
                        canned_responsesList_assessment={this.props.canned_responses_assessment}
                        canned_responsesList_plan={this.props.canned_responses_plan}
                        onChangeCannedDescription={(event) => this.onChangeCannedDescription(event)}
                        onChangeCannedShortCut={(event) => this.onChangeCannedShortCut(event)}
                        onCannedResponseTextChange={(text) => this.description(text)}
                        onCannedResponseShortcutChange={(text) => this.shortcut(text)}
                        onSaveCannedResponse={(event) => this.onSaveCannedResponse(event)}
                        updateCannedResponse={(uid, desc, shortcut) => this.updateCannedResponse(uid, desc, shortcut)}
                        updateCancelClick={() => this.updateCancelClick()}
                        onEditClick={(uid, desc, shortcut) => this.onEditClick(uid, desc, shortcut)}
                        onDeleteClick={(uid) => this.onDeleteClick(uid)}
                        confirmDeleteClick={(uid) => this.confirmDeleteClick(uid)}
                        confirmCancelClick={() => this.confirmCancelClick()}
                        billingCodesList={this.props.billing_codes}
                        billing_code={this.props.billing_code_value}
                        billing_code_desc={this.props.billing_code_desc_value}
                        onBillingCodeTextChange={(text) => this.onBillingCodeTextChange(text)}
                        onBillingCodeDescriptionChange={(text) => this.onBillingCodeDescriptionChange(text)}
                        onSaveBillingCode={(event) => this.onSaveBillingCode(event)}
                    />
                </div>
            </Aux>
        )
    }
}

const mapStateToProps = ({ tagsReducer, clinicalcannedResponseReducer, billingcodesReducer,
    conversationReducer, acceptingReducer, doctorReducer }) => {

    const { tags, tag_value } = tagsReducer;
    const { description, short_cut, canned_responses, chats_canned_responses, canned_responses_assessment,
        canned_responses_plan } = clinicalcannedResponseReducer;
    const { billing_code_value, billing_code_desc_value, billing_codes } = billingcodesReducer;
    const { acceptingChat ,changedDoctorKey} = acceptingReducer
    const { doctorDetail } = doctorReducer
    return { tags, tag_value, description, short_cut, canned_responses, chats_canned_responses,
        canned_responses_assessment, canned_responses_plan,
        acceptingChat, doctorDetail ,changedDoctorKey, billing_code_value, billing_code_desc_value, billing_codes };
}

export default connect(mapStateToProps, {
    tag_changed, save_tags, fetchTags, canned_response_description_changed, childChangedDoctor, checkStatusAccept,
    canned_response_shortcut_changed, canned_response_save, updateCannedResponse, deleteCannedResponse,
    fetchCannedResponsesForChats, fetchCannedResponsesForObjectiveFindings, fetchCannedResponsesForAssessment, fetchCannedResponsesForPlan,
    billing_code_changed, billing_code_desc_changed, save_billing_code, updateBillingCode, fetchBillingCodes, deleteBillingCode
})(SettingBuilder);
