import  {Component} from 'react';
import {connect} from 'react-redux';
import Aux from '../../hoc/hocAux';
import classes from './Archives.module.css';

import ArchiveList from './ArchiveList';
import UserWarning from "../chatBuilder/UserWarning/UserWarning";
import ArchiveBody from './ArchiveBody';
import {childChangedDoctor} from '../../actions/AgentsAction';
import {checkStatusAccept} from '../../actions/ConversationAction';
import ArchiveUserDetail from './ArchiveUserDetail';
import _ from 'lodash';

import EditChatDetails from './editChatDetails/EditChatDetail';

import {
    fetchAllArchivesData,
    fetchArchiveMessages,
    fetchArchives,
    searchArchives,
    setAlgolia
} from '../../actions/ArchivesAction';

class ArchivesBuilder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            headerText: {name: 'Archives'},
            tagsList: [],
            editChatDetailForm: false,
            inputValue: ''
        }
    }

    componentDidMount() {
        // console.log("patientphonenumber this.props", this.props)
        // console.log("patientphonenumber this.state", this.state)
        this.props.setAlgolia();
        this.props.fetchArchives([], null);
        this.props.childChangedDoctor();

    }

    UNSAFE_componentWillReceiveProps(newProps) {
        // console.log("patientphonenumber this.props", this.props)
        // console.log("patientphonenumber this.state", this.state)
        if (newProps.changedDoctorKey === newProps.doctorDetail.uid) {
            this.props.checkStatusAccept();
        }
        if(!newProps.archive_conversationID && this.props.archive_conversationID){
            this.setState({headerText: {name: 'Archives'}});

        }else if(newProps.archive_conversationID !== this.props.archive_conversationID){
            console.log("newProps.ownerProfile? newProps.archiveOwnerProfile.name || \"\" : newProps.archive_patientName ", newProps.archiveOwnerProfile? newProps.archiveOwnerProfile.name || "" : newProps.archive_patientName)
            this.setState({headerText: {name: newProps.archiveOwnerProfile? newProps.archiveOwnerProfile.name || "" : newProps.archive_patientName, doctor: newProps.archive_doctorName}});
            console.log("componentWillReceiveProps archive_healthInsuranceCardBack ", newProps.archive_healthInsuranceCardBack )
            console.log("componentWillReceiveProps archive_healthInsuranceCardFront ", newProps.archive_healthInsuranceCardFront )
            console.log("componentWillReceiveProps archive_healthInsuranceCardNumber ", newProps.archive_healthInsuranceCardNumber )

        }
    }

    archiveHeaderItem = (user_id, patientEmail, patientName, doctorName, doctorID, patientID, doctorImage,
                         conversationID, tagsList, city, country, platform, DOB, gender, issue, currentMedications,
                         diagnosisValue, opinionDescriptionValue, commentAdded, medications, followUpData,
                         gmtOffset, buildNumber, convoCount, averageSleep, currentSleep, heartRate, heightCm,
                         heightFeet, stepCount, runningWalking, weightKg, weightLbs,
                         periodStartDate, flowType, restingHeartRate, complete_address, timestamp, issueResolved, symptoms,
                         findings,assessment,plan,ownerProfile,owner) => {
        if (tagsList) {
            const tags = _.map(tagsList, (val, uid) => {
                return {...val, uid};
            });
            this.setState({tagsList: tags});
        }else{
            this.setState({tagsList: []});
        }
        console.log("ownerProfile? ownerProfile.name || \"\" : patientName",ownerProfile? ownerProfile.name || "" : patientName)

        this.setState({headerText: {name: ownerProfile? ownerProfile.name || "" : patientName, doctor: doctorName}});
        this.props.fetchArchiveMessages(user_id, patientEmail, patientName, doctorName, doctorID, patientID, doctorImage,
            conversationID, tagsList, city, country, platform, DOB, gender, issue, currentMedications,
            diagnosisValue, opinionDescriptionValue, commentAdded, medications, followUpData,
            gmtOffset, buildNumber, convoCount, averageSleep, currentSleep, heartRate, heightCm, heightFeet,
            stepCount, runningWalking, weightKg, weightLbs, periodStartDate, flowType, restingHeartRate,
            complete_address, timestamp, issueResolved, symptoms,findings,assessment,plan,ownerProfile,owner);
    }

    archiveSearchText = (text, type) => {
        this.props.searchArchives(this.state.inputValue, [], type);
    }

    archiveSearchValue = (text) => {
        if (text === '') {
            this.props.fetchArchives([], null);
        } else {
            this.setState({inputValue: text});
        }
    }

    nextArchives = () => {
        this.props.fetchArchives(this.props.archives, this.props.lastTimeStamp);
    }

    editChatDetailClick = () => {
        this.setState({editChatDetailForm: true});
    }

    hideModal = () => {
        this.setState({editChatDetailForm: false});
    }

    nextArchives = () => {
        this.props.fetchArchives(this.props.archives, this.props.lastTimeStamp);
    }

    render() {
        return (
            <Aux>
                <div className={classes.top_bar}>
                    <div className={classes.header_doctor_name}>
                        <span>{this.props.doctorDetail.doctorName}</span>
                    </div>
                    <div className={classes.header_status}>
                        Status:
                        {this.props.acceptingChat && <span> Accepting Patients</span>}
                        {!this.props.acceptingChat &&
                        <span className={classes.notAcceptingChat}> Not Accepting Patients</span>}
                    </div>
                </div>
                <div className={classes.main}>
                    <ArchiveList
                        loading={this.props.loading}
                        archives={this.props.archives}
                        archiveHeaderItem={this.archiveHeaderItem}

                        archiveSearchValue={(text) => this.archiveSearchValue(text)}
                        archiveSearchText={this.archiveSearchText}
                        isSearch={this.props.isSearch}
                        isLast={this.props.isLast}
                        acceptingChat={this.props.acceptingChat}
                        nextArchives={() => this.nextArchives()}
                        previous_length={this.props.previous_length}
                    />
                    <ArchiveBody
                        tags={this.state.tagsList}
                        sessionID={this.props.archive_conversationID}
                        headerText={this.state.headerText}
                        load_messages={this.props.load_messages}
                        archives_messages={this.props.archives_messages}
                        doctorName={this.props.archive_doctorName}
                        doctorImg={this.props.archive_doctorImg}
                        patientName={this.props.archiveOwnerProfile?.name || this.props.archive_patientName}
                        archive_timestamp={this.props.archive_timestamp}
                    />
                    {this.state.headerText.name !== 'Archives' &&
                    <ArchiveUserDetail
                        {...this.props}
                        patientEmail={this.props.archive_patientEmail}
                        patientName={this.props.archive_patientName}
                        city={this.props.archive_patientCity}
                        country={this.props.archive_patientCountry}
                        complete_address={this.props.archive_complete_address}
                        platform={this.props.archive_platform}
                        dob={this.props.archive_patientDOB}
                        gender={this.props.archive_patientGender}
                        symptoms={this.props.archive_patientSymptoms}
                        medication={this.props.archive_patientMedications}
                        chatCount={this.props.archive_patientChatCount}
                        buildNumber={this.props.archive_patientBuildNumber}
                        sessionID={this.props.archive_conversationID}
                        editChatDetailClick={() => this.editChatDetailClick()}
                        averageSleep={this.props.averageSleep}
                        currentSleep={this.props.currentSleep}
                        heightCm={this.props.heightCm}
                        heightFeet={this.props.heightFeet}
                        stepCount={this.props.stepCount}
                        runningWalking={this.props.runningWalking}
                        weightKg={this.props.weightKg}
                        heartRate={this.props.heartRate}
                        weightLbs={this.props.weightLbs}
                        periodStartDate={this.props.periodStartDate}
                        flowType={this.props.flowType}
                        restingHeartRate={this.props.restingHeartRate}
                        diagnosisValue={this.props.archive_diagnosisValue}
                        commentAdded={this.props.archive_commentAdded}
                        patientFollowUpData={this.props.archive_patientFollowUpData}
                        patientMedicationData={this.props.archive_patientMedicationData}
                        archive_healthInsuranceCardBack={this.props.archive_healthInsuranceCardBack}
                        archive_healthInsuranceCardFront={this.props.archive_healthInsuranceCardFront}
                        archive_healthInsuranceCardNumber={this.props.archive_healthInsuranceCardNumber}
                        archiveOwnerProfile={this.props.archiveOwnerProfile}

                    />
                    }

                    <EditChatDetails
                        {...this.props}
                        show={this.state.editChatDetailForm}
                        hideModal={() => this.hideModal()}
                        patientName={this.props.archive_patientName}
                        doctorName={this.props.archive_doctorName}
                        archive_patientIssue={this.props.archive_patientSymptoms}
                        archive_commentAdded={this.props.archive_commentAdded}
                        archive_opinionDescriptionValue={this.props.archive_opinionDescriptionValue}
                        archive_diagnosisValue={this.props.archive_diagnosisValue}
                        archive_patientSymptoms={this.props.archive_patientSymptoms}
                        archive_findings={this.props.archive_findings}
                        archive_plan={this.props.archive_plan}
                        archive_assessment={this.props.archive_assessment}
                        archive_patientFollowUpData={this.props.archive_patientFollowUpData}
                        archive_patientMedicationData={this.props.archive_patientMedicationData}
                        archive_conversationID={this.props.archive_conversationID}
                        archive_userID={this.props.archive_userID}
                        archive_issueResolved={this.props.archive_issueResolved}
                    />
                </div>
            </Aux>
        )
    }
}

const mapStateToProps = ({archivesReducer, acceptingReducer, doctorReducer}) => {
    const {
        loading,
        archives,
        load_messages,
        archives_messages,
        archive_doctorName,
        archive_doctorImg,
        lastTimeStamp,
        previous_length,
        isSearch,
        isLast,
        archive_patientEmail,
        archive_patientName,
        archive_patientCity,
        archive_patientCountry,
        archive_platform,
        allArchives,
        archive_complete_address,
        archive_patientDOB,
        archive_patientGender,
        archive_patientSymptoms,
        archive_patientMedications,
        archive_userID,
        archive_patientChatCount,
        archive_patientBuildNumber,
        archive_conversationID,
        archive_timestamp,
        archive_issueResolved,
        archive_commentAdded,
        archive_opinionDescriptionValue,
        archive_diagnosisValue,
        archive_findings,
        archive_plan,
        archive_assessment,
        archive_patientFollowUpData,
        archive_patientMedicationData,
        averageSleep,
        currentSleep,
        heartRate,
        heightCm,
        heightFeet,
        stepCount,
        runningWalking,
        weightKg,
        weightLbs,
        periodStartDate,
        flowType,
        restingHeartRate,
        archive_phone_number,
        archive_healthInsuranceCardBack,
        archive_healthInsuranceCardFront,
        archive_healthInsuranceCardNumber,
        archiveOwnerProfile
    } = archivesReducer;
    const {acceptingChat, changedDoctorKey} = acceptingReducer;
    const {doctorDetail} = doctorReducer;

    return {
        loading,
        archives,
        load_messages,
        archives_messages,
        archive_doctorName,
        archive_doctorImg,
        lastTimeStamp,
        previous_length,
        isSearch,
        isLast,
        archive_patientEmail,
        archive_patientName,
        archive_patientCity,
        archive_patientCountry,
        archive_platform,
        allArchives,
        archive_complete_address,
        archive_patientDOB,
        archive_patientGender,
        archive_patientSymptoms,
        archive_patientMedications,
        archive_userID,
        archive_conversationID,
        archive_commentAdded,
        archive_findings,
        archive_plan,
        archive_assessment,
        archive_opinionDescriptionValue,
        archive_diagnosisValue,
        archive_patientFollowUpData,
        archive_patientMedicationData,
        archive_timestamp,
        archive_issueResolved,
        archive_patientChatCount,
        archive_patientBuildNumber,
        acceptingChat,
        changedDoctorKey,
        doctorDetail,
        averageSleep,
        currentSleep,
        heartRate,
        heightCm,
        heightFeet,
        stepCount,
        runningWalking,
        weightKg,
        weightLbs,
        periodStartDate,
        flowType,
        restingHeartRate,
        archive_phone_number,
        archive_healthInsuranceCardBack,
        archive_healthInsuranceCardFront,
        archive_healthInsuranceCardNumber,
        archiveOwnerProfile
    };
}

export default connect(mapStateToProps, {
    fetchArchives,
    fetchArchiveMessages,
    searchArchives,
    fetchAllArchivesData,
    childChangedDoctor,
    checkStatusAccept,
    setAlgolia
})(ArchivesBuilder);


