import  {Component} from 'react';
import {connect} from 'react-redux';

import Header from './header';
import Body from './body';

import classes from '../../../../assets/classes.module.css';

import {
    clinic_canned_response_descriptopn_changed,
    clinic_canned_response_save,
    clinic_canned_response_shortcut_changed,
    clinic_deleteCannedResponse,
    clinic_fetchCannedResponsesForAssessment,
    clinic_fetchCannedResponsesForChats,
    clinic_fetchCannedResponsesForObjectiveFindings,
    clinic_fetchCannedResponsesForPlan,
    clinic_updateCannedResponse
} from '../../../../actions/clinic/setting/ClinicCannedResponseAction';

class CannedResponses extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeLink: 'chats',
            selectedEditTag: null,
            selectedTagDesciption: '',
            selectedTagShortCut: '',
            selectedDeleteTag: null,
            showCannedResponsesItems: false,
        }
    }

    componentDidMount() {
        this.props.clinic_fetchCannedResponsesForChats(this.props.clinicId, 'chats');
    }

    onHeaderChange = (data) => {
        this.setState((state) => ({activeLink: data}));
        this.props.clinic_fetchCannedResponsesForChats(this.props.clinicId, data);
    }

    onChangeCannedDescription = (event) => {
        this.setState({selectedTagDesciption: event.target.value});
    }

    onChangeCannedShortCut = (event) => {
        this.setState({selectedTagShortCut: event.target.value});
    }

    onEditClick = (uid, desc, shortcut) => {
        this.setState({selectedEditTag: uid, selectedTagDesciption: desc, selectedTagShortCut: shortcut});
    }

    updateCannedResponse = (uid, desc, shortcut) => {
        var self = this;
        this.props.clinic_updateCannedResponse(this.props.clinicId, uid, desc, shortcut, this.state.activeLink, function (status) {
            self.setState({selectedEditTag: null, selectedTagDesciption: '', selectedTagShortCut: ''});
        });
    }

    updateCancelClick = () => {
        this.setState({selectedEditTag: null, selectedTagDesciption: '', selectedTagShortCut: ''});
    }

    onDeleteClick = (uid) => {
        this.setState({selectedDeleteTag: uid});
    }

    confirmDeleteClick = (uid) => {
        var self = this;
        if (this.state.activeLink === 'Billing Codes') {
            this.props.clinic_deleteBillingCode(this.props.clinicId, uid, this.state.activeLink, function (status) {
                self.setState({selectedDeleteTag: uid});
            });
        } else {
            this.props.clinic_deleteCannedResponse(this.props.clinicId, uid, this.state.activeLink, function (status) {
                self.setState({selectedDeleteTag: uid});
            });
        }
    }

    confirmCancelClick = () => {
        this.setState({selectedDeleteTag: null});
    }

    description = (event) => {
        this.props.clinic_canned_response_descriptopn_changed(event.target.value);
    }

    shortcut = (event) => {
        this.props.clinic_canned_response_shortcut_changed(event.target.value);
    }

    onSaveCannedResponse = (event) => {
        event.preventDefault();
        if (this.props.clinic_description !== '' && this.props.clinic_short_cut !== '') {
            this.props.clinic_canned_response_save(
                this.props.clinicId,
                this.props.clinic_description,
                this.props.clinic_short_cut,
                this.state.activeLink
            );
        } else {
            alert("Please check your canned response text and shortcut. We need both");
        }
    }

    render() {
        const {
            clinic_description, clinic_short_cut, clinic_canned_responses,
            clinic_chats_canned_responses, clinic_canned_responses_assessment,
            clinic_canned_responses_plan
        } = this.props;

        const {selectedTagDesciption, selectedTagShortCut, selectedEditTag, selectedDeleteTag} = this.state;
        return (
            <div className={classes.main_container}>
                <div className={classes.main_container_header}>Created canned responses which are pre-made messages you
                    can recall during an encounter or when creating notes.
                </div>
                <div className={classes.form_container_view}>
                    <Header
                        headerItem={(data) => this.onHeaderChange(data)}
                        activeLink={this.state.activeLink}
                    />
                    <Body
                        activeLink={this.state.activeLink}
                        description={clinic_description}
                        short_cut={clinic_short_cut}
                        selectedEditTag={selectedEditTag}
                        selectedDeleteTag={selectedDeleteTag}
                        selectedTagDesciption={selectedTagDesciption}
                        selectedTagShortCut={selectedTagShortCut}
                        canned_responsesList_findings={clinic_canned_responses}
                        canned_responsesList_chats={clinic_chats_canned_responses}
                        canned_responsesList_assessment={clinic_canned_responses_assessment}
                        canned_responsesList_plan={clinic_canned_responses_plan}
                        onChangeCannedDescription={(event) => this.onChangeCannedDescription(event)}
                        onChangeCannedShortCut={(event) => this.onChangeCannedShortCut(event)}
                        onEditClick={(uid, desc, shortcut) => this.onEditClick(uid, desc, shortcut)}
                        updateCannedResponse={(uid, desc, shortcut) => this.updateCannedResponse(uid, desc, shortcut)}
                        updateCancelClick={() => this.updateCancelClick()}
                        onDeleteClick={(uid) => this.onDeleteClick(uid)}
                        confirmDeleteClick={(uid) => this.confirmDeleteClick(uid)}
                        confirmCancelClick={() => this.confirmCancelClick()}
                        onCannedResponseTextChange={(text) => this.description(text)}
                        onCannedResponseShortcutChange={(text) => this.shortcut(text)}
                        onSaveCannedResponse={(event) => this.onSaveCannedResponse(event)}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({clinicProfileSettingReducer, clinicCannedResponseReducer}) => {

    const {clinicId, userType} = clinicProfileSettingReducer;

    const {
        clinic_description, clinic_short_cut, clinic_canned_responses,
        clinic_chats_canned_responses, clinic_canned_responses_assessment,
        clinic_canned_responses_plan
    } = clinicCannedResponseReducer;

    return {
        clinicId, userType,
        clinic_description, clinic_short_cut, clinic_canned_responses,
        clinic_chats_canned_responses, clinic_canned_responses_assessment,
        clinic_canned_responses_plan
    };
}

export default connect(mapStateToProps, {
    clinic_canned_response_descriptopn_changed,
    clinic_canned_response_shortcut_changed,
    clinic_canned_response_save,
    clinic_fetchCannedResponsesForChats,
    clinic_fetchCannedResponsesForObjectiveFindings,
    clinic_fetchCannedResponsesForAssessment,
    clinic_fetchCannedResponsesForPlan,
    clinic_updateCannedResponse,
    clinic_deleteCannedResponse
})(CannedResponses);
