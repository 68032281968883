
import classes from '../ChatBuilder.module.css';
import {connect} from "react-redux";
import {useState} from "react";
import {sendMessage} from "../../../actions/ChatAction";
import {removeTagInChat, saveTagInChat } from "../../../actions/ConversationAction";

const TagsList = (props) => {
    return (
        <p key={`tags_${props.uid}`} className={props.validDoctor ? classes.tt_suggestion : classes.tt_suggestion_none}
           onClick={() => props.onTagClick(props.name, props.id, props.uid)}>{props.name}</p>
    )
}

const SelectedTags = (props) => {
    return (
        <span key={`selected_tags_${props.uid}`} className={classes.addtag}>
            <span>{props.name}</span>
            <div className={classes.delete_tag}
                 onClick={() => props.onRemoveTagClick(props.name, props.id, props.uid)}>×</div>
        </span>
    )
}

const CannedResponses = (props) => {
    return (
        <div key={`canned_${props.uid}`} className={classes.canned_response_item}
             onClick={() => props.onCannedResponseClick(props.uid, props.short_cut, props.text)}>
            <div className={classes.canned_response_text}>{props.short_cut}</div>
            <div className={classes.canned_response_arrow}
                 onClick={() => props.onCannedResponseClick(props.uid, props.short_cut, props.text)}>
                <svg data-test="shortcut-select-button" fill="#000000" width="4px" height="12px" viewBox="0 0 8 13">
                    <g fill="inherit" fillRule="evenodd">
                        <path fillRule="nonzero" d="M.59 11.34l4.58-4.59L.59 2.16 2 .75l6 6-6 6z"></path>
                    </g>
                </svg>
            </div>
        </div>
    )
}

const SendBox = (props) => {
    const [inputBarText, setInputBarText] = useState('')
    const [showCannedResponseList, setShowCannedResponseList] = useState(false)
    const [cannedResponses, setCannedResponses] = useState([])
    const [selectedCannedResponseText, setSelectedCannedResponseText] = useState('')
    const [selectedCannedResponseShortCut, setSelectedCannedResponseShortCut] = useState('')
    const [showTagsListModal, setShowTagsListModal] = useState(false)


    const onTagClick = (name, id, uid) => {
        if (props.tagChatList.length !== 0) {
            const found = props.tagChatList.some((item) => item.id === uid);
            if (!found) {
                props.saveTagInChat(props.conversationId, {
                    id: uid,
                    name: name,
                });
                setShowTagsListModal(false)
            }
        } else {
            props.saveTagInChat(props.conversationId, {
                id: uid,
                name: name,
            });
            setShowTagsListModal(false)
        }
    };

    const onSendMessage = () => {
        if (inputBarText.length > 0) {
            //console.log("textareaRef.current?.value ", textareaRef?.current?.value)
            //textareaRef.current.value = "";
            console.log("onSendMessage inputBarText ", inputBarText)
            props.sendMessage(
                props.conversationId,
                { type: "text", content: inputBarText },
                props.patientId,
                props.doctorName,
                props.doctorImage
            );
            setInputBarText('')
        }
    };

    const onChangeText = (event) => {
        if (event.target.value.match("#")) {
            setShowCannedResponseList(true)
            filterUsers(
                event.target.value.slice(
                    event.target.value.search("#"),
                    event.target.value.length
                )
            );
        }
        setInputBarText(event.target.value)
    };

    const filterUsers = (event) => {
        let  keyword = event.toLowerCase();
        let filtered_cannedResponses = props.canned_responses.filter(
            (user) => {
                user = user.shortcut.toLowerCase();
                return user.indexOf(keyword) > -1;
            }
        );
        if (filtered_cannedResponses.length !== 0) {
            setCannedResponses(filtered_cannedResponses);
        } else {
            setCannedResponses(props.canned_responses);
        }
    };

    const onCannedResponseClick = (uid, short_cut, text) => {
        setShowCannedResponseList(false)
        setSelectedCannedResponseText(text)
        setSelectedCannedResponseShortCut(short_cut)
    };

    const onCannedResponseShow = () => {
        console.log("onCannedResponseShow triggered")
        setShowCannedResponseList(!showCannedResponseList)
        setSelectedCannedResponseText("")
        setSelectedCannedResponseShortCut("")
        filterUsers('#');
    };

    const selectedCannedResponse = (text, short_cut) => {
        let updatedString;
        setSelectedCannedResponseText("")
        setSelectedCannedResponseShortCut("")
        setCannedResponses(props.canned_responses)
        if (inputBarText.search("#") !== -1) {
            let replaceText = inputBarText.slice(
                inputBarText.search("#"),
                inputBarText.length
            );
            updatedString = inputBarText.replace(replaceText, text);
        } else {
            updatedString = inputBarText.concat(" ", text);
        }
        setInputBarText(updatedString)
    };



    const onRemoveTagClick = (name, id, uid) => {
        props.removeTagInChat(props.conversationId, { uid: uid });
    };

    const openTagListModal = () => {
        console.log("openTagListModal !showTagsListModal", !showTagsListModal )
        setShowTagsListModal(!showTagsListModal)
    };

    const onAttachmentClick = (e) => {
        console.log("e: ", e.current.click());
    };

    return (
        <div className={classes.textarea}>
            <div className={classes.input}>
                <div className={(!props.doctorIsValid) ? classes.disabled_input_container : classes.input_container}>
                    <div className={classes.type_area}>
                        <textarea
                            placeholder={!props.doctorIsValid ? "Private messages will be visible for agents only" : "Type a message"}
                            className={!props.doctorIsValid ? classes.inValid : classes.valid}
                            disabled={!props.doctorIsValid}
                            ref={props.textareaElement}
                            onChange={onChangeText}
                            value={inputBarText}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter' && !event.shiftKey) {
                                    event.preventDefault();
                                    onSendMessage();
                                }
                            }
                            }
                        />

                        {showCannedResponseList &&
                        <div className={classes.canned_response_list}>
                            <div className={classes.canned_response_view}>
                                {cannedResponses.map(canned_response => {
                                    return <CannedResponses
                                        key={canned_response.uid}
                                        uid={canned_response.uid}
                                        text={canned_response.text}
                                        short_cut={canned_response.shortcut}
                                        onCannedResponseClick={onCannedResponseClick}
                                    />
                                })}
                            </div>
                        </div>
                        }

                        {selectedCannedResponseText !== '' &&
                        <div className={classes.canned_response_list}>
                            <div className={classes.canned_response_selected_text}>
                                <div className={classes.canned_response_selected_text_li}>
                                    <div className={classes.canned_response_content}>
                                        <div className={classes.canned_response_content_inner}>
                                            <div className={classes.canned_response_content_data}>
                                                <div className={classes.canned_response_back_arrow}>
                                                    <div className={classes.canned_response_back_arrow_view}
                                                         onClick={onCannedResponseShow}>
                                                        <svg fill="#000000" width="4px" height="8px" viewBox="0 0 8 13">
                                                            <g fill="inherit" fillRule="evenodd">
                                                                <path fillRule="nonzero"
                                                                      d="M.59 11.34l4.58-4.59L.59 2.16 2 .75l6 6-6 6z"></path>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className={classes.canned_response_content_text}
                                                     onClick={() => selectedCannedResponse(selectedCannedResponseText, selectedCannedResponseShortCut)}>{selectedCannedResponseText}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>

                    <div className={classes.submit_area}>
                        <div className={classes.bottom_area}>
                            <div className={classes.hashtag}>
                                <div className={classes.attachment} disabled={!props.doctorIsValid}
                                     onClick={onCannedResponseShow}>
                                    <svg fill={!props.doctorIsValid ? "#ffffff" : "#1355c7"} width="14px" height="14px"
                                         viewBox="0 0 13 14">
                                        <g fill="inherit">
                                            <path
                                                d="M11.4800171,9.8 L1.68001709,9.8 C1.26001709,9.8 0.98001709,9.52 0.98001709,9.1 C0.98001709,8.68 1.26001709,8.4 1.68001709,8.4 L11.4800171,8.4 C11.9000171,8.4 12.1800171,8.68 12.1800171,9.1 C12.1800171,9.52 11.9000171,9.8 11.4800171,9.8 Z"></path>
                                            <path
                                                d="M12.4599915,5.88001709 L2.65999146,5.88001709 C2.23999146,5.88001709 1.95999146,5.60001709 1.95999146,5.18001709 C1.95999146,4.76001709 2.23999146,4.48001709 2.65999146,4.48001709 L12.4599915,4.48001709 C12.8799915,4.48001709 13.1599915,4.76001709 13.1599915,5.18001709 C13.1599915,5.60001709 12.8799915,5.88001709 12.4599915,5.88001709 Z"></path>
                                            <path
                                                d="M14.7,7.7 L3.5,7.7 C3.08,7.7 2.8,7.42 2.8,7 C2.8,6.58 3.08,6.3 3.5,6.3 L14.7,6.3 C15.12,6.3 15.4,6.58 15.4,7 C15.4,7.42 15.12,7.7 14.7,7.7 Z"
                                                transform="translate(9.100000, 7.000000) rotate(99.000000) translate(-9.100000, -7.000000)"></path>
                                            <path
                                                d="M10.5,7.7 L-0.7,7.7 C-1.12,7.7 -1.4,7.42 -1.4,7 C-1.4,6.58 -1.12,6.3 -0.7,6.3 L10.5,6.3 C10.92,6.3 11.2,6.58 11.2,7 C11.2,7.42 10.92,7.7 10.5,7.7 Z"
                                                transform="translate(4.900000, 7.000000) rotate(99.000000) translate(-4.900000, -7.000000)"></path>
                                        </g>
                                    </svg>
                                </div>
                                <div className={classes.attachment}
                                     onClick={() => onAttachmentClick(props.inputElement)}>
                                    <input
                                        className={classes.file_input}
                                        type="file"
                                        ref={props.inputElement}
                                        onChange={props._handleImageChange}
                                        disabled={!props.doctorIsValid}
                                    />
                                    <svg fill={!props.doctorIsValid ? "#ffffff" : "#1355c7"} width="14px" height="14px"
                                         viewBox="0 0 8 16">
                                        <g>
                                            <path fill="inherit"
                                                  d="M7 4v7.667a2.666 2.666 0 1 1-5.333 0V3.333a1.667 1.667 0 0 1 3.333 0v7c0 .367-.3.667-.667.667a.669.669 0 0 1-.666-.667V4h-1v6.333a1.667 1.667 0 0 0 3.333 0v-7a2.666 2.666 0 1 0-5.333 0v8.334a3.665 3.665 0 0 0 3.666 3.666A3.665 3.665 0 0 0 8 11.667V4H7z"></path>
                                            <path fill="#ffffff" fillOpacity="0" d="M-4 0h16v16H-4z"></path>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div className={classes.btn_area}>
                                <button className={classes.btn} disabled={!props.doctorIsValid} type="button"
                                        onClick={() => onSendMessage(props.textareaElement)}>Send
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.tag_view}>
                <div className={props.doctorIsValid || !props.admin ? classes.chat_tags : classes.chat_tags_hide}
                     disabled={props.doctorIsValid}>
                    {props.tagChatList.map(selected => {
                        return <SelectedTags
                            key={selected.id}
                            id={selected.id}
                            uid={selected.uid}
                            name={selected.name}
                            onRemoveTagClick={onRemoveTagClick}
                        />
                    })}
                    <span className={classes.another_tag_container} onClick={openTagListModal}>
                        <div className={classes.add_tag} onClick={openTagListModal}>
                            <span className={classes.add_tag_icon} onClick={openTagListModal}>
                                <div className={classes.add_icon}>
                                    <svg fill="#4384F5" width="17px" height="17px" viewBox="0 0 18 18">
                                        <g>
                                            <rect fill="#ffffff" fillOpacity="0" width="17.12" height="17.12" x=".44"
                                                  y=".44" stroke="#BCC6D0" strokeWidth=".88" rx="3.52"></rect>
                                            <path fill="inherit"
                                                  d="M9.745 8.208H13v1.32H9.745V13h-1.49V9.528H5v-1.32h3.255V5h1.49z"></path>
                                        </g>
                                    </svg>
                                </div>
                            </span>
                            add tag.
                        </div>
                    </span>
                    <div className={showTagsListModal ? classes.main_modal : classes.main_modal_hide}
                         style={{opacity: showTagsListModal ? '1' : '0'}}>
                        <div className={classes.tag_modal}>
                            <div className={classes.tag_modal_list}>
                                {props.tags.map(tag => {
                                    return <TagsList
                                        key={tag.uid}
                                        id={tag.id}
                                        uid={tag.uid}
                                        name={tag.tag}
                                        validDoctor={props.doctorIsValid}
                                        onTagClick={onTagClick}/>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ conversationReducer, chatReducer, cannedResponseReducer, tagsReducer }) => {
    const { conversationId, patientId, tagChatList, doctorIsValid, admin } = conversationReducer;
    const { doctorName, doctorImage } = chatReducer;
    const { canned_responses } = cannedResponseReducer;
    const { tags } = tagsReducer;
    return { conversationId, patientId, doctorName, doctorImage, canned_responses, tags, tagChatList, doctorIsValid };
};

export default connect(mapStateToProps, {sendMessage, removeTagInChat, saveTagInChat })(SendBox);

