import  {Component} from 'react';

import classes from '../../assets/classes.module.css';
import _classes from '../../assets/_classes.module.css';
import Input from '../../components/UI/Input/ClinicInput';
import Spinner from '../../components/UI/Spinner/Spinner';
import logo from '../../assets/logo.png';

import {connect} from 'react-redux';

import {forgotPassword} from '../../actions/LoginAction';
import isMobileView from '../../utils/isMobileView';

class ForgotPassword extends Component {

    state = {
        controls: {
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'User email address',
                    tooltip: 'Email address to reset password',
                    style: {
                        'padding': '20px 15px'
                    }
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            }
        }
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    inputChangedHandler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: this.checkValidity(event.target.value, this.state.controls[controlName].validation),
                touched: true
            }
        };
        this.setState({controls: updatedControls});
    }

    submitHandler = (event) => {
        event.preventDefault();
        this.props.forgotPassword(this.state.controls.email.value);
    }

    onAuthClick = () => {
        const path = {
            pathname: '/auth',
            search: '',
            hash: '',
        }
        this.props.history.push(path);
    }

    render() {
        const {err, msg} = this.props;
        const formElementsArray = [];
        for (let key in this.state.controls) {
            formElementsArray.push({
                id: key,
                config: this.state.controls[key]
            });
        }

        let errorMessage = null;
        if (msg !== '') {
            errorMessage = (
                isMobileView() ? <div className={_classes.error_container}>
                    <div className={_classes.absolute_success_bar}/>
                    <div className={_classes.error}>{this.props.msg}
                        <span className={_classes.underline_text} onClick={(event) => this.submitHandler(event)}> send email again.</span>
                    </div>
                </div> : <div className={classes.error_container}>
                    <div className={classes.absolute_success_bar}/>
                    <div className={classes.error}>{this.props.msg}
                        <span className={classes.underline_text} onClick={(event) => this.submitHandler(event)}> send email again.</span>
                    </div>
                </div>
            );
        }

        if (err !== '') {
            errorMessage = (
                isMobileView() ? <div className={_classes.error_container}>
                    <div className={_classes.absolute_error_bar}/>
                    <div className={_classes.error}>{this.props.err}</div>
                </div> : <div className={classes.error_container}>
                    <div className={classes.absolute_error_bar}/>
                    <div className={classes.error}>{this.props.err}</div>
                </div>
            );
        }


        let form = (
            isMobileView() ? <div>
                {formElementsArray.map(formElement => (
                    <Input
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)}/>
                ))}
                {errorMessage}
                <div className={_classes.bottom_view}>

                    <button className={_classes.button} onClick={this.submitHandler}>Send Email</button>
                    <button className={_classes.transparent_button} onClick={(event) => this.onAuthClick(event)}>Return
                        to login?
                    </button>
                </div>
            </div> : <div>
                {formElementsArray.map(formElement => (
                    <Input
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)}/>
                ))}
                {errorMessage}
                <div className={classes.bottom_view}>
                    <button className={classes.transparent_button} onClick={(event) => this.onAuthClick(event)}>Return
                        to login?
                    </button>
                    <button className={classes.button} onClick={this.submitHandler}>Send Email</button>
                </div>
            </div>
        );

        if (this.props.loader) {
            form = <Spinner/>
        }
        return (
            isMobileView() ? <div className={_classes.container}>
                <div className={_classes.content}>
                    <div className={_classes.main_view}>
                        <div className={classes.logo}>
                            <img src={logo} alt="logo" className={classes.logo_container}/>
                        </div>
                        <span className={_classes.heading}>Forgot your account?</span>
                        <p className={{..._classes.sub_heading, "text-align": "left", "align_self": "left"}}>Enter your
                            email address to recover your account.</p>
                        <div className={_classes.form_view}>
                            {form}
                        </div>
                    </div>
                </div>
                {/* <div className={_classes.footer}>
               <a href='https://yourdoctors.online/wp-content/uploads/2020/10/YDO_Terms_of_Use.pdf' target='_blank'>Privacy & Terms</a>
               <a href='mailto: info@yourdoctors.online'>Contact Us</a>
           </div> */}
            </div> : <div className={classes.container}>
                <div className={classes.content}>
                    <div className={classes.main_view}>
                        <div className={classes.logo}>
                            <img src={logo} alt="logo" className={classes.logo_container}/>
                        </div>
                        <span className={classes.heading}>Forgot your account?</span>
                        <p className={classes.sub_heading}>Enter your email address to recover your account.</p>
                        <div className={classes.form_view}>
                            {form}
                        </div>
                    </div>
                </div>
                {/* <div className={classes.footer}>
                    <a href='https://yourdoctors.online/wp-content/uploads/2020/10/YDO_Terms_of_Use.pdf' target='_blank'>Privacy & Terms</a>
                    <a href='mailto: info@yourdoctors.online'>Contact Us</a>
                </div> */}
            </div>
        );
    }
}


const mapStateToProps = ({forgotPasswordReducer}) => {
    const {loader, msg, err} = forgotPasswordReducer;
    return {loader, msg, err};
};

export default connect(mapStateToProps, {forgotPassword})(ForgotPassword);
