import  {Component} from 'react';
import {fetchCustomers, refreshCustomers} from '../../actions/CustomersAction';
import {childChangedDoctor} from '../../actions/AgentsAction';
import {checkStatusAccept} from '../../actions/ConversationAction';

import {connect} from 'react-redux';
import Aux from '../../hoc/hocAux';
import classes from './CustomersStyle.module.css';
import Header from './CustomersHeader';
import Body from './CustomersBody';

class CustomersBuilder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeLink: 'customers'
        }
    }

    onHeaderChange = (data) => {
        this.setState((state) => ({activeLink: data}))
    }

    componentDidMount() {
        this.props.refreshCustomers();
        this.props.fetchCustomers();
        this.props.childChangedDoctor();

    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.changedDoctorKey === newProps.doctorDetail.uid) {
            this.props.checkStatusAccept();
        }
    }

    onChatButtonClick = (queued, openChat, owner, patientID, doctorID, conversationId, issue, category) => {
        const path = {
            pathname: '/chats',
            search: '',
            hash: '',
            state: {
                fromCustomers: true, queued: queued, openChat: openChat, owner: owner,
                patientID: patientID, doctorID: doctorID, conversationId: conversationId,
                issue: issue, category: category
            }
        }
        this.props.history.push(path);
    }

    render() {
        return (
            <Aux>
                <div className={classes.main}>
                    {this.props.acceptingChat &&
                    <div className={classes.header}>Customers</div>
                    }
                    {this.props.acceptingChat === false &&
                    <div className={classes.header_red}>You are currently not accepting chats</div>
                    }
                    <div className={classes.body}>
                        <Header HeaderItem={(data) => this.onHeaderChange(data)} activeLink={this.state.activeLink}/>
                        <Body
                            customersData={this.props.customersData}
                            activeLink={this.state.activeLink}
                            onChatButtonClick={(queued, openChat, chatOwner, patientID, doctorID, conversationId,
                                                issue, category) => this.onChatButtonClick(queued, openChat, chatOwner, patientID,
                                doctorID, conversationId, issue, category)}
                        />
                    </div>
                </div>
            </Aux>
        )
    }
}

const mapStateToProps = ({customersReducer, conversationReducer, acceptingReducer, doctorReducer}) => {
    const {customersData} = customersReducer;
    const {acceptingChat, changedDoctorKey} = acceptingReducer
    const {doctorDetail} = doctorReducer;

    return {customersData, acceptingChat, doctorDetail, changedDoctorKey};
}

export default connect(mapStateToProps, {
    refreshCustomers,
    fetchCustomers,
    childChangedDoctor,
    checkStatusAccept
})(CustomersBuilder);
