import {
    CLINICAL_CANNED_RESPONSE_DELETE,
    CLINICAL_CANNED_RESPONSE_DESCRIPTION,
    CLINICAL_CANNED_RESPONSE_SAVE,
    CLINICAL_CANNED_RESPONSE_SHORT_CUT,
    CLINICAL_CANNED_RESPONSE_UPDATE,
    CLINICAL_FETCH_CANNED_RESPONSE_ASSESSMENT,
    CLINICAL_FETCH_CANNED_RESPONSE_PLAN,
    CLINICAL_FETCH_CHAT_CANNED_RESPONSE,
    SET_ASSESSMENT,
    SET_FINDINGS,
    SET_PLAN,
    SET_SUBJECTIVE_SYMPTOMS
} from '../constants';

import _ from 'lodash';
import {auth, database} from "../firebase";
let callbackSymptoms = null, callbackFindings = null, callbackAssesment = null, callbackPlan = null, cannedChatsListener = null, cannedFindingsListener = null

export const canned_response_description_changed = (text) => {
    return {
        type: CLINICAL_CANNED_RESPONSE_DESCRIPTION,
        payload: text
    }
}

export const canned_response_shortcut_changed = (text) => {
    return {
        type: CLINICAL_CANNED_RESPONSE_SHORT_CUT,
        payload: text
    }
}

export const canned_response_save = (text, shortcut, state) => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        getDoctorRef(currentUserID).once('value', doctorSnap => {
            if (doctorSnap.val()) {

                var doctorClinicName = doctorSnap.val().clinicName.toLowerCase();
                var type =
                    state === 'Canned Responses for Chats' ? 'chats' :
                        state === 'Canned Responses for Objective Finding' ? 'findings' :
                            state === 'Canned Responses for Assessment' ? 'assessment' : 'plan';

                let cannedResponseValue = {
                    text: text,
                    shortcut: shortcut,
                }
                let cannedresponsekey = getCannedResponseRef(doctorClinicName, type).push().key;
                var updates = {};
                updates[`/clinics_canned_responses/${doctorClinicName}/${type}/${cannedresponsekey}`] = cannedResponseValue;
                database.ref().update(updates)
                    .then(() => {
                        dispatch({
                            type: CLINICAL_CANNED_RESPONSE_SAVE
                        })
                    })
                    .catch((error) => {
                        dispatch({
                            type: CLINICAL_CANNED_RESPONSE_SAVE
                        })
                    })
            }

        });
    }
}

export const fetchCannedResponsesForChats = () => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        getDoctorRef(currentUserID).once('value', doctorSnap => {
            if (doctorSnap.val()) {
                var doctorClinicName = doctorSnap.val().clinicName.toLowerCase();
                if(cannedChatsListener){
                    cannedChatsListener.off();
                    cannedChatsListener = null
                }
                cannedChatsListener = getCannedResponseRef(doctorClinicName, 'chats')
                cannedChatsListener.on('value', cannedResponsesSnap => {
                    if (cannedResponsesSnap.val()) {
                        const cannedresponses = _.map(cannedResponsesSnap.val(), (val, uid) => {
                            val.shortcut = '#' + (val.shortcut);
                            return {...val, uid};
                        });

                        dispatch({
                            type: CLINICAL_FETCH_CHAT_CANNED_RESPONSE,
                            payload: cannedresponses
                        })
                    } else {
                        dispatch({
                            type: CLINICAL_FETCH_CHAT_CANNED_RESPONSE,
                            payload: []
                        })
                    }
                })
            }
        });
    }
}

export const fetchCannedResponsesForObjectiveFindings = () => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        getDoctorRef(currentUserID).once('value', doctorSnap => {
            if (doctorSnap.val()) {
                var doctorClinicName = doctorSnap.val().clinicName.toLowerCase();
                if(cannedFindingsListener){
                    cannedFindingsListener.off();
                    cannedFindingsListener = null
                }
                cannedFindingsListener = getCannedResponseRef(doctorClinicName, 'findings')
                cannedFindingsListener.on('value', cannedResponsesSnap => {
                    if (cannedResponsesSnap.val()) {
                        const cannedresponses = _.map(cannedResponsesSnap.val(), (val, uid) => {
                            val.shortcut = '#' + (val.shortcut);
                            return {...val, uid};
                        });

                        dispatch({
                            type: CLINICAL_FETCH_CANNED_RESPONSE_ASSESSMENT,
                            payload: cannedresponses
                        })
                    } else {
                        dispatch({
                            type: CLINICAL_FETCH_CANNED_RESPONSE_ASSESSMENT,
                            payload: []
                        })
                    }
                })
            }
        });
    }
}

export const fetchCannedResponsesForAssessment = () => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        getDoctorRef(currentUserID).once('value', doctorSnap => {
            if (doctorSnap.val()) {
                var doctorClinicName = doctorSnap.val().clinicName.toLowerCase();
                getCannedResponseRef(doctorClinicName, 'assessment').on('value', cannedResponsesSnap => {
                    if (cannedResponsesSnap.val()) {
                        const cannedresponses = _.map(cannedResponsesSnap.val(), (val, uid) => {
                            val.shortcut = '#' + (val.shortcut);
                            return {...val, uid};
                        });

                        dispatch({
                            type: CLINICAL_FETCH_CANNED_RESPONSE_ASSESSMENT,
                            payload: cannedresponses
                        })
                    } else {
                        dispatch({
                            type: CLINICAL_FETCH_CANNED_RESPONSE_ASSESSMENT,
                            payload: []
                        })
                    }
                })
            }
        });
    }
}

export const fetchCannedResponsesForPlan = () => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        getDoctorRef(currentUserID).once('value', doctorSnap => {
            if (doctorSnap.val()) {
                var doctorClinicName = doctorSnap.val().clinicName.toLowerCase();
                getCannedResponseRef(doctorClinicName, 'plan').on('value', cannedResponsesSnap => {
                    if (cannedResponsesSnap.val()) {
                        const cannedresponses = _.map(cannedResponsesSnap.val(), (val, uid) => {
                            val.shortcut = '#' + (val.shortcut);
                            return {...val, uid};
                        });

                        dispatch({
                            type: CLINICAL_FETCH_CANNED_RESPONSE_PLAN,
                            payload: cannedresponses
                        })
                    } else {
                        dispatch({
                            type: CLINICAL_FETCH_CANNED_RESPONSE_PLAN,
                            payload: []
                        })
                    }
                })
            }
        });
    }
}

export const updateCannedResponse = (uid, desc, shortcut, state, callback) => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        getDoctorRef(currentUserID).once('value', doctorSnap => {
            if (doctorSnap.val()) {
                var doctorClinicName = doctorSnap.val().clinicName.toLowerCase();
                let cannedResponseValue = {
                    text: desc,
                    shortcut: shortcut,
                }
                var type =
                    state === 'Canned Responses for Chats' ? 'chats' :
                        state === 'Canned Responses for Objective Finding' ? 'findings' :
                            state === 'Canned Responses for Assessment' ? 'assessment' : 'plan';

                getCannedResponseUidRef(doctorClinicName, uid, type).update(cannedResponseValue)
                    .then(() => {
                        callback(true);
                        dispatch({
                            type: CLINICAL_CANNED_RESPONSE_UPDATE
                        })
                    })
                    .catch((error) => {
                        callback(false);
                        dispatch({
                            type: CLINICAL_CANNED_RESPONSE_UPDATE
                        })
                    })
            }
        });
    }
}

export const deleteCannedResponse = (uid, state) => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        getDoctorRef(currentUserID).once('value', doctorSnap => {
            if (doctorSnap.val()) {
                var doctorClinicName = doctorSnap.val().clinicName.toLowerCase();
                var type =
                    state === 'Canned Responses for Chats' ? 'chats' :
                        state === 'Canned Responses for Objective Finding' ? 'findings' :
                            state === 'Canned Responses for Assessment' ? 'assessment' : 'plan';

                getCannedResponseUidRef(doctorClinicName, uid, type).remove()
                    .then(() => {
                        dispatch({
                            type: CLINICAL_CANNED_RESPONSE_DELETE
                        })
                    })
                    .catch((error) => {
                        dispatch({
                            type: CLINICAL_CANNED_RESPONSE_DELETE
                        })
                    })
            }
        });
    }
}

export const setSubjectiveSymptoms = (conversationID) => {
    return dispatch => {
        if (callbackSymptoms) {
            callbackSymptoms.off()
            callbackSymptoms=null
        }
        callbackSymptoms = getOpenConversationsSymptomsWithLocationRef(
            conversationID
        );
        callbackSymptoms.on("value", snapshot => {
            let Symptoms = ""
            //console.log("getOpenConversationsSymptomsWithLocationRef snapshot ", snapshot.val())

            if (snapshot.val()) {
                let Symptoms = snapshot.val()
                dispatch({
                    type: SET_SUBJECTIVE_SYMPTOMS,
                    payload: Symptoms,
                });
            } else {
                dispatch({
                    type: SET_SUBJECTIVE_SYMPTOMS,
                    payload: Symptoms,
                });
            }
            // console.log("fetchChat symptoms", Symptoms)
        });
    }
}

export const setFindings = (conversationID) => {
    return dispatch => {
        if (callbackFindings) {
            callbackFindings.off()
            callbackFindings=null
        }
        callbackFindings = getOpenConversationsFindingsWithLocationRef(
            conversationID
        );
        callbackFindings.on("value", snapshot => {
            let Findings = ""
            //console.log("getOpenConversationsFindingsWithLocationRef snapshot ", snapshot.val())

            if (snapshot.val()) {
                let Findings = snapshot.val()
                dispatch({
                    type: SET_FINDINGS,
                    payload: Findings,
                });
            } else {
                dispatch({
                    type: SET_FINDINGS,
                    payload: Findings,
                });
            }
            // console.log("fetchChat findings", Findings)
        });
    }
}

export const setAssessment = (conversationID) => {
    return dispatch => {
        if (callbackAssesment) {
            callbackAssesment.off()
            callbackAssesment=null
        }
        callbackAssesment = getOpenConversationsAssesmentWithLocationRef(
            conversationID
        );
        callbackAssesment.on("value", snapshot => {
            let Assesment = ""
            //console.log("getOpenConversationsAssesmentWithLocationRef snapshot ", snapshot.val())

            if (snapshot.val()) {
                let Assesment = snapshot.val()
                dispatch({
                    type: SET_ASSESSMENT,
                    payload: Assesment,
                });
            } else {
                dispatch({
                    type: SET_ASSESSMENT,
                    payload: Assesment,
                });
            }
            // console.log("fetchChat assesment", Assesment)
        });
    }
}

export const setPlan = (conversationID) => {
    return dispatch => {
        if (callbackPlan) {
            callbackPlan.off()
            callbackPlan=null
        }
        callbackPlan = getOpenConversationsPlanWithLocationRef(
            conversationID
        );
        callbackPlan.on("value", snapshot => {
            let Plan = ""
            //console.log("getOpenConversationsPlanWithLocationRef snapshot ", snapshot.val())

            if (snapshot.val()) {
                let Plan = snapshot.val()
                dispatch({
                    type: SET_PLAN,
                    payload: Plan,
                });
            } else {
                dispatch({
                    type: SET_PLAN,
                    payload: Plan,
                });
            }
            // console.log("fetchChat plan ", Plan)
        });
    }
}

const getCannedResponseRef = (doctorClinicName, type) => {
    return database.ref().child(`clinics_canned_responses/${doctorClinicName}/${type}`);
}

const getCannedResponseUidRef = (doctorClinicName, uid, type) => {
    return database.ref(`/clinics_canned_responses/${doctorClinicName}/${type}/${uid}`);
}

const getDoctorRef = (uid) => {
    return database.ref(`/doctors/${uid}`);
}

const getOpenConversationsSymptomsWithLocationRef = (uid) => {
    return database.ref(`/openClinicalConversations/${uid}/symptoms`);
};

const getOpenConversationsFindingsWithLocationRef = (uid) => {
    return database.ref(`/openClinicalConversations/${uid}/findings`);
};

const getOpenConversationsAssesmentWithLocationRef = (uid) => {
    return database.ref(`/openClinicalConversations/${uid}/assessment`);
};

const getOpenConversationsPlanWithLocationRef = (uid) => {
    return database.ref(`/openClinicalConversations/${uid}/plan`);
};
