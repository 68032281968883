import {Component, createRef, Fragment} from 'react';
import {connect} from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import csc from 'country-state-city';

import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import classes from '../../../assets/classes.module.css';
import _classes from '../../../assets/_classes.module.css';
import {
    ClinicAddress,
    ClinicalCity,
    ClinicalPostalCode,
    ClinicCountry,
    ClinicDrBillingNo,
    ClinicEmailAddress,
    ClinicFax,
    ClinicLicense,
    ClinicName,
    ClinicPhone,
    ClinicPhysicianUser,
    ClinicProvince,
    ClinicReportEmailAddress,
    FullName,
    setUpClinicProfile,
    showClinicAdminProfileImage,
    showClinicAdminSignature
} from '../../../actions/clinic/create/CreateClinicAction';

import {forgotPassword} from '../../../actions/LoginAction';
import isMobileView from '../../../utils/isMobileView';

function InputItem(props) {
    return (
        <div style={{display: 'flex', flexDirection: 'column', margin: '10px 0px'}}><label
            className={_classes.inputItemLabel}>{props.label}</label>
            {Boolean(props.options) ? <select
                    className={_classes.inputItem}
                    {...props}
                    placeholder={props.placeholder}
                    value={props.value || ''}
                    onChange={(e) => props.onChange(e)}
                >
                    {props.options.options.map(option => (
                        <option key={option.id} id={option.id} value={option.name}>
                            {option.name}
                        </option>
                    ))}
                </select> :
                props.type === 'file' ?
                    null :
                    <input className={_classes.inputItem} {...props} placeholder={props.placeholder}
                           value={props.value || ''}
                           onChange={(e) => props.onChange(e)}></input>}

        </div>
    );
}

class Admin extends Component {

    constructor(props) {
        super(props);
        this.textInput = createRef();
        this.textInput1 = createRef();
        this.signatureFile = createRef();
        this.state = {
            file: '',
            signature_file: '',
            showChangePasswordModal: false,
            selectedItem: 'profile',
            profile_value: 'Profile',
            countries: {'options': csc.getAllCountries()},
            provinces: {'options': csc.getStatesOfCountry("38")},
        }
    }

    componentWillMount() {
        if (this.props.country) {
            let selectedCountry = this.state.countries.options.filter(country => country.name === this.props.country)
            this.setState({provinces: {'options': csc.getStatesOfCountry(selectedCountry[0].id)}})
            //  this.props.ClinicProvince(this.props.province)

        }
    }

    componentDidMount() {

    }

    changeClinicName = (event) => {
        this.props.ClinicName(event.target.value);
    }

    changeFullName = (event) => {
        this.props.FullName(event.target.value);
    }

    changeAddress = (event) => {
        this.props.ClinicAddress(event.target.value);
    }

    changeProvince = (event) => {
        this.props.ClinicProvince(event.target.value);
    }

    changeCountry = (event) => {
        this.props.ClinicCountry(event.target.value);
        let selectedCountry = this.state.countries.options.filter(country => country.name === event.target.value)
        this.setState({provinces: {'options': csc.getStatesOfCountry(selectedCountry[0].id)}})
    }

    changePhone = (event) => {
        this.props.ClinicPhone(event.target.value);
    }

    changeFax = (event) => {
        this.props.ClinicFax(event.target.value);
    }

    changeFax = (event) => {
        this.props.ClinicFax(event.target.value);
    }

    changeEmailAddress = (event) => {
        this.props.ClinicEmailAddress(event.target.value);
    }

    changePhysician = (event) => {
        this.props.ClinicPhysicianUser(event.target.checked);
    }

    changeLicenseNo = (event) => {
        this.props.ClinicLicense(event.target.value);
    }

    changeBillingNo = (event) => {
        this.props.ClinicDrBillingNo(event.target.value);
    }
    changeReportEmail = (event) => {
        this.props.ClinicReportEmailAddress(event.target.value);
    }

    changeCity = (event) => {
        this.props.ClinicalCity(event.target.value);
    }

    changePostalCode = (event) => {
        this.props.ClinicalPostalCode(event.target.value);
    }

    onAttachmentClick = (e) => {
        e.current.click();
    }

    _handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            var type = file.type.split('/');
            this.props.showClinicAdminProfileImage(reader.result);
            this.setState({
                file: file,
            });
        }
        reader.readAsDataURL(file);
    }

    _handleSignatureChnage = (e) => {

        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            var type = file.type.split('/');
            this.props.showClinicAdminSignature(reader.result);
            this.setState({
                signature_file: file,
            });
        }

        reader.readAsDataURL(file);
    }


    onSaveClick = (event) => {
        event.preventDefault();
        this.props.setUpClinicProfile(
            this.state.file,
            this.state.signature_file,
            this.props.clinicId,
            this.props,
        );
        alert('Saved')
    }

    render() {
        var self = this

        const {
            clinicData,
            staffData,
            userEmail,
            clinic_name,
            full_name,
            address,
            province,
            city,
            country,
            phone,
            postal_code,
            fax,
            email_address,
            license_no,
            dr_billing_no,
            profile_image,
            signature,
            clinic_email_address,
            loading,
            message,
            physician_user,
            userType,
            userProfile,
            loader,
            msg,
            err
        } = this.props;

        let {selectedItem, countries, provinces, showChangePasswordModal} = this.state;
        return (
            !isMobileView() ? <div className={classes.main_container}>
                <div className={classes.main_container_header}>Personalize your profile</div>
                <div className={classes.form_container_view}>
                    <div className={classes.form_row}>
                        <span className={classes.label}>Clinic name: </span>
                        <OverlayTrigger
                            key={'right'}
                            placement={'right'}
                            overlay={
                                <Tooltip id={`tooltip-left`}>The clinic name will be seen by your staff and
                                    patients.</Tooltip>
                            }
                        >
                            <input
                                className={classes.form_input}
                                value={clinic_name}
                                onChange={(event) => this.changeClinicName(event)}
                            />
                        </OverlayTrigger>
                    </div>
                    <div className={classes.form_row}>
                        <span className={classes.label}>Email: </span>
                        <OverlayTrigger
                            key={'right'}
                            placement={'right'}
                            overlay={
                                <Tooltip id={`tooltip-left`}>Your email for login.</Tooltip>
                            }
                        >
                            <input
                                className={classes.form_input}
                                value={staffData && staffData.staffEmail ? staffData.staffEmail : staffData.email}
                                readOnly
                            />
                        </OverlayTrigger>
                    </div>
                    <div className={classes.form_row}>
                        <span className={classes.label}>Full name: </span>
                        <OverlayTrigger
                            key={'right'}
                            placement={'right'}
                            overlay={
                                <Tooltip id={`tooltip-left`}>Your full name that patients will be seeing.</Tooltip>
                            }
                        >
                            <input
                                className={classes.form_input}
                                value={full_name}
                                onChange={(event) => this.changeFullName(event)}
                            />
                        </OverlayTrigger>
                    </div>
                    <div className={classes.form_row}>
                        <span className={classes.label}>Address: </span>
                        <OverlayTrigger
                            key={'right'}
                            placement={'right'}
                            overlay={
                                <Tooltip id={`tooltip-left`}>This is the physical location of your clinic.</Tooltip>
                            }
                        >
                            <input
                                className={classes.form_input}
                                value={address}
                                onChange={(event) => this.changeAddress(event)}
                            />
                        </OverlayTrigger>
                    </div>
                    <div className={classes.form_row}>
                        <span className={classes.label}>Country: </span>
                        <OverlayTrigger
                            key={'left'}
                            placement={'left'}
                            overlay={
                                <Tooltip id={`tooltip-left`}>Country</Tooltip>
                            }
                        >
                            <select
                                className={classes.form_small_adjust_input}
                                placeholder="Country"
                                defaultValue={country}
                                onChange={(event) => this.changeCountry(event, country)}>
                                {countries.options.map(option => (
                                    <option key={option.id} id={option.id} value={option.name}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        </OverlayTrigger>
                        <span className={classes.label}>Province: </span>
                        <OverlayTrigger
                            key={'right'}
                            placement={'right'}
                            overlay={
                                <Tooltip id={`tooltip-left`}>Province</Tooltip>
                            }
                        >
                            <select
                                className={classes.form_small_adjust_input}
                                placeholder="Province"
                                defaultValue={province}

                                onChange={(event) => this.changeProvince(event, province)}>
                                {provinces.options.map(option => (
                                    <option key={option.id} id={option.id} value={option.name}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        </OverlayTrigger>
                    </div>
                    <div className={classes.form_row}>
                        <span className={classes.label}>City: </span>
                        <OverlayTrigger
                            key={'left'}
                            placement={'left'}
                            overlay={
                                <Tooltip id={`tooltip-left`}>City</Tooltip>
                            }
                        >
                            <input
                                className={classes.form_small_input}
                                value={city}
                                onChange={(event) => this.changeCity(event, province)}
                            />
                        </OverlayTrigger>
                        <span className={classes.label}>Postal Code: </span>
                        <OverlayTrigger
                            key={'right'}
                            placement={'right'}
                            overlay={
                                <Tooltip id={`tooltip-left`}>Postal or Zip code</Tooltip>
                            }
                        >
                            <input
                                className={classes.form_small_input}
                                value={postal_code}
                                onChange={(event) => this.changePostalCode(event, country)}
                            />
                        </OverlayTrigger>
                    </div>
                    <div className={classes.form_row}>
                        <span className={classes.label}>Clinic Phone Number: </span>
                        <OverlayTrigger
                            key={'left'}
                            placement={'left'}
                            overlay={
                                <Tooltip id={`tooltip-left`}>This is the phone number that will be used if patients have
                                    any inquiries. Please start with a + and a country code followed by your phone
                                    number. Ex +18774534777</Tooltip>
                            }
                        >
                            <input
                                className={classes.form_small_input}
                                value={phone}
                                onChange={(event) => this.changePhone(event, phone)}
                            />
                        </OverlayTrigger>
                        <span className={classes.label}>Clinic Fax Number: </span>
                        <OverlayTrigger
                            key={'right'}
                            placement={'right'}
                            overlay={
                                <Tooltip id={`tooltip-left`}>Please start with a + and a country code followed by your
                                    phone number. Ex +18774534777. If you don’t have one, please leave it
                                    blank</Tooltip>
                            }
                        >
                            <input
                                className={classes.form_small_input}
                                value={fax}
                                onChange={(event) => this.changeFax(event, fax)}
                            />
                        </OverlayTrigger>
                    </div>
                    <div className={classes.form_row}>
                        <span className={classes.label}>Clinic Email Address: </span>
                        <OverlayTrigger
                            key={'right'}
                            placement={'right'}
                            overlay={
                                <Tooltip id={`tooltip-left`}>A general inbox where patient inquiries can be directed
                                    to.</Tooltip>
                            }
                        >
                            <input
                                className={classes.form_input}
                                value={email_address}
                                onChange={(event) => this.changeEmailAddress(event)}
                            />
                        </OverlayTrigger>
                    </div>
                    <div className={classes.form_row}>
                        <span className={classes.label}>Password: </span>
                        <div className={classes.underline_label} onClick={() => {
                            this.props.onChangePassword(true)
                        }}>Reset Password
                        </div>
                    </div>
                    <div className={classes.form_row}>
                        <span className={classes.label}>Profile Picture: </span>
                        <div>
                            {(profile_image !== undefined && profile_image !== null) &&
                            <img
                                className={classes.image_container}
                                src={profile_image}
                                alt=""
                            />
                            }
                            <input
                                className={classes.hidden_input}
                                type="file"
                                accept="image/jpeg"
                                ref={this.textInput}
                                onChange={(e) => this._handleImageChange(e)}
                            />
                        </div>
                        <div className={classes.column_view}>
                            <div className={classes.underline_label}
                                 onClick={() => this.onAttachmentClick(this.textInput)}>Upload Picture
                            </div>
                            <span>Profile picture should be in a .jpg or .jpeg format with a maximum file size of 5MB.</span>
                        </div>
                    </div>
                    <div className={classes.form_row}>
                        <div className={classes.check_box}>
                            <input
                                type="checkbox"
                                checked={physician_user}
                                onChange={(event) => this.changePhysician(event)}
                            />
                            <span className={classes.span_style}> I’m a physician that will be seeing patients in this clinic </span>
                        </div>
                    </div>
                    {physician_user &&
                    <Fragment>
                        <div className={classes.form_row}>
                            <span className={classes.label}>License no: </span>
                            <OverlayTrigger
                                key={'right'}
                                placement={'right'}
                                overlay={
                                    <Tooltip id={`tooltip-left`}>This is your medical identification number that will be
                                        on SOAP notes, prescriptions, etc</Tooltip>
                                }
                            >
                                <input
                                    className={classes.form_input}
                                    value={license_no}
                                    onChange={(event) => this.changeLicenseNo(event, license_no)}
                                />
                            </OverlayTrigger>
                        </div>
                        <div className={classes.form_row}>
                            <span className={classes.label}>Billing No (Optional):</span>
                            <OverlayTrigger
                                key={'right'}
                                placement={'right'}
                                overlay={
                                    <Tooltip id={`tooltip-left`}></Tooltip>
                                }
                            >
                                <input
                                    className={classes.form_input}
                                    value={dr_billing_no}
                                    onChange={(event) => this.changeBillingNo(event, dr_billing_no)}
                                />
                            </OverlayTrigger>
                        </div>
                        <div className={classes.form_row}>
                            <span className={classes.label}>Upload Signature: </span>

                            <input
                                type="file"
                                accept="image/jpeg"
                                ref={this.signatureFile}
                                className={classes.hidden_input}
                                onChange={(e) => this._handleSignatureChnage(e)}
                            />

                            {(signature !== null && signature !== undefined) &&
                            <img
                                className={classes.signature_container}
                                src={signature}
                                alt=""
                            />
                            }

                            <div className={classes.column_view}>
                                <div className={classes.underline_label}
                                     onClick={() => this.onAttachmentClick(this.signatureFile)}>Upload Signature
                                </div>
                                <span>Signature should be in a .jpg or .jpeg format with a maximum file size of 5MB.</span>
                            </div>

                        </div>
                    </Fragment>
                    }
                    {loading &&
                    <div className={classes.center_view}>
                        <ClipLoader
                            size={100}
                            color={"#884a9d"}
                        />
                    </div>
                    }
                    {!loading &&
                    <div className={classes.button_row}>
                        <button className={classes.higlighted_button}
                                onClick={(event) => this.onSaveClick(event)}>Save
                        </button>
                    </div>
                    }
                    {message !== '' &&
                    <div className={classes.center_view}>
                        <span>{message}</span>
                    </div>
                    }
                </div>
            </div> : <div className={_classes._form} style={{
                'padding': '20px',
                'padding-bottom': '200px',
                'background-color': 'rgb(241,242,246)',
                'overflow-y': 'scroll',
                position: 'absolute',
                left: 0,
                top: 70,
                right: 0,
                height: '-webkit-fill-available',
                zIndex: 10
            }}>
                <InputItem label='Full Name:'
                           placeholder='Raihan Masroor'
                           property='fullName'
                           value={this.props.full_name}
                           onChange={(event) => this.changeFullName(event)}
                ></InputItem>

                <InputItem
                    label='License No:'
                    placeholder='123123123'
                    property='licenseNo'
                    value={this.props.license_no}
                    onChange={(event) => this.changeLicenseNo(event, license_no)}></InputItem>
                <InputItem
                    label='Billing No (Optional):'
                    placeholder='123123'
                    property='billingNo'
                    value={this.props.dr_billing_no}
                    onChange={(event) => this.changeBillingNo(event)}></InputItem>
                <InputItem
                    label='Address:'
                    placeholder='1055 Candian Place'
                    property='address'
                    value={this.props.address}
                    onChange={(event) => this.changeAddress(event)}
                ></InputItem>
                <InputItem
                    label='City:'
                    placeholder='Toronto'
                    property='city'
                    value={this.props.city}
                    onChange={(event) => this.changeCity(event)}></InputItem>
                <InputItem
                    label='Provice:'
                    placeholder='Ontario'
                    property='province'
                    options={this.state.provinces}
                    value={this.props.province} onChange={(event) => this.changeProvince(event, province)}></InputItem>
                <InputItem
                    label='Country:'
                    placeholder='Canada'
                    property='country'
                    value={this.props.country}
                    options={this.state.countries}
                    onChange={(event) => this.changeCountry(event, country)}></InputItem>
                <InputItem
                    label='Phone:'
                    placeholder='123123123'
                    property='phoneNo'
                    value={this.props.phone}
                    onChange={(event) => this.changePhone(event, phone)}
                ></InputItem>
                <InputItem label='Fax:'
                           placeholder='Canada'
                           property='fax'
                           value={this.props.fax}
                           onChange={(event) => this.changeFax(event, fax)}
                ></InputItem>
                <InputItem
                    label='Profile Picture:'
                    type="file"
                    accept="image/jpeg"
                    ref={this.textInput}
                    // className={classes.hidden_input}
                    onChange={(e) => this._handleImageChange(e)}
                ></InputItem>
                {(Boolean(profile_image)) &&
                <img
                    className={classes.image_container}
                    src={profile_image}
                    alt=""
                    style={{'margin-left': '20px'}}
                />
                }
                <input
                    className={classes.hidden_input}
                    type="file"
                    accept="image/jpeg"
                    ref={this.textInput}
                    onChange={(e) => this._handleImageChange(e)}
                />
                <div style={{display: 'flex', flexDirection: 'column', margin: '10px 0px'}}>

                    {/* <label className={_classes.inputItemLabel} >Profile Picture:</label > */}
                    <label className={_classes.inputItemLabel} style={{
                        textDecorationLine: 'underline',
                        color: 'black',
                        'margin-top': '5px',
                        'margin-bottom': '5px'
                    }} onClick={() => this.onAttachmentClick(this.textInput)}>Upload Picture</label>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', margin: '10px 0px'}}>
                    <label className={_classes.inputItemLabel}>Signature:</label>

                    <input
                        type="file"
                        accept="image/jpeg"
                        ref={this.signatureFile}
                        className={classes.hidden_input}
                        onChange={(e) => this._handleSignatureChnage(e)}
                    />

                    {(signature !== null && signature !== undefined) &&
                    <img
                        className={classes.signature_container}
                        src={signature}
                        alt=""
                        style={{'margin-left': '20px'}}
                    />
                    }
                    <label className={_classes.inputItemLabel} style={{
                        textDecorationLine: 'underline',
                        color: 'black',
                        'margin-top': '5px',
                        'margin-bottom': '5px'
                    }} onClick={() => this.onAttachmentClick(this.signatureFile)}>Upload Signature</label>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    justifyItems: 'center',
                    alignItems: 'center',
                    margin: '20px 10px'
                }}>
                    <button style={{
                        width: '50%',
                        height: '40px',
                        backgroundColor: '#fff',
                        borderRadius: '5px',
                        backgroundColor: "#884a9d",
                        border: '1px',
                        color: '#fff',
                        fontSize: '14px'
                    }}
                            title="Confirm"
                            onClick={(event) => this.onSaveClick(event)}>Save
                    </button>
                </div>

            </div>
        )
    }
}

const mapStateToProps = ({clinicProfileSettingReducer, forgotPasswordReducer, clinicBillingCodesReducer}) => {
    const {
        clinicData,
        staffData,
        userEmail,
        clinicId,
        clinic_name,
        full_name,
        address,
        province,
        city,
        country,
        phone,
        postal_code,
        fax,
        email_address,
        license_no,
        dr_billing_no,
        profile_image,
        signature,
        clinic_email_address,
        physician_user,
        loading,
        message,
        userType,
        userProfile
    } = clinicProfileSettingReducer;
    const {billing_code_value} = clinicBillingCodesReducer

    const {loader, msg, err} = forgotPasswordReducer;

    return {
        clinicData,
        staffData,
        userEmail,
        clinicId,
        clinic_name,
        full_name,
        address,
        province,
        city,
        country,
        phone,
        postal_code,
        fax,
        email_address,
        license_no,
        dr_billing_no,
        profile_image,
        signature,
        clinic_email_address,
        physician_user,
        loading,
        message,
        userType,
        userProfile,
        loader,
        msg,
        err,
        billing_code_value
    };
};

export default connect(mapStateToProps, {
    forgotPassword,
    ClinicName,
    FullName,
    ClinicAddress,
    ClinicProvince,
    ClinicalCity,
    ClinicalPostalCode,
    ClinicCountry,
    ClinicPhone,
    ClinicFax,
    ClinicEmailAddress,
    ClinicLicense,
    ClinicReportEmailAddress,
    showClinicAdminProfileImage,
    showClinicAdminSignature,
    setUpClinicProfile,
    ClinicPhysicianUser,
    ClinicDrBillingNo
})(Admin);
