
import classes from './TicketsBuilder.module.css';

const AgentList = (props) => {
    return (
        <li onClick={() => props.onAssignClick(props.doctor)}>
            <div className={classes.main_assign_view}>
                <div className={classes.main_assign_inner_view}>
                    <img
                        alt="//cdn.livechatinc.com/cloud/?uri=https://livechat.s3.amazonaws.com/default/avatars/female_44.jpg"
                        src="//cdn.livechatinc.com/cloud/?uri=https://livechat.s3.amazonaws.com/default/avatars/female_44.jpg"/>
                    <div className={classes.main_assign_content}>
                        <div className={classes.name_text}>{props.name}</div>
                        <div className={classes.email_text}>{props.email}</div>
                    </div>
                </div>
            </div>
        </li>
    )
}

const AddTicketForm = (props) => {
    const agentslist = props.agentsData.map(agent => {
        if (agent !== undefined) {
            return <AgentList
                key={agent.uid}
                doctor={agent}
                name={agent.doctorName}
                email={agent.email}
                onAssignClick={(agent) => props.onAssignClick(agent)}
            />
        }
        return null;
    });

    return (
        <form className={classes.form_body}>
            <div className={classes.lc_text_field}>
                <label className={classes.lc_field_label}>Subject</label>
                <input
                    type="text"
                    placeholder="Subject..."
                    value={props.subjectValue}
                    required
                    onChange={(text) => props.onSubjectChange(text)}
                />
            </div>

            <div className={classes.row}>
                <div className={classes.lc_req_field}>
                    <label className={classes.lc_field_label}>Requester</label>
                    <input
                        placeholder="Name"
                        type="text"
                        value={props.nameValue}
                        required
                        onChange={(text) => props.onRequesterNameChange(text)}
                    />
                </div>
                <div className={classes.lc_em_field}>
                    <input
                        placeholder="Email"
                        type="email"
                        value={props.emailValue}
                        required
                        onChange={(email) => props.onRequesterEmailChange(email)}
                    />
                </div>
            </div>

            <div className={classes.lc_assign_field} onClick={() => props.showDoctorslist()}>
                <div className={classes.lc_assign_label}>
                    <div className={classes.lc_assign_label_text}>Assignee</div>
                </div>
                {props.showDoctorData &&
                <div className={classes.one}>
                    <div className={classes.assign_list_view}>
                        <div className={classes.main_assign_list}>
                            <ul>
                                {agentslist}
                            </ul>
                        </div>
                    </div>
                </div>
                }
                {props.selectedAssign.agentName === undefined &&
                <div className={classes.lc_assign_req_field}>
                    <div className={classes.lc_assign_req_img}>
                        <img src="//cdn.livechatinc.com/s3/default/avatars/ab5b0666feffd67600206cd519fd77ea.jpg"
                             alt=""/>
                    </div>
                    <div className={classes.lc_assign_req_search}>
                        <span>Unassigned</span>
                    </div>
                </div>
                }

                {props.selectedAssign.agentName !== undefined &&
                <div className={classes.lc_assign_req_field}>
                    <div className={classes.lc_assign_req_img}>
                        <img src="//cdn.livechatinc.com/s3/default/avatars/ab5b0666feffd67600206cd519fd77ea.jpg"
                             alt=""/>
                    </div>
                    <div className={classes.lc_assign_req_search}>
                        <span>{props.selectedAssign.agentName}</span>
                        <span>{props.selectedAssign.agentEmail}</span>
                    </div>
                </div>
                }
            </div>

            <div className={classes.lc_assign_field}>
                <div className={classes.lc_msg_field}>
                    <div className={classes.lc_msg_row_field}>
                        {props.filename !== '' &&
                        <div className={classes.file_text}>
                            <span className={classes.file_text_span}>{props.filename}</span>
                            <span className={classes.remove_span} onClick={() => props.removeFile()}>remove</span>
                        </div>
                        }
                        <div className={classes.lc_msg_field_input}>
                            <textarea
                                placeholder="Type a message"
                                value={props.messageValue}
                                required
                                onChange={(text) => props.onMessageChange(text)}
                            >
                            </textarea>
                        </div>
                        <div className={classes.lc_msg_field_tag}>
                            <div className={classes.lc_msg_field_tag_view}>
                                <div className={classes.canned_responses}>
                                    <div className={classes.canned_responses_icon}>
                                        <svg fill="#424d57" width="16px" height="16px" viewBox="0 0 13 14">
                                            <g fill="inherit">
                                                <path
                                                    d="M11.4800171,9.8 L1.68001709,9.8 C1.26001709,9.8 0.98001709,9.52 0.98001709,9.1 C0.98001709,8.68 1.26001709,8.4 1.68001709,8.4 L11.4800171,8.4 C11.9000171,8.4 12.1800171,8.68 12.1800171,9.1 C12.1800171,9.52 11.9000171,9.8 11.4800171,9.8 Z"></path>
                                                <path
                                                    d="M12.4599915,5.88001709 L2.65999146,5.88001709 C2.23999146,5.88001709 1.95999146,5.60001709 1.95999146,5.18001709 C1.95999146,4.76001709 2.23999146,4.48001709 2.65999146,4.48001709 L12.4599915,4.48001709 C12.8799915,4.48001709 13.1599915,4.76001709 13.1599915,5.18001709 C13.1599915,5.60001709 12.8799915,5.88001709 12.4599915,5.88001709 Z"></path>
                                                <path
                                                    d="M14.7,7.7 L3.5,7.7 C3.08,7.7 2.8,7.42 2.8,7 C2.8,6.58 3.08,6.3 3.5,6.3 L14.7,6.3 C15.12,6.3 15.4,6.58 15.4,7 C15.4,7.42 15.12,7.7 14.7,7.7 Z"
                                                    transform="translate(9.100000, 7.000000) rotate(99.000000) translate(-9.100000, -7.000000)"></path>
                                                <path
                                                    d="M10.5,7.7 L-0.7,7.7 C-1.12,7.7 -1.4,7.42 -1.4,7 C-1.4,6.58 -1.12,6.3 -0.7,6.3 L10.5,6.3 C10.92,6.3 11.2,6.58 11.2,7 C11.2,7.42 10.92,7.7 10.5,7.7 Z"
                                                    transform="translate(4.900000, 7.000000) rotate(99.000000) translate(-4.900000, -7.000000)"></path>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                <div className={classes.canned_responses}
                                     onClick={() => props.onAttachmentClick(props.inputElement)}>
                                    <input
                                        type="file"
                                        ref={props.inputElement}
                                        onChange={(e) => props._handleImageChange(e)}
                                    />
                                    <div className={classes.canned_responses_icon}>
                                        <svg fill="#424d57" width="18px" height="18px" viewBox="0 0 8 16">
                                            <g>
                                                <path fill="inherit"
                                                      d="M7 4v7.667a2.666 2.666 0 1 1-5.333 0V3.333a1.667 1.667 0 0 1 3.333 0v7c0 .367-.3.667-.667.667a.669.669 0 0 1-.666-.667V4h-1v6.333a1.667 1.667 0 0 0 3.333 0v-7a2.666 2.666 0 1 0-5.333 0v8.334a3.665 3.665 0 0 0 3.666 3.666A3.665 3.665 0 0 0 8 11.667V4H7z"></path>
                                                <path fill="#ffffff" fillOpacity="0" d="M-4 0h16v16H-4z"></path>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.lc_msg_field_button_view}>
                                <button type="button" onClick={(event) => props.onSendPress(event)}>Send</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span>{props.error}</span>
        </form>
    )
}

export default AddTicketForm;
