import  {useEffect, useState} from 'react'
import classes from "./chatstats.module.css"

import {Table} from "react-bootstrap";
import axios from "axios";
import moment from 'moment';
import {database} from "../../firebase";
const ChatStatistics = (props) => {
    const [conversationRows,setConversationRows] = useState([])
    const [chatCategories,setChatCategories] = useState([])
    const [remainingChats, setRemainingChats] = useState([])
    const [startDate,setStartDate] = useState(null)
    const [endDate,setEndDate] = useState(null)

    const getPatientIDFromServer = (userID, callback) => {
        axios({
            method: 'GET',
            url: `https://admin.yourdoctors.online/api/0.1/patients/${userID}`,
            mode: 'cors',
        })
            .then(response => {
                //console.log('patients response', response)
                callback(response?.data?.patient?.id || null)

            })
            .catch(error => {
                callback(null)
                //console.log('patients error', error)
            })
    }
    const fetchPatientID = (email, userId, patientId, callback)=>{
        if(patientId){
            callback(patientId);
            return;
        }
        if(userId){
            getPatientIDFromServer(userId, (patID)=>{
                callback(patID)
            })
            return
        }
        if(email){

            axios({
                method: 'GET',
                url: `https://admin.yourdoctors.online/api/0.1/admin_users/getuser?email=${encodeURIComponent(email)}`,
                mode: 'cors'
            })
                .then(response => {
                    //console.log('patients response', response)
                    let uId = response?.data?.user?.id || null
                    if(uId){
                        getPatientIDFromServer(uId, (patID)=>{
                            callback(patID)
                        })
                    }else{
                        callback(null)
                    }
                    //callback(response?.data?.patient?.id || null)

                })
                .catch(error => {
                    callback(null)
                    //console.log('patients error', error)
                })
            return
        }
        callback( null)
    }



    useEffect(()=>{
        //console.log("remainingChats ", remainingChats);
        //console.log("remainingChats.length ", remainingChats.length);
        return;
        if(remainingChats.length>0){
            remainingChats.forEach(chat=>{
                let chatId = chat?.conversation_id;
                let issue = chat?.issue;
                let category = chat?.category;
                let createdAt = moment(chat?.timestamp).format('YYYY-MM-DD hh:mm:ss')
                axios
                    .get(
                        `https://doctor-services.yourdoctors.online/findConversationInChatCategory?conversationId=${chatId}`,
                    )
                    .then((response) => {
                        //console.log('response?.data ', response?.data);

                        if (response?.data?.length < 1) {
                            database.ref(`users/${chat.patientID}`).once('value', userSnap=>{
                                if(userSnap.exists()){
                                    let email = userSnap.val().email;
                                    let userId = userSnap.val().user_id;
                                    let patientId = userSnap.val().patientId;
                                    fetchPatientID(email, userId, patientId, (patientId)=>{

                                        if(patientId){
                                            database.ref(`doctors/${chat.doctorID}`).once('value', doctorSnap=>{
                                                let DoctorName = doctorSnap.val()?.doctorName || '';
                                                let url = `https://doctor-services.yourdoctors.online/saveChatCategory?category=${encodeURIComponent(category)}&issue=${encodeURIComponent(issue)}&patientId=${encodeURIComponent(patientId)}&chatId=${encodeURIComponent(chatId)}&DoctorName=${encodeURIComponent(DoctorName)}&createdAt=${encodeURIComponent(createdAt)}`
                                                //console.log("chat saved url", url)

                                                axios({
                                                    method: 'GET',
                                                    url: url,
                                                    mode: 'cors'
                                                })
                                                    .then(response => {
                                                        //console.log("chat saved ", response)
                                                    })
                                                    .catch(error=>{
                                                        //console.log("chat saved error", error)
                                                    })
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    }).catch(e=> {
                    //console.log("findConversationInChatCategory e", e)
                })


            })

        }
    },[remainingChats])

    useEffect(()=>{
        let today = moment();
        setEndDate(today.format('YYYY-MM-DD'))
        //console.log("today.format('yyyy-mm-dd') ", today.format('YYYY-MM-DD'))
        today.subtract(1, 'day')
        //today.setDate(new Date().getDate()-1)
        setStartDate(today.format('YYYY-MM-DD'))
    },[])

    useEffect(()=>{
        //console.log("startDate ", startDate)
        //console.log("endDate ", endDate)

    }, [startDate, endDate])

    const tableHead = (key) => {
        return(
            <thead>
            <tr>
                <th>#</th>
                <th>{key || "ConversationID"}</th>
                <th>Status</th>
            </tr>
            </thead>
        )
    }

    const renderTable = (data, key) => {
        return (<Table striped bordered hover>
            {tableHead(key)}
            <tbody>
            {data.map((row, index) =>{
                if(row.status || (key && !row.chatId)) return<></>
                return(<tr onClick={()=> navigator.clipboard.writeText(JSON.stringify(row))}>
                    <td>{index+1}</td>
                    <td>{row.conversation_id || row.chatId}</td>
                    <td>{row.status}</td>
                </tr>)
            })}


            </tbody>
        </Table>)
    }

    /*const storeEMR = () => {
        console.log("storeEMR clicked ")
        database.ref(`emrEncounters/`).orderByChild('emr_saved').equalTo(null).once('value', encountersSnap => {
            let count = encountersSnap.numChildren()
            console.log("emrEncounters count", count)
            setTotalUnSavedConversationCount(count)
            console.log("encountersSnap json", JSON.stringify(encountersSnap.val()))
            let index = 0;
            if(count>0){
                encountersSnap.forEach(encounter => {

                    setTimeout(()=>{
                        console.log("emrEncounters index", index)

                        if(encounter.val()){
                            let encounterData = encounter.val();
                            encounterData.conversation_id = encounter.key;
                            console.log("emrEncounters encounterData", encounterData)
                            if(!encounterData.emr_saved){
                                fetch('https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/storeEMRDataForTier1', {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify(encounterData),
                                })
                                .then(data => {
                                    let newConversationRows = conversationRows
                                    let newConversation = {
                                        conversation_id:encounter.key,
                                        data: encounterData
                                    }
                                    newConversation.status = data.status === 200? "success":"failed"
                                    if(data.status === 400) setTotalFailedConversationCount(totalFailedConversationCount)
                                    newConversationRows.push(newConversation)
                                    setConversationRows([...newConversationRows])
                                    console.log("emrEncounters  successful", " ", encounter.key, " ", data)
                                })
                                .catch(error => {
                                    let newConversationRows = conversationRows
                                    let newConversation = {
                                        conversation_id:encounter.key,
                                        status: "failed",
                                        data: encounterData
                                    }
                                    newConversationRows.push(newConversation)
                                    setConversationRows([...newConversationRows])
                                    setTotalFailedConversationCount(totalFailedConversationCount)
                                    console.log("emrEncounters failed", error)
                                })
                            }
                        }
                    }, index * 6000)
                    index++;

                })
            }
        }, error =>{
            console.log("emrEncounters error", error)

        })
    }*/

    const fetchRecord = () => {
        //console.log("start date", (new Date(startDate)).getTime());
        //console.log("end date", (new Date(endDate)).getTime());
        let start = new Date(startDate).getTime();
        let end = new Date(endDate).getTime();
        //database.ref(`archiveConversations/`).orderByKey('timestamp')
        database.ref(`archiveConversations/`).orderByChild('timestamp').startAfter(start).endBefore(end).once('value',(data)=>{
            //console.log('conversations count ', data.numChildren())
            if(data.exists()){
                let jsonConvos = [];
                let index = 0;
                let count = data.numChildren();
                data.forEach(convo => {
                    index++;
                    let con = convo.toJSON()
                    jsonConvos.push({...con, conversation_id: convo.key})
                    if(index === count){
                        setConversationRows(jsonConvos)
                    }
                })
            }
        })
        axios({
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            url: `https://doctor-services.yourdoctors.online/chat_categories?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`,
            mode: 'cors'
        })
            // .then(response => response.json())
            .then((res) => {
                //console.log("chat_categories data", res.data)
                setChatCategories(res.data || [])
            })
            .catch((error)=> {
                //console.log("chat_categories error", error)
            })
    }

    const findChat = (chatId, callback) => {
        axios
            .get(
                `https://doctor-services.yourdoctors.online/findConversationInChatCategory?conversationId=${chatId}`,
            )
            .then((response) => {
                //console.log('response?.data ', response?.data);
                if (response?.data?.length < 1) {
                    callback(true);
                }else callback(false);
            })
            .catch(error=> {/*console.log("error", error)*/; callback(false);})
    }
    const doComparison = ()=>{
        //console.log("doComparison started")
        let newChatCategories = [...chatCategories];
        let newConversations = [...conversationRows];
        for(let i = 0; i < newChatCategories.length; i++){
            let chat_id = newChatCategories[i]?.chatId || '';
            let index = newConversations.findIndex(convo => convo.conversation_id === chat_id);
            if(index>=0){
                newChatCategories[i].status = index + 1;
                newConversations[index].status = i + 1;
             }
        }
        let remaining = newConversations.filter((chat)=>{
            if(!chat.status){
                return true
            }else{
                return false
            }
        })
        let all_remaining = []
        let deleted = []
        for(let i = 0 ;i < remaining.length; i++) {
            findChat(remaining[i]['conversation_id'], (status) => {
                if (status) {
                    all_remaining.push(remaining[i])
                } else {
                    deleted.push(remaining[i])
                }
                if (all_remaining.length + deleted.length === remaining.length) {
                    setRemainingChats(all_remaining);
                    setChatCategories(newChatCategories);
                    setConversationRows(all_remaining);
                }
            })
        }

    }

    return (
        <div className={classes.container}>
            <div>
                <div>
                    Start Date
                    <input
                        type={'date'}
                        value={startDate}
                        onChange={(e) => setStartDate(e.target?.value)}
                    />
                </div>
                <div>
                    End Date
                    <input
                        type={'date'}
                        value={endDate}
                        onChange={(e) => setEndDate(e.target?.value)}
                    />
                </div>
                <div onClick={(event) => fetchRecord()} className={classes.search_button}>Fetch Record</div>
            </div>
            <div onClick={doComparison}>Do Comparison</div>
            <div style={{display:"flex"}}>
                {conversationRows.length > 0 && <div>
                    {renderTable(conversationRows)}
                </div>}
                {chatCategories.length > 0 && <div>
                    {renderTable(chatCategories, "chat_id")}
                </div>}
            </div>



        </div>
    )


}
export default ChatStatistics

