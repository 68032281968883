import  {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Aux from '../../hoc/hocAux';
import SideBar from '../sidebar/SideBar';
import classes from './clinicallayout.module.css';
import {checkAdmin} from '../../actions/HomeAction';
import ProfileModal from '../UI/profileModal/profileModal';
import {fetchProfile} from '../../actions/ProfileAction';

const image = require('../../assets/download.png');

const customStyles = {
    content: {
        top: '79%',
        left: '20%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: 250,
        width: 150
    }
};

class Layout extends Component {
    state = {
        profileClicked: false
    }

    componentDidMount() {
        this.props.checkAdmin();
        this.props.fetchProfile();
    }

    navClick = (nav) => {
        if(this.props.callData){
            alert("please disconnect the call first")
        }else if (nav === 'Profile') {
            if (this.state.profileClicked === true) {
                this.setState({profileClicked: false})
            } else {
                this.setState({profileClicked: true})
            }
        }
    }

    closeModal = () => {
        this.setState((state) => ({profileClicked: false}));
    }

    onViewProfileClick = () => {
        this.setState({profileClicked: false});
        const path = {
            pathname: '/agents/update',
            search: '',
            hash: '',
            state: {agentID: this.props.uid}
        }
        this.props.history.push(path);
    }

    render() {
        return (
            <Aux>
                {this.props.admin !== undefined && this.props.admin !== null &&
                <SideBar
                    profileImage={this.props.profile_data.profileImageURL !== null && this.props.profile_data.profileImageURL !== undefined ? this.props.profile_data.profileImageURL : image}
                    admin={this.props.admin}
                    clinicalStatus={this.props.clinicalStatus}
                    navClick={(data) => this.navClick(data)}
                />
                }
                <main className={classes.content} onClick={() => this.setState((state) => ({profileClicked: false}))}>
                    {this.props.children}
                </main>
                <ProfileModal
                    show={this.state.profileClicked}
                    onViewProfileClick={() => this.onViewProfileClick()}
                    modalClosed={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                />
            </Aux>
        )
    }
}

const mapStateToProps = ({homeReducer, profileReducer, conversationReducer}) => {
    const {admin, clinicalStatus} = homeReducer;
    const {callData} = conversationReducer;
    const {profile_data, uid} = profileReducer;
    return {admin, clinicalStatus, profile_data, uid, callData};
}

export default compose(withRouter, connect(mapStateToProps, {checkAdmin, fetchProfile}))(Layout);
