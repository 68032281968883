import classes from './navigation.module.css';
import {NavLink, withRouter} from 'react-router-dom';
import {compose} from "redux";
import {connect} from "react-redux";

const NavigationItem = (props) => (
    <li className={classes.NavigationItem}>
        {props.callData?.channelName? <div to={props.link} className={props.active ? classes.active : null}
                                               onClick={() => props.navClick(props.children)}>
            <div className={classes.NavigationItemContainer}>
                {props.children !== 'Profile' &&
                <div className={classes.NavigationItemIcon} width="22px" height="22px" fill="#ffffff">{props.path}</div>
                }
                {props.children === 'Profile' &&
                <div className={classes.NavigationItemLogout}>
                    <img className={classes.NavigationItemLogout} src={props.profileImage} alt=""/>
                </div>
                }
            </div>
            <div className={classes.NavigationItemText}>{props.children}</div>
        </div>:<NavLink to={props.link} className={props.active ? classes.active : null}
                            onClick={() => props.navClick(props.children)}>
            <div className={classes.NavigationItemContainer}>
                {props.children !== 'Profile' &&
                <div className={classes.NavigationItemIcon} width="22px" height="22px" fill="#ffffff">{props.path}</div>
                }
                {props.children === 'Profile' &&
                <div className={classes.NavigationItemLogout}>
                    <img className={classes.NavigationItemLogout} src={props.profileImage} alt=""/>
                </div>
                }
            </div>
            <div className={classes.NavigationItemText}>{props.children}</div>
        </NavLink>
        }

    </li>
)

const mapStateToProps = ({conversationReducer}) => {
    const {callData} = conversationReducer;
    return {callData};
}
export default compose(withRouter, connect(mapStateToProps, {}))(NavigationItem);
