import React, {useEffect, useRef, useState} from 'react';
import classes from '../ChatBuilder.module.css';
import * as timeago from 'timeago.js';
import { defaultStyles, FileIcon } from 'react-file-icon'
import {storage} from "../../../firebase";
import Modal from "react-bootstrap/Modal";
import styles from "../../../components/Image/image.module.css";
import {connect} from "react-redux";

const MessageList = (props) => {
    const [showModal, setShowModal] = useState(false)
    const matchpattern = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm
    const content = props.text
    const {type} = props
    let status = props.isRead ? 'Read' : 'Delivered';
    let time = timeago.format(props.timestamp);
    const [contentType, setContentType] = useState(null)
    let bubbleData = content
    const onHideModal = () => {
        // console.log("onHideModal clicked")
        setShowModal(false)
    }
    // console.log("MessageList triggered")
    useEffect(()=>{
        if(type === 'application' || type === 'video' || type === 'audio'){
            console.log("content ", content)
            try{
                storage.refFromURL(content)
                    .getMetadata()
                    .then((metadata) => {
                        console.log("storage metadata ", metadata)
                        const cType = metadata?.contentType
                        const cTypeSplit = cType.split('/')
                        setContentType(cTypeSplit[cTypeSplit.length - 1])
                        // Metadata now contains the metadata?.contentType for 'images/forest.jpg'
                    })
                    .catch((error) => {
                        console.log("storage error ", error)
                        // Uh-oh, an error occurred!
                    });
            }catch(error){
                const cTypeSplit = content.split('.')
                if(cTypeSplit.length>0){
                    setContentType(cTypeSplit[cTypeSplit.length-1])
                }

            }

        }
    },[])
    const renderModal = () => <Modal
        show={showModal} centered
        onHide={()=>{setShowModal(false)}}
        toggle={onHideModal}> {type === 'video' ?
        <video width='320' height='240' controls
               className='align-self-center  h-100'>
            <source src={content} type='video/mp4' />
            Your browser does not support the video tag.
        </video> : <img src={content} alt='full image' />
    }</Modal>;

    switch (type) {
        case 'image':
            bubbleData =
                <div>
                    <img src={content} className={classes.imageWidth} alt='image'
                     onClick={() => setShowModal(true)} />
                    <div className={styles.copyLink} onClick={()=>navigator.clipboard.writeText(content || '')}>Copy Link</div>
                </div>
            break
        case 'videoCall':
        case 'audioCall':
            bubbleData = content
            break
        case 'video':
            bubbleData = <div className={classes.iconBubble}>
                    <div onClick={() => setShowModal(true)} className={classes.fileIcon}>
                        {/* <VideoImageThumbnail
                            videoUrl={content}
                            thumbnailHandler={(thumbnail) => console.log("thumbnaill ", thumbnail)}
                            width={155}
                            height={112}
                            alt="video"
                            cors={true}
                            snapshotAtTime={1}

                        /> */}
                        <FileIcon
                            extension={contentType} {...defaultStyles[contentType]} />
                    </div>
                <div className={styles.copyLink} onClick={()=>navigator.clipboard.writeText(content || '')}>Copy Link</div>
            </div>
            break
        /*case 'application':
        case 'audio':
            bubbleData = <div className={classes.fileIcon}>
                <FileIcon
                    extension={contentType} {...defaultStyles[contentType]} />
            </div>
            break*/
        default:
            if (matchpattern.test(content)) {
                bubbleData = <a href={content} target='#'
                    /*className={props.direction === 'left' ? classes.textLeft : classes.textRight }*/>{content}</a>
            }
            break
    }
    if (props.direction === 'left') {
        return (
            <>
            <li className={classes.message_li}>
                <div className={classes.message_wrapper}>
                    <span className={classes.author}>{props?.selectedPatient?.patientName}</span>
                    <div className={classes.user_symbol}>
                        <div className={classes.user_img}>
                            <span className={classes.user_symbol_content}>P</span>
                        </div>
                    </div>
                    <div className={classes.message_container}>
                        <div className={classes.message_text}>

                            {(props.type === "image" || props.type === "video" || props.type === "application")?
                                bubbleData : props.type === "text" && props.type === "videoCall" && props.type === "audioCall"? <span>{props.text}</span> : <span>{props.text}</span>
                            /*<a href={props.text} target="_blank" rel="noopener noreferrer" alt="">
                                <p>{props.text}</p>
                            </a>*/
                            }
                        </div>
                    </div>
                    <div className={classes.message_time_left}>
                        <span className={classes.timestamp_span_left}>{time}</span>
                        <span className={classes.read_span_left}>{status}</span>
                    </div>
                </div>
            </li>
                {showModal && renderModal()}
            </>
        )
    }
    else {
        return (
            <>

            <li className={classes.message_li_right}>
                <div className={classes.message_wrapper_right}>
                    <span className={classes.author_right}>{props.doctorName}</span>
                    <div className={classes.user_symbol_right}>
                        <div className={classes.user_symbol_content_right}>
                            <span>
                                <img
                                    src={props.doctorImage}
                                    className={classes.user_img_right}
                                    alt=""
                                />
                            </span>
                        </div>
                        <div className={classes.message_container}>
                            <div className={classes.message_text_right}>
                                {(props.type === "image" || props.type === "video" || props.type === "application")?
                                    bubbleData : props.type === "text" && props.type === "videoCall" && props.type === "audioCall"? <span>{props.text}</span> : <span>{props.text}</span>
                                    /*<a href={props.text} target="_blank" rel="noopener noreferrer" alt="">
                                        <p>{props.text}</p>
                                    </a>*/
                                }
                            </div>
                        </div>
                        <div className={classes.message_time_right}>
                            <span className={classes.timestamp_span_right}>{time}</span>
                            <span className={classes.read_span_right}>{status}</span>
                        </div>
                    </div>
                </div>
            </li>
                {showModal && renderModal()}
            </>
        )
    }
}

const ChatList = (props) => {
    const messagesEndRef = useRef(null);
    //console.log("ChatList rendered")
    const [messages, setMessages] = useState([]);


    useEffect(()=>{
        //console.log("ChatList chatHistoryItemClick", props.chatHistoryItemClick)
        setMessages((props.chatHistoryItemClick || props.chatHistoryItemClick === 0 )?props.chatHistory[props.chatHistoryItemClick]?.messages|| []: props.messages)
    },[props.chatHistoryItemClick,props.messages, props.chatHistory])


    const scrollToBottom = () => {
        try{
            if(!props.loading_messages)
                setTimeout(()=>messagesEndRef.current.scrollIntoView({behavior: "smooth"}),10);
        }catch (e) {
            console.log("scrollToBottom e", e)
        }

    }


    useEffect(scrollToBottom, [messages]);

    useEffect(()=>{
        console.log("messages updated", messages)
    }, [messages]);

    return (
        <div className={classes.chatcontent}>
            <div className={classes.chatcontainer}>
                {!props.loading_messages &&
                <ul className={classes.chat_ul}>
                    <li>
                        <ul className={classes.chat_main_ul}>
                            {messages.map(message => {
                                let chatDoctor = props.doctorsNameAndImages?.length !== 0 && props.doctorsNameAndImages?.find(doctor => doctor.uid === message.receiveMessage.fromID);
                                return <MessageList
                                    key={message.receiveMessage.timestamp}
                                    type={message.receiveMessage.type}
                                    isRead={message.receiveMessage.isRead}
                                    direction={message.receiveMessage.direction}
                                    text={message.receiveMessage.content}
                                    timestamp={message.receiveMessage.timestamp}
                                    doctorName={chatDoctor? chatDoctor.doctorName : message.receiveMessage.doctorName}
                                    doctorImage={chatDoctor? chatDoctor.doctorName : message.receiveMessage.doctorImage}
                                    patientName={props?.selectedPatient?.patientName}
                                />
                            })}
                        </ul>
                        <div ref={messagesEndRef}/>
                    </li>
                </ul>
                }
                {props.loading_messages &&
                <div className={classes.no_loading}>
                    <div className={classes.no_loading_view}>
                        <div className={classes.loading_loader}/>
                    </div>
                </div>
                }
            </div>

        </div>

    )
}
const mapStateToProps = ({ conversationReducer, chatReducer, doctorReducer }) => {
    const {
        doctorsNameAndImages,
        chatHistoryItemClick,
        selectedPatient,
    } = conversationReducer;

    const  {
        doctorDetail
    } = doctorReducer
    const {
        loading_messages,
        doctorName,
        doctorImage,
        messages,
        chatHistory
    } = chatReducer;
    return {
        loading_messages,
        doctorsNameAndImages,
        doctorName,
        doctorImage,
        messages,
        chatHistory,
        chatHistoryItemClick,
        selectedPatient,
        doctorDetail
    };
};
export default connect(mapStateToProps, {})(ChatList);
