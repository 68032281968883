import  {Component} from 'react';
import {childChangedDoctor} from '../../../actions/AgentsAction';
import {fetchOnlineClinicalDoctorsGroup} from '../../../actions/DoctorAction';
import {checkStatusAccept} from '../../../actions/ConversationAction';
import {connect} from 'react-redux';
import Aux from '../../../hoc/hocAux';
import classes from './clinicdoctor.module.css';
import Header from './Header';
import Body from './Body';

class ClinicalDoctorsBuilder extends Component {

    componentDidMount() {
        this.props.fetchOnlineClinicalDoctorsGroup();
        this.props.childChangedDoctor();
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.changedDoctorKey === newProps.doctorDetail.uid) {
            this.props.checkStatusAccept();
        }
    }

    render() {
        return (
            <Aux>
                <div className={classes.main}>
                    {this.props.acceptingChat &&
                    <div className={classes.header}>Doctors</div>
                    }
                    {this.props.acceptingChat === false &&
                    <div className={classes.header_red}>You are currently not accepting chats</div>
                    }
                    <div className={classes.body}>
                        <Header/>
                        <Body
                            agentsData={this.props.onlineDoctors}
                            currentUid={this.props.doctorDetail.uid}
                        />
                    </div>
                </div>
            </Aux>
        )
    }
}

const mapStateToProps = ({acceptingReducer, agentsReducer, doctorReducer}) => {
    const {acceptingChat, changedDoctorKey} = acceptingReducer
    const {doctorDetail, loading, onlineDoctors} = doctorReducer;

    return {acceptingChat, changedDoctorKey, doctorDetail, loading, onlineDoctors};
}

export default connect(mapStateToProps, {
    fetchOnlineClinicalDoctorsGroup,
    childChangedDoctor,
    checkStatusAccept
})(ClinicalDoctorsBuilder);
