import {
    FETCH_CLINICAL_REPORTS_HISTORY,
    FETCH_CLINICAL_REPORTS_INFO,
    FETCH_CLINICALDOCTORS_SUCCESS,
    FETCH_DAILY_REPORTS_FAILS,
    FETCH_DAILY_REPORTS_PROCESS,
    FETCH_DAILY_REPORTS_SUCCESS,
    FETCH_DOCTORS_SUCCESS
} from '../constants';

const INITIAL_STATE = {
    loading: false, reports_data: [], uid: null, doctors: [], doctorArray: [], dateArray: [],
    numberOfConversations: 0, maxConvoInterval: 0, clinical_doctors: [], history_reports: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_DAILY_REPORTS_PROCESS:
            return {...state, loading: true};
        case FETCH_DAILY_REPORTS_SUCCESS:
            return {...state, loading: false, reports_data: action.payload};
        case FETCH_DAILY_REPORTS_FAILS:
            return {...state, loading: false, reports_data: []}
        case FETCH_DOCTORS_SUCCESS:
            return {...state, doctors: action.payload};
        case FETCH_CLINICAL_REPORTS_INFO:
            return {
                ...state,
                doctorArray: action.payload.doctorArray,
                dateArray: action.payload.dateArray,
                numberOfConversations: action.payload.numberOfConversations,
                maxConvoInterval: action.payload.maxConvoInterval
            };
        case FETCH_CLINICALDOCTORS_SUCCESS:
            return {...state, clinical_doctors: action.payload};
        case FETCH_CLINICAL_REPORTS_HISTORY:
            return {...state, history_reports: action.payload};
        default:
            return state;
    }
}
