import {
    ACCEPTING_CHAT_FALSE,
    ACCEPTING_CHAT_FALSE_ADMIN,
    ACCEPTING_CHAT_TRUE,
    ACCEPTING_CHAT_TRUE_ADMIN,
    CHANGED_DOCTOR_KEY
} from '../constants';

const INITIAL_STATE = {acceptingChat: false, acceptingAdmin: false, changedDoctorKey: '', acceptingChatTime: null};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACCEPTING_CHAT_TRUE:
            return {...state, acceptingChat: true, acceptingChatTime: action.payload.time};
        case ACCEPTING_CHAT_FALSE:
            return {...state, acceptingChat: false, acceptingChatTime: null};
        case ACCEPTING_CHAT_TRUE_ADMIN:
            return {...state, acceptingAdmin: true};
        case ACCEPTING_CHAT_FALSE_ADMIN:
            return {...state, acceptingAdmin: false};
        case CHANGED_DOCTOR_KEY:
            return {...state, changedDoctorKey: action.payload};
        default:
            return state;
    }
}
