import {CLEAR_USERS_LIST, FETCH_BANNED_USERS, FETCH_NEXT_USER_ID, FETCH_USER_DETAILS} from '../constants';
import {database} from "../firebase";
import axios from "axios";
import {firebaseRoot} from "../config";



export const fetchAllUsers = (nextUserID, isClear) => {
    //console.log("fetchBannedUsers fetchAllUsers triggered")
    return (dispatch) => {
        if (isClear) {
            dispatch({
                type: CLEAR_USERS_LIST
            });
        }
        var doctors = database.ref(`/users`).orderByKey();
        if (Boolean(nextUserID) && nextUserID !== '') {
            doctors = doctors.startAt(nextUserID).limitToFirst(11);
        } else {
            doctors = doctors.limitToFirst(11);
        }
        doctors.once("value", function (allusers) {

            var users = allusers.val();
            var count = allusers.numChildren();
            var usersData = [];
            var showLoadMore = true;
            nextUserID = '';

            if (users) {
                let index = 0;
                for (var user in users) {
                    if (index < 10) {
                        let chatCount = users[user].history_conversations;
                        var data = {
                            name: ((users[user].userName === 'No name' || users[user].userName === 'Patient') && Boolean(users[user].name)) ? users[user].name : (users[user].userName) ? users[user].userName : 'No name',
                            ban: (users[user].ban === 'yes') ? true : false,
                            email: users[user].email,
                            noOfChats: chatCount !== null && chatCount !== undefined ? Object.keys(chatCount).length : 0,
                            userID: user
                        }
                        dispatch({
                            type: FETCH_USER_DETAILS,
                            payload: data
                        })
                        usersData.push(data);
                        index = index + 1;
                    } else {
                        nextUserID = user;
                    }

                }
                if (count < 11) {
                    showLoadMore = false;
                    nextUserID = '';
                }
                dispatch({
                    type: FETCH_NEXT_USER_ID,
                    payload: {
                        showLoadMore: showLoadMore,
                        nextUserID: nextUserID,
                    }
                });
            }

        })
    }
}

export const fetchBannedUsers = () => {
    //console.log("fetchBannedUsers triggered")
    return (dispatch) => {

        var doctors = database.ref(`/users`).orderByChild('ban').equalTo('yes');
        doctors.once("value", function (allusers) {
            //console.log("fetchBannedUsers numChildeen", allusers.numChildren())
            var users = allusers.val();
            //console.log("fetchBannedUsers users", users)
            var usersData = [];
            if (users) {
                for (var user in users) {
                    let chatCount = users[user].history_conversations;
                    var data = {
                        name: ((users[user].userName === 'No name' || users[user].userName === 'Patient') && Boolean(users[user].name)) ? users[user].name : (users[user].userName) ? users[user].userName : 'No name',
                        ban: (users[user].ban === 'no') ? false : true,
                        email: users[user].email,
                        noOfChats: chatCount !== null && chatCount !== undefined ? Object.keys(chatCount).length : 0,
                        userID: user
                    }
                    usersData.push(data);
                }
                dispatch({
                    type: FETCH_BANNED_USERS,
                    payload: {
                        bannedUsersData: usersData,
                    }
                });
            } else {
                dispatch({
                    type: FETCH_BANNED_USERS,
                    payload: {
                        bannedUsersData: [],
                    }
                });
            }

        })
    }
}

export const userChangeState = (isBan, userID) => {
    return (dispatch) => {
        let updates = {}
        if (isBan) {
            database.ref(`/banDevices`).once("value", function (bannedUsers) {
                var banned = bannedUsers.val();
                if (banned) {
                    for (var user in banned) {
                        if (banned[user].userID === userID) {
                            database.ref(`/banDevices/${user}/`).update({userID: null, deviceID: null})
                        }
                    }
                }
            })
            updates[`/users/${userID}/ban`] = 'no';
            database.ref().update(updates).then(data => {
            }).catch((error) => {
                //console.log("Message send update error: 1 " + error);
            });

        } else {
            updates[`/users/${userID}/ban`] = 'yes';
            database.ref().update(updates).then(data => {
            }).catch((error) => {
                //console.log("Message send update error: 1 " + error);
            });
        }
    }
}

export const fetchSearchedUsers = (email) => {
    //console.log("fetchBannedUsers fetchSearchedUsers triggered")
    return (dispatch) => {
        dispatch({
            type: CLEAR_USERS_LIST
        });
        database.ref(`/users`).orderByChild('email').startAt(email).endAt(email + "\uf8ff").once("value", function (allusers) {
            var users = allusers.val();
            if (users) {
                for (var user in users) {
                    let chatCount = users[user].history_conversations;
                    var data = {
                        name: ((users[user].userName === 'No name' || users[user].userName === 'Patient') && Boolean(users[user].name)) ? users[user].name : (users[user].userName) ? users[user].userName : 'No name',
                        ban: (users[user].ban === 'no') ? false : true,
                        email: users[user].email,
                        noOfChats: chatCount !== null && chatCount !== undefined ? Object.keys(chatCount).length : 0,
                        userID: user
                    }
                    dispatch({
                        type: FETCH_USER_DETAILS,
                        payload: data
                    })
                }
                dispatch({
                    type: FETCH_NEXT_USER_ID,
                    payload: {
                        showLoadMore: false
                    }
                });
            }

        })
    }
}
