import {FETCH_CUSTOMERS, REFRESH_CUSTOMERS,} from '../constants';


import _ from 'lodash';
import {auth, database} from "../firebase";

export const refreshCustomers = () => {
    return {
        type: REFRESH_CUSTOMERS,
    }
}

export const fetchCustomers = () => {
    return (dispatch) => {
        dispatch({type: REFRESH_CUSTOMERS});

        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        getOpenConversationsRef().on('value', conversationsSnapShot => {
            if (conversationsSnapShot.val()) {
                var count = conversationsSnapShot.numChildren();
                var i = 0;
                var conversations = [];
                conversationsSnapShot.forEach(function (childSnapshot) {
                    if (childSnapshot.val().status) {
                        if (childSnapshot.val().clinical_chat !== undefined && !childSnapshot.val().clinical_chat) {
                            let location = childSnapshot.key;
                            getDoctorRef(childSnapshot.val().doctorID).once('value', doctorSnapshot => {
                                if (doctorSnapshot.val()) {
                                    getUserRef(childSnapshot.val().patientID).once('value', fromUserSnapshot => {
                                        let userName = fromUserSnapshot.val().userName !== null &&
                                        fromUserSnapshot.val().userName !== undefined ?
                                            fromUserSnapshot.val().userName : 'Patient';

                                        userName = ((userName === 'No name' || userName === 'Patient') && fromUserSnapshot.val().name) ? fromUserSnapshot.val().name : userName;

                                        let email = fromUserSnapshot.val().email !== undefined ? fromUserSnapshot.val().email : 'your@email.com';
                                        let chatCount = fromUserSnapshot.val().history_conversations;

                                        let conversationObj = {
                                            patientID: childSnapshot.val().patientID,
                                            doctorID: childSnapshot.val().doctorID,
                                            chatOwner: currentUserID === childSnapshot.val().doctorID ? true : false,
                                            doctorName: doctorSnapshot.val().doctorName,
                                            chatInQueue: childSnapshot.val().queued,
                                            issue: childSnapshot.val().issue !== undefined && childSnapshot.val().issue !== null ?
                                                childSnapshot.val().issue : 'No Issue',
                                            category: childSnapshot.val().category !== undefined && childSnapshot.val().category !== null ?
                                                childSnapshot.val().category : 'No Category',
                                            openChat: childSnapshot.val().status,
                                            timestamp: childSnapshot.val().timestamp,
                                            patientName: userName,
                                            patientEmail: email,
                                            patientChatsCount: chatCount !== null && chatCount !== undefined ? Object.keys(chatCount).length : 0,
                                            conversationId: location,
                                        }

                                        conversations.push(conversationObj);
                                        if (count - 1 === i) {
                                            const bubbles = _.map(conversations, (val, uid) => {
                                                return {...val, uid};
                                            });

                                            i = count - 1;
                                            dispatch({
                                                type: FETCH_CUSTOMERS,
                                                payload: bubbles
                                            })
                                        } else {
                                            i = i + 1;
                                        }
                                    });
                                } else {
                                    getUserRef(childSnapshot.val().patientID).once('value', fromUserSnapshot => {
                                        let userName = fromUserSnapshot.val().userName !== null &&
                                        fromUserSnapshot.val().userName !== undefined ?
                                            fromUserSnapshot.val().userName : 'Patient';

                                        userName = ((userName === 'No name' || userName === 'Patient') && fromUserSnapshot.val().name) ? fromUserSnapshot.val().name : userName;

                                        let email = fromUserSnapshot.val().email !== undefined ? fromUserSnapshot.val().email : 'your@email.com';
                                        let chatCount = fromUserSnapshot.val().history_conversations;

                                        let conversationObj = {
                                            patientID: childSnapshot.val().patientID,
                                            doctorID: childSnapshot.val().doctorID,
                                            chatOwner: false,
                                            doctorName: 'Queue',
                                            chatInQueue: childSnapshot.val().queued,
                                            issue: childSnapshot.val().issue !== undefined && childSnapshot.val().issue !== null ?
                                                childSnapshot.val().issue : 'No Issue',
                                            category: childSnapshot.val().category !== undefined && childSnapshot.val().category !== null ?
                                                childSnapshot.val().category : 'No Category',
                                            openChat: childSnapshot.val().status,
                                            timestamp: childSnapshot.val().timestamp,
                                            patientName: userName,
                                            patientEmail: email,
                                            patientChatsCount: chatCount !== null && chatCount !== undefined ? Object.keys(chatCount).length : 0,
                                            conversationId: location,
                                        }

                                        conversations.push(conversationObj);
                                        if (count - 1 === i) {
                                            const bubbles = _.map(conversations, (val, uid) => {
                                                return {...val, uid};
                                            });

                                            i = count - 1;
                                            dispatch({
                                                type: FETCH_CUSTOMERS,
                                                payload: bubbles
                                            })
                                        } else {
                                            i = i + 1;
                                        }
                                    });
                                }
                            })
                        } else {
                            i = i + 1;
                            if (count === i && conversations.length === 0) {
                                dispatch({
                                    type: FETCH_CUSTOMERS,
                                    payload: []
                                })
                            } else if (count === i && conversations.length !== 0) {
                                const bubbles = _.map(conversations, (val, uid) => {
                                    return {...val, uid};
                                });

                                i = count - 1;
                                dispatch({
                                    type: FETCH_CUSTOMERS,
                                    payload: bubbles
                                })
                            }
                        }
                    } else {
                        i = i + 1;
                        if (count === i && conversations.length === 0) {
                            dispatch({
                                type: FETCH_CUSTOMERS,
                                payload: []
                            })
                        } else if (count === i && conversations.length !== 0) {
                            const bubbles = _.map(conversations, (val, uid) => {
                                return {...val, uid};
                            });

                            i = count - 1;
                            dispatch({
                                type: FETCH_CUSTOMERS,
                                payload: bubbles
                            })
                        }
                    }
                })
            } else {
                dispatch({
                    type: FETCH_CUSTOMERS,
                    payload: []
                })
            }
        })
    }
}

/* export const fetchCustomers = () => {
    return(dispatch) => {
        const { currentUser } = auth;
        let currentUserID = currentUser.uid;
        var conversations = [];
        getOpenConversationsRef().on('child_added', conversationsSnapShot => {
            if(conversationsSnapShot.val()){
                let location = conversationsSnapShot.key;
                getDoctorRef(conversationsSnapShot.val().doctorID).once('value', doctorSnapshot => {
                    if(doctorSnapshot.val()){
                        getUserRef(conversationsSnapShot.val().patientID).once('value', fromUserSnapshot => {
                            if(fromUserSnapshot.val()){

                                let userName =  fromUserSnapshot.val().userName !== null &&
                                fromUserSnapshot.val().userName !== undefined ?
                                fromUserSnapshot.val().userName : 'Patient';
                                userName = ((userName === 'No name' || userName === 'Patient') && fromUserSnapshot.val().name)? fromUserSnapshot.val().name : userName;

                                let email = fromUserSnapshot.val().email !== undefined ? fromUserSnapshot.val().email : 'your@email.com';
                                let chatCount = fromUserSnapshot.val().history_conversations;

                                let conversationObj = {
                                    patientID: conversationsSnapShot.val().patientID,
                                    doctorID: conversationsSnapShot.val().doctorID,
                                    chatOwner: currentUserID === conversationsSnapShot.val().doctorID ? true : false,
                                    doctorName: doctorSnapshot.val().doctorName,
                                    chatInQueue: conversationsSnapShot.val().queued,
                                    openChat: conversationsSnapShot.val().status,
                                    timestamp: conversationsSnapShot.val().timestamp,
                                    patientName: userName,
                                    patientEmail: email,
                                    patientChatsCount: chatCount !== null && chatCount !== undefined ? Object.keys(chatCount).length : 0,
                                    conversationId: location,
                                }
                                updateArr(conversations, conversationObj,dispatch);
                            }
                        })
                    }
                });
            }
        })
    }
}

const updateArr = (arr, newOBJ, dispatch) => {

    for (var i in arr) {
        if (arr[i].patientID === newOBJ.patientID && arr[i].timestamp < newOBJ.timestamp) {
            arr[i] = newOBJ;
            return dispatch({
                type: FETCH_CUSTOMERS_UPDATE,
                payload: newOBJ
            });
        }
    }
    arr.push(newOBJ);
    return dispatch({
        type: FETCH_CUSTOMERS,
        payload: newOBJ
    });
}

*/

const getOpenConversationsRef = () => {
    return database.ref(`/openconversations/`);
}

const getUserRef = (uid) => {
    return database.ref(`/users/${uid}`);
}

const getDoctorRef = (uid) => {
    return database.ref(`/doctors/${uid}`);
}
