import  {Component} from 'react';
import classes from './editdetails.module.css';
import {
    clinic_fetchCannedResponsesForAssessment,
    clinic_fetchCannedResponsesForChats,
    clinic_fetchCannedResponsesForObjectiveFindings,
    clinic_fetchCannedResponsesForPlan,
    clinic_setAssessment,
    clinic_setFindings,
    clinic_setPlan,
    clinic_setSubjectiveSymptoms
} from '../../../../actions/clinic/setting/ClinicCannedResponseAction';
import {clinic_fetchBillingCodes} from '../../../../actions/clinic/setting/ClinicBillingCodesAction';
import {connect} from 'react-redux';
import ReactDOM from "react-dom";
import {refreshState, updateClinicalOutPatientForm} from '../../../../actions/ConversationAction';

import _ from 'lodash';

import TextareaAutosize from 'react-autosize-textarea';
import MyDocument from '../../../PdfBuilder';
import {pdf} from '@react-pdf/renderer';

import MyPdf from '../../../clinicalSide/encounters/CreatePdf';
import {functions} from "../../../../firebase";
import axios from "axios";


const ListItem = (props) => {
    return (
        props.data.map(function (data, index) {
            return data.title.indexOf('</') !== -1 ? (
                    <li key={index}
                        onClick={() => props.onSelectDiagnosisIssue(data, (data.title).replace(/<\s*br[^>]?>/, '\n').replace(/(<([^>]+)>)/g, ""), data.id)}
                        dangerouslySetInnerHTML={{__html: data.title.replace(/( <? *script)/gi, 'illegalscript')}}/>) :
                (<li onClick={() => props.onSelectDiagnosisIssue(data, data.title, data.id)} key={index}
                     value={data.title}>
                    <div>{`${data.title}`}</div>
                    <div
                        className={classes.titleText}>{`${(data.matchingPVs[0].label).replace(/(<([^>]+)>)/g, "")}`}</div>
                </li>);
        })
    )
}

const CannedResponsesList = (props) => {
    return (
        props.canned_responses_data.map(function (data, index) {
            return (
                <div key={index} className={classes.canned_response_item}
                     onClick={() => props.onCannedResponseClick(data.uid, data.shortcut, data.text)}>
                    <div className={classes.canned_response_text}>{data.shortcut}</div>
                    <div className={classes.canned_response_arrow}
                         onClick={() => props.onCannedResponseClick(data.uid, data.shortcut, data.text)}>
                        <svg data-test="shortcut-select-button" fill="#000000" width="4px" height="12px"
                             viewBox="0 0 8 13">
                            <g fill="inherit" fillRule="evenodd">
                                <path fillRule="nonzero" d="M.59 11.34l4.58-4.59L.59 2.16 2 .75l6 6-6 6z"></path>
                            </g>
                        </svg>
                    </div>
                </div>
            )
        })
    )
};

const BillingCodeOption = (props) => {
    return (
        props.codes.map(function (data, index) {
            return (
                <option key={index} value={data.billing_code}>{data.billing_code}</option>
            )
        })
    )
}

function formatDate() {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(d.setDate(d.getDate() + 1)),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}


var healthProblems = [];
var familyProblems = [];

class PatientForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dateTime: formatDate(),
            access_token: '',
            events: [],
            diagnosisValue: '',
            opinionDescription: false,
            opinionDescriptionValue: '',
            followUpCheck: false,
            followUpUpdated: false,
            displayDiagnosisList: false,
            commentAdded: '',
            mediciensArr: [],
            typing: false,
            typingTimeout: 0,
            symptoms: '',
            findings: '',
            assessment: '',
            plan: '',
            icd: '',
            billing_code: '',
            billing_unit: 0,
            showCannedResponseForObjectiveFindingsList: false,
            showCannedResponseForAssessmentList: false,
            showCannedResponseForPlanList: false,
            canned_responses: [],
            canned_responses_assessment: [],
            canned_responses_plan: [],
            billing_codes: [],
            showIndicator: false,
            video_consult: false,
            encounter_duration: false,
            special_referals: false,
            requisitions: false,
            prescriptions: false,
            followUpDays: 0,
        };
        this.baseState = this.state;
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (!(_.isEqual(this.props.patient_profile, newProps.patient_profile))) {
            familyProblems = []
            healthProblems = []
            this.generatePatientProfile(newProps.patient_profile);
        }
        if (newProps.archive_patientSymptoms) {
            this.setState({symptoms: newProps.archive_patientSymptoms});
        }

        if (newProps.archive_findings) {
            this.setState({findings: newProps.archive_findings});
        }

        if (newProps.archive_assessment) {
            this.setState({assessment: newProps.archive_assessment});
        }

        if (newProps.archive_plan) {
            this.setState({plan: newProps.archive_plan});
        }

        if (newProps.archive_icd) {
            this.setState({icd: newProps.archive_icd});
        }

        if (newProps.archive_billingCode) {
            this.setState({billing_code: newProps.archive_billingCode});
        }

        if (newProps.archive_billingUnit) {
            this.setState({billing_unit: newProps.archive_billingUnit});
        }

        if (newProps.archive_opinionDescriptionValue) {
            this.setState({
                opinionDescription: true,
                opinionDescriptionValue: newProps.archive_opinionDescriptionValue
            });
        } else {
            this.setState({opinionDescription: false, opinionDescriptionValue: ''});
        }

        if (newProps.archive_diagnosisValue) {
            this.setState({diagnosisValue: newProps.archive_diagnosisValue});
        } else {
            this.setState({diagnosisValue: ''});
        }

        if (newProps.archive_commentAdded) {
            this.setState({commentAdded: newProps.archive_commentAdded});
        } else {
            this.setState({commentAdded: ''});
        }

        if (newProps.archive_patientFollowUpData && newProps.archive_patientFollowUpData.length !== 0) {
            const {scheduled_date} = newProps.archive_patientFollowUpData;
            var event = new Date(scheduled_date);
            let date = JSON.stringify(event);
            date = date.slice(1, 11);
            this.setState({followUpCheck: true, dateTime: date});
        } else {
            this.setState({followUpCheck: false, dateTime: this.baseState.dateTime});
        }

        if (newProps.clinic_canned_responses !== this.props.clinic_canned_responses) {
            this.setState({canned_responses: newProps.clinic_canned_responses});
        }

        if (newProps.clinic_canned_responses_assessment !== this.props.clinic_canned_responses_assessment) {
            this.setState({canned_responses_assessment: newProps.clinic_canned_responses_assessment});
        }

        if (newProps.clinic_canned_responses_plan !== this.props.clinic_canned_responses_plan) {
            this.setState({canned_responses_plan: newProps.clinic_canned_responses_plan});
        }

        if (newProps.clinic_billing_codes) {
            this.setState({billing_codes: newProps.clinic_billing_codes});
        }

        if (newProps.archive_prescriptions) {
            this.setState({prescriptions: newProps.archive_prescriptions});
        }

        if (newProps.archive_requisitions) {
            this.setState({requisitions: newProps.archive_requisitions});
        }

        if (newProps.archive_special_referals) {
            this.setState({special_referals: newProps.archive_special_referals});
        }

        if (newProps.archive_video_consult) {
            this.setState({video_consult: newProps.archive_video_consult});
        }
        if (newProps.archive_encounter_duration) {
            this.setState({encounter_duration: newProps.archive_encounter_duration});
        }

        if (newProps.archive_followUpDays) {
            this.setState({followUpDays: newProps.archive_followUpDays});
        }
    }


    UNSAFE_componentWillMount() {
        const getToken = functions.httpsCallable('getToken');
        getToken().then(result => {
            this.setState({access_token: JSON.parse(result.data).access_token});
        });

        this.props.clinic_fetchCannedResponsesForObjectiveFindings(this.props.clinicId);
        this.props.clinic_fetchCannedResponsesForAssessment(this.props.clinicId);
        this.props.clinic_fetchCannedResponsesForPlan(this.props.clinicId);
        this.props.clinic_fetchBillingCodes(this.props.clinicId);
    }

    generatePatientProfile = (patient_profile) => {
        if (patient_profile != null && patient_profile != undefined && patient_profile != {}) {

            for (var key of Object.keys(patient_profile)) {
                if(key){
                    if (key.indexOf("Flag") != -1 && key.indexOf("Family") == -1 && key.indexOf("alcoholFlag") == -1 && key.indexOf("smokeFlag") == -1 && key.indexOf("allergyFlag") == -1) {
                        if (patient_profile[key] == true) {
                            if (key == 'anxietyFlag') {
                                healthProblems.push("Anxiety")
                            }
                            if (key == 'asthmaFlag') {
                                healthProblems.push("Asthama\n")
                            }
                            if (key == 'arrhythmiasFlag') {
                                healthProblems.push("Arrhythmias")
                            }
                            if (key == 'bpFlag') {
                                healthProblems.push("High Blood Pressure")
                            }
                            if (key == 'cancerFlag') {
                                if (patient_profile.cancerData != null && patient_profile.cancerData != undefined && patient_profile.cancerData != '' && patient_profile.cancerData != []) {
                                    healthProblems.push("Cancer" + "(" + (patient_profile.cancerData) ? patient_profile.cancerData.toString() : '' + ")")
                                } else {
                                    healthProblems.push("Cancer")
                                }
                            }
                            if (key == 'copdFlag') {
                                healthProblems.push("COPD")
                            }
                            if (key == 'depressionsFlag') {
                                healthProblems.push("Depressions")
                            }
                            if (key == 'diabetesFlag') {
                                healthProblems.push("Diabetes")
                            }
                            if (key == 'eczemaFlag') {
                                healthProblems.push("\n Eczema")
                            }
                            if (key == 'headFlag') {
                                healthProblems.push("Headaches/Migraines")
                            }
                            if (key == 'heartFlag') {
                                healthProblems.push("Heart Problems")
                            }
                            if (key == 'kidneyFlag') {
                                healthProblems.push(" Kidney Problems")
                            }
                            if (key == 'otherFlag') {
                                if (patient_profile.other != null && patient_profile.other != undefined && patient_profile.other != '' && patient_profile.other != []) {
                                    healthProblems.push("Other Problems" + "(" + patient_profile.other + ")");
                                }

                                healthProblems.push("Other Problems");
                            }
                            if (key == 'panicFlag') {
                                healthProblems.push("Panic Attacks")
                            }
                            if (key == 'psoriasisFlag') {
                                healthProblems.push("Psoriasis")
                            }
                            if (key == 'seizureFlag') {
                                healthProblems.push("Seizures")
                            }
                            if (key == 'strokeFlag') {
                                healthProblems.push("Stroke")
                            }
                            if (key == 'thyroidFlag') {
                                healthProblems.push("Thyroid")
                            }
                            if (key == 'utiFlag') {
                                healthProblems.push("Urinary Tract Infection")
                            }
                        }
                    }
                    if (key.indexOf("Family") != -1 && key.indexOf("Flag") != -1) {
                        if (patient_profile[key] == true) {
                            if (key == ("anxietyFlagFamily")) {
                                if (patient_profile.anxietyAnswer != null || patient_profile.anxietyAnswer != undefined || patient_profile.anxietyAnswer != '') {
                                    familyProblems.push("Anxiety(" + patient_profile.anxietyAnswer + ")")
                                }
                            }
                            if (key == ("arrhythmiasFlagFamily")) {
                                if (patient_profile.arrhythmiasAnswer != null || patient_profile.arrhythmiasAnswer != undefined || patient_profile.arrhythmiasAnswer != '') {
                                    familyProblems.push("Arrhythmias(" + patient_profile.arrhythmiasAnswer + ")")
                                }
                            }
                            if (key == ("asthmaFlagFamily")) {
                                if (patient_profile.asthamaAnswer != null || patient_profile.asthamaAnswer != undefined || patient_profile.asthamaAnswer != '') {
                                    familyProblems.push("Asthma(" + patient_profile.asthamaAnswer + ")")
                                }
                            }
                            if (key == ("bpFlagFamily")) {
                                if (patient_profile.bpAnswer != null || patient_profile.bpAnswer != undefined || patient_profile.bpAnswer != '') {
                                    familyProblems.push("High Blood Pressure(" + patient_profile.bpAnswer + ")")
                                }
                            }
                            if (key == ("cancerFlagFamily")) {
                                if (patient_profile.cancerAnswer != null || patient_profile.cancerAnswer != undefined || patient_profile.cancerAnswer != '') {
                                    familyProblems.push("Cancer(" + patient_profile.cancerAnswer + ")")
                                }
                            }
                            if (key == ("copdFlagFamily")) {
                                if (patient_profile.copdAnswer != null || patient_profile.copdAnswer != undefined || patient_profile.copdAnswer != '') {
                                    familyProblems.push("COPD(" + patient_profile.copdAnswer + ")")
                                }
                            }
                            if (key == ("depressionsFlagFamily")) {
                                if (patient_profile.adepressionsAnswer != null || patient_profile.depressionsAnswer != undefined || patient_profile.depressionsAnswer != '') {
                                    familyProblems.push("Depression(" + patient_profile.depressionsAnswer + ")")
                                }
                            }
                            if (key == ("diabetesFlagFamily")) {
                                if (patient_profile.diabetesAnswer != null || patient_profile.diabetesAnswer != undefined || patient_profile.diabetesAnswer != '') {
                                    familyProblems.push("Diabetes(" + patient_profile.diabetesAnswer + ")")
                                }
                            }
                            if (key == ("eczemaFlagFamily")) {
                                if (patient_profile.eczemaAnswer != null || patient_profile.eczemaAnswer != undefined || patient_profile.eczemaAnswer != '') {
                                    familyProblems.push("Eczema(" + patient_profile.eczemaAnswer + ")")
                                }
                            }
                            if (key == ("headFlagFamily")) {
                                if (patient_profile.headAnswer != null || patient_profile.headAnswer != undefined || patient_profile.headAnswer != '') {
                                    familyProblems.push("Headaches/Migraines(" + patient_profile.headAnswer + ")")
                                }
                            }
                            if (key == ("heartFlagFamily")) {
                                if (patient_profile.heartAnswer != null || patient_profile.heartAnswer != undefined || patient_profile.heartAnswer != '') {
                                    familyProblems.push("Heart Problems(" + patient_profile.heartAnswer + ")")
                                }
                            }
                            if (key == ("kidneyFlagFamily")) {
                                if (patient_profile.kidneyAnswer != null || patient_profile.kidneyAnswer != undefined || patient_profile.kidneyAnswer != '') {
                                    familyProblems.push("Kidney Problems(" + patient_profile.kidneyAnswer + ")")
                                }
                            }

                            if (key == ("panicFlagFamily")) {
                                if (patient_profile.panicAnswer != null || patient_profile.panicAnswer != undefined || patient_profile.panicAnswer != '') {
                                    familyProblems.push("Panic Attacks(" + patient_profile.panicAnswer + ")")
                                }
                            }
                            if (key == ("psoriasisFlagFamily")) {
                                if (patient_profile.psoriasisAnswer != null || patient_profile.psoriasisAnswer != undefined || patient_profile.psoriasisAnswer != '') {
                                    familyProblems.push("Psoriasis(" + patient_profile.psoriasisAnswer + ")")
                                }
                            }
                            if (key == ("seizureFlagFamily")) {
                                if (patient_profile.seizureAnswer != null || patient_profile.seizureAnswer != undefined || patient_profile.seizureAnswer != '') {
                                    familyProblems.push("Seizures(" + patient_profile.seizureAnswer + ")")
                                }
                            }
                            if (key == ("strokeFlagFamily")) {
                                if (patient_profile.strokeAnswer != null || patient_profile.strokeAnswer != undefined || patient_profile.strokeAnswer != '') {
                                    familyProblems.push("Stroke(" + patient_profile.strokeAnswer + ")")
                                }
                            }
                            if (key == ("thyroidFlagFamily")) {
                                if (patient_profile.thyroidAnswer != null || patient_profile.thyroidAnswer != undefined || patient_profile.thyroidAnswer != '') {
                                    familyProblems.push("Thyroid Problems(" + patient_profile.thyroidAnswer + ")")
                                }
                            }
                            if (key == ("utiFlagFamily")) {
                                if (patient_profile.utiAnswer != null || patient_profile.utiAnswer != undefined || patient_profile.utiAnswer != '') {
                                    familyProblems.push("Urinary Tract Infection(" + patient_profile.utiAnswer + ")")
                                }
                            }
                            if (key == ("otherFlagFamily")) {
                                if (patient_profile.otherAnswerFamily != null || patient_profile.otherAnswerFamily != undefined || patient_profile.otherAnswerFamily != '') {
                                    familyProblems.push("Other Problems(" + patient_profile.otherAnswerFamily + ":" + patient_profile.otherFamily + ")")
                                }
                            }

                        }

                    }
                }

            }
        }
        this.setState({check: Math.random()})
    }

    diagnosisInput = (event) => {

        var self = this;
        this.setState({showIndicator: true});

        this.setState({showIndicator: true});
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            icd: event.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
                self.findDiagnosis(self.state.icd);
            }, 1000)
        });
    }

    findDiagnosis = text => {
        if (text.length > 2) {
            this.setState({displayDiagnosisList: true});
            /*var options = {
                url: `https://id.who.int/icd/entity/search?q=${text}&useFlexisearch=false&flatResults=true`,
                headers: {
                    'Authorization': 'Bearer ' + this.state.access_token,
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Accept-Language': 'en',
                    'API-Version': 'v2'
                }
            };*/
            let self = this;
            /*function callback(error, response, body) {
                if (!error && response.statusCode === 200) {
                    var info = JSON.parse(body);
                    self.setState({
                        showIndicator: false,
                        events: _.orderBy(info.destinationEntities, ['score'], ['desc'])
                    });
                }
            }*/
            axios({
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + this.state.access_token,
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Accept-Language': 'en',
                    'API-Version': 'v2'
                },
                url: `https://id.who.int/icd/entity/search?q=${text}&useFlexisearch=false&flatResults=true`,
                mode: 'cors',
            }).then(res=>{
                let info = res?.data?.destinationEntities? res?.data: JSON.parse(res.data);
                self.setState({
                    showIndicator: false,
                    events: _.orderBy(info.destinationEntities, ['score'], ['desc'])
                });
            }).catch(e=>{})
            //request(options, callback);
        } else {
            this.setState({showIndicator: false});
        }
    }

    onSelectDiagnosisIssue = (data, title, id) => {
        this.setState({icd: title, displayDiagnosisList: false});
        var number = id.split('/entity/');

        /*var options = {
            url: `https://id.who.int/icd/entity/${number[1]}`,
            headers: {
                'Authorization': 'Bearer ' + this.state.access_token,
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Accept-Language': 'en',
                'API-Version': 'v2'
            }
        };*/
        var self = this;
        axios({
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + this.state.access_token,
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Accept-Language': 'en',
                'API-Version': 'v2'
            },
            url: `https://id.who.int/icd/entity/${number[1]}`,
            mode: 'cors',
        }).then(res=>{
            let info = res?.data?.definition? res?.data: JSON.parse(res.data);
            if (info.definition) {
                var descOption = Object.values(info.definition);
                self.setState({opinionDescription: true, opinionDescriptionValue: descOption[1]});
            } else {
                self.setState({opinionDescription: false, opinionDescriptionValue: ''});
            }
        }).catch(e=>{})
        /*function callback(error, response, body) {
            if (!error && response.statusCode === 200) {
                var info = JSON.parse(body);
                if (info.definition) {
                    var descOption = Object.values(info.definition);
                    self.setState({opinionDescription: true, opinionDescriptionValue: descOption[1]});
                } else {
                    self.setState({opinionDescription: false, opinionDescriptionValue: ''});
                }
            }
        }*/

        //request(options, callback);
    }

    handleChangeFollowUpDateTime = (event) => {
        this.setState({dateTime: event.target.value, followUpUpdated: true});
    };


    handleChangeFollowUp = () => {
        this.setState({followUpCheck: !this.state.followUpCheck, followUpUpdated: !this.state.followUpUpdated});
    }

    onChangeCommentAdded = (event) => {
        this.setState({commentAdded: event.target.value, followUpUpdated: true});
    }

    onCancelClick = () => {
        this.props.hideModal();
    }

    updateOutPatientFormClick = async () => {
        const {
            symptoms, findings, assessment, plan, icd, billing_code, billing_unit,
            prescriptions, requisitions, special_referals, video_consult, followUpDays, followUpCheck, dateTime,
            encounter_duration
        } = this.state;
        const {patientName, doctorName, archive_userID, archive_conversationID} = this.props;

        const {
            clinicId, clinic_name, full_name, address, country,
            phone, fax, license_no, profile_image, signature
        } = this.props;

        const {
            patientDOB,
            city,
            province,
            postal_code,
            healthInsuranceCardNumber,
            healthInsuranceCardFront,
            healthInsuranceCardBack,
            gender,
            patient_profile,
            endTime,
            start_timestamp,
            clinicAddress,
            doctorImage,
            licenseNo
        } = this.props;


        const element = <MyPdf
            symptoms={symptoms}
            plan={plan}
            assessment={assessment}
            findings={findings}
            icd={icd}
            followuptext={followUpDays !== 0 ? followUpDays + ' days' : 'as needed'}
            video_consult={video_consult}
            encounter_duration={encounter_duration}
            special_referals={special_referals}
            requisitions={requisitions}
            prescriptions={prescriptions}
            patient_issue={symptoms}
            patientName={patientName}
            patientDOB={patientDOB}
            doctorName={this.props.doctorName}
            clinicName={clinic_name}
            doctorImage={this.props.doctorImage}
            signatureImageURL={this.props.signatureURL}
            doctorlicense={licenseNo}
            clinicPhoneNumber={phone}
            clinicAddress={address + ' ' + city + ' ' + province + ' ' + country}
            clinicFaxNumber={fax}
            familyProblems={familyProblems}
            healthProblems={healthProblems}
            patient_profile={patient_profile}
            endTime={endTime}
            start_timestamp={start_timestamp}
            clinicPostalCode={postal_code}
            currentDoctorProfile={this.props.staffData}
        />;
        const myPdf = pdf();
        myPdf.updateContainer(element);
        const blob = await myPdf.toBlob();
        var pname = this.props.patientName.toLowerCase();
        var dname = full_name.toLowerCase();
        while (pname.indexOf(' ') > -1) {
            pname = pname.replace(' ', '-');
        }
        while (dname.indexOf(' ') > -1) {
            dname = dname.replace(' ', '-');
        }
        let filename = "";
        let index = this.props.soapnotespdf.indexOf('soap_notes');
        if (index > -1) {
            filename = this.props.soapnotespdf.substr(index + 11, this.props.soapnotespdf.length);
        } else {
            filename = pname + "-" + dname + "-" + this.props.start_timestamp + ".pdf";
        }
        //patientname-doctorname-datetimestamp.pdf
        var file = new File([blob], filename, {lastModified: (new Date()).getTime()});
        const formData = new FormData();
        formData.append('file', file);
        fetch(`https://reports.staging.yourdoctors.online/storage/uploadPDFFile`, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            body: formData// body data type must match "Content-Type" header
        }).then(response1 => response1.json())
            .then((data) => {
                this.props.updateClinicalOutPatientForm(patientName, doctorName, archive_userID, archive_conversationID,
                     findings, assessment, plan, icd, billing_code, billing_unit,
                    prescriptions, requisitions, special_referals, video_consult, followUpDays, this.props.clinicId, symptoms);
            })
            .catch(error => {
                console.log("pdf storing error:", error);
                this.props.updateClinicalOutPatientForm(patientName, doctorName, archive_userID, archive_conversationID,
                     findings, assessment, plan, icd, billing_code, billing_unit,
                    prescriptions, requisitions, special_referals, video_consult, followUpDays, this.props.clinicId, symptoms);
            })

    }

    closeOnSaveClick = () => {
        this.props.hideModal();
        this.setState(this.baseState);
        this.props.refreshState();
    }

    onSubjectSymptomsChange = (event) => {
        this.setState({symptoms: event.target.value});
    }

    filterUsers = (event) => {
        var keyword = event.toLowerCase();
        var filtered_cannedResponses = this.state.canned_responses.filter((user) => {
            user = user.shortcut.toLowerCase();
            return user.indexOf(keyword) > -1;
        });
        if (filtered_cannedResponses.length !== 0) {
            this.setState({canned_responses: filtered_cannedResponses});
        } else {
            this.setState({canned_responses: this.props.clinic_canned_responses});
        }
    }

    filterAssessment = (event) => {
        var keyword = event.toLowerCase();
        var filtered_cannedResponses = this.state.canned_responses_assessment.filter((user) => {
            user = user.shortcut.toLowerCase();
            return user.indexOf(keyword) > -1;
        });
        if (filtered_cannedResponses.length !== 0) {
            this.setState({canned_responses_assessment: filtered_cannedResponses});
        } else {
            this.setState({canned_responses_assessment: this.props.clinic_canned_responses_assessment});
        }
    }

    filterPlan = (event) => {
        var keyword = event.toLowerCase();
        var filtered_cannedResponses = this.state.canned_responses_plan.filter((user) => {
            user = user.shortcut.toLowerCase();
            return user.indexOf(keyword) > -1;
        });
        if (filtered_cannedResponses.length !== 0) {
            this.setState({canned_responses_plan: filtered_cannedResponses});
        } else {
            this.setState({canned_responses_plan: this.props.clinic_canned_responses_plan});
        }
    }

    onObjectiveFindingsChange = (event) => {
        if ((event.target.value).match('#')) {
            this.setState({showCannedResponseForObjectiveFindingsList: true});
            this.filterUsers((event.target.value.slice((event.target.value).search('#'), event.target.value.length)));
        }
        this.setState({findings: event.target.value});
    }

    onAssessmentGoalsChange = (event) => {
        if ((event.target.value).match('#')) {
            this.setState({showCannedResponseForAssessmentList: true});
            this.filterAssessment((event.target.value.slice((event.target.value).search('#'), event.target.value.length)));
        }
        this.setState({assessment: event.target.value});
    }

    onPlanChange = (event) => {
        if ((event.target.value).match('#')) {
            this.setState({showCannedResponseForPlanList: true});
            this.filterPlan((event.target.value.slice((event.target.value).search('#'), event.target.value.length)));
        }
        this.setState({plan: event.target.value});
    }

    onCannedResponseClick = (uid, short_cut, text) => {
        this.setState({showCannedResponseForObjectiveFindingsList: false});
        var updatedString;

        if (this.state.findings.search('#') !== -1) {
            var replaceText = (this.state.findings.slice((this.state.findings).search('#'), this.state.findings.length));
            updatedString = (this.state.findings).replace(replaceText, text);
        } else {
            updatedString = (this.state.findings).concat(' ', text);
        }
        this.setState({findings: updatedString});
    }

    onAssessmentCannedResponseClick = (uid, short_cut, text) => {
        this.setState({showCannedResponseForAssessmentList: false});
        var updatedString;

        if (this.state.assessment.search('#') !== -1) {
            var replaceText = (this.state.assessment.slice((this.state.assessment).search('#'), this.state.assessment.length));
            updatedString = (this.state.assessment).replace(replaceText, text);
        } else {
            updatedString = (this.state.assessment).concat(' ', text);
        }
        this.setState({assessment: updatedString});
    }

    onPlanCannedResponseClick = (uid, short_cut, text) => {
        this.setState({showCannedResponseForPlanList: false});
        var updatedString;

        if (this.state.plan.search('#') !== -1) {
            var replaceText = (this.state.plan.slice((this.state.plan).search('#'), this.state.plan.length));
            updatedString = (this.state.plan).replace(replaceText, text);
        } else {
            updatedString = (this.state.plan).concat(' ', text);
        }
        this.setState({plan: updatedString});
    }

    onCodeSelect = (event) => {
        this.setState({billing_code: event.target.value});
    }

    onBillingUnit = (event) => {
        this.setState({billing_unit: event.target.value});
    }

    renderPopOut() {
        if (this.modalWindow) {
            let root = this.modalWindow.document.body;

            const {
                clinicId, clinic_name, full_name, address, country,
                phone, fax, license_no, profile_image, signature
            } = this.props;

            const {
                symptoms, findings, assessment, plan, icd, billing_code, billing_unit,
                followUpCheck, dateTime, prescriptions, requisitions, special_referals,
                encounter_duration, video_consult, followUpDays
            } = this.state;

            const {
                patientDOB,
                city,
                province,
                postal_code,
                healthInsuranceCardNumber,
                healthInsuranceCardFront,
                healthInsuranceCardBack,
                gender,
                patient_profile,
                endTime,
                start_timestamp,
                patientName,
                clinicAddress,
                doctorImage,
                licenseNo,
                signatureURL,
                archive_healthCardNumber
            } = this.props;

            ReactDOM.render(
                <MyDocument
                    {...this.props}
                    plan={plan}
                    assessment={assessment}
                    findings={findings}
                    icd={icd}
                    followuptext={followUpDays !== 0 ? followUpDays + ' days' : 'as needed'}
                    video_consult={video_consult}
                    encounter_duration={encounter_duration}
                    special_referals={special_referals}
                    requisitions={requisitions}
                    prescriptions={prescriptions}
                    patient_issue={symptoms}
                    patientName={patientName}
                    patientDOB={patientDOB}
                    doctorName={this.props.doctorName}
                    clinicName={clinic_name}
                    doctorImage={this.props.doctorImage}
                    signatureImageURL={this.props.signatureURL}
                    doctorlicense={licenseNo}
                    clinicPhoneNumber={phone}
                    clinicAddress={address + ' ' + city + ' ' + province + ' ' + country}
                    clinicFaxNumber={fax}
                    familyProblems={familyProblems}
                    healthProblems={healthProblems}
                    patient_profile={patient_profile}
                    endTime={endTime}
                    start_timestamp={start_timestamp}
                    user_medications={this.props.archive_patientMedicationData}
                    clinicPostalCode={postal_code}
                    currentDoctorProfile={{
                        ...this.props.staffData,
                        healthInsuranceCardNumber: archive_healthCardNumber
                    }}
                />, root);
        }
    }

    render() {
        const {
            symptoms, findings, assessment, plan, icd, opinionDescription,
            opinionDescriptionValue, canned_responses, canned_responses_assessment, canned_responses_plan,
            billing_codes, billing_code, billing_unit, showIndicator, followUpDays
        } = this.state;

        return (
            <div className={classes.modal} style={{
                transform: this.props.show ? 'translateY(0)' : 'translateY(-100vh)',
                opacity: this.props.show ? '1' : '0'
            }}>

                <div className={classes.header}>SOAP Note</div>
                {this.props.message === '' &&
                <div className={classes.container}>
                    <div className={classes.content}>
                        <div className={classes.main}>
                            <div className={classes.symptomsText}>Subjective Symptoms:</div>
                            <TextareaAutosize
                                className={classes.input}
                                onChange={(text) => this.onSubjectSymptomsChange(text)}
                                value={symptoms}
                            />
                        </div>
                        <div className={classes.main}>
                            <div className={classes.symptomsText}>Objective Findings:</div>
                            <TextareaAutosize
                                className={classes.input}
                                placeholder={'Start typing here...'}
                                onChange={(text) => this.onObjectiveFindingsChange(text)}
                                value={findings}
                            />
                            {this.state.showCannedResponseForObjectiveFindingsList &&
                            <div className={classes.canned_response_list}>
                                <div className={classes.canned_response_view}>
                                    <CannedResponsesList
                                        canned_responses_data={canned_responses}
                                        onCannedResponseClick={(uid, short_cut, text) => this.onCannedResponseClick(uid, short_cut, text)}
                                    />
                                </div>
                            </div>
                            }
                        </div>
                        <div className={classes.diagnosticMain}>
                            <div className={classes.diagnosisText}>Assessment:</div>
                            <TextareaAutosize
                                placeholder={'Start typing here...'}
                                className={classes.input}
                                onChange={(text) => this.onAssessmentGoalsChange(text)}
                                value={assessment}
                            />
                            {this.state.showCannedResponseForAssessmentList &&
                            <div className={classes.canned_response_list}>
                                <div className={classes.canned_response_view}>
                                    <CannedResponsesList
                                        canned_responses_data={canned_responses_assessment}
                                        onCannedResponseClick={(uid, short_cut, text) => this.onAssessmentCannedResponseClick(uid, short_cut, text)}
                                    />
                                </div>
                            </div>
                            }
                        </div>
                        <div className={classes.main}>
                            <div className={classes.symptomsText}>Plan:</div>
                            <TextareaAutosize
                                placeholder={'Start typing here...'}
                                className={classes.input}
                                onChange={(text) => this.onPlanChange(text)}
                                value={plan}
                            />
                            {this.state.showCannedResponseForPlanList &&
                            <div className={classes.canned_response_list}>
                                <div className={classes.canned_response_view}>
                                    <CannedResponsesList
                                        canned_responses_data={canned_responses_plan}
                                        onCannedResponseClick={(uid, short_cut, text) => this.onPlanCannedResponseClick(uid, short_cut, text)}
                                    />
                                </div>
                            </div>
                            }
                        </div>
                        <div className={classes.diagnosticMain}>
                            <div className={classes.diagnosisText}>Diagnosis(ICD):</div>
                            <input
                                className={classes.diagnoticInput}
                                onChange={(text) => this.diagnosisInput(text)}
                                value={icd}
                            />
                            {showIndicator &&
                            <div className={classes.iconContainer}>
                                <i className={classes.loader}></i>
                            </div>
                            }
                            <ul className={classes.listView}
                                style={{display: this.state.displayDiagnosisList ? 'block' : 'none'}}>
                                <ListItem
                                    data={this.state.events}
                                    onSelectDiagnosisIssue={(data, title, id) => this.onSelectDiagnosisIssue(data, title, id)}
                                />
                            </ul>
                        </div>

                        <div className={classes.main}>
                            <div className={classes.followuptext}>Follow up:</div>
                            <span>Patient has been advised to follow up {followUpDays !== 0 ? followUpDays + ' days' : 'as needed'}</span>
                        </div>

                        <div className={classes.main}>
                            <div className={classes.split_half}>
                                <div className={classes.radioText}>Prescription:</div>
                                <div className={classes.radioButtonStyle}>
                                    <input type="checkbox" name="prescriptions"
                                           checked={this.state.prescriptions}
                                           onClick={() => {
                                               this.setState({prescriptions: !this.state.prescriptions})
                                           }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes.main}>
                            <div className={classes.split_half}>
                                <div className={classes.radioText}>Requisitions:</div>
                                <div className={classes.radioButtonStyle}>
                                    <input type="checkbox" name="requisitions"
                                           checked={this.state.requisitions}
                                           onClick={() => {
                                               this.setState({requisitions: !this.state.requisitions})
                                           }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes.main}>
                            <div className={classes.split_half}>
                                <div className={classes.radioText}>Referral:</div>
                                <div className={classes.radioButtonStyle}>
                                    <input type="checkbox" name="special_referals"
                                           checked={this.state.special_referals}
                                           onClick={() => {
                                               this.setState({special_referals: !this.state.special_referals})
                                           }}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div className={classes.main_with_border}>
                                <div className={classes.split_half}>
                                    <div className={classes.radioText}>Video Conference: </div>
                                    <div className={classes.radioButtonStyle}>
                                        <input type="checkbox" name="video_consult"
                                            checked={this.state.video_consult}
                                            onClick={()=>{ this.setState({video_consult: !this.state.video_consult }) }}
                                        />
                                    </div>
                                </div>
                            </div>  */}

                        <div className={classes.billingMain}>
                            <div className={classes.billingText}>Billing Code:</div>
                            <select className={classes.smallInput} value={billing_code}
                                    onChange={(code) => this.onCodeSelect(code)}>
                                <BillingCodeOption
                                    codes={billing_codes}
                                />
                            </select>
                            <div className={classes.billingCodeText}>Billing Unit:</div>
                            <input
                                className={classes.smallInput}
                                onChange={(text) => this.onBillingUnit(text)}
                                value={billing_unit}
                                type="number"
                                placeholder={'Billing Unit'}
                            />
                        </div>
                    </div>
                    {!this.props.loading &&
                    <div className={classes.buttonView}>
                        <button className={classes.addMoreMediciensButton}
                                onClick={() => this.updateOutPatientFormClick()}>Update and Close
                        </button>
                        <button className={classes.cancelButton} onClick={() => {
                            const modalTitle = "Virtual Clinic Visit Report";
                            if (!Boolean(this.modalWindow)) {
                                this.modalWindow = window.open("/clinicalreport", modalTitle);
                                this.modalWindow.addEventListener('load', () => {
                                    this.modalWindow.document.title = modalTitle;
                                    this.renderPopOut();
                                }, false);
                            } else {
                                this.modalWindow.close();
                                this.modalWindow = window.open("/clinicalreport", modalTitle);
                                this.modalWindow.addEventListener('load', () => {
                                    this.modalWindow.document.title = modalTitle;
                                    this.renderPopOut();
                                }, false);
                            }
                        }}> View PDF
                        </button>
                        <button className={classes.cancelButton} onClick={() => this.onCancelClick()}>Cancel</button>
                    </div>
                    }
                    {this.props.error !== '' &&
                    <div className={classes.buttonView}>
                        <div className={classes.errorText}>{this.props.error}</div>
                        <button className={classes.addMoreMediciensButton} onClick={() => this.onCancelClick()}>Cancel
                        </button>
                    </div>
                    }

                    {this.props.loading &&
                    <div className={classes.buttonView}>
                        <div className={classes.no_loading_view}>
                            <div className={classes.loading_loader}/>
                        </div>
                    </div>
                    }
                </div>
                }
                {this.props.message !== '' &&
                <div className={classes.saveView}>
                    <div className={classes.messageText}>{this.props.message}</div>
                    <button className={classes.cancelButton} onClick={() => this.closeOnSaveClick()}>OK</button>
                </div>
                }
            </div>
        )
    }
}

const mapStateToProps = ({
                             outPatientFormReducer, clinicalcannedResponseReducer, clinicBillingCodesReducer,
                             clinicProfileSettingReducer
                         }) => {

    const {
        clinicId, clinic_name, full_name, address, city, province, country, phone, fax, license_no,
        profile_image, signature, postal_code
    } = clinicProfileSettingReducer;

    const {loading, message, error} = outPatientFormReducer;
    const {
        clinic_canned_responses,
        clinic_canned_responses_assessment,
        clinic_canned_responses_plan
    } = clinicalcannedResponseReducer;
    const {clinic_billing_codes} = clinicBillingCodesReducer;

    return {
        loading, message, error, clinic_canned_responses, clinic_canned_responses_assessment,
        clinic_canned_responses_plan, clinic_billing_codes, clinicId, clinic_name, full_name, address, city,
        province, country, phone, fax, license_no, profile_image, signature, postal_code
    };
}

export default connect(mapStateToProps, {
    updateClinicalOutPatientForm, refreshState,
    clinic_fetchCannedResponsesForObjectiveFindings, clinic_fetchCannedResponsesForAssessment,
    clinic_fetchCannedResponsesForPlan, clinic_fetchCannedResponsesForChats,
    clinic_setSubjectiveSymptoms, clinic_setFindings,
    clinic_setAssessment, clinic_setPlan,
    clinic_fetchBillingCodes
})(PatientForm);
