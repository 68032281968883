import  {Fragment} from 'react';
import classes from './navigation.module.css';
import NavigationItem from './navigationItem';

const Navigation = (props) => {
    return (
        <nav className={classes.nav} id="mainNav">
            <div className={classes.firstNavBar}>
                <ul className={classes.NavigationItems}>
                    {(props.adminStatus || !props.clinicalDoctor) &&
                    <Fragment>
                        <NavigationItem link="/chats" navClick={(data) => props.navClick(data)}
                                        path={<svg fill="#ffffff" width="22px" height="22px" viewBox="0 0 24 24">
                                            <path fill="inherit" stroke="null"
                                                  transform="rotate(0.35051098465919495 11.000000000000743,10.999999999999918) "
                                                  fillRule="nonzero"
                                                  d="m19.1,5.6l-1.8,0l0,8.1l-11.7,0l0,1.8c0,0.495 0.405,0.9 0.9,0.9l9.9,0l3.6,3.6l0,-13.5c0,-0.495 -0.405,-0.9 -0.9,-0.9zm-3.6,5.4l0,-8.1c0,-0.495 -0.405,-0.9 -0.9,-0.9l-11.7,0c-0.495,0 -0.9,0.405 -0.9,0.9l0,12.6l3.6,-3.6l9,0c0.495,0 0.9,-0.405 0.9,-0.9z"></path>
                                        </svg>}>Chats</NavigationItem>
                        {props.adminStatus && <NavigationItem link="/customers" navClick={(data) => props.navClick(data)}
                                         path={<svg fill="#ffffff" width="24px" height="24px">
                                             <g fill="inherit" fillRule="evenodd">
                                                 <path
                                                     d="M19.778 3.2h-1.111V1h-2.223v2.2H7.556V1H5.333v2.2h-1.11A2.21 2.21 0 0 0 2 5.4v15.4c0 1.21.989 2.2 2.222 2.2h15.556C21 23 22 22.01 22 20.8V5.4c0-1.21-1-2.2-2.222-2.2zM12 6.5c1.844 0 3.333 1.474 3.333 3.3 0 1.826-1.489 3.3-3.333 3.3s-3.333-1.474-3.333-3.3c0-1.826 1.489-3.3 3.333-3.3zm6.667 13.2H5.333v-1.1c0-2.2 4.445-3.41 6.667-3.41 2.222 0 6.667 1.21 6.667 3.41v1.1z"
                                                     fillRule="nonzero"></path>
                                             </g>
                                         </svg>}>Customers</NavigationItem>}
                        {props.adminStatus && <NavigationItem link="/patients" navClick={(data) => props.navClick(data)}
                                        path={<svg fill="#ffffff" width="24px" height="24px">
                                            <path
                                                d="M15.636 11.571c1.51 0 2.719-1.244 2.719-2.785 0-1.542-1.21-2.786-2.719-2.786S12.91 7.244 12.91 8.786c0 1.541 1.218 2.785 2.727 2.785zm-7.272 0c1.509 0 2.718-1.244 2.718-2.785C11.082 7.244 9.872 6 8.364 6c-1.51 0-2.728 1.244-2.728 2.786 0 1.541 1.219 2.785 2.728 2.785zm0 1.858C6.245 13.429 2 14.515 2 16.679V19h12.727v-2.321c0-2.164-4.245-3.25-6.363-3.25zm7.272 0c-.263 0-.563.018-.881.046 1.054.78 1.79 1.83 1.79 3.204V19H22v-2.321c0-2.164-4.245-3.25-6.364-3.25z"
                                                fillRule="nonzero"></path>
                                        </svg>}>Patients</NavigationItem>}
                        <NavigationItem link="/archives" navClick={(data) => props.navClick(data)}
                                        path={<svg fill="#ffffff" width="24px" height="24px">
                                            <g fill="inherit" fillRule="evenodd">
                                                <path
                                                    d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm4.2 14.2L11 13V7h1.5v5.2l4.5 2.7-.8 1.3z"
                                                    fillRule="nonzero"></path>
                                            </g>
                                        </svg>}>Archives</NavigationItem>
                    </Fragment>
                    }
                    {(props.adminStatus || props.clinicalDoctor) &&
                    <NavigationItem link="/clinic_chats" navClick={(data) => props.navClick(data)}
                                    path={<svg fill="#ffffff" width="24px" height="24px" aria-hidden="true"
                                               focusable="false" data-prefix="fas" data-icon="clinic-medical"
                                               className="svg-inline--fa fa-clinic-medical fa-w-18" role="img"
                                               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                        <path fill="currentColor"
                                              d="M288 115L69.47 307.71c-1.62 1.46-3.69 2.14-5.47 3.35V496a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V311.1c-1.7-1.16-3.72-1.82-5.26-3.2zm96 261a8 8 0 0 1-8 8h-56v56a8 8 0 0 1-8 8h-48a8 8 0 0 1-8-8v-56h-56a8 8 0 0 1-8-8v-48a8 8 0 0 1 8-8h56v-56a8 8 0 0 1 8-8h48a8 8 0 0 1 8 8v56h56a8 8 0 0 1 8 8zm186.69-139.72l-255.94-226a39.85 39.85 0 0 0-53.45 0l-256 226a16 16 0 0 0-1.21 22.6L25.5 282.7a16 16 0 0 0 22.6 1.21L277.42 81.63a16 16 0 0 1 21.17 0L527.91 283.9a16 16 0 0 0 22.6-1.21l21.4-23.82a16 16 0 0 0-1.22-22.59z"></path>
                                    </svg>}>Clinic</NavigationItem>
                    }
                    {props.clinicalDoctor &&
                    <Fragment>
                        <NavigationItem link="/clinic_doctors" navClick={(data) => props.navClick(data)}
                                        path={<svg fill="#ffffff" width="24px" height="24px">
                                            <path
                                                d="M15.636 11.571c1.51 0 2.719-1.244 2.719-2.785 0-1.542-1.21-2.786-2.719-2.786S12.91 7.244 12.91 8.786c0 1.541 1.218 2.785 2.727 2.785zm-7.272 0c1.509 0 2.718-1.244 2.718-2.785C11.082 7.244 9.872 6 8.364 6c-1.51 0-2.728 1.244-2.728 2.786 0 1.541 1.219 2.785 2.728 2.785zm0 1.858C6.245 13.429 2 14.515 2 16.679V19h12.727v-2.321c0-2.164-4.245-3.25-6.363-3.25zm7.272 0c-.263 0-.563.018-.881.046 1.054.78 1.79 1.83 1.79 3.204V19H22v-2.321c0-2.164-4.245-3.25-6.364-3.25z"
                                                fillRule="nonzero"></path>
                                        </svg>}>Doctors</NavigationItem>
                        <NavigationItem link="/clinic_archives" navClick={(data) => props.navClick(data)}
                                        path={<svg fill="#ffffff" width="24px" height="24px">
                                            <g fill="inherit" fillRule="evenodd">
                                                <path
                                                    d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm4.2 14.2L11 13V7h1.5v5.2l4.5 2.7-.8 1.3z"
                                                    fillRule="nonzero"></path>
                                            </g>
                                        </svg>}>Archives</NavigationItem>
                        <NavigationItem link="/clinic_patients" navClick={(data) => props.navClick(data)}
                                        path={<svg fill="#ffffff" width="24px" height="24px">
                                            <g fill="inherit" fillRule="evenodd">
                                                <path
                                                    d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm4.2 14.2L11 13V7h1.5v5.2l4.5 2.7-.8 1.3z"
                                                    fillRule="nonzero"></path>
                                            </g>
                                        </svg>}>Patients</NavigationItem>
                    </Fragment>
                    }
                    {props.adminStatus &&
                    <NavigationItem link="/agents" navClick={(data) => props.navClick(data)}
                                    path={<svg fill="#ffffff" width="24px" height="24px">
                                        <path
                                            d="M15.636 11.571c1.51 0 2.719-1.244 2.719-2.785 0-1.542-1.21-2.786-2.719-2.786S12.91 7.244 12.91 8.786c0 1.541 1.218 2.785 2.727 2.785zm-7.272 0c1.509 0 2.718-1.244 2.718-2.785C11.082 7.244 9.872 6 8.364 6c-1.51 0-2.728 1.244-2.728 2.786 0 1.541 1.219 2.785 2.728 2.785zm0 1.858C6.245 13.429 2 14.515 2 16.679V19h12.727v-2.321c0-2.164-4.245-3.25-6.363-3.25zm7.272 0c-.263 0-.563.018-.881.046 1.054.78 1.79 1.83 1.79 3.204V19H22v-2.321c0-2.164-4.245-3.25-6.364-3.25z"
                                            fillRule="nonzero"></path>
                                    </svg>}>Doctors</NavigationItem>
                    }
                    {(!props.adminStatus && !props.clinicalDoctor) &&
                    <NavigationItem link="/doctors" navClick={(data) => props.navClick(data)}
                                    path={<svg fill="#ffffff" width="24px" height="24px">
                                        <path
                                            d="M15.636 11.571c1.51 0 2.719-1.244 2.719-2.785 0-1.542-1.21-2.786-2.719-2.786S12.91 7.244 12.91 8.786c0 1.541 1.218 2.785 2.727 2.785zm-7.272 0c1.509 0 2.718-1.244 2.718-2.785C11.082 7.244 9.872 6 8.364 6c-1.51 0-2.728 1.244-2.728 2.786 0 1.541 1.219 2.785 2.728 2.785zm0 1.858C6.245 13.429 2 14.515 2 16.679V19h12.727v-2.321c0-2.164-4.245-3.25-6.363-3.25zm7.272 0c-.263 0-.563.018-.881.046 1.054.78 1.79 1.83 1.79 3.204V19H22v-2.321c0-2.164-4.245-3.25-6.364-3.25z"
                                            fillRule="nonzero"></path>
                                    </svg>}>Doctors</NavigationItem>
                    }

                    {/* (props.adminStatus || !props.clinicalDoctor) &&
                        <NavigationItem link="/tickets" navClick={(data) => props.navClick(data)} path={<svg fill="#ffffff" width="24px" height="24px"><g fill="inherit" fillRule="evenodd"><path d="M19.2 12c0-.963.81-1.75 1.8-1.75v-3.5C21 5.787 20.19 5 19.2 5H4.8c-.99 0-1.791.787-1.791 1.75v3.5c.99 0 1.791.787 1.791 1.75s-.801 1.75-1.8 1.75v3.5c0 .962.81 1.75 1.8 1.75h14.4c.99 0 1.8-.788 1.8-1.75v-3.5c-.99 0-1.8-.787-1.8-1.75z" fillRule="nonzero"></path></g></svg>}>Tickets</NavigationItem>
                    */}
                    {props.adminStatus &&
                    <NavigationItem link="/activities" navClick={(data) => props.navClick(data)}
                                    path={<svg fill="#ffffff" width="24px" height="24px">
                                        <g fill="inherit" fillRule="evenodd">
                                            <path
                                                d="M20 12c0-1.1.9-2 2-2V6c0-1.1-.9-2-2-2H4c-1.1 0-1.99.9-1.99 2v4c1.1 0 1.99.9 1.99 2s-.89 2-2 2v4c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-4c-1.1 0-2-.9-2-2zm-4.42 4.8L12 14.5l-3.58 2.3 1.08-4.12-3.29-2.69 4.24-.25L12 5.8l1.54 3.95 4.24.25-3.29 2.69 1.09 4.11z"
                                                fillRule="nonzero"></path>
                                        </g>
                                    </svg>}>Activity</NavigationItem>
                    }
                    {props.clinicalDoctor &&
                    <NavigationItem link="/clinical_reports" navClick={(data) => props.navClick(data)}
                                    path={<svg fill="#ffffff" width="24px" height="24px">
                                        <g fill="inherit" fillRule="evenodd">
                                            <path
                                                d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z"
                                                fillRule="nonzero"></path>
                                        </g>
                                    </svg>}>Reports</NavigationItem>
                    }
                    {props.adminStatus &&
                    <NavigationItem link="/reports" navClick={(data) => props.navClick(data)}
                                    path={<svg fill="#ffffff" width="24px" height="24px">
                                        <g fill="inherit" fillRule="evenodd">
                                            <path
                                                d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z"
                                                fillRule="nonzero"></path>
                                        </g>
                                    </svg>}>Reports</NavigationItem>
                    }
                </ul>
            </div>
            <div className={classes.secondNavBar}>
                <ul className={classes.NavigationItems}>
                    {!props.clinicalDoctor &&
                    <NavigationItem link="/settings" navClick={(data) => props.navClick(data)}
                                    path={<svg fill="#ffffff" width="24px" height="24px">
                                        <g fill="inherit" fillRule="evenodd">
                                            <path
                                                d="M18.877 13.16a7.21 7.21 0 0 0 .064-.908c0-.314-.027-.61-.064-.906l1.952-1.527a.467.467 0 0 0 .11-.592l-1.85-3.201c-.11-.204-.36-.278-.564-.204l-2.304.925a6.76 6.76 0 0 0-1.563-.906l-.352-2.452A.451.451 0 0 0 13.853 3h-3.701a.451.451 0 0 0-.454.389L9.347 5.84a7.109 7.109 0 0 0-1.564.906l-2.304-.925a.451.451 0 0 0-.564.204l-1.85 3.2a.456.456 0 0 0 .11.593l1.952 1.527a7.338 7.338 0 0 0-.064.906c0 .306.027.611.064.907l-1.952 1.527a.467.467 0 0 0-.11.592l1.85 3.201c.11.204.36.278.564.204l2.304-.925c.481.37 1 .675 1.564.906l.351 2.452a.451.451 0 0 0 .454.389h3.7a.451.451 0 0 0 .454-.389l.352-2.452a7.109 7.109 0 0 0 1.563-.906l2.304.925c.213.083.453 0 .564-.204l1.85-3.201a.467.467 0 0 0-.11-.592l-1.952-1.527zm-6.875 2.33a3.242 3.242 0 0 1-3.238-3.238 3.242 3.242 0 0 1 3.238-3.238 3.242 3.242 0 0 1 3.238 3.238 3.242 3.242 0 0 1-3.238 3.239z"
                                                fillRule="nonzero"></path>
                                        </g>
                                    </svg>}>Settings</NavigationItem>
                    }
                    {props.clinicalDoctor &&
                    <NavigationItem link="/clinic_settings" navClick={(data) => props.navClick(data)}
                                    path={<svg fill="#ffffff" width="24px" height="24px">
                                        <g fill="inherit" fillRule="evenodd">
                                            <path
                                                d="M18.877 13.16a7.21 7.21 0 0 0 .064-.908c0-.314-.027-.61-.064-.906l1.952-1.527a.467.467 0 0 0 .11-.592l-1.85-3.201c-.11-.204-.36-.278-.564-.204l-2.304.925a6.76 6.76 0 0 0-1.563-.906l-.352-2.452A.451.451 0 0 0 13.853 3h-3.701a.451.451 0 0 0-.454.389L9.347 5.84a7.109 7.109 0 0 0-1.564.906l-2.304-.925a.451.451 0 0 0-.564.204l-1.85 3.2a.456.456 0 0 0 .11.593l1.952 1.527a7.338 7.338 0 0 0-.064.906c0 .306.027.611.064.907l-1.952 1.527a.467.467 0 0 0-.11.592l1.85 3.201c.11.204.36.278.564.204l2.304-.925c.481.37 1 .675 1.564.906l.351 2.452a.451.451 0 0 0 .454.389h3.7a.451.451 0 0 0 .454-.389l.352-2.452a7.109 7.109 0 0 0 1.563-.906l2.304.925c.213.083.453 0 .564-.204l1.85-3.201a.467.467 0 0 0-.11-.592l-1.952-1.527zm-6.875 2.33a3.242 3.242 0 0 1-3.238-3.238 3.242 3.242 0 0 1 3.238-3.238 3.242 3.242 0 0 1 3.238 3.238 3.242 3.242 0 0 1-3.238 3.239z"
                                                fillRule="nonzero"></path>
                                        </g>
                                    </svg>}>Settings</NavigationItem>
                    }
                    <NavigationItem link="#" profileImage={props.profileImage}
                                    navClick={(data) => props.navClick(data)}>Profile</NavigationItem>
                </ul>
            </div>
        </nav>
    )
};

export default Navigation;
