import {CHECK_ADMIN} from '../constants';
import {auth} from "../firebase";

export const checkAdmin = () => {
    return (dispatch) => {
        const {currentUser} = auth;
        currentUser.getIdTokenResult().then(idTokenResult => {
            if (idTokenResult.claims.admin) {
                dispatch({
                    type: CHECK_ADMIN,
                    payload: {
                        admin: idTokenResult.claims.admin,
                        clinicalStatus: idTokenResult.claims.clinicalDoctor
                    }
                })
            } else {
                dispatch({
                    type: CHECK_ADMIN,
                    payload: {
                        admin: false,
                        clinicalStatus: idTokenResult.claims.clinicalDoctor
                    }
                })
            }
        });
    }
}
