import  {Component} from 'react';
import {connect} from 'react-redux';
import MetaTags from 'react-meta-tags';

import classes from '../../../assets/classes.module.css';

import Input from '../../../components/UI/Input/ClinicInput';
import ClipLoader from 'react-spinners/ClipLoader';

import logo from '../../../assets/logo.png';

import {create_clinic} from '../../../actions/clinic/create/CreateClinicAction';

class CreateClinic extends Component {

    state = {
        controls: {
            full_name: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Full Name',
                    tooltip: 'Your full name that patients will be seeing',
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false
            },
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'Email Address',
                    tooltip: 'This is the email address that you will be using to login',
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            },
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Password',
                    tooltip: 'Try to keep your password secure by using at least 8 digits with one capital letter, one special character, and one number',
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false
            },
            clinic_name: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Clinic Name',
                    tooltip: 'The clinic name will be seen by your staff and patients',
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false
            }
        }
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    inputChangedHandler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: this.checkValidity(event.target.value, this.state.controls[controlName].validation),
                touched: true
            }
        };
        this.setState({controls: updatedControls});
    }

    submitHandler = (event) => {
        event.preventDefault();
        var self = this;
        this.props.create_clinic(
            this.state.controls.full_name.value,
            this.state.controls.email.value,
            this.state.controls.password.value,
            this.state.controls.clinic_name.value,
        );
    }

    onLoginClick = () => {
        const path = {
            pathname: '/auth',
            search: '',
            hash: '',
        }
        this.props.history.push(path);
    }

    render() {

        const formElementsArray = [];
        for (let key in this.state.controls) {
            formElementsArray.push({
                id: key,
                config: this.state.controls[key]
            });
        }

        let errorMessage = null;

        if (this.props.error && this.props.step === 0) {
            errorMessage = (
                <div className={classes.error_container}>
                    <div className={classes.absolute_error_bar}/>
                    <div className={classes.error}>{this.props.error}</div>
                </div>
            );
        }

        let form = (
            <div>
                {formElementsArray.map(formElement => (
                    <Input
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)}/>
                ))}
                {errorMessage}
                <div className={classes.bottom_view}>
                    <p>Already have a clinic?</p>
                    <button className={classes.get_started} onClick={(event) => this.onLoginClick(event)}>Click here
                    </button>
                    <p>to login</p>
                    <button className={classes.button} onClick={this.submitHandler}>Create your clinic</button>
                </div>
            </div>
        );

        const {loading, step, error, user} = this.props;
        return (
            <div className={classes.container}>
                <MetaTags>
                    <title>Get Started | Your Doctors Online</title>
                    <meta name="get_started" content="Get started."/>
                </MetaTags>
                <div className={classes.content}>
                    {step === 0 &&
                    <div className={classes.main_view}>
                        <div className={classes.logo}>
                            <img src={logo} alt="logo" className={classes.logo_container}/>
                        </div>
                        <span className={classes.heading}>Enter your email address to <br/> make your practice go virtual</span>
                        <p className={classes.sub_heading}>Start becoming more efficient by addressing non-critical
                            patient issues virtually<br/>and removing barriers patients have visiting your clinic</p>
                        <div className={classes.form_view}>
                            {form}
                        </div>
                    </div>
                    }
                    {(step >= 1 && step < 4) && error === '' &&
                    <div className={classes.main_view}>
                        <ClipLoader
                            size={150}
                            color={"#884a9d"}
                        />
                        <p className={classes.heading}>Sit tight, we're creating your virtual clinic</p>
                        {step === 1 &&
                        <p className={classes.sub_heading}>1 of 3: Creating your account</p>
                        }
                        {step === 2 &&
                        <p className={classes.sub_heading}>2 of 3: Initializing your virtual clinic</p>
                        }
                        {step === 3 &&
                        <p className={classes.sub_heading}>3 of 3: Applying virtual clinic settings</p>
                        }
                    </div>
                    }
                    {step === 4 &&
                    <div className={classes.main_view}>
                        <span className={classes.center_heading}>Success, your virtual clinic is ready to go!</span>
                    </div>
                    }
                </div>
                {step === 0 &&
                <div className={classes.footer}>
                    <a href='https://yourdoctors.online/wp-content/uploads/2020/10/YDO_Terms_of_Use.pdf'
                       target='_blank'>Privacy & Terms</a>
                    <a href='mailto: info@yourdoctors.online'>Contact Us</a>
                </div>
                }
            </div>
        )
    }
}


const mapStateToProps = ({createClinicReducer}) => {
    const {loading, step, error, user} = createClinicReducer;
    return {loading, step, error, user};
};

export default connect(mapStateToProps, {create_clinic})(CreateClinic);
