import {
    CHANGE_CLINICAL_CHAT_STATUS_FAILED,
    CHANGE_CLINICAL_CHAT_STATUS_SUCCESS,
    CHAT_TRANSFERED_DONE,
    CLEAR_CLINICAL_UNREAD_MESSAGES_COUNT,
    CLINICAL_HEALTH_INSURANCE_CARD_UPDATED,
    CLOSE_CHAT,
    FETCH_CLINIC_MESSAGES,
    FETCH_CLINICAL_CHATS_FAILED,
    FETCH_CLINICAL_CHATS_IN_PROCESS,
    FETCH_CLINICAL_CHATS_SUCCESS,
    FETCH_CLINICAL_CONVERSATIONS_COUNT_FOR_EACH_DOCTOR,
    FETCH_PRESCRIPTIONS, FETCH_PRESCRIPTIONS_DATA, PRESCRIPTION_ISCHANGED,
    FETCH_CLINICAL_UNREAD_MESSAGES_COUNT,
    FETCH_STATUS,
    FETCHING_CLINIC_MESSAGES,
    MEDIA_HEADER_DATA,
    REFRESH_CLINICAL_CONVERSATION,
    REFRESH_MESSAGES,
    SAVE_OUT_PATIENT_FORM_IN_PROCESS,
    SAVE_OUT_PATIENT_FORM_SUCCESS,
    SENDING_CLINIC_MESSAGE,
    SET_VIDEO_STATUS,
    UPDATE_CONVERSATION_MEDICATION,
    FETCH_PRESCRIPTIONS_DATA_CHATCHANGED,
    PRESCRIPTION_ISSAVED,
    DISCARD_CHANGES,
} from "../constants.js";

import _ from "lodash";
import rootURL from "../config";
import algoliasearch from "algoliasearch";
import {ENVIRONMENT} from "../environment";
import doctorServicesUrl from "../DoctorServicesConfig";
import {auth, database} from "../firebase";
import axios from "axios";


let callbackFunc1, callbackFunc2, callbackPrescriptions, callbackPrescriptionData;
var client_store = algoliasearch("FL1OI9QKUW", "f0427fde3618b1db1bfb004c623215db");
var index_store = null;

export const setAlgolia = clinicid => {
    return dispatch => {
        try {
            if (clinicid) {
                index_store = client_store.initIndex(clinicid + "_conversations");
            } else {
                if (ENVIRONMENT === "development") {
                    index_store = client_store.initIndex("stg_conversations");
                } else {
                    index_store = client_store.initIndex("conversations");
                }
            }
        } catch (error) {
            //console.log("get_followUp error ", error);
        }
    };
};

export const refreshMessages = () => {
    return dispatch => {
        dispatch({type: REFRESH_MESSAGES});
        return callbackFunc2
            ? callbackFunc2.off("value")
            : null && callbackFunc1
                ? callbackFunc1.off()
                : null && callbackPrescriptions
                    ? callbackPrescriptions.off()
                    : null && callbackPrescriptionData
                        ? callbackPrescriptionData.off()
                    : null;
    };
};

export const setVideo = () => {
    return dispatch => {
        dispatch({type: SET_VIDEO_STATUS});
    };
};

export const refreshClinicalConversations = () => {
    return {
        type: REFRESH_CLINICAL_CONVERSATION,
    };
};

export const updateClinicalDoctor = (patientId, doctorname) => {
    return dispatch => {
        getUserRef(patientId).once("value", patientsnap => {
            if (patientsnap.val()) {
                database
                    .ref(`/users/${patientId}/clinics/${patientsnap.val().selectedClinic}`)
                    .update({
                        lastDoctorName: doctorname,
                    });
            }
        });
    };
};

export const updateInsuranceCard = (id, number, front, back, callback) => {
    return dispatch => {
        getUserRef(id)
            .update({
                healthInsuranceCardFront: front,
                healthInsuranceCardBack: back,
                healthInsuranceCardNumber: number,
            })
            .then(() => {
                callback(true);
                dispatch({
                    type: CLINICAL_HEALTH_INSURANCE_CARD_UPDATED,
                    payload: {
                        healthInsuranceCardFront: front,
                        healthInsuranceCardBack: back,
                        healthInsuranceCardNumber: number,
                    },
                });
            })
            .catch(error => {
                //console.log("error: ", error.message);
            });
    };
};

export const fetchClinicalChats = (ID, admin) => {
    return dispatch => {
        dispatch({type: FETCH_CLINICAL_CHATS_IN_PROCESS});
        //console.log("fetchClinicalChats called: ", ID);
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        var now = new Date();

        try {
            getOpenConversationsRef(ID).on("value", conversationsSnapShot => {
                if (conversationsSnapShot.val()) {
                    var count = conversationsSnapShot.numChildren();
                    var i = 0;
                    var conversations = [];
                    var promises = [];
                    //console.log("conversations: ", conversationsSnapShot.val());
                    conversationsSnapShot.forEach(childSnapshot => {
                        i++;
                        var lastMessageTimeStamp = "";
                        var unreadCount = 0;
                        if (childSnapshot.val() && childSnapshot.val().status) {
                            let location = childSnapshot.key;
                            let conversationObj = {};
                            conversationObj.patientID = childSnapshot.val().patientID;
                            conversationObj.doctorID = childSnapshot.val().doctorID;
                            conversationObj.paidChat = childSnapshot.val().paidChat;
                            conversationObj.chatInQueue = childSnapshot.val().queued;
                            conversationObj.issue =
                                childSnapshot.val().issue !== undefined && childSnapshot.val().issue !== null
                                    ? childSnapshot.val().issue
                                    : "No Issue";
                            conversationObj.category =
                                childSnapshot.val().category !== undefined && childSnapshot.val().category !== null
                                    ? childSnapshot.val().category
                                    : "No Category";
                            conversationObj.ownerChat =
                                currentUserID === childSnapshot.val().doctorID ? true : false;
                            conversationObj.conversationId = location;
                            conversationObj.unreadCount = unreadCount;
                            conversationObj.lastMessageTimeStamp = lastMessageTimeStamp;
                            conversationObj.timestamp = now.getTime();
                            conversationObj.clinicName = "";
                            conversationObj.start_timestamp = childSnapshot.val().timestamp;
                            conversationObj.clinicalChatData = childSnapshot.val();
                            conversationObj.ownerClinics = true;
                            conversationObj.admin = admin;
                            conversationObj.appActivated =
                                childSnapshot.val().appActivated === false ? false : true;
                            //console.log("conversationObj: ", conversationObj);

                            promises.push(
                                getUserRef(childSnapshot.val().patientID).once("value", fromUserSnapshot => {

                                        if (fromUserSnapshot.exists()) {
                                            //console.log("conversationObj: ", childSnapshot.val(), childSnapshot.val().patientID)
                                            let userName = fromUserSnapshot.val().userName !== null &&
                                            fromUserSnapshot.val().userName !== undefined
                                                ? fromUserSnapshot.val().userName
                                                : "Patient";
                                            userName = (userName === "No name" || userName === "Patient") &&
                                            fromUserSnapshot.val().name
                                                ? fromUserSnapshot.val().name
                                                : userName;
                                            var country = fromUserSnapshot.val().country;
                                            var city = fromUserSnapshot.val().city;

                                            conversationObj.patientName = userName;
                                            conversationObj.patientCountry = Boolean(country) ? country : "NA";
                                            conversationObj.patientCity = Boolean(city) ? city : "NA";
                                            conversationObj.platform = fromUserSnapshot.val().platform;
                                        }
                                    }
                                )
                            );
                            if (childSnapshot.val().doctorID) {
                                promises.push(
                                    getStaffMemberByRef(ID, childSnapshot.val().doctorID).once(
                                        "value",
                                        docSnapshot => {
                                            if (docSnapshot.val()) {
                                                conversationObj.doctorName = docSnapshot.val().fullName;
                                                conversationObj.activeOnlineChats = docSnapshot.val().activeOnlineChats;
                                                conversationObj.onlineFreeChats = docSnapshot.val().onlineFreeChats;
                                                conversationObj.onlinePaidChats = docSnapshot.val().onlinePaidChats;
                                                conversationObj.totalChatLimit = docSnapshot.val().totalChatLimit;
                                                var isFound = false;

                                                for (let i = 0; i < conversations.length; i++) {
                                                    if (conversations[i].conversationId === conversationObj.conversationId) {
                                                        conversations[i] = conversationObj;
                                                        isFound = true;
                                                    }
                                                }
                                                if (!isFound) conversations.push(conversationObj);
                                            } else {
                                                conversationObj.doctorName = "";
                                                conversationObj.activeOnlineChats = 0;
                                                conversationObj.onlineFreeChats = 0;
                                                conversationObj.onlinePaidChats = 0;
                                                conversationObj.totalChatLimit = 0;
                                                conversations.push(conversationObj);
                                            }
                                        }
                                    )
                                );
                            } else {
                                conversationObj.doctorName = "";
                                conversationObj.activeOnlineChats = 0;
                                conversationObj.onlineFreeChats = 0;
                                conversationObj.onlinePaidChats = 0;
                                conversationObj.totalChatLimit = 0;
                                conversations.push(conversationObj);
                            }
                        }
                    });
                    Promise.all(promises).then(function (results) {
                        //console.log("promises completed: ", conversations);
                        if (conversations.length !== 0) {
                            const bubbles = _.map(conversations, (val, uid) => {
                                return {...val, uid};
                            });
                            dispatch({
                                type: FETCH_CLINICAL_CHATS_SUCCESS,
                                payload: bubbles,
                            });
                        } else {
                            dispatch({
                                type: FETCH_CLINICAL_CHATS_FAILED,
                                payload: [],
                            });
                        }
                    });
                } else {
                    dispatch({
                        type: FETCH_CLINICAL_CHATS_FAILED,
                        payload: [],
                    });
                }
            });
        } catch (error) {
            //console.log("fetchClinicalChats error ", error);
        }
    };
};

export const OldfetchClinicalChats = (ID, admin) => {
    return dispatch => {
        dispatch({type: FETCH_CLINICAL_CHATS_IN_PROCESS});

        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        var now = new Date();

        getOpenConversationsRef(ID).on("value", conversationsSnapShot => {
            if (conversationsSnapShot.val()) {
                var count = conversationsSnapShot.numChildren();
                var i = 0;
                var conversations = [];
                var promises = [];

                conversationsSnapShot.forEach(childSnapshot => {
                    i++;
                    var lastMessageTimeStamp = "";
                    var unreadCount = 0;
                    if (childSnapshot.val() && childSnapshot.val().status) {
                        let location = childSnapshot.key;
                        let conversationObj = {};
                        conversationObj.patientID = childSnapshot.val().patientID;
                        conversationObj.doctorID = childSnapshot.val().doctorID;
                        conversationObj.paidChat = childSnapshot.val().paidChat;
                        conversationObj.chatInQueue = childSnapshot.val().queued;
                        conversationObj.issue =
                            childSnapshot.val().issue !== undefined && childSnapshot.val().issue !== null
                                ? childSnapshot.val().issue
                                : "No Issue";
                        conversationObj.category =
                            childSnapshot.val().category !== undefined && childSnapshot.val().category !== null
                                ? childSnapshot.val().category
                                : "No Category";
                        conversationObj.ownerChat =
                            currentUserID === childSnapshot.val().doctorID ? true : false;
                        conversationObj.conversationId = location;
                        conversationObj.unreadCount = unreadCount;
                        conversationObj.lastMessageTimeStamp = lastMessageTimeStamp;
                        conversationObj.timestamp = now.getTime();
                        conversationObj.clinicName = "";
                        conversationObj.start_timestamp = childSnapshot.val().timestamp;
                        conversationObj.clinicalChatData = childSnapshot.val();
                        conversationObj.ownerClinics = true;
                        conversationObj.admin = admin;
                        conversationObj.appActivated =
                            childSnapshot.val().appActivated === false ? false : true;

                        promises.push(
                            getUserRef(childSnapshot.val().patientID).once("value", fromUserSnapshot => {
                                let userName =
                                    fromUserSnapshot.val().userName !== null &&
                                    fromUserSnapshot.val().userName !== undefined
                                        ? fromUserSnapshot.val().userName
                                        : "Patient";
                                userName =
                                    (userName === "No name" || userName === "Patient") && fromUserSnapshot.val().name
                                        ? fromUserSnapshot.val().name
                                        : userName;
                                var country = fromUserSnapshot.val().country;
                                var city = fromUserSnapshot.val().city;

                                conversationObj.patientName = userName;
                                conversationObj.patientCountry = Boolean(country) ? country : "NA";
                                conversationObj.patientCity = Boolean(city) ? city : "NA";
                                conversationObj.platform = fromUserSnapshot.val().platform;
                            })
                        );
                        if (childSnapshot.val().doctorID) {
                            promises.push(
                                getStaffMemberByRef(ID, childSnapshot.val().doctorID).once("value", docSnapshot => {
                                    if (docSnapshot.val()) {
                                        conversationObj.doctorName = docSnapshot.val().fullName;
                                        conversationObj.activeOnlineChats = docSnapshot.val().activeOnlineChats;
                                        conversationObj.onlineFreeChats = docSnapshot.val().onlineFreeChats;
                                        conversationObj.onlinePaidChats = docSnapshot.val().onlinePaidChats;
                                        conversationObj.totalChatLimit = docSnapshot.val().totalChatLimit;
                                        var isFound = false;

                                        for (let i = 0; i < conversations.length; i++) {
                                            if (conversations[i].conversationId === conversationObj.conversationId) {
                                                conversations[i] = conversationObj;
                                                isFound = true;
                                            }
                                        }
                                        if (!isFound) conversations.push(conversationObj);
                                        /* if (count === i) {
                                                            const bubbles = _.map(conversations, (val, uid) => {
                                                                return { ...val, uid };
                                                            });

                                                            dispatch({
                                                                type: FETCH_CLINICAL_CHATS_SUCCESS,
                                                                payload: bubbles
                                                            })
                                                        } else {
                                                        } */
                                    } else {
                                        conversationObj.doctorName = "";
                                        conversationObj.activeOnlineChats = 0;
                                        conversationObj.onlineFreeChats = 0;
                                        conversationObj.onlinePaidChats = 0;
                                        conversationObj.totalChatLimit = 0;

                                        conversations.push(conversationObj);
                                        /* if (count === i) {
                                                            const bubbles = _.map(conversations, (val, uid) => {
                                                                return { ...val, uid };
                                                            });

                                                            dispatch({
                                                                type: FETCH_CLINICAL_CHATS_SUCCESS,
                                                                payload: bubbles
                                                            })
                                                        } else {
                                                        } */
                                        /* if (count === i) {
                                                            const bubbles = _.map(conversations, (val, uid) => {
                                                                return { ...val, uid };
                                                            });

                                                            dispatch({
                                                                type: FETCH_CLINICAL_CHATS_SUCCESS,
                                                                payload: bubbles
                                                            })
                                                        } */
                                    }
                                })
                            );
                        } else {
                            conversationObj.doctorName = "";
                            conversationObj.activeOnlineChats = 0;
                            conversationObj.onlineFreeChats = 0;
                            conversationObj.onlinePaidChats = 0;
                            conversationObj.totalChatLimit = 0;
                            conversations.push(conversationObj);
                        }
                    } else {
                        /* if (count && conversations.length === 0) {
                                        dispatch({
                                            type: FETCH_CLINICAL_CHATS_SUCCESS,
                                            payload: []
                                        })

                                    } else if (count && conversations.length !== 0) {
                                        const bubbles = _.map(conversations, (val, uid) => {
                                            return { ...val, uid };
                                        });

                                        dispatch({
                                            type: FETCH_CLINICAL_CHATS_SUCCESS,
                                            payload: bubbles
                                        })
                                    } */
                    }
                });
                Promise.all(promises).then(function (results) {
                    if (conversations.length !== 0) {
                        const bubbles = _.map(conversations, (val, uid) => {
                            return {...val, uid};
                        });
                        dispatch({
                            type: FETCH_CLINICAL_CHATS_SUCCESS,
                            payload: bubbles,
                        });
                    } else {
                        dispatch({
                            type: FETCH_CLINICAL_CHATS_FAILED,
                            payload: [],
                        });
                    }
                });
            } else {
                dispatch({
                    type: FETCH_CLINICAL_CHATS_FAILED,
                    payload: [],
                });
            }
        });
    };
};

export const dispatchPrescriptionIschanged = (conversationID) => {
    //console.log("dispatchPrescriptionIschanged triggered ", conversationID)
    return dispatch => {
        dispatch({
            type: PRESCRIPTION_ISCHANGED,
            payload: {conversationID:conversationID},
        });
    }
}

export const dispatchPrescriptionIssaved = (conversationID) => {
    //console.log("dispatchPrescriptionIssaved triggered ", conversationID)
    return dispatch => {
        dispatch({
            type: PRESCRIPTION_ISSAVED,
            payload: {conversationID:conversationID},
        });
    }
}

export const updateConversationMedication = (conversationID, prescription) => {
    //console.log("updateConversationMedication triggered ", prescription)
    return dispatch => {
        dispatch({
            type: UPDATE_CONVERSATION_MEDICATION,
            payload: {conversationID:conversationID, data: prescription},
        });
    }
}

export const updateConversationPrescriptionData = (conversationID, prescription_data) => {
    //console.log("updateConversationPrescriptionData triggered ", prescription_data)
    return dispatch => {
        dispatch({
            type: FETCH_PRESCRIPTIONS_DATA_CHATCHANGED,
            payload: {conversationID:conversationID, data: prescription_data},
        });
    }
}

export const fetchChatPrescriptions = (isFrom, clinicID, conversationID) => {
    //console.log("fetchChatPrescriptions triggered ",isFrom, clinicID, conversationID)
    return dispatch => {
        if(isFrom !== 'tier1'){
            callbackPrescriptions = getOpenClinicConversationsPrescriptionWithLocationRef(
                clinicID,
                conversationID
            );

            callbackPrescriptions.on("value", snapshot => {
                console.log("getOpenClinicConversationsPrescriptionWithLocationRef snapshot ", snapshot.val())
                var prescriptions = [];
                if (snapshot.val()) {
                    let count = snapshot.numChildren();
                    let index = 0;
                    snapshot.forEach(prescription => {
                        index++;
                        if(prescription.val()){
                            let pres = prescription.val();
                            pres.key = prescription.key;
                            prescriptions.push(pres);
                        }
                        if (index >= count) {
                            dispatch({
                                type: FETCH_PRESCRIPTIONS,
                                payload: {conversationID:conversationID, data: prescriptions},
                            });
                        }

                    });
                } else {
                    dispatch({
                        type: FETCH_PRESCRIPTIONS,
                        payload: {conversationID:conversationID, data: prescriptions},
                    });
                }
                console.log("fetchChatPrescriptions prescriptions ", prescriptions)
            });

            callbackPrescriptionData = getOpenClinicConversationsPrescriptionDataWithLocationRef(
                clinicID,
                conversationID
            );

            callbackPrescriptionData.on("value", snapshot => {
                let prescriptionData = {}
                if (snapshot.val()) {
                    let prescriptionData = snapshot.val()
                    dispatch({
                        type: FETCH_PRESCRIPTIONS_DATA,
                        payload: {conversationID:conversationID, data: prescriptionData},
                    });
                    console.log("getOpenClinicConversationsPrescriptionDataWithLocationRef snapshot ", snapshot.val())
                } else {
                    dispatch({
                        type: FETCH_PRESCRIPTIONS_DATA,
                        payload: {conversationID:conversationID, data: prescriptionData},
                    });
                }
                console.log("fetchChatPrescriptions prescriptions ", prescriptionData)
            });
        }else{
            callbackPrescriptions = getOpenConversationsPrescriptionWithLocationRef(
                conversationID
            );

            callbackPrescriptions.on("value", snapshot => {
                var prescriptions = [];
                console.log("getOpenConversationsPrescriptionWithLocationRef snapshot ", snapshot.val())
                if (snapshot.val()) {
                    let count = snapshot.numChildren();
                    let index = 0;
                    snapshot.forEach(prescription => {
                        index++;
                        if(prescription.val()){
                            let pres = prescription.val();
                            pres.key = prescription.key;
                            prescriptions.push(pres);
                        }
                        if (index >= count) {
                            dispatch({
                                type: FETCH_PRESCRIPTIONS,
                                payload: {conversationID:conversationID, data: prescriptions},
                            });
                        }

                    });
                } else {
                    dispatch({
                        type: FETCH_PRESCRIPTIONS,
                        payload: {conversationID:conversationID, data: prescriptions},
                    });
                }
                console.log("fetchChatPrescriptions prescriptions ", prescriptions)
            });
            callbackPrescriptionData = getOpenConversationsPrescriptionDataWithLocationRef(
                conversationID
            );

            callbackPrescriptionData.on("value", snapshot => {
                let prescriptionData = {}
                console.log("getOpenConversationsPrescriptionDataWithLocationRef snapshot ", snapshot.val())

                if (snapshot.val()) {
                    let prescriptionData = snapshot.val()
                    dispatch({
                        type: FETCH_PRESCRIPTIONS_DATA,
                        payload: {conversationID:conversationID, data: prescriptionData},
                    });
                } else {
                    dispatch({
                        type: FETCH_PRESCRIPTIONS_DATA,
                        payload:{conversationID:conversationID, data: prescriptionData},
                    });
                }
                console.log("fetchChatPrescriptions prescriptions ", prescriptionData)
            });
        }

    };
};

export const discardChanges = (isFrom, clinicID, conversationID) => {
    console.log("fetchChatPrescriptions triggered ",isFrom, clinicID, conversationID)
    return dispatch => {
        if(isFrom !== 'tier1'){
            callbackPrescriptions = getOpenClinicConversationsPrescriptionWithLocationRef(
                clinicID,
                conversationID
            );

            callbackPrescriptions.on("value", snapshot => {
                console.log("getOpenClinicConversationsPrescriptionWithLocationRef snapshot ", snapshot.val())
                var prescriptions = [];
                if (snapshot.val()) {
                    let count = snapshot.numChildren();
                    let index = 0;
                    snapshot.forEach(prescription => {
                        index++;
                        if(prescription.val()){
                            let pres = prescription.val();
                            pres.key = prescription.key;
                            prescriptions.push(pres);
                        }
                        if (index >= count) {
                            dispatch({
                                type: DISCARD_CHANGES,
                                payload: {conversationID:conversationID, data: prescriptions},
                            });
                        }

                    });
                } else {
                    dispatch({
                        type: DISCARD_CHANGES,
                        payload: {conversationID:conversationID, data: prescriptions},
                    });
                }
                console.log("fetchChatPrescriptions prescriptions ", prescriptions)
            });

            callbackPrescriptionData = getOpenClinicConversationsPrescriptionDataWithLocationRef(
                clinicID,
                conversationID
            );

            callbackPrescriptionData.on("value", snapshot => {
                let prescriptionData = {}
                if (snapshot.val()) {
                    let prescriptionData = snapshot.val()
                    dispatch({
                        type: FETCH_PRESCRIPTIONS_DATA,
                        payload: {conversationID:conversationID, data: prescriptionData},
                    });
                    console.log("getOpenClinicConversationsPrescriptionDataWithLocationRef snapshot ", snapshot.val())
                } else {
                    dispatch({
                        type: FETCH_PRESCRIPTIONS_DATA,
                        payload: {conversationID:conversationID, data: prescriptionData},
                    });
                }
                console.log("fetchChatPrescriptions prescriptions ", prescriptionData)
            });
        }else{
            callbackPrescriptions = getOpenConversationsPrescriptionWithLocationRef(
                conversationID
            );

            callbackPrescriptions.on("value", snapshot => {
                var prescriptions = [];
                console.log("getOpenClinicConversationsPrescriptionWithLocationRef snapshot ", snapshot.val())
                if (snapshot.val()) {
                    let count = snapshot.numChildren();
                    let index = 0;
                    snapshot.forEach(prescription => {
                        index++;
                        if(prescription.val()){
                            let pres = prescription.val();
                            pres.key = prescription.key;
                            prescriptions.push(pres);
                        }
                        if (index >= count) {
                            dispatch({
                                type: DISCARD_CHANGES,
                                payload: {conversationID:conversationID, data: prescriptions},
                            });
                        }

                    });
                } else {
                    dispatch({
                        type: DISCARD_CHANGES,
                        payload: {conversationID:conversationID, data: prescriptions},
                    });
                }
                console.log("fetchChatPrescriptions prescriptions ", prescriptions)
            });
            callbackPrescriptionData = getOpenConversationsPrescriptionDataWithLocationRef(
                conversationID
            );

            callbackPrescriptionData.on("value", snapshot => {
                let prescriptionData = {}
                console.log("getOpenConversationsPrescriptionDataWithLocationRef snapshot ", snapshot.val())

                if (snapshot.val()) {
                    let prescriptionData = snapshot.val()
                    dispatch({
                        type: FETCH_PRESCRIPTIONS_DATA,
                        payload: {conversationID:conversationID, data: prescriptionData},
                    });
                } else {
                    dispatch({
                        type: FETCH_PRESCRIPTIONS_DATA,
                        payload:{conversationID:conversationID, data: prescriptionData},
                    });
                }
                console.log("fetchChatPrescriptions prescriptions ", prescriptionData)
            });
        }

    };
};


export const fetchAllMessagesForClinicChats = (clinicID, conversationID) => {
    return dispatch => {
        dispatch({type: FETCHING_CLINIC_MESSAGES});

        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        callbackFunc1 = getOpenClinicConversationsWithLocationRef(clinicID, conversationID);
        callbackFunc1.once("value", snapshot => {
            console.log("fetchAllMessages: ", snapshot.val());
            if (snapshot.val().status) {
                getStaffMemberByRef(clinicID, snapshot.val().doctorID).once("value", doctorSnap => {
                    if (doctorSnap.val()) {
                        getClinicRefByID(clinicID).once("value", clinicSnap => {
                            callbackFunc2 = getConversationRef(conversationID);
                            callbackFunc2.on("value", snap => {
                                if (snap.val()) {
                                    var count = snap.numChildren();
                                    var i = 0;
                                    var messages = [];
                                    snap.forEach(function (childSnapshot) {
                                        let receiveMessage = childSnapshot.val();

                                        receiveMessage.direction =
                                            receiveMessage.toID === snapshot.val().patientID ? "right" : "left";
                                        receiveMessage.doctorImgURL = {uri: doctorSnap.val().profileImageURL};
                                        receiveMessage.doctorName = doctorSnap.val().fullName;

                                        let objMessages = {
                                            receiveMessage: receiveMessage,
                                            timestamp: childSnapshot.key,
                                        };

                                        messages.push(objMessages);
                                        if (count - 1 === i) {
                                            const bubbles = _.map(messages, (val, uid) => {
                                                return {...val, uid};
                                            });
                                            var orderedMessages = _.orderBy(bubbles, ["timestamp"], ["asc"]);
                                            i = count - 1;

                                            dispatch({
                                                type: FETCH_CLINIC_MESSAGES,
                                                payload: {
                                                    messages: orderedMessages,
                                                    location: conversationID,
                                                    currentDoctorProfile: doctorSnap.val(),
                                                    doctorId: snapshot.val().doctorID,
                                                    doctorName: doctorSnap.val().fullName,
                                                    doctorImage: doctorSnap.val().profileImageURL,
                                                    clinicPhoneNumber: clinicSnap.val().clinicPhoneNumber
                                                        ? clinicSnap.val().clinicPhoneNumber
                                                        : "",
                                                    clinicFaxNumber: clinicSnap.val().clinicFaxNumber || "",
                                                    clinicPostalCode: clinicSnap.val().clinicPostalCode
                                                        ? clinicSnap.val().clinicPostalCode
                                                        : "",
                                                    signatureImageURL: doctorSnap.val().signatureImageURL
                                                        ? doctorSnap.val().signatureImageURL
                                                        : "",
                                                    doctorlicense: doctorSnap.val().licenseNo
                                                        ? doctorSnap.val().licenseNo
                                                        : "",
                                                    clinicAddress: clinicSnap.val().clinicAddress
                                                        ? clinicSnap.val().clinicAddress
                                                        : "",
                                                    clinicProvince: clinicSnap.val().clinicProvince || "",
                                                    currentUserId: currentUserID,
                                                },
                                            });
                                        } else {
                                            i = i + 1;
                                        }
                                    });
                                }
                            });
                        });
                    }
                });
            }
        });
    };
};

export const sendMessage = (ID, conversationID, message, toID, doctorName, doctorImage, dispatch1) => {

    if(dispatch1){
            dispatch1({type: SENDING_CLINIC_MESSAGE});
            const {currentUser} = auth;

            let currentUserID = currentUser.uid;
            let messageValue;
            var now = new Date();
            messageValue = {
                type: message.type,
                timestamp: now.getTime(),
                content: message.content,
                fromID: currentUserID,
                toID: toID,
                isRead: false,
                doctorName: doctorName,
                doctorImgURL: doctorImage,
            };
            saveMessage(ID, conversationID, messageValue, toID, doctorName);
    }else{
        return dispatch => {
            dispatch({type: SENDING_CLINIC_MESSAGE});
            const {currentUser} = auth;

            let currentUserID = currentUser.uid;
            let messageValue;
            var now = new Date();
            messageValue = {
                type: message.type,
                timestamp: now.getTime(),
                content: message.content,
                fromID: currentUserID,
                toID: toID,
                isRead: false,
                doctorName: doctorName,
                doctorImgURL: doctorImage,
            };
            saveMessage(ID, conversationID, messageValue, toID, doctorName);
        };
    }

};

const saveMessage = (ID, conversationID, messageValue, toID, doctorName) => {
    database
        .ref("users/" + toID)
        .once("value", function (snapshot) {
            var user_data = snapshot.val();
            console.log("saveMesage triggered");
            sendNewMessageNotificationMain(user_data.notificationToken, messageValue.content, doctorName);
        });
    getOpenClinicConversationsWithLocationRef(ID, conversationID).once("value", snapshot => {
        if (snapshot.val().status) {
            let newMessagekey = getConversationRef(conversationID).push().key;
            var updates = {};
            updates[`/conversations/${conversationID}/${newMessagekey}`] = messageValue;
            database
                .ref()
                .update(updates)
                .then(data => {
                    //send message notification should be here where the message is updated.
                })
                .catch(error => {
                });
        } else {
        }
    });
};

export const turnSelectChat = (ID, conversationID) => {
    return dispatch => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        getOpenClinicConversationsWithLocationRef(ID, conversationID).once("value", snapshot => {
            if (snapshot.val().status) {
                var updates = {};
                if (currentUserID === snapshot.val().doctorID) {
                    updates[
                        `/clinicStaffMember/${ID}/${snapshot.val().doctorID}/selected_chat`
                        ] = conversationID;
                } else if (currentUserID !== undefined && snapshot.val().doctorID !== undefined) {
                    updates[`/clinicStaffMember/${ID}/${currentUserID}/selected_chat`] = "On different chat";
                }
                database
                    .ref()
                    .update(updates)
                    .then(data => {

                    })
                    .catch(error => {

                    });
            }
        });
    };
};

export const sendDoctorFirstMessage = (ID, conversationID, transferredFromTier2 = false) => {
    return dispatch => {
        dispatch({type: SENDING_CLINIC_MESSAGE});
        getOpenClinicConversationsWithLocationRef(ID, conversationID).once("value", snapshot => {
            if (snapshot.val().status) {
                getStaffMemberByRef(ID, snapshot.val().doctorID).once("value", doctorSnap => {
                    if (doctorSnap.val()) {
                        var now = new Date();
                        let messageValue = {
                            type: "text",
                            timestamp: now.getTime(),
                            content: "",
                            fromID: snapshot.val().doctorID,
                            toID: snapshot.val().patientID,
                            isRead: false,
                            doctorName: doctorSnap.val().fullName,
                            doctorImgURL: doctorSnap.val().profileImageURL,
                        };
                        let newMessagekey = getConversationRef(conversationID).push().key;
                        messageValue.content = `Hi! Thanks for waiting, My name is ${
                            doctorSnap.val().fullName
                        }.`;
                        if (transferredFromTier2) {
                            messageValue.content = `Hi, I am Dr. ${doctorSnap.val().fullName}. I’m following up to an earlier consult conducted by my fellow colleague with regards to your issue.`;
                        }
                        var updates = {};
                        updates[`/conversations/${conversationID}/${newMessagekey}`] = messageValue;
                        database
                            .ref()
                            .update(updates)
                            .then(() => {
                                refreshMessages();
                                fetchAllMessagesForClinicChats(ID, conversationID);
                            })
                            .catch(() => {
                                refreshMessages();
                                fetchAllMessagesForClinicChats(ID, conversationID);
                            });
                    }
                });
            }
        });
    };
};

export const fetchStatus = (ID, conversationID) => {
    return dispatch => {
        getOpenConversationsWithLocationRef(ID, conversationID).once("value", statusSnap => {
            dispatch({
                type: FETCH_STATUS,
                payload: {
                    followUpStatus: statusSnap.val().followUpStatus,
                    skipStatus: statusSnap.val().skipStatus,
                    followupDate: statusSnap.val().followUpDate,
                    followUpID: statusSnap.val().followUpID,
                },
            });
        });
    };
};
export const setMediaHeader = (mediaName, mediaDob, mediaGender, mediaIssue) => {
    return dispatch => {
        dispatch({
            type: MEDIA_HEADER_DATA,
            payload: {
                mediaName: mediaName,
                mediaDob: mediaDob,
                mediaGender: mediaGender,
                mediaIssue: mediaIssue,
            },
        });
    };
};

export const sendNewMessageNotification = (notificationToken, message, doctorName) => {
    return dispatch => {
        sendNewMessageNotificationMain(notificationToken, message, doctorName);
    };
};

const sendNewMessageNotificationMain = (notificationToken, message, doctorName) => {
    if (message === "Starting video call") {
        axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            url: `https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/newVideoNotification?notificationToken=${notificationToken}&doctorName=${doctorName}`,
            mode: 'cors',
        }).then(data=> {}).catch(e=>{})

        axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            url: `https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/newVideoNotification?notificationToken=${notificationToken}&doctorName=${doctorName}`,
            mode: 'cors',
        }).then(data=> {}).catch(e=>{})


        axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            url: `https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/newVideoNotificationInapp?notificationToken=${notificationToken}&doctorName=${doctorName}`,
            mode: 'cors',
        }).then(data=> {}).catch(e=>{})

        axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            url: `https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/newVideoNotificationInapp?notificationToken=${notificationToken}&doctorName=${doctorName}`,
            mode: 'cors',
        }).then(data=> {}).catch(e=>{})
        return;
    }
    else if (message === "Starting audio call") {
        axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            url: `https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/newAudioNotification?notificationToken=${notificationToken}&doctorName=${doctorName}`,
            mode: 'cors',
        }).then(data=> {}).catch(e=>{})

        axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            url: `https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/newAudioNotification?notificationToken=${notificationToken}&doctorName=${doctorName}`,
            mode: 'cors',
        }).then(data=> {}).catch(e=>{})

        axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            url: `https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/newAudioNotificationInapp?notificationToken=${notificationToken}&doctorName=${doctorName}`,
            mode: 'cors',
        }).then(data=> {}).catch(e=>{})

        axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            url: `https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/newAudioNotificationInapp?notificationToken=${notificationToken}&doctorName=${doctorName}`,
            mode: 'cors',
        }).then(data=> {}).catch(e=>{})

        return;
    }
    else {
        axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            url: `https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/newMessage?notificationToken=${notificationToken}&doctorName=${doctorName}`,
            mode: 'cors',
        }).then(data=> {}).catch(e=>{})

        axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            url: `https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/newMessageInapp?notificationToken=${notificationToken}&doctorName=${doctorName}`,
            mode: 'cors',
        }).then(data=> {}).catch(e=>{})

        axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            url: `https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/newMessage?notificationToken=${notificationToken}&doctorName=${doctorName}`,
            mode: 'cors',
        }).then(data=> {}).catch(e=>{})

        axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            url: `https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/newMessageInapp?notificationToken=${notificationToken}&doctorName=${doctorName}`,
            mode: 'cors',
        }).then(data=> {}).catch(e=>{})
    }
};

export const fetchClinicalUnReadCount = clinic_id => {
    return dispatch => {
        try {
            getOpenConversationsRef(clinic_id).on("value", conversationsSnapShot => {
                if (conversationsSnapShot.val()) {
                    var lastMessageTimeStamp = "";
                    var unreadCount = 0;
                    var results = [];

                    var count = conversationsSnapShot.numChildren();
                    var index = 0;
                    conversationsSnapShot.forEach(openConvo => {
                        var result = {};
                        index++;
                        if (openConvo.val().status) {
                            let location = openConvo.key;
                            result.conversationId = location;
                            getConversationRef(location)
                                .orderByChild("isRead")
                                .equalTo(false)
                                .on("value", openConvoMessages => {
                                    if (openConvoMessages.val()) {
                                        unreadCount = 0;
                                        openConvoMessages.forEach(messageValue => {
                                            if (messageValue.val().fromID === openConvo.val().patientID) unreadCount++;
                                        });
                                        result.unreadCount = unreadCount;
                                        getConversationRef(openConvo.key)
                                            .orderByChild("timestamp")
                                            .limitToLast(1)
                                            .once("value", lastmsg => {
                                                lastmsg.forEach(message => {
                                                    lastMessageTimeStamp = message.val().timestamp;
                                                });
                                            })
                                            .then(data => {
                                                result.lastMessageTimeStamp = lastMessageTimeStamp;
                                                var findConvo =
                                                    results.length !== 0 &&
                                                    results.find(chat => chat.conversationId === result.conversationId);
                                                if (Boolean(findConvo)) {
                                                    results.forEach(old_result => {
                                                        if (old_result.conversationId === result.conversationId) {
                                                            old_result.lastMessageTimeStamp = result.lastMessageTimeStamp;
                                                            old_result.unreadCount = result.unreadCount;
                                                        }
                                                    });
                                                } else {
                                                    results.push(result);
                                                }

                                                if (index === count) {
                                                    dispatch({
                                                        type: CLEAR_CLINICAL_UNREAD_MESSAGES_COUNT,
                                                        payload: [],
                                                    });
                                                    dispatch({
                                                        type: FETCH_CLINICAL_UNREAD_MESSAGES_COUNT,
                                                        payload: results,
                                                    });
                                                }
                                            });
                                    }
                                });
                        }
                    });
                }
            });
        } catch (error) {
            console.log("fetchClinicalUnReadCount error: ", error);
        }
    };
};

export const fetchClinicalConversationCount = clinic_id => {
    return dispatch => {
        try {
            getOpenConversationsRef(clinic_id).on("value", conversationsSnapShot => {
                if (conversationsSnapShot.val()) {
                    var count = conversationsSnapShot.numChildren();
                    if (count !== 0) {
                        const bubbles = _.map(conversationsSnapShot.val(), (val, uid) => {
                            if (val.status && !val.queued && val.clinical_chat) {
                                return {...val, uid};
                            }
                        });

                        var filteredBubbles = bubbles.filter(function (el) {
                            return el != null && el !== "" && el !== undefined;
                        });

                        var result =
                            filteredBubbles.length !== 0 &&
                            filteredBubbles.reduce(function (obj, v) {
                                // increment or set the property
                                // `(obj[v.status] || 0)` returns the property value if defined
                                // or 0 ( since `undefined` is a falsy value
                                obj[v.doctorID] = (obj[v.doctorID] || 0) + 1;
                                // return the updated object
                                return obj;
                                // set the initial value as an object
                            }, {});

                        var resultCount = _.map(result, (onlinePaidChats, doctorID) => {
                            return {onlinePaidChats, doctorID};
                        });

                        dispatch({
                            type: FETCH_CLINICAL_CONVERSATIONS_COUNT_FOR_EACH_DOCTOR,
                            payload: resultCount,
                        });
                    }
                }
            });
        } catch (error) {
            console.log("fetchClinicalConversationCount error: ", error);
        }
    };
};

export const changeQueueStatusForClinicalChats = (
    clinicID,
    paidChat,
    conversationId,
    patientID,
    doctorDetail,
    doctorName,
    toID,
    issue,
    category
) => {
    return dispatch => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        if (currentUserID === doctorDetail.uid) {
            getOpenClinicConversationsWithLocationRef(clinicID, conversationId)
                .update({
                    queued: false,
                    doctorID: currentUserID,
                })
                .then(() => {
                    var updates = {};
                    let doctorData = {location: conversationId, patientID: patientID};
                    updates[
                        `/clinicStaffMember/${clinicID}/${currentUserID}/conversations/${conversationId}`
                        ] = doctorData;
                    updates[`/users/${patientID}/conversations/location`] = conversationId;
                    updates[`/users/${patientID}/conversations/doctorID`] = currentUserID;
                    updates[`/users/${patientID}/conversations/issue`] = issue;
                    updates[`/users/${patientID}/conversations/category`] = category;

                    database
                        .ref()
                        .update(updates)
                        .then(data => {
                            dispatch({
                                type: CHANGE_CLINICAL_CHAT_STATUS_SUCCESS,
                                payload: {
                                    conversationId: conversationId,
                                    clinicName: "",
                                },
                            });
                            database
                                .ref("users/" + toID)
                                .once("value", function (snapshot) {
                                    var user_data = snapshot.val();
                                    sendJoinChatNotification(user_data.notificationToken, doctorName);
                                });
                        })
                        .catch(error => {
                            alert("Patient is not successfully picked from the queue. Please try again");
                            dispatch({type: CHANGE_CLINICAL_CHAT_STATUS_FAILED});
                        });
                })
                .catch(error => {
                    alert("Patient is not successfully picked from the queue. Please try again");
                    dispatch({type: CHANGE_CLINICAL_CHAT_STATUS_FAILED});
                });
        }

    };
};

const getOpenClinicConversationsWithLocationRef = (id, uid) => {
    return database.ref(`/openClinicalConversations/${id}/${uid}`);
};
const getOpenClinicConversationsPrescriptionWithLocationRef = (id, uid) => {
    return database.ref(`/openClinicalConversations/${id}/${uid}/prescriptions`);
};
const getOpenClinicConversationsPrescriptionDataWithLocationRef = (id, uid) => {
    return database.ref(`/openClinicalConversations/${id}/${uid}/prescriptions_data`);
};

export const sendJoinChatNotification = (notificationToken, doctorName) => {
    axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/joinChat?notificationToken=${notificationToken}&doctorName=${doctorName}`,
        mode: 'cors',
    }).then(data=>{}).catch(e=>{})

    axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/joinChatInapp?notificationToken=${notificationToken}&doctorName=${doctorName}`,
        mode: 'cors',
    }).then(data=>{}).catch(e=>{})


};

export const transferClinicalChatToYDOByAdmin = (chatData, callback) => {
    return dispatch => {
        var userType = true;
        var root =
            ENVIRONMENT === "production"
                ? `https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/fetchOnlineAgent?userType=${userType}`
                : `https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/fetchOnlineAgent?userType=${userType}`;
        let request = new XMLHttpRequest();
        request.onreadystatechange = e => {
            if (request.readyState !== 4) {
                return;
            }

            if (request.status === 200) {
                var json = JSON.parse(request.responseText);
                if (json.status === "OK") {
                    const results = json.onlineAgent;
                    AssignYDO(dispatch, callback, chatData, results);
                }
            } else {
                AssignYDO(dispatch, callback, chatData, null);
            }
        };

        request.open("POST", root);
        request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        request.send();
    };
};

const AssignYDO = (dispatch, callback, chatData, doctorObj) => {
    const {conversationId} = chatData;
    getOpenConversationsWithLocationRef(conversationId)
        .update({
            clinical_chat: false,
            queued: doctorObj !== null ? false : true,
            doctorID: doctorObj !== null ? doctorObj.doctorID : null,
            clinical_to_skip: false,
            assignedOn: "transfered_chat_by_admin",
        })
        .then(() => {
            console.log("done it: ", doctorObj);
            callback(true);
        });
};

export const closeClinicalChatByAdmin = (chatData, callback) => {
    return dispatch => {
        const {paidChat, conversationId, patientID, issue, category, clinicalChatData} = chatData;
        var now = new Date();
        var updates = {};

        var closeData = {
            status: false,
            queued: false,
            patientID: patientID,
            timestamp: now.getTime(),
            isPaid: paidChat,
            issue: issue,
            category: category,
            location: conversationId,
        };

        var achiveData = clinicalChatData;

        var historyID = getUserHistoryConversationRef(patientID).push().key;

        getOpenConversationsWithLocationRef(conversationId).once("value", convosnap => {
            getOpenConversationsWithLocationRef(conversationId)
                .update({status: false})
                .then(() => {
                    achiveData.status = false;
                    achiveData.queued = false;
                    achiveData.clinicName = "Admin";

                    updates[`/users/${patientID}/conversations`] = null;
                    updates[`/clinicArchiveConversations/Admin/${conversationId}`] = achiveData;
                    updates[`/users/${patientID}/history_conversations/${historyID}`] = closeData;
                    updates[`/openconversations/${conversationId}`] = null;

                    database
                        .ref()
                        .update(updates)
                        .then(data => {
                            console.log("data updated now");
                            callback(true);
                            //storeCloseChatIndex(conversationId);
                            dispatch({
                                type: CLOSE_CHAT,
                                payload: {
                                    conversationId: conversationId,
                                },
                            });

                            database
                                .ref("users/" + patientID)
                                .once("value", function (snapshot) {
                                    var user_data = snapshot.val();
                                    sendCloseChatNotification(user_data.notificationToken, "Admin", conversationId);
                                });
                        })
                        .catch(error => {
                            console.log("close chat update error: 2 " + error);
                        });
                })
                .catch(error => {
                    alert("Chat was not properly closed. Please try again");
                });
        });
    };
};

export const saveClinicalPatientFormInprogress = () => {
    return dispatch => {
        dispatch({type: SAVE_OUT_PATIENT_FORM_IN_PROCESS});
    };
};

const sendPrescriptionMessageToUser = (clinic_id, conversationID, content,patientId, doctorName, doctorImage, dispatch) => {
    console.log("sendPrescriptionMessageToUser items", {
        clinic_id, conversationID, content, patientId, doctorName, doctorImage
    })
    sendMessage(
        clinic_id,
        conversationID,
        { type: "text", content },
        patientId,
        doctorName,
        doctorImage,
        dispatch)
}

const sendPrescriptionEmail = (emailData) => {
    let cloudFunctionURL = ENVIRONMENT === "production"? "https://us-central1-yourdoctorsonline-prod.cloudfunctions.net" :"https://us-central1-yourdoctorsonline-staging.cloudfunctions.net"
    if(emailData?.patient_name){
        fetch(cloudFunctionURL + '/sendPresriptionEmailConfirmation', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(emailData),
        })
            .then(data => console.log("email call successfull")).catch(error => console.log("email call failed", error))
    }

}

export const saveAndCloseClinicalPatientForm = (
    clinicID,
    clinicName,
    patientDOB,
    country,
    city,
    province,
    postalcode,
    healthInsuranceCardNumber,
    healthInsuranceCardFront,
    healthInsuranceCardBack,
    gender,
    paidChat,
    conversationId,
    doctorID,
    admin,
    doctorName,
    patientName,
    patientId,
    selectedIssue,
    selectedCategory,
    tagChatList,
    symptoms,
    findings,
    assessment,
    plan,
    icd,
    billing_code,
    billing_unit,
    followUpCheck,
    dateTime,
    heartRate,
    weightKg,
    weightLbs,
    stepCount,
    runningWalking,
    heightCm,
    currentSleep,
    averageSleep,
    heightFeet,
    periodStartDate,
    flowType,
    restingHeartRate,
    complete_address,
    prescriptions,
    requisitions,
    special_referals,
    encounter_duration,
    video_consult,
    start_timestamp,
    followUpDays,
    soapnotespdf,
    followUpID,
    prescription_pdf_data,
    user_medications
) => {
    return dispatch => {
        dispatch({type: SAVE_OUT_PATIENT_FORM_IN_PROCESS});
        let prescription_data = prescription_pdf_data?.prescription_data
        let prescription_path = prescription_pdf_data?.prescription_url
        let options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            timeZone: "America/New_York",
            timeZoneName: "long",
            hour12: true,
        };
        var d = new Date();
        let conversationTime = d.toLocaleString("en-US", options);
        getUserRef(patientId).once("value", userSnapshot => {
            if(user_medications.length > 0) {
                let emailData = {
                    "user_medications": user_medications,
                    "patient_name": prescription_data?.name || patientName,
                    "patient_dob": prescription_data?.DOB || userSnapshot.val()?.DOB,
                    "patient_gender": prescription_data?.gender || userSnapshot.val()?.gender,
                    "patient_summary": prescription_data?.summary || "",
                    "current_medications": prescription_data?.current_medications || "",
                    "known_medical_conditions": prescription_data?.medical_conditions || "",
                    "patient_time": conversationTime,
                    "health_insurance_card_number": userSnapshot.val()?.healthInsuranceCardNumber,
                    "prescription_url": prescription_path,
                    "patient_phone_no": userSnapshot.val()?.phone_number || "",
                    "patient_email":userSnapshot.val()?.email || "",
                    "pharmacy": prescription_pdf_data?.pharmacy,
                    "doctorName": doctorName,
                    "istier2": true,
                    "doctor_emails": ["ghania@yourdoctors.online", "shayan@yourdoctors.online"]

                }
                console.log("emailData ", emailData)
                ///openconversations/${conversationID}/
                //this.props.sendYDOMessage(
                //                     conversationID,
                //                     { type: "text", content },
                //                     this.props.patientId,
                //                     this.props.doctorName,
                //                     this.props.doctorImage
                //                 )
                let content= `Your prescription has been sent to the pharmacy at fax number: ${prescription_pdf_data?.pharmacy?.fax_number}.It can be accessed at url: ${prescription_path}`
                console.log("sendPrescriptionMessageToUser data", {
                    clinicID, conversationId, content,patientId, doctorName, doctorImage:prescription_pdf_data?.doctorImage
                })
                sendPrescriptionMessageToUser(clinicID, conversationId, content,patientId, doctorName, prescription_pdf_data?.doctorImage, dispatch)
                sendPrescriptionEmail(emailData)
            }
            if (userSnapshot.val().user_id) {
                closeChatNow(
                    dispatch,
                    clinicID,
                    userSnapshot,
                    null,
                    clinicName,
                    patientDOB,
                    country,
                    city,
                    province,
                    postalcode,
                    healthInsuranceCardNumber,
                    healthInsuranceCardFront,
                    healthInsuranceCardBack,
                    gender,
                    paidChat,
                    conversationId,
                    doctorID,
                    admin,
                    doctorName,
                    patientName,
                    patientId,
                    selectedIssue,
                    selectedCategory,
                    tagChatList,
                    symptoms,
                    findings,
                    assessment,
                    plan,
                    icd,
                    billing_code,
                    billing_unit,
                    followUpCheck,
                    dateTime,
                    heartRate,
                    weightKg,
                    weightLbs,
                    stepCount,
                    runningWalking,
                    heightCm,
                    currentSleep,
                    averageSleep,
                    heightFeet,
                    periodStartDate,
                    flowType,
                    restingHeartRate,
                    complete_address,
                    prescriptions,
                    requisitions,
                    special_referals,
                    encounter_duration,
                    video_consult,
                    start_timestamp,
                    followUpDays,
                    soapnotespdf,
                    followUpID,
                    prescription_path,
                    user_medications
                );
            } else {
                fetch(
                    rootURL + `admin_users/getuser?email=${encodeURIComponent(userSnapshot.val().email)}`,
                    {
                        method: "get",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                )
                    .then(response => response.json())
                    .then(success => {
                        if (success.user.id) {
                            closeChatNow(
                                dispatch,
                                clinicID,
                                userSnapshot,
                                success.user.id,
                                clinicName,
                                patientDOB,
                                country,
                                city,
                                province,
                                postalcode,
                                healthInsuranceCardNumber,
                                healthInsuranceCardFront,
                                healthInsuranceCardBack,
                                gender,
                                paidChat,
                                conversationId,
                                doctorID,
                                admin,
                                doctorName,
                                patientName,
                                patientId,
                                selectedIssue,
                                selectedCategory,
                                tagChatList,
                                symptoms,
                                findings,
                                assessment,
                                plan,
                                icd,
                                billing_code,
                                billing_unit,
                                followUpCheck,
                                dateTime,
                                heartRate,
                                weightKg,
                                weightLbs,
                                stepCount,
                                runningWalking,
                                heightCm,
                                currentSleep,
                                averageSleep,
                                heightFeet,
                                periodStartDate,
                                flowType,
                                restingHeartRate,
                                complete_address,
                                prescriptions,
                                requisitions,
                                special_referals,
                                encounter_duration,
                                video_consult,
                                start_timestamp,
                                followUpDays,
                                soapnotespdf,
                                followUpID,
                                prescription_path,
                                user_medications
                            );
                        }
                    });
            }
        });
    };
};

const closeChatNow = (
    dispatch,
    clinicID,
    userSnapshot,
    userID,
    clinicName,
    patientDOB,
    country,
    city,
    province,
    postalcode,
    healthInsuranceCardNumber,
    healthInsuranceCardFront,
    healthInsuranceCardBack,
    gender,
    paidChat,
    conversationId,
    doctorID,
    admin,
    doctorName,
    patientName,
    patientId,
    selectedIssue,
    selectedCategory,
    tagChatList,
    symptoms,
    findings,
    assessment,
    plan,
    icd,
    billing_code,
    billing_unit,
    followUpCheck,
    dateTime,
    heartRate,
    weightKg,
    weightLbs,
    stepCount,
    runningWalking,
    heightCm,
    currentSleep,
    averageSleep,
    heightFeet,
    periodStartDate,
    flowType,
    restingHeartRate,
    complete_address,
    prescriptions,
    requisitions,
    special_referals,
    encounter_duration,
    video_consult,
    start_timestamp,
    followUpDays,
    soapnotespdf,
    followUpID,
    prescriptionspdf,
    user_medications
) => {
    var root = "https://admin.yourdoctors.online/api/0.1/";
    var diagnosisData = {};
    console.log("followUpCheck: ", followUpCheck);
    if (followUpCheck) {
        diagnosisData = {
            id: followUpID,
            diagnosis: symptoms,
            message: findings,
        };
        fetch(doctorServicesUrl + `addFollowUpDiagnosis`, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(diagnosisData),
        })
            .then(data => data.json())
            .then(response => {
                console.log("followuprespose ", response);
                let obj = {
                    actual_date: response.actualDate,
                    category: response.category,
                    clicked: response.clicked,
                    clinicId: response.clinicId,
                    completed: response.completed,
                    diagnosis: response.diagnosis,
                    doctorFirebaseId: response.doctorFirebaseId,
                    doctor_name: response.doctorName,
                    duration: response.duration,
                    endDate: response.endDate,
                    id: response.id,
                    medical_details: response.medicalDetails,
                    message: response.message,
                    notification_sent: response.notificationSent,
                    patientFirebaseId: response.patientFirebaseId,
                    patient_issue: response.patientIssue,
                    patient_name: response.patientName,
                    scheduled_date: response.scheduledDate,
                    slot: response.slot,
                    user_id: response.userid,
                };

                if (obj) {
                    closeChat(
                        dispatch,
                        clinicID,
                        clinicName,
                        patientDOB,
                        country,
                        city,
                        province,
                        postalcode,
                        healthInsuranceCardNumber,
                        healthInsuranceCardFront,
                        healthInsuranceCardBack,
                        gender,
                        patientName,
                        paidChat,
                        conversationId,
                        patientId,
                        doctorID,
                        doctorName,
                        patientId,
                        admin,
                        selectedIssue,
                        selectedCategory,
                        tagChatList,
                        symptoms,
                        findings,
                        assessment,
                        plan,
                        icd,
                        billing_code,
                        billing_unit,
                        followUpCheck,
                        [obj],
                        heartRate,
                        weightKg,
                        weightLbs,
                        stepCount,
                        runningWalking,
                        heightCm,
                        currentSleep,
                        averageSleep,
                        heightFeet,
                        periodStartDate,
                        flowType,
                        restingHeartRate,
                        complete_address,
                        prescriptions,
                        requisitions,
                        special_referals,
                        encounter_duration,
                        video_consult,
                        start_timestamp,
                        followUpDays,
                        soapnotespdf,
                        followUpID,
                        prescriptionspdf,
                        user_medications
                    );
                    dispatch({type: SAVE_OUT_PATIENT_FORM_SUCCESS});
                }
            });
        /* closeChat(dispatch, clinicID, clinicName, patientDOB, city, province, postalcode,
                healthInsuranceCardNumber, healthInsuranceCardFront, healthInsuranceCardBack, gender,
                patientName, paidChat, conversationId,
                patientId, doctorID, doctorName, patientId, admin,
                selectedIssue, selectedCategory, tagChatList, symptoms, findings, assessment, plan, icd,
                billing_code, billing_unit, false, null, heartRate, weightKg, weightLbs,
                stepCount, runningWalking, heightCm, currentSleep, averageSleep, heightFeet, periodStartDate,
                flowType, restingHeartRate, complete_address, prescriptions, requisitions, special_referals,
                encounter_duration, video_consult, start_timestamp, followUpDays, soapnotespdf, followUpID);
            dispatch({ type: SAVE_OUT_PATIENT_FORM_SUCCESS });
     */
    } else {
        closeChat(
            dispatch,
            clinicID,
            clinicName,
            patientDOB,
            country,
            city,
            province,
            postalcode,
            healthInsuranceCardNumber,
            healthInsuranceCardFront,
            healthInsuranceCardBack,
            gender,
            patientName,
            paidChat,
            conversationId,
            patientId,
            doctorID,
            doctorName,
            patientId,
            admin,
            selectedIssue,
            selectedCategory,
            tagChatList,
            symptoms,
            findings,
            assessment,
            plan,
            icd,
            billing_code,
            billing_unit,
            followUpCheck,
            null,
            heartRate,
            weightKg,
            weightLbs,
            stepCount,
            runningWalking,
            heightCm,
            currentSleep,
            averageSleep,
            heightFeet,
            periodStartDate,
            flowType,
            restingHeartRate,
            complete_address,
            prescriptions,
            requisitions,
            special_referals,
            encounter_duration,
            video_consult,
            start_timestamp,
            followUpDays,
            soapnotespdf,
            followUpID,
            prescriptionspdf,
            user_medications
        );
        dispatch({type: SAVE_OUT_PATIENT_FORM_SUCCESS});
    }
};

const convertRegion = (input, to) => {
    var states = [
        ["Alabama", "AL"],
        ["Alaska", "AK"],
        ["American Samoa", "AS"],
        ["Arizona", "AZ"],
        ["Arkansas", "AR"],
        ["Armed Forces Americas", "AA"],
        ["Armed Forces Europe", "AE"],
        ["Armed Forces Pacific", "AP"],
        ["California", "CA"],
        ["Colorado", "CO"],
        ["Connecticut", "CT"],
        ["Delaware", "DE"],
        ["District Of Columbia", "DC"],
        ["Florida", "FL"],
        ["Georgia", "GA"],
        ["Guam", "GU"],
        ["Hawaii", "HI"],
        ["Idaho", "ID"],
        ["Illinois", "IL"],
        ["Indiana", "IN"],
        ["Iowa", "IA"],
        ["Kansas", "KS"],
        ["Kentucky", "KY"],
        ["Louisiana", "LA"],
        ["Maine", "ME"],
        ["Marshall Islands", "MH"],
        ["Maryland", "MD"],
        ["Massachusetts", "MA"],
        ["Michigan", "MI"],
        ["Minnesota", "MN"],
        ["Mississippi", "MS"],
        ["Missouri", "MO"],
        ["Montana", "MT"],
        ["Nebraska", "NE"],
        ["Nevada", "NV"],
        ["New Hampshire", "NH"],
        ["New Jersey", "NJ"],
        ["New Mexico", "NM"],
        ["New York", "NY"],
        ["North Carolina", "NC"],
        ["North Dakota", "ND"],
        ["Northern Mariana Islands", "NP"],
        ["Ohio", "OH"],
        ["Oklahoma", "OK"],
        ["Oregon", "OR"],
        ["Pennsylvania", "PA"],
        ["Puerto Rico", "PR"],
        ["Rhode Island", "RI"],
        ["South Carolina", "SC"],
        ["South Dakota", "SD"],
        ["Tennessee", "TN"],
        ["Texas", "TX"],
        ["US Virgin Islands", "VI"],
        ["Utah", "UT"],
        ["Vermont", "VT"],
        ["Virginia", "VA"],
        ["Washington", "WA"],
        ["West Virginia", "WV"],
        ["Wisconsin", "WI"],
        ["Wyoming", "WY"],
    ];

    var provinces = [
        ["Alberta", "AB"],
        ["British Columbia", "BC"],
        ["Manitoba", "MB"],
        ["New Brunswick", "NB"],
        ["Newfoundland and Labrador", "NL"],
        ["Northwest Territory", "NT"],
        ["Nova Scotia", "NS"],
        ["Nunavut", "NU"],
        ["Ontario", "ON"],
        ["Prince Edward Island", "PE"],
        ["Quebec", "QC"],
        ["Saskatchewan", "SK"],
        ["Yukon", "YT"],
    ];

    var regions = states.concat(provinces);

    var i; // Reusable loop variable
    if (to == 1) {
        input = input.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
        for (i = 0; i < regions.length; i++) {
            if (regions[i][0] == input) {
                return regions[i][1];
            }
        }
    } else if (to == 1) {
        input = input.toUpperCase();
        for (i = 0; i < regions.length; i++) {
            if (regions[i][1] == input) {
                return regions[i][0];
            }
        }
    }
    return null;
};

const closeChat = (
    dispatch,
    clinicID,
    clinicName,
    patientDOB,
    country,
    city,
    province,
    postalcode,
    healthInsuranceCardNumber,
    healthInsuranceCardFront,
    healthInsuranceCardBack,
    gender,
    patientName,
    paidChat,
    conversationID,
    patientID,
    doctorID,
    doctorName,
    toID,
    isadmin,
    issue,
    category,
    tags,
    symptoms,
    findings,
    assessment,
    plan,
    icd,
    billing_code,
    billing_unit,
    followUpCheck,
    followUpData,
    heartRate,
    weightKg,
    weightLbs,
    stepCount,
    runningWalking,
    heightCm,
    currentSleep,
    averageSleep,
    heightFeet,
    periodStartDate,
    flowType,
    restingHeartRate,
    complete_address,
    prescriptions,
    requisitions,
    special_referals,
    encounter_duration,
    video_consult,
    start_timestamp,
    followUpDays,
    soapnotespdf,
    followUpID,
    prescriptionspdf,
    user_medications
) => {
    console.log(
        "data: ",
        clinicID,
        clinicName,
        patientDOB,
        country,
        city,
        province,
        postalcode,
        healthInsuranceCardNumber,
        healthInsuranceCardFront,
        healthInsuranceCardBack,
        gender,
        patientName,
        paidChat,
        conversationID,
        patientID,
        doctorID,
        doctorName
    );
    let medications = [];
    if (user_medications.length > 0) {
        for (let i = 0; i < user_medications.length; i++) {
            let med = {...user_medications[i]};
            medications.push(med);
        }
    }
    const found = tags.some(item => item.name === "rating_screen");
    var now = new Date();
    var updates = {};

    var historyID = getUserHistoryConversationRef(patientID).push().key;

    var achiveData = {
        status: false,
        queued: false,
        patientID: patientID,
        doctorID: doctorID,
        timestamp: now.getTime(),
        start_timestamp: start_timestamp,
        soapnotespdf: soapnotespdf,
        isPaid: paidChat,
        issue: issue,
        category: category,
        symptoms: symptoms,
        findings: findings,
        assessment: assessment,
        plan: plan,
        icd: icd,
        billing_code: billing_code,
        billing_unit: billing_unit,
        prescriptions: prescriptions,
        requisitions: requisitions,
        special_referals: special_referals,
        encounter_duration: encounter_duration,
        video_consult: video_consult,
        followUpDays: followUpDays,
        followUpData: followUpCheck ? followUpData[0] : null,
        followUpID: followUpID,
        tagsList: tags,
        rateChatFlag: found,
        heartRate:
            heartRate === "" || heartRate === "NaN" || heartRate === "NA" || !Boolean(heartRate)
                ? "NA"
                : heartRate,
        weightKg:
            weightKg === "" || weightKg === "NaN" || weightKg === "NA" || !Boolean(weightKg)
                ? "NA"
                : weightKg,
        weightLbs:
            weightLbs === "" || weightLbs === "NA" || weightLbs === "NaN" || !Boolean(weightLbs)
                ? "NA"
                : weightLbs,
        stepCount:
            stepCount === "" || stepCount === "NaN" || stepCount === "NA" || !Boolean(stepCount)
                ? "NA"
                : stepCount,
        runningWalking:
            runningWalking === "" ||
            runningWalking === "NaN" ||
            runningWalking === "NA" ||
            !Boolean(runningWalking)
                ? "NA"
                : runningWalking,
        heightCm:
            heightCm === "" || heightCm === "NaN" || heightCm === "NA" || !Boolean(heightCm)
                ? "NA"
                : heightCm,
        currentSleep:
            currentSleep === "" ||
            currentSleep === "NaN" ||
            currentSleep === "NA" ||
            !Boolean(currentSleep)
                ? "NA"
                : currentSleep,
        averageSleep:
            averageSleep === "" ||
            averageSleep === "NaN" ||
            averageSleep === "NA" ||
            !Boolean(averageSleep)
                ? "NA"
                : averageSleep,
        heightFeet:
            heightFeet === "" || heightFeet === "NaN" || heightFeet === "NA" || !Boolean(heightFeet)
                ? "NA"
                : heightFeet,
        periodStartDate:
            periodStartDate === "" ||
            periodStartDate === "NaN" ||
            periodStartDate === "NA" ||
            !Boolean(periodStartDate)
                ? "NA"
                : periodStartDate,
        flowType:
            flowType === "" || flowType === "NaN" || flowType === "NA" || !Boolean(flowType)
                ? "NA"
                : flowType,
        restingHeartRate:
            restingHeartRate === "" ||
            restingHeartRate === "NaN" ||
            restingHeartRate === "NA" ||
            !Boolean(restingHeartRate)
                ? "NA"
                : restingHeartRate,
        complete_address: Boolean(complete_address) ? complete_address : "NA",
    };
    if (medications.length > 0) {
        achiveData.prescriptionspdf = prescriptionspdf;
        achiveData.medications = medications;
    }

    var statecode = Boolean(province) ? convertRegion(province, 1) : null;
    var lname = "";
    var fname = "";
    var fullName = patientName.split(" ");
    if (fullName.length === 1) {
        fname = fullName[0];
    } else if (fullName.length === 2) {
        fname = fullName[0];
        lname = fullName[1];
    } else if (fullName.length > 2) {
        fname = patientName.split(" ").slice(0, -1).join(" ");
        lname = patientName.split(" ").slice(-1).join(" ");
    }
    var reportsData = {
        doctorID: doctorID,
        patientID: patientID,
        timestamp: now.getTime(), //visitdate 'mm/dd/yyyy hh:mi'
        findings: findings,
        clinicName: clinicName,
        patientDOB: patientDOB, //birthday 'mm/dd/yyyy'
        city: city, //city
        country: country, //country
        province: province, //province
        postalcode: postalcode, //postalcode
        healthInsuranceCardNumber: healthInsuranceCardNumber,
        healthInsuranceCardFront: healthInsuranceCardFront,
        healthInsuranceCardBack: healthInsuranceCardBack,
        gender: gender, //sex
        patientName: patientName,
        lname: lname, //lname
        fname: fname, //fname
        complete_address: complete_address, //address
        soapnotespdf: soapnotespdf,
        doctorName: doctorName,
        symptoms: symptoms, //visitreason
        //unresolved params= phone,email, famproviderid
        //encounterID,isPatientCreated,isEncounterCreated from alembico api
        //famproviderid= 6 digits OHIP number
        //fs=<pdf bytestream>
        //docType='pdf'
        //clientFile= <pdf file name>
        //docTitle='Virtual Clinic Visit Report'
        /* for canada
            clinic Name: Your Doctor Online
            registerid = clinicID 1027
            doctorEMRid = staffid Dr Cheema 1766
            scannerid = Scan Manager Key:1027 88EC1BE9-175F-4235-9FEB-BA2FC44DCF75


            outside canada

            registerid = clinicID 1030

            your username: raihan-ydointl
            doctorEMRid = staffid Dr Cheema 1766
            your email used: raihan@yourdoctors.online (check email later  when I reset your password)

            scannerid = Scan managerid: 88EC1BE9-175F-4235-9FEB-BA2FC44DCF75
            */
    };

    console.log("patientID: ", patientID);
    if (Boolean(province) && Boolean(statecode)) {
        reportsData.provcode = statecode; //provcode
    }

    if (Boolean(province)) {
        if (province.toLowerCase() === "ontario") {
            reportsData.payor = "OHIP";
            if (healthInsuranceCardNumber) {
                if (healthInsuranceCardNumber.length > 10) {
                    reportsData.hcardversion = healthInsuranceCardNumber.substring(
                        10,
                        healthInsuranceCardNumber.length
                    ); //hcardversion
                    reportsData.hcardno = healthInsuranceCardNumber.substring(0, 10); //hcardno
                }
            }
        }
    }

    var closeData = {
        status: false,
        queued: false,
        patientID: patientID,
        doctorID: doctorID,
        timestamp: now.getTime(),
        start_timestamp: start_timestamp,
        isPaid: paidChat,
        issue: issue,
        category: category,
        location: conversationID,
        symptoms: symptoms,
        findings: findings,
        assessment: assessment,
        plan: plan,
        icd: icd,
        billing_code: billing_code,
        billing_unit: billing_unit,
        followUpData: followUpCheck ? followUpData[0] : null,
        tagsList: tags,
        rateChatFlag: found,
        prescriptions: prescriptions,
        requisitions: requisitions,
        encounter_duration: encounter_duration,
        special_referals: special_referals,
        video_consult: video_consult,
        followUpDays: followUpDays,
        clinicID: clinicID,
        clinical_chat: true,
    };
    console.log("billing_code ", Boolean(billing_code));

    getStaffMemberByRef(clinicID, doctorID).once("value", staffSnap => {
        getOpenConversationsWithLocationRef(clinicID, conversationID).once("value", convosnap => {
            if (convosnap.val()?.stripeToken && Boolean(billing_code)) {
                var url;
                if (ENVIRONMENT === "development") {
                    url = "https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/payWithStripe";
                } else {
                    url = "https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/payWithStripe";
                }
                fetch(
                    ENVIRONMENT === "development" ? `https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/createEMRTask?` : "https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/createEMRTask?" +
                        `init=true&clinic_id=${clinicID}&conversation_id=${conversationID}`,
                    {}
                )
                    .then(success => {
                        console.log("reportingMailData success", success);
                    })
                    .catch(error => {
                        console.log("reportingMailData error", error);
                    });
                fetch(url, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        amount: 4900,
                        id: convosnap.val()?.stripeToken,
                    }),
                })
                    .then(response => response.json())
                    .then(success => {
                        console.log("success: ", success);

                        achiveData.payment_id = success.charge.id;
                        closeData.payment_id = success.charge.id;
                        reportsData.payment_id = success.charge.id;

                        getOpenConversationsWithLocationRef(clinicID, conversationID)
                            .update({status: false})
                            .then(() => {
                                if (staffSnap.val())
                                    if (staffSnap.val().EHRDoctorID)
                                        reportsData.EHRDoctorID = staffSnap.val().EHRDoctorID;
                                updates[`/clinicArchiveConversations/${clinicID}/${conversationID}`] = achiveData;
                                updates[`/clinicalReportsData/${clinicID}/${conversationID}`] = reportsData;
                                updates[`/users/${patientID}/conversations`] = null;
                                updates[`/openClinicalConversations/${clinicID}/${conversationID}`] = null;
                                updates[`/users/${patientID}/history_conversations/${historyID}`] = closeData;
                                if (medications.length > 0) {
                                    let pres = {};
                                    pres.timestamp = now.getTime();
                                    pres.prescriptionspdf = prescriptionspdf;
                                    pres.prescriptions = medications;
                                    pres.symptoms = symptoms;
                                    pres.doctorID = doctorID;
                                    pres.doctorName = doctorName;
                                    pres.clinicID = clinicID;
                                    pres.isSentToPharmacy = true;
                                    updates[`/users/${patientID}/history_prescriptions/${conversationID}`] = pres;
                                }
                                console.log("close chat updates", updates)
                                database
                                    .ref()
                                    .update(updates)
                                    .then(data => {
                                        console.log("data: ", data);
                                        storeCloseChatIndex(clinicID, conversationID);
                                        dispatch({
                                            type: CLOSE_CHAT,
                                            payload: {conversationId: conversationID},
                                        });
                                        database
                                            .ref("users/" + toID)
                                            .once("value", function (snapshot) {
                                                var user_data = snapshot.val();
                                                var historyCount = Boolean(user_data.history_conversations)
                                                    ? Object.keys(user_data.history_conversations).length
                                                    : 0;
                                                sendFirstChatEmail(historyCount, patientID, doctorID);
                                                sendCloseChatNotification(
                                                    user_data.notificationToken,
                                                    doctorName,
                                                    conversationID
                                                );
                                            });
                                    })
                                    .catch(error => {
                                        console.log("error inside: ", error.message);
                                        alert("chat is not properly closed");
                                    });
                            })
                            .catch(error => {
                                console.log("error outside: ", error.message);
                                alert(" close Chat was not properly closed. Please try again");
                            });
                    });
            }
            if (
                !convosnap.val().stripeToken &&
                Boolean(billing_code) &&
                Boolean(healthInsuranceCardNumber)
            ) {
                console.log("is with emr ", healthInsuranceCardNumber);
                //
                fetch(
                    ENVIRONMENT === "development" ? `https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/createEMRTask?` : "https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/createEMRTask?" +

                        `init=true&clinic_id=${clinicID}&conversation_id=${conversationID}`,
                )
                    .then(success => {
                        console.log("reportingMailData success", success);
                    })
                    .catch(error => {
                        console.log("reportingMailData error", error);
                    });
                getOpenConversationsWithLocationRef(clinicID, conversationID)
                    .update({status: false})
                    .then(() => {
                        if (staffSnap.val())
                            if (staffSnap.val().EHRDoctorID)
                                reportsData.EHRDoctorID = staffSnap.val().EHRDoctorID;
                        updates[`/clinicArchiveConversations/${clinicID}/${conversationID}`] = achiveData;
                        updates[`/clinicalReportsData/${clinicID}/${conversationID}`] = reportsData;
                        updates[`/users/${patientID}/conversations`] = null;
                        updates[`/openClinicalConversations/${clinicID}/${conversationID}`] = null;
                        updates[`/users/${patientID}/history_conversations/${historyID}`] = closeData;
                        if (medications.length > 0) {
                            let pres = {};
                            pres.timestamp = now.getTime();
                            pres.prescriptionspdf = prescriptionspdf;
                            pres.prescriptions = medications;
                            pres.symptoms = symptoms;
                            pres.doctorID = doctorID;
                            pres.doctorName = doctorName;
                            pres.clinicID = clinicID;
                            pres.isSentToPharmacy = true;
                            updates[`/users/${patientID}/history_prescriptions/${conversationID}`] = pres;
                        }
                        console.log("close chat updates", updates)
                        database
                            .ref()
                            .update(updates)
                            .then(data => {
                                console.log("data: ", data);
                                storeCloseChatIndex(clinicID, conversationID);
                                dispatch({
                                    type: CLOSE_CHAT,
                                    payload: {conversationId: conversationID},
                                });
                                database
                                    .ref("users/" + toID)
                                    .once("value", function (snapshot) {
                                        var user_data = snapshot.val();
                                        var historyCount = Boolean(user_data.history_conversations)
                                            ? Object.keys(user_data.history_conversations).length
                                            : 0;
                                        sendFirstChatEmail(historyCount, patientID, doctorID);
                                        sendCloseChatNotification(
                                            user_data.notificationToken,
                                            doctorName,
                                            conversationID
                                        );
                                    });
                            })
                            .catch(error => {
                                console.log("error inside: ", error.message);
                                alert("chat is not properly closed");
                            });
                    })
                    .catch(error => {
                        console.log("error outside: ", error.message);
                        alert(" close Chat was not properly closed. Please try again");
                    });
            } else {
                getOpenConversationsWithLocationRef(clinicID, conversationID)
                    .update({status: false})
                    .then(() => {
                        if (staffSnap.val())
                            if (staffSnap.val().EHRDoctorID)
                                reportsData.EHRDoctorID = staffSnap.val().EHRDoctorID;
                        updates[`/clinicArchiveConversations/${clinicID}/${conversationID}`] = achiveData;
                        updates[`/clinicalReportsData/${clinicID}/${conversationID}`] = reportsData;
                        updates[`/users/${patientID}/conversations`] = null;
                        updates[`/openClinicalConversations/${clinicID}/${conversationID}`] = null;
                        updates[`/users/${patientID}/history_conversations/${historyID}`] = closeData;
                        if (medications.length > 0) {
                            let pres = {};
                            pres.timestamp = now.getTime();
                            pres.prescriptionspdf = prescriptionspdf;
                            pres.prescriptions = medications;
                            pres.symptoms = symptoms;
                            pres.doctorID = doctorID;
                            pres.doctorName = doctorName;
                            pres.clinicID = clinicID;
                            pres.isSentToPharmacy = true;
                            updates[`/users/${patientID}/history_prescriptions/${conversationID}`] = pres;
                        }
                        console.log("close chat updates", updates)

                        database
                            .ref()
                            .update(updates)
                            .then(data => {
                                console.log("data: ", data);
                                storeCloseChatIndex(clinicID, conversationID);
                                dispatch({
                                    type: CLOSE_CHAT,
                                    payload: {conversationId: conversationID},
                                });
                                database
                                    .ref("users/" + toID)
                                    .once("value", function (snapshot) {
                                        var user_data = snapshot.val();
                                        var historyCount = Boolean(user_data.history_conversations)
                                            ? Object.keys(user_data.history_conversations).length
                                            : 0;
                                        sendFirstChatEmail(historyCount, patientID, doctorID);
                                        sendCloseChatNotification(
                                            user_data.notificationToken,
                                            doctorName,
                                            conversationID
                                        );
                                    });
                            })
                            .catch(error => {
                                console.log("error inside: ", error.message);
                                alert("chat is not properly closed");
                            });
                    })
                    .catch(error => {
                        console.log("error outside: ", error.message);
                        alert(" close Chat was not properly closed. Please try again");
                    });
            }
        });
    });
};

const storeCloseChatIndex = (clinicid, conversationID) => {
    database.ref(`clinicArchiveConversations/${clinicid}/${conversationID}`)
        .once("value", archiveSnap => {
            if (archiveSnap.val()) {
                if (!archiveSnap.val().status) {
                    var convos = "";
                    database.ref(`/conversations/${conversationID}`)
                        .once("value", convoSnap => {
                            convoSnap.forEach(convoInstance => {
                                if (convoInstance.val().type === "text") {
                                    convos = convos + " " + convoInstance.val().content;
                                }
                            });
                        })
                        .then(data => {
                            database.ref(`/clinicStaffMember/${clinicid}/${archiveSnap.val().doctorID}`)
                                .once("value", doctorSnap => {
                                    if (doctorSnap.val()) {
                                        database.ref(`/users/${archiveSnap.val().patientID}`)
                                            .once("value", patientSnap => {
                                                if (patientSnap.val()) {
                                                    let userName =
                                                        (patientSnap.val().userName === "No name" ||
                                                            patientSnap.val().userName === "Patient") &&
                                                        patientSnap.val().name
                                                            ? patientSnap.val().name
                                                            : patientSnap.val().userName;
                                                    let archiveData = archiveSnap.val();
                                                    let tags = "";
                                                    if (Boolean(archiveData.tagsList)) {
                                                        var tagslist = _.map(archiveData.tagsList, (val, uid) => {
                                                            return {...val, uid};
                                                        });
                                                        tagslist.forEach(tag => {
                                                            if (Boolean(tag.name)) {
                                                                tags = tags + ", " + tag.name;
                                                            }
                                                        });
                                                    }
                                                    let chatCount = patientSnap.val().history_conversations;

                                                    archiveData.tags = tags;
                                                    archiveData.objectID = archiveSnap.key;
                                                    archiveData.conversations = convos;
                                                    archiveData.conversationID = archiveSnap.key;
                                                    archiveData.patientEmail = patientSnap.val().email;
                                                    archiveData.city = patientSnap.val().city;
                                                    archiveData.country = patientSnap.val().country;
                                                    archiveData.platform = patientSnap.val().platform;
                                                    archiveData.DOB = patientSnap.val().DOB;
                                                    archiveData.gender = patientSnap.val().gender;
                                                    archiveData.symptoms =
                                                        archiveData.followUpData !== undefined
                                                            ? archiveData.followUpData.diagnosis
                                                            : patientSnap.val().symptoms;
                                                    archiveData.medication = patientSnap.val().medication;
                                                    archiveData.gmtOffset = patientSnap.val().gmtOffset;
                                                    archiveData.buildNumber = patientSnap.val().buildNumber;
                                                    archiveData.patientName = userName;
                                                    archiveData.convoCount =
                                                        chatCount !== null && chatCount !== undefined
                                                            ? Object.keys(chatCount).length
                                                            : 0;
                                                    archiveData.doctorName = doctorSnap.val().fullName;
                                                    archiveData.doctorProfileImg = doctorSnap.val().profileImageURL;
                                                    archiveData.doctorEmail = doctorSnap.val().email
                                                        ? doctorSnap.val().email
                                                        : doctorSnap.val().staffEmail;
                                                    archiveData.user_id = patientSnap.val().user_id;

                                                    index_store
                                                        .addObject(archiveData)
                                                        .then(data => {
                                                        })
                                                        .catch(error => {
                                                        });
                                                }
                                            })
                                            .then(d => {
                                            })
                                            .catch(error => {
                                            });
                                    }
                                })
                                .then(d => {
                                })
                                .catch(error => {
                                });
                        })
                        .then(d => {
                        })
                        .catch(error => {
                        });
                }
            }
        })
        .then(d => {
        })
        .catch(error => {
        });
};

const sendFirstChatEmail = (historyCount, patientID, doctorID) => {
    if (historyCount === 1) {
        var rootCloudURL;
        if (ENVIRONMENT === "development") {
            rootCloudURL = `https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/createEmailTask?uid=${patientID}&doctorID=${doctorID}&flowType=1`;
        } else {
            rootCloudURL = `https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/createEmailTask?uid=${patientID}&doctorID=${doctorID}&flowType=1`;
        }

        fetch(rootCloudURL)
            .then(() => {
                //console.log("sign up tasks completed");
            })
            .catch(() => {
                //console.log("sign up tasks completed");
            });
    }
};

const sendCloseChatNotification = (notificationToken, doctorName, chatId) => {

    axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/closeChatInapp?notificationToken=${notificationToken}&doctorName=${doctorName}&chatId=${chatId}`,
        mode: 'cors',
    }).then(data=>{}).catch(e=>{})

    axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/closeChat?notificationToken=${notificationToken}&doctorName=${doctorName}&chatId=${chatId}`,
        mode: 'cors',
    }).then(data=>{}).catch(e=>{})


};

export const transferChatToAnotherClinicDoctor = (
    status,
    clinicID,
    conversationId,
    doctor,
    paidChat,
    isadmin,
    patientName
) => {
    return dispatch => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        var updates = {};

        getOpenConversationsWithLocationRef(clinicID, conversationId).once(
            "value",
            conversationData => {
                if (conversationData.val()) {
                    getOpenConversationsWithLocationRef(clinicID, conversationId)
                        .update({
                            queued: status,
                            doctorID: doctor.uid,
                            transferedBy: currentUserID,
                            assignedOn: status ? "transfered_into_queued" : "transfered_chat",
                        })
                        .then(() => {
                            if (ENVIRONMENT === "development") {
                                fetch(
                                    `https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/send_transferNotif`,
                                    {
                                        method: "POST",
                                        body: JSON.stringify({
                                            notificationToken: doctor.notification_token,
                                            patientName: patientName,
                                        }),
                                    }
                                );
                            } else {
                                fetch(
                                    `https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/send_transferNotif`,
                                    {
                                        method: "POST",
                                        body: JSON.stringify({
                                            notificationToken: doctor.notification_token,
                                            patientName: patientName,
                                        }),
                                    }
                                );
                            }
                            dispatch({type: CHAT_TRANSFERED_DONE});
                        })
                        .catch(error => {
                            dispatch({
                                type: CHAT_TRANSFERED_DONE,
                                payload: error.message,
                            });
                        });
                }
            }
        );
    };
};

const getOpenConversationsRef = id => {
    return database.ref(`/openClinicalConversations/${id}`);
};

const getConversationRef = location => {
    return database.ref(`/conversations/${location}`);
};

const getUserRef = uid => {
    return database.ref(`/users/${uid}`);
};

const getDoctorRef = uid => {
    return database.ref(`/doctors/${uid}`);
};

const getOpenConversationsWithLocationRef = (clinicID, location) => {
    return database.ref(`/openClinicalConversations/${clinicID}/${location}`);
};

const getUserHistoryConversationRef = uid => {
    return database.ref(`/users/${uid}/history_conversations`);
};

const getdoctorByRef = uid => {
    return database.ref(`/doctors/${uid}`);
};

const getStaffMemberByRef = (clinicid, uid) => {
    return database.ref(`/clinicStaffMember/${clinicid}/${uid}`);
};

const getClinicRefByID = clinicid => {
    return database.ref(`/clinic/${clinicid}`);
};
const getOpenConversationsPrescriptionWithLocationRef = (uid) => {
    return database.ref(`/openconversations/${uid}/prescriptions`);
};
const getOpenConversationsPrescriptionDataWithLocationRef = (uid) => {
    return database.ref(`/openconversations/${uid}/prescriptions_data`);
};
