import  {Component} from 'react';
import classes from './editchatdetail.module.css';
import {connect} from 'react-redux';
import TextareaAutosize from 'react-autosize-textarea';
import MyPdf from '../../clinicalSide/encounters/CreatePdf';
import {refreshState, updateOutPatientForm} from '../../../actions/ConversationAction';
import {pdf} from '@react-pdf/renderer';
import {functions, storage, storageRef} from "../../../firebase";


const CannedResponsesList = (props) => {
    return (
        props.canned_responses_data.map(function (data, index) {
            return (
                <div key={index} className={classes.canned_response_item}
                     onClick={() => props.onCannedResponseClick(data.uid, data.shortcut, data.text)}>
                    <div className={classes.canned_response_text}>{data.shortcut}</div>
                    <div className={classes.canned_response_arrow}
                         onClick={() => props.onCannedResponseClick(data.uid, data.shortcut, data.text)}>
                        <svg data-test="shortcut-select-button" fill="#000000" width="4px" height="12px"
                             viewBox="0 0 8 13">
                            <g fill="inherit" fillRule="evenodd">
                                <path fillRule="nonzero" d="M.59 11.34l4.58-4.59L.59 2.16 2 .75l6 6-6 6z"></path>
                            </g>
                        </svg>
                    </div>
                </div>
            )
        })
    )
};

function formatDate() {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(d.setDate(d.getDate() + 1)),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

class PatientForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dateTime: formatDate(),
            access_token: '',
            events: [],
            assessment: '',
            opinionDescription: false,
            opinionDescriptionValue: '',
            followUpCheck: false,
            followUpUpdated: false,
            medicationCheck: false,
            medication: [{
                id: null,
                medicationName: '',
                medicationDosage: 1,
                medicationUnit: 'mg',
                frequency: 'Once',
                frequencyInterval: 'Daily',
                medicationUnitDropDown: false,
                medicationFrequencyDropDown: false,
                medicationFrequencyIntervalDropDown: false,
                isMedicationUpdated: false,
                medicationData: []
            }],
            displayDiagnosisList: false,
            plan: '',
            findings: '',
            symptoms: '',
            issueResolved: '',
            canned_responses: [],
            showCannedResponseForObjectiveFindingsList: false,
        };
        this.baseState = this.state;
    }

    UNSAFE_componentWillMount() {
        const getToken = functions.httpsCallable('getToken');
        getToken().then(result => {
            this.setState({access_token: JSON.parse(result.data).access_token});
        });
    }

    filterUsers = (event) => {
        var keyword = event.toLowerCase();
        var filtered_cannedResponses = this.state.canned_responses.filter((user) => {
            user = user.shortcut.toLowerCase();
            return user.indexOf(keyword) > -1;
        });
        if (filtered_cannedResponses.length !== 0) {
            this.setState({canned_responses: filtered_cannedResponses});
        } else {
            this.setState({canned_responses: this.props.canned_responses});
        }
    }

    diagnosisInput = (event) => {
        if ((event.target.value).match('#')) {
            this.setState({showCannedResponseForObjectiveFindingsList: true});
            this.filterUsers((event.target.value.slice((event.target.value).search('#'), event.target.value.length)));
        }
        this.setState({assessment: event.target.value});
    }

    onObjectiveCannedResponseClick = (uid, short_cut, text) => {
        this.setState({showCannedResponseForObjectiveFindingsList: false});
        var updatedString;

        if (this.state.assessment.search('#') !== -1) {
            var replaceText = (this.state.assessment.slice((this.state.assessment).search('#'), this.state.assessment.length));
            updatedString = (this.state.assessment).replace(replaceText, text);
        } else {
            updatedString = (this.state.assessment).concat(' ', text);
        }
        this.setState({assessment: updatedString});
    }

    onChangeCommentAdded = (event) => {
        this.setState({plan: event.target.value, followUpUpdated: true});
    }

    onCancelClick = () => {
        this.props.hideModal();
    }

    updateOutPatientFormClick = async () => {
        // console.log("updateoutpatientform props", this.props)
        const {
            symptoms,
            assessment,
            followUpCheck,
            dateTime,
            medicationCheck,
            medication,
            plan,
            findings,
            followUpUpdated,
            issueResolved,
        } = this.state;
        const {
            archive_patientIssue,
            patientName,
            doctorName,
            archive_userID,
            archive_conversationID,
            archive_patientFollowUpData
        } = this.props;
        this.props.updateOutPatientForm(archive_patientIssue, patientName, doctorName, archive_userID, archive_conversationID,
            assessment, followUpCheck, dateTime, medicationCheck,
            medication, plan,findings, followUpUpdated, archive_patientFollowUpData, issueResolved, symptoms);

    }

    uploadPdf = (clinicId, patientId, conversationId, filename, file, metadata, fax_number, location, callback) => {
        //var uploadTask = storage.ref(`/${location}/tier2/${clinicId}/${patientId}/${conversationId}/`).child(`${filename}`).put(file, metadata);
        var uploadTask = storage.ref(`/${location}/tier1/${patientId}/${conversationId}/`).child(`${filename}`).put(file, metadata);
        uploadTask.on("state_changed" , (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                //console.log('firebasestorage Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case storageRef.TaskState.PAUSED: // or 'paused'
                        //console.log('firebasestorage Upload is paused');
                        break;
                    case storageRef.TaskState.RUNNING: // or 'running'
                        //console.log('firebasestorage Upload is running');
                        break;
                }
            },
            (error) => {
                //console.log("firebasestorage error ", error)
                callback(null)
                // Handle unsuccessful uploads
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    //console.log('firebasestorage File available at', downloadURL);
                    callback(downloadURL)
                }).catch((error) =>{
                    callback(null)
                })
            });
    }

    closeOnSaveClick = () => {
        this.props.hideModal();
        this.setState(this.baseState);
        this.props.refreshState();
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        // console.log("editchatdetails props", this.props)
        // console.log("editchatdetails newprops", newProps)
        if(newProps.show !== this.props.show){
            //console.log("if")
            this.setState({symptoms: newProps.archive_patientSymptoms || ""});
            this.setState({assessment: newProps.archive_assessment || ""});
            this.setState({plan: newProps.archive_plan || ""});
            this.setState({findings: newProps.archive_findings || ""});
            this.setState({issueResolved: newProps.archive_issueResolved || ""});
        }else{
            //console.log("else")
            if (newProps.archive_assessment !== this.props.archive_assessment) {
                // console.log("newProps ", newProps)
                // console.log("this.props ", this.props)
                // console.log("this.state ", this.state)
                this.setState({assessment: newProps.archive_assessment || ""});
            } else if(!newProps.archive_assessment && (newProps.archive_diagnosisValue !== this.props.archive_diagnosisValue)){
                this.setState({assessment: newProps.archive_diagnosisValue || ''});
            }
            if (newProps.archive_plan !== this.props.archive_plan) {
                this.setState({plan: newProps.archive_plan || ""});
            }else if (!newProps.archive_plan && (newProps.archive_commentAdded !== this.props.archive_commentAdded)) {
                this.setState({plan: newProps.archive_commentAdded || ""});
            }

            if (newProps.archive_findings !== this.props.archive_findings) {
                this.setState({findings: newProps.archive_findings || ""});
            }

            if (newProps.archive_issueResolved !== this.props.archive_issueResolved) {
                this.setState({issueResolved: newProps.archive_issueResolved});
            }

            if (newProps.archive_patientSymptoms !== this.props.archive_patientSymptoms) {
                this.setState({symptoms: newProps.archive_patientSymptoms || ""});
            }
        }


        if (newProps.archive_opinionDescriptionValue !== this.props.archive_opinionDescriptionValue) {
            this.setState({
                opinionDescription: true,
                opinionDescriptionValue: newProps.archive_opinionDescriptionValue
            });
        } else {
            this.setState({opinionDescription: false, opinionDescriptionValue: ''});
        }

        if (newProps.archive_patientFollowUpData && newProps.archive_patientFollowUpData.length !== 0) {
            const {scheduled_date} = newProps.archive_patientFollowUpData;
            var event = new Date(scheduled_date);
            let date = JSON.stringify(event);
            date = date.slice(1, 11);
            this.setState({followUpCheck: true, dateTime: date});
        } else {
            this.setState({followUpCheck: false, dateTime: this.baseState.dateTime});
        }

        if (newProps.canned_responses !== this.props.canned_responses) {
            this.setState({canned_responses: newProps.canned_responses});
        }
        if(newProps.archive_patientIssue !== this.props.archive_patientIssue){
            this.setState({findings: newProps.archive_findings || newProps.archive_patientIssue || ''});
        }
    }

    handleChangeIssueResolved = (text) => {
        this.setState({issueResolved: text});
    }

    render() {
        const {
            symptoms,
            assessment,
            opinionDescription,
            opinionDescriptionValue,
            plan,
            canned_responses,
            issueResolved,
            findings,
        } = this.state;
        console.log("this.state ", this.state)
        const {archive_patientIssue} = this.props;

        return (
            <div className={classes.modal} style={{
                transform: this.props.show ? 'translateY(0)' : 'translateY(-100vh)',
                opacity: this.props.show ? '1' : '0'
            }}>

                <div className={classes.header}>Edit SOAP Note</div>
                {this.props.message === '' &&
                <div className={classes.container}>
                    <div className={classes.content}>
                    <div className={classes.main}>
                            <div className={classes.symptomsText}>Subjective Symptoms:</div>
                            <TextareaAutosize
                                readOnly={false}
                                className={classes.input}
                                value={symptoms}
                                onChange={(event)=>{
                                    this.setState({symptoms:event.target.value})
                                }}
                            />
                        </div>
                        <div className={classes.main}>
                            <div className={classes.symptomsText}>Objective Findings:</div>
                            <TextareaAutosize
                                readOnly={false}
                                className={classes.input}
                                value={findings}
                                onChange={(event)=>{
                                    this.setState({findings:event.target.value})
                                }}
                            />
                        </div>
                        <div className={classes.main}>
                            <div className={classes.symptomsText}>Assessment:</div>
                            <TextareaAutosize
                                className={classes.input}
                                onChange={(text) => this.diagnosisInput(text)}
                                value={assessment}
                            />
                            {this.state.showCannedResponseForObjectiveFindingsList &&
                            <div className={classes.canned_response_list}>
                                <div className={classes.canned_response_view}>
                                    <CannedResponsesList
                                        canned_responses_data={canned_responses}
                                        onCannedResponseClick={(uid, short_cut, text) => this.onObjectiveCannedResponseClick(uid, short_cut, text)}
                                    />
                                </div>
                            </div>
                            }
                        </div>

                        {opinionDescription &&
                        <div className={classes.diagnosticMain}>
                            <div className={classes.diagnosisText}>Objective Description:</div>
                            <textarea
                                className={classes.diagnoticTextArea}
                                value={opinionDescriptionValue}
                                readOnly={true}
                            />
                        </div>
                        }

                        <div className={classes.main}>
                            <div className={classes.diagnosisText}>Plan:</div>
                            <TextareaAutosize
                                className={classes.input}
                                onChange={(text) => this.onChangeCommentAdded(text)}
                                value={plan}
                            />
                        </div>
                        <div className={classes.main}>
                            <div className={classes.diagnosisText}>Issue resolved:</div>
                            <div className={classes.contentView}>
                                <input
                                    type="radio"
                                    onChange={() => this.handleChangeIssueResolved('virtually')}
                                    checked={issueResolved === 'virtually' ? true : false}
                                />
                                <div className={classes.yesText}>Virtually</div>
                                <input
                                    type="radio"
                                    onChange={() => this.handleChangeIssueResolved('doctor_visit_needed')}
                                    checked={issueResolved === 'doctor_visit_needed' ? true : false}
                                />
                                <div className={classes.yesText}>Doctor visit needed</div>
                            </div>
                        </div>


                        {!this.props.loading &&
                        <div className={classes.buttonView}>
                            <button className={classes.addMoreMediciensButton}
                                    onClick={() => this.updateOutPatientFormClick()}>Update and Close
                            </button>
                            <button className={classes.cancelButton} onClick={() => this.onCancelClick()}>Cancel
                            </button>
                        </div>
                        }

                        {this.props.error !== '' &&
                        <div className={classes.buttonView}>
                            <div className={classes.errorText}>{this.props.error}</div>
                            <button className={classes.addMoreMediciensButton}
                                    onClick={() => this.onCancelClick()}>Cancel
                            </button>
                        </div>
                        }

                        {this.props.loading &&
                        <div className={classes.buttonView}>
                            <div className={classes.no_loading_view}>
                                <div className={classes.loading_loader}/>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                }
                {this.props.message !== '' &&
                <div className={classes.saveView}>
                    <div className={classes.messageText}>{this.props.message}</div>
                    <button className={classes.cancelButton} onClick={() => this.closeOnSaveClick()}>OK</button>
                </div>
                }
            </div>
        )
    }
}

const mapStateToProps = ({outPatientFormReducer, cannedResponseReducer}) => {
    const {loading, message, error} = outPatientFormReducer;
    const {canned_responses} = cannedResponseReducer;
    return {loading, message, error, canned_responses};
}

export default connect(mapStateToProps, {updateOutPatientForm, refreshState})(PatientForm);
