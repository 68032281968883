import  {Component} from 'react';
import MetaTags from 'react-meta-tags';

import classes from '../../assets/classes.module.css';
import _classes from '../../assets/_classes.module.css';
import Input from '../../components/UI/Input/ClinicInput';
import Spinner from '../../components/UI/Spinner/Spinner';
import logo from '../../assets/logo.png';

import {connect} from 'react-redux';

import {loginUser} from '../../actions/LoginAction';
import isMobileView from '../../utils/isMobileView';

class Auth extends Component {

    state = {
        controls: {
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'User email address',
                    tooltip: 'Email address for login',
                    style: {
                        'padding': '20px 15px'
                    }
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            },
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Password',
                    tooltip: 'Password for login',
                    style: {
                        'padding': '20px 15px'
                    }
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false
            }
        }
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    inputChangedHandler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: this.checkValidity(event.target.value, this.state.controls[controlName].validation),
                touched: true
            }
        };
        this.setState({controls: updatedControls});
    }

    submitHandler = (event) => {
        event.preventDefault();
        this.props.loginUser(this.state.controls.email.value, this.state.controls.password.value);
    }

    onGetStartedClick = () => {
        const path = {
            pathname: '/create_clinic',
            search: '',
            hash: '',
        }
        this.props.history.push(path);
    }

    onForgotPassword = () => {
        const path = {
            pathname: '/forgot_password',
            search: '',
            hash: '',
        }
        this.props.history.push(path);
    }

    onContactUs = () => {
        window.location.href = `mailto: info@yourdoctors.online`;
    }

    render() {
        const formElementsArray = [];
        for (let key in this.state.controls) {
            formElementsArray.push({
                id: key,
                config: this.state.controls[key]
            });
        }

        let errorMessage = null;

        if (this.props.error) {
            errorMessage = (
                isMobileView() ? <div className={_classes.error_container}>
                    <div className={_classes.absolute_error_bar}/>
                    <div className={_classes.error}>{this.props.error}</div>
                </div> : <div className={classes.error_container}>
                    <div className={classes.absolute_error_bar}/>
                    <div className={classes.error}>{this.props.error}</div>
                </div>
            );
        }

        let form = (
            isMobileView() ? <div>
                {formElementsArray.map(formElement => (
                    <Input
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)}/>
                ))}
                {errorMessage}
                <div className={_classes.bottom_view}>

                    <button className={_classes.button} onClick={this.submitHandler}>Login</button>
                    <button className={_classes.transparent_button}
                            onClick={(event) => this.onForgotPassword(event)}>Forgot Password?
                    </button>
                </div>
            </div> : <div>
                {formElementsArray.map(formElement => (
                    <Input
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)}/>
                ))}
                {errorMessage}
                <div className={classes.bottom_view}>
                    <button className={classes.transparent_button}
                            onClick={(event) => this.onForgotPassword(event)}>Forgot Password?
                    </button>
                    <button className={classes.button} onClick={this.submitHandler}>Login</button>
                </div>
            </div>
        );

        if (this.props.loading) {
            form = <Spinner/>
        }
        return (
            isMobileView() ? <div className={_classes.container}>
                <MetaTags>
                    <title>Sign in | Virtual Clinic</title>
                    <meta name="get_started" content="Get started."/>
                </MetaTags>
                <div className={_classes.content}>
                    <div className={_classes.main_view}>
                        <div className={_classes.logo}>
                            <img src={logo} alt="logo" className={classes.logo_container}/>
                        </div>
                        <span className={_classes.heading}>Sign in to your account</span>
                        <p className={_classes.sub_heading}>Our platform works best with a<b> Google Chrome </b>browser.
                            Please enter your email address below</p>
                        <div className={_classes.form_view}>
                            {form}
                            {/* <div className={_classes.bottom_center_view}>
                               <span className={-classes.bottom_text}>Need to create a new virtual clinic?</span>
                               <button className={_classes.get_started} onClick={() => this.onGetStartedClick()}>Get Started</button>
                           </div> */}
                        </div>
                    </div>
                </div>

            </div> : <div className={classes.container}>
                <MetaTags>
                    <title>Sign in | Virtual Clinic</title>
                    <meta name="get_started" content="Get started."/>
                </MetaTags>
                <div className={classes.content}>
                    <div className={classes.main_view}>
                        <div className={classes.logo}>
                            <img src={logo} alt="logo" className={classes.logo_container}/>
                        </div>
                        <span className={classes.heading}>Sign in to your account</span>
                        <p className={classes.sub_heading}>Our platform works best with a<b> Google Chrome </b>browser.
                            Please enter your email address below</p>
                        <div className={classes.form_view}>
                            {form}
                            <div className={classes.bottom_center_view}>
                                <span className={classes.bottom_text}>Need to create a new virtual clinic?</span>
                                <button className={classes.get_started} onClick={() => this.onGetStartedClick()}>Get
                                    Started
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.footer}>
                    <a href='https://yourdoctors.online/wp-content/uploads/2020/10/YDO_Terms_of_Use.pdf'
                       target='_blank'>Privacy & Terms</a>
                    <a href='mailto: info@yourdoctors.online'>Contact Us</a>
                </div>
            </div>
        );
    }
}


const mapStateToProps = ({loginAuth}) => {
    const {loading, error} = loginAuth;
    return {loading, error};
};

export default connect(mapStateToProps, {loginUser})(Auth);
