import {Component, createRef, Fragment} from 'react';
import {connect} from 'react-redux';

import classes from './UpdateAgent.module.css';
import {
    addFreeChatLimit,
    addPaidChatLimit,
    addTotalChatLimit,
    fetchAgentData,
    minusFreeChatLimit,
    minusPaidChatLimit,
    minusTotalChatLimit,
    showImagePreview,
    showSignaturePreview,
    updateClinicAddress,
    updateClinicLocation,
    updateClinicName,
    updateClinicPhoneNumber,
    updateClinicStatus,
    updateDoctorDetail,
    updateDoctorLicense,
    updateDoctorName,
    updateDoctorTitle,
    updateDoctorDescription,
    updateDoctorExperience,
    updateDoctorHospitalLocationChange,
    updateDoctorChatAssignCheckChange,
    resetAgentsMessage
} from '../../../actions/AgentsAction';
import {auth} from "../../../firebase";


const Header = (props) => {
    return (
        <div className={classes.add_content_header}>
            <div className={classes.absolute_back_button} onClick={() => props.backClick()}>
                <a href="true">
                    <div className={classes.back_button}>
                        <svg width="16" height="16" viewBox="0 0 16 16">
                            <g fill="none" fillRule="evenodd">
                                <path d="M-4-4h24v24H-4z"></path>
                                <path fill="#4384F5" fillRule="nonzero"
                                      d="M16 7H3.83l5.59-5.59L8 0 0 8l8 8 1.41-1.41L3.83 9H16z"></path>
                            </g>
                        </svg>
                    </div>
                    <span>Back</span>
                </a>
            </div>
            <div className={classes.absolute_header}>{props.doctorName}</div>
        </div>
    )
}

const UpdatePasswordForm = (props) => {
    if (props.changePasswordSelected) {
        return (
            <div className={classes.update_password_main}>
                <span className={classes.update_password_header}> Update Password </span>
                <div className={classes.update_password}>
                    <div className={classes.update_password_form}>
                        <div className={classes.update_password_field}>
                            <div className={classes.update_password_tag}>
                                <input
                                    onChange={(event) => props.onOldPasswordChange(event)}
                                    value={props.currentState.old_password}
                                    placeholder="Old Password"
                                    type="password"
                                />
                            </div>
                            <div className={classes.update_password_tag}>
                                <input
                                    onChange={(event) => props.onNewPasswordChange(event)}
                                    value={props.currentState.new_password}
                                    placeholder="New Password"
                                    type="password"
                                />
                            </div>
                            <div className={classes.update_password_tag}>
                                <input
                                    onChange={(event) => props.onConfirmPasswordChange(event)}
                                    value={props.currentState.confirm_password}
                                    placeholder="Confirm New Password"
                                    type="password"
                                />
                            </div>
                            <div className={(props.currentState.show_error) ? classes.error_msg : classes.hidden}>
                                <span>{props.currentState.error_msg}</span>
                            </div>
                        </div>
                        <div className={classes.update_password_submit}>
                            <span className={classes.password_button} onClick={() => props.onChangePasswordSubmit()}>Update Password</span>
                            <span>or</span>
                            <span className={classes.password_button}
                                  onClick={() => props.onCancelClicked()}>Cancel</span>
                        </div>

                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <div className={(props.currentState.show_success) ? classes.success_msg : classes.hidden}>
                    <span>Password Changed Successfully!</span>
                </div>
                <div className={classes.absolute_change_password} onClick={() => props.onchangePasswordClick()}>Change
                    Password
                </div>
            </div>
        )
    }
}

class UpdateAgent extends Component {

    constructor(props) {
        super(props);
        this.textInput = createRef();
        this.signatureFile = createRef();
        this.state = {
            file: '',
            filetype: '',
            signature_file: '',
            signatureFileType: '',
            changePasswordSelected: false,
            old_password: "",
            new_password: "",
            confirm_password: "",
            show_error: false,
            error_msg: "",
            show_success: false,
            clinicaldoctorUpdate: false,
            clinicalDoctor: false,
            clinicName: '',
            locationName: '',
            chatTypeOptions: [
                {value: "--", displayValue: 'Please select doctor chat type'},
                {value: 'health-card', displayValue: 'Health Card'},
                {value: 'private-pay', displayValue: 'Private Pay'}
            ],
            doctorChatType: null,

        }
    }
    componentWillReceiveProps(nextProps) {
        if(this.props.agentData?.doctorChatType !== nextProps.agentData?.doctorChatType){
            let type = this.state.chatTypeOptions.find(option=> option.value === nextProps.agentData?.doctorChatType)
            this.setState({doctorChatType: type || {value: "--", displayValue: 'Please select doctor chat type'}})
        }
    }
    sendChangePasswordEmail = () => {
        auth.sendPasswordResetEmail(this.props.agentData.email);
    }

    componentDidMount() {
        this.props.resetAgentsMessage();
        this.props.fetchAgentData(this.props.history.location.state.agentID);
        let type = this.state.chatTypeOptions.find(option=> option.value === this.props?.agentData?.doctorChatType)
        this.setState({doctorChatType: type || {value: "--", displayValue: 'Please select doctor chat type'}})

    }

    onDoctorNameChange = (event) => {
        this.props.updateDoctorName(event.target.value);
    }

    onDoctorTitleChange = (event) => {
        this.props.updateDoctorTitle(event.target.value);
    }

    onDoctorLicenseChange = (event) => {
        this.props.updateDoctorLicense(event.target.value);
    }

    onDoctorDescriptionChange = (event) => {
        this.props.updateDoctorDescription(event.target.value);
    }
    onDoctorExperienceChange = (event) => {
        this.props.updateDoctorExperience(event.target.value);
    }
    onDoctorHospitalLocationChange = (event) => {
        this.props.updateDoctorHospitalLocationChange(event.target.value);
    }
    onDoctorChatAssignCheckChange = (event) => {
        this.props.updateDoctorChatAssignCheckChange(event.target.value);
    }

    onAttachmentClick = (e) => {
        e.current.click();
    }

    _handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            var type = file.type.split('/');
            this.props.showImagePreview(reader.result);
            this.setState({
                file: file,
                filetype: type[0],
                changePasswordSelected: false
            });
        }
        reader.readAsDataURL(file);
    }

    _handleSignatureChnage = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            var type = file.type.split('/');
            this.props.showSignaturePreview(reader.result);
            this.setState({
                signature_file: file,
                signatureFileType: type[0],
                changePasswordSelected: false
            });
        }
        reader.readAsDataURL(file);
    }

    addTotalClick = () => {
        if (this.props.admin) {
            this.props.addTotalChatLimit(this.props.updateTotalChatLimit);
        }
    }

    minusTotalClick = () => {
        if (this.props.admin) {
            this.props.minusTotalChatLimit(this.props.updateTotalChatLimit);
        }
    }

    addFreeClick = () => {
        if (this.props.admin) {
            this.props.addFreeChatLimit(this.props.updateFreeChatLimit);
        }
    }

    minusFreeClick = () => {
        if (this.props.admin) {
            this.props.minusFreeChatLimit(this.props.updateFreeChatLimit);
        }
    }

    addPaidClick = () => {
        if (this.props.admin) {
            this.props.addPaidChatLimit(this.props.updatePaidChatLimit);
        }
    }

    minusPaidClick = () => {
        if (this.props.admin) {
            this.props.minusPaidChatLimit(this.props.updatePaidChatLimit);
        }
    }

    backClick = () => {
        this.props.history.goBack();
    }

    onchangePasswordClick = (event) => {
        this.setState({changePasswordSelected: true, show_success: false});
    }

    onSaveClick = (event) => {
        event.preventDefault();
        if(this.state.doctorChatType.value === "--"){
            alert("Please select doctor's chat type")
            return;
        }
        this.props.updateDoctorDetail(this.state.file, this.state.signature_file, this.props.history.location.state.agentID, this.props,
            this.state.clinicaldoctorUpdate, this.props.agentData.email, this.state.doctorChatType);
    }

    onCancelClick = (event) => {
        event.preventDefault();
        this.props.history.goBack();
    }

    onOldPasswordChange = (event) => {
        this.setState({old_password: event.target.value});
    }

    onNewPasswordChange = (event) => {
        this.setState({new_password: event.target.value});
    }

    onConfirmPasswordChange = (event) => {
        this.setState({confirm_password: event.target.value});
    }

    onChangePasswordSubmit = (event) => {

        this.setState({show_error: false, error_msg: ""});
        var user = auth.currentUser;

        auth.signInWithEmailAndPassword(user.email, this.state.old_password)
            .then(() => {
                if (this.state.new_password !== this.state.confirm_password) {
                    this.setState({show_error: true, error_msg: "New and confirm passwords do not match"});
                } else if (this.state.new_password.length < 8) {
                    this.setState({show_error: true, error_msg: "Password must be atleast of 8 characters"});
                } else {

                    user.updatePassword(this.state.new_password)
                        .then(() => {
                            this.setState({
                                show_success: true,
                                show_error: false,
                                error_msg: "",
                                old_password: "",
                                new_password: "",
                                confirm_password: "",
                                changePasswordSelected: false
                            });
                        }).catch((error) => {
                        this.setState({show_error: true, error_msg: "Update password failed"});
                    });
                }
            }).catch((error) => {
            this.setState({show_error: true, error_msg: "Old password authenticated failed"});
        });
    }

    onCancelClicked = () => {
        this.setState({
            changePasswordSelected: false,
            old_password: "",
            new_password: "",
            confirm_password: "",
            show_error: false,
            error_msg: "",
            show_success: false,
        });
    }

    handleChange = () => {
        this.setState({clinicaldoctorUpdate: true});
        this.props.updateClinicStatus(this.props.clinicalDoctorStatus);
    }

    clinicNameChange = (event) => {
        this.props.updateClinicName(event.target.value);
    }

    clinicLocationChange = (event) => {
        this.props.updateClinicLocation(event.target.value);
    }

    clinicAddressChange = (event) => {
        this.props.updateClinicAddress(event.target.value);
    }

    clinicPhoneNumberChange = (event) => {
        this.props.updateClinicPhoneNumber(event.target.value);
    }
    onDoctorChatTypeChange = (event) => {
        let type = this.state.chatTypeOptions.find(option=> option.value === event.target.value)

        this.setState({doctorChatType: type || event.target.value})
    }


    render() {
        return (
            <div className={classes.main}>
                <div className={classes.add_content}>
                    <div className={classes.content_block}>
                        <div className={classes.add_content_body}>
                            <Header doctorName={this.props.agentData.doctorName} backClick={() => this.backClick()}/>
                            <div className={classes.add_body}>
                                <div className={classes.main_add_body}>
                                    <div className={classes.form_body}>
                                        <div className={classes.image_body}>
                                            <div className={classes.image_header}>
                                                <img
                                                    className={classes.image_header}
                                                    src={this.props.updateImageURL}
                                                    alt=""
                                                />
                                            </div>
                                            <div className={classes.image_header_hover}
                                                 onClick={() => this.onAttachmentClick(this.textInput)}>Change Picture
                                                <input
                                                    type="file"
                                                    accept="image/jpeg"
                                                    className={classes.inputStyle}
                                                    ref={this.textInput}
                                                    onChange={(e) => this._handleImageChange(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.form_content}>
                                            <fieldset className={classes.content_field}>
                                                <label className={classes.floated}>Name</label>
                                                <input
                                                    className={classes.font_size}
                                                    value={this.props.updateName}
                                                    onChange={(event) => this.onDoctorNameChange(event)}
                                                />
                                            </fieldset>
                                            <fieldset className={classes.content_field}>
                                                <label className={classes.floated}>Job title</label>
                                                <input
                                                    className={classes.sub_font_size}
                                                    value={this.props.updateTitle}
                                                    onChange={(event) => this.onDoctorTitleChange(event)}
                                                />
                                            </fieldset>
                                            <fieldset className={classes.content_field}>
                                                <label className={classes.floated}>License #</label>
                                                <input
                                                    className={classes.sub_font_size}
                                                    value={this.props.updateLicense}
                                                    onChange={(event) => this.onDoctorLicenseChange(event)}
                                                />
                                            </fieldset>
                                            <fieldset className={classes.content_field}>
                                                <label className={classes.floatedText}>Description</label>
                                                <textarea
                                                    className={classes.sub_font_size}
                                                    value={this.props.updateDescription}
                                                    onChange={(event) => this.onDoctorDescriptionChange(event)}
                                                />
                                            </fieldset>
                                            <fieldset className={classes.content_field}>
                                                <label className={classes.floated}>Experience</label>
                                                <input
                                                    className={classes.sub_font_size}
                                                    value={this.props.updateExperience}
                                                    onChange={(event) => this.onDoctorExperienceChange(event)}
                                                />
                                            </fieldset>
                                            <fieldset className={classes.content_field}>
                                                <label className={classes.floated}>Hospital Location</label>
                                                <input
                                                    className={classes.sub_font_size}
                                                    value={this.props.updateHospitalLocation}
                                                    onChange={(event) => this.onDoctorHospitalLocationChange(event)}
                                                />
                                            </fieldset>
                                            <fieldset className={classes.content_field}>
                                                <label className={classes.floated}>Chat Type</label>
                                                <select
                                                    className={classes.select_input}
                                                    value={this.state.doctorChatType?.value}
                                                    onChange={(event) => this.onDoctorChatTypeChange(event)}>
                                                    {this.state.chatTypeOptions.map(option => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.displayValue}
                                                        </option>
                                                    ))}
                                                </select>
                                            </fieldset>
                                            <fieldset className={classes.content_field}>
                                                <label className={classes.floated}>Email</label>
                                                <input
                                                    className={classes.sub_font_size}
                                                    value={this.props.agentData.email ? this.props.agentData.email : ''}
                                                    readOnly={true}
                                                />
                                                <div className={classes.lock}>
                                                    <svg width="16px" height="16px" viewBox="0 0 16 16">
                                                        <path fill="inherit" fillRule="nonzero"
                                                              d="M12 5.333h-.667V4a3.335 3.335 0 0 0-6.666 0v1.333H4c-.733 0-1.333.6-1.333 1.334v6.666c0 .734.6 1.334 1.333 1.334h8c.733 0 1.333-.6 1.333-1.334V6.667c0-.734-.6-1.334-1.333-1.334zm-4 6c-.733 0-1.333-.6-1.333-1.333S7.267 8.667 8 8.667s1.333.6 1.333 1.333-.6 1.333-1.333 1.333zm2.067-6H5.933V4c0-1.14.927-2.067 2.067-2.067 1.14 0 2.067.927 2.067 2.067v1.333z"></path>
                                                    </svg>
                                                </div>
                                            </fieldset>
                                            <fieldset className={classes.content_field}>
                                                <div className={classes.license_container}>
                                                    <input
                                                        type="file"
                                                        accept="image/jpeg"
                                                        className={classes.inputStyle}
                                                        ref={this.signatureFile}
                                                        onChange={(e) => this._handleSignatureChnage(e)}
                                                    />
                                                    {this.props.signatureImageURL !== null &&
                                                    <img
                                                        className={classes.license_container}
                                                        src={this.props.signatureImageURL}
                                                        alt=""
                                                    />
                                                    }

                                                    {this.props.signatureImageURL === null &&
                                                    <div>Upload digital signature</div>
                                                    }
                                                </div>
                                            </fieldset>
                                            <span className={classes.absolute_change_password}
                                                  onClick={() => this.onAttachmentClick(this.signatureFile)}> Update Digital Signature File </span>

                                            <label className={classes.label_style}>
                                                {/* <span className={classes.span_style}>Clinical Doctor: </span>
                                                <Switch
                                                    onChange={() => this.handleChange()}
                                                    checked={this.props.clinicalDoctorStatus}
                                                    onColor="#85479f"
                                                    onHandleColor="#d36797"
                                                    handleDiameter={30}
                                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                    height={20}
                                                    width={48}
                                                    className="react-switch"
                                                /> */}
                                            </label>
                                            {this.props.clinicalDoctorStatus &&
                                            <Fragment>
                                                <fieldset className={classes.content_field}>
                                                    <label className={classes.floated}>Clinic Name</label>
                                                    <input
                                                        placeholder="Clinic Name"
                                                        className={classes.sub_font_size}
                                                        value={this.props.clinicName}
                                                        onChange={(event) => this.clinicNameChange(event)}
                                                    />
                                                </fieldset>
                                                <fieldset className={classes.content_field}>
                                                    <label className={classes.floated}>Clinic State/Province</label>
                                                    <input
                                                        placeholder="Clinic State/Province"
                                                        className={classes.sub_font_size}
                                                        value={this.props.clinicLocation}
                                                        onChange={(event) => this.clinicLocationChange(event)}
                                                    />
                                                </fieldset>
                                                <fieldset className={classes.content_field}>
                                                    <label className={classes.floated}>Clinic Address</label>
                                                    <input
                                                        placeholder="Clinic Address"
                                                        className={classes.sub_font_size}
                                                        value={this.props.clinicAddress}
                                                        onChange={(event) => this.clinicAddressChange(event)}
                                                    />
                                                </fieldset>
                                                <fieldset className={classes.content_field}>
                                                    <label className={classes.floated}>Clinic Phone Number</label>
                                                    <input
                                                        placeholder="Clinic Phone Number"
                                                        className={classes.sub_font_size}
                                                        value={this.props.clinicPhoneNumber}
                                                        onChange={(event) => this.clinicPhoneNumberChange(event)}
                                                    />
                                                </fieldset>
                                            </Fragment>
                                            }

                                            {!(this.props.admin && this.props.current_user_id !== this.props.agentData.uid) &&
                                            <UpdatePasswordForm
                                                changePasswordSelected={this.state.changePasswordSelected}
                                                onOldPasswordChange={(event) => this.onOldPasswordChange(event)}
                                                onNewPasswordChange={(event) => this.onNewPasswordChange(event)}
                                                onConfirmPasswordChange={(event) => this.onConfirmPasswordChange(event)}
                                                currentState={this.state}
                                                onchangePasswordClick={() => this.onchangePasswordClick()}
                                                onChangePasswordSubmit={() => this.onChangePasswordSubmit()}
                                                onCancelClicked={() => this.onCancelClicked()}
                                            />
                                            }
                                            {(this.props.admin && this.props.current_user_id !== this.props.agentData.uid) &&
                                            <span className={classes.absolute_change_password}
                                                  onClick={() => this.sendChangePasswordEmail()}> Send Change Password Email </span>
                                            }

                                            <h2 className={classes.chat_limit_view}>Chat limit</h2>
                                            <div className={classes.chat_limit_box}>
                                                <span className={classes.chat_limit_box_span}>
                                                    <input
                                                        className={classes.chat_limit_box_input}
                                                        type="text"
                                                        value={this.props.updateTotalChatLimit}
                                                        readOnly={true}
                                                    />
                                                    <span className={classes.add_count}
                                                          onClick={() => this.addTotalClick()}>
                                                        <svg fill="rgba(66, 77, 87, 0.8)" className={classes.span_svg}
                                                             width="20px" height="20px" viewBox="0 0 32 32"
                                                             aria-hidden="true">
                                                            <path d="M8 20.695l7.997-11.39L24 20.695z"/>
                                                        </svg>
                                                    </span>
                                                    <span className={classes.remove_count}
                                                          onClick={() => this.minusTotalClick()}>
                                                        <svg fill="rgba(66, 77, 87, 0.8)" className={classes.span_svg}
                                                             width="20px" height="20px" viewBox="0 0 32 32"
                                                             aria-hidden="true">
                                                            <path d="M24 11.305l-7.997 11.39L8 11.305z"/>
                                                        </svg>
                                                    </span>
                                                </span>
                                                concurrent chats
                                            </div>
                                            {/*<h2 className={classes.chat_limit_view}>Free Chat limit</h2>
                                            <div className={classes.chat_limit_box}>
                                                <span className={classes.chat_limit_box_span}>
                                                    <input
                                                        className={classes.chat_limit_box_input}
                                                        type="text"
                                                        value={this.props.updateFreeChatLimit}
                                                        readOnly={true}
                                                    />
                                                    <span className={classes.add_count} onClick={() => this.addFreeClick()}>
                                                        <svg fill="rgba(66, 77, 87, 0.8)" className={classes.span_svg} width="20px" height="20px" viewBox="0 0 32 32" aria-hidden="true">
                                                            <path d="M8 20.695l7.997-11.39L24 20.695z"/>
                                                        </svg>
                                                    </span>
                                                    <span className={classes.remove_count} onClick={() => this.minusFreeClick()}>
                                                        <svg fill="rgba(66, 77, 87, 0.8)" className={classes.span_svg} width="20px" height="20px" viewBox="0 0 32 32" aria-hidden="true">
                                                            <path d="M24 11.305l-7.997 11.39L8 11.305z"/>
                                                        </svg>
                                                    </span>
                                                </span>
                                                concurrent chats
                                            </div>
                                            <h2 className={classes.chat_limit_view}>Paid Chat limit</h2>
                                            <div className={classes.chat_limit_box}>
                                                <span className={classes.chat_limit_box_span}>
                                                    <input
                                                        className={classes.chat_limit_box_input}
                                                        type="text"
                                                        value={this.props.updatePaidChatLimit}
                                                        readOnly={true}
                                                    />
                                                    <span className={classes.add_count} onClick={() => this.addPaidClick()}>
                                                        <svg fill="rgba(66, 77, 87, 0.8)" className={classes.span_svg} width="20px" height="20px" viewBox="0 0 32 32" aria-hidden="true">
                                                            <path d="M8 20.695l7.997-11.39L24 20.695z"/>
                                                        </svg>
                                                    </span>
                                                    <span className={classes.remove_count} onClick={() => this.minusPaidClick()}>
                                                        <svg fill="rgba(66, 77, 87, 0.8)" className={classes.span_svg} width="20px" height="20px" viewBox="0 0 32 32" aria-hidden="true">
                                                            <path d="M24 11.305l-7.997 11.39L8 11.305z"/>
                                                        </svg>
                                                    </span>
                                                </span>
                                                concurrent chats
                                            </div>
                                            */}
                                            <div className={classes.message_view}>{this.props.message}</div>
                                            <div className={classes.save_button_view}>
                                                <button className={classes.cancel_button}
                                                        onClick={(event) => this.onCancelClick(event)}>Cancel
                                                </button>
                                                <button className={classes.save_button}
                                                        onClick={(event) => this.onSaveClick(event)}>Save Changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({agentsReducer, homeReducer}) => {
    const {admin} = homeReducer;
    const {
        agentData, updateImageURL, updateName, updateTitle, updateLicense, updateTotalChatLimit, updateFreeChatLimit,
        updatePaidChatLimit, message, current_user_id, signatureImageURL,
        updateDescription, updateExperience, updateHospitalLocation, updateDoctorChatAssignCheck,
        clinicalDoctorStatus, clinicName, clinicLocation, clinicAddress, clinicPhoneNumber
    } = agentsReducer;
    return {
        admin,
        agentData,
        updateImageURL,
        updateName,
        updateTitle,
        updateLicense,
        updateTotalChatLimit,
        updateFreeChatLimit,
        updatePaidChatLimit,
        message,
        current_user_id,
        signatureImageURL,
        clinicalDoctorStatus,
        clinicName,
        clinicLocation,
        clinicAddress,
        clinicPhoneNumber,
        updateDescription,
        updateExperience,
        updateHospitalLocation,
        updateDoctorChatAssignCheck
    };
}

export default connect(mapStateToProps, {
    fetchAgentData, updateDoctorName, updateDoctorTitle, updateDoctorLicense, addTotalChatLimit,
    minusTotalChatLimit, addFreeChatLimit, minusFreeChatLimit, addPaidChatLimit, minusPaidChatLimit,
    updateDoctorDetail, showImagePreview, showSignaturePreview, updateClinicStatus, updateClinicName,
    updateClinicLocation, updateClinicAddress, updateClinicPhoneNumber,
    updateDoctorDescription,updateDoctorExperience,updateDoctorHospitalLocationChange,
    updateDoctorChatAssignCheckChange, resetAgentsMessage
})(UpdateAgent);
