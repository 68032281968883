import  {Fragment} from 'react';
import ChatList from './ClinicalChatList';
import SendBox from './ClinicalSendBox';
import classes from '../clinicscreen.module.css';
import moment from 'moment';

const Header = (props) => {
    var DOB = props.DOB;
    if (DOB !== "NA") {
         // let timeNow = new Date().getFullYear();
         // let ageInYears = timeNow - new Date(dob).getFullYear();
        let ageInYears = moment().diff(moment(DOB, "MMMM,DD,YYYY"), "years");
        DOB = DOB + " (" + ageInYears + ")";
    }
    // eslint-disable-next-line array-callback-return
    var rate_tag = props.tags.find(tag => {
        if (tag !== undefined) {
            if (tag.tag === 'rating_screen') {
                return tag;
            }
        }
    });
    var ban_tag = props.tags.find(tag => {
        if (tag !== undefined) {
            if (tag.tag === 'ban') {
                return tag;
            }
        }
    });
    if (!rate_tag) {
        rate_tag = {tag: null, uid: null, id: null}
    }
    if (!ban_tag) {
        ban_tag = {tag: null, uid: null, id: null}
    }
    return (
        <div className={classes.list_header}>
            {props.showChat &&
            <div className={classes.msg_header}>
                <div className={classes.scrollable}>
                    <div className={classes.info_row}>
                        <div className={classes.col_4}><span className={classes.header_attr}>Patient:</span> <span
                            className={classes.header_attr_value}> {props.name}</span></div>
                        <div className={classes.col_4}><span className={classes.header_attr}>D.O.B:</span> <span
                            className={classes.header_attr_value}> {DOB}</span></div>
                        <div className={classes.col_4}><span className={classes.header_attr}>Gender:</span> <span
                            className={classes.header_attr_value}> {props.gender}</span></div>
                    </div>
                    <div className={classes.info_row}>
                        <div className={classes.col_6}><span className={classes.header_attr}>Issue:</span> <span
                            className={classes.header_attr_value}>{props.symptoms}</span></div>
                    </div>
                </div>
            </div>
            }
            {!props.showChat &&
            <Fragment>
                <div className={classes.msg_header_default}>
                    <span className={classes.header_text}>Select a chat from left pane</span>
                </div>
            </Fragment>
            }
            <div className={classes.info_section_col}>
                {(props.doctorIsValid || props.adminIsValid) &&
                <Fragment>


                    {props.showChat &&
                    <div className={classes.header_banUser} style={{right: '140px'}} onClick={() => props.videoChat()}>
                        <span>Start Conference Call</span>
                    </div>
                    }

                    {/* props.showChat &&
                            <div className={classes.header_rate} onClick={() => props.onTagClick(rate_tag.tag, rate_tag.id, rate_tag.uid)}>
                                <span>Get Patient Review</span>
                            </div> */
                    }

                    {/* props.showChat &&
                            <div className={classes.header_banUser} onClick={() => props.onTagClick(ban_tag.tag, ban_tag.id, ban_tag.uid)}>
                                <span>Ban User For Spam</span>
                            </div> */
                    }


                </Fragment>
                }
            </div>
        </div>
    )
}

const ClinicalChatScreen = (props) => {
    let date = new Date();
    let gmtOffset = (props.gmtOffset) ? props.gmtOffset : 0;
    let utc = date.getTime() + (date.getTimezoneOffset() * 60000);
    let current_time = (new Date(utc + (3600000 * gmtOffset))).toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    });
    let showChat = props.gender || props.city || props.country || props.platform || props.email || props.convoCount || props.buildNumber || props.medication;
    return (
        <div className={classes.chats}>
            <div className={classes.block}>
                <Header
                    doctorIsValid={props.doctorIsValid}
                    adminIsValid={props.adminIsValid}
                    videoChat={props.videoChat()}
                    audioChat={props.audioChat()}
                    videoState={props.videoState}
                    audioState={props.audioState}

                    maximizeVideo={props.maximizeVideo()}
                    closeChat={() => props.closeChat()}
                    tags={props.tags}
                    onTagClick={(name, id, uid) => props.onTagClick(name, id, uid)}
                    city={props.city ? props.city : "NA"}
                    country={props.country ? props.country : "NA"}
                    complete_address={props.complete_address ? props.complete_address : "NA"}
                    platform={props.platform ? props.platform.toUpperCase() : "NA"}
                    email={props.email ? props.email : "NA"}
                    current_time={current_time ? current_time : "NA"}
                    convoCount={props.convoCount ? props.convoCount : "0"}
                    buildNumber={props.buildNumber ? props.buildNumber : "NA"}
                    medication={props.medication ? props.medication : "None"}
                    symptoms={props.symptoms ? props.symptoms : "NA"}
                    gender={props.gender ? props.gender : "NA"}
                    name={props.patientName}
                    showChat={showChat}
                    acceptingChat={props.acceptingChat}
                    DOB={props.DOB ? props.DOB.replace(' ', ',').replace(' ', ',') : "NA"}
                />
                <div className={classes.msg_body}>
                    <div className={classes.list}>
                        <div className={classes.chatlist}>
                            <div className={classes.chatview}>
                                <div className={classes.chatwindow}>
                                    <ChatList
                                        loading_messages={props.loading_messages}
                                        messages={props.messages}
                                        doctorName={props.doctorName}
                                        doctorImage={props.doctorImage}
                                        patientName={props.patientName}
                                    />
                                    <SendBox
                                        doctorIsValid={props.doctorIsValid}
                                        adminIsValid={props.adminIsValid}
                                        inputBarText={props.inputBarText}
                                        onChangeText={(event) => props.onChangeText(event)}
                                        onsendmessage={(textareaRef) => props.onsendmessage(textareaRef)}
                                        inputElement={props.inputElement}
                                        textareaElement={props.textareaElement}
                                        show={props.open}
                                        tags={props.tags}
                                        showCannedResponseList={props.showCannedResponseList}
                                        selectedCannedResponseText={props.selectedCannedResponseText}
                                        selectedCannedResponseShortCut={props.selectedCannedResponseShortCut}
                                        canned_responses={props.canned_responses}
                                        onCannedResponseClick={(uid, short_cut, text) => props.onCannedResponseClick(uid, short_cut, text)}
                                        selectedCannedResponse={(textareaRef, text, short_cut) => props.selectedCannedResponse(textareaRef, text, short_cut)}
                                        onCannedResponseShow={() => props.onCannedResponseShow()}
                                        selectedTags={props.selectedTags}
                                        onRemoveTagClick={(name, id, uid) => props.onRemoveTagClick(name, id, uid)}
                                        onTagClick={(name, id, uid) => props.onTagClick(name, id, uid)}
                                        openTagListModal={() => props.openTagListModal()}
                                        onAttachmentClick={(file_input) => props.onAttachmentClick(file_input)}
                                        _handleImageChange={(e) => props._handleImageChange(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClinicalChatScreen;
