import React, {Fragment, useEffect, useState} from 'react';
import ChatList from './ChatList';
import SendBox from './SendBox';
import classes from '../ChatBuilder.module.css';
import CallWithTwilio from "../../../components/twilioCallComponent/CallWithTwilioTier1";
import ProgressBar from "../../../components/UI/Progressbar/Progressbar";
import Modal from "../../../components/UI/Modal/Modal";
import TextareaAutosize from "react-autosize-textarea";
import isMobileView from "../../../utils/isMobileView";
import _style from "../../../assets/_classes.module.css";
import axios from "axios";
import {ENVIRONMENT} from "../../../environment";
import Spinner from "../../../components/Spinner";
import {connect} from "react-redux";
import {onChangeText} from "../../../actions/ChatAction";
import {saveTagInChat} from "../../../actions/ConversationAction";
import ChatPanelHeader from "../../../components/ChatPanelHeader/ChatPanelHeader";




const ChatScreen = (props) => {
    console.log("ChatScreen rendered")
    return (
        <div className={classes.chats}>
            <div className={classes.block}>
                <ChatPanelHeader
                    showOnlineDoctors={props.showOnlineDoctors}
                    onAssignClick={props.onAssignClick}
                    closeChat={props.closeChat}
                    transferChat={props.transferChat}
                />
                <div className={classes.msg_body}>
                    <div className={classes.list}>
                        <div className={classes.chatlist}>
                            <div className={classes.chatview}>
                                <div className={classes.chatwindow}>
                                    <ChatList />
                                    <SendBox
                                        inputElement={props.inputElement}
                                        textareaElement={props.textareaElement}
                                        _handleImageChange={props._handleImageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps, {onChangeText, saveTagInChat})(ChatScreen);
