import  {Component} from 'react';
import {connect} from 'react-redux';
import Aux from '../../hoc/hocAux';
import classes from './TicketsBuilder.module.css';
import TicketsList from './TicketsList';
import TicketsBody from './TicketsBody';
import {childChangedDoctor} from '../../actions/AgentsAction'
import {checkStatusAccept} from '../../actions/ConversationAction'


class Tickets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            headerText: 'All tickets'
        }
    }

    componentDidMount() {
        this.props.childChangedDoctor()
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.changedDoctorKey === newProps.doctorDetail.uid) {
            this.props.checkStatusAccept();
        }
    }

    onAddTicket = () => {
        this.props.history.push('/tickets/add');
    }

    ticketsItemsClick = (header) => {
        this.setState({headerText: header});
    }

    render() {
        return (
            <Aux>
                <div className={classes.main}>
                    <TicketsList onAddTicket={() => this.onAddTicket()}
                                 ticketsItemsClick={(header) => this.ticketsItemsClick(header)}
                                 acceptingChat={this.props.acceptingChat}
                    />
                    <TicketsBody header={this.state.headerText}/>
                </div>
            </Aux>
        )
    }
}

const mapStateToProps = ({customersReducer, conversationReducer, acceptingReducer, doctorReducer}) => {
    const {customersData} = customersReducer;
    const {acceptingChat, changedDoctorKey} = acceptingReducer
    const {doctorDetail} = doctorReducer
    return {customersData, acceptingChat, changedDoctorKey, doctorDetail};
}
export default connect(mapStateToProps, {childChangedDoctor, checkStatusAccept})(Tickets);
