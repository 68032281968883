import React, {useEffect, useState, Fragment, useRef} from 'react'
import classes from "../../../components/pharmacyComponent/pharmacycomponent.module.css";
import {connect} from "react-redux";
import moment from "moment";


const SickNotesSection = (props) => {

    const [medicalNotes, setMedicalNotes] = useState(null)
    const [showBox, setShowBox] = useState(false)
    const [patientId, setPatientId] = useState(null)

    useEffect(()=>{
        if(patientId !== props.patientId){
            setMedicalNotes(null)
            setPatientId(props.patientId)
        }
        if(props.selectedPatient && props.patientId){
            if(props.selectedPatient['historyMedicalNotes']) setMedicalNotes(Object.values(props.selectedPatient['historyMedicalNotes']))
            //else setMedicalNotes(null)
            //setPatientId(props.patientId)
        }
    },[props.patientId, props.selectedPatient])
    const setShowBoxFunction = () => {
        setShowBox(!showBox)
    }


    return (
        <div className={classes.generalInformation}>
            <Fragment>
                <div className={classes.openchatlist}
                     onClick={setShowBoxFunction}>
                    <div className={classes.openchatlistSection}>
                        <div
                            className={showBox === true ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                            <svg fill="#884a9d" width="12px" height="8px"
                                 viewBox="0 0 12 8">
                                <path fill="inherit" fillRule="evenodd"
                                      d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                            </svg>
                        </div>
                        <div className={classes.subListTitle}>Medical Notes</div>
                    </div>
                </div>
                {showBox &&
                <div className={classes.expandable_section}>
                    {
                        <div className={classes.general_info_section}>

                            {medicalNotes?.length>0? medicalNotes.map((report)=>{
                                let notes = report?.notes? Object.values(report?.notes): null

                                return <div className={classes.importantLinks} key={report.timestamp + "_notes"}>
                                    <span className={classes.SOAP_heading}>{report.symptoms}</span>
                                    {notes?.length>0 && notes.map(note=>{
                                        return <div className={classes.sickNoteDiv} key={note.timestamp+'_note'}><a target="_blank" href={(note.file || '').replace('export=download', 'export=pdf')} key={note.fileName} className={classes.sickNoteFileName}>{note.fileName}</a><span>{moment(note.timestamp).format('DD-MM-YYYY hh:mm a')}</span></div>
                                    })}

                                </div>

                            }):<div className={classes.list_text}>No Medical Notes History</div>}
                        </div>
                    }
                </div>
                }
            </Fragment>
        </div>
    )


}
const mapStateToProps = ({ conversationReducer }) => {
    const { patientId, doctorsNameAndImages, selectedPatient } = conversationReducer;
    return { patientId, doctorsNameAndImages, selectedPatient };
};

export default connect(mapStateToProps, {})(SickNotesSection);
