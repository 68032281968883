
import {FaAngleLeft} from 'react-icons/fa'

import _classes from './_Header.module.css';

const Header = (props) => {


    return (
        <div className={_classes.container} style={{...props.style}}>
            <div className={_classes.headerOption} style={Boolean(props.customView) ? {width: '5%'} : null}
                 onClick={() => {
                     if (Boolean(props.back)) props.back.onClick()
                 }}>
                {Boolean(props.back) && <>
                    {Boolean(props.leftIcon) ? props.leftIcon :
                        <FaAngleLeft style={{justifySelf: 'flex-start'}} color="#884a9d" size={30}/>}
                    {Boolean(props.back.title) ? <span>{props.back.title}</span> : <span>Back</span>}

                </>}
            </div>
            {!Boolean(props.customView) && <div className={_classes.title}>{props.title}</div>}
            {Boolean(props.customView) && props.customView}
            {!Boolean(props.customView) ? <div className={_classes.headerOption}>
                {false && <><FaAngleLeft style={{justifySelf: 'flex-start'}} color="#884a9d"
                                         size={22}/><span>Back</span>
                </>}
            </div> : null}
            {/* {inputElement} */}
        </div>
    );

};

export default Header;
