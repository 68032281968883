import React, {useEffect, useState, Fragment, useRef} from 'react'
import classes from "../../../components/pharmacyComponent/pharmacycomponent.module.css";
import TextareaAutosize from "react-autosize-textarea";
import Button from "react-bootstrap/Button";
import Image from "../../../components/Image";
import {connect} from "react-redux";
import Spinner from "../../../components/Spinner";
import {database} from "../../../firebase";
import {updatePatient} from "../../../actions/ConversationAction";
import moment from "moment";

const ImportantLinksSection = (props) => {
    const [showBox, setShowBox] = useState(false)

    const setShowBoxFunction = () => {
        setShowBox(!showBox)
    }
    // Prescription Link:
    //

    // Medical Note:
    //

    // Lab Link:
    //

    return (
        <div className={classes.generalInformation}>
            <Fragment>
                <div className={classes.openchatlist}
                     onClick={setShowBoxFunction}>
                    <div className={classes.openchatlistSection}>
                        <div
                            className={showBox === true ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                            <svg fill="#884a9d" width="12px" height="8px"
                                 viewBox="0 0 12 8">
                                <path fill="inherit" fillRule="evenodd"
                                      d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                            </svg>
                        </div>
                        <div className={classes.subListTitle}>Important Links</div>
                    </div>
                </div>
                {showBox &&
                <div className={classes.expandable_section}>
                    {
                        <div className={classes.general_info_section}>
                            <div className={classes.importantLinks}>
                                <a target="_blank"
                                   href="https://www.omnicalculator.com/health/pediatric-dose/"
                                   className={classes.importantLinkText}>
                                    Pediatric Dose Calculator
                                </a>
                            </div>
                            <div className={classes.importantLinks}>
                                <a target="_blank"
                                   href="https://yourdoctors.online/support/"
                                   className={classes.importantLinkText}>
                                    Support Chatbot
                                </a>
                            </div>
                            <div className={classes.importantLinks}>
                                <a target="_blank"
                                   href="https://prescription-authorization-yourdoctorsonline.paperform.co/"
                                   className={classes.importantLinkText}>
                                    Prescription Form
                                </a>
                            </div>
                            <div className={classes.importantLinks}>
                                <a target="_blank"
                                   //href={`https://docs.google.com/forms/d/e/1FAIpQLSfhi9KjDuJw4cUUi6vQZiNdW_lnP4GLUb2mFAq2StFxroMMhA/viewform?usp=pp_url${props.conversationId?`&entry.2078426561=${encodeURIComponent(props.conversationId)}`:""}${props.doctorDetail?.doctorName?`&entry.1315844274=${encodeURIComponent(props.doctorDetail?.doctorName)}`:""}&entry.314570027=${encodeURIComponent(moment().format('YYYY-MM-DD'))}${props?.selectedPatient?.email?`&entry.1265348619=${encodeURIComponent(props?.selectedPatient?.email)}`:""}${props?.selectedPatient?.pharmacy?.name?`&entry.660671468=${encodeURIComponent(props?.selectedPatient?.pharmacy?.name)}`:""}${props?.selectedPatient?.pharmacy?.address?`&entry.1077925019=${encodeURIComponent(props?.selectedPatient?.pharmacy?.address)}`:""}${props?.selectedPatient?.phone_number?`&entry.1762439525=${encodeURIComponent(props?.selectedPatient?.phone_number)}`:""}${props?.selectedPatient?.fax_number?`&entry.564406445=${encodeURIComponent(props?.selectedPatient?.fax_number)}`:""}`}
                                //    href={`https://docs.google.com/forms/d/e/1FAIpQLSfhi9KjDuJw4cUUi6vQZiNdW_lnP4GLUb2mFAq2StFxroMMhA/viewform?usp=pp_url&entry.314570027=${encodeURIComponent(moment().format('YYYY-MM-DD'))}`}
                                   href={`https://docs.google.com/spreadsheets/d/16Zyt4lzQ3dbNJHjnljPhS5a7rci_ylAcPT2gg4mkgbY/edit#gid=2003786481`}
                                   className={classes.importantLinkText}>
                                    Canadian Pharmacies excel sheet
                                </a>
                            </div>
                            <div className={classes.importantLinks}>
                                <a target="_blank"
                                    //href={`https://docs.google.com/forms/d/e/1FAIpQLSds9-Qn4aVWITEMSBxrD6QAsYDA6qr1xzBjhIAY06AH4txtgg/viewform?usp=pp_url${props.conversationId?`&entry.2078426561=${encodeURIComponent(props.conversationId)}`:""}${props.doctorDetail?.doctorName?`&entry.1227193885=${encodeURIComponent(props.doctorDetail?.doctorName)}`:""}&entry.1587644495=${encodeURIComponent(moment().format('YYYY-MM-DD'))}${props?.selectedPatient?.country?`&entry.1850608816=${encodeURIComponent(props?.selectedPatient?.country)}`:""}${props?.selectedPatient?.state?`&entry.1166165612=${encodeURIComponent(props?.selectedPatient?.state)}`:""}`}
                                //    href={`https://docs.google.com/forms/d/e/1FAIpQLSds9-Qn4aVWITEMSBxrD6QAsYDA6qr1xzBjhIAY06AH4txtgg/viewform?usp=pp_url&entry.1587644495=${encodeURIComponent(moment().format('YYYY-MM-DD'))}`}
                                   href={"https://docs.google.com/forms/d/e/1FAIpQLSds9-Qn4aVWITEMSBxrD6QAsYDA6qr1xzBjhIAY06AH4txtgg/viewform"}
                                    className={classes.importantLinkText}>
                                    Medical Notes
                                </a>
                            </div>
                            <div className={classes.importantLinks}>
                                <a target="_blank"
                                   //href={`https://docs.google.com/forms/d/e/1FAIpQLSd2w9qFcYaE2ibbBjuOWI2UeTqibjgGejvJAHdJNUwxuM_A5g/viewform?usp=pp_url${props.conversationId?`&entry.989978360=${encodeURIComponent(props.conversationId)}`:""}${props.doctorDetail?.doctorName?`&entry.1023481665=${encodeURIComponent(props.doctorDetail?.doctorName)}`:""}&entry.856106590=${encodeURIComponent(moment().format('YYYY-MM-DD'))}${props?.selectedPatient?.country?`&entry.2140257595=${encodeURIComponent(props?.selectedPatient?.country)}`:""}${props?.selectedPatient?.country?`&entry.116917989=${encodeURIComponent(props?.selectedPatient?.country)}`:""}${props?.selectedPatient?.state?`&entry.156358029=${encodeURIComponent(props?.selectedPatient?.state)}`:""}`}
                                //    href={`https://docs.google.com/forms/d/e/1FAIpQLSd2w9qFcYaE2ibbBjuOWI2UeTqibjgGejvJAHdJNUwxuM_A5g/viewform?usp=pp_url&entry.856106590=${encodeURIComponent(moment().format('YYYY-MM-DD'))}`}
                                   href={"https://docs.google.com/forms/d/e/1FAIpQLSd2w9qFcYaE2ibbBjuOWI2UeTqibjgGejvJAHdJNUwxuM_A5g/viewform?usp=sf_link"}
                                   className={classes.importantLinkText}>
                                    Lab Requisition
                                </a>
                            </div>
                            {/* <div className={classes.importantLinks}>
                                <a target="_blank"
                                   href="https://buy.stripe.com/14k02o3xO6bHaZibII"
                                   className={classes.importantLinkText}>
                                    Stripe link (FOR US PATIENTS WHEN HUBSPOT IS SUBMITTED)
                                </a>
                            </div> */}
                            <div className={classes.importantLinks}>
                                <a target="_blank"
                                   href="https://docs.google.com/spreadsheets/d/1rTZ2U24N81MZy_npokyfW9FvH4npEApn4vfLDcszeMk/edit#gid=0"
                                   className={classes.importantLinkText}>
                                    Prescription Recommendations
                                </a>
                            </div>
                            <div className={classes.importantLinks}>
                                <a target="_blank"
                                   href="https://docs.google.com/spreadsheets/d/10guNNSSNCRD_c37YKNqNqnEpFOwEHR163j8Rew2kjoI/edit#gid=891834841"
                                   className={classes.importantLinkText}>
                                    Allowed Medications List
                                </a>
                            </div>
                            <div className={classes.importantLinks}>
                                <a target="_blank"
                                   href="https://www.deadiversion.usdoj.gov/schedules/orangebook/c_cs_alpha.pdf"
                                   className={classes.importantLinkText}>
                                    Controlled Substances
                                </a>
                            </div>
                            <div className={classes.importantLinks}>
                                <a target="_blank"
                                   href="https://docs.google.com/spreadsheets/d/1fX4DUg2205HASX4ej6b-ARz8u0syWYINZlYct0SXQL4/edit#gid=0"
                                   className={classes.importantLinkText}>
                                    Medications refused by US doctors
                                </a>
                            </div>
                        </div>
                    }
                </div>
                }
            </Fragment>
        </div>
    )


}
const mapStateToProps = ({ conversationReducer, doctorReducer }) => {
    const { doctorDetail } = doctorReducer;


    const { patientId, patients, conversationId, selectedPatient } = conversationReducer;
    return { patientId, patients, conversationId, doctorDetail, selectedPatient };
};

export default connect(mapStateToProps, {updatePatient})(ImportantLinksSection);
