import {CLINIC_FETCH_TAGS, CLINIC_TAG_CHANGE, CLINIC_TAG_SAVE} from '../../constants';

const INITIAL_STATE = {clinic_tags: [], clinic_tag_value: ''};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLINIC_TAG_CHANGE:
            return {...state, clinic_tag_value: action.payload};
        case CLINIC_TAG_SAVE:
            return {...state, clinic_tag_value: ''};
        case CLINIC_FETCH_TAGS:
            return {...state, clinic_tags: action.payload};
        default:
            return state;
    }
}
