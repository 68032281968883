import {
    ADD_AGENT_FAILS,
    ADD_AGENT_INPROGRESS,
    ADD_AGENT_SUCCESS,
    ADD_FREE_CHAT_LIMIT,
    ADD_PAID_CHAT_LIMIT,
    ADD_TOTAL_CHAT_LIMIT,
    CLINIC_ADDRESS,
    CLINIC_LOCATION,
    CLINIC_NAME,
    CLINIC_PHONE_NUMBER,
    CLINICAL_DOCTOR_STATUS,
    CURRENT_USER_ID,
    DELETE_DOCTOR_SUCCESS, DOCTOR_CHAT_ASSIGN_CHECK, DOCTOR_DESCRIPTION, DOCTOR_EXPERIENCE, DOCTOR_HOSPITAL_LOCATION,
    FETCH_AGENT,
    FETCH_AGENTS_FAILED,
    FETCH_AGENTS_INPROGRESS,
    FETCH_AGENTS_SUCCESS,
    MINUS_FREE_CHAT_LIMIT,
    MINUS_PAID_CHAT_LIMIT,
    MINUS_TOTAL_CHAT_LIMIT, REFRESH_AGENT_STATUS,
    RESET_SUCCESS,
    SHOW_IMAGE_PREVIEW,
    SHOW_SIGNATURE_PREVIEW,
    UPDATE_DOCTOR_DETAIL_FAILED,
    UPDATE_DOCTOR_DETAIL_SUCCESS,
    UPDATE_DOCTOR_LICENSE,
    UPDATE_DOCTOR_NAME,
    UPDATE_DOCTOR_TITLE
} from '../constants';

const INITIAL_STATE = {
    loading: false,
    error: '',
    agentsData: [],
    groupData: [],
    agentData: [],
    updateName: '',
    success: '',
    updateTitle: 'No Title',
    updateLicense: '',
    updateTotalChatLimit: 5,
    updateFreeChatLimit: 2,
    updatePaidChatLimit: 3,
    updateDescription: '',
    updateExperience: '',
    updateHospitalLocation: '',
    updateDoctorChatAssignCheck: false,
    updateImageURL: '//cdn.livechatinc.com/cloud/?uri=https://livechat.s3.amazonaws.com/default/avatars/miamisunset.jpg',
    signatureImageURL: null,
    message: '',
    doctorDeleted: false,
    current_user_id: null,
    clinicalDoctorStatus: false,
    clinicName: '',
    clinicLocation: '',
    clinicAddress: '',
    clinicPhoneNumber: ''
};


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REFRESH_AGENT_STATUS:
            return INITIAL_STATE;
        case ADD_AGENT_INPROGRESS:
            return {...state, loading: true, error: ''};
        case ADD_AGENT_SUCCESS:
            return {...state, loading: false, error: action.payload, success: 'true'};
        case ADD_AGENT_FAILS:
            return {...state, loading: false, error: action.payload, success: 'false'};
        case FETCH_AGENTS_INPROGRESS:
            return {...state, loading: true};
        case FETCH_AGENTS_SUCCESS:
            return {...state, agentsData: action.payload};
        case FETCH_AGENTS_FAILED:
            return {...state, agentsData: action.payload};
        case FETCH_AGENT:
            return {
                ...state, agentData: action.payload,
                updateDescription: action.payload.description || "",
                updateExperience: action.payload.experience || "",
                updateHospitalLocation: action.payload.hospital_location || "",
                updateDoctorChatAssignCheck: action.payload.chatAssignCheck || false,
                updateName: action.payload.doctorName,
                updateLicense: action.payload.doctorlicense || '',
                updateImageURL: action.payload.profileImageURL || '//cdn.livechatinc.com/cloud/?uri=https://livechat.s3.amazonaws.com/default/avatars/miamisunset.jpg',
                signatureImageURL: action.payload.signatureImageURL || null,
                updateTitle: action.payload.jobTitle || 'Please update title',
                updateTotalChatLimit: action.payload.totalChatLimit,
                updateFreeChatLimit: action.payload.freeChatLimit,
                updatePaidChatLimit: action.payload.paidChatLimit,
                clinicalDoctorStatus: action.payload.clinicalDoctor || false,
                clinicName: action.payload.clinicName || '',
                clinicLocation: action.payload.clinicLocationName || '',
                clinicAddress: action.payload.clinicAddress || '',
                clinicPhoneNumber: action.payload.clinicPhoneNumber || '',
            };
        case DOCTOR_DESCRIPTION:
            return {...state, updateDescription: action.payload};
        case DOCTOR_EXPERIENCE:
            return {...state, updateExperience: action.payload};
        case DOCTOR_HOSPITAL_LOCATION:
            return {...state, updateHospitalLocation: action.payload};
        case DOCTOR_CHAT_ASSIGN_CHECK:
            return {...state, updateDoctorChatAssignCheck: action.payload};
        case UPDATE_DOCTOR_NAME:
            return {...state, updateName: action.payload};
        case UPDATE_DOCTOR_TITLE:
            return {...state, updateTitle: action.payload};
        case UPDATE_DOCTOR_LICENSE:
            return {...state, updateLicense: action.payload};
        case SHOW_IMAGE_PREVIEW:
            return {...state, updateImageURL: action.payload};
        case SHOW_SIGNATURE_PREVIEW:
            return {...state, signatureImageURL: action.payload};
        case ADD_TOTAL_CHAT_LIMIT:
            return {...state, updateTotalChatLimit: action.payload};
        case MINUS_TOTAL_CHAT_LIMIT:
            return {...state, updateTotalChatLimit: action.payload};
        case ADD_FREE_CHAT_LIMIT:
            return {...state, updateFreeChatLimit: action.payload};
        case MINUS_FREE_CHAT_LIMIT:
            return {...state, updateFreeChatLimit: action.payload};
        case ADD_PAID_CHAT_LIMIT:
            return {...state, updatePaidChatLimit: action.payload};
        case MINUS_PAID_CHAT_LIMIT:
            return {...state, updatePaidChatLimit: action.payload};
        case UPDATE_DOCTOR_DETAIL_SUCCESS:
            return {...state, message: action.payload};
        case UPDATE_DOCTOR_DETAIL_FAILED:
            return {...state, message: action.payload};
        case RESET_SUCCESS:
            return {...state, success: ''};
        case DELETE_DOCTOR_SUCCESS:
            return {...state, doctorDeleted: true};
        case CLINICAL_DOCTOR_STATUS:
            return {...state, clinicalDoctorStatus: action.payload};
        case CLINIC_NAME:
            return {...state, clinicName: action.payload};
        case CLINIC_LOCATION:
            return {...state, clinicLocation: action.payload};
        case CLINIC_ADDRESS:
            return {...state, clinicAddress: action.payload};
        case CLINIC_PHONE_NUMBER:
            return {...state, clinicPhoneNumber: action.payload};
        case CURRENT_USER_ID:
            return {...state, current_user_id: action.payload};
        default:
            return state;
    }
}
