import {
    REFRESH_STATE,
    SAVE_CLINICAL_OUT_PATIENT_FORM_IN_PROCESS,
    SAVE_CLINICAL_OUT_PATIENT_FORM_UPDATED,
    SAVE_OUT_PATIENT_FORM_FAILS,
    SAVE_OUT_PATIENT_FORM_IN_PROCESS,
    SAVE_OUT_PATIENT_FORM_SUCCESS,
    SAVE_OUT_PATIENT_FORM_UPDATED
} from '../constants';

const INITIAL_STATE = {loading: false, message: '', error: ''};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REFRESH_STATE:
            return INITIAL_STATE;
        case SAVE_OUT_PATIENT_FORM_IN_PROCESS:
            return {...state, loading: true, message: '', error: ''};
        case SAVE_OUT_PATIENT_FORM_SUCCESS:
            return {...state, loading: false, message: 'Data saved and patient chat closed', error: ''};
        case SAVE_OUT_PATIENT_FORM_FAILS:
            return {...state, loading: false, message: '', error: action.payload};
        case SAVE_OUT_PATIENT_FORM_UPDATED:
            return {...state, loading: false, message: 'Data is updated', error: ''};
        case SAVE_CLINICAL_OUT_PATIENT_FORM_IN_PROCESS:
            return {...state, loading: true, message: '', error: ''};
        case SAVE_CLINICAL_OUT_PATIENT_FORM_UPDATED:
            return {...state, loading: false, message: 'Data is updated', error: ''};
        default:
            return state;
    }
}
