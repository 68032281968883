
import classes from './clinicdoctor.module.css';

const Header = () => {
    return (
        <div className={classes.main_body}>
            <div className={classes.heading}>
                <div className={classes.active}>Online Doctors</div>
            </div>
        </div>
    )
}
export default Header;
