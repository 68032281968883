import  {useEffect, useState} from 'react'
import classes from "./emrPanel.module.css"

import {Table} from "react-bootstrap";
import {database} from "../../firebase";
import Spinner from "../../components/Spinner";
import {firebaseRoot} from "../../config";


const objects = []

const EmrPanel = (props) => {
    const [conversationRows,setConversationRows] = useState([])
    const [totalUnSavedConversationCount, setTotalUnSavedConversationCount] = useState(0)
    const [totalFailedConversationCount, setTotalFailedConversationCount] = useState(0)
    const [loading, setLoading] = useState(false)
    useEffect(()=>{
        console.log("EmrPanel props ", props)
    }, [])
    const tableHead = () => {
        return(
            <thead>
            <tr>
                <th>#</th>
                <th>Conversation ID</th>
                <th>Status</th>
            </tr>
            </thead>
        )
    }
    const renderTable = () => {
        return (<Table striped bordered hover>
            {tableHead()}
            <tbody>
            {conversationRows.map((row, index) =>{
                return(<tr onClick={()=> navigator.clipboard.writeText(JSON.stringify(row.data))}>
                    <td>{index+1}</td>
                    <td>{row.conversation_id}</td>
                    <td>{row.status}</td>
                </tr>)
            })}


            </tbody>
        </Table>)
    }


    /*const storeReferrals = () => {
        console.log("storeReferrals clicked ", loading)
        try{
            if(!loading){
                setLoading(true)
                    let count = objects.length;
                    setTotalUnSavedConversationCount(count)
                    let index = 0;
                    let completed = 0;
                    let ignored = 0;
                    if(count>0){
                        objects.forEach((encounter, i) => {
                            setTimeout(()=>{
                                database.ref(`emrReferralLetters/${encounter["Encounter ID"]}`).once('value', refSnap=>{
                                    if(!refSnap.exists()){
                                        console.log("encounterrr ", encounter)
                                        index++;
                                        setTimeout(()=>{
                                            console.log("emrEncounters index", index)

                                            fetch('http://localhost:5001/yourdoctorsonline-prod/us-central1/addReferralsToEMR', {
                                                method: "POST",
                                                headers: {
                                                    "Content-Type": "application/json",
                                                },
                                                body: JSON.stringify(encounter),
                                            })
                                                .then(data => {

                                                    completed++;
                                                    if(completed === count){
                                                        setLoading(false)
                                                    }
                                                })
                                                .catch(error => {

                                                    console.log("emrEncounters failed", error)
                                                    completed++;
                                                    if(completed === count){
                                                        setLoading(false)
                                                    }
                                                })


                                        }, index * 500)
                                    }else{
                                        completed++;
                                        if(completed === count){
                                            setLoading(false)
                                            alert("completed")

                                        }
                                    }


                                })

                            }, 50)


                        })
                    }
                    else{
                        setLoading(false)

                    }

            }
        }catch (e) {
            setLoading(false)
            alert(`error ${e?.message}`)
        }


    }*/
    const storeEncounter = (encounterArray, index) => {
        console.log("emrEncounters index", index)
        if (index >= encounterArray.length) {
            setLoading(false)
            alert("completed")
            return;
        }

        let encounterData = encounterArray[index]
        let encounterSaved = encounterData?.emr_saved
        encounterData.conversation_id = encounterData.key;
        if(!encounterSaved){
            console.log("emrEncounters encounterData", JSON.stringify(encounterData))
            fetch(firebaseRoot + '/storeEMRDataForTier1', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(encounterData),
            })
                .then(data => {
                    let newConversationRows = conversationRows
                    let newConversation = {
                        conversation_id: encounterData.key,
                        data: encounterData
                    }
                    newConversation.status = data.status === 200? "success":"failed"
                    if(data.status === 400) setTotalFailedConversationCount(totalFailedConversationCount)
                    newConversationRows.push(newConversation)
                    setConversationRows([...newConversationRows])
                    console.log("emrEncounters  successful", " ", encounterData.key, " ", data)
                    storeEncounter(encounterArray, index+1)
                })
                .catch(error => {
                    let newConversationRows = conversationRows
                    let newConversation = {
                        conversation_id:encounterData.key,
                        status: "failed",
                        data: encounterData
                    }
                    newConversationRows.push(newConversation)
                    setConversationRows([...newConversationRows])
                    setTotalFailedConversationCount(totalFailedConversationCount)
                    console.log("emrEncounters failed", error)
                    storeEncounter(encounterArray, index+1)
                })

        }else{
            storeEncounter(encounterArray, index+1)
        }
    }
    useEffect(()=>{
        if (loading) {
            try{
                database.ref(`emrEncounters`).orderByChild('emr_saved').equalTo(null).once('value', encountersSnap => {
                    let count = encountersSnap.numChildren() || 0;
                    console.log("emrEncounters count", count)
                    setTotalUnSavedConversationCount(count)
                    let index = 0;
                    let completed = 0;
                    if(count>0){
                        let encounterJSON = encountersSnap.toJSON()
                        console.log("encountersSnap json", JSON.stringify(encounterJSON))
                        /*setTimeout(()=>{
                            setLoading(false)
                        }, (count*6000) + 6000)*/
                        // write recursive function to do below work without settimeout

                        let encounterKeys = Object.keys(encounterJSON)
                        let encounterValues = encounterKeys.map(key => {
                            return{...encounterJSON[key], key}
                        })
                        storeEncounter(encounterValues, 0)
                    }
                    else{
                        setLoading(false)

                    }
                }, error =>{
                    console.log("emrEncounters error", error)
                    setLoading(false)
                    alert(`error ${error?.message}`)

                })
            }catch (e) {
                setLoading(false)
                alert(`error ${e?.message}`)
            }
        }
    }, [loading])

    const storeEMR = () => {
        console.log("storeEMR clicked ", loading)
        if(!loading){
            setLoading(true)
        }

    }

    return (
        <div className={classes.container}>
            {conversationRows.length>0 &&<div>
                {renderTable()}
            </div>}
            {conversationRows.length>0 &&<div>
                Total unsaved conversations: {totalUnSavedConversationCount}
            </div>}
            {conversationRows.length>0 && conversationRows.length === totalUnSavedConversationCount && <div>
                Total failed conversations: {totalFailedConversationCount}
            </div>}
            {loading &&
            <div className={classes.search_button}>
                <Spinner  color={"black"}  isLoading={true}/>
            </div>}

            {!loading && <div onClick={storeEMR} className={classes.search_button}>Store EMR</div>}

        </div>
    )


}
export default EmrPanel;

