import {
    ACCEPTING_CHAT_FALSE,
    ADMIN_LOGIN_EMAIL,
    ADMIN_LOGIN_FAILS,
    ADMIN_LOGIN_PASSWORD,
    ADMIN_LOGIN_PROCESS,
    ADMIN_LOGIN_SUCCESS,
    ADMIN_LOGOUT_FAILS,
    ADMIN_LOGOUT_SUCCESS,
    CHECK_ADMIN,
    DOCTOR_LOGOUT_FAILS,
    DOCTOR_LOGOUT_INPROGRESS,
    DOCTOR_LOGOUT_SUCCESS,
    FORGOT_PASSWORD_FAILED,
    FORGOT_PASSWORD_IN_PROGRESS,
    FORGOT_PASSWORD_SUCCESS,
    RESET_FORGORT_PASSWORD_STATES
} from '../constants';


import ngrokUrl from '../ngrok';
import {auth, database, getToken, messaging, messagingRef, functions} from "../firebase";

const {detect} = require('detect-browser');
const browser = detect();


export function emailChanged(text) {
    return {
        type: ADMIN_LOGIN_EMAIL,
        payload: text
    }
}

export function passwordChanged(text) {
    return {
        type: ADMIN_LOGIN_PASSWORD,
        payload: text
    }
}

export const resetForgotPasswordStates = () => {
    return {
        type: RESET_FORGORT_PASSWORD_STATES,
    }
}

export const forgotPassword = (email) => {
    return (dispatch) => {
        dispatch({type: FORGOT_PASSWORD_IN_PROGRESS});

        auth.sendPasswordResetEmail(email)
            .then(() => {
                dispatch({
                    type: FORGOT_PASSWORD_SUCCESS,
                    payload: 'Password reset email has been sent. You didn\'t receive it? Check your spam folder or click '
                })
            }).catch((error) => {
            ////console.log('error: ', error.message);
            dispatch({
                type: FORGOT_PASSWORD_FAILED,
                payload: error.message
            })
        });
    }
}

export function loginUser(admin_email, admin_password) {
    return (dispatch) => {
        dispatch({type: ADMIN_LOGIN_PROCESS});
        /* const addDoctorRole = functions.httpsCallable('addDoctorRole');
        addDoctorRole({ email: "shayan@doctor.com" }).then(status => {
        if (status.data) {
                console.log('addDoctorRole status.data: ', status.data);
            // callback(true);
            // dispatch({
            // 	type: ADD_AGENT_SUCCESS,
        // 	payload: 'Doctor added successfully'
            // })
            } else {
                // callback(false);
            // dispatch({
            // 	type: ADD_AGENT_FAILS,
            // 	payload: 'Doctor added but addition auth role failed'
            // })
        }
        }); */
        /* const addAdminRole = functions.httpsCallable('addAdminRole');
        addAdminRole({ email: "shayan@doctor.com" }).then(status => {
        if (status.data) {
                console.log('status.data: ', status.data);
            // callback(true);
            // dispatch({
            // 	type: ADD_AGENT_SUCCESS,
        // 	payload: 'Doctor added successfully'
            // })
            } else {
                console.log("claimfailed")
                // callback(false);
            // dispatch({
            // 	type: ADD_AGENT_FAILS,
            // 	payload: 'Doctor added but addition auth role failed'
            // })
        }
        }); */
        //addAdminRole
        auth.signInWithEmailAndPassword(admin_email, admin_password)
            .then(user => checkuserifdeleted(dispatch, user))
            .catch(error => loginuserfail(dispatch, error));
    }
}


const checkuserifdeleted = (dispatch, userData) => {
    const {user} = userData;
    if (user) {
        user.getIdTokenResult().then(idTokenResult => {
            if (idTokenResult.claims.userType === undefined) {
                database.ref(`/doctors/${user.uid}`).once('value', agentSnap => {
                    if (agentSnap.val().profileStatus === "deleted") {
                        loginuserfail(dispatch, {message: "this account has been deactivated."});
                    } else {
                        loginusersuccess(dispatch, userData);
                    }
                })
            } else {
                setTimeout(() => {
                    dispatch({type: ADMIN_LOGIN_SUCCESS, payload: userData})
                }, 1000);
                if (messagingRef.isSupported()) {
                    let token = getToken();
                    console.log("token ", token)
                    addOnlineStatus(dispatch, idTokenResult.claims.clinicID, user.uid, token, userData);
                } else {
                    addOnlineStatus(dispatch, idTokenResult.claims.clinicID, user.uid, null, userData);
                }
            }
        });
    } else {
        loginusersuccess(dispatch, userData);
    }
}

const addOnlineStatus = (dispatch, clinicID, userID, token, userData) => {
    return (dispatch) => {
        var updates = {};
        updates[`/clinicStaffMember/${clinicID}/${userID}/notification_token`] = token;
        updates[`/clinicStaffMember/${clinicID}/${userID}/online`] = true;

        database.ref().update(updates)
            .then(data => {
                dispatch({type: ADMIN_LOGIN_SUCCESS, payload: userData});
            })
            .catch(error => {
                dispatch({type: ADMIN_LOGIN_SUCCESS, payload: userData});
            })
    }
}

const loginusersuccess = (dispatch, userData) => {
    const {user} = userData;
    if (user) {
        user.getIdTokenResult().then(idTokenResult => {
            if (idTokenResult.claims.userType === undefined) {
                dispatch({
                    type: CHECK_ADMIN,
                    payload: {
                        admin: idTokenResult.claims.admin,
                        clinicalStatus: idTokenResult.claims.clinicalDoctor
                        // admin: false,
                        // clinicalStatus: false
                    }
                })
                if (idTokenResult.claims.admin || idTokenResult.claims.doctor) {
                    // if (idTokenResult.claims || idTokenResult.claims) {
                    let reportKey = getReportsRef().push().key;
                    var now = new Date();

                    var month = now.getUTCMonth() + 1; //months from 1-12
                    var day = now.getUTCDate();
                    var year = now.getUTCFullYear();
                    var currentDate = year + "-" + month + "-" + day;

                    var data = {onlineTime: now.getTime()};
                    var updates = {};

                    updates[`/doctors/${idTokenResult.claims.user_id}/accepting_chat`] = false;
                    updates[`/reports/${currentDate}/${idTokenResult.claims.user_id}/${reportKey}`] = data;

                    database.ref().update(updates)
                        .then(data => {
                            dispatch({type: ACCEPTING_CHAT_FALSE});

                        })
                        .catch(error => {
                            //console.log("failed change status");

                        })

                    if (messagingRef.isSupported()) {
                        let token = getToken();
                        console.log("token ", token)
                        var updates = {};
                        updates[`/doctors/${idTokenResult.claims.user_id}/notification_token`] = token;
                        database.ref().update(updates)
                            .then(data => {

                            })
                            .catch(error => {
                                //console.log("failed change status");

                            })
                    } else {
                        //console.log("Firebase not supported");
                    }

                    if (idTokenResult.claims.doctor) {
                        // if (idTokenResult.claims) {
                        createSession(idTokenResult.claims.user_id, function (status, data) {
                            //if (status) {
                            //console.log("createSession response ", data)
                                changeOnlineStatus(idTokenResult.claims.user_id, true, function (update_status) {
                                    if (update_status) {
                                        setActiveOnlineChats();
                                    }
                                    var userData = {
                                        user,
                                        admin: idTokenResult.claims.admin,
                                        doctor: idTokenResult.claims.doctor
                                    }
                                    // var userData = { user, admin: false, doctor: true }
                                    dispatch({type: ADMIN_LOGIN_SUCCESS, payload: userData});
                                });
                            //} else {
                            //     auth.signOut()
                            //         .then(() => {
                            //             dispatch({
                            //                 type: ADMIN_LOGIN_FAILS,
                            //                 payload: 'Login failed. Please refresh the page and try again'
                            //             });
                            //         })
                            //         .catch(() => {
                            //             dispatch({
                            //                 type: ADMIN_LOGIN_FAILS,
                            //                 payload: 'Login failed. Please refresh the page and try again'
                            //             });
                            //         })
                            // }
                        });
                    } else {
                        var userData = {user, admin: idTokenResult.claims.admin, doctor: idTokenResult.claims.doctor}
                        // var userData = { user, admin: false, doctor: true }
                        dispatch({type: ADMIN_LOGIN_SUCCESS, payload: userData});
                    }

                } else {
                    /* const addDoctorRole = functions.httpsCallable('addDoctorRole');
                    addDoctorRole({ email: idTokenResult.claims.email }).then(status => {
                    if (status.data) {
                            console.log('status.data: ', status.data);
                        // callback(true);
                        // dispatch({
                        // 	type: ADD_AGENT_SUCCESS,
                    // 	payload: 'Doctor added successfully'
                        // })
                        } else {
                            // callback(false);
                        // dispatch({
                        // 	type: ADD_AGENT_FAILS,
                        // 	payload: 'Doctor added but addition auth role failed'
                        // })
                    }
                    }); */

                    auth.signOut()
                        .then(() => {
                            dispatch({type: ADMIN_LOGIN_FAILS, payload: 'You are not allowed to use it'})
                        })
                        .catch(() => {
                            dispatch({type: ADMIN_LOGIN_FAILS, payload: 'You are not allowed to use it'})
                        })
                }
            } else {
                dispatch({type: ADMIN_LOGIN_SUCCESS, payload: userData});
            }
        })

    } else {
        auth.signOut()
            .then(() => {
                dispatch({type: ADMIN_LOGIN_FAILS, payload: '* You are not admin'})
            })
            .catch(() => {
                dispatch({type: ADMIN_LOGIN_FAILS, payload: '* You are not admin'})
            })
    }
}

const loginuserfail = (dispatch, error) => {
    dispatch({
        type: ADMIN_LOGIN_FAILS,
        payload: error.message
    })
}

export const logoutUser = () => {
    return (dispatch) => {
        dispatch({type: DOCTOR_LOGOUT_INPROGRESS});

        const {currentUser} = auth;
        getOpenConversationsRef().once('value', userConversationSnapShot => {
            if (userConversationSnapShot.val()) {
                var count = userConversationSnapShot.numChildren();
                var i = 0;
                let chatFound = 0
                userConversationSnapShot.forEach(function (childSnapshot) {
                    if (childSnapshot.val().doctorID === currentUser.uid && childSnapshot.val().status) {
                        if(!chatFound) {
                            alert("Please close your assigned chats")
                            chatFound++;
                        }
                        //console.log('childSnapshot.val(): ', childSnapshot.val());
                        dispatch({type: DOCTOR_LOGOUT_FAILS});

                    } else {
                        i++;
                        if (count === i) {
                            goToLogout(dispatch, currentUser);
                        }
                    }
                })
            } else {
                goToLogout(dispatch, currentUser);
            }
        });
    }
}

const goToLogout = (dispatch, currentUser) => {
    var updates = {};
    var now = new Date();
    currentUser.getIdTokenResult().then(idTokenResult => {
        if (idTokenResult.claims.userStatus === undefined) {
            getdoctorByRef(currentUser.uid).once('value', async doctorSnapShot => {
                if (doctorSnapShot.val().accepting_chat) {
                    updateAcceptChatSession(currentUser.uid);

                    let activityKey = getActivitiesRef().push().key;
                    var month = now.getUTCMonth() + 1; //months from 1-12
                    var day = now.getUTCDate();
                    var year = now.getUTCFullYear();
                    var currentDate = year + "-" + month + "-" + day;
                    var sessionStart = null


                    var acceptingChatTime = 'acceptingChatOffTime';
                    var obj = {[acceptingChatTime]: now.getTime(), 'browserInfo': browser};
                    await database
                    .ref('activitiesV2')
                    .orderByChild('doctorID')
                    .equalTo(currentUser.uid)
                    .limitToLast(10)
                    .once('value', (snapshot) => {
                      const d = snapshot.toJSON()
                    if(d!==null){
                        const keys = Object.keys(d)
                        for (let i = keys.length - 1; i >= 0; i--) {
                          if (d[keys[i]]['event'] == 'accepting_chat_on') {
                            sessionStart = d[keys[i]]["timestamp"]
                            return
                          }
                        }
                    }
                    })
                    updates[`/doctors/${currentUser.uid}/selected_chat`] = "On different chat";
                    updates[`/doctors/${currentUser.uid}/accepting_chat`] = false;
                    updates[`/doctors/${currentUser.uid}/acceptingChatOffTime`] = now.getTime();
                    updates[`/activities/${currentDate}/${currentUser.uid}/${activityKey}`] = obj;
                    //New V2
                    updates[`/activitiesV2/${activityKey}`] = { doctorID:currentUser.uid,event:"accepting_chat_off",browserInfo:browser,timestamp:now.getTime(),startTimestamp:sessionStart};
                    database.ref().update(updates)
                        .then(data => {
                            //console.log("successfully change status from false");
                        })
                        .catch(error => {
                            //console.log("failed change status");
                        })

                    currentUser.getIdTokenResult().then(idTokenResult => {
                        if (messagingRef.isSupported()) {
                            var updates = {};
                            updates[`/doctors/${idTokenResult.claims.user_id}/selected_chat`] = "On different chat"
                            database.ref().update(updates)
                                .then(data => {
                                    //console.log("successfully updated chat status");
                                })
                                .catch(error => {
                                    //console.log("failed change status");
                                })
                        }

                        if (!idTokenResult.claims.admin) {
                            changeOnlineStatus(idTokenResult.claims.user_id, false, function (status) {
                                if (status) {
                                    endSession(function (status) {
                                        if (status) {
                                            let updates = {};
                                            updates[`/doctors/${idTokenResult.claims.user_id}/online`] = false;
                                            database.ref().update(updates)
                                                .then(data => {
                                                    //console.log("successfully updated chat status");
                                                })
                                                .catch(error => {
                                                    //console.log("failed change status");
                                                })
                                            auth.signOut()
                                                .then(() => {
                                                    dispatch({type: DOCTOR_LOGOUT_SUCCESS});

                                                })
                                                .catch((error) => {
                                                    auth.signOut();
                                                    dispatch({type: DOCTOR_LOGOUT_SUCCESS, payload: error.message})
                                                })
                                        } else {
                                            alert('Please refresh the page and try logout again');
                                            dispatch({type: DOCTOR_LOGOUT_FAILS});
                                        }
                                    });
                                } else {
                                    let updates = {};
                                    updates[`/doctors/${idTokenResult.claims.user_id}/online`] = false;
                                    database.ref().update(updates)
                                        .then(data => {
                                            //console.log("successfully updated chat status");
                                        })
                                        .catch(error => {
                                            //console.log("failed change status");
                                        })
                                    auth.signOut()
                                        .then(() => {

                                            //console.log("sign out successfully");
                                            dispatch({type: ADMIN_LOGOUT_SUCCESS})
                                        })
                                        .catch((error) => {
                                            //console.log("sign out error");
                                            dispatch({type: ADMIN_LOGOUT_FAILS, payload: error.message})
                                        })
                                }
                            })
                        } else {

                        }
                    });
                } else {
                    updates[`/doctors/${currentUser.uid}/acceptingChatOffTime`] = now.getTime();
                    database.ref().update(updates)
                        .then(data => {
                            //console.log("successfully change status from false");
                        })
                        .catch(error => {
                            //console.log("failed change status");
                        })

                    currentUser.getIdTokenResult().then(idTokenResult => {
                        if (messagingRef.isSupported()) {
                            var updates = {};
                            updates[`/doctors/${idTokenResult.claims.user_id}/selected_chat`] = "On different chat";
                            database.ref().update(updates)
                                .then(data => {
                                    //console.log("successfully updated chat status");
                                })
                                .catch(error => {
                                    //console.log("failed change status");
                                })
                        }

                        if (!idTokenResult.claims.admin) {
                            changeOnlineStatus(idTokenResult.claims.user_id, false, function (status) {
                                if (status) {
                                    endSession(function (status) {
                                        if (status) {
                                            let updates = {};
                                            updates[`/doctors/${idTokenResult.claims.user_id}/online`] = false;
                                            database.ref().update(updates)
                                                .then(data => {
                                                    //console.log("successfully updated chat status");
                                                })
                                                .catch(error => {
                                                    //console.log("failed change status");
                                                })
                                            auth.signOut()
                                                .then(() => {
                                                    dispatch({type: DOCTOR_LOGOUT_SUCCESS});
                                                })
                                                .catch((error) => {
                                                    auth.signOut();
                                                    dispatch({type: DOCTOR_LOGOUT_SUCCESS, payload: error.message})
                                                })
                                        } else {
                                            alert('Please refresh the page and try logout again');
                                            dispatch({type: DOCTOR_LOGOUT_FAILS});
                                        }
                                    });
                                } else {
                                    let updates = {};
                                    updates[`/doctors/${idTokenResult.claims.user_id}/online`] = false;
                                    database.ref().update(updates)
                                        .then(data => {
                                            //console.log("successfully updated chat status");
                                        })
                                        .catch(error => {
                                            //console.log("failed change status");
                                        })
                                    auth.signOut()
                                        .then(() => {
                                            //console.log("sign out successfully");
                                            dispatch({type: ADMIN_LOGOUT_SUCCESS})
                                        })
                                        .catch((error) => {
                                            //console.log("sign out error");
                                            dispatch({type: ADMIN_LOGOUT_FAILS, payload: error.message})
                                        })
                                }
                            })
                        } else {
                            let updates = {};
                            updates[`/doctors/${idTokenResult.claims.user_id}/online`] = false;
                            database.ref().update(updates)
                                .then(data => {
                                    //console.log("successfully updated chat status");
                                })
                                .catch(error => {
                                    //console.log("failed change status");
                                })
                            auth.signOut()
                                .then(() => {
                                    //console.log("sign out successfully");
                                    dispatch({type: ADMIN_LOGOUT_SUCCESS})
                                })
                                .catch((error) => {
                                    //console.log("sign out error");
                                    dispatch({type: ADMIN_LOGOUT_FAILS, payload: error.message})
                                })
                        }
                    });
                }
            });
        } else {
            let updates = {};
            updates[`/doctors/${idTokenResult.claims.user_id}/online`] = false;
            database.ref().update(updates)
                .then(data => {
                    //console.log("successfully updated chat status");
                })
                .catch(error => {
                    //console.log("failed change status");
                })
            auth.signOut()
                .then(() => {
                    dispatch({type: DOCTOR_LOGOUT_SUCCESS});
                })
                .catch((error) => {
                    auth.signOut();
                    dispatch({type: DOCTOR_LOGOUT_SUCCESS, payload: error.message})
                })
        }
    });
}

const changeOnlineStatus = async (id, status, callback) => {
    var updates = {};
    var now = new Date();

    var logtime = status ? 'loginTime' : 'logoutTime';
    var obj = {[logtime]: now.getTime(), 'browserInfo': browser};

    let activityKey = getActivitiesRef().push().key;

    var month = now.getUTCMonth() + 1; //months from 1-12
    var day = now.getUTCDate();
    var year = now.getUTCFullYear();
    var currentDate = year + "-" + month + "-" + day;
    var sessionStart = null

    await database
                    .ref('activitiesV2')
                    .orderByChild('doctorID')
                    .equalTo(id)
                    .limitToLast(10)
                    .once('value', (snapshot) => {
                      const d = snapshot.toJSON()
                      if(d!==null){
                        const keys = Object.keys(d)
                      for (let i = keys.length - 1; i >= 0; i--) {
                        if (d[keys[i]]['event'] == 'Logged_In') {
                          sessionStart = d[keys[i]]["timestamp"]
                          return
                        }
                      }
                      }
                    })

    updates[`/doctors/${id}/online`] = status;
    updates[`/doctors/${id}/${logtime}`] = now.getTime();
    updates[`/doctors/${id}/activeOnlineChats`] = 0;
    updates[`/onlineDoctors/${id}`] = status;
    updates[`/activities/${currentDate}/${id}/${activityKey}`] = obj;
    //Activities v2
    let objj  = { doctorID:id,event:status?"Logged_In":"Logged_Out",browserInfo:browser,timestamp:now.getTime()};
    if(!status){
        objj["startTimestamp"] = sessionStart
    }
    updates[`/activitiesV2/${activityKey}`] = objj
    console.log("changeOnlineStatus ", updates)
    database.ref().update(updates)
        .then(data => {
            //console.log("successfully change status");
            callback(true);
        })
        .catch(error => {
            //console.log("failed change status: ", error.message);
            callback(false);
        })
}

const setActiveOnlineChats = () => {
    getOnlineAgentsRef().once('value', agentSnap => {
        agentSnap.forEach(childSnapshot => {
            if (childSnapshot.val()) {
                var updates = {};
                updates[`/doctors/${childSnapshot.key}/activeOnlineChats`] = 0;
                database.ref().update(updates)
                    .then(data => {
                        //console.log("successfully change status: ");
                    })
                    .catch(error => {
                        //console.log("failed change status");
                    })
            }
        });
    })
}

const getOnlineAgentsRef = () => {
    return database.ref(`/onlineDoctors`);
}

const getReportsRef = () => {
    return database.ref(`/reports`);
}

const getActivitiesRef = () => {
    return database.ref(`/activities`);
}

const getdoctorByRef = (uid) => {
    return database.ref(`/doctors/${uid}`);
}

const getOpenConversationsRef = () => {
    return database.ref(`/openconversations/`);
}

async function postData(url = '', data = {}, method) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return await response.json(); // parses JSON response into native JavaScript objects
}

const createSession = (uid, callback) => {
    //console.log("log sessions........");
    var now = new Date();
    var raw = {
        "firebaseID": uid,
        "loginTime": now.getTime(),
        "logoutTime": null,
        "totalSessionTime": null,
        "browserInfo": browser.name
    };
    callback(true)
    /*postData(`${ngrokUrl}/new_session`, raw, 'POST')
        .then((data) => {
            window.localStorage.setItem('login_session', JSON.stringify(data));
            callback(true, data);
        })
        .catch((error) => {
            callback(false, error);
        })*/
}

const endSession = (callback) => {
    // let data = window.sessionStorage.getItem('login_session');
    let data = window.localStorage.getItem('login_session');
    let session = JSON.parse(data);
    var now = new Date();

    var raw = {
        "id": session.id,
        "logoutTime": now.getTime(),
        "totalSessionTime": getHours(now.getTime(), session.loginTime)
    }
    callback(true);
    /*postData(`${ngrokUrl}/new_session`, raw, 'PUT')
        .then((data) => {
            //console.log("data: ",data);
            // window.sessionStorage.setItem('login_session', JSON.stringify(data));
            callback(true);
            window.localStorage.setItem('login_session', JSON.stringify(data)); // JSON data parsed by `response.json()` call
        })
        .catch((error) => {
            callback(false);
            //console.log("error: ", error);
        })*/
}

const updateAcceptChatSession = (currentUserID) => {
    /*var now = new Date();
    var raw;
    var session;
    // let data = window.sessionStorage.getItem('accept_chat_session');
    let data = window.localStorage.getItem('accept_chat_session');
    session = JSON.parse(data);

    let chat_session = session?.chatSessionsList?.slice(-1)[0];
    if (session) {
        raw = {
            "id": chat_session.id,
            "sessionID": session.id,
            "firebaseID": currentUserID,
            "acceptChatOffTime": now.getTime(),
            "totalChatSessionTime": getHours(now.getTime(), chat_session.acceptChatOnTime)
        }

        postData(`${ngrokUrl}/chat_session`, raw, 'PUT')
            .then((data) => {
                //console.log("chat session data at accepting chat off: ",data);
                // window.sessionStorage.setItem('accept_chat_session', JSON.stringify(data));
                window.localStorage.setItem('accept_chat_session', JSON.stringify(data));
            })
            .catch((error) => {
                //console.log("error: ", error);
            })
    }*/
}

const getHours = (logoutTime, loginTime) => {
    var delta = Math.abs(logoutTime - loginTime) / 1000;
    return delta;
}
