import  {useEffect, useState, Fragment} from 'react'

import classes from "./pharmacycomponent.module.css";
import TextareaAutosize from "react-autosize-textarea";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {ENVIRONMENT} from "../../environment";
import {database} from "../../firebase";
import axios from "axios";
import {firebaseRoot} from "../../config";

const PharmacyComponent = (props) => {
    const [pharmacyName, setPharmacyName] = useState('')
    const [pharmacyAddress, setPharmacyAddress] = useState('')
    const [pharmacyPhoneNo, setPharmacyPhoneNo] = useState('')
    const [pharmacyFaxNo, setPharmacyFaxNo] = useState('')
    const [pharmacyErrorMessage, setPharmacyErrorMessage] = useState('')
    const [showDesiredPharmacy, setShowDesiredPharmacy] = useState(true)
    const [showFaxModal, setShowFaxModal] = useState(false);
    const [prescriptionURL, setPrescriptionURL] = useState('');
    const [prescriptionMessage, setPrescriptionMessage] = useState('');
    const [prescriptionError, setPrescriptionError] = useState(false);
    const numberValidationRegex = /[^\+{1}\d]/g
    useEffect(()=>{
        //console.log("PharmacyComponent props ", props)
    }, [])

    useEffect(()=>{
        setPharmacyName(props.pharmacy?.name || "")
        setPharmacyAddress(props.pharmacy?.address || "")
        setPharmacyPhoneNo(props.pharmacy?.phone_number || "")
        setPharmacyFaxNo(props.pharmacy?.fax_number || "")
    },[props.pharmacy])

    const showDesiredPharmacyFunction = () => {
        setShowDesiredPharmacy(!showDesiredPharmacy)
    }

    const onSavePharmacy = () => {
        if(pharmacyName && pharmacyFaxNo){
            let updates = {}
            if(pharmacyName)updates[`/users/${props.patientFirebaseID}/pharmacy/name`] = pharmacyName;
            if(pharmacyAddress)updates[`/users/${props.patientFirebaseID}/pharmacy/address`] = pharmacyAddress;
            if(pharmacyPhoneNo)updates[`/users/${props.patientFirebaseID}/pharmacy/phone_number`] = pharmacyPhoneNo;
            if(pharmacyFaxNo)updates[`/users/${props.patientFirebaseID}/pharmacy/fax_number`] = pharmacyFaxNo;
            database.ref().update(updates).then((data) => {
                setPharmacyErrorMessage("")
                setShowDesiredPharmacy(false)
            }).catch(error=> {})

        }else{
            //console.log("show error message")
            setPharmacyErrorMessage("please fill all fields")
        }
    }

    const handleFaxModalClose = () => {
        setPrescriptionURL('');
        setShowFaxModal(false);
        setPrescriptionMessage('')
        setPrescriptionError(false)
    }
    const handleFaxModalShow = () => setShowFaxModal(true);

    const sendFax = () => {
        if(prescriptionURL && pharmacyFaxNo){

            let presData = {
                perscription_url: prescriptionURL,
                fax_number:pharmacyFaxNo
            }
            //console.log("presData ", JSON.stringify(presData))
            let cloudFunctionURL = ENVIRONMENT === "production"? "https://us-central1-yourdoctorsonline-prod.cloudfunctions.net" :"https://us-central1-yourdoctorsonline-staging.cloudfunctions.net"
            fetch(cloudFunctionURL + '/sendPrescriptionFax',{
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(presData)// body data type must match "Content-Type" header
            }).then(data => {
                setPrescriptionMessage("prescription sent successfully")
                setPrescriptionError(false)

                setTimeout(()=>{
                    setShowFaxModal(false);
                }, 100)
            }).catch(error => {
                //console.log("prescription failed:", error)
                setPrescriptionMessage("prescription failed due to some error, please try again.")
                setPrescriptionError(true)
            })
        }else{
            setPrescriptionMessage("please fill all fields.")
            setPrescriptionError(true)
        }
    }
    const renderFaxModal = () => {
        return(
            <Modal show={showFaxModal} onHide={handleFaxModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Resend Previous Prescription</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={classes.SOAP_div} style={{marginBottom:10}}>
                        <span className={classes.SOAP_heading}>Fax Number:</span>
                        <input
                            className={classes.SOAP_textarea}
                            style={{minHeight:45}}
                            type="tel"
                            placeholder="Enter fax number +99999999"
                            onChange={(event) => setPharmacyFaxNo((event.target.value).replace(numberValidationRegex, ""))}
                            value={pharmacyFaxNo}
                        />
                    </div>
                    <div className={classes.SOAP_div} style={{marginBottom:10}}>
                        <span className={classes.SOAP_heading}>Prescription URL:</span>
                        <input
                            className={classes.SOAP_textarea}
                            style={{minHeight:45}}
                            placeholder="Enter prescription URL"
                            onChange={(event) => setPrescriptionURL(event.target.value)}
                            value={prescriptionURL}
                        />
                    </div>
                    {prescriptionMessage && <span style={{
                        color:prescriptionError? "#a30707": "#00B74A"
                    }}>{prescriptionMessage}</span>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleFaxModalClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={sendFax}>
                        Send Fax
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
    return (
        <div className={classes.generalInformation}>
            <Fragment>
                <div className={classes.openchatlist}
                     onClick={() => showDesiredPharmacyFunction()}>
                    <div className={classes.openchatlistSection}>
                        <div
                            className={showDesiredPharmacy === true ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                            <svg fill="#884a9d" width="12px" height="8px"
                                 viewBox="0 0 12 8">
                                <path fill="inherit" fillRule="evenodd"
                                      d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                            </svg>
                        </div>
                        <div className={classes.subListTitle}>Desired Pharmacy</div>
                    </div>
                </div>
                {showDesiredPharmacy &&
                <div className={classes.expandable_section}>
                    {
                        <div className={classes.general_info_section}>
                            <div className={classes.SOAP_div}>
                                <span className={classes.SOAP_heading}>Name:</span>
                                <input
                                    className={classes.SOAP_textarea}
                                    style={{minHeight:45}}
                                    placeholder="Enter pharmacy name"
                                    onChange={(event) => setPharmacyName(event.target.value)}
                                    value={pharmacyName}
                                />
                            </div>
                            <div className={classes.SOAP_div}>
                                <span className={classes.SOAP_heading}>Address:</span>
                                <TextareaAutosize
                                    className={classes.SOAP_textarea}
                                    placeholder="Enter pharmacy address"
                                    onChange={(event) => setPharmacyAddress(event.target.value)}
                                    value={pharmacyAddress}
                                />
                            </div>
                            <div className={classes.SOAP_div}>
                                <span className={classes.SOAP_heading}>Phone Number:</span>
                                <input
                                    className={classes.SOAP_textarea}
                                    style={{minHeight:45}}
                                    type="tel"
                                    placeholder="Enter phone number"
                                    onChange={(event) => setPharmacyPhoneNo((event.target.value).replace(numberValidationRegex, ""))}
                                    value={pharmacyPhoneNo}
                                />

                            </div>
                            <div className={classes.SOAP_div}>
                                <span className={classes.SOAP_heading}>Fax Number:</span>
                                <input
                                    className={classes.SOAP_textarea}
                                    style={{minHeight:45}}
                                    type="tel"
                                    placeholder="Enter fax number"
                                    onChange={(event) => setPharmacyFaxNo((event.target.value).replace(numberValidationRegex,""))}
                                    value={pharmacyFaxNo}
                                />
                            </div>
                            {pharmacyErrorMessage && <span style={{
                                fontSize: 14,
                                color: "#a30707"
                            }}>{pharmacyErrorMessage}</span>}
                            <div className={classes.SOAP_buttons} style={{justifyContent: "right"}}>
                                {pharmacyFaxNo &&  <Button size="sm" className={classes.button_invert}
                                        onClick={() => handleFaxModalShow()}>Fax Old Prescription</Button>}
                                <Button size="sm" className={classes.button}
                                        onClick={() => onSavePharmacy()}>Save</Button>
                            </div>
                            {pharmacyFaxNo && renderFaxModal()}
                        </div>
                    }
                </div>
                }
            </Fragment>
        </div>
    )


}
export default PharmacyComponent

