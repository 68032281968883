import { Component } from "react";
import moment from "moment";
import classes from "./calendar.module.css";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';

export default class Calendar extends Component {

    state = {
        showCalendarTable: true,
        showMonthTable: false,
        dateObject: moment(),
        selectedDateObject: moment(),
        allmonths: moment.months(),
        showYearNav: false,
        selectedDay: null
    };
    componentWillReceiveProps = (newProps) => {
        if (newProps.resetDate) {
            this.setState({
                showCalendarTable: true,
                showMonthTable: false,
                dateObject: (newProps.resetDateValue) ? newProps.resetDateValue : moment(),
                selectedDateObject: (newProps.resetDateValue) ? newProps.resetDateValue : moment(),
                allmonths: moment.months(),
                showYearNav: false,
                selectedDay: null
            });
            this.weekdayshort = moment.weekdaysShort();
            this.props.resetUpdate();
        }
    }
    daysInMonth = () => {
        return this.state.dateObject.daysInMonth();
    };
    year = () => {
        return this.state.dateObject.format("Y");
    };
    currentDay = () => {
        return this.state.dateObject.format("D");
    };
    firstDayOfMonth = () => {
        let dateObject = this.state.dateObject;
        let firstDay = moment(dateObject)
            .startOf("month")
            .format("d"); // Day of week 0...1..5...6
        return firstDay;
    };
    month = () => {
        return this.state.dateObject.format("MMMM");
    };
    showMonth = (e, month) => {
        this.setState({
            showMonthTable: !this.state.showMonthTable,
            showCalendarTable: !this.state.showCalendarTable
        });
    };
    setMonth = month => {
        let monthNo = this.state.allmonths.indexOf(month);
        let dateObject = Object.assign({}, this.state.dateObject);
        dateObject = moment(dateObject).set("month", monthNo);
        this.setState({
            dateObject: dateObject,
            showMonthTable: !this.state.showMonthTable,
            showCalendarTable: !this.state.showCalendarTable
        });
    };
    MonthList = props => {
        let months = [];
        props.data.map(data => {
            months.push(
                <td
                    key={data}
                    className={classes.calendar_month}
                    onClick={e => {
                        this.setMonth(data);
                    }}
                >
                    <span>{data}</span>
                </td>
            );
        });
        let rows = [];
        let cells = [];

        months.forEach((row, i) => {
            if (i % 3 !== 0 || i == 0) {
                cells.push(row);
            } else {
                rows.push(cells);
                cells = [];
                cells.push(row);
            }
        });
        rows.push(cells);
        let monthlist = rows.map((d, i) => {
            return <tr>{d}</tr>;
        });

        return (
            <table className="calendar-month">
                <thead>
                <tr>
                    <th colSpan="4">Select a Month</th>
                </tr>
                </thead>
                <tbody>{monthlist}</tbody>
            </table>
        );
    };
    showYearEditor = () => {
        this.setState({
            showYearNav: true,
            showCalendarTable: !this.state.showCalendarTable
        });
    };

    onPrev = () => {
        let curr = "";
        if (this.state.showMonthTable == true) {
            curr = "year";
        } else {
            curr = "month";
        }
        this.setState({
            dateObject: this.state.dateObject.subtract(1, curr)
        });
    };
    onNext = () => {
        let curr = "";
        if (this.state.showMonthTable == true) {
            curr = "year";
        } else {
            curr = "month";
        }
        this.setState({
            dateObject: this.state.dateObject.add(1, curr)
        });
    };
    setYear = year => {
        // alert(year)
        let dateObject = Object.assign({}, this.state.dateObject);
        dateObject = moment(dateObject).set("year", year);
        this.setState({
            dateObject: dateObject,
            showMonthTable: !this.state.showMonthTable,
            showYearNav: !this.state.showYearNav,
            showMonthTable: !this.state.showMonthTable
        });
    };
    onYearChange = e => {
        this.setYear(e.target.value);
    };

    getDates(startDate, stopDate) {
        var dateArray = [];
        var currentDate = moment(startDate);
        var stopDate = moment(stopDate);
        while (currentDate <= stopDate) {
            dateArray.push(moment(currentDate).format("YYYY"));
            currentDate = moment(currentDate).add(1, "year");
        }
        return dateArray;
    }

    YearTable = props => {
        let months = [];
        let nextten = moment()
            .set("year", props)
            .add("year", 12)
            .format("Y");

        let tenyear = this.getDates(props, nextten);

        tenyear.map(data => {
            months.push(
                <td
                    key={data}
                    className="calendar-month"
                    onClick={e => {
                        this.setYear(data);
                    }}
                >
                    <span>{data}</span>
                </td>
            );
        });
        let rows = [];
        let cells = [];

        months.forEach((row, i) => {
            if (i % 3 !== 0 || i == 0) {
                cells.push(row);
            } else {
                rows.push(cells);
                cells = [];
                cells.push(row);
            }
        });
        rows.push(cells);
        let yearlist = rows.map((d, i) => {
            return <tr>{d}</tr>;
        });

        return (
            <table className="calendar-month">
                <thead>
                <tr>
                    <th colSpan="4">Select a Year</th>
                </tr>
                </thead>
                <tbody>{yearlist}</tbody>
            </table>
        );
    };
    dateInPast = function (firstDate, secondDate) {
        if (firstDate.setHours(0, 0, 0, 0) < secondDate.setHours(0, 0, 0, 0)) {
            return true;
        }

        return false;
    };


    onDayClick = (e, d) => {
        let dateObject = Object.assign({}, this.state.dateObject);
        dateObject = moment(dateObject).set("date", d);
        let selectedDate = moment(dateObject.format('YYYY-MM-DD'))
        var past = new Date(selectedDate);
        var today = new Date();
        if (this.dateInPast(past, today)) {
            return
        }
        this.setState(
            {
                selectedDay: d
            },
            () => {
                this.setState({
                    dateObject: dateObject,
                    selectedDateObject: selectedDate
                });
                this.props.dateChanged(selectedDate);
            }
        );
    };

    render() {
        let weekdayshortname = (this.weekdayshort) ? this.weekdayshort.map(day => {
            return <th key={day}>{day}</th>;
        }) : null;
        let blanks = [];
        for (let i = 0; i < this.firstDayOfMonth(); i++) {
            blanks.push(<td className="calendar-day empty">{""}</td>);
        }
        let daysInMonth = [];
        for (let d = 1; d <= this.daysInMonth(); d++) {
            let currentDay = d == this.currentDay() ? "today" : "";
            // let selectedClass = (d == this.state.selectedDay ? " selected-day " : "")
            var sameDate = this.state.dateObject.month() === this.state.selectedDateObject.month() && this.state.dateObject.year() === this.state.selectedDateObject.year();
            daysInMonth.push(
                <td key={d}>
          <span
              onClick={e => {
                  this.onDayClick(e, d);
              }}
          >
            <span
                className={sameDate && this.state.selectedDateObject.date() === d ? classes.selectedDate : classes.hoverDate}>{d}</span>
          </span>
                </td>
            );
        }
        var totalSlots = [...blanks, ...daysInMonth];
        let rows = [];
        let cells = [];

        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row);
            } else {
                rows.push(cells);
                cells = [];
                cells.push(row);
            }
            if (i === totalSlots.length - 1) {
                // let insertRow = cells.slice();
                rows.push(cells);
            }
        });

        let daysinmonth = rows.map((d, i) => {
            return <tr>{d}</tr>;
        });

        return (
            <div className={classes.tail_datetime_calendar}>
                <div className={classes.calendar_navi}>
                    <div>
            <span
                className={classes.calendar_label}
                onClick={e => {
                    //this.showYearEditor();
                }}
            >
              {this.month() + ' ' + this.year()}
            </span>
                    </div>
                    <div className={classes.arrowSegment}>
                        <IconButton aria-label="prev" className={classes.button_prev} onClick={e => {
                            this.onPrev();
                        }}>
                            <ArrowBackIosIcon fontSize='small'/>
                        </IconButton>
                        <IconButton aria-label="next" className={classes.button_next} onClick={e => {
                            this.onNext();
                        }}>
                            <ArrowForwardIosIcon fontSize='small'/>
                        </IconButton>
                    </div>
                </div>
                <div className={classes.calendar_date}>
                    {this.state.showYearNav && <this.YearTable props={this.year()}/>}
                    {this.state.showMonthTable && (
                        <this.MonthList data={moment.months()}/>
                    )}
                </div>

                {this.state.showCalendarTable && (
                    <div className={classes.calendar_date}>
                        <table className="calendar-day">
                            <thead>
                            <tr>{weekdayshortname}</tr>
                            </thead>
                            <tbody>{daysinmonth}</tbody>
                        </table>
                    </div>
                )}
            </div>
        );
    }
}
