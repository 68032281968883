import  {useEffect, useRef} from 'react';
import classes from '../encounters.module.css';
import _classes from '../_encounters.module.css';
import TimeAgo from 'timeago-react';
import isMobileView from '../../../../utils/isMobileView';
import {FaCaretLeft, FaCaretRight} from 'react-icons/fa'
import classNames from 'classnames'

const MessageList = (props) => {
    var status = props.isRead ? 'Read' : 'Delivered';
    if (props.direction === 'left') {
        return (
            isMobileView() ? <li className={_classes.message_li}>
                <FaCaretLeft size={30} className={_classes.left_message_caret}></FaCaretLeft>

                <div className={classNames(_classes.message_wrapper, _classes.sb11)}>
                    <span className={_classes.author}>{props.patientName}</span>
                    <div className={_classes.message_container}>
                        <div className={_classes.message_text}>
                            {props.type === "text" &&
                            <span>{props.text}</span>
                            }
                            {(props.type === "image" || props.type === "video" || props.type === "application") &&
                            <a href={props.text} target="_blank" rel="noopener noreferrer" alt="">
                                <p>{props.text}</p>
                            </a>
                            }
                        </div>
                    </div>
                    <div className={_classes.message_time_left}>
                        <span className={_classes.timestamp_span_left}><TimeAgo datetime={props.timestamp}/></span>
                        <span className={_classes.read_span_left}>{status}</span>
                    </div>
                </div>
            </li> : <li className={classes.message_li}>
                {/* <FaCaretRight size={22} color="#fff" style={{position:'absolute',left:'10px',bottom:'30px'}} /> */}
                <div className={classes.message_wrapper}>
                    <span className={classes.author}>{props.patientName}</span>
                    <div className={classes.user_symbol}>
                        <div className={classes.user_img}>
                            <span className={classes.user_symbol_content}>P</span>
                        </div>
                    </div>
                    <div className={classes.message_container}>
                        <div className={classes.message_text}>
                            {props.type === "text" &&
                            <span>{props.text}</span>
                            }
                            {(props.type === "image" || props.type === "video" || props.type === "application") &&
                            <a href={props.text} target="_blank" rel="noopener noreferrer" alt="">
                                <p>{props.text}</p>
                            </a>
                            }
                        </div>
                    </div>
                    <div className={classes.message_time_left}>
                        <span className={classes.timestamp_span_left}><TimeAgo datetime={props.timestamp}/></span>
                        <span className={classes.read_span_left}>{status}</span>
                    </div>
                </div>
            </li>
        )
    } else {
        return (
            isMobileView() ? <li className={_classes.message_li_right}>
                <FaCaretRight size={30} className={_classes.right_message_caret}/>
                <div className={_classes.message_wrapper_right}>
                    {/* <span className={_classes.author_right}>{props.doctorName}</span> */}
                    <div className={_classes.message_container}>
                        <div className={_classes.message_text_right}>
                            {props.type === "text" &&
                            <span>{props.text}</span>
                            }
                            {(props.type === "image" || props.type === "video" || props.type === "application") &&
                            <a href={props.text} target="_blank" rel="noopener noreferrer" alt="">
                                <p>{props.text}</p>
                            </a>
                            }
                        </div>
                    </div>
                    <div className={_classes.message_time_right}>
                        <span className={_classes.timestamp_span_right}><TimeAgo datetime={props.timestamp}/></span>
                        <span className={_classes.read_span_right}>{status}</span>
                    </div>

                </div>
            </li> : <li className={classes.message_li_right}>
                <div className={classes.message_wrapper_right}>
                    {/* <span className={classes.author_right}>{props.doctorName}</span> */}
                    <div className={classes.user_symbol_right}>
                        <div className={classes.user_symbol_content_right}>
                            <span>
                                <img
                                    src={props.doctorImage}
                                    className={classes.user_img_right}
                                    alt=""
                                />
                            </span>
                        </div>
                        <div className={classes.message_container}>
                            <div className={classes.message_text_right}>
                                {props.type === "text" &&
                                <span>{props.text}</span>
                                }
                                {(props.type === "image" || props.type === "video" || props.type === "application") &&
                                <a href={props.text} target="_blank" rel="noopener noreferrer" alt="">
                                    <p>{props.text}</p>
                                </a>
                                }
                            </div>
                        </div>
                        <div className={classes.message_time_right}>
                            <span className={classes.timestamp_span_right}><TimeAgo datetime={props.timestamp}/></span>
                            <span className={classes.read_span_right}>{status}</span>
                        </div>
                    </div>
                </div>
            </li>
        )
    }
}

const List = (props) => {
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        !props.loading_messages && messagesEndRef.current.scrollIntoView({behavior: "smooth"})
    }

    const messages = props.messages.map(message => {
        return <MessageList
            key={message.receiveMessage.timestamp}
            type={message.receiveMessage.type}
            isRead={message.receiveMessage.isRead}
            direction={message.receiveMessage.direction}
            text={message.receiveMessage.content}
            timestamp={message.receiveMessage.timestamp}
            doctorName={message.receiveMessage.doctorName}
            doctorImage={message.receiveMessage.doctorImage}
            patientName={props.patientName}
        />
    });

    useEffect(scrollToBottom, [messages]);

    return (
        <div className={classes.chatcontent}>
            <div className={classes.chatcontainer}>
                {!props.loading_messages &&
                isMobileView() ? <ul className={_classes.chat_ul} style={{paddingTop: '65px'}}>
                    <li>
                        <ul className={_classes.chat_main_ul}>
                            {messages}
                        </ul>
                        <div ref={messagesEndRef}/>
                    </li>
                </ul> : <ul className={classes.chat_ul}>
                    <li>
                        <ul className={classes.chat_main_ul}>
                            {messages}
                        </ul>
                        <div ref={messagesEndRef}/>
                    </li>
                </ul>
                }
                {props.loading_messages &&
                <div className={classes.no_loading}>
                    <div className={classes.no_loading_view}>
                        <div className={classes.loading_loader}/>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}

export default List;
