import  {Component} from 'react';
import {connect} from 'react-redux';
import Aux from '../../hoc/hocAux';
import classes from './SettingBuilder.module.css';
import SettingList from './SettingList';
import SettingDetail from './SettingDetail';
import {fetchTags, save_tags, tag_changed} from '../../actions/TagsAction';
import {
    canned_response_description_changed,
    canned_response_save,
    canned_response_shortcut_changed,
    deleteCannedResponse,
    fetchCannedResponses,
    updateCannedResponse
} from '../../actions/CannedResponseAction';
import {childChangedDoctor} from '../../actions/AgentsAction';
import {checkStatusAccept} from '../../actions/ConversationAction';

class SettingBuilder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            headerText: 'Canned Responses',
            selectedEditTag: null,
            selectedTagDesciption: '',
            selectedTagShortCut: '',
            selectedDeleteTag: null,
        }
    }

    componentDidMount() {
        this.props.fetchTags();
        this.props.fetchCannedResponses();
        this.props.childChangedDoctor()
    }

    settingItemsClick = (item) => {
        this.setState({headerText: item});
    }

    onTagTextChange = (event) => {
        this.props.tag_changed(event.target.value);
    }

    onSaveTag = (event) => {
        event.preventDefault();
        if (this.props.tag_value !== '') {
            this.props.save_tags(this.props.tag_value);
        } else {
            alert("Please check tag input.");
        }
    }

    description = (event) => {
        this.props.canned_response_description_changed(event.target.value);
    }

    shortcut = (event) => {
        this.props.canned_response_shortcut_changed(event.target.value);
    }

    onSaveCannedResponse = (event) => {
        event.preventDefault();
        if (this.props.description !== '' && this.props.short_cut !== '') {
            this.props.canned_response_save(this.props.description, this.props.short_cut);
        } else {
            alert("Please check your canned response text and shortcut. We need both");
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.changedDoctorKey === newProps.doctorDetail.uid) {
            this.props.checkStatusAccept();
        }
    }

    onEditClick = (uid, desc, shortcut) => {
        this.setState({selectedEditTag: uid, selectedTagDesciption: desc, selectedTagShortCut: shortcut});
    }

    onChangeCannedDescription = (event) => {
        this.setState({selectedTagDesciption: event.target.value});
    }

    onChangeCannedShortCut = (event) => {
        this.setState({selectedTagShortCut: event.target.value});
    }

    updateCannedResponse = (uid, desc, shortcut) => {
        var self = this;
        this.props.updateCannedResponse(uid, desc, shortcut, function (status) {
            self.setState({selectedEditTag: null, selectedTagDesciption: '', selectedTagShortCut: ''});
        });
    }

    updateCancelClick = () => {
        this.setState({selectedEditTag: null, selectedTagDesciption: '', selectedTagShortCut: ''});
    }

    onDeleteClick = (uid) => {
        this.setState({selectedDeleteTag: uid});
    }

    confirmDeleteClick = (uid) => {
        var self = this;
        this.props.deleteCannedResponse(uid, function (status) {
            self.setState({selectedDeleteTag: uid});
        });
    }

    confirmCancelClick = () => {
        this.setState({selectedDeleteTag: null});
    }

    render() {
        return (
            <Aux>
                <div className={classes.main}>
                    <SettingList settingItemsClick={(item) => this.settingItemsClick(item)}
                                 acceptingChat={this.props.acceptingChat}
                    />
                    <SettingDetail
                        header={this.state.headerText}
                        tag={this.props.tag_value}
                        tagsList={this.props.tags}
                        onTagTextChange={(text) => this.onTagTextChange(text)}
                        onSaveTag={(event) => this.onSaveTag(event)}
                        description={this.props.description}
                        short_cut={this.props.short_cut}
                        selectedEditTag={this.state.selectedEditTag}
                        selectedDeleteTag={this.state.selectedDeleteTag}
                        selectedTagDesciption={this.state.selectedTagDesciption}
                        selectedTagShortCut={this.state.selectedTagShortCut}
                        canned_responsesList={this.props.canned_responses}
                        onChangeCannedDescription={(event) => this.onChangeCannedDescription(event)}
                        onChangeCannedShortCut={(event) => this.onChangeCannedShortCut(event)}
                        onCannedResponseTextChange={(text) => this.description(text)}
                        onCannedResponseShortcutChange={(text) => this.shortcut(text)}
                        onSaveCannedResponse={(event) => this.onSaveCannedResponse(event)}
                        updateCannedResponse={(uid, desc, shortcut) => this.updateCannedResponse(uid, desc, shortcut)}
                        updateCancelClick={() => this.updateCancelClick()}
                        onEditClick={(uid, desc, shortcut) => this.onEditClick(uid, desc, shortcut)}
                        onDeleteClick={(uid) => this.onDeleteClick(uid)}
                        confirmDeleteClick={(uid) => this.confirmDeleteClick(uid)}
                        confirmCancelClick={() => this.confirmCancelClick()}
                    />
                </div>
            </Aux>
        )
    }
}

const mapStateToProps = ({
                             tagsReducer,
                             cannedResponseReducer,
                             conversationReducer,
                             acceptingReducer,
                             doctorReducer
                         }) => {
    const {tags, tag_value} = tagsReducer;
    const {description, short_cut, canned_responses} = cannedResponseReducer;
    const {acceptingChat, changedDoctorKey} = acceptingReducer
    const {doctorDetail} = doctorReducer
    return {tags, tag_value, description, short_cut, canned_responses, acceptingChat, doctorDetail, changedDoctorKey};
}

export default connect(mapStateToProps, {
    tag_changed,
    save_tags,
    fetchTags,
    canned_response_description_changed,
    childChangedDoctor,
    checkStatusAccept,
    canned_response_shortcut_changed,
    canned_response_save,
    updateCannedResponse,
    deleteCannedResponse,
    fetchCannedResponses
})(SettingBuilder);
