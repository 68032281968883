import {CHECK_ADMIN} from '../constants';

const INITIAL_STATE = {admin: false, clinicalStatus: false};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHECK_ADMIN:
            return {
                ...state,
                admin: action.payload.admin,
                clinicalStatus: action.payload.clinicalStatus
            };
        default:
            return state;
    }
}
