import {Component, createRef, useEffect, useRef, useState} from 'react';
import {Provider} from "react-redux";
import {applyMiddleware, createStore } from "redux";
import ReduxThunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reducers from './reducers';
import Router from './router';
import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import NetworkDetector from '../src/hoc/network';
import thrown from '../src/assets/accomplished.mp3';
import Sound from 'react-sound';
import {messaging, messagingRef} from './firebase'
const middlewares = [];

if (process.env.NODE_ENV === 'development') {
    middlewares.push(createLogger());
}

const store = createStore(reducers, {}, applyMiddleware(...middlewares,ReduxThunk));

const App = (props) => {
    const [playSound, setPlaySound] = useState(false);

    const addNotification = (type, name, message) => {
        if (type === 'joined_chat') {
            Store.addNotification({
                title: "New Patient",
                message: `${name} joined the chat`,
                type: "info",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 15000,
                    onScreen: true,
                    pauseOnHover: true
                }
            });
        }

        if (type === 'transfer_chat') {
            Store.addNotification({
                title: "Chat Transferred",
                message: `${name} has been transferred to you`,
                type: "info",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 15000,
                    onScreen: true,
                    pauseOnHover: true
                }
            });
        }

        if (type === 'queued_chat') {
            Store.addNotification({
                title: "New chat in queue",
                message: `${name} is in the queue`,
                type: "info",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 15000,
                    onScreen: true,
                    pauseOnHover: true
                }
            });
        }

        if (type === 'new_message') {
            Store.addNotification({
                title: "New Message",
                message: `${name} has sent a new message: ` + message,
                type: "info",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 15000,
                    onScreen: true,
                    pauseOnHover: true
                }
            });
        }
    };

    useEffect(()=>{
        if (messagingRef.isSupported()) {
            console.log("onMessage isSupported")

            messaging.onMessage((payload) => {
                console.log("messaging.onMessage payload", payload)
                if (payload?.data?.isSame === 'false') {
                    addNotification(payload?.data?.type, payload?.data?.patientName, payload?.data?.message)
                }
            });


        }
    },[])


    return (
        <Provider store={store}>
            <ReactNotifications />

            {playSound &&
            <Sound
                url={thrown}
                playStatus={Sound.status.PLAYING}
                playFromPosition={0 /* in milliseconds */}
                onLoading={()=>{}}
                onError={(errorCode, description)=>{console.log("erorroroororor", errorCode, description)}}
                onPlaying={()=>{}}
                onFinishedPlaying={() => setPlaySound(false)}
            />
            }
            <Router/>
        </Provider>
    );
}

export default NetworkDetector(App);
