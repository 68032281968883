
import classes from './CustomersStyle.module.css';

const CustomerList = (props) => {
    var chatting = !props.queued && props.openChat ? 'Chatting' : !props.queued ? 'Left Website' : 'Queued';
    return (
        <div className={classes.selected}>
            <div className={classes.nameView}>
                <div className={classes.mainNameView}>
                    <div className={classes.subView}>
                        <div className={classes.icon_image}>
                            <span className={classes.icon_image_letter}>{(props.name).charAt(0).toUpperCase()}</span>
                        </div>
                        <div className={classes.doctor_content}>{props.name}</div>
                    </div>
                </div>
            </div>
            <div className={classes.emailView}>
                <div className={classes.user_email}>{props.email}</div>
            </div>
            <div className={classes.actions_view}>
                <div className={classes.actions_sub_view}>
                    {!props.chatOwner && chatting === 'Chatting' &&
                    <div className={classes.button}
                         onClick={() => props.onCustomerButtonClick(props.queued, props.openChat, props.chatOwner,
                             props.patientID, props.doctorID, props.conversationId,
                             props.issue, props.category)}>
                        <button className={classes.actions_button}>Supervise chat</button>
                    </div>
                    }
                    {props.chatOwner && chatting === 'Chatting' &&
                    <div className={classes.button}
                         onClick={() => props.onCustomerButtonClick(props.queued, props.openChat, props.chatOwner,
                             props.patientID, props.doctorID, props.conversationId,
                             props.issue, props.category)}>
                        <button className={classes.owner_actions_button}>Go to chat</button>
                    </div>
                    }
                    {chatting === 'Queued' &&
                    <div className={classes.button} onClick={() => props.onCustomerButtonClick(props.queued,
                        props.openChat, props.chatOwner, props.patientID, props.doctorID, props.conversationId,
                        props.issue, props.category)}>
                        <button className={classes.queued_button}>Pick from queue</button>
                    </div>
                    }
                </div>
            </div>
            <div className={classes.activity_view}>
                {!props.queued && props.openChat &&
                <div className={classes.activity_sub_view}>
                    <div className={classes.activity_status_icon + ' ' + classes.activity_icon_color}/>
                    <div className={classes.activity_text + ' ' + classes.activity_list}>Chatting</div>
                </div>
                }
                {!props.queued &&
                <div className={classes.activity_sub_view}>
                    <div className={classes.activity_status_icon + ' ' + classes.left_activity_icon_color}/>
                    <div className={classes.left_activity_text + ' ' + classes.activity_list}>Left Website</div>
                </div>
                }
                {props.queued &&
                <div className={classes.activity_sub_view}>
                    <div className={classes.activity_status_icon + ' ' + classes.queued_activity_icon_color}/>
                    <div className={classes.queued_activity_text + ' ' + classes.activity_list}>Queued</div>
                </div>
                }
            </div>
            <div className={classes.chatting_view}>
                <div className={classes.chatting_sub_view}>
                    <div className={classes.chatting_icon}>
                        <div className={classes.chatting_image}></div>
                        <span className={classes.chatting_icon_online}></span>
                    </div>
                    <span className={classes.chatting_name}>{props.doctorName}</span>
                </div>
            </div>
            <div className={classes.chats_main_view}>
                <div className={classes.chats_count_view}>
                    <span className={classes.chat_count_status}>{props.chatcount}</span>
                </div>
            </div>
        </div>
    )
}

export default CustomerList;
