import  {Component} from "react";
import {connect} from "react-redux";
import Aux from "../../../hoc/hocAux";
import classes from "./archivestyles.module.css";
import _style from "../../../assets/_classes.module.css";

import ArchiveList from "./List";
import _ArchiveList from "./_List";
import ArchiveBody from "./Body";
import {childChangedDoctor} from "../../../actions/AgentsAction";
import {checkStatusAccept} from "../../../actions/ConversationAction";
import ArchiveUserDetail from "./UserDetail";
import _Sidebar from "../../clinicalSide/encounters/patientDetail/_Sidebar";
import _ from "lodash";

import EditChatDetails from "./editDetail/EditDetails";

import {
    fetchClinicalArchiveMessages,
    fetchClinicalArchives,
    fetchPatientsClinicalArchives,
    resetArchiveMessages,
    searchArchives,
    setAlgolia,
} from "../../../actions/ArchivesAction";
import isMobileView from "../../../utils/isMobileView";
import Header from "../../../components/UI/Header/Header";
import AcceptChatFooter from "../../../components/AcceptChatFooter/AcceptChat";

class ClinicalArchivesBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headerText: {name: "Archives"},
            tagsList: [],
            editChatDetailForm: false,
            inputValue: "",
        };
    }

    componentDidMount() {
        var action = this.props.location.state
            ? this.props.location.state.action === "isfromCliniclPaitientsRegistrations"
                ? "isfromCliniclPaitientsRegistrations"
                : null
            : null;
        this.props.setAlgolia(this.props.clinicId);
        if (Boolean(action)) {
            this.props.fetchPatientsClinicalArchives(
                this.props.location.state.userId,
                this.props.clinicId
            );
        } else this.props.fetchClinicalArchives([], null, this.props.clinicId);
        this.props.childChangedDoctor(this.props.clinicId);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.changedDoctorKey === newProps.staffData.uid) {
            this.props.checkStatusAccept(this.props.clinicId);
        }
    }

    componentWillMount() {
        this.props.resetArchiveMessages();
    }

    archiveHeaderItem = (
        user_id,
        patientEmail,
        patientName,
        doctorName,
        doctorID,
        patientID,
        doctorImage,
        conversationID,
        tagsList,
        city,
        country,
        platform,
        DOB,
        gender,
        issue,
        currentMedications,
        diagnosisValue,
        opinionDescriptionValue,
        commentAdded,
        medications,
        followUpData,
        gmtOffset,
        buildNumber,
        convoCount,
        averageSleep,
        currentSleep,
        heartRate,
        heightCm,
        heightFeet,
        stepCount,
        runningWalking,
        weightKg,
        weightLbs,
        periodStartDate,
        flowType,
        restingHeartRate,
        complete_address,
        timestamp,
        issueResolved,
        symptoms,
        findings,
        assessment,
        plan,
        icd,
        billing_code,
        billing_unit,
        prescriptions,
        requisitions,
        special_referals,
        video_consult,
        followUpDays,
        archive_object
    ) => {
        if (tagsList !== null && tagsList !== undefined) {
            const tags = _.map(tagsList, (val, uid) => {
                return {...val, uid};
            });
            this.setState({tagsList: tags});
        }
        this.setState({headerText: {name: patientName, doctor: doctorName, DOB, city, country}});
        this.props.fetchClinicalArchiveMessages(
            user_id,
            patientEmail,
            patientName,
            doctorName,
            doctorID,
            patientID,
            doctorImage,
            conversationID,
            tagsList,
            city,
            country,
            platform,
            DOB,
            gender,
            issue,
            currentMedications,
            diagnosisValue,
            opinionDescriptionValue,
            commentAdded,
            medications,
            followUpData,
            gmtOffset,
            buildNumber,
            convoCount,
            averageSleep,
            currentSleep,
            heartRate,
            heightCm,
            heightFeet,
            stepCount,
            runningWalking,
            weightKg,
            weightLbs,
            periodStartDate,
            flowType,
            restingHeartRate,
            complete_address,
            timestamp,
            issueResolved,
            symptoms,
            findings,
            assessment,
            plan,
            icd,
            billing_code,
            billing_unit,
            prescriptions,
            requisitions,
            special_referals,
            video_consult,
            followUpDays,
            archive_object
        );
    };

    archiveSearchText = () => {
        this.props.searchArchives(this.state.inputValue, []);
    };

    archiveSearchValue = text => {
        if (text === "") {
            this.props.fetchClinicalArchives([], null, this.props.clinicId);
        } else {
            this.setState({inputValue: text});
        }
    };

    nextArchives = () => {
        this.props.fetchClinicalArchives(
            this.props.archives,
            this.props.lastTimeStamp,
            this.props.clinicId
        );
    };

    editChatDetailClick = () => {
        this.setState({editChatDetailForm: true});
    };

    hideModal = () => {
        this.setState({editChatDetailForm: false});
    };

    nextArchives = () => {
        this.props.fetchClinicalArchives(
            this.props.archives,
            this.props.lastTimeStamp,
            this.props.clinicId
        );
    };

    render() {
        return isMobileView() ? (
            <Aux style={{heigth: "100%", "background-color": "#F1F2F6"}}>
                {!Boolean(this.state.chatSelected) && (
                    <div className={_style.main} style={{height: window.innerHeight}}>
                        <Header
                            title="Patient Archives"
                            back={{onClick: () => this.props.history.goBack(null)}}
                        ></Header>
                        <_ArchiveList
                            chatSelected={val => {
                                //  alert(val)
                                this.setState({chatSelected: val});
                                this.setState({showChatConfirmationModal: true});
                            }}
                            loading={this.props.loading}
                            archives={this.props.archives}
                            archiveHeaderItem={(
                                user_id,
                                patientEmail,
                                patientName,
                                doctorName,
                                doctorID,
                                patientID,
                                doctorImage,
                                conversationID,
                                tagsList,
                                city,
                                country,
                                platform,
                                DOB,
                                gender,
                                issue,
                                currentMedications,
                                diagnosisValue,
                                opinionDescriptionValue,
                                commentAdded,
                                medications,
                                followUpData,
                                gmtOffset,
                                buildNumber,
                                convoCount,
                                averageSleep,
                                currentSleep,
                                heartRate,
                                heightCm,
                                heightFeet,
                                stepCount,
                                runningWalking,
                                weightKg,
                                weightLbs,
                                periodStartDate,
                                flowType,
                                restingHeartRate,
                                complete_address,
                                timestamp,
                                issueResolved,
                                symptoms,
                                findings,
                                assessment,
                                plan,
                                icd,
                                billing_code,
                                billing_unit,
                                prescriptions,
                                requisitions,
                                special_referals,
                                video_consult,
                                followUpDays,
                                archive_object
                            ) =>
                                this.archiveHeaderItem(
                                    user_id,
                                    patientEmail,
                                    patientName,
                                    doctorName,
                                    doctorID,
                                    patientID,
                                    doctorImage,
                                    conversationID,
                                    tagsList,
                                    city,
                                    country,
                                    platform,
                                    DOB,
                                    gender,
                                    issue,
                                    currentMedications,
                                    diagnosisValue,
                                    opinionDescriptionValue,
                                    commentAdded,
                                    medications,
                                    followUpData,
                                    gmtOffset,
                                    buildNumber,
                                    convoCount,
                                    averageSleep,
                                    currentSleep,
                                    heartRate,
                                    heightCm,
                                    heightFeet,
                                    stepCount,
                                    runningWalking,
                                    weightKg,
                                    weightLbs,
                                    periodStartDate,
                                    flowType,
                                    restingHeartRate,
                                    complete_address,
                                    timestamp,
                                    issueResolved,
                                    symptoms,
                                    findings,
                                    assessment,
                                    plan,
                                    icd,
                                    billing_code,
                                    billing_unit,
                                    prescriptions,
                                    requisitions,
                                    special_referals,
                                    video_consult,
                                    followUpDays,
                                    archive_object
                                )
                            }
                            archiveSearchValue={text => this.archiveSearchValue(text)}
                            archiveSearchText={text => this.archiveSearchText(text)}
                            isSearch={this.props.isSearch}
                            isLast={this.props.isLast}
                            acceptingChat={this.props.acceptingChat}
                            nextArchives={() => this.nextArchives()}
                            previous_length={this.props.previous_length}
                        />
                        <AcceptChatFooter></AcceptChatFooter>
                    </div>
                )}
                {Boolean(this.state.chatSelected) && (
                    <ArchiveBody
                        back={{onClick: () => this.props.history.goBack(null)}}
                        chatSelected={val => {
                            this.setState({chatSelected: val});
                            this.setState({showChatConfirmationModal: false});
                        }}
                        toggleSidebar={val => this.setState({showSidebar: val})}
                        showSidebar={Boolean(this.state.showSidebar)}
                        tags={this.state.tagsList}
                        headerText={this.state.headerText}
                        load_messages={this.props.load_messages}
                        archive_timestamp={this.props.archive_timestamp}
                        archives_messages={this.props.archives_messages}
                        doctorName={this.props.archive_doctorName}
                        doctorImg={this.props.archive_doctorImg}
                        patientName={this.props.archive_patientName}
                    />
                )}
                {Boolean(this.state.showSidebar) && (
                    <_Sidebar
                        isClinicalArchive={true}
                        toggleSidebar={val => this.setState({showSidebar: val})}
                        showSidebar={Boolean(this.state.showSidebar)}
                        patientEmail={this.props.archive_patientEmail}
                        patientName={this.props.archive_patientName}
                        city={this.props.archive_patientCity}
                        country={this.props.archive_patientCountry}
                        complete_address={this.props.archive_complete_address}
                        platform={this.props.archive_platform}
                        dob={this.props.archive_patientDOB}
                        gender={this.props.archive_patientGender}
                        symptoms={this.props.archive_patientSymptoms}
                        archive_findings={this.props.archive_findings}
                        archive_assessment={this.props.archive_assessment}
                        archive_plan={this.props.archive_plan}
                        medication={this.props.archive_patientMedications}
                        chatCount={this.props.archive_patientChatCount}
                        buildNumber={this.props.archive_patientBuildNumber}
                        sessionID={this.props.archive_conversationID}
                        editChatDetailClick={() => this.editChatDetailClick()}
                        averageSleep={this.props.averageSleep}
                        currentSleep={this.props.currentSleep}
                        heightCm={this.props.heightCm}
                        heightFeet={this.props.heightFeet}
                        stepCount={this.props.stepCount}
                        runningWalking={this.props.runningWalking}
                        weightKg={this.props.weightKg}
                        heartRate={this.props.heartRate}
                        weightLbs={this.props.weightLbs}
                        periodStartDate={this.props.periodStartDate}
                        flowType={this.props.flowType}
                        restingHeartRate={this.props.restingHeartRate}
                        diagnosisValue={this.props.archive_diagnosisValue}
                        commentAdded={this.props.archive_commentAdded}
                        hideEndEncounter
                        patientFollowUpData={this.props.archive_patientFollowUpData}
                        patientMedicationData={this.props.archive_patientMedicationData}
                    ></_Sidebar>
                )}
            </Aux>
        ) : (
            <Aux>
                <div className={classes.top_bar}>
                    <div className={classes.header_doctor_name}>
                        <span>{this.props.full_name}</span>
                    </div>
                    {/*<div className={classes.header_status}>
                        Status:
                        {this.props.acceptingChat && <span> Accepting Chat</span>}
                        {!this.props.acceptingChat && <span className={classes.notAcceptingChat}> Not Accepting Chat</span>}
                    </div>*/}
                </div>
                <div className={classes.main}>
                    <ArchiveList
                        loading={this.props.loading}
                        archives={this.props.archives}
                        archiveHeaderItem={(
                            user_id,
                            patientEmail,
                            patientName,
                            doctorName,
                            doctorID,
                            patientID,
                            doctorImage,
                            conversationID,
                            tagsList,
                            city,
                            country,
                            platform,
                            DOB,
                            gender,
                            issue,
                            currentMedications,
                            diagnosisValue,
                            opinionDescriptionValue,
                            commentAdded,
                            medications,
                            followUpData,
                            gmtOffset,
                            buildNumber,
                            convoCount,
                            averageSleep,
                            currentSleep,
                            heartRate,
                            heightCm,
                            heightFeet,
                            stepCount,
                            runningWalking,
                            weightKg,
                            weightLbs,
                            periodStartDate,
                            flowType,
                            restingHeartRate,
                            complete_address,
                            timestamp,
                            issueResolved,
                            symptoms,
                            findings,
                            assessment,
                            plan,
                            icd,
                            billing_code,
                            billing_unit,
                            prescriptions,
                            requisitions,
                            special_referals,
                            video_consult,
                            followUpDays,
                            archive_object
                        ) =>
                            this.archiveHeaderItem(
                                user_id,
                                patientEmail,
                                patientName,
                                doctorName,
                                doctorID,
                                patientID,
                                doctorImage,
                                conversationID,
                                tagsList,
                                city,
                                country,
                                platform,
                                DOB,
                                gender,
                                issue,
                                currentMedications,
                                diagnosisValue,
                                opinionDescriptionValue,
                                commentAdded,
                                medications,
                                followUpData,
                                gmtOffset,
                                buildNumber,
                                convoCount,
                                averageSleep,
                                currentSleep,
                                heartRate,
                                heightCm,
                                heightFeet,
                                stepCount,
                                runningWalking,
                                weightKg,
                                weightLbs,
                                periodStartDate,
                                flowType,
                                restingHeartRate,
                                complete_address,
                                timestamp,
                                issueResolved,
                                symptoms,
                                findings,
                                assessment,
                                plan,
                                icd,
                                billing_code,
                                billing_unit,
                                prescriptions,
                                requisitions,
                                special_referals,
                                video_consult,
                                followUpDays,
                                archive_object
                            )
                        }
                        archiveSearchValue={text => this.archiveSearchValue(text)}
                        archiveSearchText={text => this.archiveSearchText(text)}
                        isSearch={this.props.isSearch}
                        isLast={this.props.isLast}
                        acceptingChat={this.props.acceptingChat}
                        nextArchives={() => this.nextArchives()}
                        previous_length={this.props.previous_length}
                    />

                    <ArchiveBody
                        tags={this.state.tagsList}
                        headerText={this.state.headerText}
                        load_messages={this.props.load_messages}
                        archive_timestamp={this.props.archive_timestamp}
                        archives_messages={this.props.archives_messages}
                        doctorName={this.props.archive_doctorName}
                        doctorImg={this.props.archive_doctorImg}
                        patientName={this.props.archive_patientName}
                    />

                    {this.state.headerText.name !== "Archives" && (
                        <ArchiveUserDetail
                            {...this.props}
                            patientEmail={this.props.archive_patientEmail}
                            patientName={this.props.archive_patientName}
                            city={this.props.archive_patientCity}
                            country={this.props.archive_patientCountry}
                            complete_address={this.props.archive_complete_address}
                            platform={this.props.archive_platform}
                            dob={this.props.archive_patientDOB}
                            gender={this.props.archive_patientGender}
                            symptoms={this.props.archive_patientSymptoms}
                            archive_findings={this.props.archive_findings}
                            archive_assessment={this.props.archive_assessment}
                            archive_plan={this.props.archive_plan}
                            medication={this.props.archive_patientMedications}
                            chatCount={this.props.archive_patientChatCount}
                            buildNumber={this.props.archive_patientBuildNumber}
                            sessionID={this.props.archive_conversationID}
                            editChatDetailClick={() => this.editChatDetailClick()}
                            averageSleep={this.props.averageSleep}
                            currentSleep={this.props.currentSleep}
                            heightCm={this.props.heightCm}
                            heightFeet={this.props.heightFeet}
                            stepCount={this.props.stepCount}
                            runningWalking={this.props.runningWalking}
                            weightKg={this.props.weightKg}
                            heartRate={this.props.heartRate}
                            weightLbs={this.props.weightLbs}
                            periodStartDate={this.props.periodStartDate}
                            flowType={this.props.flowType}
                            restingHeartRate={this.props.restingHeartRate}
                            diagnosisValue={this.props.archive_diagnosisValue}
                            commentAdded={this.props.archive_commentAdded}
                            patientFollowUpData={this.props.archive_patientFollowUpData}
                            patientMedicationData={this.props.archive_patientMedicationData}
                        />
                    )}

                    <EditChatDetails
                        {...this.props}
                        show={this.state.editChatDetailForm}
                        hideModal={() => this.hideModal()}
                        patientName={this.props.archive_patientName}
                        patientDOB={this.props.archive_patientDOB}
                        doctorName={this.props.archive_doctorName}
                        doctorImage={this.props.archive_doctorImg}
                        archive_patientIssue={this.props.archive_patientSymptoms}
                        archive_diagnosisValue={this.props.archive_diagnosisValue}
                        archive_opinionDescriptionValue={this.props.archive_opinionDescriptionValue}
                        archive_commentAdded={this.props.archive_commentAdded}
                        archive_patientSymptoms={this.props.archive_patientSymptoms}
                        archive_findings={this.props.archive_findings}
                        archive_assessment={this.props.archive_assessment}
                        archive_plan={this.props.archive_plan}
                        archive_icd={this.props.archive_icd}
                        archive_billingCode={this.props.archive_billingCode}
                        archive_billingUnit={this.props.archive_billingUnit}
                        archive_prescriptions={this.props.archive_prescriptions}
                        archive_requisitions={this.props.archive_requisitions}
                        archive_special_referals={this.props.archive_special_referals}
                        archive_video_consult={this.props.archive_video_consult}
                        archive_followUpDays={this.props.archive_followUpDays}
                        archive_patientFollowUpData={this.props.archive_patientFollowUpData}
                        archive_patientMedicationData={this.props.archive_patientMedicationData}
                        archive_conversationID={this.props.archive_conversationID}
                        archive_userID={this.props.archive_userID}
                        patient_profile={
                            this.props.selectedArchiveObj ? this.props.selectedArchiveObj.patient_profile : null
                        }
                        staffData={this.props.staffData}
                        endTime={this.props.selectedArchiveObj ? this.props.selectedArchiveObj.timestamp : null}
                        encounter_duration={
                            this.props.selectedArchiveObj
                                ? this.props.selectedArchiveObj.encounter_duration
                                : null
                        }
                        start_timestamp={
                            this.props.selectedArchiveObj ? this.props.selectedArchiveObj.start_timestamp : null
                        }
                        soapnotespdf={
                            this.props.selectedArchiveObj ? this.props.selectedArchiveObj.soapnotespdf : ""
                        }
                        signatureURL={
                            this.props.selectedArchiveObj ? this.props.selectedArchiveObj.signatureURL : ""
                        }
                        licenseNo={this.props.selectedArchiveObj ? this.props.selectedArchiveObj.licenseNo : ""}
                    />
                </div>
            </Aux>
        );
    }
}

const mapStateToProps = ({
                             archivesReducer,
                             acceptingReducer,
                             doctorReducer,
                             clinicProfileSettingReducer,
                         }) => {
    const {
        loading,
        archives,
        load_messages,
        archives_messages,
        archive_doctorName,
        archive_doctorImg,
        lastTimeStamp,
        previous_length,
        isSearch,
        isLast,
        archive_patientEmail,
        archive_patientName,
        archive_patientCity,
        archive_patientCountry,
        archive_platform,
        allArchives,
        archive_patientDOB,
        archive_patientGender,
        archive_patientMedications,
        archive_userID,
        archive_patientChatCount,
        archive_patientBuildNumber,
        archive_conversationID,
        archive_complete_address,
        archive_commentAdded,
        archive_patientFollowUpData,
        archive_patientMedicationData,
        averageSleep,
        currentSleep,
        heartRate,
        heightCm,
        heightFeet,
        stepCount,
        runningWalking,
        weightKg,
        weightLbs,
        periodStartDate,
        flowType,
        restingHeartRate,
        archive_patientSymptoms,
        archive_diagnosisValue,
        archive_opinionDescriptionValue,
        archive_findings,
        archive_assessment,
        archive_plan,
        archive_icd,
        archive_billingCode,
        archive_billingUnit,
        archive_timestamp,
        archive_prescriptions,
        archive_requisitions,
        archive_special_referals,
        archive_video_consult,
        archive_followUpDays,
        selectedArchiveObj,
        archive_healthCardNumber
    } = archivesReducer;

    const {
        clinicData,
        staffData,
        clinicId,
        clinic_name,
        full_name,
        address,
        province,
        city,
        country,
        phone,
        postal_code,
        fax,
        email_address,
        license_no,
        profile_image,
        signature,
        clinic_email_address,
        physician_user,
        message,
        userType,
        userProfile,
    } = clinicProfileSettingReducer;

    const {acceptingChat, changedDoctorKey} = acceptingReducer;
    const {doctorDetail} = doctorReducer;

    return {
        loading,
        archives,
        load_messages,
        archives_messages,
        archive_doctorName,
        archive_doctorImg,
        lastTimeStamp,
        previous_length,
        isSearch,
        isLast,
        archive_patientEmail,
        archive_patientName,
        archive_patientCity,
        archive_patientCountry,
        archive_platform,
        allArchives,
        archive_patientDOB,
        archive_patientGender,
        archive_patientMedications,
        archive_userID,
        archive_patientChatCount,
        archive_patientBuildNumber,
        archive_conversationID,
        archive_complete_address,
        archive_commentAdded,
        archive_patientFollowUpData,
        archive_patientMedicationData,
        averageSleep,
        currentSleep,
        heartRate,
        heightCm,
        heightFeet,
        stepCount,
        runningWalking,
        weightKg,
        weightLbs,
        periodStartDate,
        flowType,
        restingHeartRate,
        acceptingChat,
        changedDoctorKey,
        doctorDetail,
        archive_patientSymptoms,
        archive_diagnosisValue,
        archive_opinionDescriptionValue,
        archive_findings,
        archive_assessment,
        archive_plan,
        archive_icd,
        archive_billingCode,
        archive_billingUnit,
        archive_timestamp,
        archive_prescriptions,
        archive_requisitions,
        archive_special_referals,
        archive_video_consult,
        archive_followUpDays,
        clinicData,
        staffData,
        clinicId,
        clinic_name,
        full_name,
        address,
        province,
        city,
        country,
        phone,
        postal_code,
        fax,
        email_address,
        license_no,
        profile_image,
        signature,
        clinic_email_address,
        physician_user,
        message,
        userType,
        userProfile,
        selectedArchiveObj,
        archive_healthCardNumber
    };
};

export default connect(mapStateToProps, {
    fetchClinicalArchives,
    fetchPatientsClinicalArchives,
    fetchClinicalArchiveMessages,
    searchArchives,
    childChangedDoctor,
    checkStatusAccept,
    setAlgolia,
    resetArchiveMessages,
})(ClinicalArchivesBuilder);
