import  {Component, Fragment} from 'react';
import Aux from '../../../hoc/hocAux';
import {connect} from 'react-redux';
import classes from './ClinicalReportsBuilder.module.css';
import _ from 'lodash';
import {ENVIRONMENT} from '../../../environment';
import {fetchClinicalDoctors, fetchEncountersInfo, fetchReportsHistory} from '../../../actions/ReportsAction';
import {MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';
import {Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

var moment = require("moment");


class ClinicalReportsBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDateRange: false,
            showError: false,
            ErrorText: '',
            selectedDateRange: -1,
            selectedDateRangeFinal: -1,
            startDate: '',
            endDate: '',
            selectedDoctor: {},
            selectedDoctorFinal: {},
            showDoctorFilter: false,
            sendingMail: false,
            sendingMailError: false,
            sendingMailResponse: '',
            showSentEmail: false,
            copyToClipboard: false,
            showEncryptKey: false,
            lastEncryptKey: "",
            latestReportTimestamp: 0
        }
    }

    UNSAFE_componentWillMount() {
        this.props.fetchClinicalDoctors(this.props.clinicId, doctors => {
            this.props.fetchReportsHistory(this.props.clinicId);
            this.props.fetchEncountersInfo({
                clinicId: this.props.clinicId,
                doctor: {},
                dateRange: -1,
                doctors: doctors
            });
        });

    }

    copyKeyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(this.state.lastEncryptKey);
            this.setState({copyToClipboard: true});
        } catch (err) {
            this.setState({copyToClipboard: true});
            console.error('Failed to copy: ', err);
        }
    }

    sendClinicReport = () => {
        var params = 'doctorEmail=' + encodeURIComponent(this.props.staffData.staffEmail) + '&' +
            'doctor_id=' + encodeURIComponent(this.state.selectedDoctorFinal.uid ? this.state.selectedDoctorFinal.uid : 'all') + '&' +
            'startDate=' + encodeURIComponent((this.state.startDate) ? this.state.startDate : 'no-date') + '&' +
            'endDate=' + encodeURIComponent((this.state.endDate) ? this.state.endDate : 'no-date') + '&' +
            'selectRange=' + encodeURIComponent(this.state.selectedDateRangeFinal) + '&' +
            'doctorName=' + encodeURIComponent(this.props.full_name) + '&' +
            'clinicId=' + encodeURIComponent(this.props.clinicId) + '&' +
            'clinicName=' + encodeURIComponent(this.props.clinic_name);
        var self = this;
        let url = '';
        if (ENVIRONMENT === 'development') {
            url = "https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/sendClinicCSV?";
        } else {
            url = "https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/sendClinicCSV?";
        }
        fetch(url + params)
            .then(res => res.json())
            .then((data) => {
                this.setState({showSentEmail: true, sendingMail: false, sendingMailError: false});
                if (Boolean(data.response)) {
                    if (Boolean(data.response.filename) && Boolean(data.response.password)) {
                        this.setState({sendingMailResponse: `An encrypted report:"${data.response.filename}" with password: "${data.response.password}" has been generated and sent to you via email.`})
                    } else {
                        this.setState({sendingMailResponse: `An encrypted report has been generated and sent to you via email.`})
                    }
                } else {
                    this.setState({sendingMailResponse: `An encrypted report has been generated and sent to you via email.`})
                }
                this.props.fetchReportsHistory(this.props.clinicId);
                /* setTimeout(function(){
                    self.setState({showSentEmail:false,sendingMail:false,sendingMailError:false, sendingMailResponse:''});
                }, 3000); */
            })
            .catch((e) => {
                this.setState({
                    showSentEmail: true,
                    sendingMail: false,
                    sendingMailError: true,
                    sendingMailResponse: "Email failed to send due to some issue. Please try again later."
                });
                setTimeout(function () {
                    self.setState({
                        showSentEmail: false,
                        sendingMail: false,
                        sendingMailError: false,
                        sendingMailResponse: ''
                    });
                }, 3000);
                console.log("mail failed ", e);
            })

    }

    saveDateRange = () => {
        if (this.state.selectedDateRange === 7) {
            if (this.state.startDate === '' || this.state.endDate === '') {
                this.setState({showError: true, ErrorText: 'Please select start and end date to continue'});
            } else {
                var g1 = new Date(this.state.startDate);
                var g2 = new Date(this.state.endDate);

                if (g1.getTime() > g2.getTime()) {
                    var end = this.state.endDate;
                    var start = this.state.startDate;
                    this.setState({endDate: start, startDate: end});
                }
                this.setState({
                    showError: false,
                    ErrorText: '',
                    selectedDateRangeFinal: this.state.selectedDateRange,
                    showDateRange: false
                });
                this.fetchUpdates();
            }
        } else {
            this.fetchUpdates();
            this.setState({
                showError: false,
                ErrorText: '',
                selectedDateRangeFinal: this.state.selectedDateRange,
                showDateRange: false
            });
        }
    }
    cancelDateRange = () => {
        this.setState({
            showError: false,
            ErrorText: '',
            selectedDateRange: this.state.selectedDateRangeFinal,
            showDateRange: false,
            startDate: '',
            endDate: ''
        });
    }
    fetchUpdates = () => {
        this.props.fetchEncountersInfo({
            clinicId: this.props.clinicId,
            doctor: this.state.selectedDoctor,
            dateRange: this.state.selectedDateRange,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            doctors: this.props.clinical_doctors
        });
    }
    saveDoctorFilterChange = () => {
        this.setState({
            showError: false,
            ErrorText: '',
            selectedDoctorFinal: this.state.selectedDoctor,
            showDoctorFilter: false
        });
        this.fetchUpdates();
    }
    cancelDoctorFilterChange = () => {
        this.setState({
            showError: false,
            ErrorText: '',
            selectedDoctor: this.state.selectedDoctorFinal,
            showDoctorFilter: false
        });
    }

    render() {
        const headers = [
            {
                label: 'Full Name',
                field: 'name',
                sort: 'asc'
            },
            {
                label: '# of Encounters',
                field: 'encounter',
                sort: 'asc'
            }

        ];
        var data = [];

        if (Boolean(this.state.selectedDoctorFinal.uid)) {
            var selectedDoc = _.find(this.props.doctorArray, ["doctorID", this.state.selectedDoctorFinal.uid]);
            //delete selectedDoc.doctorID;
            data.push({name: selectedDoc.name, encounter: selectedDoc.encounter});
        } else if (this.props.doctorArray) {
            data = [];//this.props.doctorArray;
            for (var i = 0; i < this.props.doctorArray.length; i++) {
                data.push({name: this.props.doctorArray[i].name, encounter: this.props.doctorArray[i].encounter})
            }
        }
        var linechartdata = this.props.dateArray;
        if (linechartdata) {
            for (i = 0; i < linechartdata.length; i++) {
                delete linechartdata[i].timestamp;
            }
        }
        const clinical_doctors = this.props.clinical_doctors.map(doctor => {
            return (<div>
                <div key={doctor.uid} className={classes.dateFilterRows}><input type='radio'
                                                                                checked={this.state.selectedDoctor.uid === doctor.uid}
                                                                                onChange={() => {
                                                                                    this.setState({selectedDoctor: doctor})
                                                                                }}
                                                                                className={classes.dateFiltersRadio}/>{doctor.fullName}
                </div>
            </div>)
        });

        const history_reports = (this.props.history_reports) ? this.props.history_reports.map(history => {
            if (history.timestamp > this.state.latestReportTimestamp) {
                this.setState({latestReportTimestamp: history.timestamp, lastEncryptKey: history.password});
            }
            return (<div>
                <div key={history.uid} className={classes.reportsHistoryRow}>{history.filename} was send
                    to {history.doctorName !== 'weekly' ? history.doctorName : 'Administrator\'s email address'} at {moment(history.timestamp).format('YYYY-MM-DD')} at {moment(history.timestamp).format('HH-mm-ss')}.
                    Encryption key is {history.password}</div>
            </div>)
        }) : null;
        return (

            <Fragment>
                <Aux>
                    <div className={classes.top_bar}>
                        <div className={classes.header_doctor_name}>
                            <span>{this.props.full_name}</span>
                        </div>
                        <div className={classes.header_status}>
                            Status:
                            {this.props.acceptingChat && <span> Accepting Patients</span>}
                            {!this.props.acceptingChat &&
                            <span className={classes.notAcceptingChat}> Not Accepting Patients</span>}
                        </div>
                    </div>

                    <div className={this.state.showDateRange ? classes.overlay : classes.hide_overlay}
                         onClick={this.cancelDateRange}></div>
                    <div className={classes.dataRangeModal} style={{
                        transform: this.state.showDateRange ? 'translateY(0)' : 'translateY(-100vh)',
                        opacity: this.state.showDateRange ? '1' : '0',
                    }}>
                        <div className={classes.header}>Date Range</div>
                        <div className={classes.dateFilters}>
                            <div className={classes.dateFilterRows}><input type='radio'
                                                                           checked={this.state.selectedDateRange === 0}
                                                                           onChange={() => {
                                                                               this.setState({selectedDateRange: 0})
                                                                           }} className={classes.dateFiltersRadio}/>Most
                                recent date ({moment().format('LL')})
                            </div>
                            <div className={classes.dateFilterRows}><input type='radio'
                                                                           checked={this.state.selectedDateRange === 1}
                                                                           onChange={() => {
                                                                               this.setState({selectedDateRange: 1})
                                                                           }} className={classes.dateFiltersRadio}/>Last
                                7 days
                            </div>
                            <div className={classes.dateFilterRows}><input type='radio'
                                                                           checked={this.state.selectedDateRange === 2}
                                                                           onChange={() => {
                                                                               this.setState({selectedDateRange: 2})
                                                                           }} className={classes.dateFiltersRadio}/>Last
                                28 days
                            </div>
                            <div className={classes.dateFilterRows}><input type='radio'
                                                                           checked={this.state.selectedDateRange === 3}
                                                                           onChange={() => {
                                                                               this.setState({selectedDateRange: 3})
                                                                           }} className={classes.dateFiltersRadio}/>Last
                                3 months
                            </div>
                            <div className={classes.dateFilterRows}><input type='radio'
                                                                           checked={this.state.selectedDateRange === 4}
                                                                           onChange={() => {
                                                                               this.setState({selectedDateRange: 4})
                                                                           }} className={classes.dateFiltersRadio}/>Last
                                6 months
                            </div>
                            <div className={classes.dateFilterRows}><input type='radio'
                                                                           checked={this.state.selectedDateRange === 5}
                                                                           onChange={() => {
                                                                               this.setState({selectedDateRange: 5})
                                                                           }} className={classes.dateFiltersRadio}/>Last
                                12 months
                            </div>
                            <div className={classes.dateFilterRows}><input type='radio'
                                                                           checked={this.state.selectedDateRange === 6}
                                                                           onChange={() => {
                                                                               this.setState({selectedDateRange: 6})
                                                                           }} className={classes.dateFiltersRadio}/>Last
                                16 months
                            </div>
                            <div className={classes.dateFilterRows}><input type='radio'
                                                                           checked={this.state.selectedDateRange === 7}
                                                                           onChange={() => {
                                                                               this.setState({selectedDateRange: 7})
                                                                           }} className={classes.dateFiltersRadio}/>Custom
                            </div>
                        </div>
                        {this.state.selectedDateRange === 7 &&
                        <div className={classes.dateFiltersInputSection}>
                            <div className={classes.dateFiltersInputSubSection}>
                                <span className={classes.dateFilterSubSectionText}>Start Date</span>
                                <input type='date'
                                       onChange={(e) => {
                                           this.setState({startDate: e.target.value})
                                       }}
                                       value={this.state.startDate}
                                       className={classes.dateRangeInput}/>
                            </div>
                            <div className={classes.dateFiltersInputSubSection}><span
                                className={classes.dateFilterSubSectionText1}>-</span></div>
                            <div className={classes.dateFiltersInputSubSection}>
                                <span className={classes.dateFilterSubSectionText}>End Date</span>
                                <input type='date'
                                       onChange={(e) => {
                                           this.setState({endDate: e.target.value})
                                       }}
                                       value={this.state.endDate}
                                       className={classes.dateRangeInput}/>
                            </div>
                        </div>
                        }
                        {this.state.showError &&
                        <div className={classes.batchUploadErrorText}>{this.state.ErrorText}</div>}
                        <div className={classes.addPatientButtonView}>
                            <div className={classes.saveButton} onClick={this.saveDateRange}>Apply</div>
                            <div className={classes.cancelButton} onClick={this.cancelDateRange}>Cancel</div>
                        </div>
                    </div>

                    <div className={this.state.showDoctorFilter ? classes.overlay : classes.hide_overlay}
                         onClick={this.cancelDoctorFilterChange}></div>
                    <div className={classes.dataRangeModal} style={{
                        transform: this.state.showDoctorFilter ? 'translateY(0)' : 'translateY(-100vh)',
                        opacity: this.state.showDoctorFilter ? '1' : '0',
                    }}>
                        <div className={classes.header}>Doctors</div>
                        <div className={classes.doctorFilters}>
                            <div key={'all'} className={classes.dateFilterRows}><input type='radio'
                                                                                       checked={!Boolean(this.state.selectedDoctor.uid)}
                                                                                       onChange={() => {
                                                                                           this.setState({selectedDoctor: {}})
                                                                                       }}
                                                                                       className={classes.dateFiltersRadio}/>All
                            </div>
                            {clinical_doctors}
                        </div>

                        {this.state.showError &&
                        <div className={classes.batchUploadErrorText}>{this.state.ErrorText}</div>}
                        <div className={classes.addPatientButtonView}>
                            <div className={classes.saveButton} onClick={this.saveDoctorFilterChange}>Apply</div>
                            <div className={classes.cancelButton} onClick={this.cancelDoctorFilterChange}>Cancel</div>
                        </div>
                    </div>
                    <div className={this.state.showSentEmail ? classes.overlay : classes.hide_overlay}
                         onClick={() => this.setState({showSentEmail: false})}></div>
                    <div className={classes.responseModal} style={{
                        transform: this.state.showSentEmail ? 'translateY(0)' : 'translateY(-100vh)',
                        opacity: this.state.showSentEmail ? '1' : '0',
                    }}>
                        <div
                            className={classes.emailFeedback}>{this.state.sendingMail ? "Processing the report, Please wait..." : this.state.sendingMailResponse}</div>
                    </div>
                    <div className={this.state.showEncryptKey ? classes.overlay : classes.hide_overlay}
                         onClick={() => this.setState({showEncryptKey: false, copyToClipboard: false})}></div>
                    <div className={classes.dataRangeModal} style={{
                        transform: this.state.showEncryptKey ? 'translateY(0)' : 'translateY(-100vh)',
                        opacity: this.state.showEncryptKey ? '1' : '0',
                    }}>
                        <div className={classes.reportHeader}>Report History</div>
                        <div className={classes.reportsSectionScrollable}>
                            {history_reports}
                        </div>
                        <span className={classes.reportPopUpBottomText}>viewing last 10 reports</span>
                        {this.state.copyToClipboard && <div className={classes.copyClipboardSuccess}>Copied!</div>}
                        <div className={classes.reportsButtonView}>
                            <div className={classes.saveButton} onClick={this.copyKeyToClipboard}>Copy Last Encryption
                                Key
                            </div>
                            <div className={classes.cancelButton}
                                 onClick={() => this.setState({showEncryptKey: false, copyToClipboard: false})}>Close
                            </div>
                        </div>


                    </div>
                    <div className={classes.main}>
                        <div className={classes.head_buttons}>
                            <span className={classes.top_cancel_button} onClick={() => {
                                this.setState({showEncryptKey: true})
                            }}>View Report History</span>
                            <span className={classes.add_patient_button} onClick={() => {
                                this.setState({sendingMail: true, showSentEmail: true});
                                this.sendClinicReport();
                            }}>Send Clinic Report</span>
                        </div>
                        <div className={classes.performance_table}>
                            <div className={classes.performance_section}>
                                <span className={classes.performance_section_text}>Performance</span>
                            </div>
                            <div className={classes.filter_section}>
                                <span className={classes.filter_section_text}>Filters: </span><span
                                className={classes.filter_button} onClick={() => {
                                this.setState({showDoctorFilter: true})
                            }}>Doctors: {!Boolean(this.state.selectedDoctorFinal.uid) ? 'All' : this.state.selectedDoctorFinal.doctorName}</span><span
                                className={classes.filter_button} onClick={() => {
                                this.setState({showDateRange: true})
                            }}>Date: {this.state.selectedDateRangeFinal === -1 ? 'Last 30 days' : this.state.selectedDateRangeFinal === 0 ? moment().format('LL') : this.state.selectedDateRangeFinal === 1 ? 'Last 7 days' : this.state.selectedDateRangeFinal === 2 ? 'Last 28 days' : this.state.selectedDateRangeFinal === 3 ? 'Last 3 months' : this.state.selectedDateRangeFinal === 4 ? 'Last 6 months' : this.state.selectedDateRangeFinal === 5 ? 'Last 12 months' : this.state.selectedDateRangeFinal === 6 ? 'Last 16 months' : moment(this.state.startDate).format('LL') + ' - ' + moment(this.state.endDate).format('LL')}</span>
                            </div>
                            <div className={classes.encounter_section}>
                                <div className={classes.encounter_sub_section}>
                                    <span className={classes.encounter_section_text}>Total Encounter: </span>
                                    <span
                                        className={classes.encounter_section_value}>{this.props.numberOfConversations}</span>
                                </div>
                            </div>
                            <div>
                                <div className={classes.chart_label}>Encounters</div>
                                <div>
                                    <ResponsiveContainer width="100%" height={250}>
                                        <LineChart data={linechartdata}
                                                   margin={{top: 25, right: 30, left: 20, bottom: 5}}>
                                            <XAxis dataKey="name"/>
                                            <YAxis/>
                                            <Tooltip/>
                                            <Legend/>
                                            <Line dataKey="Encounters" stroke="#7B1FA2"/>
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                        <div className={classes.patients_table}>
                            <MDBTable style={{borderRadius: "8px !important"}}>
                                <MDBTableHead style={{
                                    backgroundColor: "#E3E3EA",
                                    fontSize: 17,
                                    fontFamily: "HarmoniaSansStd-SemiBd",
                                    borderRadius: "8px !important"
                                }} columns={headers}/>
                                <MDBTableBody rows={data}/>
                            </MDBTable>
                        </div>
                    </div>
                </Aux>
            </Fragment>
        );
    }
}

const mapStateToProps = ({acceptingReducer, doctorReducer, reportReducer, clinicProfileSettingReducer}) => {

    const {
        clinicData, staffData, clinicId, clinic_name, full_name, address, province, city, country, phone, postal_code,
        fax, email_address, license_no, profile_image, signature, clinic_email_address, physician_user,
        loading, message, userType, userProfile
    } = clinicProfileSettingReducer;
    const {acceptingChat} = acceptingReducer;
    const {doctorDetail} = doctorReducer;
    const {doctorArray, dateArray, numberOfConversations, clinical_doctors, history_reports} = reportReducer;


    return {
        acceptingChat, doctorDetail, doctorArray, dateArray, numberOfConversations, clinical_doctors, history_reports,
        clinicData, staffData, clinicId, clinic_name, full_name, address, province, city, country, phone, postal_code,
        fax, email_address, license_no, profile_image, signature, clinic_email_address, physician_user,
        loading, message, userType, userProfile
    };
};

export default connect(mapStateToProps, {
    fetchEncountersInfo,
    fetchClinicalDoctors,
    fetchReportsHistory
})(ClinicalReportsBuilder);
