import {
    ADMIN_PROFILE_SETTING_FAILED,
    ADMIN_PROFILE_SETTING_PROCESS,
    ADMIN_PROFILE_SETTING_SUCCESS,
    CLINIC_ADMIN_ADDRESS,
    CLINIC_ADMIN_NAME,
    CLINIC_ADMIN_PHONE_NUMBER,
    CLINIC_ADMIN_PROFILE_IMAGE,
    CLINIC_ADMIN_SIGNATURE,
    CLINIC_CITY,
    CLINIC_COUNTRY,
    CLINIC_DR_BILLING_NO,
    CLINIC_EMAIL_ADDRESS,
    CLINIC_FAX,
    CLINIC_LICENSE_NO,
    CLINIC_PHYSICIAN_USER,
    CLINIC_POSTAL_CODE,
    CLINIC_PROVINCE,
    CLINIC_REPORT_EMAIL_ADDRESS,
    FULL_NAME,
    GET_CLINIC_DATA_FAILED,
    GET_CLINIC_DATA_SUCCESS,
    LISTEN_CLINIC_DATA,
    STAFF_PHONE_NUMBER,
} from '../constants';

const INITIAL_STATE = {
    userType: '', userEmail: '', userProfile: '', loading: false, message: '', user: null,
    physician_user: false, clinicData: null, staffData: null, clinicId: null, clinic_name: '', full_name: '',
    address: '', city: '', province: '', country: '', phone: '', fax: '', postal_code: '', email_address: '',
    license_no: '', profile_image: null, signature: null, clinic_email_address: '', mailDuration: '',
    DOBFormat: '', EncounterDateFormat: '', EHRClinicId: '', EHRScannerId: '', staffPhoneNumber: '', dr_billing_no: ''
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_CLINIC_DATA_SUCCESS:
            return {
                ...state,
                clinicData: action.payload.clinicData,
                staffData: action.payload.staffData,
                userEmail: action.payload.staffData.email !== undefined ? action.payload.staffData.email : action.payload.staffData.staffEmail,
                userType: action.payload.userType,
                userProfile: action.payload.userProfile,
                clinicId: action.payload.clinicID,
                full_name: action.payload.staffData.fullName,
                clinic_name: action.payload.clinicData.clinicName,
                address: action.payload.clinicData.clinicAddress,
                province: action.payload.clinicData.clinicProvince,
                city: action.payload.clinicData.clinicCity,
                country: action.payload.clinicData.clinicCountry,
                phone: action.payload.clinicData.clinicPhoneNumber,
                fax: action.payload.clinicData.clinicFaxNumber,
                postal_code: action.payload.clinicData.clinicPostalCode,
                email_address: action.payload.clinicData.clinicEmail,
                staffPhoneNumber: action.payload.staffData.phoneNumber !== undefined ? action.payload.staffData.phoneNumber : '',
                license_no: action.payload.staffData.licenseNo !== undefined ? action.payload.staffData.licenseNo : '',
                dr_billing_no: action.payload.staffData.billingNumber !== undefined ? action.payload.staffData.billingNumber : '',
                profile_image: action.payload.staffData.profileImageURL !== undefined ? action.payload.staffData.profileImageURL : null,
                signature: action.payload.staffData.signatureImageURL !== undefined ? action.payload.staffData.signatureImageURL : null,
                clinic_email_address: action.payload.clinicData.clinicEmailAddress ? action.payload.clinicData.clinicEmailAddress : '',
                physician_user: action.payload.staffData.physician !== undefined ? action.payload.staffData.physician : false,
                mailDuration: action.payload.clinicData.mailDuration !== undefined ? action.payload.clinicData.mailDuration : '',
                DOBFormat: action.payload.clinicData.DOBFormat !== undefined ? action.payload.clinicData.DOBFormat : '',
                EncounterDateFormat: action.payload.clinicData.EncounterDateFormat !== undefined ? action.payload.clinicData.EncounterDateFormat : '',
                EHRClinicId: action.payload.clinicData.EHRClinicId !== undefined ? action.payload.clinicData.EHRClinicId : '',
                EHRScannerId: action.payload.clinicData.EHRScannerId !== undefined ? action.payload.clinicData.EHRScannerId : '',

            };
        case GET_CLINIC_DATA_FAILED:
            return {...state, clinicData: null};
        case CLINIC_ADMIN_NAME:
            return {...state, clinic_name: action.payload};
        case FULL_NAME:
            return {...state, full_name: action.payload};
        case CLINIC_ADMIN_ADDRESS:
            return {...state, address: action.payload};
        case CLINIC_PROVINCE:
            return {...state, province: action.payload};
        case CLINIC_CITY:
            return {...state, city: action.payload};
        case CLINIC_POSTAL_CODE:
            return {...state, postal_code: action.payload};
        case CLINIC_COUNTRY:
            return {...state, country: action.payload};
        case CLINIC_ADMIN_PHONE_NUMBER:
            return {...state, phone: action.payload};
        case STAFF_PHONE_NUMBER:
            return {...state, staffPhoneNumber: action.payload};
        case CLINIC_FAX:
            return {...state, fax: action.payload};
        case CLINIC_EMAIL_ADDRESS:
            return {...state, email_address: action.payload};
        case CLINIC_PHYSICIAN_USER:
            return {...state, physician_user: action.payload};
        case CLINIC_LICENSE_NO:
            return {...state, license_no: action.payload};
        case CLINIC_DR_BILLING_NO:
            return {...state, dr_billing_no: action.payload};
        case CLINIC_REPORT_EMAIL_ADDRESS:
            return {...state, clinic_email_address: action.payload};
        case CLINIC_ADMIN_PROFILE_IMAGE:
            return {...state, profile_image: action.payload};
        case CLINIC_ADMIN_SIGNATURE:
            return {...state, signature: action.payload};
        case ADMIN_PROFILE_SETTING_PROCESS:
            return {...state, loading: true, message: ''};
        case ADMIN_PROFILE_SETTING_SUCCESS:
            return {...state, loading: false, message: action.payload};
        case ADMIN_PROFILE_SETTING_FAILED:
            return {...state, loading: false, message: action.payload};
        case LISTEN_CLINIC_DATA:
            return {
                ...state,
                phone: action.payload.clinicPhoneNumber,
                clinic_name: action.payload.clinicName,
                address: action.payload.clinicAddress,
                city: action.payload.clinicCity,
                province: action.payload.clinicProvince,
                country: action.payload.clinicCountry,
                fax: action.payload.clinicFaxNumber,
                postal_code: action.payload.clinicPostalCode,
            }
        default:
            return state;
    }
}
