import React, {Fragment, useEffect, useState} from "react";
import classes from "../../containers/chatBuilder/ChatBuilder.module.css";
import TextareaAutosize from "react-autosize-textarea";
import Spinner from "../Spinner";
import {ENVIRONMENT} from "../../environment";
import axios from "axios";
import {connect} from "react-redux";
import { sendDoctorFirstMessage} from "../../actions/ChatAction";
import {saveTagInChat, transferChatToAnotherDoctor, startAgoraCall} from "../../actions/ConversationAction";
import {firebaseNorthEastRoot, firebaseRoot} from "../../config";
import {database} from "../../firebase";
import moment from "moment";

const AgentList = (props) => {
    return (
        <li>
            <div className={classes.main_assign_view}>
                <div className={classes.main_assign_inner_view}>
                    <img src={props.profileImageURL}
                         alt="//cdn.livechatinc.com/cloud/?uri=https://livechat.s3.amazonaws.com/default/avatars/female_44.jpg"/>
                    <span className={classes.online_style}></span>
                    <div className={classes.main_assign_content}>
                        <div className={classes.name_text}>{props.name}</div>
                        <div className={classes.email_text}>{props.email}</div>
                    </div>
                    <div className={classes.main_transfer_button}>
                        <button onClick={() => props.onAssignClick(props.doctor)}>Transfer Chat</button>
                    </div>
                </div>
            </div>
        </li>
    )
}

const ChatPanelHeader = (props) => {

    const { closeChat, transfer_doctors,
        sendDoctorFirstMessage, conversationId, tags,
        symptoms, doctorIsValid,
        admin} = props
    const [showOnlineDoctors, setShowOnlineDoctors] = useState(false);

    const [showSMSModal, setShowSMSModal] = useState(false);
    const [showCallModal, setShowCallModal] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showChat, setShowChat] = useState(false)
    const [DOB, setDOB] = useState(null)
    const [gender, setGender] = useState(null)
    const [name, setName] = useState(null)
    const [textMessage, setTextMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [callLoading, setCallLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState('')
    const [banTag, setBanTag] = useState({tag: null, uid: null, id: null})
    const [patientObj, setPatientObj] = useState(null)
    const [patientSymptom, setPatientSymptom] = useState(null)
    const [patientId, setPatientId] = useState(null);
    const [adminDoctor, setAdminDoctor] = useState(null);
    const [noPayment, setNoPayment] = useState(false);
    const [headerText, setHeaderText] = useState("" );
    const transferChat = ()=>{
        setShowOnlineDoctors(!showOnlineDoctors)
    }

    useEffect(() => {

        let newHeaderText = "";
        if(props.selectedConversationObj?.doNotCharge){
            if(props.selectedConversationObj?.partner_id === 7){
                newHeaderText = `Orchyd User. Do not charge for prescriptions.`;
            } else if(props.selectedConversationObj?.subscriptionType === "yearly"){
                newHeaderText = `Yearly plan user. Do not charge for prescriptions and medical note.`;
            } else if(props.selectedConversationObj?.subscriptionType === "monthly"){
                newHeaderText = `Monthly plan user. Do not charge for prescriptions.`;
            } else if(props.selectedConversationObj?.subscriptionType === "quarterly"){
                newHeaderText = `Quarterly plan user. Do not charge for prescriptions.`;
            }else if(props.selectedConversationObj?.isPhysioPatient){
                newHeaderText = `Physio patient, Do not charge for prescriptions.`;
            }else if(props?.selectedConversationObj?.payAfterChat){
                newHeaderText = `Trial plan user, Do not charge for prescriptions.`;
                if (props?.selectedConversationObj?.checkoutSessionID){
                    if (props?.selectedConversationObj?.paymentCompleted) {
                        newHeaderText += ` User has paid, complete submission.`;
                    }else{
                        newHeaderText += ` Payment pending.`;
                    }
                }
            }else if(props.selectedConversationObj?.fromConsumable){
                if (props.selectedConversationObj?.freePrescription && props.selectedConversationObj?.freeMedicalNote){
                    newHeaderText = `Single visit user. Do not charge for prescriptions and medical note.`;
                } else if (props.selectedConversationObj?.freePrescription){
                    newHeaderText = `Single visit user. Do not charge for prescriptions.`;
                } else if (props.selectedConversationObj?.freeMedicalNote){
                    newHeaderText = `Single visit user. Do not charge for medical note.`;
                }
            }

        }
        setHeaderText(newHeaderText)
    }, [props.selectedConversationObj]);

    useEffect(()=>{
        if(props.selectedPatient && props.patientId){
            //if(props.selectedPatient !== patientObj){
                const myPatient = props.selectedPatient;
                const selectedConvo = props.selectedConversationObj;
                console.log("selectedConvo ", selectedConvo);
                setAdminDoctor(selectedConvo?.adminDoctor || null);
                setNoPayment(selectedConvo?.partner_id === 7 || selectedConvo?.doNotCharge);


                let selectOwner = null;
                if(selectedConvo?.owner && selectedConvo?.owner !== props.patientId){
                    selectOwner = selectedConvo?.owner;
                    console.log("selectOwner ", selectOwner)
                    const { city,country,platform,email,convoCount,buildNumber,medication, profiles} = myPatient || {}
                    const {DOB, gender, name, userName } = profiles?profiles[selectOwner] || {} : {}

                    setGender(gender)
                    setName(name || userName)
                    setShowChat(!!(gender || city || country || platform || email || convoCount || buildNumber || medication))
                    let dob= DOB ? DOB.replace(' ', ',').replace(' ', ',') : "NA";
                    if (dob !== "NA") {
                        // let timeNow = new Date().getFullYear();
                        // let ageInYears = timeNow - new Date(dob).getFullYear();
                        let ageInYears = moment().diff(moment(dob, "MMMM,DD,YYYY"), "years");
                        dob = dob + " (" + ageInYears + ")";
                        setDOB(dob)
                    }
                }else{
                    const {DOB, gender, city, name, userName,country,platform,email,convoCount,buildNumber,medication, profiles} = myPatient || {}
                    setShowChat(!!(gender || city || country || platform || email || convoCount || buildNumber || medication));
                    setGender(gender)
                    setName(name || userName)
                    let dob= DOB ? DOB.replace(' ', ',').replace(' ', ',') : "NA";
                    if (dob !== "NA") {
                         // let timeNow = new Date().getFullYear();
                        // let ageInYears = timeNow - new Date(dob).getFullYear();
                        let ageInYears = moment().diff(moment(dob, "MMMM,DD,YYYY"), "years");
                        dob = dob + " (" + ageInYears + ")";
                        setDOB(dob)
                    }
                }

                /*const {DOB, gender, city,country,platform,email,convoCount,buildNumber,medication, profiles} = myPatient || {}
                setShowChat(!!(gender || city || country || platform || email || convoCount || buildNumber || medication))
                let dob= DOB ? DOB.replace(' ', ',').replace(' ', ',') : "NA";
                if (dob !== "NA") {
                    let timeNow = new Date().getFullYear();
                    let ageInYears = timeNow - new Date(dob).getFullYear();
                    dob = dob + " (" + ageInYears + ")";
                    setDOB(dob)
                }*/
                setPatientSymptom(props.selectedConversationObj?.issue || symptoms || myPatient?.symptoms || "")
                setPatientObj(myPatient)
            //}
        }
    },[props.patientId, props.selectedPatient, props.selectedConversationObj])

    useEffect(()=>{
        if(patientId !== props.patientId) setPatientId(props.patientId)
    },[props.patientId])

    useEffect(()=>{
        setPhoneNumber(patientObj?.phone_number || '')
    },[patientObj, showSMSModal])


    const agentslist = transfer_doctors.map(agent => {
        let type = props.selectedConversationObj?.isEMRChat? "health-card": "private-pay";
        // console.log("agentslist type ", type)
        // console.log("agentslist doctorChatType ",agent.doctorName," ", agent.doctorChatType)
        if (agent !== undefined && type === agent.doctorChatType) {
            return <AgentList
                key={agent.uid}
                doctor={agent}
                profileImageURL={agent.profileImageURL}
                patientName={agent.doctorName}
                email={agent.email}
                onAssignClick={(doctor)=>{
                    if(props.callData?.conversationId && props.callData?.conversationId === props.conversationId){
                        alert("Please end the call first!")
                        return;
                    }
                    props.transferChatToAnotherDoctor(
                        props.conversationId,
                        doctor,
                        true,
                        props.admin,
                        patientObj?.name || patientObj?.userName || 'Patient'
                    );
                    props.onAssignClick();
                }}
            />
        } else {
            // eslint-disable-next-line array-callback-return

        }
    });
    const onHideAddAgentModal = () => {
        setShowSMSModal(false)
    }
    const renderSMSModal = () => {

        return <div
            className={classes.SMSModal}
            style={{
                transform: showSMSModal ? 'translateY(0)' : 'translateY(-100vh)',
                opacity: showSMSModal ? '1' : '0'
            }}
        >
            <div >
                <div className={classes.SOAP_div}>
                    <span className={classes.SOAP_heading}>Phone Number:</span>
                    <input
                        className={classes.SOAP_textarea}
                        style={{minHeight:45}}
                        placeholder="Phone Number"
                        value={phoneNumber}
                        onChange={(e)=>setPhoneNumber(e.target.value)}

                    />
                </div>
                <div className={classes.SOAP_div}>
                    <span className={classes.SOAP_heading}>Message:</span>
                    <TextareaAutosize
                        className={classes.SOAP_textarea}
                        placeholder="Start typing here..."
                        onChange={(e) => setTextMessage(e.target.value)}
                        value={textMessage}
                    />
                </div>
                {errorMessage && <span style={{
                    fontSize: 14,
                    color: "#a30707"
                }}>{errorMessage}</span>}
                {loading?<div className={classes.modalButtons}>
                    <Spinner color={"black"}  loading/>
                </div>:<div className={classes.modalButtons}>

                    <button className={classes.SaveModalButton}
                            onClick={() => {
                                setErrorMessage('')
                                if(!phoneNumber){
                                    setErrorMessage("Please enter the phone number")
                                    return
                                }
                                if(!textMessage){
                                    setErrorMessage("Please enter the message")
                                    return
                                }
                                setLoading(true)
                                let cloudFunctionURL = ENVIRONMENT === "production"? "https://us-central1-yourdoctorsonline-prod.cloudfunctions.net" :"https://us-central1-yourdoctorsonline-staging.cloudfunctions.net"
                                let data = {
                                    "phoneNumberTo": phoneNumber,
                                    "messageText": textMessage
                                }
                                axios.post(cloudFunctionURL+'/sendTextMessage', data).then((data)=>{
                                    sendDoctorFirstMessage(conversationId, textMessage)

                                    setLoading(false)
                                    setShowSMSModal(false)
                                }).catch(error=>{
                                    setLoading(false)
                                    setErrorMessage(error?.message)
                                })
                            }}>Send SMS and Close
                    </button>
                    <button className={classes.cancelModalButton}
                            onClick={() => {setShowSMSModal(false); setTextMessage('');setPhoneNumber('')}}>Cancel
                    </button>
                </div>
                }


            </div>
        </div>
    }

    const onCall = async (callType) => {

        setCallLoading(true)
        if(props.callData?.conversationId && props.conversationId !== props.callData?.conversationId ){
            alert("please close the existing call to start a new call");
            setCallLoading(false)
            setShowCallModal(false)
            return;
        }
        if(props.callData?.conversationId){
            setCallLoading(false)
            setShowCallModal(false)
            return;
        }
        await database.ref(`callLogs/${props.conversationId}`).once('value', async callLogsSnap =>{

            try{
                let callLogs = callLogsSnap.exists()? callLogsSnap.toJSON() : {};
                let doctorToken = null
                let patientToken = null
                let deeplink =  null

                if(!doctorToken){
                    const doctorTokenResponse = await axios({
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        url: `${firebaseNorthEastRoot}/doctorDashboard-getAgoraToken`,
                        mode: 'cors',
                        data: JSON.stringify({
                            uid: 1,
                            channelName: props.conversationId
                        }),
                    })
                    doctorToken = doctorTokenResponse.data?.rctToken
                }
                if(!patientToken){
                    const patientTokenResponse = await  axios({
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        url: `${firebaseNorthEastRoot}/doctorDashboard-getAgoraToken`,
                        mode: 'cors',
                        data: JSON.stringify({
                            uid: 2,
                            channelName: props.conversationId
                        }),
                    })
                    patientToken = patientTokenResponse.data?.rctToken
                }
                let notificationPayload = {
                    "notificationToken":props?.selectedPatient?.notificationToken,
                    "data":{
                        "name" : props?.doctorDetail?.doctorName,
                        "conversationID": props?.conversationId || null,
                        "profileImageUrl" : props?.doctorDetail?.profileImageURL,
                        "ReminderType": callType,
                        "channelToken" : patientToken,
                        "channelName" : props.conversationId,
                        "uid" : "2"
                    }
                }
                let voipNotificationPayload = {
                    "production": process.env.NODE_ENV !== 'development',
                    "token": props?.selectedPatient?.voipToken,
                    "payload": {
                        "name": props?.doctorDetail?.doctorName,
                        "conversationID": props?.conversationId || null,
                        "profileImageUrl": props?.doctorDetail?.profileImageURL,
                        "reminderType": callType,
                        "channelToken": patientToken,
                        "channelName": props.conversationId,
                        "uid": "2"
                    }
                }
                if(props?.conversationId){
                    if(!deeplink /*&& (props?.selectedPatient?.platform).toLowerCase() === "web"*/){
                        const deeplinkPayload = {
                            "domain": "https://app.yourdoctors.online/",
                            "params": {
                                "channelToken": encodeURIComponent(patientToken),
                                "channelName": encodeURIComponent(props.conversationId),
                                "uid": "2",
                                "type": encodeURIComponent(callType),
                                "conversationId": encodeURIComponent(props.conversationId),
                                "name": encodeURIComponent(props?.doctorDetail?.doctorName),
                                "profileImageUrl": encodeURIComponent(props?.doctorDetail?.profileImageURL)

                            }
                        };
                        console.log("deeplinkPayload ", JSON.stringify(deeplinkPayload))
                        const deeplinkResponse = await axios({
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            url: `${firebaseNorthEastRoot}/generateDeepLink`,
                            mode: 'cors',
                            data: JSON.stringify(deeplinkPayload),
                        })
                        let domain = "http://localhost:3002/";
                        let keys = Object.keys(deeplinkPayload.params);
                        let values = Object.values(deeplinkPayload.params);
                        let redirectUrl = domain;
                        if (keys.length > 0) {
                            redirectUrl += '?';
                        }
                        keys.forEach((key, index) => {
                            redirectUrl += `${key}=${encodeURIComponent(values[index])}`;
                            if (index !== keys.length - 1) {
                                redirectUrl += '&';
                            }
                        });
                        console.log({redirectUrl})
                        deeplink = deeplinkResponse?.data?.data?.referralLink;

                    }

                    const content = `Hi I'm waiting for you in the call, please join and complete your consult`
                    let messageValue = {
                        type: callType,
                        content: content,
                        fromID: props?.selectedConversationObj?.doctorID || props?.doctorDetail?.uid || null,
                        toID: props?.selectedConversationObj?.patientID,
                        isRead: false,
                        doctorName: props?.doctorDetail?.doctorName,
                        doctorImgURL: props?.doctorDetail?.profileImageURL,
                        additionalData: {
                            "channelToken": patientToken,
                            "channelName": props.conversationId,
                            "uid": "2",
                            "deeplink": deeplink
                        },
                        timestamp: Date.now()
                    };
                    let newMessageKey = database.ref(`conversations/${props?.conversationId}`).push().key;

                    let updates = {};
                    updates[`/conversations/${props?.conversationId}/${newMessageKey}`] = messageValue;
                    updates[`/callLogs/${props?.conversationId}/status`] = "initiated";
                    updates[`/callLogs/${props?.conversationId}/patientChannelToken`] = patientToken;
                    updates[`/callLogs/${props?.conversationId}/channelName`] = props.conversationId;
                    updates[`/callLogs/${props?.conversationId}/patientUid`] = "2";
                    updates[`/callLogs/${props?.conversationId}/doctorName`] = props?.doctorDetail?.doctorName;
                    updates[`/callLogs/${props?.conversationId}/profileImageUrl`] = props?.doctorDetail?.profileImageURL;
                    updates[`/callLogs/${props?.conversationId}/doctorChannelToken`] = doctorToken;
                    updates[`/callLogs/${props?.conversationId}/doctorUid`] = "1";
                    updates[`/callLogs/${props?.conversationId}/hostStatus`] = "available";
                    updates[`/callLogs/${props?.conversationId}/deeplink`] = deeplink;
                    updates[`/callLogs/${props?.conversationId}/callType`] = callType;
                    updates[`/callLogs/${props?.conversationId}/events/${Date.now()}`] = "initiated";
                    await database
                        .ref()
                        .update(updates)
                    /*if((props?.selectedPatient?.platform).toLowerCase() === "web"){
                        const textMessagePayload = {
                            "phoneNumberTo": props?.selectedPatient?.phone_number,
                            "messageText": content
                        };
                        axios({
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            url: `${firebaseRoot}/sendTextMessage`,
                            mode: 'cors',
                            data: JSON.stringify(textMessagePayload),
                        }).then((data)=>{}).catch((error)=>console.log("error ", error))
                    }*/
                }

                if((props?.selectedPatient?.platform).toLowerCase() !== "ios"){
                    if(props?.selectedPatient?.notificationToken){
                        console.log("getAgoraToken notificationPayload", JSON.stringify(notificationPayload))
                        setTimeout(async ()=>{
                            await axios({
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                url: `${firebaseRoot}/customCloudMessage`,
                                mode: 'cors',
                                data: JSON.stringify(notificationPayload),
                            })
                        },3000)

                    }

                }
                else if(props?.selectedPatient?.voipToken){
                    console.log("getAgoraToken notificationPayload", JSON.stringify(voipNotificationPayload))
                    await axios({
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        url: `${firebaseNorthEastRoot}/callNotificationVOIP`,
                        mode: 'cors',
                        data: JSON.stringify(voipNotificationPayload),
                    })
                }

                props.startAgoraCall({rctToken: doctorToken,channelName: props.conversationId, callType:callType === "audioCall"?"audio": "video", conversationId: props.conversationId})
                setCallLoading(false)
                setShowCallModal(false)


            }catch (e) {
                setCallLoading(false)
                console.log("e", e)
                alert(e.message || "Call failed! Please try again")
            }
        })



    }

    const renderCallModal = () => {

        return <div
            className={classes.SMSModal}
            style={{
                transform: showCallModal ? 'translateY(0)' : 'translateY(-100vh)',
                opacity: showCallModal ? '1' : '0'
            }}
        >
            {callLoading?<div className={classes.callsButton}>
                <Spinner color={"white"}  loading/>
            </div>:<div className={classes.callButtonsSection}>
                <div className={classes.SOAP_div}>
                    <a href={`https://ydo-call.web.app/${(patientObj?.phone_number || phoneNumber) ? `?phoneNumber=${patientObj?.phone_number || phoneNumber}` : ""}`}
                       target='_blank'
                       className={classes.callsButton}
                       onClick={() => setShowCallModal(false)}
                    ><span>Phone Call</span></a>
                </div>
                <div className={classes.SOAP_div}>
                    <div onClick={()=>onCall("audioCall")} className={classes.callsButton}
                    ><span>In-app Call</span></div>
                </div>
                <div className={classes.SOAP_div}>
                    <div onClick={()=>onCall("videoCall")} className={classes.callsButton}
                    ><span>Video Call</span></div>
                </div>
                <div className={classes.SOAP_div}>
                    <div onClick={()=>{setCallLoading(false)
                        setShowCallModal(false)}} className={classes.callsButton}
                    ><span>Cancel</span></div>
                </div>
            </div>}
        </div>
    }

    // eslint-disable-next-line array-callback-return
    /*var rate_tag = props.tags.find(tag => {
        if (tag !== undefined) {
            if (tag.tag === 'rating_screen') {
                return tag;
            }
        }
    });*/
    const onTagClick = (name, id, uid) => {
        if (props.tagChatList.length !== 0) {
            const found = props.tagChatList.some((item) => item.id === uid);
            console.log("saveTagInChat found ", found)
            console.log("saveTagInChat uid ", uid)
            if (!found) {
                props.saveTagInChat(props.conversationId, {
                    id: uid,
                    name: name,
                });
            }
        } else {
            props.saveTagInChat(props.conversationId, {
                id: uid,
                name: name,
            });
        }
    };

    useEffect(()=>{
        if(tags){
            let ban_tag = tags.find(tag => {
                if (tag !== undefined) {
                    if (tag.tag === 'ban') {
                        return tag;
                    }
                }
            });
            if (!ban_tag) {
                ban_tag = {tag: null, uid: null, id: null}
            }
            console.log("saveTagInChat ban_tag ", ban_tag)
            setBanTag(ban_tag);

        }
    },[tags])

    /*if (!rate_tag) {
        rate_tag = {tag: null, uid: null, id: null}
    }*/


    return (
        <Fragment>
            <div className={classes.list_header}>
                {showChat &&
                <div className={classes.msg_header}>
                    <div className={classes.scrollable}>
                        <div className={classes.info_row}>
                            {adminDoctor && <div className={classes.col_4}><span
                                className={classes.header_attr}>Supervising Doctor:</span> <span
                                className={classes.header_attr_value}> {adminDoctor}</span></div>}
                            {noPayment && <div className={classes.col_4}><span
                                className={classes.header_attr_value_red}> {headerText}</span></div>}
                        </div>
                        <div className={classes.info_row}>
                            <div className={classes.col_4}><span className={classes.header_attr}>Patient:</span> <span
                                className={classes.header_attr_value}> {name || ""}</span></div>
                            <div className={classes.col_4}><span className={classes.header_attr}>D.O.B:</span> <span
                                className={classes.header_attr_value}> {DOB}</span></div>
                            <div className={classes.col_4}><span className={classes.header_attr}>Gender:</span> <span
                                className={classes.header_attr_value}> {gender || ""}</span></div>
                        </div>
                        <div className={classes.info_row}>
                            <div className={classes.col_6}><span className={classes.header_attr}>Issue:</span> <span
                                className={classes.header_attr_value}>{patientSymptom}</span></div>
                        </div>
                    </div>
                </div>
                }
                {!showChat &&
                <Fragment>
                    <div className={classes.msg_header_default}>
                        <span className={classes.header_text}>Select a chat from left pane</span>
                    </div>
                </Fragment>
                }
                <div className={classes.info_section_col}>
                    {(doctorIsValid || admin) &&
                    <Fragment>
                        {showChat &&
                        <div className={classes.header_transfer}
                             onClick={() => {setShowSMSModal(true)}}>
                            <span>SMS Patient</span>
                        </div>
                        }
                        {showChat && <>
                            { props?.selectedConversationObj?.callEnabled? <a onClick={() => {
                                if(!admin)setShowCallModal(true)
                            }}
                                className={classes.header_rate}
                            ><span>Call Patient</span></a>:
                                <a href={`https://ydo-call.web.app/${(patientObj?.phone_number || phoneNumber) ? `?phoneNumber=${patientObj?.phone_number || phoneNumber}` : ""}`}
                                     target='_blank'
                                     className={classes.header_rate}
                            ><span>Call Patient</span></a>
                                /*<div className={classes.header_transfer}><CallWithTwilio phoneNumber={patientObj?.phone_number || phoneNumber} /></div>*/
                            }
                        </>}
                        {showChat &&
                        <div className={classes.header_banUser}
                             onClick={transferChat}>
                            <span>Transfer Chat</span>
                        </div>
                        }

                        {/*{showChat &&
                        <div className={classes.header_banUser}
                             onClick={() => onTagClick(banTag.tag, banTag.id, banTag.uid)}>
                            <span>Ban User For Spam</span>
                        </div>
                        }*/}

                        {showChat && <div className={classes.header_close} onClick={() => {
                            return alert("Please use PHD to close chat!");
                                if(props.callData?.conversationId && props.callData?.conversationId === props.conversationId){
                                    alert("Please end the call first!")
                                    return;
                                }
                                closeChat();
                            }}>
                                <span>End Chat</span>
                            </div>
                        }
                    </Fragment>
                    }
                </div>
            </div>
            {showOnlineDoctors && agentslist.length !== 0 &&
            <div className={classes.one}>
                <div className={classes.assign_list_view}>
                    <div className={classes.main_assign_list}>
                        <ul>
                            {agentslist}
                        </ul>
                    </div>
                </div>
            </div>
            }
            {showOnlineDoctors && agentslist.length === 0 &&
            <div className={classes.one}>
                <div className={classes.assign_list_view}>
                    <div className={classes.main_assign_list}>
                        <p>No other doctor is online</p>
                    </div>
                </div>
            </div>
            }
            {renderSMSModal()}
            {renderCallModal()}
        </Fragment>

    )
}
const mapStateToProps = ({
                             conversationReducer,
                             tagsReducer,
                             doctorReducer
                        }) => {
    const {
        conversationId,
        doctorIsValid,
        transfer_doctors,
        admin,
        tagChatList,
        patientId,
        selectedPatient,
        selectedConversationObj,
        callData
    } = conversationReducer;

    const {doctorDetail} = doctorReducer;
    const { tags } = tagsReducer;
    return {
        transfer_doctors, conversationId, tags, doctorIsValid,tagChatList,
        admin,patientId, selectedPatient, selectedConversationObj, doctorDetail, callData
    };
};
export default connect(mapStateToProps, {sendDoctorFirstMessage, saveTagInChat, transferChatToAnotherDoctor, startAgoraCall})(ChatPanelHeader);
