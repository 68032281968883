import  {Component} from 'react';
import {connect} from 'react-redux';

import Body from './body';

import classes from '../../../../assets/classes.module.css';

import {
    clinic_billing_code_changed,
    clinic_billing_code_desc_changed,
    clinic_deleteBillingCode,
    clinic_fetchBillingCodes,
    clinic_save_billing_code,
    clinic_updateBillingCode
} from '../../../../actions/clinic/setting/ClinicBillingCodesAction';

class BillingCodes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeLink: 'billing_codes',
            selectedEditTag: null,
            selectedTagDesciption: '',
            selectedTagShortCut: '',
            selectedDeleteTag: null,
            showCannedResponsesItems: false,
        }
    }

    componentDidMount() {
        this.props.clinic_fetchBillingCodes(this.props.clinicId);
    }

    onChangeCannedDescription = (event) => {
        this.setState({selectedTagDesciption: event.target.value});
    }

    onChangeCannedShortCut = (event) => {
        this.setState({selectedTagShortCut: event.target.value});
    }

    onEditClick = (uid, desc, shortcut) => {
        this.setState({selectedEditTag: uid, selectedTagDesciption: desc, selectedTagShortCut: shortcut});
    }

    updateCannedResponse = (uid, desc, shortcut) => {
        var self = this;
        this.props.clinic_updateBillingCode(this.props.clinicId, uid, desc, shortcut, function (status) {
            self.setState({selectedEditTag: null, selectedTagDesciption: '', selectedTagShortCut: ''});
        });
    }

    updateCancelClick = () => {
        this.setState({selectedEditTag: null, selectedTagDesciption: '', selectedTagShortCut: ''});
    }

    onDeleteClick = (uid) => {
        this.setState({selectedDeleteTag: uid});
    }

    confirmDeleteClick = (uid) => {
        var self = this;
        this.props.clinic_deleteBillingCode(this.props.clinicId, uid, function (status) {
            self.setState({selectedDeleteTag: uid});
        });

    }

    confirmCancelClick = () => {
        this.setState({selectedDeleteTag: null});
    }

    onBillingCodeTextChange = (event) => {
        this.props.clinic_billing_code_changed(event.target.value);
    }

    onBillingCodeDescriptionChange = (event) => {
        this.props.clinic_billing_code_desc_changed(event.target.value);
    }

    onSaveBillingCode = (event) => {
        event.preventDefault();
        if (this.props.clinic_billing_code_value !== '' && this.props.clinic_billing_code_desc_value !== '') {
            this.props.clinic_save_billing_code(this.props.clinicId, this.props.clinic_billing_code_value, this.props.clinic_billing_code_desc_value);
        } else {
            alert("Please check dilling codes input.");
        }
    }


    render() {
        const {clinic_billing_code_value, clinic_billing_code_desc_value, clinic_billing_codes} = this.props;

        const {selectedTagDesciption, selectedTagShortCut, selectedEditTag, selectedDeleteTag} = this.state;
        return (
            <div className={classes.main_container}>
                <div className={classes.main_container_header}>Create billing codes to document each encounter.</div>
                <div className={classes.form_container_view}>
                    <Body
                        billingCodesList={clinic_billing_codes}
                        billing_code={clinic_billing_code_value}
                        billing_code_desc={clinic_billing_code_desc_value}
                        selectedTagDesciption={selectedTagDesciption}
                        selectedTagShortCut={selectedTagShortCut}
                        selectedEditTag={selectedEditTag}
                        selectedDeleteTag={selectedDeleteTag}
                        onBillingCodeTextChange={(text) => this.onBillingCodeTextChange(text)}
                        onBillingCodeDescriptionChange={(text) => this.onBillingCodeDescriptionChange(text)}
                        onSaveBillingCode={(event) => this.onSaveBillingCode(event)}
                        onEditClick={(uid, desc, shortcut) => this.onEditClick(uid, desc, shortcut)}
                        onDeleteClick={(uid) => this.onDeleteClick(uid)}
                        onChangeCannedShortCut={(event) => this.onChangeCannedShortCut(event)}
                        onChangeCannedDescription={(event) => this.onChangeCannedDescription(event)}
                        updateCannedResponse={(uid, desc, shortcut) => this.updateCannedResponse(uid, desc, shortcut)}
                        updateCancelClick={() => this.updateCancelClick()}
                        confirmDeleteClick={(uid) => this.confirmDeleteClick(uid)}
                        confirmCancelClick={() => this.confirmCancelClick()}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({clinicProfileSettingReducer, clinicBillingCodesReducer}) => {

    const {clinicId, userType} = clinicProfileSettingReducer;

    const {clinic_billing_code_value, clinic_billing_code_desc_value, clinic_billing_codes} = clinicBillingCodesReducer;

    return {
        clinicId, userType,
        clinic_billing_code_value, clinic_billing_code_desc_value, clinic_billing_codes
    };
}

export default connect(mapStateToProps, {
    clinic_billing_code_changed, clinic_billing_code_desc_changed,
    clinic_save_billing_code, clinic_fetchBillingCodes, clinic_updateBillingCode, clinic_deleteBillingCode
})(BillingCodes);
