import {Fragment, useEffect, useState} from 'react';
import classes from './AddAgents.module.css';
import Switch from 'react-switch'
import {database} from "../../firebase";
import {DELETE_DOCTOR_SUCCESS} from "../../constants";

const defaultImage = 'https://firebasestorage.googleapis.com/v0/b/yourdoctorsonline-staging.appspot.com/o/DoctorsProfileImage%2Fdownload.png?alt=media&token=cf3b29d0-a67e-430b-b317-c0b7002ce098';

const AgentsDetails = (props) => {
    const [chatTypeOptions, setChatTypeOptions] = useState([
        {value: "--", displayValue: 'Please select doctor chat type'},
        {value: "health-card", displayValue: "Health Card"},
        {value: "private-pay", displayValue: "Private Pay"}
    ])
    const [doctorChatType, setDoctorChatType] = useState(null)
    const [chatcount, setChatCount] = useState(0)

    useEffect(()=>{
        let type = chatTypeOptions.find(option=> option.value === props.doctorData?.doctorChatType)
        setDoctorChatType(type || {value: "--", displayValue: 'Please select doctor chat type'})
        setChatCount(props.doctorData.conversations !== null && props.doctorData.conversations !== undefined ? Object.keys(props.doctorData.conversations).length : 0)
    },[props.doctorData])

    const onDoctorChatTypeChange = (event) => {
        let type = chatTypeOptions.find(option=> option.value === event.target.value)
        if(props?.doctorData?.uid){
            let updates = {};
            updates[`/doctors/${props?.doctorData?.uid}/doctorChatType`] = type?.value;
            database.ref().update(updates)
                .then(() => {

                })
            setDoctorChatType( type || event.target.value)
        }


    }

    if (props.selectedAgent !== null) {
        return (
            <div className={classes.detail}>
                <div className={classes.header}>Details</div>
                <div className={classes.detail_info}>
                    <div className={classes.profile}>
                        <div className={classes.profile_info}>
                            <div className={classes.avatar_container}>
                                <div className={classes.profile_avatar}>
                                    <img
                                        className={classes.profile_avatar_view}
                                        src={
                                            props.doctorData.profileImageURL !== undefined &&
                                            props.doctorData.profileImageURL !== null ?
                                                props.doctorData.profileImageURL : defaultImage
                                        }
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className={classes.profile_schema}>
                                <div className={classes.profile_name}>{props.doctorData.doctorName}
                                    <div className={classes.edit_icon}
                                         onClick={() => props.updateDoctorDetail(props.selectedAgent)}>
                                        <svg fill="#4384f5" width="16px" height="16px" viewBox="0 0 18 19">
                                            <g>
                                                <path fill="inherit"
                                                      d="M13.313 6L10.5 3.187l-7.5 7.5V13.5h2.813l7.5-7.5zm2.22-2.22a.747.747 0 0 0 0-1.057L13.777.968a.747.747 0 0 0-1.058 0l-1.47 1.47 2.813 2.812 1.47-1.47z"></path>
                                                <path fill="inherit" d="M0 15.75h18v3H0z"></path>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                <div className={classes.doctor_email}>{props.doctorData.email}
                                    <div className={
                                        props.doctorData.role === 'Admin' ? classes.admin_role_dt :
                                            props.doctorData.role === 'Doctor' && props.doctorData.clinicalDoctor ?
                                                classes.clinic_doctor_role_dt : classes.doctor_role_dt
                                    }>{props.doctorData.clinicalDoctor ? 'Clinical Doctor' : props.doctorData.role}</div>
                                </div>
                                <div
                                    className={classes.doctor_category}>{props.doctorData.jobTitle !== undefined && props.doctorData.jobTitle !== null ? props.doctorData.jobTitle : ''}</div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.additional} onClick={() => props.hideInfo()}>
                        <div className={props.showingInfo === true ? classes.chevron : classes.chevron_rotated}>
                            <svg fill="#4384F5" width="12px" height="8px" viewBox="0 0 12 8">
                                <path fill="inherit" fillRule="evenodd"
                                      d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                            </svg>
                        </div>
                        <div style={{width: 20}}></div>
                        Additional Info
                    </div>

                    {props.showingInfo &&
                    <div className={classes.groups_info}>
                        <div className={classes.groups_category}>
                            <div className={classes.groups_member}>
                                <div className={classes.additional_1}>Status after login:</div>
                                {props.acceptStatus === true &&
                                <div className={classes.additional_status}>Accepting Patients</div>
                                }
                                {props.acceptStatus === false &&
                                <div className={classes.additional_status}>Not Accepting Patients</div>
                                }
                            </div>
                        </div>
                        {/*<div className={classes.groups_category}>
                                <div className={classes.groups_member}>
                                    <div className={classes.additional_1}>Active Free Chats:</div>
                                    <div className={classes.additional_status}>{props.doctorData.onlineFreeChats} chats</div>
                                </div>
                            </div>
                            <div className={classes.groups_category}>
                                <div className={classes.groups_member}>
                                    <div className={classes.additional_1}>Active Paid Chats:</div>
                                    <div className={classes.additional_status}>{props.doctorData.onlinePaidChats} chats</div>
                                </div>
                            </div>
                            <div className={classes.groups_category}>
                                <div className={classes.groups_member}>
                                    <div className={classes.additional_1}>Free Chat Limit:</div>
                                    <div className={classes.additional_status}>{props.doctorData.freeChatLimit} chats</div>
                                </div>
                            </div>
                            <div className={classes.groups_category}>
                                <div className={classes.groups_member}>
                                    <div className={classes.additional_1}>Paid Chat Limit:</div>
                                    <div className={classes.additional_status}>{props.doctorData.paidChatLimit} chats</div>
                                </div>
                            </div>*/}
                        <div className={classes.groups_category}>
                            <div className={classes.groups_member}>
                                <div className={classes.additional_1}>Doctor Chat Type:</div>
                                <div className={classes.additional_status}>{props.doctorData.doctorChatType === "health-card"? "Health Card": props.doctorData.doctorChatType === "private-pay"? "Private Pay": "Unset"}</div>
                            </div>
                        </div>
                        <div className={classes.groups_category}>
                            <div className={classes.groups_member}>
                                <div className={classes.additional_1}>Total Chat Limit:</div>
                                <div className={classes.additional_status}>{props.doctorData.totalChatLimit} chats</div>
                            </div>
                        </div>
                    </div>
                    }

                    <div className={classes.additional} onClick={() => props.hidePerformance()}>
                        <div className={props.showingPerformance === true ? classes.chevron : classes.chevron_rotated}>
                            <svg fill="#4384F5" width="12px" height="8px" viewBox="0 0 12 8">
                                <path fill="inherit" fillRule="evenodd"
                                      d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                            </svg>
                        </div>
                        <div style={{width: 20}}></div>
                        Performance
                    </div>

                    {props.showingPerformance &&
                    <Fragment>
                        <div className={classes.groups_info}>
                            <div className={classes.groups_category}>
                                <div className={classes.groups_member}>
                                    <div className={classes.chat_icon}>
                                        <svg fill="rgba(66,77,87,0.6)" viewBox="0 0 24 24">
                                            <g fill="none" fillRule="evenodd">
                                                <path fill="#4384F5" fillRule="nonzero"
                                                      d="M12.333.333H1.667C.933.333.333.933.333 1.667v12L3 11h9.333c.734 0 1.334-.6 1.334-1.333v-8c0-.734-.6-1.334-1.334-1.334z"></path>
                                                <path d="M-1-1h16v16H-1z" opacity=".7"></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className={classes.chat_count}>Total Chats:</div>
                                    <div className={classes.chat_count_status}>{chatcount}</div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                    }

                    {props.doctorData.role === "Doctor" && props.doctorData.profileStatus !== 'deleted' && props.doctorData.online &&
                    <div className={classes.detail_bottom_div}>
                        <label className={classes.label_style}>
                            <span className={classes.span_style}>Accept Patients </span>
                            <span>
                                <Switch
                                    checked={props.acceptStatus}
                                    onChange={() => props.acceptStatusChanged(props.acceptStatus)}
                                    onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={30}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="react-switch"
                                />
                            </span>
                        </label>
                    </div>}
                    {props.doctorData.role === "Doctor" && props.doctorData.profileStatus !== 'deleted' &&
                    <div className={classes.detail_bottom_div}>
                        <div className={classes.del_doctor} onClick={() => props.deleteDoctor(props.doctorData)}>
                            Deactivate Doctor
                        </div>
                    </div>}
                    {props.doctorData.role === "Doctor" && props.doctorData.profileStatus === 'deleted' &&
                    <div className={classes.detail_bottom_div}>
                        <div className={classes.activate_doctor} onClick={() => props.activateDoctor(props.doctorData)}>
                            Activate Doctor
                        </div>
                    </div>}
                    {props.doctorData.role === "Doctor" && props.doctorData.profileStatus !== 'deleted' &&
                    <div className={classes.detail_bottom_div}>
                        <label className={classes.label_style}>Chat Type</label>
                        <select
                            className={classes.span_style}
                            value={doctorChatType?.value}
                            onChange={(event) => onDoctorChatTypeChange(event)}>
                            {chatTypeOptions.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.displayValue}
                                </option>
                            ))}
                        </select>
                    </div>}
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default AgentsDetails;
