import {Component} from 'react'
import _style from '../../../../assets/_classes.module.css'
import isMobileView from '../../../../utils/isMobileView';
import _Header from '../../../../components/UI/Header/Header'
import PrescriptionForm from './prescriptionForm'
import {FaAngleLeft, FaAngleRight} from "react-icons/fa";
import {updateInsuranceCard} from '../../../../actions/ClinicalChatsAction';
import {connect} from 'react-redux';
import {Transition} from 'react-transition-group'
import {IoMdExit} from 'react-icons/io'

const duration = 1000
const sidebarStyle = {
    transition: `width ${duration}ms`
}
const sidebarTransitionStyles = {
    entering: {width: 0},
    entered: {width: 270},
    exiting: {width: 270},
    exited: {width: 0}
}
const linkStyle = {
    transition: `width ${duration}ms`,
    transition: `opacity ${duration}ms`
}
const linkTransitionStyles = {
    entering: {opacity: 0, width: 0},
    entered: {opacity: 1, width: window.innerWidth},
    exiting: {opacity: 1, width: window.innerWidth},
    exited: {opacity: 0, width: 0}
}


const InputItem = (props) => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', margin: '15px 0px 0px 0px'}}>
            <label className={_style.inputItemLabel}>{props.label}</label>
            {(props.type === 'file' || props.type === 'labelOnly') ? null :
                <input className={_style.inputItem} {...props} placeholder={props.placeholder} value={props.value || ''}
                       onChange={(e) => props.onChange(e)}></input>}

        </div>
    );
}

class Sidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showSidebarModal: true,
            selectedSidebarItem: ''
        }
    }

    componentDidMount() {
        let {healthInsuranceCardNumber, healthInsuranceCardFront, healthInsuranceCardBack} = this.props;
        this.setState({
            // showInsuranceEditModal: true,
            insuranceNumber: healthInsuranceCardNumber,
            insuranceFront: healthInsuranceCardFront,
            insuranceBack: healthInsuranceCardBack,
        });
    }

    onLogoutClick = () => {
        window.localStorage.setItem('logged_in', false);
        const {staffData, clinicId} = this.props;
        this.props.logoutClinicMember(clinicId, staffData.uid);
    }
    goBack = () => {
        // this.setState({ showSidebarModal: false });
        if (this.state.selectedSidebarItem !== '') {
            this.setState({selectedSidebarItem: ''})
        } else {
            this.props.toggleSidebar(false)
        }

    }
    showInsuranceModal = () => {
        let {healthInsuranceCardNumber, healthInsuranceCardFront, healthInsuranceCardBack} = this.props;
        this.setState({
            showInsuranceEditModal: true,
            insuranceNumber: healthInsuranceCardNumber,
            insuranceFront: healthInsuranceCardFront,
            insuranceBack: healthInsuranceCardBack,
        });
    }

    insuranceNumberChange = (event) => {
        this.setState({insuranceNumber: event.target.value});
    }

    insuranceFrontUrl = (event) => {
        this.setState({insuranceFront: event.target.value});
    }

    insuranceBackUrl = (event) => {
        this.setState({insuranceBack: event.target.value});
    }

    saveInsuranceCard = () => {
        let {insuranceNumber, insuranceFront, insuranceBack} = this.state;
        var self = this;
        this.props.updateInsuranceCard(this.props.patientFirebaseID, insuranceNumber, insuranceFront, insuranceBack, function (status) {
            if (status) {
                self.setState({showInsuranceEditModal: false});
            }
        });
    }
    _sidebar = (props) => {
        return <>

            {!Boolean(props.isClinicalArchive) && <div className={_style.sidebar_item}
                                                       onClick={() => this.setState({selectedSidebarItem: 'Insurance Information'})}>
                <p>Insurance Information</p>
                <FaAngleRight onClick={() => this.setState({selectedSidebarItem: 'Insurance Information'})}>
                </FaAngleRight>
            </div>}
            <div className={_style.sidebar_item}
                 onClick={() => this.setState({selectedSidebarItem: 'General Information'})}>
                <p>General Information</p>
                <FaAngleRight onClick={() => this.setState({selectedSidebarItem: 'General Information'})}>
                </FaAngleRight>
            </div>
            {/* <div className={_style.sidebar_item} onClick={() => this.setState({ selectedSidebarItem: 'Past Encounters' })}>
                <p>Past Encounters</p>
                <FaAngleRight onClick={() => this.setState({ selectedSidebarItem: 'Past Encounters' })}>
                </FaAngleRight>
            </div>
            <div className={_style.sidebar_item} onClick={() => this.setState({ selectedSidebarItem: 'Patient Follow up' })}>
                <p>Patient Follow up</p>
                <FaAngleRight onClick={() => this.setState({ selectedSidebarItem: 'Patient Follow up' })}>
                </FaAngleRight>
            </div>
            <div className={_style.sidebar_item} onClick={() => this.setState({ selectedSidebarItem: 'Create SOAP Notes' })}>
                <p>Create SOAP Notes</p>
                <FaAngleRight onClick={() => this.setState({ selectedSidebarItem: 'Create SOAP Notes' })}>
                </FaAngleRight>
            </div>*/}
            {!Boolean(props.isClinicalArchive) && Boolean(this.props.staffData.createPrescription) &&
            <div className={_style.sidebar_item} onClick={() => this.setState({selectedSidebarItem: 'Prescriptions'})}>
                <p>Prescriptions</p>
                <FaAngleRight onClick={() => this.setState({selectedSidebarItem: 'Prescriptions'})}>
                </FaAngleRight>
            </div>}
            {/* <div className={_style.sidebar_item} onClick={() => this.setState({ selectedSidebarItem: 'Medical Notes' })}>
                <p>Medical Notes</p>
                <FaAngleRight onClick={() => this.setState({ selectedSidebarItem: 'Medical Notes' })}>
                </FaAngleRight>
            </div> */}
            <div className={_style.sidebar_logout_btn_wrapper}>
                {/* <button className={_style.sidebar_btn}
                style={{ margin: '40px' }}
                onClick={() => this.saveInsuranceCard()}
            >Save</button> */}
                {/* <IoMdExit style={{ justifySelf: 'flex-start' }} color="#884a9d" size={30} /> */}
                {Boolean(this.props.hideEndEncounter) ? null : <button className={_style.sidebar_logout_btn}
                                                                       style={{}}
                                                                       onClick={() => this.props.closeChat()}><IoMdExit
                    style={{justifySelf: 'flex-start', marginRight: '10px'}} color="#6D7278" size={25}/>End Patient
                    Encounter</button>
                }
            </div>
        </>
    }
    _insuranceInformation = () => {
        return <div style={{width: '100%'}}>
            <InputItem type="labelOnly" label='Insurance #:'></InputItem>
            <label className={_style.inputItemLabel} style={{
                fontWeight: 'normal',
                color: 'black',
                'margin-top': '0px',
                'margin-bottom': '0px'
            }}>{this.props.healthInsuranceCardNumber}</label>
            <InputItem type="labelOnly" label='Front View'></InputItem>
            <label className={_style.inputItemLabel} style={{
                textDecorationLine: 'underline',
                fontWeight: 'normal',
                color: 'black',
                'margin-top': '0px',
                'margin-bottom': '0px'
            }}><a href={this.props.healthInsuranceCardFront} target="_blank" rel="noopener noreferrer" alt=""
                  style={{color: 'black'}}>
                <span>View Image</span>
            </a></label>
            <InputItem type="labelOnly" label='Back View:'></InputItem>
            <label className={_style.inputItemLabel} style={{
                textDecorationLine: 'underline',
                fontWeight: 'normal',
                color: 'black',
                'margin-top': '0px',
                'margin-bottom': '0px',
                width: '100%'
            }}><a href={this.props.healthInsuranceCardBack} target="_blank" rel="noopener noreferrer" alt=""
                  style={{color: 'black'}}>
                <span>View Image</span>
            </a></label>
            <button className={_style.sidebar_btn}
                    style={{margin: '40px'}}
                    onClick={() => this.showInsuranceModal()}
            >Edit
            </button>
        </div>
    }
    _editInsuranceInformation = () => {
        return <div>
            <InputItem
                label='Insurance #:'
                placeholder={this.state.insuranceNumber}
                property='insuranceNumber'
                value={this.state.insuranceNumber}
                onChange={(event) => this.insuranceNumberChange(event)}
            />

            <InputItem
                label='Front View'
                placeholder={this.state.insuranceFront}
                property='insuranceFront'
                value={this.state.insuranceFront}
                onChange={(event) => this.insuranceFrontUrl(event)}
            />

            <InputItem
                label='Back View:'
                placeholder={this.state.insuranceBack}
                property='insuranceBack'
                value={this.state.insuranceBack}
                onChange={(event) => this.insuranceBackUrl(event)}
            />

            <button className={_style.sidebar_btn}
                    style={{margin: '40px'}}
                    onClick={() => this.saveInsuranceCard()}
            >Save
            </button>
            <button className={_style.sidebar_transparent_btn}
                    style={{margin: '40px', marginTop: '0px'}}
                    onClick={() => this.setState({showInsuranceEditModal: false})}
            >Cancel
            </button>
        </div>
    }
    _generalInformation = () => {
        let date = new Date();
        let gmtOffset = (this.props.gmtOffset) ? this.props.gmtOffset : 0;
        let utc = date.getTime() + (date.getTimezoneOffset() * 60000);
        let current_time = (new Date(utc + (3600000 * gmtOffset))).toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
        return <div>
            <InputItem type="labelOnly" label='Phone #:'></InputItem>
            <label className={_style.inputItemLabel}
                   style={{fontWeight: 'normal', color: 'black', 'margin-top': '0px', 'margin-bottom': '0px'}}>
                {this.props.patient_phone_number || 'N/A'}</label>
            <InputItem type="labelOnly" label='Address:'></InputItem>
            <label className={_style.inputItemLabel}
                   style={{fontWeight: 'normal', color: 'black', 'margin-top': '0px', 'margin-bottom': '0px'}}>
                {this.props.complete_address}</label>
            <InputItem type="labelOnly" label='Local Time:'></InputItem>
            <label className={_style.inputItemLabel} style={{
                fontWeight: 'normal',
                color: 'black',
                'margin-top': '0px',
                'margin-bottom': '0px'
            }}>{current_time}</label>
            <InputItem type="labelOnly" label='# of Prior Encounters:'></InputItem>
            <label className={_style.inputItemLabel}
                   style={{fontWeight: 'normal', color: 'black', 'margin-top': '0px', 'margin-bottom': '0px'}}>
                {this.props.convoCount}</label>
            <InputItem type="labelOnly" label='Device:'></InputItem>
            <label className={_style.inputItemLabel}
                   style={{fontWeight: 'normal', color: 'black', 'margin-top': '0px', 'margin-bottom': '0px'}}>
                {this.props.platform.toUpperCase()}</label>
            <InputItem type="labelOnly" label='Chat Session ID:'></InputItem>
            <label className={_style.inputItemLabel}
                   style={{fontWeight: 'normal', color: 'black', 'margin-top': '0px', 'margin-bottom': '0px'}}>
                {this.props.selectedConversation}</label>


        </div>
    }
    _prescriptions = () => {
        return <div>
            {/* <InputItem
                label='Medication'
                placeholder={''}
                property='insuranceNumber'
                value={this.state.insuranceNumber}
                onChange={(event) => this.insuranceNumberChange(event)}
            />

            <InputItem
                label='Dose'
                placeholder={''}
                property='insuranceFront'
                value={this.state.insuranceFront}
                onChange={(event) => this.insuranceFrontUrl(event)}
            />

            <InputItem
                label='Quantity'
                placeholder={''}
                property='insuranceBack'
                value={this.state.insuranceBack}
                onChange={(event) => this.insuranceBackUrl(event)}
            />
            <InputItem
                label='Repeats'
                placeholder={''}
                property='insuranceBack'
                value={this.state.insuranceBack}
                onChange={(event) => this.insuranceBackUrl(event)}
            />  <InputItem
                label='LU Code'
                placeholder={''}
                property='insuranceBack'
                value={this.state.insuranceBack}
                onChange={(event) => this.insuranceBackUrl(event)}
            />

            <label className={_style.inputItemLabel} style={{ 'margin-bottom': '0px' }} >Side Effects / Instructions</label>
            <textarea

                className={_style.inputItem}
                rows="5"
                style={{ width: '225px' }}
                onClick={() => { }}
            />
            <button className={_style.sidebar_btn}
                style={{ margin: '40px', marginBottom: '10px', backgroundColor: '#f00' }}
                onClick={() => this.setState({ selectedSidebarItem: '' })}
            >Delete</button>
            <button className={_style.sidebar_transparent_btn}
                style={{ margin: '40px', marginTop: '0px' }}
                onClick={() => this.setState({ showInsuranceEditModal: false })}
            >Add Medication</button>
            <button className={_style.sidebar_btn}
                style={{ margin: '40px', marginBottom: '10px', marginTop: '20px' }}
                onClick={() => this.setState({ showInsuranceEditModal: false })}
            >Save & Close</button>
            <button className={_style.sidebar_transparent_btn}
                style={{ margin: '40px', marginTop: '0px', marginBottom: '100px' }}
                onClick={() => this.setState({ showInsuranceEditModal: false })}
            >Cancel</button> */}
            <PrescriptionForm {...this.props} goBack={() => this.goBack()}></PrescriptionForm>
        </div>
    }
    _medicalNotes = () => {
        return <div style={{'margin-top': '10px', 'margin-bottom': '10px'}}>
            <div style={{'margin-top': '5px', 'margin-bottom': '0px', display: 'flex', flex: 1}}>
                <input type="radio" id="male" name="gender" value="male"/>
                <label for="male" className={_style.inputItemLabel}
                       style={{fontWeight: 'normal', color: 'black', 'margin-top': '0px', 'margin-bottom': '0px'}}>states
                    that he was unable to work/attend school for medical reasons indicated below</label>
            </div>
            <div style={{'margin-top': '5px', 'margin-bottom': '0px', display: 'flex', flex: 1}}><input type="radio"
                                                                                                        id="male"
                                                                                                        name="gender"
                                                                                                        value="male"/>
                <label for="male" className={_style.inputItemLabel}
                       style={{fontWeight: 'normal', color: 'black', 'margin-top': '0px', 'margin-bottom': '0px'}}>was
                    unable to work/attend school for medication reasons indicated below</label>
            </div>
            <div style={{'margin-top': '5px', 'margin-bottom': '0px', display: 'flex', flex: 1}}><input type="radio"
                                                                                                        id="male"
                                                                                                        name="gender"
                                                                                                        value="male"/>
                <label for="male" className={_style.inputItemLabel}
                       style={{fontWeight: 'normal', color: 'black', 'margin-top': '0px', 'margin-bottom': '0px'}}>may
                    return to work/school/daycare without restrictions</label>
            </div>
            <div style={{'margin-top': '5px', 'margin-bottom': '0px', display: 'flex', flex: 1}}><input type="radio"
                                                                                                        id="male"
                                                                                                        name="gender"
                                                                                                        value="male"/>
                <label for="male" className={_style.inputItemLabel}
                       style={{fontWeight: 'normal', color: 'black', 'margin-top': '0px', 'margin-bottom': '0px'}}>may
                    return to work/school/daycare with the following restrictions</label>
            </div>
            <div style={{'margin-top': '5px', 'margin-bottom': '0px', display: 'flex', flex: 1}}><input type="radio"
                                                                                                        id="male"
                                                                                                        name="gender"
                                                                                                        value="male"/>
                <label for="male" className={_style.inputItemLabel}
                       style={{fontWeight: 'normal', color: 'black', 'margin-top': '0px', 'margin-bottom': '0px'}}>was
                    absent from work/school due to illness in the family for dates indicated below</label>
            </div>
            <div style={{'margin-top': '5px', 'margin-bottom': '0px', display: 'flex', flex: 1}}><input type="radio"
                                                                                                        id="male"
                                                                                                        name="gender"
                                                                                                        value="male"/>
                <label for="male" className={_style.inputItemLabel}
                       style={{fontWeight: 'normal', color: 'black', 'margin-top': '0px', 'margin-bottom': '0px'}}>should
                    not participate in gym or sports for medical reasons for the dates indicated below.</label>
            </div>
            <div style={{'margin-top': '5px', 'margin-bottom': '0px', display: 'flex', flex: 1}}><input type="radio"
                                                                                                        id="male"
                                                                                                        name="gender"
                                                                                                        value="male"/>
                <label for="male" className={_style.inputItemLabel}
                       style={{fontWeight: 'normal', color: 'black', 'margin-top': '0px', 'margin-bottom': '0px'}}>is
                    advised not to return to work for medical reasons</label>
            </div>
            <label for="male" className={_style.inputItemLabel} style={{'margin-bottom': '0px'}}>Remarks</label>
            <textarea

                className={_style.inputItem}
                rows="5"
                style={{width: '225px'}}
                onClick={() => {
                }}
            />
            <div style={{'margin-top': '5px', 'margin-bottom': '0px', display: 'flex', flex: 1}}>
                <input
                    type="checkbox" name={'patients.uid'}
                    checked={Boolean(this.state.medicalNotesStartDate)}
                    onClick={() => {
                    }}
                />
                <label className={_style.inputItemLabel} style={{
                    fontWeight: 'normal',
                    color: 'black',
                    'margin-top': '0px',
                    'margin-bottom': '0px',
                    marginLeft: '5px'
                }}>Start Date</label>
            </div>
            <input
                type="date"
                // name={'patients.uid'}
                className={_style.inputItem}
                style={{marginLeft: '10px', width: '225px'}}
                onClick={() => {
                }}
            />
            <div style={{'margin-top': '5px', 'margin-bottom': '0px', display: 'flex', flex: 1}}>
                <input
                    type="checkbox"
                    // name={'patients.uid'}
                    checked={Boolean(this.state.medicalNotesStartDate)}
                    onClick={() => {
                    }}
                />
                <label className={_style.inputItemLabel} style={{
                    fontWeight: 'normal',
                    color: 'black',
                    'margin-top': '0px',
                    'margin-bottom': '0px',
                    marginLeft: '5px'
                }}>End Date</label>
            </div>
            <input
                type="date" name={'patients.uid'}
                className={_style.inputItem}
                style={{marginLeft: '10px', width: '225px'}}
                onClick={() => {
                }}
            />
            <button className={_style.sidebar_btn}
                    style={{margin: '40px', marginBottom: '100px', marginTop: '20px'}}
                    onClick={() => this.setState({showInsuranceEditModal: false})}
            >Save & Close
            </button>

        </div>
    }
    _renderSidebar = (props) => {
        switch (this.state.selectedSidebarItem) {
            case 'Insurance Information':
                return Boolean(this.state.showInsuranceEditModal) ? this._editInsuranceInformation(props) : this._insuranceInformation(props)
                // return
                break;
            case 'General Information':
                return this._generalInformation(props)
                // return
                break;
            case 'Prescriptions':
                return this._prescriptions(props)
                // return
                break;
            case 'Medical Notes':
                return this._medicalNotes(props)
                // return
                break;
            default:
                // alert('j')
                // return !Boolean(this.state.showInsuranceEditModal)?this._editInsuranceInformation(props):this._insuranceInformation(props)
                return this._sidebar(props)
                break;
        }
    }

    render() {
        return (isMobileView()
                ? <>

                    {<Transition in={this.props.showSidebar} timeout={duration}>
                        {(tstate) => <>
                            <div className={_style.sidebar_container} style={{
                                height: window.innerHeight, backgroundColor: 'rgba(185, 185, 185, 0.8)',
                                //  ...linkStyle,
                                //  ...linkTransitionStyles[tstate]
                            }
                            }
                            ></div>
                            <div className={_style.sidebar_items_container} style={{
                                ...sidebarStyle,
                                ...sidebarTransitionStyles[tstate]
                            }}>
                                <div className={_style.sidebar_items_wrapper}>
                                    <_Header
                                        // title={Boolean(selectedSidebarItem) ? selectedSidebarItem : "Tools"}
                                        style={{position: 'fixed', left: 'initial', width: '250px'}}
                                        customView={<div style={{
                                            flex: 1,
                                            flexDirection: 'row',
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            fontSize: '14px',
                                            fontFamily: 'HarmoniaSansStd-Bold',
                                            marginRight: '50px',
                                            color: "#884a9d"
                                        }}>
                                            {Boolean(this.state.selectedSidebarItem) ? this.state.selectedSidebarItem : "Tools"}
                                        </div>}
                                        leftIcon={<FaAngleLeft style={{justifySelf: 'flex-start'}} color="#884a9d"
                                                               size={22}/>}
                                        back={{
                                            title: ' ', onClick: () => this.goBack()


                                        }}></_Header>

                                    <div className={_style.sidebar_wrapper}>
                                        {this._renderSidebar(this.props)}
                                    </div>
                                </div>
                            </div>

                            {/* <FaAngleRight onClick={() => this.setState({ isOpen: true })}></FaAngleRight> */}
                        </>}</Transition>}
                </> :
                null
        )
    }
}

const mapStateToProps = ({clinicProfileSettingReducer, forgotPasswordReducer}) => {
    const {staffData} = clinicProfileSettingReducer;
    return {staffData,};
};

export default connect(mapStateToProps, {updateInsuranceCard})(Sidebar);
