import  {useEffect, useState} from 'react'
import classes from "../ChatBuilder.module.css";
import BarLoader from "../../../components/Spinner/BarLoader";
import Spinner from "../../../components/Spinner";
import {connect} from "react-redux";
import TimeAgo from "timeago-react";
import {debounce} from "lodash";
import {database} from "../../../firebase";
import {
    fetchAllMessages, fetchUserChatHistory,
    refreshMessages,
    sendDoctorFirstMessage,
    sendNewMessageNotification,
    turnSelectChat
} from "../../../actions/ChatAction";
import {
    changeQueueStatus,
    fetchChatTags, fetchStatus,
    findPatientDetail,
    setSelectedItem, updatePatient
} from "../../../actions/ConversationAction";

const QueuedChatList = (props) => {
    const [selected, setSelected] = useState(false)
    const [loading, setLoading] = useState(false)
    const [doctor, setDoctor] = useState(null)
    const [patient, setPatient] = useState(null)
    const [assignedAs, setAssignedAs] = useState(null)
    const [transferChat, setTransferChat] = useState(false)
    const {patientID, doctorID, unreadCount, owner, issue, category, start_timestamp, paidChat, lastMessageTimeStamp, transferedBy, assignedOn}  = props.chat;
    const [name, setName] = useState('')
    const [chatType, setChatType] = useState("");

    useEffect(() => {
        if (props?.chat){
            if (props?.chat?.isEMRChat) {
                if (props?.chat?.referralFromApp) {
                    setChatType("Free Chat");
                }else {
                    setChatType("Health Card");
                }
            }else if(props?.chat?.subscriptionType === "quarterly"){
                setChatType("Quarterly Plan User");
            } else if(props?.chat?.subscriptionType === "yearly"){
                setChatType("Yearly Plan User");
            } else if(props?.chat?.subscriptionType === "monthly"){
                setChatType("Monthly Plan User");
            }else if(props?.chat?.partner_id ===7){
                setChatType("Orchyd Patient");
            } else if(props?.chat?.isPhysioPatient){
                setChatType("Physio Patient");
            }else if(props?.chat?.payAfterChat){
                setChatType(`Trial plan user${props?.chat?.paymentCompleted ? " - Paid" : props?.chat?.checkoutSessionID ? " - Payment pending" : ""}`);
            }else if(props?.chat?.doNotCharge){
                 if(props.chat?.fromConsumable){
                    if (props.chat?.freePrescription && props.chat?.freeMedicalNote){
                        setChatType("Free prescriptions & notes");
                    } else if (props.chat?.freePrescription){
                        setChatType("Free Prescription");
                    } else if (props.chat?.freeMedicalNote){
                        setChatType("Free Medical Note");
                    }else{
                        setChatType("Free Prescription");
                    }
                }else{
                     setChatType("Free Prescription");
                 }
            } else{
                setChatType("Private Pay");
            }
        }
    }, [props?.chat]);

    useEffect(()=>{
        if(loading){
            removedFromQueueAndShowMessages();
        }
    },[loading])

    const checkAssignedMessage = (message) => {
        switch (message) {
            case 'close_chat':
                return 'Assigned on close chat';
            case 'picked_from_queue_chat':
                return 'Picked from queue';
            case 'transfered_chat':
                return 'Transferred chat';
            default:
                return null
        }
    }

    useEffect(()=>{
        if(props.patients && patientID){
            if(props.patients[patientID]){
                if(props.patients[patientID]) setPatient(props.patients[patientID])
            }
        }
    },[props.patients, patientID])

    let userRef = null
    useEffect(()=>{
        //console.log("QueuedChatList userReff ", userRef)

        return ()=>{
            if(userRef){
                //console.log("QueuedChatList userRef found ", patientID)
                database.ref(`/users/${patientID}`).off('value', userRef);
            }else{
                //console.log("QueuedChatList userRef not ", patientID)
            }

        }
    },[])

    const fetchUser = (patientID) => {
        if(userRef){
            //console.log("QueuedChatList userRef found ", patientID)
            database.ref(`/users/${patientID}`).off('value', userRef);
        }

        userRef = database.ref(`/users/${patientID}`).on("value",fromUserSnapshot => {
            if(fromUserSnapshot.val()) {
                let userName =  fromUserSnapshot.val().name || fromUserSnapshot.val().userName || 'Patient';
                let country = fromUserSnapshot.val().country;
                let city = fromUserSnapshot.val().city;
                let patientObj = {
                    ...fromUserSnapshot.toJSON(),
                    patientName: userName,
                    patientCountry: Boolean(country) ? country : "NA",
                    patientCity: Boolean(city) ? city : "NA",
                    tier2ClinicData: fromUserSnapshot.val()?.conversations?.tier2ClinicData || {},
                    transferredFromTier2: fromUserSnapshot.val()?.conversations?.transferredFromTier2 || false,
                }
                //setPatient(patientObj)
                props.updatePatient(patientID, patientObj)
            }
        })
    }

    const removedFromQueueAndShowMessages =() => {
        let conversationId = props?.chat?.conversationId;
        if(props.patients && !props.patients[patientID]){
            fetchUser(patientID)
        }
        let start_time = start_timestamp;
        if (props.doctorDetail?.role === "Doctor") {
            if (props.doctorDetail?.profileStatus !== "deleted") {
                //if (props.doctorDetail?.accepting_chat) {
                    database.ref(`/users/${patientID}`)
                        .once("value",(res) => {
                            if (res.val() && props?.doctorDetail?.doctorName) {
                                props.sendNewMessageNotification(
                                    res.val().notificationToken,
                                    null,
                                    props.doctorDetail?.doctorName
                                );
                            } else {
                                console.error(
                                    "failed to send queue removal notification to patient from ydo doctor"
                                );
                            }
                        }).then((data) => console.log("firebase user data:", data))
                        .catch((err) => console.log("firebase user query error:", err));

                    props.changeQueueStatus(
                        paidChat,
                        conversationId,
                        patientID,
                        props.doctorDetail,
                        props?.doctorDetail?.doctorName || "",
                        patientID,
                        issue,
                        category,
                        start_time,
                        props.chat
                    );
                    props.setSelectedItem(
                        paidChat,
                        patientID,
                        props.doctorDetail?.uid,
                        conversationId,
                        issue,
                        category,
                        start_time,
                        props.chat
                    );
                    props.findPatientDetail(patientID,patient, true);
                    props.sendDoctorFirstMessage(conversationId);
                    props.refreshMessages();
                    props.turnSelectChat(conversationId);
                    props.fetchAllMessages(
                        conversationId,
                        props?.chatHistoryItemClick
                    );
                    props.fetchChatTags(conversationId);
                    props.fetchUserChatHistory(patientID, null, owner === patientID? null: owner || null);
                    props.fetchStatus(conversationId);
                    setLoading(false)
                    /*setShowUpView(false)
                    setSkipClassStatus(false)
                    setFollowClassStatus(false)
                    checkAutoFollowUpEnabled();*/
                /*} else {
                    setLoading(false)
                    alert("Please turn on the accepting chat.");
                }*/
            } else {
                setLoading(false)
                alert("This account has been deactivated.");
            }
        } else {
            setLoading(false)
            alert(
                "You are logged in with admin account, So you can't pick patient from the queue."
            );
        }

    };

    useEffect(()=>{
        if(assignedOn) setAssignedAs( checkAssignedMessage(assignedOn))
    },[assignedOn])

    useEffect(()=>{
        if(props?.conversationId && props?.chat?.conversationId)
            setSelected(props?.conversationId === props?.chat?.conversationId ? true : false)
    },[props?.conversationId, props?.chat?.conversationId])

    useEffect(()=>{
        if(props.doctorsNameAndImages && doctorID)setDoctor(props.doctorsNameAndImages?.length > 0? props.doctorsNameAndImages.find(doc => doc.uid === doctorID) : null)
    },[props.doctorsNameAndImages, doctorID])
    useEffect(()=>{
        if(doctor && transferedBy)setTransferChat(transferedBy !== null && props.doctorsNameAndImages?.length !== 0 && doctor?.uid === transferedBy? doctor: false)
    },[doctor, transferedBy])

    useEffect(()=>{
        setName(props?.chat?.owner && props.chat?.owner !== props.chat?.patientID ? patient?.profiles? patient?.profiles[props.chat?.owner]?.name:patient?.name:patient?.name)
    },[props?.chat, patient])

    return (
        loading? <div className={classes.queue_list_view} style={{justifyContent:"center"}}><Spinner  color={"black"} size={"10px"} isLoading={true}/></div>:
        <div key={props?.chat?.conversationId} className={classes.queue_list_view}
             onClick={() => {
                 if (!loading) setLoading(true)
             }}>
            {/*<div className={classes.list_img_style}>
                <div className={classes.selected_list_img}>
                    <span className={classes.selected_user_img}>{!!name ? name[0].toUpperCase() : 'P'}</span>
                </div>
            </div>*/}
            <div className={classes.list_item}>
                <div
                    className={classes.patient_name}>{props.chat?.patientName? props.chat?.patientName : patient?.name === undefined ? <BarLoader  color={"black"} isLoading={true}/>: name || 'Patient'}</div>
                <div
                    className={classes.item_name}>Location: {props.chat?.city || patient?.city || patient?.patientCity || "NA"}, {props.chat?.country || patient?.country || patient?.patientCountry || "NA"}</div>
                <div className={selected ? classes.item_payment_status : classes.item_payment_status}>{chatType}</div>
                {props.chat?.referralType && <div className={selected ? classes.selected_item_name : classes.item_name}>Referral: {props.chat?.referralType || ""}</div>}

            </div>
            <div className={classes.list_item_second}>
                <div>
                    <div className={classes.time_style}>In queue:</div>
                    <div className={classes.time_style}><TimeAgo datetime={start_timestamp}/></div>
                </div>
            </div>
        </div>
    )

}

const mapStateToProps = ({conversationReducer, doctorReducer}) => {
    const { doctorDetail } = doctorReducer;
    const {conversationId, doctorsNameAndImages, patients } = conversationReducer;
    return {doctorDetail, conversationId, doctorsNameAndImages, patients };
};

export default connect(mapStateToProps, {
    sendNewMessageNotification, changeQueueStatus, setSelectedItem,
    findPatientDetail, sendDoctorFirstMessage, refreshMessages, turnSelectChat,
    fetchAllMessages, fetchChatTags, fetchUserChatHistory, fetchStatus, updatePatient
})(QueuedChatList);
