import  {useCallback, useState} from 'react';
import _classes from '../_encounters.module.css';
import TimeAgo from 'timeago-react';
import isMobileView from '../../../../utils/isMobileView';
import {auth, database} from "../../../../firebase";


const OpenChatList = (props) => {
    var selected = props.selectedConversation === props.conversationId ? true : false;
    var initial = Boolean(props.name) ? props.name[0].toUpperCase() : 'P';
    return (
        <div className={_classes.list_view} onClick={() => {
            props.onChatItemClick(props.chatData);
            props.chatSelected(true)
        }}>
            <div className={_classes.list_img_style}>
                <div className={_classes.selected_list_img}>
                    <span className={_classes.selected_user_img}>{initial}</span>
                </div>
            </div>
            <div className={_classes.list_item}>
                <div
                    className={_classes.patient_name}>{props.name !== undefined || props.name !== '' ? props.name : 'Patient'}</div>
                <div className={_classes.item_name}>{props.doctorName}</div>
                {/* <div className={selected ? _classes.selected_item_name : _classes.item_name}>Active Chats: {props.onlineChats}/{props.totalChatLimit}</div> */}
                {props.admin &&
                <div className={_classes.item_name}>Clinic name: {props.clinicName}</div>
                }
            </div>
            {/*
                !selected &&
                <div className={_classes.list_item_second}>
                    <div>
                        <div className={_classes.time_style}>Last message:</div>
                        <div className={_classes.time_style}><TimeAgo datetime={props.lastMessageTimeStamp} /></div>
                    </div>
                </div>
            */}
        </div>
    )
}

const MyChatList = (props) => {
    var selected = props.selectedConversation === props.conversationId ? true : false;
    var initial = Boolean(props.name) ? props.name[0].toUpperCase() : 'P';

    return (
        <div className={_classes.list_view} onClick={() => {
            props.onChatItemClick(props.chatData);
            if (props.chatSelected) props.chatSelected(true)
            // alert('he')
        }}>
            <div className={_classes.list_img_style}>
                <div className={_classes.selected_list_img}>
                    <span className={_classes.selected_user_img}>{initial}</span>
                </div>
            </div>
            <div className={_classes.list_item}>
                <div
                    className={_classes.patient_name}>{props.name !== undefined || props.name !== '' ? props.name : 'Patient'}</div>
                <div className={_classes.item_name}>{props.doctorName}</div>
                {/*<div className={selected ? _classes.selected_item_name : _classes.item_name}>Active Chats: {props.onlineChats}/{props.totalChatLimit}</div>*/}
            </div>
            {/*!selected &&
                <div className={_classes.list_item_second}>
                    {Boolean(props.unReadCount) &&
                        <div className={_classes.list_unread_style}>
                            <span className={_classes.unread_msg_style}>{props.unReadCount}</span>
                        </div>
                    }
                    <div>
                        <div className={_classes.time_style}>Last message:</div>
                        <div className={_classes.time_style}><TimeAgo datetime={props.lastMessageTimeStamp} /></div>
                    </div>
                </div>
            */}


        </div>
    )
}

const FollowUpList = (props) => {
    var initial = Boolean(props.allFollowUp.patient_name) ? props.allFollowUp.patient_name[0].toUpperCase() : 'P';

    var date_now = new Date()
    var date_future = new Date(props.allFollowUp.scheduled_date)
    var d = Math.abs(date_future - date_now) / 1000;                           // delta
    var r = {};                                                                // result
    var s = {                                                                  // structure
        year: 31536000,
        month: 2592000,
        week: 604800, // uncomment row to ignore
        day: 86400,   // feel free to add your own row
        hour: 3600,
        minute: 60,
        second: 1
    };

    Object.keys(s).forEach(function (key) {
        r[key] = Math.floor(d / s[key]);
        d -= r[key] * s[key];
    });
    if (r.day != 0 && r.day > 0) {
        if (r.hour >= 12) {
            r.day = r.day + 1
        }
        var detailString = `${r.day > 1 ? `${r.day} days` : `${r.day} day`}`
    } else if (r.hour != 0 && r.hour > 0) {
        detailString = `${r.hour > 1 ? `${r.hour} hours` : `${r.hour} hour`}`

    } else if (r.hour == 0 && r.day == 0 && r.minute > 0) {
        detailString = `${r.minute > 1 ? `${r.minute} minutes` : `${r.minute} minute`}`

    } else if (r.minute == 0 && r.hour == 0 && r.day == 0 && r.second > 0) {
        detailString = `${r.second > 1 ? `${r.second} seconds` : `${r.second} second`}`
    }
    let tDate = new Date(props.allFollowUp.scheduled_date)
    tDate.setHours(tDate.getHours() - 3)
    // <TimeAgo datetime={tDate} />
    return (
        <div className={_classes.queue_list_view} onClick={() => {

            props.searchFirebaseId(props.allFollowUp.user_id, props.allFollowUp.id, props.index, props.allFollowUp.patientFirebaseId)
        }}>
            <div className={_classes.list_img_style}>
                <div className={_classes.selected_list_img}>
                    <span className={_classes.selected_user_img}>{initial}</span>
                </div>
            </div>
            <div className={_classes.list_item}>
                <div className={_classes.patient_name}>{props.allFollowUp.patient_name}</div>
                <div
                    className={_classes.item_name}>Issue: {props.allFollowUp.diagnosis != null && props.allFollowUp.diagnosis != undefined && props.allFollowUp.diagnosis != '' ? props.allFollowUp.diagnosis : props.allFollowUp.patient_issue}</div>
                <div
                    className={_classes.item_name}>{props.allFollowUp.doctor_name != null && props.allFollowUp.doctor_name != undefined && props.allFollowUp.doctor_name != '' ? "Scheduled for " + props.allFollowUp.doctor_name : ''}</div>

            </div>
            <div className={_classes.list_item_second}>
                {props.allFollowUp?.medical_details !== 'transferredFromTier2' ? <div>
                    <div
                        className={_classes.time_style}>{props.allFollowUp.notification_sent == 0 ? props.allTime >= 0 ? "Schedueled in:" : "Over due by:" : "Notification Sent"}</div>
                    <div
                        className={_classes.time_style}>{props.allFollowUp.notification_sent == 0 ? detailString : ''}</div>
                </div> : <div>
                    <div
                        className={_classes.time_style}>{props.allFollowUp.notification_sent == 0 ? "Last Chat: " : "Notification Sent"}</div>
                    <div className={_classes.time_style}>{props.allFollowUp.notification_sent == 0 ?
                        <TimeAgo datetime={tDate}/> : ''}</div>
                </div>}

            </div>
        </div>
    )
}
const QueuedChatList = (props) => {
    var initial = Boolean(props.name) ? props.name[0].toUpperCase() : 'P';
    if (props.removedFromQueueAndshowMessagesConfirmedStatus) {
        props.onQueuedChatItemClick(props.chatData);
    }
    return (
        <div className={_classes.queue_list_view} onClick={() => {

            props.chatSelected(true)
        }}>
            <div className={_classes.list_img_style}>
                <div className={_classes.selected_list_img}>
                    <span className={_classes.selected_user_img}>{initial}</span>
                </div>
            </div>
            <div className={_classes.list_item}>
                <div
                    className={_classes.patient_name}>{props.name !== undefined || props.name !== '' ? props.name : 'Patient'}</div>
                <div
                    className={_classes.item_name}>Location: {Boolean(props.patientCity) ? props.patientCity : "NA"}, {Boolean(props.patientCountry) ? props.patientCountry : "NA"}</div>
                {props.doctorID && props.doctorName &&
                <div className={_classes.item_name}>Assigned: {props.doctorName}</div>
                }
            </div>
            <div className={_classes.list_item_second}>
                <div>
                    <div className={_classes.time_style}>In queue:</div>
                    <div className={_classes.time_style}><TimeAgo datetime={props.timestamp}/></div>
                </div>
            </div>
        </div>
    )
}

const AwaitingChatList = (props) => {
    var initial = Boolean(props.name) ? props.name[0].toUpperCase() : 'P';
    return (
        <div className={_classes.list_view}>
            <div className={_classes.list_img_style}>
                <div className={_classes.selected_list_img}>
                    <span className={_classes.selected_user_img}>{initial}</span>
                </div>
            </div>
            <div className={_classes.list_item}>
                <div
                    className={_classes.patient_name}>{props.name !== undefined || props.name !== '' ? props.name : 'Patient'}</div>
                <div className={_classes.item_name}>{props.doctorName}</div>
                {/* <div className={selected ? _classes.selected_item_name : _classes.item_name}>Active Chats: {props.onlineChats}/{props.totalChatLimit}</div> */}
                {props.admin &&
                <div className={_classes.item_name}>Clinic name: {props.clinicName}</div>
                }
            </div>

        </div>
    )
}

const EmptyChat = (props) => {
    return (<div className={_classes.empty_chat_list}>{props.msg}</div>)
}

const ConversationList = (props) => {

    const [showQueue, changeQueue] = useState(true);
    const [showOtherChats, changeOtherChats] = useState(true);
    const [showMyChats, changeMyChats] = useState(true);
    const [showFollowUp, changeFollowUp] = useState(true);
    const [showAwaiting, changeAwaiting] = useState(true);
    const [clickedConversation, setClickedConversation] = useState('');
    const showHideFollowUp = useCallback(() => {
        changeFollowUp(!showFollowUp);
    }, [showFollowUp]);
    const showHideQueue = useCallback(() => {
        changeQueue(!showQueue);
    }, [showQueue]);
    const showHideOtherChats = useCallback(() => {
        changeOtherChats(!showOtherChats);
    }, [showOtherChats]);
    const showHideMyChats = useCallback(() => {
        changeMyChats(!showMyChats);
    }, [showMyChats]);

    const showHideAwaiting = useCallback(() => {
        changeAwaiting(!showAwaiting);
    }, [showAwaiting]);

    const myconversation = props.conversations.map(mychat => {

        var onlineChats = props.chatCounts.length !== 0 && props.chatCounts.find(chat => chat.doctorID === mychat.doctorID);
        return (!mychat.chatInQueue && mychat.ownerChat && mychat.ownerClinics && mychat.appActivated &&
            <MyChatList
                chatSelected={props.chatSelected ? (val) => props.chatSelected(val) : () => {
                }}
                selectedConversation={props.selectedConversation}
                patientID={mychat.patientID}
                chatHistoryItemClick={props.chatHistoryItemClick}
                doctorID={mychat.doctorID}
                doctorName={mychat.doctorName}
                onlineChats={onlineChats !== undefined && onlineChats !== false ? onlineChats.onlinePaidChats : 0}
                totalChatLimit={mychat.totalChatLimit}
                conversationId={mychat.conversationId}
                unReadCount={mychat.unreadCount}
                lastMessageTimeStamp={mychat.lastMessageTimeStamp}
                key={mychat.conversationId}
                name={mychat.patientName}
                ownerChat={mychat.ownerChat}
                issue={mychat.issue}
                category={mychat.category}
                paidChat={mychat.paidChat}
                chatData={mychat}
                chatSelected={props.chatSelected ? (val) => props.chatSelected(val, true) : () => {
                }}
                onChatItemClick={(paidChat, conversationId, patientID, doctorID, issue, category) => props.showMessages(paidChat, conversationId, patientID, doctorID, issue, category)}
            />
        )
    });

    const openconversation = props.conversations.map(openchat => {

        var onlineChats = props.chatCounts.length !== 0 && props.chatCounts.find(chat => chat.doctorID === openchat.doctorID);
        return (!openchat.chatInQueue && !openchat.ownerChat && openchat.ownerClinics && openchat.appActivated &&
            <OpenChatList
                chatSelected={props.chatSelected ? (val) => props.chatSelected(val) : () => {
                }}
                selectedConversation={props.selectedConversation}
                patientID={openchat.patientID}
                doctorID={openchat.doctorID}
                doctorName={openchat.doctorName}
                onlineChats={onlineChats !== undefined && onlineChats !== false ? onlineChats.onlinePaidChats : 0}
                totalChatLimit={openchat.totalChatLimit}
                conversationId={openchat.conversationId}
                key={openchat.conversationId}
                name={openchat.patientName}
                ownerChat={openchat.ownerChat}
                issue={openchat.issue}
                category={openchat.category}
                unReadCount={openchat.unreadCount}
                lastMessageTimeStamp={openchat.lastMessageTimeStamp}
                paidChat={openchat.paidChat}
                chatData={openchat}
                clinicName={openchat.clinicName}
                admin={props.admin}
                chatSelected={props.chatSelected ? (val) => props.chatSelected(val, true) : () => {
                }}
                onChatItemClick={(paidChat, conversationId, patientID, doctorID, issue, category) => props.showMessages(paidChat, conversationId, patientID, doctorID, issue, category)}
            />
        )
    });

    const queuedconversation = props.conversations.map(queuechat => {
        return (queuechat.chatInQueue && queuechat.appActivated &&
            <QueuedChatList
                chatSelected={props.chatSelected ? (val) => props.chatSelected(val) : () => {
                }}
                selectedConversation={props.selectedConversation}
                patientID={queuechat.patientID}
                doctorID={queuechat.doctorID}
                doctorName={queuechat.doctorName}
                conversationId={queuechat.conversationId}
                key={queuechat.conversationId}
                name={queuechat.patientName}
                ownerChat={queuechat.ownerChat}
                issue={queuechat.issue}
                category={queuechat.category}
                timestamp={queuechat.start_timestamp}
                unReadCount={queuechat.unreadCount}
                lastMessageTimeStamp={queuechat.lastMessageTimeStamp}
                patientCountry={queuechat.patientCountry}
                patientCity={queuechat.patientCity}
                paidChat={queuechat.paidChat}
                chatData={queuechat}
                chatSelected={props.chatSelected ? (val) => props.chatSelected(val, false, true) : () => {
                }}
                removedFromQueueAndshowMessagesConfirmedStatus={props.removedFromQueueAndshowMessagesConfirmedStatus}
                onQueuedChatItemClick={(paidChat, conversationId, patientID, doctorID, issue, category) => {
                    if (clickedConversation === queuechat.conversationId) {
                        return
                    }
                    setClickedConversation(queuechat.conversationId)
                    props.removedFromQueueAndshowMessages(paidChat, conversationId, patientID, doctorID, issue, category)
                }
                }
            />
        )
    });

    const inwaitconversation = props.conversations.map(awaitingresponsechat => {
        var onlineChats = props.chatCounts.length !== 0 && props.chatCounts.find(chat => chat.doctorID === awaitingresponsechat.doctorID);

        return (awaitingresponsechat.ownerClinics && !awaitingresponsechat.appActivated &&
            <AwaitingChatList
                chatSelected={props.chatSelected ? (val) => props.chatSelected(val, true) : () => {
                }}
                selectedConversation={props.selectedConversation}
                patientID={awaitingresponsechat.patientID}
                doctorID={awaitingresponsechat.doctorID}
                doctorName={awaitingresponsechat.doctorName}
                onlineChats={awaitingresponsechat !== undefined && onlineChats !== false ? onlineChats.onlinePaidChats : 0}
                totalChatLimit={awaitingresponsechat.totalChatLimit}
                conversationId={awaitingresponsechat.conversationId}
                key={awaitingresponsechat.conversationId}
                name={awaitingresponsechat.patientName}
                ownerChat={awaitingresponsechat.ownerChat}
                issue={awaitingresponsechat.issue}
                category={awaitingresponsechat.category}
                unReadCount={awaitingresponsechat.unreadCount}
                lastMessageTimeStamp={awaitingresponsechat.lastMessageTimeStamp}
                paidChat={awaitingresponsechat.paidChat}
                chatData={awaitingresponsechat}
                clinicName={awaitingresponsechat.clinicName}
                admin={props.admin}
            />
        )
    });

    const followUp = Boolean(props.allFollowUp) ? props.allFollowUp.length > 0 ? props.allFollowUp.map((item, index) => {
        let show = (props.allFollowUp[index]?.doctorFirebaseId === auth.currentUser.uid || (props.allFollowUp[index]?.medical_details === 'transferredFromTier2' && props.clinicId === props.allFollowUp[index]?.clinicId))
        return (Boolean(show) ?
                <FollowUpList
                    allFollowUp={props.allFollowUp[index]}
                    allTime={props.allTime[index]}
                    index={index}
                    searchFirebaseId={(id, followUp_id, index, patientid) => {
                        let selectedFollowUp = props.allFollowUp[index]
                        let doctorID = auth.currentUser.uid
                        if (selectedFollowUp?.medical_details === 'transferredFromTier2') {
                            if (!Boolean(props.acceptingChat)) {
                                alert("Please turn on the accepting chat.");
                                return;
                            }
                            let conversationID = database.ref('conversations').push().key;
                            let messageID = database.ref(`/conversations/${conversationID}`).push().key;
                            var now = new Date();
                            var freeData = {
                                status: true,
                                queued: false,
                                patientID: selectedFollowUp.patientFirebaseId,
                                doctorID,
                                timestamp: now.getTime(),
                                paidChat: true,
                                issue: selectedFollowUp.patient_issue,
                                category: selectedFollowUp.category,
                                clinical_chat: true,
                                clinical_location: 'Canada',
                                clinical_to_skip: false,
                            }

                            let data = {
                                location: selectedFollowUp.conversationId,
                                doctorID,
                                issue: selectedFollowUp.patient_issue,
                                category: selectedFollowUp.category,
                                clinical_chat: true,
                                tier2: true,
                                clinic_id: props.clinicId
                            };
                            // var timeNow = new Date();
                            // var ageInYears = timeNow - new Date(age);
                            // ageInYears=ageInYears/(1000 * 3600 * 24*365)
                            // ageInYears=Math.floor(ageInYears)


                            var updates = {};
                            // let messageValue = {
                            //     type: "text",
                            //     timestamp: now.getTime(),
                            //     content: `Hello Doctor!\nMy name is ${patientName}.\nGender is ${gender}.\nAge is ${ageInYears}.\nMy main issue is: ${issue}`,
                            //     fromID: currentUserID,
                            //     toID: null,
                            //     isRead: false
                            // };
                            updates[`/openClinicalConversations/${props.clinicId}/${selectedFollowUp.conversationId}`] = freeData;

                            // updates[`/conversations/${conversationID}/${messageID}`] = messageValue;
                            updates[`/users/${selectedFollowUp.patientFirebaseId}/conversations`] = data;
                            updates[`/users/${selectedFollowUp.patientFirebaseId}/followupId`] = null;
                            database.ref().update(updates)
                            // { paidChat, conversationId:selectedFollowUp.conversationId, patientID:selectedFollowUp.patientFirebaseId,doctorID, issue:selectedFollowUp.patient_issue, category:selectedFollowUp.category }
                            props.removedFromQueueAndshowMessages({
                                paidChat: true,
                                conversationId: selectedFollowUp.conversationId,
                                patientID: selectedFollowUp.patientFirebaseId,
                                doctorID,
                                issue: selectedFollowUp.patient_issue,
                                category: selectedFollowUp.category,
                                transferredFromTier2: true
                            })
                            props.searchFirebaseId(selectedFollowUp.user_id, selectedFollowUp.id, index, selectedFollowUp.patientFirebaseId)
                            return;
                        }
                        props.searchFirebaseId(id, followUp_id, index, patientid)
                    }}
                ></FollowUpList> : null
        )
    }).filter(x => Boolean(x)) : null : null;
    var newmyconversation = myconversation.filter(function (el) {
        return el != null && el !== "" && el !== false;
    });

    var newopenconversation = openconversation.filter(function (el) {
        return el != null && el !== "" && el !== false;
    });

    var newqueuedconversation = queuedconversation.filter(function (el) {
        return el != null && el !== "" && el !== false;
    });

    var newinwaitconversation = inwaitconversation.filter(function (el) {
        return el != null && el !== "" && el !== false;
    });

    var nochatsavailavble = (newmyconversation.length === 0 && newopenconversation.length === 0 && newqueuedconversation.length === 0 && newinwaitconversation.length === 0) && !Boolean(followUp) ?
        <EmptyChat msg='no chats available'/> : "";
    return (
        <div className={_classes.chatsList} style={{'padding-top': '65px'}}>
            <div className={_classes.block} style={{height: window.innerHeight - 150}}>
                <div className={_classes.list_body}>
                    <div className={_classes.convo_list}>
                        {newmyconversation.length !== 0 &&
                        <div className={_classes.openchatlist} onClick={showHideMyChats}>
                            <div className={_classes.openchatlistSection}>
                                <div
                                    className={showMyChats ? _classes.subListTitleIcon : _classes.subListTitleIconRotate}>
                                    <svg fill="#4384F5" width="12px" height="8px" viewBox="0 0 12 8">
                                        <path fill="inherit" fillRule="evenodd"
                                              d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                    </svg>
                                </div>
                                <div
                                    className={_classes.subListTitle}> {isMobileView() ? 'Active Patients' : 'Engaged Patients'} </div>
                            </div>

                            <div className={_classes.subListCount}>
                                {newmyconversation.length}
                            </div>
                        </div>
                        }
                        {newmyconversation.length !== 0 &&
                        <div className={showMyChats ? _classes.showConvo : _classes.hideConvo}> {myconversation} </div>
                        } {/*
                        {newopenconversation.length !== 0 && !isMobileView()&&
                            <div className={_classes.openchatlist} onClick={showHideOtherChats}>
                                <div className={_classes.openchatlistSection}>
                                    <div className={showOtherChats ? _classes.subListTitleIcon : _classes.subListTitleIconRotate}>
                                        <svg fill="#4384F5" width="12px" height="8px" viewBox="0 0 12 8">
                                            <path fill="inherit" fillRule="evenodd" d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                        </svg>
                                    </div>
                                    <div className={_classes.subListTitle}> Other Active Chats </div>
                                </div>

                                <div className={_classes.subListCount}> {newopenconversation.length} </div>
                            </div>
                        }
                        {newopenconversation.length !== 0 &&  !isMobileView()&&
                            <div className={showOtherChats ? _classes.showConvo : _classes.hideConvo}>
                                {newopenconversation}
                            </div>
                        } */}
                        {newqueuedconversation.length !== 0 &&
                        <div className={_classes.openchatlist} onClick={showHideQueue}>
                            <div className={_classes.openchatlistSection}>
                                <div
                                    className={showQueue ? _classes.subListTitleIcon : _classes.subListTitleIconRotate}>
                                    <svg fill="#4384F5" width="12px" height="8px" viewBox="0 0 12 8">
                                        <path fill="inherit" fillRule="evenodd"
                                              d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                    </svg>
                                </div>
                                <div className={_classes.subListTitle}> Patients Queued</div>
                            </div>
                            <div className={_classes.subListCount}> {newqueuedconversation.length} </div>
                        </div>
                        }
                        {newqueuedconversation.length !== 0 &&
                        <div className={showQueue ? _classes.showConvo : _classes.hideConvo}>
                            {newqueuedconversation}
                        </div>
                        }
                        {newinwaitconversation.length !== 0 &&
                        <div className={_classes.openchatlist} onClick={showHideAwaiting}>
                            <div className={_classes.openchatlistSection}>
                                <div
                                    className={showAwaiting ? _classes.subListTitleIcon : _classes.subListTitleIconRotate}>
                                    <svg fill="#4384F5" width="12px" height="8px" viewBox="0 0 12 8">
                                        <path fill="inherit" fillRule="evenodd"
                                              d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                    </svg>
                                </div>
                                <div className={_classes.subListTitle}> Awaiting Response</div>
                            </div>
                            <div className={_classes.subListCount}> {newinwaitconversation.length} </div>
                        </div>
                        }
                        {newinwaitconversation.length !== 0 &&
                        <div className={showAwaiting ? _classes.showConvo : _classes.hideConvo}>
                            {newinwaitconversation}
                        </div>
                        }
                        {
                            Boolean(followUp) && followUp.length !== 0 &&
                            <div className={_classes.openchatlist} onClick={showHideFollowUp}>
                                <div className={_classes.openchatlistSection}>
                                    <div
                                        className={showFollowUp ? _classes.subListTitleIcon : _classes.subListTitleIconRotate}>
                                        <svg fill="#4384F5" width="12px" height="8px" viewBox="0 0 12 8">
                                            <path fill="inherit" fillRule="evenodd"
                                                  d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                        </svg>
                                    </div>
                                    <div
                                        className={_classes.subListTitle}> {isMobileView() ? 'Upcoming Patient Follow Ups' : 'Upcoming Conversations'}  </div>
                                </div>
                                <div className={_classes.subListCount}>
                                    {followUp.length}
                                </div>
                            </div>
                        }
                        {Boolean(followUp) && followUp.length !== 0 &&
                        <div className={showFollowUp ? _classes.showConvo : _classes.hideConvo}>
                            {followUp}
                        </div>
                        }
                        {nochatsavailavble}
                    </div>
                </div>
            </div>


        </div>
    )
}

export default ConversationList;
