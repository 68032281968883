import { Component } from 'react';
import {connect} from 'react-redux';
import Switch from "react-switch";

import _classes from '../../assets/_classes.module.css'
import {changeAcceptStatus, checkStatusAccept, getAcceptStatus} from '../../actions/ConversationAction';
import {logoutClinicMember} from '../../actions/ClinicStaffAction';

var moment = require("moment");


class AcceptChatFooter extends Component {
    constructor(props) {
        console.log("AcceptChatFooter ", AcceptChatFooter)
        super(props)
        this.state = {}
    }

    _handleSwitch = (val) => {
        console.log("_handleSwitch this.props ", this.props)
        const {acceptingChat, clinicId} = this.props;
        var self = this;

        if (acceptingChat === false) {
            this.setState({_showWorkingHoursModal: true});
        } else {
            this.props.changeAcceptStatus(clinicId, false, null, () => {
                self.setState({_showWorkingHoursModal: false});
            });
        }


    }
    onLogoutClick = () => {
        window.localStorage.setItem('logged_in', false);
        const {staffData, clinicId} = this.props;
        this.props.logoutClinicMember(clinicId, staffData.uid);
    }
    _onApplyWorkingHours = (datetime) => {
        let newDatetime = moment(datetime).unix() * 1000;
        const {acceptingChat, clinicId} = this.props;
        var self = this;

        //this.props.changeAcceptStatus(clinicId, true, datetime, () => {
        this.props.changeAcceptStatus(clinicId, true, newDatetime, () => {
            self.setState({_showWorkingHoursModal: false});
        });

    }

    render() {
        const {
            staffData, clinic_name, full_name,
            loading, message, physician_user, userType, userProfile, loader, msg, err, acceptingChat, acceptingChatTime
        } = this.props;
        var self = this
        // let { selectedItem, countries, provinces, showChangePasswordModal } = this.state;
        return (<>
            <div className={_classes.footer}>
                <div>
                    <div style={{verticalAlign: 'center', marginBottom: '5px'}}>
                        <span className={_classes.subHeaderText} style={{"font-size": "14px",}}>Accept Patients:</span>
                        <Switch
                            onChange={() => {
                                self._handleSwitch(Boolean(self.state._showWorkingHoursModal))
                            }}
                            checked={acceptingChat && !Boolean(self.state._showWorkingHoursModal)}
                            onColor="#884a9d"
                            onHandleColor="#884a9d"
                            handleDiameter={20}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={12}
                            width={48}
                            hidden={true}
                            className="react-switch"
                            style={{'margin-bottom': '-5px'}}
                        />
                    </div>
                    {Boolean(acceptingChat) && !Boolean(this.state._showWorkingHoursModal) &&
                    <span className={_classes.subHeaderText} style={{
                        fontSize: "12px",
                        fontWeight: 'normal'
                    }}>until {moment(acceptingChatTime).format('MMMM DD, YYYY h:mm a')}</span>

                    }
                    {!Boolean(acceptingChat) && Boolean(this.state._showWorkingHoursModal) &&
                    <span className={_classes.subHeaderText} style={{fontSize: "12px", fontWeight: 'normal'}}>until    [select date]
                <input ref={this.acceptingChatInput} type="datetime-local"
                       onChange={(e) => {
                           this._onApplyWorkingHours(e.target.value)
                       }} defaultValue={''} value={acceptingChatTime} style={{width: '50px', height: '10px'}}/>
                </span>}
                </div>

                <button className={_classes.button}
                        style={{width: '30%', backgroundColor: '#fff', color: '#884a9d', boxShadow: 'none'}}
                        onClick={() => this.onLogoutClick()}>Log Out
                </button>
            </div>
            {!Boolean(acceptingChat) && Boolean(this.state._showWorkingHoursModal) && <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: window.innerWidth,
                height: window.innerHeight,
                alignContent: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(52, 52, 52, 0.8)',
                position: 'fixed',
                top: 0,
                zIndex: '10'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '300px',
                    height: '200px',
                    alignContent: 'center',
                    justifyContent: 'space-evenly',
                    top: '100px',
                    backgroundColor: '#fff',
                    borderRadius: '5px',
                    alignSelf: 'center',
                    justifySelf: 'center',
                    padding: '10px',
                }}>
                    <div style={{textAlign: 'center', color: "#884a9d"}}>Please select a date</div>
                    <span className={_classes.subHeaderText}
                          style={{fontSize: "12px", fontWeight: 'normal', alignContent: 'center'}}>
                                You want to accept chats until
                        {!Boolean(this.state.acceptingChatTime) ? ' [select date]' : null}
                        <input
                            ref={this.acceptingChatInput}
                            type="datetime-local"
                            onChange={(e) => {
                                this.setState({acceptingChatTime: e.target.value})
                            }}
                            defaultValue={''}
                            value={acceptingChatTime}
                            size={5}
                            style={{height: '20px', paddingTop: '10px'}}/>
                            </span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        justifySelf: 'end',
                        width: '100%'
                    }}>
                        <button style={{
                            width: '100px',
                            height: '40px',
                            border: '1px solid #884a9d',
                            borderRadius: '5px',
                            backgroundColor: '#fff',
                            color: "#884a9d",
                            fontSize: '14px'
                        }}
                                color="#884a9d"
                                title="Cancel"
                                onClick={() => {
                                    this.setState({
                                        _showWorkingHoursModal: false
                                    })
                                }}>Cancel
                        </button>
                        <button style={{
                            width: '100px',
                            height: '40px',
                            backgroundColor: '#fff',
                            borderRadius: '5px',
                            backgroundColor: "#884a9d",
                            border: '1px',
                            color: '#fff',
                            fontSize: '14px'
                        }}
                                title="Confirm"
                                onClick={() => {
                                    this._onApplyWorkingHours(this.state.acceptingChatTime)
                                    this.setState({
                                        _showWorkingHoursModal: false
                                    })
                                }}>DONE
                        </button>
                    </div>
                </div>
            </div>
            }
        </>)
    }

}

const mapStateToProps = ({
                             clinicProfileSettingReducer,
                             forgotPasswordReducer,
                             clinicalChatsReducer,
                             acceptingReducer
                         }) => {
    const {
        staffData, clinic_name, full_name,
        userType, userProfile, clinicId, address, city, province, country, phone, fax, license_no,
        profile_image, signature
    } = clinicProfileSettingReducer;
    const {clinical_conversations} = clinicalChatsReducer;
    const {acceptingChat, acceptingChatTime, changedDoctorKey} = acceptingReducer;
    const {loader, msg, err} = forgotPasswordReducer;
    return {
        staffData,
        clinic_name,
        full_name,
        clinicId,
        address,
        city,
        province,
        country,
        phone,
        fax,
        license_no,
        profile_image,
        signature,
        userType,
        userProfile,
        loader,
        msg,
        err,
        clinical_conversations,
        acceptingChat,
        acceptingChatTime,
        changedDoctorKey
    };
}
export default connect(mapStateToProps, {
    logoutClinicMember, getAcceptStatus, checkStatusAccept, changeAcceptStatus
})(AcceptChatFooter)
