import  {useEffect, useState} from 'react'
import classes from "./referralPanel.module.css"

import {Table} from "react-bootstrap";
import {database} from "../../firebase";
import Spinner from "../../components/Spinner";
import moment from "moment-timezone";

import { DateRangePicker } from 'rsuite';
import "rsuite/dist/rsuite.min.css"
//import "react-datepicker/dist/react-datepicker.css";

const ReferralPanel = (props) => {
    //const [startDate, setStartDate] = useState(null)
    //const [endDate, setEndDate] = useState(null)
    const [doctors, setDoctors] = useState({})
    const [hideInactive, setHideInactive] = useState(false)

    const [value, setValue] =  useState(null);
    const [conversationRows,setConversationRows] = useState([])
    const [filterConvos,setFilterConvos] = useState([])

    const [totalConversationsCount, setTotalConversationsCount] = useState({})
    const [totalInactiveConversationCount, setTotalInactiveConversationCount] = useState({})
    const [totalBillableConversationCount, setTotalBillableConversationCount] = useState({})
    const [pendingBillableConversationCount, setPendingBillableConversationCount] = useState({})
    const [totalBilledConversationCount, setTotalBilledConversationCount] = useState({})

    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        if(hideInactive){
            let newConversationRows = [...conversationRows];
            if(hideInactive){
                newConversationRows = newConversationRows.filter(convo=> convo.issueResolved !== "inactive_chat")
            }
            setFilterConvos(newConversationRows)
        }else{

            setFilterConvos(conversationRows)
        }
    },[hideInactive, conversationRows])
    useEffect(()=>{
        console.log("Referral props ", props)
        let myTimezone = "America/Toronto";
        let date = moment().tz(myTimezone);
        date.set("hours", 0)
            .set("minutes", 0)
            .set("seconds", 0)
            .set("milliseconds", 0);
        //setStartDate(date.toDate());
        let end = moment().tz(myTimezone);
        end.set("hours", 23)
            .set("minutes", 99)
            .set("seconds", 99)
            .set("milliseconds", 99);
        //setEndDate(end.toDate());
        console.log("startDate", date.toDate())
        console.log("endDate", end.toDate())
        setValue([date.toDate(), end.toDate()])

        database.ref('doctors').orderByChild('profileStatus').equalTo("active").once('value', doctorsSnap=>{
            setDoctors(doctorsSnap.exists()?doctorsSnap.toJSON(): {})
        })
    }, [])

    /*useEffect(()=>{
        console.log("conversationRows ", conversationRows)
    },[conversationRows])*/
/*
    useEffect(()=>{
        console.log("doctors ", doctors)
    },[doctors])
*/

    const fetchChats = () => {
        if(value){
            setLoading(true)
            let conversationsObj = [];
            let timerIncrement = 0;
            database.ref(`archiveConversations/`).orderByChild("timestamp").startAt(value[0].getTime())
                .endAt(value[1].getTime()).once('value', archivesSnap => {
                let archivesJSON = archivesSnap.exists()?archivesSnap.toJSON(): {};
                let archivesKeys = Object.keys(archivesJSON);
                let count = archivesKeys.length;
                let index = 0;
                //console.log("archives count ", count)
                if(archivesKeys.length === 0){
                    setConversationRows([]);
                    setTotalConversationsCount({});
                    setTotalInactiveConversationCount({});
                    setTotalBillableConversationCount({});
                    setTotalBilledConversationCount({});
                    setPendingBillableConversationCount({});
                }
                archivesKeys.map(key=>{
                    //console.log("index ", index)
                    if(archivesJSON[key]?.referralChat){
                        let obj = {
                            conversationID : key,
                            timestamp : archivesJSON[key]?.timestamp || value[0].getTime(),
                            patientName : archivesJSON[key]?.patientName || null,
                            fromDoctorName: archivesJSON[key]?.fromDoctorName || null,
                            adminDoctor: archivesJSON[key]?.adminDoctor || null,
                            issueResolved: archivesJSON[key]?.issueResolved || null,
                            referralType: archivesJSON[key]?.referralType || null,
                            referralFromApp: archivesJSON[key]?.referralFromApp || false,
                            doctorName: archivesJSON[key]?.doctorID? doctors[archivesJSON[key]?.doctorID]?.doctorName || "" : ""
                        }
                        console.log("key ", key)
                        console.log("archivesJSON[key]?.doctorID ", archivesJSON[key]?.doctorID)
                        console.log("doctors[archivesJSON[key]?.doctorID] ", doctors[archivesJSON[key]?.doctorID])


                        database.ref(`emrEncounters/${key}`).once('value', emrEncounterSnap =>{
                            let {doctorName, emr_encounter_id} = emrEncounterSnap.exists()?emrEncounterSnap.toJSON():{};
                            obj.billedDoctorName = doctorName || "";
                            if(obj.billedDoctorName && obj.billedDoctorName !== obj.adminDoctor){
                                console.log("wrong data conversationID ", key)
                            }
/*
                            if(!emr_encounter_id && doctorName && obj.issueResolved !== "inactive_chat" && doctorName === obj.adminDoctor){
                                let encounterData = {...(emrEncounterSnap.toJSON())};
                                if(encounterData.hcardversion){
                                    encounterData.hcardversion = encounterData.hcardversion.toUpperCase();
                                }
                                encounterData.conversation_id = key;
                                console.log("emrEncounters encounterData", encounterData)
                                if(!encounterData.emr_saved){
                                    timerIncrement++;
                                    console.log("timerIncrement", timerIncrement)
                                    setTimeout(()=> {

                                        fetch('http://localhost:5001/yourdoctorsonline-prod/us-central1/storeEMRDataForTier1', {
                                            method: "POST",
                                            headers: {
                                                "Content-Type": "application/json",
                                            },
                                            body: JSON.stringify(encounterData),
                                        })
                                            .then(data => {

                                                console.log("emrEncounterss encounter saved with data, ", data)
                                            })
                                            .catch(error => {

                                                console.log("emrEncounterss failed", error)

                                            })
                                    },timerIncrement*2000)
                                }else{
                                    console.log("emrEncounters already saved")
                                }
                            }
*/
                            obj.emr_encounter_id = emr_encounter_id || ""
                            if(!obj.patientName && archivesJSON[key]?.patientID){
                                database.ref(`users/${archivesJSON[key]?.patientID}`).once('value', userSnap => {
                                    let userJSON = userSnap.exists() ? userSnap.toJSON() : {};
                                    let name = userJSON.name || userJSON.userName || "";
                                    if(archivesJSON[key]?.owner && userJSON.profiles && userJSON.profiles[archivesJSON[key]?.owner]){
                                        name = userJSON.profiles[archivesJSON[key]?.owner].name || userJSON.profiles[archivesJSON[key]?.owner].userName || "";
                                    }
                                    obj.patientName = name;
                                    conversationsObj.push(obj)
                                    index++;
                                    if(index === count){
                                        setLoading(false)
                                        setConversationRows(conversationsObj)
                                        //setTotalConversationsCount(conversationsObj.length);
                                        let inactive = {};
                                        let billable = {};
                                        let billed = {};
                                        let pendingBillable = {};
                                        let total = {
                                            "all": conversationsObj.length
                                        };

                                        conversationsObj.forEach(convo=>{
                                            total[convo.adminDoctor || "Unset"] = (convo[convo.adminDoctor || "Unset"] || 0) + 1
                                            if(convo.issueResolved === "inactive_chat"){
                                                inactive.all = (inactive.all || 0) + 1
                                                inactive[convo.adminDoctor || "Unset"] = (inactive[convo.adminDoctor || "Unset"] || 0) + 1
                                            }

                                            if(convo.emr_encounter_id && convo.issueResolved !== "inactive_chat"){
                                                billed.all = (billed.all || 0) + 1
                                                billed[convo.billedDoctorName || "Unset"] = (billed[convo.billedDoctorName || "Unset"] || 0) + 1
                                            }

                                            if(convo.billedDoctorName && convo.issueResolved !== "inactive_chat"){
                                                billable.all = (billable.all || 0) + 1;
                                                billable[convo.billedDoctorName || "Unset"] = (billable[convo.billedDoctorName || "Unset"] || 0) + 1;
                                            }
                                            if(!convo.emr_encounter_id && convo.billedDoctorName && convo.issueResolved !== "inactive_chat"){
                                                pendingBillable.all = (pendingBillable.all || 0) + 1;
                                                pendingBillable[convo.billedDoctorName || "Unset"] = (pendingBillable[convo.billedDoctorName || "Unset"] || 0) + 1;
                                            }
                                        })
                                        setPendingBillableConversationCount(pendingBillable)
                                        setTotalInactiveConversationCount(inactive);
                                        setTotalBillableConversationCount(billable);
                                        setTotalBilledConversationCount(billed);
                                        setTotalConversationsCount(total);
                                    }
                                })
                            }else{
                                conversationsObj.push(obj)
                                index++;
                                if(index === count){
                                    setLoading(false)
                                    setConversationRows(conversationsObj)
                                    let inactive = {};
                                    let billable = {};
                                    let billed = {};
                                    let pendingBillable = {};
                                    let total = {
                                        "all": conversationsObj.length
                                    };

                                    conversationsObj.forEach(convo=>{
                                        total[convo.adminDoctor || "Unset"] = (total[convo.adminDoctor || "Unset"] || 0) + 1
                                        if(convo.issueResolved === "inactive_chat"){
                                            inactive.all = (inactive.all || 0) + 1
                                            inactive[convo.adminDoctor || "Unset"] = (inactive[convo.adminDoctor || "Unset"] || 0) + 1
                                        }

                                        if(convo.emr_encounter_id && convo.issueResolved !== "inactive_chat"){
                                            billed.all = (billed.all || 0) + 1
                                            billed[convo.billedDoctorName || "Unset"] = (billed[convo.billedDoctorName || "Unset"] || 0) + 1
                                        }

                                        if(convo.billedDoctorName && convo.issueResolved !== "inactive_chat"){
                                            billable.all = (billable.all || 0) + 1;
                                            billable[convo.billedDoctorName || "Unset"] = (billable[convo.billedDoctorName || "Unset"] || 0) + 1;
                                        }
                                        if(!convo.emr_encounter_id && convo.billedDoctorName && convo.issueResolved !== "inactive_chat"){
                                            pendingBillable.all = (pendingBillable.all || 0) + 1;
                                            pendingBillable[convo.billedDoctorName || "Unset"] = (pendingBillable[convo.billedDoctorName || "Unset"] || 0) + 1;
                                        }
                                    })
                                    setPendingBillableConversationCount(pendingBillable)
                                    setTotalInactiveConversationCount(inactive);
                                    setTotalBillableConversationCount(billable);
                                    setTotalBilledConversationCount(billed);
                                    setTotalConversationsCount(total);
                                }
                            }

                        })

                    }else{
                        index++;
                        if(index === count){
                            setLoading(false)
                            setConversationRows(conversationsObj)
                            let inactive = {};
                            let billable = {};
                            let billed = {};
                            let pendingBillable = {};
                            let total = {
                                "all": conversationsObj.length
                            };

                            conversationsObj.forEach(convo=>{
                                total[convo.adminDoctor || "Unset"] = (total[convo.adminDoctor || "Unset"] || 0) + 1
                                if(convo.issueResolved === "inactive_chat"){
                                    inactive.all = (inactive.all || 0) + 1
                                    inactive[convo.adminDoctor || "Unset"] = (inactive[convo.adminDoctor || "Unset"] || 0) + 1
                                }

                                if(convo.emr_encounter_id && convo.issueResolved !== "inactive_chat"){
                                    billed.all = (billed.all || 0) + 1
                                    billed[convo.billedDoctorName || "Unset"] = (billed[convo.billedDoctorName || "Unset"] || 0) + 1
                                }

                                if(convo.billedDoctorName && convo.issueResolved !== "inactive_chat"){
                                    billable.all = (billable.all || 0) + 1;
                                    billable[convo.billedDoctorName || "Unset"] = (billable[convo.billedDoctorName || "Unset"] || 0) + 1;
                                }
                                if(!convo.emr_encounter_id && convo.billedDoctorName && convo.issueResolved !== "inactive_chat"){
                                    pendingBillable.all = (pendingBillable.all || 0) + 1;
                                    pendingBillable[convo.billedDoctorName || "Unset"] = (pendingBillable[convo.billedDoctorName || "Unset"] || 0) + 1;
                                }
                            })
                            setPendingBillableConversationCount(pendingBillable)
                            setTotalInactiveConversationCount(inactive);
                            setTotalBillableConversationCount(billable);
                            setTotalBilledConversationCount(billed);
                            setTotalConversationsCount(total);
                        }
                    }

                })
            })
        }
    }

    const dateFunction = (arr)=>{
        if(arr){
            let myTimezone = "America/Toronto";
            let date = moment(arr[0]).tz(myTimezone);
            date.set("hours", 0)
                .set("minutes", 0)
                .set("seconds", 0)
                .set("milliseconds", 0);
            //setStartDate(date.toDate())

            let end = moment(arr[1]).tz(myTimezone);
            end.set("hours", 23)
                .set("minutes", 99)
                .set("seconds", 99)
                .set("milliseconds", 99);;
            //setEndDate(end.toDate());
            setValue([date.toDate(), end.toDate()])

            //setStartDate(arr[0]);
            //setEndDate(arr[1]);
            //setSelectedOption({ value: 'custom', label: 'Custom' })

            /*const firstDateFormat =moment(arr[0]).format("YYYY-MM-DD")
            query.set('startDate',firstDateFormat)

            const secondDateFormat =moment(arr[1]).format("YYYY-MM-DD")
            query.set('endDate',secondDateFormat)

            const paramsString = query.toString();
            const url = new URL(window.location.href);
            url.search = paramsString;
            history.push(url.search);*/
        }

    }

    const updateDate = (d) => {
        let myTimezone = "America/Toronto";
        let date = moment(d).tz(myTimezone);
        date.set("hours", 0)
            .set("minutes", 0)
            .set("seconds", 0)
            .set("milliseconds", 0);
        //setStartDate(date.toDate())

        let end = moment(d).tz(myTimezone);
        end.set("hours", 23)
            .set("minutes", 99)
            .set("seconds", 99)
            .set("milliseconds", 99);
        //setEndDate(end.toDate());
        setValue([date.toDate(), end.toDate()])
        console.log("startDate", date.toDate())
        console.log("endDate", end.toDate())
    }

    const tableHead = () => {
        return(
            <thead>
            <tr>
                <th>#</th>
                <th>conversationID</th>
                <th>Date</th>
                <th>Patient Name</th>
                <th>Doctor Name</th>
                <th>Referred by</th>
                <th>Referred to</th>
                <th>Billed to</th>
                <th>EMR ID</th>
                <th>Status</th>
                <th>Type</th>
                <th>Source</th>
            </tr>
            </thead>
        )
    }
    const renderTable = () => {

        return (<Table striped bordered hover>
            {tableHead()}
            <tbody>
            {filterConvos.map((row, index) =>{
                return(<tr key={row.conversationID}/*onClick={()=> navigator.clipboard.writeText(JSON.stringify(row.data))}*/>
                    <td>{index+1}</td>
                    <td>{row.conversationID}</td>
                    <th>{(new Date(row.timestamp)).toLocaleDateString("en-GB")}</th>
                    <th>{row.patientName || ""}</th>
                    <th>{row.doctorName || ""}</th>
                    <th>{row.fromDoctorName || ""}</th>
                    <th>{row.adminDoctor || ""}</th>
                    <th>{row.billedDoctorName || ""}</th>
                    <th>{row.emr_encounter_id || ""}</th>
                    <th>{row.issueResolved || "virtually"}</th>
                    <th>{row.referralType || ""}</th>
                    <th>{row.referralFromApp?"From App" : "Referral"}</th>
                </tr>)
            })}


            </tbody>
        </Table>)
    }


    return (
        <div className={classes.container}>
            <div className={classes.header}>
                {value && <div>
                    <DateRangePicker
                        value={value}
                        onChange={dateFunction}
                        showMeridian
                        format="yyyy-dd-MM"
                    />
                </div>}

                {!loading?<div className={classes.search_button} onClick={fetchChats}>
                    Fetch Referrals
                </div>:<div className={classes.search_button}>
                    <Spinner  color={"black"}  isLoading={true}/>
                </div>}


            </div>

            <div className={classes.stats}>
                <div><input type="checkbox" checked={hideInactive} onClick={()=>setHideInactive(!hideInactive)} /> HIDE INACTIVE CHATS </div>
                {Object.keys(totalConversationsCount || {}).map(key=>{
                    let label = key === "all"? "Total" : key;
                    return <div>{label} Chats: {totalConversationsCount[key] || 0}</div>
                })}
                {Object.keys(totalConversationsCount || {}).length>0 && "------------------"}
                {Object.keys(totalConversationsCount || {}).map(key=>{
                    let label = key === "all"? "Total" : key;
                    return <div>{label} Active Chats: {(totalConversationsCount[key] || 0) - (totalInactiveConversationCount[key] || 0)} </div>
                })}
                {Object.keys(totalConversationsCount || {}).length>0 && "------------------"}
                {Object.keys(totalInactiveConversationCount || {}).map(key=>{
                    let label = key === "all"? "Total" : key;
                    return <div>{label} In-active Chats: {(totalInactiveConversationCount[key])} </div>
                })}
                {Object.keys(totalInactiveConversationCount || {}).length>0 && "------------------"}
                {Object.keys(totalBillableConversationCount || {}).map(key=>{
                    let label = key === "all"? "Total" : key;
                    return <div>{label} Billable Chats: {(totalBillableConversationCount[key])} </div>
                })}
                {Object.keys(totalBillableConversationCount || {}).length>0 && "------------------"}
                {Object.keys(pendingBillableConversationCount || {}).map(key=>{
                    let label = key === "all"? "Total" : key;
                    return <div>{label} Pending Billable Chats: {(pendingBillableConversationCount[key])} </div>
                })}
                {Object.keys(pendingBillableConversationCount || {}).length>0 && "------------------"}
                {Object.keys(totalBilledConversationCount || {}).map(key=>{
                    let label = key === "all"? "Total" : key;
                    return <div>{label} Billed Chats: {(totalBilledConversationCount[key])} </div>
                })}
                {Object.keys(totalBilledConversationCount || {}).length>0 && "------------------"}
               {/* <div>Total Billable Chats: {totalBillableConversationCount?.all} </div>
                <div>Pending Billable Chats: {pendingBillableConversationCount?.all} </div>
                <div>Total Billed Chats: {totalBilledConversationCount?.all} </div>*/}


            </div>
            {filterConvos.length>0 &&<div>
                {renderTable()}
            </div>}



        </div>
    )


}
export default ReferralPanel;

