
import classes from '../../../../assets/classes.module.css';


const Header = (props) => {
    return (
        <div className={classes.main_body}>
            <div className={classes.header_bar}>
                <span className={props.activeLink === 'chats' ? classes.active : classes.un_active}
                      onClick={() => props.headerItem('chats')}>Chats</span>
                <span className={props.activeLink === 'findings' ? classes.active : classes.un_active}
                      onClick={() => props.headerItem('findings')}>Objective Findings</span>
                <span className={props.activeLink === 'assessment' ? classes.active : classes.un_active}
                      onClick={() => props.headerItem('assessment')}>Assessment</span>
                <span className={props.activeLink === 'plan' ? classes.active : classes.un_active}
                      onClick={() => props.headerItem('plan')}>Plan</span>
            </div>
        </div>
    )
}

export default Header;
