import React, {useEffect, useState, Fragment, useRef} from 'react'
import classes from "../../../components/pharmacyComponent/pharmacycomponent.module.css";
import TextareaAutosize from "react-autosize-textarea";
import Button from "react-bootstrap/Button";
import Image from "../../../components/Image";
import {connect} from "react-redux";
import Spinner from "../../../components/Spinner";
import {database} from "../../../firebase";
import {updatePatient} from "../../../actions/ConversationAction";
import moment from "moment";
import _ from "lodash";
import axios from "axios";
import {firebaseRoot} from "../../../config";

const ParentInfo = (props) => {
    const [showBox, setShowBox] = useState(true)
    const [ownerID, setOwnerID] = useState(null)
    useEffect(()=>{
        setOwnerID(props.selectedConversationObj?.owner)
    },[props.selectedConversationObj])


    const setShowBoxFunction = () => {
        setShowBox(!showBox)
    }

    if(ownerID){
        return (
            <div className={classes.generalInformation}>
                <Fragment>
                    <div className={classes.openchatlist}
                         onClick={setShowBoxFunction}>
                        <div className={classes.openchatlistSection}>
                            <div
                                className={showBox === true ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                                <svg fill="#884a9d" width="12px" height="8px"
                                     viewBox="0 0 12 8">
                                    <path fill="inherit" fillRule="evenodd"
                                          d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                </svg>
                            </div>
                            <div className={classes.subListTitle}>Dependent of:</div>
                        </div>
                    </div>
                    {showBox &&
                    <div className={classes.expandable_section}>
                        {
                            <div className={classes.general_info_section}>
                                <div>
                                    <span className={classes.header_attr}>Name:</span>
                                    <span className={classes.header_attr_value}>{props?.selectedPatient?.name || props?.selectedPatient?.userName || 'N/A'}</span>
                                </div>
                                <div>
                                    <span className={classes.header_attr}>Gender:</span>
                                    <span className={classes.header_attr_value}>{(props?.selectedPatient?.gender || 'N/A')}</span>
                                </div>
                                <div>
                                    <span className={classes.header_attr}>DOB:</span>
                                    <span className={classes.header_attr_value}>{props.selectedPatient?.DOB }</span>
                                </div>
                            </div>
                        }
                    </div>
                    }
                </Fragment>
            </div>
        )
    }else{
        return <div></div>
    }

}
const mapStateToProps = ({ conversationReducer }) => {
    const { patientId, conversationId, conversations, selectedPatient, selectedConversationObj } = conversationReducer;
    return { patientId, conversationId, conversations, selectedPatient, selectedConversationObj};
};

export default connect(mapStateToProps, {updatePatient})(ParentInfo);
