import {
    CLINICAL_PATIENTS_FETCH_CLEAR,
    CLINICAL_PATIENTS_FETCH_SUCCESS,
    SCHEDULE_SLOTS,
    SLOTS_LOADING,
    UPDATE_PATIENT
} from '../constants';

import doctorServicesUrl from '../DoctorServicesConfig';
import {ENVIRONMENT} from '../environment';
import {database} from "../firebase";

var moment = require("moment");
var clinicalpatientsListener = null;

export const sendUnFilledInvites = (patientsInvites, clinicId, clinic_name, callback) => {
    return (dispatch) => {
        if (patientsInvites.length > 0) {
            for (var i = 0; i < patientsInvites.length; i++) {
                var params = 'email=' + encodeURIComponent(patientsInvites[i]) + '&' +
                    'clinic_name=' + encodeURIComponent(clinic_name) + '&' +
                    'clinic_id=' + encodeURIComponent(clinicId) + '&isManual=false';
                let url = '';
                if (ENVIRONMENT === 'development') {
                    url = "https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/sendClinicPatientEmail?";
                } else {
                    url = "https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/sendClinicPatientEmail?";
                }
                fetch(url + params)
                    .then(() => {
                        callback(true, "Invitation sent successfully");
                    })
                    .catch((e) => {
                        callback(false, "Invitation sent failed due to error: ", e);
                    })
            }
        } else {
            callback(false, "no email found.");
        }
    }
}
export const storeSlot = (clinicId, doctor, patientid, patient, startTime, duration, followupid, conversationId, callback, patientissue, category) => {
    return (dispatch) => {
        var startDate = new Date(startTime);

        startTime = startDate.getTime();
        var endTime = startTime + duration * 60000;
        var slot = {
            doctorName: doctor.fullName,
            patientid: patientid,
            startTime: startTime,
            endTime: startTime + duration * 60000
        };

        getUserRef(patient.userId).once('value', patientRef => {

            if (patientRef.val()) {
                let tier2 = false
                console.log('conversations', patientRef.val(), clinicId)
                if (Boolean(patientRef.val()?.conversations?.tier2)) {
                    tier2 = true
                }
                if (clinicId === 'transferredFromTier2') {
                    tier2 = true
                }
                var todayDate = new Date();
                let gmtOffset = (patientRef.val().gmtOffset) ? patientRef.val().gmtOffset : 0;
                todayDate.setDate(todayDate.getDate() + 3);

                var booked = {
                    user_id: patientRef.val().user_id,
                    doctor_name: doctor.fullName,
                    patient_name: (patientRef.val().userName) ? patientRef.val().userName : patientRef.val().name,
                    category: "Scheduled appointment",
                    scheduled_date: startDate.toISOString(),
                    actual_date: startDate.toISOString(),
                    notification_sent: 0,
                    patient_issue: (patientissue) ? patientissue : `Scheduled appointment with ${doctor.fullName}`,
                    message: `Hi, my name is ${doctor.fullName}. How can I help you?`,
                    doctor_firebaseid: doctor.uid,
                    patient_firebaseid: patient.userId,
                    completed: 0,
                    clicked: 0,
                    clinic_id: clinicId,
                    slot: duration,
                    tier2,
                    conversation_id: conversationId,
                    end_date: (new Date(endTime)).toISOString(),
                    medical_details: Boolean(patient?.transferredFromTier2) ? "transferredFromTier2" : ""
                }
                if (followupid) {
                    booked.id = followupid;
                    fetch(`${doctorServicesUrl}/update_clinical_followup/`, {
                        method: "put",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(booked)

                    })
                        .then(response => response.json())
                        .then(data => {
                            console.log("save followupdata2 ", data);
                            callback(true, followupid);
                        })
                        .catch((error) => {
                            console.log("save followupdata2 ", error);
                            callback(false, error);
                        })
                } else {
                    fetch(`${doctorServicesUrl}/save_clinical_followup/`, {
                        method: "post",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(booked)

                    })
                        .then(response => response.json())
                        .then(data => {
                            console.log("save followupdata2 ", data);
                            callback(true, data);
                        })
                        .catch((error) => {
                            console.log("save followupdata error2 ", error);
                            callback(false, error);
                        })
                }

            } else {
                callback(false, "unable to find patient");
            }
        }).then(() => {
        })
            .catch(error => {
                callback(false, error);
            })
    }
}
export const fetchTimeSlots = (doctor, patientid, patient, date, duration) => {
    return (dispatch) => {
        dispatch({type: SLOTS_LOADING});
        console.log("fetchTimeSlots doctor", doctor);
        console.log("fetchTimeSlots patientid", patientid, {});
        console.log("fetchTimeSlots date", date);
        console.log("fetchTimeSlots duration", duration);
        let url = `${doctorServicesUrl}/fetch_clinical_followup/${encodeURI(patient.userId)}/${encodeURI(doctor.uid)}`;
        fetch(url)
            .then(response => response.json())
            .then(data => {
                let bookedSlots = data;

                var slots = [];
                var startDate = new Date(date);
                startDate.setHours(0, 0, 0, 0);
                startDate = startDate.getTime();
                var endDate = new Date(date);
                endDate.setDate(endDate.getDate() + 1);
                endDate.setHours(0, 0, 0, 0);
                endDate = endDate.getTime();
                console.log("startDate ", moment(startDate).format('MMMM Do YYYY, h:mm:ss a'));
                console.log("endDate ", moment(endDate).format('MMMM Do YYYY, h:mm:ss a'));
                var endDuration = startDate + duration * 60000;

                while (endDate >= endDuration) {
                    let slot = {
                        startTime: startDate,
                        endTime: endDuration
                    }
                    startDate = endDuration;
                    endDuration = startDate + duration * 60000;
                    slots.push(slot);
                }
                for (let i = 0; i < bookedSlots.length; i++) {
                    console.log("fetchTimeSlots " + i + " startTime " + moment(bookedSlots[i].scheduledDate).format("MMMM Do YYYY, h:mm:ss a"), " endTime ", moment(bookedSlots[i].endDate).format("MMMM Do YYYY, h:mm:ss a"));
                }
                var newSlots = [];
                for (let i = 0; i < slots.length; i++) {
                    let isconflict = false;
                    for (let j = 0; j < bookedSlots.length && !isconflict; j++) {
                        if (bookedSlots[j].scheduledDate < slots[i].endTime && bookedSlots[j].endDate > slots[i].startTime) {
                            isconflict = true;
                        }
                    }
                    if (!isconflict) {
                        newSlots.push(slots[i]);
                    }
                }

                dispatch({
                    type: SCHEDULE_SLOTS,
                    payload: newSlots
                })
            })
            .catch(error => {
                console.log("fetchTimeSlots error ", error)
            })
    }
}

export const sendInvites = (patientsInvites, clinicID, clinic_name, callback) => {
    console.log("patientsInvites ", patientsInvites);
    return (dispatch) => {
        if (patientsInvites.length > 0) {
            var updates = {};
            for (var i = 0; i < patientsInvites.length; i++) {
                let clinicalPatientRegistrykey = getClinicalPatientRegistryRef(clinicID).push().key;
                let clinicalPatientRegistryValue = patientsInvites[i];
                clinicalPatientRegistryValue.registrationStatus = 'mailsent';
                clinicalPatientRegistryValue.timestamp = (new Date().getTime());
                updates[`/clinicalPatients/${clinicID}/${clinicalPatientRegistrykey}`] = clinicalPatientRegistryValue;

                var params = 'email=' + encodeURIComponent(patientsInvites[i].email) + '&' +
                    'clinic_patient_registry_key=' + encodeURIComponent(clinicalPatientRegistrykey) + '&' +
                    'clinic_name=' + encodeURIComponent(clinic_name) + '&' +
                    'clinic_id=' + encodeURIComponent(clinicID) + '&' +
                    'patient_name=' + encodeURIComponent(patientsInvites[i].firstName) + '&isManual=true';

                let url = '';
                if (ENVIRONMENT === 'development') {
                    url = "https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/sendClinicPatientEmail?";
                } else {
                    url = "https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/sendClinicPatientEmail?";
                }
                fetch(url + params)
                    .then(() => {
                        console.log("mailsent");
                        callback(true, "Invitation sent successfully");
                    })
                    .catch((e) => {
                        console.log("mail failed ", e);
                        callback(false, "Invitation sent failed due to error: ", e);
                    })
            }
            if (patientsInvites.length > 0) {
                database.ref().update(updates)
                    .then(() => {

                    })
                    .catch((error) => {

                    })
            }
        } else {
            callback(false, "no email found.");
        }
    }
}
export const removeClinicalPatientsListener = () => {
    return (dispatch) => {
        console.log("removeClinicalPatientsListener");
        if (clinicalpatientsListener) {
            clinicalpatientsListener.off();
            clinicalpatientsListener = null;
        }
    }
}
export const deletePatients = (patients, clinicId) => {
    return (dispatch) => {


        var updates = {};
        for (var i = 0; i < patients.length; i++) {
            updates[`clinicalPatients/${clinicId}/${patients[i]}`] = null;
        }
        console.log("updates ", updates);
        if (patients.length > 0) {
            database.ref().update(updates)
                .then(() => {

                })
                .catch((error) => {

                })
        }

    }
}

export const updateRegistrationStatus = (patientID, status) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_PATIENT,
            payload: {patientID, status}
        })
    }
}

export const fetchRegisteredPatients = (clinicID) => {
    return (dispatch) => {
        console.log("fetchRegisteredPatients called");
        //const { currentUser } = auth;
        //let currentUserID = currentUser.uid;
        dispatch({
            type: CLINICAL_PATIENTS_FETCH_CLEAR,
            payload: []
        })
        //getDoctorRef(currentUserID).once('value', doctorSnap => {
        //if (doctorSnap.val()) {
        //var doctorClinicName = doctorSnap.val().clinicName.toLowerCase();
        clinicalpatientsListener = getClinicalPatientRef(clinicID);
        clinicalpatientsListener.on('child_added', clinicalPatientSnap => {

            if (clinicalPatientSnap.val()) {
                let clinicalPatient = clinicalPatientSnap.val();
                clinicalPatient.uid = clinicalPatientSnap.key;
                var date = new Date(clinicalPatientSnap.val().timestamp);
                console.log("moment ", moment.unix(date).isAfter(moment().subtract(24, 'hours')));
                if (clinicalPatientSnap.val().registrationStatus === 'mailsent') {
                    const actualTimeMilliseconds = new Date().getTime()
                    if (actualTimeMilliseconds - clinicalPatientSnap.val().timestamp > 86400000) {
                        clinicalPatient.registrationStatus = 'outstanding';
                        var updates = {};
                        updates[`clinicalPatients/${clinicID}/${clinicalPatientSnap.key}/registrationStatus`] = 'outstanding';
                        database.ref().update(updates)
                    }
                }

                if (clinicalPatientSnap.val().userId) {
                    getUserRef(clinicalPatientSnap.val().userId).once('value', userSnap => {
                        if (userSnap.val()) {
                            if (userSnap.val().gmtOffset) {
                                clinicalPatient.gmtOffset = userSnap.val().gmtOffset;
                            }
                        }
                    })
                }

                dispatch({
                    type: CLINICAL_PATIENTS_FETCH_SUCCESS,
                    payload: clinicalPatient
                })

            }
        })
        //}
        //})
    }
}
export const updatePatient = (data, clinicid) => {
    return (dispatch) => {
        console.log("fetchRegisteredPatients called");
        var updates = {};
        updates[`clinicalPatients/${clinicid}/${data.uid}/firstName`] = data.firstName;
        updates[`clinicalPatients/${clinicid}/${data.uid}/lastName`] = data.lastName;
        updates[`clinicalPatients/${clinicid}/${data.uid}/gender`] = data.gender;
        updates[`clinicalPatients/${clinicid}/${data.uid}/dob`] = data.dob;
        updates[`clinicalPatients/${clinicid}/${data.uid}/insuranceCardNo`] = data.insuranceCardNo;
        updates[`clinicalPatients/${clinicid}/${data.uid}/email`] = data.email;
        database.ref().update(updates)
            .then(() => {
            })
            .catch((e) => {
                console.log("update error ", e)
            })

    }
}
const getDoctorRef = (uid) => {
    return database.ref(`/doctors/${uid}`);
}

const getClinicalPatientRegistryRef = (clinicID) => {
    return database.ref().child(`clinicalPatients/${clinicID}`);
}
const getClinicalPatientRef = (clinicID) => {
    return database.ref(`clinicalPatients/${clinicID}`);
}
const getUserRef = (uid) => {
    return database.ref(`/users/${uid}`);
}
const getUserHistoryConversationRef = (userId) => {
    return database.ref(`/users/${userId}/history_conversations`);
}

const getStaffMemberByRef = (clinicid, uid) => {
    return database.ref(`/clinicStaffMember/${clinicid}/${uid}`);
}
