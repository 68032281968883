import  {Component} from 'react';
import {connect} from 'react-redux';


import classes from '../../../../assets/classes.module.css';

import {sendInvites, sendUnFilledInvites} from '../../../../actions/clinicalPatientsActions';

var moment = require("moment");

class InvitePatient extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeLink: 'invitepatients',
            isManualInfo: false,
            firstName: '',
            lastName: '',
            DOB: '',
            healthInsuranceNo: '',
            patientEmail: '',
            gender: '',
            patientEmails: '',
            showAddPatientError: false,
            addPatientError: '',
            showAddPatientSuccess: false,
            addPatientSuccess: '',
            isloading: false
        }
    }

    componentDidMount() {
    }


    sendRegistrationEmail = () => {
        this.setState({isloading: true});
        if (this.state.isManualInfo) {
            var now = new Date(this.state.DOB)
            var DOBFormat = moment(now).format('YYYY-MM-DD');
            var data = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                dob: DOBFormat,
                insuranceCardNo: this.state.healthInsuranceNo,
                email: this.state.patientEmail,
                gender: this.state.gender
            }
            if (!Boolean(this.state.firstName) || !Boolean(this.state.lastName) || !Boolean(this.state.DOB) || !Boolean(this.state.healthInsuranceNo) || !Boolean(this.state.patientEmail) || !Boolean(this.state.gender)) {
                this.setState({
                    showAddPatientError: true,
                    addPatientError: "empty fields are not allowed",
                    showAddPatientSuccess: false,
                    isloading: false
                });
            } else {
                var re = /\S+@\S+\.\S+/;
                if (!re.test(this.state.patientEmail)) {
                    this.setState({
                        showAddPatientError: true,
                        addPatientError: `${this.state.patientEmail} is not a valid email`,
                        isloading: false
                    })
                } else {
                    this.props.sendInvites([data], this.props.clinicId, this.props.clinic_name, (status, msg) => {
                        if (status) {
                            this.setState({
                                showAddPatientModal: false,
                                firstName: '',
                                lastName: '',
                                gender: '',
                                DOB: '',
                                healthInsuranceNo: '',
                                patientEmail: '',
                                showAddPatientError: false,
                                addPatientError: '',
                                showAddPatientSuccess: true,
                                addPatientSuccess: msg,
                                isloading: false

                            });
                        } else {
                            this.setState({
                                showAddPatientModal: false,
                                firstName: '',
                                lastName: '',
                                gender: '',
                                DOB: '',
                                healthInsuranceNo: '',
                                patientEmail: '',
                                showAddPatientError: true,
                                addPatientError: msg,
                                showAddPatientSuccess: false,
                                addPatientSuccess: '',
                                isloading: false
                            });
                        }

                    });
                }
            }
        } else {
            if (this.state.patientEmails) {
                var patientEmails = this.state.patientEmails.replace(/\s/g, '').split(',');
                var errorEmail = '';
                var re = /\S+@\S+\.\S+/;
                for (var i = 0; i < patientEmails.length; i++) {
                    if (!re.test(patientEmails[i])) {
                        errorEmail = patientEmails[i];
                        break;
                    }
                }
                if (errorEmail !== '') {
                    this.setState({
                        showAddPatientError: true,
                        addPatientError: `${errorEmail} is not a valid email`,
                        showAddPatientSuccess: false,
                        isloading: false
                    });
                } else {
                    this.props.sendUnFilledInvites(patientEmails, this.props.clinicId, this.props.clinic_name, (status, msg) => {
                        if (status) {
                            this.setState({
                                showAddPatientError: false,
                                addPatientError: '',
                                showAddPatientSuccess: true,
                                addPatientSuccess: msg,
                                isloading: false
                            });
                        } else {
                            this.setState({
                                showAddPatientError: true,
                                addPatientError: msg,
                                showAddPatientSuccess: false,
                                addPatientSuccess: '',
                                isloading: false
                            });

                        }
                    });
                }
            } else {
                this.setState({isloading: false});
            }


        }


    }

    render() {

        return (
            <div className={classes.main_container}>
                <div className={classes.main_container_header}>Invite your patients by asking them to register to your
                    clinic or if you have their details, you can manually invite them.
                </div>
                <div className={classes.form_container_view}>

                    <div>
                        <div className={classes.invitePatientRadioButton}>
                            <input type='radio' checked={this.state.isManualInfo === false} onChange={() => {
                                this.setState({isManualInfo: false})
                            }} className={classes.marginRight5}/>
                            <span>Invite a patient to create their profile</span>
                        </div>
                        <div className={classes.invitePatientRadioButton}>
                            <input type='radio' checked={this.state.isManualInfo === true} onChange={() => {
                                this.setState({isManualInfo: true})
                            }} className={classes.marginRight5}/>
                            <span>Manually enter patient’s information</span>
                        </div>
                    </div>
                    {!this.state.isManualInfo &&
                    <div>
                        <span>Enter your patient’s email address so that they can regsiter to your clinic. You can enter multiple patient email addresses seperated by a comma.</span>
                        <div className={classes.form_row}>
                            <span className={classes.label}>Email Address:</span>
                            <input type='text' className={classes.form_input}
                                   onChange={(e) => {
                                       this.setState({patientEmails: e.target.value})
                                   }}
                                   value={this.state.patientEmails}/>
                        </div>
                    </div>
                    }
                    {this.state.isManualInfo &&
                    <div>
                        <div className={classes.form_row}>
                            <span className={classes.label}>First Name:</span>
                            <input type='text' className={classes.form_input}
                                   onChange={(e) => {
                                       this.setState({firstName: e.target.value})
                                   }}
                                   value={this.state.firstName}
                            />
                        </div>
                        <div className={classes.form_row}>
                            <span className={classes.label}>Last Name:</span>
                            <input type='text' className={classes.form_input}
                                   onChange={(e) => {
                                       this.setState({lastName: e.target.value})
                                   }}
                                   value={this.state.lastName}
                            />
                        </div>
                        <div className={classes.form_row}>
                            <span className={classes.label}>Gender:</span>
                            <select className={classes.form_small_input} onChange={(e) => {
                                this.setState({gender: e.target.value})
                            }}>
                                <option key={0} value={''}></option>
                                <option key={1} value={'Male'}>Male</option>
                                <option key={2} value={'Female'}>Female</option>
                            </select>

                        </div>
                        <div className={classes.form_row}>
                            <span className={classes.label}>Date of Birth:</span>
                            <input type='date' className={classes.form_small_input}
                                   value={this.state.DOB}
                                   onChange={(e) => {
                                       this.setState({DOB: e.target.value})
                                   }}/>
                        </div>
                        <div className={classes.form_row}>
                            <span className={classes.label}>Health Insurance:</span>
                            <input type='text' className={classes.form_input}
                                   onChange={(e) => {
                                       this.setState({healthInsuranceNo: e.target.value})
                                   }}
                                   value={this.state.healthInsuranceNo}/>
                        </div>
                        <div className={classes.form_row}>
                            <span className={classes.label}>Email Address:</span>
                            <input type='text' className={classes.form_input}
                                   onChange={(e) => {
                                       this.setState({patientEmail: e.target.value})
                                   }}
                                   value={this.state.patientEmail}/>
                        </div>
                    </div>
                    }
                    {this.state.showAddPatientError &&
                    <div className={classes.center_view_error}><span>{this.state.addPatientError}</span></div>}
                    {this.state.showAddPatientSuccess &&
                    <div className={classes.center_view}><span>{this.state.addPatientSuccess}</span></div>}
                    <div className={classes.button_row}>
                        <button className={classes.unhiglighted_button} onClick={() => {
                            this.props.viewPatients()
                        }}>View Patients
                        </button>
                        {!this.state.isloading &&
                        <button className={classes.higlighted_button} onClick={this.sendRegistrationEmail}>Invite
                            Patients</button>}
                        {this.state.isloading && <button className={classes.higlighted_button_centered}>
                            <div className={classes.loading_loader_sm}/>
                        </button>}

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({clinicProfileSettingReducer}) => {

    const {clinicId, clinic_name, userType} = clinicProfileSettingReducer;


    return {
        clinicId, userType, clinic_name

    };
}

export default connect(mapStateToProps, {
    sendInvites, sendUnFilledInvites
})(InvitePatient);
