import {
    CLINIC_ASSESSMENT,
    CLINIC_CANNED_RESPONSE_DESCRIPTION,
    CLINIC_CANNED_RESPONSE_SAVE,
    CLINIC_CANNED_RESPONSE_SHORT_CUT,
    CLINIC_FETCH_CANNED_RESPONSE,
    CLINIC_FETCH_CANNED_RESPONSE_ASSESSMENT,
    CLINIC_FETCH_CANNED_RESPONSE_PLAN,
    CLINIC_FETCH_CHAT_CANNED_RESPONSE,
    CLINIC_FINDINGS,
    CLINIC_PLAN,
    CLINIC_SUBJECTIVE_SYMPTOMS
} from '../../constants';

const INITIAL_STATE = {
    clinic_description: '',
    clinic_short_cut: '',
    clinic_canned_responses: [],
    clinic_chats_canned_responses: [],
    clinic_canned_responses_assessment: [],
    clinic_canned_responses_plan: [],
    subjective_symptoms: '',
    findings: '',
    assessment: '',
    plan: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLINIC_CANNED_RESPONSE_DESCRIPTION:
            return {...state, clinic_description: action.payload};
        case CLINIC_CANNED_RESPONSE_SHORT_CUT:
            return {...state, clinic_short_cut: action.payload};
        case CLINIC_CANNED_RESPONSE_SAVE:
            return {...state, clinic_description: '', clinic_short_cut: ''};
        case CLINIC_FETCH_CANNED_RESPONSE:
            return {...state, clinic_canned_responses: action.payload};
        case CLINIC_FETCH_CHAT_CANNED_RESPONSE:
            return {...state, clinic_chats_canned_responses: action.payload};
        case CLINIC_FETCH_CANNED_RESPONSE_ASSESSMENT:
            return {...state, clinic_canned_responses_assessment: action.payload};
        case CLINIC_FETCH_CANNED_RESPONSE_PLAN:
            return {...state, clinic_canned_responses_plan: action.payload};
        case CLINIC_SUBJECTIVE_SYMPTOMS:
            return {...state, subjective_symptoms: action.payload};
        case CLINIC_FINDINGS:
            return {...state, findings: action.payload};
        case CLINIC_ASSESSMENT:
            return {...state, assessment: action.payload};
        case CLINIC_PLAN:
            return {...state, plan: action.payload};
        default:
            return state;
    }
}
