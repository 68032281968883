import  {Component} from 'react';
import {childChangedDoctor, fetchAgents} from '../../actions/AgentsAction';

import {checkStatusAccept} from '../../actions/ConversationAction';
import {connect} from 'react-redux';
import Aux from '../../hoc/hocAux';
import classes from './DoctorsStyle.module.css';
import Header from './DoctorsHeader';
import Body from './DoctorsBody';

class DoctorsBuilder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeLink: 'doctors'
        }
    }

    onHeaderChange = (data) => {
        this.setState((state) => ({activeLink: data}))
    }

    componentDidMount() {
        this.props.fetchAgents();
        this.props.childChangedDoctor();

    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.changedDoctorKey === newProps.doctorDetail.uid) {
            this.props.checkStatusAccept();
        }
    }

    render() {
        return (
            <Aux>
                <div className={classes.main}>
                    {this.props.acceptingChat &&
                    <div className={classes.header}>Doctors</div>
                    }
                    {this.props.acceptingChat === false &&
                    <div className={classes.header_red}>You are currently not accepting chats</div>
                    }
                    <div className={classes.body}>
                        <Header HeaderItem={(data) => this.onHeaderChange(data)} activeLink={this.state.activeLink}/>
                        <Body
                            agentsData={this.props.agentsData}
                            chatCounts={this.props.chatCounts}
                            groupData={this.props.groupData}
                            activeLink={this.state.activeLink}
                            onAgentClick={this.onAgentClick}
                            selectedAgent={this.state.selectedAgent}
                            currentUid={this.props.doctorDetail.uid}
                        />
                    </div>
                </div>
            </Aux>
        )
    }
}

const mapStateToProps = ({conversationReducer, customersReducer, acceptingReducer, agentsReducer, doctorReducer}) => {
    const {chatCounts} = conversationReducer;
    const {customersData} = customersReducer;
    const {acceptingChat, changedDoctorKey} = acceptingReducer
    const {doctorDetail} = doctorReducer;
    const {agentsData, success} = agentsReducer;

    return {chatCounts, customersData, acceptingChat, doctorDetail, changedDoctorKey, agentsData, success};
}

export default connect(mapStateToProps, {childChangedDoctor, checkStatusAccept, fetchAgents})(DoctorsBuilder);
