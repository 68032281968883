import  {Component} from 'react';
import {PDFViewer} from '@react-pdf/renderer';
import CreatePrescriptionPdf from './createPresciptionPdf'

//import { connect } from 'react-redux';

class PdfBuilder extends Component {
    constructor(props) {
        super(props);
        // console.log("PdfBuilder this.props", props)

        /* this.handleSubmit = this.handleSubmit.bind(this);
        this.setName = this.setName.bind(this);
        this.setEmail = this.setEmail.bind(this);
        this.setBirthDay = this.setBirthDay.bind(this); */

    }

    UNSAFE_componentWillMount = () => {
        //this.props.fetchProfile();
        // console.log("PdfBuilder this.props", this.props)
    }

    render() {
        return (
            <PDFViewer style={{height: "100%", width: "100%", position: 'absolute'}}>
                <CreatePrescriptionPdf
                    {...this.props}
                />
            </PDFViewer>
        )
    }

}

/* const mapStateToProps = ({ clinicalcannedResponseReducer, billingcodesReducer, outPatientFormReducer,profileReducer }) => {
    const { profile_data, uid } = profileReducer;
    const { loading, message, error } = outPatientFormReducer;
    const { canned_responses } = clinicalcannedResponseReducer;
    const { billing_codes } = billingcodesReducer;
    return { loading, message, error, canned_responses, billing_codes, profile_data, uid };
}

export default connect(mapStateToProps, {fetchProfile})(PdfBuilder); */
export default PdfBuilder;
