
import isMobileView from '../../../../utils/isMobileView';
import classes from '../encounters.module.css';
import _classes from '../_encounters.module.css';

const TagsList = (props) => {
    return (
        <p className={props.validDoctor ? classes.tt_suggestion : classes.tt_suggestion_none}
           onClick={() => props.onTagClick(props.name, props.id, props.uid)}>{props.name}</p>
    )
}

const SelectedTags = (props) => {
    return (
        <span className={classes.addtag}>
            <span>{props.name}</span>
            <div className={classes.delete_tag}
                 onClick={() => props.onRemoveTagClick(props.name, props.id, props.uid)}>×</div>
        </span>
    )
}

const CannedResponses = (props) => {
    return (
        <div className={classes.canned_response_item}
             onClick={() => props.onCannedResponseClick(props.uid, props.short_cut, props.text)}>
            <div className={classes.canned_response_text}>{props.short_cut}</div>
            <div className={classes.canned_response_arrow}
                 onClick={() => props.onCannedResponseClick(props.uid, props.short_cut, props.text)}>
                <svg data-test="shortcut-select-button" fill="#000000" width="4px" height="12px" viewBox="0 0 8 13">
                    <g fill="inherit" fillRule="evenodd">
                        <path fillRule="nonzero" d="M.59 11.34l4.58-4.59L.59 2.16 2 .75l6 6-6 6z"></path>
                    </g>
                </svg>
            </div>
        </div>
    )
}

const Box = (props) => {
    const taglist = props.tags.map(tag => {
        return <TagsList
            key={tag.uid}
            id={tag.id}
            uid={tag.uid}
            name={tag.tag}
            validDoctor={props.doctorIsValid}
            onTagClick={(name, id, uid) => props.onTagClick(name, id, uid)}/>
    });

    const selectedTags = props.selectedTags.map(selected => {
        return <SelectedTags
            key={selected.id}
            id={selected.id}
            uid={selected.uid}
            name={selected.name}
            onRemoveTagClick={(name, id, uid) => props.onRemoveTagClick(name, id, uid)}
        />
    });

    const cannedResponsesList = props.canned_responses.map(canned_response => {
        return <CannedResponses
            key={canned_response.uid}
            uid={canned_response.uid}
            text={canned_response.text}
            short_cut={canned_response.shortcut}
            onCannedResponseClick={(uid, short_cut, text) => props.onCannedResponseClick(uid, short_cut, text)}
        />
    });

    return (
        isMobileView() ? <div className={_classes.textarea}>

            <div className={_classes.input}>
                <div className={(!props.doctorIsValid) ? _classes.disabled_input_container : _classes.input_container}
                     style={{flexDirection: 'row',}}>
                    <div className={_classes.hashtag}>
                        {/* <div className={classes.attachment} disabled={!props.doctorIsValid} onClick={() => props.onCannedResponseShow()}>
                                    <svg fill={!props.doctorIsValid ? "#ffffff" : "#1355c7"} width="14px" height="14px" viewBox="0 0 13 14">
                                        <g fill="inherit">
                                            <path d="M11.4800171,9.8 L1.68001709,9.8 C1.26001709,9.8 0.98001709,9.52 0.98001709,9.1 C0.98001709,8.68 1.26001709,8.4 1.68001709,8.4 L11.4800171,8.4 C11.9000171,8.4 12.1800171,8.68 12.1800171,9.1 C12.1800171,9.52 11.9000171,9.8 11.4800171,9.8 Z"></path>
                                            <path d="M12.4599915,5.88001709 L2.65999146,5.88001709 C2.23999146,5.88001709 1.95999146,5.60001709 1.95999146,5.18001709 C1.95999146,4.76001709 2.23999146,4.48001709 2.65999146,4.48001709 L12.4599915,4.48001709 C12.8799915,4.48001709 13.1599915,4.76001709 13.1599915,5.18001709 C13.1599915,5.60001709 12.8799915,5.88001709 12.4599915,5.88001709 Z"></path>
                                            <path d="M14.7,7.7 L3.5,7.7 C3.08,7.7 2.8,7.42 2.8,7 C2.8,6.58 3.08,6.3 3.5,6.3 L14.7,6.3 C15.12,6.3 15.4,6.58 15.4,7 C15.4,7.42 15.12,7.7 14.7,7.7 Z" transform="translate(9.100000, 7.000000) rotate(99.000000) translate(-9.100000, -7.000000)"></path>
                                            <path d="M10.5,7.7 L-0.7,7.7 C-1.12,7.7 -1.4,7.42 -1.4,7 C-1.4,6.58 -1.12,6.3 -0.7,6.3 L10.5,6.3 C10.92,6.3 11.2,6.58 11.2,7 C11.2,7.42 10.92,7.7 10.5,7.7 Z" transform="translate(4.900000, 7.000000) rotate(99.000000) translate(-4.900000, -7.000000)"></path>
                                        </g>
                                    </svg>
                                </div>
                                 */}
                        <div className={_classes.attachment}
                             onClick={() => props.onAttachmentClick(props.inputElement)}>
                            <input
                                className={_classes.file_input}
                                type="file"
                                ref={props.inputElement}
                                onChange={(e) => props._handleImageChange(e)}
                                disabled={!props.doctorIsValid}
                            />
                            <svg fill={!props.doctorIsValid ? "#ffffff" : "#1355c7"} width="20px" height="20px"
                                 viewBox="0 0 8 16">
                                <g>
                                    <path fill="inherit"
                                          d="M7 4v7.667a2.666 2.666 0 1 1-5.333 0V3.333a1.667 1.667 0 0 1 3.333 0v7c0 .367-.3.667-.667.667a.669.669 0 0 1-.666-.667V4h-1v6.333a1.667 1.667 0 0 0 3.333 0v-7a2.666 2.666 0 1 0-5.333 0v8.334a3.665 3.665 0 0 0 3.666 3.666A3.665 3.665 0 0 0 8 11.667V4H7z"></path>
                                    <path fill="#ffffff" fillOpacity="0" d="M-4 0h16v16H-4z"></path>
                                </g>
                            </svg>
                        </div>
                    </div>
                    {/* <div className={_classes.attachment} onClick={() => props.onAttachmentClick(props.inputElement)}>
                            <input
                                className={classes.file_input}
                                type="file"
                                ref={props.inputElement}
                                onChange={(e) => props._handleImageChange(e)}
                                disabled={!props.doctorIsValid}
                            />
                            <svg fill={!props.doctorIsValid ? "#ffffff" : "#1355c7"} width="14px" height="14px" viewBox="0 0 8 16">
                                <g>
                                    <path fill="inherit" d="M7 4v7.667a2.666 2.666 0 1 1-5.333 0V3.333a1.667 1.667 0 0 1 3.333 0v7c0 .367-.3.667-.667.667a.669.669 0 0 1-.666-.667V4h-1v6.333a1.667 1.667 0 0 0 3.333 0v-7a2.666 2.666 0 1 0-5.333 0v8.334a3.665 3.665 0 0 0 3.666 3.666A3.665 3.665 0 0 0 8 11.667V4H7z"></path>
                                    <path fill="#ffffff" fillOpacity="0" d="M-4 0h16v16H-4z"></path>
                                </g>
                            </svg>
                        </div> */}
                    <div className={_classes.type_area}>
                        <textarea
                            placeholder={!props.doctorIsValid ? "Private messages will be visible for agents only" : "Type a message"}
                            className={!props.doctorIsValid ? _classes.inValid : _classes.valid}
                            disabled={!props.doctorIsValid}
                            ref={props.textareaElement}
                            onChange={(event) => props.onChangeText(event)}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault();
                                    props.onsendmessage(props.textareaElement);
                                }
                            }
                            }
                        />

                        {props.showCannedResponseList &&
                        <div className={_classes.canned_response_list}>
                            <div className={_classes.canned_response_view}>
                                {cannedResponsesList}
                            </div>
                        </div>
                        }

                        {props.selectedCannedResponseText !== '' &&
                        <div className={_classes.canned_response_list}>
                            <div className={classes.canned_response_selected_text}>
                                <div className={classes.canned_response_selected_text_li}>
                                    <div className={classes.canned_response_content}>
                                        <div className={classes.canned_response_content_inner}>
                                            <div className={classes.canned_response_content_data}>
                                                <div className={classes.canned_response_back_arrow}>
                                                    <div className={classes.canned_response_back_arrow_view}
                                                         onClick={() => props.onCannedResponseShow()}>
                                                        <svg fill="#000000" width="4px" height="8px" viewBox="0 0 8 13">
                                                            <g fill="inherit" fillRule="evenodd">
                                                                <path fillRule="nonzero"
                                                                      d="M.59 11.34l4.58-4.59L.59 2.16 2 .75l6 6-6 6z"></path>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className={classes.canned_response_content_text}
                                                     onClick={() => props.selectedCannedResponse(props.textareaElement, props.selectedCannedResponseText, props.selectedCannedResponseShortCut)}>{props.selectedCannedResponseText}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>

                    <div className={_classes.submit_area}>
                        <div className={_classes.bottom_area}>

                            <div className={_classes.btn_area}>
                                <button className={_classes.btn} disabled={!props.doctorIsValid} type="button"
                                        onClick={() => props.onsendmessage(props.textareaElement)}>Send
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className={classes.tag_view}>
                <div className={props.doctorIsValid || !props.adminIsValid ? classes.chat_tags : classes.chat_tags_hide} disabled={props.doctorIsValid}>
                    {selectedTags}
                    <span className={classes.another_tag_container} onClick={() => props.openTagListModal()}>
                        <div className={classes.add_tag} onClick={() => props.openTagListModal()}>
                            <span className={classes.add_tag_icon} onClick={() => props.openTagListModal()}>
                                <div className={classes.add_icon}>
                                    <svg fill="#4384F5" width="17px" height="17px" viewBox="0 0 18 18">
                                        <g>
                                            <rect fill="#ffffff" fillOpacity="0" width="17.12" height="17.12" x=".44" y=".44" stroke="#BCC6D0" strokeWidth=".88" rx="3.52"></rect>
                                            <path fill="inherit" d="M9.745 8.208H13v1.32H9.745V13h-1.49V9.528H5v-1.32h3.255V5h1.49z"></path>
                                        </g>
                                    </svg>
                                </div>
                            </span>
                            add tag
                        </div>
                    </span>
                    <div className={props.show ? classes.main_modal: classes.main_modal_hide} style={{ opacity: props.show ? '1' : '0' }}>
                        <div className={classes.tag_modal}>
                            <div className={classes.tag_modal_list}>
                                {taglist}
                            </div>
                        </div>
                    </div>
                </div>
            </div>*/}
        </div> : <div className={classes.textarea}>

            <div className={classes.input}>
                <div className={(!props.doctorIsValid) ? classes.disabled_input_container : classes.input_container}>
                    <div className={classes.type_area}>
                        <textarea
                            placeholder={!props.doctorIsValid ? "Private messages will be visible for agents only" : "Type a message"}
                            className={!props.doctorIsValid ? classes.inValid : classes.valid}
                            disabled={!props.doctorIsValid}
                            ref={props.textareaElement}
                            onChange={(event) => props.onChangeText(event)}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault();
                                    props.onsendmessage(props.textareaElement);
                                }
                            }
                            }
                        />

                        {props.showCannedResponseList &&
                        <div className={classes.canned_response_list}>
                            <div className={classes.canned_response_view}>
                                {cannedResponsesList}
                            </div>
                        </div>
                        }

                        {props.selectedCannedResponseText !== '' &&
                        <div className={classes.canned_response_list}>
                            <div className={classes.canned_response_selected_text}>
                                <div className={classes.canned_response_selected_text_li}>
                                    <div className={classes.canned_response_content}>
                                        <div className={classes.canned_response_content_inner}>
                                            <div className={classes.canned_response_content_data}>
                                                <div className={classes.canned_response_back_arrow}>
                                                    <div className={classes.canned_response_back_arrow_view}
                                                         onClick={() => props.onCannedResponseShow()}>
                                                        <svg fill="#000000" width="4px" height="8px" viewBox="0 0 8 13">
                                                            <g fill="inherit" fillRule="evenodd">
                                                                <path fillRule="nonzero"
                                                                      d="M.59 11.34l4.58-4.59L.59 2.16 2 .75l6 6-6 6z"></path>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className={classes.canned_response_content_text}
                                                     onClick={() => props.selectedCannedResponse(props.textareaElement, props.selectedCannedResponseText, props.selectedCannedResponseShortCut)}>{props.selectedCannedResponseText}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>

                    <div className={classes.submit_area}>
                        <div className={classes.bottom_area}>
                            <div className={classes.hashtag}>
                                <div className={classes.attachment} disabled={!props.doctorIsValid}
                                     onClick={() => props.onCannedResponseShow()}>
                                    <svg fill={!props.doctorIsValid ? "#ffffff" : "#1355c7"} width="14px" height="14px"
                                         viewBox="0 0 13 14">
                                        <g fill="inherit">
                                            <path
                                                d="M11.4800171,9.8 L1.68001709,9.8 C1.26001709,9.8 0.98001709,9.52 0.98001709,9.1 C0.98001709,8.68 1.26001709,8.4 1.68001709,8.4 L11.4800171,8.4 C11.9000171,8.4 12.1800171,8.68 12.1800171,9.1 C12.1800171,9.52 11.9000171,9.8 11.4800171,9.8 Z"></path>
                                            <path
                                                d="M12.4599915,5.88001709 L2.65999146,5.88001709 C2.23999146,5.88001709 1.95999146,5.60001709 1.95999146,5.18001709 C1.95999146,4.76001709 2.23999146,4.48001709 2.65999146,4.48001709 L12.4599915,4.48001709 C12.8799915,4.48001709 13.1599915,4.76001709 13.1599915,5.18001709 C13.1599915,5.60001709 12.8799915,5.88001709 12.4599915,5.88001709 Z"></path>
                                            <path
                                                d="M14.7,7.7 L3.5,7.7 C3.08,7.7 2.8,7.42 2.8,7 C2.8,6.58 3.08,6.3 3.5,6.3 L14.7,6.3 C15.12,6.3 15.4,6.58 15.4,7 C15.4,7.42 15.12,7.7 14.7,7.7 Z"
                                                transform="translate(9.100000, 7.000000) rotate(99.000000) translate(-9.100000, -7.000000)"></path>
                                            <path
                                                d="M10.5,7.7 L-0.7,7.7 C-1.12,7.7 -1.4,7.42 -1.4,7 C-1.4,6.58 -1.12,6.3 -0.7,6.3 L10.5,6.3 C10.92,6.3 11.2,6.58 11.2,7 C11.2,7.42 10.92,7.7 10.5,7.7 Z"
                                                transform="translate(4.900000, 7.000000) rotate(99.000000) translate(-4.900000, -7.000000)"></path>
                                        </g>
                                    </svg>
                                </div>
                                <div className={classes.attachment}
                                     onClick={() => props.onAttachmentClick(props.inputElement)}>
                                    <input
                                        className={classes.file_input}
                                        type="file"
                                        ref={props.inputElement}
                                        onChange={(e) => props._handleImageChange(e)}
                                        disabled={!props.doctorIsValid}
                                    />
                                    <svg fill={!props.doctorIsValid ? "#ffffff" : "#1355c7"} width="14px" height="14px"
                                         viewBox="0 0 8 16">
                                        <g>
                                            <path fill="inherit"
                                                  d="M7 4v7.667a2.666 2.666 0 1 1-5.333 0V3.333a1.667 1.667 0 0 1 3.333 0v7c0 .367-.3.667-.667.667a.669.669 0 0 1-.666-.667V4h-1v6.333a1.667 1.667 0 0 0 3.333 0v-7a2.666 2.666 0 1 0-5.333 0v8.334a3.665 3.665 0 0 0 3.666 3.666A3.665 3.665 0 0 0 8 11.667V4H7z"></path>
                                            <path fill="#ffffff" fillOpacity="0" d="M-4 0h16v16H-4z"></path>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div className={classes.btn_area}>
                                <button className={classes.btn} disabled={!props.doctorIsValid} type="button"
                                        onClick={() => props.onsendmessage(props.textareaElement)}>Send
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className={classes.tag_view}>
                <div className={props.doctorIsValid || !props.adminIsValid ? classes.chat_tags : classes.chat_tags_hide} disabled={props.doctorIsValid}>
                    {selectedTags}
                    <span className={classes.another_tag_container} onClick={() => props.openTagListModal()}>
                        <div className={classes.add_tag} onClick={() => props.openTagListModal()}>
                            <span className={classes.add_tag_icon} onClick={() => props.openTagListModal()}>
                                <div className={classes.add_icon}>
                                    <svg fill="#4384F5" width="17px" height="17px" viewBox="0 0 18 18">
                                        <g>
                                            <rect fill="#ffffff" fillOpacity="0" width="17.12" height="17.12" x=".44" y=".44" stroke="#BCC6D0" strokeWidth=".88" rx="3.52"></rect>
                                            <path fill="inherit" d="M9.745 8.208H13v1.32H9.745V13h-1.49V9.528H5v-1.32h3.255V5h1.49z"></path>
                                        </g>
                                    </svg>
                                </div>
                            </span>
                            add tag
                        </div>
                    </span>
                    <div className={props.show ? classes.main_modal: classes.main_modal_hide} style={{ opacity: props.show ? '1' : '0' }}>
                        <div className={classes.tag_modal}>
                            <div className={classes.tag_modal_list}>
                                {taglist}
                            </div>
                        </div>
                    </div>
                </div>
            </div>*/}
        </div>
    )
}

export default Box;
