import  {useEffect, useState} from 'react'
import classes from "../ChatBuilder.module.css";
import BarLoader from "../../../components/Spinner/BarLoader";
import Spinner from "../../../components/Spinner";
import * as timeago from 'timeago.js';
import {connect} from "react-redux";

import {database} from "../../../firebase";
import {
    fetchChatTags, fetchStatus,
    findPatientDetail,
    setChatHistoryItemClicked,
    setSelectedItem,
    updatePatient, updateSelectedPatient
} from "../../../actions/ConversationAction";
import {
    fetchAllMessages,
    fetchUserChatHistory,
    makeUnreadAllMessages,
    refreshMessages,
    turnSelectChat
} from "../../../actions/ChatAction";
import {setAssessment, setFindings, setPlan, setSubjectiveSymptoms} from "../../../actions/CannedResponseAction";

const OpenChatList = (props) => {
    const [selected, setSelected] = useState(false)
    const [doctor, setDoctor] = useState(null)
    const [patient, setPatient] = useState(null)
    const [assignedAs, setAssignedAs] = useState(null)
    const [transferChat, setTransferChat] = useState(false)
    const [unreadMessages, setUnreadMessages] = useState(0)
    const {patientID, doctorID, unreadCount, issue, category, start_timestamp,activeTimestamp, paidChat, lastMessageTimeStamp, transferedBy, assignedOn, owner, conversationId}  = props.chat;
    const [name, setName] = useState('')
    const [chatType, setChatType] = useState("");
    const time = timeago.format(activeTimestamp || start_timestamp);
    let userRef = null, unreadMessagesRef = null

    useEffect(() => {
        if (props?.chat){
            if (props?.chat?.isEMRChat) {
                if (props?.chat?.referralFromApp) {
                    setChatType("Free Chat");
                }else {
                    setChatType("Health Card");
                }
            } else if(props?.chat?.subscriptionType === "quarterly"){
                setChatType("Quarterly Plan User");
            } else if(props?.chat?.subscriptionType === "yearly"){
                setChatType("Yearly Plan User");
            } else if(props?.chat?.subscriptionType === "monthly"){
                setChatType("Monthly Plan User");
            } else if(props?.chat?.partner_id ===7){
                setChatType("Orchyd Patient");
            } else if(props?.chat?.isPhysioPatient){
                setChatType("Physio Patient");
            }else if(props?.chat?.payAfterChat){
                setChatType(`Trial plan user${props?.chat?.paymentCompleted ? " - Paid" : props?.chat?.checkoutSessionID ? " - Payment pending" : ""}`);
            }else if(props?.chat?.doNotCharge){
                if(props.chat?.fromConsumable){
                    if (props.chat?.freePrescription && props.chat?.freeMedicalNote){
                        setChatType("Free prescriptions & notes");
                    } else if (props.chat?.freePrescription){
                        setChatType("Free Prescription");
                    } else if (props.chat?.freeMedicalNote){
                        setChatType("Free Medical Note");
                    }else{
                        setChatType("Free Prescription");
                    }
                }else{
                    setChatType("Free Prescription");
                }
            } else{
                setChatType("Private Pay");
            }
        }
    }, [props?.chat]);

    const checkAssignedMessage = (message) => {
        switch (message) {
            case 'close_chat':
                return 'Assigned on close chat';
            case 'picked_from_queue_chat':
                return 'Picked from queue';
            case 'transfered_chat':
                return 'Transferred chat';
            default:
                return null
        }
    }
    useEffect(()=>{
        //props.patients[props.patientId]
        if(selected && props.patients && patientID && props.patients[patientID]){
            props.updateSelectedPatient(patientID, props.patients[patientID])
        }
    },[props.patients, patientID,props.patients[patientID], selected])

    useEffect(()=>{
        if(assignedOn) setAssignedAs( checkAssignedMessage(assignedOn))
    },[assignedOn])
    /*useEffect(()=>{
        if(patientID && props.patients ){
            setPatient(props.patients? props.patients[patientID] || null : null)
        }
    },[patientID, props.patients])*/
    useEffect(()=>{
        if(props.patients && patientID){
            if(props.patients[patientID]){
                if(props.patients[patientID]) setPatient(props.patients[patientID])
            }
        }
    },[props.patients, patientID])

    useEffect(()=>{
        //console.log("OpenChatList userReff ", userRef)
        unreadMessagesRef = database.ref(`unreadMessages/${conversationId}`).on('value',unreadMessagesSnap=>{
            let newUnreadValue = parseInt(unreadMessagesSnap.val() || 0)
            setUnreadMessages(newUnreadValue)

        })

        return ()=>{
            if(userRef){
                //console.log("OpenChatList userRef found ", patientID)
                database.ref(`/users/${patientID}`).off('value', userRef);
                //props.updatePatient(patientID, null)
            }else{
                //console.log("OpenChatList userRef not ", patientID)
            }
            if(unreadMessagesRef){
                //console.log("MyChatList unreadMessagesRef found ", conversationId)
                database.ref(`unreadMessages/${conversationId}`).off('value', userRef);
            }else{
                //console.log("MyChatList unreadMessagesRef not ", conversationId)
            }
        }
    },[])

    const fetchUser = () => {
        if(userRef){
            database.ref(`/users/${patientID}`).off('value', userRef);
        }
        userRef = database.ref(`/users/${patientID}`).on("value",fromUserSnapshot => {
            if(fromUserSnapshot.val()) {
                let userName =  fromUserSnapshot.val().name || fromUserSnapshot.val().userName || 'Patient';
                let country = fromUserSnapshot.val().country;
                let city = fromUserSnapshot.val().city;
                //let chatCount = fromUserSnapshot.val().history_conversations || [];
                let patientObj = {
                    ...fromUserSnapshot.toJSON(),
                    patientName: userName,
                    patientCountry: Boolean(country) ? country : "NA",
                    //convoCount: Object.keys(chatCount).length,
                    patientCity: Boolean(city) ? city : "NA",
                    tier2ClinicData: fromUserSnapshot.val()?.conversations?.tier2ClinicData || {},
                    transferredFromTier2: fromUserSnapshot.val()?.conversations?.transferredFromTier2 || false,
                }
                //setPatient(patientObj)
                props.updatePatient(patientID, patientObj)
            }
        })

    }

    useEffect(()=>{
        if(props?.conversationId && props?.chat?.conversationId)
            setSelected(props?.conversationId === props?.chat?.conversationId ? true : false)
    },[props?.conversationId, props?.chat?.conversationId])

    useEffect(()=>{
        if(props.doctorsNameAndImages && doctorID)setDoctor(props.doctorsNameAndImages?.length > 0? props.doctorsNameAndImages.find(doc => doc.uid === doctorID) : null)
    },[props.doctorsNameAndImages, doctorID])

    useEffect(()=>{
        if(doctor && transferedBy)setTransferChat(transferedBy !== null && props.doctorsNameAndImages?.length !== 0 && doctor?.uid === transferedBy? doctor: false)
    },[doctor, transferedBy])

    useEffect(()=>{
        setName(props?.chat?.owner && props.chat?.owner !== props.chat?.patientID ? patient?.profiles? patient?.profiles[props.chat?.owner]?.name:patient?.name:patient?.name)
    },[props?.chat, patient])

    const showMessages = ( paidChat, conversationId, patientID, doctorID, issue, category, start_time ) => {
        //setSelectedClinicInfo({})
        /*props.setFlagFalse();
        await props.setFlagTrue();*/
        if(props.patients && !props.patients[patientID]){
            fetchUser(patientID)
        }
        props.setChatHistoryItemClicked(null)
        props.setSelectedItem(
            paidChat,
            patientID,
            doctorID,
            conversationId,
            issue,
            category,
            start_time,
            props.chat
        );
        props.updateSelectedPatient(patientID,patient || null )
        props.findPatientDetail(patientID,patient, true);
        props.refreshMessages();
        props.turnSelectChat(conversationId);
        props.fetchAllMessages(
            conversationId,
            props.chatHistoryItemClick
        );
        props.fetchUserChatHistory(patientID, null, owner === patientID? null: owner || null);
        /*props.setSubjectiveSymptoms(conversationId);
        props.setFindings(conversationId);
        props.setAssessment(conversationId);
        props.setPlan(conversationId);*/
        props.fetchChatTags(conversationId);
        props.fetchStatus(conversationId);
        props.onChatItemClick();
    };


    return (
        <div key={props?.chat?.conversationId} className={selected ? classes.selected_list_view : !!unreadMessages?classes.unread_list_view:classes.list_view}
             onClick={() => showMessages(paidChat, props?.chat?.conversationId, patientID, doctorID,
                 issue, category, start_timestamp)}>
            {/*<div className={classes.list_img_style}>
                <div className={classes.selected_list_img}>
                    <span className={classes.selected_user_img}>{Boolean(name) ? name[0].toUpperCase() : 'P'}</span>
                </div>
            </div>*/}
            <div className={classes.list_item}>
                <div
                    className={selected ? classes.selected_item_name : classes.patient_name}>{props.chat?.patientName? props.chat?.patientName : patient?.name === undefined ? <BarLoader  color={"black"} isLoading={true}/>: name || 'Patient'}</div>
                <div className={selected ? classes.selected_item_name : classes.item_name}>{doctor?.doctorName === undefined? <BarLoader  color={"black"} isLoading={true}/> : doctor?.doctorName}</div>
                <div className={selected ? classes.selected_item_name : classes.item_name}>Active
                    Chats: {props.onlineChats === undefined? <Spinner color={"black"} size={"10px"} isLoading={true}/>: props.onlineChats}/{doctor?.totalChatLimit === undefined || doctor?.totalChatLimit === null? <Spinner color={"black"} size={"10px"} isLoading={true}/>: doctor?.totalChatLimit}</div>
                {!!transferChat &&
                <div className={selected ? classes.selected_item_name : classes.item_name}>Transferred
                    By: {transferChat?.doctorName}</div>
                }
                {assignedAs &&
                <div
                    className={selected ? classes.selected_item_name : classes.item_name}>Status: {assignedAs || ''}</div>
                }
                {props.chat?.referralType && <div className={selected ? classes.selected_item_name : classes.item_name}>Referral: {props.chat?.referralType || ""}</div>}
                <div className={selected ? classes.item_payment_status : classes.item_payment_status}>{chatType}</div>
                <div  className={selected? classes.chat_time_selected : classes.chat_time}>
                    This consult started {time}.
                </div>
            </div>

            {!selected && !!unreadMessages && <div className={classes.chat_time_section}>
               <div className={classes.selected_list_img}>
                    <span className={classes.message_indicator}></span>
                </div>

            </div>}

        </div>
    )

}

const mapStateToProps = ({conversationReducer, doctorReducer}) => {
    const {conversationId, doctorsNameAndImages, chatHistoryItemClick, patients } = conversationReducer;
    const { doctorDetail } = doctorReducer;

    return { conversationId, doctorsNameAndImages, doctorDetail, chatHistoryItemClick, patients };
};

export default connect(mapStateToProps,
    {updatePatient, setChatHistoryItemClicked, setSelectedItem,
        findPatientDetail, refreshMessages, turnSelectChat, fetchAllMessages, fetchUserChatHistory,
        fetchChatTags, fetchStatus, setSubjectiveSymptoms, setFindings, setAssessment, setPlan, updateSelectedPatient})(OpenChatList);
