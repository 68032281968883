


import classes from '../../../assets/classes.module.css';

const Items = (props) => (
    <div className={classes.side_container}>
        <div className={props.active === 'profile' ? classes.side_item_selected : classes.side_item_container}
             onClick={() => props.ItemSelected('profile')}>{props.userType === "admin" ? 'Profile settings' : 'Your settings'}</div>
        {(props.staffData && props.staffData.addMoreUsers) &&
        <div className={props.active === 'staff' ? classes.side_item_selected : classes.side_item_container}
             onClick={() => props.ItemSelected('staff')}>Add staff</div>
        }
        {(props.staffData && props.staffData.invitePatients) &&
        <div className={props.active === 'invitepatients' ? classes.side_item_selected : classes.side_item_container}
             onClick={() => props.ItemSelected('invitepatients')}>Invite your patients</div>
        }
        {(props.staffData && props.staffData.ansPatients) &&
        <div className={props.active === 'cannedresponses' ? classes.side_item_selected : classes.side_item_container}
             onClick={() => props.ItemSelected('cannedresponses')}>Create SOAP shortcuts</div>
        }
        {(Boolean(props.staffData?.getReports)) &&
        <div className={props.active === 'billingcodes' ? classes.side_item_selected : classes.side_item_container}
             onClick={() => props.ItemSelected('billingcodes')}>Setup billing codes</div>
        }
        {(Boolean(props.staffData?.getReports)) &&
        <div className={props.active === 'reports' ? classes.side_item_selected : classes.side_item_container}
             onClick={() => props.ItemSelected('reports')}>Setup reports</div>
        }

        {/*
            <div className={classes.side_item_container}>Setup contact info</div>
            <div className={classes.side_item_container}>Customize theme</div>
        */}
    </div>
)

export default Items;
