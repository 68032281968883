import  {useCallback, useState} from 'react';
import classes from '../clinicscreen.module.css';
import TimeAgo from 'timeago-react';

const OpenChatList = (props) => {
    var selected = props.selectedConversation === props.conversationId ? true : false;
    var initial = Boolean(props.name) ? props.name[0].toUpperCase() : 'P';
    return (
        <div className={selected ? classes.selected_list_view : classes.list_view}
             onClick={() => props.onChatItemClick(props.chatData)}>
            <div className={classes.list_img_style}>
                <div className={classes.selected_list_img}>
                    <span className={classes.selected_user_img}>{initial}</span>
                </div>
            </div>
            <div className={classes.list_item}>
                <div
                    className={selected ? classes.selected_item_name : classes.patient_name}>{props.name !== undefined || props.name !== '' ? props.name : 'Patient'}</div>
                <div className={selected ? classes.selected_item_name : classes.item_name}>{props.doctorName}</div>
                <div className={selected ? classes.selected_item_name : classes.item_name}>Active
                    Chats: {props.onlineChats}/{props.totalChatLimit}</div>
                {props.admin &&
                <div className={selected ? classes.selected_item_name : classes.item_name}>Clinic
                    name: {props.clinicName}</div>
                }
            </div>
            {/*
                !selected &&
                <div className={classes.list_item_second}>
                    <div>
                        <div className={classes.time_style}>Last message:</div>
                        <div className={classes.time_style}><TimeAgo datetime={props.lastMessageTimeStamp} /></div>
                    </div>
                </div>
            */}
        </div>
    )
}

const MyChatList = (props) => {
    var selected = props.selectedConversation === props.conversationId ? true : false;
    var initial = Boolean(props.name) ? props.name[0].toUpperCase() : 'P';

    return (
        <div className={selected ? classes.selected_list_view : classes.list_view}
             onClick={() => props.onChatItemClick(props.chatData)}>
            <div className={classes.list_img_style}>
                <div className={classes.selected_list_img}>
                    <span className={classes.selected_user_img}>{initial}</span>
                </div>
            </div>
            <div className={classes.list_item}>
                <div
                    className={selected ? classes.selected_item_name : classes.patient_name}>{props.name !== undefined || props.name !== '' ? props.name : 'Patient'}</div>
                <div className={selected ? classes.selected_item_name : classes.item_name}>{props.doctorName}</div>
                <div className={selected ? classes.selected_item_name : classes.item_name}>Active
                    Chats: {props.onlineChats}/{props.totalChatLimit}</div>
            </div>
            {/*!selected &&
                <div className={classes.list_item_second}>
                    {Boolean(props.unReadCount) &&
                        <div className={classes.list_unread_style}>
                            <span className={classes.unread_msg_style}>{props.unReadCount}</span>
                        </div>
                    }
                    <div>
                        <div className={classes.time_style}>Last message:</div>
                        <div className={classes.time_style}><TimeAgo datetime={props.lastMessageTimeStamp} /></div>
                    </div>
                </div>
            */}


        </div>
    )
}
const FollowUpList = (props) => {
    var initial = Boolean(props.allFollowUp.patient_name) ? props.allFollowUp.patient_name[0].toUpperCase() : 'P';

    var date_now = new Date()
    var date_future = new Date(props.allFollowUp.scheduled_date)
    var d = Math.abs(date_future - date_now) / 1000;                           // delta
    var r = {};                                                                // result
    var s = {                                                                  // structure
        year: 31536000,
        month: 2592000,
        week: 604800, // uncomment row to ignore
        day: 86400,   // feel free to add your own row
        hour: 3600,
        minute: 60,
        second: 1
    };

    Object.keys(s).forEach(function (key) {
        r[key] = Math.floor(d / s[key]);
        d -= r[key] * s[key];
    });

    // for example: {year:0,month:0,week:1,day:2,hour:34,minute:56,second:7}
    if (r.day != 0 && r.day > 0) {
        if (r.hour >= 12) {
            r.day = r.day + 1
        }
        var detailString = `${r.day > 1 ? `${r.day} days` : `${r.day} day`}`
    } else if (r.hour != 0 && r.hour > 0) {
        detailString = `${r.hour > 1 ? `${r.hour} hours` : `${r.hour} hour`}`

    } else if (r.hour == 0 && r.day == 0 && r.minute > 0) {
        detailString = `${r.minute > 1 ? `${r.minute} minutes` : `${r.minute} minute`}`

    } else if (r.minute == 0 && r.hour == 0 && r.day == 0 && r.second > 0) {
        detailString = `${r.second > 1 ? `${r.second} seconds` : `${r.second} second`}`

    }
    return (
        <div className={classes.queue_list_view} onClick={() => {
            props.searchFirebaseId(props.allFollowUp.user_id, props.allFollowUp.id, props.index)
        }}>
            <div className={classes.list_img_style}>
                <div className={classes.selected_list_img}>
                    <span className={classes.selected_user_img}>{initial}</span>
                </div>
            </div>
            <div className={classes.list_item}>
                <div className={classes.patient_name}>{props.allFollowUp.patient_name}</div>
                <div
                    className={classes.item_name}>Issue: {props.allFollowUp.diagnosis != null && props.allFollowUp.diagnosis != undefined && props.allFollowUp.diagnosis != '' ? props.allFollowUp.diagnosis : props.allFollowUp.patient_issue}</div>
                <div
                    className={classes.item_name}>{props.allFollowUp.doctor_name != null && props.allFollowUp.doctor_name != undefined && props.allFollowUp.doctor_name != '' ? "Scheduled for " + props.allFollowUp.doctor_name : ''}</div>

            </div>
            <div className={classes.list_item_second}>
                <div>
                    <div
                        className={classes.time_style}>{props.allFollowUp.notification_sent == 0 ? props.allTime >= 0 ? "Schedueled in:" : "Over due by:" : "Notification Sent"}</div>
                    <div
                        className={classes.time_style}>{props.allFollowUp.notification_sent == 0 ? detailString : ''}</div>
                </div>
            </div>
        </div>
    )
}
const QueuedChatList = (props) => {
    var initial = Boolean(props.name) ? props.name[0].toUpperCase() : 'P';
    return (
        <div className={classes.queue_list_view} onClick={() => props.onQueuedChatItemClick(props.chatData)}>
            <div className={classes.list_img_style}>
                <div className={classes.selected_list_img}>
                    <span className={classes.selected_user_img}>{initial}</span>
                </div>
            </div>
            <div className={classes.list_item}>
                <div
                    className={classes.patient_name}>{props.name !== undefined || props.name !== '' ? props.name : 'Patient'}</div>
                <div
                    className={classes.item_name}>Location: {Boolean(props.patientCity) ? props.patientCity : "NA"}, {Boolean(props.patientCountry) ? props.patientCountry : "NA"}</div>
            </div>
            <div className={classes.list_item_second}>
                <div>
                    <div className={classes.time_style}>In queue:</div>
                    <div className={classes.time_style}><TimeAgo datetime={props.timestamp}/></div>
                </div>
            </div>
        </div>
    )
}

const EmptyChat = (props) => {
    return (<div className={classes.empty_chat_list}>{props.msg}</div>)
}

const ConversationList = (props) => {

    const [showQueue, changeQueue] = useState(true);
    const [showOtherChats, changeOtherChats] = useState(true);
    const [showMyChats, changeMyChats] = useState(true);
    const [showFollowUp, changeFollowUp] = useState(true);
    const [clickedConversation, setClickedConversation] = useState('');
    const showHideFollowUp = useCallback(() => {
        changeFollowUp(!showFollowUp);
    }, [showFollowUp]);
    const showHideQueue = useCallback(() => {
        changeQueue(!showQueue);
    }, [showQueue]);
    const showHideOtherChats = useCallback(() => {
        changeOtherChats(!showOtherChats);
    }, [showOtherChats]);
    const showHideMyChats = useCallback(() => {
        changeMyChats(!showMyChats);
    }, [showMyChats]);

    const myconversation = props.conversations.map(mychat => {

        var onlineChats = props.chatCounts.length !== 0 && props.chatCounts.find(chat => chat.doctorID === mychat.doctorID);
        return (!mychat.chatInQueue && mychat.ownerChat && mychat.ownerClinics &&
            <MyChatList
                selectedConversation={props.selectedConversation}
                patientID={mychat.patientID}
                chatHistoryItemClick={props.chatHistoryItemClick}
                doctorID={mychat.doctorID}
                doctorName={mychat.doctorName}
                onlineChats={onlineChats !== undefined && onlineChats !== false ? onlineChats.onlinePaidChats : 0}
                totalChatLimit={mychat.totalChatLimit}
                conversationId={mychat.conversationId}
                unReadCount={mychat.unreadCount}
                lastMessageTimeStamp={mychat.lastMessageTimeStamp}
                key={mychat.conversationId}
                name={mychat.patientName}
                ownerChat={mychat.ownerChat}
                issue={mychat.issue}
                category={mychat.category}
                paidChat={mychat.paidChat}
                chatData={mychat}
                onChatItemClick={(paidChat, conversationId, patientID, doctorID, issue, category) => props.showMessages(paidChat, conversationId, patientID, doctorID, issue, category)}
            />
        )
    });

    const openconversation = props.conversations.map(openchat => {

        var onlineChats = props.chatCounts.length !== 0 && props.chatCounts.find(chat => chat.doctorID === openchat.doctorID);
        return (!openchat.chatInQueue && !openchat.ownerChat && openchat.ownerClinics &&
            <OpenChatList
                selectedConversation={props.selectedConversation}
                patientID={openchat.patientID}
                doctorID={openchat.doctorID}
                doctorName={openchat.doctorName}
                onlineChats={onlineChats !== undefined && onlineChats !== false ? onlineChats.onlinePaidChats : 0}
                totalChatLimit={openchat.totalChatLimit}
                conversationId={openchat.conversationId}
                key={openchat.conversationId}
                name={openchat.patientName}
                ownerChat={openchat.ownerChat}
                issue={openchat.issue}
                category={openchat.category}
                unReadCount={openchat.unreadCount}
                lastMessageTimeStamp={openchat.lastMessageTimeStamp}
                paidChat={openchat.paidChat}
                chatData={openchat}
                clinicName={openchat.clinicName}
                admin={props.admin}
                onChatItemClick={(paidChat, conversationId, patientID, doctorID, issue, category) => props.showMessages(paidChat, conversationId, patientID, doctorID, issue, category)}
            />
        )
    });

    const queuedconversation = props.conversations.map(queuechat => {
        return (queuechat.chatInQueue &&
            <QueuedChatList
                selectedConversation={props.selectedConversation}
                patientID={queuechat.patientID}
                doctorID={queuechat.doctorID}
                conversationId={queuechat.conversationId}
                key={queuechat.conversationId}
                name={queuechat.patientName}
                ownerChat={queuechat.ownerChat}
                issue={queuechat.issue}
                category={queuechat.category}
                timestamp={queuechat.start_timestamp}
                unReadCount={queuechat.unreadCount}
                lastMessageTimeStamp={queuechat.lastMessageTimeStamp}
                patientCountry={queuechat.patientCountry}
                patientCity={queuechat.patientCity}
                paidChat={queuechat.paidChat}
                chatData={queuechat}
                onQueuedChatItemClick={(paidChat, conversationId, patientID, doctorID, issue, category) => {
                    if (clickedConversation === queuechat.conversationId) {
                        return
                    }
                    setClickedConversation(queuechat.conversationId)
                    props.removedFromQueueAndshowMessages(paidChat, conversationId, patientID, doctorID, issue, category)
                }}
            />
        )
    });
    const followUp = Boolean(props.allFollowUp) ? props.allFollowUp.length > 0 ? props.allFollowUp.map((item, index) => {
        return (
            <FollowUpList
                allFollowUp={props.allFollowUp[index]}
                allTime={props.allTime[index]}
                index={index}
                searchFirebaseId={(id, followUp_id, index) => props.searchFirebaseId(id, followUp_id, index)}
            ></FollowUpList>
        )
    }).filter(x => Boolean(x)) : null : null;
    var newmyconversation = myconversation.filter(function (el) {
        return el != null && el !== "" && el !== false;
    });

    var newopenconversation = openconversation.filter(function (el) {
        return el != null && el !== "" && el !== false;
    });

    var newqueuedconversation = queuedconversation.filter(function (el) {
        return el != null && el !== "" && el !== false;
    });

    var nochatsavailavble = (queuedconversation.length === 0 && openconversation.length === 0) ?
        <EmptyChat msg='no chats available'/> : "";

    return (
        <div className={classes.chatsList}>
            <div className={classes.block}>
                <div className={classes.list_body}>
                    <div className={classes.convo_list}>
                        {newmyconversation.length !== 0 &&
                        <div className={classes.openchatlist} onClick={showHideMyChats}>
                            <div className={classes.openchatlistSection}>
                                <div
                                    className={showMyChats ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                                    <svg fill="#4384F5" width="12px" height="8px" viewBox="0 0 12 8">
                                        <path fill="inherit" fillRule="evenodd"
                                              d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                    </svg>
                                </div>
                                <div className={classes.subListTitle}> Engaged Patients</div>
                            </div>

                            <div className={classes.subListCount}>
                                {newmyconversation.length}
                            </div>
                        </div>
                        }
                        {newmyconversation.length !== 0 &&
                        <div className={showMyChats ? classes.showConvo : classes.hideConvo}> {myconversation} </div>
                        }
                        {newopenconversation.length !== 0 &&
                        <div className={classes.openchatlist} onClick={showHideOtherChats}>
                            <div className={classes.openchatlistSection}>
                                <div
                                    className={showOtherChats ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                                    <svg fill="#4384F5" width="12px" height="8px" viewBox="0 0 12 8">
                                        <path fill="inherit" fillRule="evenodd"
                                              d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                    </svg>
                                </div>
                                <div className={classes.subListTitle}> Other Active Chats</div>
                            </div>

                            <div className={classes.subListCount}> {newopenconversation.length} </div>
                        </div>
                        }
                        {newopenconversation.length !== 0 &&
                        <div className={showOtherChats ? classes.showConvo : classes.hideConvo}>
                            {newopenconversation}
                        </div>
                        }
                        {newqueuedconversation.length !== 0 &&
                        <div className={classes.openchatlist} onClick={showHideQueue}>
                            <div className={classes.openchatlistSection}>
                                <div className={showQueue ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                                    <svg fill="#4384F5" width="12px" height="8px" viewBox="0 0 12 8">
                                        <path fill="inherit" fillRule="evenodd"
                                              d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                    </svg>
                                </div>
                                <div className={classes.subListTitle}> Patients Queued</div>
                            </div>
                            <div className={classes.subListCount}> {newqueuedconversation.length} </div>
                        </div>
                        }
                        {newqueuedconversation.length !== 0 &&
                        <div className={showQueue ? classes.showConvo : classes.hideConvo}>
                            {newqueuedconversation}
                        </div>
                        }
                        {Boolean(followUp) && followUp.length !== 0 &&
                        <div className={classes.openchatlist} onClick={showHideFollowUp}>
                            <div className={classes.openchatlistSection}>
                                <div
                                    className={showFollowUp ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                                    <svg fill="#4384F5" width="12px" height="8px" viewBox="0 0 12 8">
                                        <path fill="inherit" fillRule="evenodd"
                                              d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                    </svg>
                                </div>
                                <div className={classes.subListTitle}> Upcoming Conversations</div>
                            </div>
                            <div className={classes.subListCount}>
                                {followUp.length}
                            </div>
                        </div>
                        }
                        {Boolean(followUp) && followUp.length !== 0 &&
                        <div className={showFollowUp ? classes.showConvo : classes.hideConvo}>
                            {followUp}
                        </div>
                        }
                        {nochatsavailavble}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConversationList;
