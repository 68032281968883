import React, {useState, Fragment, useEffect} from "react";
import { AgoraVideoPlayer,
    createClient,
    createMicrophoneAndCameraTracks,
    ClientConfig,
    IAgoraRTCRemoteUser,
    ICameraVideoTrack,
    IMicrophoneAudioTrack } from "agora-rtc-react";


import ChannelForm from "./ChannelForm";
import {VideoCall} from "./VideoCall";
import {AudioCall} from "./AudioCall";

import ProgressBar from "../../../components/UI/Progressbar/Progressbar";
import Modal from "../../../components/UI/Modal/Modal";
import classes from "../../../components/pharmacyComponent/pharmacycomponent.module.css";
import axios from "axios";
import {firebaseNorthEastRoot, firebaseRoot} from "../../../config";
import {connect} from "react-redux";
import {database} from "../../../firebase";

const config = {mode: "rtc", codec: "vp8"}

const useClient = createClient(config);
const appId= "d8fe8fa3c9564b579b91a23b6c09d3d9"; //ENTER APP ID HERE
//const token = "006d8fe8fa3c9564b579b91a23b6c09d3d9IAAgnOc4UNPnf6Ey0ui5h2D+fNC+lV5JJjI7PeK1L1qddjyvlRv/AEZPIgCqGEh7GVhXYwQAAQCpFFZjAgCpFFZjAwCpFFZjBACpFFZj";

const AgoraComponent = (props) => {
    const client = useClient();
    console.log(" client.uid ", client.uid);
    const [inCall, setInCall] = useState(false);
    const [channelName, setChannelName] = useState("");
    const [token, setToken] = useState("");
    const [callType, setCallType] = useState(null);
    const [showUploadingModal, setShowUploadingModal] = useState(true);
    const onHideAddAgentModal = () =>{
        setShowUploadingModal(!showUploadingModal);
    }
    useEffect(()=>{
        if(token){
            setInCall(true)
        }
    },[token])

    const startCall = (value) => {
        axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            url: `${firebaseNorthEastRoot}/doctorDashboard-getAgoraToken`,
            mode: 'cors',
            data: JSON.stringify({
                uid: 1,
                channelName: channelName
            }),
        }).then((res)=>{
            console.log("getAgoraToken res.data", res.data)
            setToken(res.data.rctToken)

        }).catch(error=>{
            console.log("getAgoraToken error", error)

        })
        axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            url: `${firebaseNorthEastRoot}/getAgoraToken`,
            mode: 'cors',
            data: JSON.stringify({
                uid: 2,
                channelName: channelName
            }),
        }).then((res)=>{
            console.log("getAgoraToken res.data", res.data)
            //setToken(res.data.rctToken)
            if((props?.selectedPatient?.platform).toLowerCase() === "android"){
                let notificationPayload = {
                    "notificationToken":props?.selectedPatient?.notificationToken,
                    "data":{
                        "name" : props?.doctorDetail?.doctorName,
                        "profileImageUrl" : props?.doctorDetail?.profileImageURL,
                        "reminderType": callType === "video"?"videoCall": "audioCall",
                        "channelToken" : res.data.rctToken,
                        "channelName" : channelName,
                        "uid" : "2"
                    }
                }

                console.log("getAgoraToken notificationPayload", JSON.stringify(notificationPayload))
                axios({
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    url: `${firebaseRoot}/customCloudMessage`,
                    mode: 'cors',
                    data: JSON.stringify(notificationPayload),
                }).then((res)=>{
                    console.log("customCloudMessage res.data", res.data)
                    //setToken(res.data.rctToken)


                }).catch(error=>{
                    console.log("customCloudMessage error", error)

                })
            }
            else{
                let voipNotificationPayload = {
                    "production": false,
                    "alertMessage": "YDO CALL",
                    "token": props?.selectedPatient?.notificationToken,
                    "payload": {
                        "name": props?.doctorDetail?.doctorName,
                        "conversationID": props?.conversationId || null,
                        "profileImageUrl": props?.doctorDetail?.profileImageURL,
                        "reminderType": callType === "video"?"videoCall": "audioCall",
                        "channelToken": res.data.rctToken,
                        "channelName": channelName,
                        "uid": "2"
                    }
                }

                console.log("getAgoraToken notificationPayload", JSON.stringify(voipNotificationPayload))
                axios({
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    url: `${firebaseNorthEastRoot}/callNotificationVOIP`,
                    mode: 'cors',
                    data: JSON.stringify(voipNotificationPayload),
                }).then((res)=>{
                    console.log("callNotificationVOIP res.data", res.data)
                    //setToken(res.data.rctToken)
                }).catch(error=>{
                    console.log("callNotificationVOIP error", error)
                })
            }

            if(props?.conversationId){
                let messageValue = {
                    type: callType === "video"?"videoCall": "audioCall",
                    content: "deeplink will be here",
                    fromID: props?.selectedConversationObj?.doctorID,
                    toID: props?.selectedConversationObj?.patientID,
                    isRead: false,
                    doctorName: props?.doctorDetail?.doctorName,
                    doctorImgURL: props?.doctorDetail?.profileImageURL,
                    additionalData: {
                        "channelToken": res.data.rctToken,
                        "channelName": channelName,
                        "uid": "2"
                    }
                };
                let newMessageKey = database.ref(`conversations/${props?.conversationId}`).push().key;
                var updates = {};
                updates[`/conversations/${props?.conversationId}/${newMessageKey}`] = messageValue;
                database
                    .ref()
                    .update(updates)
                    .then(data => {
                        //send message notification should be here where the message is updated.
                    })
                    .catch(error => {
                    });
            }



        }).catch(error=>{
            console.log("getAgoraToken error", error)

        })
    }

    useEffect(()=>{
        if(props.callData){
            setChannelName(props.callData?.channelName)
            setToken(props.callData?.rctToken)
            setCallType(props.callData?.callType)
        }
    },[props.callData])
    if(props.callData){
        return (
            <div className={classes.generalInformation}>
                <Fragment>
                    <div className={classes.expandable_section}>

                        <div className={classes.general_info_section}>
                            {inCall && callType === "video"?
                                <VideoCall setInCall={setInCall} channelName={channelName} client={client} appId={appId} token={token} account={1} />:
                                callType === "audio"?<AudioCall setInCall={setInCall} channelName={channelName} client={client} appId={appId} token={token} account={1} />:<></>
                            }
                        </div>
                    </div>
                </Fragment>
            </div>
        )
    }else{
        return <div></div>
    }




}
const mapStateToProps = ({
                             conversationReducer,
                             doctorReducer
                         }) => {
    const {
        selectedPatient,
        selectedConversationObj,
        callData,
        conversationId,
    } = conversationReducer;

    const {
        doctorDetail,
    } = doctorReducer;

    return {
        selectedPatient,
        selectedConversationObj,
        conversationId,
        callData,
        doctorDetail
    };
};

export default connect(mapStateToProps, {})(AgoraComponent);
