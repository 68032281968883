import {
    CLINIC_ASSESSMENT,
    CLINIC_CANNED_RESPONSE_DELETE,
    CLINIC_CANNED_RESPONSE_DESCRIPTION,
    CLINIC_CANNED_RESPONSE_SAVE,
    CLINIC_CANNED_RESPONSE_SHORT_CUT,
    CLINIC_CANNED_RESPONSE_UPDATE,
    CLINIC_FETCH_CANNED_RESPONSE,
    CLINIC_FETCH_CANNED_RESPONSE_ASSESSMENT,
    CLINIC_FETCH_CANNED_RESPONSE_PLAN,
    CLINIC_FETCH_CHAT_CANNED_RESPONSE,
    CLINIC_FINDINGS,
    CLINIC_PLAN,
    CLINIC_SUBJECTIVE_SYMPTOMS
} from '../../../constants';

import _ from 'lodash';
import {auth, database} from "../../../firebase";
let callbackSymptoms = null, callbackFindings = null, callbackAssesment = null, callbackPlan = null

export const clinic_canned_response_descriptopn_changed = (text) => {
    return {
        type: CLINIC_CANNED_RESPONSE_DESCRIPTION,
        payload: text
    }
}

export const clinic_canned_response_shortcut_changed = (text) => {
    return {
        type: CLINIC_CANNED_RESPONSE_SHORT_CUT,
        payload: text
    }
}

export const clinic_canned_response_save = (ID, text, shortcut, state) => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        var type =
            state === 'chats' ? 'chats' :
                state === 'findings' ? 'findings' :
                    state === 'assessment' ? 'assessment' : 'plan';

        let cannedResponseValue = {
            text: text,
            shortcut: shortcut,
        }

        let cannedresponsekey = getCannedResponseRef(ID, type).push().key;
        var updates = {};
        updates[`/clinicSetting/${ID}/cannedResponse/${type}/${cannedresponsekey}`] = cannedResponseValue;
        database.ref().update(updates)
            .then(() => {
                dispatch({
                    type: CLINIC_CANNED_RESPONSE_SAVE
                })
            })
            .catch((error) => {
                dispatch({
                    type: CLINIC_CANNED_RESPONSE_SAVE
                })
            })
    }
}

export const clinic_fetchCannedResponsesForChats = (ID, type) => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        try {
            getCannedResponseRef(ID, type).on('value', cannedResponsesSnap => {
                if (cannedResponsesSnap.val()) {
                    const cannedresponses = _.map(cannedResponsesSnap.val(), (val, uid) => {
                        return {...val, uid};
                    });
                    dispatch({
                        type: CLINIC_FETCH_CHAT_CANNED_RESPONSE,
                        payload: cannedresponses
                    })
                } else {
                    dispatch({
                        type: CLINIC_FETCH_CHAT_CANNED_RESPONSE,
                        payload: []
                    })
                }
            });
        } catch (error) {
            //console.log("clinic_fetchCannedResponsesForChats error ", error);
        }

    }
}

export const clinic_fetchCannedResponsesForObjectiveFindings = (ID) => {
    return (dispatch) => {

        try {
            getCannedResponseRef(ID, 'findings').on('value', cannedResponsesSnap => {
                if (cannedResponsesSnap.val()) {
                    const cannedresponses = _.map(cannedResponsesSnap.val(), (val, uid) => {
                        return {...val, uid};
                    });
                    dispatch({
                        type: CLINIC_FETCH_CANNED_RESPONSE,
                        payload: cannedresponses
                    })
                } else {
                    dispatch({
                        type: CLINIC_FETCH_CANNED_RESPONSE,
                        payload: []
                    })
                }
            })
        } catch (error) {
            //console.log("clinic_fetchCannedResponsesForObjectiveFindings error ", error);
        }

    }
}

export const clinic_fetchCannedResponsesForAssessment = (ID) => {
    return (dispatch) => {
        try {
            getCannedResponseRef(ID, 'assessment').on('value', cannedResponsesSnap => {
                if (cannedResponsesSnap.val()) {
                    const cannedresponses = _.map(cannedResponsesSnap.val(), (val, uid) => {
                        return {...val, uid};
                    });
                    dispatch({
                        type: CLINIC_FETCH_CANNED_RESPONSE_ASSESSMENT,
                        payload: cannedresponses
                    })
                } else {
                    dispatch({
                        type: CLINIC_FETCH_CANNED_RESPONSE_ASSESSMENT,
                        payload: []
                    })
                }
            })
        } catch (error) {
            //console.log("clinic_fetchCannedResponsesForAssessment error ", error);
        }

    }
}

export const clinic_fetchCannedResponsesForPlan = (ID) => {
    return (dispatch) => {

        try {
            getCannedResponseRef(ID, 'plan').on('value', cannedResponsesSnap => {
                if (cannedResponsesSnap.val()) {
                    const cannedresponses = _.map(cannedResponsesSnap.val(), (val, uid) => {
                        return {...val, uid};
                    });
                    dispatch({
                        type: CLINIC_FETCH_CANNED_RESPONSE_PLAN,
                        payload: cannedresponses
                    })
                } else {
                    dispatch({
                        type: CLINIC_FETCH_CANNED_RESPONSE_PLAN,
                        payload: []
                    })
                }
            })
        } catch (error) {
            //console.log("clinic_fetchCannedResponsesForPlan error ", error);
        }

    }
}

export const clinic_updateCannedResponse = (ID, uid, desc, shortcut, state, callback) => {
    return (dispatch) => {


        let cannedResponseValue = {
            text: desc,
            shortcut: shortcut,
        }

        var type =
            state === 'chats' ? 'chats' :
                state === 'findings' ? 'findings' :
                    state === 'assessment' ? 'assessment' : 'plan';

        getCannedResponseUidRef(ID, uid, type).update(cannedResponseValue)
            .then(() => {
                callback(true);
                dispatch({
                    type: CLINIC_CANNED_RESPONSE_UPDATE
                })
            })
            .catch((error) => {
                callback(false);
                dispatch({
                    type: CLINIC_CANNED_RESPONSE_UPDATE
                })
            })
    }
}

export const clinic_deleteCannedResponse = (ID, uid, state) => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        var type =
            state === 'chats' ? 'chats' :
                state === 'findings' ? 'findings' :
                    state === 'assessment' ? 'assessment' : 'plan';

        getCannedResponseUidRef(ID, uid, type).remove()
            .then(() => {
                dispatch({
                    type: CLINIC_CANNED_RESPONSE_DELETE
                })
            })
            .catch((error) => {
                dispatch({
                    type: CLINIC_CANNED_RESPONSE_DELETE
                })
            })
    }
}

export const clinic_setSubjectiveSymptoms = (conversationID) => {
    return dispatch => {
        if (callbackSymptoms) {
            callbackSymptoms.off()
            callbackSymptoms = null
        }
        callbackSymptoms = getOpenConversationsSymptomsWithLocationRef(
            conversationID
        );
        callbackSymptoms.on("value", snapshot => {
            let Symptoms = ""
            //console.log("getOpenConversationsSymptomsWithLocationRef snapshot ", snapshot.val())

            if (snapshot.val()) {
                let Symptoms = snapshot.val()
                dispatch({
                    type: CLINIC_SUBJECTIVE_SYMPTOMS,
                    payload: Symptoms,
                });
            } else {
                dispatch({
                    type: CLINIC_SUBJECTIVE_SYMPTOMS,
                    payload: Symptoms,
                });
            }
            // console.log("fetchChat symptoms", Symptoms)
        });
    }
}

export const clinic_setFindings = (conversationID) => {
    return dispatch => {
        if (callbackFindings) {
            callbackFindings.off()
            callbackFindings=null
        }
        callbackFindings = getOpenConversationsFindingsWithLocationRef(
            conversationID
        );
        callbackFindings.on("value", snapshot => {
            let Findings = ""
            //console.log("getOpenConversationsFindingsWithLocationRef snapshot ", snapshot.val())

            if (snapshot.val()) {
                let Findings = snapshot.val()
                dispatch({
                    type: CLINIC_FINDINGS,
                    payload: Findings,
                });
            } else {
                dispatch({
                    type: CLINIC_FINDINGS,
                    payload: Findings,
                });
            }
            // console.log("fetchChat findings", Findings)
        });
    }
}

export const clinic_setAssessment = (conversationID) => {
    return dispatch => {
        if (callbackAssesment) {
            callbackAssesment.off()
            callbackAssesment= null
        }
        callbackAssesment = getOpenConversationsAssesmentWithLocationRef(
            conversationID
        );
        callbackAssesment.on("value", snapshot => {
            let Assesment = ""
            //console.log("getOpenConversationsAssesmentWithLocationRef snapshot ", snapshot.val())

            if (snapshot.val()) {
                let Assesment = snapshot.val()
                return dispatch({
                    type: CLINIC_ASSESSMENT,
                    payload: Assesment,
                });
            } else {
                return dispatch({
                    type: CLINIC_ASSESSMENT,
                    payload: Assesment,
                });
            }
            // console.log("fetchChat assesment", Assesment)
        });
    }
}

export const clinic_setPlan = (conversationID) => {
    return dispatch => {
        if (callbackPlan) {
            callbackPlan.off()
            callbackPlan = null
        }
        callbackPlan = getOpenConversationsPlanWithLocationRef(
            conversationID
        );
        callbackPlan.on("value", snapshot => {
            let Plan = ""
            //console.log("getOpenConversationsPlanWithLocationRef snapshot ", snapshot.val())

            if (snapshot.val()) {
                let Plan = snapshot.val()
                dispatch({
                    type: CLINIC_PLAN,
                    payload: Plan,
                });
            } else {
                dispatch({
                    type: CLINIC_PLAN,
                    payload: Plan,
                });
            }
            // console.log("fetchChat plan ", Plan)
        });
    }
}

const getCannedResponseRef = (clinicID, type) => {
    return database.ref(`clinicSetting/${clinicID}/cannedResponse/${type}`);
}

const getCannedResponseUidRef = (clinicID, uid, type) => {
    return database.ref(`clinicSetting/${clinicID}/cannedResponse/${type}/${uid}`);
}

const getClinicRef = (uid) => {
    return database.ref(`/clinicSetting/${uid}`);
}

const getOpenConversationsSymptomsWithLocationRef = (uid) => {
    return database.ref(`/openClinicalConversations/${uid}/symptoms`);
};

const getOpenConversationsFindingsWithLocationRef = (uid) => {
    return database.ref(`/openClinicalConversations/${uid}/findings`);
};

const getOpenConversationsAssesmentWithLocationRef = (uid) => {
    return database.ref(`/openClinicalConversations/${uid}/assessment`);
};

const getOpenConversationsPlanWithLocationRef = (uid) => {
    return database.ref(`/openClinicalConversations/${uid}/plan`);
};
