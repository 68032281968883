
import classes from './clinicdoctor.module.css';

const image = require('../../../assets/download.png');

const DoctorsList = (props) => {
    return (
        <tr>
            {!props.isNoDoctor &&
            <td>
                <div className={classes.icon_image}>
                    <div className={classes.online_image_style}>
                        <img className={classes.online_image_style}
                             src={props.image !== undefined ? props.image : image} alt=""/>
                        {props.onlineStatus &&
                        <span className={classes.online_style}></span>
                        }
                        {!props.onlineStatus &&
                        <span className={classes.offline_style}></span>
                        }
                    </div>
                </div>
            </td>
            }
            {!props.isNoDoctor &&
            <td>
                <div className={classes.doctor_content}>{props.doctorName}</div>
                <div className={classes.doctor_email}>{props.email}</div>
            </td>
            }
            {!props.isNoDoctor &&
            <td className={classes.table_column}>
                <span
                    className={props.accepting_chat ? classes.accepting_chat : classes.not_accepting_chat}>{props.accepting_chat ? 'Accepting Patients' : 'Not Accepting Patients'}</span>
            </td>
            }
            {!props.isNoDoctor &&
            <td className={classes.table_column}>
                <span
                    className={props.accepting_chat ? classes.accepting_chat : classes.not_accepting_chat}>{props.onlineFreeChats}/{props.freeChatLimit}</span>
            </td>
            }
            {!props.isNoDoctor &&
            <td className={classes.table_column}>
                <span
                    className={props.accepting_chat ? classes.accepting_chat : classes.not_accepting_chat}>{props.onlinePaidChats}/{props.paidChatLimit}</span>
            </td>
            }
            {props.isNoDoctor &&
            <td colSpan="3" className={classes.no_doctors}>There is currently no doctor online.</td>
            }
        </tr>
    )
}

export default DoctorsList;
