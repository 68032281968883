import {
    CLINIC_BILLING_CODE_CHANGE,
    CLINIC_BILLING_CODE_DESC_CHANGE,
    CLINIC_BILLING_CODE_SAVE,
    CLINIC_FETCH_BILLING_CODES
} from '../../constants';

const INITIAL_STATE = {clinic_billing_codes: [], clinic_billing_code_value: '', clinic_billing_code_desc_value: ''};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLINIC_BILLING_CODE_CHANGE:
            return {...state, clinic_billing_code_value: action.payload};
        case CLINIC_BILLING_CODE_DESC_CHANGE:
            return {...state, clinic_billing_code_desc_value: action.payload};
        case CLINIC_BILLING_CODE_SAVE:
            return {...state, clinic_billing_code_value: '', clinic_billing_code_desc_value: ''};
        case CLINIC_FETCH_BILLING_CODES:
            return {...state, clinic_billing_codes: action.payload};
        default:
            return state;
    }
}
