import  {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import MetaTags from 'react-meta-tags';

import Aux from '../../hoc/hocAux';
import SideBar from '../sidebar/ClinicalSideBar';
import classes from './clinicallayout.module.css';
import ProfileModal from '../UI/profileModal/clinicProfileModal';
//import ScriptTag from 'react-script-tag';
import isMobileView from '../../../src/utils/isMobileView'

const image = require('../../assets/download.png');

const customStyles = {
    content: {
        top: '79%',
        left: '20%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: 250,
        width: 150
    }
};

class Layout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            profileClicked: false
        }
    }

    navClick = (nav) => {
        if (nav === 'Profile') {
            if (this.state.profileClicked === true) {
                this.setState({profileClicked: false})
            } else {
                this.setState({profileClicked: true})
            }
        }

        {/*if(nav === "Encounters"){
            const { staffData } = this.props;

            if(staffData && staffData.profileImageURL && staffData.fullName){
                if(staffData.createPrescription && staffData.soapNotes){
                    if(staffData.signatureImageURL){
                        this.props.history.push('/clinic_encounter');
                    }else{
                        alert("Please update your profile");
                    }
                }else{
                    this.props.history.push('/clinic_encounter');
                }
            }else{
                alert("Please update your profile");
            }
        }
        */
        }
    }

    render() {
        let {innerWidth, innerHeight} = window
        const {clinic_name, profile_image, staffData, videoChatFlag, videoStatus} = this.props;
        // alert(isMobileView())
        return (
            <Aux>
                <MetaTags>
                    <title>{clinic_name && clinic_name.charAt(0).toUpperCase() + clinic_name.slice(1)} | Virtual
                        Clinic</title>
                    <meta name="get_started" content="Get started."/>
                </MetaTags>
                {!isMobileView() && <SideBar
                    staffData={staffData}
                    profileImage={profile_image}
                    navClick={(data) => this.navClick(data)}
                    videoActive={videoStatus}
                />}
                <main className={classes.content} style={isMobileView() ? {left: 0} : null}>
                    {this.props.children}
                </main>
                <ProfileModal
                    show={this.state.profileClicked}
                    onViewProfileClick={() => this.onViewProfileClick()}
                    modalClosed={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                />
                {/*<ScriptTag isHydrating={true} type="text/javascript" src="some_script.js"/>*/}
            </Aux>
        )
    }
}

const mapStateToProps = ({
                             homeReducer,
                             profileReducer,
                             clinicProfileSettingReducer,
                             clinicalChatsReducer,
                             conversationReducer
                         }) => {
    const {admin, clinicalStatus} = homeReducer;
    const {profile_data, uid} = profileReducer;
    const {videoChatFlag} = clinicalChatsReducer;
    const {videoStatus} = conversationReducer;


    const {
        clinicData, staffData, clinicId, clinic_name, full_name, address, province, city, country, phone, postal_code,
        fax, email_address, license_no, profile_image, signature, clinic_email_address, physician_user,
        loading, message, userType
    } = clinicProfileSettingReducer;

    return {
        clinicData, staffData, clinicId, clinic_name, full_name, address, province, city, country, phone, postal_code,
        fax, email_address, license_no, profile_image, signature, clinic_email_address, physician_user,
        loading, message, userType, profile_data, uid, videoChatFlag, videoStatus
    };

}

export default compose(withRouter, connect(mapStateToProps))(Layout);
