import {FETCH_TAGS, REFRESH_TAGS_STATUS, TAG_CHANGE, TAG_SAVE} from '../constants';

import _ from 'lodash';
import {database} from "../firebase";

let tagsListener = null;
export const tag_changed = (text) => {
    return {
        type: TAG_CHANGE,
        payload: text
    }
}

export const save_tags = (tag) => {
    return (dispatch) => {
        let tagsValue = {
            tag: tag,
            ticketCount: 0,
            ChatCount: 0
        }
        let tagkey = getTagsRef().push().key;
        var updates = {};
        updates[`/tags/${tagkey}`] = tagsValue;
        database.ref().update(updates)
            .then(() => {
                dispatch({
                    type: TAG_SAVE
                })
            })
            .catch((error) => {
                //console.log("error: ", error.message);
            })
    }
}
export const resetTagsListeners = () => {
    return (dispatch) => {
        if(tagsListener) {
            tagsListener.off();
            tagsListener = null;
        }
        dispatch({type: REFRESH_TAGS_STATUS});
    }
}
export const fetchTags = () => {
    return (dispatch) => {
        try {
            if(tagsListener) {
                tagsListener.off();
                tagsListener = null;
            }
            tagsListener = getTagsRef().on('value', tagSnap => {
                if (tagSnap.val()) {
                    const tags = _.map(tagSnap.val(), (val, uid) => {
                        return {...val, uid};
                    });

                    const filteredTags = tags.filter(function (el) {
                        return el != null && el !== undefined;
                    });

                    dispatch({
                        type: FETCH_TAGS,
                        payload: filteredTags
                    })
                } else {
                    dispatch({
                        type: FETCH_TAGS,
                        payload: []
                    })
                }
            })
        } catch (error) {
            //console.log("fetchTags error ", error);
        }

    }
}

const getTagsRef = () => {
    return database.ref().child('tags');
}
