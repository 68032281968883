import {
    FORGOT_PASSWORD_FAILED,
    FORGOT_PASSWORD_IN_PROGRESS,
    FORGOT_PASSWORD_SUCCESS,
    RESET_FORGORT_PASSWORD_STATES
} from '../constants';

const INITIAL_STATE = {loader: false, msg: '', err: ''};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESET_FORGORT_PASSWORD_STATES:
            return {...state, loader: false, msg: '', err: ''};
        case FORGOT_PASSWORD_IN_PROGRESS:
            return {...state, loader: true, msg: '', err: ''};
        case FORGOT_PASSWORD_SUCCESS:
            return {...state, loader: false, msg: action.payload, err: ''};
        case FORGOT_PASSWORD_FAILED:
            return {...state, loader: false, msg: '', err: action.payload};
        default:
            return state;
    }
}
