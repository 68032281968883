import {Component, createRef} from 'react';
import {connect} from 'react-redux';

import classes from './TicketsBuilder.module.css';
import AddTicketForm from './AddForm';
import {
    emailChange,
    fetchAgentsForTickets,
    messageChange,
    nameChange,
    saveTicket,
    subjectChange
} from '../../actions/TicketAction';

const Header = (props) => {
    return (
        <div className={classes.add_content_header}>
            <div className={classes.absolute_back_button} onClick={() => props.backClick()}>
                <a href>
                    <div className={classes.back_button}>
                        <svg width="16" height="16" viewBox="0 0 16 16">
                            <g fill="none" fillRule="evenodd">
                                <path d="M-4-4h24v24H-4z"></path>
                                <path fill="#4384F5" fillRule="nonzero"
                                      d="M16 7H3.83l5.59-5.59L8 0 0 8l8 8 1.41-1.41L3.83 9H16z"></path>
                            </g>
                        </svg>
                    </div>
                    <span>Back</span>
                </a>
            </div>
            <div className={classes.absolute_header}>New ticket</div>
        </div>
    )
}

class AddTicket extends Component {

    constructor(props) {
        super(props);
        this.state = {
            file: '',
            filetype: '',
            showDoctors: false,
            assignDoctor: {}
        }
    }

    componentDidMount() {
        this.props.fetchAgentsForTickets();
    }

    backClick = () => {
        this.props.history.goBack();
    }

    SubjectChange = (event) => {
        this.props.subjectChange(event.target.value);
    }

    RequesterNameChange = (event) => {
        this.props.nameChange(event.target.value);
    }

    onRequesterEmailChange = (event) => {
        event.preventDefault();
        this.props.emailChange(event.target.value);
    }

    onMessageChange = (event) => {
        event.preventDefault();
        this.props.messageChange(event.target.value);
    }

    onAttachmentClick = (e) => {
    }

    _handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            var type = file.type.split('/');
            this.setState({
                file: file,
                filetype: type[0],
            });

        }
        reader.readAsDataURL(file)
    }

    removeFile = () => {
        this.setState({file: ''});
    }

    onSendPress = (event) => {
        event.preventDefault();
        // eslint-disable-next-line no-useless-escape
        var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var validEmail = mailformat.test(String(this.props.email).toLowerCase());
        if (validEmail) {
            this.props.saveTicket(this.state.file, this.state.assignDoctor, this.props);
        } else {
            alert('Please enter valid email');
        }
    }

    showDoctorslist = () => {
        this.setState({showDoctors: !this.state.showDoctors});
    }

    selectAssign = (agent) => {
        this.setState({assignDoctor: {agentName: agent.doctorName, agentEmail: agent.email, agentID: agent.uid}});
    }

    render() {
        return (
            <div className={classes.main}>
                <div className={classes.add_content}>
                    <div className={classes.content_block}>
                        <div className={classes.add_content_body}>
                            <Header backClick={() => this.backClick()}/>
                            <div className={classes.add_body}>
                                <div className={classes.main_add_body}>
                                    <AddTicketForm
                                        subjectValue={this.props.subject}
                                        nameValue={this.props.name}
                                        emailValue={this.props.email}
                                        messageValue={this.props.message}
                                        error={this.props.error}
                                        onSubjectChange={(text) => this.SubjectChange(text)}
                                        onRequesterNameChange={(text) => this.RequesterNameChange(text)}
                                        onRequesterEmailChange={(text) => this.onRequesterEmailChange(text)}
                                        onMessageChange={(text) => this.onMessageChange(text)}
                                        onSendPress={(event) => this.onSendPress(event)}
                                        agentsData={this.props.agentsData}
                                        showDoctorData={this.state.showDoctors}
                                        selectedAssign={this.state.assignDoctor}
                                        onAssignClick={(selectedAgent) => this.selectAssign(selectedAgent)}
                                        showDoctorslist={() => this.showDoctorslist()}
                                        inputElement={createRef()}
                                        filename={this.state.file.name}
                                        removeFile={() => this.removeFile()}
                                        onAttachmentClick={(file_input) => this.onAttachmentClick(file_input)}
                                        _handleImageChange={(e) => this._handleImageChange(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ticketsReducer}) => {
    const {agentsData, subject, name, email, message, error} = ticketsReducer;
    return {agentsData, subject, name, email, message, error};
}

export default connect(mapStateToProps, {
    fetchAgentsForTickets,
    subjectChange,
    nameChange,
    emailChange,
    messageChange,
    saveTicket
})(AddTicket);
