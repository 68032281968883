import {Component, useEffect, useState} from 'react';
import classes from './patientform.module.css';
import TextareaAutosize from 'react-autosize-textarea';
import {connect} from 'react-redux';
import ReactDOM from "react-dom";
import MyDocument from '../../PdfBuilder';
import MyPdf from '../../clinicalSide/encounters/CreatePdf';
import {
    closePatientChat,
    destroyThread,
    refreshState,
    saveAndCloseOutPatientForm
} from '../../../actions/ConversationAction';
import _Header from '../../../components/UI/Header/Header'
import CreatePrescPdf from '../../clinicalSide/encounters/createPresciptionPdf';

import isMobileView from "../../../utils/isMobileView";
import _style from "../../../assets/_classes.module.css";
import {pdf} from "@react-pdf/renderer";
import {database, functions, storage, storageRef} from "../../../firebase";
import {setAssessment, setFindings, setPlan, setSubjectiveSymptoms} from "../../../actions/CannedResponseAction";


const CannedResponsesList = (props) => {
    return (
        props.canned_responses_data.map(function (data, index) {
            return (
                <div key={data.uid} className={classes.canned_response_item}
                     onClick={() => props.onCannedResponseClick(data.uid, data.shortcut, data.text)}>
                    <div className={classes.canned_response_text}>{data.shortcut}</div>
                    <div className={classes.canned_response_arrow}
                         onClick={() => props.onCannedResponseClick(data.uid, data.shortcut, data.text)}>
                        <svg data-test="shortcut-select-button" fill="#000000" width="4px" height="12px"
                             viewBox="0 0 8 13">
                            <g fill="inherit" fillRule="evenodd">
                                <path fillRule="nonzero" d="M.59 11.34l4.58-4.59L.59 2.16 2 .75l6 6-6 6z"></path>
                            </g>
                        </svg>
                    </div>
                </div>
            )
        })
    )
};

let healthProblems = [];
let familyProblems = [];
const PatientForm = (props) => {

    const [symptoms, setSymptoms] = useState('')
    const [findings, setFindings] = useState('')
    const [assessment, setAssessment] = useState('')
    const [plan, setPlan] = useState('')
    const [cannedResponses, setCannedResponses] = useState([])
    const [issueResolved, setIssueResolved] = useState('')
    const [followUpCheck, setFollowUpCheck] = useState(false)
    const [unresponsiveChat, setUnresponsiveChat] = useState(false)

    const [medicationCheck, setMedicationCheck] = useState(false)
    const [medication, setMedication] = useState([{
        medicationName: '',
        medicationDosage: 1,
        medicationUnit: 'mg',
        frequency: 'Once',
        frequencyInterval: 'Daily',
        medicationUnitDropDown: false,
        medicationFrequencyDropDown: false,
        medicationFrequencyIntervalDropDown: false,
    }])
    const [opinionDescriptionValue, setOpinionDescriptionValue] = useState('')
    const [opinionDescription, setOpinionDescription] = useState(false)
    const [showCannedResponseForObjectiveFindingsList, setShowCannedResponseForObjectiveFindingsList] = useState(false)
    const [showCannedResponseForAssessmentList, setShowCannedResponseForAssessmentList] = useState(false)
    const [showCannedResponseForPlanList, setShowCannedResponseForPlanList] = useState(false)
    const [symptomsCannedShortcut, setSymptomsCannedShortcut] = useState('')
    const [findingsCannedShortcut, setFindingsCannedShortcut] = useState('')
    const [assessmentGoalsCannedShortcut, setAssessmentGoalsCannedShortcut] = useState('')
    const [planCannedShortcut, setPlamCannedShortcut] = useState('')
    const [checkBoxes, setCheckboxes] = useState({})
    const [currentConversationId, setCurrentConversationId] = useState(null)



    const [prescriptionDoctor, setPrescriptionDoctor] = useState({})
    const [loading, setLoading] = useState(false)
    const [modalWindow, setModalWindow] = useState(null)
    const [selectedClinicInfo, setSelectedClinicInfo] = useState(null)
    // const [referralOptions,setReferralOptions] = useState([{value:null,text:"None"}])
    const [referralOptionSelected,setReferralOptionSelected] = useState(null)    

    const formatDate = () => {
        let d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(d.setDate(d.getDate() + 1)),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    const [dateTime, setDateTime] = useState(formatDate())

    const resetForm = () =>{
        setSymptoms('')
        setFindings('')
        setAssessment('')
        setPlan('')
        setCannedResponses([])
        setIssueResolved('')
        setFollowUpCheck(false)
        setUnresponsiveChat(false)
        setMedicationCheck(false)
        setMedication([{
            medicationName: '',
            medicationDosage: 1,
            medicationUnit: 'mg',
            frequency: 'Once',
            frequencyInterval: 'Daily',
            medicationUnitDropDown: false,
            medicationFrequencyDropDown: false,
            medicationFrequencyIntervalDropDown: false,
        }])
        setOpinionDescriptionValue('')
        setOpinionDescription(false)
        setShowCannedResponseForObjectiveFindingsList(false)
        setShowCannedResponseForAssessmentList(false)
        setShowCannedResponseForPlanList(false)

        setPrescriptionDoctor({})
        setLoading(false)
        setModalWindow(null)
        setSelectedClinicInfo(null)
        setDateTime(formatDate())
    }

    useEffect(()=>{
        if (props.conversationId && props.patientId){
            database.ref("users/" + props.patientId)
                .once("value", (snap) => {
                    setSelectedClinicInfo(snap.val()?.conversations?.tier2ClinicData || null)
                });
        }

    },[props.conversationId, props.patientId])

    useEffect(()=>{
        if(props.loading !== loading){
            setLoading(props.loading);
        }
    },[props.loading])

    useEffect(()=>{
        if(props.followClassStatus === true || props.followUpStatus === true )
            setFollowUpCheck(true)
        else setFollowUpCheck(false)
    },[props.followClassStatus, props.followUpStatus])

    useEffect(()=>{
        setCannedResponses(props.canned_responses)
    },[props.canned_responses])

    useEffect(()=>{
        setCannedResponses(props.canned_responses)
    },[])

    useEffect(()=>{
            setCurrentConversationId(props.conversationId);
            setFindings(props.selectedConversationObj?.findings || "");
    },[props.conversationId, props.selectedConversationObj?.findings])
    useEffect(()=>{
            setCurrentConversationId(props.conversationId);
            setAssessment(props.selectedConversationObj?.assessment || ""); 
           },[props.conversationId, props.selectedConversationObj?.assessment])
    useEffect(()=>{
            setCurrentConversationId(props.conversationId);
            setPlan(props.selectedConversationObj?.plan || "");
    },[props.conversationId, props.selectedConversationObj?.plan])
    useEffect(()=>{
            setCurrentConversationId(props.conversationId);
            setSymptoms(props.selectedConversationObj?.symptoms || "");
    },[props.conversationId, props.selectedConversationObj?.symptoms])

    useEffect(()=>{
        setReferralOptionSelected(null)
    },[props.show])

    const filterUsers = (event) => {
        let keyword = event.toLowerCase();
        let filtered_cannedResponses = cannedResponses.filter((user) => {
            user = user.shortcut.toLowerCase();
            return user.indexOf(keyword) > -1;
        });
        if (filtered_cannedResponses.length !== 0) {
            setCannedResponses(filtered_cannedResponses)
        } else {
            setCannedResponses(props.canned_responses)
        }
    }



    const diagnosisInput = (event) => {
        if ((event.target.value).match('#')) {
            setShowCannedResponseForObjectiveFindingsList(true)
            filterUsers((event.target.value.slice((event.target.value).search('#'), event.target.value.length)));
        }
        setAssessment(event.target.value)
    }

    const handleChangeIssueResolved = (text) => {
        setIssueResolved(text)
    }

    const onChangeplan = (event) => {
        setPlan(event.target.value)
    }

    const onCancelClick = () => {
        props.hideModal();
    }

    const uploadPdf = (clinicId, patientId, conversationId, filename, file, metadata, fax_number, location, callback) => {
        //let uploadTask = storage.ref(`/${location}/tier2/${clinicId}/${patientId}/${conversationId}/`).child(`${filename}`).put(file, metadata);
        let uploadTask = storage.ref(`/${location}/tier1/${patientId}/${conversationId}/`).child(`${filename}`).put(file, metadata);
        uploadTask.on("state_changed" , (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                switch (snapshot.state) {
                    case storageRef.TaskState.PAUSED: // or 'paused'
                        break;
                    case storageRef.TaskState.RUNNING: // or 'running'
                        break;
                }
            },
            (error) => {
                console.log("firebasestorage error ", error)
                callback(null)
                // Handle unsuccessful uploads
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    callback(downloadURL)
                }).catch((error) =>{
                    callback(null)
                })
            });
    }
    const saveSOAPS = (conversationId,callback)=>{
        let update = {};
        if(assessment){
            update[`openconversations/${conversationId}/assessment`] = assessment;
        }
        if(findings){
            update[`openconversations/${conversationId}/findings`] = findings;
        }
        if(symptoms){
            update[`openconversations/${conversationId}/symptoms`] = symptoms;
        }
        if(plan){
            update[`openconversations/${conversationId}/plan`] = plan;
        }
        if(Object.keys(update).length>0){
            database.ref().update(update).then(()=>{
                callback();
            }).catch(()=>{
                callback();
            })
        }else{
            callback();
        }
    }
    const saveOutPatientForm = async () => {
        console.log("saveOutPatientForm triggered")
        setLoading(true)
        try{
            let soap_notes_url = null
            let medicationObject = props.user_medications && props.user_medications[props.conversationId]
            let medications = medicationObject?.data || []
            let presformData = null;
            const {
                paidChat,
                conversationId,
                doctorID,
                admin,
                doctorName,
                patientId,
                selectedIssue,
                selectedCategory,
                tagChatList,
                chatStartTime,
                selectedPatient
            } = props;
            const {patientName,
                heartRate,
                weightKg,
                weightLbs,
                stepCount,
                runningWalking,
                heightCm,
                currentSleep,
                averageSleep,
                heightFeet,
                periodStartDate,
                flowType,
                restingHeartRate,
                complete_address
            } = selectedPatient || {};
            let prescription_data = props.prescription_data || {}
            if(prescription_data && prescription_data[props.conversationId]){
                prescription_data[props.conversationId].doctor_emails = prescriptionDoctor?.doctor_emails || null
            }
            if(medications){
                const pres = <CreatePrescPdf
                    prescriptionID={props?.conversationId}
                    clinicName={prescriptionDoctor?.clinic_name}
                    clinicAddress={prescriptionDoctor?.address}
                    clinicCity={prescriptionDoctor?.city}
                    clinicCountry={prescriptionDoctor?.country}
                    clinicState={prescriptionDoctor?.state}
                    clinicPostalCode={prescriptionDoctor?.postal_code}
                    clinicPhoneNumber={prescriptionDoctor?.phone}
                    clinicFaxNumber={prescriptionDoctor?.fax}
                    patientName={props.prescription_data[props.conversationId]?.name || props?.selectedPatient?.patientName}
                    patientDOB={props.prescription_data[props.conversationId]?.DOB || props?.selectedPatient?.DOB}
                    patientGender={props.prescription_data[props.conversationId]?.gender || props?.selectedPatient?.gender}
                    patient_phone_number={props?.selectedPatient?.phone_number}
                    patientAddress={props?.selectedPatient?.complete_address}
                    healthInsuranceCardNumber={props?.selectedPatient?.healthInsuranceCardNumber || ""}
                    start_timestamp={props.start_timestamp || props.chatStartTime || (new Date()).getTime()}
                    user_medications={medications}
                    doctorName={prescriptionDoctor?.doctor_name}
                    signatureImageURL={prescriptionDoctor?.signature_url}
                    doctorlicense={prescriptionDoctor?.license_no}
                    license_name={prescriptionDoctor?.license_name}
                    endTime={props.endTime || (new Date()).getTime()}
                />;
                const myPrescPdf = pdf();
                myPrescPdf.updateContainer(pres);
                const presBlob = await myPrescPdf.toBlob();
                let pname = props?.selectedPatient?.patientName?.toLowerCase() || '';
                let dname = props.doctorName?.toLowerCase() || '';
                while (pname.indexOf(' ') > -1) {
                    pname = pname.replace(' ', '-');
                }
                while (dname.indexOf(' ') > -1) {
                    dname = dname.replace(' ', '-');
                }
                let start_timestamp = props.start_timestamp || (new Date()).getTime()
                let presFilename = pname + "-" + dname + "-prescription-" + start_timestamp + ".pdf";
                let presFile = new File([presBlob], presFilename, {lastModified: (new Date()).getTime()});
                let metadata = {
                    contentType: 'application/pdf',
                };
                presformData = {presFilename, presFile, metadata, pharmacy:props?.selectedPatient?.pharmacy, doctorImage:props.doctorImage}

            }
            saveSOAPS(conversationId,async ()=>{
                database
                    .ref(
                        `/openconversations/${conversationId}/`
                    )
                    .once("value")
                    .then(async (snap) => {
                        const val = snap.val();
                        if(val.soapnotespdf){
                            soap_notes_url = val.soapnotespdf

                        }

                if(props.payPerMinuteChat == true) {
                    props.destroyThread(props?.conversationId, props?.selectedPatient?.email)
                    setTimeout(async () => {
                        props.saveAndCloseOutPatientForm( referralOptionSelected,paidChat, conversationId, doctorID, admin, doctorName, patientName, patientId,
                            selectedIssue, selectedCategory, tagChatList, assessment, opinionDescription, opinionDescriptionValue, followUpCheck, dateTime, medicationCheck,
                            medication, plan,findings, heartRate, weightKg, weightLbs, stepCount, runningWalking, heightCm,
                            currentSleep, averageSleep, heightFeet, periodStartDate, flowType, restingHeartRate,
                            complete_address, chatStartTime, issueResolved, presformData, medications, prescription_data[props.conversationId], selectedClinicInfo, symptoms, soap_notes_url, snap);
                    }, 5000)
                } else {
                    props.saveAndCloseOutPatientForm(referralOptionSelected, paidChat, conversationId, doctorID, admin, doctorName, patientName, patientId,
                        selectedIssue, selectedCategory, tagChatList, assessment, opinionDescription, opinionDescriptionValue, followUpCheck, dateTime, medicationCheck,
                        medication, plan,findings, heartRate, weightKg, weightLbs, stepCount, runningWalking, heightCm,
                        currentSleep, averageSleep, heightFeet, periodStartDate, flowType, restingHeartRate,
                        complete_address, chatStartTime, issueResolved, presformData, medications, prescription_data[props.conversationId], selectedClinicInfo, symptoms, soap_notes_url, snap);
                }

                    })
                    .catch(error => {
                        console.log("val.soapnotespdf error ", error)
                        setLoading(false)
                    })
            })

        }
        catch (e) {
            console.log("form error ", e)
            setLoading(false)
        }

    }

    const closeChat = async () => {


        const {
            paidChat,
            conversationId,
            doctorID,
            admin,
            doctorName,
            patientId,
            selectedIssue,
            selectedCategory,
            chatStartTime,
            selectedPatient
        } = props;
        const {patientName,
            heartRate,
            weightKg,
            weightLbs,
            stepCount,
            runningWalking,
            heightCm,
            currentSleep,
            averageSleep,
            heightFeet,
            periodStartDate,
            flowType,
            restingHeartRate,
            complete_address
        } = selectedPatient || {};
        props.closePatientChat(paidChat, conversationId, doctorID, admin, doctorName, patientName, patientId, selectedIssue, selectedCategory, heartRate, weightKg, weightLbs, stepCount, runningWalking, heightCm,
            currentSleep, averageSleep, heightFeet, periodStartDate, flowType, restingHeartRate,
            complete_address, chatStartTime);
    }

    const closeOnSaveClick = () => {
        props.hideModal();
        resetForm();
        props.refreshState();
    }

    const onAssessmentCannedResponseClick = (uid, short_cut, text) => {
        setShowCannedResponseForAssessmentList(false)
        let updatedString;

        if (assessment.search('#') !== -1) {
            let replaceText = (assessment.slice((assessment).search('#'), assessment.length));
            updatedString = (assessment).replace(replaceText, text);
        } else {
            updatedString = (assessment).concat(' ', text);
        }
        setAssessment(updatedString)
    }
    const onPlanCannedResponseClick = (uid, short_cut, text) => {
        setShowCannedResponseForPlanList(false)
        let updatedString;

        if (plan.search('#') !== -1) {
            let replaceText = (plan.slice((plan).search('#'), plan.length));
            updatedString = (plan).replace(replaceText, text);
        } else {
            updatedString = (plan).concat(' ', text);
        }
        setPlan(updatedString)
    }

    const onObjectiveCannedResponseClick = (uid, short_cut, text) => {
        setShowCannedResponseForObjectiveFindingsList(false)
        let updatedString;

        if (findings.search('#') !== -1) {
            let replaceText = (findings.slice((findings).search('#'), findings.length));
            updatedString = (findings).replace(replaceText, text);
        } else {
            updatedString = (findings).concat(' ', text);
        }
        setFindings(updatedString)
    }


    const onSubjectSymptomsChange = (event) => {
        setSymptoms(event.target.value)
    }

    const onObjectiveFindingsChange = (event) => {
        if ((event.target.value).match('#')) {
            setShowCannedResponseForObjectiveFindingsList(true)
            filterUsers((event.target.value.slice((event.target.value).search('#'), event.target.value.length)));
        }
        setFindings(event.target.value)
    }

    const onAssessmentGoalsChange = (event) => {

        if ((event.target.value).match('#')) {
            setShowCannedResponseForAssessmentList(true)
            filterUsers((event.target.value.slice((event.target.value).search('#'), event.target.value.length)));
        }
        setAssessment(event.target.value)
    }

    const onPlanChange = (event) => {

        if ((event.target.value).match('#')) {
            setShowCannedResponseForPlanList(true)
            filterUsers((event.target.value.slice((event.target.value).search('#'), event.target.value.length)));
        }
        setPlan(event.target.value);
    }

    const onReferralOptionChange= (event)=>{
        if(event.target.value === "None")
        {
            setReferralOptionSelected(null)
        }else{
            setReferralOptionSelected(event.target.value)
            alert("Make sure the health card is uploaded under the health insurance tab or else the referral wouldn’t be generated.")
        }
    }

    const renderPopOut = () => {
        const {
            clinic_name,
            full_name,
            address,
            city,
            province,

            phone,
            fax,
            license_no,
            profile_image,
            signature,
            selectedPatient
        } = props;
        const {country, healthInsuranceCardNumber} = selectedPatient
        if (modalWindow) {
            let root = modalWindow.document.body;
            ReactDOM.render(<MyDocument
                {...props}
                plan={plan}
                assessment={assessment}
                findings={findings}
                //icd={icd}
                followuptext={props.followUpDays !== 0 ? props.followUpDays + ' days' : 'as needed'}
                /*video_consult={video_consult}
                encounter_duration={encounter_duration}
                special_referals={special_referals}
                requisitions={requisitions}
                prescriptions={prescriptions}*/
                patient_issue={symptoms}
                patientName={props?.selectedPatient?.patientName}
                patientDOB={props?.selectedPatient?.DOB}
                doctorName={full_name}
                clinicName={clinic_name}
                doctorImage={profile_image}
                signatureImageURL={signature}
                currentDoctorProfile={{
                    ...props.currentDoctorProfile,
                    staffPhone: Boolean(props.currentDoctorProfile?.phoneNumber) ? props.currentDoctorProfile.phoneNumber : null,
                    healthInsuranceCardNumber
                }}
                doctorlicense={license_no}
                clinicPhoneNumber={phone}
                clinicPostalCode={props.clinicPostalCode}
                clinicFaxNumber={fax}
                clinicAddress={address + ' ' + city + ' ' + province + ' ' + country}
                familyProblems={familyProblems}
                healthProblems={healthProblems}
                patient_profile={props?.selectedPatient?.patientProfile}
                endTime={props.endTime}
                start_timestamp={props.start_timestamp}
                user_medications={props.user_medications[props.conversationId]?.data}
            />, root);
        }
    }

    return (
        <div className={classes.modal} style={{
            transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
            opacity: props.show ? '1' : '0'
        }}>

            {isMobileView() ?
                <_Header title="End Patient Encounter" back={{onClick: () => onCancelClick()}}/> :
                <div className={classes.header}>SOAP Note</div>}

            {props.message === '' &&
            <div className={classes.container}>
                <div className={classes.content} style={isMobileView() ? {marginTop: '110px'} : null}>
                    {isMobileView() ?
                        <div className={classes.ongoingConsultation}><p>{props?.selectedPatient?.patientName} • Ongoing
                            Consultation</p></div> : null}
                    {isMobileView() ? <div className={classes.main_with_border}>

                        <span>You can press # to load any saved templates </span>
                    </div> : null}
                    {[{
                        label: 'Subjective Symptoms:',
                        placeholder: 'Start typing here...',
                        value: symptoms,
                        onChange: (text) => onSubjectSymptomsChange(text),
                        onChangeCannedShortcut: (text) => setSymptomsCannedShortcut(text),
                        cannedShortcut: 'symptomsCannedShortcut',
                        checkbox: 'symptomsCheckbox',
                        checkboxLabel: 'Save as a Subjective Symptom template',
                    },
                        {
                            label: 'Objective Findings:',
                            placeholder: 'Start typing here...',
                            value: findings,
                            onChange: (text) => onObjectiveFindingsChange(text),
                            onChangeCannedShortcut: (text) => setFindingsCannedShortcut(text),
                            cannedShortcut: 'findingsCannedShortcut',
                            checkbox: 'findingsCheckbox',
                            checkboxLabel: 'Save as a Objective Finding template',
                            children: showCannedResponseForObjectiveFindingsList &&
                                <div className={classes.canned_response_list}>
                                    <div className={classes.canned_response_view}>
                                        <CannedResponsesList
                                            canned_responses_data={cannedResponses}
                                            onCannedResponseClick={(uid, short_cut, text) => onObjectiveCannedResponseClick(uid, short_cut, text)}
                                        />
                                    </div>
                                </div>
                        },
                        {
                            label: 'Assessment:',
                            placeholder: 'Start typing here...',
                            value: assessment,
                            onChange: (text) => onAssessmentGoalsChange(text),
                            onChangeCannedShortcut: (text) => setAssessmentGoalsCannedShortcut(text),
                            cannedShortcut: 'assessmentGoalsCannedShortcut',
                            checkbox: 'assessmentCheckbox',
                            checkboxLabel: 'Save as a Assessment template',
                            children: showCannedResponseForAssessmentList &&
                                <div className={classes.canned_response_list}>
                                    <div className={classes.canned_response_view}>
                                        <CannedResponsesList
                                            canned_responses_data={cannedResponses}
                                            onCannedResponseClick={(uid, short_cut, text) => onAssessmentCannedResponseClick(uid, short_cut, text)}
                                        />
                                    </div>
                                </div>
                        },
                        {
                            label: 'Plan:',
                            placeholder: 'Start typing here...',
                            value: plan,
                            onChange: (text) => onPlanChange(text),
                            onChangeCannedShortcut: (text) => setPlamCannedShortcut(text),
                            cannedShortcut: 'planCannedShortcut',
                            checkbox: 'planCheckbox',
                            checkboxLabel: 'Save as a Plan template',
                            children: showCannedResponseForPlanList &&
                                <div className={classes.canned_response_list}>
                                    <div className={classes.canned_response_view}>
                                        <CannedResponsesList
                                            canned_responses_data={cannedResponses}
                                            onCannedResponseClick={(uid, short_cut, text) => onPlanCannedResponseClick(uid, short_cut, text)}
                                        />
                                    </div>
                                </div>
                            ,
                            sibling: null
                        }].map(item => <div key={item.label}>
                        <div className={classes.main}>
                            <div className={classes.symptomsText}>{item.label} </div>
                            {isMobileView() ? <textarea
                                className={classes.input}
                                placeholder={item.placeholder}
                                onChange={(text) => item.onChange(text)}
                                value={item.value}
                                style={{height: '80px'}}
                            /> : <TextareaAutosize
                                className={classes.input}
                                placeholder={item.placeholder}
                                onChange={(text) => item.onChange(text)}
                                value={item.value}
                            />}
                            {item.children ? item.children : null}
                        </div>
                        {isMobileView() ? <div className={classes.main} style={{flexDirection: 'row'}}>
                            <div className={classes.split_half}>

                                <div className={classes.radioButtonStyle}>
                                        <input type="checkbox" name={item.checkbox}
                                               checked={checkBoxes[item.checkbox]}
                                               onClick={() => {
                                                   setCheckboxes({...checkBoxes, [item.checkbox]: ![item.checkbox]})
                                               }}
                                        />
                                    </div>

                            </div>
                            <textarea
                                className={classes.symptomCheckboxLabel}
                                placeholder={item.checkboxLabel}
                                onChange={(text) => item.onChangeCannedShortcut(text.target.value)}
                                value={checkBoxes[item.cannedShortcut]}
                                style={{height: '15px'}}
                            />
                            {/* <div className={classes.symptomCheckboxLabel}>{item.checkboxLabel} */}
                            {/* </div> */}
                        </div> : null}
                    </div>)}


                    <div className={classes.main}>
                        <div className={classes.diagnosisText}>Issue resolved:</div>
                        <div className={classes.contentView}>
                            <input
                                type="radio"
                                onChange={() => handleChangeIssueResolved('virtually')}
                                checked={issueResolved === 'virtually' ? true : false}
                            />
                            <div className={classes.yesText}>Virtually</div>
                            <input
                                type="radio"
                                onChange={() => handleChangeIssueResolved('doctor_visit_needed')}
                                checked={issueResolved === 'doctor_visit_needed' ? true : false}
                            />
                            <div className={classes.yesText}>Doctor visit needed</div>
                            <input
                                type="radio"
                                onChange={() => handleChangeIssueResolved('inactive_chat')}
                                checked={issueResolved === 'inactive_chat' ? true : false}
                            />
                            <div className={classes.yesText}>Inactive chat</div>
                        </div>
                    </div>

                  {/* Referral Option Select */}
                    <div className={classes.main}>
                        <div className={classes.diagnosisText}>Referral Type:</div>
                        <div className={classes.contentView}>
                        <select
                        value={referralOptionSelected}
                        className={classes.referral_dropdown}
                        onChange={(event) => onReferralOptionChange(event)}
                        >
                        <option value={null}>None</option>
                        {props.show && props.referralArray && props.referralArray.map((option,index) => (
                             <option key={index} value={option}>
                            {option}
                            </option>
                        ))
                        }
                    </select>
                        </div>
                    </div>
                </div>

                {!loading &&
                <div className={classes.buttonView}>
                    <button className={isMobileView() ? _style.sidebar_btn : classes.addMoreMediciensButton}
                            onClick={() => saveOutPatientForm()}>Save and Close
                    </button>
                    {/* <button className={isMobileView() ? _style.sidebar_transparent_btn : classes.cancelButton}
                                onClick={() => {
                                    const modalTitle = "Virtual Clinic Visit Report";
                                    if (!Boolean(modalWindow)) {
                                        modalWindow = window.open("/clinicalreport", modalTitle);
                                        modalWindow.addEventListener('load', () => {
                                            modalWindow.document.title = modalTitle;
                                            renderPopOut();
                                            // renderPrescription()
                                        }, false);
                                    } else {
                                        modalWindow.close();
                                        modalWindow = window.open("/clinicalreport", modalTitle);
                                        modalWindow.addEventListener('load', () => {
                                            modalWindow.document.title = modalTitle;
                                            renderPopOut();
                                            // renderPrescription()
                                        }, false);
                                    }
                                }}> View PDF
                        </button> */}
                    <button className={isMobileView() ? _style.sidebar_transparent_btn : classes.cancelButton}
                            onClick={() => onCancelClick()}>Cancel
                    </button>
                </div>
                }

                {props.error !== '' &&
                <div className={classes.buttonView}>
                    <div className={classes.errorText}>{props.error}</div>
                    <button className={isMobileView() ? _style.sidebar_btn : classes.addMoreMediciensButton}
                            onClick={() => closeChat()}>Close Chat
                    </button>
                </div>
                }

                {loading &&
                <div className={classes.buttonView}>
                    <div className={classes.no_loading_view}>
                        <div className={classes.loading_loader}/>
                    </div>
                </div>
                }
            </div>
            }
            {props.message !== '' &&
            <div className={classes.saveView} style={isMobileView ? {marginTop: '70px'} : null}>
                <div className={classes.messageText}>{props.message}</div>
                <button className={isMobileView() ? _style.sidebar_transparent_btn : classes.cancelButton}
                        onClick={() => closeOnSaveClick()}>OK
                </button>
            </div>
            }
        </div>
    )
}


const mapStateToProps = ({outPatientFormReducer, cannedResponseReducer, conversationReducer, chatReducer, tagsReducer}) => {
    const {loading, message, error} = outPatientFormReducer;
    const { followUpStatus  } = tagsReducer;
    const {canned_responses} = cannedResponseReducer;
    const {
        patientId,
        conversationId,
        paidChat,
        tagChatList,
        admin,
        selectedIssue,
        selectedCategory,
        chatStartTime,
        payPerMinuteChat,
        prescription_data,
        selectedPatient,
        selectedConversationObj,
        user_medications } = conversationReducer;
    const {
        doctorID,
        doctorName,
        doctorImage
    } = chatReducer;
//start_timestamp, endTime
    return {loading, message, error, canned_responses, user_medications, prescription_data,
        followUpStatus, payPerMinuteChat,
        paidChat,conversationId,doctorID, admin,doctorName, patientId,
        selectedIssue,selectedCategory,chatStartTime, tagChatList,
        doctorImage, selectedPatient, selectedConversationObj
    };
}

export default connect(mapStateToProps, {
    saveAndCloseOutPatientForm,
    destroyThread,
    closePatientChat,
    refreshState,
    setSubjectiveSymptoms,
    setFindings,
    setAssessment,
    setPlan
})(PatientForm);
