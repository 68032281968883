import  {useRef} from 'react';
import classes from './UserStyle.module.css';
import UsersList from './UsersList';
import {MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';

import _ from 'lodash';


const Header = (props) => {
    return (
        <tr>
            <th className={classes.table_column_name_email}>
                <span className={classes.table_heading}>Name</span>
            </th>
            <th className={classes.table_column_name_email}>
                <span className={classes.table_heading}>Email</span>
            </th>
            <th className={classes.table_column}>
                <span className={classes.table_heading}>No. of Chats</span>
            </th>
            <th className={classes.table_column}>
                <span className={classes.table_heading}>Status</span>
            </th>
            <th className={classes.table_column}>
                <span className={classes.table_heading}>Action</span>
            </th>
        </tr>
    )
}

const UserBody = (props) => {
    const inputRef = useRef(null);

    if (props.activeLink === 'patients') {
        // eslint-disable-next-line array-callback-return
        const users = props.usersData.length > 0 ? props.usersData.map(user => {
            if (user) {
                return <UsersList
                    Name={user.name}
                    userID={user.userID}
                    Email={user.email}
                    noOfChats={user.noOfChats}
                    Ban={user.ban}
                    userState={(isBan, userID) => props.userState(isBan, userID)}
                />
            }

        }) : null;

        const filtered_users = _.compact(users);
        if (props.search_query !== '') {
            return (

                <div className={classes.table_content}>
                    <MDBTable>
                        <MDBTableHead>
                            <Header/>
                        </MDBTableHead>
                        <MDBTableBody>
                            {filtered_users}
                            {props.showLoadMore &&
                            <div onClick={() => props.LoadMore()} className={classes.load_more}>Load More</div>
                            }
                        </MDBTableBody>

                    </MDBTable>
                    {filtered_users.length === 0 &&
                    <div className={classes.empty_list_text}>
                        <span>There is no user available with this email address.</span>
                    </div>
                    }
                </div>
            )
        } else {
            return (
                <div className={classes.searchDivRow}>
                    <div className={classes.searchDivCol}>
                        <input type="text" ref={inputRef} placeholder="email"
                               className={classes.search_input_main}></input>
                        <input
                            type="button"
                            value="Search"
                            className={classes.search_button_main}
                            onClick={() => props.searchUsers(inputRef.current.value)}
                        />
                    </div>
                </div>
            )
        }
    } else {
        // eslint-disable-next-line array-callback-return
        const users = props.bannedUsersData.length > 0 ? props.bannedUsersData.map(user => {
            if (user) {
                return <UsersList
                    Name={user.name}
                    userID={user.userID}
                    Email={user.email}
                    noOfChats={user.noOfChats}
                    Ban={user.ban}
                    userState={(isBan, userID) => props.userState(isBan, userID)}
                />
            }

        }) : null;
        const filtered_users = _.compact(users);
        return (

            <div className={classes.table_content}>
                <MDBTable>
                    <MDBTableHead>
                        <Header/>
                    </MDBTableHead>
                    <MDBTableBody>
                        {filtered_users}

                    </MDBTableBody>
                </MDBTable>
                {filtered_users.length === 0 &&
                <div className={classes.empty_list_text}>
                    <span>Currently there is no user banned.</span>
                </div>
                }
            </div>
        )
    }
}

export default UserBody;
