import {
    ACCEPTING_CHAT_FALSE_ADMIN,
    ACCEPTING_CHAT_TRUE,
    ACCEPTING_CHAT_TRUE_ADMIN,
    ADD_AGENT_FAILS,
    ADD_AGENT_INPROGRESS,
    ADD_AGENT_SUCCESS,
    ADD_FREE_CHAT_LIMIT,
    ADD_PAID_CHAT_LIMIT,
    ADD_TOTAL_CHAT_LIMIT, ATTACHMENT_UPLOAD_FAIL, ATTACHMENT_UPLOAD_SUCCESS,
    CHANGED_DOCTOR_KEY,
    CLINIC_ADDRESS,
    CLINIC_LOCATION,
    CLINIC_NAME,
    CLINIC_PHONE_NUMBER,
    CLINICAL_DOCTOR_STATUS,
    CURRENT_USER_ID,
    DELETE_DOCTOR_SUCCESS,
    DOCTOR_CHAT_ASSIGN_CHECK,
    DOCTOR_DESCRIPTION,
    DOCTOR_EXPERIENCE,
    DOCTOR_HOSPITAL_LOCATION,
    FETCH_AGENT,
    FETCH_AGENTS_FAILED,
    FETCH_AGENTS_SUCCESS,
    MINUS_FREE_CHAT_LIMIT,
    MINUS_PAID_CHAT_LIMIT,
    MINUS_TOTAL_CHAT_LIMIT, REFRESH_AGENT_STATUS,
    RESET_SUCCESS,
    SHOW_IMAGE_PREVIEW,
    SHOW_SIGNATURE_PREVIEW,
    UPDATE_DOCTOR_DETAIL_FAILED,
    UPDATE_DOCTOR_DETAIL_SUCCESS,
    UPDATE_DOCTOR_LICENSE,
    UPDATE_DOCTOR_NAME,
    UPDATE_DOCTOR_TITLE
} from '../constants';
import _ from 'lodash';
import {auth, database, functions, storage, storageRef} from "../firebase";
import axios from "axios";
import {firebaseNorthEastRoot} from "../config";
const {detect} = require('detect-browser');
const browser = detect();
let doctorsRef = null
let doctorsListener = null
export const updateDoctorName = (name) => {
    return {
        type: UPDATE_DOCTOR_NAME,
        payload: name
    }
}

export const updateDoctorLicense = (license) => {
    return {
        type: UPDATE_DOCTOR_LICENSE,
        payload: license,
    }
}

export const addTotalChatLimit = (limit) => {
    return {
        type: ADD_TOTAL_CHAT_LIMIT,
        payload: limit + 1
    }
}

export const showImagePreview = (image) => {
    return {
        type: SHOW_IMAGE_PREVIEW,
        payload: image
    }
}

export const showSignaturePreview = (image) => {
    return {
        type: SHOW_SIGNATURE_PREVIEW,
        payload: image
    }
}

export const resetSuccess = (image) => {
    return (dispatch) => {
        dispatch({
            type: RESET_SUCCESS
        });
    }
}

export const minusTotalChatLimit = (limit) => {
    return {
        type: MINUS_TOTAL_CHAT_LIMIT,
        payload: limit - 1
    }
}

export const addFreeChatLimit = (limit) => {
    return {
        type: ADD_FREE_CHAT_LIMIT,
        payload: limit + 1
    }
}

export const minusFreeChatLimit = (limit) => {
    return {
        type: MINUS_FREE_CHAT_LIMIT,
        payload: limit - 1
    }
}

export const addPaidChatLimit = (limit) => {
    return {
        type: ADD_PAID_CHAT_LIMIT,
        payload: limit + 1
    }
}

export const updateClinicStatus = (status) => {
    return {
        type: CLINICAL_DOCTOR_STATUS,
        payload: !status
    }
}

export const updateClinicName = (name) => {
    return {
        type: CLINIC_NAME,
        payload: name
    }
}

export const updateClinicLocation = (location) => {
    return {
        type: CLINIC_LOCATION,
        payload: location
    }
}

export const updateClinicAddress = (address) => {
    return {
        type: CLINIC_ADDRESS,
        payload: address,
    }
};

export const updateClinicPhoneNumber = (number) => {
    return {
        type: CLINIC_PHONE_NUMBER,
        payload: number,
    }
}

export const updateDoctorDescription = (data) => {
    return {
        type: DOCTOR_DESCRIPTION,
        payload: data,
    }
}

export const updateDoctorExperience = (data) => {
    return {
        type: DOCTOR_EXPERIENCE,
        payload: data,
    }
}

export const updateDoctorHospitalLocationChange = (data) => {
    return {
        type: DOCTOR_HOSPITAL_LOCATION,
        payload: data,
    }
}

export const updateDoctorChatAssignCheckChange = (data) => {
    return {
        type: DOCTOR_CHAT_ASSIGN_CHECK,
        payload: data,
    }
}


export const deleteDoctor = (doctorID) => {
    return (dispatch) => {
        let updates = {};
        updates[`/doctors/${doctorID}/online`] = false;
        updates[`/doctors/${doctorID}/profileStatus`] = "deleted";
        updates[`/onlineDoctors/${doctorID}`] = false;
        database.ref().update(updates)
            .then(() => {
                dispatch({
                    type: DELETE_DOCTOR_SUCCESS
                })
            })
    }
}

export const activateDoctor = (doctorID) => {
    return (dispatch) => {
        let updates = {};
        updates[`/doctors/${doctorID}/profileStatus`] = "active";
        database.ref().update(updates)
            .then(() => {
                dispatch({
                    type: DELETE_DOCTOR_SUCCESS
                })
            })
    }
}

export const minusPaidChatLimit = (limit) => {
    return {
        type: MINUS_PAID_CHAT_LIMIT,
        payload: limit - 1
    }
}

export const updateDoctorTitle = (title) => {
    return {
        type: UPDATE_DOCTOR_TITLE,
        payload: title
    }
}

export const setStatusFromAdmin = (time) => {
    return (dispatch) => {
        dispatch({
            type: ACCEPTING_CHAT_TRUE,
            payload: time,
        })
    }
}

export const resetAgentListeners = () => {
    return (dispatch) => {

        if(doctorsListener){
            doctorsRef.off('child_changed', doctorsListener);
            doctorsRef = null;
            doctorsListener = null;
        }
        dispatch({type:REFRESH_AGENT_STATUS})
    }

}
export const childChangedDoctor = (clinic_id) => {
    return (dispatch) => {
        try {
            if(doctorsListener){
                doctorsRef.off('child_changed', doctorsListener);
                doctorsRef = null;
                doctorsListener = null;
            }
            if (clinic_id) {
                doctorsRef = database.ref(`/clinicStaffMember/${clinic_id}`)
                doctorsListener = doctorsRef.on('child_changed', childChangedSnap => {
                    dispatch({
                        type: CHANGED_DOCTOR_KEY,
                        payload: childChangedSnap.ref_.path.pieces_[1]
                    })
                })
            } else {
                doctorsRef = database.ref(`/doctors`)
                doctorsListener = doctorsRef.on('child_changed', childChangedSnap => {
                    dispatch({
                        type: CHANGED_DOCTOR_KEY,
                        payload: childChangedSnap?.ref_?.path?.pieces_[1] || null
                    })
                })
            }

        } catch (error) {
            //console.log("childChangedDoctor error ", error);
        }

    }
}

const storeImage = (filename, imageURL, callback) => {
    if(imageURL){
        const uploadTask = storage.ref(`/doctor_images/${filename}`).put(imageURL)
        uploadTask.on('state_changed', (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                //setNow(progress)
                console.log(`firebasestorage Upload is ${progress}% done`)
                switch (snapshot.state) {
                    case storageRef.TaskState.PAUSED: // or 'paused'
                        console.log('firebasestorage Upload is paused')
                        break
                    case storageRef.TaskState.RUNNING: // or 'running'
                        console.log('firebasestorage Upload is running')
                        break
                    default:
                        break
                }
            },
            (error) => {
                console.log('firebasestorage error ', error)
                callback(null, error)
                // Handle unsuccessful uploads
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    callback(downloadURL)

                }).catch((error) => {
                    console.log('firebasestorage getDownloadURL error ', error)
                    callback(null, error)

                })
            })
    }else{
        callback(null)
    }
}

export const resetAgentsMessage = () => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_DOCTOR_DETAIL_SUCCESS,
            payload: ''
        })
    }
}

export const updateDoctorDetail = (imageURL, signatureURL, uid, {
    updateName, updateTitle, updateLicense, updateTotalChatLimit, updateFreeChatLimit, updatePaidChatLimit,
    clinicalDoctorStatus, clinicName, clinicLocation, clinicAddress, clinicPhoneNumber, updateDescription,
    updateExperience,updateHospitalLocation,updateDoctorChatAssignCheck
}, clinicalStatusUpdate, email, doctorChatType) => {
    return (dispatch) => {

        let doctorValue = {
            doctorName: updateName,
            jobTitle: updateTitle,
            doctorlicense: updateLicense,
            freeChatLimit: updateFreeChatLimit,
            paidChatLimit: updateTotalChatLimit,
            totalChatLimit: updateTotalChatLimit,
            clinicalDoctor: clinicalDoctorStatus,
            clinicName: clinicName,
            clinicLocationName: clinicLocation,
            clinicAddress: clinicAddress,
            clinicPhoneNumber: clinicPhoneNumber,
            description:updateDescription,
            experience:updateExperience,
            hospital_location:updateHospitalLocation,
            chatAssignCheck:updateDoctorChatAssignCheck,
            doctorChatType:doctorChatType
        }


        const filename = `${uid}/profile/${(new Date()).getTime()}_${updateName.replace(/[^0-9a-z^]/gi, '')}`
        storeImage(filename, imageURL, (data, error)=>{
            if(error){
                dispatch({
                    type: UPDATE_DOCTOR_DETAIL_FAILED,
                    payload: 'Image failed to upload'
                })
            }
            if(data){
                doctorValue.profileImageURL =data;
            }
            const signatureName = `${uid}/signature/${(new Date()).getTime()}_${updateName.replace(/[^0-9a-z^]/gi, '')}`
            storeImage(signatureName, signatureURL, (data, error)=>{
                if(error){
                    dispatch({
                        type: UPDATE_DOCTOR_DETAIL_FAILED,
                        payload: 'Signature file fail to upload'
                    })
                }
                if(data){
                    doctorValue.signatureImageURL = data;
                }
                getAgentRef(uid).update(doctorValue)
                    .then(() => {
                        dispatch({
                            type: UPDATE_DOCTOR_DETAIL_SUCCESS,
                            payload: 'Profile is successfully updated'
                        })
                    })
                    .catch((error) => {
                        dispatch({
                            type: UPDATE_DOCTOR_DETAIL_FAILED,
                            payload: error.message
                        })
                    })
            })
        })

        /*if (imageURL !== '') {

            S3FileUpload.uploadFile(imageURL, config)
                .then(response => {
                    if (response.result.status !== 204) {
                        dispatch({
                            type: UPDATE_DOCTOR_DETAIL_FAILED,
                            payload: 'Image failed to upload'
                        })
                    }

                    //File Uploaded to S3.
                    if (response.location) {
                        doctorValue.profileImageURL = response.location;

                        if (signatureURL !== '') {

                            S3FileUpload.uploadFile(signatureURL, config)
                                .then(sign_response => {
                                    if (sign_response.result.status !== 204) {
                                        dispatch({
                                            type: UPDATE_DOCTOR_DETAIL_FAILED,
                                            payload: 'Signature file fail to upload'
                                        })
                                    }

                                    //File Uploaded to S3.
                                    if (sign_response.location) {
                                        doctorValue.signatureImageURL = sign_response.location;

                                        getAgentRef(uid).update(doctorValue)
                                            .then(() => {
                                                dispatch({
                                                    type: UPDATE_DOCTOR_DETAIL_SUCCESS,
                                                    payload: 'Profile is successfully updated'
                                                })
                                            })
                                            .catch((error) => {
                                                dispatch({
                                                    type: UPDATE_DOCTOR_DETAIL_FAILED,
                                                    payload: error.message
                                                })
                                            })
                                    }
                                })
                        } else {
                            getAgentRef(uid).update(doctorValue)
                                .then(() => {
                                    dispatch({
                                        type: UPDATE_DOCTOR_DETAIL_SUCCESS,
                                        payload: 'Profile is successfully updated'
                                    })
                                })
                                .catch((error) => {
                                    dispatch({
                                        type: UPDATE_DOCTOR_DETAIL_FAILED,
                                        payload: error.message
                                    })
                                })
                        }
                    }
                })
        }
        else {
            if (clinicalStatusUpdate) {
                const addClinicalDoctor = functions.httpsCallable('addClinicalDoctor');
                addClinicalDoctor({email: email, name: clinicName, location: clinicLocation})
                    .then(status => {
                        if (status.data) {

                            if (signatureURL !== '') {
                                S3FileUpload.uploadFile(signatureURL, config)
                                    .then(sign_response => {
                                        if (sign_response.result.status !== 204) {
                                            dispatch({
                                                type: UPDATE_DOCTOR_DETAIL_FAILED,
                                                payload: 'Signature file fail to upload'
                                            })
                                        }

                                        //File Uploaded to S3.
                                        if (sign_response.location) {
                                            doctorValue.signatureImageURL = sign_response.location;

                                            getAgentRef(uid).update(doctorValue)
                                                .then(() => {
                                                    dispatch({
                                                        type: UPDATE_DOCTOR_DETAIL_SUCCESS,
                                                        payload: 'Profile is successfully updated'
                                                    })
                                                })
                                                .catch((error) => {
                                                    dispatch({
                                                        type: UPDATE_DOCTOR_DETAIL_FAILED,
                                                        payload: error.message
                                                    })
                                                })
                                        }
                                    })
                            } else {
                                getAgentRef(uid).update(doctorValue)
                                    .then(() => {
                                        dispatch({
                                            type: UPDATE_DOCTOR_DETAIL_SUCCESS,
                                            payload: 'Profile is successfully updated'
                                        })
                                    })
                                    .catch((error) => {
                                        dispatch({
                                            type: UPDATE_DOCTOR_DETAIL_FAILED,
                                            payload: error.message
                                        })
                                    })
                            }
                        } else {
                            getAgentRef(uid).update(doctorValue)
                                .then(() => {
                                    dispatch({
                                        type: UPDATE_DOCTOR_DETAIL_SUCCESS,
                                        payload: 'Profile is successfully updated but clinical status failed. Please try once again'
                                    })
                                })
                                .catch((error) => {
                                    dispatch({
                                        type: UPDATE_DOCTOR_DETAIL_FAILED,
                                        payload: error.message
                                    })
                                })
                        }
                    });
            }
            else {

                if (signatureURL !== '') {
                    S3FileUpload.uploadFile(signatureURL, config)
                        .then(sign_response => {
                            if (sign_response.result.status !== 204) {
                                dispatch({
                                    type: UPDATE_DOCTOR_DETAIL_FAILED,
                                    payload: 'Signature file fail to upload'
                                })
                            }

                            //File Uploaded to S3.
                            if (sign_response.location) {
                                doctorValue.signatureImageURL = sign_response.location;

                                getAgentRef(uid).update(doctorValue)
                                    .then(() => {
                                        dispatch({
                                            type: UPDATE_DOCTOR_DETAIL_SUCCESS,
                                            payload: 'Profile is successfully updated'
                                        })
                                    })
                                    .catch((error) => {
                                        dispatch({
                                            type: UPDATE_DOCTOR_DETAIL_FAILED,
                                            payload: error.message
                                        })
                                    })
                            }
                        })
                } else {
                    getAgentRef(uid).update(doctorValue)
                        .then(() => {
                            dispatch({
                                type: UPDATE_DOCTOR_DETAIL_SUCCESS,
                                payload: 'Profile is successfully updated'
                            })
                        })
                        .catch((error) => {
                            dispatch({
                                type: UPDATE_DOCTOR_DETAIL_FAILED,
                                payload: error.message
                            })
                        })
                }
            }
        }*/
    }
}


export function addAgent(agent_email, agent_password, agent_name, agent_role, clinicalDoctor,
                         clinicName, locationName, address, phoneNumber, callback) {
    return (dispatch) => {
        dispatch({type: ADD_AGENT_INPROGRESS});
        console.log("addAgent data ", {agent_email, agent_password, agent_name, agent_role, clinicalDoctor,
            clinicName, locationName, address, phoneNumber});
        let payload = {
            email: agent_email,
            password: agent_password,
            name: agent_name,
            role: agent_role,
            clinicalDoctor: clinicalDoctor,
            clinicName: clinicName,
            locationName: locationName,
            clinicAddress: address,
            clinicPhoneNumber: phoneNumber,
        }
        axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            url: `${firebaseNorthEastRoot}/doctorDashboard-addAgent`,
            data: JSON.stringify(payload),
            mode: 'cors',
        }).then(res=>{
                console.log("res ", res)
            //console.log('this.state.controls.role.value', result)
                switch (res?.data?.result || null) {
                    case "user_exists":
                        dispatch({
                            type: ADD_AGENT_SUCCESS,
                            payload: agent_role + ' already exists'
                        })
                        callback(true);
                        return;
                    case "user_added":
                        dispatch({
                            type: ADD_AGENT_SUCCESS,
                            payload: agent_role + ' added successfully'
                        })
                        callback(true);
                        return;
                    case "user_role_failed":
                        dispatch({
                            type: ADD_AGENT_FAILS,
                            payload: agent_role + ' added but addition auth role failed'
                        })
                        callback(false);
                        return;
                    case "user_creation_failed":
                        dispatch({
                            type: ADD_AGENT_FAILS,
                            payload: agent_role + ' creation failed'
                        })
                        callback(false);
                        return;
                    case "user_profile_setup_failed":
                        dispatch({
                            type: ADD_AGENT_FAILS,
                            payload: agent_role + ' profile setup failed'
                        })
                        callback(false);
                        return;
                    default:
                        dispatch({
                            type: ADD_AGENT_FAILS,
                            payload: 'something went wrong'
                        })
                        callback(false);
                        return;
                }
            /*if (result.data.data === 1) {
                //console.log('addAgent data ===1', result.data.data)
                if (agent_role === 'Doctor') {
                    if (clinicalDoctor) {
                        //console.log('addClinicalDoctor', result.data.data)
                        const addClinicalDoctor = functions.httpsCallable('addClinicalDoctor');
                        addClinicalDoctor({
                            email: agent_email,
                            name: clinicName,
                            location: locationName,
                            address: address,
                            phoneNumber: phoneNumber,
                        }).then(status => {
                            if (status.data) {
                                //console.log('addClinicalDoctor true', status)
                                callback(true);
                                dispatch({
                                    type: ADD_AGENT_SUCCESS,
                                    payload: 'Clinical doctor has been added successfully'
                                })
                            } else {

                                //console.log('addClinicalDoctor false', status)
                                callback(false);
                                dispatch({
                                    type: ADD_AGENT_FAILS,
                                    payload: 'Clinical doctor has been added but addition auth role failed'
                                })
                            }
                        });
                    }
                    else {
                        //console.log('addDoctorRole', result.data.data)
                        const addDoctorRole = functions.httpsCallable('addDoctorRole');
                        addDoctorRole({email: agent_email}).then(status => {
                            if (status.data) {
                                //console.log('addDoctorRole true', status)
                                callback(true);
                                dispatch({
                                    type: ADD_AGENT_SUCCESS,
                                    payload: 'Doctor added successfully'
                                })
                            } else {
                                //console.log('addDoctorRole false', status)
                                callback(false);
                                dispatch({
                                    type: ADD_AGENT_FAILS,
                                    payload: 'Doctor added but addition auth role failed'
                                })
                            }
                        });
                    }
                } else {
                    const addAdminRole = functions.httpsCallable('addAdminRole');
                    addAdminRole({email: agent_email}).then(status => {
                        if (status.data) {
                            callback(true);
                            dispatch({
                                type: ADD_AGENT_SUCCESS,
                                payload: 'Admin is added successfully'
                            })
                        } else {
                            callback(false);
                            dispatch({
                                type: ADD_AGENT_FAILS,
                                payload: 'Admin is added but addition auth role failed'
                            })
                        }
                    });
                }
            } else if (result.data.data === 0) {
                //console.log('addAgent data ===0', result.data.data)
                callback(false);
                dispatch({
                    type: ADD_AGENT_FAILS,
                    payload: 'Agent added but saving agent rule failed'
                })
            } else if (result.data.data === -1) {
                callback(false);
                dispatch({
                    type: ADD_AGENT_FAILS,
                    payload: 'Agent creation failed'
                })
            }*/
        });
    }
}

export const fetchAgents = () => {
    return (dispatch) => {
        getAgents().on('value', doctorsSnapshot => {
            if (doctorsSnapshot.val()) {
                const agents = _.map(doctorsSnapshot.val(), (val, uid) => {
                    return {...val, uid};
                });
                dispatch({
                    type: FETCH_AGENTS_SUCCESS,
                    payload: agents
                })
            } else {
                dispatch({
                    type: FETCH_AGENTS_FAILED,
                    payload: []
                })
            }
        })
    }
}
let agentsRef = null
export const fetchAgentData = (uid) => {
    return (dispatch) => {
        var user = auth.currentUser;
        if (user) {
            dispatch({
                type: CURRENT_USER_ID,
                payload: user.uid
            })

        }
        if(agentsRef){
            agentsRef.off()
            agentsRef = null;
        }
        agentsRef = getAgentRef(uid)
        agentsRef.on('value', doctorsSnapshot => {
            if (doctorsSnapshot.val()) {
                var doctorData = doctorsSnapshot.val();
                doctorData.uid = doctorsSnapshot.key;
                dispatch({
                    type: FETCH_AGENT,
                    payload: doctorData
                })
            } else {
                dispatch({
                    type: FETCH_AGENT,
                    payload: []
                })
            }
        })
    }
}

export const status = (agent) => {
    return (dispatch) => {
        if (agent.accepting_chat === true) {
            dispatch({type: ACCEPTING_CHAT_TRUE_ADMIN})
            return true
        } else {
            dispatch({type: ACCEPTING_CHAT_FALSE_ADMIN})
            return false

        }
    }
}

export const changeAcceptStatusAdmin = (uid, status) => {
    return (dispatch) => {
        database.ref(`/doctors/${uid.uid}/accepting_chat`).once('value', async doctorsSnapshot => {
            var updates = {}
            var key  = database.ref('/activitiesV2').push().key
            var sessionStart = null
            await database
                    .ref('activitiesV2')
                    .orderByChild('doctorID')
                    .equalTo(uid.uid)
                    .limitToLast(10)
                    .once('value', (snapshot) => {
                      const d = snapshot.toJSON()
                     if(d!==null){
                        const keys = Object.keys(d)
                        for (let i = keys.length - 1; i >= 0; i--) {
                          if (d[keys[i]]['event'] == 'accepting_chat_on') {
                            sessionStart = d[keys[i]]["timestamp"]
                            return
                          }
                        }
                     }
                    })

            updates[`/doctors/${uid.uid}/accepting_chat`] = status
            //V2
            let obj = {
                doctorID:uid.uid,
                timestamp: new Date().getTime(),
                event: status?"accepting_chat_on":"accepting_chat_off",
                admin: auth.currentUser.uid,
                browserInfo:browser,
            }
            if(!status){
                obj["startTimestamp"] = sessionStart
            }
            updates[`/activitiesV2/${key}`] = obj
            //
            database.ref().update(updates)
                .then(data => {
                    if (status === false) {
                        dispatch({type: ACCEPTING_CHAT_FALSE_ADMIN})
                    } else {
                        dispatch({type: ACCEPTING_CHAT_TRUE_ADMIN})
                    }
                })
                .catch(error => {
                })
        })
    }
}

const getAgents = () => {
    return database.ref().child('doctors');
}

const getAgentRef = (uid) => {
    return database.ref(`/doctors/${uid}`);
}
