import {
    FETCH_DOCTOR_DETAILS,
    FETCH_ONLINE_CLINICAL_DOCTORS_GROUP,
    FETCH_ONLINE_CLINICAL_DOCTORS_GROUP_IN_PROCESS, REFRESH_DOCTOR_STATUS
} from '../constants';


import _ from 'lodash';
import {auth, database} from "../firebase";

let doctorRef = null, doctorListener = null, doctorsRef = null, doctorsListener = null
export const resetDoctorsListeners = () => {
    return (dispatch) => {
        if(doctorListener){
            doctorRef.off('value',doctorListener );
            doctorRef = null;
            doctorListener = null;
        }
        if(doctorsListener){
            doctorsRef.off('value',doctorsListener );
            doctorsRef = null;
            doctorsListener = null;
        }
        dispatch({type: REFRESH_DOCTOR_STATUS});

    }
}
export const fetchDoctorDetails = () => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        try {
            if(doctorListener){
                doctorRef.off('value',doctorListener );
                doctorRef = null;
                doctorListener = null;
            }
            doctorRef = getDoctorByRef(currentUserID)
            doctorListener = doctorRef.on('value', doctorSnap => {
                if (doctorSnap.val()) {
                    var doctor = doctorSnap.val();
                    doctor.uid = doctorSnap.key;

                    dispatch({
                        type: FETCH_DOCTOR_DETAILS,
                        payload: doctor
                    })
                } else {
                    dispatch({
                        type: FETCH_DOCTOR_DETAILS,
                        payload: []
                    })
                }
            })
        } catch (error) {
            //console.log("fetchDoctorDetails error ", error);
        }

    }
}

export const fetchOnlineClinicalDoctorsGroup = () => {
    return (dispatch) => {
        dispatch({type: FETCH_ONLINE_CLINICAL_DOCTORS_GROUP_IN_PROCESS});
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        getDoctorByRef(currentUserID).once('value', doctorSnap => {
            if (doctorSnap.val()) {
                if(doctorsListener){
                    doctorsRef.off('value',doctorsListener );
                    doctorsRef = null;
                    doctorsListener = null;
                }
                doctorsRef = getDoctors()
                doctorsListener = doctorsRef.on('value', doctorsSnapshot => {
                    if (doctorsSnapshot.val()) {
                        const onlineClinicalDoctors = _.map(doctorsSnapshot.val(), (val, uid) => {
                            if (val.online && val.profileStatus !== "deleted" && val.clinicalDoctor &&
                                val.clinicName.toLowerCase() === doctorSnap.val().clinicName.toLowerCase()) {
                                return {...val, uid};
                            }
                        });

                        var filteredDoctors = onlineClinicalDoctors.filter(function (el) {
                            return el != null && el !== "" && el !== undefined;
                        });

                        dispatch({
                            type: FETCH_ONLINE_CLINICAL_DOCTORS_GROUP,
                            payload: filteredDoctors
                        })
                    } else {
                        dispatch({
                            type: FETCH_ONLINE_CLINICAL_DOCTORS_GROUP,
                            payload: []
                        })
                    }
                })
            }
        });
    }
}

const getDoctorByRef = (uid) => {
    return database.ref(`/doctors/${uid}`);
}

const getDoctors = () => {
    return database.ref().child('doctors');
}
