import  {Component, Fragment} from 'react';
import classes from './archivestyles.module.css';
import _eStyle from '../../clinicalSide/encounters/_encounters.module.css'
import TimeAgo from 'timeago-react';
import _Header from '../../../components/UI/Header/Header'
import {FaBars, FaCaretLeft, FaCaretRight} from 'react-icons/fa';
import isMobileView from '../../../utils/isMobileView';
import hocAux from '../../../hoc/hocAux';
import classNames from 'classnames';
import moment from 'moment'


const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const LeftMessage = (props) => {
    var status = props.isRead ? 'Read' : 'Delivered';
    var name = props.patientName !== undefined ? capitalize(props.patientName) : 'Patient';
    return (
        isMobileView() ?
            <div className={_eStyle.message_li}>
                <FaCaretLeft size={30} className={_eStyle.left_message_caret}></FaCaretLeft>

                <div className={classNames(_eStyle.message_wrapper, _eStyle.sb11)}>
                    <span className={_eStyle.author}>{props.patientName}</span>
                    <div className={_eStyle.message_container}>
                        <div className={_eStyle.message_text}>
                            {props.type === "text" &&
                            <span>{props.content}</span>
                            }
                            {(props.type === "image" || props.type === "video" || props.type === "application") &&
                            <a href={props.text} target="_blank" rel="noopener noreferrer" alt="">
                                <p>{props.content}</p>
                            </a>
                            }
                        </div>
                    </div>
                    <div className={_eStyle.message_time_left}>
                        <span className={_eStyle.timestamp_span_left}>
                            {/* {moment(props.timestamp).format('HH:mm')} */}
                        </span>
                        <span className={_eStyle.read_span_left}>{moment(props.timestamp).format('HH:mm a')}</span>
                    </div>
                </div>
            </div>

            : <div className={classes.msg_body}>
                <div className={classes.msg_name}>
                    <div className={classes.msg_name_text}>{name}</div>
                </div>
                <div className={classes.msg_left_body}>
                    <div className={classes.msg_left_img}>{name.charAt(0)}</div>
                    <div className={classes.msg_text_body}>
                        <div className={classes.msg_text_container}>
                            <div className={classes.msg_bubble_view}>
                                <div className={classes.msg_bubble_container}>
                                    <div>
                                        <div className={classes.msg_bubble_content}>
                                            <div className={classes.msg_bubble_content1}>
                                                <div className={classes.msg_bubble_content2}>
                                                    <div className={classes.msg_bubble_content_row}>
                                                        <div className={classes.msg_bubble_content_view}>
                                                            <div className={classes.msg_bubble_container_content}>
                                                                <div>
                                                                    {props.content}
                                                                </div>
                                                                <div className={classes.message_time_left}>
                                                                    <span className={classes.timestamp_span_left}><TimeAgo
                                                                        datetime={props.timestamp}/></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

const RightMessage = (props) => {
    var status = 'Read'
    // props.isRead ? 'Read' : 'Delivered';
    return (
        isMobileView() ? <div className={_eStyle.message_li_right}>
            <FaCaretRight size={30} className={_eStyle.right_message_caret}/>
            <div className={_eStyle.message_wrapper_right}>
                {/* <span className={_eStyle.author_right}>{props.doctorName}</span> */}
                <div className={_eStyle.message_container}>
                    <div className={_eStyle.message_text_right}>
                        {props.type === "text" &&
                        <span>{props.content}</span>
                        }
                        {(props.type === "image" || props.type === "video" || props.type === "application") &&
                        <a href={props.content} target="_blank" rel="noopener noreferrer" alt="">
                            <p>{props.content}</p>
                        </a>
                        }
                    </div>
                </div>
                <div className={_eStyle.message_time_right}>
                    <span className={_eStyle.timestamp_span_right}>
                        {/* <TimeAgo datetime={props.timestamp} /> */}
                        {moment(props.timestamp).format('HH:mm a')}
                    </span>
                    <span className={_eStyle.read_span_right}>{status}</span>
                </div>

            </div>
        </div> : <div className={classes.msg_body}>
            <div className={classes.msg_right_name}>
                <div className={classes.msg_name_text}>{props.doctorName}</div>
            </div>
            <div className={classes.msg_right_body}>
                {props.doctorImg !== undefined &&
                <img className={classes.msg_right_img} alt="" src={props.doctorImg}/>
                }
                {props.doctorImg === undefined &&
                <div className={classes.msg_right_img}>{(props.doctorName).charAt(0)}</div>
                }
                <div className={classes.msg_right_container}>
                    <div className={classes.msg_right_content}>
                        <div className={classes.msg_right_content1}>
                            <div className={classes.msg_right_content2}>
                                <div>
                                    <div className={classes.msg_right_bubble_view}>
                                        <div className={classes.msg_right_bubble_container}>
                                            <div className={classes.msg_right_bubble_container_content}>
                                                <div className={classes.msg_bubble_content_row}>
                                                    <div className={classes.msg_bubble_right}>
                                                        <div className={classes.msg_bubble_container_content}>
                                                            <div>
                                                                {props.content}
                                                            </div>
                                                            <div className={classes.message_time_right}>
                                                                <span className={classes.timestamp_span_right}><TimeAgo
                                                                    datetime={props.timestamp}/></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const TagList = (props) => {
    return (
        <span>
            <span className={classes.tag_name}>{props.name}</span>
        </span>
    )
}

export default class Body extends Component {
    render() {
        const messageList = this.props.archives_messages.map(message => {
            if (message.receiveMessage.direction === 'left') {
                return <LeftMessage
                    // key={message.receiveMessage.timestamp}
                    type={message.receiveMessage.type}
                    isRead={message.receiveMessage.isRead}
                    direction={message.receiveMessage.direction}
                    text={message.receiveMessage.content}
                    // timestamp={message.receiveMessage.timestamp}
                    doctorName={message.receiveMessage.doctorName}
                    doctorImage={message.receiveMessage.doctorImage}

                    key={message.receiveMessage.uid}
                    timestamp={message.receiveMessage.timestamp}
                    content={message.receiveMessage.content}
                    patientName={this.props.patientName}
                />
            } else {
                return <RightMessage
                    // key={message.receiveMessage.timestamp}
                    type={message.receiveMessage.type}
                    isRead={message.receiveMessage.isRead}
                    direction={message.receiveMessage.direction}
                    text={message.receiveMessage.content}
                    // timestamp={message.receiveMessage.timestamp}
                    doctorName={message.receiveMessage.doctorName}
                    doctorImage={message.receiveMessage.doctorImage}
                    // patientName={props.patientName}
                    key={message.receiveMessage.uid}
                    timestamp={message.receiveMessage.timestamp}
                    content={message.receiveMessage.content}
                    doctorName={this.props.doctorName}
                    doctorImg={this.props.doctorImg}
                />
            }
        });

        const taglist = this.props.tags.map(tag => {
            return <TagList key={tag.id} name={tag.name}/>
        });

        var inputDate = new Date(Boolean(this.props.archive_timestamp) ? this.props.archive_timestamp : null);

        var todaysDate = new Date();
        var date = "";
        if (inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
            date = "Today";
        } else {
            date = inputDate.toDateString();
        }

        var doctor_name = this.props.headerText.doctor !== undefined ? ` and ${this.props.headerText.doctor}` : '';
        return (
            isMobileView() ? <aux style={{heigth: '100%',}}>
                    <_Header title="Chat" back={{title: ' ', onClick: () => this.props.chatSelected(false)}} customView={
                        <div style={{
                            flex: 1,
                            flexDirection: 'row',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginRight: '10px'
                        }}>
                            <div style={{
                                flexDirection: 'column',
                                display: 'flex',
                                fontSize: '14px',
                                fontFamily: 'HarmoniaSansStd-Bold',
                                color: "#884a9d"
                            }}>
                                <div style={{
                                    fontFamily: 'HarmoniaSansStd-Bold',
                                    color: "#884a9d"
                                }}> {this.props.headerText.name}</div>
                                <div style={{
                                    fontFamily: 'HarmoniaSansStd-Regular',
                                    color: "#000"
                                }}>{moment().diff((this.props.headerText.DOB), 'years', false) + ' years'}, {this.props.headerText.city}, {this.props.headerText.country}</div>
                            </div>
                            <div style={{flexDirection: 'row', display: 'flex', fontSize: '14px', verticalAlign: 'center'}}>
                                <FaBars color="#884a9d" size={22} style={{marginRight: '10px',}}
                                        onClick={() => {
                                            this.props.toggleSidebar(true)
                                            // setSidebarVisibility(!showSidebarModal);
                                            // alert('he')
                                        }}></FaBars>
                            </div>
                        </div>}/>
                    <div className={_eStyle.msg_body} style={{top: '65px', height: 'calc(100%-65px)'}}>
                        <div className={_eStyle.list}>
                            <div className={_eStyle.chatlist}>
                                <div className={_eStyle.chatview}>
                                    <div className={_eStyle.chatwindow} style={{'overflow-y': 'scroll'}}>
                                        {messageList}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </aux> :
                <div className={classes.main_body}>
                    <div className={classes.block}>
                        <div className={classes.main_block}>
                            <div className={classes.header}>

                                <div className={classes.body_header}>
                                    <Fragment>
                                        <div className={classes.msg_header_default}>
                                            <span
                                                className={classes.header_text_top}><strong>{this.props.headerText.name}</strong>{doctor_name}</span>
                                        </div>
                                    </Fragment>
                                </div>
                            </div>
                            <div className={classes.main_body_view}>
                                <div className={classes.main_body_container}>
                                    {!this.props.load_messages &&
                                    <Fragment>
                                        <div className={classes.main_body_content}>
                                            {Boolean(this.props.archive_timestamp) &&
                                            <div className={classes.main_body_content_header}>
                                                <div className={classes.header_line}></div>
                                                <div className={classes.header_text}>{date}</div>
                                                <div className={classes.header_line}></div>
                                            </div>
                                            }
                                            {messageList}
                                        </div>
                                        <div className={classes.tag_list_view}>
                                            <div className={classes.tag_view}>
                                                {taglist}
                                            </div>
                                        </div>
                                    </Fragment>
                                    }
                                    {this.props.load_messages &&
                                    <div className={classes.no_loading}>
                                        <div className={classes.no_loading_view}>
                                            <div className={classes.loading_loader}/>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}
