import  {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import classes from '../profileModal.module.css';
import Aux from '../../../../hoc/hocAux';
import {logoutClinicMember} from '../../../../actions/ClinicStaffAction';
import {changeAcceptStatus} from '../../../../actions/ConversationAction';

import Spinner from 'react-bootstrap/Spinner';
import isMobileView from '../../../../utils/isMobileView';

const image = require('../../../../assets/download.png');

class ProfileModal extends Component {

    constructor() {
        super();
        this.state = {checked: false};
        this.handleChange = this.handleChange.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.show !== this.props.show ||
            nextProps.children !== this.props.children ||
            nextProps.acceptingChat !== this.props.acceptingChat ||
            nextProps.doctor_loading !== this.props.doctor_loading
    }

    onLogoutClick = () => {
        window.localStorage.setItem('logged_in', false);
        const {staffData, clinicId} = this.props;
        this.props.logoutClinicMember(clinicId, staffData.uid);
    }

    handleChange() {
        const {acceptingChat, clinicId} = this.props;
        if (acceptingChat === false) {
            this.props.changeAcceptStatus(clinicId, true, null, () => {
            });
        } else {
            this.props.changeAcceptStatus(clinicId, false, null, () => {
            });
        }
    }


    render() {
        const {acceptingChat} = this.props;
        return (
            <Aux>
                {!isMobileView() &&
                <div className={(this.props.profile_data.role !== 'Admin') ? classes.ModalDoctor : classes.Modal}
                     style={{
                         transform: this.props.show ? 'translateX(0)' : 'translateX(-50vh)',
                         opacity: this.props.show ? '1' : '0'
                     }}>
                    <div className={classes.main_view}>
                        <div className={classes.icon_image_view}>
                            <img
                                className={classes.icon_image}
                                src={this.props.profile_image !== undefined && this.props.profile_image !== null ? this.props.profile_image : image}
                                alt=""
                            />
                            <div className={classes.profile_detail_view}>
                                <h3>{this.props.full_name !== undefined && this.props.full_name !== null ? this.props.full_name : 'Admin'}</h3>
                                <span>{this.props.userType}</span>
                            </div>
                        </div>
                        {/*<label className={classes.label_style}>
                            <span className={classes.span_style} >Accept Chats</span>
                            <Switch
                                onChange={this.handleChange}
                                checked={acceptingChat}
                                onColor="#884a9d"
                                onHandleColor="#884a9d"
                                handleDiameter={30}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                            />
                        </label>*/}
                        <div className={classes.border_bottom}/>
                        <div className={classes.logout_view}>
                            {!this.props.doctor_loading &&
                            <button className={classes.logout_button} onClick={() => this.onLogoutClick()}>
                                <div className={classes.btn_text}>Logout</div>
                            </button>
                            }
                            {this.props.doctor_loading &&
                            <div className={classes.spinner_view}>
                                <Spinner animation="border" size="sm" variant="light"/>
                            </div>
                            }
                        </div>
                    </div>
                </div>}
            </Aux>
        )
    }
}

const mapStateToProps = ({profileReducer, acceptingReducer, loginAuth, clinicProfileSettingReducer}) => {
    const {profile_data, uid} = profileReducer;
    const {acceptingChat} = acceptingReducer;
    const {doctor_loading} = loginAuth;

    const {
        clinicData, staffData, clinicId, clinic_name, full_name, address, province, city, country, phone, postal_code,
        fax, email_address, license_no, profile_image, signature, clinic_email_address, physician_user,
        loading, message, userType
    } = clinicProfileSettingReducer;

    return {
        clinicData,
        staffData,
        clinicId,
        clinicId,
        clinic_name,
        full_name,
        address,
        province,
        city,
        country,
        phone,
        postal_code,
        fax,
        email_address,
        license_no,
        profile_image,
        signature,
        clinic_email_address,
        physician_user,
        loading,
        message,
        userType,
        doctor_loading,
        profile_data,
        uid,
        acceptingChat
    };
}

export default compose(withRouter, connect(mapStateToProps, {changeAcceptStatus, logoutClinicMember}))(ProfileModal);

