import  {Component} from 'react';
import {PDFViewer} from '@react-pdf/renderer';
import PatientProfile from './createPatientProfile'

//import { connect } from 'react-redux';

class PdfBuilder extends Component {
    constructor(props) {
        super(props);
        /* this.handleSubmit = this.handleSubmit.bind(this);
        this.setName = this.setName.bind(this);
        this.setEmail = this.setEmail.bind(this);
        this.setBirthDay = this.setBirthDay.bind(this); */

    }

    UNSAFE_componentWillMount = () => {
        //this.props.fetchProfile();
    }

    render() {
        return (
            <PDFViewer style={{height: "100%", width: "100%", position: 'absolute'}}>
                <PatientProfile

                    patientName={this.props.patientName}
                    dob={this.props.dob}
                    email={this.props.email}
                    patient_profile={this.props.patient_profile}

                />
            </PDFViewer>
        )
    }

}

/* const mapStateToProps = ({ clinicalcannedResponseReducer, billingcodesReducer, outPatientFormReducer,profileReducer }) => {
    const { profile_data, uid } = profileReducer;
    const { loading, message, error } = outPatientFormReducer;
    const { canned_responses } = clinicalcannedResponseReducer;
    const { billing_codes } = billingcodesReducer;
    return { loading, message, error, canned_responses, billing_codes, profile_data, uid };
}

export default connect(mapStateToProps, {fetchProfile})(PdfBuilder); */
export default PdfBuilder;
