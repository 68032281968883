import  {useCallback, useEffect, useState} from 'react';
import classes from '../ChatBuilder.module.css';
import TimeAgo from 'timeago-react';
import Spinner from "../../../components/Spinner";
import BarLoader from "../../../components/Spinner/BarLoader";
import _ from "lodash";
import OpenChatList from "./OpenChatList";
import {connect} from "react-redux";
import MyChatList from "./MyChatList";
import QueuedChatList from "./QueuedChatList";

/*const OpenChatList = (props) => {
    let selected = props.conversationId === props.conversationId ? true : false;
    let initial = Boolean(props.name) ? props.name[0].toUpperCase() : 'P';
    return (
        <div key={props.conversationId} className={selected ? classes.selected_list_view : classes.list_view}
             onClick={() => props.onChatItemClick(props.paidChat, props.conversationId, props.patientID, props.doctorID,
                 props.issue, props.category, props.timestamp)}>
            <div className={classes.list_img_style}>
                <div className={classes.selected_list_img}>
                    <span className={classes.selected_user_img}>{initial}</span>
                </div>
            </div>
            <div className={classes.list_item}>
                <div
                    className={selected ? classes.selected_item_name : classes.patient_name}>{props.name === undefined ? <BarLoader  color={"black"} size={10} isLoading={true}/>: props.name !== '' ? props.name : 'Patient'}</div>
                <div className={selected ? classes.selected_item_name : classes.item_name}>{props.doctorName === undefined? <BarLoader  color={"black"} size={10} isLoading={true}/> : props.doctorName}</div>
                <div className={selected ? classes.selected_item_name : classes.item_name}>Active
                    Chats: {props.onlineChats === undefined? <Spinner color={"black"} size={"10px"} isLoading={true}/>: props.onlineChats}/{props.totalChatLimit === undefined? <Spinner color={"black"} size={10} isLoading={true}/>: props.totalChatLimit}</div>
                {props.transferedBy !== false &&
                <div className={selected ? classes.selected_item_name : classes.item_name}>Transferred
                    By: {props.transferedBy}</div>
                }
                {props.assignedAs !== false &&
                <div
                    className={selected ? classes.selected_item_name : classes.item_name}>Status: {props.assignedAs}</div>
                }
            </div>
            {!selected &&
            <div className={classes.list_item_second}>
                <div>
                    {/!*<div className={classes.time_style}>Last message:</div>
                        <div className={classes.time_style}><TimeAgo datetime={props.lastMessageTimeStamp} /></div>*!/}
                </div>
            </div>
            }
        </div>
    )
}*/

/*
const MyChatList = (props) => {
    let selected = props.conversationId === props.conversationId ? true : false;
    let initial = Boolean(props.name) ? props.name[0].toUpperCase() : 'P';
    return (
        <div key={props.conversationId} className={selected ? classes.selected_list_view : classes.list_view}
             onClick={() => {
                 if (!selected)
                     props.onChatItemClick(props.paidChat, props.conversationId, props.patientID, props.doctorID,
                     props.issue, props.category, props.timestamp)
             }}>
            <div className={classes.list_img_style}>
                <div className={classes.selected_list_img}>
                    <span className={classes.selected_user_img}>{initial}</span>
                </div>
            </div>
            <div className={classes.list_item}>
                <div
                    className={selected ? classes.selected_item_name : classes.patient_name}>{props.name === undefined?  <BarLoader  color={"black"} size={10} isLoading={true}/>: props.name || 'Patient'}</div>
                <div className={selected ? classes.selected_item_name : classes.item_name}>{props.doctorName === undefined? <BarLoader  color={"black"} size={10} isLoading={true}/> : props.doctorName}</div>
                <div className={selected ? classes.selected_item_name : classes.item_name}>Active
                    Chats: {props.onlineChats === undefined? <Spinner color={"black"} size={"10px"} isLoading={true}/>: props.onlineChats}/{props.totalChatLimit === undefined? <Spinner color={"black"} size={10} isLoading={true}/>: props.totalChatLimit}</div>
                {props.transferedBy !== false &&
                <div className={selected ? classes.selected_item_name : classes.item_name}>Transferred
                    By: {props.transferedBy}</div>
                }
                {props.assignedAs !== false &&
                <div
                    className={selected ? classes.selected_item_name : classes.item_name}>Status: {props.assignedAs}</div>
                }
            </div>
            {!selected &&
            <div className={classes.list_item_second}>
                {Boolean(props.unReadCount) &&
                <div className={classes.list_unread_style}>
                    <span className={classes.unread_msg_style}>{props.unReadCount}</span>
                </div>
                }
                {/!*<div>
                        <div className={classes.time_style}>Last message:</div>
                        <div className={classes.time_style}><TimeAgo datetime={props.lastMessageTimeStamp} /></div>
                    </div>*!/}
            </div>
            }
        </div>
    )
}
*/

const EmptyChat = (props) => {
    return (<div className={classes.empty_chat_list}>{props.msg}</div>)
}

/*
const QueuedChatList = (props) => {
    let initial = Boolean(props.name) ? props.name[0].toUpperCase() : 'P';
    return (
        <div key={props.conversationId} className={classes.queue_list_view}
             onClick={() => props.onQueuedChatItemClick(props.paidChat, props.conversationId, props.patientID,
                 props.doctorID, props.issue, props.category, props.timestamp)}>
            <div className={classes.list_img_style}>
                <div className={classes.selected_list_img}>
                    <span className={classes.selected_user_img}>{initial}</span>
                </div>
            </div>
            <div className={classes.list_item}>
                <div
                    className={classes.patient_name}>{props.name === undefined?<BarLoader  color={"black"} size={10} isLoading={true}/> :props.name !== '' ? props.name : 'Patient'}</div>
                <div
                    className={classes.item_name}>Location: {Boolean(props.patientCity) ? props.patientCity : "NA"}, {Boolean(props.patientCountry) ? props.patientCountry : "NA"}</div>
            </div>
            <div className={classes.list_item_second}>
                <div>
                    <div className={classes.time_style}>In queue:</div>
                    <div className={classes.time_style}><TimeAgo datetime={props.timestamp}/></div>
                </div>
            </div>
        </div>
    )
}
*/
const FollowUpList = (props) => {
    let initial = Boolean(props.all_followUp.patient_name) ? props.all_followUp.patient_name[0].toUpperCase() : 'P';

    let date_now = new Date()
    let date_future = new Date(props.all_followUp.scheduled_date)
    let d = Math.abs(date_future - date_now) / 1000;                           // delta
    let r = {};                                                                // result
    let s = {                                                                  // structure
        year: 31536000,
        month: 2592000,
        week: 604800, // uncomment row to ignore
        day: 86400,   // feel free to add your own row
        hour: 3600,
        minute: 60,
        second: 1
    };

    Object.keys(s).forEach(function (key) {
        r[key] = Math.floor(d / s[key]);
        d -= r[key] * s[key];
    });

    // for example: {year:0,month:0,week:1,day:2,hour:34,minute:56,second:7}
    if (r.day != 0 && r.day > 0) {
        if (r.hour >= 12) {
            r.day = r.day + 1
        }
        var detailString = `${r.day > 1 ? `${r.day} days` : `${r.day} day`}`
    } else if (r.hour != 0 && r.hour > 0) {
        detailString = `${r.hour > 1 ? `${r.hour} hours` : `${r.hour} hour`}`

    } else if (r.hour == 0 && r.day == 0 && r.minute > 0) {
        detailString = `${r.minute > 1 ? `${r.minute} minutes` : `${r.minute} minute`}`

    } else if (r.minute == 0 && r.hour == 0 && r.day == 0 && r.second > 0) {
        detailString = `${r.second > 1 ? `${r.second} seconds` : `${r.second} second`}`

    }
    return (
        <div className={classes.queue_list_view} onClick={() => {
            props.searchFirebaseId(props.all_followUp.user_id, props.all_followUp.id, props.index)
        }}>
            <div className={classes.list_img_style}>
                <div className={classes.selected_list_img}>
                    <span className={classes.selected_user_img}>{initial}</span>
                </div>
            </div>
            <div className={classes.list_item}>
                <div className={classes.patient_name}>{props.all_followUp.patient_name}</div>
                <div
                    className={classes.item_name}>Issue: {props.all_followUp.diagnosis != null && props.all_followUp.diagnosis != undefined && props.all_followUp.diagnosis != '' ? props.all_followUp.diagnosis : props.all_followUp.patient_issue}</div>
                <div
                    className={classes.item_name}>{props.all_followUp.doctor_name != null && props.all_followUp.doctor_name != undefined && props.all_followUp.doctor_name != '' ? "Scheduled for " + props.all_followUp.doctor_name : ''}</div>

            </div>

            <div className={classes.list_item_second}>
                <div>
                    <div
                        className={classes.time_style}>{props.all_followUp.notification_sent == 0 ? props.all_time >= 0 ? "Schedueled in:" : "Over due by:" : "Notification Sent"}</div>
                    <div
                        className={classes.time_style}>{props.all_followUp.notification_sent == 0 ? detailString : ''}</div>
                </div>
            </div>

        </div>
    )
}

const checkAssignedMessage = (message) => {
    switch (message) {
        case 'close_chat':
            return 'Assigned on close chat';
        case 'picked_from_queue_chat':
            return 'Picked from queue';
        case 'transfered_chat':
            return 'Transferred chat';
        default:
            break;
    }
}

const ConversationList = (props) => {

    const [showPPQueue, changePPQueue] = useState(true);
    const [showHCQueue, changeHCQueue] = useState(true);

    const [showOtherChats, changeOtherChats] = useState(true);
    const [showMyChats, changeMyChats] = useState(true);
    const [showFollowUp, changeFollowUp] = useState(true);
    const [myconversation, setMyConversation] = useState([]);
    const [openconversation, setOpenConversation] = useState([]);
    const [queuedHCConversation, setQueueHCConversation] = useState([]);
    const [queuedHCConversationSubset, setQueueHCConversationSubset] = useState([]);
    const [queueHCConversationCount, setQueueHCConversationCount] = useState(0);


    const [queuedPPConversation, setQueuePPConversation] = useState([]);

    const [clickedConversation, setClickedConversation] = useState('');
    const [allFollowUp, setAllFollowUp] = useState(props.all_followUp || [])

    const showHidePPQueue = useCallback(() => {
        changePPQueue(!showPPQueue);
    }, [showPPQueue]);
    const showHideHCQueue = useCallback(() => {
        changeHCQueue(!showHCQueue);
    }, [showHCQueue]);
    const showHideFollowUp = useCallback(() => {
        changeFollowUp(!showFollowUp);
    }, [showFollowUp]);
    const showHideOtherChats = useCallback(() => {
        changeOtherChats(!showOtherChats);
    }, [showOtherChats]);
    const showHideMyChats = useCallback(() => {
        changeMyChats(!showMyChats);
    }, [showMyChats]);

    useEffect(()=>{
        setAllFollowUp(props.all_followUp)
    },[props.all_followUp])

    useEffect(()=>{
        if(props.conversations.length > 0){
            let newMyConversations = [];
            let newOpenConversations = [];
            let newQueueHCConversations = [];
            let newQueuePPConversations = [];
            let result = props.conversations.length > 0? props.conversations.reduce(function (obj, v) {
                // increment or set the property
                // `(obj[v.status] || 0)` returns the property value if defined
                // or 0 ( since `undefined` is a falsy value
                //if(obj[v.status] && !obj[v.queued]){
                    obj[v.doctorID] = (obj[v.doctorID] || 0) + 1;
                //}
                // return the updated object
                return obj;
                // set the initial value as an object
            }, {}): {};
            props.conversations.forEach(chat => {
                //var onlineChats = resultCount.length !== 0 && resultCount.find(c => c.doctorID === chat.doctorID);
                let onlinePaidChats = result[chat.doctorID];
                //let doctor = props.doctorsNameAndImages?.length > 0?props.doctorsNameAndImages.find(doctor => doctor.uid === chat.doctorID) : null;
                /*let transferedChat = chat.transferedBy !== null && props.doctorsNameAndImages?.length !== 0 && doctor?.uid === chat.transferedBy? doctor: false;
                let assignedAs = chat.assignedOn !== null && checkAssignedMessage(chat.assignedOn);
                */
                if(!chat.chatInQueue && chat.ownerChat){
                    newMyConversations.push({...chat,onlinePaidChats})
                }
                else if(!chat.chatInQueue && !chat.ownerChat){
                    newOpenConversations.push({...chat,onlinePaidChats})
                }else if(chat.chatInQueue && chat.isEMRChat && ( props.doctorDetail.doctorChatType === "health-card" || props.doctorDetail.role === "Admin") ){
                    newQueueHCConversations.push(chat)
                }else if(chat.chatInQueue && !chat.isEMRChat && ( props.doctorDetail.doctorChatType === "private-pay" || props.doctorDetail.role === "Admin")){
                    newQueuePPConversations.push(chat)
                }
            })
            setMyConversation(newMyConversations);
            setOpenConversation(newOpenConversations);
            setQueueHCConversationCount(newQueueHCConversations?.length);
            setQueueHCConversation(newQueueHCConversations);
            let subset = newQueueHCConversations?.length > 50? newQueueHCConversations.slice(0,50):newQueueHCConversations;
            setQueueHCConversationSubset(subset);
            setQueuePPConversation(newQueuePPConversations);
        }else{
            setMyConversation([]);
            setOpenConversation([]);
            setQueueHCConversation([]);
            setQueueHCConversationSubset([]);
            setQueueHCConversationCount(0);
            setQueuePPConversation([]);
        }
    },[props.conversations])

    /*const myconversation = props.conversations.map(mychat => {
        //var onlineChats = props.chatCounts.length !== 0 && props.chatCounts.find(chat => chat.doctorID === mychat.doctorID);

        return ( &&

        )
    });*/

    /*const openconversation = props.conversations.map(openchat => {
        //var onlineChats = props.chatCounts.length !== 0 && props.chatCounts.find(chat => chat.doctorID === openchat.doctorID);
        let doctor = props.doctorsNameAndImages?.length > 0?props.doctorsNameAndImages.find(doctor => doctor.uid === openchat.doctorID) : null;
        var transferedChat = openchat.transferedBy !== null && props.doctorsNameAndImages?.length !== 0 && doctor?.uid === openchat.transferedBy? doctor:false;
        var assignedAs = openchat.assignedOn !== null && checkAssignedMessage(openchat.assignedOn);

        return ( &&

        )
    });*/

   /* const queuedconversation = props.conversations.map(queuechat => {

        return (queuechat.chatInQueue &&

        )
    });*/

    const onQueuedChatItemClick = (paidChat, conversationId, patientID, doctorID, issue, category, start_time) => {
        if (clickedConversation === conversationId) {
            return
        }
        setClickedConversation(conversationId)
        setTimeout(() => setClickedConversation(null), 3000)
        props.removedFromQueueAndshowMessages(paidChat, conversationId, patientID, doctorID,
            issue, category, start_time)
    }

    /*const followUp = Boolean(props.all_followUp) ? props.all_followUp.length > 0 ? props.all_followUp.map((item, index) => {
        return (
            <FollowUpList
                allFollowUp={props.all_followUp[index]}
                allTime={props.all_time[index]}
                index={index}
                key={index}
                searchFirebaseId={(id, followUp_id, index) => props.searchFirebaseId(id, followUp_id, index)}
            ></FollowUpList>
        )
    }).filter(x => Boolean(x)) : null : null;*/

   /* var myconversation = myconversation.filter(function (el) {
        return el != null && el !== "" && el !== false;
    });*/

    /*var openconversation = openconversation.filter(function (el) {
        return el != null && el !== "" && el !== false;
    });*/

   /* var queuedconversation = queuedconversation.filter(function (el) {
        return el != null && el !== "" && el !== false;
    });*/

    //let nochatsavailavble = (queuedconversation.length === 0 && openconversation.length === 0) ?

    return (
        <div className={classes.chatsList}>
            <div className={classes.block}>
                <div className={classes.list_body}>
                    <div className={classes.convo_list}>
                        {myconversation.length !== 0 &&
                        <div className={classes.openchatlist} onClick={showHideMyChats}>
                            <div className={classes.openchatlistSection}>
                                <div
                                    className={showMyChats ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                                    <svg fill="#4384F5" width="12px" height="8px" viewBox="0 0 12 8">
                                        <path fill="inherit" fillRule="evenodd"
                                              d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                    </svg>
                                </div>
                                <div className={classes.subListTitle}> Engaged Patients</div>
                            </div>

                            <div className={classes.subListCount}>
                                {myconversation.length}
                            </div>
                        </div>
                        }
                        {myconversation.length !== 0 &&
                        <div className={showMyChats ? classes.showConvo : classes.hideConvo}>
                            {myconversation.map(chat=>(<MyChatList
                                key={chat.conversationId}
                                /*selectedConversation={props.conversationId}
                                patientID={chat.patientID}
                                chatHistoryItemClick={props.chatHistoryItemClick}
                                doctorID={chat.doctorID}
                                doctorName={chat?.doctor?.doctorName}
                                onlineChats={onlinePaidChats}
                                totalChatLimit={chat?.doctor?.totalChatLimit}
                                conversationId={chat.conversationId}
                                unReadCount={chat.unreadCount}
                                lastMessageTimeStamp={chat.lastMessageTimeStamp}

                                name={chat?.patient?.patientName}
                                ownerChat={chat.ownerChat}
                                issue={chat.issue}
                                category={chat.category}
                                timestamp={chat.start_timestamp}
                                paidChat={chat.paidChat}
                                transferedBy={chat?.transferedChat !== undefined && chat?.transferedChat !== false ? chat?.transferedChat.doctorName : false}
                                assignedAs={chat?.assignedAs !== undefined ? chat?.assignedAs : false}*/
                                selectedConversation={props.conversationId}
                                chat={chat}
                                chatHistoryItemClick={props.chatHistoryItemClick}
                                onlineChats={chat.onlinePaidChats}
                                onChatItemClick={props.showMessages}
                            />))}
                        </div>
                        }
                        {openconversation.length !== 0 &&
                        <div className={classes.openchatlist} onClick={showHideOtherChats}>
                            <div className={classes.openchatlistSection}>
                                <div
                                    className={showOtherChats ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                                    <svg fill="#4384F5" width="12px" height="8px" viewBox="0 0 12 8">
                                        <path fill="inherit" fillRule="evenodd"
                                              d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                    </svg>
                                </div>
                                <div className={classes.subListTitle}> Other Active Chats</div>
                            </div>

                            <div className={classes.subListCount}>
                                {openconversation.length}
                            </div>
                        </div>
                        }
                        {openconversation.length !== 0 &&
                        <div className={showOtherChats ? classes.showConvo : classes.hideConvo}>
                            {openconversation.map(chat=>(<OpenChatList
                                key={chat.conversationId}
                                /*selectedConversation={props.conversationId}
                                patientID={chat.patientID}
                                doctorID={chat.doctorID}
                                doctorName={doctor?.doctorName}
                                onlineChats={onlinePaidChats}
                                totalChatLimit={doctor?.totalChatLimit}
                                conversationId={chat.conversationId}
                                key={chat.conversationId}
                                name={patient?.patientName}
                                ownerChat={chat.ownerChat}
                                issue={chat.issue}
                                category={chat.category}
                                timestamp={chat.start_timestamp}
                                unReadCount={chat.unreadCount}
                                lastMessageTimeStamp={chat.lastMessageTimeStamp}
                                paidChat={chat.paidChat}
                                transferedBy={transferedChat !== undefined && transferedChat !== false ? transferedChat.doctorName : false}
                                assignedAs={assignedAs !== undefined ? assignedAs : false}*/
                                selectedConversation={props.conversationId}
                                chat={chat}
                                /*patientID={chat.patientID}
                                doctorID={chat.doctorID}
                                conversationId={chat.conversationId}
                                unReadCount={chat.unreadCount}
                                ownerChat={chat.ownerChat}
                                issue={chat.issue}
                                category={chat.category}
                                timestamp={chat.start_timestamp}
                                paidChat={chat.paidChat}
                                lastMessageTimeStamp={chat.lastMessageTimeStamp}*/
                                chatHistoryItemClick={props.chatHistoryItemClick}
                                onlineChats={chat?.onlinePaidChats}
                                //name={patient?.patientName}
                                //transferedBy={transferedChat !== undefined && transferedChat !== false ? transferedChat.doctorName : false}

                                onChatItemClick={props.showMessages}
                            />))}
                        </div>
                        }
                        {queuedHCConversation.length !== 0 &&
                        <div className={classes.openchatlist} onClick={showHideHCQueue}>
                            <div className={classes.openchatlistSection}>
                                <div className={showHCQueue ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                                    <svg fill="#4384F5" width="12px" height="8px" viewBox="0 0 12 8">
                                        <path fill="inherit" fillRule="evenodd"
                                              d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                    </svg>
                                </div>
                                <div className={classes.subListTitle}>Health Card Patients Queued</div>
                            </div>
                            <div className={classes.subListCount}>
                                {queueHCConversationCount}
                            </div>
                        </div>
                        }
                        {queuedHCConversation.length !== 0 &&
                        <div className={showHCQueue ? classes.showConvo : classes.hideConvo}>
                            {queuedHCConversationSubset.map(chat=>(
                                <QueuedChatList
                                    key={chat.conversationId}
                                    /*selectedConversation={props.conversationId}
                                    patientID={chat.patientID}
                                    doctorID={chat.doctorID}
                                    conversationId={chat.conversationId}
                                    key={chat.conversationId}
                                    name={patient?.patientName}
                                    ownerChat={chat.ownerChat}
                                    issue={chat.issue}
                                    category={chat.category}
                                    timestamp={chat.start_timestamp}
                                    unReadCount={chat.unreadCount}
                                    lastMessageTimeStamp={chat.lastMessageTimeStamp}
                                    patientCountry={patient?.patientCountry}
                                    patientCity={patient?.patientCity}
                                    paidChat={chat.paidChat}*/
                                    selectedConversation={props.conversationId}
                                    chat={chat}
                                    chatHistoryItemClick={props.chatHistoryItemClick}
                                    onQueuedChatItemClick={onQueuedChatItemClick}
                                />
                            ))}
                        </div>
                        }
                        {queuedPPConversation.length !== 0 &&
                        <div className={classes.openchatlist} onClick={showHidePPQueue}>
                            <div className={classes.openchatlistSection}>
                                <div className={showPPQueue ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                                    <svg fill="#4384F5" width="12px" height="8px" viewBox="0 0 12 8">
                                        <path fill="inherit" fillRule="evenodd"
                                              d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                    </svg>
                                </div>
                                <div className={classes.subListTitle}>Private Pay Patients Queued</div>
                            </div>
                            <div className={classes.subListCount}>
                                {queuedPPConversation.length}
                            </div>
                        </div>
                        }
                        {queuedPPConversation.length !== 0 &&
                        <div className={showPPQueue ? classes.showConvo : classes.hideConvo}>
                            {queuedPPConversation.map(chat=>(
                                <QueuedChatList
                                    key={chat.conversationId}
                                    /*selectedConversation={props.conversationId}
                                    patientID={chat.patientID}
                                    doctorID={chat.doctorID}
                                    conversationId={chat.conversationId}
                                    key={chat.conversationId}
                                    name={patient?.patientName}
                                    ownerChat={chat.ownerChat}
                                    issue={chat.issue}
                                    category={chat.category}
                                    timestamp={chat.start_timestamp}
                                    unReadCount={chat.unreadCount}
                                    lastMessageTimeStamp={chat.lastMessageTimeStamp}
                                    patientCountry={patient?.patientCountry}
                                    patientCity={patient?.patientCity}
                                    paidChat={chat.paidChat}*/
                                    selectedConversation={props.conversationId}
                                    chat={chat}
                                    chatHistoryItemClick={props.chatHistoryItemClick}
                                    onQueuedChatItemClick={onQueuedChatItemClick}
                                />
                            ))}
                        </div>
                        }
                        {/*{Boolean(props.all_followUp) && props.all_followUp.length !== 0 &&
                        <div className={classes.openchatlist} onClick={showHideFollowUp}>
                            <div className={classes.openchatlistSection}>
                                <div
                                    className={showFollowUp ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                                    <svg fill="#4384F5" width="12px" height="8px" viewBox="0 0 12 8">
                                        <path fill="inherit" fillRule="evenodd"
                                              d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                    </svg>
                                </div>
                                <div className={classes.subListTitle}> Upcoming Conversations</div>
                            </div>
                            <div className={classes.subListCount}>
                                {props.all_followUp.length}
                            </div>
                        </div>
                        }*/}
                        {/*{Boolean(props.all_followUp) && props.all_followUp.length !== 0 &&
                        <div className={showFollowUp ? classes.showConvo : classes.hideConvo}>
                            {followUp}
                        </div>
                        }*/}
                        {props?.conversations?.length === 0 && <EmptyChat msg='no chats available'/>}
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = ({ conversationReducer, doctorReducer }) => {
const { conversations, doctorsNameAndImages, chatCounts,
    all_time, chatHistoryItemClick, all_followUp,
    } = conversationReducer;
    const { doctorDetail } = doctorReducer;
return { conversations, doctorsNameAndImages, chatCounts,
    all_time, chatHistoryItemClick, all_followUp, doctorDetail };
};
export default connect(mapStateToProps, {})(ConversationList);
