
import classes from './SettingBuilder.module.css';

const SettingList = (props) => {
    return (
        <div className={classes.chatsList}>
            <div className={classes.block}>
                <div className={classes.list_body}>
                    <div className={classes.list_header}>
                        {props.acceptingChat &&
                        <div className={classes.header_body}>
                            <div className={classes.header_text}>Settings</div>
                        </div>
                        }
                        {props.acceptingChat === false &&
                        <div className={classes.header_body_red}>
                            <div className={classes.header_text_red}>You are currently not accepting chat</div>
                        </div>
                        }
                    </div>

                    <div className={classes.list}>
                        <div className={classes.list_container}>
                            {/*<div className={classes.list_item}>
                                <div className={classes.list_main}>
                                    <div className={classes.list_item_view} width="12px" height="8px" fill="#a0a6ab">
                                        <svg fill="#a0a6ab" width="12px" height="8px" viewBox="0 0 12 8"><path fill="inherit" fillRule="evenodd" d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path></svg>
                                    </div>
                                </div>
                                <a href onClick={() => props.settingItemsClick('Tags')}>
                                    <span>Tags</span>
                                </a>
                            </div>*/}
                            <div className={classes.list_item}>
                                <div className={classes.list_main}>
                                    <div className={classes.list_item_view} width="12px" height="8px" fill="#a0a6ab">
                                        <svg fill="#a0a6ab" width="12px" height="8px" viewBox="0 0 12 8">
                                            <path fill="inherit" fillRule="evenodd"
                                                  d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <a href onClick={() => props.settingItemsClick('Canned Responses')}>
                                    <span>Canned Responses</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingList;
