import {
    ACCOUNT_TYPE,
    ADD_MORE_USERS_INTO_STAFF,
    ADMIN_PROFILE,
    ANSWER_PATIENTS,
    CREATE_PRESCRIPTIONS,
    CUSTOM_PROFILE,
    FETCH_STAFF_MEMBERS,
    GET_REPORTS,
    INVITE_PATIENTS,
    INVITE_STAFF_FAILED,
    INVITE_STAFF_PROCESS,
    INVITE_STAFF_SUCCESS,
    NEED_STAFF_ADDRESS,
    NURSE_PROFILE,
    OWNER_PROFILE,
    PHYSICIAN_PROFILE,
    RECEPTIONIST_PROFILE,
    STAFF_ADDRESS,
    STAFF_CITY,
    STAFF_COUNTRY,
    STAFF_EMAIL_ADDRESS,
    STAFF_FAX_NUMBER,
    STAFF_FULL_NAME,
    STAFF_LICENSE_NO,
    STAFF_MEMEBER_PHONE_NUMBER,
    STAFF_PHONE,
    STAFF_PROFILE_IMAGE,
    STAFF_PROVINCE,
    STAFF_SIGNATURE,
    STAFF_SOAP_NOTES,
    TRANSFER_CHAT,
    VIEW_ARCHIVES,
    VIEW_INSURANCE_INFO,
    VIEW_PATIENTS_IN_QUEUE
} from '../constants';

const INITIAL_STATE = {
    staff_email: '', staff_profile: 'Profile', add_more_users: false, create_prescription: false,
    invite_patients: false, soap_notes: false, view_patients_in_queue: false, ans_patients: false, transfer_chat: false,
    view_archives: false, view_insurance_info: false, get_reports: false, account_type: 'invite_link',
    full_name: '', phone: '', license_no: '', profile_image: null, signature: null, loading: false, message: '',
    staffMembers: [], need_staff_address: true, staff_address: '', staff_country: 'Canada', staff_province: '',
    staff_city: '', staff_phone_number: '', staff_fax_no: ''
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case STAFF_EMAIL_ADDRESS:
            return {...state, staff_email: action.payload};
        case OWNER_PROFILE:
            return {
                ...state,
                staff_profile: action.payload,
                add_more_users: true,
                create_prescription: false,
                invite_patients: true,
                soap_notes: false,
                view_patients_in_queue: true,
                ans_patients: false,
                view_insurance_info: true,
                transfer_chat: true,
                view_archives: true,
                get_reports: true,
            };
        case ADMIN_PROFILE:
            return {
                ...state,
                staff_profile: action.payload,
                add_more_users: true,
                create_prescription: false,
                invite_patients: true,
                soap_notes: false,
                view_patients_in_queue: true,
                ans_patients: false,
                view_insurance_info: true,
                transfer_chat: true,
                view_archives: true,
                get_reports: true,
            };
        case PHYSICIAN_PROFILE:
            return {
                ...state,
                staff_profile: action.payload,
                add_more_users: false,
                create_prescription: true,
                invite_patients: true,
                soap_notes: true,
                view_patients_in_queue: true,
                ans_patients: true,
                view_insurance_info: false,
                transfer_chat: false,
                view_archives: true,
                get_reports: false,
            };
        case NURSE_PROFILE:
            return {
                ...state,
                staff_profile: action.payload,
                add_more_users: false,
                create_prescription: false,
                invite_patients: true,
                soap_notes: true,
                view_patients_in_queue: true,
                ans_patients: true,
                view_insurance_info: false,
                transfer_chat: false,
                view_archives: true,
                get_reports: false,
            };
        case RECEPTIONIST_PROFILE:
            return {
                ...state,
                staff_profile: action.payload,
                add_more_users: true,
                invite_patients: true,
                view_patients_in_queue: true,
                view_insurance_info: true,
                view_archives: true,
                transfer_chat: true,
                get_reports: true,
                create_prescription: false,
                soap_notes: false,
                ans_patients: false,
            };
        case CUSTOM_PROFILE:
            return {
                ...state,
                staff_profile: action.payload,
                add_more_users: false,
                create_prescription: false,
                invite_patients: false,
                soap_notes: false,
                view_patients_in_queue: false,
                ans_patients: false,
                view_insurance_info: false,
                transfer_chat: false,
                view_archives: false,
                get_reports: false,
            };

        case ADD_MORE_USERS_INTO_STAFF:
            return {...state, add_more_users: action.payload, staff_profile: 'custom'};
        case CREATE_PRESCRIPTIONS:
            return {...state, create_prescription: action.payload, staff_profile: 'custom'};
        case INVITE_PATIENTS:
            return {...state, invite_patients: action.payload, staff_profile: 'custom'};
        case STAFF_SOAP_NOTES:
            return {...state, soap_notes: action.payload, staff_profile: 'custom'};
        case VIEW_PATIENTS_IN_QUEUE:
            return {...state, view_patients_in_queue: action.payload, staff_profile: 'custom'};
        case ANSWER_PATIENTS:
            return {...state, ans_patients: action.payload, staff_profile: 'custom'};
        case TRANSFER_CHAT:
            return {...state, transfer_chat: action.payload, staff_profile: 'custom'};
        case VIEW_ARCHIVES:
            return {...state, view_archives: action.payload, staff_profile: 'custom'};
        case VIEW_INSURANCE_INFO:
            return {...state, view_insurance_info: action.payload, staff_profile: 'custom'};
        case GET_REPORTS:
            return {...state, get_reports: action.payload, staff_profile: 'custom'};
        case ACCOUNT_TYPE:
            return {...state, account_type: action.payload};
        case STAFF_FULL_NAME:
            return {...state, full_name: action.payload};
        case STAFF_PHONE:
            return {...state, phone: action.payload};
        case NEED_STAFF_ADDRESS:
            return {...state, need_staff_address: action.payload};
        case STAFF_ADDRESS:
            return {...state, staff_address: action.payload};
        case STAFF_COUNTRY:
            return {...state, staff_country: action.payload};
        case STAFF_PROVINCE:
            return {...state, staff_province: action.payload};
        case STAFF_CITY:
            return {...state, staff_city: action.payload};
        case STAFF_MEMEBER_PHONE_NUMBER:
            return {...state, staff_phone_number: action.payload};
        case STAFF_FAX_NUMBER:
            return {...state, staff_fax_no: action.payload};
        case STAFF_LICENSE_NO:
            return {...state, license_no: action.payload};
        case STAFF_PROFILE_IMAGE:
            return {...state, profile_image: action.payload};
        case STAFF_SIGNATURE:
            return {...state, signature: action.payload};
        case INVITE_STAFF_PROCESS:
            return {...state, loading: true, message: ''};
        case INVITE_STAFF_SUCCESS:
            return {...state, loading: false, message: action.payload};
        case INVITE_STAFF_FAILED:
            return {...state, loading: false, message: action.payload};
        case FETCH_STAFF_MEMBERS:
            return {...state, staffMembers: action.payload};
        default:
            return state;
    }
};
