import  {Component, createRef} from 'react';
import TimeAgo from 'timeago-react';
import _classes from './_archivestyles.module.css';

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const Item = (props) => {
    var selected = props.selectedArchive === props.conversationID ? true : false;
    var name = props.patientName !== undefined ? capitalize(props.patientName) : 'Patient';
    var email = props.patientEmail !== undefined ? `(${props.patientEmail})` : '';
    return (
        <div className={false ? _classes.selected_list_view : _classes.arc_list_view}
             onClick={() => props.onArchiveItemClick(props.archiveData)}>
            <div className={_classes.list_img_style}>
                <div className={_classes.selected_list_img}>
                    <span className={_classes.selected_user_img}>{name.charAt(0)}</span>
                </div>
            </div>
            <div className={_classes.list_item}>
                <div className={false ? _classes.selected_item_name : _classes.patient_name}>{name}</div>
                {/* <div className={false ? _classes.selected_item_name : _classes.item_name}>{email}</div>
                <div className={false ? _classes.selected_item_name : _classes.item_name}>{props.doctorName}</div> */}
                <div className={false ? _classes.selected_item_name : _classes.item_name}>
                    <span>{name.charAt(0)}: </span>
                    Issue: {props.issue}.
                </div>

            </div>
            {!false &&
            <div className={_classes.list_item_second}>
                <div>
                    <div className={_classes.time_style}>Scheduled: <TimeAgo datetime={props.timestamp}/></div>
                </div>
            </div>
            }
        </div>
    )
}

export default class List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedArchive: null,
            archiveSetState: 0
        }
    }

    onArchiveItemClick = (data) => {

        const {
            user_id, patientEmail, patientName, doctorName, doctorID, patientID, doctorImage,
            conversationID, tagsList, city, country, platform, DOB, gender, issue, currentMedications,
            diagnosisValue, opinionDescriptionValue, commentAdded, medications, followUpData,
            gmtOffset, buildNumber, convoCount, averageSleep, currentSleep, heartRate, heightCm, heightFeet,
            stepCount, runningWalking, weightKg, weightLbs, periodStartDate, flowType, restingHeartRate,
            complete_address, timestamp, issueResolved,
            symptoms, findings, assessment, plan, icd, billing_code, billing_unit,
            prescriptions, requisitions, special_referals, video_consult, followUpDays
        } = data;

        this.setState({selectedArchive: conversationID});
        this.props.archiveHeaderItem(user_id, patientEmail, patientName, doctorName, doctorID, patientID, doctorImage,
            conversationID, tagsList, city, country, platform, DOB, gender, issue, currentMedications,
            diagnosisValue, opinionDescriptionValue, commentAdded, medications, followUpData,
            gmtOffset, buildNumber, convoCount, averageSleep, currentSleep, heartRate, heightCm, heightFeet,
            stepCount, runningWalking, weightKg, weightLbs, periodStartDate, flowType, restingHeartRate,
            complete_address, timestamp, issueResolved,
            symptoms, findings, assessment, plan, icd, billing_code, billing_unit,
            prescriptions, requisitions, special_referals, video_consult, followUpDays, data);
    }

    archiveDefault = () => {
        if (this.props.archives[0]) {
            const {
                user_id,
                patientEmail,
                patientName,
                doctorName,
                doctorID,
                patientID,
                doctorImage,
                conversationID,
                tagsList,
                city,
                country,
                platform,
                DOB,
                gender,
                issue,
                currentMedications,
                diagnosisValue,
                opinionDescriptionValue,
                commentAdded,
                medications,
                followUpData,
                gmtOffset,
                buildNumber,
                convoCount,
                averageSleep,
                currentSleep,
                heartRate,
                heightCm,
                heightFeet,
                stepCount,
                runningWalking,
                weightKg,
                weightLbs,
                periodStartDate,
                flowType,
                restingHeartRate,
                complete_address,
                timestamp,
                issueResolved,
                symptoms,
                findings,
                assessment,
                plan,
                icd,
                billing_code,
                billing_unit,
                prescriptions,
                requisitions,
                special_referals,
                video_consult,
                followUpDays
            } = this.props.archives[0];

            this.setState({selectedArchive: conversationID});
            this.props.archiveHeaderItem(user_id, patientEmail, patientName, doctorName, doctorID, patientID, doctorImage,
                conversationID, tagsList, city, country, platform, DOB, gender, issue, currentMedications,
                diagnosisValue, opinionDescriptionValue, commentAdded, medications, followUpData,
                gmtOffset, buildNumber, convoCount, averageSleep, currentSleep, heartRate, heightCm,
                heightFeet, stepCount, runningWalking, weightKg, weightLbs, periodStartDate, flowType,
                restingHeartRate, complete_address, timestamp, issueResolved,
                symptoms, findings, assessment, plan, icd, billing_code, billing_unit,
                prescriptions, requisitions, special_referals, video_consult, followUpDays, this.props.archives[0]);
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (Boolean(newProps.archives) && Boolean(newProps.archives[0]) && this.state.archiveSetState !== 1) {
            const {
                user_id,
                patientEmail,
                patientName,
                doctorName,
                doctorID,
                patientID,
                doctorImage,
                conversationID,
                tagsList,
                city,
                country,
                platform,
                DOB,
                gender,
                issue,
                currentMedications,
                diagnosisValue,
                opinionDescriptionValue,
                commentAdded,
                medications,
                followUpData,
                gmtOffset,
                buildNumber,
                convoCount,
                averageSleep,
                currentSleep,
                heartRate,
                heightCm,
                heightFeet,
                stepCount,
                runningWalking,
                weightKg,
                weightLbs,
                periodStartDate,
                flowType,
                restingHeartRate,
                complete_address,
                timestamp,
                issueResolved,
                symptoms,
                findings,
                assessment,
                plan,
                icd,
                billing_code,
                billing_unit,
                prescriptions,
                requisitions,
                special_referals,
                video_consult,
                followUpDays
            } = newProps.archives[0];

            this.setState({selectedArchive: newProps.archives[0].conversationID, archiveSetState: 1});

            this.props.archiveHeaderItem(user_id, patientEmail, patientName, doctorName, doctorID, patientID, doctorImage,
                conversationID, tagsList, city, country, platform, DOB, gender, issue, currentMedications,
                diagnosisValue, opinionDescriptionValue, commentAdded, medications, followUpData,
                gmtOffset, buildNumber, convoCount, averageSleep, currentSleep, heartRate, heightCm,
                heightFeet, stepCount, runningWalking, weightKg, weightLbs, periodStartDate, flowType,
                restingHeartRate, complete_address, timestamp, issueResolved,
                symptoms, findings, assessment, plan, icd, billing_code, billing_unit,
                prescriptions, requisitions, special_referals, video_consult, followUpDays, newProps.archives[0]);
        }
    }

    componentDidUpdate() {
        if (Boolean(this.props.previous_length) && !this.props.isSearch) {
            if (this.props.previous_length !== this.props.archives.length - 1 && Boolean(this[`myrefRow ${this.props.previous_length}`].current))
                this[`myrefRow ${this.props.previous_length}`].current.scrollIntoView({block: "start"});
        }
    }

    archiveSearchValue = (event) => {
        this.props.archiveSearchValue(event.target.value);
    }
    archiveSearchText = (event) => {
        this.props.archiveSearchText();
    }

    render() {
        var archivesList = [];
        if (Boolean(this.props.archives)) {
            archivesList = this.props.archives.map((archive, index) => {
                let ref = `myrefRow ${index}`;
                this[ref] = createRef();

                return <Item
                    key={archive.conversationID}
                    patientName={archive.patientName}
                    doctorName={archive.doctorName}
                    conversationID={archive.conversationID}
                    doctorImage={archive.doctorProfileImg}
                    doctorID={archive.doctorID}
                    timestamp={archive.timestamp}
                    archiveData={archive}
                    patientEmail={archive.patientEmail}
                    tagsList={archive.tagsList !== undefined ? archive.tagsList : []}
                    onArchiveItemClick={(data) => {
                        this.props.chatSelected(true)
                        this.onArchiveItemClick(data)
                    }}
                    selectedArchive={this.state.selectedArchive}
                    nextref={this[ref]}
                />
            })
        }

        return (<div className={_classes.list_body_main} style={{top: '75px'}}>

                <div className={_classes.search_box}>
                    <div className={_classes.search_box_section}>
                        <div className={_classes.input_view}>
                            <input
                                placeholder="Press enter to search in archives"
                                className={_classes.input_style}
                                onChange={(event) => this.archiveSearchValue(event)}
                                onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                        this.props.archiveSearchText(event)
                                    }
                                }}
                            />
                        </div>
                        <div className={_classes.search_sub_section}>
                            <div className={_classes.list_count}>
                                {Boolean(this.props.archives) &&
                                <div><span>{this.props.archives.length} </span>chats</div>
                                }
                            </div>
                            <div className={_classes.search_button} onClick={event => {
                                this.props.archiveSearchText(event)
                            }}> Search
                            </div>
                        </div>
                    </div>
                </div>

                {!this.props.loading && Boolean(this.props.archives) && this.props.archives.length !== 0 &&
                <div className={_classes.ul_list} style={{marginTop: '20px',}}>
                    {archivesList}
                    {!this.props.isSearch && !this.props.isLast &&
                    <div className={_classes.load_more} onClick={() => this.props.nextArchives()}>
                        Load More
                    </div>
                    }
                </div>
                }
                {!this.props.loading && this.props.archives.length === 0 &&
                <div className={_classes.no_text}>No data is found</div>
                }

                {this.props.loading &&
                <div className={_classes.no_loading}>
                    <div className={_classes.no_loading_view}>
                        <div className={_classes.loading_loader}/>
                    </div>
                </div>
                }
            </div>
        )
    }
}
