
import classes from '../settings.module.css';

const CannedResponsesList = (props) => {
    if (props.selectedEditTag === props.uid) {
        return (
            <div className={classes.response_item}>
                <div className={classes.response_item_form}>
                    <div className={classes.response_item_field}>
                        <textarea
                            value={props.selectedTagDesciption}
                            onChange={(event) => props.onChangeCannedDescription(event)}
                        />
                        <div className={classes.response_item_tag}>
                            <input
                                value={props.selectedTagShortCut}
                                onChange={(event) => props.onChangeCannedShortCut(event)}
                            />
                        </div>
                    </div>
                    <div className={classes.response_item_submit}>
                        <button
                            onClick={() => props.updateCannedResponse(props.uid, props.selectedTagDesciption, props.selectedTagShortCut)}>
                            <span>Save changes</span>
                        </button>
                        <span>or
                            <div
                                className={classes.cancel_button}
                                onClick={() => props.updateCancelClick()}
                            >cancel</div>
                        </span>
                    </div>
                </div>
            </div>
        )
    } else if (props.selectedDeleteTag === props.uid) {
        return (
            <div className={classes.response_item}>
                <div className={classes.delete_view}>
                    <div className={classes.confirm_remove}>
                        <div className={classes.wrapper}>
                            <div>
                                <button onClick={() => props.confirmDeleteClick(props.selectedDeleteTag)}><span>Delete this response</span>
                                </button>
                                or
                                <span
                                    className={classes.cancel_delete_button}
                                    onClick={() => props.confirmCancelClick()}
                                >cancel</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className={classes.response_item}>
            <div className={classes.view}>
                <div className={classes.text}>{props.description}</div>
                <div className={classes.links}>
                    <span onClick={() => props.onEditClick(props.uid, props.description, props.shortcut)}>Edit</span>
                    <span onClick={() => props.onDeleteClick(props.uid)}>Delete</span>
                </div>
                <div className={classes.tags}>
                    <span>shortcuts</span>
                    <div className={classes.shortcut}>
                        <span>{props.shortcut[0]}</span>{props.shortcut.slice(1, (props.shortcut.length))}</div>
                </div>
            </div>
        </div>
    )
}

const CannedResponses = (props) => {

    const cannedResponsesList = props.canned_responsesList.map(canned_response => {
        return <CannedResponsesList
            key={canned_response.uid}
            uid={canned_response.uid}
            description={canned_response.text}
            shortcut={canned_response.shortcut}
            selectedEditTag={props.selectedEditTag}
            selectedDeleteTag={props.selectedDeleteTag}
            selectedTagDesciption={props.selectedTagDesciption}
            selectedTagShortCut={props.selectedTagShortCut}
            onChangeCannedDescription={(event) => props.onChangeCannedDescription(event)}
            onChangeCannedShortCut={(event) => props.onChangeCannedShortCut(event)}
            onEditClick={(uid, desc, shortcut) => props.onEditClick(uid, desc, shortcut)}
            updateCannedResponse={(uid, desc, shortcut) => props.updateCannedResponse(uid, desc, shortcut)}
            updateCancelClick={() => props.updateCancelClick()}
            onDeleteClick={(uid) => props.onDeleteClick(uid)}
            confirmDeleteClick={(uid) => props.confirmDeleteClick(uid)}
            confirmCancelClick={() => props.confirmCancelClick()}
        />
    });

    return (
        <div className={classes.detail_content}>
            <div className={classes.content}>
                <h2>What are canned responses?</h2>
                <p className={classes.intro}>Canned responses are pre-made messages you can recall easily during
                    chat.</p>
            </div>
            <form className={classes.form}>
                <div className={classes.field}>
                    <div>
                        <textarea
                            type="text"
                            id="add-tag"
                            placeholder="Canned responses text...."
                            tabIndex="1"
                            required
                            value={props.description}
                            onChange={(text) => props.onCannedResponseTextChange(text)}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            id="add-tag"
                            placeholder="Shortcut..."
                            tabIndex="1"
                            required
                            value={props.short_cut}
                            onChange={(text) => props.onCannedResponseShortcutChange(text)}
                        />
                    </div>
                    <p>To use a canned response during a chat, type in <strong>#</strong> and a shortcut. <br/>You can
                        use comma to add more shortcuts.</p>
                </div>
                <div className={classes.submit}>
                    <button tabIndex="2" onClick={(event) => props.onSaveCannedResponse(event)}>
                        <span>Add a Canned Response</span>
                    </button>
                </div>
            </form>
            <div className={classes.response}>
                <div className={classes.response_list}>
                    {cannedResponsesList}
                </div>
            </div>
        </div>
    )
}

export default CannedResponses;
