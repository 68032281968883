import  {Component} from 'react';
import {Document, Font, Image, Page, StyleSheet, Text, View} from '@react-pdf/renderer';
import timesnewroman from './fonts/timesnewroman.ttf';
import timesnewromanbold from './fonts/timesnewromanbold.ttf';
import timesnewromanbolditalic from './fonts/timesnewromanbolditalic.ttf';
import timesnewromanitalic from './fonts/timesnewromanitalic.ttf';
import arial from './fonts/ARIAL.TTF';
import arialbold from './fonts/ARIALBD.TTF';
import arialbolditalic from './fonts/ARIALBI.TTF';
import arialitalic from './fonts/ARIALI.TTF';

var moment = require("moment");
Font.register({
    family: 'serif', fonts: [
        {src: timesnewroman}, // font-style: normal, font-weight: normal
        {src: timesnewromanitalic, fontStyle: 'italic'},
        {src: timesnewromanbold, fontWeight: 700},
        {src: timesnewromanbolditalic, fontStyle: 'italic', fontWeight: 700},
    ]
});

Font.register({
    family: 'arial', fonts: [
        {src: arial}, // font-style: normal, font-weight: normal
        {src: arialitalic, fontStyle: 'italic'},
        {src: arialbold, fontWeight: 700},
        {src: arialbolditalic, fontStyle: 'italic', fontWeight: 700},
    ]
});
// Create styles
const styles = StyleSheet.create({
    page: {

        backgroundColor: 'white',
        paddingHorizontal: 96,
        fontFamily: "arial",
        fontSize: "11",
        paddingVertical: 65,
        height: "100%"
    },
    pageNumber: {
        position: 'absolute',
        bottom: 15,
        left: 0,
        right: 0,
        textAlign: "right",
        marginRight: "20",
        fontSize: "10",
    },

    title_section: {
        margin: 10,
        paddingVertical: 30,
        fontWeight: "ultrabold",
        fontSize: "11",
    },
    pdf_header: {
        width: "100%",
        fontSize: "10"
    },
    report_title: {
        textAlign: "center",
        fontSize: "11",
        fontWeight: 900
    },
    patient_info_row_sub_section: {
        flexDirection: 'row',
        paddingRight: "20",
        width: "50%",
        textAlign: "left",
        fontSize: "11",
    },
    patient_info_detail_sub_section: {
        flexDirection: 'row',
        fontSize: "11",
    },
    patient_info_detail_header: {
        flexDirection: 'row',
        paddingBottom: 5,
        fontWeight: 900,
        fontSize: "11",
    },
    patient_info_detail_value: {
        paddingBottom: 10,
        fontSize: "11",
    },
    patient_info_detail_value_last: {
        fontSize: "11"
    },
    patient_info_row: {
        paddingBottom: "5",
        width: "100%",
        flexDirection: 'row',
        fontSize: "11",
    },
    patient_info_section: {
        flexDirection: 'column',
        paddingBottom: "15",
        fontSize: "11",
        borderBottom: "1px grey solid"
    },
    patient_info_section_without_border: {
        flexDirection: 'column',
        paddingBottom: "15",
        marginBottom: "20",
        fontSize: "11"
    },
    patient_info_detail_doctor_header: {
        flexDirection: 'row',
        paddingBottom: 5,
        fontSize: "11",
    },
    history_section_row: {
        flexDirection: 'row',
        fontSize: "11",
    },
    history_section_detail_header: {
        flexDirection: 'row',
        fontWeight: 900,
        fontSize: "11",
        width: "140"
    },
    history_section_detail_value: {
        paddingBottom: 5,
        fontSize: "11",
    },
    history_section_header: {
        paddingBottom: 15,
        fontSize: "12",
        fontWeight: 900
    }

});

// Create Document Component
class MyDocument extends Component {
    render = () => {
        //var str = "1055 Canadian Place, Suite #112, Mississauga, ON, L4W 0C2";
        var list = (this.props.clinicAddress) ? this.props.clinicAddress.split(",") : ['', ''];
        var first_row = list[0];
        list.splice(0, 1);
        var second_row = list.toString().replace(" ", '');
        var patientDOB = this.props.patientDOB.replace(" ", ",").replace(" ", ", ").replace(",", " ");
        var current_date = moment(this.props.start_timestamp).format('LL');
        const startDate = moment(this.props.start_timestamp);
        const timeEnd = moment(this.props.endTime);
        const diff = timeEnd.diff(startDate);
        const diffDuration = moment.duration(diff);
        return (

            <Document title="Virtual Clinic Visit Report">
                <Page size={["8.5in", "11in"]} style={styles.page} wrap>

                    <View style={styles.pdf_header}>
                        <Text>{this.props.clinicName}</Text>
                        <Text>{first_row}</Text>
                        <Text>{second_row}</Text>
                        <Text>{"Ph: " + this.props.clinicPhoneNumber}</Text>
                        <Text>{"Fax: " + this.props.clinicPhoneNumber}</Text>
                    </View>
                    <View style={styles.title_section}>
                        <Text style={styles.report_title}>Virtual Clinic Visit Report - {current_date}</Text>
                    </View>
                    <View style={styles.patient_info_section}>
                        {this.props.encounter_duration && <View style={styles.patient_info_row}>
                            <View style={styles.patient_info_row_sub_section}>
                                <Text>Reporting Physician: </Text>
                                <Text>{this.props.doctorName}</Text>
                            </View>

                            <View style={styles.patient_info_row_sub_section}>
                                <Text>Encounter Duration: </Text>
                                <Text>{(Boolean(diffDuration.hours()) ? diffDuration.hours() + " hours and " : "") + (diffDuration.minutes() + " minutes")}</Text>
                            </View>
                        </View>}
                        {!this.props.encounter_duration && <View style={styles.patient_info_row}>
                            <Text>Reporting Physician: </Text>
                            <Text>{this.props.doctorName}</Text>
                        </View>}
                        <View style={styles.patient_info_row}>
                            <View style={styles.patient_info_row_sub_section}>
                                <Text>Patient's Name: </Text>
                                <Text>{this.props.patientName}</Text>
                            </View>
                            <View style={styles.patient_info_row_sub_section}>
                                <Text>Date of Birth: </Text>
                                <Text>{patientDOB}</Text>
                            </View>
                        </View>

                    </View>
                    <View style={styles.patient_info_section}>
                        <Text style={styles.history_section_header}>ENCOUNTER INFORMATION</Text>
                        <View style={styles.history_section_row}>
                            <View style={styles.history_section_detail_header}>
                                <Text>Video Consult:</Text>
                            </View>
                            <View style={styles.history_section_detail_value}>
                                <Text>{this.props.video_consult ? "Yes" : "No"}</Text>
                            </View>
                        </View>

                        <View style={styles.history_section_row}>
                            <View style={styles.history_section_detail_header}>
                                <Text>Requisitions:</Text>
                            </View>
                            <View style={styles.history_section_detail_value}>
                                <Text>{this.props.requisitions ? "Yes" : "No"}</Text>
                            </View>
                        </View>

                        <View style={styles.history_section_row}>
                            <View style={styles.history_section_detail_header}>
                                <Text>Prescription:</Text>
                            </View>
                            <View style={styles.history_section_detail_value}>
                                <Text>{this.props.prescriptions ? "Yes" : "No"}</Text>
                            </View>
                        </View>

                        <View style={styles.history_section_row}>
                            <View style={styles.history_section_detail_header}>
                                <Text>Referral:</Text>
                            </View>
                            <View style={styles.history_section_detail_value}>
                                <Text>{this.props.special_referals ? "Yes" : "No"}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.patient_info_section}>
                        <Text style={styles.history_section_header}>PATIENT INFORMATION</Text>

                        <View style={styles.history_section_row}>
                            <View style={styles.history_section_detail_header}>
                                <Text>Allergies:</Text>
                            </View>
                            <View style={styles.history_section_detail_value}>
                                <Text>{Boolean(this.props.patient_profile) ? !Boolean(this.props.patient_profile.allergyData) ? '-' : this.props.patient_profile.allergyData.length < 1 ? '-' : this.props.patient_profile.allergyData.join(', ') : '-'}</Text>
                            </View>
                        </View>

                        <View style={styles.history_section_row}>
                            <View style={styles.history_section_detail_header}>
                                <Text>Prior Health History:</Text>
                            </View>
                            <View style={styles.history_section_detail_value}>
                                <Text>{this.props.healthProblems.length == 0 ? "-" : this.props.healthProblems.join(', ')}</Text>
                            </View>
                        </View>

                        <View style={styles.history_section_row}>
                            <View style={styles.history_section_detail_header}>
                                <Text>Current Medications:</Text>
                            </View>
                            <View style={styles.history_section_detail_value}>
                                <Text>{Boolean(this.props.patient_profile) ? !Boolean(this.props.patient_profile.selected) ? "-" : this.props.patient_profile.selected.length < 1 ? '-' : this.props.patient_profile.selected.join(', ') : '-'}</Text>
                            </View>
                        </View>

                        <View style={styles.history_section_row}>
                            <View style={styles.history_section_detail_header}>
                                <Text>Family History:</Text>
                            </View>
                            <View style={styles.history_section_detail_value}>
                                <Text>{this.props.familyProblems.length == 0 ? "-" : this.props.familyProblems.join(', ')}</Text>
                            </View>
                        </View>

                        <View style={styles.history_section_row}>
                            <View style={styles.history_section_detail_header}>
                                <Text>Smoking:</Text>
                            </View>
                            <View style={styles.history_section_detail_value}>
                                <Text>{this.props.patient_profile.smokeFlag == true ? this.props.patient_profile.smokeAnswer != null && this.props.patient_profile.smokeAnswer != undefined && this.props.patient_profile.smokeAnswer != '' ? this.props.patient_profile.smokeAnswer : this.props.patient_profile.smokeAnswer != null && this.props.patient_profile.smokeAnswer != undefined ? 'No' : '-' : this.props.patient_profile.smokeFlag != null && this.props.patient_profile.smokeFlag != undefined ? 'No' : '-'}</Text>
                            </View>
                        </View>

                        <View style={styles.history_section_row}>
                            <View style={styles.history_section_detail_header}>
                                <Text>Alcohol Use:</Text>
                            </View>
                            <View style={styles.history_section_detail_value}>
                                <Text>{this.props.patient_profile.alcoholFlag == true ? this.props.patient_profile.alocoholAnswer != null && this.props.patient_profile.alocoholAnswer != undefined && this.props.patient_profile.alocoholAnswer != '' ? this.props.patient_profile.alocoholAnswer : this.props.patient_profile.alocoholAnswer != null && this.props.patient_profile.alocoholAnswer != undefined ? 'No' : '-' : this.props.patient_profile.alcoholFlag != null && this.props.patient_profile.alcoholFlag != undefined ? 'No' : '-'}</Text>
                            </View>
                        </View>

                        <View style={styles.history_section_row}>
                            <View style={styles.history_section_detail_header}>
                                <Text>Blood Group:</Text>
                            </View>
                            <View style={styles.history_section_detail_value}>
                                <Text>{this.props.patient_profile.bloodType != null && this.props.patient_profile.bloodType != undefined && this.props.patient_profile.bloodType != '' ? this.props.patient_profile.bloodType : '-'}</Text>
                            </View>
                        </View>
                    </View>

                    <View style={styles.patient_info_section_without_border}>
                        <Text style={styles.history_section_header}>SOAP NOTE</Text>
                        <View style={styles.patient_info_detail_header}>
                            <Text>SUBJECTIVE FINDINGS</Text>
                        </View>
                        <View style={styles.patient_info_detail_value}>
                            <Text>{this.props.patient_issue}</Text>
                        </View>

                        <View style={styles.patient_info_detail_header}>
                            <Text>PHYSICAL EXAMINATION</Text>
                        </View>
                        <View style={styles.patient_info_detail_value}>
                            <Text>Physical Examination was not performed in view of virtual visit.</Text>
                        </View>

                        <View style={styles.patient_info_detail_header}>
                            <Text>OBJECTIVE FINDINGS</Text>
                        </View>
                        <View style={styles.patient_info_detail_value}>
                            <Text>{this.props.findings}
                            </Text>
                        </View>
                        <View style={styles.patient_info_detail_header}>
                            <Text>CLINICAL ASSESSMENT</Text>
                        </View>
                        <View style={styles.patient_info_detail_value}>
                            <Text>{this.props.assessment}
                            </Text>
                        </View>

                        <View style={styles.patient_info_detail_header}>
                            <Text>CLINICAL DIAGNOSIS</Text>
                        </View>
                        <View style={styles.patient_info_detail_value}>
                            <Text>{this.props.icd}</Text>
                        </View>

                        <View style={styles.patient_info_detail_header}>
                            <Text>MANAGEMENT PLAN</Text>
                        </View>
                        <View style={styles.patient_info_detail_value}>
                            <Text>{this.props.plan}
                            </Text>
                        </View>

                        <View style={styles.patient_info_detail_header}>
                            <Text>RECOMMENDED FOLLOW-UP</Text>
                        </View>
                        <View style={styles.patient_info_detail_value}>
                            <Text>Patient has been advised to follow up {this.props.followuptext}
                            </Text>
                        </View>
                        <View style={styles.patient_info_detail_value_last}>
                            <Image /* src={this.props.signatureImageURL} */
                                source={{
                                    uri: this.props.signatureImageURL,
                                    headers: {Pragma: 'no-cache', 'Cache-Control': 'no-cache'}
                                }}
                                style={{width: 80, height: 50}}
                            />
                            <Text>{this.props.doctorName}</Text>

                        </View>
                        <View style={styles.patient_info_detail_doctor_header}>
                            <Text>Lic# {this.props.doctorlicense}</Text>
                        </View>
                    </View>

                    <Text style={styles.pageNumber} render={({pageNumber, totalPages}) => (
                        `${this.props.clinicName} Consultation Summary, ${this.props.patientName}, ${current_date}, Page ${pageNumber} / ${totalPages}`
                    )} fixed/>
                </Page>
            </Document>
        )
    };
}

export default MyDocument;
