
import classes from './staff.module.css';

const image = require('../../../assets/download.png');

const AgentsItems = (props) => {
    var selected = props.selectedAgent === props.doctorID ? true : false;
    return (
        <tr className={selected ? classes.selected : classes.item} onClick={() => props.onAgentClick(props.agent)}>
            <td>
                {selected && <div className={classes.selected_mark}/>}
                <div className={classes.icon_image}>
                    <div className={classes.online_image_style}>
                        <img className={classes.online_image_style}
                             src={props.image !== undefined ? props.image : image} alt=""/>
                        {props.onlineStatus &&
                        <span className={classes.online_style}></span>
                        }
                        {!props.onlineStatus &&
                        <span className={classes.offline_style}></span>
                        }
                    </div>
                </div>
            </td>
            <td>
                <div className={classes.doctor_content}>{props.agent.fullName}</div>
                <div className={classes.doctor_email}>{props.agent.staffEmail}</div>
            </td>
            <td>
                <div
                    className={props.agent.staffProfile === 'admin' ? classes.admin_role : classes.doctor_role}>{props.agent.staffProfile && props.agent.staffProfile.charAt(0).toUpperCase() + props.agent.staffProfile.slice(1)}</div>
            </td>
            {/*<td colSpan="1">
                <div className={classes.profile_status}>{(props.agent.profileStatus === 'deleted')?" Deactivated":""}</div>
            </td>*/}
        </tr>
    )
}

export default AgentsItems;
