import {Component, createRef, Fragment} from 'react';
import Aux from '../../../hoc/hocAux';
import {connect} from 'react-redux';
import classes from './clinicscreen.module.css';

import {
    changeQueueStatusForClinicalChats,
    closeClinicalChatByAdmin,
    fetchClinicalChats,
    fetchClinicalConversationCount,
    fetchClinicalUnReadCount,
    refreshClinicalConversations,
    setAlgolia,
    transferClinicalChatToYDOByAdmin,
    updateClinicalDoctor
} from '../../../actions/ClinicalChatsAction.js';
import {
    checkStatusAccept,
    childChanged,
    endVideoCall,
    fetchChatTags,
    fetchStatus,
    findPatientDetail,
    get_followUp,
    getAcceptStatus,
    refreshDeclineStatus,
    refreshRemoteStream,
    removeTagInChat,
    save_followUp,
    saveRemoteStream,
    saveTagInChat,
    set_notification,
    setFlagFalse,
    setFlagTrue,
    setFollowUpStatus,
    setSelectedItem,
    setSkipStatus,
    setVideoId,
    setVideoState,
    setVideoStatusFalse,
    setVideoStatusTrue
} from '../../../actions/ConversationAction';
import {
    fetchAllMessages,
    fetchUserChatHistory,
    imageData,
    makeUnreadAllMessages,
    onChangeText,
    refreshMessages,
    sendDoctorFirstMessage,
    sendMessage,
    turnSelectChat
} from '../../../actions/ChatAction';

import Spinner from '../../../components/UI/Spinner/Spinner';

import {fetchDoctorDetails} from '../../../actions/DoctorAction';
import {
    fetchCannedResponsesForAssessment,
    fetchCannedResponsesForChats,
    fetchCannedResponsesForObjectiveFindings,
    fetchCannedResponsesForPlan,
    setAssessment,
    setFindings,
    setPlan,
    setSubjectiveSymptoms
} from '../../../actions/ClinicalCannedResponseAction';
import {fetchTags} from '../../../actions/TagsAction';

import {childChangedDoctor} from '../../../actions/AgentsAction';

import ClinicalConversationList from './conversations/ClinicalConversationList';
import ClinicalChatScreen from './screens/ClinicalChatScreen';
import ClinicalPatientDetail from './patientDetail/ClinicalPatientDetail';
import OutPatientForm from './patientDetail/outPatientForm/outPatientForm';

import _, {debounce} from 'lodash';

import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import Modal from '../../../components/UI/Modal/Modal';
import ProgressBar from '../../../components/UI/Progressbar/Progressbar';

import {FaMicrophone, FaMicrophoneSlash, FaPhoneSlash} from "react-icons/fa";

//import AgoraRTC from "agora-rtc-sdk";

//let client = AgoraRTC.createClient({mode: "live", codec: "h264"});
const USER_ID = Math.floor(Math.random() * 1000000001);


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const Header = (props) => {
    return (
        <div className={classes.cross}>
            <div className={classes.add}>Upload Attachment</div>
        </div>
    )
}
const MediaHeader = (props) => {
    return (
        <div className={classes.cross}>
            <div className={classes.add}>Audio/Video</div>
        </div>
    )
}

class ClinicalDoctors extends Component {

    /*localStream = AgoraRTC.createStream({
        streamID: USER_ID,
        audio: true,
        video: true,
        screen: false,
    });*/
    /*localAudioStream = AgoraRTC.createStream({
        streamID: USER_ID,
        audio: true,
        video: false,
        screen: false,
    });*/

    constructor(props) {
        super(props);
        this.state = {
            fromID: '',
            conversationId: '',
            file: '',
            filetype: '',
            showUploadingModal: false,
            showCloseModal: false,
            showTagsListModal: false,
            selectedTags: [],
            showCannedResponseList: false,
            selectedCannedResponseShortCut: '',
            selectedCannedResponseText: '',
            showSelectedMessages: null,
            showOnlineDoctors: false,
            canned_responses: [],
            chats_canned_responses: [],
            playSound: false,
            messageLength: 0,
            showOutPatientForm: false,
            chatHistoryItemClick: false,
            chatHistoryUid: '',
            chatHistoryCategory: '',
            remoteStreams: {},
            minimizeVideo: true,
            audMute: false,
            vidMute: false,
            declineShown: false,
            videoState: false,
            videoStatus: this.props.videoStatus,
            audioState: false,
            type: '',
            patientInitial: '',
            showMediaModal: false,
            showPulse: false,
            open: false,
            clinicalChatData: null,
            actionPressed: false,
            showUpView: false,
            followClassStatus: false,
            skipClassStatus: false,
            dateTime: '',
            endTime: null,
            showSOAPNotes: 0,
            followUpDays: 0,
            allFollowUp: (this.props.all_followUp) ? this.props.all_followUp.length == 0 ? [] : this.props.all_followUp : []

        }

        if (props.history.location.state !== undefined && props.history.location.state !== null) {
            let {queued, conversationId, patientID, doctorID, issue, category} = props.history.location.state;
            if (queued) {
                this.removedFromQueueAndshowMessages(true, conversationId, patientID, doctorID, issue, category);
            } else {
                this.showMessages(true, conversationId, patientID, doctorID, issue, category);
            }
        }
    }

    initLocalStream = () => {
        /*let me = this;
        me.localStream.setVideoProfile('720p_1')
        me.localStream.init(
            function () {
                me.localStream.play("agora_local");
            },
            function (err) {
                //console.log("getUserMedia failed", err);
            }
        );*/
    };
    initLocalAudioStream = () => {
        /*let me = this;
        me.localAudioStream.setAudioProfile('high_quality')
        me.localAudioStream.init(
            function () {
                me.localAudioStream.play("agora_local");
            },
            function (err) {
                //console.log("getUserMedia failed", err);
            }
        );*/
    };

    UNSAFE_componentWillMount() {
        this.props.setAlgolia(this.props.clinicId);
        this.props.fetchDoctorDetails();
        this.props.fetchTags();
        this.props.childChanged();
        this.props.childChangedDoctor();
        this.props.fetchCannedResponsesForChats();
        this.props.fetchCannedResponsesForObjectiveFindings();
        this.props.fetchCannedResponsesForAssessment();
        this.props.fetchCannedResponsesForPlan();
        this.props.getAcceptStatus();
        this.props.refreshMessages();
        this.props.refreshClinicalConversations();

        this.props.fetchClinicalChats(this.props.clinicId, this.props.admin);
        this.props.fetchClinicalConversationCount();
        this.props.fetchClinicalUnReadCount();
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.props.doctorDetail.length == 0 && newProps.doctorDetail.length != 0) {
            this.props.get_followUp("CLINICAL", newProps.doctorDetail.doctorName)

        }
        if (this.props.isLoading === true && newProps.isLoading === false) {
            this.setState({showUpView: false})
        }
        if (this.props.all_followUp != newProps.all_followUp) {
            this.setState({allFollowUp: newProps.all_followUp})
        }

        if (newProps.changedDoctorKey === newProps.doctorDetail.uid) {
            this.props.checkStatusAccept();
        }
        if (this.props.messages.length > 0 && this.state.messageLength !== this.props.messages.length) {
            this.setState({messageLength: this.props.messages.length});
        }
        if (newProps.canned_responses !== this.props.canned_responses) {
            this.setState({canned_responses: newProps.canned_responses});
        }
        if (newProps.chats_canned_responses !== this.props.chats_canned_responses) {
            this.setState({chats_canned_responses: newProps.chats_canned_responses});
        }
        if (newProps.videoChatDeclineStatus === true && this.state.declineShown === false && this.props.declinedDoctorId === this.props.doctorDetail.uid) {
            this.state.declineShown = true;
            window.alert(`${this.state.type} chat request was declined by the patient`);
            if (this.state.type === 'Video') {
                this.stopVideoCall();
            } else {
                this.stopAudioCall()
            }
            this.props.refreshDeclineStatus();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.deletedConversationKey === this.props.conversationId || this.props.changedConversation === this.props.conversationId) {
            this.props.refreshMessages();
        }
    }

    skipStatus = () => {
        this.props.setSkipStatus(this.props.conversationId)
        this.setState({skipClassStatus: true, showUpView: false, followUpDays: 0});
    }

    componentDidMount() {
        if (this.state.videoStatus === true) {
        }
    }

    showMediaModalPress = () => {
        this.setState({showMediaModal: true})

    }
    onVideoPress = () => {
        this.startVideoChat()
        this.setState({showMediaModal: false})
        var message_video = `Starting video call`
        this.props.sendMessage(this.props.conversationId, {
            type: "text",
            content: message_video
        }, this.props.patientId, this.props.doctorName, this.props.doctorImage);


    }
    onAudioPress = () => {
        this.startAudioChat()
        this.setState({showMediaModal: false})
        var message_audio = `Starting audio call`
        this.props.sendMessage(this.props.conversationId, {
            type: "text",
            content: message_audio
        }, this.props.patientId, this.props.doctorName, this.props.doctorImage);

    }
    startVideoChat = () => {
        setTimeout(() => {
            this.props.setVideoState(this.props.location, "Video");
            this.props.setVideoStatusTrue();
        }, 5000);
        this.props.setVideoId(this.props.conversationId)
        this.setState({minimizeVideo: false, videoState: true, type: 'Video', showSOAPNotes: 2});
        this.initLocalStream();
        this.initClient();
        this.joinChannel();
    }
    startAudioChat = () => {
        setTimeout(() => {
            this.props.setVideoState(this.props.location, "Audio");
            this.props.setVideoStatusTrue();
        }, 5000);
        this.props.setVideoId(this.props.conversationId)
        this.setState({
            minimizeVideo: false,
            audioState: true,
            type: 'Audio',
            patientInitial: this.props.patientName,
            showSOAPNotes: 1
        });
        this.initLocalAudioStream();
        this.initClient();
        this.joinAudioChannel();
    }

    showMessages = (chatData) => {
        const {paidChat, conversationId, patientID, doctorID, issue, category} = chatData;
        if (this.userDetail !== null && this.userDetail !== undefined) {
            this.userDetail.refreshHistory();
        }

        this.props.setFlagTrue();
        this.setState({
            chatHistoryItemClick: false,
            showUpView: false,
            skipClassStatus: false,
            followClassStatus: false
        });

        this.props.setSelectedItem(paidChat, patientID, doctorID, conversationId, issue, category);
        if (this.props.doctorDetail.uid === doctorID)
            this.props.makeUnreadAllMessages(conversationId, patientID);

        this.props.findPatientDetail(patientID);
        this.props.refreshMessages();
        this.props.turnSelectChat(conversationId);
        this.props.fetchAllMessages(conversationId, this.state.chatHistoryItemClick);
        this.props.fetchChatTags(conversationId);
        this.props.fetchUserChatHistory(patientID);
        this.props.fetchStatus(conversationId);
        this.setState({minimizeVideo: true});
        this.props.setSubjectiveSymptoms(conversationId)
        this.props.setFindings(conversationId)
        this.props.setAssessment(conversationId)
        this.props.setPlan(conversationId)

    }

    removedFromQueueAndshowMessages = debounce((chatData) => {
        const {paidChat, conversationId, patientID, issue, category} = chatData;
        if (this.props.doctorDetail.role === 'Doctor') {
            if (this.props.doctorDetail.profileStatus !== "deleted") {
                if (this.props.doctorDetail.accepting_chat) {
                    this.props.changeQueueStatusForClinicalChats(paidChat, conversationId, patientID, this.props.doctorDetail, this.props.doctorName, patientID, issue, category);
                    this.props.setSelectedItem(paidChat, patientID, this.props.doctorDetail.uid, conversationId, issue, category);
                    this.props.findPatientDetail(patientID);
                    this.props.sendDoctorFirstMessage(conversationId);
                    this.props.refreshMessages();
                    this.props.turnSelectChat(conversationId);
                    this.props.fetchAllMessages(conversationId, this.state.chatHistoryItemClick);
                    this.props.fetchChatTags(conversationId);
                    this.props.fetchUserChatHistory(patientID);
                    this.props.fetchStatus(conversationId);
                    this.props.updateClinicalDoctor(patientID, this.props.doctorDetail.doctorName);
                    this.setState({showUpView: false, skipClassStatus: false, followClassStatus: false})
                } else {
                    alert("Please turn on the Accept Patient.");
                }
            } else {
                alert("This account has been deactivated.");
            }
        } else {
            this.setState({open: true, clinicalChatData: chatData});
        }
    }, 1000);

    followUpSaveSuccess = () => {
        this.props.get_followUp("CLINICAL", this.props.doctorDetail.doctorName)


    }
    onChangeText = (event) => {
        if ((event.target.value).match('#')) {
            this.setState({showCannedResponseList: true});
            this.filterUsers((event.target.value.slice((event.target.value).search('#'), event.target.value.length)));
        }
        this.props.onChangeText(event.target.value);
    }

    onsendmessage = (textareaRef, patientId, doctorName) => {
        if (this.props.inputBarText.length > 0) {
            textareaRef.current.value = "";
            this.props.sendMessage(this.props.conversationId, {
                type: "text",
                content: this.props.inputBarText
            }, this.props.patientId, this.props.doctorName, this.props.doctorImage);
        }
    }
    searchFirebaseId = (id, followUp_id, index) => {
        this.props.set_notification(id, followUp_id, index)
        setTimeout(() => {
            this.setState({allFollowUp: this.props.all_followUp})
        }, 2000)

    }

    followStatus = (dateTime, diffDays, timeString, dateString) => {
        this.props.save_followUp(this.props.patientId, this.props.symptoms, this.props.doctorName, this.props.patientName, dateTime, this.props.email, this.props.gmtOffset, 'Clinical')
        this.props.setFollowUpStatus(this.props.conversationId)
        this.setState({followClassStatus: true, dateTime: dateTime, followUpDays: diffDays + 1})
        let message = `Your follow up has been set to ${dateString} at ${timeString} with ${this.props.doctorName}`
        this.props.sendMessage(this.props.conversationId, {
            type: "text",
            content: message
        }, this.props.patientId, this.props.doctorName, this.props.doctorImage);
    }

    filterUsers = (event) => {
        var keyword = event.toLowerCase();
        var filtered_cannedResponses = this.state.chats_canned_responses.filter((user) => {
            user = user.shortcut.toLowerCase();
            return user.indexOf(keyword) > -1;
        });
        if (filtered_cannedResponses.length !== 0) {
            this.setState({chats_canned_responses: filtered_cannedResponses});
        } else {
            this.setState({chats_canned_responses: this.props.chats_canned_responses});
        }
    }

    uploadImage = () => {
        var self = this;
        if (this.state.file !== '') {

            let changedName = this.state.file.name.replace(/ /gi, "_");
            const myNewFile = new File([this.state.file], changedName, {
                type: this.state.file.type,
            });


            //"myNewFile" has been used in place of "this.state.file" because setState was not working for it
            this.props.imageData(myNewFile, function (image_url) {
                if (image_url !== null) {
                    self.props.sendMessage(self.props.conversationId, {
                        type: self.state.filetype,
                        content: image_url
                    }, self.props.patientId, self.props.doctorName, self.props.doctorImage);
                    self.setState({showUploadingModal: false});
                }
                self.setState({showUploadingModal: false});
            });
        }
    }

    getFileExtension(filename) {
        return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
    }

    onAttachmentClick = (e) => {
        //console.log("e: ", e.current.click());
    }

    _handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            var type = file.type.split('/');
            this.setState({
                file: file,
                filetype: type[0],
                showUploadingModal: true
            });
        }
        reader.readAsDataURL(file)
    }

    closeChat = () => {
        if (!this.props.admin) {
            if (this.props.followUpStatus === true || this.props.skipStatus === true || this.state.skipClassStatus === true || this.state.followClassStatus === true) {
                if (window.confirm("Are you sure you want to close this chat?")) {
                    if (this.props.doctorIsValid || this.props.admin) {
                        if (this.props.videoChatId === this.props.conversationId && (this.state.videoState === true || this.state.audioState === true)) {
                            if (this.state.type === 'Video') {
                                this.stopVideoCall();
                            } else {
                                this.stopAudioCall();
                            }

                        }
                        this.userDetail.refreshCloseHistory();
                        this.setState((state) => ({
                            endTime: new Date().getTime(),
                            showOutPatientForm: true,
                            showUpView: false
                        }));
                    }
                }
            } else {
                if (window.confirm(`We dont see any follow up set for ${this.props.patientName}. Would you like to set a follow up?`)) {
                    this.setState({showUpView: true});
                } else {
                    this.setState({showUpView: true});
                }
            }
        } else {
            if (window.confirm("Are you sure you want to close this chat?")) {
                if (this.props.doctorIsValid || this.props.admin) {
                    if (this.props.videoChatId === this.props.conversationId && (this.state.videoState === true || this.state.audioState === true)) {
                        if (this.state.type === 'Video') {
                            this.stopVideoCall();
                        } else {
                            this.stopAudioCall();
                        }
                    }
                    this.userDetail.refreshCloseHistory();

                    this.setState((state) => ({endTime: new Date().getTime(), showOutPatientForm: true}));
                }
            }
        }
    }

    closeChatModal = () => {
        this.setState((state) => ({showOutPatientForm: false}));
    }

    openTagListModal = () => {
        this.setState({showTagsListModal: !this.state.showTagsListModal});
    }

    onChatHistoryItemClick = (historyIndex, category, issue) => {
        this.setState({chatHistoryItemClick: true, chatHistoryUid: historyIndex, chatHistoryCategory: issue})
    }

    loadCurrentChat = () => {
        this.props.fetchAllMessages(this.props.conversationId, this.state.chatHistoryItemClick);
        this.setState({chatHistoryItemClick: false})
    }

    onTagClick = (name, id, uid) => {
        if (this.props.tagChatList.length !== 0) {
            const found = this.props.tagChatList.some(item => item.id === uid);
            if (!found) {
                this.props.saveTagInChat(this.props.conversationId, {id: uid, name: name});
                this.setState({showTagsListModal: false});
            }
        } else {
            this.props.saveTagInChat(this.props.conversationId, {id: uid, name: name});
            this.setState({showTagsListModal: false});
        }
    }

    onRemoveTagClick = (name, id, uid) => {
        this.props.removeTagInChat(this.props.conversationId, {uid: uid});
    }

    onCannedResponseClick = (uid, short_cut, text) => {
        this.setState({
            showCannedResponseList: false,
            selectedCannedResponseText: text,
            selectedCannedResponseShortCut: short_cut
        });
    }

    onCannedResponseShow = () => {
        this.setState({
            showCannedResponseList: !this.state.showCannedResponseList,
            selectedCannedResponseText: '',
            selectedCannedResponseShortCut: ''
        });
    }

    selectedCannedResponse = (textareaRef, text, short_cut) => {
        var updatedString;
        this.setState({
            selectedCannedResponseText: '',
            selectedCannedResponseShortCut: '',
            chats_canned_responses: this.props.chats_canned_responses
        });
        if (this.props.inputBarText.search('#') !== -1) {
            var replaceText = (this.props.inputBarText.slice((this.props.inputBarText).search('#'), this.props.inputBarText.length));
            updatedString = (this.props.inputBarText).replace(replaceText, text);
        } else {
            updatedString = (this.props.inputBarText).concat(' ', text);
        }
        textareaRef.current.value = updatedString;
        this.props.onChangeText(updatedString);
    }

    initClient = () => {
        /*client.init("e816acbc1d8e4e4db0e2b99df7694527", function () {
        }, function (err) {
            //console.log("AgoraRTC client init failed", err);
        });

        this.subscribeToClient();
        client.enableAudioVolumeIndicator();*/
    };

    subscribeToClient = () => {
        /*let me = this;
        client.on("stream-added", me.onStreamAdded);
        client.on("stream-subscribed", me.onRemoteClientAdded);
        client.on("peer-leave", me.onPeerLeave);
        client.on("peer-join", me.onPeerJoin);
        client.on("volume-indicator", function (evt) {
            //console.log("Event", evt, evt.attr.length)
            if (evt.attr.length > 0) {
                me.setState({showPulse: true})
            } else {
                me.setState({showPulse: false})

            }
        });*/
    };

    onPeerJoin = evt => {
    }

    onPeerLeave = evt => {
        if (this.state.type === 'Video') {
            this.stopVideoCall();
        } else {
            this.stopAudioCall()
        }
    }

    onStreamAdded = evt => {
        /*let me = this;
        let stream = evt.stream;
        me.setState({remoteStreams: {...me.state.remoteStream, [stream.getId()]: stream}}, () => {
            this.props.saveRemoteStream(this.state.remoteStreams);
            client.subscribe(stream, function (err) {
                //console.log("Subscribe stream failed", err);
            });
        });*/
    };

    stopAudioCall = () => {
        /*this.localAudioStream.stop();
        client.leave();

        this.localAudioStream.close();
        this.props.endVideoCall(this.props.location);
        this.props.setVideoStatusFalse();
        this.props.refreshRemoteStream();

        this.setState({
            minimizeVideo: true,
            audMute: false,
            vidMute: false,
            remoteStreams: {},
            declineShown: false,
            audioState: false
        })*/
    }

    cancelMediaModal = () => {
        this.setState({showMediaModal: false})
    }

    stopVideoCall = () => {
        /*this.localStream.stop();
        client.leave();

        this.localStream.close();
        this.props.endVideoCall(this.props.location);
        this.props.setVideoStatusFalse();
        this.props.refreshRemoteStream();

        this.setState({
            minimizeVideo: true,
            audMute: false,
            vidMute: false,
            remoteStreams: {},
            declineShown: false,
            videoState: false
        })*/

    }

    joinChannel = () => {
        /*let me = this;
        client.join(null, String(this.props.patientId), USER_ID, function (uid) {
                client.publish(me.localStream, function (err) {
                    //console.log("Publish local stream error: " + err);
                });

                client.on("stream-published", function (evt) {
                });
            },
            function (err) {
                //console.log("Join channel failed", err);
            });*/
    };

    joinAudioChannel = () => {
        /*let me = this;
        client.join(null, String(this.props.patientId), USER_ID, function (uid) {
                client.publish(me.localAudioStream, function (err) {
                    //console.log("Publish local stream error: " + err);
                });

                client.on("stream-published", function (evt) {
                });
            },
            function (err) {
                //console.log("Join channel failed", err);
            });*/
    };

    onSendFollowUpRequest = (diffDays, timeString, dateString) => {
        let message = `I would like to follow up with you in ${diffDays} ${diffDays > 1 ? 'days' : 'day'}. Is ${dateString} at ${timeString} good for you?`
        this.props.sendMessage(this.props.conversationId, {
            type: "text",
            content: message
        }, this.props.patientId, this.props.doctorName, this.props.doctorImage);
    }

    onRemoteClientAdded = evt => {
        let me = this;
        let remoteStream = evt.stream;
        me.state.remoteStreams[remoteStream.getId()].play("agora_remote " + remoteStream.getId(), function (errState) {
            if (errState != null) {
                if (errState.video != null) {
                    if (errState.video.status != null) {
                        if (errState.video.status === 'paused') {
                            me.state.remoteStreams[remoteStream.getId()].play("agora_remote " + remoteStream.getId(), function (errState) {
                                //console.log("Error reviving stream", errState)
                            })
                        }
                    }
                }
            }
        });
    };

    maximizeVideo = () => {
        this.setState({minimizeVideo: false});
    }

    toggleAudio = () => {
        /*if (this.state.audMute === false) {
            this.localStream.muteAudio()
            this.setState({audMute: true});
        } else {
            this.localStream.unmuteAudio()
            this.setState({audMute: false});
        }*/
    }

    toggleVideo = () => {
        /*if (this.state.vidMute === false) {
            this.localStream.muteVideo();
            this.setState({vidMute: true});
        } else {
            this.localStream.unmuteVideo();
            this.setState({vidMute: false});
        }*/
    }

    toggleAudioChat = () => {
        /*if (this.state.audMute === false) {
            this.localAudioStream.muteAudio()
            this.setState({audMute: true});
        } else {
            this.localAudioStream.unmuteAudio()
            this.setState({audMute: false});
        }*/
    }

    handleClose = () => {
        this.setState({open: false});
    }

    TransferClinicalChatToYDO = () => {
        this.setState({actionPressed: true});
        var self = this;
        this.props.transferClinicalChatToYDOByAdmin(this.state.clinicalChatData, function (status) {
            self.setState({open: false, actionPressed: false});
        });
    }

    CloseChatByAdmin = () => {
        this.setState({actionPressed: true});
        var self = this;
        this.props.closeClinicalChatByAdmin(this.state.clinicalChatData, function (status) {
            self.setState({open: false, actionPressed: false});
        });
    }

    render() {

        var selectedconvoCount = _.find(this.props.unreadClinicalConvoCounts, ["conversationId", this.props.conversationId]);
        var selectedconvo = _.find(this.props.clinical_conversations, ["conversationId", this.props.conversationId]);
        if (this.props.buildNumber !== undefined && this.props.buildNumber != null) {
            var splitBuild = this.props.buildNumber.split('(');
            if (splitBuild.length > 1) {
                splitBuild = splitBuild[1].substring(0, splitBuild[1].length - 1);
                splitBuild = parseInt(splitBuild);
            }
        }
        let showChat = this.props.gender || this.props.city || this.props.country || this.props.platform || this.props.email || this.props.convoCount || this.props.buildNumber || this.props.medication;
        const {open, actionPressed} = this.state;
        return (
            <Aux>
                <div className={classes.top_bar}>
                    <div className={classes.header_doctor_name}>
                        <span>{this.props.doctorDetail.doctorName}</span>
                    </div>
                    <div className={classes.header_status}>
                        Status:
                        {this.props.acceptingChat && <span> Accepting Patients</span>}
                        {!this.props.acceptingChat &&
                        <span className={classes.notAcceptingChat}> Not Accepting Patients</span>}
                        {(this.props.doctorIsValid || this.props.admin) && showChat &&
                        <div className={classes.header_close} onClick={() => this.closeChat()}>
                            <span>End Encounter</span>
                        </div>
                        }
                    </div>
                </div>

                <div className={classes.main}>

                    <ClinicalConversationList
                        admin={this.props.admin}
                        conversations={this.props.clinical_conversations}
                        searchFirebaseId={(id, followUp_id, index) => this.searchFirebaseId(id, followUp_id, index)}
                        allFollowUp={this.state.allFollowUp}
                        allTime={this.props.all_time}

                        chatCounts={this.props.clinicalChatCounts}
                        unreadConvoCounts={this.props.unreadClinicalConvoCounts}
                        selectedConversation={this.props.conversationId}
                        chatHistoryItemClick={this.state.chatHistoryItemClick}
                        showMessages={(chatData) => this.showMessages(chatData)}
                        removedFromQueueAndshowMessages={(chatData) => this.removedFromQueueAndshowMessages(chatData)}
                    />
                    {this.state.minimizeVideo === true &&
                    <Fragment>

                        <ClinicalChatScreen
                            loading_messages={this.props.loading_messages}
                            doctorIsValid={this.props.doctorIsValid}
                            adminIsValid={this.props.admin}
                            closeChat={() => this.closeChat()}
                            videoState={this.state.videoState}
                            videoChat={() => this.showMediaModalPress}
                            audioChat={() => this.showMediaModalPress}
                            audioState={this.state.audioState}
                            maximizeVideo={() => this.maximizeVideo}
                            open={this.state.showTagsListModal}
                            tags={this.props.tags}
                            showCannedResponseList={this.state.showCannedResponseList}
                            canned_responses={this.state.chats_canned_responses}
                            selectedCannedResponseText={this.state.selectedCannedResponseText}
                            selectedCannedResponseShortCut={this.state.selectedCannedResponseShortCut}
                            onCannedResponseClick={(uid, short_cut, text) => this.onCannedResponseClick(uid, short_cut, text)}
                            onCannedResponseShow={() => this.onCannedResponseShow()}
                            selectedCannedResponse={(textareaRef, text, short_cut) => this.selectedCannedResponse(textareaRef, text, short_cut)}
                            onTagClick={(name, id, uid) => this.onTagClick(name, id, uid)}
                            openTagListModal={() => this.openTagListModal()}
                            selectedTags={this.props.tagChatList}
                            onRemoveTagClick={(name, id, uid) => this.onRemoveTagClick(name, id, uid)}
                            messages={this.state.chatHistoryItemClick === false ? this.props.messages : this.props.chatHistory[this.state.chatHistoryUid].messages}
                            patientName={this.props.patientName}
                            doctorName={this.props.doctorName}
                            doctorImage={this.props.doctorImage}
                            inputBarText={this.props.inputBarText}
                            onChangeText={(event) => this.onChangeText(event)}
                            onsendmessage={(textareaRef) => this.onsendmessage(textareaRef, this.props.patientId, this.props.doctorName)}
                            inputElement={createRef()}
                            textareaElement={createRef()}
                            onAttachmentClick={(file_input) => this.onAttachmentClick(file_input)}
                            _handleImageChange={(e) => this._handleImageChange(e)}
                            convoCount={this.props.convoCount}
                            city={this.props.city}
                            country={this.props.country}
                            complete_address={this.props.complete_address}
                            user_id={this.props.user_id}
                            platform={this.props.platform}
                            email={this.props.email}
                            gmtOffset={this.props.gmtOffset}
                            medication={this.props.medication}
                            symptoms={this.state.chatHistoryItemClick === false ? this.props.symptoms : this.state.chatHistoryCategory}
                            gender={this.props.gender}
                            DOB={this.props.DOB}
                            buildNumber={this.props.buildNumber}
                            acceptingChat={this.props.acceptingChat}
                            heartRate={this.props.heartRate}
                            weightKg={this.props.weightKg}
                            weightLbs={this.props.weightLbs}
                            stepCount={this.props.stepCount}
                            runningWalking={this.props.runningWalking}
                            heightCm={this.props.heightCm}
                            currentSleep={this.props.currentSleep}
                            averageSleep={this.props.averageSleep}
                            heightFeet={this.props.heightFeet}
                            periodStartDate={this.props.periodStartDate}
                            flowType={this.props.flowType}
                            restingHeartRate={this.props.restingHeartRate}
                        />
                    </Fragment>
                    }

                    {/*<div>
                        {Object.keys(this.state.remoteStreams).length === 0 && this.state.minimizeVideo === false &&
                        <Fragment>
                            <div className={this.state.type === 'Audio' ? classes.spinner1 : classes.spinner}>
                                <Spinner/>
                            </div>
                            <div className={classes.requestDiv}>Requesting patient to connect</div>
                            <div className={this.state.type === 'Video' ? classes.videoHeader22 : classes.videoHeader2}>
                                <div onClick={() => this.setState({minimizeVideo: true})}>
                                    <svg fill="white" width="15px" height="11px" viewBox="0 0 12 8">
                                        <path fill="inherit" fillRule="evenodd"
                                              d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                    </svg>
                                </div>
                                <div
                                    onClick={() => this.state.videoState === true ? this.toggleAudio() : this.toggleAudioChat()}>
                                    {this.state.audMute === false &&
                                    <FaMicrophone className={classes.microphone}/>
                                    }
                                    {this.state.audMute === true &&
                                    <FaMicrophoneSlash className={classes.microphone}/>
                                    }
                                </div>
                                <div
                                    onClick={() => this.state.videoState === true ? this.stopVideoCall() : this.stopAudioCall()}>
                                    <div onClick={() => this.setState({minimizeVideo: true})}>
                                        <FaPhoneSlash className={classes.microphone}/>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                        }
                        <Fragment>
                            <div
                                className={this.state.minimizeVideo ? classes.minimizeVideo : Object.keys(this.state.remoteStreams).length == 0 ? classes.minimizeVideo : classes.mainDiv}>
                                {Object.keys(this.state.remoteStreams).map(key => {
                                    let stream = this.state.remoteStreams[key];
                                    let streamId = stream.getId();
                                    return (
                                        <div key={streamId}
                                             id={`agora_remote ${streamId}`}
                                             className={this.state.minimizeVideo ? classes.minimizeVideo : classes.div1}>
                                            {this.state.type === 'Audio' &&
                                            <div style={{
                                                height: 180,
                                                width: 180,
                                                borderRadius: 100,
                                                backgroundColor: this.state.showPulse === true ? 'white' : 'white',
                                                marginLeft: 220,
                                                paddingTop: 1,
                                                marginTop: 220
                                            }}>
                                                <div style={{
                                                    height: 150,
                                                    width: 150,
                                                    borderRadius: 80,
                                                    backgroundColor: 'green',
                                                    alignSelf: 'center',
                                                    top: 60,
                                                    marginTop: 130,
                                                    marginLeft: 16,
                                                    marginTop: 14
                                                }}>
                                                    <span style={{
                                                        color: 'white',
                                                        display: 'flex',
                                                        paddingTop: 30,
                                                        paddingLeft: 50,
                                                        fontSize: 70
                                                    }}>{this.state.patientInitial.charAt(0)}</span>
                                                </div>
                                                {this.state.showPulse === true &&
                                                <div style={{
                                                    marginTop: 10,
                                                    marginLeft: 5
                                                }}>{this.state.patientInitial} is talking</div>
                                                }
                                            </div>
                                            }
                                        </div>
                                    )
                                })
                                }

                                <div id="agora_local"
                                     className={this.state.minimizeVideo ? classes.minimizeVideo : Object.keys(this.state.remoteStreams).length == 0 ? classes.minimizeVideo : classes.div2}></div>
                                <Fragment>
                                    <div
                                        className={this.state.minimizeVideo ? classes.minimizeVideo : Object.keys(this.state.remoteStreams).length == 0 ? classes.minimizeVideo : this.state.type === 'Video' ? classes.videoHeader11 : classes.videoHeader22}>
                                        <div onClick={() => this.setState({minimizeVideo: true})}>
                                            <svg fill="white" width="15px" height="11px" viewBox="0 0 12 8">
                                                <path fill="inherit" fillRule="evenodd"
                                                      d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                            </svg>
                                        </div>
                                        <div
                                            onClick={() => this.state.videoState === true ? this.toggleAudio() : this.toggleAudioChat()}>
                                            {this.state.audMute === false &&
                                            <FaMicrophone className={classes.microphone}/>
                                            }
                                            {this.state.audMute === true &&
                                            <FaMicrophoneSlash className={classes.microphone}/>
                                            }
                                        </div>
                                        <div
                                            onClick={() => this.state.videoState === true ? this.stopVideoCall() : this.stopAudioCall()}>
                                            <div onClick={() => this.setState({minimizeVideo: true})}>
                                                <FaPhoneSlash className={classes.microphone}/>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            </div>
                        </Fragment>


                    </div>*/}

                    <ClinicalPatientDetail
                        childRef={ref => (this.userDetail = ref)}
                        prev_chats={this.props.chatHistory}
                        selectedConversation={this.props.conversationId}
                        onSendFollowUpRequest={(diffDays, timeString, dateString) => this.onSendFollowUpRequest(diffDays, timeString, dateString)}
                        skipStatus={() => this.skipStatus()}
                        followStatus={(dateTime, diffDays, timeString, dateString) => this.followStatus(dateTime, diffDays, timeString, dateString)}
                        showUpView={this.state.showUpView}
                        doctorIsValid={this.props.doctorIsValid}
                        isLoading={this.props.isLoading}
                        maximizeVideo={() => this.maximizeVideo()}
                        videoState={this.state.videoState}
                        audioState={this.state.audioState}
                        minimizeVideo={this.state.minimizeVideo}
                        email={this.props.email}
                        DOB={this.props.DOB}
                        followUpSaveSuccess={() => this.followUpSaveSuccess()}

                        patient_profile={this.props.patientProfile}
                        closeChat={() => this.closeChat()}
                        showHistoryMessages={(uid) => this.showHistoryMessages(uid)}
                        heartRate={this.props.heartRate}
                        weightKg={this.props.weightKg}
                        weightLbs={this.props.weightLbs}
                        stepCount={this.props.stepCount}
                        runningWalking={this.props.runningWalking}
                        heightCm={this.props.heightCm}
                        currentSleep={this.props.currentSleep}
                        averageSleep={this.props.averageSleep}
                        patientName={this.props.patientName}
                        heightFeet={this.props.heightFeet}
                        periodStartDate={this.props.periodStartDate}
                        flowType={this.props.flowType}
                        restingHeartRate={this.props.restingHeartRate}
                        historyMessage={(historyIndex, category, issue) => this.onChatHistoryItemClick(historyIndex, category, issue)}
                        loadCurrentChat={this.loadCurrentChat}
                        setFlagTrue={this.props.setFlagTrue}
                        setFlagFalse={this.props.setFlagFalse}
                        unReadCountObject={selectedconvoCount}
                        user_id={this.props.user_id}
                        patientId={this.props.patientId}
                        patientFirebaseID={this.props.patientFirebaseID}
                        healthInsuranceCardFront={this.props.healthInsuranceCardFront}
                        healthInsuranceCardBack={this.props.healthInsuranceCardBack}
                        healthInsuranceCardNumber={this.props.healthInsuranceCardNumber}
                        complete_address={this.props.complete_address}
                        convoCount={this.props.convoCount}
                        gmtOffset={this.props.gmtOffset}
                        platform={this.props.platform}
                        buildNumber={this.props.buildNumber}
                        plan={this.props.plan}
                        subjective_symptoms={this.props.subjective_symptoms}
                        findings={this.props.findings}
                        assessment={this.props.assessment}
                        canned_responses={this.state.canned_responses}
                        canned_responses_assessment={this.props.canned_responses_assessment}
                        canned_responses_plan={this.props.canned_responses_plan}
                        setSubjectiveSymptoms={this.props.setSubjectiveSymptoms}
                        setFindings={this.props.setFindings}
                        setAssessment={this.props.setAssessment}
                        setPlan={this.props.setPlan}
                        showSOAPNotes={this.state.showSOAPNotes}
                    />

                    <OutPatientForm
                        show={this.state.showOutPatientForm}
                        hideModal={() => this.closeChatModal()}
                        followClassStatus={this.state.followClassStatus}
                        followUpStatus={this.props.followUpStatus}
                        patientName={this.props.patientName}
                        patientDOB={this.props.DOB}
                        doctorName={this.props.doctorName}
                        doctorImage={this.props.doctorImage}
                        clinicName={this.props.doctorDetail.clinicName}
                        signatureImageURL={this.props.signatureImageURL}
                        doctorlicense={this.props.doctorlicense}
                        clinicPhoneNumber={this.props.clinicPhoneNumber}
                        clinicAddress={this.props.clinicAddress}
                        start_timestamp={Boolean(selectedconvo) ? selectedconvo.start_timestamp : null}
                        endTime={this.state.endTime}
                        isVideoCall={this.state.showSOAPNotes}
                        followUpDays={this.state.followUpDays}
                        closeChatData={{
                            "paidChat": this.props.paidChat,
                            "conversationId": this.props.conversationId,
                            "doctorID": this.props.doctorID,
                            "admin": this.props.admin,
                            "doctorName": this.props.doctorName,
                            "patientName": this.props.patientName,
                            "patientId": this.props.patientId,
                            "selectedIssue": this.props.selectedIssue,
                            "selectedCategory": this.props.selectedCategory,
                            "tagChatList": this.props.tagChatList,
                            "heartRate": this.props.heartRate,
                            "weightKg": this.props.weightKg,
                            "weightLbs": this.props.weightLbs,
                            "stepCount": this.props.stepCount,
                            "runningWalking": this.props.runningWalking,
                            "heightCm": this.props.heightCm,
                            "currentSleep": this.props.currentSleep,
                            "averageSleep": this.props.averageSleep,
                            "heightFeet": this.props.heightFeet,
                            "periodStartDate": this.props.periodStartDate,
                            "flowType": this.props.flowType,
                            "restingHeartRate": this.props.restingHeartRate,
                            "complete_address": this.props.complete_address,
                        }}
                        healthInsuranceCardFront={this.props.healthInsuranceCardFront}
                        healthInsuranceCardBack={this.props.healthInsuranceCardBack}
                        healthInsuranceCardNumber={this.props.healthInsuranceCardNumber}
                        city={this.props.city}
                        province={this.props.province}
                        gender={this.props.gender}
                        postalcode={this.props.postalcode}

                        plan={this.props.plan}
                        subjective_symptoms={this.props.subjective_symptoms}
                        findings={this.props.findings}
                        assessment={this.props.assessment}
                        setSubjectiveSymptoms={this.props.setSubjectiveSymptoms}
                        setFindings={this.props.setFindings}
                        setAssessment={this.props.setAssessment}
                        setPlan={this.props.setPlan}
                        patient_profile={this.props.patientProfile}
                    />

                    <Modal show={this.state.showUploadingModal} modalClosed={this.onHideAddAgentModal}>
                        <div className={classes.modal}>
                            <Header/>
                            {this.props.uploadingImage &&
                            <ProgressBar/>
                            }
                            {!this.props.uploadingImage &&
                            <div className={classes.modalcontainer}>
                                <span className={classes.spandiv}>{this.state.file.name}</span>
                                <button
                                    className={classes.btn}
                                    type="button"
                                    onClick={() => this.uploadImage()}
                                >Upload
                                </button>
                            </div>
                            }
                        </div>
                    </Modal>

                    <Modal show={this.state.showMediaModal}>
                        <div className={classes.modal}>
                            <MediaHeader/>
                            <div className={classes.modalHeader}>Please select a media chat type</div>
                            <div style={{display: 'flex', flexDirection: 'row'}}>

                                {/* <button
                                    className={(this.props.platform === 'android' && splitBuild > 259) || (this.props.platform === 'ios' && splitBuild > 384) ? classes.btn1 : classes.btn1a}
                                    type="button"
                                    onClick={() => this.onVideoPress()}
                                >Video Conference</button> */}

                                {((this.props.platform === 'android' && splitBuild > 259) || (this.props.platform === 'ios' && splitBuild > 384)) &&
                                <button
                                    className={classes.btn2}
                                    type="button"
                                    onClick={() => this.onAudioPress()}
                                >Audio Only</button>
                                }

                                <button
                                    className={(this.props.platform === 'android' && splitBuild > 259) || (this.props.platform === 'ios' && splitBuild > 384) ? classes.btn3 : classes.btn3a}
                                    type="button"
                                    onClick={() => this.cancelMediaModal()}
                                >Cancel
                                </button>

                            </div>
                            {((this.props.platform === 'android' && splitBuild <= 259) || (this.props.platform === 'ios' && splitBuild <= 384)) &&
                            <div className={classes.error}>Audio Chat is not available for this patient as the patient
                                is on an older version of the app.</div>
                            }
                        </div>
                    </Modal>

                    <Dialog
                        open={open}
                        onClose={() => this.handleClose()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="customized-dialog-title" onClose={() => this.handleClose()}> Clinical Queued
                            Chats </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                You can't pick chat from queue as admin, but you can transfer the chat to YDO doctors or
                                close the chat.
                            </DialogContentText>
                        </DialogContent>
                        {!actionPressed &&
                        <DialogActions>
                            <Button onClick={() => this.TransferClinicalChatToYDO()} color="primary">
                                Transfer to YDO doctors
                            </Button>
                            <Button onClick={() => this.CloseChatByAdmin()} color="primary">
                                Close the chat
                            </Button>
                        </DialogActions>
                        }
                        {actionPressed &&
                        <div className={classes.spinner_view}>
                            <CircularProgress color="#85479f"/>
                        </div>
                        }
                    </Dialog>
                </div>
            </Aux>
        )
    }
}

const mapStateToProps = ({
                             clinicProfileSettingReducer,
                             homeReducer,
                             clinicalChatsReducer,
                             conversationReducer,
                             acceptingReducer,
                             chatReducer,
                             doctorReducer,
                             tagsReducer,
                             clinicalcannedResponseReducer
                         }) => {

    const {clinicId} = clinicProfileSettingReducer;

    const {admin} = homeReducer;
    const {loading, clinical_conversations, clinicalChatCounts, unreadClinicalConvoCounts} = clinicalChatsReducer;
    const {
        newMessage,
        deletedConversationKey,
        patientId,
        doctorIsValid,
        conversationId,
        paidChat,
        tagChatList,
        transfer_doctors,
        changedConversation,
        selectedIssue,
        selectedCategory,
        videoChatDeclineStatus,
        declinedDoctorId,
        videoStatus,
        remoteStreams,
        videoChatId,
        all_followUp,
        all_time,
        updateFollowUp
    } = conversationReducer;
    const {acceptingChat, changedDoctorKey} = acceptingReducer;
    const {
        loading_messages,
        messages,
        inputBarText,
        location,
        patientName,
        convoCount,
        buildNumber,
        city,
        province,
        postalcode,
        averageSleep,
        heartRate,
        heightFeet,
        currentSleep,
        weightKg,
        weightLbs,
        stepCount,
        runningWalking,
        heightCm,
        country,
        gmtOffset,
        DOB,
        email,
        medication,
        symptoms,
        gender,
        platform,
        doctorID,
        doctorName,
        doctorImage,
        currentUserID,
        uploadingImage,
        chatHistory,
        restingHeartRate,
        periodStartDate,
        flowType,
        complete_address,
        user_id,
        healthInsuranceCardFront,
        healthInsuranceCardBack,
        healthInsuranceCardNumber,
        patientFirebaseID,
        clinicPhoneNumber,
        signatureImageURL,
        doctorlicense,
        clinicAddress,
        patientProfile
    } = chatReducer;
    const {doctorDetail} = doctorReducer;
    const {tags, tag_value, followUpStatus, skipStatus, isLoading} = tagsReducer;
    const {
        chats_canned_responses, canned_responses, canned_responses_assessment,
        canned_responses_plan, subjective_symptoms, findings, assessment, plan
    } = clinicalcannedResponseReducer;

    return {
        clinicId,
        loading,
        clinical_conversations,
        clinicalChatCounts,
        newMessage,
        deletedConversationKey,
        patientId,
        updateFollowUp,
        doctorIsValid,
        conversationId,
        videoChatId,
        paidChat,
        tagChatList,
        transfer_doctors,
        admin,
        all_followUp,
        all_time,
        changedConversation,
        selectedIssue,
        selectedCategory,
        acceptingChat,
        isLoading,
        changedDoctorKey,
        loading_messages,
        messages,
        inputBarText,
        location,
        patientName,
        convoCount,
        buildNumber,
        city,
        averageSleep,
        heartRate,
        heightFeet,
        currentSleep,
        weightKg,
        weightLbs,
        stepCount,
        runningWalking,
        heightCm,
        country,
        province,
        postalcode,
        gmtOffset,
        DOB,
        email,
        medication,
        symptoms,
        gender,
        platform,
        doctorID,
        doctorName,
        doctorImage,
        patientProfile,
        currentUserID,
        uploadingImage,
        chatHistory,
        restingHeartRate,
        periodStartDate,
        flowType,
        doctorDetail,
        tags,
        tag_value,
        chats_canned_responses,
        canned_responses,
        canned_responses_assessment,
        canned_responses_plan,
        videoChatDeclineStatus,
        declinedDoctorId,
        videoStatus,
        remoteStreams,
        complete_address,
        user_id,
        healthInsuranceCardFront,
        healthInsuranceCardBack,
        healthInsuranceCardNumber,
        patientFirebaseID,
        unreadClinicalConvoCounts,
        followUpStatus,
        skipStatus,
        clinicPhoneNumber,
        signatureImageURL,
        doctorlicense,
        clinicAddress,
        subjective_symptoms,
        findings,
        assessment,
        plan
    };
};

export default connect(mapStateToProps, {
    fetchDoctorDetails,
    fetchTags,
    childChanged,
    childChangedDoctor,
    setVideoId,
    fetchCannedResponsesForChats,
    fetchCannedResponsesForObjectiveFindings,
    fetchCannedResponsesForAssessment,
    get_followUp,
    fetchCannedResponsesForPlan,
    getAcceptStatus,
    checkStatusAccept,
    refreshMessages,
    fetchAllMessages,
    fetchClinicalUnReadCount,
    refreshClinicalConversations,
    fetchClinicalChats,
    fetchClinicalConversationCount,
    setSelectedItem,
    findPatientDetail,
    turnSelectChat,
    changeQueueStatusForClinicalChats,
    setFlagTrue,
    setFlagFalse,
    sendDoctorFirstMessage,
    onChangeText,
    sendMessage,
    makeUnreadAllMessages,
    imageData,
    fetchUserChatHistory,
    fetchChatTags,
    saveTagInChat,
    removeTagInChat,
    setFollowUpStatus,
    setSkipStatus,
    save_followUp,
    fetchStatus,
    closeClinicalChatByAdmin,
    transferClinicalChatToYDOByAdmin,
    setVideoState,
    endVideoCall,
    setVideoStatusFalse,
    setVideoStatusTrue,
    saveRemoteStream,
    refreshRemoteStream,
    refreshDeclineStatus,
    setSubjectiveSymptoms,
    setFindings,
    setAssessment,
    setPlan,
    set_notification,
    updateClinicalDoctor,
    setAlgolia
})(ClinicalDoctors);
