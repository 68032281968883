import {
    EMAIL_CHANGE,
    FETCH_TICKET_DATA,
    FETCH_TICKETS,
    FETCH_TICKETS_AGENTS,
    MESSAGE_CHANGE,
    NAME_CHANGE,
    NEW_MESSAGE,
    SUBJECT_CHANGE,
    TICKET_FAIL,
    TICKET_SAVE
} from '../constants';

const INITIAL_STATE = {
    subject: '', name: '', email: '', message: '', error: '', tickets: [], ticketData: [],
    updateTicketMessage: [], updateTicketStatus: false, updateMessage: '', agentsData: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SUBJECT_CHANGE:
            return {...state, subject: action.payload};
        case NAME_CHANGE:
            return {...state, name: action.payload};
        case EMAIL_CHANGE:
            return {...state, email: action.payload};
        case MESSAGE_CHANGE:
            return {...state, message: action.payload};
        case TICKET_SAVE:
            return {
                ...state, subject: '', name: '', email: '', message: '', error: 'Ticket has been saved successfully',
                updateMessage: ''
            };
        case TICKET_FAIL:
            return {...state, error: action.payload};
        case FETCH_TICKETS:
            return {...state, tickets: action.payload};
        case FETCH_TICKET_DATA:
            return {
                ...state, ticketData: action.payload.ticket, updateTicketMessage: action.payload.messages,
                updateTicketStatus: action.payload.status
            };
        case NEW_MESSAGE:
            return {...state, updateMessage: action.payload};
        case FETCH_TICKETS_AGENTS:
            return {...state, agentsData: action.payload};
        default:
            return state;
    }
}
