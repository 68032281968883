import {
    AgoraVideoPlayer,
    createClient,
    createMicrophoneAndCameraTracks,
    createMicrophoneAudioTrack
} from "agora-rtc-react";
import {useEffect} from "react";
import Controls from "./Controls";
import styles from "./AgoraComponent.module.css";
import Spinner from "../../../components/Spinner";
import classes from "./AgoraComponent.module.css";
const microphone = require('../../../assets/microphone.png');
const muteMicrophone = require('../../../assets/mute-microphone.png');

const {useState} = require("react");

const useMicrophoneAudioTrack = createMicrophoneAudioTrack();

export const AudioCall = (props) => {
    const {setInCall, channelName, client, appId, token} = props;
    const [users, setUsers] = useState ([]);
    const [start, setStart] = useState(false);
    const [remoteMute, setRemoteMuteAudio] = useState(false);
    const [remoteConnectionState, setRemoteConnectionState] = useState ({audio: true, video: true});


    const {ready, track, error} = useMicrophoneAudioTrack();
    useEffect(()=>{
        console.log("agoraaa error ", error)
    },[error])
    useEffect(()=>{
        console.log("agoraaa remoteConnectionState ", remoteConnectionState)
    },[remoteConnectionState])
    useEffect(()=>{
        if(track){
            //track.play()
        }
    },[track])
    const init = async (name) => {
        try{
        console.log("init", name);
        client.on("user-published", async (user, mediaType) => {

            console.log("agoraaa user-published", user, mediaType);
            setRemoteConnectionState((prevState) => {
                let newState = {...prevState};
                newState[mediaType] = true;
                return newState;
            })
            await client.subscribe(user, mediaType);
            /*if (mediaType === "video") {
                setUsers((prevUsers) => {
                    return [...prevUsers, user];
                });
            }*/
            setUsers((prevUsers) => {
                let oldUsers = prevUsers.filter((User) => User.uid !== user.uid);
                return [...oldUsers, user];
            });
            setRemoteMuteAudio(false)
            if (mediaType === "audio") {
                //console.log("")
                if(user?.audioTrack)user.audioTrack.play()
                /*
                                        setUsers((prevUsers) => {
                                            return [...prevUsers, user];
                                        });*/

            }
        });

        client.on("user-unpublished", (user, type) => {
            console.log("agoraaa user-unpublished", user, type);
            setRemoteConnectionState((prevState) => {
                let newState = {...prevState};
                newState[type] = false;
                return newState;
            })
            if (type === "audio") {
                setRemoteMuteAudio(true);
                user.audioTrack?.stop();
                /* setUsers((prevUsers) => {
                    return prevUsers.filter((User) => User.uid !== user.uid);
                }); */

            }
            /*if (type === "video") {
                setUsers((prevUsers) => {
                    return prevUsers.filter((User) => User.uid !== user.uid);
                });
            }*/
        });

        client.on("user-left", (user) => {
            console.log("agoraaa  user-left", user);
            setRemoteConnectionState({audio: false, video: false})
            setUsers((prevUsers) => {
                return prevUsers.filter((User) => User.uid !== user.uid);
            });
        });

        await client.join(appId, channelName, token, props.account);

        if (track) await client.publish([track]);
        if (track) await track.setEnabled(true);
        setStart(true);
        }catch (e) {
            console.log("agoraaa init e", e)
        }

    };

    useEffect(() => {
        // function to initialise the SDK
        try{
            if (ready && track && !start) {
                console.log("agoraaa useEffect ready");
                init(channelName);
            }
        }catch (e) {
            console.log("agoraaa useEffect e", e)
        }


    }, [channelName, client, ready, track, start]);
    useEffect(()=>{
        console.log("agoraaa users ", users)
        console.log("agoraaa track ", track)
    },[users, track])
    return (
        <>
            {ready && track && (
                <div>
                    <div>

                        {start && track && users.length > 0?
                        users.map((user) => {
                            if (user) {

                                return (
                                    <div className={`vid ${styles.video_frame_black}`}><img
                                        className={classes.icon_image}
                                        src={remoteConnectionState.audio?microphone: muteMicrophone}
                                        alt=""
                                    /></div>
                                );
                            } else if(remoteMute) {
                                return <div className={`vid ${styles.video_frame_black}`}><img
                                    className={classes.icon_image}
                                    src={muteMicrophone}
                                    alt=""
                                /></div>
                            } else return <div className={`vid ${styles.video_frame_black}`}><Spinner  color={"white"}  isLoading={true}/></div>;
                        }):remoteMute? <div className={`vid ${styles.video_frame_black}`}><img
                                className={classes.icon_image}
                                src={muteMicrophone}
                                alt=""
                            /></div>: <div className={`vid ${styles.video_frame_black}`}><Spinner  color={"white"}  isLoading={true}/></div>}
                    </div>
                    <div>
                        <Controls track={track} setStart={setStart} setInCall={setInCall} client={client} type={"audioCall"}/>
                    </div>
                </div>
            )}

            {/*{start && track && <Videos users={users} track={track} />}*/}
        </>
    );

};
