
import classes from './settings.module.css';

const SettingList = (props) => {
    return (
        <div className={classes.chatsList}>
            <div className={classes.block}>
                <div className={classes.list_body}>
                    <div className={classes.list_header}>
                        <div className={classes.header_body}>
                            <div className={classes.header_text}>Settings</div>
                        </div>
                    </div>

                    <div className={classes.list}>
                        <div className={classes.list_container}>
                            <div className={classes.list_item} onClick={() => props.showCannedResponsesInfo()}>
                                <div className={classes.list_main}>
                                    <div
                                        className={props.showCannedResponsesItems ? classes.list_item_view_rotate : classes.list_item_view}
                                        width="12px" height="8px" fill="#a0a6ab">
                                        <svg fill="#a0a6ab" width="12px" height="8px" viewBox="0 0 12 8">
                                            <path fill="inherit" fillRule="evenodd"
                                                  d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className={classes.list_content}>
                                    <span>Canned Responses</span>
                                </div>
                            </div>
                            {props.showCannedResponsesItems &&
                            <div className={classes.list_item}>
                                <div className={classes.list_content_item}
                                     onClick={() => props.settingItemsClick('Canned Responses for Chats')}>
                                    <span>Chats</span>
                                </div>
                                <div className={classes.list_content_item}
                                     onClick={() => props.settingItemsClick('Canned Responses for Objective Finding')}>
                                    <span>Objective Findings</span>
                                </div>
                                <div className={classes.list_content_item}
                                     onClick={() => props.settingItemsClick('Canned Responses for Assessment')}>
                                    <span>Assessment</span>
                                </div>
                                <div className={classes.list_content_item}
                                     onClick={() => props.settingItemsClick('Canned Responses for Plan')}>
                                    <span>Plan</span>
                                </div>
                            </div>
                            }
                            <div className={classes.list_item} onClick={() => props.settingItemsClick('Billing Codes')}>
                                <div className={classes.list_main}>
                                    <div className={classes.list_item_view} width="12px" height="8px" fill="#a0a6ab">
                                        <svg fill="#a0a6ab" width="12px" height="8px" viewBox="0 0 12 8">
                                            <path fill="inherit" fillRule="evenodd"
                                                  d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className={classes.list_content}>
                                    <span>Billing Codes</span>
                                </div>
                            </div>
                            {props.userType === "admin" &&
                            <div className={classes.list_item} onClick={() => props.settingItemsClick('Billing')}>
                                <div className={classes.list_main}>
                                    <div className={classes.list_item_view} width="12px" height="8px" fill="#a0a6ab">
                                        <svg fill="#a0a6ab" width="12px" height="8px" viewBox="0 0 12 8">
                                            <path fill="inherit" fillRule="evenodd"
                                                  d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className={classes.list_content}>
                                    <span>Billing</span>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingList;
