
import classes from './UserStyle.module.css';

const UsersList = (props) => {
    return (
        <tr>
            <td className={classes.table_column_name_email}>
                <span className={classes.username_text}>{props.Name}</span>
            </td>
            <td className={classes.table_column_name_email}>
                <span className={classes.email_text}>{props.Email}</span>
            </td>
            <td className={classes.table_column}>
                <span className={classes.chatcount_text}>{Boolean(props.noOfChats) ? props.noOfChats : '0'}</span>
            </td>
            <td className={classes.table_column}>
                <span
                    className={props.Ban ? classes.not_accepting_chat : classes.accepting_chat}>{props.Ban ? 'banned' : 'active'}</span>
            </td>
            <td className={classes.table_column}>
                <span className={props.Ban ? classes.ban_button : classes.unban_button}
                      onClick={() => props.userState(props.Ban, props.userID)}>{props.Ban ? 'Unban Patient' : 'Ban Patient'}</span>
            </td>
        </tr>
    )
}

export default UsersList;
