import {CLINIC_ACCEPTING_CHAT} from '../../constants';

const INITIAL_STATE = {clinicAcceptingChat: false};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLINIC_ACCEPTING_CHAT:
            return {...state, clinicAcceptingChat: true};
        default:
            return state;
    }
}
