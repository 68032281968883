import  {Component} from 'react';
import {connect} from 'react-redux';
import {fetchClinicalDoctors, saveReportSetting} from '../../../../actions/ReportsAction';


import classes from '../../../../assets/classes.module.css';

import {sendInvites, sendUnFilledInvites} from '../../../../actions/clinicalPatientsActions';

var _ = require('lodash');
var moment = require('moment');

const dateFormats = [
    {format: 'YYYY-MM-DD', date: moment().format('YYYY-MM-DD')},
    {format: 'll', date: moment().format('ll')}, {format: 'LL', date: moment().format('LL')},
    {format: 'L', date: moment().format('L')}, {format: 'MMMM-D-YY', date: moment().format('MMMM-D-YY')},
    {format: 'M-D-YY', date: moment().format('M-D-YY')}, {format: 'M-D-YYYY', date: moment().format('M-D-YYYY')},
    {format: 'M-DD-YY', date: moment().format('M-DD-YY')}, {format: 'M-DD-YYYY', date: moment().format('M-DD-YYYY')},
    {format: 'MM-D-YY', date: moment().format('MM-D-YY')}, {format: 'MM-D-YYYY', date: moment().format('MM-D-YYYY')},
    {format: 'MM-DD-YY', date: moment().format('MM-DD-YY')}, {
        format: 'MM-DD-YYYY',
        date: moment().format('MM-DD-YYYY')
    },
    {format: 'MMM-D-YY', date: moment().format('MMM-D-YY')}, {
        format: 'MMM-D-YYYY',
        date: moment().format('MMM-D-YYYY')
    },
    {format: 'MMM-DD-YY', date: moment().format('MMM-DD-YY')}, {
        format: 'MMM-DD-YYYY',
        date: moment().format('MMM-DD-YYYY')
    },
    {format: 'MMMM-D-YYYY', date: moment().format('MMMM-D-YYYY')}, {
        format: 'MMMM-DD-YY',
        date: moment().format('MMMM-DD-YY')
    },
    {format: 'MMMM-DD-YYYY', date: moment().format('MMMM-DD-YYYY')}, {
        format: 'D-M-YY',
        date: moment().format('D-M-YY')
    },
    {format: 'D-M-YYYY', date: moment().format('D-M-YYYY')}, {format: 'DD-M-YY', date: moment().format('DD-M-YY')},
    {format: 'DD-M-YYYY', date: moment().format('DD-M-YYYY')}, {format: 'D-MM-YY', date: moment().format('D-MM-YY')},
    {format: 'D-MM-YYYY', date: moment().format('D-MM-YYYY')}, {format: 'DD-MM-YY', date: moment().format('DD-MM-YY')},
    {format: 'DD-MM-YYYY', date: moment().format('DD-MM-YYYY')}, {format: 'Do-M-YY', date: moment().format('Do-M-YY')},
    {format: 'Do-M-YYYY', date: moment().format('Do-M-YYYY')}, {format: 'Do-MM-YY', date: moment().format('Do-MM-YY')},
    {format: 'Do-MM-YYYY', date: moment().format('Do-MM-YYYY')}, {
        format: 'D-MMM-YY',
        date: moment().format('D-MMM-YY')
    },
    {format: 'D-MMM-YYYY', date: moment().format('D-MMM-YYYY')}, {
        format: 'D-MMMM-YYYY',
        date: moment().format('D-MMMM-YYYY')
    },
    {format: 'DD-MMMM-YY', date: moment().format('DD-MMMM-YY')}, {format: 'YY-M-D', date: moment().format('YY-M-D')},
    {format: 'DD-MMM-YY', date: moment().format('DD-MMM-YY')}, {
        format: 'DD-MMMM-YYYY',
        date: moment().format('DD-MMMM-YYYY')
    },
    {format: 'DD-MMM-YYYY', date: moment().format('DD-MMM-YYYY')}, {
        format: 'D-MMMMM-YY',
        date: moment().format('D-MMMMM-YY')
    },
    {format: 'Do-MMM-YY', date: moment().format('Do-MMM-YY')}, {
        format: 'D-MMMMM-YYYY',
        date: moment().format('D-MMMMM-YYYY')
    },
    {format: 'Do-MMM-YYYY', date: moment().format('Do-MMM-YYYY')}, {
        format: 'Do-MMMM-YY',
        date: moment().format('Do-MMMM-YY')
    },
    {format: 'D-MMMM-YY', date: moment().format('D-MMMM-YY')}, {
        format: 'Do-MMMM-YYYY',
        date: moment().format('Do-MMMM-YYYY')
    },
    {format: 'YYYY-M-D', date: moment().format('YYYY-M-D')}, {format: 'YY-M-Do', date: moment().format('YY-M-Do')},
    {format: 'YY-M-DD', date: moment().format('YY-M-DD')}, {format: 'YYYY-M-Do', date: moment().format('YYYY-M-Do')},
    {format: 'YYYY-M-DD', date: moment().format('YYYY-M-DD')}, {format: 'YY-MM-Do', date: moment().format('YY-MM-Do')},
    {format: 'YY-MM-D', date: moment().format('YY-MM-D')}, {format: 'YYYY-MM-Do', date: moment().format('YYYY-MM-Do')},
    {format: 'YYYY-MM-D', date: moment().format('YYYY-MM-D')}, {format: 'YY-MMM-D', date: moment().format('YY-MMM-D')},
    {format: 'YY-MM-DD', date: moment().format('YY-MM-DD')}, {
        format: 'YYYY-MMM-D',
        date: moment().format('YYYY-MMM-D')
    },
    {format: 'YY-MMM-DD', date: moment().format('YY-MMM-DD')}, {
        format: 'YYYY-MMMM-DD',
        date: moment().format('YYYY-MMMM-DD')
    },
    {format: 'YYYY-MMM-DD', date: moment().format('YYYY-MMM-DD')}, {
        format: 'YY-MMMMM-D',
        date: moment().format('YY-MMMMM-D')
    },
    {format: 'YY-MMM-Do', date: moment().format('YY-MMM-Do')}, {
        format: 'YYYY-MMMMM-D',
        date: moment().format('YYYY-MMMMM-D')
    },
    {format: 'YYYY-MMM-Do', date: moment().format('YYYY-MMM-Do')}, {
        format: 'YY-MMMM-Do',
        date: moment().format('YY-MMMM-Do')
    },
    {format: 'YY-MMMM-D', date: moment().format('YY-MMMM-D')}, {
        format: 'YYYY-MMMM-Do',
        date: moment().format('YYYY-MMMM-Do')
    },
    {format: 'YYYY-MMMM-D', date: moment().format('YYYY-MMMM-D')}, {
        format: 'YY-MMMM-DD',
        date: moment().format('YY-MMMM-DD')
    }

]

class Reports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeLink: 'reports',
            showError: false,
            showSuccess: false,
            displayMessage: '',
            mailDuration: (this.props.clinicData.mailDuration) ? this.props.clinicData.mailDuration : 'weekly',
            reportEmail: (this.props.clinicData.clinicReportEmailAddress) ? this.props.clinicData.clinicReportEmailAddress : '',
            DOBFormat: (this.props.clinicData.DOBFormat) ? this.props.clinicData.DOBFormat : 'YYYY-MM-DD',
            EncounterDateFormat: (this.props.clinicData.EncounterDateFormat) ? this.props.clinicData.EncounterDateFormat : 'YYYY-MM-DD',
            EHRClinicId: (this.props.clinicData.EHRClinicId) ? this.props.clinicData.EHRClinicId : '',
            EHRScannerId: (this.props.clinicData.EHRScannerId) ? this.props.clinicData.EHRScannerId : '',
            clinical_doctors: [],
            selectedDoctorid: 0,
            isloading: false
        }
    }

    componentDidMount() {
        this.props.fetchClinicalDoctors(this.props.clinicId, (clinical_doctors) => {
            var clinicaldoctors = [];
            for (let i = 0; i < clinical_doctors.length; i++) {
                let clinicaldoctor = clinical_doctors[i];
                if (!Boolean(clinicaldoctor.EHRDoctorID)) {
                    clinicaldoctor.EHRDoctorID = '';
                }
                clinicaldoctors.push(clinicaldoctor);
            }
            this.setState({clinical_doctors: clinicaldoctors});
        });
    }

    clearForm = () => {
        var clinicaldoctors = [];
        for (let i = 0; i < this.props.clinical_doctors; i++) {
            let clinicaldoctor = this.props.clinical_doctors[i];
            if (!Boolean(clinicaldoctor.EHRDoctorID)) {
                clinicaldoctor.EHRDoctorID = '';
            }
            clinicaldoctors.push(clinicaldoctor);
        }
        this.setState({
            mailDuration: (this.props.clinicData.weekly) ? this.props.clinicData.weekly : '',
            reportEmail: (this.props.clinicData.clinicReportEmailAddress) ? this.props.clinicData.clinicReportEmailAddress : '',
            DOBFormat: (this.props.clinicData.DOBFormat) ? this.props.clinicData.DOBFormat : 'YYYY-MM-DD',
            EncounterDateFormat: (this.props.clinicData.EncounterDateFormat) ? this.props.clinicData.EncounterDateFormat : 'YYYY-MM-DD',
            EHRClinicId: (this.props.clinicData.EHRClinicId) ? this.props.clinicData.EHRClinicId : '',
            EHRScannerId: (this.props.clinicData.EHRScannerId) ? this.props.clinicData.EHRScannerId : '',
            clinical_doctors: clinicaldoctors,
            selectedDoctorid: 0
        })
    }
    saveReportSetting = () => {
        var re = /\S+@\S+\.\S+/;
        this.setState({isloading: true})
        if (!re.test(this.state.reportEmail)) {
            this.setState({
                showError: true,
                showSuccess: false,
                displayMessage: "email address is not valid.",
                isloading: false
            });
        } else {
            this.props.saveReportSetting({
                previousMailDuration: this.props.clinicData.mailDuration,
                mailDuration: this.state.mailDuration,
                reportEmail: this.state.reportEmail,
                DOBFormat: this.state.DOBFormat,
                EncounterDateFormat: this.state.EncounterDateFormat,
                EHRClinicId: this.state.EHRClinicId,
                EHRScannerId: this.state.EHRScannerId,
                clinical_doctors: this.state.clinical_doctors,
                clinicId: this.props.clinicId
            }, (status, msg) => {
                if (status) {
                    this.setState({showSuccess: true, showError: false, displayMessage: msg, isloading: false})
                } else {
                    this.setState({showError: true, showSuccess: false, displayMessage: msg, isloading: false})
                }
            })
        }

    }

    render() {
        return (
            <div className={classes.main_container}>
                <div className={classes.main_container_header}>Reports of your encounters will be sent to you</div>
                <div className={classes.form_container_view}>
                    <div className={classes.form_row}>
                        <span>Email address for receiving your billing reports</span>
                    </div>
                    <div className={classes.form_row}>
                        <span className={classes.label}>Email Address:</span>
                        <input type='text' className={classes.form_input}
                               onChange={(e) => {
                                   this.setState({reportEmail: e.target.value})
                               }}
                               value={this.state.reportEmail}/>
                    </div>
                    <div className={classes.form_row}>
                        <span>Frequency on receiving billing reports of patient encounters:</span>
                    </div>
                    <div className={classes.form_row}>
                        <input type='radio' checked={this.state.mailDuration === 'daily'} onChange={() => {
                            this.setState({mailDuration: 'daily'})
                        }} className={classes.marginRight5}/>
                        <span>Daily</span>
                    </div>
                    <div className={classes.form_row}>
                        <input type='radio' checked={this.state.mailDuration === 'weekly'} onChange={() => {
                            this.setState({mailDuration: 'weekly'})
                        }} className={classes.marginRight5}/>
                        <span>Weekly</span>
                    </div>
                    <div className={classes.form_row}>
                        <input type='radio' checked={this.state.mailDuration === 'monthly'} onChange={() => {
                            this.setState({mailDuration: 'monthly'})
                        }} className={classes.marginRight5}/>
                        <span>Monthly</span>
                    </div>
                    <div className={classes.form_row}>
                        <span>Select the date format that should be represented in your reports.</span>
                    </div>
                    <div className={classes.form_row}>
                        <span className={classes.label}>Date of Birth:</span>
                        <select className={classes.form_input} onChange={(e) => {
                            this.setState({DOBFormat: e.target.value})
                        }}>
                            {dateFormats.map(o => <option key={o.format + '_dob'} value={o.format}>{o.date}</option>)}
                        </select>
                    </div>
                    <div className={classes.form_row}>
                        <span className={classes.label}>Encounter Date:</span>
                        <select className={classes.form_input} onChange={(e) => {
                            this.setState({EncounterDateFormat: e.target.value})
                        }}>
                            {dateFormats.map(o => <option key={o.format + '_enc_date'}
                                                          value={o.format}>{o.date}</option>)}
                        </select>

                    </div>
                    <div className={classes.form_row}>
                        <span>If you are using an EHR, you can assign the respective Clinic ID and a Doctor ID to be represented in your reports.</span>
                    </div>
                    <div className={classes.form_row}>
                        <span className={classes.label}>Clinic ID:</span>
                        <input type='text' className={classes.form_small_input}
                               onChange={(e) => {
                                   this.setState({EHRClinicId: e.target.value})
                               }}
                               value={this.state.EHRClinicId}/>
                    </div>
                    <div className={classes.form_row}>
                        <span className={classes.label}>Scanner ID:</span>
                        <input type='text' className={classes.form_input}
                               onChange={(e) => {
                                   this.setState({EHRScannerId: e.target.value})
                               }}
                               value={this.state.EHRScannerId}/>
                    </div>
                    <div className={classes.form_row}>
                        <span className={classes.label}>Staff Name:</span>
                        <select className={classes.form_small_input} onChange={(e) => {
                            let selectedDoctor = _.findIndex(this.state.clinical_doctors, ["uid", e.target.value]);
                            this.setState({selectedDoctorid: selectedDoctor})
                        }}>

                            {this.state.clinical_doctors.map(o => <option key={o.uid}
                                                                          value={o.uid}>{o.fullName}</option>)}
                        </select>

                        <span className={classes.label}>Doctor ID:</span>
                        <input type='text' className={classes.form_small_input}
                               onChange={(e) => {
                                   if (this.state.clinical_doctors.length > 0) {
                                       let clinicaldoctors = this.state.clinical_doctors;
                                       clinicaldoctors[this.state.selectedDoctorid].EHRDoctorID = e.target.value;
                                       this.setState({clinical_doctors: clinicaldoctors});
                                   }
                               }}
                               value={(this.state.clinical_doctors.length > 0) ? this.state.clinical_doctors[this.state.selectedDoctorid].EHRDoctorID : ''}/>
                    </div>


                    {this.state.showError &&
                    <div className={classes.center_view_error}><span>{this.state.displayMessage}</span></div>}
                    {this.state.showSuccess &&
                    <div className={classes.center_view}><span>{this.state.displayMessage}</span></div>}
                    <div className={classes.button_row}>
                        <button className={classes.unhiglighted_button} onClick={this.clearForm}>Cancel</button>
                        {!this.state.isloading &&
                        <button className={classes.higlighted_button} onClick={this.saveReportSetting}>Save</button>}
                        {this.state.isloading && <button className={classes.higlighted_button_centered}>
                            <div className={classes.loading_loader_sm}/>
                        </button>}

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({clinicProfileSettingReducer, reportReducer}) => {

    const {
        clinicId,
        clinic_name,
        clinicData,
        userType,
        clinic_email_address,
        mailDuration,
        DOBFormat,
        EncounterDateFormat,
        EHRClinicId,
        EHRScannerId
    } = clinicProfileSettingReducer;
    const {clinical_doctors} = reportReducer;

    return {
        clinicId,
        clinicData,
        userType,
        clinic_name,
        clinical_doctors,
        clinic_email_address,
        mailDuration,
        DOBFormat,
        EncounterDateFormat,
        EHRClinicId,
        EHRScannerId

    };
}

export default connect(mapStateToProps, {
    sendInvites, sendUnFilledInvites, fetchClinicalDoctors, saveReportSetting
})(Reports);
