import {FETCH_PROFILE} from '../constants';
import {auth, database} from "../firebase";


export const fetchProfile = () => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        getDoctortRef(currentUserID).on('value', doctorSnap => {
            if (doctorSnap.val()) {
                dispatch({
                    type: FETCH_PROFILE,
                    payload: {doctor: doctorSnap.val(), uid: currentUserID}
                })
            }
        })
    }
}

const getDoctortRef = (uid) => {
    return database.ref(`/doctors/${uid}`);
}
