import {
    ACCOUNT_TYPE,
    ADD_MORE_USERS_INTO_STAFF,
    ADMIN_PROFILE,
    ANSWER_PATIENTS,
    CREATE_PRESCRIPTIONS,
    CUSTOM_PROFILE,
    FETCH_STAFF_MEMBERS,
    GET_REPORTS,
    INVITE_PATIENTS,
    INVITE_STAFF_FAILED,
    INVITE_STAFF_PROCESS,
    INVITE_STAFF_SUCCESS,
    NEED_STAFF_ADDRESS,
    NURSE_PROFILE,
    OWNER_PROFILE,
    PHYSICIAN_PROFILE,
    RECEPTIONIST_PROFILE,
    STAFF_ADDRESS,
    STAFF_CITY,
    STAFF_COUNTRY,
    STAFF_EMAIL_ADDRESS,
    STAFF_FAX_NUMBER,
    STAFF_FULL_NAME,
    STAFF_LICENSE_NO,
    STAFF_MEMEBER_PHONE_NUMBER,
    STAFF_PHONE,
    STAFF_PROFILE_IMAGE,
    STAFF_PROVINCE,
    STAFF_SIGNATURE,
    STAFF_SOAP_NOTES,
    TRANSFER_CHAT,
    VIEW_ARCHIVES,
    VIEW_INSURANCE_INFO,
    VIEW_PATIENTS_IN_QUEUE
} from '../constants';


import _ from 'lodash';
import {auth, database, functions} from "../firebase";

export const staffEmail = (email) => {
    return {
        type: STAFF_EMAIL_ADDRESS,
        payload: email
    }
}

export const staffProfileOwner = (profile) => {
    return {
        type: OWNER_PROFILE,
        payload: profile
    }
}

export const staffProfileAdmin = (profile) => {
    return {
        type: ADMIN_PROFILE,
        payload: profile
    }
}

export const staffProfilePhysician = (profile) => {
    return {
        type: PHYSICIAN_PROFILE,
        payload: profile
    }
}

export const staffProfileNurse = (profile) => {
    return {
        type: NURSE_PROFILE,
        payload: profile
    }
}

export const staffProfileReceptionist = (profile) => {
    return {
        type: RECEPTIONIST_PROFILE,
        payload: profile
    }
}

export const staffProfileCustom = (profile) => {
    return {
        type: CUSTOM_PROFILE,
        payload: profile
    }
}

export const addMoreUserIntoStaff = (type) => {
    return {
        type: ADD_MORE_USERS_INTO_STAFF,
        payload: type
    }
}

export const createPrescription = (type) => {
    return {
        type: CREATE_PRESCRIPTIONS,
        payload: type
    }
}

export const invitePatients = (type) => {
    return {
        type: INVITE_PATIENTS,
        payload: type
    }
}

export const soapNotes = (type) => {
    return {
        type: STAFF_SOAP_NOTES,
        payload: type
    }
}

export const anwserPatients = (type) => {
    return {
        type: ANSWER_PATIENTS,
        payload: type
    }
}

export const transferChat = (type) => {
    return {
        type: TRANSFER_CHAT,
        payload: type
    }
}

export const viewPatientsInQueue = (type) => {
    return {
        type: VIEW_PATIENTS_IN_QUEUE,
        payload: type
    }
}

export const viewArchives = (type) => {
    return {
        type: VIEW_ARCHIVES,
        payload: type
    }
}

export const viewInsuranceInfo = (type) => {
    return {
        type: VIEW_INSURANCE_INFO,
        payload: type
    }
}

export const getReports = (type) => {
    return {
        type: GET_REPORTS,
        payload: type
    }
}

export const accountType = (type) => {
    return {
        type: ACCOUNT_TYPE,
        payload: type
    }
}

export const staffFullName = (name) => {
    return {
        type: STAFF_FULL_NAME,
        payload: name
    }
}

export const staffPhone = (phone) => {
    return {
        type: STAFF_PHONE,
        payload: phone
    }
}

export const staffLicenseNo = (license_no) => {
    return {
        type: STAFF_LICENSE_NO,
        payload: license_no
    }
}

export const showStaffProfileImage = (image) => {
    return {
        type: STAFF_PROFILE_IMAGE,
        payload: image
    }
}

export const showStaffSignature = (image) => {
    return {
        type: STAFF_SIGNATURE,
        payload: image
    }
}

export const needStaffAddress = (address) => {
    return {
        type: NEED_STAFF_ADDRESS,
        payload: address,
    }
}

export const staffAddress = (address) => {
    return {
        type: STAFF_ADDRESS,
        payload: address,
    }
}

export const staffCountry = (country) => {
    return {
        type: STAFF_COUNTRY,
        payload: country,
    }
}

export const staffProvince = (province) => {
    return {
        type: STAFF_PROVINCE,
        payload: province,
    }
}

export const staffCity = (city) => {
    return {
        type: STAFF_CITY,
        payload: city
    }
}

export const staffPhoneNumber = (phone) => {
    return {
        type: STAFF_MEMEBER_PHONE_NUMBER,
        payload: phone
    }
}

export const staffFaxNo = (fax) => {
    return {
        type: STAFF_FAX_NUMBER,
        payload: fax
    }
}

export const addStaffMember = (clinic_ID, {
                                   clinicName, clinicAddress, clinicCity, clinicProvince, clinicCountry, clinicPhoneNumber, clinicFaxNumber
                               },
                               {
                                   staff_email,
                                   staff_profile,
                                   add_more_users,
                                   create_prescription,
                                   invite_patients,
                                   soap_notes,
                                   account_type,
                                   ans_patients,
                                   transfer_chat,
                                   view_patients_in_queue,
                                   view_archives,
                                   view_insurance_info,
                                   get_reports,
                                   full_name,
                                   phone,
                                   license_no,
                                   profile_image,
                                   signature,
                                   need_staff_address,
                                   staff_address,
                                   staff_country,
                                   staff_province,
                                   staff_city,
                                   staff_phone_number,
                                   staff_fax_no
                               }) => {
    return (dispatch) => {
        dispatch({type: INVITE_STAFF_PROCESS});

        const createStaffMemberByAdmin = functions.httpsCallable('createStaffMemberByAdmin');
        createStaffMemberByAdmin({
            email: staff_email,
        })
            .then(admin => {
                if (admin.data.id === 2) {

                    const addStaff = functions.httpsCallable('addStaff');
                    addStaff({
                        uid: admin.data.user.uid,
                        staffProfile: staff_profile,
                        clinicID: clinic_ID,
                    })
                        .then(staff => {
                            if (staff.data.id === 3) {
                                var staffData = {
                                    staffEmail: staff_email,
                                    staffProfile: staff_profile,
                                    addMoreUsers: add_more_users,
                                    createPrescription: create_prescription,
                                    invitePatients: invite_patients,
                                    soapNotes: soap_notes,
                                    accountType: account_type,
                                    ansPatients: ans_patients,
                                    transferChat: transfer_chat,
                                    viewPatientsInQueue: view_patients_in_queue,
                                    viewArchives: view_archives,
                                    viewInsuranceInfo: view_insurance_info,
                                    getReports: get_reports,
                                    fullName: full_name,
                                    phoneNumber: phone,
                                    licenseNo: license_no,
                                    profileImageURL: profile_image,
                                    signatureURL: signature,
                                    clinicID: clinic_ID,
                                    needStaffAddress: !need_staff_address,
                                    staffAddress: !need_staff_address ? staff_address : clinicAddress,
                                    staffCity: !need_staff_address ? staff_city : clinicCity,
                                    staffProvince: !need_staff_address ? staff_province : clinicProvince,
                                    staffCountry: !need_staff_address ? staff_country : clinicCountry,
                                    staffPhone: !need_staff_address ? staff_phone_number : clinicPhoneNumber,
                                    staffFaxNo: !need_staff_address ? staff_fax_no : clinicFaxNumber,
                                };

                                var updates = {};
                                updates[`clinicStaffMember/${clinic_ID}/${admin.data.user.uid}`] = staffData;
                                database.ref().update(updates)
                                    .then(() => {

                                        const sendEmailToStaffMember = functions.httpsCallable('sendEmailToStaffMember');
                                        sendEmailToStaffMember({
                                            staffMemberName: full_name,
                                            staffEmail: staff_email,
                                            staffPassword: admin.data.password,
                                            clinicName: clinicName,
                                        }).then(result => {
                                            dispatch({
                                                type: INVITE_STAFF_SUCCESS,
                                                payload: `Your staff has been created successfully and an invitational email has been sent.`
                                            })
                                        })
                                            .catch((error) => {
                                                dispatch({
                                                    type: INVITE_STAFF_FAILED,
                                                    payload: error.message
                                                })
                                            })
                                    })
                                    .catch((error) => {
                                        dispatch({
                                            type: INVITE_STAFF_FAILED,
                                            payload: error.message
                                        })
                                    })
                            }
                        })
                } else if (admin.data.id === -1) {
                    dispatch({
                        type: INVITE_STAFF_FAILED,
                        payload: admin.data.error.errorInfo.message
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: INVITE_STAFF_FAILED,
                    payload: error.message,
                })
            })
    }
}

const logout = (clinicID, userID, convos) => {


    if (Boolean(convos.length)) {
        alert("Please close your assigned chats")
        //console.log('convo.val(): ', convos);
        // dispatch({type:'',payload:''});

    } else {
        var updates = {};
        updates[`/clinicStaffMember/${clinicID}/${userID}/online`] = false;

        database.ref().update(updates)
        auth.signOut()
            .then(data => {
                //console.log("sign out successfully...");
            })
            .catch(error => {
                //console.log("sign out failed");
            })
    }

}

export const logoutClinicMember = (clinicID, userID) => {
    return (dispatch, getState) => {
        const {acceptingChat} = getState().acceptingReducer;
        if (acceptingChat) {
            alert("Kindly Turn off Your Accepting Patient");
            return;
        }
        let convos = [];
        database.ref('openClinicalConversations/' + clinicID).once('value', (snapshot) => {
            if (snapshot.exists()) {
                convos = Object.values(snapshot.val()).filter(convo => {
                    if (convo.doctorID === userID && Boolean(convo.status) && Boolean(convo.appActivated)) {
                        //console.log('convo.val(): ', convo);
                        return true;
                    }
                })
            }
            logout(clinicID, userID, convos)

        })
    }
}

export const fetchStaffMembers = (ID) => {
    return (dispatch) => {
        getStaff(ID).on('value', staffSnapshot => {
            if (staffSnapshot.val()) {
                const staff = _.map(staffSnapshot.val(), (val, uid) => {
                    return {...val, uid};
                });
                dispatch({
                    type: FETCH_STAFF_MEMBERS,
                    payload: staff
                })
            } else {
                dispatch({
                    type: FETCH_STAFF_MEMBERS,
                    payload: []
                })
            }
        })
    }
}

const getStaff = (uid) => {
    return database.ref(`/clinicStaffMember/${uid}`);
}
