import {
    CANNED_RESPONSE_DELETE,
    CANNED_RESPONSE_DESCRIPTION,
    CANNED_RESPONSE_SAVE,
    CANNED_RESPONSE_SHORT_CUT,
    CANNED_RESPONSE_UPDATE,
    FETCH_CANNED_RESPONSE,
    SET_SUBJECTIVE_SYMPTOMS,
    SET_ASSESSMENT,
    SET_FINDINGS,
    SET_PLAN, REFRESH_CANNED_STATUS,
} from '../constants';

import _ from 'lodash';
import {database} from "../firebase";
let callbackSymptoms = null, callbackFindings = null, callbackAssesment = null, callbackPlan = null, cannedResponse = null

export const canned_response_description_changed = (text) => {
    return {
        type: CANNED_RESPONSE_DESCRIPTION,
        payload: text
    }
}

export const resetCannedResponseListeners = () => {
    return (dispatch) => {
        if(cannedResponse){
            cannedResponse.off()
            cannedResponse = null
        }
        if (callbackSymptoms) {
            callbackSymptoms.off();
            callbackSymptoms = null;
        }
        if (callbackFindings) {
            callbackFindings.off();
            callbackFindings = null
        }
        if (callbackAssesment) {
            callbackAssesment.off()
            callbackAssesment = null
        }
        if (callbackPlan) {
            callbackPlan.off()
            callbackPlan = null
        }
        return dispatch({type: REFRESH_CANNED_STATUS});

    }
}
export const canned_response_shortcut_changed = (text) => {
    return {
        type: CANNED_RESPONSE_SHORT_CUT,
        payload: text
    }
}

export const canned_response_save = (text, shortcut) => {
    return (dispatch) => {
        let cannedResponseValue = {
            text: text,
            shortcut: shortcut,
        }
        let cannedresponsekey = getCannedResponseRef().push().key;
        var updates = {};
        updates[`/canned_responses/${cannedresponsekey}`] = cannedResponseValue;
        database.ref().update(updates)
            .then(() => {
                return dispatch({
                    type: CANNED_RESPONSE_SAVE
                })
            })
            .catch((error) => {
                return dispatch({
                    type: CANNED_RESPONSE_SAVE
                })
            })
    }
}

export const fetchCannedResponses = () => {
    return (dispatch) => {
        try {
            if(cannedResponse){
                cannedResponse.off()
                cannedResponse = null
            }
            cannedResponse = getCannedResponseRef()
            cannedResponse.on('value', cannedResponsesSnap => {
                if (cannedResponsesSnap.val()) {
                    const cannedresponses = _.map(cannedResponsesSnap.val(), (val, uid) => {
                        val.shortcut = '#' + (val.shortcut);
                        return {...val, uid};
                    });

                    return dispatch({
                        type: FETCH_CANNED_RESPONSE,
                        payload: cannedresponses
                    })
                } else {
                    return dispatch({
                        type: FETCH_CANNED_RESPONSE,
                        payload: []
                    })
                }
            })
        } catch (error) {
            console.log("fetchCannedResponses error ", error);
        }

    }
}

export const updateCannedResponse = (uid, desc, shortcut, callback) => {
    return (dispatch) => {
        let cannedResponseValue = {
            text: desc,
            shortcut: shortcut,
        }
        getCannedResponseUidRef(uid).update(cannedResponseValue)
            .then(() => {
                callback(true);
                return dispatch({
                    type: CANNED_RESPONSE_UPDATE
                })
            })
            .catch((error) => {
                callback(false);
                return dispatch({
                    type: CANNED_RESPONSE_UPDATE
                })
            })

    }
}

export const deleteCannedResponse = (uid) => {
    return (dispatch) => {
        getCannedResponseUidRef(uid).remove()
            .then(() => {
                return dispatch({
                    type: CANNED_RESPONSE_DELETE
                })
            })
            .catch((error) => {
                return dispatch({
                    type: CANNED_RESPONSE_DELETE
                })
            })
    }
}


export const setSubjectiveSymptoms = (conversationID) => {
    return dispatch => {
        if (callbackSymptoms) {
            callbackSymptoms.off();
            callbackSymptoms = null;
        }
        callbackSymptoms = getOpenConversationsSymptomsWithLocationRef(
            conversationID
        );
        callbackSymptoms.on("value", snapshot => {
            let Symptoms = ""
            console.log("getOpenConversationsSymptomsWithLocationRef snapshot ", snapshot.val())

            if (snapshot.val()) {
                let Symptoms = snapshot.val()
                return dispatch({
                    type: SET_SUBJECTIVE_SYMPTOMS,
                    payload: Symptoms,
                });
            } else {
                return dispatch({
                    type: SET_SUBJECTIVE_SYMPTOMS,
                    payload: Symptoms,
                });
            }
            // console.log("fetchChat symptoms", Symptoms)
        });
    }
}

export const setFindings = (conversationID) => {
    return dispatch => {
        if (callbackFindings) {
            callbackFindings.off();
            callbackFindings = null
        }
        callbackFindings = getOpenConversationsFindingsWithLocationRef(
            conversationID
        );
        callbackFindings.on("value", snapshot => {
            let Findings = ""
            console.log("getOpenConversationsFindingsWithLocationRef snapshot ", snapshot.val())

            if (snapshot.val()) {
                let Findings = snapshot.val()
                return dispatch({
                    type: SET_FINDINGS,
                    payload: Findings,
                });
            } else {
                return dispatch({
                    type: SET_FINDINGS,
                    payload: Findings,
                });
            }
            // console.log("fetchChat findings", Findings)
        });
    }
}

export const setAssessment = (conversationID) => {
    return dispatch => {
        if (callbackAssesment) {
            callbackAssesment.off()
            callbackAssesment = null
        }
        callbackAssesment = getOpenConversationsAssesmentWithLocationRef(conversationID);
        callbackAssesment.on("value", snapshot => {
            let Assesment = ""
            console.log("getOpenConversationsAssesmentWithLocationRef snapshot ", snapshot.val())

            if (snapshot.val()) {
                let Assesment = snapshot.val()
                return dispatch({
                    type: SET_ASSESSMENT,
                    payload: Assesment,
                });
            } else {
                return dispatch({
                    type: SET_ASSESSMENT,
                    payload: Assesment,
                });
            }
            // console.log("fetchChat assesment", Assesment)
        });
    }
}

export const setPlan = (conversationID) => {
    return dispatch => {
        if (callbackPlan) {
            callbackPlan.off()
            callbackPlan = null
        }
        callbackPlan = getOpenConversationsPlanWithLocationRef(conversationID);
        callbackPlan.on("value", snapshot => {
            let Plan = ""
            console.log("getOpenConversationsPlanWithLocationRef snapshot ", snapshot.val())

            if (snapshot.val()) {
                let Plan = snapshot.val()
                return dispatch({
                    type: SET_PLAN,
                    payload: Plan,
                });
            } else {
                return dispatch({
                    type: SET_PLAN,
                    payload: Plan,
                });
            }
            // console.log("fetchChat plan ", Plan)
        });
    }
}

const getOpenConversationsSymptomsWithLocationRef = (uid) => {
    return database.ref(`/openconversations/${uid}/symptoms`);
};

const getOpenConversationsFindingsWithLocationRef = (uid) => {
    return database.ref(`/openconversations/${uid}/findings`);
};

const getOpenConversationsAssesmentWithLocationRef = (uid) => {
    return database.ref(`/openconversations/${uid}/assessment`);
};

const getOpenConversationsPlanWithLocationRef = (uid) => {
    return database.ref(`/openconversations/${uid}/plan`);
};

const getCannedResponseRef = () => {
    return database.ref().child('canned_responses');
}

const getCannedResponseUidRef = (uid) => {
    return database.ref(`/canned_responses/${uid}`);
}
