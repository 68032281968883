import  {Component} from 'react';
import {PDFViewer} from '@react-pdf/renderer';
import MyDocument from '../containers/clinicalSide/encounters/CreatePdf';
//import { connect } from 'react-redux';

var moment = require("moment");

class PdfBuilder extends Component {
    constructor(props) {
        super(props);
        /* this.handleSubmit = this.handleSubmit.bind(this);
        this.setName = this.setName.bind(this);
        this.setEmail = this.setEmail.bind(this);
        this.setBirthDay = this.setBirthDay.bind(this); */
        this.state = {
            name: props.name || "",
            email: props.email || "",
            birthDate: props.birthDate || ""
        };
    }

    UNSAFE_componentWillMount = () => {
        //this.props.fetchProfile();
    }

    render() {
        let medicationObject = this.props.user_medications && this.props.user_medications[this.props.selectedConversation || this.props.conversationId]
        let medications = medicationObject?.data || []
        return (
            <PDFViewer style={{height: "100%", width: "100%", position: 'absolute'}}>
                <MyDocument
                    {...this.props}
                    plan={this.props.plan}
                    assessment={this.props.assessment}
                    findings={this.props.findings}
                    icd={this.props.icd}
                    patient_issue={this.props.patient_issue}
                    patientName={this.props.patientName}
                    patientDOB={this.props.patientDOB}
                    doctorName={this.props.doctorName}
                    doctorImage={this.props.doctorImage}
                    signatureImageURL={this.props.signatureImageURL}
                    doctorlicense={this.props.doctorlicense}
                    clinicPhoneNumber={this.props.clinicPhoneNumber}
                    clinicFaxNumber={this.props.clinicFaxNumber}
                    clinicAddress={this.props.clinicAddress}
                    familyProblems={this.props.familyProblems}
                    healthProblems={this.props.healthProblems}
                    patient_profile={this.props.patient_profile}
                    video_consult={this.props.video_consult}
                    special_referals={this.props.special_referals}
                    requisitions={this.props.requisitions}
                    prescriptions={this.props.prescriptions}
                    clinicName={this.props.clinicName}
                    followuptext={this.props.followuptext}
                    endTime={this.props.endTime}
                    encounter_duration={this.props.encounter_duration}
                    start_timestamp={this.props.start_timestamp}
                    user_medications={medications}
                    clinicPostalCode={this.props.clinicPostalCode}
                    currentDoctorProfile={this.props.currentDoctorProfile}
                />
            </PDFViewer>
        )
    }

}

/* const mapStateToProps = ({ clinicalcannedResponseReducer, billingcodesReducer, outPatientFormReducer,profileReducer }) => {
    const { profile_data, uid } = profileReducer;
    const { loading, message, error } = outPatientFormReducer;
    const { canned_responses } = clinicalcannedResponseReducer;
    const { billing_codes } = billingcodesReducer;
    return { loading, message, error, canned_responses, billing_codes, profile_data, uid };
}

export default connect(mapStateToProps, {fetchProfile})(PdfBuilder); */
export default PdfBuilder;
