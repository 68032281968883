import  {Component} from 'react';

import Aux from '../../hoc/hocAux';
import {Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

import {connect} from 'react-redux';
import classes from './ReportsBuilder.module.css';

import {
    dailyReport,
    fetchDoctorReports,
    fetchDoctors,
    fetchReportsByDate,
    fetchReportsByFirebaseAndGroupByDate
} from '../../actions/ReportsAction';
import DateRangePicker from 'react-bootstrap-daterangepicker';

function getDate(timestamp) {

    // eslint-disable-next-line no-array-constructor
    var week = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var dateObj = new Date(timestamp);
    var month = week[dateObj.getUTCMonth()]; //months from 1-12
    var day = dateObj.getUTCDate();

    var newdate = month + " " + day;
    return newdate;
}

function getTime(seconds) {
    var hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    var minutes = Math.floor(seconds / 60);
    return hours + ' hr ' + minutes + ' mins';
}

class ReportsBuilder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            focusedInput: null,
            show: false,
            showList: false,
            selectedDoctor: null,
        };
    }

    UNSAFE_componentWillMount() {
        this.props.dailyReport();
        this.props.fetchDoctors();
    }

    showListView = () => {
        this.setState({showList: !this.state.showList});
    }

    hideAll = () => {
        this.setState({showList: false, show: false}, () => {
            //console.log("report data: ", this.props.reports_data);
            //console.log("selectedDoctor data: ", this.state.selectedDoctor);
            //console.log("startDate data: ", this.state.startDate);
            //console.log("endDate data: ", this.state.endDate);
            if (this.props.reports_data.length === 0 && this.state.selectedDoctor === null &&
                this.state.startDate === null && this.state.endDate === null) {
                this.props.dailyReport();
            }
        });
    }

    showDocotrosList = () => {
        this.setState({show: !this.state.show});
    }

    onlistClick = (doctor) => {
        //console.log("doctor: ", doctor);
        this.setState({show: !this.state.show, selectedDoctor: doctor});
    }

    removeDoctor = () => {
        this.setState({selectedDoctor: null});
    }

    hideDoctorList = () => {
        this.setState({showList: false, show: false});
    }

    onApply = (event, picker) => {
        var startDate = picker.startDate.format('YYYY-MM-DD');
        var endDate = picker.endDate.format('YYYY-MM-DD');
        this.setState({startDate: startDate, endDate: endDate}, () => {
            //console.log("state: ", this.state.selectedDoctor, this.state.startDate, this.state.endDate);
            if (this.state.selectedDoctor === null) {
                this.props.fetchReportsByDate(this.state.startDate, this.state.endDate);
            } else {
                this.props.fetchReportsByFirebaseAndGroupByDate(this.state.selectedDoctor.uid, this.state.startDate, this.state.endDate);
            }
        });
    }

    onCancel = (event, picker) => {
        this.setState({startDate: null, endDate: null});
    }

    onDoneClick = () => {
        this.setState({showList: false});
        if (this.state.selectedDoctor !== null) {
            this.props.fetchDoctorReports(this.state.selectedDoctor.uid);
        }
    }

    render() {

        var reportData = [];
        var totalSum = 0;
        this.props.reports_data.map(report => {
            totalSum += report[1];
            return reportData.push({name: getDate(report[0]), mins: Math.round(report[1] / 60)});
        });

        return (
            <Aux>
                <div className={classes.main}>
                    <div className={classes.container}>
                        <div className={classes.content}>
                            <div className={classes.main_content}>
                                <div className={classes.header}>
                                    <div className={classes.header_container}>
                                        <div className={classes.header_content}>Reports</div>
                                    </div>
                                </div>
                                <div className={classes.content_body}>
                                    <div className={classes.content_header}>
                                        <div className={classes.content_header_main}>
                                            <div className={classes.content_label}>Filter:</div>
                                            <div className={classes.content_header_body}>
                                                <button className={classes.content_button}
                                                        onClick={() => this.showListView()}>
                                                    <div className={classes.button_body}>
                                                        <svg fill="#4384f5" width="12px" height="12px"
                                                             viewBox="0 0 14 14">
                                                            <path fill="inherit" fillRule="nonzero"
                                                                  d="M14 8H8v6H6V8H0V6h6V0h2v6h6z"></path>
                                                        </svg>
                                                    </div>
                                                    <span>Agent</span>
                                                </button>
                                                {this.state.showList &&
                                                <div className={classes.date_button}>
                                                    <div className={classes.list}>
                                                        <div className={classes.list_container}>
                                                            <div className={classes.list_input}>
                                                                <div className={classes.list_head}
                                                                     onClick={() => this.showDocotrosList()}>
                                                                    {this.state.selectedDoctor !== null &&
                                                                    <div
                                                                        className={classes.selected_item_input_container}>
                                                                        <div className={classes.selected_item}>
                                                                            <svg onClick={() => this.removeDoctor()}
                                                                                 xmlns="http://www.w3.org/2000/svg"
                                                                                 width="14px" height="14px"
                                                                                 viewBox="0 0 24 24" fill="#fff">
                                                                                <path
                                                                                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
                                                                            </svg>
                                                                            <div className={classes.selected_item_view}>
                                                                                <div
                                                                                    className={classes.selected_item_container}>{this.state.selectedDoctor.doctorName}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    }

                                                                    {this.state.selectedDoctor === null &&
                                                                    <div className={classes.list_input_container}>
                                                                        <input
                                                                            className={classes.list_input_view}
                                                                            placeholder="Select"
                                                                            autoComplete="false"
                                                                        />
                                                                    </div>
                                                                    }
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px"
                                                                         height="24px" viewBox="0 0 24 24"
                                                                         fill="#424d57">
                                                                        <path d="M7 10l5 5 5-5H7z"></path>
                                                                    </svg>
                                                                </div>
                                                                {this.props.doctors.length !== 0 && this.state.show &&
                                                                <div className={classes.agent_list}>
                                                                    <ul className={classes.ul_list}>
                                                                        {this.props.doctors.map(doctor => {
                                                                            return (
                                                                                <li key={doctor.uid}
                                                                                    onClick={() => this.onlistClick(doctor)}>
                                                                                    <div
                                                                                        className={classes.li_container}>
                                                                                        <div
                                                                                            className={classes.li_inner_container}>
                                                                                            <img
                                                                                                className={classes.img_content}
                                                                                                src={doctor.profileImageURL}
                                                                                                alt="//cdn.livechatinc.com/cloud/?uri=https://livechat.s3.amazonaws.com/default/avatars/miamisunset.jpg"/>
                                                                                            <div
                                                                                                className={classes.li_info}>
                                                                                                <span>{doctor.doctorName}</span>
                                                                                                <span
                                                                                                    className={classes.email_style}>{doctor.email}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                                }
                                                            </div>
                                                            <div className={classes.button_conatianer}>
                                                                <button onClick={() => this.onDoneClick()}>Done</button>
                                                            </div>
                                                            <div className={classes.close_button_container}>
                                                                <button onClick={() => this.hideAll()}>Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                {this.state.selectedDoctor !== null &&
                                                <div className={classes.selected_container}
                                                     onClick={() => this.showListView()}>
                                                        <span className={classes.span_icon}>
                                                            <div className={classes.selected_icon} width="16px"
                                                                 height="16px" fill="rgba(255, 255, 255, 0.5)">
                                                                <svg fill="rgba(255, 255, 255, 0.5)" width="16px"
                                                                     height="16px" viewBox="0 0 16 16">
                                                                    <path
                                                                        d="M8 1.333A6.67 6.67 0 0 0 1.333 8 6.67 6.67 0 0 0 8 14.667 6.67 6.67 0 0 0 14.667 8 6.67 6.67 0 0 0 8 1.333zm0 2c1.107 0 2 .894 2 2 0 1.107-.893 2-2 2s-2-.893-2-2c0-1.106.893-2 2-2zM8 12.8a4.8 4.8 0 0 1-4-2.147C4.02 9.327 6.667 8.6 8 8.6c1.327 0 3.98.727 4 2.053A4.8 4.8 0 0 1 8 12.8z"
                                                                        fillRule="nonzero"></path>
                                                                </svg>
                                                            </div>
                                                        </span>
                                                    <span className={classes.selected_remove}>
                                                            <div className={classes.selected_icon} width="10px"
                                                                 height="10px" fill="#ffffff">
                                                                <svg fill="#ffffff" width="10px" height="10px"
                                                                     viewBox="0 0 14 14">
                                                                    <g fill="inherit" fillRule="evenodd">
                                                                        <path
                                                                            d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7z"></path>
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </span>
                                                    <div className={classes.selected_item_name}>
                                                        <span>{this.state.selectedDoctor.doctorName}</span>
                                                    </div>
                                                </div>
                                                }
                                                {this.state.startDate === null && this.state.endDate === null &&
                                                <div className={classes.date_button}
                                                     onClick={() => this.hideDoctorList()}>
                                                    <DateRangePicker
                                                        onApply={(ev, picker) => this.onApply(ev, picker)}
                                                        onCancel={(ev, picker) => this.onCancel(ev, picker)}
                                                    >
                                                        <div className={classes.date_view}>
                                                            <div className={classes.date_body}>
                                                                    <span className={classes.icon}>
                                                                        <svg fill="rgba(255, 255, 255, 0.5)"
                                                                             width="16px" height="16px"
                                                                             viewBox="0 0 24 24">
                                                                            <path fill="inherit"
                                                                                  d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"></path>
                                                                        </svg>
                                                                    </span>
                                                                <span>
                                                                        <div className={classes.title}>Date</div>
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </DateRangePicker>
                                                </div>
                                                }
                                                {this.state.startDate !== null && this.state.endDate !== null &&
                                                <div onClick={() => this.hideDoctorList()}>
                                                    <DateRangePicker
                                                        onApply={(ev, picker) => this.onApply(ev, picker)}
                                                        onCancel={(ev, picker) => this.onCancel(ev, picker)}
                                                    >
                                                        <div className={classes.selected_container}>
                                                                <span className={classes.span_icon}>
                                                                    <div className={classes.selected_icon} width="16px"
                                                                         height="16px" fill="rgba(255, 255, 255, 0.5)">
                                                                        <svg fill="rgba(255, 255, 255, 0.5)"
                                                                             width="16px" height="16px"
                                                                             viewBox="0 0 16 16">
                                                                            <path
                                                                                d="M8 1.333A6.67 6.67 0 0 0 1.333 8 6.67 6.67 0 0 0 8 14.667 6.67 6.67 0 0 0 14.667 8 6.67 6.67 0 0 0 8 1.333zm0 2c1.107 0 2 .894 2 2 0 1.107-.893 2-2 2s-2-.893-2-2c0-1.106.893-2 2-2zM8 12.8a4.8 4.8 0 0 1-4-2.147C4.02 9.327 6.667 8.6 8 8.6c1.327 0 3.98.727 4 2.053A4.8 4.8 0 0 1 8 12.8z"
                                                                                fillRule="nonzero"></path>
                                                                        </svg>
                                                                    </div>
                                                                </span>
                                                            <span className={classes.selected_remove}>
                                                                    <div className={classes.selected_icon} width="10px"
                                                                         height="10px" fill="#ffffff">
                                                                        <svg fill="#ffffff" width="10px" height="10px"
                                                                             viewBox="0 0 14 14">
                                                                            <g fill="inherit" fillRule="evenodd">
                                                                                <path
                                                                                    d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7z"></path>
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                </span>
                                                            <div className={classes.selected_item_name}>
                                                                <span>{this.state.startDate}</span> - <span>{this.state.endDate}</span>
                                                            </div>
                                                        </div>
                                                    </DateRangePicker>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.main_body}>
                                        <div className={classes.body}>
                                            <div className={classes.body_container}>
                                                <div className={classes.body_header}>
                                                    <div className={classes.body_header_contanier}>Chat Availablility
                                                    </div>
                                                </div>
                                                <div className={classes.body_main_container}>
                                                    <div className={classes.body_main_container_header}>
                                                        <div className={classes.body_title}> {getTime(totalSum)} </div>
                                                    </div>
                                                </div>
                                                <div className={classes.bar_chat_view}>
                                                    <div className={classes.bar_chat_container}>
                                                        {!this.props.loading &&
                                                        <ResponsiveContainer>
                                                            <BarChart
                                                                data={this.props.reports_data.length !== 0 ? reportData : []}>
                                                                <XAxis dataKey="name"/>
                                                                <YAxis domain={[0, 8]}/>
                                                                <Tooltip/>
                                                                <Legend/>
                                                                <Bar dataKey="mins" fill="rgb(92, 157, 255)"/>
                                                            </BarChart>
                                                        </ResponsiveContainer>
                                                        }
                                                        {this.props.loading &&
                                                        <div className={classes.no_loading}>
                                                            <div className={classes.no_loading_view}>
                                                                <div className={classes.loading_loader}/>
                                                            </div>
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Aux>
        );
    }
}

const mapStateToProps = ({reportReducer}) => {
    const {loading, reports_data, doctors} = reportReducer;
    return {loading, reports_data, doctors};
}

export default connect(mapStateToProps, {
    dailyReport, fetchDoctors, fetchDoctorReports,
    fetchReportsByDate, fetchReportsByFirebaseAndGroupByDate
})(ReportsBuilder);
