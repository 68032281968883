import {Component, useEffect, useState} from 'react';
import {connect} from 'react-redux';

import classes from './AddAgents.module.css';
import Aux from '../../hoc/hocAux';
import Modal from '../../components/UI/Modal/Modal';
import AddAgent from './AddAgents';
import {
    activateDoctor,
    changeAcceptStatusAdmin,
    childChangedDoctor,
    deleteDoctor,
    fetchAgents,
    resetSuccess,
    status
} from '../../actions/AgentsAction';
import Header from './AgentHeader';
import Body from './AgentBody';
import AgentsDetail from './AgentsDetail';

const AgentBuilder = (props) => {

    const  [activeLink, setActiveLink] = useState('Agents')
    const  [showAddAgentModal, setShowAddAgentModal] = useState(false)
    const  [showAddGroupModal, setShowAddGroupModal] = useState(false)
    const  [showingGroups, setShowingGroups] = useState(true)
    const  [showingInfo, setShowingInfo] = useState(true)
    const  [showingPerformance, setShowingPerformance] = useState(true)
    const  [doctorDetail, setDoctorDetail] = useState([])
    const  [selectedAgent, setSelectedAgent] = useState(null)
    const  [agentStatus, setAgentStatus] = useState(false)

    useEffect(()=>{
        props.fetchAgents();
        props.childChangedDoctor();
    },[])

    useEffect(()=>{
        if(selectedAgent && props.agentsData){
            let detail = props.agentsData.find(agent => agent.uid === selectedAgent)
            setDoctorDetail(detail || [])
        }
    },[props.agentsData, selectedAgent])

    const hideGroups = () => {
        if (showingGroups === true) {
            setShowingGroups(false)
        } else {
            setShowingGroups(true)
        }
    }
    const hideInfo = () => {
        if (showingInfo === true) {
            setShowingInfo(false)
        } else {
            setShowingInfo(true)
        }
    }

    const hidePerformance = () => {
        if (showingPerformance === true) {
            setShowingPerformance(false)
        } else {
            setShowingPerformance(false)
        }
    }

    const forceLogout = (selectedAgent) => {
    }

    const deleteDoctor = (selectedAgent) => {
        if (selectedAgent.activeOnlineChats) {
            alert("Please Close the existing chats of this doctor before deleting it.");
        } else if (selectedAgent['uid']) {
            if (window.confirm("Are you sure you want to delete this doctor?")) {
                props.deleteDoctor(selectedAgent['uid']);
                setSelectedAgent(null)
            }
        }
    }

    const activateDoctor = (selectedAgent) => {
        if (selectedAgent['uid']) {
            if (window.confirm("Are you sure you want to activate this doctor?")) {
                props.activateDoctor(selectedAgent['uid']);
                setSelectedAgent(null)
            }
        }
    }

    const onAddAgentClick = () => {
        props.resetSuccess();
        setShowAddAgentModal(true)
    }

    const onAddGroupClick = () => {
        setShowAddGroupModal(true)
    }

    const onHideAddAgentModal = () => {
        setShowAddGroupModal(false)
        setShowAddAgentModal(false)
    }

    const onHeaderChange = (data) => {
        setActiveLink(data)
    }

    const onAgentClick = (agent) => {
        let status = props.status(agent);
        setSelectedAgent(agent.uid)
        setDoctorDetail(agent)
        setAgentStatus(status)
    }

    const onAcceptStatusChange = (status) => {
        props.changeAcceptStatusAdmin(doctorDetail, status);
    }

    const updateDoctorDetail = (uid) => {
        const path = {
            pathname: '/agents/update',
            search: '',
            hash: '',
            state: {agentID: uid}
        }
        props.history.push(path);
    }

    const changeStat = (checked) => {
        setAgentStatus(!checked)
        onAcceptStatusChange(!checked);
    }

    return (
        <Aux>
            <Modal show={showAddAgentModal} modalClosed={onHideAddAgentModal}>
                <AddAgent modalClosed={onHideAddAgentModal}/>
            </Modal>
            <div className={classes.main_div}>
                <div className={ classes.main}>
                    <div className={classes.header}>Team</div>
                    <div className={classes.body}>
                        <Header HeaderItem={onHeaderChange}
                                activeLink={activeLink}/>
                        <Body
                            activeLink={activeLink}
                            onAddAgentClick={onAddAgentClick}
                            onAddGroupClick={onAddGroupClick}
                            agentsData={props.agentsData}
                            groupData={props.groupData}
                            onAgentClick={onAgentClick}
                            selectedAgent={selectedAgent}
                        />
                    </div>
                </div>
                <AgentsDetail
                    hideGroups={hideGroups}
                    hideInfo={hideInfo}
                    hidePerformance={hidePerformance}
                    deleteDoctor={deleteDoctor}
                    activateDoctor={activateDoctor}
                    forceLogout={forceLogout}
                    showingGroups={showingGroups}
                    showingInfo={showingInfo}
                    showingPerformance={showingPerformance}
                    selectedAgent={selectedAgent}
                    doctorData={doctorDetail}
                    changeAcceptStatus={onAcceptStatusChange}
                    updateDoctorDetail={updateDoctorDetail}
                    acceptStatus={agentStatus}
                    acceptStatusChanged={changeStat}
                />
            </div>
        </Aux>
    )
}

const mapStateToProps = ({agentsReducer, acceptingReducer}) => {
    const {agentsData, success} = agentsReducer;
    const {acceptingAdmin} = acceptingReducer
    return {agentsData, success, acceptingAdmin};
}

export default connect(mapStateToProps, {
    fetchAgents,
    resetSuccess,
    deleteDoctor,
    changeAcceptStatusAdmin,
    status,
    childChangedDoctor,
    activateDoctor
})(AgentBuilder);
