import React, {useEffect, useState, Fragment, useRef} from 'react'
import classes from "../../../components/pharmacyComponent/pharmacycomponent.module.css";
import {connect} from "react-redux";
import moment from "moment";


const LabReportsSection = (props) => {

    const [labReports, setLabReports] = useState(null)
    const [showBox, setShowBox] = useState(false)
    const [patientId, setPatientId] = useState(null)

   /* useEffect(()=>{
        if(patientId !== props.patientId){
            setLabReports(null)
            setPatientId(props.patientId)
        }
        if(props.patients && props.patientId){
            if(props.patients[props.patientId]){
                if(props.patients[props.patientId]['historyLabs']) setLabReports(Object.values(props.patients[props.patientId]['historyLabs']))
                //else setLabReports(null)
                // setPatientId(props.patientId)
            }
        }
    },[props.patientId, props.patients[props.patientId]]) */

    useEffect(()=>{
        if(patientId !== props.patientId){
            setLabReports(null)
            setPatientId(props.patientId)
        }
        if(props.selectedPatient && props.patientId){
           if(props.selectedPatient['historyLabs']) setLabReports(Object.values(props.selectedPatient['historyLabs']))
           //else setLabReports(null)
           // setPatientId(props.patientId)
        }
    },[props.patientId, props.selectedPatient])

    const setShowBoxFunction = () => {
        setShowBox(!showBox)
    }


    return (
        <div className={classes.generalInformation}>
            <Fragment>
                <div className={classes.openchatlist}
                     onClick={setShowBoxFunction}>
                    <div className={classes.openchatlistSection}>
                        <div
                            className={showBox === true ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                            <svg fill="#884a9d" width="12px" height="8px"
                                 viewBox="0 0 12 8">
                                <path fill="inherit" fillRule="evenodd"
                                      d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                            </svg>
                        </div>
                        <div className={classes.subListTitle}>Lab Reports</div>
                    </div>
                </div>
                {showBox &&
                <div className={classes.expandable_section}>
                    {
                        <div className={classes.general_info_section}>

                            {labReports?.length>0? labReports.map((report)=>{
                                const labForms = report?.labForms? Object.values(report?.labForms): null
                                const labResults = report?.labResults? Object.values(report?.labResults): null

                                return <div className={classes.importantLinks} key={report.timestamp + "_notes"}>
                                    <span className={classes.SOAP_heading}>{report.symptoms}</span>
                                    <span className={classes.importantLinkText}>Lab Forms</span>
                                    {labForms?.length>0? labForms.map(form=>{
                                        return <div className={classes.sickNoteDiv} key={form.timestamp+'_note'}><a target="_blank" href={(form.file || '').replace('export=download', 'export=pdf')} key={form.fileName} className={classes.sickNoteFileName}>{form.fileName}</a><span>{moment(form.timestamp).format('DD-MM-YYYY hh:mm a')}</span></div>

                                    }):<div className={classes.list_text}>No Lab Forms</div>}
                                    <span className={classes.importantLinkText}>Lab Results</span>
                                    {labResults?.length>0? labResults.map(form=>{
                                        //return <a target="_blank" href={form.file} key={form.fileName}>{form.fileName}</a>
                                        return <div className={classes.sickNoteDiv} key={form.timestamp+'_note'}><a target="_blank" href={(form.file || '').replace('export=download', 'export=pdf')} key={form.fileName} className={classes.sickNoteFileName}>{form.fileName}</a><span>{moment(form.timestamp).format('DD-MM-YYYY hh:mm a')}</span></div>

                                    }):<div className={classes.list_text}>No Lab Reports</div>}
                                </div>

                            }):<div className={classes.list_text}>No Lab Reports History</div>}
                        </div>
                    }
                </div>
                }
            </Fragment>
        </div>
    )


}
const mapStateToProps = ({ conversationReducer }) => {
    const { patientId, doctorsNameAndImages, selectedPatient } = conversationReducer;
    return { patientId, doctorsNameAndImages, selectedPatient };
};

export default connect(mapStateToProps, {})(LabReportsSection);
