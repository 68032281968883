import {
    ACCEPTING_CHAT_FALSE,
    ACCEPTING_CHAT_TRUE,
    CHAT_TRANSFERED_DONE,
    CLOSE_CHAT,
    CLOSE_CHAT_OTHER,
    CONVERSATIONS_FETCH_SUCCESS,
    CONVERSATIONS_FETCHING,
    FETCH_CHAT_TAGS,
    FETCH_CHAT_TAGS_FAILED,
    FETCH_CHAT_TAGS_SUCCESS,
    FETCH_CONVERSATIONS_COUNT_FOR_EACH_DOCTOR,
    FETCH_DOCTORS_NAME_AND_IMAGES,
    FETCH_ONLINE_DOCTORS,
    FETCH_STATUS,
    FLAG_FALSE,
    FLAG_TRUE,
    FOLLOW_SAVE_DONE,
    FOLLOW_SAVE_PROGRESS,
    FOLLOW_UP,
    FOLLOW_UP_CHANGE,
    FOLLOW_UP_REMOVAL,
    PICKED_FROM_QUEUE,
    REFRESH_CONVERSATION,
    REFRESH_REMOTE_STREAMS,
    REFRESH_STATE,
    REFRESH_VIDEO_CHAT_DECLINED,
    RESET_CONVERSATIONID,
    SAVE_CLINICAL_OUT_PATIENT_FORM_IN_PROCESS,
    SAVE_CLINICAL_OUT_PATIENT_FORM_UPDATED,
    SAVE_OUT_PATIENT_FORM_IN_PROCESS,
    SAVE_OUT_PATIENT_FORM_SUCCESS,
    SAVE_OUT_PATIENT_FORM_UPDATED,
    SAVE_REMOTE_STREAMS,
    SELECTED_CHAT,
    TAB_CHANGE_SELECTED_CHAT,
    SELECTED_CHAT_PATIENT_DETAIL,
    SET_VIDEOCHAT_ID,
    VIDEO_CHAT_DECLINED,
    VIDEO_STATE_FALSE,
    VIDEO_STATE_TRUE,
    UPDATE_DOCTORS,
    UPDATE_PATIENTS,
    CONVERSATIONS_REMOVED,
    REMOVE_PATIENT,
    SET_CHAT_HISTORY_ITEM_CLICKED,
    UPDATE_SELECTED_PATIENT,
    SET_VIDEO_STATUS,
    SET_CALL_STATUS,
    ALL_CONVERSATIONS_FETCH_SUCCESS,
    CONVERSATIONS_FETCH_SUCCESS_ON_ADDED
} from '../constants';

import _ from 'lodash';
import algoliasearch from 'algoliasearch';

import {ENVIRONMENT} from '../environment';
import ngrokUrl from '../ngrok';
import rootURL, {firebaseRoot} from '../config';
import doctorServicesUrl from '../DoctorServicesConfig'
import {sendMessage} from "./ChatAction";
import {auth, database, storage, storageRef} from "../firebase";
import axios from "axios";

var moment = require("moment");
var userListenerRef = null
var userListener = null

var client_store = algoliasearch("FL1OI9QKUW", "f0427fde3618b1db1bfb004c623215db");
var index_store = null;
let acceptChatListener = null;
let openConversationsListener = null;
let openConversationsChangedListener = null;
let openConversationsRemovedListener = null;
let doctorsImagesListener = null
let doctorsListener = null
let openConvoListener = null
let openClinicalConvoListener = null
let openClinicRef = null
let openConversationsWithLocationAddTagRef = null
let openConversationsWithLocationAddTagListener = null
export const setAlgolia = (clinicid) => {
    return (dispatch) => {
        // convertArchivesData();
        try {
            if (clinicid) {
                index_store = client_store.initIndex(clinicid + '_conversations')
            } else {
                if (ENVIRONMENT === "development") {
                    index_store = client_store.initIndex('stg_conversations');
                } else {
                    index_store = client_store.initIndex('conversations');
                }
            }
        } catch (error) {
            console.log("setAlgolia error ", error);
        }

    }
}
export const setFlagTrue = () => {
    return {
        type: FLAG_TRUE
    }
}

export const setVideoStatusTrue = () => {
    return {
        type: VIDEO_STATE_TRUE
    }
}

export const refreshRemoteStream = () => {
    return {
        type: REFRESH_REMOTE_STREAMS
    }
}

export const setVideoId = (id) => {
    return {
        type: SET_VIDEOCHAT_ID,
        payload: id
    }
}

export const saveRemoteStream = (remoteStream) => {
    return {
        type: SAVE_REMOTE_STREAMS,
        payload: remoteStream
    }
}

export const setVideoStatusFalse = () => {
    return {
        type: VIDEO_STATE_FALSE
    }
}

export const setFlagFalse = () => {
    return {
        type: FLAG_FALSE
    }
}

export const refreshConversations = () => {
    return {
        type: REFRESH_CONVERSATION
    }
}

export const setSelectedItem = (paidChat, patientID, doctorID, conversationID, issue, category, start_time, selectedChat) => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        currentUser.getIdTokenResult().then(idTokenResult => {

            if (idTokenResult.claims.admin) {
                return dispatch({
                    type: SELECTED_CHAT,
                    payload: {
                        paidChat: paidChat, patientId: patientID, doctorIsValid: false,
                        conversationId: conversationID, admin: true, issue: issue, category: category,
                        chatStartTime: start_time,
                        selectedChat
                    }
                })
            } else {
                return dispatch({
                    type: SELECTED_CHAT,
                    payload: {
                        paidChat: paidChat, patientId: patientID,
                        doctorIsValid: doctorID === currentUserID ? true : false,
                        conversationId: conversationID, admin: false, issue: issue, category: category,
                        chatStartTime: start_time,
                        selectedChat
                    }
                })
            }
        });
    }
}

export const tabChangeSetSelectedItem = (paidChat, patientID, doctorID, conversationID, issue, category, start_time, selectedChat) => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        currentUser.getIdTokenResult().then(idTokenResult => {

            if (idTokenResult.claims.admin) {
                dispatch({
                    type: TAB_CHANGE_SELECTED_CHAT,
                    payload: {
                        paidChat: paidChat, patientId: patientID, doctorIsValid: false,
                        conversationId: conversationID, admin: true, issue: issue, category: category,
                        chatStartTime: start_time,
                        selectedChat
                    }
                })
            } else {
                dispatch({
                    type: TAB_CHANGE_SELECTED_CHAT,
                    payload: {
                        paidChat: paidChat, patientId: patientID,
                        doctorIsValid: doctorID === currentUserID ? true : false,
                        conversationId: conversationID, admin: false, issue: issue, category: category,
                        chatStartTime: start_time,
                        selectedChat
                    }
                })
            }
        });
    }
}

export const calcFeet = (heightInCm) => {
    heightInCm = parseInt(heightInCm)
    var totalInches = heightInCm / 2.54
    var heightInFeet = Math.floor(totalInches / 12)
    var remainderInches = totalInches % 12
    remainderInches = Math.ceil(remainderInches)
    var heightInformat = heightInFeet + ',' + remainderInches
    return heightInformat;
}

export const updateSelectedPatient = (patientID, patientObj) => {
    return (dispatch) => {
        if(patientObj){
            return dispatch({
                type: UPDATE_SELECTED_PATIENT,
                payload: patientObj
            })
        }else{
            database.ref(`/users/${patientID}`).once("value",fromUserSnapshot => {
                if(fromUserSnapshot.val()) {
                    let userName =  fromUserSnapshot.val().name || fromUserSnapshot.val().userName || 'Patient';
                    let country = fromUserSnapshot.val().country;
                    let city = fromUserSnapshot.val().city;
                    let chatCount = fromUserSnapshot.val().history_conversations || [];
                    let newPatientObj = {
                        ...fromUserSnapshot.toJSON(),
                        patientName: userName,
                        convoCount: Object.keys(chatCount).length,
                        patientCountry: Boolean(country) ? country : "NA",
                        patientCity: Boolean(city) ? city : "NA",
                        tier2ClinicData: fromUserSnapshot.val()?.conversations?.tier2ClinicData || {},
                        transferredFromTier2: fromUserSnapshot.val()?.conversations?.transferredFromTier2 || false,
                    }
                    return dispatch({
                        type: UPDATE_SELECTED_PATIENT,
                        payload: newPatientObj
                    })
                }
            })
        }
    }
}

export const findPatientDetail = (patientID, patientObj, selectedChat) => {
    return (dispatch) => {
        if(userListener && userListenerRef) {
            userListenerRef.off('value', userListener)
            userListener = null
            userListenerRef = null
        }

        console.log("findPatientDetail patientID ", patientID)
        userListenerRef = getUserRef(patientID);
        userListener = userListenerRef.on('value', patientSnap => {
            console.log("findPatientDetail patientSnap.val() ", patientSnap.val())
            if (patientSnap.val()) {
                let patientName = ((patientSnap.val().userName === 'No name' || patientSnap.val().userName === 'Patient') && patientSnap.val().name) ? patientSnap.val().name : patientSnap.val().userName;
                let chatCount = patientSnap.val().history_conversations || [];
                return dispatch({
                    type: SELECTED_CHAT_PATIENT_DETAIL,
                    payload: {
                        patientFirebaseID: patientID || null,
                        patientName: patientName || null,
                        convoCount: Object.keys(chatCount).length,
                        city: patientSnap.val().city || null,
                        country: patientSnap.val().country || null,
                        province: patientSnap.val().state || null,
                        postalcode: patientSnap.val().postalcode || null,
                        complete_address: patientSnap.val().complete_address || null,
                        patient_phone_number: patientSnap.val().phone_number || null,
                        user_id: patientSnap.val().user_id || null,
                        followupId: patientSnap.val().followupId || null,
                        platform: patientSnap.val().platform || null,
                        email: patientSnap.val().email || null,
                        DOB: patientSnap.val().DOB || null,
                        gender: patientSnap.val().gender || null,
                        symptoms: patientSnap.val().symptoms || patientSnap.val()?.conversations?.issue || "",
                        medication: patientSnap.val().medication || null,
                        gmtOffset: patientSnap.val().gmtOffset || null,
                        buildNumber: patientSnap.val().buildNumber || null,
                        heartRate: patientSnap.val().heartRate || null,
                        weightKg: patientSnap.val().weightKg || null,
                        weightLbs: patientSnap.val().weightLbs || null,
                        stepCount: patientSnap.val().stepCount || null,
                        runningWalking: patientSnap.val().runningWalking || null,
                        heightCm: patientSnap.val().heightCm || null,
                        heightFeet: patientSnap.val().heightCm === 'NA' ? 'NA' : calcFeet(patientSnap.val().heightCm),
                        currentSleep: patientSnap.val().currentSleep || null,
                        averageSleep: patientSnap.val().averageSleep || null,
                        periodStartDate: patientSnap.val().periodStartDate || null,
                        flowType: patientSnap.val().flowType || null,
                        restingHeartRate: patientSnap.val().restingHeartRate || null,
                        healthInsuranceCardFront: patientSnap.val().healthInsuranceCardFront || null,
                        healthInsuranceCardBack: patientSnap.val().healthInsuranceCardBack || null,
                        healthInsuranceCardNumber: patientSnap.val().healthInsuranceCardNumber || null,
                        patientProfile: patientSnap.val().PatientProfile || {},
                        pharmacy: patientSnap.val().pharmacy || {}
                    }
                })
            }
        })
    }
}

export const changeAcceptStatus = (clinicId, checked, time, callback) => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        var updates = {};

        if (clinicId) {
            updates[`/clinicStaffMember/${clinicId}/${currentUserID}/accepting_chat`] = checked;
            updates[`/clinicStaffMember/${clinicId}/${currentUserID}/accepting_chat_timestamp`] = time;
            database.ref().update(updates)
                .then(data => {
                    callback()
                    checked ?
                        dispatch({
                            type: ACCEPTING_CHAT_TRUE,
                            payload: {
                                time: time
                            }
                        }) : dispatch({
                            type: ACCEPTING_CHAT_FALSE
                        })
                })
                .catch(error => {
                    callback()
                    dispatch({
                        type: ACCEPTING_CHAT_FALSE,
                    })
                })
        } else {
            let activityKey = getActivitiesRef().push().key;
            var now = new Date();
            var month = now.getUTCMonth() + 1; //months from 1-12
            var day = now.getUTCDate();
            var year = now.getUTCFullYear();
            var currentDate = year + "-" + month + "-" + day;

            var acceptingChatTime = checked ? 'acceptingChatOnTime' : 'acceptingChatOffTime';

            updates[`/doctors/${currentUserID}/accepting_chat`] = checked;
            updates[`/doctors/${currentUser.uid}/${acceptingChatTime}`] = new Date().getTime();
            updates[`/activities/${currentDate}/${currentUser.uid}/${activityKey}/${acceptingChatTime}`] = now.getTime();
            console.log("chat_session updates", updates);
            database.ref().update(updates)
                .then(data => {
                    console.log("chat_session data", data);
                    createAndUpdateAcceptChatSession(dispatch, checked, currentUserID);
                })
                .catch(error => {
                    console.log("chat_session failed change status");

                })
        }
    }
}

async function postData(url = '', data = {}, method) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return await response.json(); // parses JSON response into native JavaScript objects
}

const createAndUpdateAcceptChatSession = (dispatch, checked, currentUserID) => {
    var now = new Date();
    var raw;
    var session;
    /*dispatch({
        type: checked?ACCEPTING_CHAT_TRUE:ACCEPTING_CHAT_FALSE,
        payload: {
            time: null
        }
    })*/
    if (checked) {
        // let data = window.sessionStorage.getItem('login_session');
        /*let data = window.localStorage.getItem('login_session');
        session = JSON.parse(data);
        if (session) {
            raw = {
                "sessionID": session?.id,
                "firebaseID": currentUserID,
                "acceptChatOnTime": now.getTime()
            }

            postData(`${ngrokUrl}/chat_session`, raw, 'POST')
                .then((data) => {
                    // window.sessionStorage.setItem('accept_chat_session', JSON.stringify(data));
                    console.log("chat_session data ", data)
                    window.localStorage.setItem('accept_chat_session', JSON.stringify(data));

                     // JSON data parsed by `response.json()` call
                })
                .catch((error) => {
                    console.log("chat_session error ", error)
                    //console.log("error: ", error);


                })
        }*/
    } else {
        // let data = window.sessionStorage.getItem('accept_chat_session');
        /*let data = window.localStorage.getItem('accept_chat_session');
        session = JSON.parse(data);
        let chat_session = session?.chatSessionsList?.slice(-1)[0];

        if (session) {
            raw = {
                "id": chat_session?.id,
                "sessionID": session?.id,
                "firebaseID": currentUserID,
                "acceptChatOffTime": now.getTime(),
                "totalChatSessionTime": getHours(now.getTime(), chat_session?.acceptChatOnTime)
            }
            dispatch({
                type: ACCEPTING_CHAT_FALSE,
                payload: {
                    time: null
                }
            })
            postData(`${ngrokUrl}/chat_session`, raw, 'PUT')
                .then((data) => {

                    console.log("chat_session data ", data)
                    // window.sessionStorage.setItem('accept_chat_session', JSON.stringify(data));
                    window.localStorage.setItem('accept_chat_session', JSON.stringify(data));
                     // JSON data parsed by `response.json()` call
                })
                .catch((error) => {
                    console.log("chat_session error ", error)
                    //console.log("error: ", error);
                })
        }*/
    }
}

const getHours = (logoutTime, loginTime) => {
    var delta = Math.abs(logoutTime - loginTime) / 1000;
    return delta;
}

export const getAcceptStatus = (clinic_id) => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        if(acceptChatListener){
            acceptChatListener.off();
            acceptChatListener = null;
        }
        try {
            if (clinic_id) {
                acceptChatListener = database.ref(`clinicStaffMember/${clinic_id}/${currentUserID}`);
                acceptChatListener.on('value', function (snapshot) {
                        if (snapshot.val().accepting_chat === false) {
                            dispatch({
                                type: ACCEPTING_CHAT_FALSE,
                            })
                        } else {
                            dispatch({
                                type: ACCEPTING_CHAT_TRUE,
                                payload: {
                                    time: snapshot.val().accepting_chat_timestamp
                                }
                            })
                        }
                    })
            } else {
                acceptChatListener = database.ref(`doctors/${currentUserID}/accepting_chat`)
                acceptChatListener.on('value', function (snapshot) {
                        if (snapshot.val() === false) {
                            dispatch({
                                type: ACCEPTING_CHAT_FALSE,
                            })
                        } else {
                            dispatch({
                                type: ACCEPTING_CHAT_TRUE,
                                payload: {
                                    time: null
                                }
                            })
                        }
                    })
            }
        } catch (error) {
            console.log("getAcceptStatus error ", error);
        }
    }
}

export const checkStatusAccept = (clinicId) => {
    return (dispatch) => {
        /*const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        if (clinicId) {
            getClinicStaffMemeberRef(clinicId, currentUserID).once('value', doctorSnap => {
                if (doctorSnap.val().accepting_chat === false) {
                    dispatch({type: ACCEPTING_CHAT_FALSE})
                }
                if (doctorSnap.val().accepting_chat === true) {
                    dispatch({
                        type: ACCEPTING_CHAT_TRUE,
                        payload: {
                            time: null
                        }
                    })
                }
            })
        } else {
            getDoctorRef(currentUserID).once('value', doctorSnap => {
                if (doctorSnap.val().accepting_chat === false) {
                    dispatch({type: ACCEPTING_CHAT_FALSE})
                }
                if (doctorSnap.val().accepting_chat === true) {
                    dispatch({
                        type: ACCEPTING_CHAT_TRUE,
                        payload: {
                            time: null
                        }
                    })
                }
            })
        }*/

    }
}

export const changeQueueStatus = (paidChat, conversationId, patientID, doctorID, doctorName, toID, issue, category, start_time) => {
    return (dispatch) => {

        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        var now = new Date();
        if (currentUserID === doctorID.uid) {
            getOpenConversationsWithLocationRef(conversationId).update({
                queued: false,
                doctorID: currentUserID,
                doctorName: doctorName || null,
                assignedOn: 'picked_from_queue_chat',
                activeTimestamp: now.getTime()
            })
                .then(() => {
                    getDoctorRef(currentUserID).once('value', doctorSnap => {
                        if (doctorSnap.val()) {
                            var updates = {};
                            let doctorData = {location: conversationId, patientID: patientID};

                            let patientData = {
                                location: conversationId,
                                doctorID: currentUserID,
                                issue: issue,
                                category: category
                            };

                            let latestExistQueueChatData = {
                                location: conversationId,
                                start_time: start_time,
                                waitEndTime: now.getTime()
                            }

                            updates[`/latestExistQueueChat/`] = latestExistQueueChatData;
                            updates[`/doctors/${currentUserID}/conversations/${conversationId}`] = doctorData;
                            updates[`/doctors/${currentUserID}/activeOnlineChats`] = doctorSnap.val().activeOnlineChats + 1;

                            database.ref('users/' + patientID + '/conversations').once('value', function (snapshot) {
                                let stripeToken = Boolean(snapshot.val().stripeToken) ? snapshot.val().stripeToken : null
                                let tranferredFromTier2 = Boolean(snapshot.val().tranferredFromTier2) ? snapshot.val().tranferredFromTier2 : null
                                if (snapshot.exists()) {
                                    patientData = {...patientData, stripeToken, tranferredFromTier2}
                                    snapshot.ref.update(patientData)
                                } else {
                                    snapshot.ref.set(patientData)
                                }
                            })
                            // updates[`/users/${patientID}/conversations`] = patientData;

                            database.ref().update(updates)
                                .then(data => {
                                })
                                .catch((error) => {
                                    console.log("Message send update error: 1 " + error);
                                });
                        }
                    })

                    dispatch({
                        type: PICKED_FROM_QUEUE,
                        payload: conversationId
                    })
                    database.ref('users/' + toID).once('value', function (snapshot) {
                        var user_data = snapshot.val();
                        sendJoinChatNotification(user_data.notificationToken, doctorID.doctorName)
                    });
                })
        }

    }
}

function sendChatToQueue(conversationID) {
    getOpenConversationsWithLocationRef(conversationID).update({
        queued: true,
        tier2: false
    }).then((data) => {
    }).catch(e => console.log("error", e))
}

function removeInValidConversation(conversationID) {
    /*getOpenConversationsWithLocationRef(conversationID).update({followUpStatus: null}).then((data) => {
    }).catch(e => console.log("error", e))*/
}

/*const fetchDoctorDetail = (dispatch, getState, doctorID ) => {
    let doctors = getState().conversationReducer.doctors
    if(!(doctors && doctors[doctorID])){
        getDoctorRef(doctorID).once('value', docSnapshot => {
            if (docSnapshot.exists()) {
                let doctorObj = docSnapshot.toJSON()
                let newDoctors = {...doctors} || {};
                newDoctors[doctorID] = doctorObj
                dispatch({
                    type: UPDATE_DOCTORS,
                    payload: newDoctors
                })
            }
        });
    }
}*/

const fetchPatientDetails = (dispatch, getState, patientID, force, existingPatientObj) => {
    let patients = getState().conversationReducer.patients
    if((patients)){
        if(existingPatientObj){
            dispatch({
                type: UPDATE_PATIENTS,
                payload: {
                    type:"add",
                    patientID,
                    patientObj:existingPatientObj
                }
            })
        }else{
            getUserRef(patientID).once('value', fromUserSnapshot => {
                if(fromUserSnapshot.val()){
                    let userName = fromUserSnapshot.val().userName !== null &&
                    fromUserSnapshot.val().userName !== undefined ?
                        fromUserSnapshot.val().userName : 'Patient';

                    userName = ((userName === 'No name' || userName === 'Patient') && fromUserSnapshot.val().name) ? fromUserSnapshot.val().name : userName;
                    let country = fromUserSnapshot.val().country;
                    let city = fromUserSnapshot.val().city;
                    let patientObj = {
                        ...fromUserSnapshot.toJSON(),
                        patientName: userName,
                        patientCountry: Boolean(country) ? country : "NA",
                        patientCity: Boolean(city) ? city : "NA",
                        tier2ClinicData: fromUserSnapshot.val()?.conversations?.tier2ClinicData || {},
                        transferredFromTier2: fromUserSnapshot.val()?.conversations?.transferredFromTier2 || false,
                    }
                    dispatch({
                        type: UPDATE_PATIENTS,
                        payload: {
                            type:"add",
                            patientID,
                            patientObj
                        }
                    })
                }
            })
        }

    }
}

export const updatePatient = (patientID, patientObj) => {
    return (dispatch, getState) => {
        //console.log("updatePatient patientObj", patientObj)
        fetchPatientDetails(dispatch, getState, patientID, true, patientObj);
    }
}

export const removePatient = (dispatch, getState, patientID) => {

}
const convoCount = (dispatch, conversationsSnapShot) => {
    let count = conversationsSnapShot.numChildren();
    if (count !== 0) {
        const bubbles = _.map(conversationsSnapShot.val(), (val, uid) => {
            if (val.status && !val.queued && !Boolean(val.clinical_chat)) {
                return {...val, uid};
            }
        });

        let filteredBubbles = bubbles.filter(function (el) {
            return el != null && el !== "" && el !== undefined;
        });

        let result = filteredBubbles.length !== 0 && filteredBubbles.reduce(function (obj, v) {
            // increment or set the property
            // `(obj[v.status] || 0)` returns the property value if defined
            // or 0 ( since `undefined` is a falsy value
            obj[v.doctorID] = (obj[v.doctorID] || 0) + 1;
            // return the updated object
            return obj;
            // set the initial value as an object
        }, {});

        let resultCount = _.map(result, (onlinePaidChats, doctorID) => {
            return {onlinePaidChats, doctorID};
        });


        dispatch({
            type: FETCH_CONVERSATIONS_COUNT_FOR_EACH_DOCTOR,
            payload: resultCount
        })
    }
}

const fetchAllConversations = (dispatch) => {
    return new Promise((resolve, reject)=>{
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        let now = new Date();

        getOpenConversationsRef().once('value', openConvoSnap => {
            let count = openConvoSnap.numChildren();
            let index = 0;
            let convos = []
            openConvoSnap.forEach(childSnapshot => {
                //convoCount(dispatch, childSnapshot)
                let lastMessageTimeStamp = '';
                let unreadCount = 0;

                if (childSnapshot.val().status && childSnapshot.val().patientID && !Boolean(childSnapshot.val().clinical_chat) ) {
                    index++;
                    if(!childSnapshot.val().isDelegate){
                        if (childSnapshot.val().status && !childSnapshot.val().queued && !childSnapshot.val().doctorID) {
                            sendChatToQueue(childSnapshot.key)
                        }
                        let location = childSnapshot.key;
                        //fetchPatientDetails(dispatch, getState, childSnapshot.val().patientID)
                        //fetchDoctorDetail(dispatch, getState, childSnapshot.val().doctorID)
                        let conversationObj = {
                            ...childSnapshot.toJSON(),
                            conversationId: location,
                            ownerChat: currentUserID === childSnapshot.val().doctorID ? true : false,
                            chatInQueue: childSnapshot.val().queued,
                            paidChat: childSnapshot.val().paidChat,
                            issue: childSnapshot.val().issue !== undefined && childSnapshot.val().issue !== null ?
                                childSnapshot.val().issue : 'No Issue',
                            category: childSnapshot.val().category !== undefined && childSnapshot.val().category !== null ?
                                childSnapshot.val().category : 'No Category',
                            unreadCount: unreadCount,
                            lastMessageTimeStamp: lastMessageTimeStamp,
                            timestamp: now.getTime(),
                            start_timestamp: childSnapshot.val().timestamp,
                            transferedBy: !_.isUndefined(childSnapshot.val().transferedBy) ? childSnapshot.val().transferedBy : null,
                            assignedOn: !_.isUndefined(childSnapshot.val().assignedOn) ? childSnapshot.val().assignedOn : null,
                        }

                        convos.push(conversationObj)
                    }

                    if(index === count){
                        dispatch({
                            type: ALL_CONVERSATIONS_FETCH_SUCCESS,
                            payload: convos
                        })
                        resolve();
                    }


                }
                else {
                    removeInValidConversation(childSnapshot.key)
                    index++
                    if(index === count){
                        dispatch({
                            type: ALL_CONVERSATIONS_FETCH_SUCCESS,
                            payload: convos
                        })
                        resolve();
                    }
                }
            })
        })

    })
}

export const fetchConversations = () => {
    console.log("fetchConversations triggered")
    return async (dispatch, getState) => {
        dispatch({type: CONVERSATIONS_FETCHING});
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        let now = new Date();
        try {
            if(openConversationsListener) {
                getOpenConversationsRef().off('child_added', openConversationsListener);
                openConversationsListener = null;

            };
            if(openConversationsRemovedListener) {
                getOpenConversationsRef().off('child_removed', openConversationsRemovedListener);
                openConversationsRemovedListener = null
            }
            if(openConversationsChangedListener){
                getOpenConversationsRef().off('child_changed', openConversationsChangedListener);
                openConversationsChangedListener = null
            }
            await fetchAllConversations(dispatch)
            openConversationsListener = getOpenConversationsRef().on('child_added', childSnapshot => {
                //convoCount(dispatch, childSnapshot)
                let lastMessageTimeStamp = '';
                let unreadCount = 0;

                if (childSnapshot.val().status && childSnapshot.val().patientID && !Boolean(childSnapshot.val().clinical_chat)) {
                    if(!(childSnapshot.val().isDelegate)){
                        if (childSnapshot.val().status && !childSnapshot.val().queued && !childSnapshot.val().doctorID) {
                            sendChatToQueue(childSnapshot.key)
                        }
                        let location = childSnapshot.key;
                        //fetchPatientDetails(dispatch, getState, childSnapshot.val().patientID)
                        //fetchDoctorDetail(dispatch, getState, childSnapshot.val().doctorID)
                        let conversationObj = {
                            ...childSnapshot.toJSON(),
                            conversationId: location,
                            ownerChat: currentUserID === childSnapshot.val().doctorID ? true : false,
                            chatInQueue: childSnapshot.val().queued,
                            paidChat: childSnapshot.val().paidChat,
                            issue: childSnapshot.val().issue !== undefined && childSnapshot.val().issue !== null ?
                                childSnapshot.val().issue : 'No Issue',
                            category: childSnapshot.val().category !== undefined && childSnapshot.val().category !== null ?
                                childSnapshot.val().category : 'No Category',
                            unreadCount: unreadCount,
                            lastMessageTimeStamp: lastMessageTimeStamp,
                            timestamp: now.getTime(),
                            start_timestamp: childSnapshot.val().timestamp,
                            transferedBy: !_.isUndefined(childSnapshot.val().transferedBy) ? childSnapshot.val().transferedBy : null,
                            assignedOn: !_.isUndefined(childSnapshot.val().assignedOn) ? childSnapshot.val().assignedOn : null,
                        }
                        dispatch({
                            type: CONVERSATIONS_FETCH_SUCCESS_ON_ADDED,
                            payload: conversationObj
                        })
                        /*var index = _.findIndex(conversations, {conversationId: conversationObj.conversationId});
                        index !== -1 ? conversations.splice(index, 1, conversationObj) : conversations.push(conversationObj);
                        if (count - 1 === i) {
                            const bubbles = _.map(conversations, (val, uid) => {
                                return {...val, uid};
                            });

                            i = count - 1;
                            dispatch({
                                type: CONVERSATIONS_FETCH_SUCCESS,
                                payload: bubbles
                            })
                        } else {
                            i = i + 1;
                        }*/
                    }


                }
                else {
                    removeInValidConversation(childSnapshot.key)
                    /*i = i + 1;
                    if (count === i && conversations.length === 0) {
                        dispatch({
                            type: CONVERSATIONS_FETCH_SUCCESS,
                            payload: []
                        })
                    } else if (count === i && conversations.length !== 0) {
                        const bubbles = _.map(conversations, (val, uid) => {
                            return {...val, uid};
                        });

                        i = count - 1;
                        dispatch({
                            type: CONVERSATIONS_FETCH_SUCCESS,
                            payload: bubbles
                        })
                    }*/
                }
            })
            openConversationsChangedListener = getOpenConversationsRef().on('child_changed', childSnapshot => {
                //convoCount(dispatch, childSnapshot)
                let lastMessageTimeStamp = '';
                let unreadCount = 0;

                if (childSnapshot.val().status && childSnapshot.val().patientID && !Boolean(childSnapshot.val().clinical_chat)) {
                    if(! (childSnapshot.val().isDelegate)){
                        if (childSnapshot.val().status && !childSnapshot.val().queued && !childSnapshot.val().doctorID) {
                            sendChatToQueue(childSnapshot.key)
                        }
                        let location = childSnapshot.key;
                        //fetchPatientDetails(dispatch, getState, childSnapshot.val().patientID)
                        //fetchDoctorDetail(dispatch, getState, childSnapshot.val().doctorID)
                        let conversationObj = {
                            ...childSnapshot.toJSON(),
                            conversationId: location,
                            ownerChat: currentUserID === childSnapshot.val().doctorID ? true : false,
                            chatInQueue: childSnapshot.val().queued,
                            paidChat: childSnapshot.val().paidChat,
                            issue: childSnapshot.val().issue !== undefined && childSnapshot.val().issue !== null ?
                                childSnapshot.val().issue : 'No Issue',
                            category: childSnapshot.val().category !== undefined && childSnapshot.val().category !== null ?
                                childSnapshot.val().category : 'No Category',
                            unreadCount: unreadCount,
                            lastMessageTimeStamp: lastMessageTimeStamp,
                            timestamp: now.getTime(),
                            start_timestamp: childSnapshot.val().timestamp,
                            transferedBy: !_.isUndefined(childSnapshot.val().transferedBy) ? childSnapshot.val().transferedBy : null,
                            assignedOn: !_.isUndefined(childSnapshot.val().assignedOn) ? childSnapshot.val().assignedOn : null,
                        }
                        dispatch({
                            type: CONVERSATIONS_FETCH_SUCCESS,
                            payload: conversationObj
                        })
                        /*var index = _.findIndex(conversations, {conversationId: conversationObj.conversationId});
                   index !== -1 ? conversations.splice(index, 1, conversationObj) : conversations.push(conversationObj);
                   if (count - 1 === i) {
                       const bubbles = _.map(conversations, (val, uid) => {
                           return {...val, uid};
                       });

                       i = count - 1;
                       dispatch({
                           type: CONVERSATIONS_FETCH_SUCCESS,
                           payload: bubbles
                       })
                   } else {
                       i = i + 1;
                   }*/
                    }



                }
                else {
                    removeInValidConversation(childSnapshot.key)
                    /*i = i + 1;
                    if (count === i && conversations.length === 0) {
                        dispatch({
                            type: CONVERSATIONS_FETCH_SUCCESS,
                            payload: []
                        })
                    } else if (count === i && conversations.length !== 0) {
                        const bubbles = _.map(conversations, (val, uid) => {
                            return {...val, uid};
                        });

                        i = count - 1;
                        dispatch({
                            type: CONVERSATIONS_FETCH_SUCCESS,
                            payload: bubbles
                        })
                    }*/
                }
            })
            openConversationsRemovedListener = getOpenConversationsRef().on('child_removed', childSnapshot => {
                //let lastMessageTimeStamp = '';
                //let unreadCount = 0;
                dispatch({
                    type: CONVERSATIONS_REMOVED,
                    payload: childSnapshot.key
                })
                if(childSnapshot.val().patientID){
                    dispatch({
                        type: REMOVE_PATIENT,
                        payload: childSnapshot.val().patientID
                    })
                }

                //removePatient(dispatch, getState, childSnapshot.val().patientID)
                /*if (childSnapshot.val().status && childSnapshot.val().patientID && !Boolean(childSnapshot.val().clinical_chat)) {
                    if (childSnapshot.val().status && !childSnapshot.val().queued && !childSnapshot.val().doctorID) {
                        sendChatToQueue(childSnapshot.key)
                    }
                    let location = childSnapshot.key;
                    if(childSnapshot.val().patientID){

                        //fetchDoctorDetail(dispatch, getState, childSnapshot.val().doctorID)
                        let conversationObj = {
                            ...childSnapshot.toJSON(),
                            conversationId: location,
                            ownerChat: currentUserID === childSnapshot.val().doctorID ? true : false,
                            chatInQueue: childSnapshot.val().queued,
                            paidChat: childSnapshot.val().paidChat,
                            issue: childSnapshot.val().issue !== undefined && childSnapshot.val().issue !== null ?
                                childSnapshot.val().issue : 'No Issue',
                            category: childSnapshot.val().category !== undefined && childSnapshot.val().category !== null ?
                                childSnapshot.val().category : 'No Category',
                            unreadCount: unreadCount,
                            lastMessageTimeStamp: lastMessageTimeStamp,
                            timestamp: now.getTime(),
                            start_timestamp: childSnapshot.val().timestamp,
                            transferedBy: !_.isUndefined(childSnapshot.val().transferedBy) ? childSnapshot.val().transferedBy : null,
                            assignedOn: !_.isUndefined(childSnapshot.val().assignedOn) ? childSnapshot.val().assignedOn : null,
                        }
                        dispatch({
                            type: CONVERSATIONS_FETCH_SUCCESS,
                            payload: conversationObj
                        })
                        /!*var index = _.findIndex(conversations, {conversationId: conversationObj.conversationId});
                        index !== -1 ? conversations.splice(index, 1, conversationObj) : conversations.push(conversationObj);
                        if (count - 1 === i) {
                            const bubbles = _.map(conversations, (val, uid) => {
                                return {...val, uid};
                            });

                            i = count - 1;
                            dispatch({
                                type: CONVERSATIONS_FETCH_SUCCESS,
                                payload: bubbles
                            })
                        } else {
                            i = i + 1;
                        }*!/
                    }else{
                        removeInValidConversation(childSnapshot.key)
                        /!*i = i + 1;
                        if (count === i && conversations.length === 0) {
                            dispatch({
                                type: CONVERSATIONS_FETCH_SUCCESS,
                                payload: []
                            })
                        } else if (count === i && conversations.length !== 0) {
                            const bubbles = _.map(conversations, (val, uid) => {
                                return {...val, uid};
                            });

                            i = count - 1;
                            dispatch({
                                type: CONVERSATIONS_FETCH_SUCCESS,
                                payload: bubbles
                            })
                        }*!/
                    }
                } else {
                    removeInValidConversation(childSnapshot.key)
                    /!*i = i + 1;
                    if (count === i && conversations.length === 0) {
                        dispatch({
                            type: CONVERSATIONS_FETCH_SUCCESS,
                            payload: []
                        })
                    } else if (count === i && conversations.length !== 0) {
                        const bubbles = _.map(conversations, (val, uid) => {
                            return {...val, uid};
                        });

                        i = count - 1;
                        dispatch({
                            type: CONVERSATIONS_FETCH_SUCCESS,
                            payload: bubbles
                        })
                    }*!/
                }*/
            })


            /* openConversationsListener.on('value', conversationsSnapShot => {
                if (conversationsSnapShot.val()) {
                    convoCount(dispatch, conversationsSnapShot)
                    var count = conversationsSnapShot.numChildren();
                    var i = 0;
                    var conversations = [];
                    conversationsSnapShot.forEach(function (childSnapshot) {
                        var lastMessageTimeStamp = '';
                        var unreadCount = 0;

                        if (childSnapshot.val().status && childSnapshot.val().patientID && !Boolean(childSnapshot.val().clinical_chat)) {
                            if (childSnapshot.val().status && !childSnapshot.val().queued && !childSnapshot.val().doctorID) {
                                sendChatToQueue(childSnapshot.key)
                            }
                            let location = childSnapshot.key;
                            if(childSnapshot.val().patientID){
                                fetchPatientDetails(dispatch, getState, childSnapshot.val().patientID)
                                //fetchDoctorDetail(dispatch, getState, childSnapshot.val().doctorID)
                                let conversationObj = {
                                    ...childSnapshot.toJSON(),
                                    conversationId: location,
                                    ownerChat: currentUserID === childSnapshot.val().doctorID ? true : false,
                                    chatInQueue: childSnapshot.val().queued,
                                    paidChat: childSnapshot.val().paidChat,
                                    issue: childSnapshot.val().issue !== undefined && childSnapshot.val().issue !== null ?
                                        childSnapshot.val().issue : 'No Issue',
                                    category: childSnapshot.val().category !== undefined && childSnapshot.val().category !== null ?
                                        childSnapshot.val().category : 'No Category',
                                    unreadCount: unreadCount,
                                    lastMessageTimeStamp: lastMessageTimeStamp,
                                    timestamp: now.getTime(),
                                    start_timestamp: childSnapshot.val().timestamp,
                                    transferedBy: !_.isUndefined(childSnapshot.val().transferedBy) ? childSnapshot.val().transferedBy : null,
                                    assignedOn: !_.isUndefined(childSnapshot.val().assignedOn) ? childSnapshot.val().assignedOn : null,
                                }
                                var index = _.findIndex(conversations, {conversationId: conversationObj.conversationId});
                                index !== -1 ? conversations.splice(index, 1, conversationObj) : conversations.push(conversationObj);
                                if (count - 1 === i) {
                                    const bubbles = _.map(conversations, (val, uid) => {
                                        return {...val, uid};
                                    });

                                    i = count - 1;
                                    dispatch({
                                        type: CONVERSATIONS_FETCH_SUCCESS,
                                        payload: bubbles
                                    })
                                } else {
                                    i = i + 1;
                                }
                            }else{
                                removeInValidConversation(childSnapshot.key)
                                i = i + 1;
                                if (count === i && conversations.length === 0) {
                                    dispatch({
                                        type: CONVERSATIONS_FETCH_SUCCESS,
                                        payload: []
                                    })
                                } else if (count === i && conversations.length !== 0) {
                                    const bubbles = _.map(conversations, (val, uid) => {
                                        return {...val, uid};
                                    });

                                    i = count - 1;
                                    dispatch({
                                        type: CONVERSATIONS_FETCH_SUCCESS,
                                        payload: bubbles
                                    })
                                }
                            }
                        } else {
                            removeInValidConversation(childSnapshot.key)
                            i = i + 1;
                            if (count === i && conversations.length === 0) {
                                dispatch({
                                    type: CONVERSATIONS_FETCH_SUCCESS,
                                    payload: []
                                })
                            } else if (count === i && conversations.length !== 0) {
                                const bubbles = _.map(conversations, (val, uid) => {
                                    return {...val, uid};
                                });

                                i = count - 1;
                                dispatch({
                                    type: CONVERSATIONS_FETCH_SUCCESS,
                                    payload: bubbles
                                })
                            }
                        }
                    })
                } else {
                    dispatch({
                        type: CONVERSATIONS_FETCH_SUCCESS,
                        payload: []
                    })
                }
            }) */
        } catch (error) {
            console.log("fetchConversations error ", error);
        }

    }
}


export const fetchConversationCount = () => {
    return (dispatch) => {
        try {
            /*getOpenConversationsRef().on('value', conversationsSnapShot => {
                if (conversationsSnapShot.val()) {

                }
            })*/

        } catch (error) {
            console.log("fetchConversationCount error ", error);
        }

    }
}

export const fetchDoctorsNameAndImages = () => {
    return (dispatch) => {
        try {
            console.log("fetchDoctorsNameAndImages triggered ", (new Date()).toString() )
            const {currentUser} = auth;
            let currentUserID = currentUser.uid;
            if(doctorsImagesListener){
                getDoctors().off('value', doctorsImagesListener);
                doctorsImagesListener = null;
            }
            doctorsImagesListener = getDoctors().orderByChild('profileStatus').equalTo("active").on('value', doctorsSnapshot => {
                //console.log("fetchDoctorsNameAndImages fetched ", (new Date()).toString() )
                if (doctorsSnapshot.val()) {
                    const doctors = _.map(doctorsSnapshot.val(), (val, uid) => {
                        return {...val, uid};
                    });
                    dispatch({
                        type: FETCH_DOCTORS_NAME_AND_IMAGES,
                        payload: doctors
                    })
                } else {
                    dispatch({
                        type: FETCH_DOCTORS_NAME_AND_IMAGES,
                        payload: []
                    })
                }
                if (doctorsSnapshot.val()) {
                    const doctors = _.map(doctorsSnapshot.val(), (val, uid) => {
                        if (uid !== currentUserID && val.online &&
                            val.profileStatus !== "deleted" &&
                            val.accepting_chat && !val.clinicalDoctor) {
                            return {...val, uid};
                        }
                    });

                    var transferDoctors = doctors.filter(function (el) {
                        return el != null && el !== "" && el !== undefined;
                    });

                    return dispatch({
                        type: FETCH_ONLINE_DOCTORS,
                        payload: transferDoctors
                    })
                } else {
                    return dispatch({
                        type: FETCH_ONLINE_DOCTORS,
                        payload: []
                    })
                }
            })
        } catch (error) {
            console.log("fetchDoctorsNameAndImages error ", error);
        }

    }
}

export const sendCloseChatNotification = (notificationToken, doctorName, chatId, doctorID) => {
    axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/closeChatInapp?notificationToken=${notificationToken}&doctorName=${doctorName}&chatId=${chatId}&doctorID=${doctorID}`,
        mode: 'cors',
    }).then(data=>{}).catch(e=>{})
    axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/closeChat?notificationToken=${notificationToken}&doctorName=${doctorName}&chatId=${chatId}&doctorID=${doctorID}`,
        mode: 'cors',
    }).then(data=>{}).catch(e=>{})


}

export const sendJoinChatNotification = (notificationToken, doctorName) => {
    axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/joinChat?notificationToken=${notificationToken}&doctorName=${doctorName}`,
        mode: 'cors',
    }).then(data=>{}).catch(e=>{})
    axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/joinChatInapp?notificationToken=${notificationToken}&doctorName=${doctorName}`,
        mode: 'cors',
    }).then(data=>{}).catch(e=>{})


}

export const fetchStatus = (conversationID) => {
    return (dispatch) => {

        getOpenConversationsWithLocationRef(conversationID).once('value', statusSnap => {
            dispatch({
                type: FETCH_STATUS,
                payload: {
                    followUpStatus: statusSnap.val()?.followUpStatus,
                    skipStatus: statusSnap.val()?.skipStatus,
                    followUpID: statusSnap.val()?.followUpID
                }
            })

        })
    }
}

export const setFollowUpStatus = (conversationID, clinicid, scheuledDate, followUpID) => {
    return (dispatch) => {
        dispatch({type: "setting_status"});

        var updates = {};
        if (clinicid) {
            updates[`/openClinicalConversations/${clinicid}/${conversationID}/followUpDate`] = scheuledDate;
            updates[`/openClinicalConversations/${clinicid}/${conversationID}/followUpStatus`] = true;
            if (followUpID) updates[`/openClinicalConversations/${clinicid}/${conversationID}/followUpID`] = followUpID;
        } else {
            updates[`/openconversations/${conversationID}/followUpStatus`] = true;
        }
        database.ref().update(updates)
            .then(data => {
                let payload =
                    dispatch({
                        type: FETCH_STATUS,
                        payload: {
                            followUpStatus: true,
                            followupDate: scheuledDate,
                            skipStatus: 'nosave',
                            followUpID: followUpID
                        }
                    })
            })
            .catch((error) => {
                console.log("Error updating followup");
            })
    }
}

export const setSkipStatus = (conversationID, clinic_id) => {
    return (dispatch) => {
        dispatch({type: "setting_status"});

        var updates = {};

        if (clinic_id) {
            updates[`/openClinicalConversations/${clinic_id}/${conversationID}/skipStatus`] = true;
        } else {
            updates[`/openconversations/${conversationID}/skipStatus`] = true;
        }
        database.ref().update(updates)
            .then(data => {
            })
            .catch((error) => {
                console.log("Error updating followup")
            })

    }
}

export const fetchChatTags = (conversationID) => {
    return (dispatch) => {
        dispatch({type: FETCH_CHAT_TAGS});
        if(openConversationsWithLocationAddTagListener){
            openConversationsWithLocationAddTagRef.off('value', openConversationsWithLocationAddTagListener);
            openConversationsWithLocationAddTagListener = null;
            openConversationsWithLocationAddTagRef = null;
        }
        openConversationsWithLocationAddTagRef = getOpenConversationsWithLocationAddTagRef(conversationID)
        openConversationsWithLocationAddTagListener = openConversationsWithLocationAddTagRef.on('value', chatTagsSnap => {
            if (chatTagsSnap.val()) {
                var tags = _.map(chatTagsSnap.val(), (val, uid) => {
                    return {...val, uid};
                });

                dispatch({
                    type: FETCH_CHAT_TAGS_SUCCESS,
                    payload: tags
                })
            } else {
                dispatch({
                    type: FETCH_CHAT_TAGS_FAILED,
                    payload: []
                })
            }
        })
    }
}

export const saveTagInChat = (conversationID, tagsList) => {
    return (dispatch) => {
        var updates = {};
        let tagLocation = getOpenConversationsWithLocationAddTagRef(conversationID).push().key;
        if (tagsList.name === 'rating_screen') {
            updates[`/openconversations/${conversationID}/tagsList/${tagLocation}`] = tagsList;
            updates[`/openconversations/${conversationID}/rateChatFlag`] = true;
            database.ref().update(updates)
                .then(data => {
                })
                .catch((error) => {
                    //console.log("error: ", error.message);
                })
        } else {
            console.log("saveTagInChat tagsList ", tagsList)
            updates[`/openconversations/${conversationID}/tagsList/${tagLocation}`] = tagsList;
            //updates[`/openconversations/${conversationID}/rateChatFlag`] = true;
            database.ref().update(updates)
                .then(data => {
                })
                .catch((error) => {
                    //console.log("error: ", error.message);
                })
        }
    }
}

export const save_followUp = (patientId, symptoms, doctorName, patientName, dateTime, email, offset, chat_type) => {
    return (dispatch) => {
        return dispatch({
            type: FOLLOW_SAVE_DONE,
        });
        // TODO disabling AWS
        /* dispatch({
            type: FOLLOW_SAVE_PROGRESS,
        })
        var root = 'https://admin.yourdoctors.online/api/0.1/';
        fetch(rootURL + `admin_users/getuser?email=${encodeURIComponent(email)}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(success => {
                var date_to_send;
                if (isNaN(offset)) {
                    date_to_send = moment(dateTime).utc().format("YYYY-MM-DD HH:mm:ss");
                } else {
                    date_to_send = moment(dateTime).add(parseInt(offset) * -1, 'hours').format("YYYY-MM-DD HH:mm:ss");
                }
                var actual_date = moment(dateTime).format(("YYYY-MM-DD HH:mm:ss"))
                const followUpDate = new Date(dateTime)
                const finalDate = new Date()
                const diffTime = Math.abs(followUpDate - finalDate);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1
                getUserRef(patientId).once('value', userSnapshot => {
                    var followUpData = {
                        user_id: userSnapshot.val().user_id !== null ? userSnapshot.val().user_id : success.id,
                        patient_issue: symptoms,
                        doctor_name: doctorName,
                        diagnosis: "",
                        duration: diffDays,
                        patient_name: patientName,
                        starting_date: date_to_send,
                        actual_date: actual_date,
                        chat_type: chat_type
                    };
                    fetch(root + `follow_ups`, {
                        method: "post",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(followUpData)
                    })
                        .then(response => response.json())
                        .then(response => {
                            dispatch({
                                type: FOLLOW_SAVE_DONE,
                            })
                        })
                })
            }) */
    }

}

export const set_notification = (user_id, followUp_id, index, clinic_id, patientFirebaseId) => {
    return (dispatch, getState) => {
        var all_followUp = getState().conversationReducer.all_followUp
        all_followUp[index].notification_sent = 1
        return dispatch({
            type: FOLLOW_UP_CHANGE,
            payload: all_followUp
        });
        // TODO disabling AWS
        /*setTimeout(() => {
            let followUpList = getState().conversationReducer.all_followUp;
            let dates = getState().conversationReducer.all_time

            var newArr = followUpList.reduce(function (acc, curr, index) {
                if (curr.id == followUp_id) {
                    followUpList.splice(index, 1);
                    dates.splice(index, 1);
                    let followObject = {
                        follow_up: followUpList,
                        time: dates
                    }
                    dispatch({
                        type: FOLLOW_UP_REMOVAL,
                        payload: followObject
                    })
                }
                return acc;
            }, []);


            // dispatch({
            //     type: FOLLOW_UP_REMOVAL,
            //     payload: followObject
            // })
        }, 1000)
        if (clinic_id) {
            let url = 'https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/send_followup_notification?';
            if (ENVIRONMENT === 'development')
                url = 'https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/send_followup_notification?';

            fetch(doctorServicesUrl + `setFollowUpNotification/${encodeURI(followUp_id)}`, {
                method: "put",
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(response => response.json())
                .then(success => {
                    fetch(url + `user_id=${user_id}&followUp_id=${followUp_id}&clinic_id=${clinic_id}&patient_firebase_id=${patientFirebaseId}`, {
                        method: "get",
                        headers: {
                            "Content-Type": "application/json"
                        },

                    })
                        .then(response => response.json())
                        .then(success => {

                        }).catch(error => {
                        console.log("send_followup_notification error", error)
                        })

                }).catch(error => {
                })

        } else {
            fetch(rootURL + `follow_ups/setNotification?id=${followUp_id}`, {
                method: "put",
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(response => response.json())
                .then(success => {
                    fetch(`https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/send_followup_notification?user_id=${user_id}&followUp_id=${followUp_id}`, {
                        method: "get",
                        headers: {
                            "Content-Type": "application/json"
                        },

                    })
                        .then(response => response.json())
                        .then(success => {
                        })
                })
        }*/


    }

}
export const removeFollowUpBySettingNotification = (user_id, followUp_id, index, clinic_id, patientFirebaseId) => {
    return (dispatch, getState) => {
        var all_followUp = getState().conversationReducer.all_followUp
        all_followUp[index].notification_sent = 1
        return dispatch({
            type: FOLLOW_UP_CHANGE,
            payload: all_followUp
        });

        // TODO disabling AWS

        /*setTimeout(() => {
            let followUpList = getState().conversationReducer.all_followUp;
            let dates = getState().conversationReducer.all_time

            var newArr = followUpList.reduce(function (acc, curr, index) {
                if (curr.id == followUp_id) {
                    followUpList.splice(index, 1);
                    dates.splice(index, 1);
                    let followObject = {
                        follow_up: followUpList,
                        time: dates
                    }
                    dispatch({
                        type: FOLLOW_UP_REMOVAL,
                        payload: followObject
                    })
                }
                return acc;
            }, []);


            // dispatch({
            //     type: FOLLOW_UP_REMOVAL,
            //     payload: followObject
            // })
        }, 1000)
        if (clinic_id) {
            let url = 'https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/send_followup_notification?';
            if (ENVIRONMENT === 'development')
                url = 'https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/send_followup_notification?';

            fetch(doctorServicesUrl + `setFollowUpNotification/${encodeURI(followUp_id)}`, {
                method: "put",
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(response => response.json())
                .then(success => {
                }).catch(error => {
                console.log("setFollowUpNotification error", error)
            })

        } else {
            fetch(rootURL + `follow_ups/setNotification?id=${followUp_id}`, {
                method: "put",
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(response => response.json())
                .then(success => {
                })
        }*/


    }
}
export const resetSelectedConversationId = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_CONVERSATIONID
        })
    }
}

export const get_followUp = (chat_type, doctor_name, clinic_id) => {
    return (dispatch) => {
        try {
            //TODO disabling AWS
            /*if (clinic_id) {
                fetch(doctorServicesUrl + `clinical_followup/${encodeURI(doctor_name)}/${encodeURI(clinic_id)}`, {
                    // mode: 'no-cors',
                })
                    .then(response => response.json())
                    .then(raw_data => {
                        let data = [];
                        let timeData = [];

                        for (let i = 0; i < raw_data.length; i++) {
                            let slot = {};
                            let scheduledDate = new Date(raw_data[i].scheduledDate);

                            slot.actual_date = new Date(raw_data[i].actualdate);
                            slot.category = raw_data[i].category;
                            slot.clicked = raw_data[i].clicked;
                            slot.clinicId = raw_data[i].clinicId;
                            slot.completed = raw_data[i].completed;
                            slot.diagnosis = raw_data[i].diagnosis;
                            slot.doctorFirebaseId = raw_data[i].doctorFirebaseId;
                            slot.doctor_name = raw_data[i].doctorName;
                            slot.medical_details = raw_data[i].medicalDetails;
                            slot.duration = raw_data[i].duration;
                            slot.endDate = new Date(raw_data[i].endDate);
                            slot.id = raw_data[i].id;
                            slot.message = raw_data[i].message;
                            slot.notification_sent = raw_data[i].notificationSent;
                            slot.patientFirebaseId = raw_data[i].patientFirebaseId;
                            slot.patient_issue = raw_data[i].patientIssue;
                            slot.patient_name = raw_data[i].patientName;
                            slot.scheduled_date = new Date(raw_data[i].scheduledDate);
                            slot.user_id = raw_data[i].userid;
                            slot.slot = raw_data[i].slot;
                            slot.conversationId = raw_data[i].conversationId;
                            data.push(slot);
                            let wait_time = (scheduledDate.getTime() - (new Date()).getTime()) / 3600;
                            timeData.push(wait_time);
                        }
                        let success = {follow_up: data, time: timeData};
                        dispatch({
                            type: FOLLOW_UP,
                            payload: success
                        })
                    })
                    .catch(error => {
                        console.log("get_followUp error ", error);
                    })
            } else {
                var bodyData = {
                    chat_type: chat_type,
                    doctor_name: doctor_name
                }
                fetch(rootURL + `follow_ups/doctorFollowUp`, {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(bodyData)
                })
                    .then(response => response.json())
                    .then(success => {
                        dispatch({
                            type: FOLLOW_UP,
                            payload: success
                        })
                    })
                    .catch(error => console.log("error ", error ))
            }
*/
        } catch (error) {
            console.log("get_followUp error ", error);
        }


    }

}

export const removeTagInChat = (conversationID, tagsList) => {
    return (dispatch) => {
        var updates = {};
        updates[`/openconversations/${conversationID}/tagsList/${tagsList.uid}`] = null;
        database.ref().update(updates)
            .then(data => {
            })
            .catch((error) => {
                //console.log("error: ", error.message);
            })
    }
}

const sendFirstChatEmail = (historyCount, patientID, doctorID) => {
    if (historyCount === 1) {

        var rootCloudURL = `https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/createEmailTask?uid=${patientID}&doctorID=${doctorID}&flowType=1`;;
        if (ENVIRONMENT === "development") {
            rootCloudURL = `https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/createEmailTask?uid=${patientID}&doctorID=${doctorID}&flowType=1`;
        }

        fetch(rootCloudURL)
            .then(() => {
            })
            .catch(() => {
                //console.log("sign up tasks completed");
            })
    }

}

const storeCloseChatIndex = (conversationID) => {
    database.ref(`archiveConversations/${conversationID}`).once('value', archiveSnap => {
        if (archiveSnap.val()) {
            if (!archiveSnap.val()?.status) {
                var convos = '';
                database.ref(`/conversations/${conversationID}`).once('value', convoSnap => {
                    convoSnap.forEach(convoInstance => {
                        if (convoInstance.val().type === 'text') {
                            convos = convos + " " + convoInstance.val().content;
                        }
                    });
                }).then(data => {
                    database.ref(`/doctors/${archiveSnap.val().doctorID}`).once('value', doctorSnap => {
                        if (doctorSnap.val()) {
                            database.ref(`/users/${archiveSnap.val().patientID}`).once('value', patientSnap => {
                                if (patientSnap.val()) {
                                    let userName = ((patientSnap.val().userName === 'No name' || patientSnap.val().userName === 'Patient') && patientSnap.val().name) ? patientSnap.val().name : patientSnap.val().userName;
                                    let archiveData = archiveSnap.toJSON();
                                    let tags = "";
                                    if (Boolean(archiveData.tagsList)) {
                                        var tagslist = _.map(archiveData.tagsList, (val, uid) => {
                                            return {...val, uid};
                                        });
                                        tagslist.forEach(tag => {
                                            if (Boolean(tag.name)) {
                                                tags = tags + ', ' + tag.name;
                                            }
                                        })
                                    }
                                    let chatCount = patientSnap.val().history_conversations;

                                    archiveData.tags = tags;
                                    archiveData.objectID = archiveSnap.key;
                                    archiveData.conversations = convos;
                                    archiveData.category = archiveSnap.val().category;
                                    archiveData.doctorID = archiveSnap.val().doctorID;
                                    archiveData.patientID = archiveSnap.val().patientID;
                                    archiveData.conversationID = archiveSnap.key;
                                    archiveData.patientEmail = patientSnap.val().email;
                                    archiveData.city = patientSnap.val().city;
                                    archiveData.country = patientSnap.val().country;
                                    archiveData.platform = patientSnap.val().platform;
                                    archiveData.DOB = patientSnap.val().DOB;
                                    archiveData.gender = patientSnap.val().gender;
                                    archiveData.symptoms = archiveData.followUpData?.diagnosis || patientSnap.val().symptoms;
                                    archiveData.medication = patientSnap.val().medication;
                                    archiveData.gmtOffset = patientSnap.val().gmtOffset;
                                    archiveData.buildNumber = patientSnap.val().buildNumber;
                                    archiveData.patientName = userName;
                                    archiveData.convoCount = chatCount !== null && chatCount !== undefined ? Object.keys(chatCount).length : 0;
                                    archiveData.doctorName = doctorSnap.val().doctorName;
                                    archiveData.doctorID = archiveSnap.val().doctorID;
                                    archiveData.doctorProfileImg = doctorSnap.val().profileImageURL;
                                    archiveData.doctorEmail = doctorSnap.val().email;
                                    archiveData.user_id = patientSnap.val().user_id;
                                    archiveData.healthInsuranceCardBack = patientSnap.val().healthInsuranceCardBack;
                                    archiveData.healthInsuranceCardFront = patientSnap.val().healthInsuranceCardFront;
                                    archiveData.healthInsuranceCardNumber = patientSnap.val().healthInsuranceCardNumber;
                                    index_store.addObject(archiveData)
                                        .then(data => {
                                        })
                                        .catch(error => {
                                        })
                                }
                            }).then((d) => {
                            }).catch((error) => {
                            })
                        }
                    }).then((d) => {
                    }).catch((error) => {
                    })

                }).then((d) => {
                }).catch((error) => {
                })
            }
        }
    }).then((d) => {
    }).catch((error) => {
    })
}
export const destroyThread = (convoId, email) => {
    return (dispatch) => {
        fetch(`https://api.yourdoctors.online/patient/destroyThread`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'

            },
            body: JSON.stringify({
                chatId: convoId,
                email: email
            })
        })
    }

}
export const resetConversationListeners = () => {
    return (dispatch) => {
        if(doctorsListener){
            getDoctors().off('value', doctorsListener);
            doctorsListener = null;
        }
        if(userListener && userListenerRef) {
            userListenerRef.off('value', userListener)
            userListener = null
            userListenerRef = null
        }
        if(openConversationsListener) {
            getOpenConversationsRef().off('child_added', openConversationsListener);
            openConversationsListener = null;

        };
        if(openConversationsRemovedListener) {
            getOpenConversationsRef().off('child_removed', openConversationsRemovedListener);
            openConversationsRemovedListener = null
        }
        if(openConversationsWithLocationAddTagListener){
            openConversationsWithLocationAddTagRef.off('value', openConversationsWithLocationAddTagListener);
            openConversationsWithLocationAddTagListener = null;
            openConversationsWithLocationAddTagRef = null;
        }
        if(doctorsImagesListener){
            doctorsImagesListener.off();
            doctorsImagesListener = null;
        }
        if(openClinicalConvoListener){
            openClinicRef.off('child_changed', openClinicalConvoListener)
            openClinicRef = null;
            openClinicalConvoListener = null;
        }
        if(openConvoListener){
            getOpenConversationsRef().off('child_changed', openConvoListener )
            openConvoListener = null;
        }
        return dispatch({
            type: REFRESH_CONVERSATION
        })
    }
}
const sendPdf = (prescription_pdf_data,patientId, conversationId, user_medications,prescriptionspdf, callback) => {
    try{
        if(prescriptionspdf){
            callback(prescriptionspdf);
            return;
        }
        if(user_medications?.length>0){
            var uploadTask = storage.ref(`/prescriptions/tier1/${patientId}/${conversationId}/`).child(`${prescription_pdf_data?.presFilename}`).put(prescription_pdf_data?.presFile, prescription_pdf_data?.metadata);
            uploadTask.on("state_changed" , (snapshot) => {

                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    switch (snapshot.state) {
                        case storageRef.TaskState.PAUSED: // or 'paused'
                            break;
                        case storageRef.TaskState.RUNNING: // or 'running'
                            break;
                    }
                },
                (error) => {
                    console.log("firebasestorage error ", error)
                    callback(null)
                    // Handle unsuccessful uploads
                },
                () => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        let cloudFunctionURL = ENVIRONMENT === "production"? "https://us-central1-yourdoctorsonline-prod.cloudfunctions.net" :"https://us-central1-yourdoctorsonline-staging.cloudfunctions.net"
                        fetch(cloudFunctionURL + '/sendPrescriptionFax',{
                            method: "POST", // *GET, POST, PUT, DELETE, etc.
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                perscription_url: downloadURL,
                                fax_number:prescription_pdf_data?.pharmacy?.fax_number
                            })// body data type must match "Content-Type" header
                        }).then(data => {

                        }).catch(error => {

                        })
                        callback(downloadURL)
                    }).catch((error) =>{
                        callback(null)
                    })
                });
        }else{
            callback(null)
        }
    }catch(e){
        callback(null)
    }

}

const sendPrescriptionEmail = (emailData) => {
    let cloudFunctionURL = ENVIRONMENT === "production"? "https://us-central1-yourdoctorsonline-prod.cloudfunctions.net" :"https://us-central1-yourdoctorsonline-staging.cloudfunctions.net"
    if(emailData?.patient_name){
        fetch(cloudFunctionURL + '/sendPresriptionEmailConfirmation', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(emailData),
        })
            .then(data => {}).catch(error => console.log("email call failed", error))
    }

}

export const startAgoraCall = (callInfo) => {
    return (dispatch) => {
        return dispatch({
            type: SET_CALL_STATUS,
            payload: callInfo
        });
    }
}

export const endAgoraCall = () => {
    return (dispatch) => {
        return dispatch({
            type: SET_CALL_STATUS,
            payload: null
        });
    }
}

const sendPrescriptionMessageToUser = (conversationID, content,patientId, doctorName, doctorImage, dispatch) => {
    sendMessage(
        conversationID,
    { type: "text", content },
        patientId,
        doctorName,
        doctorImage,
        dispatch)
}

export const setChatHistoryItemClicked = (value) => {
    return (dispatch) => {
        return dispatch({
            type: SET_CHAT_HISTORY_ITEM_CLICKED,
            payload: value
        });
    }
}
export const saveAndCloseOutPatientForm = (referralOptionSelected,  paidChat, conversationId, doctorID, admin, doctorName, patientName,
                                           patientId, selectedIssue, selectedCategory, tagChatList, assessment, opinionDescription, opinionDescriptionValue,
                                           followUpCheck, dateTime, medicationCheck, medication, plan,findings, heartRate, weightKg, weightLbs, stepCount,
                                           runningWalking, heightCm, currentSleep, averageSleep, heightFeet, periodStartDate, flowType, restingHeartRate,
                                           complete_address, start_time, issueResolved, prescription_pdf_data, user_medications, prescription_data,selectedClinicInfo, symptoms, soapnotespdf, snap) => {


    return (dispatch) => {
        console.log("saveAndCloseOutPatientForm triggered")
        dispatch({type: SAVE_OUT_PATIENT_FORM_IN_PROCESS});
        let options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            timeZone: "America/New_York",
            timeZoneName: "long",
            hour12: true,
        };
        var d = new Date();
        let conversationTime = d.toLocaleString("en-US", options);
        let attachment = null;
        const val = snap.val();
        sendPdf(prescription_pdf_data,patientId, conversationId, user_medications,val.prescriptionspdf, (prescription_path) => {
            console.log("sendPdf returned")

            getUserRef(patientId).once('value', userSnapshot => {
                console.log("getUserRef returned")
                if(user_medications.length > 0) {
                    let emailData = {
                        "user_medications": user_medications,
                        "patient_name": prescription_data?.name || patientName,
                        "patient_dob": prescription_data?.DOB || userSnapshot.val()?.DOB,
                        "patient_gender": prescription_data?.gender || userSnapshot.val()?.gender,
                        "patient_summary": prescription_data?.summary || "",
                        "current_medications": prescription_data?.current_medications || "",
                        "known_medical_conditions": prescription_data?.medical_conditions || "",
                        "patient_time": conversationTime,
                        "health_insurance_card_number": userSnapshot.val()?.healthInsuranceCardNumber,
                        "prescription_url": prescription_path,
                        "patient_phone_no": userSnapshot.val()?.phone_number || "",
                        "patient_email":userSnapshot.val()?.email || "",
                        "pharmacy": prescription_pdf_data?.pharmacy,
                        "doctorName": doctorName,
                        "istier2": false,
                        "doctor_emails": prescription_data?.doctor_emails || []
                    }
                    let content= `Your prescription has been sent to the pharmacy at fax number: ${prescription_pdf_data?.pharmacy?.fax_number}.It can be accessed at url: ${prescription_path}`
                    sendPrescriptionMessageToUser(conversationId, content,patientId, doctorName, prescription_pdf_data?.doctorImage, dispatch)
                    sendPrescriptionEmail(emailData)
                }


                if(val.prescriptionspdf){
                    prescription_path = val.prescriptionspdf
                }
                if(val.attachment){
                    attachment = val.attachment
                }
                closeChatNow(dispatch,  referralOptionSelected, userSnapshot,snap, userSnapshot.val()?.user_id || null, paidChat, conversationId, doctorID, admin, doctorName, patientName,
                    patientId, selectedIssue, selectedCategory, tagChatList, assessment, opinionDescription, opinionDescriptionValue,
                    followUpCheck, dateTime, medicationCheck, medication, plan, findings,
                    heartRate, weightKg, weightLbs, stepCount, runningWalking, heightCm,
                    currentSleep, averageSleep, heightFeet, periodStartDate, flowType, restingHeartRate,
                    complete_address, start_time, issueResolved, prescription_path, user_medications, prescription_data, selectedClinicInfo, symptoms, soapnotespdf, attachment);


                // TODO didn't get below logic
                /*if (userSnapshot.val()?.user_id) {
                database
                .ref(
                  `/openconversations/${conversationId}/`
                )
                .once("value")
                .then(async (snap) => {
                  const val = snap.val();
                  if(val.prescriptionspdf){
                    prescription_path = val.prescriptionspdf
                }
                if(val.attachment){
                   attachment = val.attachment
                }
                    closeChatNow(dispatch, userSnapshot,snap, null, paidChat, conversationId, doctorID, admin, doctorName, patientName,
                        patientId, selectedIssue, selectedCategory, tagChatList, assessment, opinionDescription, opinionDescriptionValue,
                        followUpCheck, dateTime, medicationCheck, medication, plan, findings,
                        heartRate, weightKg, weightLbs, stepCount, runningWalking, heightCm,
                        currentSleep, averageSleep, heightFeet, periodStartDate, flowType, restingHeartRate,
                        complete_address, start_time, issueResolved, prescription_path, user_medications, prescription_data, selectedClinicInfo, symptoms, soapnotespdf, attachment);
                    })
                    .catch(error => {
                        console.log("val.prescriptionspdf error ", error)
                    })
                }
                else {
                    closeChatNow(dispatch, userSnapshot, snap, null, paidChat, conversationId, doctorID, admin, doctorName, patientName,
                        patientId, selectedIssue, selectedCategory, tagChatList, assessment, opinionDescription, opinionDescriptionValue,
                        followUpCheck, dateTime, medicationCheck, medication, plan, findings,
                        heartRate, weightKg, weightLbs, stepCount, runningWalking, heightCm,
                        currentSleep, averageSleep, heightFeet, periodStartDate, flowType, restingHeartRate,
                        complete_address, start_time, issueResolved, prescription_path, user_medications, prescription_data, selectedClinicInfo,symptoms, soapnotespdf, attachment);

                    // TODO disabling AWS

                    /!*fetch(rootURL + `admin_users/getuser?email=${encodeURIComponent(userSnapshot.val().email)}`, {
                        method: "get",
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                        .then(response => response.json())
                        .then(success => {
                            if (Boolean(success?.user?.id)) {
                                database.ref(`/openconversations/${conversationId}/`).once("value")
                                .then(async (snap) => {
                                const val = snap.val();
                                if(val.prescriptionspdf){
                                    prescription_path = val.prescriptionspdf
                                }
                                if(val.attachment){
                                    attachment = val.attachment
                                }
                                closeChatNow(dispatch, userSnapshot, success?.user?.id, paidChat, conversationId, doctorID, admin, doctorName, patientName,
                                    patientId, selectedIssue, selectedCategory, tagChatList, assessment, opinionDescription, opinionDescriptionValue,
                                    followUpCheck, dateTime, medicationCheck, medication, plan, findings,
                                    heartRate, weightKg, weightLbs, stepCount, runningWalking, heightCm,
                                    currentSleep, averageSleep, heightFeet, periodStartDate, flowType, restingHeartRate,
                                    complete_address, start_time, issueResolved, prescription_path, user_medications, prescription_data, selectedClinicInfo, symptoms, soapnotespdf, attachment);
                                })
                                .catch(error => {
                                    console.log("val.prescriptionspdf error ", error)

                                })
                            }else{
                                closeChatNow(dispatch, userSnapshot, null, paidChat, conversationId, doctorID, admin, doctorName, patientName,
                                    patientId, selectedIssue, selectedCategory, tagChatList, assessment, opinionDescription, opinionDescriptionValue,
                                    followUpCheck, dateTime, medicationCheck, medication, plan, findings,
                                    heartRate, weightKg, weightLbs, stepCount, runningWalking, heightCm,
                                    currentSleep, averageSleep, heightFeet, periodStartDate, flowType, restingHeartRate,
                                    complete_address, start_time, issueResolved, prescription_path, user_medications, prescription_data, selectedClinicInfo, symptoms, soapnotespdf, attachment);
                            }
                        }).catch(error=> {
                        console.log("error", error)
                        closeChatNow(dispatch, userSnapshot, null, paidChat, conversationId, doctorID, admin, doctorName, patientName,
                            patientId, selectedIssue, selectedCategory, tagChatList, assessment, opinionDescription, opinionDescriptionValue,
                            followUpCheck, dateTime, medicationCheck, medication, plan, findings,
                            heartRate, weightKg, weightLbs, stepCount, runningWalking, heightCm,
                            currentSleep, averageSleep, heightFeet, periodStartDate, flowType, restingHeartRate,
                            complete_address, start_time, issueResolved, prescription_path, user_medications, prescription_data, selectedClinicInfo,symptoms, soapnotespdf, attachment);

                    })*!/
                }*/
            });

        })

    }
}

const closeChatNow = (dispatch,referralOptionSelected, userSnapshot, conversationSnap, userID, paidChat, conversationId, doctorID, admin, doctorName, patientName,
                      patientId, selectedIssue, selectedCategory, tagChatList, assessment, opinionDescription, opinionDescriptionValue, followUpCheck, dateTime, medicationCheck,
                      medication, plan,findings, heartRate, weightKg, weightLbs, stepCount, runningWalking, heightCm,
                      currentSleep, averageSleep, heightFeet, periodStartDate, flowType, restingHeartRate,
                      complete_address, start_time, issueResolved, prescription_path, user_medications, prescription_data, selectedClinicInfo, symptoms, soapnotespdf, attachment) => {

    // var root = 'https://admin.yourdoctors.online/api/0.1/';

    var followUpData = {};

    closeChat(dispatch, referralOptionSelected, paidChat, conversationId, patientId, doctorID, doctorName, patientId, admin, selectedIssue,
        selectedCategory, tagChatList, assessment, opinionDescription, opinionDescriptionValue, followUpCheck,
        null, medicationCheck, null, plan,findings, heartRate, weightKg, weightLbs, stepCount, runningWalking, heightCm,
        currentSleep, averageSleep, heightFeet, periodStartDate, flowType, restingHeartRate, complete_address,
        start_time, issueResolved, prescription_path, user_medications, prescription_data, selectedClinicInfo, symptoms, soapnotespdf, attachment, userSnapshot,conversationSnap );

    dispatch({type: SAVE_OUT_PATIENT_FORM_SUCCESS});

    {/*if (medicationCheck && followUpCheck) {

        var diagnosisData = {
            user_id: userSnapshot.val().user_id !== null ? userSnapshot.val().user_id : userID,
            diagnosis: diagnosisValue,
            message: commentAdded

        }
        fetch(root + `follow_ups/addDiagnosis`, {
            method: "put",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(diagnosisData)
        })
        .then(response => response.json())
        .then(response => {

            var medCount = medication.length;
            var i = 0;
            var storedMedications = [];
            medication.forEach(function (med) {
                var medicationData = {
                    user_id: userSnapshot.val().user_id !== null ? userSnapshot.val().user_id : userID,
                    medicine_name: med.medicationName,
                    quantity: med.medicationDosage,
                    unit: med.medicationUnit,
                    frequency: med.frequency,
                    frequency_interval: med.frequencyInterval
                };


                fetch(root + "stores", {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(medicationData)
                })
                .then(medResponse => medResponse.json())
                .then(medResponse => {
                    if (medResponse.medication) {
                        sendMedicationNotificationToUser(userSnapshot.val().notificationToken, doctorName);
                        storedMedications.push(medResponse.medication);
                        i++;
                        if (i === medCount) {
                            closeChat(dispatch, paidChat, conversationId, patientId, doctorID, doctorName,
                                patientId, admin, selectedIssue, selectedCategory, tagChatList, diagnosisValue,
                                opinionDescription, opinionDescriptionValue, followUpCheck, response.follow_up,
                                medicationCheck, storedMedications, commentAdded, heartRate, weightKg, weightLbs, stepCount,
                                runningWalking, heightCm, currentSleep, averageSleep, heightFeet, periodStartDate, flowType,
                                restingHeartRate, complete_address, start_time, issueResolved);
                            dispatch({ type: SAVE_OUT_PATIENT_FORM_SUCCESS });
                        }
                    }
                });
            });
        });
    } else if (followUpCheck) {
        diagnosisData = {
            user_id: userSnapshot.val().user_id !== null ? userSnapshot.val().user_id : userID,
            diagnosis: diagnosisValue,
            message: commentAdded

        }
        fetch(root + `follow_ups/addDiagnosis`, {
            method: "put",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(diagnosisData)
        })
        .then(response => response.json())
        .then(response => {
            if (response.follow_up) {
                closeChat(dispatch, paidChat, conversationId, patientId, doctorID, doctorName,
                    patientId, admin, selectedIssue, selectedCategory, tagChatList, diagnosisValue, opinionDescription,
                    opinionDescriptionValue, followUpCheck, response.follow_up, medicationCheck, null, commentAdded,
                    heartRate, weightKg, weightLbs, stepCount, runningWalking, heightCm, currentSleep, averageSleep,
                    heightFeet, periodStartDate, flowType, restingHeartRate, complete_address, start_time,
                    issueResolved);
                dispatch({ type: SAVE_OUT_PATIENT_FORM_SUCCESS });
            }
        });

    } else if (medicationCheck) {
        var medCount = medication.length;
        var i = 0;
        var storedMedications = [];
        medication.forEach(function (med) {
            var medicationData = {
                user_id: userSnapshot.val().user_id !== null ? userSnapshot.val().user_id : userID,
                medicine_name: med.medicationName,
                quantity: med.medicationDosage,
                unit: med.medicationUnit,
                frequency: med.frequency,
                frequency_interval: med.frequencyInterval
            };

            fetch(root + "stores", {
                method: "post",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(medicationData)
            })
            .then(response => response.json())
            .then(response => {
                if (response.medication) {
                    sendMedicationNotificationToUser(userSnapshot.val().notificationToken, doctorName);
                    storedMedications.push(response.medication);
                    i++;

                    if (i === medCount) {
                        closeChat(dispatch, paidChat, conversationId, patientId, doctorID, doctorName,
                            patientId, admin, selectedIssue, selectedCategory, tagChatList, diagnosisValue, opinionDescription,
                            opinionDescriptionValue, followUpCheck, followUpData, medicationCheck, storedMedications,
                            commentAdded, heartRate, weightKg, weightLbs, stepCount, runningWalking, heightCm, currentSleep,
                            averageSleep, heightFeet, periodStartDate, flowType, restingHeartRate, complete_address,
                            start_time, issueResolved);
                        dispatch({ type: SAVE_OUT_PATIENT_FORM_SUCCESS });
                    }
                }
            });
        })
    } else {
        //console.log("both are false");

    }
    */
    }
}

export const refreshState = () => {
    return {
        type: REFRESH_STATE
    }
}

export const updateClinicalOutPatientForm = (patientName, doctorName, archive_userID, archive_conversationID,
                                             findings, assessment, plan, icd, billing_code, billing_unit,
                                             prescriptions, requisitions, special_referals, video_consult, followUpDays, clinicId, symptoms) => {
    return (dispatch) => {

        dispatch({type: SAVE_CLINICAL_OUT_PATIENT_FORM_IN_PROCESS});

        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        updateClinicalChatDetail(clinicId, archive_conversationID).update({
            symptoms: symptoms,
            findings: findings,
            assessment: assessment,
            plan: plan,
            icd: icd,
            billing_code: billing_code,
            billing_unit: billing_unit,
            prescriptions: prescriptions,
            requisitions: requisitions,
            special_referals: special_referals,
            video_consult: video_consult,
            followUpDays: followUpDays,
        }).then(() => {
            return dispatch({
                type: SAVE_CLINICAL_OUT_PATIENT_FORM_UPDATED,
                payload: {
                    symptoms: symptoms,
                    findings: findings,
                    assessment: assessment,
                    plan: plan,
                    icd: icd,
                    billing_code: billing_code,
                    billing_unit: billing_unit,
                    prescriptions: prescriptions,
                    requisitions: requisitions,
                    special_referals: special_referals,
                    video_consult: video_consult,
                    followUpDays: followUpDays,
                }
            });
        })
            .catch((error) => {
                console.log('error', error.message);
            });

    }
}

export const setVideoState = (location, type, clinic_id) => {
    return (dispatch) => {
        var updates = {};
        if (clinic_id) {
            database.ref(`/openClinicalConversations/${clinic_id}/${location}`).once('value', locationSnap => {
                if (type === 'Video') {
                    if (locationSnap.val().videoChatStatus !== 'Initiate Video Chat' && locationSnap.val().videoChatStatus !== 'Initiate Audio Chat' && locationSnap.val().videoChatStatus !== 'Initiated') {
                        updates[`/openClinicalConversations/${clinic_id}/${location}/videoChatStatus`] = 'Initiate Video Chat';
                        database.ref().update(updates)
                            .then(data => {
                            })
                            .catch((error) => {
                                //console.log("Message send update error: 1 " + error);
                            });
                    } else {
                        updates[`/openClinicalConversations/${clinic_id}/${location}/videoChatStatus`] = 'Ended';
                        database.ref().update(updates)
                            .then(data => {
                                updates = {}
                                updates[`/openClinicalConversations/${clinic_id}/${location}/videoChatStatus`] = 'Initiate Video Chat';
                                database.ref().update(updates)
                                    .then(data => {
                                    })
                                    .catch((error) => {
                                        //console.log("Message send update error: 1 " + error);
                                    });
                            })
                            .catch((error) => {
                                //console.log("Message send update error: 1 " + error);
                            });
                    }
                } else {
                    if (locationSnap.val().videoChatStatus !== 'Initiate Audio Chat' && locationSnap.val().videoChatStatus !== 'Initiate Video Chat' && locationSnap.val().videoChatStatus !== 'Initiated') {
                        updates[`/openClinicalConversations/${clinic_id}/${location}/videoChatStatus`] = 'Initiate Audio Chat';
                        database.ref().update(updates)
                            .then(data => {
                            })
                            .catch((error) => {
                                //console.log("Message send update error: 1 " + error);
                            });
                    } else {
                        updates[`openClinicalConversations/${clinic_id}/${location}/videoChatStatus`] = 'Ended';
                        database.ref().update(updates)
                            .then(data => {
                                updates = {}
                                updates[`openClinicalConversations/${clinic_id}/${location}/videoChatStatus`] = 'Initiate Audio Chat';
                                database.ref().update(updates)
                                    .then(data => {
                                    })
                                    .catch((error) => {
                                        //console.log("Message send update error: 1 " + error);
                                    });
                            })
                            .catch((error) => {
                                //console.log("Message send update error: 1 " + error);
                            });
                    }
                }
            })

        } else {
            database.ref(`/openconversations/${location}`).once('value', locationSnap => {
                if (type === 'Video') {
                    if (locationSnap.val().videoChatStatus !== 'Initiate Video Chat' && locationSnap.val().videoChatStatus !== 'Initiate Audio Chat' && locationSnap.val().videoChatStatus !== 'Initiated') {
                        updates[`/openconversations/${location}/videoChatStatus`] = 'Initiate Video Chat';
                        database.ref().update(updates)
                            .then(data => {
                            })
                            .catch((error) => {
                                //console.log("Message send update error: 1 " + error);
                            });
                    } else {
                        updates[`/openconversations/${location}/videoChatStatus`] = 'Ended';
                        database.ref().update(updates)
                            .then(data => {
                                updates = {}
                                updates[`/openconversations/${location}/videoChatStatus`] = 'Initiate Video Chat';
                                database.ref().update(updates)
                                    .then(data => {
                                    })
                                    .catch((error) => {
                                        //console.log("Message send update error: 1 " + error);
                                    });
                            })
                            .catch((error) => {
                                //console.log("Message send update error: 1 " + error);
                            });
                    }
                } else {
                    if (locationSnap.val().videoChatStatus !== 'Initiate Audio Chat' && locationSnap.val().videoChatStatus !== 'Initiate Video Chat' && locationSnap.val().videoChatStatus !== 'Initiated') {
                        updates[`/openconversations/${location}/videoChatStatus`] = 'Initiate Audio Chat';
                        database.ref().update(updates)
                            .then(data => {
                            })
                            .catch((error) => {
                                //console.log("Message send update error: 1 " + error);
                            });
                    } else {
                        updates[`/openconversations/${location}/videoChatStatus`] = 'Ended';
                        database.ref().update(updates)
                            .then(data => {
                                updates = {}
                                updates[`/openconversations/${location}/videoChatStatus`] = 'Initiate Audio Chat';
                                database.ref().update(updates)
                                    .then(data => {
                                    })
                                    .catch((error) => {
                                        //console.log("Message send update error: 1 " + error);
                                    });
                            })
                            .catch((error) => {
                                //console.log("Message send update error: 1 " + error);
                            });
                    }
                }
            })

        }

    }
}

export const closePatientChat = (paidChat, conversationId, doctorID, admin, doctorName, patientName,
                                 patientId, selectedIssue, selectedCategory, tagChatList,
                                 heartRate, weightKg, weightLbs, stepCount, runningWalking, heightCm,
                                 currentSleep, averageSleep, heightFeet, periodStartDate, flowType, restingHeartRate,
                                 complete_address, start_time) => {
    return (dispatch) => {
        console.log("closePatientChat triggered")

        closeChat(dispatch, paidChat, conversationId, patientId, doctorID, doctorName, patientId, admin, selectedIssue, selectedCategory, tagChatList,
            null, false, null, false, null, false, null, null,
            heartRate, weightKg, weightLbs, stepCount, runningWalking, heightCm, currentSleep, averageSleep, heightFeet, periodStartDate, flowType,
            restingHeartRate, complete_address, null);
    }
}

const updateRemainingCloseChat = (dispatch, doctorID, conversationID, archiveData, patientID, historyID, closeData, toID, doctorName, start_time) => {
    var updates = {};
    getDoctorRef(doctorID).once('value', doctorSnap => {
        if (doctorSnap.val()) {

            updates[`/archiveConversations/${conversationID}`] = archiveData;
            updates[`/users/${patientID}/conversations`] = null;
            updates[`/openconversations/${conversationID}`] = null;
            updates[`/users/${patientID}/history_conversations/${conversationID}`] = closeData;

            database.ref().update(updates).then(data => {
                storeCloseChatIndex(conversationID);
                database.ref('users/' + toID).once('value', function (snapshot) {
                    var user_data = snapshot.val();
                    var historyCount = Object.keys(user_data?.history_conversations || []).length;
                    sendFirstChatEmail(historyCount, patientID, doctorID);
                    sendCloseChatNotification(user_data.notificationToken, doctorName, conversationID, doctorID)
                });
                checkQueueStatus(dispatch, conversationID, doctorID, doctorSnap.val().paidChatLimit, doctorName, start_time, doctorSnap.toJSON());
            })
                .catch((error) => {
                    alert("chat is not properly closed");
                    //console.log("close chat update error: 2 " + error);
                });

        }
    });
}

const closeChat = (dispatch,referralOptionSelected, paidChat, conversationID, patientID, doctorID, doctorName, toID, isadmin, issue, category, tags,
                   assessment, opinionDescription, opinionDescriptionValue, followUpCheck, followUpData, medicationCheck, medicationData,
                   plan,findings, heartRate, weightKg, weightLbs, stepCount, runningWalking, heightCm,
                   currentSleep, averageSleep, heightFeet, periodStartDate, flowType, restingHeartRate,
                   complete_address, start_time, issueResolved, prescription_path, medications, prescription_data, selectedClinicInfo, symptoms, soapnotespdf, attachment,userSnap,convosnap) => {
    const found = tags.some(item => item.name === 'rating_screen');
    var now = new Date();
    var updates = {};
    let owner = convosnap.val().owner === patientID? null: convosnap.val().owner || null;
    var historyID = getUserHistoryConversationRef(patientID).push().key;
    let adminDoctor = convosnap.val().adminDoctor || null;
    /* let medications = [];
    if (user_medications.length > 0) {
        for (let i = 0; i < user_medications.length; i++) {
            let med = {...user_medications[i]};
            medications.push(med);
        }
        convosnap
    } */
    console.log("closeChat triggered")
    var archiveData = {
        ...convosnap.toJSON(),
        activeTimestamp: convosnap.val().activeTimestamp || null,
        start_timestamp: convosnap.val().timestamp || null,
        adminDoctor,
        referralRequired:referralOptionSelected || null,
        status: false,
        queued: false,
        patientID: patientID,
        doctorID: doctorID || convosnap.val()?.doctorID,
        timestamp: now.getTime(),
        partner_id: convosnap.val()?.partner_id || null,
        isPaid: Boolean(paidChat),
        issue: issue || null,
        symptoms:  symptoms || convosnap.val().symptoms || null,
        soapnotespdf : soapnotespdf || null,
        attachment: attachment || null,
        category: category || null,
        diagnosisValue: assessment || null,
        assessment:assessment || null,
        opinionDescriptionValue: opinionDescription ? opinionDescriptionValue : null,
        // followUpData: followUpCheck ? followUpData[0] : null,
        // medications: medicationCheck ? medicationData : null,
        followUpData: null,
        medications: null,
        commentAdded: plan || null,
        plan:plan || null,
        findings:findings || null,
        issueResolved: issueResolved || null,
        tagsList: tags,
        rateChatFlag: found,
        heartRate: heartRate === 'NaN'? "NA" : heartRate || "NA",
        weightKg: weightKg === 'NaN'? "NA" : weightKg || "NA",
        weightLbs: weightLbs === 'NaN'? "NA" : weightLbs || "NA",
        stepCount: stepCount === 'NaN'? "NA" : stepCount || "NA",
        runningWalking: runningWalking === 'NaN'? "NA" : runningWalking || "NA",
        heightCm: heightCm === 'NaN'? "NA" : heightCm || "NA",
        currentSleep: currentSleep === 'NaN'? "NA" : currentSleep || "NA",
        averageSleep: averageSleep === 'NaN'? "NA" : averageSleep || "NA",
        heightFeet: heightFeet === 'NaN'? "NA" : heightFeet || "NA",
        periodStartDate: periodStartDate === 'NaN'? "NA" : periodStartDate || "NA",
        flowType: flowType === 'NaN'? "NA" : flowType || "NA",
        restingHeartRate: restingHeartRate === 'NaN'? "NA" : restingHeartRate || "NA",
        complete_address: complete_address || "NA",
        owner
    }
    console.log("closeChat archiveData", archiveData)
    if (medications.length > 0) {
        archiveData.medications = medications;
        if(prescription_data) archiveData.prescription_data = prescription_data

    }
    if(prescription_path) archiveData.prescriptionspdf = prescription_path;
    var closeData = {
        ...convosnap.toJSON(),
        activeTimestamp: convosnap.val().activeTimestamp || convosnap.val().timestamp || null,
        start_timestamp: convosnap.val().timestamp || null,
        adminDoctor,
        status: false,
        queued: false,
        patientID: patientID,
        referralRequired:referralOptionSelected || null,
        doctorID: doctorID,
        timestamp: now.getTime(),
        isPaid: Boolean(paidChat),
        issue: issue || null,
        symptoms:  symptoms || convosnap.val().symptoms || null,
        category: category || null,
        location: conversationID,
        diagnosisValue: assessment || null,
        assessment:assessment || null,
        findings:findings || null,
        opinionDescriptionValue: opinionDescription ? opinionDescriptionValue : null,
        // followUpData: followUpCheck ? followUpData[0] : null,
        // medications: medicationCheck ? medicationData : null,
        commentAdded: plan || null,
        plan:plan || null,
        issueResolved: issueResolved || null,
        tagsList: tags || null,
        rateChatFlag: found || null,
        tier2ClinicData : selectedClinicInfo || null,
        owner
    }
    if(prescription_path) {
        closeData.prescriptionspdf = prescription_path;
        closeData.billableChat =  true;
    }
    let userInfo = (userSnap.exists()) ? userSnap.toJSON() : null;
    let sources = userInfo?.sources || {};
    let sourceKeys = Object.keys(sources);
    let sourceValues = Object.values(sources);
    let timeStamp = null;
    let source = null;
    if (sourceKeys.length > 0) {
        timeStamp = sourceValues[0];
        source = sourceKeys[0]
        sourceValues.forEach((sourceTime, index) => {
            if (sourceTime > timeStamp) {
                timeStamp = sourceTime;
                source = sourceKeys[index]
            }
        })
    }
    archiveData.source = source;
    closeData.source = source;
    if (!isadmin) {
        /*getOpenConversationsWithLocationRef(conversationID).update({status: false})
            .then(() => {*/
                if (convosnap.val()?.stripeToken) {
                    updateRemainingCloseChat(dispatch, doctorID, conversationID, archiveData, patientID,
                        conversationID, closeData, toID, doctorName, start_time);
                } else {
                    updateRemainingCloseChat(dispatch, doctorID, conversationID, archiveData, patientID,
                        conversationID, closeData, toID, doctorName, start_time);
                }
                let pres_update = {}
                if (medications.length > 0) {
                    let pres = {};
                    pres.timestamp = now.getTime();
                    pres.prescriptionspdf = prescription_path;
                    pres.prescriptions = medications;
                    pres.symptoms = issue;
                    pres.doctorID = doctorID;
                    pres.doctorName = doctorName;
                    pres.isSentToPharmacy = true;
                    pres_update[`/users/${patientID}/history_prescriptions/${conversationID}`] = pres;
                }
        database.ref().update(pres_update).then((main) => {
                        console.log('uploadUsersUpdate main ', main);
                    })
                    .catch((error) => {
                        console.log('uploadUsersUpdate error ', error);
                    });
                /*database.ref().update(pres_update)
                    .then(data => {}).catch(error=>{console.log("databaseerror ", error)})*/
            /*})
            .catch((error) => {
                console.log("getOpenConversationsWithLocationRef error", error);
                updateRemainingCloseChat(dispatch, doctorID, conversationID, archiveData, patientID,
                    historyID, closeData, toID, doctorName, start_time);

                let pres_update = {}
                if (medications.length > 0) {
                    let pres = {};
                    pres.timestamp = now.getTime();
                    pres.prescriptionspdf = prescription_path;
                    pres.prescriptions = medications;
                    pres.symptoms = issue;
                    pres.doctorID = doctorID;
                    pres.doctorName = doctorName;
                    pres.isSentToPharmacy = true;
                    pres_update[`/users/${patientID}/history_prescriptions/${conversationID}`] = pres;
                }
                database.ref().update(pres_update).then((main) => {
                        console.log('uploadUsersUpdate main ', main);
                    })
                    .catch((error) => {
                        console.log('uploadUsersUpdate error ', error);
                    });

            })*/
    } else {
        /*getOpenConversationsWithLocationRef(conversationID).update({status: false})
            .then(() => {*/
                getDoctorRef(convosnap.val().doctorID).once('value', async doctorSnap => {
                    if (doctorSnap.val()) {
                        if (medications.length > 0) {
                            let pres = {};
                            pres.timestamp = now.getTime();
                            pres.prescriptionspdf = prescription_path;
                            pres.prescriptions = medications;
                            pres.symptoms = issue;
                            pres.doctorID = doctorID;
                            pres.doctorName = doctorName;
                            pres.isSentToPharmacy = true;
                            updates[`/users/${patientID}/history_prescriptions/${conversationID}`] = pres;
                        }
                        updates[`/archiveConversations/${conversationID}`] = archiveData;
                        updates[`/users/${patientID}/conversations`] = null;
                        updates[`/openconversations/${conversationID}`] = null;
                        updates[`/users/${patientID}/history_conversations/${conversationID}`] = closeData;

                        database.ref().update(updates).then(data => {

                                storeCloseChatIndex(conversationID);
                                dispatch({
                                    type: CLOSE_CHAT,
                                    payload: {
                                        conversationId: conversationID
                                    }
                                });

                                database.ref('users/' + toID).once('value', function (snapshot) {
                                    var user_data = snapshot.val();
                                    // if()
                                    var historyCount = Boolean(user_data.history_conversations) ? Object.keys(user_data.history_conversations).length : 0;
                                    sendFirstChatEmail(historyCount, patientID, doctorID);
                                    sendCloseChatNotification(user_data.notificationToken, doctorName, conversationID, doctorID);
                                });
                            })
                            .catch((error) => {

                                alert("chat is not properly closed");
                                console.log("close chat update error: 2 " + error);
                                let pres_update = {}
                                if (medications.length > 0) {
                                    let pres = {};
                                    pres.timestamp = now.getTime();
                                    pres.prescriptionspdf = prescription_path;
                                    pres.prescriptions = medications;
                                    pres.symptoms = issue;
                                    pres.doctorID = doctorID;
                                    pres.doctorName = doctorName;
                                    pres.isSentToPharmacy = true;
                                    pres_update[`/users/${patientID}/history_prescriptions/${conversationID}`] = pres;
                                }
                                database.ref().update(pres_update).then(data => {}).catch(error=>{})

                            });

                    }
                });
            /*})
            .catch((error) => {
                //console.log("close chat failed");

            })*/
    }

}


export const updateOutPatientForm = (issue, patientName, doctorName, userID, conversationID, assessment, followUpCheck, dateTime,
                                     medicationCheck, medication, plan,findings, followUpUpdated, archive_patientFollowUpData, issueResolved, symptoms) => {
    return (dispatch) => {

        dispatch({type: SAVE_OUT_PATIENT_FORM_IN_PROCESS});
        updateChatDetail(conversationID).update({
            symptoms: symptoms,
            assessment:assessment,
            plan:plan,
            findings:findings,
            issueResolved: issueResolved,
        }).then(() => {
            storeCloseChatIndex(conversationID)
            return dispatch({
                type: SAVE_OUT_PATIENT_FORM_UPDATED,
                payload: {
                    symptoms: symptoms,
                    findings:findings,
                    plan:plan,
                    assessment:assessment,
                    issueResolved: issueResolved,
                    conversationID:conversationID
                }
            });
        }).catch(error=> {
            return dispatch({
                type: SAVE_OUT_PATIENT_FORM_UPDATED,
                payload: {
                    symptoms: symptoms,
                    findings:findings,
                    plan:plan,
                    assessment:assessment,
                    issueResolved: issueResolved,
                }
            });
        })
    }
}

export const fetchOnlineDoctors = () => {
    return (dispatch) => {
        /*const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        try {
            if(doctorsListener){
                getDoctors().off('value', doctorsListener);
                doctorsListener = null;
            }
            doctorsListener = getDoctors().on('value', doctorsSnapshot => {
                if (doctorsSnapshot.val()) {
                    const doctors = _.map(doctorsSnapshot.val(), (val, uid) => {
                        if (uid !== currentUserID && val.online &&
                            val.profileStatus !== "deleted" &&
                            val.accepting_chat && !val.clinicalDoctor) {
                            return {...val, uid};
                        }
                    });

                    var transferDoctors = doctors.filter(function (el) {
                        return el != null && el !== "" && el !== undefined;
                    });

                    return dispatch({
                        type: FETCH_ONLINE_DOCTORS,
                        payload: transferDoctors
                    })
                } else {
                    return dispatch({
                        type: FETCH_ONLINE_DOCTORS,
                        payload: []
                    })
                }
            })
        } catch (error) {
            console.log("fetchOnlineDoctors error ", error);
        }*/

    }
}

export const transferChatToAnotherDoctor = (conversationId, doctor, paidChat, isadmin, patientName) => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        var updates = {};
        getOpenConversationsWithLocationRef(conversationId).once('value', conversationDataSnap => {
            if (conversationDataSnap.val()) {
                let conversationData = conversationDataSnap.val()

                conversationData.doctorID = doctor.uid
                conversationData.transferedBy = currentUserID
                conversationData.assignedOn = 'transfered_chat'


                getOpenConversationsWithLocationRef(conversationId).update(conversationData)
                    .then(() => {
                        if (ENVIRONMENT === 'development') {
                            fetch(`https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/send_transferNotif`, {
                                method: 'POST',
                                body: JSON.stringify({
                                    'notificationToken': doctor.notification_token,
                                    'patientName': patientName
                                })
                            })
                        } else {
                            fetch(`https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/send_transferNotif`, {
                                method: 'POST',
                                body: JSON.stringify({
                                    'notificationToken': doctor.notification_token,
                                    'patientName': patientName
                                })
                            })
                        }

                        getDoctorRef(doctor.uid).once('value', newdoctorSnap => {
                            if (newdoctorSnap.val()) {
                                getDoctorRef(conversationDataSnap.val().doctorID).once('value', olddoctorSnap => {
                                    if (olddoctorSnap.val()) {
                                        updates[`/doctors/${doctor.uid}/activeOnlineChats`] = newdoctorSnap.val().activeOnlineChats + 1;
                                        updates[`/doctors/${olddoctorSnap.key}/activeOnlineChats`] = olddoctorSnap.val().activeOnlineChats - 1 >= 0 ? olddoctorSnap.val().activeOnlineChats - 1 : 0;
                                        database.ref().update(updates)
                                            .then(data => {
                                            })
                                            .catch((error) => {
                                                console.log("Transferred the chat error: 1 " + error);
                                            });
                                    }
                                });
                            }
                        });
                        return dispatch({type: CHAT_TRANSFERED_DONE});
                    })
                    .catch((error) => {
                        return dispatch({
                            type: CHAT_TRANSFERED_DONE,
                            payload: error.message
                        })
                    })
            }
        });
    }
}

export const childChanged = (clinicID) => {
    return (dispatch) => {
        try {
            if (clinicID) {
                if(openClinicalConvoListener){
                    openClinicRef.off('child_changed', openClinicalConvoListener)
                    openClinicRef = null;
                    openClinicalConvoListener = null;
                }
                openClinicRef = getOpenClinicConversationsRef(clinicID)
                openClinicalConvoListener = openClinicRef.on('child_changed', childChangedSnap => {
                    if (childChangedSnap.val().status === false) {
                        dispatch({
                            type: CLOSE_CHAT_OTHER,
                            payload: childChangedSnap.key
                        })
                    }
                    if (childChangedSnap.val().videoChatStatus === 'Declined') {
                        dispatch({
                            type: VIDEO_CHAT_DECLINED,
                            payload: childChangedSnap.val().doctorID
                        })
                    }
                })
            } else {
                if(openConvoListener){
                    getOpenConversationsRef().off('child_changed', openConvoListener )
                    openConvoListener = null;
                }
                openConvoListener = getOpenConversationsRef().on('child_changed', childChangedSnap => {
                    if (childChangedSnap.val().status === false) {
                        dispatch({
                            type: CLOSE_CHAT_OTHER,
                            payload: childChangedSnap.key
                        })
                    }
                    if (childChangedSnap.val().videoChatStatus === 'Declined') {
                        dispatch({
                            type: VIDEO_CHAT_DECLINED,
                            payload: childChangedSnap.val().doctorID
                        })
                    }
                })
            }

        } catch (error) {
            console.log("childChanged error ", error);
        }

    }
}

export const refreshDeclineStatus = () => {
    return (dispatch) => {
        dispatch({
            type: REFRESH_VIDEO_CHAT_DECLINED,
        })
    }
}

export const endVideoCall = (location, clinic_id) => {
    return (dispatch) => {
        var updates = {}
        if (Boolean(clinic_id)) {
            updates[`/openClinicalConversations/${clinic_id}/${location}/videoChatStatus`] = 'Ended';
            database.ref().update(updates)
                .then(data => {
                })
                .catch(error => {
                });
        } else {
            updates[`/openconversations/${location}/videoChatStatus`] = 'Ended';
            database.ref().update(updates)
                .then(data => {
                })
                .catch(error => {
                });
        }

    }
}

const sendMedicationNotificationToUser = (notificationToken, doctorName) => {

    axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': true
        },
        url: `https://us-central1-yourdoctorsonline-dbbe3.cloudfunctions.net/sendMedicationNotificationToUser?notificationToken=${notificationToken}&doctorName=${doctorName}`,
        mode: 'cors',
    }).then(data=>{}).catch(e=>{})

    axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': true
        },
        url: `https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/sendMedicationNotificationToUser?notificationToken=${notificationToken}&doctorName=${doctorName}`,
        mode: 'cors',
    }).then(data=>{}).catch(e=>{})
}

const checkQueueStatus = (dispatch, conversationID, doctorID, chatLimit, doctorName, start_time, doctorObj) => {
    getOpenConversationsRef().once('value', conversationsSnapShot => {
        let isEMRChats = doctorObj.doctorChatType === "health-card"
        if (conversationsSnapShot.val()) {
            let doctorChatCount = 0;
            const queuedAllChats = _.map(conversationsSnapShot.val(), (val, uid) => {
                if (val.status && val.queued && !Boolean(val.clinical_chat)) {

                    if(val.doctorID === doctorID && uid !== conversationID) doctorChatCount++;

                    if(isEMRChats && val.isEMRChat){
                        return {...val, uid};
                    }else if(!isEMRChats && !val.isEMRChat){
                        return {...val, uid};
                    }

                }
            });
            let totalChatLimit = doctorObj.totalChatLimit || 0;
            if(totalChatLimit<=doctorChatCount){
                dispatch({
                    type: CLOSE_CHAT,
                    payload: {conversationId: conversationID}
                })
                return;
            }
            var queuedChat = queuedAllChats.filter(function (el) {
                return el !== undefined
            });
            if (queuedChat.length !== 0) {

                var soretdQueueChat = _.sortBy(queuedChat, ['timestamp']);
                const {uid, patientID, issue, category} = soretdQueueChat[0];

                const bubbles = _.map(conversationsSnapShot.val(), (val, uid) => {
                    if (val.status && !val.queued && !Boolean(val.clinical_chat)) {
                        return {...val, uid};
                    }
                });

                var filteredBubbles = bubbles.filter(function (el) {
                    return el != null && el !== "" && el !== undefined;
                });

                if (filteredBubbles.length === 0) {
                    assignQueueChat(dispatch, doctorID, uid, patientID, issue, category, conversationID, doctorName, start_time);
                } else {

                    var result = filteredBubbles.length !== 0 && filteredBubbles.reduce(function (obj, v) {
                        // increment or set the property
                        // `(obj[v.status] || 0)` returns the property value if defined
                        // or 0 ( since `undefined` is a falsy value
                        if (!v.queued && !Boolean(v.clinical_chat) && v.status) {
                            obj[v.doctorID] = (obj[v.doctorID] || 0) + 1;
                        }
                        // return the updated object
                        return obj;
                        // set the initial value as an object
                    }, {});

                    var resultCount = _.map(result, (onlinePaidChats, doctorID) => {
                        return {onlinePaidChats, doctorID};
                    });

                    var onlineChats = resultCount.length !== 0 && resultCount.find(chat => chat.doctorID === doctorID);
                        if (doctorObj && doctorObj.accepting_chat) {
                            if (onlineChats === false || _.isUndefined(onlineChats)) {
                                assignQueueChat(dispatch, doctorID, uid, patientID, issue, category, conversationID, doctorName, start_time);
                            } else if (onlineChats !== false && onlineChats.onlinePaidChats < doctorObj.totalChatLimit) {
                                assignQueueChat(dispatch, doctorID, uid, patientID, issue, category, conversationID, doctorName, start_time);
                            } else {
                                dispatch({
                                    type: CLOSE_CHAT,
                                    payload: {conversationId: conversationID}
                                })
                            }
                        } else {
                            dispatch({
                                type: CLOSE_CHAT,
                                payload: {conversationId: conversationID}
                            })
                        }
                }
            } else {
                dispatch({
                    type: CLOSE_CHAT,
                    payload: {conversationId: conversationID}
                })
            }

        } else {
            dispatch({
                type: CLOSE_CHAT,
                payload: {conversationId: conversationID}
            })
        }
    })
}

const assignQueueChat = (dispatch, doctorID, uid, patientID, issue, category, conversationID, doctorName, start_time) => {
    var now = new Date();
    getOpenConversationsWithLocationRef(uid).once('value', conversationData => {
        getOpenConversationsWithLocationRef(uid).update({
            queued: false,
            doctorID: doctorID,
            assignedOn: 'close_chat',
            activeTimestamp: now.getTime()
        })
            .then(() => {
                getDoctorRef(doctorID).once('value', newdoctorSnap => {
                    if (newdoctorSnap.val()) {
                        var updates = {};

                        //let doctorData = {location: uid, patientID: patientID};
                        let patientData = {
                            location: uid,
                            doctorID: doctorID,
                            issue: issue,
                            category: category
                        };

                        /*let messageValue = {
                            type: "text",
                            timestamp: now.getTime(),
                            content: "",
                            fromID: doctorID,
                            toID: patientID,
                            isRead: false,
                            doctorName: newdoctorSnap.val().doctorName,
                            doctorImgURL: newdoctorSnap.val().profileImageURL
                        };*/

                        //let newMessagekey = getConversationRef(uid).push().key;
                        //messageValue.content = `Hi! Thanks for waiting, My name is ${newdoctorSnap.val().doctorName}.`;

                        let latestExistQueueChatData = {
                            location: uid,
                            start_time: conversationData.val().timestamp,
                            waitEndTime: now.getTime()
                        };

                        updates[`/latestExistQueueChat/`] = latestExistQueueChatData;
                        //updates[`/conversations/${uid}/${newMessagekey}`] = messageValue;
                        updates[`/doctors/${doctorID}/activeOnlineChats`] = newdoctorSnap.val().activeOnlineChats + 1;
                        //updates[`/doctors/${doctorID}/conversations/${uid}`] = doctorData;
                        updates[`/users/${patientID}/conversations/location`] = uid;
                        updates[`/users/${patientID}/conversations/doctorID`] = doctorID;
                        updates[`/users/${patientID}/conversations/issue`] = issue;
                        updates[`/users/${patientID}/conversations/category`] = category;

                        database.ref().update(updates).then(data => {
                        })
                            .catch((error) => {
                                //console.log("Message send update error: 1 " + error);
                            });

                        const onlineAgent={
                            doctorID:doctorID,
                            profileImageURL:newdoctorSnap.val().profileImageURL,
                            doctorName:newdoctorSnap.val().doctorName
                        }
                        const sendFirstMessageBody = {
                            onlineAgent,
                            conversation_id: uid,
                            patient_id: patientID,
                        };
                        axios({
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            url: `${firebaseRoot}/sendDoctorFirstMessage`,
                            mode: 'cors',
                            data: JSON.stringify(sendFirstMessageBody),
                        })
                            .then((main) => {
                                console.log('sendDoctorFirstMessage main ', main.data);
                            })
                            .catch((error) => {
                                console.log('sendDoctorFirstMessage error ', error);
                            });
                    }
                })

                dispatch({
                    type: CLOSE_CHAT,
                    payload: {conversationId: conversationID}
                })

                dispatch({
                    type: PICKED_FROM_QUEUE,
                    payload: doctorID
                })

                database.ref('users/' + patientID).once('value', function (snapshot) {
                    var user_data = snapshot.val();
                    sendJoinChatNotification(user_data.notificationToken, doctorName)
                });
            })
    })
}

const getConversationRef = (location) => {
    return database.ref(`/conversations/${location}`);
}

const getOpenConversationsRef = () => {
    return database.ref(`/openconversations/`);
}
const getOpenClinicConversationsRef = (clinicID) => {
    return database.ref(`/openClinicalConversations/${clinicID}`);
}

const getOpenConversationsWithLocationRef = (location) => {
    return database.ref(`/openconversations/${location}`);
}

const getOpenConversationsWithLocationAddTagRef = (location) => {
    return database.ref(`/openconversations/${location}/tagsList`);
}

const getUserRef = (uid) => {
    return database.ref(`/users/${uid}`);
}

const getUserHistoryConversationRef = (uid) => {
    return database.ref(`/users/${uid}/history_conversations`);
}

const getDoctorRef = (uid) => {
    return database.ref(`/doctors/${uid}`);
}
const getClinicStaffMemeberRef = (clinicID, uid) => {
    return database.ref(`/clinicStaffMember/${clinicID}/${uid}`);
}

const getDoctors = () => {
    return database.ref().child('doctors');
}

const updateChatDetail = (conversationID) => {
    return database.ref().child(`/archiveConversations/${conversationID}`);
}

const getActivitiesRef = () => {
    return database.ref(`/activities`);
}

const updateClinicalChatDetail = (doctorClinicName, uid) => {
    return database.ref(`/clinicArchiveConversations/${doctorClinicName}/${uid}`);
}
