import {Component, createRef, Fragment} from 'react';
import {connect} from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import classes from '../../../assets/classes.module.css';

import {
    accountType,
    addMoreUserIntoStaff,
    addStaffMember,
    anwserPatients,
    createPrescription,
    getReports,
    invitePatients,
    needStaffAddress,
    showStaffProfileImage,
    showStaffSignature,
    soapNotes,
    staffAddress,
    staffCity,
    staffCountry,
    staffEmail,
    staffFaxNo,
    staffFullName,
    staffLicenseNo,
    staffPhone,
    staffPhoneNumber,
    staffProfileAdmin,
    staffProfileCustom,
    staffProfileNurse,
    staffProfileOwner,
    staffProfilePhysician,
    staffProfileReceptionist,
    staffProvince,
    transferChat,
    viewArchives,
    viewInsuranceInfo,
    viewPatientsInQueue
} from '../../../actions/ClinicStaffAction';
import {ClinicDrBillingNo} from '../../../actions/clinic/create/CreateClinicAction';

import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import csc from 'country-state-city';

class ClinicalStaff extends Component {

    constructor(props) {
        super(props);
        this.textInput = createRef();
        this.signatureFile = createRef();
        this.state = {
            file: '',
            signature_file: '',
            countries: {'options': csc.getAllCountries()},
            provinces: {'options': csc.getStatesOfCountry("38")},
        }
    }

    changeEmail = (event) => {
        this.props.staffEmail(event.target.value);
    }

    changeProfile = (event) => {
        event.target.value === 'owner' ? this.props.staffProfileOwner(event.target.value) :
            event.target.value === 'admin' ? this.props.staffProfileAdmin(event.target.value) :
                event.target.value === 'physician' ? this.props.staffProfilePhysician(event.target.value) :
                    event.target.value === 'nurse' ? this.props.staffProfileNurse(event.target.value) :
                        event.target.value === 'receptionist' ? this.props.staffProfileReceptionist(event.target.value) : this.props.staffProfileCustom(event.target.value);
    }

    changeAddMoreUsers = (event) => {
        this.props.addMoreUserIntoStaff(event.target.checked);
    }

    changeCreatePrescription = (event) => {
        this.props.createPrescription(event.target.checked);
    }

    changeInvitePatients = (event) => {
        this.props.invitePatients(event.target.checked);
    }

    changeSoapNotes = (event) => {
        this.props.soapNotes(event.target.checked);
    }

    changeAnswerPatients = (event) => {
        this.props.anwserPatients(event.target.checked);
    }

    changeTransferChat = (event) => {
        this.props.transferChat(event.target.checked);
    }

    changeViewPatientsInQueue = (event) => {
        this.props.viewPatientsInQueue(event.target.checked);
    }

    changeViewArchives = (event) => {
        this.props.viewArchives(event.target.checked);
    }

    changeViewInsuranceInfo = (event) => {
        this.props.viewInsuranceInfo(event.target.checked);
    }

    changeGetReports = (event) => {
        this.props.getReports(event.target.checked);
    }

    changeAccountCreation = (type) => {
        this.props.accountType(type);
    }

    changeFullName = (event) => {
        this.props.staffFullName(event.target.value);
    }

    changePhone = (event) => {
        this.props.staffPhone(event.target.value);
    }

    changeLicenseNo = (event) => {
        this.props.staffLicenseNo(event.target.value);
    }
    changeBillingNo = (event) => {
        this.props.ClinicDrBillingNo(event.target.value);
    }
    onAttachmentClick = (e) => {
        e.current.click();
    }

    _handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            var type = file.type.split('/');
            this.props.showStaffProfileImage(reader.result);
            this.setState({
                file: file,
            });
        }
        reader.readAsDataURL(file);
    }

    _handleSignatureChnage = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            var type = file.type.split('/');
            this.props.showStaffSignature(reader.result);
            this.setState({
                signature_file: file,
            });
        }
        reader.readAsDataURL(file);
    }

    onSaveClick = (event) => {
        this.props.addStaffMember(this.props.clinicId, this.props.clinicData, this.props);
    }

    onViewStaffMembers = (event) => {
        this.props.onViewStaffMembers();
    }

    onInviteStaff = (event) => {
        this.props.addStaffMember(this.props.clinicId, this.props.clinicData, this.props);
    }

    onCancelClick = (event) => {
        event.preventDefault();
    }

    changeNeedStaffAddress = (event) => {
        this.props.needStaffAddress(event.target.checked);
    }

    changeStaffAddress = (event) => {
        this.props.staffAddress(event.target.value);
    }

    changeStaffCountry = (event) => {
        this.props.staffCountry(event.target.value);
    }

    changeStaffProvince = (event) => {
        this.props.staffProvince(event.target.value);
    }

    changeStaffCity = (event) => {
        this.props.staffCity(event.target.value);
    }

    changeStaffPhoneNumber = (event) => {
        this.props.staffPhoneNumber(event.target.value);
    }

    changeStaffFaxNo = (event) => {
        this.props.staffFaxNo(event.target.value);
    }


    render() {
        const {
            clinicData, staff_email, staff_profile, add_more_users, create_prescription, invite_patients, soap_notes,
            ans_patients, transfer_chat, view_patients_in_queue, view_archives, view_insurance_info, get_reports,
            full_name, phone, license_no, profile_image, signature, account_type, loading, message,
            need_staff_address, staff_address, staff_country, staff_province, staff_city,
            staff_phone_number, staff_fax_no, dr_billing_no
        } = this.props;

        let {countries, provinces} = this.state;
        return (
            <div className={classes.main_container}>
                <div className={classes.main_container_header}>Add user</div>
                <div className={classes.main_container_heading}>Add your staff members. You can either enter their email
                    address and invite them to the clinic, or manually enter their information if you have them ready.
                    You can also set their user privileges that you feel is appropriate.
                </div>
                <div className={classes.form_container_view}>
                    <div className={classes.form_row}>
                        <OverlayTrigger
                            key={'left'}
                            placement={'left'}
                            overlay={
                                <Tooltip id={`tooltip-left`}>The email of the your staff.</Tooltip>
                            }
                        >
                            <input
                                className={classes.adjustable_form_input}
                                placeholder="Email"
                                value={staff_email}
                                onChange={(event) => this.changeEmail(event)}
                            />
                        </OverlayTrigger>
                        <select
                            className={classes.adjustable_form_small_input}
                            value={staff_profile}
                            onChange={(event) => this.changeProfile(event)}
                        >
                            <option value="profile">Profile</option>
                            <option value="owner">Owner</option>
                            <option value="admin">Admin</option>
                            <option value="physician">Physician</option>
                            <option value="nurse">Nurse</option>
                            <option value="receptionist">Receptionist</option>
                            <option value="custom">Custom</option>
                        </select>
                    </div>
                    <div className={classes.form_row}>
                        <div className={classes.form_heading}>Your added staff can do the following:</div>
                    </div>
                    <div className={classes.form_row}>
                        <div className={classes.form_item_left}>
                            <input
                                type="checkbox"
                                checked={add_more_users}
                                onChange={(event) => this.changeAddMoreUsers(event)}
                            />
                            <span> Add more users to your staff </span>
                        </div>
                        <div className={classes.form_item_right}>
                            <input
                                type="checkbox"
                                checked={create_prescription}
                                onChange={(event) => this.changeCreatePrescription(event)}
                            />
                            <span> Create prescriptions </span>
                        </div>
                    </div>
                    <div className={classes.form_row}>
                        <div className={classes.form_item_left}>
                            <input
                                type="checkbox"
                                checked={invite_patients}
                                onChange={(event) => this.changeInvitePatients(event)}
                            />
                            <span> Invite patients </span>
                        </div>
                        <div className={classes.form_item_right}>
                            <input
                                type="checkbox"
                                checked={soap_notes}
                                onChange={(event) => this.changeSoapNotes(event)}
                            />
                            <span> Create SOAP notes </span>
                        </div>
                    </div>
                    <div className={classes.form_row}>
                        <div className={classes.form_item_left}>
                            <input
                                type="checkbox"
                                checked={ans_patients}
                                onChange={(event) => this.changeAnswerPatients(event)}
                            />
                            <span> Answer patients </span>
                        </div>
                        <div className={classes.form_item_right}>
                            <input
                                type="checkbox"
                                checked={transfer_chat}
                                onChange={(event) => this.changeTransferChat(event)}
                            />
                            <span> Transfer chat </span>
                        </div>
                    </div>
                    <div className={classes.form_row}>
                        <div className={classes.form_item_left}>
                            <input
                                type="checkbox"
                                checked={view_patients_in_queue}
                                onChange={(event) => this.changeViewPatientsInQueue(event)}
                            />
                            <span> View patients in queue </span>
                        </div>
                        <div className={classes.form_item_right}>
                            <input
                                type="checkbox"
                                checked={view_archives}
                                onChange={(event) => this.changeViewArchives(event)}
                            />
                            <span> View archives </span>
                        </div>
                    </div>
                    <div className={classes.form_row}>
                        <div className={classes.form_item_left}>
                            <input
                                type="checkbox"
                                checked={view_insurance_info}
                                onChange={(event) => this.changeViewInsuranceInfo(event)}
                            />
                            <span> View patient insurance info </span>
                        </div>
                        <div className={classes.form_item_right}>
                            <input
                                type="checkbox"
                                checked={get_reports}
                                onChange={(event) => this.changeGetReports(event)}
                            />
                            <span> Receive reports </span>
                        </div>
                    </div>
                    <div className={classes.form_bottom_row}>
                        <div className={classes.form_bottom_item}>
                            <input
                                type="radio"
                                checked={account_type === 'invite_link' ? true : false}
                                onChange={(event) => this.changeAccountCreation('invite_link')}
                            />
                            <span> Email user to create their own account </span>
                        </div>
                        <div className={classes.form_bottom_item}>
                            <input
                                type="radio"
                                checked={account_type === 'manual' ? true : false}
                                onChange={(event) => this.changeAccountCreation('manual')}
                            />
                            <span> Manually create their account </span>
                        </div>
                    </div>
                    {account_type === 'manual' && !loading &&
                    <Fragment>
                        <div className={classes.form_row}>
                            <span className={classes.label}>Full name: </span>
                            <OverlayTrigger
                                key={'left'}
                                placement={'left'}
                                overlay={
                                    <Tooltip id={`tooltip-left`}>The staff name will be seen by your staff and
                                        patients.</Tooltip>
                                }
                            >
                                <input
                                    className={classes.form_input}
                                    value={full_name}
                                    onChange={(event) => this.changeFullName(event)}
                                />
                            </OverlayTrigger>
                        </div>
                        <div className={classes.form_row}>
                            <span className={classes.label}>Phone: </span>
                            <OverlayTrigger
                                key={'left'}
                                placement={'left'}
                                overlay={
                                    <Tooltip id={`tooltip-left`}>This is the phone number that will be used if patients
                                        have any inquiries. Please start with a + and a country code followed by your
                                        phone number. Ex +18774534777.</Tooltip>
                                }
                            >
                                <input
                                    className={classes.form_small_input}
                                    value={phone}
                                    onChange={(event) => this.changePhone(event, phone)}
                                />
                            </OverlayTrigger>
                        </div>
                        {(create_prescription && soap_notes) &&
                        <div className={classes.form_row}>
                            <span className={classes.label}>License no: </span>
                            <OverlayTrigger
                                key={'left'}
                                placement={'left'}
                                overlay={
                                    <Tooltip id={`tooltip-left`}>This is your medical identification number that will be
                                        on SOAP notes, prescriptions, etc</Tooltip>
                                }
                            >
                                <input
                                    className={classes.form_input}
                                    value={license_no}
                                    onChange={(event) => this.changeLicenseNo(event)}
                                />
                            </OverlayTrigger>
                        </div>
                        }
                        {(create_prescription && soap_notes) &&
                        <div className={classes.form_row}>
                            <span className={classes.label}>Billing No (Optional): </span>
                            <OverlayTrigger
                                key={'left'}
                                placement={'left'}
                                overlay={
                                    <Tooltip id={`tooltip-left`}></Tooltip>
                                }
                            >
                                <input
                                    className={classes.form_input}
                                    value={dr_billing_no}
                                    onChange={(event) => this.changeBillingNo(event)}
                                />
                            </OverlayTrigger>
                        </div>
                        }
                        <div className={classes.form_row}>
                            <div className={classes.check_box}>
                                <input
                                    type="checkbox"
                                    checked={need_staff_address}
                                    onChange={(event) => this.changeNeedStaffAddress(event)}
                                />
                                <span className={classes.span_style}> Do you want this user to have the same address as your clinic? </span>
                            </div>
                        </div>
                        {!need_staff_address &&
                        <Fragment>
                            <div className={classes.form_row}>
                                <span className={classes.label}>Address: </span>
                                <OverlayTrigger
                                    key={'left'}
                                    placement={'left'}
                                    overlay={
                                        <Tooltip id={`tooltip-left`}>This is the physical location of your
                                            clinic.</Tooltip>
                                    }
                                >
                                    <input
                                        className={classes.form_input}
                                        value={staff_address}
                                        onChange={(event) => this.changeStaffAddress(event)}
                                    />
                                </OverlayTrigger>
                            </div>
                            <div className={classes.form_row}>
                                <span className={classes.label}>Country: </span>
                                <OverlayTrigger
                                    key={'left'}
                                    placement={'left'}
                                    overlay={
                                        <Tooltip id={`tooltip-left`}>Country</Tooltip>
                                    }
                                >
                                    <select
                                        className={classes.form_small_adjust_input}
                                        placeholder="Country"
                                        defaultValue={staff_country}
                                        onChange={(event) => this.changeStaffCountry(event, staff_country)}>
                                        {countries.options.map(option => (
                                            <option key={option.id} id={option.id} value={option.name}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </OverlayTrigger>
                                <span className={classes.label}>Province: </span>
                                <OverlayTrigger
                                    key={'right'}
                                    placement={'right'}
                                    overlay={
                                        <Tooltip id={`tooltip-left`}>Province</Tooltip>
                                    }
                                >
                                    <select
                                        className={classes.form_small_adjust_input}
                                        placeholder="Province"
                                        defaultValue={staff_province}
                                        onChange={(event) => this.changeStaffProvince(event, staff_province)}>
                                        {provinces.options.map(option => (
                                            <option key={option.id} id={option.id} value={option.name}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </OverlayTrigger>
                            </div>
                            <div className={classes.form_row}>
                                <span className={classes.label}>City: </span>
                                <OverlayTrigger
                                    key={'left'}
                                    placement={'left'}
                                    overlay={
                                        <Tooltip id={`tooltip-left`}>City</Tooltip>
                                    }
                                >
                                    <input
                                        className={classes.form_small_input}
                                        value={staff_city}
                                        onChange={(event) => this.changeStaffCity(event)}
                                    />
                                </OverlayTrigger>
                                <span className={classes.label}>Fax Number: </span>
                                <OverlayTrigger
                                    key={'left'}
                                    placement={'left'}
                                    overlay={
                                        <Tooltip id={`tooltip-left`}>Please start with a + and a country code followed
                                            by your phone number. Ex +18774534777. If you don’t have one, please leave
                                            it blank</Tooltip>
                                    }
                                >
                                    <input
                                        className={classes.form_small_input}
                                        value={staff_fax_no}
                                        onChange={(event) => this.changeStaffFaxNo(event)}
                                    />
                                </OverlayTrigger>
                                {/*<span className={classes.label}>Phone: </span>
                                        <OverlayTrigger
                                            key={'right'}
                                            placement={'right'}
                                            overlay={
                                                <Tooltip id={`tooltip-left`}>This is the phone number that will be used if patients have any inquiries. Please start with a + and a country code followed by your phone number. Ex +18774534777</Tooltip>
                                            }
                                        >
                                            <input
                                                className={classes.form_small_input}
                                                value={staff_phone_number}
                                                onChange={(event) => this.changeStaffPhoneNumber(event)}
                                            />
                                        </OverlayTrigger>*/}
                            </div>
                        </Fragment>
                        }
                        <div className={classes.form_row}>
                            <span className={classes.label}>Profile Picture: </span>
                            <div>
                                {(profile_image !== null && profile_image !== undefined) &&
                                <img
                                    className={classes.image_container}
                                    src={profile_image}
                                    alt=""
                                />
                                }
                                <input
                                    className={classes.hidden_input}
                                    type="file"
                                    accept="image/jpeg"
                                    ref={this.textInput}
                                    onChange={(e) => this._handleImageChange(e)}
                                />
                            </div>
                            <div className={classes.column_view}>
                                <div className={classes.underline_label}
                                     onClick={() => this.onAttachmentClick(this.textInput)}>Upload Picture
                                </div>
                                <span>Profile picture should be in a .jpg or .jpeg format with a maximum file size of 5MB.</span>
                            </div>
                        </div>
                        {(create_prescription && soap_notes) &&
                        <div className={classes.form_row}>
                            <span className={classes.label}>Upload Signature: </span>
                            {(signature === null || signature === undefined) &&
                            <div>
                                <input
                                    type="file"
                                    accept="image/jpeg"
                                    ref={this.signatureFile}
                                    className={classes.hidden_input}
                                    onChange={(e) => this._handleSignatureChnage(e)}
                                />
                            </div>
                            }
                            {(signature !== null && signature !== undefined) &&
                            <img
                                className={classes.signature_container}
                                src={signature}
                                alt=""
                            />
                            }
                            <div className={classes.column_view}>
                                <div className={classes.underline_label}
                                     onClick={() => this.onAttachmentClick(this.signatureFile)}>Upload Signature
                                </div>
                                <span>Signature should be in a .jpg or .jpeg format with a maximum file size of 5MB.</span>
                            </div>
                        </div>
                        }
                        <div className={classes.button_row}>
                            {/*<button className={classes.unhiglighted_button} onClick={(event) => this.onCancelClick(event)}>Cancel</button>*/}
                            <button className={classes.higlighted_button}
                                    onClick={(event) => this.onSaveClick(event)}>Save
                            </button>
                        </div>
                    </Fragment>
                    }
                    {account_type !== 'manual' && !loading &&
                    <div className={classes.button_row}>
                        <button className={classes.unhiglighted_button}
                                onClick={(event) => this.onViewStaffMembers(event)}>View staff members
                        </button>
                        <button className={classes.higlighted_button}
                                onClick={(event) => this.onInviteStaff(event)}>Invite your staff
                        </button>
                    </div>
                    }
                    {account_type === 'invite_link' && loading &&
                    <div className={classes.center_view}>
                        <ClipLoader
                            size={100}
                            color={"#884a9d"}
                        />
                    </div>
                    }
                    {account_type !== 'invite_link' && loading &&
                    <div className={classes.center_view}>
                        <ClipLoader
                            size={100}
                            color={"#884a9d"}
                        />
                    </div>
                    }
                    {message !== '' &&
                    <div className={classes.center_view}>
                        <span>{message}</span>
                    </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({clinicProfileSettingReducer, staffClinicReducer}) => {
    const {clinicData, clinicId, dr_billing_no} = clinicProfileSettingReducer;

    const {
        staff_email, staff_profile, add_more_users, create_prescription, invite_patients, soap_notes,
        account_type, ans_patients, transfer_chat, view_patients_in_queue, view_archives, view_insurance_info,
        get_reports, full_name, phone, license_no, profile_image, signature, loading, message,
        need_staff_address, staff_address, staff_country, staff_province, staff_city, staff_phone_number,
        staff_fax_no
    } = staffClinicReducer;

    return {
        clinicData, clinicId, staff_email, staff_profile, add_more_users, create_prescription, invite_patients,
        soap_notes, account_type, ans_patients, transfer_chat, view_patients_in_queue, view_archives,
        view_insurance_info, get_reports, full_name, phone, license_no, profile_image, signature, loading, message,
        need_staff_address, staff_address, staff_country, staff_province, staff_city, staff_phone_number,
        staff_fax_no, dr_billing_no
    };
};

export default connect(mapStateToProps, {
    staffEmail,
    staffProfileOwner,
    staffProfileAdmin,
    staffProfilePhysician,
    staffProfileNurse,
    staffProfileReceptionist,
    staffProfileCustom,
    addMoreUserIntoStaff,
    createPrescription,
    transferChat,
    viewPatientsInQueue,
    soapNotes,
    anwserPatients,
    invitePatients,
    viewArchives,
    viewInsuranceInfo,
    getReports,
    accountType,
    staffFullName,
    staffPhone,
    staffLicenseNo,
    showStaffProfileImage,
    showStaffSignature,
    addStaffMember,
    needStaffAddress,
    staffAddress,
    staffCountry,
    staffProvince,
    staffCity,
    staffPhoneNumber,
    staffFaxNo,
    ClinicDrBillingNo
})(ClinicalStaff);

