
import Navigation from '../../navigationItems/clinicalNavigation/Navigation';

const ClinicalSideBar = (props) => (<Navigation
        staffData={props.staffData}
        profileImage={props.profileImage}
        navClick={(data) => props.navClick(data)}
        videoChatActive={props.videoActive}
    />
)

export default ClinicalSideBar;
