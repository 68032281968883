import {
    ATTACHMENT_UPLOAD_FAIL,
    ATTACHMENT_UPLOAD_PROGRESS,
    ATTACHMENT_UPLOAD_SUCCESS,
    CHANGE_TEXT,
    FETCH_USER_CHAT_HISTORY,
    FETCHING_MESSAGES,
    MESSAGES_FETCH_SUCCESS,
    REFRESH_MESSAGES,
    SENDING_MESSAGE,
    SET_PAYPERMINUTE
} from '../constants';

//import S3FileUpload from 'react-s3';
//import config from '../aws3config';
import _ from 'lodash';
import {auth, database, storage, storageRef} from "../firebase";
import axios from "axios";
import {firebaseRoot} from "../config";

let callbackFunc1, callbackFunc2,callbackFunc2Ref;

export const imageData = (source, callback) => {
    if (source == null) {
        callback(null)
        return
    }
    //let changedName = source.name.replace(/ /gi, "_");
    const filename = `${(new Date()).getTime()}_${source.name.replace(/[^0-9a-z.^.]/gi, '')}`

    /*const newSource = new File([source], changedName, {
        type: source.type,
    });*/

    return (dispatch, getState) => {
        // Uploading File Amazon S3. //
        dispatch({type: ATTACHMENT_UPLOAD_PROGRESS});
        let conversationId = getState().conversationReducer.conversationId
        const uploadTask = storage.ref(`/conversations/${conversationId}/${filename}`).put(source)
        uploadTask.on('state_changed', (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                //setNow(progress)
                console.log(`firebasestorage Upload is ${progress}% done`)
                switch (snapshot.state) {
                    case storageRef.TaskState.PAUSED: // or 'paused'
                        console.log('firebasestorage Upload is paused')
                        break
                    case storageRef.TaskState.RUNNING: // or 'running'
                        console.log('firebasestorage Upload is running')
                        break
                    default:
                        break
                }
            },
            (error) => {
                console.log('firebasestorage error ', error)
                callback(null)
                // Handle unsuccessful uploads
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    console.log('firebasestorage File available at', downloadURL)
                    callback(downloadURL)
                    dispatch({
                        type: ATTACHMENT_UPLOAD_SUCCESS,
                        payload: downloadURL
                    });
                }).catch((error) => {
                    console.log('firebasestorage getDownloadURL error ', error)
                    callback(null)
                    dispatch({
                        type: ATTACHMENT_UPLOAD_FAIL
                    });
                })
            })
        /*S3FileUpload.uploadFile(newSource, config)
            .then(response => {
                if (response.result.status !== 204)
                    throw new Error("Failed to upload image to S3");
                //File Uploaded to S3.
                if (response.location) {
                    callback(response.location);
                    dispatch({
                        type: ATTACHMENT_UPLOAD_SUCCESS,
                        payload: response.location
                    });
                } else {
                    dispatch({
                        type: ATTACHMENT_UPLOAD_FAIL
                    });
                }
            });*/
    };
};

export const onChangeText = (text) => {
    console.log("onChangeText ", text.target.value)
    return {
        type: CHANGE_TEXT,
        payload: text.target.value
    }
}

export const refreshMessages = () => {
    return (dispatch) => {
        dispatch({type: REFRESH_MESSAGES});
        try {
            if(callbackFunc2){
                callbackFunc2Ref.off('value', callbackFunc2);
                callbackFunc2 = null;
                callbackFunc2Ref = null;
            }
            /*let response = callbackFunc2 ? callbackFunc2.off('value') : null && callbackFunc1 ? callbackFunc1.off() : null && callbackPrescriptions
                ? callbackPrescriptions.off()
                : null && callbackPrescriptionData
                    ? callbackPrescriptionData.off() : null;*/
        } catch (error) {
            console.log("refreshMessages error ", error);
        }
    }
}

export const turnSelectChat = (conversationID) => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        getOpenConversationsRef(conversationID).once('value', snapshot => {
            if (snapshot.val()?.payPerMinuteChat === true) {
                dispatch({
                    type: SET_PAYPERMINUTE,
                    payload: true
                })
            } else {
                dispatch({
                    type: SET_PAYPERMINUTE,
                    payload: false
                })
            }
            if (snapshot.val()?.status) {
                var updates = {};
                if (currentUserID === snapshot.val()?.doctorID) {
                    updates[`/doctors/${snapshot.val()?.doctorID}/selected_chat`] = conversationID
                } else if (currentUserID !== undefined && snapshot.val()?.doctorID !== undefined) {
                    updates[`/doctors/${currentUserID}/selected_chat`] = 'On different chat'
                }
                database.ref().update(updates)
                    .then(data => {

                    })
                    .catch(error => {

                    })
            }
        })
    }
}
export const resetChatListeners = () => {
    return (dispatch) => {
        if(callbackFunc2){
            callbackFunc2Ref.off('value', callbackFunc2);
            callbackFunc2 = null;
            callbackFunc2Ref = null;
        }
        dispatch({type: REFRESH_MESSAGES});

    }
}
export const fetchAllMessages = (conversationID) => {
    console.log("fetchAllMessages triggered")
    return (dispatch) => {

        dispatch({type: FETCHING_MESSAGES});
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        callbackFunc1 = getOpenConversationsRef(conversationID)
        callbackFunc1.once('value', snapshot => {
            if (snapshot.exists() && snapshot.val().status) {
                getdoctorByRef(snapshot.val().doctorID).once('value', doctorSnap => {
                    if (doctorSnap.val()) {
                        getUserRef(snapshot.val().patientID).once('value', patientSnap => {
                            if(callbackFunc2){
                                callbackFunc2Ref.off('value', callbackFunc2);
                                callbackFunc2 = null;
                                callbackFunc2Ref = null;
                            }
                            callbackFunc2Ref = getConversationRef(conversationID)
                            callbackFunc2 = callbackFunc2Ref.on('value', snap => {
                                if (snap.val()) {
                                    var count = snap.numChildren();
                                    var i = 0;
                                    var messages = [];
                                    snap.forEach(function (childSnapshot) {
                                        let receiveMessage = childSnapshot.val() || {};

                                        receiveMessage.direction = receiveMessage?.toID === snapshot.val()?.patientID ? "right" : "left";
                                        receiveMessage.doctorImgURL = {uri: doctorSnap.val().profileImageURL};
                                        receiveMessage.doctorName = doctorSnap.val().doctorName;

                                        let objMessages = {
                                            receiveMessage: receiveMessage,
                                            timestamp: childSnapshot.key
                                        };

                                        messages.push(objMessages);
                                        if (count - 1 === i) {

                                            const bubbles = _.map(messages, (val, uid) => {
                                                return {...val, uid};
                                            });
                                            var orderedMessages = _.orderBy(bubbles, ['timestamp'], ['asc']);
                                            i = count - 1;

                                            dispatch({
                                                type: MESSAGES_FETCH_SUCCESS,
                                                payload: {
                                                    messages: orderedMessages,
                                                    location: conversationID,
                                                    doctorId: snapshot.val().doctorID,
                                                    doctorName: doctorSnap.val().doctorName,
                                                    doctorImage: doctorSnap.val().profileImageURL,
                                                    clinicPhoneNumber: (doctorSnap.val().clinicPhoneNumber) ? doctorSnap.val().clinicPhoneNumber : "",
                                                    clinicFaxNumber: doctorSnap.val().clinicFaxNumber || "",
                                                    clinicProvince: doctorSnap.val().clinicProvince || "",
                                                    clinicPostalCode: (doctorSnap.val().clinicPostalCode) ? doctorSnap.val().clinicPostalCode : "",
                                                    signatureImageURL: (doctorSnap.val().signatureImageURL) ? doctorSnap.val().signatureImageURL : "",
                                                    doctorlicense: (doctorSnap.val().doctorlicense) ? doctorSnap.val().doctorlicense : "",
                                                    clinicAddress: (doctorSnap.val().clinicAddress) ? doctorSnap.val().clinicAddress : "",
                                                    currentUserId: currentUserID,
                                                }
                                            })
                                        } else {
                                            i = i + 1;
                                        }
                                    })
                                }
                            });
                        })
                    }
                })
            }
        });
    }
};

export const makeUnreadAllMessages = (location, patientId) => {
    return (dispatch) => {
        getConversationRef(location).orderByChild('isRead').equalTo(false).once('value', unreadMessagesSnapshot => {
            unreadMessagesSnapshot.forEach(messageSnapshot => {
                if (messageSnapshot.val().fromID === patientId) {
                    database.ref(`/conversations/${location}/${messageSnapshot.key}`).update({isRead: true});
                }
            });

        });
    }
}

export const sendNewMessageNotification = (notificationToken, message, doctorName) => {
    return (dispatch) => {
    axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/newMessage?notificationToken=${notificationToken}&doctorName=${doctorName}`,
        mode: 'cors',
    }).then(data=>{}).catch(e=>{})
    axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `https://us-central1-yourdoctorsonline-staging.cloudfunctions.net/newMessageInapp?notificationToken=${notificationToken}&doctorName=${doctorName}`,
        mode: 'cors',
    }).then(data=>{}).catch(e=>{})
    axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/newMessage?notificationToken=${notificationToken}&doctorName=${doctorName}`,
        mode: 'cors',
    }).then(data=>{}).catch(e=>{})
    axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `https://us-central1-yourdoctorsonline-prod.cloudfunctions.net/newMessageInapp?notificationToken=${notificationToken}&doctorName=${doctorName}`,
        mode: 'cors',
    }).then(data=>{}).catch(e=>{})
    }
}

export const sendMessage = (conversationID, message, toID, doctorName, doctorImage, dispatch1) => {
    console.log("sendMessage triggered")
    if(dispatch1){
        console.log("sendMessage triggered a")
        dispatch1({type: SENDING_MESSAGE});
        console.log("sendMessage triggered b")
        const {currentUser} = auth;
        console.log("sendMessage triggered c")
        let currentUserID = currentUser.uid;
        console.log("sendMessage triggered d")
        let messageValue = {
            type: message.type,
            content: message.content,
            fromID: currentUserID,
            toID: toID,
            isRead: false,
            doctorName: doctorName,
            doctorImgURL: doctorImage
        };
        console.log("sendMessage triggered messageValue", messageValue)
        saveMessage(conversationID, messageValue, toID, doctorName);
    }else{
        return (dispatch) => {
            console.log("sendMessage triggered a")
            dispatch({type: SENDING_MESSAGE});
            console.log("sendMessage triggered b")
            const {currentUser} = auth;
            console.log("sendMessage triggered c")
            let currentUserID = currentUser.uid;
            console.log("sendMessage triggered d")
            let messageValue = {
                type: message.type,
                content: message.content,
                fromID: currentUserID,
                toID: toID,
                isRead: false,
                doctorName: doctorName,
                doctorImgURL: doctorImage
            };
            console.log("sendMessage triggered messageValue", messageValue)
            saveMessage(conversationID, messageValue, toID, doctorName);
        }
    }

}

export const fetchUserChatHistory = (uid, clinicID, owner) => {
    return (dispatch) => {
        if (clinicID) {
            getStaffAllMemberByRef(clinicID).once('value', allDoctorSnapshot => {
                if (allDoctorSnapshot.val()) {
                    var allDoctorKeys = Object.keys(allDoctorSnapshot.val());
                    getUserHistoryRef(uid, owner).once('value', chatHistorySnap => {
                        if (chatHistorySnap.val()) {
                            var count_total = chatHistorySnap.numChildren();
                            var j = 0;
                            var history_conversations = [];
                            chatHistorySnap.forEach(function (childSnapshots) {
                                getStaffMemberByRef(clinicID, childSnapshots.val().doctorID)
                                    .once('value', doctorSnapshot => {
                                        if (doctorSnapshot.val()) {

                                            var name = (doctorSnapshot.val().fullName !== undefined && doctorSnapshot.val().fullName !== null) ?
                                                doctorSnapshot.val().fullName : 'Doctor';

                                            var image = doctorSnapshot.val().profileImageURL;
                                            var findings = childSnapshots.val().findings;
                                            var location = childSnapshots.val().location;
                                            getConversationRef(childSnapshots.val().location).once('value', snap => {
                                                if (snap.val()) {

                                                    var count = snap.numChildren();
                                                    var i = 0;
                                                    var messages = [];
                                                    snap.forEach(function (childSnapshot) {

                                                        let receiveMessage = childSnapshot.val();

                                                        if (allDoctorKeys.indexOf(receiveMessage.toID) !== -1) {
                                                            receiveMessage.direction = "left";
                                                        } else {
                                                            receiveMessage.direction = "right";
                                                        }

                                                        if (receiveMessage.toID === undefined && childSnapshot.val().doctorID === undefined) {
                                                            receiveMessage.direction = "left";

                                                        }

                                                        let objMessages = {receiveMessage: receiveMessage}
                                                        messages.push(objMessages);

                                                        if (count - 1 === i) {
                                                            const bubbles = _.map(messages, (val, uid) => {
                                                                return {...val, uid};
                                                            });
                                                            i = count - 1;

                                                            var chat_history_child = {
                                                                messages: bubbles,
                                                                name: name,
                                                                image: image,
                                                                timestamp: childSnapshot.val().timestamp,
                                                                category: childSnapshots.val().category,
                                                                issue: childSnapshots.val().issue,
                                                                findings: findings,
                                                                location:location
                                                            };

                                                            history_conversations.push(chat_history_child);
                                                            if (count_total - 1 === j) {

                                                                const history_conversations_bubbles = _.map(history_conversations.reverse(), (val, uid) => {
                                                                    return {...val, uid};
                                                                });

                                                                dispatch({
                                                                    type: FETCH_USER_CHAT_HISTORY,
                                                                    payload: history_conversations_bubbles
                                                                })

                                                            } else {
                                                                j = j + 1;
                                                            }
                                                        } else {
                                                            i = i + 1;
                                                        }
                                                    })
                                                }
                                            });
                                        } else {
                                            j = j + 1;
                                        }
                                    });
                            });
                        } else {
                            dispatch({
                                type: FETCH_USER_CHAT_HISTORY,
                                payload: []
                            })
                        }
                    })
                }
            })
        } else {
            getAllDoctorsRef().once('value', allDoctorSnapshot => {
                var allDoctorKeys = Object.keys(allDoctorSnapshot.val())
                getUserHistoryRef(uid, owner)/*.orderByChild('timestamp')*/.limitToLast(10).once('value', chatHistorySnap => {
                    if (chatHistorySnap.val()) {
                        var count_total = chatHistorySnap.numChildren();
                        var j = 0;
                        var history_conversations = [];
                        chatHistorySnap.forEach(function (childSnapshots) {
                            getdoctorByRef(childSnapshots.val().doctorID).once('value', doctorSnapshot => {
                                if (doctorSnapshot.val()) {
                                    var name = (doctorSnapshot.val().doctorName !== undefined && doctorSnapshot.val().doctorName !== null) ?
                                        doctorSnapshot.val().doctorName : 'Doctor';
                                    var image = doctorSnapshot.val().profileImageURL;
                                    var findings = doctorSnapshot.val().findings;
                                    var location = childSnapshots.val().location;
                                    var isDelegate = childSnapshots.val().isDelegate;
                                    getConversationRef(childSnapshots.val().location).once('value', snap => {
                                        if (snap.val()) {
                                            var count = snap.numChildren();
                                            var i = 0;
                                            var messages = [];
                                            snap.forEach(function (childSnapshot) {

                                                let receiveMessage = childSnapshot.val();

                                                if (allDoctorKeys.indexOf(receiveMessage.toID) !== -1) {
                                                    receiveMessage.direction = "left";
                                                } else {
                                                    receiveMessage.direction = "right";
                                                }
                                                if (receiveMessage.toID === undefined && childSnapshot.val().doctorID === undefined) {
                                                    receiveMessage.direction = "left";

                                                }
                                                let objMessages = {receiveMessage: receiveMessage}
                                                messages.push(objMessages);
                                                if (count - 1 === i) {
                                                    const bubbles = _.map(messages, (val, uid) => {
                                                        return {...val, uid};
                                                    });
                                                    i = count - 1;
                                                    var chat_history_child = {
                                                        messages: bubbles,
                                                        name: name,
                                                        image: image,
                                                        timestamp: childSnapshot.val().timestamp,
                                                        category: childSnapshots.val().category,
                                                        issue: childSnapshots.val().issue,
                                                        findings: findings,
                                                        location:location
                                                    };

                                                    if(!isDelegate){
                                                        history_conversations.push(chat_history_child);
                                                    }

                                                    if (count_total - 1 === j) {

                                                        const history_conversations_bubbles = _.map(history_conversations.reverse(), (val, uid) => {
                                                            return {...val, uid};
                                                        });

                                                        dispatch({
                                                            type: FETCH_USER_CHAT_HISTORY,
                                                            payload: history_conversations_bubbles
                                                        })

                                                    } else {
                                                        j = j + 1;
                                                    }
                                                } else {
                                                    i = i + 1;
                                                }
                                            })
                                        }
                                        else {
                                            var messages = [];

                                            var chat_history_child = {
                                                messages: messages,
                                                name: name,
                                                image: image,
                                                timestamp: childSnapshots.val().timestamp,
                                                category: childSnapshots.val().category,
                                                issue: childSnapshots.val().issue,
                                                findings: findings,
                                                location:location
                                            };
                                            if(!isDelegate){
                                                history_conversations.push(chat_history_child);
                                            }

                                            if (count_total - 1 === j) {

                                                const history_conversations_bubbles = _.map(history_conversations.reverse(), (val, uid) => {
                                                    return {...val, uid};
                                                });

                                                dispatch({
                                                    type: FETCH_USER_CHAT_HISTORY,
                                                    payload: history_conversations_bubbles
                                                })
                                            }
                                            else {
                                                j = j + 1;
                                            }
                                        }
                                    });
                                } else {
                                    j = j + 1;
                                }
                            });
                        });
                    } else {
                        dispatch({
                            type: FETCH_USER_CHAT_HISTORY,
                            payload: []
                        })
                    }
                })
            })
        }
    }
}

const saveMessage = (conversationID, messageValue, toID, doctorName) => {
    console.log("saveMessage triggered")
    database.ref('users/' + toID).once('value', function (snapshot) {
        var user_data = snapshot.val();
        // sendNewMessageNotification(user_data.notificationToken, messageValue.content, doctorName);
    });
    console.log("saveMessage triggered1")
    getOpenConversationsRef(conversationID).once('value', snapshot => {
        if (snapshot.exists() && snapshot.val()?.status) {
            let newMessagekey = getConversationRef(conversationID).push().key;
            var updates = {};
            var now = new Date();
            messageValue.timestamp = now.getTime();
            console.log("saveMessage triggered messageValue", messageValue)
            updates[`/conversations/${conversationID}/${newMessagekey}`] = messageValue;
            database.ref().update(updates)
                .then(data => {
                    //send message notification should be here where the message is updated.
                })
                .catch((error) => {
                });
        }
    });
}

export const sendDoctorFirstMessage = (conversationID, message) => {
    return (dispatch) => {
        dispatch({type: SENDING_MESSAGE});
        getOpenConversationsRef(conversationID)
            .once('value', snapshot => {
                if (snapshot.val().status) {
                    getdoctorByRef(snapshot.val().doctorID).once('value', doctorSnap => {
                        if (doctorSnap.val()) {
                            if(message){
                                var now = new Date();
                                let messageValue = {
                                    type: "text",
                                    timestamp: now.getTime(),
                                    content: "",
                                    fromID: snapshot.val().doctorID,
                                    toID: snapshot.val().patientID,
                                    isRead: false,
                                    doctorName: doctorSnap.val().doctorName,
                                    doctorImgURL: doctorSnap.val().profileImageURL
                                };
                                let newMessagekey = getConversationRef(conversationID).push().key;
                                messageValue.content = message;
                                var updates = {};
                                updates[`/conversations/${conversationID}/${newMessagekey}`] = messageValue;
                                database.ref().update(updates)
                                    .then(() => {
                                        refreshMessages();
                                        fetchAllMessages(conversationID);
                                    })
                                    .catch(() => {
                                        refreshMessages();
                                        fetchAllMessages(conversationID);
                                    })
                            }else{
                                const onlineAgent={
                                    doctorID:snapshot.val().doctorID,
                                    profileImageURL:doctorSnap.val().profileImageURL,
                                    doctorName:doctorSnap.val().doctorName
                                }
                                const sendFirstMessageBody = {
                                    onlineAgent,
                                    conversation_id: conversationID,
                                    patient_id: snapshot.val().patientID,
                                };
                                axios({
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    url: `${firebaseRoot}/sendDoctorFirstMessage`,
                                    mode: 'cors',
                                    data: JSON.stringify(sendFirstMessageBody),
                                })
                                    .then((main) => {
                                        console.log('sendDoctorFirstMessage main ', main.data);
                                    })
                                    .catch((error) => {
                                        console.log('sendDoctorFirstMessage error ', error);
                                    });
                            }

                        }
                    })
                }
            });
    }
}

const getConversationRef = (location) => {
    return database.ref(`/conversations/${location}`);
}

const getOpenConversationsRef = (location) => {
    return database.ref(`/openconversations/${location}`);
}

const getdoctorByRef = (uid) => {
    return database.ref(`/doctors/${uid}`);
}

const getUserRef = (uid) => {
    return database.ref(`/users/${uid}`);
}

const getUserHistoryRef = (uid, owner) => {
    return database.ref(`/users/${uid}/history_conversations`).orderByChild('owner').equalTo(owner || null);

}

const getAllDoctorsRef = () => {
    return database.ref(`/doctors`);
}

const getStaffAllMemberByRef = (clinicid) => {
    return database.ref(`/clinicStaffMember/${clinicid}`);
}

const getStaffMemberByRef = (clinicid, uid) => {
    return database.ref(`/clinicStaffMember/${clinicid}/${uid}`);
}

const getOpenConversationsPrescriptionWithLocationRef = (uid) => {
    return database.ref(`/openconversations/${uid}/prescriptions`);
};
const getOpenConversationsPrescriptionDataWithLocationRef = (id, uid) => {
    return database.ref(`/openconversations/${uid}/prescriptions_data`);
};
