import {FETCHING_ACTIVITIES_FAILS, FETCHING_ACTIVITIES_IN_PROCESS, FETCHING_ACTIVITIES_SUCCESS} from '../constants.js';

const INITIAL_STATE = {actvities: [], loading: false, error: ''};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCHING_ACTIVITIES_IN_PROCESS:
            return {...state, loading: true};
        case FETCHING_ACTIVITIES_SUCCESS:
            return {...state, actvities: action.payload, loading: false};
        case FETCHING_ACTIVITIES_FAILS:
            return {...state, actvities: action.payload, loading: false};
        default:
            return state;
    }
}
