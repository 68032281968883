import {
    ADD_CLINIC_DATA_FAILED,
    ADD_CLINIC_DATA_PROCESS,
    ADD_CLINIC_DATA_SUCCESS,
    ADMIN_PROFILE_SETTING_FAILED,
    ADMIN_PROFILE_SETTING_PROCESS,
    ADMIN_PROFILE_SETTING_SUCCESS,
    CLINIC_ADMIN_ADDRESS,
    CLINIC_ADMIN_NAME,
    CLINIC_ADMIN_PHONE_NUMBER,
    CLINIC_ADMIN_PROFILE_IMAGE,
    CLINIC_ADMIN_SIGNATURE,
    CLINIC_CITY,
    CLINIC_COUNTRY,
    CLINIC_DR_BILLING_NO,
    CLINIC_EMAIL_ADDRESS,
    CLINIC_FAX,
    CLINIC_LICENSE_NO,
    CLINIC_PHYSICIAN_USER,
    CLINIC_POSTAL_CODE,
    CLINIC_PROVINCE,
    CLINIC_REPORT_EMAIL_ADDRESS,
    CLINICAL_ADMIN_CREATED_FAILED,
    CLINICAL_ADMIN_CREATED_SUCCESS,
    CLINICAL_ADMIN_ROLE_ASSIGNED_SUCCESS,
    CLINICAL_DATA_STORED_SUCCESS,
    CREATE_CLINIC_PROCESS,
    FULL_NAME,
    GET_CLINIC_DATA,
    GET_CLINIC_DATA_FAILED,
    GET_CLINIC_DATA_PROCESS,
    GET_CLINIC_DATA_SUCCESS,
    LISTEN_CLINIC_DATA,
    STAFF_PHONE_NUMBER
} from '../../../constants';

import S3FileUpload from 'react-s3';
import config from '../../../aws3config';
import {auth, database, functions} from "../../../firebase";

const {detect} = require('detect-browser');
const browser = detect();

export function create_clinic(admin_name, admin_email, admin_password, admin_clinic_name) {
    return (dispatch) => {
        dispatch({type: CREATE_CLINIC_PROCESS});

        const createClinicByAdmin = functions.httpsCallable('createClinicByAdmin');
        createClinicByAdmin({
            name: admin_name,
            email: admin_email,
            password: admin_password,
            clinicName: admin_clinic_name,
        })
            .then(admin => {
                if (admin.data.id === 2) {
                    dispatch({
                        type: CLINICAL_ADMIN_CREATED_SUCCESS,
                        payload: admin.data
                    });
                    const assignClinicalAdminRole = functions.httpsCallable('assignClinicalAdminRole');
                    assignClinicalAdminRole({
                        uid: admin.data.user.uid,
                    })
                        .then(role => {
                            if (role.data.id === 3) {
                                dispatch({
                                    type: CLINICAL_ADMIN_ROLE_ASSIGNED_SUCCESS,
                                    payload: role.data
                                })
                                const storeAdminData = functions.httpsCallable('storeAdminData');
                                storeAdminData({
                                    clinicName: admin_clinic_name,
                                    name: admin_name,
                                    uid: admin.data.user.uid,
                                    email: admin.data.user.email,
                                    clinicID: role.data.clinicID,
                                })
                                    .then(result => {
                                        if (result.data.id === 4) {
                                            auth.signInWithEmailAndPassword(admin_email, admin_password)
                                                .then(user => {
                                                    dispatch({
                                                        type: CLINICAL_DATA_STORED_SUCCESS,
                                                        payload: result.data
                                                    })
                                                })
                                                .catch(error => {
                                                    console.log('admin login error: ', error);
                                                });
                                        }
                                    })
                                    .catch((error) => {
                                        console.log('storeAdminData error: ', error);
                                    })
                            }
                        })
                        .catch((error) => {
                            console.log('assignClinicalAdminRole error: ', error);
                        });
                } else if (admin.data.id === -1) {
                    dispatch({
                        type: CLINICAL_ADMIN_CREATED_FAILED,
                        payload: admin.data.error.errorInfo.message
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: CLINICAL_ADMIN_CREATED_FAILED,
                    payload: error.message,
                })
            })
    }
}

export const getClinicAdminAndClinicData = () => {
    return (dispatch) => {
        const {currentUser} = auth;
        let currentUserID = currentUser.uid;
        currentUser.getIdTokenResult().then(idTokenResult => {
            getClinicStaffMembers(idTokenResult.claims.clinicID, currentUserID).once('value', snapshot => {
                if (snapshot.val()) {
                    getClinicsKey(snapshot.val().clinicID).once('value', clinicNameSnapshot => {
                        if (clinicNameSnapshot.val()) {
                            dispatch({
                                type: GET_CLINIC_DATA,
                                payload: {
                                    data: snapshot.val(),
                                    name: clinicNameSnapshot.val().clinicName
                                }
                            })
                        } else {
                            dispatch({
                                type: GET_CLINIC_DATA,
                                payload: []
                            })
                        }
                    })
                } else {
                    dispatch({
                        type: GET_CLINIC_DATA,
                        payload: []
                    })
                }
            });
        });
    }
}

export function add_clinic(ID, clinic_name, name, address, clinic_email, city, province, country, phone_number,
                           fax_number, postal_code, email, password, callback) {
    return (dispatch) => {
        dispatch({type: ADD_CLINIC_DATA_PROCESS});

        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        let data = {
            clinicName: clinic_name,
            clinicEmail: clinic_email,
            clinicAddress: address,
            clinicCity: city,
            clinicProvince: province,
            clinicCountry: country,
            clinicPhoneNumber: phone_number,
            clinicFaxNumber: fax_number,
            clinicPostalCode: postal_code,
        };

        let updates = {};

        updates[`clinic/${ID}/`] = data;
        updates[`clinicStaffMember/${ID}/${currentUserID}/profileCreated/`] = "update_now";

        database.ref().update(updates)
            .then(() => {
                dispatch({type: ADD_CLINIC_DATA_SUCCESS});
                callback(true);
            })
            .catch(err => {
                dispatch({type: ADD_CLINIC_DATA_FAILED});
                callback(false);
            });
    }
}

export function listenClinicData(clinicId) {
    return dispatch => {
        getClinicData(clinicId).on("value", clinicDataSnapshot => {
            if (clinicDataSnapshot.val()) {
                const {
                    clinicAddress,
                    clinicFaxNumber,
                    clinicName,
                    clinicPhoneNumber,
                    clinicCity,
                    clinicProvince,
                    clinicCountry,
                    clinicPostalCode
                } = clinicDataSnapshot.val();

                dispatch({
                    type: LISTEN_CLINIC_DATA,
                    payload: {
                        clinicAddress,
                        clinicFaxNumber,
                        clinicName,
                        clinicPhoneNumber,
                        clinicCity,
                        clinicProvince,
                        clinicCountry,
                        clinicPostalCode
                    },
                });
            }
        });
    };
}

export function getClinicDataByID(callback) {
    return (dispatch) => {
        dispatch({type: GET_CLINIC_DATA_PROCESS});

        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        currentUser.getIdTokenResult().then(idTokenResult => {

            if (idTokenResult.claims.userType === "clinical_admin") {
                getClinicStaffMembers(idTokenResult.claims.clinicID, currentUserID).once('value', snapshot => {
                    if (snapshot.val()) {
                        getClinicData(snapshot.val().clinicID).once('value', clinicDataSnapshot => {
                            if (clinicDataSnapshot.val()) {
                                let staffData = snapshot.val();
                                staffData.uid = snapshot.key;
                                dispatch({
                                    type: GET_CLINIC_DATA_SUCCESS,
                                    payload: {
                                        clinicData: clinicDataSnapshot.val(),
                                        staffData: staffData,
                                        userType: "admin",
                                        userProfile: "owner",
                                        clinicID: idTokenResult.claims.clinicID,
                                    }
                                })
                                callback(idTokenResult.claims.clinicID)
                            } else {
                                dispatch({
                                    type: GET_CLINIC_DATA_FAILED,
                                })
                                callback(null)
                            }
                        })
                    } else {
                        callback(null)
                        dispatch({
                            type: GET_CLINIC_DATA_FAILED,
                        })
                    }
                })
            } else {
                if (idTokenResult.claims.clinicID) {
                    getClinicData(idTokenResult.claims.clinicID).once('value', clinicDataSnapshot => {
                        if (clinicDataSnapshot.val()) {
                            getClinicStaffMembers(idTokenResult.claims.clinicID, currentUserID).once('value', snapshot => {
                                if (snapshot.val()) {
                                    let staffData = snapshot.val();
                                    staffData.uid = snapshot.key;
                                    dispatch({
                                        type: GET_CLINIC_DATA_SUCCESS,
                                        payload: {
                                            clinicData: clinicDataSnapshot.val(),
                                            staffData: staffData,
                                            userType: "staff",
                                            userProfile: idTokenResult.claims.userType,
                                            clinicID: idTokenResult.claims.clinicID,
                                        }
                                    })
                                    callback(idTokenResult.claims.clinicID)
                                } else {
                                    dispatch({
                                        type: GET_CLINIC_DATA_SUCCESS,
                                        payload: {
                                            clinicData: clinicDataSnapshot.val(),
                                            staffData: idTokenResult.claims,
                                            userType: "staff",
                                            userProfile: idTokenResult.claims.userType,
                                            clinicID: idTokenResult.claims.clinicID,
                                        }
                                    })
                                    callback(idTokenResult.claims.clinicID)
                                }
                            });
                        } else {
                            dispatch({
                                type: GET_CLINIC_DATA_FAILED,
                            })
                            callback(null)
                        }
                    });
                } else {
                    dispatch({
                        type: GET_CLINIC_DATA_FAILED,
                    })
                    callback(null)
                }
            }
        });
    }
}

export function ClinicName(name) {
    return {
        type: CLINIC_ADMIN_NAME,
        payload: name
    }
}

export function FullName(full_name) {
    return {
        type: FULL_NAME,
        payload: full_name
    }
}

export function ClinicAddress(address) {
    return {
        type: CLINIC_ADMIN_ADDRESS,
        payload: address
    }
}

export function ClinicProvince(province) {
    return {
        type: CLINIC_PROVINCE,
        payload: province
    }
}

export function ClinicCountry(country) {
    return {
        type: CLINIC_COUNTRY,
        payload: country
    }
}

export function ClinicalCity(city) {
    return {
        type: CLINIC_CITY,
        payload: city
    }
}

export function ClinicalPostalCode(postal_code) {
    return {
        type: CLINIC_POSTAL_CODE,
        payload: postal_code
    }
}

export function ClinicPhone(phone) {
    return {
        type: CLINIC_ADMIN_PHONE_NUMBER,
        payload: phone
    }
}

export function StaffPhoneNumber(phone) {
    return {
        type: STAFF_PHONE_NUMBER,
        payload: phone
    }
}

export function ClinicFax(fax) {
    return {
        type: CLINIC_FAX,
        payload: fax
    }
}

export function ClinicEmailAddress(email_address) {
    return {
        type: CLINIC_EMAIL_ADDRESS,
        payload: email_address
    }
}

export function ClinicPhysicianUser(physician_user) {
    return {
        type: CLINIC_PHYSICIAN_USER,
        payload: physician_user,
    }
}

export function ClinicLicense(license) {
    return {
        type: CLINIC_LICENSE_NO,
        payload: license
    }
}

export function ClinicDrBillingNo(billingNo) {
    return {
        type: CLINIC_DR_BILLING_NO,
        payload: billingNo
    }
}

export function ClinicReportEmailAddress(report_address) {
    return {
        type: CLINIC_REPORT_EMAIL_ADDRESS,
        payload: report_address
    }
}

export const showClinicAdminProfileImage = (image) => {
    return {
        type: CLINIC_ADMIN_PROFILE_IMAGE,
        payload: image
    }
}

export const showClinicAdminSignature = (image) => {
    return {
        type: CLINIC_ADMIN_SIGNATURE,
        payload: image
    }
}

export const setUpClinicProfile = (imageURL, signatureURL, uid, {
    clinic_name,
    full_name,
    address,
    province,
    city,
    country,
    phone,
    fax,
    postal_code,
    email_address,
    license_no,
    dr_billing_no,
    profile_image,
    signature,
    clinic_email_address,
    physician_user
}) => {
    return (dispatch) => {

        dispatch({type: ADMIN_PROFILE_SETTING_PROCESS});

        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        let updates = {};

        let clinicData = {
            clinicName: clinic_name,
            clinicAddress: address,
            clinicCountry: country,
            clinicProvince: province,
            clinicCity: city,
            clinicPostalCode: postal_code,
            clinicPhoneNumber: phone,
            clinicFaxNumber: fax,
            clinicEmail: email_address,
            clinicEmailAddress: clinic_email_address,
        }

        if (physician_user) {
            updates[`/clinicStaffMember/${uid}/${currentUserID}/physician`] = true;
            updates[`/clinicStaffMember/${uid}/${currentUserID}/createPrescription`] = true;
            updates[`/clinicStaffMember/${uid}/${currentUserID}/soapNotes`] = true;
            updates[`/clinicStaffMember/${uid}/${currentUserID}/ansPatients`] = true;
        }
        updates[`/clinicStaffMember/${uid}/${currentUserID}/fullName`] = full_name;
        updates[`/clinicStaffMember/${uid}/${currentUserID}/licenseNo`] = license_no;
        if (dr_billing_no) updates[`/clinicStaffMember/${uid}/${currentUserID}/billingNumber`] = dr_billing_no;


        if (imageURL !== '') {
            S3FileUpload.uploadFile(imageURL, config)
                .then(response => {
                    if (response.result.status !== 204) {
                        dispatch({
                            type: ADMIN_PROFILE_SETTING_FAILED,
                            payload: 'Image failed to upload'
                        })
                    }

                    //File Uploaded to S3.
                    if (response.location) {
                        updates[`/clinicStaffMember/${uid}/${currentUserID}/profileImageURL`] = response.location;

                        if (signatureURL !== '') {

                            S3FileUpload.uploadFile(signatureURL, config)
                                .then(sign_response => {
                                    if (sign_response.result.status !== 204) {
                                        dispatch({
                                            type: ADMIN_PROFILE_SETTING_FAILED,
                                            payload: 'Signature file fail to upload'
                                        })
                                    }

                                    //File Uploaded to S3.
                                    if (sign_response.location) {

                                        updates[`/clinic/${uid}`] = clinicData;
                                        updates[`/clinicStaffMember/${uid}/${currentUserID}/signatureImageURL`] = sign_response.location;

                                        database.ref().update(updates)
                                            .then((data) => {
                                                dispatch({
                                                    type: ADMIN_PROFILE_SETTING_SUCCESS,
                                                    payload: 'Profile has been updated successfully'
                                                })
                                            })
                                            .catch((error) => {
                                                dispatch({
                                                    type: ADMIN_PROFILE_SETTING_FAILED,
                                                    payload: error.message
                                                })
                                            })
                                    }
                                })
                        } else {

                            updates[`/clinic/${uid}`] = clinicData;
                            updates[`/clinicStaffMember/${uid}/${currentUserID}/profileImageURL`] = response.location;

                            database.ref().update(updates)
                                .then((data) => {
                                    dispatch({
                                        type: ADMIN_PROFILE_SETTING_SUCCESS,
                                        payload: 'Profile has been updated successfully'
                                    })
                                })
                                .catch((error) => {
                                    dispatch({
                                        type: ADMIN_PROFILE_SETTING_FAILED,
                                        payload: error.message
                                    })
                                })
                        }
                    }
                })
        } else {
            if (signatureURL !== '') {
                S3FileUpload.uploadFile(signatureURL, config)
                    .then(sign_response => {
                        if (sign_response.result.status !== 204) {
                            dispatch({
                                type: ADMIN_PROFILE_SETTING_FAILED,
                                payload: 'Signature file fail to upload'
                            })
                        }

                        //File Uploaded to S3.
                        if (sign_response.location) {

                            updates[`/clinic/${uid}`] = clinicData;
                            updates[`/clinicStaffMember/${uid}/${currentUserID}/signatureImageURL`] = sign_response.location;

                            database.ref().update(updates)
                                .then((data) => {
                                    dispatch({
                                        type: ADMIN_PROFILE_SETTING_SUCCESS,
                                        payload: 'Profile has been updated successfully'
                                    })
                                })
                                .catch((error) => {
                                    dispatch({
                                        type: ADMIN_PROFILE_SETTING_FAILED,
                                        payload: error.message
                                    })
                                })
                        }
                    })
            } else {
                updates[`/clinic/${uid}`] = clinicData;

                database.ref().update(updates)
                    .then((data) => {
                        dispatch({
                            type: ADMIN_PROFILE_SETTING_SUCCESS,
                            payload: 'Profile has been updated successfully'
                        })
                    })
                    .catch((error) => {
                        dispatch({
                            type: ADMIN_PROFILE_SETTING_FAILED,
                            payload: error.message
                        })
                    })
            }
        }
    }
}

export const setUpStaffProfile = (imageURL, signatureURL, uid, {
    full_name,
    phone,
    staffPhoneNumber,
    license_no,
    dr_billing_no,
    profile_image,
    signature
}) => {
    return (dispatch) => {

        dispatch({type: ADMIN_PROFILE_SETTING_PROCESS});

        const {currentUser} = auth;
        let currentUserID = currentUser.uid;

        let updates = {};

        updates[`/clinicStaffMember/${uid}/${currentUserID}/fullName`] = full_name;
        updates[`/clinicStaffMember/${uid}/${currentUserID}/phoneNumber`] = staffPhoneNumber;
        updates[`/clinicStaffMember/${uid}/${currentUserID}/licenseNo`] = license_no;
        if (dr_billing_no) updates[`/clinicStaffMember/${uid}/${currentUserID}/billingNumber`] = dr_billing_no;


        if (imageURL !== '') {
            S3FileUpload.uploadFile(imageURL, config)
                .then(response => {
                    if (response.result.status !== 204) {
                        dispatch({
                            type: ADMIN_PROFILE_SETTING_FAILED,
                            payload: 'Image failed to upload'
                        })
                    }

                    //File Uploaded to S3.
                    if (response.location) {
                        updates[`/clinicStaffMember/${uid}/${currentUserID}/profileImageURL`] = response.location;

                        if (signatureURL !== '') {

                            S3FileUpload.uploadFile(signatureURL, config)
                                .then(sign_response => {
                                    if (sign_response.result.status !== 204) {
                                        dispatch({
                                            type: ADMIN_PROFILE_SETTING_FAILED,
                                            payload: 'Signature file fail to upload'
                                        })
                                    }

                                    //File Uploaded to S3.
                                    if (sign_response.location) {
                                        updates[`/clinicStaffMember/${uid}/${currentUserID}/signatureImageURL`] = sign_response.location;

                                        database.ref().update(updates)
                                            .then((data) => {
                                                dispatch({
                                                    type: ADMIN_PROFILE_SETTING_SUCCESS,
                                                    payload: 'Profile has been updated successfully'
                                                })
                                            })
                                            .catch((error) => {
                                                dispatch({
                                                    type: ADMIN_PROFILE_SETTING_FAILED,
                                                    payload: error.message
                                                })
                                            })
                                    }
                                })
                        } else {
                            updates[`/clinicStaffMember/${uid}/${currentUserID}/profileImageURL`] = response.location;

                            database.ref().update(updates)
                                .then((data) => {
                                    dispatch({
                                        type: ADMIN_PROFILE_SETTING_SUCCESS,
                                        payload: 'Profile has been updated successfully'
                                    })
                                })
                                .catch((error) => {
                                    dispatch({
                                        type: ADMIN_PROFILE_SETTING_FAILED,
                                        payload: error.message
                                    })
                                })
                        }
                    }
                })
        } else {
            if (signatureURL !== '') {
                S3FileUpload.uploadFile(signatureURL, config)
                    .then(sign_response => {
                        if (sign_response.result.status !== 204) {
                            dispatch({
                                type: ADMIN_PROFILE_SETTING_FAILED,
                                payload: 'Signature file fail to upload'
                            })
                        }

                        //File Uploaded to S3.
                        if (sign_response.location) {

                            updates[`/clinicStaffMember/${uid}/${currentUserID}/signatureImageURL`] = sign_response.location;

                            database.ref().update(updates)
                                .then((data) => {
                                    dispatch({
                                        type: ADMIN_PROFILE_SETTING_SUCCESS,
                                        payload: 'Profile has been updated successfully'
                                    })
                                })
                                .catch((error) => {
                                    dispatch({
                                        type: ADMIN_PROFILE_SETTING_FAILED,
                                        payload: error.message
                                    })
                                })
                        }
                    })
            } else {

                database.ref().update(updates)
                    .then((data) => {
                        dispatch({
                            type: ADMIN_PROFILE_SETTING_SUCCESS,
                            payload: 'Profile has been updated successfully'
                        })
                    })
                    .catch((error) => {
                        dispatch({
                            type: ADMIN_PROFILE_SETTING_FAILED,
                            payload: error.message
                        })
                    })
            }
        }
    }
}

const getClinicsKey = (uid) => {
    return database.ref(`/clinic_ids/${uid}`);
}

const getClinicData = (uid) => {
    return database.ref(`/clinic/${uid}`);
}

const getClinicAdminData = (uid) => {
    return database.ref(`/doctors/${uid}`);
}

const getClinicStaffMembers = (ID, uid) => {
    return database.ref(`/clinicStaffMember/${ID}/${uid}`);
}
