import React, {useEffect, useState, Fragment, useRef} from 'react'
import classes from "./PastConsultationSection.module.css";
import TextareaAutosize from "react-autosize-textarea";
import Button from "react-bootstrap/Button";
import Image from "../../../components/Image";
import {connect} from "react-redux";
import Spinner from "../../../components/Spinner";
import {database} from "../../../firebase";
import { setFlagTrue, setFlagFalse } from "../../../actions/ConversationAction";
import TimeAgo from "timeago-react";
import LeftMessage from "../../../components/MessageBubble/LeftMessage";
import RightMessage from "../../../components/MessageBubble/RightMessage";
import _ from "lodash";

const PastConsultationSection = (props) => {

    const [limit, setLimit] = useState(2);
    const [showBox, setShowBox] = useState(false)
    const [unReadCountObject, setUnReadCountObject] = useState(null)
    const [showSelectedMessages, setShowSelectedMessages] = useState(null)
    const [selectedChat, setSelectedChat] = useState('')
    const [currentChatSelected, setCurrentChatSelected] = useState(true)
    const [conversationId, setConversationId] = useState(null)

    useEffect(()=>{
        setUnReadCountObject(_.find(props.conversations, [
            "conversationId",
            props.conversationId,
        ]))
    },[props.conversationId, props.conversations && props.conversations[props.conversationId]])
    useEffect(()=>{
        if(props.conversationId !== conversationId){
            setShowBox(false);
            setShowSelectedMessages(null);
            setSelectedChat('');
            setCurrentChatSelected(true);
            setConversationId(props.conversationId)
            setLimit(2);
        }
    },[props.conversationId])
    const setShowBoxFunction = () => {
        setShowBox(!showBox)
    }
    const showHistoryMessages = (uid, category, issue) => {
        props.setFlagTrue()
        setShowSelectedMessages(null);
        setSelectedChat(uid);
        setCurrentChatSelected(false);
        props.historyMessage(uid, category, issue);
    }

    const renderChatList = () => {
        return (props.chatHistory.slice(0, limit).map(chat => {
            const messagesList = chat.messages.map(message => {
                if (message.receiveMessage.direction === 'left') {
                    return <LeftMessage
                        key={message.uid}
                        content={message.receiveMessage.content}
                        patientName={'Patient'}
                    />
                } else {
                    return <RightMessage
                        key={message.uid}
                        content={message.receiveMessage.content}
                        doctorName={chat.name}
                        doctorImg={chat.image}
                    />
                }
            });
            return (
                <Fragment key={chat.uid}>
                    <div className={classes.list_container}
                         onClick={() => showHistoryMessages(chat.uid, chat.category, chat.issue)}>
                        <div
                            className={chat.uid === selectedChat ? classes.selected_item_container : classes.item_container}>
                            <div className={classes.item_content_view}>
                                <div
                                    className={chat.uid === selectedChat ? classes.first_selected_item_name : classes.first_item_name}>
                                    <TimeAgo datetime={chat.timestamp}/></div>
                                <div className={classes.categoryTab}>
                                    <div
                                        className={chat.uid === selectedChat ? classes.selected_item_name : classes.item_name}>
                                        <span className={classes.header_attr}>Symptom:</span> {chat.category}</div>
                                    <div
                                        className={chat.uid === selectedChat ? classes.selected_item_name : classes.item_name}>
                                        <span className={classes.header_attr}>Issue:</span> {chat.issue}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={showSelectedMessages === chat.uid ? classes.response_item_selected : classes.response_item}>
                        {messagesList}
                    </div>
                </Fragment>
            )
        }))
    }

    const loadCurrent = () => {
        if(unReadCountObject)
            unReadCountObject.unreadCount = 0;
        setSelectedChat(null);
        setCurrentChatSelected(true);
        props.setFlagFalse();
        props.loadCurrentChat();
    }

    const onLoadMore = () => {
        setLimit(limit + 2)
    }




    return (
        <div className={classes.generalInformation}>
            <Fragment>
                <div className={classes.openchatlist}
                     onClick={setShowBoxFunction}>
                    <div className={classes.openchatlistSection}>
                        <div
                            className={showBox === true ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                            <svg fill="#884a9d" width="12px" height="8px"
                                 viewBox="0 0 12 8">
                                <path fill="inherit" fillRule="evenodd"
                                      d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                            </svg>
                        </div>
                        <div className={classes.subListTitle}>Past Encounters</div>
                    </div>
                </div>
                {showBox && props.chatHistory.length !== 0 &&
                    <Fragment>

                    <div
                        className={currentChatSelected ? classes.selected_currentChat : classes.currentChat}
                        onClick={() => {
                            loadCurrent()
                        }}>
                        Current Chat
                        {unReadCountObject?.unreadCount !== 0 &&
                        <div className={classes.list_unread_style}>
                                                <span
                                                    className={classes.unread_msg_style}>{unReadCountObject?.unreadCount}</span>
                        </div>
                        }
                    </div>

                    <div className={classes.list}>
                        {renderChatList()}
                    </div>
                    <div className={classes.spacer}></div>
                    {limit < props.chatHistory.length &&
                    <div className={classes.loadMore} onClick={() => onLoadMore()}> Load More
                        Chat </div>
                    }
                </Fragment>}
                    {!!props.conversationId && showBox && props.chatHistory.length === 0 && <div className={classes.list}>
                        <div className={classes.list_text}>No Chat History</div>
                    </div>}

            </Fragment>
        </div>
    )


};

const mapStateToProps = ({ conversationReducer, chatReducer }) => {
    const { patientId, patients, conversationId } = conversationReducer;
    const {chatHistory} = chatReducer;
    return { patientId, patients, conversationId, chatHistory };
};

export default connect(mapStateToProps, {setFlagTrue, setFlagFalse})(PastConsultationSection);
