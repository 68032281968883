
import {NavLink} from 'react-router-dom';
import classes from './AddAgents.module.css';

const Header = (props) => {
    return (
        <div className={classes.main_body}>
            <div className={classes.heading}>
                <NavLink to="/agents"
                         className={props.activeLink === 'Agents' ? classes.active : null}
                         onClick={() => props.HeaderItem('Agents')}
                >Agents</NavLink>
                <NavLink to="/agents"
                         className={props.activeLink === 'deactivatedAgents' ? classes.active : null}
                         onClick={() => props.HeaderItem('deactivatedAgents')}
                >Deactivated Agents</NavLink>
            </div>
        </div>
    )
}

export default Header;
