import React, {useEffect, useState, Fragment, useRef} from 'react'
import classes from "../../../components/pharmacyComponent/pharmacycomponent.module.css";
import TextareaAutosize from "react-autosize-textarea";
import Button from "react-bootstrap/Button";
import Image from "../../../components/Image";
import {connect} from "react-redux";
import Spinner from "../../../components/Spinner";
import {database} from "../../../firebase";
import {updatePatient} from "../../../actions/ConversationAction";
import axios from "axios";
import {firebaseRoot} from "../../../config";

const HealthCardSection = (props) => {
    const [errorMessage, setErrorMessage] = useState('')
    const [frontPath, setFrontPath] = useState(null)
    const [patientObj, setPatientObj] = useState(null)
    const [patientId, setPatientId] = useState(null)
    const [healthCardFrontUrl, setHealthCardFrontUrl] = useState(null)
    const [healthCardBackUrl, setHealthCardBackUrl] = useState(null)
    const [backPath, setBackPath] = useState(null)
    const [healthCardNumber, setHealthCardNumber] = useState('')
    const [showBox, setShowBox] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isArchive, setIsArchive] = useState(false);
    const [ownerID, setOwnerID] = useState(null)
    const {archive_healthInsuranceCardBack, archive_healthInsuranceCardFront,archive_healthInsuranceCardNumber, from} = props;
    const frontImg = useRef();
    const backImg = useRef();
    useEffect(()=>{
        setOwnerID(props.selectedConversationObj?.owner)
        if(props.selectedConversationObj?.owner && props.selectedConversationObj?.owner !== props.selectedConversationObj?.patientID){
            setFrontPath(`/user_profile/${props.patientId}/profile_data/profiles/${props.selectedConversationObj?.owner}/healthInsuranceCardFront`)
            setBackPath(`/user_profile/${props.patientId}/profile_data/profiles/${props.selectedConversationObj?.owner}/healthInsuranceCardBack`)
            if(patientId !== props.patientId){
                setHealthCardFrontUrl( null)
                setHealthCardBackUrl(null)
                setHealthCardNumber('')
                setPatientId(props.patientId)
            }
        }else{
            setFrontPath(`/user_profile/${props.patientId}/healthCard/healthInsuranceCardFront`)
            setBackPath(`/user_profile/${props.patientId}/healthCard/healthInsuranceCardBack`)

            if(patientId !== props.patientId){
                setHealthCardFrontUrl( null)
                setHealthCardBackUrl(null)
                setHealthCardNumber('')
                setPatientId(props.patientId)
            }
        }

    }, [props.patientId, props.selectedConversationObj])
    useEffect(()=>{

        if(props.selectedPatient && props.patientId){
            console.log("HealthCardSection props.selectedPatient ", props.selectedPatient)
            console.log("HealthCardSection ownerID ", ownerID)
            console.log("HealthCardSection props.patientId ", props.patientId)

            if(props.selectedPatient && ownerID !== props.patientId && ownerID){
                if(props.selectedPatient?.profiles){
                    setHealthCardFrontUrl(props.selectedPatient?.profiles[ownerID]?.healthInsuranceCardFront || null)
                    setHealthCardBackUrl(props.selectedPatient?.profiles[ownerID]?.healthInsuranceCardBack || null)
                    setHealthCardNumber(props.selectedPatient?.profiles[ownerID]?.healthInsuranceCardNumber || '')
                }else{
                    setHealthCardFrontUrl( null)
                    setHealthCardBackUrl(null)
                    setHealthCardNumber( '')
                }

                setPatientObj(props.selectedPatient)
                if(frontImg.current)frontImg.current.resetState();
                if(backImg.current)backImg.current.resetState();
            }else{
                setHealthCardFrontUrl(props.selectedPatient?.healthInsuranceCardFront || null)
                setHealthCardBackUrl(props.selectedPatient?.healthInsuranceCardBack || null)
                setHealthCardNumber(props.selectedPatient?.healthInsuranceCardNumber || '')
                setPatientObj(props.selectedPatient)
                if(frontImg.current)frontImg.current.resetState();
                if(backImg.current)backImg.current.resetState();
            }
        }
    },[props.patientId, props.selectedPatient, ownerID])
    useEffect(()=>{
        console.log("HealthCardSection archive_healthInsuranceCardBack", archive_healthInsuranceCardBack)
        console.log("HealthCardSection archive_healthInsuranceCardFront", archive_healthInsuranceCardFront)
        console.log("HealthCardSection archive_healthInsuranceCardNumber", archive_healthInsuranceCardNumber)

        if(from === "archives"){
            setHealthCardFrontUrl(archive_healthInsuranceCardFront || null)
            setHealthCardBackUrl(archive_healthInsuranceCardBack || null)
            setHealthCardNumber(archive_healthInsuranceCardNumber || '')
            setIsArchive(true)
        }
    }, [archive_healthInsuranceCardBack, archive_healthInsuranceCardFront,archive_healthInsuranceCardNumber, from])
    const setShowBoxFunction = () => {
        setShowBox(!showBox)
    }
    const setHealthCardNumberFunction = (e) => {
        setHealthCardNumber(e.target.value)
    }
    const saveHealthCards = () => {
        if(props.patientId){
            setLoading(true)
            frontImg.current.saveImage((fUrl)=>{
                backImg.current.saveImage((bUrl)=>{
                    let updates = {};
                    if(fUrl) updates[ownerID && ownerID !== props.patientId?`users/${props.patientId}/profiles/${ownerID}/healthInsuranceCardFront`:`users/${props.patientId}/healthInsuranceCardFront`] = fUrl
                    if(bUrl) updates[ownerID && ownerID !== props.patientId?`users/${props.patientId}/profiles/${ownerID}/healthInsuranceCardBack`:`users/${props.patientId}/healthInsuranceCardBack`] = bUrl
                    if(healthCardNumber) updates[ownerID && ownerID !== props.patientId?`users/${props.patientId}/profiles/${ownerID}/healthInsuranceCardNumber`:`users/${props.patientId}/healthInsuranceCardNumber`] = healthCardNumber
                    database.ref().update(updates).then(()=>{
                        props.updatePatient(props.patientId)
                        setTimeout(setLoading(false), 100)
                    }).catch(error=>{
                        props.updatePatient(props.patientId)
                        setTimeout(setLoading(false), 100)
                    })


                })
            })
        }
    }

    const discardChanges = () =>{
        frontImg.current.resetState();
        backImg.current.resetState();
        if(props.selectedPatient && props.patientId) setHealthCardNumber(ownerID && ownerID !== props.patientId?props.selectedPatient?.profiles[ownerID]?.healthInsuranceCardNumber || "": props.selectedPatient?.healthInsuranceCardNumber || "")
    }

    return (
        <div className={classes.generalInformation}>
            <Fragment>
                <div className={classes.openchatlist}
                     onClick={setShowBoxFunction}>
                    <div className={classes.openchatlistSection}>
                        <div
                            className={showBox === true ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                            <svg fill="#884a9d" width="12px" height="8px"
                                 viewBox="0 0 12 8">
                                <path fill="inherit" fillRule="evenodd"
                                      d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                            </svg>
                        </div>
                        <div className={classes.subListTitle}>Health Insurance</div>
                    </div>
                </div>
                {showBox &&
                <div className={classes.expandable_section}>
                    {
                        <div className={classes.general_info_section}>
                            {!props.selectedConversationObj?.isEMRChat && <div className={classes.SOAP_div}>
                                <span className={classes.SOAP_heading}>This is a private pay chat.</span>
                            </div>}
                            <div className={classes.SOAP_div}>
                                <span className={classes.SOAP_heading}>Front Side:</span>
                                <Image slug='healthCardFront'
                                       value={healthCardFrontUrl}
                                       path={frontPath}
                                       ref={frontImg}
                                       saveImage={(image, callback)=>{

                                       }}
                                       disabled={isArchive}
                                       onRemoveImage={()=>{
                                           setHealthCardFrontUrl(null)
                                       }}
                                />
                            </div>
                            <div className={classes.SOAP_div}>
                                <span className={classes.SOAP_heading}>Back Side:</span>
                                <Image slug='healthCardBack'
                                       value={healthCardBackUrl}
                                       path={backPath}
                                       ref={backImg}
                                       disabled={isArchive}
                                       onRemoveImage={()=>{
                                           setHealthCardBackUrl(null)
                                       }}/>
                            </div>
                            <div className={classes.SOAP_div}>
                                <span className={classes.SOAP_heading}>Health Insurance Number:</span>
                                <input
                                    className={classes.SOAP_textarea}
                                    style={{minHeight:45}}
                                    placeholder="Enter health card number"
                                    value={healthCardNumber}
                                    disabled={isArchive}
                                    onChange={setHealthCardNumberFunction}
                                />

                            </div>

                            {errorMessage && <span style={{
                                fontSize: 14,
                                color: "#a30707"
                            }}>{errorMessage}</span>}
                            {isArchive? <div></div> :
                                loading? <div className={classes.SOAP_buttons} style={{justifyContent: "center", marginTop:10}}><Spinner color={"black"}  loading/></div> : <div className={classes.SOAP_buttons} style={{justifyContent: "right"}}>
                                {<Button size="sm" className={classes.button_invert}
                                         onClick={discardChanges}>Discard</Button>}
                                <Button size="sm" className={classes.button}
                                        onClick={saveHealthCards}>Save</Button>
                            </div>

                            }

                        </div>
                    }
                </div>
                }
            </Fragment>
        </div>
    )


}
const mapStateToProps = ({ conversationReducer }) => {
    const { patientId, selectedPatient, selectedConversationObj } = conversationReducer;
    return { patientId, selectedPatient, selectedConversationObj };
};

export default connect(mapStateToProps, {updatePatient})(HealthCardSection);
