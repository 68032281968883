import  {Component} from 'react';
import {connect} from 'react-redux';

import classes from './staff.module.css';
import Aux from '../../../hoc/hocAux';

import {fetchStaffMembers} from '../../../actions/ClinicStaffAction';
import {
    activateDoctor,
    changeAcceptStatusAdmin,
    childChangedDoctor,
    deleteDoctor,
    fetchAgents,
    resetSuccess,
    status
} from '../../../actions/AgentsAction';
import Header from './header';
import Body from './body';
import Detail from './detail';


class AgentBuilder extends Component {
    state = {
        activeLink: 'Agents',
        showAddAgentModal: false,
        showAddGroupModal: false,
        showingGroups: true,
        showingInfo: true,
        showingPerformance: true,
        doctorDetail: [],
        selectedAgent: null,
        agentStatus: false
    }

    componentDidMount() {
        this.props.fetchStaffMembers(this.props.clinicId);
        this.props.childChangedDoctor();
    }

    hideGroups = () => {
        if (this.state.showingGroups === true) {
            this.setState({showingGroups: false})
        } else {
            this.setState({showingGroups: true})
        }
    }

    hideInfo = () => {
        if (this.state.showingInfo === true) {
            this.setState({showingInfo: false})
        } else {
            this.setState({showingInfo: true})
        }
    }

    hidePerformance = () => {
        if (this.state.showingPerformance === true) {
            this.setState({showingPerformance: false})
        } else {
            this.setState({showingPerformance: true})
        }
    }

    forceLogout = (selectedAgent) => {
    }

    deleteDoctor = (selectedAgent) => {
        if (selectedAgent.onlineFreeChats || selectedAgent.onlinePaidChats) {
            alert("Please Close the existing chats of this doctor before deleting it.");
        } else if (selectedAgent['uid']) {
            if (window.confirm("Are you sure you want to delete this doctor?")) {
                this.props.deleteDoctor(selectedAgent['uid']);
                this.setState({selectedAgent: null});
            }
        }
    }

    activateDoctor = (selectedAgent) => {
        if (selectedAgent['uid']) {
            if (window.confirm("Are you sure you want to activate this doctor?")) {
                this.props.activateDoctor(selectedAgent['uid']);
                this.setState({selectedAgent: null});
            }
        }
    }

    onHeaderChange = (data) => {
        this.setState((state) => ({activeLink: data}));
    }

    onAgentClick = (agent) => {
        var status = this.props.status(agent);
        this.setState({selectedAgent: agent.uid, doctorDetail: agent, agentStatus: status});
    }

    onAcceptStatusChange = (status) => {
        this.props.changeAcceptStatusAdmin(this.state.doctorDetail, status);
    }

    updateDoctorDetail = (uid) => {
        const path = {
            pathname: '/agents/update',
            search: '',
            hash: '',
            state: {agentID: uid}
        }
        this.props.history.push(path);
    }

    changeStat = (checked) => {
        this.setState({agentStatus: !checked});
        this.onAcceptStatusChange(!checked);
    }

    render() {
        return (
            <Aux>
                <div className={classes.main_div}>
                    <div className={this.state.doctorName === "" ? classes.main_2 : classes.main}>
                        <div className={classes.header}>Staff Members</div>
                        <div className={classes.body}>
                            <Header HeaderItem={(data) => this.onHeaderChange(data)}
                                    activeLink={this.state.activeLink}/>
                            <Body
                                agentsData={this.props.staffMembers}
                                activeLink={this.state.activeLink}
                                selectedAgent={this.state.selectedAgent}
                                onAgentClick={this.onAgentClick}
                            />
                        </div>
                    </div>
                    <Detail
                        hideGroups={this.hideGroups}
                        hideInfo={this.hideInfo}
                        hidePerformance={this.hidePerformance}
                        // deleteDoctor={(selectedAgent) => this.deleteDoctor(selectedAgent)}
                        // activateDoctor={(selectedAgent) => this.activateDoctor(selectedAgent)}
                        // forceLogout={(selectedAgent) => this.forceLogout(selectedAgent)}
                        // changeAcceptStatus={() => this.onAcceptStatusChange()}
                        // updateDoctorDetail={(uid) => this.updateDoctorDetail(uid)}
                        // acceptStatusChanged={(checked) => this.changeStat(checked)}
                        showingGroups={this.state.showingGroups}
                        showingInfo={this.state.showingInfo}
                        showingPerformance={this.state.showingPerformance}
                        selectedAgent={this.state.selectedAgent}
                        doctorData={this.state.doctorDetail}
                        acceptStatus={this.state.agentStatus}
                    />
                </div>
            </Aux>
        )
    }
}

const mapStateToProps = ({clinicProfileSettingReducer, staffClinicReducer}) => {
    const {clinicId} = clinicProfileSettingReducer;
    const {staffMembers} = staffClinicReducer;

    return {clinicId, staffMembers};
}

export default connect(mapStateToProps, {
    fetchStaffMembers,
    fetchAgents,
    resetSuccess,
    deleteDoctor,
    changeAcceptStatusAdmin,
    status,
    childChangedDoctor,
    activateDoctor
})(AgentBuilder);
