import  {Component} from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import {createBrowserHistory} from 'history';

import ClinicalLayout from './components/layout/ClinicalLayout';

import Layout from './components/layout/Layout';
import Auth from './containers/auth/Auth';
import ForgotPassword from './containers/forgotPassword';
import ChatBuilder from './containers/chatBuilder/ChatBuilder';
import AgentsBuilder from './containers/agentsBuilder/AgentsBuilder';
import DoctorsBuilder from './containers/DoctorsBuilder/DoctorsBuilder';
import UserBuilder from './containers/UserBuilder/UserBuilder';

import UpdateAgent from './containers/agentsBuilder/UpdateAgent/UpdateAgent';
import CustomersBuilder from './containers/customers/CustomersBuilder';
import SettingBuilder from './containers/settings/SettingBuilder';
import TicketBuilder from './containers/tickets/TicketsBuilder';
import AddTicket from './containers/tickets/AddTicket';
import UpdateTicket from './containers/tickets/TicketDetail/TicketDetail';
import ReportsBuilder from './containers/reports/ReportsBuilder';
import ArchivesBuilder from './containers/archives/ArchivesBuilder';
import ActivitiesBuilder from './containers/activities/ActivityBuilder';
import ClinicalReportsBuilder from './containers/clinicalScreens/clinicalreports/ClinicalReportsBuilder';
import ClinicChatBuilder from './containers/clinicalScreens/clinic/ClinicChatsBuilder';
import ClinicalOnlineDoctors from './containers/clinicalScreens/doctors/ClinicalDoctorsBuilder';
import ClinicalArchives from './containers/clinicalScreens/archives/ClinicalArchivesBuilder';
import ClinicalPatients from './containers/clinicalScreens/patients/ClinicalPatientsBuilder';
import PatientRegistration from './containers/clinicalScreens/patients/ClinicalPatientRegistrationBuilder';
import ClinicalSettings from './containers/clinicalScreens/settings/SettingBuilder';
import CreateClinic from './containers/clinicalSide/create';
import AddClinic from './containers/clinicalSide/add';
import ClinicAdminHome from './containers/clinicalSide/home';
import ClinicStaff from './containers/clinicalSide/staff';
import ClinicEncounter from './containers/clinicalSide/encounters';
import ClinicSettings from './containers/clinicalSide/settings';
import {ENVIRONMENT} from './environment';

import classes from './main.module.css';
import EmrPanel from "./containers/EmrPanel";
import ReferralPanel from "./containers/referralPanel";
import ChatStatistics from "./containers/ChatStatictics";
import {auth, database, getToken, messaging, messagingRef} from "./firebase";
const browserHistory = createBrowserHistory();
class RouterApp extends Component {

	state = {loading: false, patientRegistration_url: "/patient-registration", userType: ''};

	UNSAFE_componentWillMount() {



		let self = this;
		this.setState((state) => ({loading: true}));
		auth.onAuthStateChanged(function (user) {
			if (browserHistory.location.pathname === "/prescription_doctors" || browserHistory.location.pathname === "/clinicalreport" || browserHistory.location.pathname === "/view_pdf"  || browserHistory.location.pathname === "/chat_stats" || browserHistory.location.pathname.indexOf("/patient-registration") !== -1) {
				if (browserHistory.location.pathname.indexOf("/patient-registration") !== -1) {
					self.setState((state) => ({patientRegistration_url: browserHistory.location.pathname}));
				}
				self.setState((state) => ({loading: false}));
			} else {
				if (user) {
					if(browserHistory.location.pathname === "/emr_panel" || browserHistory.location.pathname === "/referral_panel"){
						self.setState((state) => ({loading: false}));
					}else{
						user.getIdTokenResult().then(idTokenResult => {
							if (idTokenResult.claims.userType === undefined) {
								database.ref(`/doctors/${user.uid}`).once('value', agentSnap => {
									if (agentSnap.val()?.profileStatus === "deleted") {

										let updates = {};
										updates[`/doctors/${user.uid}/online`] = false;
										updates[`/onlineDoctors/${user.uid}`] = false;

										database.ref().update(updates);
										auth.signOut()
											.then(() => {
												browserHistory.replace("/auth");
												self.setState((state) => ({loading: false}));
											});

									} else if (agentSnap.val().clinicalDoctor) {
										browserHistory.push("/clinic_chats");
										self.setState((state) => ({loading: false, userType: 'ydo'}));
									} else {
										self.setState((state) => ({loading: false, userType: 'ydo'}));
										if (messagingRef.isSupported()) {
											let token = getToken();
											let updates = {};
											updates[`/doctors/${user.uid}/online`] = true;
											updates[`/doctors/${user.uid}/notification_token`] = token;
											console.log("getToken updates", updates)
											database.ref().update(updates)
												.then(data => {
													//console.log("data: ", data);
												})
												.catch(error => {
													//console.log("error: ", error);
												})
										}
										browserHistory.push("/chats");

									}
								});
							} else {
								database.ref(`/clinicStaffMember/${idTokenResult.claims.clinicID}/${user.uid}`).once('value', agentSnap => {
									if (agentSnap.val() && agentSnap.val().profileStatus === "deleted") {

										let updates = {};
										updates[`/clinicStaffMember/${idTokenResult.claims.clinicID}/${user.uid}/online`] = false;

										database.ref().update(updates);
										auth.signOut()
											.then(() => {
												browserHistory.replace("/auth");
												self.setState((state) => ({loading: false}));
											});

									} else if (agentSnap.val() && agentSnap.val().profileCreated === "newly_created") {
										browserHistory.push("/add_clinic");
										self.setState((state) => ({loading: false}));
									} else {
										browserHistory.push("/clinic_admin_home");
										self.setState((state) => ({loading: false, userType: 'clinic'}));

										if (messagingRef.isSupported()) {
											let token = getToken();
											var updates = {};
											updates[`/clinicStaffMember/${idTokenResult.claims.clinicID}/${user.uid}/online`] = true;
											updates[`/clinicStaffMember/${idTokenResult.claims.clinicID}/${user.uid}/notification_token`] = token;
											database.ref().update(updates)
												.then(data => {
													//console.log("data: ", data);
												})
												.catch(error => {
													//console.log("error: ", error);
												})

										}
									}
								});
							}
						});
					}

				} else {
					if (browserHistory.location.pathname === "/create_clinic") {
						browserHistory.replace("/create_clinic");
						self.setState((state) => ({loading: false}));
					} else {
						auth.signOut()
							.then(() => {
								browserHistory.replace("/auth");
								self.setState((state) => ({loading: false}));
							})
					}
				}
			}
		});
	}

	render() {
		if (this.state.loading) {
			return (
				<div className={classes.no_loading}>
					<div className={classes.no_loading_view}>
						<div className={classes.loading_loader}/>
					</div>
				</div>
			);
		}
		return (
			<Router history={browserHistory}>
				<Switch>
					<Route path="/auth" component={Auth}/>
					<Route path={this.state.patientRegistration_url} component={PatientRegistration}/>
					<Route path="/create_clinic" component={CreateClinic}/>
					<Route path="/emr_panel" component={EmrPanel}/>
					<Route path="/referral_panel" component={ReferralPanel}/>

					<Route path="/add_clinic" component={AddClinic}/>
					<Route path="/chat_stats" component={ChatStatistics}/>
					<Route path="/forgot_password" component={ForgotPassword}/>
					{this.state.userType === 'ydo' &&
					<Layout>
						<Route path="/chats" exact component={ChatBuilder}/>
						<Route path="/customers" component={CustomersBuilder}/>
						<Route path="/doctors" component={DoctorsBuilder}/>
						<Route path="/patients" component={UserBuilder}/>
						<Route path="/agents">
							<Route path="/agents" exact component={AgentsBuilder}/>
							<Route path="/agents/update" component={UpdateAgent}/>
						</Route>
						<Route path="/settings" component={SettingBuilder}/>
						<Route path="/tickets">
							<Route path="/tickets" exact component={TicketBuilder}/>
							<Route path="/tickets/add" component={AddTicket}/>
							<Route path="/tickets/update" component={UpdateTicket}/>
						</Route>
						<Route path="/archives" component={ArchivesBuilder}/>
						<Route path="/activities" component={ActivitiesBuilder}/>
						<Route path="/reports" component={ReportsBuilder}/>
						<Route path="/clinic_chats" component={ClinicChatBuilder}/>
						<Route path="/clinic_doctors" component={ClinicalOnlineDoctors}/>
						<Route path="/clinic_settings" component={ClinicalSettings}/>
					</Layout>
					}

					{this.state.userType !== 'ydo' &&
					<ClinicalLayout>
						<Route path="/clinic_admin_home" component={ClinicAdminHome}/>
						<Route path="/clinic_staff" component={ClinicStaff}/>
						<Route path="/clinic_patients" component={ClinicalPatients}/>
						<Route path="/clinical_reports" component={ClinicalReportsBuilder}/>
						<Route path="/clinic_encounter" component={ClinicEncounter}/>
						<Route path="/clinic_setting" component={ClinicSettings}/>
						<Route path="/clinic_archives" component={ClinicalArchives}/>
					</ClinicalLayout>
					}
				</Switch>
			</Router>
		);
	}
}

export default RouterApp;
